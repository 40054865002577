import styled from "styled-components";

import { Radio } from "../../../../../../../../../styles/radio-group-styles";

export const Container = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StatusInProgress = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const StatusCompleted = styled(StatusInProgress)`
    color: ${({ theme }) => theme.green1};
    font-weight: 600;
`;

export const StyledRadioWrapper = styled(Radio.Wrapper)`
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;
