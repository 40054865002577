import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../assets";
import { LoadingGray } from "../../components";
import ConfigsCheck from "../../components/molecules/configs-check/configs-check";
import { ModalChangePassword } from "../../components/molecules/modals/modal-change-password/modal-change-password";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TUser } from "../../models";
import { manageUser } from "../../services";
import * as s from "./styled-config-profile";

type THandleConfigurationChange = {
    groupTasks?: boolean;
    clearTaskFieldsAfterAdd?: boolean;
};

export const ConfigProfile = () => {
    const { setUser, user: contextUser } = useUser();
    const navigate = useNavigate();
    const { state: user } = useLocation() || null;

    const [userData, setUserData] = useState<TUser>({});
    const [loading, setLoading] = useState(true);
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const updateUserConfigs = useFetch({
        fn: manageUser.updateUserConfigs,
        start: false,
    });

    useEffect(() => {
        if (user === null) {
            navigate("/cronometro");
        }
    }, []);

    useEffect(() => {
        if (user) {
            getUser.onRefresh({
                userId: user.id,
            });
        }
    }, [user]);

    useEffect(() => {
        if (getUser.response) {
            setUserData(getUser.response);
            setLoading(false);
        }
        if (getUser.error) {
            toast.error("Erro ao carregar usuário.");
        }
    }, [getUser.response, getUser.error]);

    const handleClearTaskFieldsAfterAddChange = (
        clearTaskFieldsAfterAdd?: boolean
    ) => {
        if (clearTaskFieldsAfterAdd !== undefined && contextUser.id_e) {
            setUser({
                ...contextUser,
                UsersConfigs: {
                    ...contextUser.UsersConfigs,
                    clearTaskFieldsAfterAdd,
                },
            });
        }
    };

    const handleGroupTasks = (groupTasks?: boolean) => {
        if (groupTasks !== undefined && contextUser.id_e) {
            setUser({
                ...contextUser,
                UsersConfigs: {
                    ...contextUser.UsersConfigs,
                    groupTasks,
                },
            });
        }
    };

    const handleChange = (userConfigs: THandleConfigurationChange) => {
        if (userData.UsersConfigs && userData.id_e === user.id) {
            updateUserConfigs.onRefresh({
                userConfigs,
                userId: userData.id_e,
            });
            handleClearTaskFieldsAfterAddChange(
                userConfigs.clearTaskFieldsAfterAdd
            );
            handleGroupTasks(userConfigs.groupTasks);
            return;
        }
        toast.error("Erro ao atualizar configurações");
    };

    useEffect(() => {
        if (updateUserConfigs.error) {
            toast.error("Erro ao atualizar configurações");
        }
    }, [updateUserConfigs.error]);

    useEffect(() => {
        if (updateUserConfigs.response) {
            toast.success("Configuração atualizada com sucesso!");
        }
    }, [updateUserConfigs.response]);

    return (
        <>
            {isPasswordModalOpen && (
                <ModalChangePassword setOpen={setPasswordModalOpen} />
            )}
            <s.Container>
                <s.Breadcrumb>
                    <Icons.Arrow
                        className="arrow-left"
                        onClick={() => navigate("/cronometro")}
                        title="Perfil"
                    />
                    <a
                        className="breadcrumb"
                        onClick={() => navigate("/cronometro")}
                        title="Perfil"
                    >
                        Perfil
                    </a>
                    <a href="" title=""></a>
                    <Icons.Chevron
                        className="chevron-rigth"
                        color={theme.gray400}
                    />
                    <p className="blacktext">Configurações</p>
                </s.Breadcrumb>
                <s.Header>
                    <h1>Configurações</h1>
                    <p>
                        Defina as configurações para melhorar o fluxo de
                        trabalho conforme suas preferencias.
                    </p>
                </s.Header>
                {loading ? (
                    <LoadingGray />
                ) : (
                    <>
                        <s.Body>
                            <h2>CRONÔMETRO</h2>
                            <div>
                                <ConfigsCheck
                                    isChecked={
                                        userData.UsersConfigs?.groupTasks
                                    }
                                    onChange={(groupTasks) => {
                                        handleChange({
                                            groupTasks,
                                        });
                                    }}
                                />
                                <p>
                                    Agrupar entradas de tempo com a mesma
                                    descrição
                                </p>
                            </div>
                            <div>
                                <ConfigsCheck
                                    isChecked={
                                        userData.UsersConfigs
                                            ?.clearTaskFieldsAfterAdd
                                    }
                                    onChange={(clearTaskFieldsAfterAdd) => {
                                        handleChange({
                                            clearTaskFieldsAfterAdd,
                                        });
                                    }}
                                />
                                <p>
                                    Limpar descrição e projeto após o registro
                                </p>
                            </div>
                        </s.Body>
                        <s.Body>
                            <h2>AÇÕES DA CONTA</h2>
                            <s.ChangePasswordButton
                                onClick={() => setPasswordModalOpen(true)}
                            >
                                alterar senha
                            </s.ChangePasswordButton>
                        </s.Body>
                    </>
                )}
            </s.Container>
        </>
    );
};
