import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
`;

export const Picture = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
`;

export const TooltipContainer = styled.div`
    padding: 4px 12px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const AddUserButton = styled.button`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.gray400};
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Info = styled.p`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 4px;
`;

export const Users = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    flex-wrap: wrap;
`;
