import { yupResolver } from "@hookform/resolvers/yup";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../../assets";
import { Input, PrimaryButton } from "../../../../../components/atoms";
import { TProject, useUser } from "../../../../../context";
import { useFetch } from "../../../../../hooks";
import { TAuthBody, TAuthError, TAuthResponse } from "../../../../../models";
import {
    auth,
    manageUser,
    organizations,
    projects as projectsReq,
} from "../../../../../services";
import OAuth, { OAuthParams } from "../../../../../services/auth/OAuth";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import { STEPS } from "../../../register";
import * as s from "./styled-first-step";
import validation from "./validation";

type TFields = {
    email: string;
};

type TFirstStep = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
};

export const RegisterFirstStep = ({ setStep, setEmail }: TFirstStep) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reason = queryParams.get("reason");
    const credential = queryParams.get("credential");
    const isGoogleRegister = queryParams.get("google");
    // const howYouKnowUs = queryParams.get("howYouKnowUs");
    const verificationToken = queryParams.get("verificationToken");
    const email = queryParams.get("email");

    const {
        setIsLogged,
        setPermissions,
        setUserProjects,
        setWorkedHours,
        setHaveOrganization,
    } = useUser();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<TFields>({
        resolver: yupResolver(validation),
        defaultValues: {
            email: "",
        },
    });
    const [emailExists, setEmailExists] = useState(false);

    const simplifiedCreateOrganization = useFetch({
        fn: organizations.simplifiedCreateOrganization,
        start: false,
    });

    const authenticateWithGoogle = useFetch<
        OAuthParams,
        TAuthResponse,
        TAuthError
    >({
        fn: OAuth,
        start: false,
    });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const loginReq = useFetch<TAuthBody, TAuthResponse, TAuthError>({
        fn: auth,
        start: false,
    });

    const getProjects = useFetch({ fn: projectsReq.getProject, start: false });

    const userMustFinishRegister =
        authenticateWithGoogle?.response?.code === "USER_MUST_FINISH_REGISTER";

    useEffect(() => {
        if (verificationToken && email) {
            simplifiedCreateOrganization.onRefresh({
                email,
                verificationToken,
            });
        }
    }, [verificationToken]);

    useEffect(() => {
        if (
            simplifiedCreateOrganization.error?.body?.message ===
            "user already exists"
        ) {
            setEmailExists(true);
        }
        if (
            simplifiedCreateOrganization.error?.body?.message ===
            "invalid token"
        ) {
            navigate("/cadastro");
        }
    }, [simplifiedCreateOrganization.error]);

    useEffect(() => {
        if (
            simplifiedCreateOrganization?.response?.code === "GO_TO_SECOND_STEP"
        ) {
            Mixpanel.track("Primeira etapa do formulário");
            setEmail(simplifiedCreateOrganization.response.email);
            setStep(STEPS.FINAL);
        }
        if (
            simplifiedCreateOrganization?.response?.code ===
            "EMAIL_CONFIRMATION_SENT"
        ) {
            setEmail(simplifiedCreateOrganization.response.email);
            setStep(STEPS.EMAIL);
        }
    }, [simplifiedCreateOrganization.response]);

    const handleGoogleLogin = ({ credential }: CredentialResponse) => {
        authenticateWithGoogle.onRefresh({
            credential,
        });
    };

    const redirect = (haveOrganization?: boolean) => {
        if (!haveOrganization) return navigate("/registrar-empresa");
        return navigate("/cronometro");
    };

    const setProjects = (
        projects: TProject[],
        user: { name: string; email: string; organization?: string | undefined }
    ) => {
        setUserProjects(projects);
        redirect(!!user.organization);
    };

    const handleUserOrganizationData = (getUser: any, loginReq: any) => {
        setWorkedHours({
            total: getUser.response.monthlyTime,
            limit:
                (getUser?.response?.Organization?.monthly_hour_limit || 1) * 60,
        });

        if (loginReq.response.user.organization) {
            getProjects.onRefresh({ sectors: true });
        } else {
            redirect(!!loginReq.response.user.organization);
        }
    };

    const login = (response: TAuthResponse) => {
        setIsLogged(true);
        setPermissions(response.userPermsId);
        toast.success("Logado com sucesso");
        localStorage.setItem(
            "haveOrganization",
            JSON.stringify(!!response.user.organization)
        );
        setHaveOrganization(!!response.user.organization);

        getUser.onRefresh();

        redirect(!!response.user.organization);
    };

    useEffect(() => {
        if (isGoogleRegister === "true" && credential) {
            setStep(STEPS.FINAL);
        }
    }, [credential]);

    useEffect(() => {
        if (authenticateWithGoogle.response && !userMustFinishRegister) {
            login(authenticateWithGoogle.response);
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (
            getUser.response &&
            authenticateWithGoogle.response &&
            !userMustFinishRegister
        ) {
            handleUserOrganizationData(getUser, authenticateWithGoogle);
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (
            getProjects.response &&
            authenticateWithGoogle.response &&
            !userMustFinishRegister
        ) {
            setProjects(
                getProjects.response,
                authenticateWithGoogle.response.user
            );
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (userMustFinishRegister && authenticateWithGoogle.response) {
            navigate(
                `/cadastro?credential=${authenticateWithGoogle.response.credential}&google=true&name=${authenticateWithGoogle.response.name}`
            );
        }
    }, [authenticateWithGoogle.response]);

    useEffect(() => {
        if (loginReq.response && !loginReq.response.message) {
            login(loginReq.response);
        }
    }, [loginReq.response || loginReq.error]);

    useEffect(() => {
        if (getUser.response && loginReq.response) {
            handleUserOrganizationData(getUser, loginReq);
        }
    }, [getUser.response]);

    useEffect(() => {
        if (getProjects.response && loginReq.response) {
            setProjects(getProjects.response, loginReq.response.user);
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (reason === "sem-permissao")
            toast.error(
                "Suas permissões foram atualizadas. Logue-se novamente!"
            );
        if (reason === "erro")
            toast.error("Erro! Entre em contato com o suporte.");
    }, [reason]);

    const onSubmit = (data: TFields) => {
        simplifiedCreateOrganization.onRefresh(data);
    };

    return (
        <s.Container onSubmit={handleSubmit(onSubmit)}>
            <s.Title>Cadastre-se. É grátis.</s.Title>
            <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => {
                    toast.error(
                        "Erro! Tente novamente mais tarde ou faça o login com e-mail e senha."
                    );
                }}
            />
            <s.Or>ou</s.Or>
            {emailExists && (
                <s.EmailAlreadyRegistered>
                    <Icons.Info
                        width="24px"
                        height="24px"
                        color={theme.redError}
                    />
                    <div className="message-container">
                        <div> E-mail já cadastrado</div>
                        <div>
                            Entre ou redefina a senha caso tenha esquecido
                        </div>
                    </div>
                </s.EmailAlreadyRegistered>
            )}
            <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <Input
                        mask=""
                        ref={(el) => el && el.focus()}
                        placeholder="nome@empresa.com.br"
                        label="E-mail"
                        error={errors.email?.message}
                        value={value}
                        onChange={onChange}
                    />
                )}
            />
            <PrimaryButton
                icon={<Icons.Arrow color={theme.white} />}
                loading={simplifiedCreateOrganization.loading}
            >
                Continuar
            </PrimaryButton>
            <s.Terms>
                Ao continuar, você concorda com os{" "}
                <a
                    target="_blank"
                    href="https://prod-paperon.s3.eu-central-1.amazonaws.com/TERMOS_DE_USO_-_PAPER_ON_15_05_2023.pdf"
                >
                    Termos de Uso
                </a>{" "}
                e com as{" "}
                <a
                    target="_blank"
                    href="https://prod-paperon.s3.eu-central-1.amazonaws.com/POLITICA_DE_PRIVACIDADE_-_PAPERON_15_05_2023.pdf"
                >
                    Políticas de Privacidade
                </a>{" "}
                do PaperON.
            </s.Terms>
            <s.AlreadyRegister>
                Já possui conta? <a href="/login">Entrar</a>
            </s.AlreadyRegister>
        </s.Container>
    );
};
