import styled from "styled-components";

type TTable = {
    templateColumns: string;
};

export const Table = styled.div`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    cursor: default;

    .left {
        text-align: left;
    }

    .pill {
        display: flex;
        justify-content: center;

        p {
            padding: 0.625rem 1.25rem;
            &:hover {
                border: 1px solid ${({ theme }) => theme.gray200};
                border-radius: 4px;
            }
        }
    }
`;

export const TableHeader = styled.div<TTable>`
    width: 100%;
    display: grid;
    padding: 0 1.5rem;
    height: 3.5rem;
    align-items: center;
    border-radius: 4px 4px 0 0;
    background: ${({ theme }) => theme.purple50};
    grid-template-columns: ${({ templateColumns }) => templateColumns};
    text-align: center;

    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.purple800};
`;

export const TableRow = styled.div<TTable>`
    width: 100%;
    display: grid;
    padding: 0 1.5rem;
    height: 3.5rem;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.gray900};
    background: ${({ theme }) => theme.white};
    grid-template-columns: ${({ templateColumns }) => templateColumns};
    border-top: 1px solid ${({ theme }) => theme.gray200};
    text-align: center;

    &:last-child {
        border-radius: 0 0 4px 4px;
    }

    &:hover {
        background: ${({ theme }) => theme.pink50};
    }
`;
