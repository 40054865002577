import { PrimaryButton, SecondaryButton } from "../../..";
import { TDefinePermissionsModalData } from "../../../../pages/organization/project/tab-collabs/collabs-edit";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-define-permissions";

type TDefinePermissions = {
    data?: TDefinePermissionsModalData;
    removePermission?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleCheckbox: (type: "reviewer" | "biller", userId: string) => void;
};

export const DefinePermissionsModal = ({
    data,
    removePermission,
    setOpen,
    handleCheckbox,
}: TDefinePermissions) => {
    const convertTypeToText = (type?: "reviewer" | "biller") => {
        return type === "reviewer" ? "revisor" : "faturador";
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                {removePermission ? (
                    <>
                        <s.Title>
                            Remover permissão de {convertTypeToText(data?.type)}
                        </s.Title>
                        <s.Description>
                            Esta ação removerá o acesso a informações
                            confidenciais dos profissionais e a outros recursos
                            da aplicação.
                        </s.Description>
                        <s.Description>
                            Você tem certeza de que deseja remover
                            <strong> {data?.user.name}</strong> como{" "}
                            <strong>{convertTypeToText(data?.type)}</strong>?
                        </s.Description>
                    </>
                ) : (
                    <>
                        <s.Title>
                            Conceder permissão de{" "}
                            {convertTypeToText(data?.type)}
                        </s.Title>
                        <s.Description>
                            Esta ação dará acesso a informações confidenciais
                            dos profissionais e a mais recursos da aplicação.
                        </s.Description>
                        <s.Description>
                            Você tem certeza de que deseja tornar
                            <strong> {data?.user.name}</strong> um{" "}
                            <strong>{convertTypeToText(data?.type)}</strong>?
                        </s.Description>
                    </>
                )}
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            if (!data || !data.user.id_e) return;
                            handleCheckbox(data.type, data.user.id_e);
                            setOpen(false);
                        }}
                    >
                        CONFIRMAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
