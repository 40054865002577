import axios, { AxiosResponse } from "axios";

import { Auth } from "./auth/Authentication";
import { verifyWebsiteStatus } from "./middlewares/verifyWebsiteStatus";

const auth = Auth.getInstance();

export const runMiddlewares = async (...functions: (() => void)[]) => {
    await Promise.all(
        functions.map(async (middleware) => {
            await middleware();
        })
    );
};

export const axiosHttpClient = async (data: any) => {
    let axiosResponse: AxiosResponse;

    await runMiddlewares(verifyWebsiteStatus);

    try {
        axiosResponse = await axios.request({
            url: data.url,
            method: data.method,
            data: data.body,
            headers: {
                ...data.headers,
                ...(data.headers?.auth && {
                    Authorization: `Bearer ${auth.token}`,
                }),
                ...(data.headers?.refresh && {
                    "x-refresh-token": auth.refreshToken,
                }),
            },
            params: data.params,
        });
    } catch (error: any) {
        axiosResponse = error.response;
    }
    return {
        statusCode: axiosResponse?.status,
        body: axiosResponse?.data,
    };
};
