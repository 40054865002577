import { Level } from "../../models";

type LevelsMapper = {
    [key: Level | number]: string;
};

export const levelsMapper: LevelsMapper = {
    1: "Novato",
    2: "Aprendiz",
    3: "Explorador",
    4: "Experiente",
    5: "Proficiente",
    6: "Especialista",
    7: "Mestre",
    8: "Visionário",
    9: "Mestre supremo",
    10: "Rei do cronômetro",
};

export const levelsPointsRangeMapper: LevelsMapper = {
    1: "0 - 4.999 pts",
    2: "5.000 - 9.999 pts",
    3: "10.000 - 14.999 pts",
    4: "15.000 - 19.999 pts",
    5: "20.000 - 24.999 pts",
    6: "25.000 - 29.999 pts",
    7: "30.000 - 34.999 pts",
    8: "35.000 - 39.999 pts",
    9: "40.000 - 44.999 pts",
    10: "A partir de 45.000 pts",
};
