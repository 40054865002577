import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { chooseTheme } from "./assets/themes";
import { Header, ModalNPS, Sidebar } from "./components";
import { useUser } from "./context";
import AppRoutes from "./routes/routes";
import GlobalStyle, { Flex, Main } from "./styles/global-style";
import { handleUnauthenticatedRoutes, Hotjar } from "./utils";
import { Mixpanel } from "./utils/mixpanel/Mixpanel";
import { queryClient } from "./utils/query-client/query-client";
import { verifyIfHaveRedirectToTheTrack } from "./utils/user/verifyIfHaveRedirectToTheTrack";

Chart.register(...registerables, ChartDataLabels);

function App() {
    Hotjar.init();
    const navigate = useNavigate();
    const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_KEY ?? "";
    const location = useLocation();
    const { user } = useUser();

    const [openModal, setOpenModal] = useState(false);

    const [myTheme, setMyTheme] = useState<string | null>(
        localStorage.getItem("tema")
    );
    const [theme, setTheme] = useState(chooseTheme());

    const scorePending = async () => {
        return user?.netPromoterScore?.userParticipation === false;
    };

    useEffect(() => {
        setTheme(chooseTheme());
    }, [myTheme]);

    useEffect(() => {
        if (!localStorage.getItem("tema"))
            localStorage.setItem("tema", "light");
    }, []);

    useEffect(() => {
        const checkNetPromoterScore = async () => {
            if (await scorePending()) setOpenModal(true);
        };
        checkNetPromoterScore();
    }, [scorePending]);

    useEffect(() => {
        if (
            verifyIfHaveRedirectToTheTrack({
                created_at: user?.Organization?.Subscription?.[0]?.created_at,
                plan: user?.Organization?.Subscription?.[0]?.plan,
                location: window.location.href,
                trial_days: user?.Organization?.Subscription?.[0]?.trial_days,
            })
        ) {
            navigate("/cronometro");
        }
        if (window.location.href) {
            Mixpanel.track("Página acessada", {
                route: window.location.pathname,
            });
        }
    }, [window.location.href]);

    const renderHeader = () => {
        const currentUrl = location.pathname.split("/")[1];

        const routesWithoutHeader = ["not-found", "manutencao", "indisponivel"];
        if (routesWithoutHeader.includes(currentUrl)) return null;

        return <Header myTheme={myTheme} setMyTheme={setMyTheme} />;
    };

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <>
                        <Toaster
                            position="bottom-right"
                            toastOptions={{
                                className: "",
                                style: {
                                    padding: "16px",
                                    display: "flex",
                                    gap: "4px",
                                },
                                success: {
                                    style: {
                                        border: `1px solid ${theme.greenDone}`,
                                    },
                                },
                                error: {
                                    style: {
                                        border: `1px solid ${theme.red}`,
                                    },
                                },
                            }}
                            containerStyle={{
                                zIndex: 999999,
                            }}
                        />
                        <GlobalStyle />
                        <Flex isLogged={handleUnauthenticatedRoutes(location)}>
                            {handleUnauthenticatedRoutes(location) ? (
                                renderHeader()
                            ) : (
                                <>
                                    {openModal && (
                                        <ModalNPS
                                            setOpen={setOpenModal}
                                            question={
                                                user?.netPromoterScore
                                                    ?.description
                                            }
                                        />
                                    )}
                                    {/* <GameficationModals /> */}
                                    <Sidebar
                                        myTheme={myTheme}
                                        setMyTheme={setMyTheme}
                                    />
                                </>
                            )}
                            <Main>
                                <AppRoutes />
                            </Main>
                        </Flex>
                    </>
                </ThemeProvider>
            </QueryClientProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
