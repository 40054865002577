import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/roles/getRoleNames`,
        method: "get",
        headers: { auth: true },
    });

    const { body, statusCode } = httpResponse;
    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
