import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

import { Input } from "../../components";

type TTooltipContent = { simple?: boolean };

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;
    background-color: ${({ theme }) => theme.white};
`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 1rem;
    border-radius: 4px;
    width: 100%;
    div#status {
        display: flex;
        gap: 2.5rem;
        flex-direction: row;
        span:hover {
            cursor: pointer;
        }
    }
`;

export const HeaderContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.gray200};
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
`;

export const Period = styled.div`
    display: flex;
    width: 100%;
    margin-top: 24px;
    gap: 14px;
    align-items: center;
`;

export const PeriodTitle = styled.div`
    color: var(--color-gray-400, #9e9ba7);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
`;

export const PDFButton = styled.div`
    width: 200px;

    .inside-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        width: 100%;
    }

    svg {
        width: 25px;
    }
`;

export const ExcelButton = styled.div`
    width: 200px;

    .inside-button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        width: 100%;
    }

    svg {
        width: 25px;
    }
`;

export const History = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    overflow: hidden;

    span.no-reports {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        color: ${({ theme }) => theme.gray500};
        border-top: 1px solid ${({ theme }) => theme.gray200};
    }

    div.table-row {
        display: grid;
        grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 3rem;
        padding-left: 24px;

        &:hover {
            background: ${({ theme }) => theme.gray50};
        }
    }
    div.table-row-header {
        display: grid;
        grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 3rem;
        margin-left: 24px;
        height: 48px;
    }

    div.table-header {
        background: ${({ theme }) => theme.purple50};
        border-radius: 4px 4px 0 0;
    }

    div.rows {
        border-top: 1px solid ${({ theme }) => theme.gray200};
        div.table-row {
            height: 3.75rem;

            span.body-user {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-start;
                gap: 1rem;
                padding: 0 1rem;
                font-size: 0.875rem;
                color: ${({ theme }) => theme.gray600};

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre-wrap;
                }

                img.photo {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            span.body-text {
                font-size: 0.875rem;
                color: ${({ theme }) => theme.gray600};
            }

            div.buttons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;

                span.body-button {
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-size: 0.875rem;
                    height: 2rem;
                    width: 2rem;
                    color: ${({ theme }) => theme.white};
                    border: 1px solid ${({ theme }) => theme.gray100};
                    border-radius: 0.25rem;
                }

                span.options {
                    display: flex;
                    opacity: 0;
                    width: 25px;
                    height: 25px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
        max-width: 28.125rem;
        text-align: center;
    }
`;

export const InputDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TooltipContentStatus = styled.div<TTooltipContent>`
    display: flex;
    flex-direction: column;
    width: auto;

    span.tooltip-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
    }

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div.tooltip-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2rem;
            height: 32px;
            border: 1px solid ${({ theme }) => theme.gray100};
            border-radius: 4px;

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
`;

export const Tab = styled.span<{ open?: boolean }>`
    ${({ open, theme }) =>
        open
            ? `color: ${theme.purple600} !important;`
            : `color: ${theme.gray400} !important;`}
`;

export const TabTitle = styled.p`
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    text-align: left;
`;

export const SearchBar = styled(Input)`
    padding: 13px 24px !important;
    height: 50px !important;
    background-color: ${({ theme }) => theme.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    color: var(--color-gray-400, #9e9ba7);
`;

export const HeaderText = styled.div<{
    click?: boolean;
    orderBy?: "asc" | "desc";
}>`
    color: var(--color-gray-800, #3e374e);
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    text-align: left;
    display: flex;
    gap: 8px;
    align-items: center;

    ${({ click }) => click && "cursor: pointer;"};

    svg {
        transform: rotate(
            ${({ orderBy }) => (orderBy === "asc" ? "180deg" : "0deg")}
        );
    }
`;

export const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 40px;
`;

export const User = styled.div`
    display: flex;
    gap: 8px;
`;

export const Name = styled.div`
    color: var(--color-gray-600, #6e697a);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    span {
        font-size: 12px;
    }
`;

export const CommonColumn = styled.div`
    color: var(--color-gray-600, #6e697a);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Status = styled.div<{ billed?: boolean }>`
    color: ${({ billed, theme }) => (billed ? theme.green1 : "#D14141")};
    background-color: ${({ billed }) => (billed ? "#E7ECE7" : "#FDF6F6")};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.07px;
    text-transform: capitalize;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;
`;

export const TooltipContent = styled.div`
    padding: 12px 16px;
    color: var(--color-gray-600, #6e697a);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
`;

export const TotalValue = styled.div`
    height: 58px;
    border-top: 1px solid var(--color-gray-200, #cfcdd3);
    background: var(--color-gray-050, #f3f3f4);
    display: grid;
    padding-left: 24px;
    grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;

    p {
        color: var(--color-gray-800, #3e374e);
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.024px;
    }
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;

export const RadioGroupItemLabel = styled.label`
    color: var(--color-gray-800, #3e374e);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const ShowButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        width: 200px;
    }
`;

export const Buttons = styled.div`
    padding: 16px;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    gap: 20px;
`;
