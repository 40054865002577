import { styled } from "styled-components";

export const Container = styled.div`
    .tooltip-container {
        border-radius: 8px;
    }
`;

export const Content = styled.div`
    padding: 12px 16px;
    max-width: 240px;

    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Children = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
`;
