export const phoneMask = (value: string) => {
    if (value === undefined) value = "";

    let pattern = value;

    if (value.includes("+")) {
        pattern = pattern.replace(/\D/g, "");
        pattern = pattern.replace(/(\d{2})(\d)/, "+$1 $2");
        pattern = pattern.replace(/(\d{2})(\d)/, "($1) $2");
        pattern = pattern.replace(/(\d{5})(\d)/, "$1 $2");
        return pattern;
    }

    pattern = pattern.replace(/\D/g, "");
    pattern = pattern.replace(/(\d{2})(\d)/, "($1) $2");
    pattern = pattern.replace(/(\d{5})(\d)/, "$1 $2");
    return pattern;
};
