import dayjs from "dayjs";

import { Tag } from "../../../../../components";
import { TUser } from "../../../../../models";
import { formatStringNumberToMoney } from "../../../../../utils";
import { firstUpperCase } from "../../../../../utils/string-manipulation/first-upper-case";
import * as s from "./styles";

type Props = {
    userData: TUser;
};

export function TabBenefits({ userData }: Props) {
    const benefits = userData.ProjectUserBenefit;

    return (
        <s.Container>
            <s.ContainerHeader>
                <s.ContainerHeaderTitle>Benefícios</s.ContainerHeaderTitle>
            </s.ContainerHeader>
            <s.ContainerContent>
                {benefits?.length ? (
                    <s.Table>
                        <s.TableHead>
                            <s.TableHeadCell>Mês do benefício</s.TableHeadCell>
                            <s.TableHeadCell>Comentário</s.TableHeadCell>
                            <s.TableHeadCell>Projeto</s.TableHeadCell>
                            <s.TableHeadCell>Valor</s.TableHeadCell>
                        </s.TableHead>
                        <s.TableBody>
                            {benefits.map((benefit) => (
                                <s.TableRow key={benefit.Project.id_e}>
                                    <s.TableData>
                                        {firstUpperCase(
                                            dayjs(benefit.date).format(
                                                "MMMM [de] YYYY"
                                            )
                                        )}
                                    </s.TableData>
                                    <s.TableData>{benefit.comment}</s.TableData>
                                    <s.TableData>
                                        {
                                            <Tag
                                                color={benefit.Project.color}
                                                maxWidth="200px"
                                            >
                                                {benefit.Project.name}
                                            </Tag>
                                        }
                                    </s.TableData>
                                    <s.TableData>
                                        {formatStringNumberToMoney(
                                            benefit.value
                                        )}
                                    </s.TableData>
                                </s.TableRow>
                            ))}
                        </s.TableBody>
                    </s.Table>
                ) : (
                    <s.NoBenefits>
                        Não há benefícios cadastrados para este colaborador.
                    </s.NoBenefits>
                )}
            </s.ContainerContent>
        </s.Container>
    );
}
