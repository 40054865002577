import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icons, theme } from "../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../components";
import ContainerModal from "../../../../../components/molecules/modals/container-modal";
import { useFetch } from "../../../../../hooks";
import { password } from "../../../../../services";
import * as s from "./styled-password-confirmation-modal";
import { passwordConfirmationSchema } from "./validation";

type TPasswordConfirmationModal = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
};

export const PasswordConfirmationModal = ({
    setOpen,
    onSuccess,
}: TPasswordConfirmationModal) => {
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<{ password: string }>({
        resolver: yupResolver(passwordConfirmationSchema),
    });

    const validatePassword = useFetch({
        fn: password.validatePassword,
        start: false,
        noError: true,
    });

    const onSubmit = async ({ password }: { password: string }) => {
        validatePassword.onRefresh({ password });
    };

    useEffect(() => {
        if (validatePassword?.error) {
            setError("password", {
                type: "manual",
                message: "Senha incorreta",
            });
        }
    }, [validatePassword.error]);

    useEffect(() => {
        if (validatePassword.response) {
            onSuccess();
        }
    }, [validatePassword.response]);

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(onSubmit)}>
                <s.Title>Verificar senha</s.Title>
                <s.Description>
                    Para editar informações de conta, precisamos confirmar a sua
                    senha.
                </s.Description>
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            mask=""
                            type={passwordIsVisible ? "text" : "password"}
                            label="Senha"
                            placeholder="Senha"
                            value={value}
                            onChange={onChange}
                            icon={
                                passwordIsVisible ? (
                                    <Icons.Eye
                                        color={theme.gray600}
                                        onClick={() =>
                                            setPasswordIsVisible(
                                                !passwordIsVisible
                                            )
                                        }
                                    />
                                ) : (
                                    <Icons.SenhaFechado
                                        color={theme.gray600}
                                        onClick={() =>
                                            setPasswordIsVisible(
                                                !passwordIsVisible
                                            )
                                        }
                                    />
                                )
                            }
                            error={errors.password?.message}
                        />
                    )}
                />
                <s.ButtonsContainer>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton type="submit">CONTINUAR</PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};
