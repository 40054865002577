import { useState } from "react";
import { Matcher } from "react-day-picker";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    DayPicker,
    Input,
    PopoverRadix,
    TextArea,
} from "../../../../../../../../../components";
import { Radio } from "../../../../../../../../../styles/radio-group-styles";
import { priorities } from "../../mappers";
import * as s from "./styles";

type TInformationRow = {
    value: string | undefined;
    label: string;
    type?: "textarea" | "date" | "time" | "select";
    color?: string;
    editable?: boolean;
    icon?: React.ReactNode;
    altValue?: string;
    onSave?: (value: string | undefined) => void;
    disableCalendarDates?: Matcher | Matcher[];
};

export const InformationRow = ({
    value,
    label,
    type,
    color,
    editable,
    icon,
    altValue,
    disableCalendarDates,
    onSave,
}: TInformationRow) => {
    const [editing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState<string | undefined>(value);

    const chooseColor = () => {
        if (color) return color;
        if (!value) return theme.gray400;
        return theme.gray800;
    };

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const generateInputMask = () => {
        if (type === "date") return "99/99/9999";
        return "";
    };

    const generatePlaceholder = () => {
        if (type === "date") return "dd/mm/aaaa";
        if (type === "time") return "00:00";
        return "";
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (type !== "time") return;
        const inputValue = e.target.value;
        const input = inputValue.replace(/\D/g, "").split("");
        const colonIndex = inputValue.length <= 5 ? 2 : 3;
        const formattedValue = input.reduce((acc, curr, index) => {
            if (index === colonIndex) {
                return `${acc}:${curr}`;
            }
            return `${acc}${curr}`;
        }, "");
        setInputValue(formattedValue);
    };

    const generateValue = () => {
        if (type === "select") {
            if (priorities[value as keyof typeof priorities]) {
                return priorities[value as keyof typeof priorities].label;
            }
            return value;
        }
        if (type === "date") {
            return value ? new Date(value).toLocaleDateString() : altValue;
        }
        return value || altValue;
    };

    const save = () => {
        onSave?.(inputValue);
        toggleEdit();
    };

    const generateInputs = () => {
        if (type === "textarea") {
            return (
                <s.TextAreaContainer
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && e.shiftKey) {
                            setInputValue((prev) => `${prev}`);
                        }
                    }}
                >
                    <s.Button onClick={save}>
                        <Icons.Checked width="24px" color={theme.gray600} />
                    </s.Button>
                    <TextArea
                        ref={(input) => input?.focus()}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder={altValue}
                        maxLength={500}
                    />
                </s.TextAreaContainer>
            );
        }
        if (type === "date") {
            return (
                <s.DayPickerWrapper>
                    <DayPicker
                        value={inputValue ? new Date(inputValue) : undefined}
                        placeholder="Data"
                        onSelect={(date) => {
                            setInputValue(date?.toISOString());
                        }}
                        icon={
                            <Icons.Checked
                                width="24px"
                                color={theme.gray600}
                                onClick={() => {
                                    save();
                                }}
                            />
                        }
                        disabled={disableCalendarDates}
                        toYear={new Date().getFullYear() + 1}
                    />
                </s.DayPickerWrapper>
            );
        }
        if (type === "select") {
            return (
                <PopoverRadix
                    align="start"
                    open={editing}
                    side="bottom"
                    onOpenChange={(open) => {
                        if (!open) save();
                    }}
                    trigger={
                        <s.Row>
                            <s.Value color={chooseColor()}>
                                {type === "select" && (
                                    <s.PriorityIndicator color={color}>
                                        •
                                    </s.PriorityIndicator>
                                )}
                                <p>{generateValue()}</p>
                            </s.Value>
                            {editable && (
                                <s.Button onClick={toggleEdit}>{icon}</s.Button>
                            )}
                        </s.Row>
                    }
                >
                    <Radio.Root>
                        <s.StyledRadioWrapper
                            onClick={() => setInputValue("high")}
                        >
                            <Radio.Item
                                value="high"
                                checked={inputValue === "high"}
                            >
                                <Radio.Indicator />
                            </Radio.Item>
                            <s.Value color={theme.redError}>
                                <s.PriorityIndicator>•</s.PriorityIndicator>
                                Alta
                            </s.Value>
                        </s.StyledRadioWrapper>
                        <s.StyledRadioWrapper
                            onClick={() => setInputValue("medium")}
                        >
                            <Radio.Item
                                value="medium"
                                checked={inputValue === "medium"}
                            >
                                <Radio.Indicator />
                            </Radio.Item>

                            <s.Value color={theme.orange}>
                                <s.PriorityIndicator>•</s.PriorityIndicator>
                                Média
                            </s.Value>
                        </s.StyledRadioWrapper>
                        <s.StyledRadioWrapper
                            onClick={() => setInputValue("low")}
                        >
                            <Radio.Item
                                value="low"
                                checked={inputValue === "low"}
                            >
                                <Radio.Indicator />
                            </Radio.Item>

                            <s.Value color={theme.green1}>
                                <s.PriorityIndicator>•</s.PriorityIndicator>
                                Baixa
                            </s.Value>
                        </s.StyledRadioWrapper>
                    </Radio.Root>
                </PopoverRadix>
            );
        }

        return (
            <s.InputContainer width="117px">
                <Input
                    ref={(input) => input?.focus()}
                    mask={generateInputMask()}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        handleInputChange(e);
                    }}
                    placeholder={generatePlaceholder()}
                    icon={
                        <s.Button onClick={save}>
                            <Icons.Checked width="24px" color={theme.gray600} />
                        </s.Button>
                    }
                    maxLength={type === "time" ? 6 : undefined}
                />
            </s.InputContainer>
        );
    };

    return (
        <s.Row
            onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                    save();
                }
            }}
        >
            <s.Label>{label}:</s.Label>
            {editing ? (
                <s.EditContainer>{generateInputs()}</s.EditContainer>
            ) : (
                <>
                    <s.Value color={chooseColor()}>
                        {type === "select" && (
                            <s.PriorityIndicator color={color}>
                                •
                            </s.PriorityIndicator>
                        )}
                        <p>{generateValue()}</p>
                    </s.Value>
                    {editable && (
                        <s.Button onClick={toggleEdit}>{icon}</s.Button>
                    )}
                </>
            )}
        </s.Row>
    );
};
