import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TToggleLockProject = {
    hourControlId?: string;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({ hourControlId }: TToggleLockProject = {}) => {
    const params = {
        hourControlId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/project/toggleLock`,
        method: "patch",
        headers: { auth: true, "x-hour-control-id": hourControlId },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Controle de horas não encontrado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
