import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import { Actions } from "./components/actions/actions";
import { NPSCards } from "./components/nps-cards/nps-cards";
import * as s from "./styles";

type NPSTabProps = {
    data: TBackOfficeData;
};

export const NPSTab = ({ data }: NPSTabProps) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Play color={theme.purple500} />
                Ações
            </generalStyles.Title>
            <Actions />
            <NPSCards data={data} />
        </s.Container>
    );
};
