import styled from "styled-components";

type THome = {
    isOpen?: boolean;
    showCookie?: boolean;
};

export const MainContainer = styled.main<THome>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.white};

    #section1 {
        display: flex;
        gap: 1.5rem;
        max-width: 75rem;
        margin: 0 3.125rem;
        margin-top: 2.25rem;
        min-height: 50vh;
        max-height: 100vh;
        margin-bottom: 3rem;

        @media (min-width: 1200px) {
            height: 100vh;
        }

        #section1-left-content {
            display: flex;
            flex-direction: column;
            max-width: 60vh;
            margin-top: 11rem;
            gap: 4.1875rem;
            height: fit-content;

            #section1-left-text {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                h1 {
                    font-style: normal;
                    font-size: 2.75rem;
                    font-weight: 700;
                    line-height: 3.375rem;
                    color: ${({ theme }) => theme.purple600};
                }

                h2 {
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.625rem;
                    color: ${({ theme }) => theme.gray800};
                }
            }

            #section1-button {
                z-index: 1000;
                width: 18.75rem;
                button {
                    text-transform: none;
                }
                @media (max-width: 1200px) {
                    button {
                        font-size: 1.5rem;
                    }
                    width: 100%;
                }
            }
        }

        #section1-right-picture {
            margin-top: 4.6875rem;
            display: block;
            width: 34.9375rem;
        }

        @media (max-width: 1200px) {
            #section1-right-picture {
                display: none;
            }
        }
    }

    #section2 {
        position: relative;
        display: flex;
        background-color: ${({ theme }) => theme.purple700};
        align-items: center;
        justify-content: center;
        height: 35.0625rem;
        margin: 0 3.125rem;
        width: 100%;
        color: ${({ theme }) => theme.white};

        #section2-content {
            display: flex;
            max-width: 77rem;
            align-items: center;
            justify-content: center;
            margin: 0 3.125rem;
            gap: 2rem;
            position: relative;

            #section2-right-content {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                div {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    h2 {
                        font-weight: 700;
                        font-size: 2.25rem;
                        line-height: 2.6875rem;
                    }

                    p {
                        font-weight: 400;
                        font-size: 1.125rem;
                        line-height: 1.375rem;
                    }
                }

                #section2-button {
                    button {
                        width: 300px;
                        background-color: ${({ theme }) => theme.purple100};
                        text-transform: none;
                        color: ${({ theme }) => theme.purple600};
                        font-weight: 700;
                    }
                }
            }

            #section2-left-picture {
                z-index: 1000;
                margin-top: 10rem;
                box-shadow: -10px 10px 25px 0px rgba(225, 85, 243, 0.25);
                width: 45.375rem;
            }

            @media (max-width: 1200px) {
                flex-direction: column;
                margin-top: 3vw;

                #section2-left-picture {
                    width: 90vw;
                    margin-top: 0;
                }

                #section2-right-content {
                    width: 80vw;
                    margin-top: 1rem;
                    margin-bottom: 4.375rem;
                    #section2-button {
                        width: 100%;
                        button {
                            font-size: 1.5rem;
                            width: 100%;
                        }
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            height: fit-content;
        }
    }

    #section3 {
        #section3-content {
            margin-top: 11.625rem;
            margin-left: 3.125rem;
            margin-right: 3.125rem;
            margin-bottom: 8rem;
            display: flex;
            gap: 7rem;
            max-width: 65rem;

            .section3-phone-button {
                display: none;
            }

            #section3-left-content {
                width: 20vw;
                display: flex;
                flex-direction: column;
                gap: 3.25rem;

                h1 {
                    span {
                        color: ${({ theme }) => theme.pink600};
                    }
                    color: ${({ theme }) => theme.purple600};
                    font-size: 2.75rem;
                    font-weight: 700;
                    line-height: 3.75rem;
                }

                button {
                    width: 15.0625rem;
                    border: 2px solid ${({ theme }) => theme.purple600};
                    color: ${({ theme }) => theme.purple600};
                    border-radius: 4px;
                    text-transform: none;
                    font-weight: 700;
                }
            }

            #section3-right-content {
                .benefits {
                    display: flex;
                    align-items: center;
                    gap: 2.8125rem;
                    padding-bottom: 1.875rem;

                    h2 {
                        font-weight: 700;
                        font-size: 1.5rem;
                        line-height: 2.5rem;
                        color: ${({ theme }) => theme.gray800};
                    }
                    p {
                        font-weight: 400;
                        font-size: 1rem;
                        color: ${({ theme }) => theme.gray800};
                    }

                    div {
                        &:last-child {
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            margin-bottom: 6px;
                        }
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid ${({ theme }) => theme.gray200};
                    }
                    &:not(:first-child) {
                        padding-top: 1.875rem;
                    }
                }
            }

            @media (max-width: 1200px) {
                flex-direction: column;

                #section3-left-content {
                    width: 100%;
                }

                .section3-wide-button {
                    display: none;
                }

                .section3-phone-button {
                    display: flex;
                    border: 2px solid ${({ theme }) => theme.purple600};
                    color: ${({ theme }) => theme.purple600};
                    border-radius: 4px;
                    text-transform: none;
                    font-weight: 700;
                }

                button {
                    font-size: 1.5rem;
                    width: 100%;
                }
            }
        }
    }

    #section4 {
        display: flex;
        background-color: ${({ theme }) => theme.purple700};
        align-items: center;
        justify-content: center;
        height: 35.0625rem;
        margin: 0 3.125rem;
        width: 100%;
        position: relative;

        #section4-content {
            display: flex;
            margin: 0 3.125rem;
            max-width: 75rem;
            align-items: center;
            gap: 4.25rem;
            height: 100%;

            img {
                z-index: 1000;
                margin-top: 10rem;
            }

            #section4-text {
                width: 37.375rem;

                @media (max-width: 1200px) {
                    margin-top: 3rem;
                }

                h2 {
                    font-weight: 700;
                    font-size: 1.5rem;
                    line-height: 2.3438rem;
                    color: ${({ theme }) => theme.white};
                    text-align: center;
                }
            }
        }

        #section4-picture {
            width: 21.875rem;
            height: 100%;
            box-shadow: -10px 10px 25px 0px rgba(225, 85, 243, 0.25);
        }

        @media (max-width: 1200px) {
            height: fit-content;
            padding-top: 2rem;

            #section4-picture {
                svg {
                    width: 14.438rem;
                    height: 21.625rem;
                }
                box-shadow: -10px 10px 25px 0px rgba(225, 85, 243, 0.25);
            }

            #section4-content {
                flex-direction: column;
                gap: 0;

                img {
                    top: -20px;
                    width: 80vw;
                    margin-top: 0;
                }
                #section4-text {
                    margin-bottom: 9.0625rem;
                }
            }
        }
    }

    #section5 {
        display: flex;
        width: 100%;
        background: ${({ theme }) => theme.gray50};
        justify-content: center;
    }

    #section6 {
        display: flex;
        width: 100%;
        justify-content: center;

        #section6-content {
            display: flex;
            margin-top: 8.125rem;
            max-width: 76.5rem;
            gap: 6.25rem;

            #section6-header {
                h3 {
                    font-size: 1.75rem;
                    line-height: 3.25rem;
                    font-weight: 700;
                    color: ${({ theme }) => theme.purple600};
                }
            }

            #section6-faq {
                max-width: 50.5rem;
            }
        }

        @media (max-width: 1200px) {
            #section6-content {
                flex-direction: column;
                margin-left: 1rem;
                margin-right: 1rem;
                gap: 2rem;

                #section6-header {
                    h3 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    #section7 {
        display: flex;
        width: 100%;
        justify-content: center;

        #section7-content {
            display: flex;
            padding: 2.8125rem;
            border-radius: 0.5rem;
            margin: 139px 1.25rem;
            max-width: 76.5rem;
            background-color: ${({ theme }) => theme.purple700};
            gap: 3.8125rem;
            color: ${({ theme }) => theme.white};

            #section7-button {
                width: 18.5rem;
                button {
                    border: 2px solid ${({ theme }) => theme.white};
                    background: transparent;
                    text-transform: none;
                    color: ${({ theme }) => theme.white};
                }
            }

            #section7-left {
                display: flex;
                flex-direction: column;
                gap: 4rem;

                #section7-text {
                    display: flex;
                    flex-direction: column;
                    gap: 0.6875rem;

                    h2 {
                        font-weight: 700;
                        font-size: 2.75rem;
                        line-height: 3.3125rem;
                    }
                    p {
                        font-weight: 400;
                        font-size: 1.125rem;
                        line-height: 1.375rem;

                        margin-bottom: 2.75rem;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            #section7-content {
                flex-direction: column;
                margin-left: 1rem;
                margin-right: 1rem;

                #section7-button {
                    width: 100%;
                    button {
                        font-size: 1.5rem;
                    }
                }

                img {
                    width: 100%;
                }
            }
        }
    }

    footer {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: ${({ theme }) => theme.purple700};
        color: ${({ theme }) => theme.purple100};

        .bold-title {
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
            color: ${({ theme }) => theme.white};
        }

        #footer-content {
            display: flex;
            flex-direction: column;
            min-width: 76.5rem;

            #upper-content {
                display: flex;
                justify-content: space-between;
                margin-top: 5.375rem;

                #logo-content {
                    display: flex;
                    flex-direction: column;
                    max-width: 20rem;
                    gap: 1.875rem;
                }

                #right-content {
                    display: flex;
                    gap: 10rem;

                    #contact-content {
                        display: flex;
                        flex-direction: column;
                        gap: 2.0625rem;

                        ul {
                            display: flex;
                            flex-direction: column;
                            gap: 1.875rem;
                            li {
                                display: flex;
                                gap: 0.75rem;
                                font-weight: 500;
                            }
                        }
                    }

                    #column-content {
                        display: flex;
                        flex-direction: column;
                        gap: 2.25rem;

                        div {
                            display: flex;
                            gap: 1rem;
                        }
                    }

                    #social-content,
                    #partners-content {
                        display: flex;
                        flex-direction: column;
                        gap: 2.25rem;

                        div {
                            display: flex;
                            gap: 1rem;
                        }
                    }
                }
            }

            #bottom-content {
                display: flex;
                justify-content: space-between;
                margin-bottom: 4rem;
                margin-top: 6rem;

                a {
                    color: ${({ theme }) => theme.purple100};
                    text-decoration-line: underline;
                }
            }
        }

        @media (max-width: 1200px) {
            font-size: 1.2rem;

            #footer-content {
                min-width: 100%;
                padding-left: 1rem;
                padding-right: 1rem;

                #upper-content {
                    flex-direction: column;
                    gap: 3rem;

                    #right-content {
                        flex-direction: column;
                        gap: 3rem;

                        #social-content {
                            padding-bottom: 4rem;
                            border-bottom: 1px solid
                                ${({ theme }) => theme.purple400};
                        }
                    }
                }
                #bottom-content {
                    margin-top: 4rem;
                    flex-direction: column;
                    text-align: center;
                    gap: 2rem;
                }
            }
        }
    }
`;

export const Accordion = styled.div<THome>`
    display: flex;
    flex-direction: column;
    border-top: 2px solid ${({ theme }) => theme.gray200};
    padding: 1.5rem 0;
    gap: 1rem;
    cursor: pointer;

    font-size: 1.375rem;
    font-weight: 400;
    line-height: 2rem;
    color: ${({ theme }) => theme.gray600};

    :last-child {
        border-bottom: 2px solid ${({ theme }) => theme.gray200};
    }

    .faq-row-header {
        display: flex;
        justify-content: space-between;
        width: 100%;

        svg {
            transition: transform 0.3s ease;
            transform: ${(props) =>
                props.isOpen ? "rotate(180deg)" : "rotate(0)"};
        }
    }

    .faq-row-body {
        height: auto;
        max-height: ${(props) => (props.isOpen ? "600px" : "0")};
        overflow: hidden;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        -ms-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
    }

    ul {
        margin-top: 0.5rem;
        margin-left: 1.5rem;
        list-style-type: disc;
        color: ${({ theme }) => theme.gray600};
        list-style: none;
    }

    li {
        margin-bottom: 0.5rem;
    }
    h4 {
        font-size: 1.375rem;
        font-weight: 500;
        line-height: 1.75rem;
        color: ${({ theme }) => theme.gray800};
    }
`;

export const CircleButton = styled.button`
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.pink500};
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2000;
    transition: background-color 0.3s ease;

    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);

    &:hover {
        background-color: ${({ theme }) => theme.pink600};
    }
`;

export const ScrollToTopButton = styled(CircleButton)`
    bottom: 80px;

    svg {
        transform: rotate(-180deg);
    }
`;

export const CookieBar = styled.div<THome>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.white};
    padding: 0.75rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 99999;
    font-size: 1rem;

    bottom: ${({ showCookie }) => (showCookie ? "-50px" : "0")};
    transition: bottom 0.5s ease-in-out;

    button {
        display: flex;
        align-items: center;
        padding: 0.375rem;
        border-radius: 0.25rem;
        background-color: ${({ theme }) => theme.purple600};
    }
`;
