import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Icons } from "../../../../../assets";
import { useFetch } from "../../../../../hooks";
import { changePassword as changePasswordFn } from "../../../../../services/user";
import { PrimaryButton } from "../../../../atoms";
import { PasswordStrength } from "../../../password-strength/password-strength";
import * as s from "../styled-modal-change-password";
import validation from "../validation";

export type TFields = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
};

type TChangePassword = {
    setChangePassword: React.Dispatch<React.SetStateAction<boolean>>;
};

type TSeePassword = "current" | "new" | "confirm";

export const ChangePassword = ({ setChangePassword }: TChangePassword) => {
    const [seePassword, setSeePassword] = useState<TSeePassword[]>([]);

    const changePassword = useFetch({
        fn: changePasswordFn,
        start: false,
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
        watch,
    } = useForm<TFields>({
        resolver: yupResolver(validation()),
    });

    const onSubmit = (data: TFields) => {
        changePassword.onRefresh(data);
    };

    const correctInputType = (type: TSeePassword) => {
        if (seePassword.includes(type)) {
            setSeePassword(seePassword.filter((item) => item !== type));
            return;
        }
        setSeePassword([...seePassword, type]);
    };

    const typeMapper = (type: TSeePassword) => {
        return seePassword.includes(type) ? "text" : "password";
    };

    const eyeIconMapper = (type: TSeePassword) => {
        return seePassword.includes(type) ? (
            <Icons.SenhaFechado />
        ) : (
            <Icons.Eye />
        );
    };

    useEffect(() => {
        if (changePassword.response) {
            toast.success("Senha alterada com sucesso!");
            setChangePassword(false);
        }
    }, [changePassword.response]);

    useEffect(() => {
        if (changePassword.error) {
            setError("currentPassword", {
                message:
                    "Senha incorreta. Tente novamente ou clique em Esqueci minha senha.",
            });
        }
    }, [changePassword.error]);

    return (
        <s.Container onSubmit={handleSubmit(onSubmit)}>
            <h1>Alteração de senha</h1>
            <s.Label>Senha atual</s.Label>
            <Controller
                name="currentPassword"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <s.StyledInput
                        placeholder="Senha atual"
                        mask=""
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        icon={
                            <s.EyeContainer
                                onClick={() => correctInputType("current")}
                            >
                                {eyeIconMapper("current")}
                            </s.EyeContainer>
                        }
                        type={typeMapper("current")}
                        error={errors.currentPassword?.message}
                    />
                )}
            />
            <s.Label>Nova senha</s.Label>
            <Controller
                name="newPassword"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <s.StyledInput
                        placeholder="Nova senha"
                        value={value}
                        type={typeMapper("new")}
                        onChange={(e) => onChange(e.target.value)}
                        mask=""
                        icon={
                            <s.EyeContainer
                                onClick={() => correctInputType("new")}
                            >
                                {eyeIconMapper("new")}
                            </s.EyeContainer>
                        }
                        error={errors.newPassword?.message ? " " : undefined}
                    />
                )}
            />
            <s.PasswordStrengthWrapper>
                <PasswordStrength
                    error={errors.newPassword}
                    password={watch("newPassword")}
                />
            </s.PasswordStrengthWrapper>
            <s.Label>Confirme nova senha</s.Label>
            <Controller
                name="newPasswordConfirm"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <s.StyledInput
                        placeholder="Confirme nova senha"
                        value={value}
                        type={typeMapper("confirm")}
                        onChange={(e) => onChange(e.target.value)}
                        mask=""
                        icon={
                            <s.EyeContainer
                                onClick={() => correctInputType("confirm")}
                            >
                                {eyeIconMapper("confirm")}
                            </s.EyeContainer>
                        }
                        error={errors.newPasswordConfirm?.message}
                    />
                )}
            />
            <PrimaryButton style={{ marginTop: "44px" }}>
                ALTERAR SENHA
            </PrimaryButton>
        </s.Container>
    );
};
