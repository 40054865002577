import styled from "styled-components";

import { theme } from "../../../assets";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 13.5625rem;
    margin-bottom: 8.0625rem;
    gap: 3.25rem;

    .toggleAnnualMode {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        align-items: center;

        .planMode {
            color: ${({ theme }) => theme.gray400};
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.042px;
        }
    }

    #section5-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 40px;
        h2 {
            font-weight: 700;
            font-size: 2.75rem;
            line-height: 3.75rem;
            color: ${({ theme }) => theme.purple600};
        }
        p {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.625rem;
            color: ${({ theme }) => theme.gray800};
        }
        text-align: center;
    }

    #section5-cards {
        display: flex;
        gap: 23px;
        justify-content: center;
        align-items: flex-start;

        .section5-card {
            display: flex;
            flex-direction: column;
            width: 288px;
            position: relative;
            min-width: 288px;
            justify-content: center;
            align-items: center;
            gap: 1.1875rem;
            padding-bottom: 0;
            border-radius: 8px;
            box-shadow: 0px -10px 0px ${({ theme }) => theme.purple200};
            background-color: ${({ theme }) => theme.white};
            .annualPromotionInformation {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                p {
                    color: #2e7d32;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
            .section5-card-header {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 45px;
                gap: 20px;
                height: 150px;
                width: 240px;

                strong {
                    font-weight: 600;
                    font-size: 20px;
                    color: ${({ theme }) => theme.purple300};
                }

                .information {
                    color: ${({ theme }) => theme.gray800};
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    span {
                        text-decoration-line: underline;
                    }
                }

                .priceInformation {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: baseline;

                    .coin {
                        display: flex;
                        justify-content: center;
                        align-items: end;
                        height: 100%;
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    }

                    .planValue {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .price {
                            color: ${({ theme }) => theme.gray800};
                            text-align: right;
                            font-size: 50px;
                            font-weight: 700;
                            line-height: 26px;
                            height: 100%;
                        }

                        .centsAndMonth {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: start;
                            align-items: start;

                            .cents {
                                color: ${({ theme }) => theme.gray800};
                                text-align: center;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px;
                            }

                            .months {
                                color: ${({ theme }) => theme.gray400};
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }

            section {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                padding-top: 20px;

                .planInformation {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 4px;
                    height: 70px;
                    h1 {
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                    }
                    p {
                        color: ${({ theme }) => theme.gray400};
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }

                .section5-card-button {
                    padding: 0 2.9375rem;

                    button {
                        border-radius: 4px;
                        width: 240px;
                        text-transform: none;
                        color: var(--color-neutral-white-02, #fcfcfc);
                        text-align: center;
                    }
                }
            }

            .section5-card-list {
                display: flex;
                flex-direction: column;
                justify-content: start;
                padding: 0 2rem;
                list-style: none;
                gap: 14px;
                padding-bottom: 40px;
                padding-top: 40px;
                border-top: 1px solid #e7e6e9;

                svg {
                    width: 24px;
                    height: 24px;
                }

                li {
                    display: flex;
                    justify-content: start;
                    gap: 18px;
                    align-items: center;
                    flex-direction: row;
                    color: ${({ theme }) => theme.gray800};
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }

            .section5-card-footer {
                display: flex;
                gap: 0.3125rem;
                justify-content: center;
                align-items: center;
                padding: 1.375rem 0;
                border-radius: 0 0 8px 8px;
                background-color: ${({ theme }) => theme.purple300};
                color: ${({ theme }) => theme.white};
                font-size: 1.125rem;
                line-height: 2.0625rem;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 1400px) {
        display: flex;
        flex-direction: column;
        margin-top: 13.5625rem;
        margin-bottom: 8.0625rem;
        gap: 3.25rem;

        .toggleAnnualMode {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            align-items: center;

            .planMode {
                color: ${({ theme }) => theme.gray400};
                font-size: 28px;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0.042px;
            }
        }

        #section5-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 40px;
            h2 {
                font-weight: 700;
                font-size: 2.75rem;
                line-height: 3.75rem;
                color: ${({ theme }) => theme.purple600};
            }
            p {
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.625rem;
                color: ${({ theme }) => theme.gray800};
            }
            text-align: center;
        }

        #section5-cards {
            display: flex;
            gap: 1.4375rem;
            justify-content: center;
            align-items: flex-start;

            .section5-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 240px;
                position: relative;
                min-width: 240px;
                gap: 1.1875rem;
                padding-bottom: 0;
                border-radius: 8px;
                box-shadow: 0px -10px 0px ${({ theme }) => theme.purple200};
                background-color: ${({ theme }) => theme.white};

                .section5-card-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding-top: 45px;
                    gap: 20px;
                    height: 120px;
                    width: 200px;

                    strong {
                        font-weight: 600;
                        font-size: 18px;
                        color: ${({ theme }) => theme.purple300};
                    }

                    .information {
                        color: ${({ theme }) => theme.gray800};
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        span {
                            text-decoration-line: underline;
                        }
                    }

                    .priceInformation {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: baseline;

                        .coin {
                            display: flex;
                            justify-content: center;
                            align-items: end;
                            height: 100%;
                            color: ${({ theme }) => theme.gray800};
                            text-align: center;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 26px;
                        }

                        .planValue {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .price {
                                color: ${({ theme }) => theme.gray800};
                                text-align: right;
                                font-size: 48px;
                                font-weight: 700;
                                line-height: 26px;
                                height: 100%;
                            }

                            .centsAndMonth {
                                display: flex;
                                flex-direction: column;
                                height: 100%;
                                justify-content: start;
                                align-items: start;

                                .cents {
                                    color: ${({ theme }) => theme.gray800};
                                    text-align: center;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: 26px;
                                }

                                .months {
                                    color: ${({ theme }) => theme.gray400};
                                    font-size: 10px;
                                    font-weight: 400;
                                    line-height: 26px;
                                }
                            }
                        }
                    }
                }

                .annualPromotionInformation {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    p {
                        color: #2e7d32;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }

                section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    padding-top: 20px;

                    .planInformation {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        gap: 4px;
                        height: 70px;
                        h1 {
                            color: ${({ theme }) => theme.gray800};
                            text-align: center;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 26px;
                        }
                        p {
                            color: ${({ theme }) => theme.gray400};
                            text-align: center;
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 26px;
                        }
                    }

                    .section5-card-button {
                        padding: 0 2.9375rem;
                        button {
                            border-radius: 4px;
                            font-size: 14px;
                            width: 200px;
                            text-transform: none;
                            color: var(--color-neutral-white-02, #fcfcfc);
                            text-align: center;
                        }
                    }
                }

                .section5-card-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    padding: 0 20px;
                    list-style: none;
                    gap: 14px;
                    padding-bottom: 40px;
                    padding-top: 40px;
                    border-top: 1px solid #e7e6e9;

                    svg {
                        width: 22px;
                        height: 22px;
                    }

                    li {
                        display: flex;
                        justify-content: start;
                        gap: 18px;
                        align-items: center;
                        flex-direction: row;
                        color: ${({ theme }) => theme.gray800};
                        font-size: 11px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                .section5-card-footer {
                    display: flex;
                    gap: 0.3125rem;
                    justify-content: center;
                    align-items: center;
                    padding: 1.375rem 0;
                    border-radius: 0 0 8px 8px;
                    background-color: ${({ theme }) => theme.purple300};
                    color: ${({ theme }) => theme.white};
                    font-size: 1.125rem;
                    line-height: 2.0625rem;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 1050px) {
        #section5-cards {
            display: flex;
            flex-wrap: wrap;
            width: 750px;

            .section5-card {
                margin: 12px;
            }
        }
    }

    @media (max-width: 550px) {
        #section5-cards {
            display: flex;
            gap: 1.4375rem;
            justify-content: center;
            align-items: center;
            width: 400px;

            .section5-card {
                width: 300px;
            }
        }
    }

    @media (max-width: 450px) {
        #section5-cards {
            display: flex;
            gap: 1.4375rem;
            justify-content: center;
            align-items: center;
            width: 400px;

            .section5-card {
                width: 300px;
            }
        }
    }
`;

export const ContainerToggle = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 24px;
    padding-left: 0.3rem;
    padding-right: 0.3;

    div > div.react-switch-handle {
        box-shadow: 0px 1.6px 2px rgba(0, 0, 0, 0.2);
    }
`;
