import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { CustomTooltip } from "..";

import { Icons, theme } from "../../../../../../../assets";
import {
    CheckBox,
    ModalReviewerBillerMass,
} from "../../../../../../../components";
import { manageUser } from "../../../../../../../services";
import { ReadUserProjectsResponse } from "../../../../../../../services/user/types";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import * as s from "./styles";

type ProjectsPermissionsMassControlProps = {
    data: ReadUserProjectsResponse | undefined;
};

type Error = {
    message: string;
};

export const ProjectsPermissionsMassControl = ({
    data,
}: ProjectsPermissionsMassControlProps) => {
    const [modalReviewerBillerMassIsOpen, setModalReviewerBillerMassIsOpen] =
        useState(false);

    const { state } = useLocation();
    const userId: string = state.id;

    if (!data) return null;

    const { hasReviewPermission, hasBillPermission, userHasAllPermissions } =
        data;

    const { isLoading: giveIsLoading, mutate: giveAllPermissions } =
        useMutation<unknown, Error>({
            mutationKey: ["giveAllPermissions"],
            mutationFn: () =>
                manageUser.giveUserAllProjectPermissions({
                    userId,
                }),
            onSuccess: () => {
                toast.success(
                    "Usuário adicionado a todos os projetos e com permissão de revisor e faturador em massa."
                );
                queryClient.refetchQueries(["userProjects"]);
            },
            onError: (error) => {
                toast.error(error?.message);
            },
            onSettled: () => {
                setModalReviewerBillerMassIsOpen(false);
            },
        });

    const { isLoading: removeIsLoading, mutate: removeAllPermissions } =
        useMutation({
            mutationKey: ["removeAllPermissions"],
            mutationFn: () =>
                manageUser.removeUserAllProjectPermissions({
                    userId,
                }),
            onSuccess: () => {
                toast.success(
                    "Usuário removido de todos os projetos e com permissão de revisor e faturador em massa."
                );
                queryClient.refetchQueries(["userProjects"]);
            },
            onSettled: () => {
                setModalReviewerBillerMassIsOpen(false);
            },
        });

    const disableCheckbox = !hasReviewPermission || !hasBillPermission;
    const isLoading = giveIsLoading || removeIsLoading;

    return (
        <>
            {modalReviewerBillerMassIsOpen && (
                <ModalReviewerBillerMass
                    isLoading={isLoading}
                    userName={state.name}
                    userHasAllPermissions={userHasAllPermissions}
                    onOpenChange={setModalReviewerBillerMassIsOpen}
                    onConfirm={() => {
                        if (userHasAllPermissions) {
                            removeAllPermissions();
                            return;
                        }
                        giveAllPermissions();
                    }}
                />
            )}
            <s.Container>
                <s.Wrapper>
                    <s.CheckboxWrapper
                        disabled={disableCheckbox}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!disableCheckbox) {
                                setModalReviewerBillerMassIsOpen(true);
                            }
                        }}
                    >
                        <CheckBox
                            key={userHasAllPermissions?.toString()}
                            id="all-permissions"
                            width={16}
                            height={16}
                            isChecked={userHasAllPermissions}
                            disabled={disableCheckbox}
                        />
                    </s.CheckboxWrapper>
                    <s.Text>Permissão de revisor e faturador em massa</s.Text>
                    <CustomTooltip text="Permissão disponível apenas para usuários com permissão de revisar e faturar.">
                        <Icons.Info
                            color={theme.purple400}
                            width="18px"
                            height="18px"
                        />
                    </CustomTooltip>
                </s.Wrapper>
                <s.Description>
                    O profissional será vinculado a todos os projetos da
                    organização e terá permissão de revisar e faturar todos os
                    profissionais da organização
                </s.Description>
            </s.Container>
        </>
    );
};
