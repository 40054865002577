import React from "react";

import { Icons, theme } from "../../../../../../../assets";
import {
    Input,
    LoadingGray,
    PopoverRadix,
} from "../../../../../../../components";
import { ReadUserProjectsResponse } from "../../../../../../../services/user/types";
import { CustomTooltip } from "../tooltip/tooltip";
import { MassControlRow, TableRow } from "./components";
import * as s from "./styles";

type ProjectsTableProps = {
    data: ReadUserProjectsResponse | undefined;
    isLoading: boolean;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export const ProjectsTable = ({
    isLoading,
    data,
    search,
    setSearch,
}: ProjectsTableProps) => {
    const havePermission = data?.hasBillPermission && data?.hasReviewPermission;
    return (
        <s.Container>
            {isLoading ? (
                <s.LoadingContainer>
                    <LoadingGray />
                </s.LoadingContainer>
            ) : (
                <>
                    <s.Table>
                        <s.TableHead>
                            <PopoverRadix
                                side="right"
                                align="end"
                                alignOffset={-10}
                                sideOffset={10}
                                trigger={
                                    <s.TableHeadCell
                                        style={{ cursor: "pointer" }}
                                    >
                                        <s.Info>
                                            Projeto <Icons.FilterList />
                                        </s.Info>
                                    </s.TableHeadCell>
                                }
                            >
                                <s.SearchWrapper>
                                    <Input
                                        mask=""
                                        placeholder="Pesquise por projeto..."
                                        icon={
                                            <Icons.SearchOutline
                                                color={theme.gray600}
                                            />
                                        }
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />
                                </s.SearchWrapper>
                            </PopoverRadix>

                            <s.TableHeadCell>
                                <CustomTooltip
                                    positionArrow={90}
                                    text={
                                        <>
                                            <s.TooltipTitle>
                                                Setores
                                            </s.TooltipTitle>
                                            O setor que o projeto está atrelado.
                                        </>
                                    }
                                >
                                    <s.Info>
                                        Setores <Icons.Info />
                                    </s.Info>
                                </CustomTooltip>
                            </s.TableHeadCell>
                            {havePermission && (
                                <>
                                    <s.TableHeadCell>
                                        <CustomTooltip
                                            positionArrow={90}
                                            text={
                                                <>
                                                    <s.TooltipTitle>
                                                        Revisor
                                                    </s.TooltipTitle>
                                                    Poderá revisar e aprovar os
                                                    relatórios de horas dos
                                                    profissionais.
                                                </>
                                            }
                                        >
                                            <s.Info>
                                                Revisor <Icons.Info />
                                            </s.Info>
                                        </CustomTooltip>
                                    </s.TableHeadCell>
                                    <s.TableHeadCell>
                                        <CustomTooltip
                                            positionArrow={90}
                                            text={
                                                <>
                                                    <s.TooltipTitle>
                                                        Faturador
                                                    </s.TooltipTitle>
                                                    Poderá definir o valor a ser
                                                    faturado pelos
                                                    profissionais.
                                                </>
                                            }
                                        >
                                            <s.Info>
                                                Faturador <Icons.Info />
                                            </s.Info>
                                        </CustomTooltip>
                                    </s.TableHeadCell>
                                    <s.TableHeadCell>Status</s.TableHeadCell>
                                </>
                            )}
                        </s.TableHead>
                        <s.TableBody>
                            {havePermission && <MassControlRow data={data} />}
                            {data?.projects.map((project) => (
                                <TableRow
                                    key={project.id_e}
                                    project={project}
                                    data={data}
                                />
                            ))}
                        </s.TableBody>
                    </s.Table>
                </>
            )}
        </s.Container>
    );
};
