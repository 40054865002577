import { TCreateProject } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    description,
    color,
    start_at,
    end_at,
    usersId,
    clientId,
    sectorsId,
    reviewersId,
    billersId,
    scopePercentage,
    cost,
    estimatedHours,
    status,
    technologies,
}: TCreateProject = {}) => {
    const params = {
        name,
        description,
        color,
        start_at,
        end_at,
        scopePercentage,
        cost,
        estimatedHours,
        status,
        technologies,
    };

    const tempHeaders = {
        auth: true,
        "x-users-id": usersId,
        "x-client-id": clientId,
        "x-sectors-id": sectorsId,
        "x-reviewers-id": reviewersId,
        "x-billers-id": billersId,
    };

    if (!usersId) delete tempHeaders["x-users-id"];
    if (!clientId) delete tempHeaders["x-users-id"];
    if (!sectorsId) delete tempHeaders["x-sectors-id"];
    if (!reviewersId) delete tempHeaders["x-reviewers-id"];
    if (!billersId) delete tempHeaders["x-billers-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/create`,
        method: "post",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro ao criar projeto.",
        404:
            body.message === "User does not exists!"
                ? "Necessário ao menos um profissional"
                : "Necessário ao menos um setor!",
        409:
            (body.message === "A project with this name already exists!" &&
                "Já existe um projeto com o mesmo nome!") ||
            (body.message === "project already exists!" &&
                "Esse projeto já existe") ||
            "Houve um problema ao criar o projeto!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
