import * as s from "./styles";

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    email: string;
};

export function EmailStep({ email }: Props) {
    return (
        <s.Container>
            <s.Title>Confirmação de e-mail</s.Title>
            <s.Text>
                Um e-mail de confirmação foi enviado para{" "}
                <strong>{email}</strong>
            </s.Text>
            <s.Text>
                Verifique sua caixa de entrada e siga as instruções para
                finalizar o cadastro.
            </s.Text>
            <s.Text>
                <strong>Não esqueça de verificar a caixa de spam.</strong>
            </s.Text>
        </s.Container>
    );
}
