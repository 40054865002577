import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    color = theme.gray500,
    className,
    onClick,
    width = "32px",
    height = "32px",
}: TBaseSVG) => {
    return (
        <svg
            version="1.1"
            id="Icons"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 32 32"
            xmlSpace="preserve"
            className={className}
            onClick={onClick}
            width={width}
            height={height}
        >
            <path
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M14,17V3c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v14"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                x1="14"
                y1="14"
                x2="18"
                y2="14"
            />
            <path
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M26,31H6l2.7-12.4c0.2-0.9,1-1.6,2-1.6h10.8c0.9,0,1.8,0.7,2,1.6L26,31z"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                x1="9"
                y1="21"
                x2="23"
                y2="21"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                x1="11"
                y1="27"
                x2="11"
                y2="30"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                x1="21"
                y1="29"
                x2="21"
                y2="30"
            />
        </svg>
    );
};
