import {
    Dispatch,
    ReactElement,
    RefCallback,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import toast from "react-hot-toast";
import Select from "react-select";

import { Icons, theme } from "../../../../assets";
import { TOption } from "../../../../models";
import * as s from "./styled-select-emails";

type TEmails = {
    id?: string;
    key?: string;
    error?: boolean;
    name?: string;
    inputRef?: RefCallback<Record<string, string>>;
    placeholder?: string;
    positionY?: string;
    disabled?: boolean;
    emails: string[];
    emailsToSend: string[];
    setEmails: Dispatch<React.SetStateAction<string[]>>;
    setEmailsToSend: Dispatch<SetStateAction<string[]>>;
};

const SelectEmails = ({
    id,
    key,
    error,
    name,
    placeholder,
    positionY,
    disabled,
    emails,
    emailsToSend,
    setEmails,
    setEmailsToSend,
}: TEmails) => {
    const [inputValues, setInputValues] = useState<TOption[]>([]);

    const [inputValue, setInputValue] = useState("");

    const DropdownIndicator = () => {
        return (
            <s.PositionIcons positionY={positionY} disabled={disabled}>
                <Icons.MailOutlined
                    color={
                        emailsToSend.length > 0 ? theme.white3 : theme.gray600
                    }
                />
            </s.PositionIcons>
        );
    };

    const addValue = (newValue: TOption) => {
        setInputValues([...inputValues, newValue]);
    };

    function getOptions(): TOption[] {
        const options: TOption[] = [];
        emails.forEach((email) => {
            options.push({
                value: email,
                label: email,
            });
        });
        return options;
    }

    function validarEmail(email: string) {
        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return regexEmail.test(email);
    }

    useEffect(() => {
        setEmailsToSend(inputValues.map((inputValue) => inputValue.value));
    }, [inputValues]);

    return (
        <s.FlexColumn>
            <s.Flex error={error}>
                <Select
                    id={id}
                    key={key}
                    placeholder={placeholder || "Selecione o valor"}
                    name={name}
                    onChange={(options) => {
                        const optionsArray = options.map((option) => option);
                        setInputValues(optionsArray);
                    }}
                    classNamePrefix={"react-select"}
                    options={getOptions()}
                    value={inputValues}
                    isDisabled={disabled}
                    components={{ DropdownIndicator }}
                    noOptionsMessage={() => "Sem opções"}
                    closeMenuOnSelect={false}
                    isMulti
                    inputValue={inputValue}
                    onInputChange={(value) => {
                        setInputValue(value);
                        const valueWithoutWhiteSpaces = value.replace(
                            /\s/g,
                            ""
                        );
                        if (valueWithoutWhiteSpaces === "") return;

                        const lastChar = value[value.length - 1];
                        if (lastChar === " ") {
                            const email = valueWithoutWhiteSpaces;
                            if (!validarEmail(email)) {
                                toast.error("Email inválido");
                                setInputValue(email);
                                return;
                            }
                            setEmails([...emails, email]);
                            addValue({ value: email, label: email });
                            setInputValue("");
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                            const valueWithoutWhiteSpaces = inputValue.replace(
                                /\s/g,
                                ""
                            );
                            if (valueWithoutWhiteSpaces === "") return;

                            const email = valueWithoutWhiteSpaces;
                            if (!validarEmail(email)) {
                                toast.error("Email inválido");
                                setInputValue(email);
                                return;
                            }
                            setEmails([...emails, email]);
                            addValue({ value: email, label: email });
                            setInputValue("");
                        }
                    }}
                />
            </s.Flex>

            <s.Error>{error ? "Insira um e-mail válido" : null}</s.Error>
        </s.FlexColumn>
    );
};

export default SelectEmails;
