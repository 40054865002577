import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, IlustracaoTelaVazia, theme } from "../../../../assets";
import {
    BlockTooltip,
    LoadingGray,
    Pagination,
    PopoverRadix,
    ProjectStatus,
} from "../../../../components";
import Tag from "../../../../components/atoms/tag/tag";
import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import { useFetch } from "../../../../hooks";
import { TProject } from "../../../../models";
import { projects } from "../../../../services";
import { ReadProjectsParams } from "../../../../services/projects/types/read-projects";
import { minutesToStringDate, formatISOToDate } from "../../../../utils";
import {
    projectName,
    renderListWithComma,
    verifyProjectStatus,
} from "../../utils/projects-utils";
import { TFilterType } from "../organization-projects";
import * as s from "./styled-tab-projects";

type TabResumeProps = {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setFilterType: React.Dispatch<React.SetStateAction<TFilterType>>;
    getProjects: {
        response: {
            projects: TProject[];
            total_pages: number;
        };
        error:
            | {
                  message?: string | undefined;
                  errorToken: boolean;
                  body?:
                      | {
                            status: string;
                            message: string;
                        }
                      | undefined;
              }
            | undefined;
        loading: boolean;
        onRefresh: (params?: ReadProjectsParams | undefined) => void;
        allSubsequentResponses: { projects: TProject[] };
    };
    projectsData?: TProject[];
    loading: boolean;
    searchValue?: string;
    filterType?: string;
    organizationProjects?: boolean;
    haveProjectsPermissions?: boolean;
    isFromMyProjects?: boolean;
    showArchived?: boolean;
};

type TFindProjects = {
    selected: number;
    searchTerm?: string;
};

export const TabProjects = ({
    loading,
    setLoading,
    projectsData,
    searchValue,
    setFilterType,
    filterType = undefined,
    getProjects,
    organizationProjects,
    haveProjectsPermissions,
    isFromMyProjects,
    showArchived,
}: TabResumeProps) => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [projectLoading, setProjectLoading] = useState<string | undefined>(
        undefined
    );

    const readSingleProject = useFetch({
        fn: projects.getProject,
        start: false,
    });

    const findProjects = ({ selected, searchTerm }: TFindProjects) => {
        getProjects.onRefresh({
            page: selected,
            length: 10,
            searchTerm,
            hourProgressFilter: filterType,
            showArchived,
        });
    };

    useEffect(() => {
        if (searchValue !== undefined) {
            const delayDebounceFn = setTimeout(() => {
                if (searchValue) {
                    findProjects({ selected: page, searchTerm: searchValue });
                    return;
                }
                findProjects({ selected: page, searchTerm: searchValue });
            }, 500);
            return () => clearTimeout(delayDebounceFn);
        }
        return () => ({});
    }, [searchValue]);

    useEffect(() => {
        if (filterType !== undefined) findProjects({ selected: page });
    }, [filterType]);

    const goToRoute = (project: TProject) => {
        navigate(`/projetos/${projectName(project)}`, {
            state: {
                project,
                isFromMyProjects,
            },
        });
    };

    const loadSingleProject = (project: TProject) => {
        setLoading(true);
        readSingleProject.onRefresh({
            all: haveProjectsPermissions,
            users: true,
            hours: true,
            finished: false,
            sectors: true,
            reviewers: true,
            length: 1,
            projectId: project.id_e,
        });
    };

    useEffect(() => {
        if (readSingleProject.response) {
            const project = readSingleProject.response.projects?.[0];
            setLoading(false);
            goToRoute(project);
        }
    }, [readSingleProject.response]);

    const renderProjects = () => {
        if (projectsData && projectsData.length > 0) {
            setLoading(false);
            return projectsData.map((project) => (
                <div
                    key={project.id_e}
                    className="content-row"
                    onClick={() => {
                        loadSingleProject(project);
                        setProjectLoading(project.id_e);
                    }}
                >
                    <s.TagContainer archived={project.archived}>
                        {project.archived && (
                            <Tooltip
                                content={
                                    <s.TooltipContent>
                                        <p>Arquivado</p>
                                    </s.TooltipContent>
                                }
                            >
                                <div>
                                    <Icons.IconArchive
                                        color={theme.gray500}
                                        width="24px"
                                        height="24px"
                                    />
                                </div>
                            </Tooltip>
                        )}
                        <Tag color={project?.color}>{project?.name}</Tag>
                    </s.TagContainer>
                    {(project?.start_at || project?.end_at) && (
                        <div>
                            {project?.start_at
                                ? formatISOToDate(project?.start_at)
                                : "-"}{" "}
                            -{" "}
                            {project?.end_at
                                ? formatISOToDate(project?.end_at)
                                : "-"}
                        </div>
                    )}
                    {!project?.start_at && !project?.end_at && <div>-</div>}
                    <div>{verifyProjectStatus(project?.status || "")}</div>
                    <div>{project?.expectedHours || "-"}</div>
                    <div>{minutesToStringDate(project?.total_time) || "-"}</div>
                    <div>
                        {
                            <ProjectStatus
                                actualPercentage={project?.percentageHoursDone}
                                projectStatus={project?.status}
                                idealPercentage={
                                    project?.idealPercentageOfProgress
                                }
                            />
                        }
                    </div>
                    <div className="projects-icons">
                        <BlockTooltip
                            offset={12}
                            align="end"
                            side="top"
                            trigger={<Icons.UserManage />}
                        >
                            <ul className="head-list">
                                <li>
                                    <span>Faturador:</span>
                                    {renderListWithComma(
                                        project?.ProjectBiller
                                    )}
                                </li>
                                <li>
                                    <span>Revisor:</span>
                                    {renderListWithComma(
                                        project?.ProjectReviewers
                                    )}
                                </li>
                            </ul>
                        </BlockTooltip>
                        {haveProjectsPermissions && (
                            <Icons.EditOutline
                                className="edit"
                                color={theme.gray800}
                                onClick={() => {
                                    loadSingleProject(project);
                                }}
                            />
                        )}
                        {projectLoading === project.id_e && (
                            <Icons.Loading color={theme.purple500} />
                        )}
                    </div>
                </div>
            ));
        }

        if (!loading && projectsData?.length === 0) {
            setLoading(false);
            return (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Nenhum projeto encontrado</h2>
                </s.NoContent>
            );
        }

        return <LoadingGray />;
    };

    return (
        <s.Content organizationProjects={organizationProjects}>
            <div className="projects-title">
                <div>Projeto</div>
                <div>Duração projeto</div>
                <div>Status</div>
                <div>
                    Horas <br />
                    previstas
                </div>
                <div>
                    Horas <br />
                    realizadas
                </div>
                <Tooltip
                    arrow
                    positionArrow={33}
                    positiony={4}
                    content={
                        <s.TooltipContent>
                            <h3>
                                Andamento de horas <Icons.Info />
                            </h3>
                            <p>
                                O andamento de horas ideal é baseado na
                                porcentagem do tempo do projeto que transcorreu
                                até agora.
                            </p>
                        </s.TooltipContent>
                    }
                >
                    <div className="filter-time">
                        Andamento horas{" "}
                        <PopoverRadix
                            side="bottom"
                            sideOffset={5}
                            alignOffset={5}
                            align="end"
                            trigger={
                                <span className={"options"}>
                                    <Icons.Filler />
                                </span>
                            }
                        >
                            <s.RadioGroupRoot
                                className="RadioGroupRoot"
                                defaultValue={filterType || "all"}
                                aria-label="View density"
                            >
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="all"
                                        id="todos"
                                        onClick={() => setFilterType("all")}
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label className="Label" htmlFor="todos">
                                        Todos
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="late"
                                        id="atrasados"
                                        onClick={() => setFilterType("late")}
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label
                                        className="Label"
                                        htmlFor="atrasados"
                                    >
                                        Atrasados
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="canceled"
                                        id="cancelados"
                                        onClick={() =>
                                            setFilterType("canceled")
                                        }
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label
                                        className="Label"
                                        htmlFor="cancelados"
                                    >
                                        Cancelados
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="paused"
                                        id="pausados"
                                        onClick={() => setFilterType("paused")}
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label className="Label" htmlFor="pausados">
                                        Pausados
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="onTime"
                                        id="em-dia"
                                        onClick={() => setFilterType("onTime")}
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label className="Label" htmlFor="em-dia">
                                        Em dia
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="finished"
                                        id="finalizados"
                                        onClick={() =>
                                            setFilterType("finished")
                                        }
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label
                                        className="Label"
                                        htmlFor="finalizados"
                                    >
                                        Finalizados
                                    </label>
                                </s.RadioWrapper>
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        className="RadioGroupItem"
                                        value="noInformation"
                                        id="sem-informacao"
                                        onClick={() =>
                                            setFilterType("noInformation")
                                        }
                                    >
                                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                                    </s.RadioGroupItem>
                                    <label
                                        className="Label"
                                        htmlFor="sem-informacao"
                                    >
                                        Sem informação
                                    </label>
                                </s.RadioWrapper>
                            </s.RadioGroupRoot>
                        </PopoverRadix>
                    </div>
                </Tooltip>
                <div></div>
            </div>
            <div className="projects-content">{renderProjects()}</div>
            {!loading && projectsData?.length !== 0 && (
                <Pagination
                    id="pagination"
                    disabled={loading}
                    page={page - 1}
                    onClick={({ selected }) => {
                        findProjects({
                            selected: selected + 1,
                            searchTerm: searchValue,
                        });
                        setPage(selected + 1);
                    }}
                    totalPages={getProjects?.response?.total_pages}
                />
            )}
        </s.Content>
    );
};
