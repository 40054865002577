import {
    DialogContent,
    DialogOverlay,
    DialogClose,
} from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";

const overlayShow = keyframes`
    from{ opacity: 0 };
    to { opacity: 1 };
`;

export const Overlay = styled(DialogOverlay)`
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    display: flex;
    align-items: baseline;
    justify-content: center;
    overflow-y: auto;
    inset: 0;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
    padding-top: 20px;
`;

export const Content = styled(DialogContent)`
    z-index: 999999999999999999999;
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
    :focus {
        outline: "none";
    }
`;

export const RadixClose = styled(DialogClose)`
    position: absolute;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    margin-top: 10px;
`;

export const Flex = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;
