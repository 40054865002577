import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { FotoUser, Icons, theme } from "../../../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../../../components/atoms/menu/menu";
import Tooltip from "../../../../../../../../../components/atoms/tooltip/tooltip";
import { activities } from "../../../../../../../../../services";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { DeleteConfirmationModal } from "../../../../modals/delete-confirmation-general/delete-confirmation-general";
import { ActivityDTO, SubItemDTO } from "../../../../types/activityDTO";
import { EditSubItem } from "./components/edit-sub-item/edit-sub-item";
import * as s from "./styles";

type TSubItem = {
    item: SubItemDTO;
    activity: ActivityDTO;
};

type TUpdateSubItem = {
    title?: string;
    finished?: boolean;
    users?: string[];
};

export const SubItem = ({ activity, item }: TSubItem) => {
    const [subItem, setSubItem] = useState(item);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [editSubItem, setEditSubItem] = useState(false);

    useEffect(() => {
        setSubItem(item);
    }, [item]);

    const { mutate: updateSubItem } = useMutation<
        unknown,
        unknown,
        TUpdateSubItem
    >({
        mutationFn: (params) =>
            activities.updateSubItem({
                subtaskId: item.id_e,
                ...params,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const { mutate: deleteChecklistItem } = useMutation({
        mutationFn: () =>
            activities.deleteChecklistSubItem({
                subItemId: item.id_e,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const { mutate: duplicateChecklistItem } = useMutation({
        mutationFn: () =>
            activities.duplicateSubItem({
                subItemId: item.id_e,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const toggleChecklist = () => {
        updateSubItem({
            finished: !subItem.finished_at,
        });
        setSubItem({
            ...subItem,
            finished_at: !subItem.finished_at
                ? new Date().toISOString()
                : undefined,
        });
    };

    const subItemOptions: TMenuAtom["options"] = [
        {
            label: "Editar",
            icon: <Icons.EditOutline color={theme.gray800} />,
            action: () => {
                setEditSubItem(true);
            },
            color: theme.gray800,
        },
        {
            label: "Duplicar",
            icon: <Icons.Duplicate color={theme.gray600} />,
            action: () => {
                duplicateChecklistItem();
            },
            color: theme.gray600,
        },
        {
            label: "Deletar",
            icon: <Icons.Trash color={theme.redError} />,
            action: () => {
                setDeleteConfirmation(true);
            },
            color: theme.redError,
        },
    ];

    return (
        <>
            {deleteConfirmation && (
                <DeleteConfirmationModal
                    setOpen={setDeleteConfirmation}
                    message="o item do checklist"
                    onConfirm={deleteChecklistItem}
                />
            )}
            {editSubItem ? (
                <EditSubItem
                    activity={activity}
                    subItem={subItem}
                    setEditSubItem={setEditSubItem}
                />
            ) : (
                <s.Container>
                    <s.LeftSideContent>
                        <Icons.Drag className="show-on-hover" />
                        <s.Button onClick={toggleChecklist}>
                            {subItem.finished_at ? (
                                <Icons.IconCheckCircle2
                                    width="24px"
                                    height="24px"
                                />
                            ) : (
                                <Icons.CheckCircle
                                    color={theme.gray400}
                                    width="24px"
                                    height="24px"
                                />
                            )}
                        </s.Button>
                        <s.Title title={subItem.title}>{subItem.title}</s.Title>
                    </s.LeftSideContent>
                    <s.RightSideContent>
                        <s.Users>
                            {item?.users?.length >= 1 && (
                                <Tooltip
                                    positiony={5}
                                    content={
                                        <s.TooltipContainer>
                                            {item.users?.[0]?.User?.name}
                                        </s.TooltipContainer>
                                    }
                                >
                                    <s.UserAvatar
                                        src={
                                            item.users?.[0]?.User?.avatar ??
                                            FotoUser
                                        }
                                    />
                                </Tooltip>
                            )}
                            {item?.users?.length > 1 && (
                                <s.UsersLength>
                                    +{item.users.length - 1}
                                </s.UsersLength>
                            )}
                        </s.Users>
                        <MenuAtom options={subItemOptions}>
                            <s.Button className="show-on-hover">
                                <Icons.More color={theme.gray600} />
                            </s.Button>
                        </MenuAtom>
                    </s.RightSideContent>
                </s.Container>
            )}
        </>
    );
};
