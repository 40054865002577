/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { RefCallback, useState } from "react";
import Select, { GroupBase, MenuListProps } from "react-select";

import { LoadingGray, ModalClient } from "../../..";
import { Icons, theme } from "../../../../assets";
import { TOption } from "../../../../models";
import { TUseFetch } from "../../../../types/TUseFetch";
import * as s from "./styled-select-client";

type TSelectClient = {
    id?: string;
    key?: string;
    error?: string;
    title?: string;
    options?: TOption[];
    name?: string;
    placeholder?: string;
    value?: TOption;
    positionY?: string;
    disabled?: boolean;
    buttonName?: string;
    noOptionsMessage?: string;
    onChange?: (arg: any) => void;
    inputRef?: RefCallback<Record<string, string>>;
    getClients?: TUseFetch;
    clearsClientSelect?: () => void;
};

const SelectClient = ({
    id,
    key,
    error,
    title,
    options,
    onChange,
    name,
    placeholder,
    value,
    positionY,
    disabled,
    buttonName,
    noOptionsMessage,
    getClients,
    clearsClientSelect,
}: TSelectClient) => {
    const [option, setOption] = useState<TOption>();
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [editRegister, setEditRegister] = useState(false);

    const handleEdit = (option: TOption) => {
        setOption(option);
        setEditRegister(true);
        setShowDialog(true);
    };

    const handleCreate = () => {
        setEditRegister(false);
        setShowDialog(true);
    };

    const MenuList = (
        props: MenuListProps<TOption, false, GroupBase<TOption>>
    ) => {
        return (
            <s.Menu {...props}>
                {props.selectProps.isLoading ? (
                    <div className="fetching">
                        <LoadingGray />
                    </div>
                ) : Array.isArray(props.children) && props.children.length ? (
                    props.children?.map((child) => (
                        <s.Option isSelected={child.props.isSelected}>
                            {child}
                            <button
                                onClick={() => handleEdit(child.props.data)}
                            >
                                {child.props.isSelected ? (
                                    <Icons.PencilOutline color={theme.white} />
                                ) : (
                                    <Icons.PencilOutline />
                                )}
                            </button>
                        </s.Option>
                    ))
                ) : (
                    <s.NoOptions>Nenhum cliente cadastrado</s.NoOptions>
                )}

                <div className="bottom-bar">
                    <s.Button className={"change-data"} onClick={handleCreate}>
                        <Icons.Plus />{" "}
                        {buttonName?.toUpperCase() || `ADICIONAR`}
                    </s.Button>
                </div>
            </s.Menu>
        );
    };

    const NoOptions = () => {
        let message = noOptionsMessage;
        if (!noOptionsMessage) message = "Sem Informação";
        return <s.NoOptions>{message}</s.NoOptions>;
    };

    return (
        <>
            {showDialog && (
                <ModalClient
                    setOpen={setShowDialog}
                    editRegister={editRegister}
                    option={option}
                    getClients={getClients}
                    clearsClientSelect={clearsClientSelect}
                />
            )}

            <s.FlexColumn>
                <s.Label>{title}</s.Label>
                <s.Flex error={!!error}>
                    <Select
                        id={id}
                        key={key}
                        placeholder={placeholder || "Selecione o valor"}
                        name={name}
                        onChange={onChange}
                        classNamePrefix={"react-select"}
                        options={options}
                        value={value}
                        isDisabled={disabled}
                        components={{ MenuList }}
                        noOptionsMessage={({ inputValue }) =>
                            inputValue && <NoOptions />
                        }
                        required
                        styles={{
                            option: (base, { isSelected }) => ({
                                ...base,
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                ":active": {
                                    backgroundColor: "transparent",
                                },
                                color: isSelected ? theme.white : theme.gray900,
                                padding: "0.75rem 1.4375rem !important",
                            }),
                        }}
                    />
                    <s.PositionIcons positionY={positionY}>
                        <Icons.Chevron />
                    </s.PositionIcons>
                </s.Flex>
                {error && <s.Error>{error}</s.Error>}
            </s.FlexColumn>
        </>
    );
};

export default SelectClient;
