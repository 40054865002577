import styled from "styled-components";

import { theme } from "../../../assets";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 40.9375rem;
    gap: 1rem;

    #form-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 50px;
        margin-bottom: 4.5rem;

        p#required {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.purple400};
        }

        button {
            padding: 0.5rem 1rem;
        }
    }

    .input-group {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .switch {
        display: flex;
        font-size: 0.75rem;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .button-group {
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-top: 3.5rem;
        gap: 1rem;
        width: 70%;
    }
`;

export const ProjectList = styled.div`
    width: 100%;
    border-radius: 4px;
    border: 1.2px solid ${theme.gray200};
    height: 30vh;
    overflow-y: auto;

    #projetos-header {
        padding: 10px 22px;
        font-weight: 600;
        color: ${theme.gray600};
        border-bottom: 1.2px solid ${theme.gray200};
    }

    #projetos-content {
        #projetos-row {
            cursor: default;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 22px;
            gap: 16px;
            border-bottom: 1.2px solid ${theme.gray200};
        }

        #projetos-row:last-child {
            border-bottom: none;
        }
    }

    #projetos-no-content {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 22px;
    }
`;
