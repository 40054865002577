import React, { ReactNode } from "react";

import * as s from "./styled-table";

type TProjects = {
    header: { column: ReactNode }[];
    body: { row: { column: ReactNode }[] }[];
    templateColumns: string;
};

export default ({ header, templateColumns, body }: TProjects) => (
    <s.Table>
        <s.TableHeader
            className="table-header"
            templateColumns={templateColumns || "1fr"}
        >
            {header.map((row, index) => (
                <span key={index} className="table-header-item">
                    {row.column}
                </span>
            ))}
        </s.TableHeader>
        <div className="table-body">
            {body.map((row, index) => (
                <s.TableRow
                    templateColumns={templateColumns || "1fr"}
                    key={index}
                    className="table-row"
                >
                    {row.row.map((column, index) => (
                        <span key={index}>{column.column}</span>
                    ))}
                </s.TableRow>
            ))}
        </div>
    </s.Table>
);
