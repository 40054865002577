import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    top: 0;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 2fr;
    gap: 8rem;
    min-height: 100vh;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.white};

    @media (max-width: 700px) {
        display: flex;
        place-items: center;
        justify-content: center;
    }
`;

export const Section = styled.section`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    width: 100%;
    padding: 7.5rem 0;
    height: 100%;
    justify-self: flex-end;
    justify-content: center;

    h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 2.125rem;
        line-height: 2.9375rem;
        letter-spacing: 0.0025em;
        color: ${({ theme }) => theme.purple500};
        margin-bottom: 1rem;
    }

    span#line {
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.gray50};
        margin: 40px 0;
    }

    span#question {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.purple300};
        margin-bottom: 1rem;
    }

    p {
        color: ${({ theme }) => theme.gray600};
    }

    div#password-expired {
        display: flex;
        flex-direction: column;
        max-width: 400px;

        button.redirect {
            margin-top: 72px;
        }
    }

    div#buttons {
        display: flex;
        flex-direction: row;
        margin-top: 3.5rem;
        gap: 1rem;

        button {
            width: 240px;
        }
    }

    @media (max-width: 700px) {
        order: 1;
    }
`;

export const Img = styled.img`
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    height: 100%;
    object-fit: cover;
    object-position: right;

    @media (max-width: 700px) {
        display: none;
    }
`;
