import { CSSProperties } from "react";

import { Icons } from "../../../assets";
import * as s from "./styled-warning";

type TTag = {
    success?: boolean;
    projectStatus?: string;
    projectIsLate?: boolean;
    title?: string;
    message?: string;
    style?: CSSProperties;
};

const Warning = ({
    projectStatus,
    projectIsLate,
    title,
    message,
    style,
}: TTag) => {
    const renderWarningIcon = (
        projectIsLate?: boolean,
        projectStatus?: string
    ) => {
        if (
            ["paused", "canceled", "no_information", "finished"].includes(
                projectStatus || "no_information"
            )
        ) {
            return <Icons.WarningInfo />;
        }
        if (projectIsLate) {
            return <Icons.Warning />;
        }
        return <Icons.WarningSuccess />;
    };

    return (
        <s.Container
            className="project-tag"
            style={style}
            projectIsLate={projectIsLate}
            projectStatus={projectStatus}
        >
            <span>
                {renderWarningIcon(projectIsLate, projectStatus)}
                {title}
            </span>
            <p>{message}</p>
        </s.Container>
    );
};

export default Warning;
