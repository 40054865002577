/* eslint-disable no-nested-ternary */
import { pdf } from "@react-pdf/renderer";
import { format, getWeek, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import CsvDownloader from "react-csv-downloader";
import { DateRange } from "react-day-picker";
import { toast } from "react-hot-toast";

import { Icons, theme } from "../../assets";
import IlustracaoTelaVazia from "../../assets/images/ilustracao-tela-vazia";
import {
    BarChartDashboard,
    DoughnutChartDashboard,
    ModalDocument,
    PopoverRadix,
    MyReportsOptions,
    Tooltip,
    CollaboratorResume,
    ReportStatus,
    LoadingGray,
    SecondaryButton,
    ModalSendReport,
    PopoverProjectList,
} from "../../components";
import { ModalSendReportWrongConfirm } from "../../components/molecules/modals/modal-send-report-wrong/modal-send-report-wrong";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import {
    TColumnData,
    TDoughnutData,
    TReportProject,
    TReport,
    TReports,
    TTasks,
} from "../../models";
import { timeReports, track } from "../../services";
import { axiosHttpClient } from "../../services/axios";
import {
    dayFromWeek,
    formatFullDateTimeWithoutUTC,
    minutesToStringDate,
    monthFromYear,
} from "../../utils";
import ModalSendReportError from "./components/modals/modal-send-report-error";
import * as s from "./styled-report-generated";

const { REACT_APP_BASE_URL } = process.env;

export type TActivity = {
    description: string;
    total_time: number;
    start_at: string;
    end_at: string;
    Project: {
        id_e: string;
        color: string;
        name: string;
        Client: { name: string };
    };
};

export type TCsvData = {
    start_at: string;
    end_at: string;
    description: string;
    project: string;
    duration: string;
}[];

export const ReportGenerated = () => {
    const { user } = useUser();

    const [loading, setLoading] = useState(true);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenSend, setIsOpenSend] = useState(false);
    const [isOpenResend, setIsOpenResend] = useState(false);
    const [isOpenSendWrongConfirm, setIsOpenSendWrongConfirm] = useState(false);
    const [isOpenModalSendError, setIsOpenModalSendError] = useState(false);
    const [recipientsEmailHistory, setRecipientsEmailHistory] = useState<
        string[]
    >([]);
    const [projects, setProjects] = useState<TReportProject>([]);
    const [reports, setReports] = useState<TReports>();
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selected, setSelected] = useState<TReport>();
    const [selectedSend, setSelectedSend] = useState<TReport>();

    const [barChartData, setBarChartData] = useState<TColumnData>();
    const [doughnutChartData, setDoughnutChartData] = useState<TDoughnutData>();

    const getEntries = useFetch({
        fn: track.getEntries,
        start: false,
    });

    const getReports = useFetch({
        fn: timeReports.getReports,
        params: {
            projects: true,
            users: true,
            length: 10,
            page: 1,
            errorStatus: "unfixed",
            reportBilling: true,
        },
    });

    const lockReport = useFetch({
        fn: timeReports.patchLockReport,
        successMessage: "Relatório enviado com sucesso",
        start: false,
    });

    const getReportsByPage = (page?: number) => {
        if (page !== 1 && page) {
            setPage(page);
            getReports.onRefresh({
                projects: true,
                length: 10,
                page: page - 1,
                errorStatus: "unfixed",
                reportBilling: true,
            });
        } else
            getReports.onRefresh({
                projects: true,
                users: true,
                length: 10,
                page: 1,
                errorStatus: "unfixed",
                reportBilling: true,
            });
    };

    const getPeriod = (period: DateRange | undefined, csv?: boolean) => {
        if (csv) {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                page: 0,
            });
        } else {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                responseFormat: "summaryReport",
                page: 0,
            });
        }
    };

    const formatChartsData = (entries: {
        response1: { date: string; total_time: number }[];
        response2: TReportProject;
    }) => {
        const barDates: string[] = [];
        const barTimes: number[] = [];
        const barPeriods: string[] = [];
        const barAbreviatedDates: string[] = [];
        const doughnutLabels: string[] = [];
        const doughnutColors: string[] = [];
        const doughnutTimes: number[] = [];
        let doughnutMaxTime = 0;

        entries?.response1?.sort((a: { date: string }, b: { date: string }) => {
            return a.date.localeCompare(b.date);
        });

        entries?.response1?.forEach(
            (entry: { date: string; total_time: number }) => {
                if (entry.date.length > 10) {
                    const tempDate = entry.date.split(" - ");
                    barAbreviatedDates.push(
                        `${format(parseISO(tempDate[0]), "dd/MM")} - ${format(
                            parseISO(tempDate[1]),
                            "dd/MM"
                        )}`
                    );
                    const tempPeriod = getWeek(
                        parseISO(tempDate[0])
                    ).toString();
                    barPeriods.push(`Sem. ${tempPeriod}`);
                } else if (entry.date.length < 8) {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "yyyy")
                    );
                    barPeriods.push(monthFromYear(parseISO(entry.date), true));
                } else {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "dd/MM")
                    );
                    barPeriods.push(dayFromWeek(parseISO(entry.date), true));
                }
                barDates.push(entry.date);
                barTimes.push(entry.total_time);
            }
        );

        entries?.response2?.forEach(
            (activity: {
                project_name: string;
                project_color: string;
                project_percent: string;
                project_time: number;
                project_entries: TTasks;
            }) => {
                doughnutLabels.push(activity.project_name);
                doughnutColors.push(activity.project_color);
                doughnutTimes.push(activity.project_time);
                doughnutMaxTime += activity.project_time;
            }
        );

        if (Object.keys(entries?.response2).length === 0) {
            setProjects([]);
        } else setProjects(entries?.response2);

        setBarChartData({
            dates: barDates,
            times: barTimes,
            periods: barPeriods,
            abreviatedDates: barAbreviatedDates,
        });
        setDoughnutChartData({
            labels: doughnutLabels,
            colors: doughnutColors,
            times: doughnutTimes,
            maxTime: doughnutMaxTime,
        });
    };

    const showMore = () => {
        getReports.onRefresh({
            projects: true,
            length: 999,
            page: 0,
            errorStatus: "unfixed",
        });
    };

    const sendReport = () => {
        if (selectedSend) {
            if (selectedSend.total_time === 0) {
                setIsOpenModalSendError(true);
                return;
            }
            lockReport.onRefresh({ reportId: selectedSend.id_e });
        }
    };
    const hasCsvData = async () => {
        const request = await axiosHttpClient({
            url: `${REACT_APP_BASE_URL}/timeEntry/read`,
            method: "get",
            headers: { auth: true },
        })
            .then((response) => {
                const tempCsvData: TCsvData = [];
                response.body.entries.forEach((entry: TActivity) => {
                    tempCsvData.push({
                        start_at: formatFullDateTimeWithoutUTC(entry.start_at),
                        end_at: formatFullDateTimeWithoutUTC(entry.end_at),
                        description: entry.description,
                        project: entry.Project.name,
                        duration: minutesToStringDate(entry.total_time),
                    });
                });
                return tempCsvData;
            })
            .catch(() =>
                Promise.reject(toast.error("Erro ao buscar os dados do CSV"))
            );

        return Promise.resolve(request);
    };

    const hasDocuments = (report: TReport) => {
        return (
            report?.invoices?.length ||
            report?.billets?.length ||
            report?.recipients?.length
        );
    };

    async function generatesDocument(
        barChartImg: string,
        doughnutChartImg: string
    ) {
        try {
            if (selected) {
                const blob = await pdf(
                    <CollaboratorResume
                        pdfData={{
                            barChartImg,
                            doughnutChartImg,
                            projects,
                        }}
                        name={user.name}
                        period={`${format(
                            parseISO(selected.start_at.slice(0, -1)),
                            "dd/MM/yyyy"
                        )} - ${format(
                            parseISO(selected.end_at.slice(0, -1)),
                            "dd/MM/yyyy"
                        )}`}
                        totalTime={minutesToStringDate(selected.total_time)}
                    />
                ).toBlob();

                const fileURL = window.URL.createObjectURL(blob);
                const tempLink = document.createElement("a");
                tempLink.href = fileURL;
                tempLink.setAttribute("download", "Relatorio.pdf");
                tempLink.click();
            }
        } catch (error) {
            toast.error("Erro ao fazer download do relatório!");
        } finally {
            setBarChartData(undefined);
            setDoughnutChartData(undefined);
        }
    }

    const getRecipientsEmailHistory = (reports: TReports) => {
        const recipientsEmailHistory: string[] = [];

        reports?.forEach((report) => {
            report?.recipients?.forEach((recipient) => {
                recipientsEmailHistory.push(recipient);
            });
        });

        const recipientsEmailHistoryWithoutDuplicate =
            recipientsEmailHistory.filter((valor, indice, self) => {
                return self.indexOf(valor) === indice;
            });

        setRecipientsEmailHistory(recipientsEmailHistoryWithoutDuplicate);
    };

    useEffect(() => {
        const barChartDashboard = document.getElementById(
            "bar-chart-dashboard"
        ) as HTMLCanvasElement;
        const doughnutChartDashboard = document.getElementById(
            "doughnut-chart-dashboard"
        ) as HTMLCanvasElement;

        const tempBarChartImg = barChartDashboard?.toDataURL("image/png");
        const tempDoughnutChartImg =
            doughnutChartDashboard?.toDataURL("image/png");
        if (tempBarChartImg && tempDoughnutChartImg) {
            generatesDocument(tempBarChartImg, tempDoughnutChartImg);
        }
    }, [barChartData, doughnutChartData]);

    useEffect(() => {
        if (getEntries.response) {
            const { entries } = getEntries.response;
            formatChartsData(entries);
        }

        if (getReports.response) {
            getRecipientsEmailHistory(getReports.response.reports);
            if (!reports?.length) {
                setReports(getReports.response.reports);
                setLoading(false);
            } else {
                setReports(getReports.response.reports);
            }

            setTotalPages(getReports.response.total_pages);
        }

        if (lockReport.response) {
            const tempReports = reports?.map((report) => {
                if (report.id_e === selectedSend?.id_e) {
                    return { ...report, locked: true, ReportError: [] };
                }
                return report;
            });

            setReports(tempReports);
            setSelectedSend(undefined);
        }
    }, [getEntries.response, getReports.response, lockReport.response]);

    const renderProjects = (
        projects?: { name: string; color: string; id_e: string }[]
    ) => {
        const filteredProjects = projects?.filter(
            (project, index, self) =>
                index === self.findIndex((t) => t.name === project.name)
        );

        return (
            <PopoverProjectList
                projects={filteredProjects}
                message={"Relatório sem projetos."}
            />
        );
    };

    const calculateWorkedHoursPercentage = (totalTime: number): number => {
        const ONE_HOUR_IN_MINUTES = 60;
        const reportHours = totalTime / ONE_HOUR_IN_MINUTES;
        const workload = user.workload || 0;
        const workedHoursPercentage = (reportHours * 100) / workload;
        return workedHoursPercentage;
    };

    const handleClickSendButton = (report: TReport) => {
        setSelectedSend(report);
        if (report.ReportError?.length) {
            setIsOpenResend(true);
            return;
        }
        const workedHoursPercentage = calculateWorkedHoursPercentage(
            report.total_time
        );
        if (workedHoursPercentage < 80) {
            setIsOpenSendWrongConfirm(true);
            return;
        }
        setIsOpenSend(true);
    };

    return (
        <s.Container>
            {isOpenModalSendError && (
                <ModalSendReportError setOpen={setIsOpenModalSendError} />
            )}

            {isOpenSendWrongConfirm && (
                <ModalSendReportWrongConfirm
                    report={selectedSend}
                    setOpen={setIsOpenSendWrongConfirm}
                    onClickConfirm={() => {
                        setIsOpenSendWrongConfirm(false);
                        sendReport();
                    }}
                />
            )}

            {isOpenModal && (
                <ModalDocument
                    setIsOpenModal={setIsOpenModal}
                    data={selected}
                    getReportsByPage={getReportsByPage}
                    emailsHistory={recipientsEmailHistory}
                />
            )}

            {isOpenSend && (
                <ModalSendReport
                    setOpen={setIsOpenSend}
                    onClick={() => {
                        setIsOpenSend(false);
                        sendReport();
                    }}
                />
            )}

            {isOpenResend && (
                <ModalSendReport
                    setOpen={setIsOpenResend}
                    onClick={() => {
                        setIsOpenResend(false);
                        sendReport();
                    }}
                    resend
                />
            )}

            {barChartData && doughnutChartData && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            right: -2000,
                            top: -2000,
                            width: "2180px",
                        }}
                    >
                        <BarChartDashboard
                            times={barChartData?.times}
                            periods={barChartData?.periods}
                            abreviatedDates={barChartData?.abreviatedDates}
                        />
                    </div>

                    <div
                        style={{
                            position: "absolute",
                            right: -2000,
                            top: -2000,
                            width: "200px",
                            height: "200px",
                        }}
                    >
                        <DoughnutChartDashboard
                            labels={doughnutChartData?.labels}
                            times={doughnutChartData?.times}
                            colors={doughnutChartData?.colors}
                        />
                    </div>
                </>
            )}

            <MyReportsOptions />

            {loading ? (
                <s.LoadingWrapper>
                    <LoadingGray />
                </s.LoadingWrapper>
            ) : reports && reports?.length > 0 ? (
                <>
                    <s.History>
                        <div className="table-header">
                            <div className="table-row">
                                <span className="heading-text">Período</span>

                                <span className="heading-text">
                                    Total de horas
                                </span>

                                <span className="heading-text">Projetos</span>

                                <Tooltip
                                    side="top"
                                    align={"end"}
                                    positionArrow={43}
                                    content={
                                        <s.TooltipContent>
                                            <span className="tooltip-title">
                                                Status
                                            </span>

                                            <span className="tooltip-text">
                                                Aqui é mostrado em que ponto
                                                está seu relatório no caminho
                                                até a aprovação para
                                                faturamento. Clique no status e
                                                veja com detalhes a rota do
                                                relatório.
                                            </span>
                                        </s.TooltipContent>
                                    }
                                >
                                    <span className="heading-text">
                                        Status
                                        <Icons.Info />
                                    </span>
                                </Tooltip>

                                <Tooltip
                                    side="top"
                                    align={"end"}
                                    positionArrow={43}
                                    content={
                                        <s.TooltipContent
                                            style={{ width: 218 }}
                                        >
                                            <span className="tooltip-title">
                                                Envio
                                            </span>

                                            <div className="tooltip-row">
                                                <div className="icon-wrapper">
                                                    <Icons.ArrowDiagonal
                                                        color={theme.purple600}
                                                    />
                                                </div>
                                                <span className="tooltip-text">
                                                    Fechar e enviar o relatório
                                                    para revisão, Depois de
                                                    fechado não poderá edita-lo.
                                                </span>
                                            </div>

                                            <div className="tooltip-row">
                                                <div className="icon-wrapper">
                                                    <Icons.ArrowsRetry
                                                        color={theme.purple600}
                                                    />
                                                </div>
                                                <span className="tooltip-text">
                                                    Aqui você reenvia o
                                                    relatório corrigido para
                                                    revisão
                                                </span>
                                            </div>
                                        </s.TooltipContent>
                                    }
                                >
                                    <span className="heading-text">
                                        Envio
                                        <Icons.Info />
                                    </span>
                                </Tooltip>

                                <Tooltip
                                    side="top"
                                    align={"end"}
                                    content={
                                        <s.TooltipContent>
                                            <span className="tooltip-title">
                                                Documentos
                                            </span>

                                            <div className="tooltip-row">
                                                <div className="icon-wrapper">
                                                    <Icons.Upload
                                                        color={theme.purple600}
                                                    />
                                                </div>
                                                <span className="tooltip-text">
                                                    Enviar nota fiscal e colar
                                                    linhas digitáveis do seu
                                                    boleto.
                                                </span>
                                            </div>

                                            <div className="tooltip-row">
                                                <div className="icon-wrapper">
                                                    <Icons.Checked
                                                        color={theme.purple600}
                                                    />
                                                </div>
                                                <span className="tooltip-text">
                                                    Documentos enviados com
                                                    sucesso e consultar
                                                    documentos.
                                                </span>
                                            </div>
                                        </s.TooltipContent>
                                    }
                                >
                                    <span className="heading-text">
                                        Documentos
                                        <Icons.Info />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="rows">
                            {reports?.map((report, index) => (
                                <div className="table-row" key={index}>
                                    <span className="body-text">
                                        {monthFromYear(
                                            parseISO(
                                                report.start_at.slice(0, -1)
                                            ),
                                            true
                                        )}
                                        /
                                        {format(
                                            parseISO(
                                                report.start_at.slice(0, -1)
                                            ),
                                            "yyyy"
                                        )}
                                    </span>

                                    <span className="body-text">
                                        {minutesToStringDate(report.total_time)}
                                    </span>

                                    <Tooltip
                                        side="bottom"
                                        content={renderProjects(
                                            report.projects
                                        )}
                                    >
                                        <span className="project">
                                            <s.ButtonTable>
                                                <Icons.Tag
                                                    color={theme.purple600}
                                                />
                                            </s.ButtonTable>
                                        </span>
                                    </Tooltip>

                                    <ReportStatus data={report} />

                                    <span className="body-text">
                                        {report.locked ? (
                                            "Não disponível"
                                        ) : (
                                            <Tooltip
                                                side="top"
                                                positionArrow={43}
                                                content={
                                                    <s.TooltipContent simple>
                                                        <span className="tooltip-text">
                                                            {report.ReportError &&
                                                            report.ReportError
                                                                .length > 0
                                                                ? "Reenviar relatório"
                                                                : "Enviar relatório"}
                                                        </span>
                                                    </s.TooltipContent>
                                                }
                                            >
                                                <s.ButtonTable
                                                    onClick={() =>
                                                        handleClickSendButton(
                                                            report
                                                        )
                                                    }
                                                >
                                                    {report.ReportError &&
                                                    report.ReportError.length >
                                                        0 ? (
                                                        <Icons.ArrowsRetry
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    ) : (
                                                        <Icons.ArrowDiagonal
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    )}
                                                </s.ButtonTable>
                                            </Tooltip>
                                        )}
                                    </span>

                                    <div className="documents">
                                        {report.reviewed &&
                                        report.ReportBilling != null ? (
                                            <Tooltip
                                                side="top"
                                                positionArrow={43}
                                                content={
                                                    <s.TooltipContent simple>
                                                        <span className="tooltip-text">
                                                            Documentos
                                                        </span>
                                                    </s.TooltipContent>
                                                }
                                            >
                                                <s.ButtonTable
                                                    onClick={() => {
                                                        setIsOpenModal(true);
                                                        setSelected(report);
                                                    }}
                                                >
                                                    {!hasDocuments(report) ? (
                                                        <Icons.Upload
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    ) : (
                                                        <Icons.Eye
                                                            title="Visualizar"
                                                            color={
                                                                theme.purple600
                                                            }
                                                        />
                                                    )}
                                                </s.ButtonTable>
                                            </Tooltip>
                                        ) : (
                                            <span className="body-text">
                                                Não disponível
                                            </span>
                                        )}
                                    </div>
                                    <PopoverRadix
                                        side="bottom"
                                        sideOffset={5}
                                        alignOffset={5}
                                        align="end"
                                        trigger={
                                            <span className={"options"}>
                                                <Icons.More />
                                            </span>
                                        }
                                    >
                                        <s.ContainerPopover>
                                            <s.ButtonPopover
                                                onClick={() => {
                                                    getPeriod({
                                                        from: new Date(
                                                            report.start_at.slice(
                                                                0,
                                                                -1
                                                            )
                                                        ),
                                                        to: new Date(
                                                            report.end_at.slice(
                                                                0,
                                                                -1
                                                            )
                                                        ),
                                                    });

                                                    setSelected(report);
                                                }}
                                                disabled={getEntries.loading}
                                            >
                                                <Icons.Download />
                                                <span className="button-text">
                                                    Baixar resumo PDF
                                                </span>
                                            </s.ButtonPopover>

                                            <s.ButtonPopover
                                                disabled={getEntries.loading}
                                            >
                                                <CsvDownloader
                                                    filename="Relatorio Detalhado"
                                                    datas={hasCsvData}
                                                    columns={[
                                                        {
                                                            id: "start_at",
                                                            displayName:
                                                                "Data/Hora Inicio",
                                                        },
                                                        {
                                                            id: "end_at",
                                                            displayName:
                                                                "Data/Hora Fim",
                                                        },
                                                        {
                                                            id: "description",
                                                            displayName:
                                                                "Descrição",
                                                        },
                                                        {
                                                            id: "project",
                                                            displayName:
                                                                "Projeto",
                                                        },
                                                        {
                                                            id: "duration",
                                                            displayName:
                                                                "Duração",
                                                        },
                                                    ]}
                                                >
                                                    <Icons.Download />
                                                    <span className="button-text">
                                                        Baixar detalhado CSV
                                                    </span>
                                                </CsvDownloader>
                                            </s.ButtonPopover>
                                        </s.ContainerPopover>
                                    </PopoverRadix>
                                </div>
                            ))}
                        </div>
                    </s.History>

                    {totalPages > page && (
                        <s.Footer>
                            {getReports.loading ? (
                                <LoadingGray />
                            ) : (
                                <SecondaryButton
                                    onClick={() => {
                                        showMore();
                                        setPage(page + 1);
                                    }}
                                >
                                    MOSTRAR MAIS
                                </SecondaryButton>
                            )}
                        </s.Footer>
                    )}
                </>
            ) : (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Ainda não há relatórios gerados</h2>
                    <p>
                        Vá em resumo, selecione um período no calendário e após
                        gerá-lo, seu relatório para faturamento estará aqui
                    </p>
                </s.NoContent>
            )}
        </s.Container>
    );
};
