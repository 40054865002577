import styled from "styled-components";

import { SecondaryButton } from "../../atoms";

type TFlexRow = {
    width?: string;
};

export const Container = styled.div<{ home?: boolean }>`
    position: relative;
    display: flex;
    justify-content: center;
    background: ${({ theme }) => theme.white};
    height: 5rem;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.gray100};

    ${({ home }) => home && "position: fixed; z-index: 9999;"}

    button {
        text-transform: capitalize;
    }
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 75rem;
    margin: 0 3.125rem;
`;
export const FlexRow = styled.div<TFlexRow>`
    width: ${({ width }) => width};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.125rem;

    @media (max-width: 768px) {
        #register-button {
            display: none;
        }
    }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
    width: fit-content;
`;
