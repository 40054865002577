import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

export default async (value?: string) => {
    const cep = value?.replace(/\D/g, "");

    const httpResponse = await axiosHttpClient({
        url: `https://viacep.com.br/ws/${cep}/json/`,
        method: "get",
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "CEP inválido!",
    };

    return handleStatusCases({
        body: body || {},
        statusCode: statusCode === 0 ? 400 : statusCode,
        errorFeedBacks,
    });
};
