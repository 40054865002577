import styled from "styled-components";

import { Level } from "../../../../models";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 35px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 70%;
`;

export const Name = styled.h1`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
`;

export const PictureContainer = styled.div`
    position: relative;
    width: 120px;
    height: 120px;

    .level-circle {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: -10px;
        margin-right: -5px;
    }
`;

export const Picture = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
`;

export const LevelText = styled.span<{ level: Level }>`
    color: ${({ theme, level }) => theme.levels[level]};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Points = styled.span`
    color: ${({ theme }) => theme.gray400};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const SeeLevels = styled.button`
    color: ${({ theme }) => theme.gray400};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: ${({ theme }) => theme.gray600};
    }
`;
