import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-medicine-chest"}
    >
        <title>{title}</title>
        <path
            d="M20 8H4C3.17157 8 2.5 8.67157 2.5 9.5V19.5C2.5 20.3284 3.17157 21 4 21H20C20.8284 21 21.5 20.3284 21.5 19.5V9.5C21.5 8.67157 20.8284 8 20 8Z"
            stroke={color || theme.purple400}
            strokeWidth="1.2"
            strokeLinejoin="round"
        />
        <path
            d="M12 11.5V17.5M9 14.5H15H9Z"
            stroke={color || theme.purple400}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 8V4.7C8 4.03726 8.53726 3.5 9.2 3.5H14.8C15.4627 3.5 16 4.03726 16 4.7V8"
            stroke={color || theme.purple400}
            strokeWidth="1.2"
            strokeLinejoin="round"
        />
    </svg>
);
