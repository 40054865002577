import { styled } from "styled-components";

export const Container = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border-radius: 4px;
    border: ${({ active, theme }) => active && `1px solid ${theme.pink10}`};
    background: ${({ active, theme }) => active && `${theme.pink50}`};
    padding: 3px 2px;
`;

export const PopoverTrigger = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    width: 250px;

    background-color: ${({ theme }) => theme.white};
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
`;

export const PopoverContentTitle = styled.h1`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    margin-top: 5px;
`;

export const PopoverContentText = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
`;

export const PopoverContentActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
    margin-top: 10px;
`;

export const PopoverContentEdit = styled.div`
    svg {
        cursor: pointer;
    }
`;

export const PopoverContentDelete = styled.div`
    svg {
        cursor: pointer;
    }
`;
