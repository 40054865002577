import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 12px;
    background-color: #fdeded;
    display: flex;
    margin-top: 41px;
    gap: 12px;
    border-radius: 4px;
`;

export const Text = styled.p`
    color: #d32f2f;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Title = styled.p`
    color: #d32f2f;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
`;
