import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TInviteMass = {
    docFile?: FormData;
};

export default async ({ docFile }: TInviteMass = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/massCreate`,
        method: "post",
        body: docFile,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    const errorMessage = findErrorString(body);

    if (errorMessage?.includes("invalid email:")) {
        const errorFeedBacks = {
            400: `O seguinte e-mail é inválido: ${
                errorMessage.split("invalid email:")[1]
            }`,
            409: "Convite já existe para este usuário!",
        };

        return handleStatusCases({
            body,
            statusCode,
            errorFeedBacks,
        });
    }

    const errorFeedBacks = {
        400: "O arquivo está incorreto!",
        409: "Convite já existe para este usuário!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
