import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    button {
        width: 121px;

        &:last-child {
            background-color: ${({ theme }) => theme.redError};
            border-color: ${({ theme }) => theme.redError};

            &:hover {
                box-shadow: 0px 2px 12px ${({ theme }) => theme.redError};
            }
        }
    }
`;
