import { ActivityDTO } from "../../types/activityDTO";
import { minutesToStringHoursAndMinutes } from "../../utils";
import * as s from "./styles";

type THours = {
    activity: ActivityDTO;
};

export const Hours = ({ activity }: THours) => {
    return (
        <s.Container>
            <s.Box>
                <s.Title>Horas previstas</s.Title>
                <s.Value>
                    {minutesToStringHoursAndMinutes(activity.expectedMinutes)}
                </s.Value>
            </s.Box>
            <s.Box>
                <s.Title>Horas realizadas</s.Title>
                <s.Value>
                    {minutesToStringHoursAndMinutes(
                        activity.hourProgress.minutesDone
                    )}
                </s.Value>
            </s.Box>
            <s.Box>
                <s.Title>Previsto X Realizado</s.Title>
                <s.Value>
                    {activity.hourProgress.percentageOfHoursDone}%
                </s.Value>
            </s.Box>
        </s.Container>
    );
};
