import { Text, View, Image, Svg, Circle } from "@react-pdf/renderer";

import { PurpleLogo, theme } from "../../../../assets";
import { formatISOToDate, minutesToStringDate } from "../../../../utils";
import PDFTemplate, { TPDFData } from "../pdf-template";
import * as s from "../styled-pdf-template";

type TClientResume = TPDFData & {
    client?: string;
    period?: string;
    totalTime?: string;
    currentSearchTerm?: string;
    allSelected?: boolean;
};

const DemonstrativesReport = ({
    pdfData,
    client,
    period,
    totalTime,
    currentSearchTerm,
    allSelected,
}: TClientResume) => {
    const selectedSize =
        pdfData?.projects?.reduce(
            (accumulator, project) =>
                accumulator + (project.project_entries?.length || 0),
            0
        ) || 0;

    return (
        <PDFTemplate>
            <View
                style={[s.PDFTableSummaryReport.Header, { marginBottom: 50 }]}
            >
                <Text
                    style={{
                        ...s.PDFTableSummaryReport.Title,
                        color: theme.gray500,
                    }}
                >
                    Relatório - {formatISOToDate(new Date().toISOString())}
                </Text>

                <Image
                    src={PurpleLogo}
                    style={s.PDFTableSummaryReport.ImageStyle}
                    fixed
                />
            </View>

            <View style={s.PDFTableSummaryReport.Project}>
                <View style={s.PDFTableSummaryReport.ProjectHeader}>
                    <View
                        style={{
                            ...s.PDFTableSummaryReport.Section,
                            borderBottom: `1px solid ${theme.gray100}`,
                        }}
                    >
                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    width: "50%",
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                CLIENTE
                            </Text>
                            <Text
                                style={s.PDFTableSummaryReport.HeaderValueText}
                            >
                                {client}
                            </Text>
                        </View>

                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    borderLeft: `1px solid ${theme.gray200}`,
                                    width: "40%",
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                PROJETO
                            </Text>
                            <Text
                                style={s.PDFTableSummaryReport.HeaderValueText}
                            >
                                {pdfData?.filteredProject}
                            </Text>
                        </View>

                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    borderLeft: `1px solid ${theme.gray200}`,
                                    width: "30%",
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                PERÍODO
                            </Text>
                            <Text
                                style={s.PDFTableSummaryReport.HeaderValueText}
                            >
                                {period}
                            </Text>
                        </View>
                    </View>

                    <View style={s.PDFTableSummaryReport.Section}>
                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    width: "50%",
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                ATIVIDADE
                            </Text>
                            <Text
                                style={{
                                    ...s.PDFTableSummaryReport.HeaderValueText,
                                    color: theme.gray400,
                                }}
                            >
                                {allSelected && !currentSearchTerm && "Todas"}
                                {!allSelected &&
                                    !currentSearchTerm &&
                                    "Diversas"}
                                {!allSelected &&
                                    currentSearchTerm &&
                                    currentSearchTerm}
                            </Text>
                        </View>
                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    width: "40%",
                                    borderLeft: `1px solid ${theme.gray200}`,
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                SELEÇÃO
                            </Text>
                            <Text
                                style={{
                                    ...s.PDFTableSummaryReport.HeaderValueText,
                                    color: allSelected
                                        ? theme.gray400
                                        : theme.gray800,
                                }}
                            >
                                {allSelected
                                    ? `Todos (${selectedSize})`
                                    : `${selectedSize} itens selecionados`}
                            </Text>
                        </View>
                        <View
                            style={[
                                s.PDFTableSummaryReport.HeaderColumn,
                                {
                                    width: "30%",
                                    borderLeft: `1px solid ${theme.gray200}`,
                                },
                            ]}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                TOTAL DE HORAS
                            </Text>
                            <Text
                                style={s.PDFTableSummaryReport.HeaderValueText}
                            >
                                {totalTime}
                            </Text>
                        </View>
                    </View>
                </View>
            </View>

            <View
                style={{
                    border: `1px solid ${theme.gray200}`,
                    borderRadius: 4,
                    marginTop: 30,
                }}
            >
                <View
                    style={{
                        width: "100%",
                    }}
                >
                    <View
                        style={s.PDFTableSummaryReport.TableHeader}
                        wrap={false}
                    >
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                CLIENTE
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                PROJETO
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "40%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                ATIVIDADE
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "10%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                DATA
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "10%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                ATUAÇÃO
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "10%",
                            }}
                        >
                            <Text
                                style={s.PDFTableSummaryReport.TableHeaderText}
                            >
                                HORAS REALIZADAS
                            </Text>
                        </View>
                    </View>

                    <View>
                        {pdfData?.projects?.map((project, index) => (
                            <>
                                {project.project_entries.map((entry) => (
                                    <View
                                        style={[
                                            s.PDFTableSummaryReport.TableRow,
                                            { backgroundColor: theme.white },
                                        ]}
                                        key={index}
                                        wrap={false}
                                        id={"activity"}
                                    >
                                        <View
                                            style={{
                                                width: "15%",
                                            }}
                                        >
                                            <Text
                                                style={
                                                    s.PDFTableSummaryReport
                                                        .TableBodyText
                                                }
                                            >
                                                {project.project_client}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                width: "15%",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <View
                                                        style={[
                                                            s
                                                                .PDFTableSummaryReport
                                                                .ProjectTag,
                                                            {
                                                                backgroundColor:
                                                                    project.project_color
                                                                        ? `${project.project_color}0D`
                                                                        : `${theme.purple500}0D`,
                                                            },
                                                        ]}
                                                    >
                                                        <Svg
                                                            viewBox="0 0 100 100"
                                                            width="10"
                                                            height="10"
                                                        >
                                                            <Circle
                                                                cx="50"
                                                                cy="50"
                                                                r="20"
                                                                fill={
                                                                    project.project_color
                                                                        ? project.project_color
                                                                        : theme.purple500
                                                                }
                                                            />
                                                        </Svg>

                                                        <Text
                                                            style={[
                                                                s
                                                                    .PDFTableSummaryReport
                                                                    .ProjectName,
                                                                {
                                                                    color: project.project_color
                                                                        ? project.project_color
                                                                        : theme.purple500,
                                                                },
                                                            ]}
                                                        >
                                                            {project?.project_name ||
                                                                "-"}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        <View
                                            style={{
                                                width: "40%",
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    s.PDFTableSummaryReport
                                                        .TableBodyText,
                                                    { maxWidth: "90%" },
                                                ]}
                                            >
                                                {entry?.description || "-"}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <Text
                                                style={
                                                    s.PDFTableSummaryReport
                                                        .TableBodyText
                                                }
                                            >
                                                {formatISOToDate(
                                                    entry.start_date
                                                )}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <Text
                                                style={
                                                    s.PDFTableSummaryReport
                                                        .TableBodyText
                                                }
                                            >
                                                {entry?.entry_percent || "-"}
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                width: "10%",
                                            }}
                                        >
                                            <Text
                                                style={
                                                    s.PDFTableSummaryReport
                                                        .TableBodyText
                                                }
                                            >
                                                {minutesToStringDate(
                                                    entry?.entry_time
                                                ) || "-"}
                                            </Text>
                                        </View>
                                    </View>
                                ))}
                                ;
                            </>
                        ))}
                    </View>
                </View>
            </View>
        </PDFTemplate>
    );
};

export default DemonstrativesReport;
