import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TCreateStatusReport = {
    startAt?: string;
    endAt?: string;
    description?: string;
    projectId?: string;
};

export default async ({
    startAt,
    endAt,
    description,
    projectId,
}: TCreateStatusReport = {}) => {
    const params = {
        startAt,
        endAt,
        description,
    };

    const headers = {
        auth: true,
        "x-project-id": projectId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/createStatusReport`,
        method: "post",
        body: params,
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
