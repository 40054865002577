import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="184"
            height="27"
            viewBox="0 0 184 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-paper-white"}
        >
            <title>{title}</title>
            <path
                d="M6.82274 24.7625H0V2.08691H10.3813C12.9231 2.08691 14.903 2.371 16.3746 2.96501C17.8194 3.55902 18.8629 4.41129 19.505 5.54766C20.1204 6.68402 20.4415 8.10448 20.4415 9.75737C20.4415 11.307 20.1204 12.6241 19.4783 13.7088C18.8361 14.7935 17.7926 15.62 16.3211 16.1882C14.8495 16.7563 12.8696 17.0404 10.408 17.0404H6.82274V24.7625ZM11.7191 11.7977C12.2274 11.7977 12.5753 11.6944 12.7893 11.4877C13.0033 11.2811 13.1104 11.0229 13.1639 10.6613C13.2174 10.3256 13.2174 9.93815 13.2174 9.4991C13.2174 9.11171 13.1371 8.80179 12.9766 8.54353C12.8161 8.28526 12.602 8.10448 12.388 7.97534C12.1472 7.84621 11.9331 7.79456 11.7191 7.79456H7.17057L7.0903 11.7977H11.7191Z"
                fill={color || theme.white}
            />
            <path
                d="M40.2944 24.8142H33.7392L31.7058 19.6489H25.6322L23.2777 24.8142H17.3379L24.883 2.08691H31.9733L40.2944 24.8142ZM30.5285 14.6902L28.6824 7.97535L26.756 14.6902H30.5285Z"
                fill={color || theme.white}
            />
            <path
                d="M47.9458 24.7625H41.123V2.08691H51.5043C54.0461 2.08691 56.0261 2.371 57.4976 2.96501C58.9424 3.55902 59.9859 4.41129 60.6281 5.54766C61.2435 6.68402 61.5645 8.10448 61.5645 9.75737C61.5645 11.307 61.2435 12.6241 60.6013 13.7088C59.9592 14.7935 58.9157 15.62 57.4441 16.1882C55.9726 16.7563 53.9926 17.0404 51.5311 17.0404H47.9725V24.7625H47.9458ZM52.8421 11.7977C53.3505 11.7977 53.6983 11.6944 53.9123 11.4877C54.1264 11.2811 54.2334 11.0229 54.2869 10.6613C54.3404 10.3256 54.3404 9.93815 54.3404 9.4991C54.3404 9.11171 54.2602 8.80179 54.0996 8.54353C53.9391 8.28526 53.7251 8.10448 53.511 7.97534C53.2702 7.84621 53.0562 7.79456 52.8421 7.79456H48.2936L48.2134 11.7718H52.8421V11.7977Z"
                fill={color || theme.white}
            />
            <path
                d="M80.7489 24.6851H63.0098V2.08691H80.7489V7.43299L70.6887 7.56212V10.7129H79.0633V15.9041L70.6887 16.059V19.6231H80.7489V24.6851Z"
                fill={color || theme.white}
            />
            <path
                d="M89.6319 24.7367H82.8359V2.08691H93.2172C95.759 2.08691 97.7389 2.371 99.2105 2.96501C100.682 3.55902 101.726 4.41129 102.341 5.54766C102.956 6.68402 103.277 8.10448 103.277 9.75737C103.277 11.2295 102.983 12.495 102.421 13.5539C101.833 14.6127 100.896 15.4392 99.5583 16.0332L104.053 24.6592H97.2573L93.244 17.0663H89.6587V24.7367H89.6319ZM89.9262 11.7718L94.555 11.746C95.0634 11.746 95.4112 11.6427 95.6252 11.4361C95.8393 11.2295 95.9463 10.9712 95.9998 10.6096C96.0266 10.2739 96.0533 9.8865 96.0533 9.47328C96.0533 9.08588 95.9731 8.77596 95.8125 8.5177C95.652 8.25943 95.4647 8.07865 95.2239 7.94952C94.9831 7.82038 94.769 7.76873 94.555 7.76873H90.0065L89.9262 11.7718Z"
                fill={color || theme.white}
            />
            <path
                d="M125.241 26.25H145.014C152.52 26.25 158.642 20.5494 158.642 13.5C158.642 6.45061 152.52 0.75 145.014 0.75H125.241C117.735 0.75 111.613 6.45061 111.613 13.5C111.613 20.5494 117.735 26.25 125.241 26.25ZM112.595 13.5258C112.595 7.01627 118.246 1.71488 125.241 1.71488H145.014C152.009 1.71488 157.66 7.01627 157.66 13.5258C157.66 20.0354 152.009 25.3368 145.014 25.3368H125.241C118.246 25.3368 112.595 20.0354 112.595 13.5258Z"
                fill={color || theme.white}
                stroke={color || theme.white}
                strokeWidth="0.5"
            />
            <path
                d="M146.058 22.1843C151.363 22.1843 155.664 18.172 155.664 13.2225C155.664 8.27307 151.363 4.26074 146.058 4.26074C140.754 4.26074 136.453 8.27307 136.453 13.2225C136.453 18.172 140.754 22.1843 146.058 22.1843Z"
                fill={color || theme.white}
            />
            <path
                d="M170.408 16.3689V24.7367H162.648V2.08691H170.194L176.267 10.3772V2.08691H184V24.7367H176.749L176.508 24.8658L170.408 16.3689Z"
                fill={color || theme.white}
            />
        </svg>
    );
};
