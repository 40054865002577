import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { PopoverRadix, Scroll, Tag, Tooltip } from "../../..";
import { Icons, theme } from "../../../../assets";
import { THourControl } from "../../../../models";
import { numberHoursToString } from "../../../../utils";
import * as s from "./styled-popover-hour-track";

type TPopoverHourTrack = {
    trigger: JSX.Element;
    hourControl?: THourControl[];
    side?: "bottom" | "top" | "right" | "left";
    align?: "end" | "start" | "center";
    sideOffset?: number;
    alignOffset?: number;
    getHourControl?: UseQueryResult<any, unknown>;
};

const PopoverHourTrack = ({
    trigger,
    side,
    align,
    sideOffset,
    alignOffset,
    getHourControl,
}: TPopoverHourTrack) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hourControlData, setHourControlData] = useState([]);

    const verifyLimit = (
        pendingHours: number | undefined,
        hoursDone: number | undefined,
        workLoad: number | undefined
    ): boolean => {
        if (pendingHours === 0) return false;
        if (hoursDone && workLoad) return hoursDone >= 0.9 * workLoad;
        return false;
    };

    useEffect(() => {
        if (getHourControl?.error) {
            toast.error(`${getHourControl?.error}`);
        }
        if (getHourControl?.data) {
            setHourControlData(getHourControl?.data?.[0]?.HourControl);
        }
    }, [getHourControl?.data]);

    return (
        <PopoverRadix
            side={side}
            align={align}
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            onOpenChange={(e) => setIsOpen(e)}
            avoidCollisions={true}
            trigger={<s.Trigger isOpen={isOpen}>{trigger}</s.Trigger>}
            open={isOpen}
        >
            <s.Container>
                <s.Header>
                    <div className="left-align">
                        Projetos ( {hourControlData?.length} )
                    </div>
                    <div>
                        Horas
                        <br />
                        previstas
                    </div>
                    <div>
                        Horas
                        <br />
                        realizadas
                    </div>
                    <div>
                        Horas
                        <br />
                        pendentes
                    </div>
                    <div>
                        Regras
                        <br />
                        de uso{" "}
                        <Tooltip
                            side="top"
                            align={"end"}
                            positionx={15}
                            positiony={10}
                            content={
                                <s.LockTooltip>
                                    <div className="rule">
                                        <div>
                                            <Icons.iconLockClosedFill
                                                color={theme.gray600}
                                            />
                                        </div>
                                        <div>
                                            <div className="title">
                                                Obrigatório
                                            </div>
                                            <div>
                                                Você deve trabalhar o número de
                                                horas previstas, sem
                                                ultrapassá-las.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rule">
                                        <div>
                                            <Icons.IconLockOpen
                                                color={theme.gray600}
                                            />
                                        </div>
                                        <div>
                                            <div className="title">
                                                Flexível
                                            </div>
                                            <div>
                                                Você pode trabalhar menos ou
                                                mais horas do que o previsto,
                                                conforme combinado com o seu
                                                gestor.
                                            </div>
                                        </div>
                                    </div>
                                </s.LockTooltip>
                            }
                        >
                            <span>
                                <Icons.Info color={theme.gray600} />
                            </span>
                        </Tooltip>
                    </div>
                </s.Header>
                <s.List>
                    <Scroll>
                        {hourControlData?.map(
                            (hour: THourControl, index: number) => {
                                const isHourControlLocked = hour?.locked;
                                let expectedHours;
                                if (hour.workLoad) {
                                    expectedHours = `${hour.workLoad}:00`;
                                } else {
                                    expectedHours = "00:00";
                                }
                                return (
                                    <div className="content-row" key={index}>
                                        <div className="left-align">
                                            <div className="tag-container">
                                                <Tag
                                                    color={hour?.Project.color}
                                                >
                                                    {hour?.Project?.name || "-"}
                                                </Tag>
                                            </div>
                                        </div>
                                        <div>
                                            {isHourControlLocked === true
                                                ? expectedHours
                                                : ""}
                                            {isHourControlLocked === false && (
                                                <Tooltip
                                                    side="top"
                                                    align={"end"}
                                                    positionx={15}
                                                    positiony={10}
                                                    content={
                                                        <s.CommonTooltip>
                                                            Você pode trabalhar
                                                            neste projeto, mesmo
                                                            sem horas previstas.
                                                        </s.CommonTooltip>
                                                    }
                                                >
                                                    <div>
                                                        Sem previsão
                                                        <Icons.Info
                                                            color={
                                                                theme.gray600
                                                            }
                                                        />
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div>
                                            {numberHoursToString(
                                                hour?.statistics?.hoursDone || 0
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                color: verifyLimit(
                                                    hour?.statistics
                                                        ?.pendingHours,
                                                    hour?.statistics?.hoursDone,
                                                    hour?.workLoad
                                                )
                                                    ? theme.redError
                                                    : "inherit",
                                                fontWeight: verifyLimit(
                                                    hour?.statistics
                                                        ?.pendingHours,
                                                    hour?.statistics?.hoursDone,
                                                    hour?.workLoad
                                                )
                                                    ? "bold"
                                                    : "normal",
                                            }}
                                        >
                                            {numberHoursToString(
                                                hour?.statistics
                                                    ?.pendingHours || 0
                                            )}
                                        </div>
                                        <div>
                                            {hour?.locked
                                                ? `Obrigatório`
                                                : `Flexível`}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </Scroll>
                </s.List>
            </s.Container>
        </PopoverRadix>
    );
};

export default PopoverHourTrack;
