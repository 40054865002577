import { TCreateUserBody } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type Error = "Change role of owner is not allowed";

export type TUpdateUser = {
    userData?: any;
    userId?: string;
    sectorsId?: string;
    projectsId?: string;
    roleId?: string;
} & TCreateUserBody;

export default async ({
    userData,
    userId,
    sectorsId,
    projectsId,
    roleId,
}: TUpdateUser = {}) => {
    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
        "x-sectors-id": sectorsId,
        "x-role-id": roleId,
    };

    const data = {
        ...userData,
        projectsId,
    };

    if (!userId) delete tempHeaders["x-user-id"];
    if (!sectorsId) delete tempHeaders["x-sectors-id"];
    if (!projectsId) delete data.projectsId;
    if (!roleId) delete tempHeaders["x-role-id"];

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/update`,
        method: "put",
        body: data,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    const error: Error =
        body?.message?.info ||
        body?.message?.name?.[0] ||
        body?.message?.name?.[0] ||
        body?.message;

    const errorsMapper = {
        "Change role of owner is not allowed":
            "Você não pode alterar a atuação do dono da organização.",
        "there must be at least one admin in the organization":
            "Não é possível inativar o único administrador da organização",
        "max users reached":
            "Você atingiu o limite de usuários para este plano",
        "the user is unique reviewer or biller in the following projects": `O usuário é único revisor/faturador em alguns projetos, por isso não pode ser inativado.\n\n ${body?.message?.projects}`,
        "email already exists": "E-mail já existe!",
        "you don't have permission to do this!":
            "Você não pode inativar um administrador!",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Erro! campos obrigatórios não preenchidos",
        403: errorsMapper[error] || "Você não tem permissão para fazer isso!",
        409:
            errorsMapper[error] || "Telefone já cadastrado! Tente outro número",
    };
    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
