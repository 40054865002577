import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../../../../../assets";
import ContainerModal from "../../../../../../../../../components/molecules/modals/container-modal";
import { activities } from "../../../../../../../../../services";
import { TDeleteAttachment } from "../../../../../../../../../services/activities/delete-attachment";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { DeleteConfirmationModal } from "../../../../modals/delete-confirmation-general/delete-confirmation-general";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TImageFullScreen = {
    attachment: ActivityDTO["ActivityAttachments"][0];
    image: string;
    setImageIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activity: ActivityDTO;
};

export const ImageFullScreen = ({
    image,
    setImageIsOpen,
    attachment,
    activity,
}: TImageFullScreen) => {
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const { mutate: deleteAttachment } = useMutation<
        unknown,
        unknown,
        TDeleteAttachment
    >({
        mutationFn: (params) => activities.deleteAttachment(params),
        onSuccess: async () => {
            toast.success("Anexo deletado com sucesso!");
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    return (
        <>
            {deleteConfirmation && (
                <DeleteConfirmationModal
                    message="o anexo"
                    setOpen={setDeleteConfirmation}
                    onConfirm={() => {
                        deleteAttachment({
                            attachmentId: attachment.id_e,
                            activityId: activity.id_e,
                        });
                        setDeleteConfirmation(false);
                        setImageIsOpen(false);
                    }}
                />
            )}
            <ContainerModal
                onOpenChange={setImageIsOpen}
                backgroundColor={"transparent"}
                boxShadow="none"
                overlayColor="#000000ee"
            >
                <s.Container>
                    <s.Image src={image} />
                    <s.Bottom>
                        <s.ImageInformation>
                            <s.Title>{attachment.title}</s.Title>
                            <s.Subtitle>
                                {`${attachment.user.name} adicionou em ${dayjs(
                                    attachment.created_at
                                ).format("DD/MM/YYYY [às] HH:mm")}
                    `}
                            </s.Subtitle>
                        </s.ImageInformation>
                        <s.DeleteButton
                            onClick={() => setDeleteConfirmation(true)}
                        >
                            <Icons.Trash color={theme.white} /> Deletar
                        </s.DeleteButton>
                    </s.Bottom>
                </s.Container>
            </ContainerModal>
        </>
    );
};
