import { useState, useEffect } from "react";

import { useFetch } from "../../../../../hooks";
import { TNotification } from "../../../../../models";
import { audit } from "../../../../../services";
import { getDateFromIsoString } from "../../../../../utils";
import * as s from "./styled-tab-historic";

const HISTORIC_DATA = [
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
    {
        id_e: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        title: "Relatório Aprovado!",
        description: "Relatório aprovado por Edivan no setor de Produtos!",
        wasRead: false,
        type: "common",
        created_at: "2022-08-31T14:09:58.771Z",
        User: {
            avatar: "",
            name: "",
        },
        Sender: {
            avatar: "",
            name: "",
        },
        Receiver: {
            avatar: "",
            name: "",
        },
    },
];

type TTabHistoric = {
    userId: string;
};

export const TabHistoric = ({ userId }: TTabHistoric) => {
    const [notifications, setNotifications] =
        useState<TNotification[]>(HISTORIC_DATA);

    const getAudit = useFetch({
        fn: audit.getAudit,
        params: {
            userId,
        },
    });

    useEffect(() => {
        if (getAudit.response) {
            setNotifications(getAudit.response);
        }
    }, [getAudit.response]);

    return (
        <s.Content>
            <div className="content-title">
                <p className="title-row">Alteração</p>
                <p className="title-row">Usuário/Autor</p>
                <p className="title-row-historic">Data</p>
            </div>
            <div className="resume">
                <ul className="resume-historic">
                    {notifications.map((data, ind) => (
                        <li key={ind} className="resume-historic">
                            <p>{Object.values(data.description)}</p>
                            <p>{Object.values(data.User?.name || " - ")}</p>
                            <p>
                                {Object.values(
                                    `${getDateFromIsoString(data.created_at)
                                        .toLocaleTimeString("pt-BR")
                                        .slice(0, 5)} - ${getDateFromIsoString(
                                        data.created_at
                                    ).toLocaleDateString("pt-BR")}`
                                )}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </s.Content>
    );
};
