import { useMutation } from "@tanstack/react-query";

import createBugReport from "../../services/back-office/create-bug-report";

type Params = {
    onSuccess?: () => void;
};

export function useCreateBugReport({ onSuccess }: Params = {}) {
    return useMutation({
        mutationKey: ["createBugReport"],
        mutationFn: createBugReport,
        onSuccess,
    });
}
