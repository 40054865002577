import styled from "styled-components";

import { theme } from "../../../../assets";

type TSelected = {
    selected?: boolean;
};

export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    left: 13px;
    top: -35px;
    position: relative;
    background: ${theme.white};
    border-radius: 4px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    padding: 11px;
    gap: 6px;
`;

export const Button = styled.button<TSelected>`
    display: flex;
    align-items: center;
    gap: 9px;

    p {
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.01em;
        color: ${({ selected }) =>
            selected ? theme.purple500 : theme.gray400};
    }
`;
