import { Content } from "@radix-ui/react-popover";
import styled from "styled-components";

export const StyledContent = styled(Content)`
    #close-btn {
        position: absolute;
        right: 0;
        margin: 18px 24px;
        z-index: 9;
    }
    z-index: 9999999999;
`;
