import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TUpdateOrganization = {
    reportModel?: "project" | "sector";
    receiveEmails?: boolean;
};

export default async ({
    reportModel,
    receiveEmails,
}: TUpdateOrganization = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/organization/updateSettings`,
        method: "patch",
        headers: { auth: true },
        body: { reportModel, receiveEmails },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
