import { StyleSheet } from "@react-pdf/renderer";

import { theme } from "../../../assets";

export const PDF = StyleSheet.create({
    PageStyle: {
        flexDirection: "column",
        padding: "30px 5px 40px",
        display: "flex",
        justifyContent: "space-between",
    },
    PageContent: { margin: "0 20px 50px" },
    Pagination: {
        position: "absolute",
        right: 20,
        bottom: 15,
        fontFamily: "Helvetica",
        fontSize: 6,
        color: theme.gray600,
    },
    Header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    Title: {
        fontFamily: "Helvetica",
        fontSize: 12,
        fontWeight: 400,
    },
    ImageStyle: { width: "85px", height: "12px" },
    Project: {
        display: "flex",
        flexDirection: "column",
    },
    ProjectHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: `1px solid ${theme.gray50}`,
        borderRadius: 4,
        marginBottom: 40,
    },
    HeaderColumn: {
        display: "flex",
        padding: 15,
    },
    HeaderValueText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray800,
        marginTop: 5,
    },
    ProjectTag: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        padding: "0 5px",
        marginRight: 8,
        height: 14,
    },
    ProjectName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
    },
    ClientName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray400,
    },
    Table: {
        width: "auto",
    },
    TableHeader: {
        flexDirection: "row",
        padding: "7.5px 10px",
        alignItems: "center",
    },
    TableRow: {
        display: "flex",
        flexDirection: "row",
        padding: "7.5px 10px",
        minHeight: 30,
        alignItems: "center",
        borderTop: `1px solid ${theme.gray50}`,
    },
    ActivityColumn: {
        width: "80%",
    },
    CentralizedColumn: {
        width: "10%",
        alignItems: "center",
    },
    TableHeaderText: {
        fontFamily: "Helvetica",
        fontSize: 5,
        fontWeight: 600,
        color: theme.gray400,
    },
    TableBodyText: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray800,
    },
    TableBodyLowerText: {
        fontFamily: "Helvetica",
        fontSize: 6,
        fontWeight: 400,
        color: theme.gray400,
    },
    SectionText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray600,
    },
});

export const PDFTableSummaryReport = StyleSheet.create({
    PageStyle: {
        flexDirection: "column",
        padding: "30px 5px 40px",
        display: "flex",
        justifyContent: "space-between",
    },
    PageContent: { margin: "0 20px 50px" },
    Pagination: {
        position: "absolute",
        right: 20,
        bottom: 15,
        fontFamily: "Helvetica",
        fontSize: 6,
        color: theme.gray600,
    },
    Header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    Title: {
        fontFamily: "Helvetica",
        fontSize: 12,
        fontWeight: 400,
    },
    ImageStyle: { width: "85px", height: "12px" },
    Project: {
        display: "flex",
        flexDirection: "column",
    },
    ProjectHeader: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: `1px solid ${theme.gray200}`,
        borderRadius: 4,
    },
    HeaderColumn: {
        display: "flex",
        padding: 15,
    },
    HeaderValueText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray800,
        marginTop: 5,
    },
    ProjectTag: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        padding: "0 5px",
        marginRight: 8,
        height: 14,
        maxWidth: "90%",
    },
    ProjectName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        maxWidth: "90%",
    },
    ClientName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray400,
    },
    Table: {
        width: "auto",
    },
    TableHeader: {
        flexDirection: "row",
        padding: "7.5px 10px",
        alignItems: "center",
    },
    TableRow: {
        display: "flex",
        flexDirection: "row",
        padding: "7.5px 10px",
        minHeight: 30,
        alignItems: "center",
        borderTop: `1px solid ${theme.gray200}`,
    },
    ActivityColumn: {
        width: "80%",
    },
    CentralizedColumn: {
        width: "10%",
        alignItems: "center",
    },
    TableHeaderText: {
        fontFamily: "Helvetica",
        fontSize: 5,
        fontWeight: 600,
        color: theme.gray400,
    },
    TableBodyText: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray800,
    },
    TableBodyLowerText: {
        fontFamily: "Helvetica",
        fontSize: 6,
        fontWeight: 400,
        color: theme.gray400,
    },
    SectionText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray600,
    },
    Section: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
});

export const TableInvoiceReport = StyleSheet.create({
    TableHeader: {
        flexDirection: "row",
        padding: "7.5px 10px",
        alignItems: "center",
        backgroundColor: "#F6EDFF",
        border: `1px solid ${theme.gray200}`,
    },
    TableHeaderText: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 800,
        color: theme.gray800,
    },
    TableRow: {
        flexDirection: "row",
        padding: "7.5px 10px",
        alignItems: "center",
        border: `1px solid ${theme.gray200}`,
        borderTop: "none",
    },
    TableBodyText: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray600,
    },
    TableBodyLowerText: {
        fontFamily: "Helvetica",
        fontSize: 6,
        fontWeight: 400,
        color: theme.gray400,
    },
    BilledStatus: {
        backgroundColor: "#E7ECE7",
        borderRadius: 4,
        height: 7,
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.green1,
    },
    NotBilledStatus: {
        backgroundColor: "#FDF6F6",
        borderRadius: 4,
        height: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#D14141",
    },
});

export const ProjectCosts = StyleSheet.create({
    CostText: {
        color: theme.gray800,
        textAlign: "center",
        fontSize: "11px",
        fontWeight: 700,
        letterSpacing: "0.1px",
    },
    CostTextPurple: {
        color: theme.purple500,
        textAlign: "center",
        fontSize: "11px",
        fontWeight: 700,
        letterSpacing: "0.1px",
    },
    CostHeader: {
        color: theme.gray600,
        textAlign: "center",
        fontSize: "12px",
        fontWeight: 700,
        letterSpacing: "0.1px",
        marginBottom: "3px",
    },
});
