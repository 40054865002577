export const onlyLetters = (value: string) => {
    let pattern = value;
    pattern = pattern.replace(/[^A-zÁ-ú " "]/, "");
    return pattern;
};

export const onlyNumbers = (value: string) => {
    let pattern = value;
    pattern = pattern.replace(/\D/g, "");
    return pattern;
};

export const telephoneMask = (value: string) => {
    let pattern = value;

    if (value.includes("+")) {
        pattern = pattern.replace(/\D/g, "");
        pattern = pattern.replace(/(\d{2})(\d)/, "+$1 $2");
        pattern = pattern.replace(/(\d{2})(\d)/, "($1) $2");
        pattern = pattern.replace(/(\d{5})(\d)/, "$1 $2");
        return pattern;
    }

    pattern = pattern.replace(/\D/g, "");
    pattern = pattern.replace(/(\d{2})(\d)/, "($1) $2");
    pattern = pattern.replace(/(\d{5})(\d)/, "$1 $2");
    return pattern;
};

export const removePhoneMask = (value: string) => {
    let pattern = value;

    pattern = pattern.replace(/\D/g, "");
    pattern = pattern.replace(/(\d{2})\+(\d{2})/, "$1+ ($2) ");
    pattern = pattern.replace(/(\d{5})(\d{4})/, "$1 $2");

    pattern = pattern.replace(/\D/g, "");
    pattern = pattern.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "$1+ ($2) $3 $4");
    return pattern;
};

export const formatToBRMoney = (value?: number | null) => {
    if (value === null || value === undefined) {
        value = 0;
    }
    return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        currencyDisplay: "narrowSymbol",
    });
};

export const formatToBRMoneyWithoutMoneySign = (value?: number | null) => {
    if (value === null || value === undefined) {
        value = 0;
    }
    return value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: "BRL",
        currencyDisplay: "narrowSymbol",
    });
};
