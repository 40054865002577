import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-filter-list"}
            onClick={onClick}
        >
            <title>{title}</title>
            <g id="filter_list">
                <mask
                    id="mask0_25379_2906"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                >
                    <rect
                        id="Bounding box"
                        width="16"
                        height="16"
                        fill="#D9D9D9"
                    />
                </mask>
                <g mask="url(#mask0_25379_2906)">
                    <path
                        id="filter_list_2"
                        d="M7.3532 11.6653C7.21153 11.6653 7.09278 11.6174 6.99695 11.5215C6.90113 11.4256 6.85322 11.3068 6.85322 11.1651C6.85322 11.0234 6.90113 10.9047 6.99695 10.8089C7.09278 10.7132 7.21153 10.6653 7.3532 10.6653H8.64165C8.78332 10.6653 8.90207 10.7133 8.9979 10.8092C9.09373 10.905 9.14165 11.0238 9.14165 11.1656C9.14165 11.3073 9.09373 11.426 8.9979 11.5217C8.90207 11.6175 8.78332 11.6653 8.64165 11.6653H7.3532ZM4.76987 8.49866C4.62821 8.49866 4.50947 8.45073 4.41363 8.35486C4.3178 8.25899 4.26988 8.14019 4.26988 7.99846C4.26988 7.85673 4.3178 7.738 4.41363 7.64228C4.50947 7.54656 4.62821 7.4987 4.76987 7.4987H11.225C11.3667 7.4987 11.4854 7.54663 11.5812 7.6425C11.6771 7.73838 11.725 7.85718 11.725 7.9989C11.725 8.14063 11.6771 8.25936 11.5812 8.35508C11.4854 8.4508 11.3667 8.49866 11.225 8.49866H4.76987ZM2.83398 5.33201C2.69232 5.33201 2.57357 5.28408 2.47773 5.1882C2.3819 5.09233 2.33398 4.97353 2.33398 4.8318C2.33398 4.69008 2.3819 4.57135 2.47773 4.47563C2.57357 4.3799 2.69232 4.33203 2.83398 4.33203H13.1673C13.309 4.33203 13.4277 4.37997 13.5235 4.47585C13.6194 4.57173 13.6673 4.69053 13.6673 4.83225C13.6673 4.97398 13.6194 5.09271 13.5235 5.18843C13.4277 5.28415 13.309 5.33201 13.1673 5.33201H2.83398Z"
                        fill={color || "#3E374E"}
                    />
                </g>
            </g>
        </svg>
    );
};
