import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Icons, theme } from "../../../../assets";
import ContainerModal from "../../../../components/molecules/modals/container-modal";
import * as s from "./styled-modal-send-report-error";

type TModalWelcomePlan = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalSendReportError = ({ setOpen }: TModalWelcomePlan) => {
    return (
        <ContainerModal
            backgroundColor={theme.gray50}
            onOpenChange={setOpen}
            closeOnIconOnly
            closeIcon={true}
            closeIconColor={theme.gray600}
        >
            <s.Container>
                <section>
                    <header>
                        <Icons.ExclamationOutline
                            color="#d32f2f"
                            width="24px"
                            height="24px"
                        />
                        <h1>Erro ao enviar relatório</h1>
                    </header>
                    <p>
                        Não existem horas de trabalho registradas dentro do
                        período selecionado. Para enviar o relatório, registre
                        as suas horas de trabalho no período desejado.
                    </p>
                    <button
                        onClick={() => {
                            sessionStorage.removeItem("planChoice");
                            setOpen(false);
                        }}
                    >
                        OK
                    </button>
                </section>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalSendReportError;
