import styled from "styled-components";

export const Container = styled.form`
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const AttachmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const AttachmentLabel = styled.label`
    cursor: pointer;
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
`;

export const AttachmentInput = styled.input``;

export const AttachmentError = styled.span`
    color: ${({ theme }) => theme.redError};
    font-size: 12px;
`;
