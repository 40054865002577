import * as yup from "yup";

import { validationErrors } from "../../../utils";

const { name } = validationErrors;

export default () =>
    yup.object().shape({
        name: yup
            .string()
            .required(name.required)
            .min(3, name.minChars)
            .matches(
                /^\s*\S[^]*$/,
                "Favor preencher com valores válidos (Letras e/ou números)"
            ),
        description: yup.string(),
    });
