import styled from "styled-components";

export const Container = styled.div`
    width: 800px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
`;

export const ErrorsContainer = styled.li`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    padding-left: 20px;
    background-color: ${({ theme }) => theme.redError}1A;
`;

export const ErrorWarning = styled.li`
    color: ${({ theme }) => theme.redError};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
`;

export const Item = styled.div<{ bold?: boolean }>`
    color: ${({ theme }) => theme.gray800};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${({ bold }) => (bold ? "500" : "400")};
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Table = styled.div`
    max-height: 600px;
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
`;

export const Content = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Header = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr;
    padding: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.gray100};

    color: ${({ theme }) => theme.gray800};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
`;

export const Row = styled.div<{ error?: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr;
    padding: 15px;

    color: ${({ theme }) => theme.gray800};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    gap: 20px;

    background-color: ${({ error, theme }) => error && `${theme.redError}B0`};

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.gray100};
    }

    &:last-child {
        border-radius: 0px 0px 4px 4px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
    gap: 20px;
`;
