import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Icons,
    PrintPaperOn,
    FotoDepoimento,
    FotoSolucoes,
    IlustracaoHome,
    theme,
    ImgCronometro,
    ImgGestao,
    ImgRelatorios,
    ConstrusystemLogo,
} from "../../assets";
import { PlanCards, PrimaryButton, SecondaryButton } from "../../components";
import ModalBusinessContact from "../../components/molecules/modals/modal-business-contact/modal-business-contact";
import { redirectToWhatsApp } from "../../utils/contact/redirect-to-whatsapp";
import * as s from "./styled-home";

type TAccordionProps = {
    header: string;
    content: string;
    list?: {
        basic: string;
        premium: string;
    };
};

export const Home = () => {
    const navigate = useNavigate();
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [showCookie, setShowCookie] = useState(true);
    const [isModalBusinessOpen, setIsModalBusinessOpen] = useState(false);

    const urlPolitica =
        "https://prod-paperon.s3.eu-central-1.amazonaws.com/POLITICA_DE_PRIVACIDADE_-_PAPERON_15_05_2023.pdf";
    const urlTermos =
        "https://prod-paperon.s3.eu-central-1.amazonaws.com/TERMOS_DE_USO_-_PAPER_ON_15_05_2023.pdf";

    const goToRegister = () => {
        navigate("/cadastro");
        window.scrollTo(0, 0);
    };

    const faqData = [
        {
            header: "Quem pode se beneficiar do PaperON?",
            content:
                "O PaperON oferece benefícios para uma ampla gama de setores e profissionais. Qualquer pessoa ou empresa que queira rastrear o tempo trabalhado, gerenciar equipes e projetos de forma eficiente e aumentar a produtividade.",
        },
        {
            header: "Como funciona o PaperON?",
            content:
                "Após o cadastro, é possível criar perfis para clientes, projetos e profissionais. Os profissionais utilizam o cronômetro de horas para registrar o tempo gasto em cada atividade, associando-o aos projetos. Com base nesses registros, o PaperON gera demonstrativos de performance dos projetos.",
        },
    ];

    const Accordion = ({ header, content, list }: TAccordionProps) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleAccordion = () => {
            setIsOpen(!isOpen);
        };

        return (
            <s.Accordion isOpen={isOpen} onClick={toggleAccordion}>
                <div className="faq-row-header">
                    <h4>{header}</h4>
                    <Icons.ChevronRounded />
                </div>
                <div className="faq-row-body">
                    <p>{content}</p>
                    {list && (
                        <ul>
                            <li>
                                <strong>Plano Básico: </strong>
                                {list.basic}
                            </li>
                            <li>
                                <strong>Plano Premium: </strong>
                                {list.premium}
                            </li>
                        </ul>
                    )}
                </div>
            </s.Accordion>
        );
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleAcceptCookies = () => {
        localStorage.setItem("acceptedCookies", "true");
        setShowCookie(false);
    };

    useEffect(() => {
        const hasAcceptedCookies = localStorage.getItem("acceptedCookies");
        if (hasAcceptedCookies) {
            setShowCookie(false);
        }

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <s.MainContainer>
            <section id="section1">
                <div id="section1-left-content">
                    <div id="section1-left-text">
                        <h1>
                            Transformamos a gestão de times e projetos em algo
                            simples e intuitivo
                        </h1>
                        <h2>
                            Maximize a produtividade da sua equipe ou projeto
                            com uma plataforma de gestão personalizada para sua
                            necessidade. Otimize o controle de tempo, equipes,
                            projetos e obtenha relatórios ágeis e precisos -
                            tudo isso em uma única plataforma.
                        </h2>
                    </div>
                    <div id="section1-button">
                        <PrimaryButton
                            icon={
                                <Icons.Arrow
                                    color={theme.white}
                                    strokeWidth="2"
                                />
                            }
                            iconSolid={true}
                            onClick={goToRegister}
                        >
                            COMECE AGORA
                        </PrimaryButton>
                    </div>
                </div>
                <div id="section1-right-picture">
                    <IlustracaoHome />
                </div>
            </section>

            <section id="section2">
                <div id="section2-content">
                    <img
                        src={PrintPaperOn}
                        id="section2-left-picture"
                        alt="Imagem do sistema na página do cronômetro"
                    />
                    <div id="section2-right-content">
                        <div>
                            <h2>
                                Organização e controle de horas baseados em
                                dados
                            </h2>
                            <p>
                                Facilite a sua jornada com relatórios de horas
                                inteligentes e tenha uma visão 360 de seus
                                projetos e demandas trabalhadas.
                            </p>
                        </div>
                        <div id="section2-button">
                            <PrimaryButton onClick={goToRegister}>
                                Teste Grátis
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </section>

            <section id="section3">
                <div id="section3-content">
                    <div id="section3-left-content">
                        <h1>
                            Organização <span>inteligente</span> de tarefas.
                        </h1>
                        <SecondaryButton
                            className="section3-wide-button"
                            icon={
                                <Icons.ArrowDiagonal color={theme.purple600} />
                            }
                            onClick={goToRegister}
                        >
                            Conheça a plataforma
                        </SecondaryButton>
                    </div>
                    <div id="section3-right-content">
                        <div className="benefits">
                            <div>
                                <ImgCronometro />
                            </div>

                            <div>
                                <h2>Controle de atividades</h2>
                                <p>
                                    Reduza custos e aumente a receita ao mapear
                                    o tempo gasto nas atividades de cada
                                    projeto.
                                </p>
                            </div>
                        </div>

                        <div className="benefits">
                            <div>
                                <ImgGestao />
                            </div>

                            <div>
                                <h2>Gestão de times e projetos</h2>
                                <p>
                                    Acelere a performance da sua empresa com a
                                    organização dos profissionais distribuídos
                                    em times e projetos distintos.
                                </p>
                            </div>
                        </div>

                        <div className="benefits">
                            <div>
                                <ImgRelatorios />
                            </div>

                            <div>
                                <h2>Relatórios de produtividade e horas</h2>
                                <p>
                                    Gere relatórios mais inteligentes que
                                    embasam a tomada de decisão.
                                </p>
                            </div>
                        </div>
                    </div>
                    <SecondaryButton
                        className="section3-phone-button"
                        icon={<Icons.ArrowDiagonal color={theme.purple600} />}
                        onClick={goToRegister}
                    >
                        Conheça a plataforma
                    </SecondaryButton>
                </div>
            </section>

            <section id="section4">
                <div id="section4-content">
                    <img
                        src={FotoDepoimento}
                        id="section4-picture"
                        alt="Imagem de mulher falando no telefone, representando seriedade e confiança"
                    />

                    <div id="section4-text">
                        <h2>
                            Aumente a eficiência e produtividade com nossa
                            plataforma de gestão de horas e projetos. Desenhada
                            para atender às necessidades de empresas,
                            profissionais e empreendedores que buscam economizar
                            tempo e alcançar resultados com informações
                            confiáveis.
                        </h2>
                    </div>
                </div>
            </section>

            <section id="section5">
                <PlanCards setIsModalBusinessOpen={setIsModalBusinessOpen} />
            </section>

            <section id="section6">
                <div id="section6-content">
                    <div id="section6-header">
                        <h3>Perguntas frequentes</h3>
                    </div>
                    <div id="section6-faq">
                        {faqData.map((question, index) => (
                            <Accordion
                                key={index}
                                header={question.header}
                                content={question.content}
                            />
                        ))}
                    </div>
                </div>
            </section>

            <section id="section7">
                <div id="section7-content">
                    <div id="section7-left">
                        <div id="section7-header">
                            <Icons.PaperWhite />
                        </div>
                        <div id="section7-text">
                            <h2>Soluções corporativas</h2>
                            <p>
                                Te ajudamos a implementar o PaperON na sua
                                empresa. Fale com um de nossos especialistas e
                                montaremos um plano de ação para você.
                            </p>
                            <div id="section7-button">
                                <SecondaryButton onClick={redirectToWhatsApp}>
                                    Fale com um especialista
                                </SecondaryButton>
                            </div>
                        </div>
                    </div>
                    <img
                        src={FotoSolucoes}
                        id="solucoes-picture"
                        alt="Imagem de dois profissionais felizes fazendo um 'high five', demonstrando alegria"
                    />
                </div>
            </section>

            <footer>
                <div id="footer-content">
                    <div id="upper-content">
                        <div id="logo-content">
                            <Icons.PaperWhite />
                        </div>
                        <div id="right-content">
                            <div id="contact-content">
                                <p className="bold-title">Contato</p>
                                <ul>
                                    <li>
                                        <Icons.Location />
                                        Campo Bom, RS, Brasil
                                    </li>
                                    <li>
                                        <Icons.PhoneOutlined />
                                        +55 (51) 99826-9032
                                    </li>
                                    <li>
                                        <Icons.MailOutlined />
                                        contato@paperon.app
                                    </li>
                                </ul>
                            </div>
                            <div id="column-content">
                                <div id="social-content">
                                    <p className="bold-title">Nossas páginas</p>
                                    <div>
                                        <a
                                            href="https://www.linkedin.com/company/paperonapp/"
                                            target="_blank"
                                        >
                                            <Icons.Linkedin cursor="pointer" />
                                        </a>
                                        <a
                                            href="https://www.instagram.com/paperon.app/"
                                            target="_blank"
                                        >
                                            <Icons.Instagram cursor="pointer" />
                                        </a>
                                        <a
                                            href="https://blog.paperon.app/conteudo"
                                            target="_blank"
                                        >
                                            <Icons.Book cursor="pointer" />
                                        </a>
                                    </div>
                                </div>
                                <div id="partners-content">
                                    <p className="bold-title">
                                        Nossos parceiros
                                    </p>
                                    <div>
                                        <a
                                            href="https://conteudo.paipe.co/plataforma-construsystem"
                                            target="_blank"
                                            title="ConstruSystem"
                                        >
                                            <img
                                                alt="Logo do sistema ConstruSystem"
                                                src={ConstrusystemLogo}
                                                width={200}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="bottom-content">
                        <div id="terms">
                            <a href={urlTermos || ""} target="_blank">
                                Termos e Condições
                            </a>{" "}
                            |{" "}
                            <a href={urlPolitica || ""} target="_blank">
                                Política de privacidade
                            </a>
                        </div>
                        <div id="copyright">
                            <p>
                                Todos os direitos reservados ©2023 paperon.app
                            </p>
                        </div>
                    </div>
                </div>
            </footer>

            <s.CircleButton>
                <Icons.Whatsapp
                    color={theme.white}
                    onClick={redirectToWhatsApp}
                />
            </s.CircleButton>

            {showScrollToTop && (
                <s.ScrollToTopButton onClick={scrollToTop}>
                    <Icons.ChevronRounded color={theme.white} />
                </s.ScrollToTopButton>
            )}

            {showCookie && (
                <s.CookieBar>
                    <div></div>
                    <p>
                        Este site utiliza cookies para proporcionar uma
                        experiência de navegação melhor. Consulte nossa{" "}
                        <a href={urlPolitica || ""} target="_blank">
                            Politica de Privacidade.
                        </a>
                    </p>
                    <button onClick={() => handleAcceptCookies()}>
                        <Icons.XSolid color={theme.white} />
                    </button>
                </s.CookieBar>
            )}

            {isModalBusinessOpen && (
                <ModalBusinessContact
                    setOpen={setIsModalBusinessOpen}
                    type="homePage"
                />
            )}
        </s.MainContainer>
    );
};
