import { TBaseSVG } from "../../models";

type TIconLinkedin = {
    title?: string;
    color?: string;
    className?: string;
    width?: string;
    height?: string;
    opacity?: string;
    onClick?: () => void;
    cursor?: string;
};

export default ({
    title,
    color,
    className,
    width,
    height,
    opacity,
    onClick,
    cursor,
}: TIconLinkedin) => {
    return (
        <svg
            onClick={onClick}
            width={width || "48"}
            height={height || "48"}
            viewBox="0 0 48 48"
            cursor={cursor || "default"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-linkedin"}
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0ZM11.5216 19.8778H16.9605V36.2196H11.5216V19.8778ZM17.3188 14.8227C17.2835 13.2204 16.1377 12 14.277 12C12.4164 12 11.2 13.2204 11.2 14.8227C11.2 16.3918 12.3805 17.6473 14.2064 17.6473H14.2412C16.1377 17.6473 17.3188 16.3918 17.3188 14.8227ZM30.3131 19.4941C33.8922 19.4941 36.5754 21.8303 36.5754 26.8497L36.5752 36.2196H31.1365V27.4767C31.1365 25.2807 30.3494 23.7822 28.3805 23.7822C26.8779 23.7822 25.9829 24.7924 25.5898 25.7682C25.446 26.1178 25.4107 26.605 25.4107 27.0934V36.22H19.9711C19.9711 36.22 20.0428 21.4117 19.9711 19.8783H25.4107V22.1929C26.1325 21.0802 27.4254 19.4941 30.3131 19.4941Z"
                fill={color || "#F3F3F4E5"}
                fillOpacity={opacity || "0.9"}
            />
        </svg>
    );
};
