import { useEffect } from "react";

import { FotoUser } from "../../../../../assets";
import { LoadingGray, Scroll, Tag } from "../../../../../components";
import { TUser } from "../../../../../models";
import * as s from "./styled-tab-resume";

type TabResumeProps = {
    userData: TUser;
    loading: boolean;
    getUserOnRefresh: (
        value:
            | { userId: string; sectors: boolean; projects: boolean }
            | undefined
    ) => void;
};

export const TabResume = ({
    userData,
    loading,
    getUserOnRefresh,
}: TabResumeProps) => {
    useEffect(() => {
        if (userData?.id_e) {
            getUserOnRefresh({
                userId: userData?.id_e || "",
                sectors: true,
                projects: true,
            });
        }
    }, []);

    return (
        <s.Content>
            <div className="content-title">{}</div>
            <div className="resume">
                {loading ? (
                    <LoadingGray />
                ) : (
                    <>
                        <img
                            src={userData?.avatar || FotoUser}
                            alt="Foto do usuário"
                        />

                        <ul className="resume-data">
                            <li>
                                Nome:<span>{userData?.name || "-"}</span>
                            </li>
                            <li>
                                Setores:
                                <span>
                                    {userData?.Sectors?.length
                                        ? userData?.Sectors?.map(
                                              (sector, index) => {
                                                  if (
                                                      userData?.Sectors
                                                          ?.length ===
                                                      index + 1
                                                  ) {
                                                      return `${sector.name}`;
                                                  }
                                                  return `${sector.name}, `;
                                              }
                                          )
                                        : "-"}
                                </span>
                            </li>
                            <li>
                                Atuação:
                                <span>{userData?.Role?.name || "-"}</span>
                            </li>
                            <li>
                                E-mail:<span>{userData?.email || "-"}</span>
                            </li>
                            <li>
                                Contratação:
                                <span>
                                    {`${
                                        userData?.ContractFormat?.name || " "
                                    } - ${
                                        userData?.workload || "0"
                                    }h mensais` || "-"}
                                </span>
                            </li>
                        </ul>

                        <div className="resume-projects">
                            <h2>Projetos:</h2>
                            <Scroll>
                                <ul>
                                    {userData?.Projects?.map((project) => (
                                        <Tag color={project.color}>
                                            {project.name}
                                        </Tag>
                                    ))}
                                </ul>
                            </Scroll>
                        </div>
                    </>
                )}
            </div>
        </s.Content>
    );
};
