import styled, { keyframes } from "styled-components";

const moveCircle = keyframes`
    0% { left: 13px }
    25% { left: 73px }
    100% { left: 73px }
`;

const rotateAll = keyframes`
    0% {
        box-shadow: none;
        transform: rotate(0deg);
    }
    60% {
        box-shadow: 0px 10px 12px -3px rgba(82, 19, 175, 0.4);
        transform: rotate(0deg);
    }
    75% {
        box-shadow: none;
        transform: rotate(0deg);
    }
    100% {
        box-shadow: none;
        transform: rotate(180deg);
    }
`;

export const ContainerLoader = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 98vh;
`;

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 157px;
    width: 210px;

    .border {
        display: block;
        position: relative;
        height: 78px;
        width: 138px;
        border-color: ${({ theme }) => theme.purple600};
        border-radius: 78px;
        border-style: solid;
        border-width: 5px;
        box-shadow: none;
        content: "";
        animation-delay: 0s;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-name: ${rotateAll};
        animation-timing-function: linear;
    }

    .line {
        position: absolute;
        left: 13px;
        top: 23px;
        height: 32px;
        width: 112px;
        background-color: ${({ theme }) => theme.pink50};
        border-radius: 32px;
        content: "";
    }

    .circle {
        position: absolute;
        left: 60px;
        top: 13px;
        height: 52px;
        width: 52px;
        background-color: ${({ theme }) => theme.purple600};
        border-radius: 50%;
        content: "";
        animation-delay: 0s;
        animation-duration: 2.5s;
        animation-name: ${moveCircle};
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-family: noto-sans, sans-serif;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.15%;
    margin-top: 0.25rem;
`;
