import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-width: 472px;
    gap: 32px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.09px;
    text-align: center;
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-align: center;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 1rem;

    button {
        &:first-child {
            max-width: 120px;
        }
    }

    a {
        display: block;
        width: 100%;
    }
`;
