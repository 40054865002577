import { Icons } from "../../../../assets";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalUniqueWithPermissionsProps = {
    projectName: string;
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalUniqueWithPermissions = ({
    projectName,
    onOpenChange,
}: ModalUniqueWithPermissionsProps) => {
    return (
        <ContainerModal closeIcon onOpenChange={onOpenChange}>
            <s.Container>
                <s.Title>
                    <Icons.Warning />
                    Único revisor ou faturador
                </s.Title>
                <s.Text>
                    Este é o único revisor ou faturador do projeto{" "}
                    <span>{projectName}</span>
                    . <br /> Para garantir que o projeto não fique sem revisor
                    ou faturador, antes de remover as permissões deste
                    profissional, designe outra pessoa como revisor e faturador.
                </s.Text>
            </s.Container>
        </ContainerModal>
    );
};
