import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { Icons, theme } from "../../../../../assets";
import * as s from "./styled-plan-canceled";

import "dayjs/locale/pt-br";

dayjs.extend(utc);

type TPlanCanceledBanner = {
    limitDate: string;
};

export const PlanCanceledBanner = ({ limitDate }: TPlanCanceledBanner) => {
    const maxDate = dayjs.utc(limitDate).format("DD [de] MMMM");
    const invalidDate = dayjs
        .utc(limitDate)
        .add(1, "day")
        .format("DD [de] MMMM");

    return (
        <s.Container>
            <Icons.Info
                width="22px"
                height="22px"
                color={theme.orangeWarning}
            />
            <s.Text>
                <s.Title>Plano cancelado</s.Title>Você pode continuar
                utilizando-o até o dia {maxDate}, data que encerra o pagamento
                já efetuado. A partir de {invalidDate}, todos usuários
                vinculados à organização perderão acesso à plataforma até que um
                novo plano seja comprado..
            </s.Text>
        </s.Container>
    );
};
