import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../../../components/atoms/menu/menu";
import { TProject } from "../../../../../../../../../models";
import { projects } from "../../../../../../../../../services";
import { ReadProjectCostsResult } from "../../../../../../../../../services/projects/types";
import {
    formatISOToDate,
    formatToBRMoneyWithoutMoneySign,
} from "../../../../../../../../../utils";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { CostsFields } from "../../../../../tab-costs";
import {
    costStringToNumber,
    resultToCost,
    statusIsActive,
} from "../../../../../utils";
import { ModalDeleteAdditionalCost } from "../../../../modals/modal-delete-additional-cost/modal-delete-additional-cost";
import { ModalUpsertAdditionalCost } from "../../../../modals/modal-upsert-additional-cost/modal-upsert-additional-cost";
import * as Table from "../../../../table/table";

type VariableCostData = ReadProjectCostsResult["variableCosts"]["data"][0];

type VariableCostRowProps = {
    row: VariableCostData;
    data: ReadProjectCostsResult;
};

export const VariableCostRow = ({ data, row }: VariableCostRowProps) => {
    const [isToEdit, setIsToEdit] = useState(false);
    const [isToDelete, setIsToDelete] = useState(false);

    const { state } = useLocation();
    const { project }: { project: TProject } = state || {};

    const menuOptions: TMenuAtom["options"] = [
        {
            icon: <Icons.EditOutline color={theme.gray900} />,
            label: "Editar",
            action: () => {
                setIsToEdit(true);
            },
            color: theme.gray900,
        },
        {
            icon: <Icons.Trash color={theme.redError} />,
            label: "Deletar",
            action: () => {
                setIsToDelete(true);
            },
            color: theme.redError,
        },
    ];

    const dataToDefaultValues = (data: VariableCostData) => {
        return {
            ...data,
            date: new Date(data.date),
            cost: data.cost.toString(),
        };
    };

    const updateCost = useMutation({
        mutationKey: ["updateProjectAdditionalCost"],
        mutationFn: projects.updateProjectAdditionalCost,
        onSuccess: (result) => {
            const updatedData = data.variableCosts.data.map((item) => {
                if (item.id_e !== result.id_e) return item;
                return resultToCost(result);
            });
            const realizedCost = updatedData.reduce(
                (acc, { cost }) => acc + cost,
                0
            );
            queryClient.setQueryData(["readCosts"], {
                ...data,
                resume: {
                    ...data.resume,
                    total: {
                        ...data.resume.total,
                        variableCost: {
                            ...data.resume.total.variableCost,
                            realized: realizedCost,
                        },
                    },
                },
                variableCosts: {
                    ...data.variableCosts,
                    data: updatedData,
                    total: {
                        ...data.variableCosts.total,
                        realizedCost,
                    },
                },
            });
            toast.success("Custo variável atualizado com sucesso!");
            setIsToEdit(false);
            queryClient.invalidateQueries(["readCosts"]);
        },
    });

    const deleteCost = useMutation({
        mutationKey: ["deleteProjectAdditionalCost"],
        mutationFn: projects.deleteProjectAdditionalCost,
        onSuccess: (result) => {
            const updatedData = data.variableCosts.data.filter(
                ({ id_e }) => id_e !== result.id_e
            );
            const realizedCost = updatedData.reduce(
                (acc, { cost }) => acc + cost,
                0
            );
            queryClient.setQueryData(["readCosts"], {
                ...data,
                resume: {
                    ...data.resume,
                    total: {
                        ...data.resume.total,
                        variableCost: {
                            ...data.resume.total.variableCost,
                            realized: realizedCost,
                        },
                    },
                },
                variableCosts: {
                    ...data.variableCosts,
                    data: updatedData,
                    total: {
                        ...data.variableCosts.total,
                        realizedCost,
                    },
                },
            });
            toast.success("Custo variável deletado com sucesso!");
            setIsToDelete(false);
            queryClient.invalidateQueries(["readCosts"]);
        },
    });

    const update = (fields: CostsFields) => {
        updateCost.mutate({
            additionalCostId: fields.id_e,
            cost: costStringToNumber(fields.cost),
            date: fields.date?.toISOString(),
            description: fields.description,
            title: fields.title,
            projectId: project.id_e,
            recurringId: fields.recurringId,
            updateChoice: fields.updateChoice,
        });
    };

    const remove = () => {
        deleteCost.mutate({
            additionalCostId: row.id_e,
            projectId: project.id_e,
        });
    };

    return (
        <React.Fragment key={row.id_e}>
            {isToDelete && (
                <ModalDeleteAdditionalCost
                    onConfirm={remove}
                    onOpenChange={setIsToDelete}
                />
            )}
            {isToEdit && (
                <ModalUpsertAdditionalCost
                    defaultValues={dataToDefaultValues(row)}
                    onCancel={() => setIsToEdit(false)}
                    onSave={update}
                    loading={updateCost.isLoading}
                />
            )}
            <Table.Row>
                <Table.Data>{row.title}</Table.Data>
                <Table.Data>
                    {row.recurring && <Icons.Repeat />}
                    {row.description}
                </Table.Data>
                <Table.Data>{formatISOToDate(row.date)}</Table.Data>
                <Table.Data>
                    {formatToBRMoneyWithoutMoneySign(row.cost)}
                </Table.Data>
                <Table.Data>
                    <Table.Status active={statusIsActive(row.date)}>
                        {statusIsActive(row.date) ? "Realizado" : "Programado"}
                    </Table.Status>
                </Table.Data>
                <MenuAtom options={menuOptions} data={row.id_e}>
                    <Icons.More color={theme.gray600} className="more" />
                </MenuAtom>
            </Table.Row>
        </React.Fragment>
    );
};
