import styled from "styled-components";

type TContacts = {
    edit: boolean;
};

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 25rem;
    padding-bottom: 2rem;
    position: absolute;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: calc(24px + 1.5rem);
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .title-row {
        width: 18.125rem;
        margin-right: auto;
    }
`;

export const Contacts = styled.form<TContacts>`
    display: flex;
    padding: 1.5rem 1.5rem 1.5rem 2.5rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
    background-color: ${({ theme }) => theme.white2};
    justify-content: space-between;

    svg {
        align-self: flex-start;
    }

    .icons-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: flex-start;
    }

    span.tooltip {
        align-self: flex-start;
        cursor: pointer;
    }

    div.form-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        height: 100%;
        margin-right: 2rem;
        gap: 8px;

        label {
            width: 1.75rem;
            height: 1.75rem;
            border-color: ${({ theme }) => theme.gray500};
            position: relative;

            svg {
                position: absolute;
                top: 1px;
                left: 1px;
                transform: scale(0.75);
            }
        }

        .camera-icon {
            position: relative;
            align-self: center;
            margin: 0 auto 0 1.75rem;

            svg {
                transform: scale(1.05);
                position: absolute;
                border: 1px solid ${({ theme }) => theme.gray500};
                border-radius: 50%;
                padding: 2px;
                padding-top: 3px;
                top: 56px;
                left: 56px;
                background-color: ${({ theme }) => theme.gray50};
                cursor: pointer;
            }
        }

        img {
            border-radius: 50%;
            max-width: 80px;
            max-height: 80px;
            object-fit: cover;
            aspect-ratio: 1/1;
        }

        span#contact-name {
            font-weight: 500;
            color: ${({ theme }) => theme.gray800};
            margin-top: 2px;
            margin-bottom: 26px;
        }

        div.contact-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-height: 50px;
            gap: 0.5rem;

            span {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.gray900};
            }

            span.field-title {
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                color: ${({ theme }) => theme.gray400};
                white-space: nowrap;
            }

            div.input-wrapper {
                max-height: 50px;
                min-height: 36px;
                max-width: 200px;
                margin-left: auto;

                input {
                    max-height: 50px;
                    min-height: 36px;
                }
            }

            div.select-input {
                min-height: 36px;
                max-height: 50px;
                max-width: 200px;
                margin-left: auto;
            }

            div#tip-field {
                display: flex;
                flex-direction: column;
                position: relative;
                margin-left: auto;
            }
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const TipCep = styled.a`
    position: absolute;
    right: 0;
    bottom: -16px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.gray800};
    z-index: 100;
    cursor: pointer;
`;

export const AddressColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 340px;
    gap: 10px;

    div.contact-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        span {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray900};
        }

        span.field-title {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            color: ${({ theme }) => theme.gray400};
            white-space: nowrap;
        }

        div.input-wrapper {
            max-width: 200px;
            margin-left: auto;
        }

        div.select-input {
            max-width: 200px;
            margin-left: auto;
        }

        div#tip-field {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-left: auto;
        }
    }
`;
