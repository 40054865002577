import * as Dialog from "@radix-ui/react-dialog";
import { Dispatch, SetStateAction } from "react";

import { Icons, theme } from "../../../assets";
import * as s from "./styled-container-modal";

type TAlert = {
    children: JSX.Element;
    onOpenChange?: Dispatch<SetStateAction<boolean>>;
    closeIcon?: boolean;
    closeIconColor?: string;
    closeOnIconOnly?: boolean;
    onCloseAction?: () => void;
    topDistance?: string;
    backgroundColor?: string;
    width?: string;
    height?: string;
    boxShadow?: "none";
    overlayColor?: string;
};

const ContainerModal = ({
    children,
    onOpenChange,
    closeIcon,
    closeIconColor,
    closeOnIconOnly,
    onCloseAction,
    topDistance,
    backgroundColor,
    width,
    height,
    boxShadow,
    overlayColor,
}: TAlert) => {
    return (
        <Dialog.Root onOpenChange={onOpenChange} open={true}>
            <Dialog.DialogPortal>
                <s.Overlay
                    style={{
                        backgroundColor: overlayColor,
                    }}
                />

                <s.Content
                    onInteractOutside={(e) => {
                        if (closeOnIconOnly) {
                            e.preventDefault();
                        }
                    }}
                    style={{
                        top: topDistance || "50%",
                        backgroundColor: backgroundColor || theme.white,
                        width: width || undefined,
                        height: height || undefined,
                        boxShadow,
                    }}
                >
                    {children}

                    {closeIcon && (
                        <s.RadixClose
                            onClick={() => {
                                if (onCloseAction) {
                                    onCloseAction();
                                }
                            }}
                        >
                            <Icons.XSolid
                                color={closeIconColor || theme.gray200}
                            />
                        </s.RadixClose>
                    )}
                </s.Content>
            </Dialog.DialogPortal>
        </Dialog.Root>
    );
};

export default ContainerModal;
