import * as yup from "yup";

import { validationErrors } from "../../utils";

const { password, confirmPassword } = validationErrors;

export default () =>
    yup.object().shape({
        password: yup
            .string()
            .required(password.required)
            .min(5, password.minChars),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], confirmPassword.invalid)
            .required(confirmPassword.required),
    });
