import styled from "styled-components";

export const Container = styled.form`
    background-color: ${({ theme }) => theme.white};
    padding: 16px;
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.red};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Field = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    button {
        display: flex;
        align-items: center;
        margin-top: 25px;
    }
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const SkillSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;

export const Experience = styled.div`
    display: flex;
    gap: 10px;

    .input-wrapper {
        width: 100px;
    }
`;
