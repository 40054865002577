import styled from "styled-components";

type TButton = {
    btnState: boolean;
};

export const FlexRow = styled.div`
    display: flex;
    align-items: center;

    span {
        display: flex;

        svg.icon-paper {
            height: 16px;
            width: 82px;
        }

        svg.icon-n {
            height: 16px;
            width: 16px;
            margin-left: 2px;
        }
    }
`;

export const ContainerToggl = styled.div`
    display: flex;
    align-items: center;
    width: 32px;
    height: 18px;
    border-radius: 24px;
    border: 0.1rem solid #663398;
    margin-left: 8px;
`;

export const ButtonToggl = styled.button<TButton>`
    width: 1.0625rem;
    height: 1.0625rem;
    border-radius: 50%;
    background: #663398;
    transition-duration: 0.5s;
    transform: ${({ btnState }) =>
        btnState ? "translateX(120%)" : "translate(0%)"};
`;
