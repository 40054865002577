import { styled } from "styled-components";

export const Container = styled.div`
    padding: 44px 16px 16px;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    width: 442px;
    height: 469px;
`;

export const Image = styled.img`
    width: 100px;
    height: 100px;
`;

export const Points = styled.div`
    color: ${({ theme }) => theme.pink600};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ProgressContainer = styled.div`
    margin-top: -14px;
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Indicator = styled.div<{ active?: boolean }>`
    padding: 2px 9px;
    background-color: ${({ theme, active }) =>
        active ? theme.pink600 : theme.gray200};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.gray50};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
`;

export const Text = styled.div`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
    max-width: 350px;
`;
