import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-camera"}
        >
            <title>{title}</title>
            <path
                d="M3 7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H5.93C6.25918 5.00005 6.58329 4.91884 6.87357 4.76359C7.16384 4.60834 7.4113 4.38383 7.594 4.11L8.406 2.89C8.5887 2.61617 8.83616 2.39166 9.12643 2.23641C9.41671 2.08116 9.74082 1.99995 10.07 2H13.93C14.2592 1.99995 14.5833 2.08116 14.8736 2.23641C15.1638 2.39166 15.4113 2.61617 15.594 2.89L16.406 4.11C16.5887 4.38383 16.8362 4.60834 17.1264 4.76359C17.4167 4.91884 17.7408 5.00005 18.07 5H19C19.5304 5 20.0391 5.21071 20.4142 5.58579C20.7893 5.96086 21 6.46957 21 7V16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V7Z"
                stroke={color || theme.purple500}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 10C15 10.7956 14.6839 11.5587 14.1213 12.1213C13.5587 12.6839 12.7956 13 12 13C11.2044 13 10.4413 12.6839 9.87868 12.1213C9.31607 11.5587 9 10.7956 9 10C9 9.20435 9.31607 8.44129 9.87868 7.87868C10.4413 7.31607 11.2044 7 12 7C12.7956 7 13.5587 7.31607 14.1213 7.87868C14.6839 8.44129 15 9.20435 15 10V10Z"
                stroke={color || theme.purple500}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
