import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icons, theme } from "../../../assets";
import { TDetailsEntry } from "../../../models";
import * as s from "./styled-card-edit-running-timer";

type TEditRunningTimerProps = {
    entry: TDetailsEntry | undefined;
    close: (startAt: string, isDirty: boolean) => void;
};
dayjs.extend(timezone);
dayjs.extend(utc);
export const EditRunningTimerCard = ({
    entry,
    close,
}: TEditRunningTimerProps) => {
    const currentTime = dayjs().format("HH:mm");
    const startAt = dayjs.utc(entry?.start_at).format("HH:mm");

    const {
        control,
        watch,
        formState: { isDirty },
    } = useForm();

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (!ref?.current?.contains(event.target as Node)) {
                close(watch("startAt"), isDirty);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, isDirty]);

    return (
        <s.Container
            onKeyDown={({ key }) => {
                if (key === "Enter") {
                    close(watch("startAt"), isDirty);
                }
            }}
            ref={ref}
        >
            <s.Inputs>
                <s.Title>Inicio</s.Title>
                <Controller
                    name="startAt"
                    control={control}
                    defaultValue={startAt}
                    render={({ field: { value, onChange } }) => (
                        <s.EditInput
                            value={value}
                            onChange={onChange}
                            mask="99:99"
                            maskChar=""
                        />
                    )}
                />
            </s.Inputs>
            <s.Inputs>
                <s.Title>Fim</s.Title>
                <s.EditInput
                    defaultValue={currentTime}
                    disabled
                    style={{
                        color: theme.gray200,
                    }}
                    mask=""
                />
            </s.Inputs>
            <s.SaveIcon
                onClick={() => {
                    close(watch("startAt"), isDirty);
                }}
                title="Salvar"
            >
                <Icons.Checked color={theme.gray600} />
            </s.SaveIcon>
        </s.Container>
    );
};
