import { styled } from "styled-components";

export const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;

    width: 240px;
    padding: 12px 16px;

    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
    letter-spacing: 0.07px;
`;

export const TooltipTitle = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;

    color: ${({ theme }) => theme.orangeWarning};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    text-transform: none;
    letter-spacing: 0.07px;
`;

export const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
