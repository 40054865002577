import React, { Dispatch, SetStateAction } from "react";

import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-logout";

type TModalLogout = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    logoutUser?: () => void;
    loading?: boolean;
};

const ModalLogout = ({ loading, setOpen, logoutUser }: TModalLogout) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <h1>Tem certeza que deseja fazer logout?</h1>

                <div id="buttons">
                    <SecondaryButton onClick={() => setOpen(false)}>
                        CANCELAR
                    </SecondaryButton>

                    <PrimaryButton onClick={logoutUser} loading={loading}>
                        FAZER LOGOUT
                    </PrimaryButton>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalLogout;
