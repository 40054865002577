import { TUpdateInvite } from "../../models";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    inviteIds,
    inviteId,
    multipleUpdate,
    name,
    email,
    contract,
    hourValue,
    roleName,
    sectors,
    projects,
    workload,
}: TUpdateInvite = {}) => {
    const params = {
        inviteIds,
        inviteId,
        multipleUpdate,
        name,
        email,
        contract,
        hourValue,
        roleName,
        sectors,
        projects,
        workload,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/update`,
        method: "put",
        headers: { auth: true },
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errors = {
        "this invite has already been redeemed":
            "Esse convite já foi resgatado",
    };

    const errorMessage = findErrorString<keyof typeof errors>(body);

    const errorFeedBacks = {
        400:
            errors[errorMessage] || "Erro! campos obrigatórios não preenchidos",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
