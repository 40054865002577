import ReactSwitch from "react-switch";

import { theme } from "../../../assets";

type SwitchProps = {
    checked?: boolean;
    onChange: () => void;
    disabled?: boolean;
    scale?: number;
};

export const Switch = ({
    checked = false,
    disabled,
    scale = 1,
    onChange,
}: SwitchProps) => {
    return (
        <ReactSwitch
            width={scale * 40}
            height={scale * 14}
            handleDiameter={scale * 26}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={theme.purple100}
            offColor={theme.gray400}
            offHandleColor={theme.white}
            onHandleColor={theme.purple500}
            boxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
            activeBoxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            className="switch"
        />
    );
};
