import Hotjar from "@hotjar/browser";

type TIdentifyParams = {
    userId: string;
    userProperty: Record<string, string>;
};

class HotjarService {
    public init() {
        if (window.location.hostname === "localhost") return;
        Hotjar.init(3594625, 6);
    }

    public identify({ userId, userProperty }: TIdentifyParams) {
        Hotjar.identify(userId, userProperty);
    }

    public event(event: string) {
        Hotjar.event(event);
    }
}

export default new HotjarService();
