import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TGetCollaborators = {
    page?: number;
    admin?: boolean;
    filter?: string;
    active?: boolean;

    contractFilter?: string[];
    roleFilter?: string[];
    descendingMode?: boolean;
};

export default async ({
    page,
    admin,
    filter,
    active,
    contractFilter,
    descendingMode,
    roleFilter,
}: TGetCollaborators = {}) => {
    const params = {
        page,
        admin,
        filter,
        length: 8,
        active,
    };

    const bodyParams = {
        contractFilter,
        descendingMode,
        roleFilter,
    };

    if (contractFilter === undefined || contractFilter?.length === 0)
        delete bodyParams.contractFilter;
    if (roleFilter === undefined || roleFilter?.length === 0)
        delete bodyParams.roleFilter;
    if (descendingMode === undefined) bodyParams.descendingMode = false;

    if (roleFilter?.[0] === "all") delete bodyParams.roleFilter;
    if (contractFilter?.[0] === "all") delete bodyParams.contractFilter;

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/collaborators`,
        method: "post",
        headers: {
            auth: true,
        },
        params,
        body: bodyParams,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Usuário não encontrado",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
