import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.purple50};
    padding: 25px 60px;
    margin-top: 38px;
    display: flex;
    align-items: center;
    gap: 55px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.03px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;

    .indicator {
        font-size: 26px;
        color: ${({ theme }) => theme.pink400};
    }
`;

export const Item = styled.div`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.07px;
    display: flex;
    align-items: center;
    gap: 22px;
`;

export const CloseButton = styled.button`
    align-self: flex-start;
    margin-right: -30px;
`;
