import dayjs from "dayjs";
import { useState } from "react";

import { PrimaryButton, Tooltip } from "../../../../components";
import { ModalRequestMoreHours } from "../../../../components/molecules/modals/modal-request-more-hours/modal-request-more-hours";
import { useUser } from "../../../../context";
import * as s from "./styles";

export function RequestMoreHours() {
    const [requestMoreHoursModal, setRequestMoreHoursModal] = useState(false);
    const { user, refreshUser } = useUser();
    const additionalHours = user.UserAdditionalHours?.[0];

    function chooseTooltip() {
        if (!additionalHours) return <></>;
        if (additionalHours.status === "requested") {
            return (
                <s.RequestMoreHoursTooltip>
                    Sua solicitação foi enviada. Aguarde a aprovação do seu
                    gestor.
                    <br />
                    Solicitação realizada em{" "}
                    {dayjs(additionalHours?.created_at).format(
                        "DD/MM/YYYY [às] HH:mm"
                    )}
                </s.RequestMoreHoursTooltip>
            );
        }
        if (additionalHours.status === "accepted") {
            return (
                <s.RequestMoreHoursTooltip>
                    Sua solicitação já foi aprovada.
                    <br />
                    Solicitação realizada em{" "}
                    {dayjs(additionalHours?.created_at).format(
                        "DD/MM/YYYY [às] HH:mm"
                    )}
                </s.RequestMoreHoursTooltip>
            );
        }
        if (additionalHours.status === "refused") {
            return (
                <s.RequestMoreHoursTooltip>
                    Sua solicitação foi recusada pelo seu gestor. <br />
                    Entre em contato com ele para mais informações.
                    <br />
                    Solicitação realizada em{" "}
                    {dayjs(additionalHours?.created_at).format(
                        "DD/MM/YYYY [às] HH:mm"
                    )}
                </s.RequestMoreHoursTooltip>
            );
        }
        return <></>;
    }

    return (
        <>
            {requestMoreHoursModal && (
                <ModalRequestMoreHours
                    setOpen={setRequestMoreHoursModal}
                    onSuccess={refreshUser}
                />
            )}

            <Tooltip
                positiony={10}
                content={chooseTooltip()}
                open={additionalHours ? undefined : false}
            >
                <s.RequestMoreHours>
                    <PrimaryButton
                        variation="small"
                        onClick={() => setRequestMoreHoursModal(true)}
                        disabled={
                            additionalHours &&
                            additionalHours.status !== "refused"
                        }
                    >
                        Solicitar mais horas
                    </PrimaryButton>
                </s.RequestMoreHours>
            </Tooltip>
        </>
    );
}
