import styled from "styled-components";

export const Container = styled.div`
    border: 1px solid ${({ theme }) => theme.gray200};
    border-top: none;

    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.white};
`;

export const ContainerHeader = styled.div`
    background-color: ${({ theme }) => theme.purple50};
    padding: 16px 24px;
`;

export const ContainerHeaderTitle = styled.h2`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-weight: 600;
`;

export const ContainerContent = styled.div`
    padding-bottom: 20px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
`;

export const TableHead = styled.thead`
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
`;

export const TableBody = styled.tbody``;

export const TableHeadCell = styled.th`
    padding: 15px 0;

    &:first-child {
        padding-left: 24px;
    }

    color: ${({ theme }) => theme.gray800};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const TableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
`;

export const TableData = styled.td`
    padding: 10px 0;

    &:first-child {
        padding-left: 24px;
    }

    color: ${({ theme }) => theme.gray800};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.07px;

    max-width: 200px;
`;

export const NoBenefits = styled.div`
    padding: 20px 24px;
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.07px;
`;
