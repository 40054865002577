import styled from "styled-components";

import { Icons } from "../../../../../../assets";

export const Container = styled.div`
    padding: 32px;
    width: 664px;
    height: 472px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 34px;
`;

export const ProjectTeam = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 170px;
    overflow-y: auto;

    margin-top: 44px;
`;

export const ProjectTeamTitle = styled.h2`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;
`;

export const ProjectTeamList = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
`;

export const ProjectTeamListItem = styled.div`
    width: 48px;
    height: 48px;
    position: relative;
`;

export const ProjectTeamListPhoto = styled.img`
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
`;

export const ProjectTeamListNameTooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
`;

export const ButtonsContainer = styled.div`
    position: absolute;
    width: 600px;
    display: flex;
    align-items: center;
    gap: 13px;
    bottom: 0;
    margin-bottom: 32px;
`;

export const CheckCircle = styled(Icons.IconCheckCircle2)`
    position: absolute;
    margin-top: 25px;
    margin-left: 30px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 50%;
`;
