import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            onClick={onClick}
            className={className || "icon-chevron-decrease"}
        >
            <title>{title}</title>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.29279 8.15539C5.48031 7.96791 5.73462 7.8626 5.99979 7.8626C9.04815 7.8626 10.9776 7.87643 14.0034 7.85014C14.1362 7.84898 14.2678 7.87428 14.3907 7.92456C14.5136 7.97484 14.6253 8.0491 14.7192 8.14299C14.8131 8.23688 14.8873 8.34854 14.9376 8.47143C14.9879 8.59433 15.0132 8.72601 15.012 8.85879C15.0109 8.99157 14.9833 9.12279 14.9309 9.24479C14.8785 9.36679 14.8023 9.47714 14.7068 9.56939L10.7068 13.5694C10.5193 13.7569 10.265 13.8622 9.99979 13.8622C9.73462 13.8622 9.48031 13.7569 9.29279 13.5694L5.29279 9.56939C5.10532 9.38186 5 9.12755 5 8.86239C5 8.59722 5.10532 8.34291 5.29279 8.15539Z"
                fill={color || "#6E697A"}
            />
        </svg>
    );
};
