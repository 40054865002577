import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { TabInvites, TabSketches } from ".";
import { Icons, theme } from "../../assets";
import {
    CollaboratorsOptions,
    Input,
    ModalInviteChoose,
    ModalInviteSend,
    ModalUserLimitReached,
    PrimaryButton,
    SecondaryButton,
} from "../../components";
import { useFetch } from "../../hooks";
import { invite } from "../../services";
import * as s from "./styled-collaborators-invite";

export type TSketch = {
    id_e: string;
    name: { value: string; isValid: boolean };
    email: { value: string; isValid: boolean; whyEmailIsValid: string };
    contract_format: string;
    value: string;
    role: string;
    isUsed: boolean;
    sectors: {
        id_e: string;
        name: string;
    }[];
    projects: {
        id_e: string;
        name: string;
        color: string;
    }[];
    status: string;
    workload: string;
};

export type TSketchData = {
    inviteSketchReturn: TSketch[];
    total_pages: number;
};

export type TInvite = {
    id_e: string;
    name: string;
    email: string;
    contract: string;
    hourValue: string;
    role: string;
    isUsed: boolean;
    sectors: {
        id: string;
        name: string;
    }[];
    projects: {
        id: string;
        name: string;
        color: string;
    }[];
    inviteStatus: string;
    workload: string;
    created_at: string;
};

export type TInviteData = {
    invites: TInvite[];
    total_pages?: number;
    total_entries: number;
};

export const CollaboratorsInvite = () => {
    const {
        state,
    }: {
        state?: {
            redirectionType: string;
        };
    } = useLocation() || null;
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [isOpenChoose, setIsOpenChoose] = useState(false);
    const [isOpenSendInvites, setIsOpenSendInvites] = useState(false);
    const [isOpenUserLimitReached, setIsOpenUserLimitReached] = useState(false);

    const [sketchData, setSketchData] = useState<TSketchData>();
    const [sketchPage, setSketchPage] = useState<number>(1);
    const [filteredSketches, setFilteredSketches] = useState(
        sketchData?.inviteSketchReturn
    );

    const [inviteData, setInviteData] = useState<TInviteData>();
    const [invitePage, setInvitePage] = useState<number>(1);
    const [filteredInvites, setFilteredInvites] = useState(inviteData?.invites);

    const getSketches = useFetch({
        fn: invite.getSketches,
        errorMessage: "Erro ao pegar rascunhos",
        params: { length: 8, page: sketchPage },
    });

    const convertSketches = useFetch({
        fn: invite.convertSketches,
        errorMessage: "Erro ao criar convite",
        start: false,
    });

    const deleteSketches = useFetch({
        fn: invite.deleteSketches,
        errorMessage: "Erro ao deletar rascunho",
        start: false,
    });

    const getInvites = useFetch({
        fn: invite.getInvites,
        errorMessage: "Erro ao pegar convites",
        params: { all: true, length: 8, page: invitePage },
    });

    const createInvite = useFetch({
        fn: invite.createInvite,
        errorMessage: "Erro ao criar convite",
        start: false,
    });

    const deleteInvites = useFetch({
        fn: invite.deleteInvites,
        errorMessage: "Erro ao deletar convite",
        start: false,
    });

    const resendInvites = useFetch({
        fn: invite.resendInvites,
        errorMessage: "Erro ao criar convite",
        start: false,
    });

    const toggleInviteStatus = useFetch({
        fn: invite.toggleInviteStatus,
        errorMessage: "Erro ao cancelar convite",
        start: false,
    });

    const deleteSketch = (sketchIds: string[]) => {
        deleteSketches.onRefresh({ sketchIds });
    };

    const resendInvite = (inviteIds: string[]) => {
        resendInvites.onRefresh({ inviteIds });
    };

    const cancelInvite = (inviteId: string) => {
        toggleInviteStatus.onRefresh({ inviteId });
    };

    const deleteInvite = (inviteIds: string[]) => {
        deleteInvites.onRefresh({ inviteIds });
    };

    const getSketchesByPage = (page?: number) => {
        if (page) {
            setSketchPage(page);
            getSketches.onRefresh({
                length: 8,
                page,
            });
        }
    };

    const getInvitesByPage = (page?: number) => {
        if (page) {
            setInvitePage(page);
            getInvites.onRefresh({
                all: true,
                length: 8,
                page,
            });
            return;
        }
        setInvitePage(0);
    };

    const sendSketch = (sketchIds: string[]) => {
        convertSketches.onRefresh({ sketchIds });
    };

    const sendAllSketches = (sketchData: TSketch[]) => {
        const sketchesToSend = sketchData?.map(
            (sketch: TSketch) => sketch.id_e
        );
        convertSketches.onRefresh({ sketchIds: sketchesToSend });
        setIsOpenSendInvites(false);
    };

    const refreshTabs = () => {
        getSketches.onRefresh({
            length: 8,
            page: sketchPage,
        });
        getInvites.onRefresh({
            all: true,
            length: 8,
            page: invitePage,
        });
        setSketchPage(0);
    };

    useEffect(() => {
        setLoading(true);
        if (getSketches.response) {
            setSketchData(getSketches.response);
        }
    }, [getSketches.response]);

    useEffect(() => {
        setLoading(true);
        if (getInvites.response) {
            setInviteData(getInvites.response);
        }
    }, [getInvites.response]);

    useEffect(() => {
        const searchFilter = new RegExp(searchValue || "", "i");

        const delay = setTimeout(() => {
            const searchedSketches = sketchData?.inviteSketchReturn.filter(
                (sketch: TSketch) => sketch?.name.value.match(searchFilter)
            );
            const searchedInvites = inviteData?.invites.filter(
                (invite: TInvite) => invite?.name.match(searchFilter)
            );
            setFilteredSketches(searchedSketches);
            setFilteredInvites(searchedInvites);
            setLoading(false);
        }, 500);

        return () => clearTimeout(delay);
    }, [searchValue, sketchData, inviteData]);

    useEffect(() => {
        setLoading(true);
        if (convertSketches.response) {
            toast.success("Convites enviados com sucesso!");
            refreshTabs();
        }
        if (convertSketches.error) {
            refreshTabs();
        }
    }, [convertSketches.response, convertSketches.error]);

    useEffect(() => {
        setLoading(true);
        if (deleteSketches.response) {
            toast.success("Rascunho deletado com sucesso!");
            refreshTabs();
        }
    }, [deleteSketches.response]);

    useEffect(() => {
        setLoading(true);
        if (createInvite.response) {
            toast.success("Convite criado com sucesso!");
            refreshTabs();
        }
    }, [createInvite.response]);

    useEffect(() => {
        setLoading(true);
        if (deleteInvites.response) {
            toast.success("Convite deletado com sucesso!");
            refreshTabs();
            setLoading(false);
        }
    }, [deleteInvites.response]);

    useEffect(() => {
        setLoading(true);
        if (resendInvites.response) {
            toast.success("Convite deletado com sucesso!");
            refreshTabs();
        }
    }, [resendInvites.response]);

    useEffect(() => {
        if (
            resendInvites.error &&
            resendInvites.error.body?.message ===
                "user limit reached for this plan"
        ) {
            setIsOpenUserLimitReached(true);
        }
    }, [resendInvites.error]);

    useEffect(() => {
        setLoading(true);
        if (toggleInviteStatus.response) {
            toast.success("Convite cancelado com sucesso!");
            refreshTabs();
        }
    }, [toggleInviteStatus.response]);

    return (
        <>
            {isOpenChoose && (
                <ModalInviteChoose
                    setOpen={setIsOpenChoose}
                    getSketches={getSketches}
                    getInvites={getInvites}
                    setIsOpenChoose={setIsOpenChoose}
                />
            )}
            {isOpenSendInvites && (
                <ModalInviteSend
                    setOpen={setIsOpenSendInvites}
                    sendAllSketches={sendAllSketches}
                    sketchData={sketchData}
                />
            )}
            {isOpenUserLimitReached && (
                <ModalUserLimitReached setOpen={setIsOpenUserLimitReached} />
            )}

            <s.Container>
                <CollaboratorsOptions />

                <s.Details>
                    <header>
                        <form id="filter">
                            <Input
                                mask=""
                                type="text"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                value={searchValue}
                                icon={<Icons.SearchOutline />}
                                placeholder="Pesquise por profissional, atuação, setor ou projeto"
                                disabled={loading}
                            />

                            <SecondaryButton
                                type="button"
                                disabled={loading}
                                onClick={() => setIsOpenChoose(true)}
                                icon={
                                    <Icons.UserAdd
                                        color={loading ? theme.gray300 : ""}
                                    />
                                }
                            >
                                ADICIONAR
                            </SecondaryButton>

                            <PrimaryButton
                                type="button"
                                disabled={
                                    loading ||
                                    !sketchData?.inviteSketchReturn.length
                                }
                                onClick={() => setIsOpenSendInvites(true)}
                                icon={<Icons.SendFilled />}
                            >
                                ENVIAR CONVITES
                            </PrimaryButton>
                        </form>
                    </header>
                </s.Details>
                <Tabs.Root
                    className="profile-root"
                    defaultValue={state?.redirectionType || "enviados"}
                    orientation="vertical"
                >
                    <Tabs.List
                        aria-label="Gerencie Convites"
                        className="profile-tabs"
                    >
                        <Tabs.Trigger value="rascunhos">Rascunhos</Tabs.Trigger>
                        <Tabs.Trigger value="enviados">
                            Convites enviados
                        </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content value="rascunhos">
                        <TabSketches
                            getSketches={getSketches}
                            sketchData={sketchData}
                            filteredSketches={filteredSketches}
                            loading={loading}
                            setLoading={setLoading}
                            sendSketch={sendSketch}
                            deleteSketch={deleteSketch}
                            setPage={setSketchPage}
                            page={sketchPage}
                            getSketchesByPage={getSketchesByPage}
                        />
                    </Tabs.Content>
                    <Tabs.Content value="enviados">
                        <TabInvites
                            getInvites={getInvites}
                            inviteData={inviteData}
                            filteredInvites={filteredInvites}
                            loading={loading}
                            setLoading={setLoading}
                            resendInvite={resendInvite}
                            cancelInvite={cancelInvite}
                            deleteInvite={deleteInvite}
                            setPage={setInvitePage}
                            page={invitePage}
                            getInvitesByPage={getInvitesByPage}
                        />
                    </Tabs.Content>
                </Tabs.Root>
            </s.Container>
        </>
    );
};
