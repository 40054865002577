import { theme } from "..";

export default () => (
    <svg
        width="110"
        height="72"
        viewBox="0 0 110 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="12.5" y="0.5" width="97" height="71" rx="1.5" fill="#FDF1FE" />
        <line
            x1="23"
            y1="37"
            x2="46"
            y2="37"
            stroke={theme.gray200}
            strokeWidth="6"
            strokeLinecap="round"
        />
        <line
            x1="23"
            y1="15"
            x2="82"
            y2="15"
            stroke={theme.purple400}
            strokeWidth="10"
            strokeLinecap="round"
        />
        <line
            x1="23"
            y1="48"
            x2="57"
            y2="48"
            stroke={theme.gray200}
            strokeWidth="6"
            strokeLinecap="round"
        />
        <line
            x1="23"
            y1="59"
            x2="38"
            y2="59"
            stroke={theme.gray200}
            strokeWidth="6"
            strokeLinecap="round"
        />
        <path d="M0.921875 35L12.6102 45V25L0.921875 35Z" fill="#FDF1FE" />
        <rect
            x="12.5"
            y="0.5"
            width="97"
            height="71"
            rx="1.5"
            stroke="#F6EDFF"
        />
    </svg>
);
