import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TChangePassword = {
    currentPassword?: string;
    newPassword?: string;
    newPasswordConfirm?: string;
};

type TError = "invalid current password";

const errorsMessages = {
    "invalid current password": "Senha atual inválida",
};

export default async ({
    currentPassword,
    newPassword,
    newPasswordConfirm,
}: TChangePassword = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/password/change`,
        method: "put",
        headers: {
            auth: true,
        },
        body: {
            currentPassword,
            newPassword,
            newPasswordConfirm,
        },
    });
    const { body, statusCode } = httpRequest;

    const error: TError = body?.message;

    const errorFeedBacks = {
        400: errorsMessages[error] || error,
        404: "Histórico de usuário não encontrado",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
