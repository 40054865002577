import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type RequestAdditionalHoursParams = {
    requestedHours?: number;
    managerId?: string;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    requestedHours,
    managerId,
}: RequestAdditionalHoursParams = {}) => {
    const params = {
        requestedHours,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/additional/request`,
        method: "post",
        body: params,
        headers: { auth: true, "x-manager-id": managerId },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
