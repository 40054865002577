import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateAttachment = {
    link?: string;
    title?: string;
    type?: "FILE" | "LINK";
    activityId?: string;
    activityAttachment?: FormData;
};

export default async ({
    activityAttachment,
    activityId,
    link,
    title,
    type,
}: TCreateAttachment = {}) => {
    const headers = {
        "x-activity-id": activityId,
        auth: true,
        link,
        title,
        type,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/attachment/create`,
        method: "post",
        body: activityAttachment,
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Extensão de arquivo não permitida",
    };

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
