import styled from "styled-components";

import { Icons } from "../../../../../assets";

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.1fr 1fr 1fr 1fr 0.3fr;
    place-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    height: 5.625rem;
    background-color: ${({ theme }) => theme.white};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    &:last-child {
        border-bottom: 0;
        border-radius: 0px 0px 4px 4px;
    }
    &:hover {
        background-color: ${({ theme }) => theme.gray50};
        -webkit-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
    }

    &:not(:hover) {
        #project-row-edit-icon {
            opacity: 0;
            -webkit-transition: opacity 200ms linear;
            -ms-transition: opacity 200ms linear;
            transition: opacity 200ms linear;
        }
    }
`;

export const ProjectTag = styled.div`
    width: 15rem;
    .project-tag {
        cursor: pointer;
        width: fit-content;
        max-width: 15rem;
        white-space: nowrap;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

export const ProgressBarColumn = styled.div<{ flex?: string | number }>`
    flex: ${({ flex = 1 }) => flex};
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        cursor: pointer;
    }
`;

export const CollapseContent = styled.div`
    display: grid;
    place-items: flex-start;
    place-content: space-between;
    grid-template-columns: 1.4fr 0.7fr 0.8fr 1fr;
    gap: 1.5rem;

    padding: 3rem 1.5rem;
    height: 100%;

    div {
        &:nth-child(3) {
            margin-left: 15px;
        }

        &:nth-child(4) {
            margin-left: 15px;
        }
    }
`;

export const ExpandIcon = styled(Icons.CollapseArrow)<{ open?: boolean }>`
    cursor: pointer;
    transition: transform 333ms ease-out, color 333ms;
    margin-left: 15px;
    ${({ open }) =>
        open &&
        `-ms-transform: rotate(-180deg);
    transform: rotate(-180deg);`}
`;

export const Label = styled.label`
    margin-top: 2px;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.gray400};
`;
export const Value = styled.label`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.gray900};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`;

export const BaseDetails = styled.div<{ flex?: number }>`
    flex: ${({ flex = 1 }) => flex};
    height: 100%;
`;

export const ThreeInfo = styled.div`
    display: grid;
    place-content: center;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.8rem;
    margin-bottom: 1rem;
`;

export const ProjectDetails = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    height: auto;
    gap: 27px;
`;
