import {
    MissionChallenge,
    MissionChallengesContainer,
    MissionRoot,
    MissionText,
    MissionTitle,
} from "./components";

export const Mission = {
    Root: MissionRoot,
    Challenge: MissionChallenge,
    ChallengesContainer: MissionChallengesContainer,
    Title: MissionTitle,
    Text: MissionText,
};
