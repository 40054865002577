import { styled } from "styled-components";

export const Container = styled.div`
    margin-top: 60px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.018px;

    span {
        font-weight: 500;
    }
`;
