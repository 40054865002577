import { styled } from "styled-components";

export const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TooltipContainer = styled.div`
    width: 240px;

    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
    letter-spacing: normal;
`;
