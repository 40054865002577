import styled, { keyframes } from "styled-components";

type TButton = {
    isSelected: boolean;
    $end?: boolean;
    height?: string;
};

type TContainer = {
    isOpen?: boolean;
};

const sideBarSizes = {
    desktop: "17rem",
    tablet: "5.5rem",
};

const animationCloseSideBar = keyframes`
from{max-width:${sideBarSizes.desktop}}
to{max-width:${sideBarSizes.tablet}}
`;

const animationOpenSideBar = keyframes`
from{max-width:${sideBarSizes.tablet}}
to{max-width:${sideBarSizes.desktop}}
`;

const animationOpenChevron = keyframes`
from{ transform: rotate(-90deg);}
to{ transform: rotate(90deg);}
`;

const animationCloseChevron = keyframes`
from{ transform: rotate(90deg);}
to{ transform: rotate(-90deg);}
`;

export const Container = styled.div<TContainer>`
    z-index: 1000;
    animation: ${({ isOpen }) =>
            !isOpen ? animationCloseSideBar : animationOpenSideBar}
        0.5s ease-in-out forwards;
    width: 100%;

    #side-bar {
        width: 100%;
        animation: ${({ isOpen }) =>
                !isOpen ? animationCloseSideBar : animationOpenSideBar}
            0.5s ease-in-out forwards;
    }

    #icon-wrapper {
        animation: ${({ isOpen }) =>
                !isOpen ? animationCloseChevron : animationOpenChevron}
            0.5s ease-in-out forwards;
    }

    @media (max-width: 768px) {
        width: 100vw;
        animation: none;

        #side-bar {
            animation: none;
        }

        #icon-wrapper {
            animation: none;
        }
    }
`;

export const ContainerBar = styled.nav`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    border: 1px solid ${({ theme }) => theme.gray100};
    background: ${({ theme }) => theme.white};
    position: fixed;
    left: 0;

    @media (max-width: 768px) {
        height: 55px;
        max-width: 100vw;
        flex-direction: row;
        position: relative;
    }

    @media (max-width: 768px) {
        .subscription {
            display: none;
        }
    }

    button[aria-haspopup="dialog"] {
        width: 100%;
    }
`;

export const ContainerPaper = styled.div`
    margin-top: 20px;
    margin-bottom: 27px;
    height: 26px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }

    div#logo {
        overflow: hidden;
    }

    #icon-wrapper {
        cursor: pointer;
        width: 26px;
        height: 26px;
        right: -10px;
        position: absolute;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.purple200};
        background: ${({ theme }) => theme.white};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        transform: rotate(90deg);
    }
`;

export const Button = styled.span<TButton>`
    text-transform: capitalize;
    word-wrap: break-word;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5rem;
    width: 100%;
    height: ${({ height }) => height || "48px"};
    margin-top: 8px;

    display: flex;
    align-items: center;

    color: ${({ theme, isSelected }) =>
        isSelected ? theme.purple800 : theme.gray600};
    background: ${({ theme, isSelected }) =>
        isSelected ? theme.pink50 : theme.white};
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 100%;
        margin-top: 0px;
        padding: 6px;
        display: none;

        &.button-cronometro,
        &.button-profile {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0px;
            padding: 3px;
        }
    }

    #bell-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
        color: ${({ theme }) => theme.purple800};
        transition: 0.5s ease-in-out;
    }

    div.selected-bar {
        min-width: 4px;
        height: 100%;
        background: ${({ isSelected, theme }) =>
            isSelected ? theme.purple400 : "transparent"};

        @media (max-width: 768px) {
            display: none;
        }
    }

    div.hollow-bar {
        min-width: 4px;
        height: 100%;
    }

    .chevron-button {
        rotate: 270deg;
    }
`;

export const ButtonMulti = styled.span<TButton>`
    text-transform: capitalize;
    word-wrap: break-word;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5rem;
    width: 100%;
    height: 48px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.purple800 : theme.gray600};
    background: ${({ theme, isSelected }) =>
        isSelected ? theme.pink50 : theme.white};
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    &:hover {
        background: ${({ theme }) => theme.gray50};
        color: ${({ theme }) => theme.purple800};
        transition: 0.5s ease-in-out;
    }

    div.selected-bar {
        min-width: 4px;
        height: 100%;
        background: ${({ isSelected, theme }) =>
            isSelected ? theme.purple400 : "transparent"};
    }

    div.hollow-bar {
        min-width: 4px;
        height: 100%;
    }

    .chevron-button {
        position: absolute;
        rotate: 270deg;
        right: 0;
        margin-right: 20px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const SubmenuContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    margin-left: 4px;
    box-shadow: 0px 2px 8px 0px rgba(14, 2, 26, 0.2);
    border-radius: 4px;
`;

export const SubmenuOption = styled.div<{ active?: boolean }>`
    padding: 12px 28px;
    cursor: pointer;

    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.09px;

    background-color: ${({ active, theme }) => active && theme.pink50};
    border-left: 4px solid
        ${({ active, theme }) => (active ? theme.purple400 : "transparent")};

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;

export const ButtonModal = styled.span<TButton>`
    text-transform: capitalize;
    border-left: 4px solid
        ${({ theme, isSelected }) =>
            isSelected ? theme.purple400 : theme.white};
    word-wrap: break-word;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.purple800 : theme.gray600};
    background: ${({ theme, isSelected }) =>
        isSelected ? theme.pink50 : theme.white};
    font-weight: 400;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 100%;
        margin-bottom: 0px !important;
        font-size: 0px;
    }

    &:hover {
        background: ${({ theme, isSelected }) =>
            isSelected ? theme.pink50 : theme.gray50};
        color: ${({ theme }) => theme.purple800};
        border-left: 4px solid
            ${({ theme, isSelected }) =>
                isSelected ? theme.purple400 : theme.gray50};
        transition: 0.5s ease-in-out;
    }

    div.selected-bar {
        min-width: 4px;
        height: 100%;
        background: ${({ isSelected, theme }) =>
            isSelected ? theme.purple400 : "transparent"};
    }

    div.hollow-bar {
        min-width: 4px;
        height: 100%;
    }

    .notification-dot {
        position: absolute;
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        background: ${({ theme }) => theme.red};
        margin-left: 1.2rem;
        margin-top: -2px;
    }
`;

export const ContainerIcons = styled.div<TButton>`
    display: flex;
    justify-content: center;
    width: 70px;
    padding: 0 16px;
    padding-left: 21px;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
    }

    svg {
        path {
            stroke: ${({ isSelected, theme }) =>
                isSelected ? theme.purple800 : theme.gray700};
        }
    }

    #bell-container {
        position: relative;
    }

    #bell-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        top: -0.5625rem;
        right: -0.5rem;
        position: absolute;
        min-width: 1.25rem;
        height: 1.25rem;
        padding: 0 0.125rem;
        border-radius: 0.625rem;
        background: ${({ theme }) => theme.red};
        font-size: 0.625rem;
        color: ${({ theme }) => theme.white};

        @media (max-width: 767px) {
            right: -0.3rem;
        }

        @media (max-width: 480px) {
            right: 0rem;
        }
    }
`;

export const BottomContainer = styled.div`
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.gray100};
`;

export const BigP = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.purple600};
`;

export const MediumP = styled.p`
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray800};
`;

export const H1 = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.purple500};
`;

export const Separator = styled.hr<{ end?: boolean }>`
    width: 100%;
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.gray100};
    margin-top: ${({ end }) => (end ? `auto` : "16px")};
    margin-bottom: ${({ end }) => end && "16px"};

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ContainerModal = styled.div`
    position: relative;
    background: ${({ theme }) => theme.white};
    border-radius: 4px;
    width: 17rem;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    padding: 8px 0px;

    @media (max-width: 768px) {
        display: none;

        .close-btn {
            display: none;
        }
    }

    div#top-container {
        padding: 18px 24px;
        width: 100%;

        div#header {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }
`;

export const ContainerProfile = styled.div`
    display: flex;
    gap: 24px;
    text-align: start;
    align-items: center;

    .container-image {
        position: relative;

        img {
            height: 48px;
            width: 48px;
            margin-left: 18px;
            border-radius: 50%;
            object-fit: cover;

            @media (max-width: 768px) {
                margin-left: 5px;
                margin-right: 5px;
                width: 40px;
                height: 40px;
            }
        }

        .level-circle {
            position: absolute;
            right: 0;
            margin-top: -20px;
            margin-right: -3px;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    div.container-infos {
        display: flex;
        flex-direction: column;
        width: fit-content;

        @media (max-width: 768px) {
            display: none;
        }

        span#user-name {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            letter-spacing: 0.01em;
            color: ${({ theme }) => theme.gray800};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 160px;
        }

        span#user-team {
            font-style: normal;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            letter-spacing: 0.01em;
            color: ${({ theme }) => theme.gray400};
            white-space: nowrap;
        }
    }

    .chevron {
        transform: rotate(270deg);

        @media (max-width: 768px) {
            display: none;
        }
    }
`;

export const ReportBug = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;

    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    margin-top: 20px;

    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    padding: 5px 10px;
`;
