import { styled } from "styled-components";

import { Level } from "../../../../models";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 44px 49px 16px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
`;

export const LevelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
`;

export const LevelMedal = styled.div<{ level: Level }>`
    background-color: ${({ theme, level }) => theme.levels[level]};
    width: 108px;
    height: 108px;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg {
        scale: 1.8;
    }
`;

export const LevelText = styled.div`
    color: #e4b558;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.08px;
`;

export const Points = styled.div`
    color: ${({ theme }) => theme.gray400};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.14px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
`;
