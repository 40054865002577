import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 23px 32px;
`;

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    div#errors {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 4px;
        background-color: #fdeded;
        width: 100%;
        padding: 12px;
        gap: 12px;
        margin-top: 24px;
        max-width: 600px;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h1 {
                color: #d32f2f;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
            }

            span {
                color: #d32f2f;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 600px;
    margin-top: 40px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.black};
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.024px;
`;

export const SubTitle = styled.p`
    color: var(--light-text-secondary, rgba(0, 0, 0, 0.6));
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin-top: 8px;
`;

export const Brands = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.gray200};
    margin-top: 24px;
    margin-bottom: 24px;
`;

export const Terms = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 24px;

    span {
        text-decoration: underline;
        cursor: pointer;
    }
`;
