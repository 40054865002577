import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { LoadingCircle } from "../../../../../../../../../assets";
import {
    CheckBox,
    DefineMassPermissionsModal,
} from "../../../../../../../../../components";
import { manageUser } from "../../../../../../../../../services";
import {
    GiveUserAllProjectPermissionsParams,
    ReadUserProjectsResponse,
    RemoveUserAllProjectPermissionsParams,
} from "../../../../../../../../../services/user/types";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import * as s from "./styles";

type MassControlRowProps = {
    data: ReadUserProjectsResponse | undefined;
};

type Error = {
    message: string;
};

export const MassControlRow = ({ data }: MassControlRowProps) => {
    if (!data || !data?.projects?.length) return null;

    const { state } = useLocation();
    const userId = state.id;
    const userName = state.name;

    const [target, setTarget] = useState<"reviewer" | "biller">();
    const [definePermissionsModalOpen, setDefinePermissionsModalOpen] =
        useState(false);

    const {
        hasReviewPermission,
        hasBillPermission,
        reviewsAllProjects,
        billsAllProjects,
    } = data;

    const { isLoading: giveIsLoading, mutate: giveAllPermissions } =
        useMutation<unknown, Error, GiveUserAllProjectPermissionsParams>({
            mutationKey: ["giveAllPermissions"],
            mutationFn: ({ userId, type }) =>
                manageUser.giveUserAllProjectPermissions({ userId, type }),
            onSuccess: () => {
                toast.success(
                    "Usuário adicionado a todos os projetos e com permissão de revisor e faturador em massa."
                );
                queryClient.refetchQueries(["userProjects"]);
            },
            onError: (error) => {
                toast.error(error?.message);
            },
        });

    const { isLoading: removeIsLoading, mutate: removeAllPermissions } =
        useMutation<unknown, unknown, RemoveUserAllProjectPermissionsParams>({
            mutationKey: ["removePermissions"],
            mutationFn: ({ userId, type, removeProjects }) =>
                manageUser.removeUserAllProjectPermissions({
                    userId,
                    type,
                    removeProjects,
                }),
            onSuccess: () => {
                toast.success("Permissões removidas com sucesso.");
                queryClient.refetchQueries(["userProjects"]);
            },
        });

    const disableReview = !hasReviewPermission || giveIsLoading;
    const disableBill = !hasBillPermission || giveIsLoading;
    const isLoading = giveIsLoading || removeIsLoading;

    const handleMassReviewer = () => {
        if (disableReview) return;
        if (!reviewsAllProjects) {
            giveAllPermissions({
                userId,
                type: "reviewer",
            });
            return;
        }
        removeAllPermissions({
            userId,
            type: "reviewer",
            removeProjects: false,
        });
    };

    const handleMassBiller = () => {
        if (disableBill) return;
        if (!billsAllProjects) {
            giveAllPermissions({
                userId,
                type: "biller",
            });
            return;
        }
        removeAllPermissions({
            userId,
            type: "biller",
            removeProjects: false,
        });
    };

    const handleConfirm = (type: "reviewer" | "biller") => {
        if (type === "reviewer") {
            handleMassReviewer();
            return;
        }
        handleMassBiller();
    };

    return (
        <>
            {definePermissionsModalOpen && (
                <DefineMassPermissionsModal
                    setOpen={setDefinePermissionsModalOpen}
                    data={{
                        type: target || "reviewer",
                        user: {
                            name: userName,
                        },
                    }}
                    handleConfirm={handleConfirm}
                    isToRemovePermissions={
                        target === "reviewer"
                            ? reviewsAllProjects
                            : billsAllProjects
                    }
                />
            )}
            <s.Container>
                <td></td>
                <td></td>
                <s.CheckboxCell>
                    <s.CheckboxWrapper
                        disabled={disableReview || isLoading}
                        onClick={() => {
                            setTarget("reviewer");
                            setDefinePermissionsModalOpen(true);
                        }}
                    >
                        {isLoading && target === "reviewer" ? (
                            <s.LoadingWrapper>
                                <img src={LoadingCircle} alt="loading" />
                            </s.LoadingWrapper>
                        ) : (
                            <>
                                <CheckBox
                                    id={`all-reviewer`}
                                    key={`${reviewsAllProjects}-all-reviewer`}
                                    width={16}
                                    height={16}
                                    disabled={disableReview}
                                    isChecked={reviewsAllProjects}
                                />
                                Todos
                            </>
                        )}
                    </s.CheckboxWrapper>
                </s.CheckboxCell>
                <s.CheckboxCell>
                    <s.CheckboxWrapper
                        disabled={disableBill || isLoading}
                        onClick={() => {
                            setTarget("biller");
                            setDefinePermissionsModalOpen(true);
                        }}
                    >
                        {isLoading && target === "biller" ? (
                            <s.LoadingWrapper>
                                <img src={LoadingCircle} alt="loading" />
                            </s.LoadingWrapper>
                        ) : (
                            <>
                                <CheckBox
                                    id={`all-biller`}
                                    key={`${billsAllProjects}-all-biller`}
                                    width={16}
                                    height={16}
                                    disabled={disableBill}
                                    isChecked={billsAllProjects}
                                />
                                Todos
                            </>
                        )}
                    </s.CheckboxWrapper>
                </s.CheckboxCell>
                <td></td>
                <td></td>
            </s.Container>
        </>
    );
};
