import { Icons, theme } from "../../../assets";
import * as s from "./styles";

export function ArchivedTag() {
    return (
        <s.Container>
            <Icons.IconArchive color={theme.gray900} />
            ARQUIVADO
        </s.Container>
    );
}
