import { Icons, theme } from "../../../../../../assets";
import { howYouKnowUsAlternatives } from "../../../../../../components/molecules/modals/modal-how-you-know-us/modal-how-you-know-us";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const NewUsersOrigins = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Instagram color={theme.purple500} width="24px" />
                Origem dos novos usuários
            </generalStyles.Title>
            <generalStyles.Row>
                {data.usersOriginAllTime.length > 0 ? (
                    data.usersOriginAllTime.map(({ source, percentage }) => (
                        <generalStyles.Content key={source}>
                            <h1>
                                {howYouKnowUsAlternatives.find(
                                    (item) => item.id === source
                                )?.label || source}
                            </h1>
                            <p>
                                <span>{percentage.toFixed(2)}%</span>
                            </p>
                        </generalStyles.Content>
                    ))
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhuma origem de usuário</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
