import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";

import { Icons, theme } from "../../../../assets";
import ContainerModal from "../../../../components/molecules/modals/container-modal";
import * as s from "./modal-edit-use-case";

type TFormInputs = {
    locked: boolean;
};

type TModalEditUseCases = {
    selectedCollaboratorsQuantity: number;
    setCollaboratorsUseCase: (locked: boolean) => void;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalEditUseCases = ({
    selectedCollaboratorsQuantity,
    setCollaboratorsUseCase,
    setOpen,
}: TModalEditUseCases) => {
    const [checked, setChecked] = React.useState<string>("flexible");

    const { watch, setValue } = useForm<TFormInputs>({
        defaultValues: {
            locked: false,
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCollaboratorsUseCase(watch("locked"));
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <form onSubmit={handleSubmit}>
                    <header>Definir horas previstas</header>
                    <section>
                        <div>
                            Selecione a regra de uso para as horas previstas
                            mensais:
                        </div>
                        <div className="checkBoxOptions">
                            <s.RadioGroupRoot>
                                <s.RadioWrapper>
                                    <div className="flexibleOption">
                                        <div>
                                            <s.RadioGroupItem
                                                id="flexible"
                                                value="false"
                                                checked={checked === "flexible"}
                                                onClick={() => {
                                                    setChecked("flexible");
                                                    setValue("locked", false);
                                                }}
                                            >
                                                <s.RadioGroupIndicator />
                                            </s.RadioGroupItem>

                                            <label
                                                htmlFor="flexible"
                                                className="flexible"
                                            >
                                                <Icons.IconLockOpen
                                                    height="24px"
                                                    width="24px"
                                                    color={
                                                        checked === "flexible"
                                                            ? theme.purple500
                                                            : theme.gray600
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        color:
                                                            checked ===
                                                            "flexible"
                                                                ? theme.purple500
                                                                : theme.gray600,
                                                    }}
                                                >
                                                    Flexível
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className="description"
                                            style={{
                                                color:
                                                    checked === "flexible"
                                                        ? theme.purple500
                                                        : theme.gray600,
                                            }}
                                        >
                                            O profissional poderá trabalhar
                                            menos ou mais horas do que o
                                            previsto, mantendo o cronômetro
                                            sempre ativo.
                                        </div>
                                    </div>

                                    <div className="mandatoryOption">
                                        <div>
                                            <s.RadioGroupItem
                                                id="mandatory"
                                                value="true"
                                                checked={
                                                    checked === "mandatory"
                                                }
                                                onClick={() => {
                                                    setChecked("mandatory");
                                                    setValue("locked", true);
                                                }}
                                            >
                                                <s.RadioGroupIndicator />
                                            </s.RadioGroupItem>

                                            <label
                                                htmlFor="locked"
                                                className="mandatory"
                                            >
                                                <Icons.iconLockClosedFill
                                                    height="24px"
                                                    width="24px"
                                                    color={
                                                        checked === "mandatory"
                                                            ? theme.purple500
                                                            : theme.gray600
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        color:
                                                            checked ===
                                                            "mandatory"
                                                                ? theme.purple500
                                                                : theme.gray600,
                                                    }}
                                                >
                                                    Obrigatório
                                                </span>
                                            </label>
                                        </div>
                                        <div
                                            className="description"
                                            style={{
                                                color:
                                                    checked === "mandatory"
                                                        ? theme.purple500
                                                        : theme.gray600,
                                            }}
                                        >
                                            O profissional deverá trabalhar o
                                            horário previsto, sem poder
                                            ultrapassá-las, e o cronômetro será
                                            inativado ao atingir o limite das
                                            horas previstas.
                                        </div>
                                    </div>
                                </s.RadioWrapper>
                            </s.RadioGroupRoot>
                        </div>
                        <div>
                            Deseja definir para todos os profissionais
                            selecionados ({selectedCollaboratorsQuantity}) ?
                        </div>
                    </section>
                    <footer>
                        <div
                            className="cancelButton"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            CANCELAR
                        </div>
                        <button className="confirmButton" type="submit">
                            CONFIRMAR
                        </button>
                    </footer>
                </form>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalEditUseCases;
