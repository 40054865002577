import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled from "styled-components";

import { theme } from "../../../../assets";

export const Container = styled.div`
    position: relative;
    background: ${({ theme }) => theme.white2};
    border-radius: 4px;
    width: 28rem;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    div#top-container {
        background: ${({ theme }) => theme.white};
        width: 100%;
        div#header {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-start;
            padding: 18px 24px;
            gap: 19px;

            div#filter-label {
                font-weight: bold;
                display: flex;
                align-items: center;
                gap: 9px;
                p {
                    color: ${({ theme }) => theme.purple500};
                    font-style: normal;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    letter-spacing: 0.01em;
                }
            }
        }
    }
    div#content {
        height: 30rem;
        padding: 24px 24px 24px 24px;
    }
    div#no-content {
        display: flex;
        height: 30rem;
        padding: 48px;
        align-items: center;
        justify-content: center;
        text-align: center;

        h2 {
            font-size: 24px;
            font-weight: 400;
            color: ${theme.purple800};
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: ${theme.gray600};
        }
    }
`;

export const MediumP = styled.p`
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray800};
`;

export const Separator = styled.hr`
    width: 100%;
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.gray100};
`;

export const Scroll = styled(ScrollArea.Root)`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: "hidden";
`;

export const Viewport = styled(ScrollArea.Viewport)`
    width: 100%;
    height: 100%;
`;

export const Scrollbar = styled(ScrollArea.Scrollbar)`
    border-radius: 10px;
    display: "flex";
    padding: 2px;
    background-color: ${theme.pink50};
    transition: ${theme.pink50} 160ms ease-out;
    &:hover {
        background-color: #f9e8fb;
    }
    &[data-orientation="vertical"] {
        width: 10px;
    }
`;

export const Thumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background-color: ${theme.pink100};
    border-radius: 10px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: "50%";
        left: "50%";
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44;
        min-height: 44;
    }
`;
