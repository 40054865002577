import styled from "styled-components";

export const Image = styled.img`
    height: 100%;
    max-width: 70vw;
    max-height: 70vh;
    object-fit: cover;
`;

export const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.white};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
    max-width: 30vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const Subtitle = styled.div`
    color: ${({ theme }) => theme.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const ImageInformation = styled.div``;

export const DeleteButton = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    color: ${({ theme }) => theme.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.28px;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
`;
