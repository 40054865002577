import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";

import { CheckBox, PrimaryButton } from "../../../../../../components";
import { Voucher } from "../../../../../../models/voucher";
import { vouchers } from "../../../../../../services";
import { queryClient } from "../../../../../../utils/query-client/query-client";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import { CreateVoucherModal } from "./modals/create-voucher-modal/create-voucher-modal";
import * as s from "./styles";

type Props = {
    data: TBackOfficeData;
};
export const Vouchers = ({ data }: Props) => {
    const [createVoucherModalOpen, setCreateVoucherModalOpen] = useState(false);

    const getVouchers = useQuery<Array<Voucher>>({
        queryKey: ["getVouchers"],
        queryFn: vouchers.getVouchers,
    });

    const toggleVoucherActivation = useMutation({
        mutationKey: ["toggleVoucherActivation"],
        mutationFn: vouchers.toggleVoucherActivation,
        onSuccess() {
            toast.success("Cupom atualizado com sucesso");
        },
        onSettled() {
            queryClient.invalidateQueries(["getVouchers"]);
        },
    });

    function calculateDaysLeft(item: TBackOfficeData["vouchersHistory"][0]) {
        const end = dayjs().subtract(item.Voucher.value, "days");
        const reedmedAt = dayjs(item.reedemedAt);
        const daysLeft = reedmedAt.diff(end, "days");
        return daysLeft <= 0 ? "Concluído" : daysLeft;
    }

    return (
        <>
            {createVoucherModalOpen && (
                <CreateVoucherModal setOpen={setCreateVoucherModalOpen} />
            )}
            <s.Container>
                <PrimaryButton onClick={() => setCreateVoucherModalOpen(true)}>
                    Criar cupom
                </PrimaryButton>
                <generalStyles.Title style={{ marginBottom: "-15px" }}>
                    Cupons criados
                </generalStyles.Title>
                <s.Vouchers>
                    <s.VoucherRow columns={4}>
                        <p>Nome</p>
                        <p>Descrição</p>
                        <p>Dias de teste grátis</p>
                        <p>Status</p>
                    </s.VoucherRow>
                    {getVouchers.data?.map((voucher) => (
                        <s.VoucherRow key={voucher.id_e} columns={4}>
                            <div>{voucher.name}</div>
                            <div>{voucher.description || "-"}</div>
                            <div>{voucher.value} dias</div>
                            <div>
                                <CheckBox
                                    id=""
                                    width={16}
                                    height={16}
                                    isChecked={voucher.active}
                                    onChange={() => {
                                        toggleVoucherActivation.mutate({
                                            active: !voucher.active,
                                            voucherId: voucher.id_e,
                                        });
                                    }}
                                />
                                {voucher.active ? "Ativo" : "Inativo"}
                            </div>
                        </s.VoucherRow>
                    ))}
                </s.Vouchers>
                <generalStyles.Title style={{ marginBottom: "-15px" }}>
                    Histórico dos cupons
                </generalStyles.Title>
                <s.Vouchers>
                    <s.VoucherRow columns={6}>
                        <p>Organização</p>
                        <p>E-mail</p>
                        <p>Cupom usado</p>
                        <p>Dias no cupom</p>
                        <p>Dias restantes</p>
                        <p>Resgatado em</p>
                    </s.VoucherRow>
                    {data.vouchersHistory.map((item, index) => (
                        <s.VoucherRow key={index} columns={6}>
                            <div>{item.Organization.name}</div>
                            <div>{item.Organization.email}</div>
                            <div>{item.Voucher.name}</div>
                            <div>{item.Voucher.value}</div>
                            <div>{calculateDaysLeft(item)}</div>
                            <div>
                                {dayjs(item.reedemedAt).format(
                                    "DD/MM/YYYY [às] HH:mm"
                                )}
                            </div>
                        </s.VoucherRow>
                    ))}
                </s.Vouchers>
            </s.Container>
        </>
    );
};
