import dayjs from "dayjs";

import { Icons } from "../../../../../assets";
import { TSubscription } from "../../../../../models/subscription";
import * as s from "./styled-error-in-payment";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

type TErrorInPaymentBanner = {
    subscription: TSubscription;
};

export const ErrorInPaymentBanner = ({
    subscription,
}: TErrorInPaymentBanner) => {
    const limitDate = dayjs(subscription?.updated_at)
        .add(2, "days")
        .format("DD [de] MMMM [de] YYYY");
    return (
        <s.Container>
            <Icons.Info width="22px" height="22px" color="#d32f2f" />
            <s.Text>
                <s.Title>Pagamento recusado</s.Title>O pagamento da assinatura
                do plano foi recusado. Para manter a assinatura ativa, altere a
                forma de pagamento até o dia {limitDate}.
            </s.Text>
        </s.Container>
    );
};
