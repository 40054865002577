import { useNavigate } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../../../../../components";
import * as s from "./styled-button-group";

type TButtonGroupProps = {
    onSave: () => void;
    edit: boolean | undefined;
    view?: boolean;
    loading?: boolean;
};

export const ButtonGroup = ({
    onSave,
    edit,
    view,
    loading,
}: TButtonGroupProps) => {
    const navigate = useNavigate();

    return (
        <s.Container>
            {!view && (
                <PrimaryButton
                    type="submit"
                    children={edit ? "Salvar" : "Criar atuação"}
                    onClick={onSave}
                    loading={loading}
                />
            )}
            <SecondaryButton onClick={() => navigate("/organizacao/atuacoes")}>
                {"Voltar"}
            </SecondaryButton>
        </s.Container>
    );
};
