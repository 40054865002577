import dayjs from "dayjs";

import { theme } from "../../../../../../../../assets";
import { TProject } from "../../../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../../../services/projects/types";
import { StackedLineStyles } from "../stacked-line/stacked-line";
import * as s from "./styles";

type Props = {
    data: ReadDemonstrativeResult | undefined;
    project: TProject;
};

const INITIAL_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;
const OVERLAP_VALUE = 5;

export const RealizedScope = ({ project, data }: Props) => {
    if (!data) return null;

    const { scopeDone, projectionInTheEnd, monthsLate } = data.scope;

    const totalPercentage = scopeDone + projectionInTheEnd;
    const scaleFactor =
        totalPercentage > MAX_PERCENTAGE ? MAX_PERCENTAGE / totalPercentage : 1;

    const normalizedScopeDone = scopeDone * scaleFactor;
    const normalizedProjectionInTheEnd = projectionInTheEnd * scaleFactor;
    const diffBetweenDoneAndEnd = Math.abs(
        normalizedProjectionInTheEnd - normalizedScopeDone
    );
    const isOverlappingDoneAndEnd = diffBetweenDoneAndEnd <= OVERLAP_VALUE;

    const exceededTime = MAX_PERCENTAGE;
    const delayProjection =
        monthsLate < INITIAL_PERCENTAGE ? MAX_PERCENTAGE : INITIAL_PERCENTAGE;
    const leftScopeDone =
        scopeDone === MAX_PERCENTAGE ? scopeDone : normalizedScopeDone;

    const scopeDoneOffset = isOverlappingDoneAndEnd ? 80 : undefined;
    const scopeInTheEndOffset = isOverlappingDoneAndEnd ? 180 : undefined;

    const scopeIsDone = scopeDone === MAX_PERCENTAGE;
    const noProjectionInTheEnd = projectionInTheEnd === INITIAL_PERCENTAGE;
    const doneIsBiggerThanProjection = projectionInTheEnd <= scopeDone;

    const projectionIsToFinish = projectionInTheEnd === MAX_PERCENTAGE;
    const projectionOverflowLimit = projectionInTheEnd > MAX_PERCENTAGE;

    const hideProjectionInTheEnd =
        scopeIsDone || noProjectionInTheEnd || doneIsBiggerThanProjection;

    const formatDate = (date?: string) => {
        return dayjs(date).add(3, "hours").format("DD/MM/YYYY");
    };

    return (
        <s.Container>
            <s.Title>
                Escopo realizado: <span>{scopeDone}%</span>
            </s.Title>
            <StackedLineStyles.Container>
                <StackedLineStyles.Labels
                    hide={!scopeDone || !(project.start_at && project.end_at)}
                >
                    <StackedLineStyles.Label>
                        <p>{INITIAL_PERCENTAGE}%</p>
                        <p>{formatDate(project.start_at)}</p>
                    </StackedLineStyles.Label>

                    <StackedLineStyles.Label
                        left={leftScopeDone}
                        offset={scopeDoneOffset}
                    >
                        <p>{Math.ceil(scopeDone)}%</p>
                        <p>{formatDate()}</p>
                    </StackedLineStyles.Label>

                    <StackedLineStyles.Label
                        hide={hideProjectionInTheEnd}
                        left={
                            projectionIsToFinish
                                ? projectionInTheEnd
                                : normalizedProjectionInTheEnd
                        }
                        offset={scopeInTheEndOffset}
                    >
                        <p>
                            {Math.ceil(
                                projectionOverflowLimit
                                    ? MAX_PERCENTAGE
                                    : projectionInTheEnd
                            )}
                            %
                        </p>
                        <p>
                            {monthsLate > INITIAL_PERCENTAGE
                                ? dayjs(project.end_at)
                                      .subtract(monthsLate, "months")
                                      .format("DD/MM/YYYY")
                                : formatDate(project.end_at)}
                        </p>
                    </StackedLineStyles.Label>

                    <StackedLineStyles.Label
                        left={MAX_PERCENTAGE}
                        hide={scopeIsDone}
                    >
                        <p>
                            {(projectionOverflowLimit
                                ? projectionInTheEnd
                                : MAX_PERCENTAGE
                            ).toFixed(2)}
                            %
                        </p>
                        <p>
                            {monthsLate < INITIAL_PERCENTAGE
                                ? dayjs(project.end_at)
                                      .add(Math.abs(monthsLate), "months")
                                      .format("DD/MM/YYYY")
                                : formatDate(project.end_at)}
                        </p>
                    </StackedLineStyles.Label>
                </StackedLineStyles.Labels>

                <StackedLineStyles.LineContainer>
                    <StackedLineStyles.RealizedHours
                        percentage={
                            scopeIsDone ? MAX_PERCENTAGE : normalizedScopeDone
                        }
                    />
                    <StackedLineStyles.EndProjection
                        percentage={
                            projectionIsToFinish
                                ? MAX_PERCENTAGE
                                : normalizedProjectionInTheEnd
                        }
                    />
                    <StackedLineStyles.DelayProjection
                        percentage={delayProjection}
                    />
                    <StackedLineStyles.ExceededTime percentage={exceededTime} />
                </StackedLineStyles.LineContainer>
                <StackedLineStyles.LegendContainer>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.purple300}
                        />
                        Escopo realizado
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.gray200}
                        />
                        Projeção de escopo no dia do término do projeto
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.gray300}
                        />
                        Tempo excedente
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.redError}
                        />
                        Projeção de atraso
                    </StackedLineStyles.Legend>
                </StackedLineStyles.LegendContainer>
            </StackedLineStyles.Container>
        </s.Container>
    );
};
