import * as yup from "yup";

import { validationErrors } from "../../../../utils";

const { name, email } = validationErrors;

export default yup.object().shape({
    name: yup
        .string()
        .required(name.required)
        .min(3, "O nome deve conter no mínimo 3 caracteres")
        .matches(/^(?!\s+$).*/g, "Nome inválido"),
    email: yup.string().trim().email(email.invalid).required(email.required),
});
