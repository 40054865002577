import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-document-detailed"}
    >
        <title>{title}</title>
        <path
            d="M6 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17V7.414C14.9999 7.1488 14.8946 6.89449 14.707 6.707L9.293 1.293C9.10551 1.10545 8.8512 1.00006 8.586 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V14M1 19L5.879 15.1037M5.879 15.1037C6.15671 15.386 6.48757 15.6106 6.85249 15.7644C7.21742 15.9182 7.60919 15.9983 8.0052 16C8.40122 16.0016 8.79365 15.9248 9.15985 15.7741C9.52605 15.6233 9.85878 15.4015 10.1388 15.1215C10.4189 14.8416 10.6408 14.5089 10.7916 14.1427C10.9425 13.7765 11.0193 13.3841 11.0177 12.9881C11.0162 12.5921 10.9362 12.2003 10.7825 11.8353C10.6287 11.4704 10.4043 11.1395 10.122 10.8617C9.55791 10.3066 8.79732 9.99687 8.00591 10C7.2145 10.0031 6.45638 10.3189 5.8967 10.8784C5.33702 11.438 5.02111 12.196 5.0178 12.9874C5.01448 13.7788 5.32402 14.5395 5.879 15.1037ZM4 4H8.0052M4 7H10.1388"
            stroke={color || theme.purple400}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
