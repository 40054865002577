import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 21px 64px;
    background-color: ${({ theme }) => theme.pink50};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const UserAddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray400};
    border-radius: 50%;
    min-width: 32px;
    min-height: 32px;

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const UsersLength = styled.div`
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    background-color: ${({ theme }) => theme.purple700};
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    max-width: 60%;
    gap: 20px;
`;
