import { styled } from "styled-components";

export const Container = styled.div<{ $error?: boolean }>`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    gap: 3px;
    margin-top: ${({ $error: error }) => error && "-16px"};
`;

export const Rule = styled.div<{ $color?: string }>`
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: ${({ theme, $color: color }) => color || theme.gray600};
    font-weight: 500;
    height: 22px;
`;

export const XIconContainer = styled.div`
    background-color: ${({ theme }) => theme.red};
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 3px;
    margin-right: 3px;
`;
