import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../../../components/atoms/menu/menu";
import { activities } from "../../../../../../../../../services";
import { TDeleteAttachment } from "../../../../../../../../../services/activities/delete-attachment";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { DeleteConfirmationModal } from "../../../../modals/delete-confirmation-general/delete-confirmation-general";
import { ActivityDTO } from "../../../../types/activityDTO";
import { EditAttachment } from "../edit-attachment/edit-attachment";
import { ImageFullScreen } from "../image-full-screen/image-full-screen";
import * as s from "./styles";

type TAttachmentRow = {
    activity: ActivityDTO;
    attachment: ActivityDTO["ActivityAttachments"][0];
};

export const AttachmentRow = ({ activity, attachment }: TAttachmentRow) => {
    const [editAttachment, setEditAttachment] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [imageIsValid, setImageIsValid] = useState(true);
    const [imageIsOpen, setImageIsOpen] = useState(false);

    const { mutate: deleteAttachment } = useMutation<
        unknown,
        unknown,
        TDeleteAttachment
    >({
        mutationFn: (params) => activities.deleteAttachment(params),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const openLink = (url: string) => {
        window.open(url, "_blank");
    };

    const options: TMenuAtom["options"] = [
        {
            label: "Abrir",
            action: (attachment: ActivityDTO["ActivityAttachments"][0]) => {
                openLink(attachment.attachment_url);
            },
            icon: <Icons.ExpandContent />,
            color: theme.gray800,
        },
        {
            label: "Copiar link",
            action: (attachment: ActivityDTO["ActivityAttachments"][0]) => {
                navigator.clipboard.writeText(attachment.attachment_url);
                toast.success("Link copiado com sucesso!");
            },
            icon: <Icons.Duplicate />,
            color: theme.gray800,
        },
        {
            label: "Editar",
            action: () => {
                setEditAttachment(true);
            },
            icon: <Icons.EditOutline color={theme.gray600} />,
            color: theme.gray800,
        },
        {
            label: "Excluir",
            action: () => {
                setDeleteConfirmation(true);
            },
            icon: <Icons.Trash color={theme.redError} />,
            color: theme.redError,
        },
    ];

    return (
        <>
            {deleteConfirmation && (
                <DeleteConfirmationModal
                    message="o anexo"
                    setOpen={setDeleteConfirmation}
                    onConfirm={() => {
                        deleteAttachment({
                            attachmentId: attachment.id_e,
                            activityId: activity.id_e,
                        });
                        setDeleteConfirmation(false);
                    }}
                />
            )}
            {imageIsOpen && (
                <ImageFullScreen
                    image={attachment.attachment_url}
                    setImageIsOpen={setImageIsOpen}
                    attachment={attachment}
                    activity={activity}
                />
            )}
            <s.Container>
                {imageIsValid ? (
                    <s.AttachmentImage
                        src={attachment.attachment_url}
                        onClick={() => {
                            setImageIsOpen(true);
                        }}
                        onError={() => {
                            setImageIsValid(false);
                        }}
                    />
                ) : (
                    <s.AttachmentIndicator
                        onClick={() => openLink(attachment.attachment_url)}
                    >
                        <Icons.AttachFile color={theme.gray400} />
                    </s.AttachmentIndicator>
                )}

                {editAttachment ? (
                    <EditAttachment
                        activity={activity}
                        attachment={attachment}
                        setEditAttachment={setEditAttachment}
                    />
                ) : (
                    <s.AttachmentInfos>
                        <s.FileName title={attachment.title}>
                            {attachment.title}
                        </s.FileName>
                        {attachment.type === "LINK" && (
                            <s.AttachmentLink
                                onClick={() =>
                                    openLink(attachment.attachment_url)
                                }
                                title={attachment.attachment_url}
                            >
                                {attachment.attachment_url}
                            </s.AttachmentLink>
                        )}
                        <s.AttachmentDescription>
                            {attachment.user.name} adicionou em{" "}
                            {dayjs(attachment.created_at).format(
                                "DD/MM/YYYY [às] HH:mm"
                            )}
                        </s.AttachmentDescription>
                    </s.AttachmentInfos>
                )}
                {!editAttachment && (
                    <MenuAtom options={options} data={attachment}>
                        <s.MoreButton>
                            <Icons.More />
                        </s.MoreButton>
                    </MenuAtom>
                )}
            </s.Container>
        </>
    );
};
