import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { TProject } from "../../../../../../../models";
import { projects } from "../../../../../../../services";
import {
    CreateProjectAdditionalCostParams,
    CreateProjectAdditionalCostResult,
} from "../../../../../../../services/projects/types";
import { formatToBRMoneyWithoutMoneySign } from "../../../../../../../utils";
import { Mixpanel } from "../../../../../../../utils/mixpanel/Mixpanel";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import { CostsFields, TabProps } from "../../../tab-costs";
import { costStringToNumber, resultToCost } from "../../../utils";
import { ModalUpsertAdditionalCost } from "../../modals/modal-upsert-additional-cost/modal-upsert-additional-cost";
import * as Table from "../../table/table";
import { FixedCostRow } from "./components/fixed-cost-row/fixed-cost-row";
import * as s from "./styles";

export const FixedCostsTab = ({
    data,
    date,
    isToCreate,
    orderByDate,
    setIsToCreate,
    setOrderByDate,
}: TabProps) => {
    if (!data) return null;

    const { state } = useLocation();
    const { project }: { project: TProject } = state || {};

    const createCost = useMutation<
        CreateProjectAdditionalCostResult[],
        unknown,
        CreateProjectAdditionalCostParams
    >({
        mutationKey: ["createProjectAdditionalCost"],
        mutationFn: projects.createProjectAdditionalCost,
        onSuccess: (result) => {
            const orderedData = orderBy(
                [
                    ...data.fixedCosts.data,
                    ...result
                        .filter((r) =>
                            dayjs(r.created_at).isSame(dayjs(date.end), "month")
                        )
                        .map((r) => resultToCost(r)),
                ],
                "date",
                orderByDate
            );
            const realizedCost = orderedData.reduce(
                (acc, { cost }) => acc + cost,
                0
            );
            queryClient.setQueryData(["readCosts"], {
                ...data,
                resume: {
                    ...data.resume,
                    total: {
                        ...data.resume.total,
                        fixedCost: {
                            ...data.resume.total.fixedCost,
                            realized: realizedCost,
                        },
                    },
                },
                fixedCosts: {
                    ...data.fixedCosts,
                    data: orderedData,
                    total: {
                        realizedCost,
                    },
                },
            });
            toast.success("Custo fixo criado com sucesso!");
            setIsToCreate(false);
            queryClient.invalidateQueries(["readCosts"]);
        },
    });

    const create = (fields: CostsFields) => {
        Mixpanel.track("Criar custo fixo");
        createCost.mutate({
            ...fields,
            date: fields.date?.toISOString(),
            type: "fixed",
            projectId: project.id_e,
            cost: costStringToNumber(fields.cost),
        });
    };

    return (
        <s.Container>
            <Table.Root>
                <Table.Header>
                    <Table.Head>Nome</Table.Head>
                    <Table.Head>Descrição</Table.Head>
                    <Table.Head
                        orderBy={orderByDate}
                        onClick={() =>
                            setOrderByDate((prev) =>
                                prev === "asc" ? "desc" : "asc"
                            )
                        }
                    >
                        Data
                    </Table.Head>
                    <Table.Head>Custo (R$)</Table.Head>
                    <Table.Head>Status</Table.Head>
                    <Table.Head></Table.Head>
                </Table.Header>
                <Table.Body>
                    {data.fixedCosts.data.map((row) => (
                        <FixedCostRow data={data} row={row} />
                    ))}
                    {data.fixedCosts.data.length === 0 && (
                        <Table.NoData>
                            Nenhum custo fixo encontrado.
                        </Table.NoData>
                    )}
                    {isToCreate && (
                        <ModalUpsertAdditionalCost
                            onSave={create}
                            onCancel={() => setIsToCreate(false)}
                            loading={createCost.isLoading}
                        />
                    )}
                </Table.Body>
                {data.fixedCosts.data.length > 0 && (
                    <Table.Total>
                        <Table.Data></Table.Data>
                        <Table.Data></Table.Data>
                        <Table.Data>
                            {formatToBRMoneyWithoutMoneySign(
                                data.fixedCosts.total.realizedCost || 0
                            )}
                        </Table.Data>
                        <Table.Data></Table.Data>
                        <Table.Data></Table.Data>
                    </Table.Total>
                )}
            </Table.Root>
        </s.Container>
    );
};
