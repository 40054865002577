import { Icons, theme } from "../../../../../../../../../assets";
import { Tooltip } from "../../../../../../../../../components";
import * as s from "./styles";

type CustomTooltipProps = {
    children: React.ReactNode;
};

export const CustomTooltip = ({ children }: CustomTooltipProps) => {
    return (
        <Tooltip
            side="top"
            align="end"
            arrow
            positionx={10}
            positiony={10}
            positionArrow={89}
            radius={8}
            content={<s.TooltipContainer>{children}</s.TooltipContainer>}
        >
            <s.InfoContainer>
                <Icons.Info width="18px" height="18px" color={theme.gray900} />
            </s.InfoContainer>
        </Tooltip>
    );
};
