import mixpanel, { Dict } from "mixpanel-browser";

const isTest = process.env.REACT_APP_ENV === "test";

if (process.env.REACT_APP_ENV !== "test") {
    mixpanel.init("b46ae399c1d7365abdfc96f31c9e208f", {
        debug: process.env.REACT_APP_ENV === "local",
        track_pageview: true,
        persistence: "localStorage",
    });
}

const envCheck = process.env.REACT_APP_ENV === "production";

const actions = {
    identify: (id: string | undefined) => {
        if (envCheck) mixpanel.identify(id);
    },
    alias: (id: string) => {
        if (envCheck) mixpanel.alias(id);
    },
    track: (name: string, props?: Dict) => {
        if (isTest) return;
        mixpanel.track(name, {
            ...props,
            env: process.env.REACT_APP_ENV,
        });
    },
    people: {
        set: (props: Dict) => {
            if (envCheck) mixpanel.people.set(props);
        },
    },
    time: (name: string) => {
        if (envCheck) mixpanel.time_event(name);
    },
};

export const Mixpanel = actions;
