import styled from "styled-components";

type TOption = {
    selected?: boolean;
};

type TCalendarTrigger = {
    alternativeInput?: boolean;
};

export const Container = styled.div`
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;

    ul#options-wrapper {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        border-right: 1px solid ${({ theme }) => theme.gray100};

        .option:first-child {
            padding: 10px 10px 5px;
        }

        .option:last-child {
            padding: 5px 10px 10px;
        }
    }

    div#calendar-wrapper {
        padding: 20px;
    }
`;

export const Option = styled.li<TOption>`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px 10px;
    width: 130px;
    cursor: pointer;

    span.option-text {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};
    }

    ${({ selected, theme }) =>
        selected
            ? `background: ${theme.pink50}; border-left: 2px solid ${theme.purple400}; padding: 5px 10px 5px 8px;`
            : `
    &:hover {
        background: ${theme.gray50}
    };`};
`;

export const CalendarTrigger = styled.div<TCalendarTrigger>`
    display: flex;
    flex-direction: row;
    height: 40px;
    border: 1px solid
        ${({ alternativeInput, theme }) =>
            alternativeInput ? theme.gray100 : theme.gray400};
    border-radius: ${({ alternativeInput }) =>
        alternativeInput ? "4px" : "32px"};
    width: fit-content;

    button#arrow-left {
        svg {
            transform: rotate(90deg);
        }
    }

    button#arrow-right {
        svg {
            transform: rotate(-90deg);
        }
    }

    button#arrow-left,
    button#arrow-right {
        display: flex;
        align-items: center;
        padding: 0 5px;
        height: 100%;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    div#time-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        gap: 10px;
        padding: 0 10px;
        ${({ alternativeInput, theme }) =>
            !alternativeInput &&
            `border-right: 1px solid ${theme.gray400}; border-left: 1px solid ${theme.gray400};`};

        span#period {
            font-size: 1rem;
            font-weight: 500;
            color: ${({ theme }) => theme.gray800};
        }
    }
`;
