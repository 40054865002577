import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import dayjs from "dayjs";

import { TabProps } from "../../paperon-data";
import * as s from "./styles";

export function DetailsTab({ data }: TabProps) {
    const formatDateWithHour = (date: string) => {
        if (!date) return "";
        return dayjs(date).format("DD/MM/YYYY HH:mm");
    };

    const lastCreatedOrganizationDetailsColumns: GridColDef[] = [
        { field: "email", headerName: "E-mail", flex: 1 },
        { field: "name", headerName: "Nome", flex: 1 },
        {
            field: "inviteQuantity",
            headerName: "Quantidade de convites",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Data de criação",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "origin",
            headerName: "Origem",
            flex: 1,
        },
        {
            field: "firstEntryAt",
            headerName: "Primeira entrada de tempo",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "phone",
            headerName: "Telefone",
            flex: 1,
        },
        {
            field: "lastLoginAt",
            headerName: "Último acesso do dono",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "google",
            headerName: "Conta Google",
            flex: 1,
            valueFormatter: ({ value }) => (value ? "Sim" : "Não"),
        },
    ];

    const inactiveOrganizationsEmailsSentColumns: GridColDef[] = [
        {
            field: "organization_email",
            headerName: "E-mail da organização",
            flex: 1,
        },
        { field: "owner_email", headerName: "E-mail do dono", flex: 1 },
        { field: "name", headerName: "Nome", flex: 1 },
        {
            field: "created_at",
            headerName: "Data de criação",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "owner_last_login",
            headerName: "Último acesso do dono",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "sent_at",
            headerName: "Enviado em",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
    ];

    return (
        <s.Container>
            {data.lastCreatedOrganizationsDetails.length > 0 && (
                <s.TableWrapper>
                    <s.Title>
                        Últimas organizações criadas e suas informações
                    </s.Title>
                    <DataGrid
                        columns={lastCreatedOrganizationDetailsColumns}
                        rows={data.lastCreatedOrganizationsDetails}
                        pageSizeOptions={[10, 20]}
                        localeText={
                            ptBR.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                </s.TableWrapper>
            )}

            <s.TableWrapper>
                <s.Title>E-mails enviados para organizações inativas</s.Title>
                <DataGrid
                    columns={inactiveOrganizationsEmailsSentColumns}
                    rows={data.inactiveOrganizationsEmailsSent}
                    pageSizeOptions={[10, 20]}
                    localeText={
                        ptBR.components.MuiDataGrid.defaultProps.localeText
                    }
                />
            </s.TableWrapper>
        </s.Container>
    );
}
