import * as RadixTabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";

import { LoadingGray } from "../../../../components/atoms";
import { useFetch } from "../../../../hooks";
import { TOrganization } from "../../../../models";
import { organizations } from "../../../../services";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-tabs-organization";
import { TabAdministrators, TabProfile } from "./tabs";
import { TabSettings } from "./tabs/tab-settings/tab-settings";

export const TabsOrganization = () => {
    const [orgData, setOrgData] = useState<TOrganization>({});

    const getOrganization = useFetch({
        fn: organizations.getOrganization,
    });

    useEffect(() => {
        if (getOrganization.response) {
            const organization = getOrganization.response.organizations[0];
            const { canDelete } = getOrganization.response;
            setOrgData({ ...organization, canDelete });
        }
    }, [getOrganization.response]);

    return (
        <s.Container>
            <RadixTabs.Root
                className="profile-root"
                defaultValue="profile"
                orientation="vertical"
                onValueChange={(value) => {
                    Mixpanel.track("Aba acessada", {
                        page: "perfil da organização",
                        tab: value,
                    });
                }}
            >
                <RadixTabs.List
                    aria-label="tabs example"
                    className="profile-tabs"
                >
                    <RadixTabs.Trigger value="profile">
                        Perfil / Dados
                    </RadixTabs.Trigger>

                    <RadixTabs.Trigger value="settings">
                        Configurações
                    </RadixTabs.Trigger>

                    <RadixTabs.Trigger value="administrators">
                        Administradores
                    </RadixTabs.Trigger>
                </RadixTabs.List>

                <s.TabSubtitles />

                <s.Content>
                    {getOrganization.loading ? (
                        <LoadingGray />
                    ) : (
                        <>
                            <RadixTabs.Content value="profile">
                                <TabProfile
                                    orgData={orgData}
                                    setOrgData={setOrgData}
                                    getOrgOnRefresh={getOrganization.onRefresh}
                                />
                            </RadixTabs.Content>

                            <RadixTabs.Content value="administrators">
                                <TabAdministrators
                                    orgData={orgData}
                                    setOrgData={setOrgData}
                                    getOrganization={getOrganization}
                                />
                            </RadixTabs.Content>

                            <RadixTabs.Content value="settings">
                                <TabSettings
                                    orgData={orgData}
                                    setOrgData={setOrgData}
                                />
                            </RadixTabs.Content>
                        </>
                    )}
                </s.Content>
            </RadixTabs.Root>
        </s.Container>
    );
};
