import * as s from "../styles";

type StatusProps = {
    children: React.ReactNode;
    active?: boolean;
};

export const Status = ({ active, children }: StatusProps) => {
    return <s.Status active={active}>{children}</s.Status>;
};
