import styled from "styled-components";

export const Container = styled.form`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 664px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;
