import { Icons, theme } from "../../../../../../assets";
import { plansMappers } from "../../../../mappers";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const Plans = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Membership color={theme.purple500} />
                Planos
            </generalStyles.Title>
            <generalStyles.Row>
                {data.plansNumbers.map(({ plan, number }) => (
                    <generalStyles.Content key={plan}>
                        <h1>{plansMappers[plan]}</h1>
                        <p>
                            <span>{number}</span>
                        </p>
                    </generalStyles.Content>
                ))}
            </generalStyles.Row>
            <generalStyles.Row>
                <generalStyles.Content>
                    <h1>Número de planos mensais</h1>
                    <p>
                        <span>{data.numberOfMonthlyPlans}</span>
                    </p>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>Número de planos anuais</h1>
                    <p>
                        <span>{data.numberOfAnnualPlans}</span>
                    </p>
                </generalStyles.Content>
            </generalStyles.Row>
        </s.Container>
    );
};
