import { styled } from "styled-components";

export const RequestMoreHours = styled.div`
    width: 210px;
`;

export const RequestMoreHoursTooltip = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding: 5px 10px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
`;
