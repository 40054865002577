import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 27px;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }
`;

export const AttachmentInfos = styled.div`
    max-width: 346px;
    width: 100%;
`;

export const AttachmentImage = styled.img`
    width: 148px;
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
`;

export const FileName = styled.div`
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const AttachmentDescription = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const AttachmentLink = styled.p`
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
`;

export const AttachmentIndicator = styled.div`
    padding: 27px 62px;
    background-color: ${({ theme }) => theme.gray100};
    width: 148px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const MoreButton = styled.button`
    width: 32px;
    height: 32px;
`;
