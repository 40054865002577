import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TChangeCard = {
    newCreditCard?: string;
    contractorName?: string;
};

export default async ({ newCreditCard, contractorName }: TChangeCard = {}) => {
    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/changeCreditCard`,
        method: "patch",
        headers: tempHeaders,
        body: {
            newCreditCard,
            contractorName,
        },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
