import {
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../components/atoms";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { activationTexts, inactivationTexts } from "./constants";
import * as s from "./styled-toggle-account-activation-modal";

type TToggleAccountActivationModal = {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
    isActive?: boolean;
};

export const ToggleAccountActivationModal = ({
    onOpenChange,
    onConfirm,
    isActive,
}: TToggleAccountActivationModal) => {
    const chooseTexts = () => {
        if (isActive) {
            return inactivationTexts;
        }
        return activationTexts;
    };
    return (
        <ContainerModal closeIcon onOpenChange={onOpenChange}>
            <s.Container>
                <s.Title>{chooseTexts().title}</s.Title>
                <s.Description>
                    {chooseTexts().firstDescriptionParagraph}
                </s.Description>
                <s.Description>
                    {chooseTexts().secondDescriptionParagraph}
                </s.Description>
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => {
                            onOpenChange(false);
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton onClick={onConfirm}>
                        {chooseTexts().buttonLabel}
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
