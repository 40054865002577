import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { ReadProjectCostsParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    projectId,
    startDate,
    endDate,
    orderByDate,
}: ReadProjectCostsParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/cost`,
        method: "get",
        headers,
        params: {
            startDate,
            endDate,
            orderByDate,
        },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
