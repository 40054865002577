import React, { useState, SetStateAction } from "react";
import Switch from "react-switch";

import { theme } from "../../../assets";
import { Icons } from "../../../assets/icons";
import * as s from "./styled-paper";

type TPaper = {
    title: string;
    myTheme: string | null;
    setMyTheme: React.Dispatch<SetStateAction<string | null>>;
    isWhite?: boolean;
    onlyToggl?: boolean;
    onClick?: () => void;
};

const Paper = ({ title, myTheme, setMyTheme, onlyToggl, onClick }: TPaper) => {
    const [togglState, setTogglState] = useState(false);

    const changeTheme = () => {
        if (myTheme === "light") {
            localStorage.removeItem("tema");
            localStorage.setItem("tema", "dark");
            setMyTheme(localStorage.getItem("tema"));
        } else {
            localStorage.removeItem("tema");
            localStorage.setItem("tema", "light");
            setMyTheme(localStorage.getItem("tema"));
        }
    };

    return (
        <s.FlexRow
            onClick={onClick}
            style={{
                cursor: onClick ? "pointer" : "default",
            }}
        >
            {!onlyToggl && (
                <span>
                    <Icons.Paper title={title} />
                </span>
            )}

            <s.ContainerToggl
                onClick={() => {
                    setTogglState(!togglState);
                }}
            >
                <Switch
                    width={20}
                    height={6}
                    handleDiameter={12}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={theme.pink50}
                    offColor={theme.pink50}
                    offHandleColor={theme.purple500}
                    onHandleColor={theme.purple500}
                    checked={true}
                    onChange={() => changeTheme()}
                />
            </s.ContainerToggl>

            {!onlyToggl && (
                <span>
                    <Icons.N title={title} />
                </span>
            )}
        </s.FlexRow>
    );
};

export default Paper;
