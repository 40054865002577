/* eslint-disable no-nested-ternary */
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import Select from "react-select";

import { IlustracaoTelaVazia } from "../../assets";
import {
    LoadingGray,
    PopoverCalendar,
    MyReportsOptions,
    Tag,
} from "../../components";
import { useFetch } from "../../hooks";
import { track } from "../../services";
import { minutesToStringDate } from "../../utils";
import * as s from "./styled-report-detailed";

export type TActivity = {
    description: string;
    total_time: number;
    start_at: string;
    end_at: string;
    Project: {
        id_e: string;
        color: string;
        name: string;
        Client: { name: string };
    };
};

type TProjectsOptions = {
    label: string;
    value: string;
};

export const ReportDetailed = () => {
    const [activities, setActivities] = useState<TActivity[]>([]);
    const [totalTime, setTotalTime] = useState<string>();
    const [filter, setFilter] = useState<TProjectsOptions>({
        label: "Todos",
        value: "all",
    });
    const [period, setPeriod] = useState<DateRange>({
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
    });

    const getEntries = useFetch({
        fn: track.getEntries,
        params: {
            startDate: startOfMonth(new Date()).toString(),
            endDate: endOfMonth(new Date()).toString(),
        },
    });

    const projectsOptions: TProjectsOptions[] = [
        { label: "Todos", value: "all" },
        ...(getEntries?.response?.user_projects_with_entries?.map(
            (project: { name: string; id_e: string }) => ({
                label: project.name,
                value: project.id_e,
            })
        ) ?? []),
    ];

    useEffect(() => {
        if (getEntries.response) {
            const { entries } = getEntries.response;
            const tempTotalTime = entries.reduce(
                (acc: number, entry: { total_time: number }) => {
                    return acc + entry.total_time;
                },
                0
            );
            setActivities(entries);
            setTotalTime(minutesToStringDate(tempTotalTime));
        }
    }, [getEntries.response]);

    const getSummaryWithFilter = (project: TProjectsOptions) => {
        if (project.value === "all") {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                page: 0,
            });
        } else {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                page: 0,
                projectId: project.value,
            });
        }
    };

    const getPeriod = (period: DateRange | undefined) => {
        getEntries.onRefresh({
            startDate: period?.from?.toString(),
            endDate: period?.to?.toString(),
        });

        setPeriod({
            from: period?.from,
            to: period?.to,
        });
    };

    const renderEntries = () => {
        if (activities.length > 0)
            return (
                <s.ActivitiesTable>
                    <thead>
                        <tr>
                            <th>
                                <span className="heading-text">ENTRADA</span>
                            </th>
                            <th>
                                <span className="heading-text">PROJETO</span>
                            </th>
                            <th>
                                <span className="heading-text">CLIENTE</span>
                            </th>
                            <th>
                                <span className="heading-text">DURAÇÃO</span>
                            </th>

                            <th>
                                <span className="heading-text">TEMPO</span>
                            </th>

                            <th>
                                <span className="heading-text">DATA</span>
                            </th>
                        </tr>
                    </thead>

                    {activities?.map((activity, index) => (
                        <tbody key={index}>
                            <tr className="activity">
                                <td className="entry">
                                    <span className="name">
                                        {activity.description}
                                    </span>
                                </td>
                                <td>
                                    <Tag
                                        color={activity.Project.color}
                                        maxWidth="9vw"
                                    >
                                        {activity.Project.name}
                                    </Tag>
                                </td>
                                <td>
                                    <span className="client">
                                        {activity.Project.Client.name}
                                    </span>
                                </td>
                                <td>
                                    <span className="duration">
                                        {minutesToStringDate(
                                            activity.total_time
                                        )}
                                    </span>
                                </td>

                                <td>
                                    <span className="time">
                                        {activity.start_at
                                            ? format(
                                                  parseISO(
                                                      activity.start_at.slice(
                                                          0,
                                                          -1
                                                      )
                                                  ),
                                                  "HH:mm"
                                              )
                                            : "?"}{" "}
                                        -{" "}
                                        {activity.end_at
                                            ? format(
                                                  parseISO(
                                                      activity.end_at.slice(
                                                          0,
                                                          -1
                                                      )
                                                  ),
                                                  "HH:mm"
                                              )
                                            : "?"}
                                    </span>
                                </td>

                                <td>
                                    <span className="date">
                                        {format(
                                            parseISO(
                                                activity.start_at.slice(0, -1)
                                            ),
                                            "dd/MM/yyyy"
                                        )}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </s.ActivitiesTable>
            );
        return (
            <s.NoContent>
                <IlustracaoTelaVazia />
                <h2>Sem registro de dados a serem mostrados</h2>
                <p>Vá ao cronômetro e faça o registro das horas trabalhadas!</p>
            </s.NoContent>
        );
    };

    return (
        <s.Container>
            <MyReportsOptions />

            <s.Details>
                <header id="details">
                    <PopoverCalendar getPeriod={getPeriod} />

                    {activities.length > 0 && (
                        <div id="time">
                            <span id="text-time">TEMPO TOTAL</span>

                            <span id="total-time">{totalTime}</span>
                        </div>
                    )}
                </header>

                <div id="filters">
                    <Select
                        options={projectsOptions}
                        placeholder="Projetos"
                        styles={s.SelectStyles}
                        value={filter}
                        isDisabled={getEntries.loading}
                        onChange={(value) => {
                            if (value) {
                                setFilter(value);
                                getSummaryWithFilter(value);
                            }
                        }}
                    />
                </div>

                {getEntries.loading && activities.length === 0 ? (
                    <LoadingGray />
                ) : activities.length > 0 ? (
                    renderEntries()
                ) : (
                    <s.NoContent>
                        <IlustracaoTelaVazia />
                        <h2>Sem registro de dados a serem mostrados</h2>
                        <p>
                            Vá ao cronômetro e faça o registro das horas
                            trabalhadas!
                        </p>
                    </s.NoContent>
                )}
            </s.Details>
        </s.Container>
    );
};
