import { Dispatch, SetStateAction } from "react";

import { Icons, theme } from "../../../../assets";
import { minutesToStringDate } from "../../../../utils";
import { PrimaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-hour-limit-reached";

type THourLimitReachedModal = {
    entryDuration: number;
    limitLeft: number;
    setOpen: Dispatch<SetStateAction<boolean>>;
    hourLimit: boolean;
};

export const HourLimitReachedModal = ({
    entryDuration,
    limitLeft,
    setOpen,
    hourLimit,
}: THourLimitReachedModal) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <s.Close onClick={() => setOpen(false)}>
                    <Icons.XSolid color={theme.gray600} />
                </s.Close>
                <s.Header>
                    <Icons.ExclamationOutline />
                    {hourLimit
                        ? `Limite de horas atingido`
                        : `Horas de projeto atingidas`}
                </s.Header>
                <s.Content>
                    <s.Text>
                        {hourLimit
                            ? `A duração da atividade não pode ultrapassar o saldo do
                        limite de horas.`
                            : `A duração da atividade não pode ultrapassar as horas pendentes do projeto.`}
                    </s.Text>
                    <div>
                        <s.Text>
                            <s.Bold>{minutesToStringDate(limitLeft)}</s.Bold>
                            {hourLimit
                                ? ` Saldo
                        do limite de horas`
                                : `Horas pendentes do projeto`}
                        </s.Text>
                        <s.Text danger>
                            <s.Bold>
                                {minutesToStringDate(entryDuration)}
                            </s.Bold>{" "}
                            Tempo excedente não aprovado
                        </s.Text>
                    </div>
                </s.Content>
                <s.Content>
                    <s.HoursToApply>
                        <s.Text>
                            Serão aplicadas{" "}
                            <s.Bold>{minutesToStringDate(limitLeft)}</s.Bold>
                        </s.Text>
                        <s.Text>nesta atividade</s.Text>
                    </s.HoursToApply>
                </s.Content>
                <s.Content>
                    <s.Text>
                        Caso necessário, solicite mais horas para o seu gestor.
                    </s.Text>
                </s.Content>
                <PrimaryButton onClick={() => setOpen(false)}>
                    ENTENDI
                </PrimaryButton>
            </s.Container>
        </ContainerModal>
    );
};
