import { TConvertSketches } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ sketchIds }: TConvertSketches = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/convertSketchesToInvite`,
        method: "post",
        headers: { auth: true },
        body: { sketchIds },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro! campos obrigatórios não preenchidos",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
