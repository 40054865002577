import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Input } from "../../../../components";
import ContainerModal from "../../../../components/molecules/modals/container-modal";
import { getCurrentMonthAndYear } from "../../../../utils";
import * as s from "./styled-modal-edit-collaborators-hours";

type TModalEditUseCase = {
    selectedCollaboratorsQuantity: number;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setHours: (hours: string) => void;
};

type TFormInputs = {
    hours: number;
};

const ModalEditCollaboratorsHours = ({
    selectedCollaboratorsQuantity,
    setHours,
    setOpen,
}: TModalEditUseCase) => {
    const { register, watch } = useForm<TFormInputs>({
        defaultValues: {
            hours: 0,
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!watch("hours")) {
            toast.error("Preencha o campo de horas para continuar");
            return;
        }
        setHours(String(watch("hours")));
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <form onSubmit={handleSubmit}>
                    <header>Definir horas previstas</header>
                    <section>
                        <div>
                            Digite o número de horas a serem trabalhadas no mês
                            vigente: <strong>{getCurrentMonthAndYear()}</strong>
                        </div>
                        <div className="inputButton">
                            <Input
                                className="input"
                                {...register("hours")}
                                placeholder="0"
                                mask="999"
                            />
                        </div>
                        <div>
                            Deseja definir para todos os profissionais
                            selecionados ({selectedCollaboratorsQuantity}) ?
                        </div>
                    </section>
                    <footer>
                        <div
                            className="cancelButton"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            CANCELAR
                        </div>
                        <button className="confirmButton" type="submit">
                            CONFIRMAR
                        </button>
                    </footer>
                </form>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalEditCollaboratorsHours;
