import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { score } from "../../../../services";
import { LoadingGray, PrimaryButton, TextArea } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-nps";
import { schema1, schema2 } from "./validation";

type TModalNPS = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    question?: string;
};

export type TFields = {
    rating?: number;
    comment?: string;
    noAnswer: boolean;
};

const ModalNPS = ({ setOpen, question }: TModalNPS) => {
    const { user, setUser } = useUser();
    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const schema = shouldValidate ? schema1 : schema2;

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<TFields>({
        resolver: yupResolver(schema),
    });

    const submitScore = useFetch({
        fn: score.submitScore,
        start: false,
    });

    const handleUserParticipation = () => {
        const updatedUser = {
            ...user,
            netPromoterScore: {
                ...user.netPromoterScore,
                userParticipation: true,
            },
        };
        setUser(updatedUser);
    };

    const onSubmit = (data: TFields) => {
        setLoading(true);
        if (shouldValidate) {
            submitScore.onRefresh({
                rating: data.rating,
                comment: data.comment,
                noAnswer: false,
            });
        } else {
            submitScore.onRefresh({
                noAnswer: true,
            });
        }

        handleUserParticipation();
    };

    useEffect(() => {
        if (submitScore.error) {
            toast.error(`${submitScore.error?.message}`);
            setLoading(false);
            setOpen(false);
        }
        if (submitScore.response) {
            if (shouldValidate) {
                toast.success("Resposta enviada com sucesso!");
            }
            setLoading(false);
            setOpen(false);
        }
    }, [submitScore.response]);

    return (
        <ContainerModal onOpenChange={setOpen} closeOnIconOnly>
            <s.Container onSubmit={handleSubmit(onSubmit)}>
                {loading ? (
                    <div id="loading-nps">
                        <LoadingGray />
                    </div>
                ) : (
                    <>
                        <h1>Avaliação</h1>
                        <s.RadixClose
                            onClick={() => {
                                setShouldValidate(false);
                                handleSubmit(onSubmit);
                            }}
                        >
                            <Icons.XSolid color={theme.gray200} />
                        </s.RadixClose>

                        {/* <s.Rating>
                            <span className="field-label">
                                {question ||
                                    "Avalie de 1 a 10 sua experiência com o PaperON"}
                            </span>

                            <div id="rates">
                                {[...Array(10).keys()].map((number) => (
                                    <s.Rate
                                        onClick={() =>
                                            setValue("rating", number + 1)
                                        }
                                        selected={
                                            watch("rating") === number + 1
                                        }
                                        type="button"
                                    >
                                        {number + 1}
                                    </s.Rate>
                                ))}
                            </div>

                            <div id="tips">
                                <span>Não gostei</span>

                                <span style={{ color: theme.redError }}>
                                    {errors.rating?.message}
                                </span>

                                <span>Gostei muito</span>
                            </div>
                        </s.Rating> */}

                        <s.TextAreaWrapper>
                            <span className="field-label">
                                Estamos sempre buscando melhorar sua experiência
                                com o PaperOn! Nos ajude fornecendo seu
                                feedback. Sua opinião é fundamental para nosso
                                desenvolvimento contínuo.
                            </span>

                            <TextArea
                                {...register("comment")}
                                placeholder="Se você pudesse mudar uma coisa no PaperOn para
                                torná-lo ainda melhor, o que seria?"
                                charCount
                                counter={watch("comment") || ""}
                                maxLength={1000}
                                error={errors.comment?.message || ""}
                                className="text-area"
                            />
                        </s.TextAreaWrapper>

                        <div id="button">
                            <PrimaryButton
                                type="submit"
                                onClick={() => setShouldValidate(true)}
                            >
                                ENVIAR ANONIMAMENTE
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </s.Container>
        </ContainerModal>
    );
};

export default ModalNPS;
