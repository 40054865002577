import { yupResolver } from "@hookform/resolvers/yup";
import { delay } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { subscriptions } from "../../../../services";
import { ContactForBusinessPlan } from "../../../../services/subscriptions";
import { DEFAULT_TRIAL_DAYS } from "../../../../utils/constants/free-days";
import { Input } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-business-contact";
import validation from "./validation";

type TModalBusinessContact = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    type?: "homePage" | "insideSystem";
};

const ModalBusinessContact = ({ setOpen, type }: TModalBusinessContact) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        register,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            celphone: "",
            numberOfUsers: "",
            message: "",
        },
        resolver: yupResolver(validation),
    });

    const ContactForBusinessPlan = useFetch({
        fn: subscriptions.ContactForBusinessPlan,
        start: false,
    });

    const onSubmit = handleSubmit((data) => {
        ContactForBusinessPlan.onRefresh({
            name: data.name,
            email: data.email,
            phone: data.celphone,
            numberOfUsers: data.numberOfUsers,
            message: data.message,
        });
    });

    useEffect(() => {
        if (ContactForBusinessPlan.response) {
            toast.success("Mensagem enviada com sucesso");
        }
    }, [ContactForBusinessPlan.response]);

    return (
        <ContainerModal
            backgroundColor={theme.gray50}
            onOpenChange={setOpen}
            closeOnIconOnly
            closeIconColor={theme.gray600}
        >
            {isLoading ? (
                <s.SuccessContainer>
                    <header>
                        <h1></h1>
                        <Icons.XSolid
                            color={theme.purple500}
                            onClick={() => setOpen(false)}
                        />
                    </header>
                    <section>
                        <Icons.IconCheckCircle2
                            color="#2E7D32"
                            onClick={() => setOpen(false)}
                            width="60px"
                            height="60px"
                        />
                        <h1>Mensagem enviada com sucesso</h1>
                        <p>
                            Um dos nossos especialistas entrará em contato o
                            mais breve possível para falar sobre o seu plano.
                        </p>
                        {type === "homePage" && (
                            <>
                                <p id="paperonExperience">
                                    Enquanto, experimente o PaperON:
                                    <button
                                        onClick={() => {
                                            navigate("/cadastro");
                                            setOpen(false);
                                        }}
                                    >
                                        TESTE {DEFAULT_TRIAL_DAYS} DIAS GRÁTIS
                                    </button>
                                </p>
                            </>
                        )}
                    </section>
                </s.SuccessContainer>
            ) : (
                <s.Container>
                    <header>
                        <h1>Entrar em contato</h1>
                        <Icons.XSolid
                            color={theme.purple500}
                            onClick={() => setOpen(false)}
                        />
                    </header>
                    <form onSubmit={onSubmit}>
                        <div id="nameInput">
                            <span>Nome</span>
                            <Input
                                mask=""
                                placeholder="Nome"
                                {...register("name")}
                                error={errors?.name?.message}
                            />
                            <span className="error">{}</span>
                        </div>
                        <div id="emailInput">
                            <span>E-mail</span>
                            <Input
                                mask=""
                                placeholder="E-mail"
                                {...register("email")}
                                error={errors?.email?.message}
                            />
                        </div>
                        <div id="celphoneInput">
                            <span>Telefone (opcional)</span>
                            <Input
                                mask="99 (99) 99999-9999"
                                placeholder="Telefone"
                                {...register("celphone")}
                                error={errors?.celphone?.message}
                            />
                        </div>
                        <div id="numberOfUsersInput">
                            <span>Número de usuários</span>
                            <Input
                                mask="999999999"
                                placeholder="Número de usuários"
                                {...register("numberOfUsers")}
                                error={errors?.numberOfUsers?.message}
                            />
                        </div>
                        <div id="messageInput">
                            <span>Mensagem (opcional)</span>
                            <textarea
                                placeholder="Nos conte mais sobre suas necessidades para o plano"
                                maxLength={3000}
                                {...register("message")}
                            />
                        </div>
                    </form>
                    <footer>
                        <button
                            onClick={() => {
                                setIsLoading(true);
                                onSubmit();
                            }}
                            id="submit"
                        >
                            ENVIAR MENSAGEM{" "}
                            <Icons.SendFilled width="20px" height="20px" />
                        </button>
                        <button id="cancel" onClick={() => setOpen(false)}>
                            CANCELAR
                        </button>
                    </footer>
                </s.Container>
            )}
        </ContainerModal>
    );
};

export default ModalBusinessContact;
