import { theme } from "..";

export default () => (
    <svg
        width="96"
        height="70"
        viewBox="0 0 96 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.5" y="12.5" width="95" height="57" rx="1.5" fill="#FDF1FE" />
        <line
            x1="11"
            y1="49"
            x2="34"
            y2="49"
            stroke={theme.gray200}
            strokeWidth="6"
            strokeLinecap="round"
        />
        <line
            x1="10.9922"
            y1="27.5"
            x2="48.0028"
            y2="27.5"
            stroke={theme.purple400}
            strokeWidth="10"
            strokeLinecap="round"
        />
        <line
            x1="11"
            y1="60"
            x2="45"
            y2="60"
            stroke={theme.gray200}
            strokeWidth="6"
            strokeLinecap="round"
        />
        <path d="M76.0644 0L66.3242 12L85.8047 12L76.0644 0Z" fill="#FDF1FE" />
        <rect
            x="0.5"
            y="12.5"
            width="95"
            height="57"
            rx="1.5"
            stroke="#F6EDFF"
        />
    </svg>
);
