import { styled } from "styled-components";

export const Container = styled.div`
    margin-top: 48px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
`;

export const Subtitle = styled.h3`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.03px;
`;
