import { useQuery } from "@tanstack/react-query";

import { gamefication } from "../services";
import { ReadDailyMissionResult } from "../services/gamefication/types";

export const useDailyMission = () => {
    const { data: daily, refetch } = useQuery<ReadDailyMissionResult>({
        queryKey: ["daily"],
        queryFn: gamefication.readDaily,
    });

    return {
        daily,
        refetch,
    };
};
