import styled from "styled-components";

import { theme } from "../../../assets";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    gap: 3.125rem;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray200};

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        div {
            color: rgba(0, 0, 0, 0.87);
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0.024px;
        }

        button {
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.purple600};
            color: var(--color-brand-purple-600, #503479);
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.12px;
            text-transform: uppercase;

            display: flex;
            width: 93px;
            height: 40px;
            padding: 14px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    section {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: baseline;
        gap: 20px;

        div.planInformation {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: baseline;
            width: 100%;

            div {
                color: ${({ theme }) => theme.gray600};
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                width: 100%;
            }

            span {
                color: ${({ theme }) => theme.gray800};
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.08px;
                width: 100%;
                white-space: nowrap;
            }

            span.charge {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                div {
                    color: ${({ theme }) => theme.gray800};
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.08px;
                }
                span {
                    color: #2e7d32;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0.15px;
                    text-align: right;
                }
            }
        }

        div#totalValue {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            border-top: 1px solid ${({ theme }) => theme.gray200};
            padding-top: 20px;

            div {
                color: ${({ theme }) => theme.gray800};
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.08px;
            }

            p {
                color: ${({ theme }) => theme.gray800};
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.08px;

                span {
                    color: ${({ theme }) => theme.gray600};
                    text-align: right;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        button#active {
            display: flex;
            width: 100%;
            height: 40px;
            padding: 14px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            color: white;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.purple600};
            background: ${({ theme }) => theme.purple600};
        }

        button#inactive {
            display: flex;
            width: 100%;
            height: 40px;
            padding: 14px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            color: white;
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.gray200};
            background: ${({ theme }) => theme.gray200};
        }
    }

    footer {
        align-self: stretch;
        color: ${({ theme }) => theme.gray600};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        a {
            color: ${({ theme }) => theme.purple600};
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-decoration-line: underline;
        }
    }
`;
