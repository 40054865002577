import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    gap: 1rem;

    span#modal-title {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
    }

    span#modal-description {
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray800};
    }

    div#buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.5rem;

        button {
            width: fit-content;
            padding: 0 1.5rem;
        }
    }
`;
