import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const Tooltip = styled.div`
    padding: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.gray800};
`;
