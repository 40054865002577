import { useNavigate, Location } from "react-router-dom";

import { Icons } from "../../../assets";
import * as s from "./styled-breadcrumb";

type TBreadcrumb = {
    defaultRoute: string;
    location: Location;
};

const Breadcrumb = ({ defaultRoute, location }: TBreadcrumb) => {
    const navigate = useNavigate();

    const formatLocation = () => {
        const locationPath = location.pathname.split("/");
        return locationPath.filter((item) => item);
    };

    const verifyItemName = (item: string) => {
        if (item === "organizacao") return "organização";

        const parts: string[] = item.split("-");
        const upperCaseParts: string[] = parts.map(
            (part: string) =>
                part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        const formattedText: string = upperCaseParts.join(" ");

        return formattedText;
    };

    return (
        <s.Container>
            <div id="arrow" onClick={() => navigate(-1)}>
                <Icons.ArrowBack />
            </div>
            {formatLocation().map((item, index) => (
                <li
                    key={index}
                    onClick={() => {
                        const pathName = formatLocation().slice(0, index + 1);
                        if (index === 0) return navigate(defaultRoute);

                        return navigate(`/${pathName.join("/")}`);
                    }}
                >
                    <s.OptionText active={formatLocation().at(-1) === item}>
                        {verifyItemName(item)}
                    </s.OptionText>
                </li>
            ))}
        </s.Container>
    );
};

export default Breadcrumb;
