import styled from "styled-components";

type TOptionText = {
    active?: boolean;
};

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    gap: 1.5rem;
`;

export const UpperOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 3.125rem;

    button {
        padding: 0.5rem 1rem;
    }
`;

export const BottomOptions = styled.div`
    display: flex;

    #input-div {
        width: 50%;
    }
`;

export const Options = styled.div`
    display: flex;
    gap: 50px;

    div.option {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;

        :hover {
            svg path {
                fill: ${({ theme }) => theme.purple600};
                stroke: ${({ theme }) => theme.purple600};
            }

            span {
                color: ${({ theme }) => theme.purple600};
            }
        }
    }
`;

export const OptionText = styled.span<TOptionText>`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ active, theme }) =>
        active ? `${theme.purple600}` : `${theme.gray400}`};
`;
