import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";
import { FinishEntryVariables } from "./types/finish-entry";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    endAt,
    description,
    project,
    sectorId,
    activityId,
}: FinishEntryVariables = {}) => {
    const tempHeaders = {
        auth: true,
        "x-project-id": project,
        "x-sector-id": sectorId,
        "x-activity-id": activityId,
    };

    if (!project) delete tempHeaders["x-project-id"];
    if (!sectorId) delete tempHeaders["x-sector-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/stop`,
        method: "patch",
        body: { end_at: endAt, description },
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorsMapper = {
        "It's impossible to do this, you've exceeded the hour limit":
            "Você excedeu o limite de horas",
        "startAt must be before EndAt!":
            "A data de início deve ser anterior a data de fim",
        "It's impossible to do this, you've exceeded the project hour limit":
            "Você excedeu o limite de horas do projeto",
        "Activity is archived":
            "Você não pode lançar tempo para uma atividade arquivada!",
        "impossible to do this to an canceled project!":
            "Você não pode lançar entradas de tempo para um projeto cancelado.",
        "impossible to do this to an paused project!":
            "Você não pode lançar entradas de tempo para um projeto pausado.",
        "impossible to do this to an finished project!":
            "Você não pode lançar entradas de tempo para um projeto finalizado.",
    };
    const error = findErrorString<keyof typeof errorsMapper>(body);

    if (!error || body === "startAt must be before EndAt!") {
        const errorFeedBacks = {
            400: "Você não tem uma entrada de tempo aberta",
            409: "Você já possui uma entrada de tempo nesse período!",
        };

        return handleStatusCases({ body, statusCode, errorFeedBacks });
    }

    if (
        error ===
        "It's impossible to do this, you've exceeded the project hour limit"
    ) {
        const errorFeedBacks = {
            400: "Você excedeu o limite de horas do projeto",
            409: "Você já possui uma entrada de tempo nesse período!",
        };

        return handleStatusCases({ body, statusCode, errorFeedBacks });
    }

    const errorFeedBacks = {
        400: errorsMapper[error] ?? "Você não tem uma entrada de tempo aberta",
        409: "Você já possui uma entrada de tempo nesse período!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
