import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TAnswerHowYouKnowUs = {
    answer?: string;
};

export default async ({ answer }: TAnswerHowYouKnowUs = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/howYouKnowUs`,
        method: "post",
        headers: {
            auth: true,
        },
        body: {
            answer,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
