type THandleError<TErrorBody> = {
    body: TErrorBody;
    message?: string;
    errorToken?: boolean;
};

export default <TErrorBody>({
    body,
    message,
    errorToken,
}: THandleError<TErrorBody>) => {
    const error = { body, message, errorToken };

    throw error;
};
