import { Icons } from "../../../../../../../assets";
import * as s from "../styles";

type HeadProps = {
    children?: React.ReactNode;
    orderBy?: "asc" | "desc";
    onClick?: () => void;
};

export const Head = ({ orderBy, children, onClick }: HeadProps) => {
    return (
        <s.Head orderBy={orderBy} onClick={onClick}>
            {children}
            {orderBy && <Icons.ArrowOrder />}
        </s.Head>
    );
};
