import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 23rem;
    padding-bottom: 2rem;
    position: absolute;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: calc(24px + 1.5rem);
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .sectors {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray900};
        white-space: nowrap;
    }
`;

export const Contract = styled.form<{ isEditing?: boolean }>`
    display: flex;
    padding: 1.5rem 1.5rem 1.5rem 2.5rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
    background-color: ${({ theme }) => theme.white2};
    justify-content: space-between;

    div.icons-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: flex-start;
    }

    span.tooltip {
        align-self: flex-start;
        cursor: pointer;
        height: fit-content;

        svg {
            align-self: flex-start;
        }
    }

    div.form-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-right: 2rem;
        gap: 8px;

        span#contact-name {
            font-weight: 500;
            color: ${({ theme }) => theme.gray800};
            margin-top: 2px;
            margin-bottom: 26px;
        }

        span.field-title {
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            color: ${({ theme }) => theme.gray400};
            white-space: nowrap;
        }

        span.field-value {
            display: flex;
            white-space: normal;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray900};
            white-space: nowrap;
        }

        div.contact-field {
            display: grid;
            grid-template-columns: ${({ isEditing }) =>
                !isEditing ? "100px 140px 1fr" : "100px 260px 1fr"};
            justify-content: flex-start;
            min-height: 36px;
            gap: 0.5rem;

            div.input-wrapper {
                min-height: 36px;
                margin-left: auto;

                input {
                    min-height: 36px;
                }
            }

            div.select-input,
            div.select-input-projects,
            .input-contact-data {
                min-height: 36px;
                width: 260px;
            }
        }

        div.contract-multi-select {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            div.contract-field {
                flex-direction: column;
            }

            div.react-select__control {
                height: unset;
            }

            span.field-title {
                display: flex;
                gap: 0.5rem;
            }

            div.contract-projects {
                width: 100%;
                height: 13rem;

                ul {
                    display: flex;
                    row-gap: 4px;
                    column-gap: 8px;
                    flex-wrap: wrap;
                    flex-direction: column;
                    font-size: 0.75rem;
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    list-style: none;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    width: 7.5625rem;
                    height: 1.75rem;
                    background-color: ${({ theme }) => theme.purple50};
                    border: 1px solid ${({ theme }) => theme.purple100};
                    border-radius: 4px;
                }

                span {
                    width: 95%;
                    text-align: left;
                    justify-content: flex-start;
                }
            }
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Status = styled.span<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 12px;
    background: ${({ $active }) => ($active ? "#2780270D" : "#F9F9F9")};
    border-radius: 8px;
    color: ${({ $active, theme }) =>
        $active ? theme.green1 : "#D14141"} !important;
    font-weight: 600;
    width: fit-content;
    padding: 2px 20px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    height: 100%;

    svg {
        cursor: pointer;
    }
`;

export const Tooltip = styled.div`
    padding: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.gray800};
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;

    .item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${({ theme }) => theme.gray800};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        padding: 8px;
        cursor: pointer;

        &:hover {
            background-color: ${({ theme }) => theme.pink50};
        }

        &--delete {
            color: ${({ theme }) => theme.red};
        }
    }
`;
