import { TGetInvites } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ all, length, page }: TGetInvites = {}) => {
    const tempParams = {
        all,
        length,
        page,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/read`,
        method: "get",
        headers: { auth: true },
        params: tempParams,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Convites inválidos",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
