import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { PrimaryButton, SecondaryButton } from "../../atoms";
import * as s from "./styled-designed-alert";

type TDesignedAlert = {
    trigger?: JSX.Element;
    title: string;
    description: string;
    textBtn: string;
    open?: boolean;
    className?: string;
    onOpenChange?: () => void;
    onClickBtn?: () => void;
};

export default ({
    trigger,
    description,
    title,
    textBtn,
    open,
    onOpenChange,
    onClickBtn,
    className,
}: TDesignedAlert) => {
    return (
        <div>
            <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
                <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
                <AlertDialog.Portal>
                    <s.Overlay />
                    <s.Content>
                        <AlertDialog.Title>
                            <s.Title>{title}</s.Title>
                        </AlertDialog.Title>
                        <AlertDialog.Description style={{ marginTop: "16px" }}>
                            <s.Description>{description}</s.Description>
                        </AlertDialog.Description>
                        <s.Flex style={{ marginTop: "1rem" }}>
                            <AlertDialog.Cancel asChild>
                                <SecondaryButton
                                    variation="small"
                                    className={"alert-cancel"}
                                >
                                    Cancelar
                                </SecondaryButton>
                            </AlertDialog.Cancel>

                            <AlertDialog.Action asChild>
                                <PrimaryButton
                                    onClick={onClickBtn}
                                    className={className || "alert-proceed"}
                                    variation="small"
                                >
                                    {textBtn}
                                </PrimaryButton>
                            </AlertDialog.Action>
                        </s.Flex>
                    </s.Content>
                </AlertDialog.Portal>
            </AlertDialog.Root>
        </div>
    );
};
