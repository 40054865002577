import { TooltipItem } from "chart.js";
import { Context } from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

import { theme } from "../../../../assets";
import { TDoughnutData } from "../../../../pages/report-summary/report-summary";
import { minutesToStringDate } from "../../../../utils";

const DoughnutChart = ({ labels, colors, times, maxTime }: TDoughnutData) => {
    const data = {
        labels,
        datasets: [
            {
                data: times,
                backgroundColor: colors,
                borderWidth: 1,
                datalabels: {
                    labels: {
                        index: {
                            align: "end" as const,
                            anchor: "end" as const,
                            color: theme.gray800,
                            font: { size: 12 },
                            formatter(value: number) {
                                if (maxTime && (value / maxTime) * 100 > 5) {
                                    return `${((value / maxTime) * 100)
                                        .toFixed(2)
                                        .replace(".", ",")}%`;
                                }
                                return "";
                            },
                            offset: 8,
                            opacity(ctx: Context) {
                                return ctx.active ? 1 : 0.5;
                            },
                        },
                    },
                },
            },
        ],
    };

    const options = {
        layout: {
            padding: 50,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title(value: TooltipItem<"doughnut">[]) {
                        return value[0].label;
                    },
                    label(value: TooltipItem<"doughnut">) {
                        return minutesToStringDate(value.parsed);
                    },
                },
                displayColors: false,
                padding: 10,
            },
        },
    };

    return (
        <div style={{ width: "300px", height: "300px" }}>
            <Doughnut data={data} options={options} id="doughnut-chart" />
        </div>
    );
};

export default DoughnutChart;
