import { FieldErrors, FieldValues } from "react-hook-form";

import { Icons, theme } from "../../../../assets";
import { PopoverProject, Tag, Tooltip } from "../../../../components";
import { TProjectList } from "../../../../components/molecules/popovers/popover-project/popover-project";
import { useUser } from "../../../../context";
import { THourControl } from "../../../../models";
import * as s from "../styled-components";

type TSelectProject = {
    setProject: React.Dispatch<React.SetStateAction<TProjectList | undefined>>;
    onChange: (event: string) => void;
    isTimeEntryRunning?: boolean;
    errors: FieldErrors<FieldValues>;
    hourControlData: THourControl[];
    hourLimitReached: boolean;
    project: TProjectList | undefined;
};

export const SelectProject = ({
    hourLimitReached,
    hourControlData,
    isTimeEntryRunning,
    setProject,
    onChange,
    project,
    errors,
}: TSelectProject) => {
    const { userProjects, favoriteProject } = useUser();
    const filteredProjects = userProjects.filter(
        (project) => !project.archived
    );

    if (hourLimitReached) {
        return (
            <div>
                <Icons.Tag />
            </div>
        );
    }

    return isTimeEntryRunning ? (
        <div>
            {project ? (
                <Tooltip
                    offset={7}
                    positiony={10}
                    side="top"
                    content={
                        <s.SmallP>
                            Para editar um projeto, certifique-se de que a
                            <br />
                            entrada de tempo não esteja em andamento.
                            <br />
                            por favor, encerre-a antes de fazer alterações.
                        </s.SmallP>
                    }
                >
                    <div>
                        <Tag
                            color={project.color}
                            maxWidth="200px"
                            isFavorite={favoriteProject?.id_e === project.id_e}
                        >
                            {project.name}
                        </Tag>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip
                    offset={7}
                    positiony={20}
                    side="top"
                    content={<s.SmallP>Selecionar projeto</s.SmallP>}
                >
                    <div className="tag-project">
                        <Icons.Tag color={theme.gray800} />
                        <p>Selecione o projeto</p>
                    </div>
                </Tooltip>
            )}
        </div>
    ) : (
        <PopoverProject
            sideOffset={8}
            align="start"
            onSelect={(item) => {
                setProject(item);
                onChange(item.name);
            }}
            projectList={filteredProjects}
            hourControlData={hourControlData}
            trigger={
                <Tooltip
                    error
                    open={Boolean(errors.project?.message)}
                    content={
                        <div
                            style={{
                                padding: "10px 16px",
                            }}
                        >
                            {errors.project?.message as React.ReactNode}
                        </div>
                    }
                >
                    <div>
                        {project ? (
                            <Tag
                                color={project.color}
                                maxWidth="200px"
                                isFavorite={
                                    favoriteProject?.id_e === project.id_e
                                }
                            >
                                {project.name}
                            </Tag>
                        ) : (
                            <Tooltip
                                offset={7}
                                positiony={20}
                                side="top"
                                content={
                                    <s.SmallP>Selecionar projeto</s.SmallP>
                                }
                            >
                                <div className="tag-project">
                                    <Icons.Tag color={theme.gray800} />
                                    <p>Selecione o projeto</p>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </Tooltip>
            }
        />
    );
};
