import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    padding: 16px;
    width: 400px;
`;

export const LoadingWrapper = styled.div`
    padding: 16px;
    width: 400px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Name = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
`;

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
        width: 140px;
    }
`;
