import { PrimaryButton, SecondaryButton } from "../../../../../../components";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { TResponse } from "../../../../../../models";
import { TDeleteActivity } from "../../../../../../services/activities/delete-activity";
import * as s from "./styled-delete-confirmation-activity";

export type DeleteConfirmationActivityProps = {
    setOpen: React.Dispatch<React.SetStateAction<string>>;
    activityId: string;
    deleteActivity: TResponse<unknown, TDeleteActivity>;
};

export const DeleteConfirmationActivity = ({
    setOpen,
    activityId,
    deleteActivity,
}: DeleteConfirmationActivityProps) => {
    const handleDeleteActivity = (activityId: string) => {
        deleteActivity.onRefresh({
            activityIds: [activityId],
        });
    };

    return (
        <ContainerModal>
            <s.Container
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setOpen("");
                    }
                    if (e.key === "Enter") {
                        handleDeleteActivity(activityId);
                    }
                }}
            >
                <s.Title>Deletar atividade</s.Title>
                <s.Description>
                    Ao deletar a atividade, esta ação não poderá ser desfeita.
                </s.Description>
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => {
                            setOpen("");
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setOpen("");
                            handleDeleteActivity(activityId);
                        }}
                    >
                        DELETAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
