import { useState } from "react";
import { Control, Controller } from "react-hook-form";

import { Icons, theme } from "../../../../../../assets";
import { CheckBox, SelectInput } from "../../../../../../components";
import { TOption } from "../../../../../../models";
import { permissionsList } from "../../../../../../utils/permissions/permissions-options";
import { IPermission } from "../../../roles-form";
import { InformationModal } from "./modals/information/information-modal";
import * as s from "./styled-permission-row";

type TPermissionRowProps = {
    permission: IPermission & {
        index: number;
    };
    enabled?: boolean;
    control: Control<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    view?: boolean;
    permissions?: IPermission[];
};

export const PermissionRow = ({
    enabled = true,
    permission,
    control,
    view,
    permissions,
}: TPermissionRowProps) => {
    const [informationModalIsOpen, setInformationModalIsOpen] = useState(false);

    const permissionOptions = permissionsList.find(
        (permissionOption) => permissionOption.id === permission.id
    );
    if (!permissionOptions) return <></>;

    const isCheckbox = permissionOptions.type === "checkbox";
    const isSelect = permissionOptions.type === "select";
    const isToShowMore =
        permissionOptions.type === "select" ||
        permission.classification === "finance";

    const transformPermissionToOption = (permission: IPermission) => {
        const option = permissionOptions?.options.find(
            (option) => option.value === permission.value
        );
        return option;
    };

    const disableOptions = (state: IPermission, option: TOption) => {
        const optionsThatCanBeDisabled = [
            {
                id: "professionals_values",
                dependsOn: "professionals",
            },
            {
                id: "projects_values",
                dependsOn: "projects_sectors",
            },
        ];
        const singleOption = optionsThatCanBeDisabled.find(
            (o) => o.id === state.id
        );
        if (!singleOption) return false;
        const permission = permissions?.find(
            ({ id }) => id === singleOption.dependsOn
        );
        if (!permission) return false;
        const viewPermission = permission.value === "view";
        return option.value === "manage" && viewPermission;
    };
    return (
        <>
            {informationModalIsOpen && (
                <InformationModal
                    setOpen={setInformationModalIsOpen}
                    permission={permissionOptions}
                />
            )}
            <s.Container isCheckbox={isCheckbox}>
                {isCheckbox && (
                    <Controller
                        name={`permissions.${permission.index}`}
                        control={control}
                        defaultValue={permission}
                        render={({ field: { value: state, onChange } }) => {
                            const isEnabled = state.value === "enabled";
                            return (
                                <CheckBox
                                    id={permission.id}
                                    onChange={() => {
                                        onChange({
                                            ...state,
                                            value: isEnabled
                                                ? "disabled"
                                                : "enabled",
                                        });
                                    }}
                                    width={16}
                                    height={16}
                                    gap="8px"
                                    isChecked={isEnabled}
                                    disabled={view}
                                />
                            );
                        }}
                    />
                )}
                <s.Content>
                    <s.Title disabled={!enabled}>
                        {permissionOptions.title}
                    </s.Title>
                    <s.Description>
                        {permissionOptions.description}
                        {isToShowMore && (
                            <s.KnowMore
                                onClick={() => setInformationModalIsOpen(true)}
                            >
                                Saiba mais
                            </s.KnowMore>
                        )}
                    </s.Description>
                </s.Content>
                {isSelect && (
                    <s.Content>
                        <Controller
                            name={`permissions.${permission.index}`}
                            control={control}
                            defaultValue={permission}
                            render={({ field: { value: state, onChange } }) => (
                                <SelectInput
                                    value={transformPermissionToOption(state)}
                                    disabled={!enabled || view}
                                    icon={
                                        <Icons.Chevron color={theme.gray600} />
                                    }
                                    options={permissionOptions.options}
                                    onChange={(e: TOption) => {
                                        onChange({ ...state, value: e.value });
                                    }}
                                    isOptionDisabled={(option) => {
                                        return disableOptions(
                                            state,
                                            option as TOption
                                        );
                                    }}
                                />
                            )}
                        />
                    </s.Content>
                )}
            </s.Container>
        </>
    );
};
