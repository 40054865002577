import React from "react";

import { Icons, theme } from "../../../../assets";
import { formatToBRMoney, getDayMonthAndHalfYear } from "../../../../utils";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalCostHistoryProps = {
    history: {
        created_at?: string;
        User?: {
            name: string;
        };
        cost?: string;
        type?: "team" | "total" | "variable" | "fixed";
    }[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalCostHistory = ({
    history,
    setOpen,
}: ModalCostHistoryProps) => {
    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.TitleContainer>
                    <Icons.History color={theme.purple600} />
                    <s.Title>Histórico de edições</s.Title>
                </s.TitleContainer>
                <s.TextContainer>
                    {history.map((item) => (
                        <s.Text>
                            {getDayMonthAndHalfYear(item.created_at)} {" - "}
                            {item.User?.name} {" - "}
                            {formatToBRMoney(Number(item.cost))}
                        </s.Text>
                    ))}
                </s.TextContainer>
            </s.Container>
        </ContainerModal>
    );
};
