import { useEffect, useState } from "react";

import { PopoverRadix, Scroll, Tag, Tooltip } from "../../..";
import { Icons, theme } from "../../../../assets";
import { THourControl } from "../../../../models";
import * as s from "./styled-popover-hour-control";

type TPopoverHourControl = {
    trigger: JSX.Element;
    hourControl: THourControl[];
    side?: "bottom" | "top" | "right" | "left";
    align?: "end" | "start" | "center";
    sideOffset?: number;
    alignOffset?: number;
};

const PopoverHourControl = ({
    trigger,
    hourControl,
    side,
    align,
    sideOffset,
    alignOffset,
}: TPopoverHourControl) => {
    const [isOpen, setIsOpen] = useState(false);
    const returnTotals = (hourControl: THourControl[]) => {
        let totalWorkLoad = 0;
        let totalAdditionalHours = 0;
        let totalHoursDone = 0;
        let totalPendingHours = 0;

        hourControl.forEach((hc) => {
            totalWorkLoad += hc.workLoad;
            totalAdditionalHours += hc.additionalHours;
            totalHoursDone += hc.statistics.hoursDone;
            totalPendingHours += hc.statistics.pendingHours;
        });

        return {
            totalWorkLoad,
            totalAdditionalHours,
            totalHoursDone,
            totalPendingHours,
        };
    };

    const {
        totalWorkLoad,
        totalAdditionalHours,
        totalHoursDone,
        totalPendingHours,
    } = returnTotals(hourControl);

    const formatPendingHours = (pendingHours: number | undefined): string => {
        if (pendingHours === undefined) {
            return "-";
        }
        if (pendingHours < 0) {
            return "0";
        }
        return pendingHours.toString();
    };

    return (
        <PopoverRadix
            side={side}
            align={align}
            sideOffset={sideOffset}
            alignOffset={alignOffset}
            onOpenChange={(e) => setIsOpen(e)}
            avoidCollisions={true}
            trigger={
                <s.ContainerTrigger isOpen={isOpen}>
                    {trigger}
                </s.ContainerTrigger>
            }
            open={isOpen}
        >
            <s.Container>
                <s.HourControlHeader>
                    <div className="left-align">
                        Projetos ( {hourControl.length} )
                    </div>
                    <div>
                        Horas
                        <br />
                        previstas
                    </div>
                    <div>
                        Horas
                        <br />
                        adicionais
                    </div>
                    <div>
                        Horas
                        <br />
                        realizadas
                    </div>
                    <div>
                        Horas
                        <br />
                        pendentes
                    </div>
                    <div>
                        Regras
                        <br />
                        de uso{" "}
                        <Tooltip
                            side="top"
                            align={"end"}
                            positionx={15}
                            positiony={10}
                            content={
                                <s.LockTooltip>
                                    <div className="rule">
                                        <div>
                                            <Icons.iconLockClosedFill
                                                color={theme.gray600}
                                            />
                                        </div>
                                        <div>
                                            <div className="title">
                                                Obrigatório
                                            </div>
                                            <div>
                                                Você deve trabalhar o número de
                                                horas previstas, sem
                                                ultrapassá-las.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rule">
                                        <div>
                                            <Icons.IconLockOpen
                                                color={theme.gray600}
                                            />
                                        </div>
                                        <div>
                                            <div className="title">
                                                Flexível
                                            </div>
                                            <div>
                                                Você pode trabalhar menos ou
                                                mais horas do que o previsto,
                                                conforme combinado com o seu
                                                gestor.
                                            </div>
                                        </div>
                                    </div>
                                </s.LockTooltip>
                            }
                        >
                            <span>
                                <Icons.Info color={theme.gray600} />
                            </span>
                        </Tooltip>
                    </div>
                    <div></div>
                </s.HourControlHeader>
                <s.HourControlList>
                    <Scroll>
                        {hourControl?.map(
                            (hour: THourControl, index: number) => (
                                <div className="content-row" key={index}>
                                    <div className="left-align">
                                        <div className="tag-container">
                                            <Tag color={hour?.Project.color}>
                                                {hour?.Project?.name || "-"}
                                            </Tag>
                                        </div>
                                    </div>
                                    <div>{hour?.workLoad || "-"}</div>
                                    <div>{hour?.additionalHours || "-"}</div>
                                    <div>
                                        {hour?.statistics?.hoursDone || "-"}
                                    </div>
                                    <div>
                                        {formatPendingHours(
                                            hour?.statistics?.pendingHours
                                        )}
                                    </div>
                                    <div>
                                        {hour.locked ? (
                                            <Icons.iconLockClosedFill
                                                color={theme.gray300}
                                            />
                                        ) : (
                                            <Icons.IconLockOpen
                                                color={theme.gray300}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </Scroll>
                </s.HourControlList>
                <s.HourControlFooter>
                    <div className="left-align">Total</div>
                    <div>{`${totalWorkLoad || "0"}:00`}</div>
                    <div>{`${totalAdditionalHours || "0"}:00`}</div>
                    <div>{`${totalHoursDone || "0"}:00`}</div>
                    <div>
                        {`${formatPendingHours(totalPendingHours) || "0"}:00`}
                    </div>
                    <div>
                        <Icons.iconLockClosedFill color={theme.gray300} />
                    </div>
                </s.HourControlFooter>
            </s.Container>
        </PopoverRadix>
    );
};

export default PopoverHourControl;
