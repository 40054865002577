import styled from "styled-components";

export const UsersList = styled.span`
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: flex-start;
    margin: 10px 0;

    div#users-header {
        text-align: left;
    }

    span#title {
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.purple400};
    }

    div#users-body {
        overflow-y: auto;
        max-height: 15rem;
        text-align: left;
        color: ${({ theme }) => theme.gray600};

        span#user-name {
            word-wrap: normal;
            p {
                padding: 4px 0;
                padding-right: 4px;
            }
        }
    }
`;
