import styled from "styled-components";

export const ArchiveContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.gray100};
    color: ${({ theme }) => theme.gray600};
`;

export const ArchiveSection = styled.div<{ isArchiveOpen?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 16px;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }

    .chevron {
        transition: transform 0.3s ease;
        transform: ${({ isArchiveOpen }) =>
            isArchiveOpen ? "rotate(0deg)" : "rotate(-90deg)"};
    }

    span {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: 16px;
        color: ${({ theme }) => theme.gray400};
    }
`;
