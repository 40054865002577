import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TGetBackOffice = {
    startAt?: Date;
    endAt?: Date;
};

export default async ({ startAt, endAt }: TGetBackOffice = {}) => {
    const headers = {
        auth: true,
        start_at: startAt,
        end_at: endAt,
    };

    if (!startAt) delete headers.start_at;
    if (!endAt) delete headers.end_at;

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/backoffice`,
        method: "get",
        headers,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
