import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const OrganizationsWithoutRegistry = ({
    data,
}: {
    data: TBackOfficeData;
}) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Warning color={theme.purple500} />
                Organizações sem registro há 3 dias
            </generalStyles.Title>
            <generalStyles.Row>
                {data.organizationsWithoutRegistryForThreeDays.length > 0 ? (
                    <generalStyles.Content>
                        {data.organizationsWithoutRegistryForThreeDays.map(
                            ({ name, email }) => (
                                <>
                                    <s.Row key={email}>
                                        <div>{name}</div>
                                        <div>{email}</div>
                                    </s.Row>
                                    <generalStyles.Divider />
                                </>
                            )
                        )}
                    </generalStyles.Content>
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhuma organização sem registro há 3 dias</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
