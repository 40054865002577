import styled from "styled-components";

export const SectorsList = styled.span`
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: flex-start;
    margin: 10px 0;

    span#title {
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.purple400};
    }

    div#sectors-body {
        overflow-y: auto;
        max-height: 15rem;
        color: ${({ theme }) => theme.gray600};
    }
`;
