import styled from "styled-components";

type TInput = {
    haveFile?: boolean;
    fileError?: boolean;
};

export const Container = styled.div`
    width: 600px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
`;

export const StepByStep = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.875rem;
    font-weight: 400;

    p {
        color: ${({ theme }) => theme.gray600};
    }

    span {
        color: ${({ theme }) => theme.purple600};
    }
`;

export const AttachmentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
        cursor: pointer;
    }

    h2 {
        color: ${({ theme }) => theme.gray800};
        font-size: 1rem;
        font-weight: 500;
    }
`;

export const AttachmentInput = styled.button<TInput>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.gray600};
    gap: 0.5rem;

    height: 40px;
    border-radius: 4px;
    padding-right: 0.625rem;
    border: 1px solid
        ${({ theme, fileError }) =>
            fileError ? theme.redError : theme.gray200};
    padding: 0 16px;

    svg {
        min-width: 24px;
        height: 24px;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    button {
        width: fit-content;
        padding: 0 1rem;
    }
`;
