import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 40px 61px;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
`;

export const HowYouKnowUs = styled.div`
    h4 {
        color: ${({ theme }) => theme.gray800};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
`;

export const CheckBoxContainer = styled.div`
    margin-left: -0.8rem;
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    gap: 0.5rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 17px;
    height: 17px;
    border-radius: 50px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;
