import styled from "styled-components";

import { theme } from "../../../assets";

type TBox = {
    checked: boolean;
    width?: number;
    height?: number;
    error?: string;
    disabled?: boolean;
};

const getBorderColor = ({ error, disabled }: TBox) => {
    if (error) return theme.redError;
    if (disabled) return theme.gray200;
    return theme.purple500;
};

const getBoxShadow = ({ disabled }: TBox) => {
    if (disabled) return "none";
    return "0px 0px 0.5rem 0.25rem rgba(102, 51, 152, 0.15)";
};

export const Flex = styled.div<{ gap?: string }>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap ?? "1rem"};
`;

export const Span = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.purple800};
`;

export const UnderlinedText = styled.a`
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.gray800};
    margin-left: 4px;
`;

export const Box = styled.label<TBox>`
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.white};
    border: 0.075rem solid ${getBorderColor};
    box-sizing: border-box;
    border-radius: 0.125rem;
    width: ${({ width }) => `${width}px` || `1.5rem`};
    height: ${({ height }) => `${height}px` || `1.5rem`};
    background: ${({ theme, checked }) =>
        checked ? theme.purple500 : theme.white};
    &:hover {
        box-shadow: ${getBoxShadow};
    }
    input {
        display: none;
    }
`;

export const FlexColumn = styled.div``;
export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 1rem;
    margin-top: 0.375rem;
`;
