import styled from "styled-components";

export const Container = styled.div`
    padding: 32px;
    background-color: white;
    border-radius: 4px;
    width: 540px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-height: 600px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.purple600};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 530px;
    overflow-y: auto;
    gap: 16px;
`;

export const Text = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.08px;
`;
