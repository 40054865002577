import { RoomWithClockAndPlant } from "../../assets";
import { SecondaryButton } from "../../components";
import * as s from "./styled-success-inactivation";

export const SuccessInactivationPage = () => {
    return (
        <s.Page>
            <s.SideImage
                src={RoomWithClockAndPlant}
                alt="Room with clock and plant"
            />
            <s.Content>
                <div>
                    <s.Title>Conta inativada</s.Title>
                    <s.Description>
                        A sua conta foi inativada. Você pode reativá-la a
                        qualquer momento entrando na plataforma. Em caso de
                        dúvidas, nos contate pelo e-mail{" "}
                        <a href="mailto: suporte@paperon.app">
                            suporte@paperon.app.
                        </a>
                    </s.Description>
                    <s.Subtitle>Quer reativar sua conta no PaperON?</s.Subtitle>
                    <SecondaryButton
                        onClick={() => {
                            window.location.href = "/login";
                        }}
                    >
                        ENTRAR
                    </SecondaryButton>
                </div>
            </s.Content>
        </s.Page>
    );
};
