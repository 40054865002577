import styled from "styled-components";

import { theme } from "../../assets";

export const Container = styled.main`
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    padding: 40px 40px;
    width: 500px;
    height: fit-content;
    display: flex;
    gap: 44px;
    flex-direction: column;
`;

export const Header = styled.header`
    color: ${theme.gray800};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
`;

export const Description = styled.p`
    color: ${theme.gray800};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;

    svg {
        margin-bottom: -2px;
    }
`;

export const Terms = styled.p`
    color: ${theme.gray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;

    a {
        color: ${theme.purple500};
        font-weight: 700;
        text-decoration-line: underline;
    }
`;
