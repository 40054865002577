import * as yup from "yup";
import { ObjectShape } from "yup/lib/object";

export const PASSWORD_MIN_LENGTH = 8;
export const capitalLettersRegex = /[A-Z]/;
export const specialCharsRegex = /[\W_]+/;

const removePhoneMask = (value: string) => value.replace(/[^\d]/g, "");

const createBaseFields = (): ObjectShape => ({
    name: yup
        .string()
        .trim()
        .required("Insira o seu nome completo")
        .max(70, "Nome tem limite de 70 caracteres."),
    organizationName: yup
        .string()
        .trim()
        .required("Insira o nome da empresa")
        .max(100, "Nome da organização tem limite de 100 caracteres."),
    celNumber: yup
        .string()
        .trim()
        .test("telefone", "Telefone inválido", (value) => {
            if (!value) return true;
            return removePhoneMask(value).length >= 10;
        })
        .nullable(),
});

const addPasswordFields = (fields: ObjectShape): ObjectShape => ({
    ...fields,
    password: yup
        .string()
        .trim()
        .required("Insira a sua senha")
        .min(
            PASSWORD_MIN_LENGTH,
            `Deve conter no mínimo ${PASSWORD_MIN_LENGTH} caracteres`
        )
        .test({
            test: (password) => {
                if (!password) return false;
                return (
                    password.length >= PASSWORD_MIN_LENGTH &&
                    capitalLettersRegex.test(password) &&
                    specialCharsRegex.test(password)
                );
            },
        }),
    password_confirmation: yup.string().trim().required("Confirme a sua senha"),
});

export const validateRegisterFinalStep = (isGoogle?: boolean) => {
    const baseFields = createBaseFields();
    return yup
        .object()
        .shape(isGoogle ? baseFields : addPasswordFields(baseFields));
};
