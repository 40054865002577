import { theme } from "..";

export default () => (
    <svg
        width="126"
        height="126"
        viewBox="0 0 126 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="126" height="126" rx="8" fill={theme.purple700} />
        <path
            d="M63.5597 116.055C87.8813 116.055 107.598 104.671 107.598 90.6287C107.598 76.586 87.8813 65.2021 63.5597 65.2021C39.2381 65.2021 19.5215 76.586 19.5215 90.6287C19.5215 104.671 39.2381 116.055 63.5597 116.055Z"
            fill="#F0F0F0"
            fillOpacity="0.5"
        />
        <path
            d="M36.5711 106.208C21.6667 97.6033 21.6507 83.677 36.5391 75.102C51.4275 66.5269 75.591 66.5269 90.5136 75.102C105.436 83.677 105.452 97.6033 90.5455 106.208C75.6389 114.813 51.4754 114.808 36.5711 106.208Z"
            fill="#FAFAFA"
            fillOpacity="0.2"
        />
        <path
            d="M63.5595 109.275C81.395 109.275 95.8535 100.927 95.8535 90.6287C95.8535 80.3309 81.395 71.9829 63.5595 71.9829C45.7241 71.9829 31.2656 80.3309 31.2656 90.6287C31.2656 100.927 45.7241 109.275 63.5595 109.275Z"
            fill="#EBEBEB"
            fillOpacity="0.5"
        />
        <path
            d="M63.5596 105.884C78.1526 105.884 89.9826 99.0541 89.9826 90.6288C89.9826 82.2034 78.1526 75.3733 63.5596 75.3733C48.9667 75.3733 37.1367 82.2034 37.1367 90.6288C37.1367 99.0541 48.9667 105.884 63.5596 105.884Z"
            fill="#FAFAFA"
            fillOpacity="0.2"
        />
        <path
            d="M63.5597 102.494C74.9102 102.494 84.1117 97.1817 84.1117 90.6288C84.1117 84.0759 74.9102 78.7637 63.5597 78.7637C52.2092 78.7637 43.0078 84.0759 43.0078 90.6288C43.0078 97.1817 52.2092 102.494 63.5597 102.494Z"
            fill="#E6E6E6"
            fillOpacity="0.2"
        />
        <path
            d="M53.1798 96.6205C47.4479 93.3099 47.4479 87.9451 53.1798 84.6345C58.9117 81.324 68.1662 81.349 73.848 84.687C79.5298 88.0249 79.5298 93.392 73.848 96.6729C68.1662 99.9538 58.9117 99.931 53.1798 96.6205Z"
            fill="#FAFAFA"
            fillOpacity="0.2"
        />
        <path
            d="M63.5596 95.713C68.4239 95.713 72.3672 93.4366 72.3672 90.6286C72.3672 87.8206 68.4239 85.5442 63.5596 85.5442C58.6953 85.5442 54.752 87.8206 54.752 90.6286C54.752 93.4366 58.6953 95.713 63.5596 95.713Z"
            fill="#E0E0E0"
            fillOpacity="0.2"
        />
        <path
            d="M67.2596 78.6562C67.5925 78.451 67.9026 78.2116 68.2377 78.0086C68.5785 77.81 68.8959 77.5737 69.1839 77.3041C69.674 76.7723 70.0606 76.1537 70.3239 75.4801C70.4611 75.1205 70.5672 74.7498 70.6408 74.372C70.8255 73.4053 70.8096 68.9844 70.8438 67.564L74.5989 69.3743C74.2243 70.9176 73.9388 72.4812 73.7439 74.0574C73.6846 75.2225 74.3436 76.6498 73.272 77.4181C73.0599 77.5686 72.816 77.6712 72.588 77.8057C71.8652 78.2412 71.3704 78.9868 70.7161 79.5248C70.2281 79.9174 69.6624 80.2018 69.0562 80.3593C68.8132 80.4493 68.558 80.5023 68.2993 80.5166C67.9678 80.4964 67.6385 80.4499 67.3143 80.3776C67.1114 80.3453 66.9165 80.2749 66.7398 80.1701C66.6505 80.1159 66.5744 80.0426 66.5168 79.9555C66.4592 79.8684 66.4217 79.7696 66.4069 79.6662C66.4024 79.5569 66.4217 79.4479 66.4634 79.3467C66.5052 79.2456 66.5683 79.1546 66.6486 79.0802C66.8074 78.9348 66.9844 78.8107 67.1752 78.7109L67.2596 78.6562Z"
            fill="#B16668"
        />
        <path
            d="M74.131 76.7593C74.1998 77.0164 74.1887 77.2884 74.0991 77.539C73.9509 77.7397 73.7184 77.8947 73.1575 78.2025C72.7229 78.4346 72.3732 78.7986 72.1588 79.2422C71.9236 79.6348 71.6308 79.9899 71.2902 80.2956C70.6586 80.8678 69.7261 81.1825 68.1962 81.1004C67.2386 81.0502 66.4566 80.6444 66.2514 80.3731C66.1906 80.2909 66.1476 80.197 66.1252 80.0972C66.1029 79.9975 66.1015 79.8942 66.1214 79.794L74.131 76.7593Z"
            fill="#263238"
        />
        <path
            d="M73.9245 74.3036C73.9245 74.1554 73.7923 73.9958 73.7398 74.0506C73.7392 74.2523 73.6924 74.4511 73.603 74.632C73.2465 75.0977 72.7356 75.4211 72.1621 75.544C72.1621 75.544 72.2145 75.3775 71.8611 75.243C71.5077 75.1085 70.6071 74.7619 70.4202 74.8577C70.2332 74.9534 70.0371 75.9224 69.4238 76.7752C68.8852 77.4885 68.1691 78.0482 67.3467 78.3985C66.8018 78.6265 66.1794 79.0825 66.1816 79.8098C66.738 80.6124 68.2336 80.7059 69.157 80.6329C70.7804 80.5007 71.5168 79.4701 71.9706 78.8522C72.4243 78.2344 72.577 78.1409 73.318 77.6165C73.6418 77.3885 73.9792 77.2243 74.132 76.7569C74.2939 75.6146 73.9131 75.5918 73.9245 74.3036Z"
            fill="#37474F"
        />
        <path
            d="M70.0416 80.4504C70.1305 79.8462 69.8547 79.0824 68.9313 78.5854C68.0079 78.0883 67.6089 78.2046 67.2874 78.3255C66.9659 78.4463 65.8578 79.0095 66.1474 79.8439C66.2523 80.2612 68.1925 81.1413 70.0416 80.4504Z"
            fill="#FAFAFA"
        />
        <path
            d="M72.1612 75.5461C72.0733 75.5429 71.9873 75.5721 71.9195 75.6282C71.8572 75.695 71.8071 75.7722 71.7713 75.8562L71.5433 76.3122C71.386 76.6155 71.2264 76.9164 71.0531 77.2083C70.8834 77.5001 70.6969 77.7817 70.4945 78.0519C70.2952 78.3245 70.0658 78.5739 69.8105 78.7951C70.1048 78.6188 70.3676 78.3946 70.588 78.1317C70.8115 77.8696 71.0121 77.5889 71.1877 77.2926C71.361 76.9972 71.5185 76.6927 71.6596 76.3806C71.7303 76.2233 71.7964 76.0614 71.8534 75.9018C71.8777 75.8214 71.913 75.7446 71.9583 75.6738C72.0105 75.6106 72.0817 75.5659 72.1612 75.5461Z"
            fill="#263238"
        />
        <path
            d="M70.2578 78.6608C69.9705 78.353 68.6914 77.7602 68.2628 77.897L68.1602 77.9631C68.2098 77.8575 68.2944 77.7721 68.3996 77.7215C68.6002 77.6052 69.7128 77.8332 70.4265 78.4853C70.463 78.5993 70.3535 78.7475 70.2578 78.6608Z"
            fill="#FAFAFA"
        />
        <path
            d="M70.7892 78.0087C70.5042 77.7009 69.3893 77.1924 68.9652 77.3247L68.8809 77.4045C68.8984 77.3481 68.9278 77.2961 68.9671 77.252C69.0065 77.208 69.0548 77.1729 69.1089 77.1491C69.3095 77.0328 70.2489 77.1765 70.9716 77.8331C70.9967 77.9471 70.885 78.0999 70.7892 78.0087Z"
            fill="#FAFAFA"
        />
        <path
            d="M71.1596 77.3225C70.8655 77.0215 69.8828 76.5769 69.4564 76.7251L69.3926 76.8186C69.4175 76.7189 69.4753 76.6305 69.5567 76.5678C69.746 76.431 70.5941 76.5085 71.3237 77.1401C71.3648 77.2518 71.2576 77.4159 71.1596 77.3225Z"
            fill="#FAFAFA"
        />
        <path
            d="M71.5232 76.7319C71.2291 76.431 70.2464 75.9864 69.82 76.1368L69.7676 76.2371C69.7829 76.1346 69.8377 76.0421 69.9203 75.9795C70.1096 75.8404 70.9577 75.9179 71.6873 76.5518C71.7193 76.6589 71.6121 76.8094 71.5232 76.7319Z"
            fill="#FAFAFA"
        />
        <path
            d="M75.1343 73.7907C75.433 73.2549 75.8183 72.8536 76.0281 72.4615C76.2953 72.0071 76.4631 71.5012 76.5205 70.9772C76.5775 70.4121 76.5942 69.8437 76.5707 69.2763C76.557 68.1591 75.1115 66.1937 73.7344 64.5316L77.4873 63.6196C78.1348 65.1905 78.8256 67.1172 78.9487 67.3132L78.9807 67.3588C79.2087 67.6849 79.6213 67.7875 79.9314 68.2936C80.0232 68.4568 80.0666 68.6428 80.0565 68.8297C80.0464 69.0167 79.9833 69.1969 79.8744 69.3493C79.8448 69.3926 79.8151 69.4359 79.7855 69.4861C79.5752 69.7886 79.3994 70.1136 79.2611 70.4551C79.1038 70.8837 79.1767 71.4628 79.1858 72.0807C79.2101 72.3342 79.175 72.5898 79.0831 72.8273C78.9913 73.0647 78.8454 73.2775 78.6569 73.4487C78.3309 73.7816 77.2228 74.518 76.4111 74.6161C75.1229 74.7871 74.8379 74.3288 75.1343 73.7907Z"
            fill="#B16668"
        />
        <path
            d="M80.1329 68.5625C80.3039 68.716 80.4147 68.9253 80.4453 69.153C80.3451 69.5482 80.1685 69.92 79.9255 70.2474C79.6017 70.7034 79.4695 70.9041 79.4945 71.3327C79.5526 71.8983 79.4849 72.4698 79.2962 73.0062C79.117 73.4785 78.8378 73.9064 78.4777 74.2606C78.1176 74.6148 77.6851 74.8869 77.21 75.0582C76.184 75.4709 75.4612 75.2338 75.1967 75.1038C74.9323 74.9739 74.9847 74.5566 74.9847 74.5566L80.1329 68.5625Z"
            fill="#263238"
        />
        <path
            d="M78.9402 67.2903C79.0036 67.3604 79.0501 67.4442 79.0762 67.535C79.1023 67.6259 79.1073 67.7216 79.0907 67.8147C78.9927 68.4098 78.1992 68.9889 77.9803 69.1371C77.8688 69.0367 77.7314 68.9695 77.5836 68.9433C77.2211 68.8407 76.5781 68.9433 76.4687 69.0869C76.3593 69.2306 76.5599 70.2588 76.3957 71.1594C76.2658 71.8797 75.9427 72.5511 75.4609 73.102C75.1167 73.4919 74.5695 74.0026 75.03 74.7254C75.3583 74.9328 76.9042 75.1494 78.1513 74.0938C79.2571 73.1544 79.248 71.8662 79.2252 71.2005C79.2024 70.5347 79.4806 70.2155 79.8659 69.511C80.0346 69.2055 80.1851 68.982 80.1327 68.5625C79.8817 68.0217 79.4637 67.5757 78.9402 67.2903Z"
            fill="#37474F"
        />
        <path
            d="M78.1517 74.094C78.131 73.8007 78.0232 73.5202 77.842 73.2887C77.6608 73.0571 77.4145 72.885 77.1348 72.7944C76.1681 72.5367 75.6141 72.7191 75.3428 72.9608C75.1148 73.1614 74.2028 74.0347 74.9848 74.7392C75.2288 75.0105 76.6788 75.4141 78.1517 74.094Z"
            fill="#FAFAFA"
        />
        <path
            d="M77.9815 69.1372C77.9105 69.1701 77.8538 69.2276 77.8219 69.2991C77.7985 69.3728 77.7869 69.4498 77.7877 69.5271C77.7877 69.6776 77.7877 69.8212 77.7717 69.9831C77.7717 70.2772 77.7467 70.5736 77.7216 70.8654C77.6948 71.1585 77.653 71.45 77.5962 71.7387C77.5394 72.0292 77.4515 72.3128 77.334 72.5846C77.4983 72.3323 77.6207 72.0551 77.6965 71.7638C77.7756 71.4734 77.829 71.1766 77.8561 70.8768C77.8831 70.579 77.8929 70.2798 77.8857 69.9808C77.8857 69.8326 77.8721 69.6821 77.8607 69.5362C77.8516 69.4651 77.8516 69.393 77.8607 69.3219C77.8803 69.2491 77.9227 69.1844 77.9815 69.1372Z"
            fill="#263238"
        />
        <path
            d="M77.5635 72.3997C77.2694 72.1717 76.2821 72.0463 76.0131 72.288L75.9629 72.3815C75.9618 72.2731 75.9962 72.1674 76.061 72.0805C76.175 71.9141 76.9388 71.7066 77.6342 72.1831C77.6934 72.288 77.6547 72.4636 77.5635 72.3997Z"
            fill="#FAFAFA"
        />
        <path
            d="M77.739 71.6566C77.4586 71.4013 76.5876 71.3237 76.3049 71.5472L76.2798 71.6315C76.2652 71.5824 76.2637 71.5303 76.2752 71.4803C76.2868 71.4304 76.3112 71.3843 76.3459 71.3465C76.4668 71.1892 77.2784 71.041 77.8234 71.4446C77.8758 71.5517 77.8279 71.7159 77.739 71.6566Z"
            fill="#FAFAFA"
        />
        <path
            d="M77.8522 70.9497C77.5741 70.6943 76.7122 70.6008 76.4386 70.8266V70.9246C76.4128 70.8799 76.402 70.8281 76.4078 70.7768C76.4135 70.7256 76.4355 70.6775 76.4706 70.6396C76.5914 70.48 77.4008 70.3341 77.948 70.7376C77.9913 70.8425 77.9434 71.0158 77.8522 70.9497Z"
            fill="#FAFAFA"
        />
        <path
            d="M77.8865 70.1974C77.5992 69.9534 76.735 69.901 76.4592 70.1381V70.2225C76.4348 70.1827 76.4219 70.1369 76.4219 70.0902C76.4219 70.0436 76.4348 69.9978 76.4592 69.958C76.564 69.787 77.4054 69.6 77.9663 69.9831C78.021 70.088 77.9799 70.2612 77.8865 70.1974Z"
            fill="#FAFAFA"
        />
        <path
            d="M68.9167 45.147C68.9167 45.147 68.5998 46.9938 68.5656 50.9473C68.5382 54.2579 68.1096 56.4626 69.3568 58.6104C70.4808 60.5256 75.3782 67.2493 75.8912 67.9173C75.8912 67.9173 77.6765 67.8353 78.5976 66.3875C78.5976 66.3875 77.7061 63.6925 76.7485 61.4513C75.7909 59.21 74.8584 57.0212 74.8584 57.0212L77.5944 46.8935L68.9167 45.147Z"
            fill="#263238"
        />
        <path
            d="M77.9067 66.8914L78.0822 66.8389C77.4256 64.6342 74.6805 58.3687 73.4812 57.1558L73.3535 57.2834C74.5095 58.4531 77.2728 64.7573 77.9067 66.8914Z"
            fill="black"
        />
        <path
            d="M78.1489 65.0833C77.8138 65.7673 76.0103 67.0851 75.1348 66.8936L76.0832 68.2046C76.8904 68.2753 78.2105 67.4453 78.6961 66.5881L78.1489 65.0833Z"
            fill="#37474F"
        />
        <path
            d="M69.3105 45.9883L77.5983 46.9003L74.8623 57.028L76.2577 60.2976C76.1916 60.4914 75.9773 61.6086 75.959 61.677C75.8929 61.905 76.431 62.8466 75.959 65.6282C75.8701 66.164 75.6512 66.9369 75.5213 67.434C74.5865 66.1549 72.5869 63.4394 71.1026 61.2597C70.9977 61.0705 70.8313 60.7353 70.8313 60.7353C70.8313 60.7353 70.544 58.9273 70.7378 57.6231C70.902 56.5242 71.5905 50.6532 71.5905 50.6532C71.1245 50.3408 70.7209 49.9441 70.4004 49.4835C70.1263 49.11 69.9435 48.6774 69.8669 48.2204C69.6358 47.4885 69.45 46.743 69.3105 45.9883Z"
            fill="#37474F"
        />
        <path
            d="M78.904 45.1196C78.904 46.629 78.977 47.1716 78.9246 48.2637C78.8584 49.6317 78.5028 50.9997 78.0832 52.5387C77.6637 54.0777 75.6528 60.9497 75.4954 61.4946C75.6528 62.687 75.8238 63.7449 75.3427 66.3213C74.6473 70.0423 73.8424 73.321 73.8424 73.321C73.8424 73.321 71.909 73.7656 70.7394 73.02C70.7394 73.02 70.6869 62.3405 70.8397 59.8986C70.9719 57.7759 72.2077 50.3385 72.2077 50.3385C71.2509 49.8396 70.4429 49.0967 69.8655 48.185C69.2882 47.2733 68.962 46.2253 68.9199 45.147L78.904 45.1196Z"
            fill="#37474F"
        />
        <path
            d="M74.5782 60.7104C74.6922 60.2886 75.0136 59.2466 75.4218 57.9265C76.1058 55.708 77.0611 52.1718 77.3848 50.8174C77.7724 49.2009 77.818 45.8014 77.818 45.7764H78.0004C78.0004 45.8106 77.9548 49.2214 77.5627 50.8562C77.2389 52.2242 76.3201 55.6442 75.595 57.9766C75.2052 59.2443 74.8654 60.3364 74.7537 60.756C74.6494 61.2554 74.6317 61.769 74.7013 62.2744C74.8132 63.5626 74.7603 64.8597 74.544 66.1345C74.2612 67.9357 73.502 71.4879 73.0779 73.4305L72.8887 73.4419C73.3105 71.5153 74.0788 67.922 74.3638 66.1071C74.5767 64.8458 74.6295 63.5627 74.5212 62.2881C74.4493 61.7628 74.4686 61.2291 74.5782 60.7104Z"
            fill="#263238"
        />
        <path
            d="M74.2098 71.7591C73.5098 72.0988 71.3233 72.1421 70.7305 71.4353V73.0587C71.3438 73.6241 72.9443 73.7427 73.8791 73.3459L74.2098 71.7591Z"
            fill="#455A64"
        />
        <path
            d="M62.837 36.5812C63.8493 36.6838 67.8963 36.7818 67.8963 36.7818L70.2242 33.6104C68.3477 32.8374 68.3454 33.282 65.6459 33.2433C62.9464 33.2045 62.5748 33.2228 62.5748 33.2228C61.3572 32.1033 60.0189 31.0818 58.8173 29.8734C58.3454 29.3217 58.4502 28.526 58.1333 27.9035C57.9281 27.3586 57.4721 26.9345 57.3216 26.3577C57.1598 25.6988 56.6376 26.2414 56.681 26.6814C56.716 27.0216 56.784 27.3576 56.8839 27.6846C56.8839 27.7325 56.8998 27.7918 56.8611 27.8237C56.7589 27.8396 56.6544 27.8203 56.5647 27.769C55.9803 27.3104 55.4677 26.7671 55.0439 26.157C54.8022 25.8515 54.0681 25.4434 54.0202 26.0932C54.0619 26.2337 54.072 26.3816 54.0498 26.5264C54.0183 26.6187 53.9976 26.7143 53.9883 26.8114C53.9883 27.0098 54.1889 27.1671 54.1844 27.3677C54.1844 27.4612 54.1319 27.5501 54.1296 27.6459C54.1296 27.8009 54.2414 27.9332 54.2824 28.0836C54.3371 28.282 54.255 28.5009 54.3166 28.6992C54.4242 28.9402 54.5563 29.1694 54.711 29.3832C54.9679 29.7918 55.2742 30.167 55.623 30.5004C56.0996 30.9838 56.8018 31.1844 57.2418 31.7111C58.1174 32.7987 59.914 35.2656 60.6413 35.9382C61.1954 36.4672 61.8246 36.4786 62.837 36.5812Z"
            fill="#B16668"
        />
        <path
            d="M56.9434 28.2727C56.8819 29.2212 57.0438 29.9371 57.5659 30.6348C56.777 30.2198 56.3598 29.3215 56.9434 28.2727Z"
            fill="#9A4A4D"
        />
        <path
            d="M71.0679 32.8761C69.8617 32.6869 68.3775 32.8009 67.1007 32.8761L62.7687 33.1475C62.9242 33.7118 63.0107 34.2928 63.0263 34.878C63.0696 36.1206 62.5977 36.6062 62.5977 36.6062C62.5977 36.6062 63.5917 36.7521 64.7796 36.8069C66.4577 36.8844 68.3706 36.9072 68.3706 36.9072L71.0679 32.8761Z"
            fill="#455A64"
        />
        <path
            d="M63.4662 33.0471C63.13 33.0261 62.7926 33.0506 62.463 33.12L62.5747 33.2226C62.5747 33.2226 62.7503 33.5441 62.7822 34.4287C62.8164 35.3658 62.7594 36.3462 62.4516 36.5423L62.1529 36.5081C62.1529 36.5081 62.0116 36.7361 62.5177 36.7817C63.0239 36.8273 63.6326 36.9162 63.8219 36.768C63.9564 36.6631 64.2916 36.1934 64.246 35.0033C64.1912 33.5578 63.8173 33.1063 63.4662 33.0471Z"
            fill="#37474F"
        />
        <path
            d="M68.2674 38.9751C68.0234 36.2163 68.199 33.5031 70.8187 32.9126C71.3248 32.7986 73.028 32.7849 74.3481 33.1178C76.3522 33.6285 77.1411 33.8018 78.0121 34.1598C78.0121 34.1598 78.7645 35.4343 78.7941 40.3158C78.8124 43.4872 78.8922 44.21 79.0381 47.5069C76.863 48.5808 70.0184 48.8042 68.6846 46.49C68.8123 44.511 68.598 42.712 68.2674 38.9751Z"
            fill="#8C53C5"
        />
        <path
            d="M77.1697 33.9546C76.8688 34.3148 76.3694 35.4548 76.0639 37.0759C75.576 39.6842 75.2135 42.4978 75.2135 42.4978C74.9581 42.5684 71.26 44.8598 69.7415 45.0058C69.208 45.0354 68.7064 44.7778 68.1728 44.7778C67.9219 44.8028 67.6691 44.8028 67.4182 44.7778C67.2457 44.7413 67.0714 44.7139 66.896 44.6957C66.4172 44.6729 66.611 45.138 66.8755 45.366C67.1765 45.6259 67.8103 45.594 68.0155 45.749C68.2207 45.9041 67.7488 46.0477 67.5732 46.0888C66.8978 46.2253 66.2046 46.25 65.5212 46.1617C65.1688 46.0955 64.8043 46.1518 64.4884 46.3213C64.3903 46.3737 64.3333 46.6109 64.4496 46.6656C64.6776 46.7636 64.7756 46.8229 64.7756 46.8229C64.7795 46.9457 64.8278 47.063 64.9118 47.1527C64.9957 47.2425 65.1094 47.2987 65.2316 47.3108C65.3342 47.3359 65.2088 47.5776 65.5942 47.7851C65.7196 47.8512 65.7629 48.0131 65.8678 48.1065C66.2936 48.3545 66.7796 48.4799 67.2722 48.4691C68.014 48.4996 68.754 48.3754 69.4451 48.1043C69.8828 47.9447 70.2385 47.6072 70.6808 47.4454C72.4684 46.7089 75.2272 45.9656 76.1209 45.6214C77.9609 44.9374 78.0407 44.9373 78.5035 42.9127C79.0621 40.4708 79.3722 38.5078 79.5546 37.4954C79.7712 36.1479 79.0986 34.2897 77.1697 33.9546Z"
            fill="#B16668"
        />
        <path
            d="M77.1163 33.8337C78.1925 34.0777 78.4364 34.2738 78.9403 34.8027C79.4442 35.3317 79.8523 36.0499 79.6494 37.8306C79.4465 39.6113 78.5915 42.9264 78.5915 42.9264C78.5915 42.9264 76.7492 42.3929 75.2969 41.9186C75.2969 41.9186 75.591 39.5474 75.7164 38.6377C75.8783 37.443 76.1336 34.9373 77.1163 33.8337Z"
            fill="#455A64"
        />
        <path
            d="M75.247 42.3381C75.8207 42.2834 76.3995 42.3574 76.941 42.5547C77.983 42.8898 78.3273 43.5488 78.3273 43.5488L78.2566 43.811C78.2566 43.811 78.4344 43.8885 78.5849 43.4325C78.7354 42.9765 78.9087 42.468 78.8015 42.1169C78.6944 41.7658 78.0217 40.9769 77.0961 40.7831C76.1704 40.5893 75.4545 40.8447 75.3427 41.0932C75.231 41.3417 74.9962 42.4042 75.2151 42.4977L75.247 42.3381Z"
            fill="#37474F"
        />
        <path
            d="M74.749 31.4215C74.8402 30.5779 75.6154 29.6568 75.9437 28.9135C76.272 28.1702 76.443 27.6321 76.361 26.9755C76.3131 26.5879 76.0326 26.0635 75.7362 25.9791C75.7891 25.7379 75.7924 25.4885 75.746 25.246C75.6996 25.0035 75.6045 24.7729 75.4663 24.5683C75.3282 24.3636 75.1499 24.1891 74.9424 24.0554C74.7348 23.9217 74.5022 23.8315 74.2588 23.7903C73.984 23.7505 73.712 23.6926 73.4448 23.617C73.1234 23.5053 73.0367 23.2568 72.7608 23.0562C72.3048 22.7142 71.5638 22.7506 71.3199 23.3298C71.0052 22.9718 70.6131 23.006 70.1639 23.1496C69.9749 23.2046 69.8138 23.3295 69.7135 23.4988C69.6131 23.6682 69.581 23.8694 69.6236 24.0616C69.513 24.0001 69.3897 23.965 69.2633 23.959C69.137 23.953 69.0109 23.9762 68.895 24.027C68.7791 24.0778 68.6766 24.1547 68.5953 24.2516C68.514 24.3486 68.4563 24.4631 68.4266 24.586C68.4015 24.8528 68.4722 25.0717 68.3148 25.3179C68.1575 25.5642 67.7676 25.6166 67.6422 25.4068C67.6035 25.7853 67.7676 26.0635 68.1735 26.0179C68.1106 26.1264 68.0724 26.2474 68.0618 26.3724C68.0511 26.4973 68.0683 26.6231 68.1119 26.7406C68.1706 26.887 68.272 27.0123 68.403 27.1002C68.534 27.188 68.6884 27.2343 68.8461 27.2331C68.8709 27.443 68.9508 27.6426 69.0778 27.8116C69.2047 27.9806 69.3741 28.113 69.5688 28.1953C70.2027 28.4506 70.8912 28.5031 71.5616 28.5988L72.3596 28.7151C72.4074 28.7151 72.6446 28.7151 72.6765 28.7584L74.749 31.4215Z"
            fill="#263238"
        />
        <path
            d="M75.6933 30.2792C75.5557 30.3925 75.3845 30.4571 75.2063 30.4629C75.0281 30.4687 74.853 30.4153 74.7084 30.3111L74.795 33.1542C74.7357 33.8382 73.3882 34.7229 72.2277 34.6454C70.8939 34.5587 70.9714 33.9066 71.5164 33.1907L71.4958 32.4292C71.151 32.4601 70.8045 32.4692 70.4584 32.4566C69.7995 32.4064 69.2728 31.9071 69.0426 31.2094C68.7784 30.3004 68.6917 29.3491 68.7872 28.4073C68.8187 28.1804 68.8187 27.9502 68.7872 27.7233C68.7091 27.2256 68.7482 26.7166 68.9012 26.2367C69.5852 25.612 71.2359 25.311 72.8479 26.1592C72.7248 27.094 73.1306 27.8031 73.8237 28.1132C73.8078 28.364 73.8237 28.9978 74.225 28.959C74.5214 28.934 74.5168 28.5623 74.7996 28.2158C75.0823 27.8692 75.7845 27.7392 76.09 28.2158C76.2849 28.5494 76.3521 28.9425 76.2791 29.322C76.2062 29.7014 75.998 30.0415 75.6933 30.2792Z"
            fill="#B16668"
        />
        <path
            d="M71.4961 32.4292C72.5267 32.2422 73.2654 32.0894 73.7601 31.3051C73.9862 30.902 74.1696 30.4764 74.3073 30.0352C74.2758 30.5733 74.158 31.103 73.9585 31.6038C73.7305 32.1008 73.124 32.5842 71.5075 32.8259L71.4961 32.4292Z"
            fill="#9A4A4D"
        />
        <path
            d="M71.6259 28.4073C71.6279 28.4465 71.6377 28.4848 71.6547 28.5202C71.6716 28.5555 71.6954 28.5871 71.7247 28.6132C71.7539 28.6393 71.7881 28.6593 71.8251 28.6721C71.8622 28.6849 71.9014 28.6902 71.9405 28.6878C72.0192 28.6797 72.0916 28.6409 72.1419 28.5799C72.1923 28.5189 72.2166 28.4405 72.2096 28.3617C72.2035 28.2843 72.167 28.2125 72.108 28.162C72.049 28.1116 71.9723 28.0866 71.8949 28.0927C71.8175 28.0987 71.7457 28.1353 71.6952 28.1943C71.6448 28.2533 71.6198 28.3299 71.6259 28.4073Z"
            fill="#263238"
        />
        <path
            d="M71.6445 27.4452L72.3285 27.6732C72.3547 27.5824 72.3458 27.4852 72.3036 27.4007C72.2613 27.3162 72.1888 27.2507 72.1005 27.2172C72.0557 27.2009 72.008 27.1939 71.9604 27.1966C71.9127 27.1994 71.8662 27.2118 71.8235 27.2331C71.7808 27.2545 71.7429 27.2843 71.7122 27.3207C71.6814 27.3572 71.6584 27.3995 71.6445 27.4452Z"
            fill="#263238"
        />
        <path
            d="M69.1485 27.712L69.8325 27.484C69.819 27.4381 69.7961 27.3956 69.7654 27.3589C69.7347 27.3223 69.6968 27.2923 69.6541 27.271C69.6113 27.2496 69.5646 27.2372 69.5169 27.2346C69.4691 27.2321 69.4214 27.2393 69.3765 27.256C69.2889 27.2903 69.2171 27.356 69.175 27.4402C69.1329 27.5244 69.1234 27.6212 69.1485 27.712Z"
            fill="#263238"
        />
        <path
            d="M69.3 28.3959C69.3068 28.4526 69.3302 28.506 69.3671 28.5496C69.404 28.5932 69.4529 28.6249 69.5077 28.641C69.5625 28.6571 69.6208 28.6567 69.6754 28.6399C69.73 28.6231 69.7785 28.5907 69.8148 28.5467C69.8512 28.5026 69.8738 28.4489 69.8799 28.3921C69.886 28.3353 69.8753 28.278 69.8492 28.2272C69.823 28.1765 69.7825 28.1345 69.7327 28.1065C69.6829 28.0785 69.626 28.0658 69.569 28.0698C69.4904 28.0779 69.418 28.1166 69.3677 28.1777C69.3173 28.2387 69.293 28.3171 69.3 28.3959Z"
            fill="#263238"
        />
        <path
            d="M69.6836 29.5518L70.8099 29.985L70.8076 27.876L69.6836 29.5518Z"
            fill="#9A4A4D"
        />
        <path
            d="M71.9164 30.22L70.7285 30.6167C70.7511 30.6963 70.7896 30.7705 70.8415 30.8349C70.8935 30.8993 70.9579 30.9525 71.0309 30.9915C71.1039 31.0304 71.184 31.0543 71.2664 31.0616C71.3488 31.0689 71.4319 31.0595 71.5106 31.0339C71.6698 30.9764 71.8009 30.8598 71.8764 30.7082C71.952 30.5566 71.9663 30.3818 71.9164 30.22Z"
            fill="#9A4A4D"
        />
        <path
            d="M71.7927 30.4228C71.8422 30.4126 71.8927 30.4081 71.9432 30.4091C71.9435 30.5433 71.9022 30.6743 71.8251 30.7841C71.7479 30.8939 71.6386 30.9772 71.5123 31.0224C71.4479 31.0414 71.381 31.0506 71.3139 31.0498C71.298 30.9038 71.3389 30.7573 71.428 30.6406C71.5171 30.5239 71.6477 30.4459 71.7927 30.4228Z"
            fill="#F28F8F"
        />
        <path
            d="M46.3782 84.2695C46.4352 83.2777 47.2902 75.2476 47.2902 75.2476L43.1543 77.3543C43.4005 81.8185 43.7311 83.9435 43.7334 84.7118C43.7426 84.7645 43.7472 84.8179 43.7471 84.8714C43.6855 85.747 44.6956 86.2964 45.4662 86.9417C46.1228 87.509 46.826 88.02 47.5684 88.4693C48.1407 88.7953 49.3399 88.9116 49.5155 88.5628C48.5784 87.7716 47.4886 86.9029 46.7613 86.0251C46.4193 85.6079 46.3258 85.1929 46.3782 84.2695Z"
            fill="#FFBDA7"
        />
        <path
            d="M51.1433 89.6321C51.1842 89.7626 51.1866 89.9021 51.1502 90.0339C51.1138 90.1658 51.0402 90.2843 50.9381 90.3754C50.6007 90.6034 49.634 90.9659 48.4073 90.7219C47.1807 90.478 46.54 90.0653 46.0749 89.6366C45.6098 89.208 44.9577 88.6015 44.4059 88.4442C43.9681 88.3321 43.5562 88.1362 43.193 87.8674C43.0379 87.7009 43.1109 87.0283 43.1109 87.0283L51.1433 89.6321Z"
            fill="#263238"
        />
        <path
            d="M43.7265 84.4839C43.7265 84.4839 43.6421 84.4679 43.5988 84.6229C43.5555 84.778 43.4643 85.3434 43.3594 85.6421C43.1927 86.1692 43.1103 86.7194 43.1155 87.2723C43.1588 87.6234 43.9887 87.9267 44.5154 88.1638C45.1285 88.4717 45.6925 88.8689 46.1889 89.3425C46.9213 89.929 47.7962 90.3106 48.7243 90.4483C49.9532 90.5601 50.7763 90.3047 51.0499 89.9057C51.3941 89.4109 51.1775 89.094 49.8095 88.3667C49.5496 88.2299 48.649 87.6325 48.3435 87.4182C47.5109 86.8739 46.8723 86.0797 46.5195 85.1496C46.4944 85.0329 46.43 84.9283 46.3371 84.8532C46.2517 84.8135 46.157 84.7985 46.0635 84.8099C45.7791 84.8112 45.4959 84.8464 45.2199 84.9148C45.0238 84.9855 44.7457 85.0653 44.7343 85.2955C44.7341 85.3728 44.7456 85.4497 44.7685 85.5235C44.7821 85.5714 44.7913 85.6353 44.7457 85.6603C44.7001 85.6854 44.6499 85.6489 44.6157 85.6147C44.4875 85.4723 44.3708 85.3198 44.2669 85.1587C44.2073 85.0745 44.1377 84.9979 44.0594 84.9307C43.9799 84.8802 43.9037 84.8246 43.8314 84.7643C43.7659 84.6853 43.7289 84.5865 43.7265 84.4839Z"
            fill="#37474F"
        />
        <path
            d="M50.038 88.4876C48.2482 88.4671 47.6098 89.4657 47.4844 90.1019C47.8805 90.2788 48.3009 90.3956 48.7315 90.4484C49.9605 90.5601 50.8314 90.3093 51.1073 89.9103C51.4311 89.4429 51.2669 89.0439 50.038 88.4876Z"
            fill="#FAFAFA"
        />
        <path
            d="M47.3554 88.3027C47.3353 88.301 47.3161 88.293 47.3007 88.2799C47.2927 88.2723 47.2863 88.2631 47.282 88.2529C47.2776 88.2427 47.2754 88.2317 47.2754 88.2207C47.2754 88.2096 47.2776 88.1986 47.282 88.1884C47.2863 88.1782 47.2927 88.169 47.3007 88.1614C47.5292 87.9727 47.7941 87.8331 48.0788 87.7511C48.3636 87.6691 48.6621 87.6466 48.956 87.6849C48.967 87.6862 48.9777 87.6898 48.9873 87.6955C48.9969 87.7011 49.0053 87.7086 49.0119 87.7176C49.0185 87.7265 49.0232 87.7367 49.0257 87.7476C49.0283 87.7584 49.0286 87.7697 49.0267 87.7806C49.0217 87.8029 49.0082 87.8224 48.989 87.8348C48.9698 87.8472 48.9464 87.8515 48.9241 87.8467C48.6584 87.8146 48.389 87.8359 48.1317 87.9095C47.8744 87.9831 47.6345 88.1075 47.4261 88.2754C47.4171 88.2849 47.406 88.2922 47.3938 88.297C47.3816 88.3017 47.3685 88.3037 47.3554 88.3027Z"
            fill="#F0F0F0"
        />
        <path
            d="M46.6284 87.8217C46.6089 87.8199 46.5905 87.8119 46.5759 87.7989C46.568 87.7914 46.5617 87.7824 46.5573 87.7724C46.553 87.7624 46.5508 87.7516 46.5508 87.7408C46.5508 87.7299 46.553 87.7191 46.5573 87.7091C46.5617 87.6991 46.568 87.6901 46.5759 87.6826C46.8041 87.4933 47.0689 87.3531 47.3537 87.2707C47.6385 87.1883 47.9372 87.1656 48.2312 87.2038C48.2418 87.2058 48.252 87.2098 48.261 87.2156C48.2701 87.2215 48.278 87.2291 48.2841 87.238C48.2902 87.2469 48.2946 87.2569 48.2968 87.2675C48.2991 87.278 48.2993 87.2889 48.2973 87.2996C48.2954 87.3102 48.2914 87.3203 48.2855 87.3294C48.2796 87.3385 48.272 87.3463 48.2631 87.3525C48.2542 87.3586 48.2442 87.3629 48.2337 87.3652C48.2231 87.3675 48.2122 87.3676 48.2016 87.3657C47.9357 87.333 47.666 87.3544 47.4086 87.4284C47.1513 87.5025 46.9114 87.6277 46.7036 87.7966C46.6938 87.8062 46.6819 87.8134 46.6688 87.8178C46.6558 87.8221 46.642 87.8234 46.6284 87.8217Z"
            fill="#F0F0F0"
        />
        <path
            d="M45.9527 87.2861C45.9333 87.2843 45.9149 87.2763 45.9003 87.2633C45.8923 87.2556 45.8859 87.2464 45.8816 87.2362C45.8772 87.226 45.875 87.2151 45.875 87.204C45.875 87.1929 45.8772 87.1819 45.8816 87.1717C45.8859 87.1616 45.8923 87.1524 45.9003 87.1447C46.129 86.9564 46.3939 86.817 46.6786 86.735C46.9633 86.6531 47.2618 86.6303 47.5556 86.6682C47.5666 86.6696 47.5773 86.6732 47.5869 86.6788C47.5965 86.6844 47.6049 86.6919 47.6115 86.7009C47.6181 86.7098 47.6228 86.7201 47.6254 86.7309C47.6279 86.7417 47.6282 86.753 47.6263 86.7639C47.6213 86.7858 47.6081 86.8049 47.5894 86.8172C47.5707 86.8295 47.548 86.8341 47.5259 86.8301C47.2601 86.7977 46.9905 86.8191 46.7331 86.8932C46.4758 86.9672 46.236 87.0923 46.028 87.261C46.0178 87.2701 46.0059 87.277 45.993 87.2813C45.98 87.2856 45.9663 87.2872 45.9527 87.2861Z"
            fill="#F0F0F0"
        />
        <path
            d="M45.3041 86.7501C45.2845 86.7488 45.266 86.7408 45.2517 86.7273C45.2438 86.7198 45.2374 86.7108 45.2331 86.7008C45.2288 86.6908 45.2266 86.6801 45.2266 86.6692C45.2266 86.6583 45.2288 86.6475 45.2331 86.6375C45.2374 86.6275 45.2438 86.6185 45.2517 86.611C45.6233 86.2303 46.4145 86.0205 47.0552 86.1368C47.0662 86.1382 47.0769 86.1418 47.0865 86.1474C47.0961 86.153 47.1045 86.1605 47.1111 86.1695C47.1177 86.1784 47.1224 86.1887 47.125 86.1995C47.1275 86.2103 47.1278 86.2216 47.1259 86.2325C47.1237 86.2435 47.1194 86.2539 47.1131 86.2632C47.1069 86.2725 47.0989 86.2804 47.0895 86.2866C47.0802 86.2927 47.0697 86.297 47.0587 86.299C47.0478 86.3011 47.0365 86.301 47.0255 86.2987C46.4487 86.1938 45.71 86.3853 45.3817 86.7227C45.372 86.7334 45.3599 86.7414 45.3464 86.7462C45.3328 86.751 45.3183 86.7523 45.3041 86.7501Z"
            fill="#F0F0F0"
        />
        <path
            d="M38.3973 79.2763L34.6764 78.5308C34.2751 80.8951 34.1634 83.5787 33.7986 85.7994C33.7302 86.2166 33.1602 89.0256 33.1602 89.9034C33.1602 91.2326 34.8109 91.3535 35.0457 90.6011C35.2806 89.8487 35.9782 86.5313 36.0489 86.1916C36.3088 84.7734 37.2277 82.5094 38.1009 80.0356C38.1328 79.9238 38.3563 79.3949 38.3973 79.2763Z"
            fill="#FFBDA7"
        />
        <path
            d="M35.5501 91.2554C35.4384 92.017 35.2674 92.514 34.9413 92.9381C34.6153 93.3622 34.0612 93.4556 33.5232 93.2778C32.9851 93.1 32.3079 92.6918 32.2122 92.0078C32.1223 91.3959 32.1762 90.7714 32.3695 90.1838L35.5501 91.2554Z"
            fill="#263238"
        />
        <path
            d="M33.8424 85.5259C33.6144 85.5259 33.4594 86.545 33.2405 87.5642C33.0125 88.654 32.6181 89.0804 32.4152 89.9742C32.1872 90.9956 32.2031 91.4607 32.5314 92.1196C32.8598 92.7786 34.1639 93.5059 34.9095 92.6691C35.516 91.9851 35.6094 91.1803 35.6482 90.1429C35.6958 89.2691 35.7948 88.399 35.9446 87.5368C36.0563 86.7662 36.3048 86.0024 36.1156 85.8998C36.0905 86.0115 36.0632 86.1529 36.0381 86.2646C36.013 86.3763 35.9583 86.7662 35.8329 86.7594C35.8303 86.6616 35.8379 86.5637 35.8557 86.4675C35.8662 86.4244 35.868 86.3795 35.861 86.3357C35.8539 86.2918 35.8382 86.2498 35.8146 86.2122C35.7498 86.1449 35.6651 86.1001 35.573 86.0845C35.345 86.0229 34.889 85.9636 34.661 85.9249C34.5492 85.9066 34.433 85.8907 34.3235 85.8838C34.2269 85.8735 34.1292 85.8782 34.034 85.8975C34.0067 85.9032 33.9809 85.9144 33.9581 85.9305C33.9354 85.9466 33.9162 85.9672 33.9017 85.991C33.8871 86.0288 33.8794 86.069 33.8789 86.1096C33.8738 86.1813 33.8585 86.2519 33.8333 86.3193C33.8219 86.349 33.7968 86.3832 33.7649 86.374C33.7534 86.3676 33.7442 86.3579 33.7385 86.346C33.7328 86.3342 33.7309 86.3209 33.733 86.3079C33.7444 86.1825 33.7672 86.0936 33.7854 85.95C33.7854 85.8816 33.8151 85.7926 33.8219 85.722C33.8288 85.6513 33.831 85.592 33.8424 85.5259Z"
            fill="#37474F"
        />
        <path
            d="M32.3071 90.5668C32.2136 91.2052 32.2136 91.6475 32.4712 92.1628C32.8018 92.8194 34.2132 93.5604 34.9587 92.7237C35.2943 92.3016 35.5017 91.7919 35.5561 91.2554C35.2072 90.5326 32.9911 90.0356 32.3071 90.5668Z"
            fill="#FAFAFA"
        />
        <path
            d="M35.0672 90.0834C35.0574 90.0816 35.0481 90.0777 35.0398 90.072C34.4421 89.77 33.7703 89.6457 33.1041 89.7141C33.0933 89.716 33.0823 89.7158 33.0715 89.7134C33.0608 89.711 33.0507 89.7065 33.0418 89.7001C33.0329 89.6937 33.0253 89.6856 33.0196 89.6763C33.0138 89.6669 33.01 89.6565 33.0084 89.6457C33.0071 89.635 33.008 89.6242 33.011 89.6139C33.014 89.6036 33.0191 89.594 33.0259 89.5857C33.0327 89.5774 33.0412 89.5705 33.0507 89.5656C33.0602 89.5606 33.0706 89.5576 33.0813 89.5568C33.7876 89.4794 34.5011 89.611 35.1333 89.9352C35.1427 89.9402 35.151 89.9471 35.1576 89.9553C35.1643 89.9636 35.1693 89.9731 35.1723 89.9833C35.1753 89.9934 35.1762 90.0041 35.175 90.0147C35.1737 90.0252 35.1704 90.0354 35.1652 90.0447C35.1549 90.0605 35.14 90.0727 35.1224 90.0797C35.1048 90.0866 35.0855 90.0879 35.0672 90.0834Z"
            fill="#F0F0F0"
        />
        <path
            d="M33.219 88.9504C33.2038 88.947 33.1898 88.9397 33.1782 88.9293C33.1667 88.919 33.1579 88.9058 33.1529 88.8911C33.1502 88.8806 33.1497 88.8697 33.1513 88.859C33.153 88.8483 33.1568 88.838 33.1626 88.8289C33.1684 88.8197 33.1759 88.8118 33.1848 88.8056C33.1938 88.7995 33.2038 88.7952 33.2144 88.7931C34.2564 88.5651 35.2163 89.1305 35.2664 89.1556C35.2846 89.1665 35.2976 89.1841 35.3028 89.2046C35.3079 89.2252 35.3047 89.2469 35.2938 89.265C35.2806 89.2825 35.2615 89.2944 35.24 89.2986C35.2186 89.3028 35.1963 89.299 35.1775 89.2878C35.1775 89.2878 34.2336 88.7269 33.2646 88.9481C33.2502 88.9543 33.234 88.9551 33.219 88.9504Z"
            fill="#F0F0F0"
        />
        <path
            d="M33.3456 88.23C33.3304 88.2266 33.3164 88.2193 33.3048 88.2089C33.2933 88.1986 33.2845 88.1854 33.2795 88.1707C33.2746 88.1499 33.2781 88.1279 33.2892 88.1096C33.3003 88.0913 33.318 88.0781 33.3388 88.0727C33.693 87.9911 34.0599 87.981 34.4181 88.0428C34.7763 88.1047 35.1186 88.2373 35.425 88.4329C35.4337 88.4386 35.4412 88.446 35.4469 88.4548C35.4526 88.4635 35.4564 88.4733 35.4582 88.4836C35.4599 88.4939 35.4594 88.5044 35.4569 88.5146C35.4543 88.5247 35.4496 88.5341 35.4432 88.5423C35.4293 88.5596 35.4094 88.5711 35.3875 88.5745C35.3655 88.5778 35.3431 88.5729 35.3246 88.5606C34.7466 88.1993 34.0497 88.0797 33.3844 88.2277L33.3456 88.23Z"
            fill="#F0F0F0"
        />
        <path
            d="M33.4847 87.4183C33.4673 87.4141 33.4519 87.4041 33.4409 87.3901C33.4298 87.376 33.4238 87.3587 33.4238 87.3408C33.4238 87.3229 33.4298 87.3056 33.4409 87.2915C33.4519 87.2775 33.4673 87.2675 33.4847 87.2633C34.5608 86.9851 35.5367 87.619 35.5823 87.6463C35.5912 87.6518 35.5989 87.6591 35.6048 87.6678C35.6108 87.6764 35.6148 87.6862 35.6168 87.6965C35.6187 87.7068 35.6184 87.7174 35.616 87.7276C35.6136 87.7378 35.6091 87.7474 35.6028 87.7558C35.5896 87.7733 35.5703 87.7851 35.5487 87.7889C35.5271 87.7927 35.5049 87.7882 35.4865 87.7763C35.4865 87.7763 34.5312 87.1584 33.5303 87.4183H33.4847Z"
            fill="#EBEBEB"
        />
        <path
            d="M39.7524 52.6277C39.6839 52.9407 39.5327 53.2297 39.3146 53.4644C38.1997 54.7754 37.3059 56.0522 37.0095 58.5785C36.2731 64.828 35.8695 71.3328 35.8695 71.3328C35.7806 71.8823 35.2288 72.8216 34.7683 75.6648C34.367 78.1523 34.1367 81.5928 34.1367 81.5928C34.1367 81.5928 35.8832 82.6028 37.4997 82.1354C38.2977 80.1541 40.0465 74.9785 40.9608 72.3086C41.7907 69.885 42.5134 66.278 42.5134 66.278L43.1632 71.4194C42.9658 72.4966 42.8893 73.5925 42.9352 74.6867C42.99 76.3807 43.3456 81.2531 43.3456 81.2531C43.3456 81.2531 45.2973 82.0146 46.7223 81.187C46.7223 81.187 47.7802 72.8422 47.9695 70.8152C48.3206 67.0418 48.959 59.3126 48.2112 54.3992C48.1989 54.0229 48.2065 53.6462 48.234 53.2706C46.809 54.251 41.4282 53.9569 39.7524 52.6277Z"
            fill="#37474F"
        />
        <path
            d="M41.6976 54.5817C41.6791 54.5763 41.6594 54.578 41.6421 54.5865C41.6248 54.5949 41.6113 54.6094 41.6041 54.6273C41.5061 54.9123 41.4286 55.1676 41.3533 55.4184C41.0797 56.3167 40.8814 56.9665 40.1267 57.6163C39.8775 57.8226 39.5895 57.9768 39.2798 58.07C38.9701 58.1632 38.6448 58.1935 38.3232 58.159C38.7792 56.3099 39.5476 54.8689 40.6625 53.7198C40.6691 53.7133 40.6744 53.7054 40.6779 53.6968C40.6815 53.6882 40.6834 53.679 40.6834 53.6697C40.6834 53.6603 40.6815 53.6511 40.6779 53.6425C40.6744 53.6339 40.6691 53.6261 40.6625 53.6195C40.648 53.6068 40.6293 53.5999 40.61 53.5999C40.5908 53.5999 40.5721 53.6068 40.5576 53.6195C39.4176 54.8005 38.631 56.2848 38.1682 58.1795C37.9878 58.9507 37.8583 59.7329 37.7806 60.5211C37.4135 64.0414 36.8686 69.3583 36.6839 70.7537C36.5662 71.5026 36.3651 72.236 36.0842 72.9402C35.838 73.6242 35.5621 74.4199 35.2817 75.5052C34.6775 77.8513 34.5452 81.7798 34.543 81.8185C34.5429 81.8371 34.55 81.855 34.5627 81.8686C34.5754 81.8821 34.5928 81.8903 34.6114 81.8915C34.6207 81.8924 34.6302 81.8914 34.6391 81.8886C34.6481 81.8857 34.6563 81.881 34.6634 81.8748C34.6704 81.8686 34.6762 81.861 34.6801 81.8525C34.6841 81.844 34.6863 81.8348 34.6866 81.8254C34.6866 81.7843 34.8211 77.8719 35.4208 75.5394C35.6989 74.4587 35.9748 73.6767 36.2188 72.9858C36.5035 72.272 36.7077 71.5287 36.8275 70.7697C37.0008 69.3743 37.5571 64.0573 37.9242 60.537C37.9993 59.7825 38.1242 59.0338 38.2981 58.2958H38.3734C38.7066 58.3256 39.0425 58.2894 39.3617 58.1892C39.681 58.0891 39.9773 57.9269 40.2338 57.7121C41.0182 57.0281 41.2211 56.3692 41.5015 55.4481C41.5768 55.1995 41.6543 54.9442 41.7523 54.6615C41.7545 54.6438 41.7502 54.6259 41.7401 54.6112C41.73 54.5965 41.7149 54.586 41.6976 54.5817Z"
            fill="#455A64"
        />
        <path
            d="M34.1895 80.8223C34.682 81.3717 36.9027 81.83 37.7623 81.4607L37.3063 82.7717C37.3063 82.7717 36.7454 83.0567 35.5621 82.8469C34.1941 82.6029 34.0801 82.1492 34.0801 82.1492L34.1895 80.8223Z"
            fill="#455A64"
        />
        <path
            d="M43.2793 80.5167C43.9633 80.8131 45.9902 80.8405 46.8065 80.4255L46.6765 81.545C46.6765 81.545 46.2045 81.9258 45.003 81.9509C43.8014 81.9759 43.3477 81.6089 43.3477 81.6089L43.2793 80.5167Z"
            fill="#455A64"
        />
        <path
            d="M42.5117 66.2803L43.8204 60.1584C44.4218 60.2162 45.0283 60.1345 45.593 59.9198C46.1576 59.7051 46.6652 59.3631 47.0763 58.9204C47.0763 58.9204 46.9942 60.4731 44.3904 60.9724L42.9677 66.5949L43.1615 71.4285L42.5117 66.2803Z"
            fill="#263238"
        />
        <path
            d="M46.0312 43.5236C48.0604 43.5236 48.5392 43.8907 51.0472 44.8916L53.8813 46.0111C55.0213 44.8187 56.2981 43.7152 57.4198 42.4338C57.8576 41.8547 57.7026 41.0658 57.9784 40.4251C58.1472 39.8688 58.5781 39.4151 58.6898 38.8291C58.8107 38.1611 59.3738 38.6718 59.3487 39.1118C59.3359 39.4537 59.29 39.7934 59.2119 40.1264C59.2119 40.1743 59.2119 40.2336 59.2438 40.2632C59.3458 40.2721 59.4478 40.2464 59.5334 40.1903C60.0886 39.6966 60.5667 39.1225 60.9516 38.4871C61.1796 38.1656 61.8795 37.7142 61.9707 38.3594C61.9376 38.5019 61.9376 38.6501 61.9707 38.7926C62.0076 38.8827 62.0337 38.9768 62.0483 39.0731C62.0483 39.2714 61.8727 39.4424 61.8887 39.6385C61.8887 39.7343 61.9502 39.8186 61.9593 39.9144C61.9753 40.0694 61.8658 40.2085 61.8362 40.3704C61.7929 40.5733 61.8886 40.7854 61.8362 40.986C61.7462 41.2314 61.6308 41.4668 61.4919 41.6882C61.264 42.1105 60.9853 42.5033 60.662 42.8579C60.206 43.3709 59.522 43.6171 59.1207 44.1689C58.3159 45.3089 56.6492 47.8579 55.9948 48.6012C55.0828 49.6249 54.7135 49.6272 52.3788 49.0412C50.2948 48.5168 46.982 47.3404 46.982 47.3404L46.0312 43.5236Z"
            fill="#FFBDA7"
        />
        <path
            d="M59.191 40.708C59.3119 41.6496 59.191 42.377 58.7168 43.1043C59.4783 42.6528 59.8386 41.7226 59.191 40.708Z"
            fill="#F0997A"
        />
        <path
            d="M46.0137 43.4439C47.1087 43.3998 48.1995 43.6024 49.2057 44.0367C50.3936 44.511 53.5924 45.8835 53.5924 45.8835C53.2394 46.3301 52.988 46.8483 52.856 47.402C52.6985 48.0015 52.7103 48.6328 52.8902 49.226C52.8902 49.226 51.6156 48.9182 50.4825 48.5602L46.8664 47.4202L46.0137 43.4439Z"
            fill="#455A64"
        />
        <path
            d="M53.8824 46.0042C53.8824 46.0042 53.4743 46.1638 53.2418 47.0713C53.0092 47.9787 53.0388 48.9546 53.2418 49.2532L53.5176 49.3125C53.5176 49.3125 53.4698 49.5268 52.9887 49.4562C52.5076 49.3855 51.8487 49.1711 51.7461 49.0389C51.6435 48.9067 51.3995 48.0494 51.664 46.8866C51.9285 45.7238 52.5965 45.4092 52.9454 45.4867C53.2984 45.5977 53.6416 45.7381 53.9714 45.9062"
            fill="#37474F"
        />
        <path
            d="M48.3868 50.3614C48.4096 51.2871 48.2614 53.7814 48.2614 53.7814C48.5077 56.1047 48.5327 57.377 48.5327 57.377C44.3672 59.4404 37.9923 57.7076 37.2422 56.7819C37.6982 54.7641 39.0297 52.9515 38.8382 51.8594L38.3024 47.8146C38.3024 47.8146 39.8277 46.629 39.757 43.64C40.5678 43.3909 41.4049 43.2379 42.2513 43.184C43.5104 43.167 44.7686 43.254 46.0133 43.4439C46.6609 43.8246 47.9992 45.0695 48.7493 46.3577C49.4995 47.6459 49.6613 49.1051 48.3868 50.3614Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M45.2398 50.1586C45.2398 50.1586 44.1409 51.0706 42.839 50.6579C42.4742 50.5553 42.1419 50.3605 41.8743 50.0921C41.6067 49.8237 41.4129 49.4909 41.3114 49.1257C41.3114 49.1257 41.2225 50.6032 42.8641 51.0501C43.3033 51.176 43.7714 51.1573 44.1992 50.9968C44.6271 50.8363 44.9919 50.5424 45.2398 50.1586Z"
            fill="black"
        />
        <path
            d="M48.2204 35.7421C48.7562 36.7612 48.5829 37.7941 47.6253 38.3458C47.6253 38.4986 47.2537 41.1981 48.1611 41.7134C47.867 42.5319 46.8433 43.1771 43.877 42.9514C40.9107 42.7257 40.0283 42.3518 39.7251 41.474C39.4219 40.5962 39.3398 35.3773 41.426 35.2815C43.2112 32.4794 47.1944 33.7949 48.2204 35.7421Z"
            fill="#37474F"
        />
        <path
            d="M46.2011 35.3613C46.8851 35.6167 47.5988 36.4284 47.7059 39.4038C47.7834 41.5242 47.2135 41.9118 46.892 42.1238C46.3744 42.4795 45.8363 42.4658 45.1569 42.3997L45.0817 43.656C45.0817 43.656 46.4497 45.6304 45.7657 46.5174C44.7202 46.3037 43.7552 45.8023 42.9795 45.0696C42.531 44.6757 42.1599 44.2017 41.8851 43.6719L42.0128 40.9564C42.0128 40.9564 41.6411 41.3509 40.9366 40.8288C40.3529 40.3978 40.1158 39.6272 40.5194 39.178C40.626 39.0708 40.7559 38.9895 40.899 38.9403C41.0421 38.8912 41.1945 38.8757 41.3446 38.8948C41.4946 38.914 41.6382 38.9674 41.7644 39.0509C41.8905 39.1344 41.9958 39.2458 42.0721 39.3764C42.0721 39.3764 42.8267 39.3764 42.9042 37.9696C42.9042 37.9696 42.9772 37.6276 43.6475 37.4863C45.736 37.0348 46.249 35.7261 46.2011 35.3613Z"
            fill="#FFBDA7"
        />
        <path
            d="M45.1568 42.3996C44.092 42.297 42.9224 41.8296 42.6602 41.2915C42.6808 41.493 42.7532 41.6858 42.8702 41.8512C42.9872 42.0166 43.145 42.1489 43.3282 42.2354C43.914 42.4715 44.5169 42.6628 45.1317 42.8077L45.1568 42.3996Z"
            fill="#F0997A"
        />
        <path
            d="M44.9662 40.3591L45.9694 40.635C45.9514 40.7003 45.9208 40.7614 45.8792 40.8149C45.8377 40.8683 45.786 40.913 45.7271 40.9465C45.6682 40.9799 45.6034 41.0015 45.5362 41.0098C45.469 41.0182 45.4008 41.0132 45.3355 40.9952C45.2025 40.9594 45.089 40.8724 45.0198 40.7533C44.9506 40.6341 44.9313 40.4924 44.9662 40.3591Z"
            fill="#B16668"
        />
        <path
            d="M45.046 40.5667H44.957C44.9687 40.6432 44.9973 40.7163 45.0408 40.7804C45.0843 40.8445 45.1416 40.8981 45.2085 40.9372C45.2754 40.9763 45.3501 41 45.4273 41.0065C45.5046 41.013 45.5822 41.0022 45.6547 40.9748C45.6024 40.856 45.5174 40.7546 45.4096 40.6823C45.3018 40.61 45.1757 40.5699 45.046 40.5667Z"
            fill="#F28F8F"
        />
        <path
            d="M44.8816 38.6058C44.8907 38.687 44.8676 38.7685 44.8173 38.8328C44.767 38.8972 44.6935 38.9393 44.6126 38.9501C44.5733 38.9576 44.5329 38.9572 44.4938 38.9488C44.4547 38.9404 44.4177 38.9243 44.3849 38.9013C44.3522 38.8783 44.3244 38.849 44.3032 38.8151C44.282 38.7812 44.2678 38.7434 44.2615 38.7039C44.253 38.6228 44.2763 38.5416 44.3265 38.4774C44.3767 38.4132 44.4498 38.3709 44.5305 38.3596C44.5698 38.3521 44.6102 38.3525 44.6493 38.3609C44.6884 38.3693 44.7254 38.3854 44.7582 38.4084C44.7909 38.4314 44.8187 38.4607 44.8399 38.4946C44.8611 38.5285 44.8753 38.5663 44.8816 38.6058Z"
            fill="black"
        />
        <path
            d="M47.2559 37.7803L46.6016 37.5295C46.6388 37.4485 46.7055 37.3846 46.7881 37.3509C46.8707 37.3173 46.963 37.3163 47.0463 37.3482C47.1296 37.3801 47.1976 37.4426 47.2365 37.5228C47.2755 37.6031 47.2824 37.6951 47.2559 37.7803Z"
            fill="black"
        />
        <path
            d="M44.6723 37.6959L44.043 38.0106C44.0216 37.9692 44.0087 37.924 44.005 37.8776C44.0013 37.8312 44.007 37.7845 44.0216 37.7403C44.0363 37.6961 44.0597 37.6553 44.0904 37.6202C44.121 37.5852 44.1584 37.5567 44.2003 37.5363C44.2412 37.513 44.2866 37.4985 44.3334 37.494C44.3803 37.4894 44.4276 37.4949 44.4722 37.51C44.5168 37.5251 44.5577 37.5494 44.5922 37.5815C44.6267 37.6135 44.654 37.6526 44.6723 37.6959Z"
            fill="black"
        />
        <path
            d="M47.249 38.3915C47.2587 38.4724 47.2362 38.5539 47.1862 38.6183C47.1363 38.6828 47.063 38.7249 46.9822 38.7357C46.943 38.7429 46.9027 38.7423 46.8637 38.7337C46.8247 38.7252 46.7878 38.709 46.7551 38.6861C46.7224 38.6632 46.6947 38.634 46.6734 38.6003C46.6521 38.5665 46.6377 38.5289 46.6311 38.4895C46.6214 38.4086 46.6439 38.3271 46.6939 38.2626C46.7438 38.1982 46.8171 38.156 46.8979 38.1452C46.9372 38.1377 46.9776 38.1381 47.0167 38.1465C47.0558 38.1549 47.0928 38.1711 47.1255 38.194C47.1583 38.217 47.1861 38.2463 47.2073 38.2802C47.2285 38.3141 47.2427 38.352 47.249 38.3915Z"
            fill="black"
        />
        <path
            d="M45.6992 38.2319L46.0526 39.9397L47.0102 39.5338C46.6409 39.112 46.0572 38.3437 45.6992 38.2319Z"
            fill="#F0997A"
        />
        <path
            d="M39.7334 43.8999C40.0959 44.1963 40.7252 45.0832 41.4366 46.5721C42.4671 48.7244 44.2182 51.5014 44.2182 51.5014C44.4849 51.5219 48.5707 52.9355 50.096 52.7759C50.6273 52.7053 51.0719 52.3655 51.594 52.2584C51.8446 52.2329 52.0921 52.1825 52.3327 52.1079C52.4951 52.0399 52.661 51.9805 52.8297 51.9301C53.2971 51.8138 53.1945 52.3085 52.9802 52.5821C52.734 52.8945 52.1047 52.9903 51.9428 53.1727C51.7809 53.3551 52.262 53.4166 52.4399 53.4235C53.1294 53.4303 53.8153 53.3216 54.469 53.102C54.8033 52.971 55.172 52.9565 55.5156 53.0609C55.6204 53.0951 55.7231 53.3186 55.6182 53.3915C55.4244 53.5306 55.3286 53.6195 55.3286 53.6195C55.3484 53.74 55.3241 53.8636 55.2599 53.9675C55.1958 54.0714 55.0963 54.1487 54.9798 54.185C54.8817 54.2283 55.0527 54.4403 54.713 54.7185C54.6036 54.8074 54.5922 54.9739 54.5055 55.0879C54.1344 55.4111 53.6818 55.6264 53.1968 55.7103C52.4767 55.8768 51.7299 55.8923 51.0035 55.7559C50.5475 55.6829 50.1302 55.4185 49.6651 55.3432C47.7681 54.9625 44.8999 54.8872 43.9651 54.7299C42.0271 54.4039 42.2163 54.3309 41.0011 52.6346C39.6992 50.8106 38.3768 48.6013 38.0052 47.6437C37.5036 46.3691 37.4671 44.5064 39.7334 43.8999Z"
            fill="#FFBDA7"
        />
        <path
            d="M39.755 43.6445C38.6355 44.0071 38.1179 44.6158 37.8284 45.2884C37.5388 45.961 37.4522 46.9596 38.273 48.5556C39.0938 50.1516 41.2005 53.0815 41.2005 53.0815C41.2005 53.0815 42.7395 51.9414 43.9365 50.9884C43.9365 50.9884 42.7303 48.9068 42.2675 48.1133C41.8047 47.3199 40.9725 44.7891 39.755 43.6445Z"
            fill="#455A64"
        />
        <path
            d="M44.2186 51.5013C44.2186 51.5013 43.4799 51.4808 42.6226 52.1603C41.7653 52.8397 41.624 53.5283 41.624 53.5283L41.82 53.811C41.82 53.811 41.6901 53.9227 41.3891 53.5465C41.0882 53.1703 40.7484 52.7553 40.7256 52.3883C40.7028 52.0212 41.0585 51.0476 41.8656 50.5437C42.6728 50.0399 43.4229 50.0285 43.6144 50.2223C43.8059 50.4161 44.3987 51.3303 44.2254 51.4922L44.2186 51.5013Z"
            fill="#37474F"
        />
        <path
            d="M68.5965 91.3241L53.0742 10.0147L53.7468 9.88477L69.2964 91.3423C69.3329 91.5339 68.6421 91.584 68.5965 91.3241Z"
            fill="#455A64"
        />
        <path
            d="M54.1557 9.95998C54.153 10.1094 54.1059 10.2546 54.0205 10.3773C53.9351 10.4999 53.8153 10.5945 53.6761 10.6489C53.5369 10.7033 53.3847 10.7152 53.2388 10.6831C53.0928 10.6509 52.9597 10.5762 52.8563 10.4683C52.7529 10.3604 52.6839 10.2242 52.6579 10.0771C52.632 9.92989 52.6503 9.77833 52.7106 9.6416C52.7709 9.50486 52.8705 9.3891 52.9966 9.30899C53.1228 9.22889 53.2699 9.18804 53.4193 9.19162C53.5181 9.1937 53.6155 9.21522 53.706 9.25496C53.7965 9.2947 53.8783 9.35188 53.9467 9.42323C54.0151 9.49459 54.0687 9.57872 54.1046 9.67081C54.1405 9.76291 54.1578 9.86117 54.1557 9.95998Z"
            fill="#37474F"
        />
        <path
            d="M53.8664 8.55998C53.9066 8.7556 53.8674 8.95918 53.7575 9.12594C53.6476 9.2927 53.476 9.40897 53.2804 9.44918C53.0848 9.4894 52.8812 9.45025 52.7144 9.34036C52.5477 9.23047 52.4314 9.05884 52.3912 8.86322C52.1632 7.81442 52.6466 6.35522 52.6466 6.35522C52.6466 6.35522 53.652 7.51802 53.8664 8.55998Z"
            fill="#37474F"
        />
        <path
            d="M53.1999 10.6643C49.4949 17.3151 42.1921 16.0679 36.5081 16.8203C30.3521 17.6251 27.0233 20.3019 25.6895 23.3274C27.3561 22.5226 32.3744 22.0187 34.1779 23.0082C34.1779 23.0082 28.7538 28.2522 30.0215 34.9007C31.2869 31.7383 34.0183 29.016 40.3362 29.0981C45.8857 29.1688 53.1042 30.327 56.0317 25.5048L53.1999 10.6643Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M50.1187 26.6426C45.4994 29.0799 30.8755 25.1219 30.0273 34.8917C31.2927 31.7293 34.0242 29.007 40.3421 29.0891C45.8916 29.1597 53.1101 30.318 56.0376 25.4958L53.2058 10.6758C54.2979 16.5035 54.738 24.2144 50.1187 26.6426Z"
            fill="black"
        />
        <path
            opacity="0.7"
            d="M43.3576 18.421L44.6869 20.8127L47.4229 20.6599C47.4595 20.6576 47.4959 20.6664 47.5275 20.6851C47.559 20.7038 47.5842 20.7316 47.5997 20.7648C47.6152 20.7981 47.6203 20.8352 47.6144 20.8714C47.6085 20.9076 47.5918 20.9412 47.5665 20.9677L45.7038 22.9696L46.6933 25.5209C46.7046 25.5547 46.7058 25.5911 46.6969 25.6257C46.688 25.6602 46.6693 25.6914 46.643 25.7155C46.6167 25.7396 46.584 25.7556 46.5488 25.7615C46.5136 25.7675 46.4775 25.7631 46.4448 25.7489L43.9596 24.5998L41.8392 26.328C41.8109 26.3511 41.7764 26.3652 41.7401 26.3685C41.7037 26.3718 41.6673 26.3641 41.6353 26.3464C41.6034 26.3287 41.5775 26.3019 41.561 26.2693C41.5445 26.2368 41.5382 26.2001 41.5428 26.1639L41.8848 23.4484L39.5842 21.9664C39.5535 21.9467 39.5293 21.9183 39.5149 21.8848C39.5004 21.8512 39.4964 21.8142 39.5033 21.7783C39.5101 21.7424 39.5276 21.7095 39.5534 21.6837C39.5793 21.6578 39.6122 21.6404 39.6481 21.6335L42.3225 21.1L43.0248 18.4643C43.0344 18.4299 43.054 18.399 43.081 18.3756C43.1081 18.3521 43.1414 18.3372 43.1769 18.3326C43.2124 18.328 43.2484 18.3339 43.2806 18.3496C43.3127 18.3653 43.3395 18.3901 43.3576 18.421Z"
            fill="white"
        />
        <path
            d="M36.3652 97.8949L38.8071 96.3103L39.9699 99.6801L37.5212 100.553L36.3652 97.8949Z"
            fill="#C8856A"
        />
        <path
            d="M48.1878 85.5282C48.4158 88.8684 48.9516 92.6486 48.0487 95.8931C47.7295 97.0331 47.3282 97.6304 44.0929 98.6291C40.7914 99.6391 39.0609 100.109 39.0609 100.109C39.4098 98.8958 38.3268 97.2337 37.3281 97.2497C37.3281 97.2497 37.889 96.91 39.045 96.1097C40.853 94.8557 41.4048 94.402 42.775 94.2857C42.775 94.2857 41.5302 89.6003 41.464 86.9372C41.4276 85.4666 48.1878 85.5282 48.1878 85.5282Z"
            fill="#263238"
        />
        <path
            d="M44.4918 97.3522C46.3158 96.561 46.3637 96.3376 46.5438 95.4028C46.724 94.468 46.2611 90.3868 45.3058 87.6052L45.4973 87.5391C46.4367 90.2751 46.9383 94.395 46.7445 95.4393C46.5507 96.4835 46.4435 96.732 44.5785 97.5391C43.0919 98.1798 40.1188 98.8912 39.0814 99.1351C39.0677 99.069 39.0495 99.0052 39.0312 98.9391C40.0595 98.6974 43.0235 97.9883 44.4918 97.3522Z"
            fill="black"
        />
        <path
            d="M39.9219 99.915L38.9916 100.143C39.1027 99.4688 38.9553 98.7775 38.5789 98.2073C37.9474 97.2292 37.2109 97.2793 37.2109 97.2793L38.0773 96.6865C38.4095 96.7521 38.7227 96.8917 38.9935 97.095C39.2642 97.2983 39.4857 97.56 39.6414 97.8607C39.9959 98.4838 40.0964 99.2197 39.9219 99.915Z"
            fill="#37474F"
        />
        <path
            d="M42.3848 94.3152C42.5138 94.2927 42.644 94.2767 42.7746 94.2673C43.1759 95.2888 44.1951 95.7174 45.3807 95.7767C44.3912 96.1666 42.8134 95.6559 42.3848 94.3152Z"
            fill="black"
        />
        <path
            d="M34.1094 103.146C34.1094 103.146 35.4546 104.475 36.0177 104.954C36.0509 104.983 36.0859 105.009 36.1226 105.034C36.2876 105.131 36.4766 105.18 36.668 105.175C36.8595 105.17 37.0457 105.112 37.2056 105.006C37.4049 104.705 37.5089 104.352 37.504 103.991C37.4991 103.63 37.3856 103.279 37.1783 102.984C37.1783 102.984 37.0962 102.972 37.2421 102.352C37.3333 101.91 37.4336 101.461 37.5659 101.028C37.5841 100.94 37.6227 100.859 37.6786 100.789C37.7345 100.72 37.806 100.665 37.8873 100.629C38.0583 100.531 38.3319 100.449 38.4391 100.271C38.5463 100.093 37.9831 100.282 38.0013 100.088C38.0196 99.8945 38.0606 99.5821 38.0629 99.3519C38.0591 99.0529 37.9601 98.763 37.7802 98.5242C37.4861 98.1617 37.046 97.651 36.5672 97.7581C36.3575 97.806 36.4737 97.8858 35.8057 98.1526C35.523 98.2643 35.1012 98.1708 34.7523 98.6245C34.7318 98.6519 34.1094 103.146 34.1094 103.146Z"
            fill="#455A64"
        />
        <path
            d="M37.1777 102.984C37.2339 103.094 37.2819 103.207 37.3214 103.324C37.4978 103.878 37.4554 104.48 37.2028 105.004C38.3428 105.134 38.7646 104.548 38.7646 104.548C38.7646 104.548 38.9425 104.005 38.3839 103.47C38.318 103.404 38.2462 103.344 38.1695 103.292H38.1581C37.8718 103.089 37.5287 102.981 37.1777 102.984Z"
            fill="#FAFAFA"
        />
        <path
            d="M34.3343 98.7295C34.0174 98.7819 34.006 99.8353 34.031 100.647C34.0561 101.459 34.0994 101.753 33.8714 102.377C33.6434 103.002 33.5317 103.278 33.7438 103.57C33.9558 103.862 35.8436 105.271 35.8436 105.271C35.7215 104.438 35.7338 103.591 35.8801 102.763C36.1218 101.324 36.2176 100.385 35.6909 99.5982C35.5381 99.3665 35.3384 99.1696 35.1047 99.0199C34.8711 98.8703 34.6086 98.7713 34.3343 98.7295Z"
            fill="#263238"
        />
        <path
            d="M38.7663 104.548C38.1755 104.837 37.5219 104.974 36.8648 104.947C35.9117 104.86 35.9391 104.491 36.0691 103.367C36.199 102.243 36.4817 100.706 36.1853 99.9468C35.8889 99.1876 35.0453 98.6153 34.7512 98.6244C34.6045 98.6136 34.4582 98.6504 34.334 98.7293C34.334 98.7293 35.018 98.8273 35.5333 99.7051C35.9619 100.419 35.8547 101.62 35.6541 102.717C35.4535 103.814 35.2391 105.09 36.0827 105.405C36.4968 105.538 36.9335 105.587 37.3668 105.548C37.8001 105.509 38.221 105.383 38.6044 105.177C38.6567 105.147 38.7024 105.106 38.739 105.058C38.7755 105.01 38.802 104.955 38.8171 104.896C38.8322 104.838 38.8354 104.777 38.8267 104.717C38.818 104.657 38.7975 104.6 38.7663 104.548Z"
            fill="#37474F"
        />
        <path
            d="M37.1964 102.564C37.2254 102.595 37.248 102.63 37.2629 102.67C37.2778 102.709 37.2846 102.751 37.283 102.792C37.2646 102.909 37.2142 103.019 37.1373 103.108C37.0604 103.198 36.9601 103.265 36.8475 103.301C36.7176 103.344 36.6195 103.301 36.8589 103.105C37.0176 102.958 37.1341 102.771 37.1964 102.564Z"
            fill="#FAFAFA"
        />
        <path
            d="M37.3731 101.798C37.4091 101.853 37.4245 101.918 37.4164 101.983C37.3218 102.217 37.1392 102.404 36.908 102.505C36.68 102.553 36.8419 102.35 36.9581 102.266C37.1076 102.12 37.2463 101.964 37.3731 101.798Z"
            fill="#FAFAFA"
        />
        <path
            d="M37.5998 100.961C37.6225 100.981 37.6405 101.005 37.6524 101.032C37.6643 101.059 37.6697 101.089 37.6682 101.119C37.6682 101.21 37.1712 101.438 37.0412 101.399C36.9113 101.36 36.8885 101.313 37.0754 101.237C37.2624 101.162 37.5998 100.961 37.5998 100.961Z"
            fill="#FAFAFA"
        />
        <path
            d="M63.2258 99.9539C63.2854 100.17 63.2932 100.397 63.2486 100.617C63.1985 100.791 62.8314 101.356 61.7415 101.739C60.768 102.081 59.5459 101.949 58.9645 101.878C58.1733 101.782 57.8085 102.124 56.6845 102.3C56.3333 102.364 55.9723 102.351 55.6269 102.26C55.2814 102.17 54.9599 102.006 54.6849 101.778C54.6291 101.711 54.5883 101.633 54.5651 101.55C54.5419 101.466 54.5369 101.378 54.5504 101.292C54.5686 100.905 54.5983 100.955 54.6439 100.959L63.2258 99.9539Z"
            fill="#37474F"
        />
        <path
            d="M54.7974 99.8057C54.7769 99.8809 54.7609 99.9584 54.745 100.034C54.6544 100.424 54.6237 100.826 54.6538 101.226C54.7267 101.36 54.8272 101.478 54.9486 101.57C55.07 101.663 55.2096 101.729 55.3583 101.764C55.8914 101.955 56.4675 101.991 57.0204 101.869C57.4764 101.776 58.1239 101.475 58.6734 101.516C59.2705 101.593 59.8728 101.623 60.4746 101.605C60.875 101.587 61.2717 101.519 61.6556 101.404C62.3074 101.231 62.8641 100.808 63.2038 100.225C63.2471 99.4705 62.6338 98.9757 62.1937 98.7842C61.1312 98.3282 59.932 98.734 59.932 98.734L59.6812 98.7523C59.2936 98.7796 58.8421 98.8207 58.5412 98.652C58.3991 98.5752 58.2868 98.4532 58.222 98.3054C58.1422 98.1253 58.1148 97.9657 57.9529 97.9474C57.9529 97.9474 55.7048 97.6875 55.2032 98.0934C55.03 98.2325 54.9752 98.8823 54.9479 99.0692C54.9205 99.2174 54.9137 99.3747 54.8749 99.5252C54.8453 99.6141 54.8202 99.7099 54.7974 99.8057Z"
            fill="#455A64"
        />
        <path
            d="M59.4165 98.7728C59.7106 98.7272 60.5428 99.3018 60.6066 99.8284C60.5314 99.963 60.3536 99.9265 60.2601 99.8695C60.1073 99.4819 59.8041 98.8914 59.1816 98.7797L59.4165 98.7728Z"
            fill="white"
        />
        <path
            d="M59.9311 98.7362C60.1591 98.7248 61.1281 99.0166 61.4929 99.687C61.8828 100.403 61.6548 101.406 61.6548 101.406C62.1427 101.271 62.5873 101.012 62.9453 100.654C63.0741 100.523 63.1625 100.357 63.1997 100.177C63.2369 99.9973 63.2214 99.8105 63.1551 99.6391C63.0447 99.3891 62.8739 99.1705 62.658 99.003C62.202 98.6473 61.7757 98.547 60.9389 98.547C60.5068 98.5205 60.0744 98.5947 59.6758 98.7636L59.9311 98.7362Z"
            fill="#FAFAFA"
        />
        <path
            d="M58.8498 98.7409C59.1303 98.8298 59.8074 99.2675 59.8735 99.7942C59.7983 99.9287 59.6205 99.89 59.527 99.8353C59.47 99.4522 59.2602 98.9734 58.5078 98.636L58.8498 98.7409Z"
            fill="white"
        />
        <path
            d="M58.7375 95.159C58.7375 95.159 58.1948 97.9885 58.1424 98.2279C58.1023 98.5044 58.1321 98.7866 58.229 99.0487C58.2723 99.1809 57.9668 99.2767 57.6613 98.9917C57.1718 98.5704 56.5835 98.2802 55.9513 98.1481C55.7073 98.0804 55.4522 98.0626 55.2012 98.0956L55.4885 93.8047L58.7375 95.159Z"
            fill="#C8856A"
        />
        <path
            d="M60.5035 85.6902C60.9868 86.7755 60.6859 88.8822 60.166 91.1622C59.6462 93.4422 58.4583 97.1495 58.4583 97.1495C58.3238 97.5348 55.7223 97.6716 55.2207 96.7869C55.2207 96.7869 55.2891 94.6141 55.3507 92.7992C55.4191 90.8019 55.3712 89.3792 56.0734 88.2392C56.7757 87.0992 60.3758 85.6947 60.3758 85.6947"
            fill="#37474F"
        />
        <path
            d="M49.8642 85.1999C53.5259 84.5296 57.8282 83.6358 59.707 84.8077C61.3554 85.836 60.53 87.8424 59.6705 88.5446C58.8109 89.2469 53.4871 91.7366 50.0945 92.0718C47.0324 92.3659 45.4319 91.2852 44.8072 90.7996C44.8072 90.7996 43.576 90.8155 42.4223 89.5592C41.1455 88.1684 41.4442 85.8976 41.5604 83.8022L49.8642 85.1999Z"
            fill="#37474F"
        />
        <path
            d="M47.9192 91.324C47.9829 91.3303 48.0471 91.3241 48.1084 91.3058C48.3729 91.2032 48.8745 90.6628 49.0478 90.2273C49.0801 90.1649 49.0995 90.0966 49.105 90.0265C49.1105 89.9564 49.1019 89.8859 49.0797 89.8192C49.0154 89.6989 48.943 89.5832 48.8631 89.4726C48.4456 88.8925 48.1473 88.2353 47.9853 87.5392C47.9821 87.526 47.9738 87.5146 47.9623 87.5074C47.9509 87.5002 47.937 87.4977 47.9238 87.5004C47.9109 87.5042 47.8998 87.5125 47.8927 87.5238C47.8855 87.5352 47.8828 87.5488 47.885 87.562C48.0503 88.2697 48.3541 88.9376 48.7788 89.5274C48.8559 89.6339 48.9252 89.7459 48.9862 89.8625C49.0022 89.9157 49.0074 89.9715 49.0015 90.0267C48.9957 90.0819 48.9788 90.1354 48.952 90.184C48.7662 90.6047 48.4589 90.9604 48.0697 91.2054C47.7687 91.324 46.0473 90.7244 45.5845 90.3413C45.1216 89.9583 44.4627 88.6359 44.4012 87.3203C44.4012 87.3136 44.3998 87.307 44.3971 87.3009C44.3944 87.2947 44.3905 87.2892 44.3856 87.2847C44.3806 87.2802 44.3748 87.2768 44.3684 87.2747C44.3621 87.2726 44.3554 87.2718 44.3487 87.2724C44.3421 87.2727 44.3357 87.2743 44.3297 87.2771C44.3237 87.2799 44.3183 87.2839 44.3139 87.2887C44.3095 87.2936 44.306 87.2993 44.3038 87.3055C44.3015 87.3117 44.3005 87.3183 44.3008 87.3249C44.3624 88.6678 45.051 90.0335 45.5206 90.4188C45.9288 90.7563 47.3355 91.2921 47.9192 91.324Z"
            fill="#263238"
        />
        <path
            d="M42.3388 89.9559H42.3616C42.4597 89.9126 42.8815 89.4338 42.7811 89.0621C42.5566 88.2195 42.4582 87.3481 42.4893 86.4766C42.4868 86.4649 42.4803 86.4543 42.471 86.4467C42.4617 86.4392 42.45 86.4351 42.438 86.4351C42.426 86.4351 42.4143 86.4392 42.405 86.4467C42.3956 86.4543 42.3892 86.4649 42.3867 86.4766C42.3564 87.3582 42.4563 88.2394 42.6831 89.0918C42.7675 89.4064 42.3821 89.8396 42.3206 89.867C42.3083 89.8729 42.2989 89.8834 42.2942 89.8961C42.2895 89.9089 42.29 89.923 42.2955 89.9354C42.3006 89.942 42.3071 89.9473 42.3147 89.9509C42.3222 89.9544 42.3305 89.9562 42.3388 89.9559Z"
            fill="#263238"
        />
        <path
            d="M59.2601 88.0818C59.0321 89.1967 58.3093 90.4553 55.4844 91.3057C55.4844 91.0595 55.4844 90.8178 55.4844 90.5852C55.9769 90.4119 58.3937 89.6664 59.2601 88.0818Z"
            fill="#263238"
        />
        <path
            d="M50.2879 85.5715C50.4475 86.6454 50.7758 87.3499 51.2911 87.7193C51.7471 88.0545 52.3946 88.1411 53.2473 87.9906C54.0294 87.8515 54.8661 87.6372 55.6732 87.432C57.4516 86.976 58.9861 86.5861 59.515 87.0809C60.0098 87.5369 60.0622 88.4352 59.7225 90.6058C59.474 92.2018 58.1881 95.8498 57.8575 97.3933C57.7906 97.4055 57.7199 97.4146 57.6454 97.4207C57.9669 95.9136 59.2711 92.1767 59.5219 90.5807C59.8502 88.4877 59.8137 87.6418 59.376 87.2337C58.9382 86.8255 57.3718 87.2109 55.728 87.6327C54.9163 87.8401 54.0772 88.0522 53.2884 88.1935C52.3764 88.3554 51.6924 88.2574 51.1771 87.888C50.6139 87.4845 50.2651 86.7366 50.0918 85.608L50.2879 85.5715Z"
            fill="#263238"
        />
        <path
            d="M58.7551 96.201C58.7551 96.201 58.2193 96.511 56.9926 96.5042C55.766 96.4974 55.2439 95.884 55.2439 95.884L55.1641 97.252C55.1641 97.252 55.4491 97.6875 56.7487 97.7787C58.0483 97.8699 58.3994 97.5234 58.3994 97.5234L58.7551 96.201Z"
            fill="#455A64"
        />
        <path
            d="M44.8474 91.096L44.9773 90.941C43.0074 89.304 43.2309 87.065 43.7074 85.1065L43.5113 85.0586C43.2149 86.283 42.5195 89.1558 44.8474 91.096Z"
            fill="#263238"
        />
        <path
            d="M45.8816 70.6398L51.8096 73.3279C52.7216 72.7077 53.6131 72.0739 54.4955 71.4218C55.1156 70.9521 56.0915 70.2932 56.5247 69.7118C56.8028 69.2079 56.698 68.549 56.8895 68.0109C57.1038 67.4859 57.2783 66.9456 57.4116 66.3944C57.4453 66.3556 57.4911 66.3293 57.5416 66.3198C57.592 66.3103 57.6443 66.3181 57.6898 66.3419C58.1868 66.7067 57.8562 67.5663 57.936 68.0839C57.9862 68.2982 58.2666 68.0132 58.319 67.9197C58.8206 67.2152 59.019 66.3761 59.2584 65.5645C59.3519 65.3137 59.6528 64.9215 59.931 65.1267C60.0382 65.3274 59.931 65.5827 59.9424 65.7902C59.9538 65.9977 60.1704 66.2234 60.1704 66.4742C60.147 66.6424 60.1895 66.8131 60.289 66.9507C60.4098 67.1924 60.346 67.4637 60.3346 67.7191C60.289 69.1965 59.1946 70.3593 58.2643 71.3671C57.5416 72.1605 55.4668 74.7643 53.5037 76.7752C52.4526 77.8286 51.5702 77.1834 50.2843 76.7524C48.1708 76.0479 43.4785 74.1715 43.4785 74.1715L45.8816 70.6398Z"
            fill="#AF6152"
        />
        <path
            d="M46.1903 70.2636C44.4438 69.9626 43.6299 70.5326 43.1032 71.1756C42.6472 71.7251 41.9632 72.9517 42.7635 76.5587L46.1903 70.2636Z"
            fill="#455A64"
        />
        <path
            d="M43.8803 71.4424C44.6783 70.4255 45.49 70.2454 46.5616 70.2637C46.7896 70.2637 47.3641 70.2979 47.3641 70.2979C47.4622 70.7995 49.1881 70.8884 50.4923 70.6444C50.8799 70.7652 52.4645 71.2555 52.7837 71.3809C53.6957 71.7343 54.7764 73.1228 53.8166 76.4539C52.8567 79.7849 52.6766 82.3294 52.9524 85.6468C51.8421 86.609 49.2497 86.7868 47.4417 86.609C43.5474 86.2396 41.5137 84.1352 41.5137 84.1352C41.6783 81.0304 42.0301 77.9385 42.567 74.8761C42.941 72.815 43.5246 71.8961 43.8803 71.4424Z"
            fill="#8C53C5"
        />
        <path
            d="M59.5596 75.9112C60.3371 75.2706 61.753 74.1716 62.665 73.2414C63.0194 72.8676 63.2667 72.4053 63.3809 71.903C63.5678 71.2327 63.8756 70.5099 63.9053 69.908C63.9463 69.1191 64.2587 69.2878 64.4616 69.5728C64.6486 69.9057 64.5824 70.5054 64.6394 70.8724C64.8104 71.7844 65.711 70.1292 65.8114 69.8761C66.0394 69.4201 65.7088 67.9996 66.3358 67.8583C66.36 67.8551 66.3846 67.8607 66.4051 67.8741C66.4256 67.8875 66.4406 67.9078 66.4475 67.9312C66.4949 68.1566 66.5254 68.3853 66.5387 68.6152C66.6268 69.0657 66.665 69.5244 66.6527 69.9832C66.6527 70.2226 66.8921 70.4392 66.9126 70.6672C66.9023 70.8541 66.9023 71.0413 66.9126 71.2281C67.0106 71.6294 66.7598 72.7534 66.2035 73.3508C65.768 73.8615 65.5195 73.9892 64.9586 74.5546C64.6417 74.8761 64.1333 75.5829 63.3946 76.5451C62.8884 77.204 61.6093 78.8251 60.9823 79.386C60.3553 79.9468 59.571 80.3299 58.3763 79.6778C56.566 78.6883 53.3147 76.5063 53.3147 76.5063C51.233 75.1816 51.6412 72.2382 52.4346 71.2623C53.5199 71.6362 54.3726 71.8825 55.6266 72.8241C57.8884 74.541 59.5596 75.9112 59.5596 75.9112Z"
            fill="#C8856A"
        />
        <path
            d="M59.5605 75.9112C60.6504 76.8529 60.5387 77.8652 60.5387 77.8652C60.5387 77.8652 60.9103 76.7708 59.9413 75.6125L59.5605 75.9112Z"
            fill="#AF6152"
        />
        <path
            d="M52.3164 71.219C53.9739 71.6636 54.5713 71.8848 55.367 72.5026C56.1627 73.1205 58.4883 74.9331 58.4883 74.9331C58.4883 74.9331 58.9785 76.1712 58.6388 77.3682C58.2786 78.6404 57.1819 79.0736 56.8376 78.8866L53.0323 76.4014C53.0323 76.4014 52.0998 75.7881 51.7965 74.1898C51.6483 73.4124 51.4978 71.7024 52.3164 71.219Z"
            fill="#455A64"
        />
        <path
            d="M57.9186 74.4884C57.9186 74.4884 58.0714 74.363 58.3906 74.4884C58.8925 74.7695 59.3347 75.1459 59.6925 75.5964C60.0801 76.3101 60.0801 77.3041 59.7654 78.2253C59.4508 79.1464 58.6528 79.8509 58.2948 79.7985C57.8665 79.7461 57.4552 79.5989 57.091 79.3675C56.676 79.0962 56.3386 78.8659 56.4549 78.6334L56.6829 78.7862C56.6829 78.7862 57.4603 78.736 58.0782 77.5823C58.6961 76.4286 58.2492 74.7483 58.2492 74.7483L57.9186 74.4884Z"
            fill="#37474F"
        />
        <path
            d="M50.6491 69.0734L50.5305 70.658C49.3495 71.3055 46.8666 70.9863 46.7891 70.3981L46.9373 68.6721L50.6491 69.0734Z"
            fill="#C8856A"
        />
        <path
            d="M52.5254 64.5637C52.8769 65.0439 53.1953 65.5474 53.4784 66.0708C53.2881 66.2675 53.0536 66.416 52.7944 66.504L52.5254 64.5637Z"
            fill="#AF6152"
        />
        <path
            d="M50.1511 61.6702C51.3185 61.652 52.6591 62.4705 52.5884 64.6525C52.5884 65.1085 52.7708 65.4892 52.8438 66.1253C53.0171 67.6507 52.7047 68.4623 52.4653 68.7587C51.7813 69.2147 51.0403 69.3424 50.0097 69.1577C49.2254 69.0187 47.2327 68.5923 46.3093 67.4295C45.2217 66.0615 44.7908 64.1144 45.7347 63.2115C47.0663 61.9393 49.5332 61.6839 50.1511 61.6702Z"
            fill="#C8856A"
        />
        <path
            d="M52.4646 68.752C52.2093 69.094 51.2745 69.3653 50.6406 69.347L50.6725 68.8386C50.6725 68.8386 51.9151 69.021 52.4646 68.752Z"
            fill="#AF6152"
        />
        <path
            d="M50.8472 63.2914C50.8757 63.489 50.8397 63.6904 50.7446 63.8659C50.6105 64.1088 50.3925 64.2944 50.1313 64.3881C50.3274 64.6897 50.4493 65.0335 50.487 65.3913C50.5071 65.5023 50.4928 65.6169 50.446 65.7196C50.428 65.7541 50.4014 65.7835 50.3688 65.8049C50.3362 65.8263 50.2987 65.8389 50.2598 65.8417C50.2209 65.8444 50.182 65.8371 50.1467 65.8206C50.1115 65.804 50.081 65.7786 50.0584 65.7469C49.9548 65.5635 49.8301 65.3929 49.6867 65.2385C49.5025 65.0596 49.2592 64.9542 49.0027 64.9421C48.0406 64.9284 48.1568 66.1779 48.5855 66.6475C49.2854 67.4182 49.7528 66.8505 49.7528 66.8505C49.7528 66.8505 50.1906 68.0885 49.3196 68.7109C49.3196 68.7109 48.0497 69.2331 46.9895 68.8044C46.3254 68.098 45.732 67.3282 45.2179 66.5062C44.0779 64.6411 44.3059 63.1021 44.9899 62.5299C45.0378 61.7296 45.5143 61.2211 46.2074 60.9065C47.1514 60.4779 48.1272 60.7081 49.1258 60.6192C49.6362 60.5734 50.1295 60.4128 50.5691 60.1495C50.7606 60.031 51.2531 59.8235 51.4628 59.9215C51.5407 59.966 51.6016 60.0349 51.6361 60.1176C51.6731 60.2494 51.6835 60.3872 51.6667 60.5231C51.6499 60.6589 51.6061 60.79 51.5381 60.9088C51.6804 60.8841 51.8264 60.8918 51.9654 60.9313C52.1043 60.9707 52.2326 61.041 52.3406 61.1368C52.6781 61.4035 52.7966 61.896 52.7032 62.3976C52.6576 62.7328 52.2107 63.5581 50.8472 63.2914Z"
            fill="#263238"
        />
    </svg>
);
