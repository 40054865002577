import { ActivityDTO } from "../../types/activityDTO";
import { EditableInfos } from "./components/editable-infos/editable-infos";
import { SelectStatus } from "./components/select-status/select-status";
import { Users } from "./components/users/users";
import * as s from "./styles";

type THeader = {
    activity: ActivityDTO;
};

export const Header = ({ activity }: THeader) => {
    return (
        <s.Container>
            <EditableInfos activity={activity} />
            <Users activity={activity} />
            <SelectStatus activity={activity} />
        </s.Container>
    );
};
