import styled, { css, keyframes } from "styled-components";

// Animations
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

// Styled Components
export const Container = styled.div`
    padding: 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    min-width: 400px;
    max-width: 800px;
`;

export const ErrorFlag = styled.div`
    background-color: ${({ theme }) => theme.softRed};
    color: ${({ theme }) => theme.red};
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    font-size: 14px;
`;

export const BetaFlag = styled.div`
    background-color: ${({ theme }) => theme.lightYellow};
    color: ${({ theme }) => theme.orange};
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    font-size: 14px;
`;

export const ListenStopButtons = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        width: 200px;
    }
`;

export const PulsingIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const SubTitle = styled.h3`
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const MicrophoneStatus = styled.p<{ listening: boolean }>`
    font-size: 18px;
    color: ${(props) => (props.listening ? "#28a745" : "#dc3545")};
    font-weight: bold;
    ${(props) =>
        props.listening &&
        css`
            animation: ${pulse} 1.5s infinite;
        `}
`;
export const Button = styled.button`
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

export const Buttons = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;

    button {
        width: 200px;
    }
`;

export const Example = styled.p`
    margin-top: 10px;
    font-size: 14px;
    color: #6c757d;
`;

export const Instructions = styled.p`
    margin-top: 20px;
    font-size: 16px;
    color: #6c757d;
`;

export const OutputText = styled.p`
    font-size: 14px;
    margin-top: 10px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
`;
