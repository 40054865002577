import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-delete-entry";

type TModalDeleteEntry = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSave: () => void;
};

export const ModalDeleteEntry = ({ setOpen, onSave }: TModalDeleteEntry) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <h1>Apagar tarefa</h1>
                <p>
                    Ao apagar esta tarefa ela não poderá ser recuperada. Deseja
                    apagar esta tarefa?
                </p>
                <s.ButtonsContainer>
                    <SecondaryButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setOpen(false);
                            onSave();
                        }}
                    >
                        Apagar
                    </PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};
