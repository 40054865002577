import { useState } from "react";

import { TProject } from "../../../../models";
import { TUseFetch } from "../../../../types/TUseFetch";
import { CollaboratorsEdit } from "./collabs-edit";
import { CollabsHourControl } from "./collabs-hour-control";
import * as s from "./styled-tab-collabs";

type TTabCollabs = {
    project: TProject;
    loading?: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    getProject: TUseFetch;
};

export const TabCollabs = ({
    project,
    setLoading,
    loading,
    getProject,
}: TTabCollabs) => {
    const [edit, setEdit] = useState<boolean>(false);

    return (
        <s.Content key={project.Users?.length}>
            {edit ? (
                <CollaboratorsEdit
                    project={project}
                    setEdit={setEdit}
                    getProject={getProject}
                />
            ) : (
                <CollabsHourControl
                    project={project}
                    setEdit={setEdit}
                    setLoading={setLoading}
                    loading={loading}
                />
            )}
        </s.Content>
    );
};
