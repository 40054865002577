import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import ReactSelect, {
    GroupBase,
    MenuListProps,
    components,
} from "react-select";

import { Icons, theme } from "../../../../../../../assets";
import { Tag } from "../../../../../../../components";
import { TOption } from "../../../../../../../models";
import { manageUser, projects } from "../../../../../../../services";
import { ReducedProjectReadResult } from "../../../../../../../services/projects/types";
import { ReadUserProjectsResponse } from "../../../../../../../services/user/types";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import * as s from "./styles";

export const SelectProject = () => {
    const { state } = useLocation();
    const userId = state?.id;

    const { data } = useQuery<ReducedProjectReadResult>({
        queryKey: ["projects"],
        queryFn: projects.reducedReadProject,
    });

    const updateUser = useMutation({
        mutationKey: ["updateUser"],
        mutationFn: manageUser.updateUser,
        onSuccess: () => {
            toast.success("Projeto adicionado com sucesso!");
            queryClient.invalidateQueries(["userProjects"]);
        },
    });

    const { allUserProjectsIds } =
        queryClient.getQueryData<ReadUserProjectsResponse>(["userProjects"]) ||
        {};
    const filteredProjects = data?.projects?.filter(
        (project) => !allUserProjectsIds?.includes(project.id_e)
    );
    const options = filteredProjects?.map((project) => ({
        label: project.name,
        value: project.id_e,
        color: project.color,
    }));

    const CustomMenuList = (
        props: MenuListProps<TOption, false, GroupBase<TOption>>
    ) => {
        return (
            <components.MenuList {...props}>
                {Array.isArray(props?.children) && props?.children?.length ? (
                    props.children.map((child) => (
                        <components.Option key={child.key} {...child.props}>
                            <s.Option>
                                <Tag color={child.props.data.color}>
                                    {child.props.children}
                                </Tag>
                                Adicionar projeto
                            </s.Option>
                        </components.Option>
                    ))
                ) : (
                    <s.NoOptionsMessage>
                        Nenhum projeto encontrado
                    </s.NoOptionsMessage>
                )}
            </components.MenuList>
        );
    };

    return (
        <s.Container>
            <ReactSelect
                placeholder="Adicionar projetos"
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => (
                        <Icons.Tag color={theme.gray400} />
                    ),
                    MenuList: CustomMenuList,
                }}
                onChange={(data) => {
                    if (data && userId) {
                        updateUser.mutate({
                            userId,
                            projectsId: [
                                ...(allUserProjectsIds || []),
                                data.value,
                            ].join(", "),
                        });
                    }
                }}
                isDisabled={updateUser.isLoading}
                hideSelectedOptions
                controlShouldRenderValue={false}
                noOptionsMessage={() =>
                    "Nenhum projeto encontrado. Tente outro nome."
                }
                options={options}
                styles={{
                    input: (provided) => ({
                        ...provided,
                        color: theme.gray400,
                    }),
                    indicatorsContainer: (provided) => ({
                        ...provided,
                        paddingRight: "14px",
                        paddingLeft: "14px",
                    }),
                    container: (provided) => ({
                        ...provided,
                        borderColor: theme.gray100,
                    }),
                    control: (provided) => ({
                        ...provided,
                        borderColor: theme.gray100,
                        boxShadow: "none",
                        cursor: "text",
                        "&:hover": {
                            borderColor: theme.gray300,
                        },
                        fontSize: "14px",
                        paddingLeft: "12px",
                    }),
                    noOptionsMessage: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: theme.gray400,
                        fontSize: "14px",
                        backgroundColor: state.isSelected
                            ? theme.purple50
                            : "transparent",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: theme.purple50,
                        },
                    }),
                }}
            />
        </s.Container>
    );
};
