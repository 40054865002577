import styled from "styled-components";

type TRate = { selected: boolean };

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
    width: 520px;
    max-height: 700px;

    .text-area {
        max-height: 200px;
        overflow-y: auto;
    }

    div#loading-nps {
        min-width: 500px;
    }

    h1 {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
    }

    span.field-label {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.gray900};
    }

    div#button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button {
            width: fit-content;
            padding: 0 1rem;
            height: 40px;
        }
    }
`;

export const RadixClose = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

export const Rating = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    div#rates {
        margin-top: 0.25rem;
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
    }

    div#tips {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            font-size: 0.75rem;
            color: ${({ theme }) => theme.gray400};
            font-weight: 400;
        }
    }
`;

export const Rate = styled.button<TRate>`
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;

    font-size: 0.875rem;

    &:hover {
        border: 1px solid ${({ theme }) => theme.purple600};
        box-shadow: 0px 2px 8px rgba(14, 2, 26, 0.2);
    }

    ${({ selected, theme }) =>
        selected &&
        `background-color: ${theme.purple600}; color: ${theme.white}; border: 1px solid ${theme.purple600};`}
`;

export const TextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
