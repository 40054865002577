import { TCreateUserBody, TCreateUserResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (data?: TCreateUserBody) => {
    const tempHeaders = {
        "x-invite-token": data?.inviteToken,
    };
    const tempData = data;

    if (!data?.inviteToken) {
        delete tempHeaders["x-invite-token"];
    } else delete tempData?.inviteToken;

    if (!data?.voucher) {
        delete tempData?.voucher;
    }

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/create`,
        method: "post",
        body: tempData,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro! Campos obrigatórios não preenchidos!",
        409: "Usuário já existente!",
    };

    return handleStatusCases<TCreateUserResponse>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
