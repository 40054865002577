import dayjs from "dayjs";

import { Icons } from "../../../../assets";
import { PrimaryButton, SecondaryButton } from "../../../../components";
import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import * as s from "../styled-components";

type ManualInputsData = {
    start: string;
    end: string;
    startUpdated: boolean;
    endUpdated: boolean;
    startKey: string;
    endKey: string;
};

type Props = {
    isOnManualMode: boolean;
    manualInputsData: ManualInputsData;
    loadingTimer: React.MutableRefObject<boolean>;
    isRunning: boolean | undefined;
    validateInputs: (start: string, end: string, message?: boolean) => boolean;
    disabled?: boolean;
    selectedDate?: Date;
    loading?: boolean;
};

export const TrackButtons = ({
    isOnManualMode,
    manualInputsData,
    loadingTimer,
    isRunning,
    validateInputs,
    disabled,
    selectedDate,
    loading,
}: Props) => {
    return isOnManualMode ? (
        <PrimaryButton
            disabled={
                disabled ||
                !validateInputs(manualInputsData.start, manualInputsData.end) ||
                dayjs(selectedDate || new Date()) > dayjs()
            }
            loading={loading}
        >
            {!loading && <Icons.Checked />}
        </PrimaryButton>
    ) : (
        <Tooltip
            side="top"
            positiony={10}
            content={
                <s.SmallP>
                    {isRunning ? "Pausar cronômetro" : "Iniciar cronômetro"}
                </s.SmallP>
            }
        >
            <div>
                {isRunning ? (
                    <SecondaryButton
                        disabled={disabled || loadingTimer.current}
                        loading={loading}
                    >
                        {!loading && <Icons.Stop />}
                    </SecondaryButton>
                ) : (
                    <PrimaryButton
                        isActive={isRunning}
                        disabled={disabled || loadingTimer.current}
                        loading={loading}
                    >
                        {!loading && <Icons.Play />}
                    </PrimaryButton>
                )}
            </div>
        </Tooltip>
    );
};
