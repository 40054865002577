import {
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../components";
import ContainerModal from "../../../../../../../components/molecules/modals/container-modal";
import * as s from "./styles";

type ModalDeleteAdditionalCostProps = {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
};

export const ModalDeleteAdditionalCost = ({
    onOpenChange,
    onConfirm,
}: ModalDeleteAdditionalCostProps) => {
    return (
        <ContainerModal onOpenChange={onOpenChange} closeIcon>
            <s.Container>
                <s.Title>Deletar o item</s.Title>
                <s.Text>
                    Você tem certeza de que deseja deletar o item selecionado?
                </s.Text>
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => {
                            onOpenChange(false);
                        }}
                        variation="small"
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton onClick={onConfirm} variation="small">
                        Sim, deletar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
