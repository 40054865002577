import { useState } from "react";

import { Icons } from "../../../../../../../../assets";
import ContainerModal from "../../../../../../../../components/molecules/modals/container-modal";
import { permissionsList } from "../../../../../../../../utils/permissions/permissions-options";
import * as s from "./styled-information-modal";

type InformationModalProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    permission: (typeof permissionsList)[0];
};

export const InformationModal = ({
    permission,
    setOpen,
}: InformationModalProps) => {
    const [currentShowImage, setCurrentShowImage] = useState<number>(0);

    const leftArrowClick = () => {
        setCurrentShowImage((currentShowImage - 1) % permission.images.length);
    };

    const rightArrowClick = () => {
        setCurrentShowImage((currentShowImage + 1) % permission.images.length);
    };

    return (
        <ContainerModal onOpenChange={setOpen} closeIcon>
            <s.Container>
                <s.Title>{permission.title}</s.Title>
                <s.ImageContainer>
                    <s.ImageButton
                        left
                        onClick={leftArrowClick}
                        hide={currentShowImage === 0}
                    >
                        <Icons.Arrow width="18px" height="18px" />
                    </s.ImageButton>
                    <img src={permission.images[currentShowImage]} />
                    <s.ImageButton
                        onClick={rightArrowClick}
                        hide={currentShowImage === permission.images.length - 1}
                    >
                        <Icons.Arrow width="18px" height="18px" />
                    </s.ImageButton>
                </s.ImageContainer>
                <s.IndicatorsContainer>
                    {permission.images.map((image, index) => (
                        <s.ImageIndicator
                            key={index}
                            selected={index === currentShowImage}
                            onClick={() => setCurrentShowImage(index)}
                        >
                            •
                        </s.ImageIndicator>
                    ))}
                </s.IndicatorsContainer>
                <s.Description>{permission.detailedDescription}</s.Description>
                <s.Button onClick={() => setOpen(false)}>Voltar</s.Button>
            </s.Container>
        </ContainerModal>
    );
};
