import * as s from "./styled-show-more";

type TShowMore = {
    onClick: () => void;
    numberOfItems?: number;
    isOpen?: boolean;
};

export default ({ onClick, isOpen, numberOfItems }: TShowMore) => (
    <s.Button type="button" onClick={onClick}>
        {numberOfItems} <s.Triangle open={isOpen} />
    </s.Button>
);
