export const activationTexts = {
    title: "Ativação do profissional",
    firstDescriptionParagraph:
        "Ao ativar a conta do profissional selecionado, ele aparecerá poderá acessar o PaperON e aparecerá para os outros usuários como profissional ativo.",
    secondDescriptionParagraph:
        "Você poderá inativar a conta do profissional a qualquer momento clicando em “Inativar conta”.",
    buttonLabel: "ATIVAR CONTA",
};
export const inactivationTexts = {
    title: "Inativação do profissional",
    firstDescriptionParagraph: `Ao inativar a conta do profissional temporariamente, ele aparecerá para os outros usuários como profissional inativo e não poderá entrar no PaperON enquanto inativo.`,
    secondDescriptionParagraph: `Você poderá reativar a conta do profissional a qualquer momento clicando em “Ativar conta”.`,
    buttonLabel: "INATIVAR CONTA",
};
