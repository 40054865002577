import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../assets";
import { ModalWelcomePlan } from "../../components";
import BlockTooltip from "../../components/molecules/block-tooltip/block-tooltip";
import ModalPlanCards from "../../components/molecules/modals/modal-plan-cards/modal-plan-cards";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TSubscription } from "../../models/subscription";
import { subscriptions } from "../../services";
import { DEFAULT_TRIAL_DAYS } from "../../utils/constants/free-days";
import { ErrorInPaymentBanner } from "../track/components/banners/error-in-payment/error-in-payment";
import { PlanCanceledBanner } from "../track/components/banners/plan-canceled/plan-canceled";
import { CancelConfirmationModal } from "./components/modals/cancel-confirmation-modal/cancel-confirmation-modal";
import { CancelPlanReasonModal } from "./components/modals/cancel-plan-reason-modal/cancel-plan-reason-modal";
import { PasswordConfirmationModal } from "./components/modals/password-confirmation-modal/password-confirmation-modal";
import {
    billStatusMapper,
    cardBrands,
    planTypeMapper,
    planTypeRecurrenceMapper,
    plans,
} from "./mappers";
import * as s from "./styled-subscriptions";

dayjs.extend(utc);
dayjs.extend(timezone);

export const Subscriptions = () => {
    const navigate = useNavigate();
    const { organization } = useUser();

    const [activeSubscription, setActiveSubscription] =
        useState<TSubscription>();
    const [remainingSubscriptions, setRemainingSubscriptions] = useState<
        TSubscription[]
    >([]);
    const [cancelReasonModalIsOpen, setCancelReasonModalIsOpen] =
        useState(false);
    const [cancelConfirmationModalIsOpen, setCancelConfirmationModalIsOpen] =
        useState(false);
    const [
        passwordConfirmationModalIsOpen,
        setPasswordConfirmationModalIsOpen,
    ] = useState(false);
    const [changePlanModal, setChangePlanModal] = useState(false);
    const [successChangedPlan, setSuccessChangedPlan] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [loading, setLoading] = useState(true);

    const { monthlyPrice, yearlyPrice } =
        plans.find((plan) => plan.id === activeSubscription?.plan) ?? {};
    const currentPeriod = activeSubscription?.plan_type ?? "monthly";
    const price = currentPeriod === "monthly" ? monthlyPrice : yearlyPrice;
    const maxUsers = activeSubscription?.max_users ?? 0;
    const numberOfUsers = activeSubscription?.number_of_users ?? 0;
    const usersRemaining = maxUsers - numberOfUsers;
    const stringPrice = `R$ ${price?.toFixed(2).replace(".", ",")}`;
    const notBusinessPlan = activeSubscription?.plan !== "business";

    const { state } = useLocation();

    const readSubscriptions = useFetch({
        fn: subscriptions.readSubscriptionForManagement,
        start: true,
    });

    const cancelSubscription = useFetch({
        fn: subscriptions.cancelSubscription,
        start: false,
    });

    useEffect(() => {
        if (readSubscriptions.response) {
            setLoading(false);
            const response = readSubscriptions.response as TSubscription[];
            const activeSubscription = response.find(
                ({ status }: TSubscription) => status === "active"
            );
            if (!activeSubscription) {
                const lastActiveSubscription = orderBy(
                    response,
                    ["created_at"],
                    ["desc"]
                ).find(
                    ({ status, plan }: TSubscription) =>
                        status !== "active" && plan !== "trial"
                );
                if (lastActiveSubscription) {
                    setActiveSubscription(lastActiveSubscription);
                }
                const remainingSubscriptions = response.filter(
                    ({ plan }: TSubscription) => plan === "trial"
                );
                setRemainingSubscriptions(
                    orderBy(remainingSubscriptions, ["created_at"], ["desc"])
                );
                return;
            }
            setActiveSubscription(activeSubscription);
            const remainingSubscriptions = response.filter(
                ({ status }: TSubscription) => status !== "active"
            );
            setRemainingSubscriptions(
                orderBy(remainingSubscriptions, ["created_at"], ["desc"])
            );
        }
    }, [readSubscriptions.response]);

    const transformBillAmount = (amount: number) => {
        return (amount / 100).toFixed(2).replace(".", ",");
    };

    const handleCancelSubscription = () => {
        cancelSubscription.onRefresh({
            reason: cancelReason,
        });
        setLoading(true);
    };

    useEffect(() => {
        if (cancelSubscription.response) {
            readSubscriptions.onRefresh();
            setLoading(false);
            setPasswordConfirmationModalIsOpen(false);
            toast.success("Assinatura cancelada com sucesso");
        }
    }, [cancelSubscription.response]);

    useEffect(() => {
        if (cancelSubscription.error) {
            readSubscriptions.onRefresh();
            setLoading(false);
            setPasswordConfirmationModalIsOpen(false);
            toast.error("Erro ao cancelar assinatura");
        }
    }, [cancelSubscription.error]);

    useEffect(() => {
        if (state?.plan) {
            setSuccessChangedPlan(true);
        }
    }, [state?.plan]);

    useEffect(() => {
        if (state?.openPlans) {
            setChangePlanModal(true);
        }
    }, [state?.openPlans]);

    return (
        <s.Page>
            {!loading && activeSubscription ? (
                <>
                    {cancelReasonModalIsOpen && (
                        <CancelPlanReasonModal
                            setOpen={setCancelReasonModalIsOpen}
                            onClickContinue={() => {
                                setCancelReasonModalIsOpen(false);
                                setCancelConfirmationModalIsOpen(true);
                            }}
                            setCancelReason={setCancelReason}
                            cancelReason={cancelReason}
                        />
                    )}
                    {cancelConfirmationModalIsOpen && (
                        <CancelConfirmationModal
                            setOpen={setCancelConfirmationModalIsOpen}
                            plan={activeSubscription.plan}
                            end_date={activeSubscription.next_billing_at ?? ""}
                            onClickContinue={() => {
                                setCancelConfirmationModalIsOpen(false);
                                setPasswordConfirmationModalIsOpen(true);
                            }}
                        />
                    )}
                    {activeSubscription.status === "canceled" && (
                        <PlanCanceledBanner
                            limitDate={activeSubscription.next_billing_at}
                        />
                    )}
                    {passwordConfirmationModalIsOpen && (
                        <PasswordConfirmationModal
                            setOpen={setPasswordConfirmationModalIsOpen}
                            onSuccess={handleCancelSubscription}
                        />
                    )}
                    {changePlanModal && (
                        <ModalPlanCards
                            numberOfUsers={
                                activeSubscription?.number_of_users || 0
                            }
                            setOpen={setChangePlanModal}
                            isOnEditMode={true}
                            currentPlan={activeSubscription.plan}
                            currentPlayType={activeSubscription.plan_type}
                            changingPlan={true}
                        />
                    )}
                    {successChangedPlan && (
                        <ModalWelcomePlan
                            planType={state?.plan}
                            setOpen={setSuccessChangedPlan}
                        />
                    )}
                    {activeSubscription.status === "failed" && (
                        <ErrorInPaymentBanner
                            subscription={activeSubscription}
                        />
                    )}
                    <s.Columns isBusinessPlan={!notBusinessPlan}>
                        {notBusinessPlan ? (
                            <s.Column>
                                <s.Title>Assinatura e cobrança</s.Title>
                                <s.Container>
                                    <s.PlanContainerHeader>
                                        <s.Title>Seu plano</s.Title>
                                        <s.Button
                                            onClick={() => {
                                                setChangePlanModal(true);
                                            }}
                                        >
                                            Alterar plano
                                        </s.Button>
                                    </s.PlanContainerHeader>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Plano atual</s.InfoTitle>
                                        <s.Info>
                                            {activeSubscription?.plan.replace(
                                                /^\w/,
                                                (c) => c.toUpperCase()
                                            )}
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Cobrança</s.InfoTitle>
                                        <s.Info>
                                            {planTypeMapper[currentPeriod]}
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Valor</s.InfoTitle>
                                        <s.Info>
                                            {stringPrice} /
                                            {
                                                planTypeRecurrenceMapper[
                                                    currentPeriod
                                                ]
                                            }
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Vagas</s.InfoTitle>
                                        <s.Info>
                                            {activeSubscription?.max_users ?? 0}{" "}
                                            usuários
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>
                                            Ocupação de vagas
                                        </s.InfoTitle>
                                        <s.Info>
                                            {usersRemaining} vagas restantes
                                        </s.Info>
                                    </s.InfoContainer>
                                </s.Container>
                                <s.PaymentContainer>
                                    <s.Content>
                                        <s.Title bottom="21px">
                                            Pagamento
                                        </s.Title>
                                        <s.Card>
                                            {
                                                cardBrands[
                                                    activeSubscription?.card_brand ??
                                                        "Mastercard"
                                                ]
                                            }
                                            •••• •••• ••••{" "}
                                            {activeSubscription?.card_last_four}
                                        </s.Card>
                                        {activeSubscription.status ===
                                        "canceled" ? (
                                            <s.NextPayment>
                                                Não existem cobranças futuras
                                            </s.NextPayment>
                                        ) : (
                                            <s.NextPayment>
                                                Próximo pagamento em{" "}
                                                {dayjs(
                                                    activeSubscription.next_billing_at
                                                ).format("DD/MM/YYYY")}
                                            </s.NextPayment>
                                        )}
                                    </s.Content>
                                    <BlockTooltip
                                        side="top"
                                        align="center"
                                        trigger={
                                            <Icons.Info
                                                width="22px"
                                                height="22px"
                                                color={theme.lightBlack}
                                            />
                                        }
                                    >
                                        <s.Tooltip>
                                            A cobrança da mensalidade ocorre no
                                            início de cada período de
                                            faturamento. Pode haver um intervalo
                                            de alguns dias antes que essa
                                            cobrança esteja visível em sua
                                            conta.
                                        </s.Tooltip>
                                    </BlockTooltip>
                                </s.PaymentContainer>
                                <s.Container>
                                    <s.Button
                                        onClick={() => {
                                            navigate(
                                                "/gerenciar-assinatura/editar-forma-de-pagamento"
                                            );
                                        }}
                                        disabled={
                                            activeSubscription.status ===
                                            "canceled"
                                        }
                                    >
                                        Editar forma de pagamento
                                    </s.Button>
                                    {activeSubscription.status !== "failed" && (
                                        <s.Button
                                            onClick={() =>
                                                setCancelReasonModalIsOpen(true)
                                            }
                                            disabled={
                                                activeSubscription.status ===
                                                "canceled"
                                            }
                                        >
                                            Cancelar assinatura
                                        </s.Button>
                                    )}
                                </s.Container>
                            </s.Column>
                        ) : (
                            <s.Column>
                                <s.Title>Assinatura e cobrança</s.Title>
                                <s.Container>
                                    <s.PlanContainerHeader>
                                        <s.Title>Seu plano</s.Title>
                                    </s.PlanContainerHeader>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Plano atual</s.InfoTitle>
                                        <s.Info>
                                            {activeSubscription?.plan.replace(
                                                /^\w/,
                                                (c) => c.toUpperCase()
                                            )}
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Cobrança</s.InfoTitle>
                                        <s.Info>Conforme contrato</s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Valor</s.InfoTitle>
                                        <s.Info>Conforme contrato</s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>Vagas</s.InfoTitle>
                                        <s.Info>
                                            {activeSubscription?.max_users ?? 0}{" "}
                                            usuários
                                        </s.Info>
                                    </s.InfoContainer>
                                    <s.InfoContainer>
                                        <s.InfoTitle>
                                            Ocupação de vagas
                                        </s.InfoTitle>
                                        <s.Info>
                                            {usersRemaining} vagas restantes
                                        </s.Info>
                                    </s.InfoContainer>
                                </s.Container>
                                <s.Container>
                                    <s.Description>
                                        Para alteração do número de vagas, forma
                                        de pagamento ou falar sobre qualquer
                                        outro assunto referente ao Plano
                                        Business, entre em contato conosco
                                        através do e-mail suporte@paperon.app .
                                    </s.Description>
                                </s.Container>
                            </s.Column>
                        )}
                        {notBusinessPlan && (
                            <s.Column>
                                <s.Title>Histórico de cobrança</s.Title>
                                <s.Historic>
                                    {activeSubscription?.bills?.map(
                                        (bill, index) => (
                                            <s.HistoricContainer
                                                isFirst={index === 0}
                                            >
                                                <s.HistoricContainerHeader>
                                                    <span>
                                                        {dayjs(
                                                            bill.billed_at
                                                        ).format("DD/MM/YYYY")}
                                                    </span>
                                                    <span>
                                                        R${" "}
                                                        {transformBillAmount(
                                                            bill.amount
                                                        )}
                                                    </span>
                                                </s.HistoricContainerHeader>
                                                <s.HistoricPayment>
                                                    <s.HistoricPaymentPeriod>
                                                        <div>
                                                            Serviço de
                                                            assinatura
                                                        </div>
                                                        <div>
                                                            {dayjs
                                                                .utc(
                                                                    bill.cycle
                                                                        .start_at
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )}{" "}
                                                            -{" "}
                                                            {dayjs
                                                                .utc(
                                                                    bill.cycle
                                                                        .end_at
                                                                )
                                                                .add(
                                                                    1,
                                                                    "minute"
                                                                )
                                                                .format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                        </div>
                                                        <s.Card>
                                                            {
                                                                cardBrands[
                                                                    activeSubscription.card_brand ??
                                                                        "Mastercard"
                                                                ]
                                                            }
                                                            •••• •••• ••••{" "}
                                                            {
                                                                activeSubscription.card_last_four
                                                            }
                                                        </s.Card>
                                                    </s.HistoricPaymentPeriod>
                                                    <s.Status
                                                        success={
                                                            bill.status ===
                                                            "paid"
                                                        }
                                                    >
                                                        {
                                                            billStatusMapper[
                                                                bill.status
                                                            ]
                                                        }
                                                    </s.Status>
                                                </s.HistoricPayment>
                                            </s.HistoricContainer>
                                        )
                                    )}
                                    {remainingSubscriptions?.map(
                                        (subscription) =>
                                            subscription.plan === "trial" ? (
                                                <s.HistoricContainer>
                                                    <s.HistoricContainerHeader>
                                                        <span>
                                                            Teste grátis por{" "}
                                                            {DEFAULT_TRIAL_DAYS}
                                                            dias
                                                        </span>
                                                        <span>Grátis</span>
                                                    </s.HistoricContainerHeader>
                                                    <s.HistoricPayment>
                                                        <s.HistoricPaymentPeriod>
                                                            <div>
                                                                Uso ilimitado da
                                                                plataforma
                                                                gratuitamente
                                                            </div>
                                                            <div>
                                                                {dayjs(
                                                                    subscription.created_at
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}{" "}
                                                                -{" "}
                                                                {dayjs(
                                                                    subscription.created_at
                                                                )
                                                                    .add(
                                                                        DEFAULT_TRIAL_DAYS,
                                                                        "days"
                                                                    )
                                                                    .format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                            </div>
                                                        </s.HistoricPaymentPeriod>
                                                    </s.HistoricPayment>
                                                </s.HistoricContainer>
                                            ) : (
                                                subscription.bills?.map(
                                                    (bill) => (
                                                        <s.HistoricContainer>
                                                            <s.HistoricContainerHeader>
                                                                <span>
                                                                    {dayjs(
                                                                        bill.billed_at
                                                                    ).format(
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                                </span>
                                                                <span>
                                                                    R${" "}
                                                                    {transformBillAmount(
                                                                        bill.amount
                                                                    )}
                                                                </span>
                                                            </s.HistoricContainerHeader>
                                                            <s.HistoricPayment>
                                                                <s.HistoricPaymentPeriod>
                                                                    <div>
                                                                        Serviço
                                                                        de
                                                                        assinatura
                                                                    </div>
                                                                    <div>
                                                                        {dayjs(
                                                                            bill
                                                                                .cycle
                                                                                .start_at
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}{" "}
                                                                        -{" "}
                                                                        {dayjs(
                                                                            bill
                                                                                .cycle
                                                                                .end_at
                                                                        ).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </div>
                                                                    <s.Card>
                                                                        {
                                                                            cardBrands[
                                                                                subscription.card_brand ??
                                                                                    "Mastercard"
                                                                            ]
                                                                        }
                                                                        ••••
                                                                        ••••
                                                                        ••••{" "}
                                                                        {
                                                                            subscription.card_last_four
                                                                        }
                                                                    </s.Card>
                                                                </s.HistoricPaymentPeriod>
                                                                <s.Status
                                                                    success={
                                                                        bill.status ===
                                                                        "paid"
                                                                    }
                                                                >
                                                                    {
                                                                        billStatusMapper[
                                                                            bill
                                                                                .status
                                                                        ]
                                                                    }
                                                                </s.Status>
                                                            </s.HistoricPayment>
                                                        </s.HistoricContainer>
                                                    )
                                                )
                                            )
                                    )}
                                </s.Historic>
                            </s.Column>
                        )}
                    </s.Columns>
                </>
            ) : (
                <Icons.LoadingSpinCircle width="80px" height="80px" />
            )}
        </s.Page>
    );
};
