import {
    TPriority,
    TSection,
} from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateAttachment = {
    title?: string;
    link?: string;
    attachmentId?: string;
};

export default async ({
    title,
    link,
    attachmentId,
}: TUpdateAttachment = {}) => {
    const headers = {
        "x-attachment-id": attachmentId,
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/attachment/update`,
        method: "put",
        body: {
            title,
            link,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
