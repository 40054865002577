import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";
import { UpdateRunningEntryVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    entryId,
    startAt,
}: UpdateRunningEntryVariables = {}) => {
    const tempHeaders = {
        auth: true,
        "x-entry-id": entryId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/updateRunning`,
        method: "put",
        headers: tempHeaders,
        body: { startAt },
    });

    const { body, statusCode } = httpResponse;

    const errors = {
        "startAt must be before current time!":
            "A data de início deve ser anterior ao horário atual!",
        "you already have entries in the same period!":
            "Você já possui uma entrada de tempo nesse período!",
    };

    const message = findErrorString<keyof typeof errors>(body);

    const errorFeedBacks = {
        400: errors[message] || "Erro ao atualizar entrada de tempo!",
        404: "Entrada de tempo inexistente!",
        409: "Você já possui uma entrada de tempo nesse período!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
