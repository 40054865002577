import { UseQueryResult } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useState } from "react";
import { DraggableStateSnapshot } from "react-beautiful-dnd";

import { Icons, theme } from "../../../../../../assets";
import { PopoverRadix } from "../../../../../../components";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import { TProject, TResponse } from "../../../../../../models";
import { TArchiveActivity } from "../../../../../../services/activities/archive-activity";
import { TDuplicateActivity } from "../../../../../../services/activities/duplicate-activity";
import { TToggleActivityStatus } from "../../../../../../services/activities/toggle-activity-status";
import { TUnarchiveActivity } from "../../../../../../services/activities/unarchive-activity";
import { ActivityDetailsDialog } from "../../dialogs/activity-details";
import { TSection, TSectionActivity } from "../../types";
import { Collaborators } from "../add-collaborators-button/add-collaborators-button";
import { HourProgress } from "../hour-progress/hour-progress";
import * as s from "./styled-activity-row";

type TActivityRow = {
    withoutSection?: boolean;
    activity: TSectionActivity;
    project: TProject;
    archive?: boolean;
    section?: TSection;
    unarchiveActivity: TResponse<unknown, TUnarchiveActivity>;
    toggleActivityStatus: TResponse<unknown, TToggleActivityStatus>;
    archiveActivity: TResponse<unknown, TArchiveActivity>;
    duplicateActivity?: TResponse<unknown, TDuplicateActivity>;
    getSections: UseQueryResult<any, unknown>;
    snapshot?: DraggableStateSnapshot;

    setDeleteConfirmationActivityId: React.Dispatch<
        React.SetStateAction<string>
    >;
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    toggleActivityEdit?: (activityId: string) => void;
};

export const ActivityRow = ({
    archive,
    activity,
    unarchiveActivity,
    archiveActivity,
    project,
    section,
    toggleActivityEdit,
    setActivityToAddCollaborators,
    snapshot,
    setDeleteConfirmationActivityId,
    toggleActivityStatus,
    withoutSection,
    duplicateActivity,
    getSections,
}: TActivityRow) => {
    const priorityMapper = {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
    };
    const [activityDetailsOpen, setActivityDetailsOpen] =
        useState<boolean>(false);

    const handleUnarchiveActivity = () => {
        unarchiveActivity.onRefresh({
            activityIds: [activity.id_e],
            projectId: project.id_e,
        });
    };

    const handleArchiveActivity = () => {
        archiveActivity.onRefresh({
            activityIds: [activity.id_e],
            projectId: project.id_e,
        });
    };

    const handleToggleActivityStatus = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        toggleActivityStatus.onRefresh({
            activityId: activity.id_e,
        });
    };

    const handleDuplicateActivity = () => {
        duplicateActivity?.onRefresh({
            activityId: activity.id_e,
        });
    };

    return (
        <>
            <ActivityDetailsDialog
                open={activityDetailsOpen}
                setOpen={setActivityDetailsOpen}
                activity={activity}
                refetchActivity={() => {
                    getSections.refetch();
                }}
            />
            <s.ActivityRow
                isDragging={snapshot?.isDragging}
                withoutSection={withoutSection}
                onClick={() => setActivityDetailsOpen(true)}
            >
                <s.RowDescription>
                    <Icons.Drag className="hide-on-hover" />
                    <Tooltip
                        align="start"
                        side="bottom"
                        content={
                            <s.TooltipContent>
                                {activity.finished
                                    ? "Marcar como não finalizada"
                                    : "Marcar como finalizada"}
                            </s.TooltipContent>
                        }
                    >
                        <div
                            onClick={handleToggleActivityStatus}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {activity.finished ? (
                                <Icons.IconCheckCircle2
                                    width="24"
                                    height="24"
                                />
                            ) : (
                                <Icons.CheckCircle
                                    color={theme.gray300}
                                    width={"24"}
                                    height={"24"}
                                />
                            )}
                        </div>
                    </Tooltip>
                    <p className="description" title={activity.name}>
                        {activity.name}
                    </p>
                </s.RowDescription>
                <Collaborators
                    key={activity.id_e + activity.Users.length}
                    openModal={(e) => {
                        e.stopPropagation();
                        setActivityToAddCollaborators({
                            ...activity,
                            activitySection_id: section?.id_e || "",
                        });
                    }}
                    activity={activity}
                    isEditing={false}
                />
                <div>
                    {activity.deadline
                        ? dayjs(activity.deadline)?.format("DD/MM/YYYY")
                        : "Sem prazo"}
                </div>
                <s.Priority type={activity.priority}>
                    <span>•</span>
                    {priorityMapper[activity.priority]}
                </s.Priority>
                <HourProgress
                    hourProgress={activity.hourProgress}
                    expectedMinutes={activity.expectedMinutes}
                />
                <div
                    className="last"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <PopoverRadix
                        align="end"
                        trigger={
                            <s.More className="hide-on-hover">
                                <Icons.More />
                            </s.More>
                        }
                    >
                        <s.MenuContainer>
                            <s.MenuOption
                                onClick={() => setActivityDetailsOpen(true)}
                                edit
                            >
                                <Icons.ExpandContent color={theme.gray800} />
                                Ver detalhes
                            </s.MenuOption>
                            {!archive && (
                                <s.MenuOption
                                    edit
                                    onClick={() =>
                                        toggleActivityEdit?.(activity.id_e)
                                    }
                                >
                                    <Icons.PencilOutline
                                        color={theme.gray800}
                                    />
                                    Editar
                                </s.MenuOption>
                            )}
                            {archive ? (
                                <s.MenuOption onClick={handleUnarchiveActivity}>
                                    <Icons.Unarchive color={theme.gray600} />
                                    Desarquivar
                                </s.MenuOption>
                            ) : (
                                <s.MenuOption onClick={handleArchiveActivity}>
                                    <Icons.IconArchive color={theme.gray600} />
                                    Arquivar
                                </s.MenuOption>
                            )}
                            {!archive && (
                                <s.MenuOption onClick={handleDuplicateActivity}>
                                    <Icons.Duplicate color={theme.gray600} />
                                    Duplicar
                                </s.MenuOption>
                            )}
                            <Tooltip
                                open={
                                    activity.hourProgress.minutesDone
                                        ? undefined
                                        : false
                                }
                                side="top"
                                align="end"
                                arrow
                                positionArrow={90}
                                positiony={5}
                                positionx={-10}
                                content={
                                    <s.TooltipContent
                                        style={{ width: "200px" }}
                                    >
                                        Não é possível deletar uma atividade com
                                        horas registradas.
                                    </s.TooltipContent>
                                }
                            >
                                <s.MenuOption
                                    danger
                                    disabled={Boolean(
                                        activity.hourProgress.minutesDone
                                    )}
                                    onClick={() => {
                                        if (!activity.hourProgress.minutesDone)
                                            setDeleteConfirmationActivityId(
                                                activity.id_e
                                            );
                                    }}
                                >
                                    <Icons.Trash
                                        color={
                                            activity.hourProgress.minutesDone
                                                ? theme.gray300
                                                : theme.red
                                        }
                                    />
                                    Deletar
                                </s.MenuOption>
                            </Tooltip>
                        </s.MenuContainer>
                    </PopoverRadix>
                </div>
            </s.ActivityRow>
        </>
    );
};
