import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
`;

export const CheckboxLabel = styled.p`
    color: ${({ theme }) => theme.purple400};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;
