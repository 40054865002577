import styled from "styled-components";

export const ProjectsList = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: flex-start;
    margin: 10px 0;

    div#projects-header {
        text-align: left;
    }

    span#title {
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.purple400};
    }

    div#projects-body {
        overflow-y: auto;
        max-height: 15rem;
        color: ${({ theme }) => theme.gray600};

        .tag {
            border-radius: 4px;
            background: ${({ theme }) => theme.white};
            padding: 4px 0;
            padding-right: 4px;

            span {
                width: 100%;
                text-align: left;
            }
        }
    }

    span {
        width: 100%;
    }
`;
