import { styled } from "styled-components";

import { Confetti } from "../../../../assets";

export const Container = styled.div`
    padding: 44px 16px 16px;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    width: 442px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 58px;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
`;

export const Points = styled.div`
    color: ${({ theme }) => theme.pink600};
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3px;
    background-image: ${`url(${Confetti})`};
    padding: 20px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
    max-width: 360px;
`;

export const PointsText = styled.p`
    color: ${({ theme }) => theme.pink600};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.12px;
`;
