import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
} from "date-fns";

export const optionsPeriods = [
    {
        label: "Hoje",
        value: { from: startOfDay(new Date()), to: endOfDay(new Date()) },
    },
    {
        label: "Ontem",
        value: {
            from: startOfDay(
                new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
            ),
            to: endOfDay(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)),
        },
    },
    {
        label: "Esta semana",
        value: {
            from: startOfWeek(new Date()),
            to: endOfWeek(new Date()),
        },
    },
    {
        label: "Última semana",
        value: {
            from: startOfWeek(
                new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7)
            ),
            to: endOfWeek(
                new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7)
            ),
        },
    },
    {
        label: "Este mês",
        value: {
            from: startOfMonth(new Date()),
            to: endOfMonth(new Date()),
        },
    },
    {
        label: "Último mês",
        value: {
            from: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 1,
                1
            ),
            to: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        },
    },
    {
        label: "Este ano",
        value: {
            from: startOfYear(new Date()),
            to: endOfYear(new Date()),
        },
    },
    {
        label: "Último ano",
        value: {
            from: startOfYear(
                new Date().setFullYear(new Date().getFullYear() - 1)
            ),
            to: endOfYear(new Date().setFullYear(new Date().getFullYear() - 1)),
        },
    },
];

export type TActivity = {
    description: string;
    total_time: number;
    start_at: string;
    end_at: string;
    project: string;
}[];

export type TTasks = {
    description: string;
    entry_percent: string;
    entry_time: number;
    id_e: string;
    start_date: string;
    start_at: string;
    end_at: string;
}[];

export type TReportError = {
    Author: {
        name: string;
    };
    msg: string;
}[];

export type TReport = {
    total_time: number;
    start_at: string;
    end_at: string;
    id_e: string;
    docFile?: string;
    invoices?: string[];
    billets?: string[];
    recipients?: string[];
    locked?: boolean;
    reviewed?: boolean;
    approved?: boolean;
    validated?: boolean;
    ReportBilling?: {
        Author: {
            name: string;
        };
        comment: string;
        final_total: string;
        additional?: string;
        discounts?: string;
    };
    ReportError?: TReportError;
    User?: {
        name?: string;
        Role?: {
            name?: string;
        };
        hourValue?: number;
        avatar?: string;
    };
    projects?: {
        color: string;
        id_e: string;
        name: string;
    }[];
};

export type TReports = TReport[];

export type TColumnData = {
    dates?: string[];
    times?: number[];
    periods?: string[];
    abreviatedDates?: string[];
};

export type TDoughnutData = {
    labels?: string[];
    times?: number[];
    colors?: string[];
    maxTime?: number;
};

export type TSingleReportProject = {
    project_name: string;
    project_client: string;
    project_color: string;
    project_id: string;
    project_percent: string;
    project_time: number;
    project_entries: TTasks;
    open?: boolean;
};

export type TReportProject = TSingleReportProject[];
