import { TNotificationReadBody } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    notificationType,
}: TNotificationReadBody = {}) => {
    const params = {
        "x-user-id": userId,
        "x-notifications-type": notificationType,
    };

    if (!notificationType) delete params["x-notifications-type"];

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/notifications/read`,
        method: "get",
        headers: { auth: true, ...params },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
