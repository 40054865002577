import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    height: 92vh;
    width: 99vw;
    align-items: center;
    justify-content: center;

    button {
        margin-top: 32px;
    }

    .inside-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const SideImage = styled.img`
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width: 40%;
    height: 100%;
    object-fit: cover;
`;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
        width: 420px;
    }
`;

export const Title = styled.h1`
    margin-bottom: 24px;
    color: ${({ theme }) => theme.purple500};
    font-size: 36px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0.09px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;

    a {
        color: ${({ theme }) => theme.purple600};
    }

    border-bottom: 1px solid ${({ theme }) => theme.gray100};
    padding-bottom: 80px;
`;

export const Subtitle = styled.h2`
    color: ${({ theme }) => theme.purple500};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
    margin-top: 80px;
`;
