import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ModalInviteMass, ModalInviteSingle } from "../../..";
import { TUseFetch } from "../../../../types/TUseFetch";
import { PrimaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-invite-choose";

export type TModalInviteChoose = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIsOpenChoose?: Dispatch<SetStateAction<boolean>>;
    getSketches?: TUseFetch;
    getInvites?: TUseFetch;
};

const ModalInviteChoose = ({
    setOpen,
    setIsOpenChoose,
    getSketches,
    getInvites,
}: TModalInviteChoose) => {
    const navigate = useNavigate();

    const [selected, setSelected] = useState<"mass" | "individual">();
    const [openSingleInvite, setOpenSingleInvite] = useState(false);
    const [openMassInvite, setOpenMassInvite] = useState(false);

    return (
        <>
            {openSingleInvite && (
                <ModalInviteSingle
                    setOpen={setOpenSingleInvite}
                    setIsOpenChoose={setIsOpenChoose}
                    getSketches={getSketches}
                    getInvites={getInvites}
                />
            )}
            {openMassInvite && (
                <ModalInviteMass
                    setOpen={setOpenMassInvite}
                    setIsOpenChoose={setIsOpenChoose}
                    getInvites={getInvites}
                />
            )}
            <ContainerModal onOpenChange={setOpen}>
                {openSingleInvite || openMassInvite ? (
                    <></>
                ) : (
                    <s.Container>
                        <span id="modal-title">Criar convite</span>

                        <p id="modal-description">
                            Escolha o formato de convite que quer preencher ao
                            adicionar novo profissional:
                        </p>

                        <div id="invite-cards">
                            <s.InviteCard
                                onClick={() => setSelected("individual")}
                                selected={selected === "individual"}
                            >
                                <span className="card-title">
                                    ADICIONAR INDIVIDUALMENTE
                                </span>

                                <p className="card-description">
                                    Complete os dados de cada profissional um a
                                    um, diretamente dentro do sistema PaperON.
                                </p>
                            </s.InviteCard>

                            <s.InviteCard
                                onClick={() => setSelected("mass")}
                                selected={selected === "mass"}
                            >
                                <span className="card-title">
                                    ADICIONAR EM MASSA
                                </span>

                                <p className="card-description">
                                    Você pode adicionar até 1.000
                                    <br />
                                    profissionais simultaneamente, importando
                                    uma planilha Excel com os dados dos
                                    profissionais.
                                </p>
                            </s.InviteCard>
                        </div>

                        <PrimaryButton
                            disabled={!selected}
                            onClick={() => {
                                if (selected === "individual") {
                                    setOpenSingleInvite(true);
                                } else {
                                    setOpenMassInvite(true);
                                }
                            }}
                        >
                            Continuar
                        </PrimaryButton>
                    </s.Container>
                )}
            </ContainerModal>
        </>
    );
};

export default ModalInviteChoose;
