import * as s from "./styles";

type MonthPickerProps = {
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    value?: string;
    defaultValue?: string;
};

export const MonthPicker = ({
    value,
    defaultValue,
    onChange,
}: MonthPickerProps) => {
    return (
        <s.Container>
            <input
                type="month"
                value={value?.slice(0, 7)}
                defaultValue={defaultValue?.slice(0, 7)}
                onChange={onChange}
            />
        </s.Container>
    );
};
