import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TCreateSubItem = {
    title?: string;
    users?: string[];
    activityId?: string;
    checklistId?: string;
};

export default async ({
    title,
    users,
    activityId,
    checklistId,
}: TCreateSubItem = {}) => {
    const headers = {
        "x-activity-id": activityId,
        "x-checklist-id": checklistId,
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/subtask/create`,
        method: "post",
        body: {
            title,
            users,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
