import { useMutation } from "@tanstack/react-query";

import { updateUserConfigs } from "../../services/user";
import { TUpdateUserConfigs } from "../../services/user/update-user-configs";

type Params = {
    onSuccess:
        | ((
              data: any,
              variables: TUpdateUserConfigs | undefined,
              context: unknown
          ) => unknown)
        | undefined;
};

export function useUpdateUserConfigs({ onSuccess }: Params) {
    return useMutation({
        mutationKey: ["updateUserConfigs"],
        mutationFn: updateUserConfigs,
        onSuccess,
    });
}
