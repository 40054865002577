import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const DateFields = styled.div`
    display: flex;
    gap: 16px;
    max-width: 100%;
`;

export const DescriptionField = styled.div``;

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 16px;

    button {
        &:first-child {
            max-width: 113px;
        }

        &:last-child {
            max-width: 215px;
        }
    }
`;
