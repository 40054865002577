import { useEffect, useState } from "react";
import Switch from "react-switch";

import { theme } from "../../../assets";
import { TUser } from "../../../models";
import { RefuseReportModal } from "./refuse-report-modal";
import * as s from "./styled-refuse-report-toggle";

type TRefuseReportToggleProps = {
    mode: "refuse" | "correct";
    user?: TUser;
    open?: boolean;
    handleOpenModal?: () => void;
    onClickRefuseReport?: () => void;
    onClickSendCorrections?: () => void;
};

export const RefuseReportToggle = ({
    mode,
    user,
    onClickRefuseReport,
    onClickSendCorrections,
    open,
    handleOpenModal,
}: TRefuseReportToggleProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [refuseMessage, setRefuseMessage] = useState("");
    const location = window.location.pathname;
    const [addAnimation, setAddAnimation] = useState(false);
    const openToggle = () => {
        setRefuseMessage(user?.TimeReports?.[0]?.ReportError[0]?.msg || "");
        setIsOpen(!isOpen);
        if (location === "/cronometro" && isOpen === true) {
            setAddAnimation(true);
        }
        if (location === "/cronometro" && isOpen === false) {
            setAddAnimation(false);
        }
        if (!isOpen && handleOpenModal) {
            handleOpenModal();
            setAddAnimation(false);
        }
    };

    useEffect(() => {
        if (open) {
            setIsOpen(true);
        }
    }, [open]);

    useEffect(() => {
        if (location === "/cronometro" && !isOpen) {
            setAddAnimation(true);
        }
    }, []);

    return (
        <>
            <RefuseReportModal
                message={refuseMessage}
                onClickRefuseReport={onClickRefuseReport}
                onClickSendCorrections={onClickSendCorrections}
                isOpen={isOpen}
                mode={mode}
            />
            <s.Container withAnimation={addAnimation} onClick={openToggle}>
                <s.ContainerToggle withAnimation={addAnimation}>
                    <Switch
                        width={20}
                        height={6}
                        handleDiameter={12}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={theme.pink50}
                        offColor={theme.pink50}
                        offHandleColor={theme.purple500}
                        onHandleColor={theme.purple500}
                        checked={isOpen}
                        onChange={() => ""}
                    />
                </s.ContainerToggle>
            </s.Container>
        </>
    );
};
