import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/missions/daily`,
        method: "get",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
