import { TOrganization } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TUpdateOrganization = {
    orgData?: TOrganization;
};

export default async ({ orgData }: TUpdateOrganization = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/organization/update`,
        method: "put",
        headers: { auth: true },
        body: orgData,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Não foi possível atualizar a organização!",
        401: "Acesso negado",
        404: "Organização não existente!",
        409: "Já existe uma organização com esse telefone, e-mail ou CNPJ!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
