import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    height,
    width,
}: TBaseSVG) => (
    <svg
        className={className || "icon-x-solid"}
        onClick={onClick}
        width={width || "20"}
        height={height || "20"}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29303 4.29296C4.48056 4.10549 4.73487 4.00017 5.00003 4.00017C5.26519 4.00017 5.5195 4.10549 5.70703 4.29296L10 8.58596L14.293 4.29296C14.3853 4.19745 14.4956 4.12127 14.6176 4.06886C14.7396 4.01645 14.8709 3.98886 15.0036 3.98771C15.1364 3.98655 15.2681 4.01186 15.391 4.06214C15.5139 4.11242 15.6255 4.18667 15.7194 4.28056C15.8133 4.37446 15.8876 4.48611 15.9379 4.60901C15.9881 4.7319 16.0134 4.86358 16.0123 4.99636C16.0111 5.12914 15.9835 5.26036 15.9311 5.38236C15.8787 5.50437 15.8025 5.61471 15.707 5.70696L11.414 9.99996L15.707 14.293C15.8892 14.4816 15.99 14.7342 15.9877 14.9964C15.9854 15.2586 15.8803 15.5094 15.6948 15.6948C15.5094 15.8802 15.2586 15.9854 14.9964 15.9876C14.7342 15.9899 14.4816 15.8891 14.293 15.707L10 11.414L5.70703 15.707C5.51843 15.8891 5.26583 15.9899 5.00363 15.9876C4.74143 15.9854 4.49062 15.8802 4.30521 15.6948C4.1198 15.5094 4.01463 15.2586 4.01236 14.9964C4.01008 14.7342 4.11087 14.4816 4.29303 14.293L8.58603 9.99996L4.29303 5.70696C4.10556 5.51943 4.00024 5.26512 4.00024 4.99996C4.00024 4.73479 4.10556 4.48049 4.29303 4.29296Z"
            fill={color || theme.purple600}
        />
    </svg>
);
