import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
    >
        <title>{title}</title>
        <g id="arrow-path">
            <path
                id="Vector"
                d="M16.0234 9.34745H21.0154L17.8344 6.16445C16.8103 5.14032 15.5346 4.40385 14.1356 4.02906C12.7366 3.65427 11.2636 3.65438 9.86464 4.02937C8.4657 4.40437 7.19014 5.14104 6.16617 6.16532C5.14221 7.1896 4.40594 8.4654 4.03138 9.86445M2.98537 19.6435V14.6515M2.98537 14.6515H7.97738M2.98537 14.6515L6.16537 17.8345C7.18949 18.8586 8.46517 19.5951 9.86416 19.9698C11.2632 20.3446 12.7362 20.3445 14.1351 19.9695C15.534 19.5945 16.8096 18.8579 17.8336 17.8336C18.8575 16.8093 19.5938 15.5335 19.9684 14.1345M21.0154 4.35545V9.34545"
                stroke={color || theme.gray600}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
