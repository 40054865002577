import { styled } from "styled-components";

export const Container = styled.div``;

export const AnswersRow = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;

    span {
        &:first-child {
        }

        &:last-child {
        }
    }
`;

export const BugRow = styled.div`
    display: grid;
    grid-template-columns: 60% 20% 20%;
    padding: 10px;
    align-items: center;
    width: 100%;

    strong {
        font-weight: 600;
        color: ${({ theme }) => theme.purple500};
    }
`;

export const Email = styled.p`
    width: 30%;
    color: ${({ theme }) => theme.purple500};
    font-weight: 500;
    font-size: 15px;
`;

export const Description = styled.p`
    margin-right: 10%;
    font-size: 15px;
`;

export const ClickToView = styled.p`
    width: 20%;
    color: ${({ theme }) => theme.purple500};
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
`;

export const CreatedAt = styled.p`
    width: 20%;
    font-size: 15px;
`;

export const Rating = styled.p`
    width: 10%;
    color: ${({ theme }) => theme.purple500};
    font-weight: 500;
    font-size: 15px;
`;

export const Comment = styled.p`
    width: 60%;
    margin-right: 10%;
    font-size: 15px;
`;

export const Date = styled.p`
    width: 20%;
    font-size: 15px;
`;
