import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";
import { StartEntryVariables } from "./types/start-entry";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    startAt,
    project,
    sectorId,
    activityId,
}: StartEntryVariables = {}) => {
    const tempHeaders = {
        auth: true,
        "x-project-id": project,
        "x-sector-id": sectorId,
        "x-activity-id": activityId,
    };

    if (!project) delete tempHeaders["x-project-id"];
    if (!sectorId) delete tempHeaders["x-sector-id"];
    if (!activityId) delete tempHeaders["x-activity-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/start`,
        method: "post",
        body: { start_at: startAt },
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorsMapper = {
        "you must link the user to project":
            "Você não faz mais parte desse projeto!",
        "It's impossible to do this, you've already exceeded the project hour limit":
            "Você excedeu as horas previstas neste projeto!",
        "Activity is archived":
            "Você não pode lançar tempo para uma atividade arquivada!",
        "The x-activity-id field is required.": "Atividade é obrigatória!",
        "It's impossible to do this, you've already exceeded the hour limit":
            "Você excedeu as horas previstas neste mês!",
        "impossible to do this to an canceled project!":
            "Você não pode lançar entradas de tempo para um projeto cancelado.",
        "impossible to do this to an paused project!":
            "Você não pode lançar entradas de tempo para um projeto pausado.",
        "impossible to do this to an finished project!":
            "Você não pode lançar entradas de tempo para um projeto finalizado.",
        "project is archived": "O projeto está arquivado.",
    };

    const error = findErrorString<keyof typeof errorsMapper>(body);

    const GENERIC_ERROR_MESSAGE = "Campos obrigatórios não preenchidos";

    const errorFeedBacks = {
        "Impossible to do this to a locked report!":
            "Relatório já fechado no mês desse registro",
        "impossible to do this to a locked report!":
            "Relatório já fechado no mês desse registro",
        "Impossible to do this to an finished project!":
            "Impossivel fazer isso em um projeto encerrado!",
        "Project and sector must be linked!":
            "Projeto e setor devem estar vinculados!",
        "you must link the user to sector":
            "Você precisa vincular o usuário ao setor!",
        "sector does not exists!": "Setor não existe!",
        "project does not exists!": "Projeto não existe!",
        409: "Você já possui uma entrada de tempo aberta",
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
