import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 50px;
    width: 80vw;

    button {
        max-width: 300px;
    }

    .icon-x-outline {
        width: 50px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`;

export const Filter = styled.div`
    display: flex;
    gap: 8px;
    width: 400px;
    height: 48px;
    margin-bottom: -10px;
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    align-items: center;
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
    padding: 20px 40px;
`;

export const DataGridContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
`;

export const Observation = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.gray600};
    align-self: flex-start;
    margin-left: 60px;
    font-size: 14px;
`;
