import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-pencil-outline"}
        onClick={onClick}
    >
        <title>{title}</title>
        <g id="PencilAltOutline">
            <path
                id="Vector"
                d="M8.25 3.74988H4.5C4.10218 3.74988 3.72064 3.90791 3.43934 4.18922C3.15804 4.47052 3 4.85205 3 5.24988V13.4999C3 13.8977 3.15804 14.2792 3.43934 14.5605C3.72064 14.8418 4.10218 14.9999 4.5 14.9999H12.75C13.1478 14.9999 13.5294 14.8418 13.8107 14.5605C14.092 14.2792 14.25 13.8977 14.25 13.4999V9.74988M13.1895 2.68938C13.3279 2.54611 13.4934 2.43184 13.6764 2.35323C13.8594 2.27461 14.0562 2.23323 14.2554 2.2315C14.4546 2.22977 14.6521 2.26772 14.8364 2.34315C15.0208 2.41857 15.1883 2.52995 15.3291 2.67079C15.4699 2.81162 15.5813 2.9791 15.6567 3.16345C15.7322 3.34779 15.7701 3.54531 15.7684 3.74448C15.7666 3.94365 15.7253 4.14048 15.6467 4.32348C15.568 4.50649 15.4538 4.67201 15.3105 4.81038L8.871 11.2499H6.75V9.12888L13.1895 2.68938Z"
                stroke={color || theme.gray600}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
