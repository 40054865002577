import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";

import { Input, TextArea } from "../../../../../components";
import { IPermission } from "../../roles-form";
import * as s from "./styled-input-group";

type TInputGroupProps = {
    register: UseFormRegister<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    watch: UseFormWatch<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    errors: FieldErrors<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    loading: boolean;
    view?: boolean;
};

export const InputGroup = ({
    register,
    watch,
    errors,
    loading,
    view,
}: TInputGroupProps) => {
    return (
        <s.Container>
            <Input
                {...register("name")}
                label={"Nome da atuação *"}
                mask=""
                type="text"
                placeholder="Digite aqui o nome da atuação"
                disabled={loading || view}
                error={errors.name?.message}
            />

            <TextArea
                {...register("description")}
                label={"Descrição da atuação"}
                placeholder={
                    "Digite aqui uma pequena descrição sobre esta atuação"
                }
                disabled={loading || view}
                charCount
                counter={watch("description")}
                maxLength={300}
            />
        </s.Container>
    );
};
