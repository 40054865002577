import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import {
    CheckBox,
    Input,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
} from "../../../../../../../../components";
import ContainerModal from "../../../../../../../../components/molecules/modals/container-modal";
import { TOption } from "../../../../../../../../models";
import { vouchers } from "../../../../../../../../services";
import { queryClient } from "../../../../../../../../utils/query-client/query-client";
import * as s from "./styles";

type Fields = {
    name: string;
    description?: string;
    type: {
        value: "daysFree";
        label: string;
    };
    active: boolean;
    value: number;
};

const schema = yup.object({
    name: yup.string().required("Obrigatório"),
    description: yup.string(),
    type: yup
        .object({
            label: yup.string(),
            value: yup.string(),
        })
        .nullable()
        .required("Obrigatório"),
    active: yup.boolean(),
    value: yup.number().typeError("Obrigatório").required("Obrigatório"),
});

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateVoucherModal = ({ setOpen }: Props) => {
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<Fields>({
        resolver: yupResolver(schema),
    });

    const options: Array<Fields["type"]> = [
        {
            label: "Cupom de dias grátis",
            value: "daysFree",
        },
    ];

    const createVoucher = useMutation({
        mutationKey: ["createVoucher"],
        mutationFn: vouchers.createVoucher,
        onSuccess() {
            toast.success("Cupom criado com sucesso");
            setOpen(false);
        },
        onError() {
            toast.error("Já existe um cupom com esse nome");
        },
        onSettled() {
            queryClient.invalidateQueries(["getVouchers"]);
        },
    });

    const submit = (values: Fields) => {
        createVoucher.mutate({
            ...values,
            type: values.type.value,
        });
    };

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>Criar cupom</s.Title>
                <s.Fields>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Nome"
                                value={value}
                                onChange={onChange}
                                error={errors.name?.message}
                                placeholder="Exemplo: FREE12PAPERON"
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Descrição"
                                value={value}
                                onChange={onChange}
                                placeholder="Essa informação não será visualizada pelo usuário"
                                error={errors.description?.message}
                            />
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        defaultValue={options[0]}
                        render={({ field: { onChange, value } }) => (
                            <SelectInput
                                label="Tipo"
                                placeholder="Selecione o tipo de cupom"
                                options={options}
                                value={value}
                                onChange={onChange}
                                error={errors.type?.message}
                                noError={!errors.type?.message}
                            />
                        )}
                    />
                    <Controller
                        name="value"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Dias grátis do cupom"
                                value={value}
                                onChange={onChange}
                                placeholder="Exemplo: 30"
                                error={errors.value?.message}
                                type="number"
                            />
                        )}
                    />
                    <Controller
                        name="active"
                        control={control}
                        defaultValue={true}
                        render={({ field: { onChange, value } }) => (
                            <CheckBox
                                id=""
                                label="Ativo"
                                width={18}
                                height={18}
                                isChecked={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit">Criar</PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
