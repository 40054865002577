import { TResendInvite } from "../../models";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ inviteIds }: TResendInvite = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/resendInvite`,
        method: "post",
        headers: { auth: true },
        body: { inviteIds },
    });
    const { body, statusCode } = httpResponse;

    const messages = {
        "user limit reached for this plan":
            "Você atingiu o limite de usuários para este plano",
    };
    const error = findErrorString<keyof typeof messages>(body);

    const errorFeedBacks = {
        201: "Convite reenviado com sucesso!",
        400: messages[error] ?? "Erro! campos obrigatórios não preenchidos",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
