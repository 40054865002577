import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { DeleteEntriesVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ id }: DeleteEntriesVariables = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/delete`,
        method: "delete",
        headers: { auth: true, "x-entries-id": id },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Você não pode deletar uma entrada de tempo trancada!",
        401: "Acesso negado",
        404: "Entrada de tempo inexistente!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
