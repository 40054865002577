import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({
    title,
    color,
    className,
    strokeWidth,
    height,
    width,
}: TBaseSVG) => (
    <svg
        width={width || "20"}
        height={height || "20"}
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-warning"}
    >
        <title>{title}</title>
        <path
            d="M9.99944 6.99975V8.99975M9.99944 12.9998H10.0094M3.07144 16.9998H16.9274C18.4674 16.9998 19.4294 15.3328 18.6594 13.9998L11.7314 1.99975C10.9614 0.66675 9.03744 0.66675 8.26744 1.99975L1.33944 13.9998C0.569436 15.3328 1.53144 16.9998 3.07144 16.9998Z"
            stroke={color || theme.redError}
            strokeWidth={strokeWidth || "1.2"}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
