/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import Switch from "react-switch";

import { theme } from "../../../assets";
import {
    CheckBox,
    Input,
    PrimaryButton,
    SecondaryButton,
    Tag,
    TextArea,
    ModalAction,
    LoadingGray,
} from "../../../components";
import { useFetch } from "../../../hooks";
import { TCreateSector, TUpdateSector } from "../../../models";
import { projects, sectors } from "../../../services";
import * as s from "./styled-sectors-form";
import validation from "./validation";

type TSectorsForm = {
    edit?: boolean;
    view?: boolean;
};

type TGetSectorsObject = {
    id_e: string;
    name: string;
    description: string;
    created_at: string;
    Users: {
        id_e: string;
        name: string;
        avatar?: string;
        permissions: string[];
    }[];
    Projects: {
        id_e: string;
        name: string;
        color?: string;
    }[];
};

type TGetProjectsObject = {
    id_e: any;
    name: string;
    color?: string;
};

export const SectorsForm = ({ edit, view }: TSectorsForm) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const { sector }: { sector: TGetSectorsObject } = location.state || "";

    const [isSwitchChecked, setIsSwitchChecked] = useState(false);

    const [projectsData, setProjectsData] = useState<TGetProjectsObject[]>([]);
    const [linkedProjects, setLinkedProjects] = useState<string[]>(() =>
        edit
            ? sector?.Projects.map(
                  (project) => project?.id_e && project.id_e
              ) || []
            : []
    );
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);

    const renderDefaultValues = () => {
        const objDefault: Record<string, string | boolean> = {
            name: sector?.name || "",
            description: sector?.description || "",
        };

        const resp = objDefault;

        sector?.Projects.forEach(
            (project: Record<string, string>) =>
                project?.id_e && (resp[project.id_e] = true)
        );

        return resp;
    };

    const {
        control,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<{
        name: string;
        description: string;
        projects: Record<string, string>[];
    }>({
        resolver: yupResolver(validation()),
        defaultValues: renderDefaultValues(),
    });

    const getProjects = useFetch({
        fn: projects.reducedReadProject,
        start: false,
    });

    const createSector = useFetch({
        fn: sectors.createSector,
        start: false,
    });

    const updateSector = useFetch({
        fn: sectors.updateSector,
        start: false,
    });

    const deleteSector = useFetch({
        fn: sectors.deleteSector,
        start: false,
    });

    const projectIsDefaultChecked = (availableProjectID: string) => {
        let isChecked = false;

        sector?.Projects.forEach((project: Record<string, string>) => {
            const linkedProjectID = project?.id_e;

            if (linkedProjectID === availableProjectID) isChecked = true;
        });

        return isChecked;
    };

    const toggleLinkProject = (id: string) => {
        const exist = linkedProjects?.includes(id);
        if (exist) {
            const arrayNovo = linkedProjects?.filter(
                (projectId) => id !== projectId
            );
            setLinkedProjects(arrayNovo);
        } else setLinkedProjects((prev) => [...prev, id]);
    };

    const toggleSwitchProject = () => {
        setIsSwitchChecked(!isSwitchChecked);
        if (isSwitchChecked === true) setLinkedProjects([]);
        else getProjects.onRefresh();
    };

    const handleVerifyChange = (data: TCreateSector) => {
        const selectedProjects = sector.Projects.map(
            (project: Record<string, string>) => project.id_e
        );
        const iguais =
            JSON.stringify(selectedProjects) === JSON.stringify(linkedProjects);

        if (edit) {
            if (
                sector.name !== data.name ||
                sector.description !== data.description ||
                !iguais
            )
                return true;
        }
        return false;
    };

    const handleCreateSector = (data: TCreateSector) => {
        createSector.onRefresh({
            name: data.name,
            description: data.description,
            projectsId: linkedProjects && linkedProjects?.join(", "),
        });
    };

    const handleUpdateSector = (data: TUpdateSector) => {
        if (handleVerifyChange(data)) {
            updateSector.onRefresh({
                name: data.name,
                description: data.description,
                sectorId: sector.id_e,
                projectsId: linkedProjects.join(", "),
            });
        } else {
            toast.success("Setor atualizado com sucesso!");
            setShowDialog(false);
            setShowDialogDelete(false);
            setLoading(false);
            navigate("/organizacao/setores");
        }
    };

    const handleDeleteSector = () => {
        deleteSector.onRefresh({
            sectorId: sector?.id_e,
        });
    };

    useEffect(() => {
        if (edit || view) {
            toggleSwitchProject();
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (getProjects.error) toast.error(`${getProjects.error?.message}`);
        if (getProjects.response) {
            setProjectsData(getProjects.response.projects);
            setLoading(false);
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (createSector.error) toast.error(`${createSector.error?.message}`);
        if (createSector.response) {
            toast.success("Setor criado com sucesso!");
            setShowDialog(false);
            setShowDialogDelete(false);
            setLoading(false);
            navigate("/organizacao/setores");
        }
    }, [createSector.response]);

    useEffect(() => {
        if (deleteSector.error) toast.error(`${deleteSector.error?.message}`);
        if (deleteSector.response) {
            toast.success("Setor deletado com sucesso!");
            setShowDialog(false);
            setShowDialogDelete(false);
            setLoading(false);
            navigate("/organizacao/setores");
        }
    }, [deleteSector.response]);

    useEffect(() => {
        if (updateSector.error) toast.error(`${updateSector.error?.message}`);
        if (updateSector.response) {
            toast.success("Setor atualizado com sucesso!");
            setShowDialog(false);
            setShowDialogDelete(false);
            setLoading(false);
            navigate("/organizacao/setores");
        }
    }, [updateSector.response]);

    return (
        <>
            <s.Form onSubmit={(event) => event.preventDefault()}>
                {showDialog && (
                    <ModalAction
                        setOpen={setShowDialog}
                        title={"Sair sem salvar alterações"}
                        description={
                            "Os dados alterados não serão salvos. Deseja sair mesmo assim?"
                        }
                        primaryAction={() => {
                            setShowDialog(false);
                            navigate("/organizacao/setores");
                        }}
                        secondaryAction={() => {
                            setShowDialog(false);
                        }}
                        primaryText={"Cancelar"}
                        secondaryText={"Sair"}
                    />
                )}

                {showDialogDelete && (
                    <ModalAction
                        setOpen={setShowDialogDelete}
                        title={"Deseja mesmo deletar o setor?"}
                        description={"Essa ação não pode ser revertida"}
                        primaryAction={handleDeleteSector}
                        primaryText={"Deletar Setor"}
                        secondaryAction={() => setShowDialogDelete(false)}
                        secondaryText={"Cancelar"}
                    />
                )}

                {edit && (
                    <div id="form-header">
                        <div></div>

                        <div>
                            <SecondaryButton
                                children={"Deletar Setor"}
                                onClick={() => setShowDialogDelete(true)}
                                color={theme.red}
                            />
                        </div>
                    </div>
                )}

                <div className="input-group">
                    <Input
                        {...register("name")}
                        label={"Nome do setor *"}
                        mask=""
                        type="text"
                        placeholder="Digite aqui o nome do setor que você está criando"
                        disabled={loading || view}
                        maxLength={100}
                        error={errors.name?.message}
                    />

                    <TextArea
                        {...register("description")}
                        label={"Descrição do setor"}
                        placeholder={
                            "Digite aqui uma pequena descrição sobre este setor"
                        }
                        charCount
                        counter={watch("description")}
                        maxLength={300}
                        disabled={loading || view}
                        error={errors.description?.message}
                    />
                </div>

                <div className="switch">
                    <Switch
                        width={40}
                        height={14}
                        handleDiameter={26}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={theme.purple100}
                        offColor={theme.gray400}
                        offHandleColor={theme.white}
                        onHandleColor={theme.purple500}
                        boxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
                        activeBoxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
                        checked={isSwitchChecked}
                        onChange={toggleSwitchProject}
                        disabled={view}
                    />
                    <p>Vincular este setor em projetos</p>
                </div>

                <div className="input-group">
                    {isSwitchChecked && (
                        <s.ProjectList>
                            <div id="projetos-header">
                                <p>Projetos</p>
                            </div>

                            {loading ? (
                                <LoadingGray />
                            ) : projectsData.length > 0 ? (
                                <div id="projetos-content">
                                    {projectsData.map((project, index) => (
                                        <div id="projetos-row" key={index}>
                                            <Controller
                                                control={control}
                                                name={project?.id_e}
                                                render={() => (
                                                    <CheckBox
                                                        id="id-checkbox"
                                                        width={16}
                                                        height={16}
                                                        strokeWidth="3"
                                                        disabled={view}
                                                        isChecked={
                                                            projectIsDefaultChecked(
                                                                project?.id_e
                                                            ) ||
                                                            linkedProjects?.includes(
                                                                project?.id_e
                                                            )
                                                        }
                                                        onChange={() =>
                                                            toggleLinkProject(
                                                                project?.id_e
                                                            )
                                                        }
                                                        label={
                                                            (
                                                                <Tag
                                                                    color={
                                                                        project?.color ||
                                                                        theme.purple500
                                                                    }
                                                                >
                                                                    {
                                                                        project?.name
                                                                    }
                                                                </Tag>
                                                            ) || "Sem projeto"
                                                        }
                                                    />
                                                )}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div id="projetos-no-content">
                                    <p>Sem informações até o momento</p>
                                </div>
                            )}
                        </s.ProjectList>
                    )}
                </div>

                <div className="button-group">
                    {!view && (
                        <PrimaryButton
                            children={
                                edit ? "Salvar alterações" : "Criar Setor"
                            }
                            onClick={
                                edit
                                    ? handleSubmit(handleUpdateSector)
                                    : handleSubmit(handleCreateSector)
                            }
                            loading={
                                createSector.loading || updateSector.loading
                            }
                        />
                    )}
                    <SecondaryButton
                        onClick={() => navigate("/organizacao/setores")}
                    >
                        {"Voltar"}
                    </SecondaryButton>
                </div>
            </s.Form>
        </>
    );
};
