import styled from "styled-components";

import { theme } from "../../../assets";

type TContainer = {
    color: string;
    isFavorite?: boolean;
};

export const Container = styled.span<TContainer>`
    display: flex;
    flex-direction: row;
    width: max-content;
    border-radius: 4px;
    padding: 4px 7px 4px 12px;
    margin-right: auto;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    border: 1px solid ${({ color }) => color || theme.purple500}1A;
    background: ${({ color }) => color || theme.purple500}0D;

    color: ${({ color }) => color || theme.purple500};
    list-style: none;
    text-align: justify;
    font-size: 0.875rem;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 10px;
    }

    ${({ isFavorite, color }) =>
        !isFavorite &&
        `
         &::before {
            content: "";
            display: flex;
            min-width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${color || theme.purple500};
        }
    `}
`;
