import { Icons } from "../../assets";

export const planTypeMapper = {
    monthly: "Mensal",
    yearly: "Anual",
};

export const planTypeRecurrenceMapper = {
    monthly: "mês",
    yearly: "ano",
};

export const plans = [
    {
        id: "lite",
        name: "Lite",
        monthlyPrice: 29.7,
        yearlyPrice: 326.7,
        maxUsers: 3,
    },
    {
        id: "starter",
        name: "Starter",
        monthlyPrice: 89.0,
        yearlyPrice: 979.0,
        maxUsers: 10,
    },
    {
        id: "standard",
        name: "Standard",
        monthlyPrice: 158.0,
        yearlyPrice: 1738.0,
        maxUsers: 20,
    },
    {
        id: "business",
        name: "Business",
        monthlyPrice: 0,
        yearlyPrice: 0,
        maxUsers: 999999999999999,
    },
];

export const cardBrands = {
    Visa: <Icons.VisaCard />,
    Mastercard: <Icons.MasterCard />,
    Elo: <Icons.EloCard />,
    Amex: <Icons.AmexCard />,
    Hipercard: <Icons.AmexCard />,
};

export const billStatusMapper = {
    paid: "Aprovado",
    failed: "Recusado",
};
