import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../components";
import { TResponse } from "../../../../../models";
import { TUpdateSection } from "../../../../../services/activities/update-section";
import { TSection } from "../types";
import * as s from "./styled-components";

type TEditSection = {
    section: TSection;
    updateSection: TResponse<unknown, TUpdateSection>;
    toggleSectionEdit: (index: number) => void;
    index: number;
};

export const EditSection = ({
    section,
    updateSection,
    toggleSectionEdit,
    index,
}: TEditSection) => {
    const {
        register,
        reset,
        handleSubmit,
        setFocus,
        setValue,
        formState: { errors },
    } = useForm();

    const submit = handleSubmit(({ sectionName }) => {
        updateSection.onRefresh({
            name: sectionName,
            sectionId: section.id_e,
        });
    });

    useEffect(() => {
        updateSection.response = undefined;
    }, []);

    useEffect(() => {
        if (updateSection.response) {
            toggleSectionEdit(index);
        }
    }, [updateSection.response]);

    useEffect(() => {
        if (section) {
            setFocus("sectionName");
            setValue("sectionName", section.name);
        }
    }, [section]);

    return (
        <s.AddSectionContainer
            onSubmit={submit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    reset();
                    toggleSectionEdit(index);
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <div>
                <Input
                    {...register("sectionName", {
                        required: {
                            value: true,
                            message: "Campo obrigatório",
                        },
                    })}
                    placeholder="Escreva o nome da sessão"
                    maxLength={150}
                    mask=""
                    error={errors.sectionName?.message as string}
                />
                <s.ButtonsContainer>
                    <SecondaryButton
                        type="button"
                        onClick={() => {
                            reset();
                            toggleSectionEdit(index);
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        id="save"
                        type="submit"
                        loading={updateSection.loading}
                    >
                        SALVAR
                    </PrimaryButton>
                </s.ButtonsContainer>
            </div>
        </s.AddSectionContainer>
    );
};
