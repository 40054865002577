import styled from "styled-components";

import { theme } from "../../../assets";

type TPermissionsCheck = {
    checked?: boolean;
};

export const Container = styled.div<TPermissionsCheck>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.gray100};

    div#header {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ checked }) => (checked ? theme.purple400 : theme.gray600)};
    }

    div#content {
        display: flex;
        align-items: center;
        gap: 1.125rem;

        font-size: 0.75rem;
        font-weight: 400;
        color: ${theme.gray400};
    }

    div#description {
        p {
            width: 100%;
        }
    }
`;
