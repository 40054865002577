import { CSSProperties, ReactNode } from "react";

import * as s from "./styled-collapse";

interface ICollapse {
    open?: boolean;
    children?: ReactNode;
    style?: CSSProperties;
    height?: string;
}

export default ({ open, children, style, height = "10rem" }: ICollapse) => (
    <s.Collapse style={style} open={open} height={height}>
        {children}
    </s.Collapse>
);
