import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    width,
    height,
    title,
    color,
    className,
    onClick,
}: TBaseSVG) => {
    return (
        <svg
            width={width || "28"}
            height={height || "28"}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <title>{title}</title>
            <g id="check-circle">
                <path
                    id="Vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9998 25.2001C16.9702 25.2001 19.819 24.0201 21.9194 21.9196C24.0198 19.8192 25.1998 16.9705 25.1998 14C25.1998 11.0296 24.0198 8.18086 21.9194 6.08045C19.819 3.98005 16.9702 2.80005 13.9998 2.80005C11.0294 2.80005 8.18062 3.98005 6.08021 6.08045C3.9798 8.18086 2.7998 11.0296 2.7998 14C2.7998 16.9705 3.9798 19.8192 6.08021 21.9196C8.18062 24.0201 11.0294 25.2001 13.9998 25.2001ZM19.1896 12.1898C19.4446 11.9258 19.5857 11.5722 19.5825 11.2051C19.5794 10.838 19.4321 10.4869 19.1726 10.2273C18.913 9.96773 18.5618 9.8205 18.1948 9.81731C17.8277 9.81412 17.474 9.95523 17.21 10.2102L12.5998 14.8204L10.7896 13.0103C10.5256 12.7552 10.1719 12.6141 9.80484 12.6173C9.43777 12.6205 9.08663 12.7677 8.82706 13.0273C8.56749 13.2869 8.42025 13.638 8.41706 14.0051C8.41387 14.3722 8.55498 14.7258 8.81001 14.9898L11.61 17.7898C11.8725 18.0523 12.2286 18.1998 12.5998 18.1998C12.971 18.1998 13.3271 18.0523 13.5896 17.7898L19.1896 12.1898Z"
                    fill={color || theme.green1}
                />
            </g>
        </svg>
    );
};
