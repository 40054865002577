import dayjs from "dayjs";

import { Icons, theme } from "../../../../../../../../assets";
import Tooltip from "../../../../../../../../components/atoms/tooltip/tooltip";
import { TProject } from "../../../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../../../services/projects/types";
import { StackedLineStyles } from "../stacked-line/stacked-line";
import * as s from "./styles";

type Props = {
    data: ReadDemonstrativeResult | undefined;
    project: TProject;
};

const INITIAL_HOUR = 0;
const MAX_PERCENTAGE = 100;
const OVERLAP_VALUE = 3;

export const RealizedHours = ({ project, data }: Props) => {
    if (!data) return null;

    const projectEstimatedHours = project.estimatedHours || 0;
    const { hoursDone, hoursInTheEnd } = data.hours;

    const hoursDonePercentage =
        (hoursDone * MAX_PERCENTAGE) / projectEstimatedHours;
    const hoursInTheEndPercentage =
        (hoursInTheEnd * MAX_PERCENTAGE) / projectEstimatedHours;
    const hoursLeft = projectEstimatedHours - hoursInTheEnd;

    const totalPercentage = hoursDonePercentage + hoursInTheEndPercentage;
    const scaleFactor =
        totalPercentage > MAX_PERCENTAGE ? MAX_PERCENTAGE / totalPercentage : 1;

    const normalizedHoursDone = hoursDonePercentage * scaleFactor;
    const normalizedHoursInTheEnd = hoursInTheEndPercentage * scaleFactor;

    const isInAdvance = hoursInTheEnd > projectEstimatedHours;
    const hoursInProjectEndDate = isInAdvance
        ? projectEstimatedHours
        : hoursInTheEnd;
    const diffBetweenDoneAndEnd = Math.abs(
        normalizedHoursInTheEnd - normalizedHoursDone
    );
    const isOverlappingDoneAndEnd = diffBetweenDoneAndEnd <= OVERLAP_VALUE;
    const isLate = !isInAdvance;

    const hoursDoneOffset = isOverlappingDoneAndEnd ? 80 : undefined;
    const hoursInTheEndOffset = isOverlappingDoneAndEnd ? 180 : undefined;

    const hasMoreHoursThanEstimated =
        normalizedHoursInTheEnd <= normalizedHoursDone;

    const fixHour = (hour?: number) => hour?.toFixed(0);
    const formatDate = (date?: string) => {
        return dayjs(date).add(3, "hours").format("DD/MM/YYYY");
    };

    return (
        <s.Container>
            <s.TitleContainer>
                <s.Title>
                    Horas realizadas: <span>{fixHour(hoursDone)}h</span>
                </s.Title>
                <Tooltip
                    align="start"
                    positiony={5}
                    content={
                        <s.TooltipContainer>
                            Cálculos baseados na velocidade de horas realizadas
                            dos últimos 3 meses
                        </s.TooltipContainer>
                    }
                >
                    <s.InfoContainer>
                        <Icons.Info
                            width="18px"
                            height="18px"
                            color={theme.gray600}
                        />
                    </s.InfoContainer>
                </Tooltip>
            </s.TitleContainer>
            <StackedLineStyles.Container>
                <StackedLineStyles.Labels
                    hide={
                        !projectEstimatedHours ||
                        !(project.start_at && project.end_at)
                    }
                >
                    <StackedLineStyles.Label>
                        <p>{INITIAL_HOUR}h</p>
                        <p>{formatDate(project.start_at)}</p>
                    </StackedLineStyles.Label>
                    <StackedLineStyles.Label
                        left={normalizedHoursDone}
                        offset={hoursDoneOffset}
                    >
                        <p>{fixHour(hoursDone)}h</p>
                        <p>{formatDate()}</p>
                    </StackedLineStyles.Label>
                    <StackedLineStyles.Label
                        left={normalizedHoursInTheEnd}
                        offset={hoursInTheEndOffset}
                        hide={hasMoreHoursThanEstimated}
                    >
                        <p>{fixHour(hoursInProjectEndDate)}h</p>
                        <p>
                            {isLate
                                ? formatDate(project.end_at)
                                : dayjs(project.end_at)
                                      .subtract(
                                          Math.ceil(
                                              Math.abs(data.hours.monthsLate)
                                          ),
                                          "months"
                                      )
                                      .format("DD/MM/YYYY")}
                        </p>
                    </StackedLineStyles.Label>
                    <StackedLineStyles.Label
                        left={MAX_PERCENTAGE}
                        hide={hoursDone >= projectEstimatedHours}
                    >
                        <p>
                            {(isLate
                                ? projectEstimatedHours
                                : data.hours.hoursInTheEnd
                            ).toFixed(2)}
                            h
                        </p>
                        <p>
                            {isLate
                                ? dayjs(project.end_at)
                                      .add(
                                          Math.abs(data.hours.monthsLate),
                                          "months"
                                      )
                                      .format("DD/MM/YYYY")
                                : formatDate(project.end_at)}
                        </p>
                    </StackedLineStyles.Label>
                </StackedLineStyles.Labels>
                <StackedLineStyles.LineContainer>
                    <StackedLineStyles.RealizedHours
                        percentage={normalizedHoursDone}
                    />
                    <StackedLineStyles.EndProjection
                        percentage={normalizedHoursInTheEnd}
                    />
                    <StackedLineStyles.DelayProjection
                        percentage={hoursLeft > 0 ? 100 : 0}
                    />
                    <StackedLineStyles.ExceededTime
                        percentage={MAX_PERCENTAGE}
                    />
                </StackedLineStyles.LineContainer>
                <StackedLineStyles.LegendContainer>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.purple300}
                        />
                        Horas realizadas
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.gray200}
                        />
                        Projeção de horas no dia do término do projeto
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.gray300}
                        />
                        Tempo excedente
                    </StackedLineStyles.Legend>
                    <StackedLineStyles.Legend>
                        <StackedLineStyles.LegendIndicator
                            color={theme.redError}
                        />
                        Projeção de atraso
                    </StackedLineStyles.Legend>
                </StackedLineStyles.LegendContainer>
            </StackedLineStyles.Container>
        </s.Container>
    );
};
