import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-paper-clip"}
    >
        <title>{title}</title>
        <path
            d="M12.172 5.00016L5.58602 11.5862C5.395 11.7707 5.24264 11.9913 5.13782 12.2354C5.033 12.4794 4.97783 12.7418 4.97552 13.0074C4.97321 13.2729 5.02381 13.5363 5.12438 13.7821C5.22494 14.0279 5.37344 14.2512 5.56123 14.439C5.74902 14.6267 5.97232 14.7752 6.21811 14.8758C6.4639 14.9764 6.72726 15.027 6.99282 15.0247C7.25838 15.0224 7.52082 14.9672 7.76483 14.8624C8.00884 14.7575 8.22953 14.6052 8.41402 14.4142L14.828 7.82816C15.5567 7.07376 15.9598 6.06335 15.9507 5.01456C15.9416 3.96577 15.5209 2.96252 14.7793 2.22089C14.0377 1.47926 13.0344 1.05858 11.9856 1.04947C10.9368 1.04035 9.92643 1.44353 9.17202 2.17216L2.75702 8.75716C1.63171 9.88248 0.999512 11.4087 0.999512 13.0002C0.999512 14.5916 1.63171 16.1179 2.75702 17.2432C3.88233 18.3685 5.40859 19.0007 7.00002 19.0007C8.59145 19.0007 10.1177 18.3685 11.243 17.2432L17.5 11.0002"
            stroke={color || theme.gray600}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
