import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 343px;

    padding: 16px;
`;

export const Title = styled.h1`
    display: flex;
    align-items: center;
    gap: 8px;

    color: ${({ theme }) => theme.redError};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    span {
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
    }
`;
