import { Dispatch, SetStateAction } from "react";

import { Icons, theme } from "../../../../assets";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-help";

type TModalHelp = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalHelp = ({ setOpen }: TModalHelp) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <div id="header">
                    <p>Ajuda</p>
                    <s.CloseButton onClick={() => setOpen(false)}>
                        <Icons.XSolid color={theme.gray800} />
                    </s.CloseButton>
                </div>
                <div id="body">
                    <p>Para obter ajuda entre em contato pelo e-mail:</p>
                    <a href="mailto:suporte@paperon.app">suporte@paperon.app</a>
                </div>
                <div id="footer">
                    <p>Consulte: </p>
                    <a
                        href="https://prod-paperon.s3.eu-central-1.amazonaws.com/TERMOS_DE_USO_-_PAPER_ON_15_05_2023.pdf"
                        target="_blank"
                        rel="noopener noreferer"
                        id="termos"
                    >
                        Termos de uso
                    </a>
                    <a
                        href="https://prod-paperon.s3.eu-central-1.amazonaws.com/POLITICA_DE_PRIVACIDADE_-_PAPERON_15_05_2023.pdf"
                        target="_blank"
                        rel="noopener noreferer"
                    >
                        Políticas de Privacidade
                    </a>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalHelp;
