import { yupResolver } from "@hookform/resolvers/yup";
import valid from "card-validator";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import {
    BlockTooltip,
    Breadcrumb,
    CustomInput,
    Input,
    SelectInput,
} from "../../../../components";
import ModalPlanCards from "../../../../components/molecules/modals/modal-plan-cards/modal-plan-cards";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TOption } from "../../../../models";
import { subscriptions } from "../../../../services";
import { normalizeString } from "../../../../utils";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import { PayMethod } from "../../components/pay-method";
import * as s from "./styled-plan-subscription";
import validation from "./validation";

export const PlanSubscription = () => {
    const { state: planInformation } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();

    const { user, refreshUser } = useUser();

    const [cardNumberVerify, setCardNumberVerify] = useState<string>("");
    const [installment, setInstallment] = useState<TOption | undefined>(
        undefined
    );
    const [installmentsOptions, setInstallmentsOptions] = useState<
        TOption[] | undefined
    >();

    const [isOpenModalPlanCards, setIsOpenModalPlanCards] =
        useState<boolean>(false);
    const [isErrorDivOpen, setIsErrorDivOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setError,
        clearErrors,
    } = useForm({
        defaultValues: {
            cardNumber: "",
            cardCVV: "",
            cardExpirationMonth: "",
            cardExpirationYear: "",
            cardName: "",
            numberOfInstallments: "",
            contractorName: "",
        },
        resolver: yupResolver(validation),
    });

    const createSubscription = useFetch({
        fn: subscriptions.CreateSubscription,
        start: false,
    });

    const changePlan = useFetch({
        fn: subscriptions.changePlan,
        start: false,
    });

    const onSubmit = handleSubmit((data) => {
        Mixpanel.track("Concluiu a compra do plano");
        const cardName = normalizeString(data.cardName);
        const contractorName = normalizeString(data.contractorName);
        const cardNumberWithoutSpace = data.cardNumber.replace(/\s/g, "");
        if (planInformation.changingPlan) {
            changePlan.onRefresh({
                contractorName,
                installments: parseInt(installment?.value ?? "1", 10),
                organizationId: user?.Organization?.id_e,
                plan: planInformation.planType,
                planType: planInformation.paymentType,
                card: {
                    cvc: data.cardCVV,
                    exp_month: data.cardExpirationMonth,
                    exp_year: data.cardExpirationYear,
                    holder_name: cardName,
                    number: cardNumberWithoutSpace,
                },
            });
            setIsLoading(true);
            return;
        }
        if (!installment) return;

        createSubscription.onRefresh({
            cardCVV: data.cardCVV,
            cardExpirationMonth: data.cardExpirationMonth,
            cardExpirationYear: data.cardExpirationYear,
            cardName,
            cardNumber: cardNumberWithoutSpace,
            contractorName,
            numberOfInstallments: installment?.value,
            organizationId: user?.Organization?.id_e,
            plan: planInformation.planType,
            planType: planInformation.paymentType,
        });
        setIsLoading(true);
    });

    useEffect(() => {
        if (createSubscription.response) {
            sessionStorage.setItem("planChoice", planInformation.planType);
            window.location.href = "/cronometro";
            Mixpanel.track("Sucesso na compra do plano");
        }

        if (createSubscription.error) {
            Mixpanel.track("Erro na compra do plano", {
                erro: createSubscription.error,
            });
            setIsLoading(false);
            setIsErrorDivOpen(true);
        }

        refreshUser();
    }, [createSubscription.response, createSubscription.error]);

    useEffect(() => {
        const { card } = valid.number(cardNumberVerify);
        const cardType = card?.type;

        if (!cardType) {
            setInstallment(undefined);
            setInstallmentsOptions(undefined);
            return;
        }

        switch (planInformation.planType) {
            case "Lite":
                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "visa"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 326,70",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 326,70",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 163,35",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 108,90",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 326,70",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 326,70",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 163,35",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 108,90",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "elo"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 326,70",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 326,70",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 163,35",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 108,90",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 326,70",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 326,70",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 163,35",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 108,90",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 326,70",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 326,70",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 163,35",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 108,90",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "visa"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 29,70" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 29,70" },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 29,70" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 29,70" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "elo"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 29,70" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 29,70" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 29,70" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 29,70" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 29,70" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 29,70" },
                    ]);
                }
                break;
            case "Starter":
                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "visa"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 979,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 979,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 489,50",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 326,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 979,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 979,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 489,50",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 326,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "elo"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 979,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 979,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 489,50",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 326,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 979,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 979,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 489,50",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 326,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 979,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 979,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 489,50",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 326,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "visa"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 89,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 89,00" },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 89,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 89,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "elo"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 89,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 89,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 89,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 89,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 89,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 89,00" },
                    ]);
                }
                break;
            case "Standard":
                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "visa"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 1738,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 1738,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 869,00",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 579,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 1738,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 1738,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 869,00",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 579,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "elo"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 1738,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 1738,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 869,00",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 579,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 1738,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 1738,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 869,00",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 579,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "yearly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({
                        value: "1",
                        label: "1x de R$ 1738,00",
                    });
                    setInstallmentsOptions([
                        {
                            value: "1",
                            label: "1x de R$ 1738,00",
                        },
                        {
                            value: "2",
                            label: "2x de R$ 869,00",
                        },
                        {
                            value: "3",
                            label: "3x de R$ 579,33",
                        },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "visa"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 158,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 158,00" },
                    ]);
                }

                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "mastercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 158,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 158,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "elo"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 158,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 158,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "hipercard"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 158,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 158,00" },
                    ]);
                }
                if (
                    planInformation.paymentType === "monthly" &&
                    cardType === "american-express"
                ) {
                    setInstallment({ value: "1", label: "1x de R$ 158,00" });
                    setInstallmentsOptions([
                        { value: "1", label: "1x de R$ 158,00" },
                    ]);
                }
                break;
            default:
                break;
        }
    }, [
        cardNumberVerify,
        planInformation.paymentType,
        planInformation.planType,
    ]);

    useEffect(() => {
        if (changePlan.response) {
            toast.success("Plano alterado com sucesso!");
            navigate("/gerenciar-assinatura", {
                state: {
                    plan: planInformation.planType,
                },
            });
        }
    }, [changePlan.response]);

    useEffect(() => {
        if (changePlan.error) {
            setIsLoading(false);
            setIsErrorDivOpen(true);
        }
    }, [changePlan.error]);

    return (
        <>
            {isOpenModalPlanCards && (
                <ModalPlanCards
                    setOpen={setIsOpenModalPlanCards}
                    numberOfUsers={planInformation.numberOfUsers}
                    isOnEditMode={true}
                    changingPlan={planInformation.changingPlan}
                    currentPlan={planInformation.currentPlan}
                />
            )}
            {isLoading && (
                <Icons.LoadingSpinCircle width="80px" height="80px" />
            )}
            {planInformation.changingPlan && (
                <s.BreadcrumbContainer>
                    <Breadcrumb
                        location={location}
                        defaultRoute="/gerenciar-assinatura"
                    />
                </s.BreadcrumbContainer>
            )}
            <s.Container changingPlan={planInformation.changingPlan}>
                <div id="content">
                    {isErrorDivOpen && (
                        <div id="errors">
                            <Icons.IconAlertCircle width="24px" height="24px" />
                            <div>
                                <h1>Erro na transação</h1>
                                <span>
                                    Algo deu errado com o pagamento, por favor,
                                    verifique o status do seu cartão
                                </span>
                            </div>
                        </div>
                    )}
                    <div id="forms">
                        <div id="pay-method">
                            <header>
                                <h1>Forma de pagamento</h1>
                                <p>
                                    Para iniciar sua assinatura do Plano{" "}
                                    {planInformation.planType
                                        ? planInformation.planType
                                        : ""}
                                    , escolha a forma de pagamento e insira
                                    corretamente seus dados.
                                </p>
                            </header>
                            <section>
                                <div id="credit-cards">
                                    <Icons.VisaCard />
                                    <Icons.MasterCard />
                                    <Icons.EloCard />
                                    <Icons.HiperCard />
                                    <Icons.AmexCard />
                                </div>
                                <form>
                                    <div id="card-number">
                                        <div>Número do cartão</div>
                                        <CustomInput
                                            {...register("cardNumber")}
                                            placeholder="Número do cartão"
                                            error={errors?.cardNumber?.message}
                                            mask=""
                                            inputType="cardNumber"
                                            maxLength={19}
                                            onChange={(e) => {
                                                const cardNumber = valid.number(
                                                    e.target.value
                                                );
                                                setCardNumberVerify(
                                                    e.target.value
                                                );
                                                if (
                                                    e.target.value.length === 17
                                                ) {
                                                    if (!cardNumber.isValid) {
                                                        setError("cardNumber", {
                                                            message:
                                                                "Número do cartão inválido",
                                                        });
                                                    } else {
                                                        clearErrors(
                                                            "cardNumber"
                                                        );
                                                    }
                                                }
                                                if (
                                                    e.target.value.length ===
                                                        18 ||
                                                    e.target.value.length === 19
                                                ) {
                                                    if (!cardNumber.isValid) {
                                                        setError("cardNumber", {
                                                            message:
                                                                "Número do cartão inválido",
                                                        });
                                                    } else {
                                                        clearErrors(
                                                            "cardNumber"
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id="card-information">
                                        <div>
                                            <div>Mês de expiração</div>
                                            <Input
                                                {...register(
                                                    "cardExpirationMonth"
                                                )}
                                                placeholder="00"
                                                error={
                                                    errors?.cardExpirationMonth
                                                        ?.message
                                                }
                                                mask="99"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 2
                                                    ) {
                                                        const cardExpirationMonth =
                                                            valid.expirationMonth(
                                                                e.target.value
                                                            );
                                                        if (
                                                            !cardExpirationMonth.isValid
                                                        ) {
                                                            setError(
                                                                "cardExpirationMonth",
                                                                {
                                                                    message:
                                                                        "Mês de expiração inválido",
                                                                }
                                                            );
                                                        } else {
                                                            clearErrors(
                                                                "cardExpirationMonth"
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div>Ano de expiração</div>
                                            <Input
                                                {...register(
                                                    "cardExpirationYear"
                                                )}
                                                placeholder="00"
                                                error={
                                                    errors?.cardExpirationYear
                                                        ?.message
                                                }
                                                mask="99"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 2
                                                    ) {
                                                        const cardExpirationYear =
                                                            valid.expirationYear(
                                                                e.target.value
                                                            );
                                                        if (
                                                            !cardExpirationYear.isValid
                                                        ) {
                                                            setError(
                                                                "cardExpirationYear",
                                                                {
                                                                    message:
                                                                        "Ano de expiração inválido",
                                                                }
                                                            );
                                                        } else {
                                                            clearErrors(
                                                                "cardExpirationYear"
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div>Código de segurança</div>
                                            <Input
                                                {...register("cardCVV")}
                                                placeholder="CVV"
                                                error={errors?.cardCVV?.message}
                                                mask="9999"
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value
                                                            .length === 3
                                                    ) {
                                                        const cardCVV =
                                                            valid.cvv(
                                                                e.target.value
                                                            );
                                                        if (!cardCVV.isValid) {
                                                            setError(
                                                                "cardCVV",
                                                                {
                                                                    message:
                                                                        "Código de segurança inválido",
                                                                }
                                                            );
                                                        } else {
                                                            clearErrors(
                                                                "cardCVV"
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div id="card-name">
                                        <div>Nome impresso no cartão</div>
                                        <Input
                                            {...register("cardName")}
                                            placeholder="Nome impresso no cartão"
                                            error={errors?.cardName?.message}
                                            mask=""
                                            onChange={(e) => {
                                                if (
                                                    e.target.value.length === 1
                                                ) {
                                                    const cardName =
                                                        valid.cardholderName(
                                                            e.target.value
                                                        );
                                                    if (!cardName.isValid) {
                                                        setError("cardName", {
                                                            message:
                                                                "Nome impresso no cartão inválido",
                                                        });
                                                    } else {
                                                        clearErrors("cardName");
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div id="installments">
                                        {planInformation.paymentType ===
                                            "yearly" && (
                                            <>
                                                <div>Número de parcelas</div>
                                                <SelectInput
                                                    {...register(
                                                        "numberOfInstallments"
                                                    )}
                                                    placeholder="Número de parcelas"
                                                    error={
                                                        errors
                                                            ?.numberOfInstallments
                                                            ?.message
                                                    }
                                                    icon={<Icons.Chevron />}
                                                    options={
                                                        installmentsOptions
                                                    }
                                                    value={installment}
                                                    onChange={(
                                                        value: TOption
                                                    ) => {
                                                        setInstallment(value);
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div id="contractor-name">
                                        <h1>Dados de cobrança</h1>
                                        <section>
                                            <span>Nome do contratante</span>
                                            <BlockTooltip
                                                offset={12}
                                                align="start"
                                                side="bottom"
                                                title=""
                                                trigger={
                                                    <Icons.Info
                                                        width="16px"
                                                        height="16px"
                                                        color={theme.gray600}
                                                    />
                                                }
                                            >
                                                <s.ContractorNameToolTip>
                                                    O nome do contratante pode
                                                    ser diferente do nome do
                                                    portador do cartão de
                                                    crédito. Por exemplo, se a
                                                    compra for feita em nome de
                                                    uma empresa ou instituição.
                                                </s.ContractorNameToolTip>
                                            </BlockTooltip>
                                        </section>
                                        <Input
                                            {...register("contractorName")}
                                            placeholder="Nome do contratante (pessoa física ou pessoal jurídica)"
                                            error={
                                                errors?.contractorName?.message
                                            }
                                            mask=""
                                            className="contractor-name-input"
                                        />
                                    </div>
                                </form>
                            </section>
                        </div>
                        <PayMethod
                            plan={planInformation.planType}
                            paymentType={planInformation.paymentType}
                            className="plan"
                            onSubmit={onSubmit}
                            isOpenModalPlan={setIsOpenModalPlanCards}
                            isFinishButtonEnabled={isValid}
                        />
                    </div>
                </div>
                <div id="talk-with-us">
                    <p>Dúvidas sobre o plano?</p>
                    <button
                        onClick={() => {
                            window.location.href =
                                "mailto: suporte@paperon.app";
                        }}
                    >
                        FALE CONOSCO
                    </button>
                </div>
            </s.Container>
        </>
    );
};
