import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFetch } from "../../../../hooks";
import { timeReports } from "../../../../services";
import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-review";

type TModalReview = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    period?: string;
    totalTime?: string;
    collaborator?: string;
    id?: string;
};

const ModalReview = ({
    setOpen,
    period,
    totalTime,
    collaborator,
    id,
}: TModalReview) => {
    const navigate = useNavigate();

    const reviewReport = useFetch({
        fn: timeReports.patchReviewReport,
        start: false,
        params: { reportId: id },
        errorMessage: "Erro ao abrir o registro!",
    });

    const handleReview = () => {
        reviewReport.onRefresh({ reportId: id });
    };

    useEffect(() => {
        if (reviewReport.response) {
            navigate("/relatorio/gerenciar/revisar");
        }
    }, [reviewReport.response]);

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <h1>Validar revisão</h1>
                <div>
                    <h2>Profissional:</h2>
                    <p>{collaborator}</p>
                </div>
                <div>
                    <h2>Período:</h2>

                    <p>{period}</p>
                </div>
                <div>
                    <h2>Total de horas:</h2>
                    <p>{totalTime}</p>
                </div>

                <div id="buttons">
                    <SecondaryButton
                        style={{ width: 95 }}
                        onClick={() => setOpen(false)}
                        disabled={reviewReport.loading}
                    >
                        CANCELAR
                    </SecondaryButton>

                    <PrimaryButton
                        onClick={() => handleReview()}
                        style={{
                            width: 130,
                        }}
                        disabled={reviewReport.loading}
                    >
                        FAZER Review
                    </PrimaryButton>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalReview;
