import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { useUser } from "../../../../context";
import { useUpdateUserConfigs } from "../../../../hooks/react-query/use-update-user-configs";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormData = {
    goal: number;
};

const schema = yup.object({
    goal: yup
        .number()
        .min(0, "Não pode ser menor que zero.")
        .max(23, "Não pode ser maior que 23h.")
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value)),
});

export function ModalDefineDailyGoal({ setOpen }: Props) {
    const { user, refreshUser } = useUser();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            goal: user.UsersConfigs?.dailyGoal,
        },
    });

    const updateUserConfigs = useUpdateUserConfigs({
        onSuccess() {
            refreshUser();
            toast.success("Meta diária definida com sucesso!");
            setOpen(false);
        },
    });

    const close = () => setOpen(false);

    const save = (formData: FormData) => {
        updateUserConfigs.mutate({
            userId: user.id_e,
            userConfigs: {
                ...user.UsersConfigs,
                dailyGoal: formData.goal,
            },
        });
    };

    return (
        <ContainerModal>
            <s.Container onSubmit={handleSubmit(save)}>
                <s.Title>Definir meta diária de horas</s.Title>
                <s.Description>
                    Você pode definir uma meta diária de horas para uma melhor
                    organização pessoal.
                    <br />
                    Ninguém além de você terá informações da sua meta diária, é
                    somente para seu controle pessoal.
                </s.Description>
                <Controller
                    name="goal"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            onChange={onChange}
                            value={value}
                            type="number"
                            label="Meta de horas"
                            placeholder="Ex. 8h"
                            error={errors.goal?.message}
                        />
                    )}
                />
                <s.Buttons>
                    <SecondaryButton
                        variation="small"
                        onClick={close}
                        type="button"
                    >
                        Fechar
                    </SecondaryButton>
                    <PrimaryButton variation="small">Salvar</PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
}
