import { TBackOfficeData } from "../../../types";
import { Billing } from "../components/billing/billing";
import { LastWeekNewUsersOrigins } from "../components/last-week-new-users-origins/last-week-new-users-origins";
import { LastWeekNewUsers } from "../components/last-week-new-users/last-week-new-users";
import { LastWeekOwners } from "../components/last-week-owners/last-week-owners";
import { NewUsersOrigins } from "../components/new-users-origins/new-users-origins";
import { OrganizationsWithoutRegistry } from "../components/organizations-without-registry/organizations-without-registry";
import { Organizations } from "../components/organizations/organizations";
import { Plans } from "../components/plans/plans";
import { PresentUsers } from "../components/present-users/present-users";
import { Users } from "../components/users/users";

type GeneralTabProps = {
    data: TBackOfficeData;
};

export const GeneralTab = ({ data }: GeneralTabProps) => {
    return (
        <>
            <Billing data={data} />
            <Users data={data} />
            <PresentUsers data={data} />
            <Organizations data={data} />
            <Plans data={data} />
            <LastWeekNewUsersOrigins data={data} />
            <NewUsersOrigins data={data} />
            <LastWeekOwners data={data} />
            <LastWeekNewUsers data={data} />
            <OrganizationsWithoutRegistry data={data} />
        </>
    );
};
