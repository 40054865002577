import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Paper, PopoverRadix, PopoverNotification, ModalLogout } from "../..";
import { FotoUser, Icons, theme } from "../../../assets";
import { useUser } from "../../../context";
import { useDailyMission, useFetch } from "../../../hooks";
import { TNotification } from "../../../models";
import { notification, logout } from "../../../services";
import { Auth } from "../../../services/auth/Authentication";
import { redirectDefaultRoute } from "../../../utils";
import { LevelCircle } from "../../atoms/level-circle/level-circle";
import ModalHelp from "../modals/modal-help/modal-help";
import { ModalReportBug } from "../modals/modal-report-bug/modal-report-bug";
import * as s from "./styled-sidebar";

type Option = {
    id: string;
    label: string;
    icon: JSX.Element;
    to: string;
    hide?: boolean;
};

type TLabelButton = {
    id: string;
    label: string;
    icon: JSX.Element;
    to: string;
    multi?: boolean;
    options?: Option[];
};

type TSidebar = {
    myTheme: string | null;
    setMyTheme: React.Dispatch<SetStateAction<string | null>>;
    id?: string;
};

export default ({ myTheme, setMyTheme }: TSidebar) => {
    const { daily } = useDailyMission();
    const {
        user,
        permissions,
        haveOrganization,
        organization,
        verifyPermission,
    } = useUser();

    const [isSelected, setIsSelected] = useState({
        cronometro: false,
        relatorio: false,
        profissionais: false,
        projetos: false,
        organizacao: false,
        configuracoes: false,
        notificacoes: false,
        profile: false,
        ajuda: false,
    });
    const keepOpen = localStorage.getItem("keepOpen") === "true";
    const [isOpen, setIsOpen] = useState(keepOpen);
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenModalHelp, setIsOpenModalHelp] = useState(false);
    const [notifications, setNotifications] = useState<TNotification[]>([]);
    const [subMenuOpenId, setSubmenuOpenId] = useState<string>();
    const [isModalReportBugOpen, setIsModalReportBugOpen] = useState(false);

    const haveAllPermission =
        haveOrganization &&
        verifyPermission({
            id: "all_permissions",
            rule: "enabled",
        });
    const organizationPermission = haveOrganization
        ? verifyPermission({
              id: "manage_teams_projects",
              rule: "enabled",
          })
        : "*";
    const viewUsers = verifyPermission({
        id: "professionals",
        rule: "view",
    });

    const billingPermission = verifyPermission({
        id: "bill_reports",
        rule: "enabled",
    });

    const reviewPermission = verifyPermission({
        id: "review_reports",
        rule: "enabled",
    });

    const isAdmin = user.Role.name === "Administrador";
    const subscriptionExists = organization?.Subscription;
    const canSeeManageSubscription =
        organization?.Subscription?.plan !== "trial";

    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.pathname.split("/")[1];
    const fullPathName = location.pathname;
    const isBackOfficeAccount = user.email === "backoffice@paperon.app";

    const labelButtons = [
        {
            id: "cronometro",
            label: "Cronômetro",
            icon: <Icons.ClockOutline color={theme.gray600} />,
            to: "/cronometro",
        },
        {
            id: "relatorio",
            label: "Relatórios",
            icon: <Icons.Document color={theme.gray600} />,
            multi: true,
            options: [
                {
                    id: "meus-relatorios",
                    label: "Meus relatórios",
                    to: "/meus-relatorios/resumo",
                },
                {
                    id: "relatorio",
                    label: "Relatórios do time",
                    to: "/relatorio/gerenciar/revisar",
                    hide: !billingPermission && !reviewPermission,
                },
            ],
        },
        {
            id: "profissionais",
            label: "Profissionais",
            icon: <Icons.UserGroup color={theme.gray600} />,
            to: "/profissionais",
        },
        {
            id: "organizacao",
            label: "Organização",
            icon: <Icons.Briefcase color={theme.gray600} />,
            to: `/organizacao${redirectDefaultRoute(permissions || [])}`,
        },
        {
            id: "projetos",
            label: "Projetos",
            icon: <Icons.Tag />,
            to: "/projetos",
        },
        {
            id: "pontuacao",
            label: "Pontuação",
            icon: <Icons.MilitaryMedal color={theme.gray600} />,
            to: "/pontuacao",
        },
    ] as TLabelButton[];

    const profileItems = [
        {
            id: "ajuda",
            label: "Ajuda",
            icon: <Icons.QuestionMarkOutline color={theme.gray600} />,
            to: "/help",
        },
    ] as const;

    const reqLogout = useFetch({
        fn: logout,
        successMessage: "Logout efetuado com sucesso!",
        start: false,
    });

    const userUrl = user.name
        ?.replaceAll(" ", "-")
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");

    const getNotification = useFetch({
        fn: notification.getNotification,
        params: {
            userId: user.id_e,
        },
    });

    const logoutUser = () => {
        reqLogout.onRefresh();
    };

    const manageSelected = (propToTrue: string) => {
        return Object.keys(isSelected).reduce(
            (acc, key) => ({ ...acc, [key]: false, [propToTrue]: true }),
            isSelected
        );
    };

    const renderBellQuantity = () => {
        if (notifications?.length > 0) {
            const sum = notifications?.filter((item) => !item.wasRead).length;
            if (sum) return <span id="bell-dot">{sum > 9 ? `9+` : sum}</span>;
        }
        return "";
    };

    const renderProfile = () => (
        <s.Button
            isSelected={isSelected.profile}
            onClick={() => setIsSelected(manageSelected("profile"))}
            height="70px"
            className="button-profile"
        >
            <s.ContainerProfile className="container-profile">
                <div className="container-image">
                    <img src={user.avatar || FotoUser} />
                    <LevelCircle level={daily?.level?.level_number || 1} />
                </div>

                <div className="container-infos">
                    <span id="user-name">{user.name}</span>

                    {user.Role.name && (
                        <span id="user-team">{user.Role.name}</span>
                    )}
                </div>

                <Icons.Chevron color={theme.gray600} className="chevron" />
            </s.ContainerProfile>
        </s.Button>
    );

    const renderAlerts = () => (
        <s.Button
            onClick={() => {
                setIsSelected(manageSelected("notificacoes"));
            }}
            isSelected={isSelected.notificacoes}
        >
            <div className="hollow-bar" />

            <s.ContainerIcons isSelected={isSelected.notificacoes}>
                <div id="bell-container">
                    <Icons.BellOn color={theme.gray600} />
                    {renderBellQuantity()}
                </div>
            </s.ContainerIcons>
            {isOpen && "Notificações"}
        </s.Button>
    );

    const checkButtonSelected = useCallback(
        (buttonId: string) => {
            const keys = Object.keys(isSelected);
            const values = Object.values(isSelected);
            const buttonIdPosition = keys.indexOf(buttonId);

            return values[buttonIdPosition];
        },
        [isSelected]
    );

    const checkSubmenuButtonActive = (submenuOption: Option): boolean => {
        if (pathName === submenuOption.id) {
            return true;
        }
        return fullPathName === submenuOption.to;
    };

    useEffect(() => {
        setIsSelected(manageSelected(pathName));
    }, [location]);

    useEffect(() => {
        if (reqLogout.response === "") {
            Auth.getInstance().clearLocalStorage();
        }
    }, [reqLogout.response]);

    useEffect(() => {
        if (getNotification.response) {
            setNotifications(getNotification.response);
        }
    }, [getNotification.response]);

    useEffect(() => {
        localStorage.setItem("keepOpen", String(isOpen));
    }, [isOpen]);

    return (
        <s.Container isOpen={isOpen}>
            {isOpenModal && (
                <ModalLogout
                    setOpen={setIsOpenModal}
                    logoutUser={logoutUser}
                    loading={reqLogout.loading}
                />
            )}
            {isOpenModalHelp && <ModalHelp setOpen={setIsOpenModalHelp} />}
            {isModalReportBugOpen && (
                <ModalReportBug onOpenChange={setIsModalReportBugOpen} />
            )}

            <s.ContainerBar id="side-bar">
                <s.ContainerPaper>
                    <div id="logo">
                        <Paper
                            onlyToggl={!isOpen}
                            title="Paper On"
                            myTheme={myTheme}
                            setMyTheme={setMyTheme}
                        />
                    </div>
                    <div id="icon-wrapper">
                        <Icons.Chevron
                            onClick={() => setIsOpen(!isOpen)}
                            color={theme.purple200}
                        />
                    </div>
                </s.ContainerPaper>

                <PopoverRadix
                    modal
                    closeIcon={<Icons.XSolid />}
                    trigger={renderProfile()}
                    side="right"
                    sideOffset={16}
                    align="start"
                    open={isOpenPopover}
                    onOpenChange={() => setIsOpenPopover(!isOpenPopover)}
                >
                    <s.ContainerModal>
                        <div id="top-container">
                            <div id="header">
                                <s.BigP>Meu Perfil</s.BigP>
                            </div>
                        </div>
                        <s.ButtonModal
                            onClick={() => {
                                navigate(`/profissional/${userUrl}`, {
                                    state: {
                                        id: user.id_e,
                                        name: user.name,
                                        isMyProfile: true,
                                    },
                                });
                                setIsOpenPopover(false);
                            }}
                            isSelected={false}
                            style={{ height: "70px" }}
                        >
                            <s.ContainerProfile className="container-profile">
                                <div className="container-image">
                                    <img src={user.avatar || FotoUser} />
                                    <LevelCircle
                                        level={daily?.level?.level_number || 1}
                                    />
                                </div>

                                <div className="container-infos">
                                    <span id="user-name">{user.name}</span>

                                    {user.Role.name && (
                                        <span id="user-team">
                                            {user.Role.name}
                                        </span>
                                    )}
                                </div>
                            </s.ContainerProfile>
                        </s.ButtonModal>

                        {haveAllPermission && (
                            <s.ButtonModal
                                onClick={() => {
                                    navigate("/organizacao/perfil");
                                }}
                                isSelected={
                                    location.pathname === "/organizacao/perfil"
                                }
                            >
                                <s.ContainerIcons isSelected={false}>
                                    <Icons.Briefcase color={theme.gray600} />
                                </s.ContainerIcons>
                                Gerenciar organização
                            </s.ButtonModal>
                        )}

                        <s.Separator />

                        <s.ButtonModal
                            onClick={() => {
                                navigate(`/perfil/configuracoes/`, {
                                    state: {
                                        id: user.id_e,
                                    },
                                });
                                setIsOpenPopover(false);
                            }}
                            isSelected={
                                location.pathname === "/perfil/configuracoes/"
                            }
                        >
                            <s.ContainerIcons
                                isSelected={isSelected.configuracoes}
                            >
                                <Icons.CogOutline color={theme.gray600} />
                            </s.ContainerIcons>
                            Configurações
                        </s.ButtonModal>

                        {profileItems.map((button) => (
                            <s.ButtonModal
                                key={button.id}
                                onClick={() => {
                                    if (button.id === "ajuda")
                                        setIsOpenModalHelp(true);
                                    else navigate(button.to);
                                }}
                                isSelected={isSelected[button.id]}
                            >
                                <s.ContainerIcons
                                    isSelected={isSelected[button.id]}
                                >
                                    {button.icon}
                                </s.ContainerIcons>
                                {button.label}
                            </s.ButtonModal>
                        ))}
                    </s.ContainerModal>
                </PopoverRadix>

                {/* <TodayGoal
                    percentage={
                        daily?.daily_mission_status?.percentage_done || 33
                    }
                    isOpen={isOpen}
                /> */}
                <s.Separator />

                <s.ReportBug onClick={() => setIsModalReportBugOpen(true)}>
                    <Icons.Bug />
                    {isOpen && "Reportar um problema"}
                </s.ReportBug>

                <s.Separator />

                <PopoverNotification
                    renderAlerts={renderAlerts()}
                    notifications={notifications}
                    setNotifications={setNotifications}
                />

                {isBackOfficeAccount && (
                    <s.ButtonModal
                        isSelected={location.pathname.includes("/backoffice")}
                        onClick={() => navigate("/backoffice")}
                    >
                        <s.ContainerIcons isSelected={false}>
                            <Icons.Document color={theme.gray600} />
                        </s.ContainerIcons>
                        {isOpen && "BackOffice"}
                    </s.ButtonModal>
                )}
                {labelButtons.map((button) => (
                    <React.Fragment key={button.id}>
                        {((button.id === "organizacao" &&
                            organizationPermission) ||
                            button.id !== "organizacao") &&
                            button.id !== "profissionais" &&
                            !button.multi && (
                                <s.Button
                                    key={button.id}
                                    onClick={() => {
                                        getNotification.onRefresh({
                                            userId: user.id_e,
                                        });
                                        navigate(button.to);
                                    }}
                                    isSelected={checkButtonSelected(button.id)}
                                    className={`button-${button.id}`}
                                >
                                    <div className="selected-bar" />

                                    <s.ContainerIcons
                                        isSelected={checkButtonSelected(
                                            button.id
                                        )}
                                    >
                                        {button.icon}
                                    </s.ContainerIcons>

                                    {isOpen && button.label}
                                </s.Button>
                            )}
                        {button.id === "profissionais" && viewUsers && (
                            <s.Button
                                onClick={() => navigate(button.to)}
                                isSelected={
                                    checkButtonSelected(button.id) ||
                                    location.pathname.includes("profissional")
                                }
                            >
                                <div className="selected-bar" />

                                <s.ContainerIcons
                                    isSelected={
                                        checkButtonSelected(button.id) ||
                                        location.pathname.includes(
                                            "profissional"
                                        )
                                    }
                                >
                                    {button.icon}
                                </s.ContainerIcons>

                                {isOpen && button.label}
                            </s.Button>
                        )}
                        {button.multi && (
                            <PopoverRadix
                                side="right"
                                align="start"
                                alignOffset={8}
                                onOpenChange={(open) => {
                                    if (!open) setSubmenuOpenId(undefined);
                                }}
                                trigger={
                                    <s.ButtonMulti
                                        key={button.id}
                                        onClick={() => {
                                            setSubmenuOpenId(button.id);
                                        }}
                                        isSelected={
                                            checkButtonSelected(button.id) ||
                                            subMenuOpenId === button.id
                                        }
                                    >
                                        <div className="selected-bar" />

                                        <s.ContainerIcons
                                            isSelected={checkButtonSelected(
                                                button.id
                                            )}
                                        >
                                            {button.icon}
                                        </s.ContainerIcons>

                                        {isOpen && button.label}

                                        {isOpen && (
                                            <Icons.Chevron
                                                className="chevron-button"
                                                color={theme.gray600}
                                            />
                                        )}
                                    </s.ButtonMulti>
                                }
                            >
                                <s.SubmenuContainer>
                                    {button.options?.map(
                                        (submenuOption) =>
                                            !submenuOption.hide && (
                                                <s.SubmenuOption
                                                    id={submenuOption.id}
                                                    onClick={() =>
                                                        navigate(
                                                            submenuOption.to
                                                        )
                                                    }
                                                    active={checkSubmenuButtonActive(
                                                        submenuOption
                                                    )}
                                                >
                                                    {submenuOption.label}
                                                </s.SubmenuOption>
                                            )
                                    )}
                                </s.SubmenuContainer>
                            </PopoverRadix>
                        )}
                    </React.Fragment>
                ))}

                <s.Separator end />

                {isAdmin && canSeeManageSubscription && subscriptionExists && (
                    <s.ButtonModal
                        isSelected={checkButtonSelected("gerenciar-assinatura")}
                        $end
                        onClick={() => {
                            navigate("/gerenciar-assinatura");
                        }}
                        className="subscription"
                    >
                        <s.ContainerIcons
                            isSelected={checkButtonSelected(
                                "gerenciar-assinatura"
                            )}
                        >
                            {(organization?.Subscription?.status === "failed" ||
                                organization?.Subscription?.status ===
                                    "canceled") && (
                                <span className="notification-dot"></span>
                            )}
                            <Icons.Membership color={theme.gray600} />
                        </s.ContainerIcons>
                        {isOpen && "Gerenciar assinatura"}
                    </s.ButtonModal>
                )}
                <s.ButtonModal
                    $end={!isAdmin || !canSeeManageSubscription}
                    isSelected={false}
                    onClick={() => {
                        setIsOpenPopover(false);
                        setIsOpenModal(true);
                    }}
                    style={{ marginBottom: "12px" }}
                >
                    <s.ContainerIcons isSelected={false}>
                        <Icons.Logout color={theme.gray600} />
                    </s.ContainerIcons>
                    {isOpen && "Sair"}
                </s.ButtonModal>
            </s.ContainerBar>
        </s.Container>
    );
};
