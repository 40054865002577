import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";

import { FotoUser, Icons, theme } from "../../../../../assets";
import { Tooltip, DesignedAlert } from "../../../../../components";
import {
    InputFoto,
    Input,
    SelectInput,
    LoadingGray,
} from "../../../../../components/atoms";
import { useUser } from "../../../../../context";
import { useFetch } from "../../../../../hooks";
import { TOption, TUser } from "../../../../../models";
import { locality, manageUser } from "../../../../../services";
import { TUpdateUser } from "../../../../../services/user/update-user";
import { onlyNumbers, telephoneMask } from "../../../../../utils";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-tab-contacts";
import schema from "./validation";

type TFields = {
    cep: string;
    celNumber: string;
    address: string;
    houseNumber: string;
    complement: string;
    city: string;
    state: string;
    socialName?: string;
    email: string;
    birthDate: Date | string | null | undefined;
};

type TData = {
    body: Record<string, string>;
};

type TTabContacts = {
    loading: boolean;
    userData: TUser;
    userId: string;
    getUserOnRefresh: (value: { userId: string } | undefined) => void;
    setSelectedTab: Dispatch<SetStateAction<string>>;
};

export const TabContacts = ({
    loading,
    userData,
    userId,
    getUserOnRefresh,
    setSelectedTab,
}: TTabContacts) => {
    const { user, setUser } = useUser();
    const { setAvatar } = manageUser;

    const defaultValues = {
        cep: userData?.cep,
        celNumber: userData?.celNumber,
        address: userData?.address,
        houseNumber: userData?.houseNumber,
        complement: userData?.complement,
        city: userData?.city,
        state: userData?.state,
        socialName: userData?.socialName,
        birthDate: userData?.birthDate,
    };

    const {
        handleSubmit,
        trigger,
        register,
        setError,
        setValue,
        formState: { errors },
        control,
        watch,
        reset,
    } = useForm<TFields & TData>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const [showEdit, setShowEdit] = useState(false);
    const [picture, setPicture] = useState<Blob | File | string | undefined>();

    const [loadingCEP, setLoadingCEP] = useState(true);
    const [allCities, setAllCities] = useState<TOption[]>();
    const [allUF, setAllUF] = useState<TOption[]>();
    const [slicedCity, setSlicedCity] = useState<TOption[]>();
    const [cityInputValue, setCityInputValue] = useState(userData.city || "");
    const [UFInputValue, setUFInputValue] = useState(userData.state || "");
    const [CEP, setCEP] = useState(userData.cep || "");
    const [CEPFields, setCEPFields] = useState(
        !!(userData.city && userData.state) || false
    );

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const updateUser = useMutation<unknown, { message: string }, TUpdateUser>({
        mutationKey: ["updateUser"],
        mutationFn: manageUser.updateUser,
        onSuccess: () => {
            toast.success("Perfil atualizado com sucesso!");
            Mixpanel.track("Perfil colaborador - Salvar Perfil / Contatos");
        },
        onError: (error) => {
            reset(defaultValues);
            toast.error(error.message);
        },
        onSettled: () => {
            setSelectedTab("contacts");
            setShowEdit(!showEdit);
            getUserOnRefresh({
                userId,
            });
        },
    });

    const sendUpdateAvatar = useFetch({
        fn: setAvatar,
        start: false,
    });

    const cepReq = useFetch({
        fn: locality.getCep,
        params: CEP,
        start: false,
    });

    const cityReq = useFetch({
        fn: locality.getCities,
    });

    const UFReq = useFetch({ fn: locality.getStates });

    const convertPicture = () => {
        if (picture) {
            const formDataImage = new FormData();
            formDataImage.append("avatar", picture);
            return formDataImage;
        }
        return undefined;
    };

    const filterCities = (value = "") => {
        const searchString = new RegExp(`^${value}`, "i");

        const filterCities = allCities?.filter((item: Record<string, string>) =>
            item.label?.match(searchString)
        );

        const fiftyCities = filterCities
            ?.sort((a: Record<string, string>, b: Record<string, string>) => {
                if (a?.label < b?.label) return -1;
                if (a?.label > b?.label) return 1;
                return 0;
            })
            ?.slice(0, 50);

        return fiftyCities;
    };

    const checkCep = () => {
        const { erro } = cepReq.response || { erro: null };
        if (erro)
            return setError("cep", {
                type: "value",
                message: "CEP não encontrado",
            });

        let adress = "";
        if (cepReq.response?.logradouro && cepReq.response?.logradouro !== "")
            adress += `${cepReq.response.logradouro}`;
        if (
            cepReq.response?.logradouro &&
            cepReq.response?.logradouro !== "" &&
            cepReq.response?.bairro &&
            cepReq.response?.bairro !== ""
        )
            adress += ", ";
        if (cepReq.response?.bairro && cepReq.response?.bairro !== "")
            adress += `${cepReq.response.bairro}`;

        setValue("address", adress);

        if (cepReq.response) {
            setValue("city", cepReq.response.localidade.substring(0, 20));
            setValue("state", cepReq.response.uf);
            setValue("houseNumber", "");
            setValue("complement", "");
            setCityInputValue(cepReq.response.localidade);
            setUFInputValue(cepReq.response.uf);
            setCEPFields(true);
        }

        trigger(["city", "state", "address", "houseNumber"]);
        return null;
    };

    useEffect(() => {
        if (!loadingCEP) {
            if (CEP !== "") cepReq.onRefresh(CEP);
            if (!CEP) {
                setValue("city", "");
                setValue("state", "");
                setValue("address", "");
                setValue("houseNumber", "");
                setValue("complement", "");
                setCityInputValue("");
                setUFInputValue("");
                setCEPFields(false);
            }
        }
        setLoadingCEP(false);
    }, [CEP]);

    useEffect(() => {
        if (cepReq.response) checkCep();
    }, [cepReq.response]);

    useEffect(() => {
        if (UFReq.response) {
            setAllUF(UFReq.response);
        }
    }, [UFReq.response]);

    useEffect(() => {
        if (cityReq.response) setAllCities(cityReq.response);
    }, [cityReq.response]);

    useEffect(() => {
        if (sendUpdateAvatar.response) {
            getUser.onRefresh();
        }
    }, [sendUpdateAvatar.response]);

    useEffect(() => {
        if (getUser.response) {
            setUser(getUser.response);
        }
    }, [getUser.response]);

    useEffect(() => {
        if (allCities) setSlicedCity(filterCities());
    }, [allCities]);

    useEffect(() => {
        if (cityInputValue) setSlicedCity(filterCities(cityInputValue));
    }, [cityInputValue]);

    useEffect(() => {
        if (picture) {
            getUser.onRefresh();
            sendUpdateAvatar.onRefresh(convertPicture());
            setTimeout(() => {
                setSelectedTab("contacts");
                getUserOnRefresh({ userId });
            }, 1000);
        }
    }, [picture]);

    const onSubmit = (data: TFields) => {
        updateUser.mutate({
            userData: {
                ...data,
                birthDate: data.birthDate,
            },
            userId,
        });
    };

    return (
        <s.Content>
            <div className="content-title">
                <p className="title-row">Dados pessoais</p>
                <p className="title-row">Endereço de correspondência</p>
            </div>

            <s.Contacts onSubmit={handleSubmit(onSubmit)} edit={showEdit}>
                {loading || updateUser.isLoading ? (
                    <LoadingGray />
                ) : (
                    <>
                        <div className="form-column">
                            <Tooltip
                                positionx={10}
                                side="right"
                                content={<s.P>Editar</s.P>}
                            >
                                {user.id_e === userId ? (
                                    <InputFoto
                                        avatar={userData?.avatar}
                                        setFoto={setPicture}
                                        onChange={(e: React.ChangeEvent) => {
                                            if (e) {
                                                const target =
                                                    e.target as HTMLInputElement;
                                                const file = (
                                                    target?.files as FileList
                                                )[0];
                                                setPicture(file);
                                            }
                                        }}
                                        color={theme.gray500}
                                    />
                                ) : (
                                    <img
                                        src={userData?.avatar || FotoUser}
                                        alt="Foto do usuário"
                                    />
                                )}
                            </Tooltip>

                            <span id="contact-name">
                                {userData?.name || "-"}
                            </span>

                            <div className="contact-field">
                                <span className="field-title">
                                    Nome Social:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("socialName", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("socialName");
                                            },
                                        })}
                                        type="text"
                                        defaultValue={
                                            userData?.socialName || ""
                                        }
                                        mask=""
                                        className="input-contact-data"
                                    />
                                ) : (
                                    <span>{userData?.socialName || "-"}</span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">E-mail:</span>
                                {showEdit ? (
                                    <Input
                                        {...register("email", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("email");
                                            },
                                        })}
                                        type="text"
                                        defaultValue={userData?.email || ""}
                                        mask=""
                                        className="input-contact-data"
                                    />
                                ) : (
                                    <span>{userData?.email || "-"}</span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">
                                    Telefone{showEdit && "*"}:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("celNumber", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("celNumber");
                                            },
                                        })}
                                        mask={"55+ (99) 99999 9999"}
                                        type="text"
                                        error={errors.celNumber?.message || ""}
                                        defaultValue={String(
                                            userData?.celNumber
                                        )}
                                        className="input-contact-data"
                                    />
                                ) : (
                                    <span>
                                        {telephoneMask(
                                            String(userData?.celNumber)
                                        )}
                                    </span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">CNPJ:</span>
                                <span>{userData?.cnpj || "-"}</span>
                            </div>

                            <div className="contact-field">
                                <span className="field-title">
                                    Data de nasc.:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("birthDate", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("birthDate");
                                            },
                                        })}
                                        mask={"99/99/9999"}
                                        type="text"
                                        error={errors.birthDate?.message || ""}
                                        defaultValue={String(
                                            userData?.birthDate
                                        )}
                                        className="input-contact-data"
                                    />
                                ) : (
                                    <span>
                                        {(userData?.birthDate as string) || ""}
                                    </span>
                                )}
                            </div>
                        </div>

                        <s.AddressColumn>
                            <div
                                className="contact-field"
                                style={showEdit ? { marginBottom: 16 } : {}}
                            >
                                <span className="field-title">
                                    CEP{showEdit && "*"}:
                                </span>
                                {showEdit ? (
                                    <div id="tip-field">
                                        <Input
                                            {...register("cep", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: async (e) => {
                                                    await trigger("cep");
                                                    setCEP(e.target.value);
                                                },
                                            })}
                                            placeholder="00000-000"
                                            mask={"99999-999"}
                                            type="text"
                                            error={errors.cep?.message || ""}
                                            defaultValue={userData?.cep}
                                            className="input-contact-data"
                                        />

                                        <s.TipCep
                                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                            target="_blank"
                                        >
                                            Não sei o CEP
                                        </s.TipCep>
                                    </div>
                                ) : (
                                    <span>{userData?.cep}</span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">
                                    Estado{showEdit && "*"}:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        name={"state"}
                                        control={control}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={<Icons.GPS />}
                                                placeholder={
                                                    CEPFields
                                                        ? UFInputValue
                                                        : "Selecione o estado"
                                                }
                                                options={allUF}
                                                error={
                                                    errors.state?.message || ""
                                                }
                                                inputRef={ref}
                                                onChange={(val: {
                                                    value: string;
                                                }) => {
                                                    onChange(val.value);
                                                    setUFInputValue(val.value);
                                                    cityReq.onRefresh(
                                                        val.value
                                                    );
                                                }}
                                                disabled={
                                                    CEPFields || !watch("cep")
                                                }
                                                key={`state-select-${CEPFields}`}
                                                positionY="0.4"
                                                noError={!errors.state}
                                                noLabel
                                            />
                                        )}
                                    />
                                ) : (
                                    <span>{userData?.state || "-"}</span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">
                                    Cidade{showEdit && "*"}:
                                </span>

                                {showEdit ? (
                                    <Controller
                                        defaultValue=""
                                        name={"city"}
                                        control={control}
                                        render={({
                                            field: { ref, onChange },
                                        }) => (
                                            <SelectInput
                                                icon={<Icons.GPS />}
                                                placeholder={
                                                    CEPFields
                                                        ? cityInputValue
                                                        : "Selecione a cidade"
                                                }
                                                options={slicedCity}
                                                error={
                                                    errors.city?.message || ""
                                                }
                                                inputRef={ref}
                                                onInputChange={(value) => {
                                                    setCityInputValue(value);
                                                }}
                                                onChange={(val: {
                                                    value: string;
                                                }) => {
                                                    onChange(val.value);
                                                    setCityInputValue(
                                                        val.value
                                                    );
                                                }}
                                                disabled={
                                                    CEPFields || !watch("state")
                                                }
                                                key={`city-select-${CEPFields}`}
                                                positionY="0.4"
                                                noError={!errors.city}
                                                noLabel
                                            />
                                        )}
                                    />
                                ) : (
                                    <span>{userData?.city || "-"}</span>
                                )}
                            </div>

                            <div className="contact-field">
                                <span className="field-title">
                                    Endereço{showEdit && "*"}:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("address", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("address");
                                            },
                                        })}
                                        mask={""}
                                        placeholder="Digite o endereço"
                                        type="text"
                                        error={errors.address?.message || ""}
                                        defaultValue={userData?.address}
                                        className="input-contact-data"
                                        disabled={!watch("cep")}
                                    />
                                ) : (
                                    <span>{userData?.address}</span>
                                )}
                            </div>
                            <div className="contact-field">
                                <span className="field-title">
                                    Número{showEdit && "*"}:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("houseNumber", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value =
                                                    onlyNumbers(value);
                                            },
                                            onBlur: () => {
                                                trigger("houseNumber");
                                            },
                                        })}
                                        mask={""}
                                        placeholder="Digite o número"
                                        type="text"
                                        error={
                                            errors.houseNumber?.message || ""
                                        }
                                        defaultValue={userData?.houseNumber}
                                        className="input-contact-data"
                                        disabled={!watch("cep")}
                                    />
                                ) : (
                                    <span>{userData?.houseNumber}</span>
                                )}
                            </div>
                            <div className="contact-field">
                                <span className="field-title">
                                    Complemento:
                                </span>
                                {showEdit ? (
                                    <Input
                                        {...register("complement", {
                                            onChange: (e) => {
                                                const { value } = e.target;
                                                e.target.value = value;
                                            },
                                            onBlur: () => {
                                                trigger("complement");
                                            },
                                        })}
                                        mask={""}
                                        type="text"
                                        error={errors.complement?.message || ""}
                                        defaultValue={
                                            userData?.complement || ""
                                        }
                                        className="input-contact-data"
                                        maxLength={40}
                                        disabled={!watch("cep")}
                                        placeholder="Digite o complemento"
                                    />
                                ) : (
                                    <span>{userData?.complement || "-"}</span>
                                )}
                            </div>
                        </s.AddressColumn>

                        {showEdit ? (
                            <div className="icons-group">
                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Salvar alterações</s.P>}
                                >
                                    <span className="tooltip">
                                        <DesignedAlert
                                            textBtn={"Salvar"}
                                            onClickBtn={handleSubmit(onSubmit)}
                                            trigger={
                                                <Icons.Save
                                                    color={theme.purple600}
                                                />
                                            }
                                            description={
                                                "Ao salvar alterações as informações serão substituídas. Deseja salvar as alterações?"
                                            }
                                            open={
                                                Object.keys(errors).length > 0
                                                    ? false
                                                    : undefined
                                            }
                                            title={"Salvar alterações"}
                                        />
                                    </span>
                                </Tooltip>

                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Descartar alterações</s.P>}
                                >
                                    <span className="tooltip">
                                        <DesignedAlert
                                            textBtn={"Descartar"}
                                            onClickBtn={() => {
                                                reset(defaultValues);
                                                setShowEdit(!showEdit);
                                            }}
                                            trigger={
                                                <Icons.Trash
                                                    color={theme.purple600}
                                                />
                                            }
                                            description={
                                                "Ao descartar as alteraçãos, os campos editados não serão editados. Deseja descartar as alterações?"
                                            }
                                            title={"Descartar alterações"}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        ) : (
                            user.id_e === userId && (
                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Editar</s.P>}
                                >
                                    <span className="tooltip">
                                        <Icons.EditOutline
                                            color={theme.purple600}
                                            onClick={() =>
                                                setShowEdit(!showEdit)
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            )
                        )}
                    </>
                )}
            </s.Contacts>
        </s.Content>
    );
};
