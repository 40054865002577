import { TGetOrganization } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    all,
    organization_id,
    page,
}: TGetOrganization = {}) => {
    const params = {
        page,
        all,
    };

    const tempHeaders = {
        auth: true,
        "x-organization-id": organization_id,
    };

    if (!organization_id) delete tempHeaders["x-organization-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/organization/read`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        404: "Sem organizações!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
