import styled from "styled-components";

export const Collapse = styled.div<{ open?: boolean; height?: string }>`
    height: ${({ open, height }) => (open ? height : "0px")};
    opacity: ${({ open }) => (open ? "1" : "0")};
    overflow: hidden;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
`;
