import { Icons } from "../../../../../../assets";
import { Tooltip } from "../../../../../../components";
import { ReadDemonstrativeResult } from "../../../../../../services/projects/types";
import {
    formatStringNumberToMoney as formatMoney,
    minutesToStringDate,
} from "../../../../../../utils";
import * as s from "./styles";

type TeamInformationProps = {
    users: ReadDemonstrativeResult["users"] | undefined;
};

export const TeamInformation = ({ users }: TeamInformationProps) => {
    return (
        <s.Container>
            <Tooltip
                align="start"
                positiony={5}
                positionx={30}
                arrow
                positionArrow={59}
                content={
                    <s.TooltipContent>
                        Os dados de horas trabalhadas e valor total mensal são
                        calculados com base nos relatórios de faturamento dos
                        profissionais. <br /> Então, pode ser que os valores do
                        mês atual não estejam atualizados, somente após o
                        faturamento dos relatórios.
                    </s.TooltipContent>
                }
            >
                <s.Title>
                    Valores do time do projeto
                    <Icons.Info width="18px" height="18px" />
                </s.Title>
            </Tooltip>
            <s.Table>
                <s.TableHead>
                    <s.TableRow>
                        <s.TableHeader>Profissional</s.TableHeader>
                        <s.TableHeader>Atuação</s.TableHeader>
                        <s.TableHeader>Horas trabalhadas</s.TableHeader>
                        <s.TableHeader>Valor total mensal</s.TableHeader>
                    </s.TableRow>
                </s.TableHead>
                <s.TableBody>
                    {users?.map((user) => (
                        <Tooltip
                            align="start"
                            positiony={10}
                            positionx={30}
                            arrow
                            positionArrow={59}
                            open={user.notInProject ? undefined : false}
                            content={
                                <s.TooltipContent style={{ width: "240px" }}>
                                    O profissional não pertence mais ao projeto,
                                    porém suas horas continuam registradas.
                                </s.TooltipContent>
                            }
                        >
                            <s.TableRow notInProject={user.notInProject}>
                                <s.TableData>{user.name}</s.TableData>
                                <s.TableData>{user.Role.name}</s.TableData>
                                <s.TableData>
                                    {minutesToStringDate(
                                        user.workedMinutesInProject || 0
                                    )}
                                </s.TableData>
                                <s.TableData>
                                    {formatMoney(
                                        user.costDone?.toString() || "0"
                                    )}
                                </s.TableData>
                            </s.TableRow>
                        </Tooltip>
                    ))}
                </s.TableBody>
            </s.Table>
        </s.Container>
    );
};
