import { Dispatch, SetStateAction } from "react";

import { Icons, theme } from "../../../../assets";
import ContainerModal from "../../../../components/molecules/modals/container-modal";
import * as s from "./styled-modal-no-users-selected";

type TModalNoUsersSelected = {
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalNoUsersSelected = ({ setOpen }: TModalNoUsersSelected) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <header>
                    <div>
                        <Icons.Warning
                            width="25px"
                            height="24px"
                            color={theme.orangeWarning}
                        />
                        <h1>Nenhum profissional selecionado</h1>
                    </div>
                    <span
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <Icons.XSolid onClick={() => setOpen(false)} />
                    </span>
                </header>
                <section>
                    Selecione pelo menos um profissional para editar as
                    informações.
                </section>
                <s.Button onClick={() => setOpen(false)} theme={theme}>
                    <span>OK</span>
                </s.Button>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalNoUsersSelected;
