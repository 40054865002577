import * as s from "./styled-loading";

const Loading = () => {
    return (
        <s.ContainerLoader>
            <s.Loading>
                <span className="border">
                    <span className="line"></span>
                    <span className="circle"></span>
                </span>
            </s.Loading>
            <s.Text>Carregando PaperOn...</s.Text>
        </s.ContainerLoader>
    );
};

export default Loading;
