import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Pagination } from "../../../../../components";
import { useUser } from "../../../../../context";
import { manageUser } from "../../../../../services";
import { ReadUserProjectsResponse } from "../../../../../services/user/types";
import {
    SelectProject,
    ProjectsPermissionsMassControl,
    ProjectsTable,
} from "./components";
import * as s from "./styles";

export const TabProjects = () => {
    const { user } = useUser();

    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");

    const { state } = useLocation();
    const userId = state?.id;

    const { data, refetch, isLoading } = useQuery<ReadUserProjectsResponse>({
        queryKey: ["userProjects"],
        queryFn: () => manageUser.readUserProjects({ userId, page, search }),
        enabled: Boolean(userId),
        cacheTime: 0,
        staleTime: 0,
    });

    useEffect(() => {
        refetch();
    }, [page]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            refetch();
        }, 500);
        return () => clearTimeout(timeout);
    }, [search]);

    const havePermission = data?.hasBillPermission && data?.hasReviewPermission;
    const isAdmin = user.Role.name === "Administrador";

    return (
        <s.Container isAdmin={isAdmin}>
            <s.Header>Projetos vinculados ao profissional</s.Header>
            {isAdmin && (
                <s.Content havePermission={havePermission}>
                    {havePermission && (
                        <ProjectsPermissionsMassControl data={data} />
                    )}
                    <SelectProject />
                </s.Content>
            )}
            <ProjectsTable
                data={data}
                isLoading={isLoading}
                setSearch={setSearch}
                search={search}
            />
            {!isLoading && data && data.projects.length > 0 && (
                <s.PaginationWrapper>
                    <Pagination
                        id="projects-table-pagination"
                        page={page - 1}
                        onClick={({ selected }) => setPage(selected + 1)}
                        totalPages={data?.totalPages || 0}
                        disabled={data?.totalPages === 0}
                    />
                </s.PaginationWrapper>
            )}
            {!isLoading && data && data.projects.length === 0 && (
                <s.NoData style={{ textAlign: "center" }}>
                    O profissional não pode ficar sem projetos! <br /> Escolha
                    um projeto para adicioná-lo.
                </s.NoData>
            )}
        </s.Container>
    );
};
