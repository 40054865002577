import { LoadingGray, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalReviewerBillerMassProps = {
    userName: string;
    isLoading: boolean;
    userHasAllPermissions: boolean;
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
};

export const ModalReviewerBillerMass = ({
    userName,
    isLoading,
    userHasAllPermissions,
    onConfirm,
    onOpenChange,
}: ModalReviewerBillerMassProps) => {
    const close = () => onOpenChange(false);

    return (
        <ContainerModal onOpenChange={onOpenChange}>
            {isLoading ? (
                <s.LoadingWrapper>
                    <LoadingGray />
                </s.LoadingWrapper>
            ) : (
                <s.Container>
                    <s.Title>Permissão de revisor e faturador em massa</s.Title>
                    {userHasAllPermissions ? (
                        <s.Text>
                            Esta ação irá remover o profissional de todos os
                            projetos e retirar a permissão de revisor e
                            faturador em massa.
                        </s.Text>
                    ) : (
                        <s.Text>
                            Esta ação irá vincular o profissional a todos os
                            projetos da organização e dará permissão de revisar
                            e faturar todos profissionais da organização.
                        </s.Text>
                    )}
                    <s.Text>
                        {userHasAllPermissions
                            ? "Você tem certeza de que deseja remover a permissão de revisor e faturador em massa para"
                            : "Você tem certeza de que dar esta permissão para"}
                        <s.Name>{` ${userName}`}</s.Name>?
                    </s.Text>
                    <s.Buttons>
                        <SecondaryButton onClick={close}>
                            CANCELAR
                        </SecondaryButton>
                        <PrimaryButton onClick={onConfirm}>
                            CONFIRMAR
                        </PrimaryButton>
                    </s.Buttons>
                </s.Container>
            )}
        </ContainerModal>
    );
};
