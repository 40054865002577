import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Label = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    width: 129px;
`;

export const Value = styled.div<{ color?: string }>`
    color: ${({ theme, color }) => color ?? theme.gray900};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
`;

export const PriorityIndicator = styled.div<{ color: string }>`
    color: ${({ color }) => color};
    font-size: 24px;
    margin-right: 5px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
`;
