import { TUpdateProject } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    description,
    color,
    start_at,
    end_at,
    status,
    projectId,
    usersId,
    clientId,
    sectorsId,
    reviewersId,
    billersId,
    estimatedHours,
    scopePercentage,
    cost,
    generalCosts,
    technologies,
    archived,
}: TUpdateProject = {}) => {
    const params = {
        name,
        description,
        color,
        start_at,
        end_at,
        estimatedHours,
        scopePercentage,
        cost,
        status,
        generalCosts,
        technologies,
        archived,
    };

    const tempHeaders = {
        auth: true,
        "x-project-id": projectId,
        "x-users-id": usersId,
        "x-client-id": clientId,
        "x-sectors-id": sectorsId,
        "x-reviewers-id": reviewersId,
        "x-billers-id": billersId,
    };

    if (!projectId) delete tempHeaders["x-project-id"];
    if (!usersId) delete tempHeaders["x-users-id"];
    if (!clientId) delete tempHeaders["x-client-id"];
    if (!sectorsId) delete tempHeaders["x-sectors-id"];
    if (!reviewersId) delete tempHeaders["x-reviewers-id"];
    if (!billersId) delete tempHeaders["x-billers-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/update`,
        method: "put",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const usersError: string = body?.message?.users
        ?.map((user: any) => user.name)
        ?.join(", ");

    const errorFeedBacks = {
        400:
            (body.message ===
                "End date must be greater than the current date!" &&
                "Data final precisa ser maior que a data atual") ||
            (body.message === "Start date must be less than end date!" &&
                "Data de início precisa ser menor do que a data de fim") ||
            (!reviewersId &&
                !billersId &&
                `Necessário ao menos um revisor e um faturador no projeto   `) ||
            (!reviewersId && "Necessário ao menos um revisor!") ||
            (!billersId && "Necessário ao menos um faturador!") ||
            (body?.message?.error ===
                "the project cannot be deleted or have its user list changed, as it is the only one available to the following users:" &&
                `Você não pode remover o único projeto de um usuário! \nOs seguintes usuários estão vinculados somente a este projeto:\n\n ${usersError}`),
        404:
            body.message === "Project does not exists!"
                ? "Necessário ao menos um profissional"
                : "Usuário não existe",
        409:
            body.message === "A project with this name already exists!"
                ? "Já existe um projeto com o mesmo nome!"
                : "Houve um problema ao criar o projeto!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
