import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 479px;
    gap: 5px;
`;

export const CheckboxWrapper = styled.div<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    &:hover {
        box-shadow: ${({ disabled }) =>
            disabled
                ? "none"
                : "0px 0px 0.5rem 0.25rem rgba(102, 51, 152, 0.15)"};
    }
    div {
        justify-content: center;
        pointer-events: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.purple400};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    margin-left: 24px;
`;
