import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../../assets";
import { Input, PrimaryButton } from "../../../../../components/atoms";
import { PasswordStrength } from "../../../../../components/molecules/password-strength/password-strength";
import { useUser } from "../../../../../context";
import { useFetch } from "../../../../../hooks";
import { useAuthenticateWithGoogle } from "../../../../../hooks/react-query/use-authenticate-with-google";
import { TAuthBody, TAuthError, TAuthResponse } from "../../../../../models";
import {
    auth,
    manageUser,
    organizations,
    projects,
} from "../../../../../services";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import { TData } from "../../../../cadastro-admin/register-admin";
import { TUserData } from "../../../../invite/invite";
import * as s from "./styled-final-step";
import { validateRegisterFinalStep } from "./validation";

type TSeePassword = "password" | "password_confirmation";

type TFinalStep = {
    userData?: TUserData;
    postUserData?: (data: TData) => void;
};

export const RegisterFinalStep = ({ postUserData, userData }: TFinalStep) => {
    const { setIsLogged, setPermissions, setHaveOrganization, setWorkedHours } =
        useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const credential = queryParams.get("credential");
    const isGoogleRegister = queryParams.get("google") === "true";
    const googleUserName = queryParams.get("name");

    const [seePassword, setSeePassword] = useState<TSeePassword[]>([]);

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        clearErrors,
    } = useForm<TData>({
        resolver: yupResolver(validateRegisterFinalStep(isGoogleRegister)),
        shouldUnregister: false,
        defaultValues: {
            name: userData?.name || googleUserName || "",
            organizationName: userData?.organizationName || "",
        },
    });

    const loginReq = useFetch<TAuthBody, TAuthResponse, TAuthError>({
        fn: auth,
        start: false,
    });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const getProjects = useFetch({ fn: projects.getProject, start: false });

    const simplifiedCreateOrganization = useFetch({
        fn: organizations.simplifiedCreateOrganization,
        start: false,
    });

    const login = (response: TAuthResponse) => {
        setIsLogged(true);
        setPermissions(response.userPermsId);
        toast.success("Logado com sucesso");
        localStorage.setItem(
            "haveOrganization",
            JSON.stringify(!!response.user.organization)
        );
        setHaveOrganization(!!response.user.organization);

        getUser.onRefresh();

        navigate("/cronometro", {
            state: {
                fromRegister: true,
            },
        });
    };

    const authenticateWithGoogle = useAuthenticateWithGoogle({
        onSuccess: (response) => {
            login(response);
        },
    });

    const isInvite = !!userData?.invitedBy;

    const correctInputType = (type: TSeePassword) => {
        if (seePassword.includes(type)) {
            setSeePassword(seePassword.filter((item) => item !== type));
            return;
        }
        setSeePassword([...seePassword, type]);
    };

    const eyeIconMapper = (type: TSeePassword) => {
        return seePassword.includes(type) ? (
            <Icons.SenhaFechado color={theme.gray600} />
        ) : (
            <Icons.Eye color={theme.gray600} />
        );
    };

    const typeMapper = (type: TSeePassword) => {
        return seePassword.includes(type) ? "text" : "password";
    };

    const onSubmit = (data: TData) => {
        if (isGoogleRegister && credential) {
            authenticateWithGoogle.mutate({
                credential,
                terms: true,
                organizationName: data.organizationName,
                userName: data.name,
                phone: data.celNumber,
                voucher: data.voucher,
            });
            return;
        }
        if (data.password !== data.password_confirmation) {
            setError("password_confirmation", {
                type: "manual",
                message: "As senhas não coincidem",
            });
            return;
        }
        if (postUserData) {
            postUserData(data);
            return;
        }
        simplifiedCreateOrganization.onRefresh({
            ...data,
            email: userData?.email,
        });
    };

    const handleUserOrganizationData = (getUser: any, loginReq: any) => {
        setWorkedHours({
            total: getUser.response.monthlyTime,
            limit:
                (getUser?.response?.Organization?.monthly_hour_limit || 1) * 60,
        });

        if (loginReq.response.user.organization) {
            getProjects.onRefresh({ sectors: true });
        } else {
            navigate("/cronometro");
        }
    };

    useEffect(() => {
        if (simplifiedCreateOrganization?.response?.id_e) {
            Mixpanel.track("Concluiu o cadastro");
            toast.success("Cadastro realizado com sucesso!");
            loginReq.onRefresh({
                email: userData?.email as string,
                password: watch("password"),
            });
        }
    }, [simplifiedCreateOrganization.response]);

    useEffect(() => {
        const message = simplifiedCreateOrganization?.error?.body?.message;
        if (message === "voucher not exists or inactive") {
            setError("voucher", {
                message: "Cupom inexistente ou inativo.",
            });
        }
    }, [simplifiedCreateOrganization.error]);

    useEffect(() => {
        if (loginReq.response) {
            login(loginReq.response);
        }
    }, [loginReq.response]);

    useEffect(() => {
        if (getUser.response && loginReq.response) {
            handleUserOrganizationData(getUser, loginReq);
        }
    }, [getUser.response]);

    return (
        <s.Container onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <s.Title>Complete seus dados</s.Title>
            <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <Input
                        mask=""
                        autoComplete="new-password"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Nome completo"
                        label={"Nome completo"}
                        error={errors.name?.message}
                    />
                )}
            />
            <Controller
                name="organizationName"
                control={control}
                render={({ field: { value, onChange } }) => (
                    <Input
                        mask=""
                        autoComplete="new-password"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="Nome da empresa, marca ou seu nome"
                        label={"Nome da organização"}
                        error={errors.organizationName?.message}
                        disabled={!!userData?.organizationName}
                    />
                )}
            />
            <Controller
                name="celNumber"
                control={control}
                defaultValue=""
                render={({ field: { value, onChange } }) => (
                    <Input
                        mask="(99) 999999999"
                        autoComplete="new-password"
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                        placeholder="Insira o seu telefone com DDD"
                        label={"DDD + Telefone (opcional)"}
                        error={errors.celNumber?.message}
                    />
                )}
            />
            {!isGoogleRegister && (
                <>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange } }) => (
                            <Input
                                mask=""
                                autoComplete="new-password"
                                value={value}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    clearErrors("password_confirmation");
                                }}
                                placeholder="Insira sua senha"
                                label={"Senha"}
                                type={typeMapper("password")}
                                error={
                                    errors.password?.message ? "  " : undefined
                                }
                                icon={
                                    <s.EyeContainer
                                        onClick={() =>
                                            correctInputType("password")
                                        }
                                    >
                                        {eyeIconMapper("password")}
                                    </s.EyeContainer>
                                }
                            />
                        )}
                    />
                    <PasswordStrength
                        password={watch("password")}
                        error={errors.password}
                    />
                    <Controller
                        name="password_confirmation"
                        control={control}
                        defaultValue=""
                        render={({ field: { value, onChange } }) => (
                            <Input
                                mask=""
                                value={value}
                                autoComplete="new-password"
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    clearErrors("password_confirmation");
                                }}
                                placeholder="Confirmação da senha"
                                label={"Confirmar senha"}
                                type={typeMapper("password_confirmation")}
                                error={errors.password_confirmation?.message}
                                icon={
                                    <s.EyeContainer
                                        onClick={() =>
                                            correctInputType(
                                                "password_confirmation"
                                            )
                                        }
                                    >
                                        {eyeIconMapper("password_confirmation")}
                                    </s.EyeContainer>
                                }
                            />
                        )}
                    />
                </>
            )}
            {!isInvite && (
                <Controller
                    name="voucher"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                        <Input
                            placeholder="Exemplo: GANHOU30"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            label="Cupom"
                            error={errors.voucher?.message}
                        />
                    )}
                />
            )}
            <PrimaryButton
                type="submit"
                loading={
                    simplifiedCreateOrganization.loading ||
                    loginReq.loading ||
                    authenticateWithGoogle.isLoading
                }
            >
                CADASTRE-SE
            </PrimaryButton>
        </s.Container>
    );
};
