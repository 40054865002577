import dayjs from "dayjs";
import { Chart } from "react-chartjs-2";

import { theme } from "../../../../../../assets";
import { TProject } from "../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../services/projects/types";
import * as s from "./styles";

type Props = {
    project: TProject;
    data: ReadDemonstrativeResult | undefined;
};

export const HoursChart = ({ project, data }: Props) => {
    const startDate = dayjs(project.start_at).add(3, "hours");
    const endDate = dayjs(project.end_at || new Date()).add(3, "hours");
    const monthsLength = endDate.diff(startDate, "months");
    const monthsInProjectPeriod = Array.from({ length: monthsLength + 1 }).map(
        (_, index) => {
            return startDate.add(index, "month").format("MMM/YY");
        }
    );

    return (
        <s.Container>
            <Chart
                type="bar"
                height={500}
                data={{
                    labels: monthsInProjectPeriod,
                    datasets: [
                        {
                            type: "line",
                            backgroundColor: theme.green1,
                            borderColor: theme.green1,
                            data: data?.suggestions,
                            borderWidth: 3,
                            label: `Sugestão para completar as horas até ${endDate.format(
                                "MMM/YYYY"
                            )}`,
                        },
                        {
                            type: "bar",
                            backgroundColor: theme.purple300,
                            data: data?.accumulatedHours,
                            borderWidth: 1,
                            label: "Horas realizadas até o mês anterior",
                        },
                        {
                            type: "bar",
                            backgroundColor: theme.purple500,
                            data: data?.currentYearMonthsWorkedHours,
                            borderWidth: 1,
                            borderRadius: 8,
                            label: "Horas realizadas no mês",
                        },
                    ],
                }}
                options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                        },
                    },
                    plugins: {
                        legend: {
                            align: "center",
                            position: "bottom",
                            fullSize: true,
                            labels: {
                                boxWidth: 18,
                                boxHeight: 18,
                                pointStyle: "circle",
                                usePointStyle: true,
                                padding: 40,
                                color: theme.gray800,
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
        </s.Container>
    );
};
