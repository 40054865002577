import * as yup from "yup";

export default yup.object().shape({
    education: yup
        .string()
        .min(16, "Mínimo de 16 caracteres.")
        .max(55, "Máximo de 55 caracteres."),
    skills: yup.string().max(255, "Máximo de 255 caracteres."),
    areasOfInterest: yup.string().max(240, "Máximo de 240 caracteres."),
    workFacilities: yup.string().max(240, "Máximo de 240 caracteres."),
});
