import React from "react";

import { PopoverCalendar } from "../../../../../../components";
import { TProject, optionsPeriods } from "../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../services/projects/types";
import {
    formatStringNumberToMoney as formatMoney,
    minutesToStringDate,
} from "../../../../../../utils";
import { statusOptions } from "../../../../utils/projects-utils";
import { Dates } from "../../tab-demonstrative";
import * as s from "./styles";

type ValuesInformationProps = {
    data: ReadDemonstrativeResult | undefined;
    project: TProject;
    setDates: React.Dispatch<React.SetStateAction<Dates>>;
};

export const ValuesInformation = ({
    data,
    project,
    setDates,
}: ValuesInformationProps) => {
    const status = statusOptions.find((item) => item.value === project.status);

    return (
        <s.Container>
            <PopoverCalendar
                getPeriod={(value) => {
                    setDates({
                        startAt: value?.from?.toISOString(),
                        endAt: value?.to?.toISOString(),
                    });
                }}
                optionsPeriods={optionsPeriods.slice(4, 8)}
            />
            <s.Content>
                <s.Title>Horas no período</s.Title>
                <s.Text>
                    {data ? minutesToStringDate(data.workedHours) : "-"}
                </s.Text>
            </s.Content>
            <s.Content>
                <s.Title>Custo no período</s.Title>
                <s.Text>{formatMoney(data?.cost?.toString())}</s.Text>
            </s.Content>
            <s.Content>
                <s.Title>Status atual</s.Title>
                <s.Text>{status?.label || "-"}</s.Text>
            </s.Content>
        </s.Container>
    );
};
