import { View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { theme } from "../../../../../../assets";
import { SelectedDate } from "../../../../../../pages/organization/project/tab-costs/tab-costs";
import { statusIsActive } from "../../../../../../pages/organization/project/tab-costs/utils";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import {
    formatToBRMoney,
    formatToBRMoneyWithoutMoneySign,
} from "../../../../../../utils";
import * as s from "../../../styled-pdf-template";

type FixedCostsProps = {
    data: ReadProjectCostsResult | undefined;
    date: SelectedDate;
};

export const FixedCosts = ({ data, date }: FixedCostsProps) => {
    if (!data) return null;

    const month = dayjs(date.start).format("MMMM/YYYY");

    return (
        <View>
            <View
                style={[
                    {
                        border: `1px solid ${theme.gray200}`,
                        borderRadius: 4,
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "25px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                    },
                ]}
            >
                <Text
                    style={[
                        s.PDFTableSummaryReport.TableBodyText,
                        {
                            textAlign: "center",
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "9px",
                            letterSpacing: "0.96px",
                        },
                    ]}
                >
                    andamento geral dos custos fixos
                </Text>
                <View
                    style={{
                        display: "flex",
                        marginTop: "15px",
                        marginBottom: "20px",
                        borderRadius: 4,
                        flexDirection: "row",
                        gap: "50px",
                        border: `1px solid ${theme.gray200}`,
                        padding: "22px 35px",
                        width: "472px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <View
                        style={{
                            borderRight: `1px solid ${theme.gray200}`,
                            paddingRight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total previsto
                        </Text>
                        <Text style={s.ProjectCosts.CostText}>
                            {formatToBRMoney(
                                data.resume.total.fixedCost.forecast
                            )}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total realizado
                        </Text>
                        <Text style={s.ProjectCosts.CostTextPurple}>
                            {formatToBRMoney(
                                data.resume.total.fixedCost.realized
                            )}
                        </Text>
                    </View>
                </View>
            </View>

            <View
                style={{
                    border: `1px solid ${theme.gray200}`,
                    borderRadius: "4px",
                    width: "100%",
                }}
            >
                <View
                    style={{
                        padding: "12px 25px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "9px",
                            width: "20%",
                        }}
                    >
                        nome
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "9px",
                            width: "20%",
                        }}
                    >
                        descrição
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "9px",
                            width: "20%",
                        }}
                    >
                        data
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "9px",
                            width: "20%",
                        }}
                    >
                        custo (R$)
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "9px",
                            width: "20%",
                        }}
                    >
                        status
                    </Text>
                </View>

                {data.fixedCosts.data.map((cost) => (
                    <View
                        style={{
                            borderTop: `1px solid ${theme.gray200}`,
                            padding: "12px 25px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Text
                            style={{
                                fontSize: "9px",
                                color: theme.gray600,
                                width: "20%",
                            }}
                        >
                            {cost.title}
                        </Text>
                        <Text
                            style={{
                                fontSize: "9px",
                                color: theme.gray600,
                                width: "20%",
                            }}
                        >
                            {cost.description}
                        </Text>
                        <Text
                            style={{
                                fontSize: "9px",
                                color: theme.gray600,
                                width: "20%",
                            }}
                        >
                            {dayjs(cost.date).format("DD/MM/YYYY")}
                        </Text>
                        <Text
                            style={{
                                fontSize: "9px",
                                color: theme.gray600,
                                width: "20%",
                            }}
                        >
                            {formatToBRMoneyWithoutMoneySign(cost.cost)}
                        </Text>
                        <View style={{ width: "20%" }}>
                            <Text
                                style={{
                                    fontSize: "9px",
                                    padding: "5px 10px",
                                    borderRadius: "8px",
                                    textTransform: "capitalize",
                                    backgroundColor: statusIsActive(cost.date)
                                        ? theme.lightGreen
                                        : theme.lightGray,
                                    color: statusIsActive(cost.date)
                                        ? theme.green1
                                        : theme.gray600,
                                    width: statusIsActive(cost.date)
                                        ? "63px"
                                        : "70px",
                                }}
                            >
                                {statusIsActive(cost.date)
                                    ? "Realizado"
                                    : "Programado"}
                            </Text>
                        </View>
                    </View>
                ))}

                <View
                    style={{
                        borderTop: `1px solid ${theme.gray200}`,
                        padding: "12px 25px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        backgroundColor: theme.gray50,
                    }}
                >
                    <Text
                        style={{
                            fontSize: "9px",
                            color: theme.gray600,
                            width: "20%",
                        }}
                    >
                        Total {month}
                    </Text>

                    <Text
                        style={{
                            width: "20%",
                        }}
                    ></Text>
                    <Text
                        style={{
                            width: "20%",
                        }}
                    ></Text>
                    <Text
                        style={{
                            fontSize: "9px",
                            color: theme.gray600,
                            width: "20%",
                        }}
                    >
                        {formatToBRMoneyWithoutMoneySign(
                            data.fixedCosts.total.realizedCost
                        )}
                    </Text>
                    <Text
                        style={{
                            width: "20%",
                        }}
                    ></Text>
                </View>
            </View>
        </View>
    );
};
