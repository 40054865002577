import {
    PrimaryButton,
    SecondaryButton,
    TextArea,
} from "../../../../../components";
import ContainerModal from "../../../../../components/molecules/modals/container-modal";
import * as s from "./styled-cancel-plan-reason-modal";

type TCancelPlanReasonModal = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickContinue: () => void;
    setCancelReason: React.Dispatch<React.SetStateAction<string>>;
    cancelReason: string;
};

export const CancelPlanReasonModal = ({
    setOpen,
    onClickContinue,
    setCancelReason,
    cancelReason,
}: TCancelPlanReasonModal) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Por que você quer cancelar seu plano?</s.Title>
                <TextArea
                    maxLength={300}
                    charCount
                    placeholder="Descreva o motivo aqui"
                    onChange={(e) => setCancelReason(e.target.value)}
                    value={cancelReason}
                    counter={cancelReason}
                />
                <s.ButtonsContainer>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        VOLTAR
                    </SecondaryButton>
                    <PrimaryButton onClick={onClickContinue}>
                        CONTINUAR
                    </PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};
