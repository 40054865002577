import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { invoicing } from "../../../../services";
import {
    formatToBRMoney,
    getDateFromIsoString,
    getDayMonthAndYearFromDate,
    minutesToStringDate,
} from "../../../../utils";
import {
    Input,
    TextArea,
    PrimaryButton,
    SecondaryButton,
} from "../../../atoms";
import Tooltip from "../../../atoms/tooltip/tooltip";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-invoicing";

type TFormInputs = {
    initial_total: string;
    discount: string;
    benefits: string;
    commentary?: string;
    final_total: string;
};

type TModalAction = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIsDone: Dispatch<SetStateAction<boolean>>;
    primaryAction?: () => void;
    secondaryAction?: () => void;
    primaryText?: string;
    secondaryText?: string;
    data: any;
};

const ModalInvoicing = ({
    setOpen,
    setIsDone,
    data,
    primaryAction,
    secondaryAction,
}: TModalAction) => {
    function formatCoin(valorInput: string): string {
        let valor = valorInput;
        valor += "";
        valor = parseFloat(valor.replace(/[\D]+/g, "")).toString();
        valor += "";
        valor = valor.replace(/([0-9]{2})$/g, ",$1");

        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        if (valor === "NaN") valor = "";
        return valor;
    }

    const totalBenefits = data.User?.ProjectUserBenefit?.reduce(
        (acc: number, { value }: { value: string }) => {
            return acc + Number(value);
        },
        0
    ) as number;

    const {
        register,
        handleSubmit,
        trigger,
        watch,
        formState: { errors },
    } = useForm<TFormInputs>({
        defaultValues: {
            initial_total: "",
            benefits: formatCoin(totalBenefits.toFixed(2)),
            discount: "",
            final_total: "",
        },
    });
    const [calculatedValue, setCalculatedValue] = useState<string>("");
    const [discountValue, setDiscountValue] = useState<string>("");
    const [additionalValue, setAdditionalValue] = useState<string>("");
    const [totalValue, setTotalValue] = useState<string>("");
    const [calculatedValueFixed, setCalculatedValueFixed] = useState<string>(
        (data.User.hourValue * (data.total_time / 60)).toFixed(2)
    );

    const period = `
    ${getDayMonthAndYearFromDate(getDateFromIsoString(data.start_at))}
    - ${getDayMonthAndYearFromDate(getDateFromIsoString(data.end_at))}
    `;

    function validateInputValue(value: string): boolean {
        const firstChar = value.charAt(0);
        return firstChar !== "0";
    }

    function parseCoin(valorFormatado: string): number {
        const hasThousandsSeparator = valorFormatado.includes(".");
        const hasDecimalSeparator = valorFormatado.includes(",");

        if (hasThousandsSeparator && hasDecimalSeparator) {
            const floatValue = parseFloat(
                valorFormatado.replace(/[.]/g, "").replace(",", ".")
            );
            return floatValue;
        }
        return parseFloat(valorFormatado.replace(",", "."));
    }

    const calculateInitialValueBill = () => {
        const totalHours = data.total_time / 60;

        const total = (data.User.hourValue * totalHours).toFixed(2);
        return total;
    };

    const createBill = useFetch({
        fn: invoicing.createBill,
        start: false,
    });

    const onSubmit = (invoicingData: any) => {
        const benefits = additionalValue === "" ? "0" : additionalValue;
        const discount = discountValue === "" ? "0" : discountValue;
        createBill.onRefresh({
            final_total: parseFloat(totalValue),
            initial_total: parseFloat(calculateInitialValueBill()),
            additional: parseCoin(benefits),
            discounts: parseCoin(discount),
            comment: invoicingData?.commentary,
            report_id: data?.id_e,
        });
    };

    const calculateTotalValueBill = (edit?: string): string => {
        let discount = parseCoin(discountValue);
        let benefits = parseCoin(additionalValue);

        if (!discount) {
            discount = 0;
        }
        if (!benefits) {
            benefits = 0;
        }

        if (edit === "edited") {
            const calc = parseCoin(calculatedValue);
            if (calculatedValue === "") {
                setCalculatedValueFixed(calculateInitialValueBill());
            } else {
                setCalculatedValueFixed(calculatedValue);
                const total = calc - discount + benefits;
                return total.toFixed(2).toString();
            }
        }
        const total = parseCoin(calculatedValueFixed) - discount + benefits;
        return total.toString();
    };

    const billed = data?.ReportBilling;

    useEffect(() => {
        setAdditionalValue(formatCoin(totalBenefits.toFixed(2)));
    }, [setAdditionalValue, totalBenefits]);

    useEffect(() => {
        const total = calculateTotalValueBill();
        setTotalValue(total);
    }, [discountValue, additionalValue]);

    useEffect(() => {
        const total = calculateTotalValueBill("edited");
        if (total !== "NaN") {
            setTotalValue(total);
        }
    }, [calculatedValue]);

    useEffect(() => {
        if (createBill.error) {
            setOpen(false);
        }
        if (createBill.response) {
            toast.success("Fatura criada com sucesso!");
            setOpen(false);
            setIsDone(true);
        }
    }, [createBill.response, createBill.error]);
    const handleEditOurView = () => {
        if (billed)
            return (
                <ContainerModal onOpenChange={setOpen}>
                    <s.Container onSubmit={handleSubmit(onSubmit)}>
                        <div id="text-tittle">
                            <h1>Visualizar faturamento</h1>{" "}
                            <Icons.XOutline
                                onClick={primaryAction}
                                width="1rem"
                                height="1rem"
                            />
                        </div>
                        <div id="views-modals">
                            <span>
                                <span className="descriptionInput">
                                    FATURADO POR
                                </span>{" "}
                                <strong>
                                    {data.ReportBilling.Author.name ||
                                        "Faturador"}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    DATA DE FATURAMENTO
                                </span>{" "}
                                <strong>
                                    {getDayMonthAndYearFromDate(
                                        getDateFromIsoString(
                                            data.ReportBilling.created_at
                                        )
                                    ) ?? "Há alguns dias"}
                                </strong>{" "}
                            </span>
                        </div>
                        <div id="views-modals">
                            <span>
                                <span className="descriptionInput">
                                    PROFISSIONAL:
                                </span>{" "}
                                <strong>{data.User.name}</strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    PERÍODO:
                                </span>{" "}
                                <strong>{period}</strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    TOTAL DE HORAS:
                                </span>{" "}
                                <strong>
                                    {data.total_time
                                        ? minutesToStringDate(data.total_time)
                                        : "Não disponível"}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR DA HORA:
                                </span>{" "}
                                <strong>
                                    {data.User.hourValue
                                        ? formatToBRMoney(data.User.hourValue)
                                        : "DADOS MOCKADOS"}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR CALCULADO:
                                </span>{" "}
                                <strong>
                                    {formatToBRMoney(
                                        data.ReportBilling.initial_total
                                    ) || 0}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    DESCONTOS
                                </span>
                                <strong>
                                    {formatToBRMoney(
                                        data.ReportBilling.discounts
                                    ) || 0}
                                </strong>
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    ADICIONAIS
                                </span>{" "}
                                <strong>
                                    {formatToBRMoney(
                                        data.ReportBilling.additional
                                    ) || 0}
                                </strong>
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR FATURADO:
                                </span>{" "}
                                <strong>
                                    {formatToBRMoney(
                                        data.ReportBilling.final_total
                                    ) || 0}
                                </strong>{" "}
                            </span>
                        </div>
                        <div className="view-box">
                            <span>
                                {data.ReportBilling.comment || "Sem comentário"}
                            </span>
                        </div>
                    </s.Container>
                </ContainerModal>
            );
        return (
            <>
                <ContainerModal onOpenChange={setOpen}>
                    <s.Container onSubmit={handleSubmit(onSubmit)}>
                        <div id="text">
                            <h1>Faturar</h1>
                        </div>

                        <div id="fields-modals">
                            <span>
                                <span className="descriptionInput">
                                    PROFISSIONAL:
                                </span>
                                <strong>{data.User.name}</strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    PERÍODO:
                                </span>
                                <strong>{period}</strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    TOTAL DE HORAS:
                                </span>{" "}
                                <strong>
                                    {data.total_time
                                        ? minutesToStringDate(data.total_time)
                                        : "Não disponível"}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR DA HORA:
                                </span>{" "}
                                <strong>
                                    {`${formatToBRMoney(data.User.hourValue)}`}
                                </strong>{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR CALCULADO
                                </span>
                                <Input
                                    placeholder={calculateInitialValueBill()}
                                    rightInputText="R$ -"
                                    className="inputs divOut"
                                    icon={<Icons.EditOutline />}
                                    {...register(
                                        "initial_total" as keyof TFormInputs,
                                        {
                                            onBlur: () => {
                                                trigger(
                                                    "initial_total" as keyof TFormInputs
                                                );
                                            },
                                            onChange: () => {
                                                trigger(
                                                    "initial_total" as keyof TFormInputs
                                                );
                                            },
                                        }
                                    )}
                                    mask={""}
                                    error={
                                        errors[
                                            "initial_total" as keyof TFormInputs
                                        ]?.message || ""
                                    }
                                    onChange={(e) => {
                                        setCalculatedValue(
                                            formatCoin(e.target.value)
                                        );
                                    }}
                                    value={calculatedValue}
                                />
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    DESCONTOS
                                </span>
                                <Input
                                    placeholder="00,00"
                                    rightInputText="R$ -"
                                    className="inputs"
                                    icon={<Icons.EditOutline />}
                                    {...register(
                                        "discount" as keyof TFormInputs,
                                        {
                                            onBlur: (e) => {
                                                trigger(
                                                    "discount" as keyof TFormInputs
                                                );
                                                setDiscountValue(
                                                    formatCoin(e.target.value)
                                                );
                                            },
                                            onChange: () => {
                                                trigger(
                                                    "discount" as keyof TFormInputs
                                                );
                                            },
                                        }
                                    )}
                                    mask={""}
                                    error={
                                        errors["discount" as keyof TFormInputs]
                                            ?.message || ""
                                    }
                                    onChange={(e) => {
                                        e.target.value = formatCoin(
                                            e.target.value
                                        );
                                    }}
                                />
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    ADICIONAIS
                                    <Tooltip
                                        content={
                                            <s.TooltipContainer>
                                                Caso haja benefícios preenchidos
                                                na tela de custo dos projetos,
                                                serão somados e adicionados
                                                automaticamente a esse campo
                                            </s.TooltipContainer>
                                        }
                                    >
                                        <div className="info-wrapper">
                                            <Icons.Info color={theme.gray600} />
                                        </div>
                                    </Tooltip>
                                </span>{" "}
                                <Input
                                    placeholder="00,00"
                                    className="inputs"
                                    rightInputText="R$ +"
                                    icon={<Icons.EditOutline />}
                                    {...register(
                                        "benefits" as keyof TFormInputs,
                                        {
                                            onBlur: (e) => {
                                                trigger(
                                                    "benefits" as keyof TFormInputs
                                                );
                                                setAdditionalValue(
                                                    formatCoin(e.target.value)
                                                );
                                            },
                                            onChange: (e) => {
                                                trigger(
                                                    "benefits" as keyof TFormInputs
                                                );
                                                const { value } = e.target;
                                                if (validateInputValue(value)) {
                                                    e.target.value = value;
                                                }
                                            },
                                        }
                                    )}
                                    mask={""}
                                    error={
                                        errors["benefits" as keyof TFormInputs]
                                            ?.message || ""
                                    }
                                    onChange={(e) => {
                                        e.target.value = formatCoin(
                                            e.target.value
                                        );
                                    }}
                                />{" "}
                            </span>
                            <span>
                                <span className="descriptionInput">
                                    VALOR TOTAL:
                                </span>{" "}
                                <strong>
                                    R${" "}
                                    {totalValue === ""
                                        ? calculateInitialValueBill()
                                        : totalValue}
                                </strong>{" "}
                            </span>
                        </div>
                        <div>
                            <TextArea
                                {...register("commentary")}
                                label="Comentário:"
                                placeholder="Espaço reservado para justificativa"
                                charCount
                                counter={watch("commentary") || ""}
                                maxLength={150}
                                className="commentary"
                                classNameLabel="labelCommentary"
                            />
                        </div>

                        <div id="buttons">
                            <SecondaryButton onClick={primaryAction}>
                                Voltar
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                onClick={secondaryAction}
                                disabled={parseFloat(totalValue) <= 0}
                            >
                                {parseFloat(totalValue) <= 0
                                    ? "Valor Inválido"
                                    : "Faturar"}
                            </PrimaryButton>
                        </div>
                    </s.Container>
                </ContainerModal>
            </>
        );
    };

    return handleEditOurView();
};

export default ModalInvoicing;
