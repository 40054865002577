import styled from "styled-components";

export const Container = styled.div`
    display: flex;
`;

export const Tab = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 12px 0px;
    width: 100%;
    gap: 12px;
    transition: background 0.3s;
    border-radius: 4px 4px 0px 0px;
    cursor: pointer;
    color: ${({ theme }) => theme.purple600};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.28px;
    text-transform: uppercase;
    position: relative;

    p {
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
    }

    ${({ active, theme }) => {
        if (active) return ``;
        return `
            color: ${theme.gray200};
        `;
    }}

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }
`;

export const Line = styled.div<{ active?: boolean }>`
    width: 100%;
    height: 4px;
    background-color: ${({ active, theme }) =>
        active ? theme.purple600 : theme.gray200};
    transition: width 0.5s;
    width: ${({ active }) => (active ? "100%" : "0")};
    margin-bottom: -12px;
    z-index: 1;
`;

export const LineBackground = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.gray200};
    margin-top: -2px;
    margin-bottom: -12px;
    z-index: 0;
`;
