import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../../../../../assets";
import { PopoverRadix } from "../../../../../../../../../components";
import { projects } from "../../../../../../../../../services";
import { ReadProjectCostsResult } from "../../../../../../../../../services/projects/types";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import * as s from "./styles";

type BenefitsProps = {
    userData: ReadProjectCostsResult["teamCosts"]["data"][0];
    active?: boolean;
    setIsToCreateBenefit: React.Dispatch<React.SetStateAction<boolean>>;
    setUserIdToEditBenefit: React.Dispatch<React.SetStateAction<string>>;
};

export const Benefit = ({
    active,
    userData,
    setUserIdToEditBenefit,
    setIsToCreateBenefit,
}: BenefitsProps) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const deleteUserProjectBenefit = useMutation({
        mutationKey: ["deleteUserProjectBenefit"],
        mutationFn: projects.deleteUserProjectBenefit,
        onSuccess: () => {
            toast.success("Benefício excluído com sucesso!");
            queryClient.invalidateQueries(["readCosts"]);
        },
    });

    return (
        <s.Container active={active || popoverOpen}>
            {userData.benefit ? (
                <PopoverRadix
                    onOpenChange={setPopoverOpen}
                    open={popoverOpen}
                    side="bottom"
                    align="start"
                    sideOffset={10}
                    closeIcon={<Icons.XSolid color={theme.gray600} />}
                    trigger={
                        <s.PopoverTrigger>
                            <Icons.Chat />
                        </s.PopoverTrigger>
                    }
                >
                    <s.PopoverContent>
                        <s.PopoverContentTitle>
                            Observações
                        </s.PopoverContentTitle>
                        <s.PopoverContentText>
                            {userData.benefit.comment}
                        </s.PopoverContentText>
                        <s.PopoverContentActions>
                            <s.PopoverContentEdit
                                onClick={() => {
                                    setUserIdToEditBenefit(userData.user_id);
                                    setPopoverOpen(false);
                                }}
                            >
                                <Icons.EditOutline color={theme.gray800} />
                            </s.PopoverContentEdit>
                            <s.PopoverContentDelete
                                onClick={() => {
                                    deleteUserProjectBenefit.mutate({
                                        benefitId: userData.benefit.id_e,
                                    });
                                    setPopoverOpen(false);
                                }}
                            >
                                <Icons.Trash color={theme.redError} />
                            </s.PopoverContentDelete>
                        </s.PopoverContentActions>
                    </s.PopoverContent>
                </PopoverRadix>
            ) : (
                <Icons.ChatDisabled
                    onClick={() => setIsToCreateBenefit((prev) => !prev)}
                />
            )}
        </s.Container>
    );
};
