import { PDFViewer } from "@react-pdf/renderer";
import { Dispatch, SetStateAction } from "react";

import { TActivity, TReportProject } from "../../../../models";
import { CollaboratorResume } from "../../pdf-templates";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-pdf";

export type TModalPDF = {
    pdfData?: {
        activities?: TActivity;
        projects?: TReportProject;
        period?: { startDate: Date; endDate: Date };
        totalTime?: string;
        barChartImg?: string;
        doughnutChartImg?: string;
    };
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalPDF = ({ setOpen, pdfData }: TModalPDF) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <PDFViewer width="100%" height="100%">
                    <CollaboratorResume pdfData={pdfData} />
                </PDFViewer>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalPDF;
