import { encryptObjectToBase64 } from "../../utils/encrypt/encrypt-to-base-64";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TCreateSubscription = {
    planType?: "monthly" | "yearly";
    plan?: "lite" | "starter" | "standard";
    card?: {
        number?: string;
        exp_month?: string;
        exp_year?: string;
        cvc?: string;
        holder_name?: string;
    };
    contractorName?: string;
    installments?: number;
    organizationId?: string;
};

export default async ({
    planType,
    plan,
    organizationId,
    card,
    contractorName,
    installments,
}: TCreateSubscription = {}) => {
    const tempHeaders = {
        auth: true,
    };

    let creditCardEncrypted;

    if (organizationId) {
        organizationId = organizationId.slice(0, 32);
        creditCardEncrypted = await encryptObjectToBase64(card, organizationId);
    }

    const bodyParams = {
        card: creditCardEncrypted,
        planType,
        plan: plan?.toLocaleLowerCase(),
        installments: installments || 1,
        contractorName,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/changePlan`,
        method: "patch",
        headers: tempHeaders,
        body: bodyParams,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
