import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    padding: 1.125rem 1.5rem;
    width: 22.875rem;
`;

export const Header = styled.header`
    color: ${({ theme }) => theme.redError};
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
`;

export const Text = styled.div<{ danger?: boolean }>`
    color: ${({ danger, theme }) => danger && theme.redError};
`;

export const Bold = styled.span`
    font-weight: 600;
`;

export const Close = styled.button`
    background: transparent;
    position: absolute;
    right: 0;
    padding-right: 20px;
`;

export const HoursToApply = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: ${({ theme }) => theme.gray800};
    background: ${({ theme }) => theme.purple50};
    padding: 12px;
    height: 66px;
`;
