import dayjs from "dayjs";

import { TimeLineSummary } from "../../../../components/molecules/timeline-summary/timeline-summary";
import { useUser } from "../../../../context";
import { firstUpperCase } from "../../../../utils/string-manipulation/first-upper-case";
import * as s from "./styles";

export function AccumulatedValue() {
    const { user, workedHours } = useUser();

    function getWorkingDays(startDate: Date, endDate: Date) {
        let result = 0;
        const currentDate = startDate;
        while (currentDate <= endDate) {
            const weekDay = currentDate.getDay();
            if (weekDay !== 0 && weekDay !== 6) result += 1;

            currentDate.setDate(currentDate.getDate() + 1);
        }
        return result;
    }

    const remaining = workedHours.limit / 60 - workedHours.total / 60;
    const workDaysUntilEndOfMonth = getWorkingDays(
        dayjs().toDate(),
        dayjs().endOf("month").toDate()
    );
    const hoursPerDay = remaining / workDaysUntilEndOfMonth;

    return (
        <s.Container>
            <s.Header>
                <s.ValueContainer>
                    <s.HeaderTitle>Período:</s.HeaderTitle>
                    <s.HeaderValue>
                        {firstUpperCase(dayjs().format("MMMM"))}{" "}
                        {dayjs().format("YYYY")}
                    </s.HeaderValue>
                </s.ValueContainer>
                <s.ValueContainer>
                    <s.HeaderTitle>Limite de horas:</s.HeaderTitle>
                    <s.HeaderValue>{workedHours.limit / 60}:00</s.HeaderValue>
                </s.ValueContainer>
            </s.Header>
            <TimeLineSummary
                limitHours={workedHours.limit}
                totalTime={workedHours.total}
                hourValue={Number(user.hourValue)}
            />
            {remaining > 0 && (
                <s.Text>
                    Você precisa trabalhar em média {hoursPerDay.toFixed(2)}h
                    por dia, pelos próximos {workDaysUntilEndOfMonth} dias úteis
                </s.Text>
            )}
        </s.Container>
    );
}
