import styled from "styled-components";

export const SmallP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    padding: 10px 16px;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme, color }) => color || theme.gray800};
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    margin: 1rem 0 3rem;
    height: 40px;

    button {
        width: 152px;
    }
`;
