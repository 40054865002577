import { Icons, Welcome } from "../../../../../assets";
import * as s from "./styled-welcome-banner";

type TWelcomeBannerProps = {
    onClose: () => void;
};

export const WelcomeBanner = ({ onClose }: TWelcomeBannerProps) => {
    return (
        <s.Container>
            <img src={Welcome} />
            <s.Content>
                <s.Title>Bem-vindo à plataforma PaperON!</s.Title>
                <s.Text>
                    O que fazer a seguir? Aqui estão algumas sugestões de
                    próximas etapas que você pode seguir para tirar mais
                    proveito da plataforma.
                </s.Text>
                <s.List>
                    <s.Item>
                        <span className="indicator">•</span>
                        <p>
                            <strong>Personalize setores e atuações:</strong>{" "}
                            Isso ajudará a organizar melhor seu trabalho e a
                            comunicação entre os profissionais.
                        </p>
                    </s.Item>
                    <s.Item>
                        <span className="indicator">•</span>
                        <p>
                            <strong>Crie projetos e atividades:</strong> Use a
                            plataforma para organizar seu trabalho e acompanhar
                            o progresso.
                        </p>
                    </s.Item>
                    <s.Item>
                        <span className="indicator">•</span>
                        <p>
                            <strong>Adicione profissionais:</strong> Convide
                            seus colegas para se juntarem à plataforma e
                            trabalharem juntos com você em projetos.
                        </p>
                    </s.Item>
                    <s.Item>
                        <span className="indicator">•</span>
                        <p>
                            <strong>Crie relatórios:</strong> Acompanhe o
                            progresso do seu trabalho e crie relatórios
                            rapidamente.
                        </p>
                    </s.Item>
                </s.List>
                <s.Text>
                    Lembre-se de que nada é obrigatório! Você pode explorar a
                    plataforma no seu próprio ritmo e seguir apenas as etapas
                    que forem relevantes para você.
                </s.Text>
            </s.Content>
            <s.CloseButton onClick={onClose}>
                <Icons.XOutline />
            </s.CloseButton>
        </s.Container>
    );
};
