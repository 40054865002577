import { pdf } from "@react-pdf/renderer";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../../../../../assets";
import {
    PopoverCalendar,
    PrimaryButton,
    ProjectCosts,
    SecondaryButton,
} from "../../../../../../components";
import { MonthPicker } from "../../../../../../components/molecules/month-picker/month-picker";
import { TProject } from "../../../../../../models";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import { ActiveTabsEnum, SelectedDate } from "../../tab-costs";
import * as s from "./styles";

type TabHeaderProps = {
    date: SelectedDate;
    activeTab: ActiveTabsEnum;
    data: ReadProjectCostsResult | undefined;
    setDate: React.Dispatch<React.SetStateAction<SelectedDate>>;
    setIsToCreate: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TabHeader = ({
    data,
    date,
    activeTab,
    setDate,
    setIsToCreate,
}: TabHeaderProps) => {
    const { state } = useLocation();
    const { project }: { project: TProject } = state || {};

    const tabsWithAddButton: ActiveTabsEnum[] = [
        "Custos fixos",
        "Custos variáveis",
    ];
    const showButton = tabsWithAddButton.includes(activeTab);
    const isTeamTab = activeTab === "Custos time";

    async function generatePDF() {
        try {
            const blob = await pdf(
                <ProjectCosts
                    data={data}
                    project={project}
                    date={date}
                    tab={activeTab}
                />
            ).toBlob();

            const fileURL = window.URL.createObjectURL(blob);

            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", "Report.pdf");
            tempLink.click();
        } catch (error) {
            toast.error("Erro ao fazer download!");
        }
    }

    return (
        <s.Container>
            <s.LeftSide>
                {isTeamTab ? (
                    <MonthPicker
                        defaultValue={dayjs().toISOString()}
                        onChange={(e) => {
                            const start = dayjs(e.target.value)
                                .startOf("month")
                                .toDate();
                            const end = dayjs(e.target.value)
                                .endOf("month")
                                .toDate();
                            setDate({
                                start,
                                end,
                            });
                        }}
                    />
                ) : (
                    <PopoverCalendar
                        alternativeInput
                        defaultValue={{
                            from: date.start,
                            to: date.end,
                        }}
                        getPeriod={(date) => {
                            setDate({
                                start: date?.from,
                                end: date?.to,
                            });
                        }}
                    />
                )}
                <SecondaryButton
                    onClick={generatePDF}
                    className="button"
                    variation="small"
                >
                    <Icons.PDF color={theme.purple600} />
                    PDF
                </SecondaryButton>
            </s.LeftSide>
            {showButton && (
                <PrimaryButton
                    onClick={() => setIsToCreate(true)}
                    className="button"
                    variation="small"
                >
                    Adicionar <Icons.Plus color={theme.white} />
                </PrimaryButton>
            )}
        </s.Container>
    );
};
