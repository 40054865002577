import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type BackOfficeCreateSocialMedia = {
    value?: number;
    socialMedia?: string;
    date?: string;
};

export default async ({
    value,
    socialMedia,
    date,
}: BackOfficeCreateSocialMedia = {}) => {
    const headers = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/backoffice/socialMedia`,
        method: "post",
        headers,
        body: {
            value,
            socialMedia,
            date,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
