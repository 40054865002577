import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const Container = styled.div`
    padding: 16px;
    display: flex;
    align-items: flex-end;
    position: absolute;
    background-color: var(--color-white, #ffffff);
    z-index: 2;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    gap: 16px;
    margin-bottom: 150px;
    margin-left: -134px;

    @media (max-width: 1000px) {
        margin-left: 0px;
    }
`;

export const Inputs = styled.div``;

export const Title = styled.div`
    color: var(--color-brand-purple-400, #8c53c5);
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 4px;
`;

export const EditInput = styled(ReactInputMask)`
    padding: 4px 16px !important;
    max-width: 96px;
`;

export const SaveIcon = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 4px;
    cursor: pointer;
`;
