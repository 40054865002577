import { TGetProject } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    all,
    userId,
    projectId,
    sectorId,
    clientId,
    sectors,
    users,
    hours,
    finished,
    reviewers,
    page,
    hourProgressFilter,
    showOnlyActiveProjects,
    showOnlyActiveUsers,
    length,
    searchTerm,
    demonstrative,
}: TGetProject = {}) => {
    const params = {
        all,
        sectors,
        users,
        hours,
        finished,
        reviewers,
        page,
        hourProgressFilter,
        showOnlyActiveProjects,
        showOnlyActiveUsers,
        length,
        searchTerm,
        demonstrative,
    };

    const tempHeaders = {
        auth: true,
        "x-project-id": projectId,
        "x-sectors-id": sectorId,
        "x-client-id": clientId,
        "x-user-id": userId,
    };

    if (!projectId) delete tempHeaders["x-project-id"];
    if (!sectorId) delete tempHeaders["x-sectors-id"];
    if (!clientId) delete tempHeaders["x-client-id"];
    if (!userId) delete tempHeaders["x-user-id"];
    if (!searchTerm) delete params.searchTerm;
    if (!showOnlyActiveUsers) delete params.showOnlyActiveUsers;

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/read`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        403:
            body.message === "You don't have permission to access this resource"
                ? "Você não tem permissão para acessar esse recurso"
                : "Erro ao tentar listar projetos",
        404: "Sem projetos!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
