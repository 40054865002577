import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { ReadActivitiesVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    projectId,
    search,
    startDate,
    endDate,
}: ReadActivitiesVariables = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/read`,
        method: "get",
        body: {},
        params: { search, startDate, endDate },
        headers: {
            "x-project-id": projectId,
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
