import React from "react";

import { Icons } from "../../../assets";
import * as s from "./styled-primary-button";

type TPrimaryButton = {
    children: string | React.ReactNode;
    onClick?: () => void;
    type?: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
    variation?: "small" | "big";
    isActive?: boolean;
    iconSolid?: boolean;
    style?: React.CSSProperties;
    className?: string;
    icon?: JSX.Element;
    loading?: boolean;
    id?: string;
};

const PrimaryButton = ({
    children,
    onClick,
    type,
    disabled,
    variation,
    isActive,
    style,
    iconSolid,
    className,
    icon,
    loading,
    id,
}: TPrimaryButton) => {
    return (
        <s.Button
            id={id}
            iconSolid={iconSolid}
            isActive={isActive}
            variation={variation || "medium"}
            type={type}
            onClick={onClick}
            disabled={disabled || loading}
            style={style}
            className={className}
        >
            <div className="inside-button">
                {children}
                {!loading && <>{icon}</>}
                {loading && <Icons.Loading />}
            </div>
        </s.Button>
    );
};

export default PrimaryButton;
