import { validateCNPJ, validateCep } from "validations-br";
import * as yup from "yup";

import { validationErrors } from "../../../../../../utils";

const { cep, phone, address, houseNumber, city, state, cnpj, email } =
    validationErrors;

export default yup.object().shape({
    name: yup
        .string()
        .min(2, "Mínimo 2 caracteres")
        .max(100, "Máximo de 100 caracteres."),
    fantasy_name: yup
        .string()
        .min(2, "Mínimo 2 caracteres")
        .max(100, "Máximo de 100 caracteres.")
        .nullable()
        .required("Nome fantasia é obrigatório"),
    cnpj: yup
        .string()
        .required(cnpj.required)
        .test("cnpj", cnpj.invalid, (value: string | undefined) => {
            if (value) {
                return validateCNPJ(value);
            }
            return false;
        }),
    phone: yup
        .string()
        .required(phone.required)
        .test("telefone", phone.invalid, (value) => {
            if (value) {
                return /(\d{2}\+) (\(\d{2}\)) (\d{5}) (\d{4})/.test(value);
            }
            return false;
        }),
    email: yup
        .string()
        .trim()
        .email(email.invalid)
        .required(email.required)
        .min(3, "Mínimo 3 caracteres")
        .max(100, "Máximo de 100 caracteres"),
    cep: yup
        .string()
        .required(cep.required)
        .test("test-cep", cep.invalid, (value) => {
            if (value) return validateCep(value);
            return false;
        }),
    address: yup
        .string()
        .required(address.required)
        .min(3, "Mínimo 3 caracteres")
        .max(100, "Máximo de 100 caracteres"),
    houseNumber: yup
        .string()
        .required(houseNumber.required)
        .test("houseNumber", houseNumber.invalid, (value) => {
            if (value) {
                return /^\d+$/.test(value);
            }
            return false;
        })
        .max(5),
    complement: yup.string().max(100, "Máximo de 100 caracteres"),
    city: yup.string().required(city.required),
    state: yup.string().required(state.required),
});
