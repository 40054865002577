import styled from "styled-components";

type TVariation = "small" | "medium" | "big" | "link";

type TButton = {
    variation: TVariation;
    color?: string;
};

const setHeight = (variation: TVariation) => {
    if (variation === "small") return "40px";
    if (variation === "big") return "56px";
    if (variation === "link") return "100%";
    return "48px";
};

export const Button = styled.button<TButton>`
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ variation, theme, color }) =>
        variation !== "link" &&
        `background: ${theme.white};
         border: 0.075rem solid ${color || theme.purple600};
         border-radius: 0.25rem;`};
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${({ theme, color }) => color || theme.purple600};
    text-decoration: dotted;
    width: 100%;
    height: ${({ variation }) => setHeight(variation)};

    ${({ variation, theme, color }) =>
        variation !== "link" &&
        `
        &:hover {
            border: 0.075rem solid ${
                color ? `hsl(0, 100% , 35%)` : theme.purple700
            };
            color: ${color ? `hsl(0, 100% , 35%)` : theme.purple700};
            background: ${theme.white};
            box-shadow: 0px 2px 12px rgba(80, 52, 121, 0.2);
        }
        &:active {
            background: ${theme.purple50};
            box-shadow: none;
        }
        &:disabled {
            background: ${theme.white};
            color: ${theme.gray300};
            border: 1px solid ${theme.gray300};
            box-shadow: none;
        }
    `};

    .inside-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;
