import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    gap: 17px;
    align-items: center;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

export const HoursDone = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Name = styled.h1`
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const LastUpdate = styled.h3`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const Picture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;
