import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TGetAudit = {
    userId?: string;
};

export default async ({ userId }: TGetAudit = {}) => {
    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
    };

    if (!userId) delete tempHeaders["x-user-id"];

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/audit/read`,
        method: "get",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Histórico de usuário não encontrado",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
