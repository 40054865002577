import { useState } from "react";
import Switch from "react-switch";

import { theme } from "../../../assets";
import * as s from "./styled-configs-check";

type TPermissions = {
    id?: string;
    description?: string;
    checked?: boolean;
    isChecked?: boolean;
    onChange: (value: boolean) => void;
    disabledSwitch?: boolean;
};

const ConfigsCheck = ({
    id,
    description,
    isChecked,
    onChange,
    disabledSwitch,
}: TPermissions) => {
    const [checked, setChecked] = useState(isChecked || false);

    const check = () => {
        setChecked(!checked);
    };

    return (
        <s.Container checked={checked} className="config-option">
            <div id="content">
                <div id="check">
                    <Switch
                        id={id}
                        width={34}
                        height={14}
                        handleDiameter={20}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={theme.purple100}
                        offColor={theme.gray400}
                        offHandleColor={theme.white}
                        onHandleColor={theme.purple500}
                        boxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
                        activeBoxShadow="0px 3px 5px -1px rgba(0, 0, 0, 0.7)"
                        checked={checked}
                        onChange={(e) => {
                            onChange(e);
                            check();
                        }}
                        disabled={disabledSwitch}
                    />
                </div>
                <div id="description">
                    <p>{description}</p>
                </div>
            </div>
        </s.Container>
    );
};

export default ConfigsCheck;
