import { TGetSector } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    all,
    projectId,
    sectorId,
    userId,
    page,
    users,
    projects,
}: TGetSector = {}) => {
    const params = {
        all,
        users,
        projects,
        page,
    };

    const tempHeaders = {
        auth: true,
        "x-project-id": projectId,
        "x-sector-id": sectorId,
        "x-user-id": userId,
    };

    if (!projectId) delete tempHeaders["x-project-id"];
    if (!sectorId) delete tempHeaders["x-sector-id"];
    if (!userId) delete tempHeaders["x-user-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sector/read`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        403:
            body.message === "You don't have permission to access this resource"
                ? "Você não tem permissão para acessar esse recurso"
                : "Erro ao tentar listar setores",
        404: "Sem setores!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
