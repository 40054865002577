import { useState } from "react";

import { Tooltip } from "../..";
import { Icons, theme } from "../../../assets";
import { formatISOToDate } from "../../../utils";
import * as s from "./styled-tooltip-history";

type TTooltipHistory = {
    dataList:
        | {
              estimated_hours?: number;
              scopePercentage?: number;
              cost?: number;
              created_at: string;
              user_name: string;
          }[]
        | undefined;
    valueKey: "estimated_hours" | "scopePercentage" | "cost";
};

const TooltipHistory = ({ dataList, valueKey }: TTooltipHistory) => {
    const [showAll, setShowAll] = useState(false);

    const originalList = dataList || [];
    let list = originalList;
    if (!showAll) list = list.slice(0, 2);

    if (originalList.length === 0)
        return (
            <div style={{ margin: "0 auto -4px 0" }}>
                <Icons.History color={theme.gray400} />
            </div>
        );
    return (
        <Tooltip
            side="right"
            align="start"
            positionx={10}
            content={
                <s.ProjectScopeHistory>
                    <h5>Histórico de edições</h5>
                    <section>
                        {list.map((historic) => (
                            <label>
                                {historic?.created_at
                                    ? formatISOToDate(historic.created_at)
                                    : "-"}
                                {" - "}
                                {historic?.user_name || "-"}
                                {" - "}
                                {historic[valueKey] || "-"}
                            </label>
                        ))}
                        {!showAll && originalList.length > list.length && (
                            <a onClick={() => setShowAll(!showAll)}>
                                Mostrar tudo ({originalList.length})
                            </a>
                        )}
                    </section>
                </s.ProjectScopeHistory>
            }
        >
            <div style={{ margin: "0 auto -4px 0" }}>
                <Icons.History />
            </div>
        </Tooltip>
    );
};

export default TooltipHistory;
