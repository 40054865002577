import React, { ChangeEvent, useEffect, useState } from "react";
import InputMask from "react-input-mask";

import { cardNumberMask } from "./masks/card-number-mask";
import { phoneMask } from "./masks/phone-mask";
import * as s from "./styled-custom-input";

type TinputType = "cellphone" | "cardNumber";

type TInput = {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: HTMLInputElement["type"];
    icon?: React.ReactNode;
    label?: string;
    error?: string;
    autoComplete?: string;
    name?: string;
    mask: string;
    formatChars?: { [key: string]: string } | undefined;
    maxLength?: number | undefined;
    id?: string;
    defaultValue?: string;
    className?: string;
    disabled?: boolean;
    rightSideLabelIcon?: React.ReactNode;
    rightInputText?: string;
    accept?: string;
    inputType?: TinputType;
};

const CustomInput = React.forwardRef<HTMLInputElement, TInput>(
    (
        {
            onChange,
            onBlur,
            onFocus,
            placeholder,
            className,
            type,
            icon,
            error,
            label,
            autoComplete,
            name,
            mask,
            formatChars,
            maxLength,
            id,
            defaultValue,
            disabled,
            rightSideLabelIcon,
            rightInputText,
            accept,
            inputType,
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState<string>("");

        function adaptToInputType() {
            if (inputType === "cellphone") {
                const formatedValue = phoneMask(inputValue);
                setInputValue(formatedValue);
            }
            if (inputType === "cardNumber") {
                const formatedValue = cardNumberMask(inputValue);
                setInputValue(formatedValue);
            }
        }

        useEffect(() => {
            adaptToInputType();
        }, [inputValue]);

        return (
            <s.Content>
                <s.FlexColumn className="input-wrapper">
                    {label && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "0.625rem",
                            }}
                        >
                            <s.Label htmlFor={id || label}>{label}</s.Label>
                        </div>
                    )}
                    <s.FlexRow error={!!error} icon={!!icon}>
                        {rightInputText ? (
                            <s.PositionText>{rightInputText}</s.PositionText>
                        ) : null}
                        <InputMask
                            disabled={disabled}
                            className={className}
                            id={id || label}
                            defaultValue={defaultValue}
                            value={inputValue}
                            maxLength={maxLength}
                            mask={mask}
                            inputRef={ref}
                            autoComplete={autoComplete}
                            type={type}
                            placeholder={placeholder}
                            onChange={(e) => {
                                setInputValue(e.target.value);
                                if (onChange) onChange(e);
                            }}
                            onBlur={onBlur}
                            name={name}
                            alwaysShowMask={false}
                            maskChar={null}
                            formatChars={formatChars}
                            onFocus={onFocus}
                            accept={accept}
                        />

                        {icon ? (
                            <s.PositionIcons>{icon}</s.PositionIcons>
                        ) : null}
                    </s.FlexRow>
                    {error && <s.Error>{error}</s.Error>}
                </s.FlexColumn>
                {rightSideLabelIcon && (
                    <s.FlexRow>{rightSideLabelIcon}</s.FlexRow>
                )}
            </s.Content>
        );
    }
);

export default CustomInput;
