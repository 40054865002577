import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Icons, theme } from "../../../../assets";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-welcome-plan";

type TModalWelcomePlan = {
    planType: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ModalWelcomePlan = ({ planType, setOpen }: TModalWelcomePlan) => {
    return (
        <ContainerModal
            backgroundColor={theme.gray50}
            onOpenChange={setOpen}
            closeOnIconOnly
            closeIcon={true}
            closeIconColor={theme.gray600}
        >
            <s.Container>
                <section>
                    <Icons.WelcomeBox
                        onClick={() => {
                            sessionStorage.removeItem("planChoice");
                            setOpen(false);
                        }}
                        width="120px"
                        height="81px"
                    />
                    <h1>Boas vindas ao plano {planType}</h1>
                    <p>
                        Seu pagamento foi registrado com sucesso. Aproveite tudo
                        que o PaperON pode oferecer para a gestão do seu tempo.
                    </p>
                    <button
                        onClick={() => {
                            sessionStorage.removeItem("planChoice");
                            setOpen(false);
                        }}
                    >
                        OK
                    </button>
                </section>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalWelcomePlan;
