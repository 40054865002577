import { styled } from "styled-components";

export const Container = styled.div`
    input[type="month"] {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        background-color: ${({ theme }) => theme.white};
        border: 1px solid ${({ theme }) => theme.gray400};
        padding: 10px 10px;
        border-radius: 4px;
    }
`;
