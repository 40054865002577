import styled from "styled-components";

export const Container = styled.div`
    padding: 16px;
    width: 460px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.09px;
    margin-bottom: 20px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 32px;
`;
