import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import Select from "react-select";

import { Icons, theme } from "../../../../../../../../../assets";
import { Input } from "../../../../../../../../../components";
import Tooltip from "../../../../../../../../../components/atoms/tooltip/tooltip";
import { activities } from "../../../../../../../../../services";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { ActivityDTO, SectionOptionDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TEditableInfos = {
    activity: ActivityDTO;
};

type TData = {
    name?: string;
    sector?: string;
    lastPosition?: number;
};

export const EditableInfos = ({ activity }: TEditableInfos) => {
    const [editSector, setEditSector] = useState(false);
    const [editName, setEditName] = useState(false);
    const [name, setName] = useState(activity.name);
    const [sector, setSector] = useState<string | undefined>(
        activity.ActivitySection?.name
    );

    const { mutate } = useMutation<unknown, unknown, TData>({
        mutationFn: (variables) =>
            activities.updateActivity({
                ...variables,
                activityIds: [activity.id_e],
                sectionId: variables.sector ?? activity.ActivitySection?.id_e,
                position: variables.lastPosition,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const mapToSelect = (options?: SectionOptionDTO[]) => {
        return options?.map((option) => ({
            value: option.id_e,
            label: option.name,
        }));
    };

    const findSelectedSectionLastPosition = () => {
        return (
            activity.sectionOptions?.find(
                (option) => option.id_e === activity.ActivitySection?.id_e
            )?.lastPosition ?? 0
        );
    };

    const save = () => {
        if (editName) {
            mutate({ name });
            setEditName(false);
        }
        if (editSector) {
            mutate({
                sector,
                lastPosition: findSelectedSectionLastPosition() + 1,
            });
            setEditSector(false);
        }
    };

    return (
        <s.Container
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    save();
                }
            }}
        >
            {editSector ? (
                <s.SectionWrapper>
                    <s.CheckButton onClick={save}>
                        <Icons.Checked width="24px" color={theme.gray600} />
                    </s.CheckButton>
                    <Select
                        options={mapToSelect(activity.sectionOptions)}
                        onChange={(e) => {
                            setSector(e?.value);
                        }}
                        defaultValue={{
                            label: activity.ActivitySection?.name,
                            value: activity.ActivitySection?.id_e,
                        }}
                        placeholder="Selecione um setor"
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                    />
                </s.SectionWrapper>
            ) : (
                <Tooltip
                    align="start"
                    positiony={5}
                    positionx={-5}
                    content={
                        <s.TooltipContainer>
                            Clique para editar o setor da atividade
                        </s.TooltipContainer>
                    }
                >
                    <s.Section onClick={() => setEditSector(true)}>
                        {activity.ActivitySection?.name}
                    </s.Section>
                </Tooltip>
            )}
            {editName ? (
                <Input
                    mask=""
                    value={name}
                    ref={(input) => input?.focus()}
                    icon={
                        <s.Button onClick={save}>
                            <Icons.Checked width="24px" color={theme.gray600} />
                        </s.Button>
                    }
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            ) : (
                <Tooltip
                    align="start"
                    positiony={5}
                    positionx={-5}
                    content={
                        <s.TooltipContainer>
                            Clique para editar o nome da atividade
                        </s.TooltipContainer>
                    }
                >
                    <s.ActivityName onClick={() => setEditName(true)}>
                        {activity.name}
                    </s.ActivityName>
                </Tooltip>
            )}
        </s.Container>
    );
};
