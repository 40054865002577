import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TMassToggleLockCollaborator = {
    usersId?: string[];
    lock?: boolean;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({ usersId, lock }: TMassToggleLockCollaborator = {}) => {
    const params = {
        usersId,
        lock,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/MassToggleLock`,
        method: "post",
        body: params,
        headers: { auth: true },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro ao bloquear profissional!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
