import { TAuthBody, TAuthResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { Auth } from "./Authentication";
import { OK } from "./status-codes.constants";

const { REACT_APP_BASE_URL } = process.env;

export default async (data?: TAuthBody) => {
    const auth = Auth.getInstance();
    auth.clearTokens();

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sessions/auth`,
        method: "post",
        body: data,
    });

    const { body, statusCode } = httpResponse;

    if (statusCode === OK) {
        auth.login({ body });
    }

    const errorFeedBacks = {
        400: "E-mail ou senha incorretos",
        403: "Usuário não autorizado",
        0: "Erro ao conectar com o servidor",
    };

    return handleStatusCases<TAuthResponse>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
