import styled from "styled-components";

type TDisable = { disabled: boolean };

export const Pagination = styled.div<TDisable>`
    display: flex;
    margin: 0 auto;

    button#previous-page svg.chevron {
        transform: rotate(90deg);
    }

    button#next-page svg.chevron {
        transform: rotate(-90deg);
    }

    svg.chevron-icon {
        width: 2rem;
        height: 2rem;

        path {
            stroke: ${({ theme }) => theme.purple400};
        }
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    li {
        display: flex;
    }

    .pagination {
        display: flex;
        gap: 5px;
    }

    button[disabled],
    a[aria-disabled="true"] {
        display: none;
        opacity: 0;
        cursor: not-allowed;
    }

    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${({ theme }) => theme.gray300};
        background: ${({ theme }) => theme.background};
        width: 2.2rem;
        height: 2.2rem;
        font-weight: 500;
        font-size: 1rem;
        cursor: pointer;
    }

    .break {
        pointer-events: none;
    }

    .page-link:hover,
    .active .page-link {
        color: ${({ theme }) => theme.purple400};
        background: ${({ theme }) => theme.background};
    }

    .active a {
        border-radius: 4px;
        background-color: ${({ theme }) => theme.pink100};
        cursor: default;
    }
`;

export const ButtonIcon = styled.button`
    border: none;
    background: none;
    padding: 0 5px;
    font-size: 0;
    height: 100%;

    :hover:not(:disabled) {
        background: none;
    }
`;
