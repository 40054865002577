/* eslint-disable prettier/prettier */
import styled from "styled-components";

type TFlexColumn = {
    gap?: string;
};

export const Container = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 2fr;
    gap: 8rem;
    height: 100%;
    background-color: ${({ theme }) => theme.white};

    @media (max-width: 700px) {
        display: flex;
        place-items: center;
        justify-content: center;
    }
`;

export const Section = styled.section`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 7.5rem 0;
    min-height: calc(100vh - 80px);

    @media (max-width: 700px) {
        order: 1;
    }
`;

export const FlexRow = styled.div`
    display: flex;
`;

export const FlexColumn = styled(FlexRow)<TFlexColumn>`
    flex-direction: column;
    position: relative;
    min-width: 100%;
    gap: ${({ gap }) => gap};
`;

export const FlexRowCenter = styled(FlexRow)`
    justify-content: center;
    align-items: center;
`;

export const FlexColumnCenter = styled(FlexRowCenter)`
    flex-direction: column;
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;

    @media (max-width: 700px) {
        display: none;
    }
`;

export const H1 = styled.h1`
    font-style: normal;
    font-weight: normal;
    font-size: 2.125rem;
    line-height: 2.9375rem;
    letter-spacing: 0.0025em;
    color: ${({ theme }) => theme.purple500};
`;

export const P = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.001em;
    color: ${({ theme }) => theme.gray600};
`;

export const Aviso = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.purple400};
    margin-bottom: 3rem;
    margin-top: 1.5rem;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;
