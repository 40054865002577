import { TProject } from "../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../services/projects/types";
import { RealizedHours } from "./components/realized-hours/realized-hours";
import { RealizedScope } from "./components/realized-scope/realized-scope";
import * as s from "./styles";

type Props = {
    project: TProject;
    data: ReadDemonstrativeResult | undefined;
};

export const Projections = ({ project, data }: Props) => {
    return (
        <s.Container>
            <s.Title>Projeções</s.Title>
            <RealizedHours project={project} data={data} />
            <RealizedScope project={project} data={data} />
        </s.Container>
    );
};
