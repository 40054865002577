import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { Chart } from "react-chartjs-2";

import { theme } from "../../../../../../assets";
import { Input } from "../../../../../../components";
import { useCreateBackOfficeSocialMedia } from "../../../../../../hooks/react-query/use-backoffice-create-social-media";
import { queryClient } from "../../../../../../utils/query-client/query-client";
import { firstUpperCase } from "../../../../../../utils/string-manipulation/first-upper-case";
import { TabProps } from "../../paperon-data";
import * as s from "./styles";

dayjs.extend(localeData);

type CreateBackOfficeFollowersHistory = {
    index: number;
    e: React.ChangeEvent<HTMLInputElement>;
    media: string;
};

const MEDIAS = {
    instagram: "instagram",
    linkedin: "linkedin",
    youtube: "youtube",
};

export function ChartsTab({ data }: TabProps) {
    const mediasArray = Object.values(MEDIAS);

    const createBackOfficeSocialMedia = useCreateBackOfficeSocialMedia({
        onSuccess() {
            queryClient.invalidateQueries(["backOffice"]);
        },
    });

    const monthsOfYear = dayjs.months().map((value) => firstUpperCase(value));
    const { predictions } = data;

    function formatGrowthPercentage(percentage: number) {
        return percentage < 0
            ? `- (${Math.abs(percentage).toFixed(2)}%)`
            : `+ (${Math.abs(percentage).toFixed(2)}%)`;
    }

    function formatGrowthTotalPercentage(percentage: number) {
        return percentage < 0
            ? `- ${Math.abs(percentage).toFixed(2)}%`
            : `+ ${Math.abs(percentage).toFixed(2)}%`;
    }

    function calculateGrowthPercentage(data: number[], index: number) {
        const lastMonth = data[index - 1];
        const currentMonth = data[index];

        if (!lastMonth) return 0;
        const percentage = ((currentMonth - lastMonth) / lastMonth) * 100;

        return percentage;
    }

    const monthsGrowth = monthsOfYear.map((_, index) => {
        if (index === 0) return 0;
        return Number(
            calculateGrowthPercentage(predictions.totalUsersInMonth, index)
        );
    });

    const calculateGrowth = (array: number[], index: number) => {
        return array
            .slice(index - 2, index + 1)
            .reduce((acc, val) => acc + val, 0);
    };

    const calculateSocialMediaGrowthPercentagesByMonth = (media: string) => {
        return monthsOfYear.map((value, index) => {
            const instagramNumbers = data.socialMedias.filter(
                ({ socialMedia }) => socialMedia === media
            );
            const currentMonth = instagramNumbers.find(({ date }) => {
                return dayjs(date).isSame(dayjs().month(index), "month");
            });
            const lastMonth = instagramNumbers.find(({ date }) => {
                return dayjs(date).isSame(dayjs().month(index - 1), "month");
            });
            if (!lastMonth || !currentMonth) return 0;
            return calculateGrowthPercentage(
                [lastMonth.value, currentMonth.value],
                1
            );
        });
    };

    const calculateSocialMediaFollowersByMonth = (media: string) => {
        return monthsOfYear.map((value, index) => {
            const instagramNumbers = data.socialMedias.filter(
                ({ socialMedia }) => socialMedia === media
            );
            const currentMonth = instagramNumbers.find(({ date }) => {
                return dayjs(date).isSame(dayjs().month(index), "month");
            });
            if (!currentMonth) return 0;
            return currentMonth.value;
        });
    };

    const createBackOfficeFollowersHistory = ({
        index,
        e,
        media,
    }: CreateBackOfficeFollowersHistory) => {
        createBackOfficeSocialMedia.mutate({
            date: dayjs().month(index).startOf("month").toISOString(),
            socialMedia: media,
            value: Number(e.target.value),
        });
    };

    return (
        <s.Container>
            <h2>Relatório anual</h2>
            <table>
                <thead>
                    <tr>
                        <th>Mês</th>
                        <th>Novos usuários</th>
                        <th>Churn de usuários</th>
                        <th>Usuários totais</th>
                        <th>Usuários pagantes</th>
                        <th>Organizações</th>
                        <th>Seguidores Instagram</th>
                        <th>Seguidores LinkedIn</th>
                        <th>Seguidores YouTube</th>
                    </tr>
                </thead>
                <tbody>
                    {monthsOfYear.map((month, index) => (
                        <>
                            <tr>
                                <td key={index}>{month}</td>
                                <td>
                                    +{" "}
                                    {
                                        predictions
                                            .createdUsersInThisYearByMonth[
                                            index
                                        ]
                                    }
                                </td>
                                <td>
                                    - {predictions.usersChurnInMonths[index]}
                                </td>
                                <td>
                                    {predictions.totalUsersInMonth[index]}
                                    {index !== 0 && (
                                        <span className="percentage">
                                            {formatGrowthPercentage(
                                                calculateGrowthPercentage(
                                                    predictions.totalUsersInMonth,
                                                    index
                                                )
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    +{" "}
                                    {
                                        predictions.payingOrganizationsInMonths[
                                            index
                                        ]
                                    }
                                    {index !== 0 && (
                                        <span className="percentage">
                                            {formatGrowthPercentage(
                                                calculateGrowthPercentage(
                                                    predictions.payingOrganizationsInMonths,
                                                    index
                                                )
                                            )}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    +{" "}
                                    {
                                        predictions
                                            .createdOrganizationsInMonths[index]
                                    }
                                    {index !== 0 && (
                                        <span className="percentage">
                                            {formatGrowthPercentage(
                                                calculateGrowthPercentage(
                                                    predictions.createdOrganizationsInMonths,
                                                    index
                                                )
                                            )}
                                        </span>
                                    )}
                                </td>
                                {mediasArray.map((mappedMedia) => {
                                    const currentMedia = data.socialMedias.find(
                                        ({ socialMedia, date }) =>
                                            socialMedia === mappedMedia &&
                                            dayjs(date).isSame(
                                                dayjs().month(index),
                                                "month"
                                            )
                                    );
                                    return (
                                        <td>
                                            <div className="input-percentage">
                                                <Input
                                                    defaultValue={
                                                        currentMedia?.value
                                                    }
                                                    onBlur={(e) => {
                                                        if (!e.target.value) {
                                                            return;
                                                        }
                                                        createBackOfficeFollowersHistory(
                                                            {
                                                                e,
                                                                index,
                                                                media: mappedMedia,
                                                            }
                                                        );
                                                    }}
                                                />
                                                {index !== 0 && (
                                                    <span className="percentage">
                                                        {formatGrowthPercentage(
                                                            calculateSocialMediaGrowthPercentagesByMonth(
                                                                mappedMedia
                                                            )[index]
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                            {(index + 1) % 3 === 0 && (
                                <tr className="growth">
                                    <td>Total {(index + 1) / 3}º trim.</td>
                                    <td>
                                        +{" "}
                                        {calculateGrowth(
                                            predictions.createdUsersInThisYearByMonth,
                                            index
                                        )}
                                    </td>
                                    <td>
                                        -{" "}
                                        {predictions.usersChurnInMonths[index]}
                                    </td>
                                    <td>
                                        {formatGrowthTotalPercentage(
                                            calculateGrowth(monthsGrowth, index)
                                        )}
                                    </td>
                                    <td>
                                        +{" "}
                                        {calculateGrowth(
                                            predictions.payingOrganizationsInMonths,
                                            index
                                        )}
                                    </td>
                                    <td>
                                        +{" "}
                                        {calculateGrowth(
                                            predictions.createdOrganizationsInMonths,
                                            index
                                        )}
                                    </td>
                                    {mediasArray.map((mappedMedia) => (
                                        <td>
                                            {formatGrowthTotalPercentage(
                                                calculateGrowth(
                                                    calculateSocialMediaGrowthPercentagesByMonth(
                                                        mappedMedia
                                                    ),
                                                    index
                                                )
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </table>

            <s.ChartContainer>
                <h2>Gráfico de novos usuários</h2>
                <Chart
                    type="bar"
                    height={500}
                    data={{
                        labels: monthsOfYear,
                        datasets: [
                            {
                                type: "line",
                                backgroundColor: theme.purple500,
                                borderColor: theme.purple500,
                                data: predictions.createdUsersInThisYearByMonth,
                                label: `Usuários criados no mês`,
                            },
                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                            },
                        },
                        plugins: {
                            legend: {
                                align: "center",
                                position: "bottom",
                                fullSize: true,
                                labels: {
                                    boxWidth: 18,
                                    boxHeight: 18,
                                    pointStyle: "circle",
                                    usePointStyle: true,
                                    padding: 40,
                                    color: theme.gray800,
                                    font: {
                                        size: 14,
                                    },
                                },
                            },
                            datalabels: {
                                display: false,
                            },
                        },
                    }}
                />
            </s.ChartContainer>

            <s.ChartContainer>
                <h2>Redes sociais</h2>
                <Chart
                    type="bar"
                    height={500}
                    data={{
                        labels: monthsOfYear,
                        datasets: [
                            {
                                type: "line",
                                backgroundColor: "#DD2A7B",
                                borderColor: "#DD2A7B",
                                data: calculateSocialMediaFollowersByMonth(
                                    MEDIAS.instagram
                                ),
                                label: `Seguidores do Instagram no mês`,
                            },
                            {
                                type: "line",
                                backgroundColor: "#0077B5",
                                borderColor: "#0077B5",
                                data: calculateSocialMediaFollowersByMonth(
                                    MEDIAS.linkedin
                                ),
                                label: `Seguidores do LinkedIn no mês`,
                            },
                            {
                                type: "line",
                                backgroundColor: "#FF0000",
                                borderColor: "#FF0000",
                                data: calculateSocialMediaFollowersByMonth(
                                    MEDIAS.youtube
                                ),
                                label: `Seguidores do YouTube no mês`,
                            },
                        ],
                    }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                align: "center",
                                position: "bottom",
                                fullSize: true,
                                labels: {
                                    boxWidth: 18,
                                    boxHeight: 18,
                                    pointStyle: "circle",
                                    usePointStyle: true,
                                    padding: 40,
                                    color: theme.gray800,
                                    font: {
                                        size: 14,
                                    },
                                },
                            },
                            datalabels: {
                                display: false,
                            },
                        },
                    }}
                />
            </s.ChartContainer>
        </s.Container>
    );
}
