import React from "react";
import { useNavigate } from "react-router-dom";

import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalUserLimitReached = ({ setOpen }: Props) => {
    const navigate = useNavigate();

    const close = () => setOpen(false);
    const goToPlans = () => {
        navigate("/gerenciar-assinatura", {
            state: {
                openPlans: true,
            },
        });
    };

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Limite de usuários no plano atingido</s.Title>
                <s.TextContainer>
                    <s.Text>
                        Você atingiu o limite de usuários para seu plano. Mas
                        não se preocupe, você pode contratar um novo plano a
                        qualquer momento.
                    </s.Text>
                </s.TextContainer>
                <s.Buttons>
                    <SecondaryButton onClick={close}>Fechar</SecondaryButton>
                    <PrimaryButton onClick={goToPlans}>
                        Ir para planos
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
