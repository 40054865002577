import React, { ChangeEvent } from "react";
import Textarea from "react-expanding-textarea";
import { ChangeHandler } from "react-hook-form";

import * as s from "./styled-text-area";

type TTextArea = {
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder?: string;
    counter?: string;
    onBlur?: ChangeHandler;
    name?: string;
    label?: string;
    error?: string;
    charCount?: boolean;
    maxLength?: number;
    disabled?: boolean;
    value?: string;
    noLabel?: boolean;
    className?: string;
    classNameLabel?: string;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TTextArea>(
    (
        {
            onChange,
            counter,
            placeholder,
            onBlur,
            name,
            label,
            error,
            charCount,
            maxLength,
            disabled,
            value,
            noLabel,
            className,
            classNameLabel,
        },
        ref
    ) => {
        return (
            <s.Flex className={className || "text-area-wrapper"}>
                {!noLabel && (
                    <s.Label className={classNameLabel} htmlFor={label}>
                        {label}
                    </s.Label>
                )}
                <Textarea
                    id={label}
                    name={name}
                    ref={ref}
                    className={`text-area ${error ? "error" : ""}`}
                    placeholder={placeholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={maxLength || 350}
                    disabled={disabled}
                    value={value}
                />
                {error && <s.Error>{error || ""}</s.Error>}
                {charCount && (
                    <s.CharCount>
                        {`${counter ? counter?.length : 0}/${maxLength || 350}`}
                    </s.CharCount>
                )}
            </s.Flex>
        );
    }
);

export default TextArea;
