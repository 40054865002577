import { useState } from "react";

import { Icons, theme } from "../../../../../../assets";
import { PrimaryButton, SecondaryButton } from "../../../../../../components";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { TLockProjectHoursProps } from "../../collabs-hour-control";
import * as s from "./styled-define-lock-rules-modal";

type TDefineLockRulesModal = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    defineLockRulesModalData: {
        hourControlId: string;
        name: string;
        locked: boolean;
    };
    lockProject: ({ hourControlId }: TLockProjectHoursProps) => void;
};

export const DefineLockRulesModal = ({
    setOpen,
    defineLockRulesModalData,
    lockProject,
}: TDefineLockRulesModal) => {
    const [selected, setSelected] = useState<"flexible" | "locked">(
        defineLockRulesModalData.locked ? "locked" : "flexible"
    );

    const handleConfirm = () => {
        setOpen(false);
        if (selected === "locked" && defineLockRulesModalData.locked) return;
        if (selected === "flexible" && !defineLockRulesModalData.locked) return;
        lockProject({
            hourControlId: defineLockRulesModalData.hourControlId,
        });
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Definir regra de uso das horas previstas</s.Title>
                <s.Subtitle>
                    Selecione a regra de uso para o limite de horas previstas
                    mensais neste projeto:
                </s.Subtitle>
                <s.RadioGroupRoot value={selected}>
                    <s.RadioWrapper onClick={() => setSelected("flexible")}>
                        <s.RadioGroupItem value="flexible">
                            <s.RadioGroupIndicator />
                        </s.RadioGroupItem>
                        <s.RadioGroupItemLabel
                            selected={selected === "flexible"}
                        >
                            <p>
                                <Icons.IconLockOpen
                                    color={
                                        selected === "flexible"
                                            ? theme.purple500
                                            : theme.gray600
                                    }
                                />
                                Flexível
                            </p>
                            <span>
                                O profissional poderá trabalhar menos ou mais
                                horas do que o previsto, mantendo o cronômetro
                                sempre ativo.
                            </span>
                        </s.RadioGroupItemLabel>
                    </s.RadioWrapper>
                    <s.RadioWrapper onClick={() => setSelected("locked")}>
                        <s.RadioGroupItem value="locked">
                            <s.RadioGroupIndicator />
                        </s.RadioGroupItem>
                        <s.RadioGroupItemLabel selected={selected === "locked"}>
                            <p>
                                <Icons.iconLockClosedFill
                                    color={
                                        selected === "locked"
                                            ? theme.purple500
                                            : theme.gray600
                                    }
                                />
                                Obrigatório
                            </p>
                            <span>
                                O profissional deverá trabalhar o horário
                                previsto, sem poder ultrapassá-las, e a tag de
                                projeto será inativado ao atingir o limite das
                                horas previstas.
                            </span>
                        </s.RadioGroupItemLabel>
                    </s.RadioWrapper>
                </s.RadioGroupRoot>
                <s.Description>
                    Deseja definir para{" "}
                    <strong>{defineLockRulesModalData.name}</strong>?
                </s.Description>
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton onClick={handleConfirm}>
                        CONFIRMAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
