import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const Organizations = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Briefcase color={theme.purple500} />
                Organizações
            </generalStyles.Title>
            <generalStyles.Row>
                <generalStyles.Content>
                    <h1>Nº de organizações criadas no último mês</h1>
                    <p>
                        <span>{data.numberOfNewOrganizationsByMonth}</span>
                    </p>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>Nº de organizações criadas na última semana</h1>
                    <p>
                        <span>{data.numberOfNewOrganizationsByWeek}</span>
                    </p>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>Nº de organizações inativas</h1>
                    <p>
                        <span>{data.numberOfInactiveOrganizations}</span>
                    </p>
                </generalStyles.Content>
            </generalStyles.Row>
        </s.Container>
    );
};
