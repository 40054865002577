import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";

import { Icons, theme } from "../../../../../../assets";
import { PopoverRadix, Tooltip } from "../../../../../../components/atoms";
import { ModalChangeMaster } from "../../../../../../components/molecules/modals/modal-change-master/modal-change-master";
import { TOrganization } from "../../../../../../models";
import { TUseFetch } from "../../../../../../types/TUseFetch";
import * as s from "./styled-tab-administrators";

type TTabAdministrators = {
    orgData: TOrganization;
    setOrgData: Dispatch<SetStateAction<TOrganization>>;
    getOrganization: TUseFetch;
};

export const TabAdministrators = ({
    orgData,
    getOrganization,
}: TTabAdministrators) => {
    const [changeMaster, setChangeMaster] = useState(false);

    return (
        <>
            {changeMaster && (
                <ModalChangeMaster
                    admins={orgData.Admins}
                    selectedAdmin={orgData.Owner}
                    setOpen={setChangeMaster}
                    getOrganization={getOrganization}
                />
            )}
            <s.Container>
                <s.Title>Administrador master</s.Title>
                <s.MasterContainer>
                    <div>
                        <div className="name">
                            <span>Nome:</span> {orgData.Owner?.name}
                        </div>
                        <div className="email">
                            <span>E-mail:</span> {orgData.Owner?.email}
                        </div>
                    </div>
                    <s.Actions>
                        <s.Action
                            onClick={() => {
                                setChangeMaster(true);
                            }}
                        >
                            <Icons.Settings
                                width="18px"
                                height="18px"
                                color={theme.gray600}
                            />
                        </s.Action>
                        {orgData.OrganizationOwnerHistory &&
                            orgData.OrganizationOwnerHistory.length > 0 && (
                                <PopoverRadix
                                    trigger={
                                        <s.Action>
                                            <Icons.History
                                                width="18px"
                                                height="18px"
                                                color={theme.gray600}
                                            />
                                        </s.Action>
                                    }
                                    align="start"
                                >
                                    <s.HistoryContainer>
                                        <s.HistoryRow>
                                            <span>
                                                <strong>Alterado por</strong>
                                            </span>
                                            <span>
                                                <strong>
                                                    Administrador master
                                                </strong>
                                            </span>
                                            <span>
                                                <strong>Modificado em</strong>
                                            </span>
                                        </s.HistoryRow>
                                        {orgData.OrganizationOwnerHistory.map(
                                            (history) => (
                                                <s.HistoryRow
                                                    key={history.id_e}
                                                >
                                                    <span>
                                                        {history.User.name}
                                                    </span>
                                                    <span>
                                                        {history.Owner.name}
                                                    </span>
                                                    <span>
                                                        {dayjs(
                                                            history.created_at
                                                        ).format(
                                                            "DD/MM/YYYY [às] HH:mm"
                                                        )}
                                                    </span>
                                                </s.HistoryRow>
                                            )
                                        )}
                                    </s.HistoryContainer>
                                </PopoverRadix>
                            )}
                    </s.Actions>
                </s.MasterContainer>
                <Tooltip
                    align="start"
                    arrow
                    positiony={10}
                    content={
                        <s.Tooltip>
                            Para adicionar ou remover administradores, altere a
                            atuação do profissional dentro da aba de
                            profissionais.
                        </s.Tooltip>
                    }
                >
                    <s.Title>
                        Administradores{" "}
                        <Icons.Info width="20px" height="20px" />
                    </s.Title>
                </Tooltip>
                {orgData.Admins?.map((admin) => (
                    <s.AdministratorContainer>
                        <div className="name">
                            <span>Nome:</span> {admin.name}
                        </div>
                        <div className="email">
                            <span>E-mail:</span> {admin.email}
                        </div>
                    </s.AdministratorContainer>
                ))}
            </s.Container>
        </>
    );
};
