import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import { Switch } from "../../switch/switch";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Fields = {
    name: string;
    active: boolean;
};

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: (data: Fields) => void;
    onClose: () => void;
    loading?: boolean;
    defaultValues?: {
        name: string;
        active?: boolean;
    };
};

const schema = yup.object({
    name: yup
        .string()
        .required("Campo obrigatório")
        .max(100, "Máximo de 100 caracteres")
        .trim(),
    active: yup.bool(),
});

export function ModalUpdateClient({
    setOpen,
    onSuccess,
    onClose,
    loading,
    defaultValues,
}: Props) {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<Fields>({
        defaultValues,
        resolver: yupResolver(schema),
    });

    function submit(data: Fields) {
        onSuccess({ ...data });
    }

    return (
        <ContainerModal
            closeIcon
            onOpenChange={setOpen}
            onCloseAction={onClose}
        >
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>Editar cliente</s.Title>
                <s.Fields>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Nome"
                                onChange={onChange}
                                value={value}
                                error={errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        name="active"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <s.SwitchWrapper>
                                <Switch
                                    checked={value}
                                    onChange={onChange}
                                    scale={0.8}
                                />
                                {value ? "Ativo" : "Inativo"}
                            </s.SwitchWrapper>
                        )}
                    />
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton
                        type="button"
                        onClick={() => setOpen(false)}
                        disabled={loading}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit" loading={loading}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
}
