import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type DeleteProjectAdditionalCostParams = {
    projectId?: string;
    additionalCostId?: string;
};

export default async ({
    projectId,
    additionalCostId,
}: DeleteProjectAdditionalCostParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
        "x-additional-cost-id": additionalCostId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/additionalCost`,
        method: "delete",
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
