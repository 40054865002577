import styled from "styled-components";

type TButtonPopover = { disabled?: boolean };

type TTooltipContent = { simple?: boolean };

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 100rem;
    gap: 2rem;
`;

export const EnvirementLock = styled.div`
    display: flex;
    align-items: center;
    width: 60%;
    border: 1px solid #f2c94c;
    padding: 1rem;
    gap: 0.75rem;
    box-shadow: 0rem 0.125rem 0.5625rem rgba(8, 1, 23, 0.2);
    border-radius: 0.25rem;
`;
export const ReportAvailable = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    border: ${({ theme }) => `1px solid ${theme.green1}`};
    padding: 16px;
    gap: 0.75rem;
    border: 1px solid var(--color-state-green-done, #57e191);
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    .icon-check-circle {
        height: 1.25rem;
        width: 1.25rem;
        background-color: #57e191;
        border-radius: 10px;
    }
    color: var(--color-gray-800, #3e374e);
`;

export const TittleBarChart = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 0rem 0rem;
    color: ${({ theme }) => theme.gray600};
`;

export const TittleTable = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2rem 2rem 2rem;
    color: ${({ theme }) => theme.gray600};
`;

export const ReviewContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0.25rem;
    padding: 1rem;
    gap: 1rem;

    .header-button {
        display: flex;
        justify-content: space-evenly;
        height: 2.5rem;
        padding: 0 0.2rem;

        &:first-child {
            width: 230px;

            background-color: ${({ theme }) => theme.orangeWarning}99;
            border: 1px solid ${({ theme }) => theme.orangeWarning}99;

            transition: all 0.2s;

            &:hover {
                box-shadow: 0px 2px 12px ${({ theme }) => theme.orangeWarning}99;
                background-color: ${({ theme }) => theme.orangeWarning}70;
            }
        }

        &:last-child {
            width: 210px;

            background-color: ${({ theme }) => theme.purple500};
            border: 1px solid ${({ theme }) => theme.purple500};

            transition: all 0.2s;

            &:hover {
                box-shadow: 0px 2px 12px ${({ theme }) => theme.purple500};
                background-color: ${({ theme }) => theme.purple500}E0;
            }
        }
    }

    .icon-check-circle {
        height: 1.25rem;
        width: 1.25rem;
        background-color: ${({ theme }) => theme.white};
        border-radius: 10px;
    }
`;

export const CollaboratorInfo = styled.div`
    display: flex;
    width: 100%;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0.25rem;

    div.name {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 55%;
        height: 100%;
        padding: 1rem;
        align-items: flex-start;
        h1 {
            font-weight: 600;
            font-size: 0.75rem;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.gray400};
        }
    }
    div.otherinfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 22.5%;
        height: 100%;
        padding: 1rem;
        align-items: flex-start;
        border-left: 1px solid ${({ theme }) => theme.gray200};
        h1 {
            font-weight: 600;
            font-size: 0.75rem;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.gray400};
        }
    }
`;

export const TooltipContent = styled.div<TTooltipContent>`
    display: flex;
    flex-direction: column;
    padding: ${({ simple }) => (simple ? "5px 12px" : "20px 16px")};
    gap: 0.625rem;
    width: ${({ simple }) => !simple && "230px"};

    span.tooltip-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
    }

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div.tooltip-row {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2rem;
            height: 32px;
            border: 1px solid ${({ theme }) => theme.gray100};
            border-radius: 4px;

            svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
`;

export const ContainerPopover = styled.div`
    background: ${({ theme }) => theme.white};
    padding: 0.5rem 0;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    position: relative;
    width: 8.125rem;
`;

export const ButtonPopover = styled.button<TButtonPopover>`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.25rem 20px;
    gap: 0.75rem;
    background: ${({ theme }) => theme.white};
    cursor: pointer;

    svg {
        width: 1.5rem;
        height: 1.5rem;

        path {
            ${({ disabled, theme }) => disabled && `stroke: ${theme.gray200}`}
        }
    }

    span.button-text {
        font-weight: 400;
        font-size: 0.875rem;
        color: ${({ theme, disabled }) =>
            disabled ? theme.gray200 : theme.gray600};
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
        transition: 0.3s ease-in-out;
    }
`;

export const ButtonTable = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2rem;
    height: 2rem;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    &:hover {
        border: 1px solid ${({ theme }) => theme.pink100};
    }

    &:active {
        background: ${({ theme }) => theme.pink50};
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
        max-width: 450px;
        text-align: center;
    }
`;

export const BarChartWrapper = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    padding: 0rem 2.5rem 2.5rem;
    gap: 2.5rem;
`;

export const Activities = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    height: fit-content;

    header#select-wrapper {
        padding: 1.25rem 2.5rem;
    }
`;

export const ActivitiesTable = styled.table`
    display: flex;
    flex-direction: column;

    tr {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 0 2.5rem;
        align-items: center;
        height: 2.5rem;

        th:first-child {
            span {
                float: left;
            }
        }

        td:not(:first-child) {
            justify-self: center;
        }

        span.heading-text {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.gray400};
            font-weight: 600;
        }
    }

    tbody {
        tr {
            height: 3.75rem;
            border-top: 1px solid ${({ theme }) => theme.gray200};
        }

        tr {
            td {
                display: flex;
                gap: 1.25rem;

                span.time,
                span.percentage {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray800};
                    text-align: center;
                }

                span.description {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray600};
                }
            }
        }

        td.entry {
            span {
                font-size: 0.875rem;
            }

            span.project {
                color: #0b49ec;
                background: rgba(11, 73, 236, 0.05);
                border: 1px solid rgba(11, 73, 236, 0.1);
                border-radius: 4px;
                padding: 0 15px;
            }
        }

        tr.activity-instance {
            height: 100%;
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
            padding: 1rem 2.5rem;
            align-items: center;
            background: ${({ theme }) => theme.gray50};

            td:first-child {
                margin-left: 3.875rem;
            }
        }
    }
`;
