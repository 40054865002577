import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import { useUser } from "../../../../context";
import { minutesToStringDate } from "../../../../utils";
import * as s from "../../styled-track";

type Props = {
    workedHours: { limit: number };
};

export function HoursInMonth({ workedHours }: Props) {
    const { user } = useUser();

    const haveAdditionalHours = user.UserAdditionalHours.length > 0;
    const acceptedAdditionalHours = user.UserAdditionalHours.filter(
        ({ status }) => status === "accepted"
    );
    const haveAcceptedAdditionalHours =
        haveAdditionalHours && acceptedAdditionalHours.length > 0;

    return (
        <span>
            <s.P>Horas mensais:</s.P>
            <s.GrayP>{minutesToStringDate(workedHours.limit)}</s.GrayP>
            <Tooltip
                side="bottom"
                align="start"
                open={haveAcceptedAdditionalHours ? undefined : false}
                content={
                    <s.CommonTooltipContainer style={{ marginTop: "5px" }}>
                        Horas adicionais aprovadas pelo gestor
                    </s.CommonTooltipContainer>
                }
            >
                <div>
                    {haveAcceptedAdditionalHours && (
                        <s.GrayP>
                            (+
                            {acceptedAdditionalHours.reduce(
                                (acc, { hours }) => acc + hours,
                                0
                            )}
                            h)
                        </s.GrayP>
                    )}
                </div>
            </Tooltip>
        </span>
    );
}
