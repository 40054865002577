import styled, { keyframes } from "styled-components";

type TSize = {
    height?: string;
    scale?: string;
};

const moveCircle = keyframes`
    0% { left: 5px }
    25% { left: 34px }
    100% { left: 34px }
`;

const rotateAll = keyframes`
    0% {
        box-shadow: none;
        transform: rotate(0deg);
    }
    60% {
        box-shadow: 0px 8px 12px -5px rgba(110, 105, 122, 0.4);
        transform: rotate(0deg);
    }
    75% {
        box-shadow: none;
        transform: rotate(0deg);
    }
    100% {
        box-shadow: none;
        transform: rotate(180deg);
    }
`;

export const ContainerLoader = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;

export const Loading = styled.div<TSize>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => (height ? `${height}rem` : "18.75rem")};
    width: 66px;

    ${({ scale }) =>
        scale &&
        `
        transform: scale(${scale});
    `}

    .border {
        display: block;
        position: relative;
        height: 34px;
        width: 66px;
        border-color: ${({ theme }) => theme.gray600};
        border-radius: 78px;
        border-style: solid;
        border-width: 2px;
        box-shadow: none;
        content: "";
        animation-delay: 0s;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-name: ${rotateAll};
        animation-timing-function: linear;
    }

    .line {
        position: absolute;
        left: 6px;
        top: 7px;
        height: 16px;
        width: 50px;
        background-color: ${({ theme }) => theme.gray50};
        border-radius: 32px;
        content: "";
    }

    .circle {
        position: absolute;
        left: 34px;
        top: 3px;
        height: 24px;
        width: 24px;
        background-color: ${({ theme }) => theme.gray600};
        border-radius: 50%;
        content: "";
        animation-delay: 0s;
        animation-duration: 2.5s;
        animation-name: ${moveCircle};
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`;
