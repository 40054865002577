import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-play"}
    >
        <title>{title}</title>
        <path
            d="M19.8563 10.7138C20.8273 11.2964 20.8273 12.7036 19.8563 13.2862L9.27174 19.637C8.27196 20.2368 7 19.5167 7 18.3507L7 5.64929C7 4.48334 8.27196 3.76317 9.27175 4.36305L19.8563 10.7138Z"
            fill={color || theme.white}
        />
    </svg>
);
