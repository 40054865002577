import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    strokeWidth,
}: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-email"}
        >
            <title>{title}</title>
            <path
                id="forward_to_inbox_2"
                d="M20 5H4V16.3846C4 16.5641 4.05769 16.7115 4.17308 16.8269C4.28846 16.9423 4.43589 17 4.61538 17H13.5V18H4.61538C4.15513 18 3.77083 17.8458 3.4625 17.5375C3.15417 17.2292 3 16.8449 3 16.3846V5.61537C3 5.15512 3.15417 4.77083 3.4625 4.4625C3.77083 4.15417 4.15513 4 4.61538 4H19.3846C19.8449 4 20.2292 4.15417 20.5375 4.4625C20.8458 4.77083 21 5.15512 21 5.61537V12.5H20V5ZM19.9827 18H15.6154C15.4731 18 15.3542 17.9522 15.2587 17.8567C15.1631 17.7612 15.1154 17.6423 15.1154 17.5C15.1154 17.3577 15.1631 17.2388 15.2587 17.1433C15.3542 17.0478 15.4731 17 15.6154 17H20.0019L18.2558 15.2538C18.1583 15.1564 18.1074 15.0385 18.1029 14.9C18.0984 14.7615 18.1513 14.6436 18.2615 14.5462C18.359 14.4526 18.4769 14.4048 18.6154 14.4029C18.7538 14.401 18.8718 14.4487 18.9692 14.5462L21.3385 16.9346C21.5 17.0962 21.5808 17.2846 21.5808 17.5C21.5808 17.7154 21.5 17.9038 21.3385 18.0654L18.9692 20.4538C18.8756 20.5474 18.7587 20.592 18.6183 20.5875C18.4779 20.583 18.359 20.5321 18.2615 20.4346C18.1679 20.341 18.1212 20.2263 18.1212 20.0904C18.1212 19.9545 18.1679 19.8398 18.2615 19.7462L19.9827 18ZM4 5V17V12.5V12.6712V5ZM12 10L20 5V5.90385L12.4289 10.6366C12.2878 10.7237 12.1449 10.7673 12 10.7673C11.8551 10.7673 11.7122 10.7237 11.5712 10.6366L4 5.90385V5L12 10Z"
                fill={color || theme.gray600}
                strokeWidth={strokeWidth || "0.3"}
            />
        </svg>
    );
};
