import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({
    title,
    color,
    className,
    onClick,
    strokeWidth,
}: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="16"
        height="9"
        viewBox="0 0 16 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-collapse-arrow"}
    >
        <title>{title}</title>
        <path
            d="M15 1L8 8L1 1"
            stroke={color || theme.gray900}
            strokeWidth={strokeWidth || "1.2"}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
