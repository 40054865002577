import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { organizations, password } from "../../../../services";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Fields = { password: string };
type Error = { body: { message: string } };

type ModalDeleteOrganizationPasswordConfirmProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalDeleteOrganizationPasswordConfirm = ({
    setOpen,
}: ModalDeleteOrganizationPasswordConfirmProps) => {
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<Fields>();

    const deleteOrganization = useMutation({
        mutationKey: ["deleteOrganization"],
        mutationFn: organizations.deleteOrganization,
    });

    const validatePassword = useMutation<unknown, Error, Fields>({
        mutationKey: ["validatePassword"],
        mutationFn: password.validatePassword,
        onSuccess: () => {
            deleteOrganization.mutate();
            navigate("/");
        },
        onError: () => {
            setError("password", {
                message: "Senha incorreta.",
            });
        },
    });

    const submit = (fields: Fields) => {
        validatePassword.mutate({
            ...fields,
        });
    };

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>
                    Confirmação de senha para exclusão de organização
                </s.Title>
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input
                            type="password"
                            label="Confirme sua senha"
                            value={value}
                            onChange={onChange}
                            error={errors.password?.message}
                        />
                    )}
                />
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit">
                        Excluir organização
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
