import styled from "styled-components";

type TProfile = {
    edit: boolean;
};

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    span.tooltip {
        align-self: flex-start;
        cursor: pointer;
    }

    div.icons-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: flex-start;

        svg {
            align-self: flex-start;
        }
    }
`;

export const Profile = styled.form<TProfile>`
    display: flex;
    gap: 1rem;
    width: 100%;
`;

export const FormColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    height: 100%;
    gap: 8px;

    label {
        width: 1.75rem;
        height: 1.75rem;
        border-color: ${({ theme }) => theme.gray500};
        position: relative;

        svg {
            position: absolute;
            top: 1px;
            left: 1px;
            transform: scale(0.75);
        }
    }

    .camera-icon {
        position: relative;
        align-self: center;
        margin: 0 auto 0 1.75rem;

        svg {
            transform: scale(1.05);
            position: absolute;
            border: 1px solid ${({ theme }) => theme.gray500};
            border-radius: 50%;
            padding: 2px;
            padding-top: 3px;
            top: 56px;
            left: 56px;
            background-color: ${({ theme }) => theme.gray50};
            cursor: pointer;
        }
    }

    img {
        border-radius: 50%;
        max-width: 8.875rem;
        max-height: 8.875rem;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    div.contact-field {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        span.field-title {
            font-size: 0.75rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray400};
        }

        span.field-description {
            font-size: 1rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray900};
        }

        div.input-wrapper {
            height: 36px;

            input {
                height: 36px;
            }
        }

        div.select-input {
            height: 36px;
        }

        div#tip-field {
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const TipCep = styled.a`
    position: absolute;
    right: 0;
    bottom: -16px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.65rem;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.gray800};
    z-index: 100;
    cursor: pointer;
`;
