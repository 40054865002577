import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { Auth } from "./Authentication";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sessions/clear`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpRequest;
    const auth = Auth.getInstance();
    auth.logout();

    return handleStatusCases({ body, statusCode });
};
