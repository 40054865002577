import { useState } from "react";

import ContainerModal from "../container-modal";
import { ChangePassword } from "./components/change-password";
import { ChangePasswordSuccess } from "./components/success";

type TModalChangePassword = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalChangePassword = ({ setOpen }: TModalChangePassword) => {
    const [isToChangePassword, setChangePassword] = useState<boolean>(true);

    return (
        <ContainerModal closeIcon onCloseAction={() => setOpen(false)}>
            {isToChangePassword ? (
                <ChangePassword setChangePassword={setChangePassword} />
            ) : (
                <ChangePasswordSuccess setOpen={setOpen} />
            )}
        </ContainerModal>
    );
};
