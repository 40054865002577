import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled from "styled-components";

import { theme } from "../../../../assets";

type TContainer = {
    read: boolean;
};

type TInput = {
    haveFile?: boolean;
    fileError?: boolean;
};

type TInputWrapper = {
    color?: string | null;
};

export const Container = styled.div<TContainer>`
    display: flex;
    flex-direction: column;
    width: 510px;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    height: auto;

    header {
        display: flex;
        height: 40px;
        background: ${({ theme, read }) =>
            read ? theme.purple50 : theme.gray50};
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 4px 4px 0 0;

        h1 {
            font-weight: 600;
            font-size: 0.875rem;
            letter-spacing: 0.005em;
            color: ${({ theme }) => theme.gray800};
        }
    }

    form#content {
        display: flex;
        flex-direction: column;
        padding: 24px 16px 32px;
        gap: 24px;

        div.input-file {
            display: flex;
            flex-direction: column;
            gap: 4px;

            span.field-name {
                font-size: 1rem;
                font-weight: 500;
                color: ${({ theme }) => theme.gray800};
                margin-bottom: 0.5rem;
            }

            div.only-pdf {
                color: ${({ theme }) => theme.gray600};
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }

            span#mandatoryInfo {
                color: ${({ theme }) => theme.purple500};
                font-size: small;
                padding-bottom: 1.5rem;
                font-weight: 500;
            }

            span.field-value {
                font-size: 0.8rem;
                font-weight: 700;
                color: ${({ theme }) => theme.gray600};
                margin-bottom: 1.5rem;
            }

            span#email-tip {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.gray600};
                margin-bottom: 0.5rem;
            }
        }

        div#buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 1.25rem;

            button {
                width: 100%;
                height: 40px;
            }
        }
    }
    div#informacoes {
        margin: 24px 16px 0px;
        display: flex;
        flex-direction: column;
        border: ${({ theme }) => theme.gray200} solid 1px;
        padding: 2%;
        font-family: noto-sans, sans-serif;
        font-size: 0.8rem;
        border-radius: 5px;
        gap: 1rem;
        span {
            letter-spacing: 0.1px;
        }
        span#atributo {
            color: ${({ theme }) => theme.gray600};
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.08px;
        }
        span#info {
            color: ${({ theme }) => theme.gray600};
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.024px;
        }
    }
`;

export const ScrollWrapper = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    max-height: 150px;

    div.scroll-documents {
        padding: 16px;
    }
`;

export const InputWrapper = styled.div<TInputWrapper>`
    display: flex;
    flex-direction: column;

    div.input-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        span.document-number {
            font-size: 0.75rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray600};
        }

        button.minus {
            display: flex;
            align-items: center;
            font-size: 0.625rem;
            font-weight: 400;
            color: ${({ theme }) => theme.purple500};
        }
    }

    input {
        height: 40px;
        border: 1px solid ${({ color }) => color};
        color: ${({ color, theme }) =>
            color === theme.purple200 && theme.purple500};
    }

    div.input-heading:not(:first-of-type) {
        margin-top: 16px;
    }
`;

export const ReadOnlyDocument = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 16px;

    span.document-number {
        font-size: 0.75rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
    }

    span.document-value {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.purple500};
    }
`;

export const WrapperCertifications = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;

    span#validated {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.purple500};
    }

    span#no-alteration {
        font-size: 10px;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const DocumentWrapper = styled.div<TInput>`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span#error {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.01em;
        color: ${({ theme }) => theme.red};
        height: 0.5rem;
    }

    .select-input-projects {
        .react-select__control {
            border: 1px solid #cfcdd3;
        }
        .react-select__placeholder {
            color: ${({ theme }) => theme.gray400};
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.08px;
        }
    }
`;

export const ButtonInput = styled.button<TInput>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.gray600};
    gap: 0.5rem;

    height: 40px;
    border-radius: 4px;
    padding-right: 0.625rem;
    border: 1px solid
        ${({ theme, fileError }) =>
            fileError ? theme.redError : theme.gray200};
    padding: 0 16px;

    svg {
        min-width: 24px;
        height: 24px;
    }

    span {
        color: ${({ theme }) => theme.gray300};
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;
    }
`;

export const EmailInputWrapper = styled.div<TInput>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.gray600};
    gap: 0.5rem;

    height: 40px;
    border-radius: 4px;
    padding-right: 0.625rem;
    border: 1px solid
        ${({ theme, fileError }) =>
            fileError ? theme.redError : theme.gray200};
    padding: 0 16px;

    .input-email {
        width: 100%;
    }

    svg {
        min-width: 24px;
        height: 24px;
    }

    button {
        display: flex;
        align-items: center;
    }
`;

export const DocumentList = styled.ul<TInput>`
    li.file-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .file-name {
            color: ${({ theme }) => theme.purple400};
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.08px;
            cursor: default;
            min-width: 20%;
            list-style-type: none;
            color: ${({ theme }) => theme.purple500};
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        button {
            display: flex;
            align-items: center;
        }
    }

    li.file-item:hover {
        border-radius: 4px;
        background: #f3f3f4;
        box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    }
`;

export const Loading = styled.div`
    width: 400px;
    height: 350px;
    padding: 20px;
`;

export const Scroll = styled(ScrollArea.Root)`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: "hidden";
`;

export const Viewport = styled(ScrollArea.Viewport)`
    width: 100%;
    max-height: 85px;
`;

export const Scrollbar = styled(ScrollArea.Scrollbar)`
    border-radius: 10px;
    display: "flex";
    padding: 2px;
    background-color: ${theme.pink50};
    transition: ${theme.pink50} 160ms ease-out;
    &:hover {
        background-color: ${theme.pink100};
    }
    &[data-orientation="vertical"] {
        width: 10px;
    }
`;

export const Thumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background-color: ${theme.purple600};
    border-radius: 10px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: "50%";
        left: "50%";
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44;
        min-height: 44;
    }
`;
