type TVisaCard = {
    width?: string;
    height?: string;
};

export default ({ width, height }: TVisaCard) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "24"}
            height={height || "16"}
            viewBox="0 0 24 16"
            fill="none"
        >
            <rect width="24" height="16" rx="2" fill="white" />
            <rect
                x="0.25"
                y="0.25"
                width="23.5"
                height="15.5"
                rx="1.75"
                stroke="black"
                stroke-opacity="0.12"
                strokeWidth="0.5"
            />
            <path
                d="M12.3092 6.91817C12.2989 7.75395 13.0307 8.22038 13.5821 8.49768C14.1485 8.78222 14.3388 8.96466 14.3366 9.21908C14.3323 9.60852 13.8848 9.78036 13.4659 9.78706C12.7351 9.79877 12.3103 9.58341 11.9724 9.42049L11.7092 10.692C12.0481 10.8533 12.6756 10.9939 13.3264 11C14.8539 11 15.8533 10.2217 15.8587 9.01488C15.8646 7.48336 13.8064 7.39855 13.8204 6.71397C13.8253 6.50642 14.0172 6.28492 14.4377 6.22857C14.6458 6.20011 15.2203 6.17835 15.8717 6.488L16.1273 5.25776C15.7771 5.12609 15.3268 5 14.7663 5C13.3286 5 12.3173 5.78892 12.3092 6.91817ZM18.5839 5.10601C18.305 5.10601 18.0699 5.27394 17.965 5.53171L15.783 10.9096H17.3094L17.6132 10.0431H19.4785L19.6547 10.9096H21L19.826 5.10601H18.5839ZM18.7974 6.6738L19.2379 8.85308H18.0315L18.7974 6.6738ZM10.4585 5.10601L9.2553 10.9096H10.7098L11.9124 5.10601H10.4585ZM8.30671 5.10601L6.79275 9.05617L6.18035 5.69741C6.10846 5.32248 5.8247 5.10601 5.50958 5.10601H3.03459L3 5.2745C3.50808 5.38832 4.08534 5.57188 4.43505 5.76827C4.64909 5.88823 4.71017 5.99312 4.78043 6.27822L5.94036 10.9096H7.47757L9.83418 5.10601H8.30671Z"
                fill="#254AA5"
            />
        </svg>
    );
};
