import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type CreateVoucherParams = {
    name?: string;
    description?: string;
    type?: "daysFree";
    value?: number;
    active?: boolean;
};

export default async ({
    active,
    description,
    name,
    type,
    value,
}: CreateVoucherParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/vouchers/`,
        method: "post",
        headers: {
            auth: true,
        },
        body: {
            active,
            description,
            name,
            type,
            value,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
