import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Error = "alguma coisa";

const { REACT_APP_BASE_URL } = process.env;

type TContactForBusinessPlan = {
    name?: string;
    email?: string;
    phone?: string;
    numberOfUsers?: string;
    message?: string;
};

export default async ({
    email,
    message,
    name,
    numberOfUsers,
    phone,
}: TContactForBusinessPlan = {}) => {
    const tempHeaders = {
        auth: true,
    };

    const bodyParams = {
        name,
        email,
        phone,
        numberOfUsers,
        message,
    };

    if (!bodyParams?.phone) delete bodyParams.phone;
    if (!bodyParams?.message) delete bodyParams.message;

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/contactForBusinessPlan`,
        method: "post",
        headers: tempHeaders,
        body: bodyParams,
    });

    const { body, statusCode } = httpResponse;

    const error: Error =
        body?.message?.info ||
        body?.message?.name?.[0] ||
        body?.message?.name?.[0] ||
        body?.message;

    const errorsMapper = {
        "alguma coisa": "mensagem para aparecer no toast",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Erro!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
