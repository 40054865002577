import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TCancelSubscription = {
    reason?: string;
};

export default async ({ reason }: TCancelSubscription = {}) => {
    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/cancel`,
        method: "patch",
        headers: tempHeaders,
        body: {
            reason,
        },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
