import styled from "styled-components";

type TTriangle = {
    open?: boolean;
};

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 2.625rem;
    padding: 0 0.3125rem;
    border: 1px solid ${({ theme }) => theme.gray400};
    border-radius: 2px;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.gray600};
`;

export const Triangle = styled.span<TTriangle>`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.gray600};
    transition: all 0.5s ease;

    ${({ open }) => open && `transform: rotate(-180deg)`}
`;
