import { useState } from "react";

import { Icons, IlustracaoTelaVazia } from "../../../assets";
import {
    AlertAction,
    LoadingGray,
    ModalInviteSingle,
    Pagination,
    PopoverProjectList,
    PopoverRadix,
    PopoverSectorList,
    Tooltip,
} from "../../../components";
import { TUseFetch } from "../../../types/TUseFetch";
import { formatToBRMoneyWithoutMoneySign } from "../../../utils";
import { TSketch, TSketchData } from "../collaborators-invite";
import * as s from "../styled-collaborators-invite";

type TTabSketch = {
    getSketches: TUseFetch;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    sketchData?: TSketchData;
    filteredSketches?: TSketch[];
    sendSketch: (sketchIds: string[]) => void;
    deleteSketch: (sketchIds: string[]) => void;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    getSketchesByPage: (page?: number) => void;
};

export const TabSketches = ({
    getSketches,
    loading,
    sketchData,
    filteredSketches,
    sendSketch,
    deleteSketch,
    page,
    getSketchesByPage,
}: TTabSketch) => {
    const [openSingleInvite, setOpenSingleInvite] = useState(false);
    const [selectedSketch, setSelectedSketch] = useState<TSketch>();

    const renderSketchStatus = (status: string) => {
        if (status === "valid") return "Correto";
        return "Incorreto";
    };

    const renderSketches = () => {
        if (!loading && filteredSketches && filteredSketches.length > 0) {
            return filteredSketches?.map((sketch: TSketch, index: number) => (
                <div className="content-row" key={index}>
                    <div
                        style={{
                            color: sketch.name.isValid ? "inherit" : "red",
                        }}
                    >
                        {sketch.name.value}
                    </div>
                    <div
                        style={{
                            color: sketch.email.isValid ? "inherit" : "red",
                        }}
                    >
                        {sketch.email.value}
                    </div>
                    <div>{sketch.contract_format}</div>
                    <div>
                        {sketch.value === "0"
                            ? `0,00`
                            : formatToBRMoneyWithoutMoneySign(
                                  Number(sketch.value)
                              )}
                    </div>
                    <div>{sketch.role}</div>
                    <div>
                        <Tooltip
                            side="bottom"
                            disabled={!sketch.sectors.length}
                            content={
                                <PopoverSectorList
                                    sectors={sketch?.sectors.map(
                                        (sector) => sector.name
                                    )}
                                />
                            }
                        >
                            <s.Pill disabled={!sketch.sectors.length}>
                                <p>
                                    <Icons.Template />
                                </p>
                            </s.Pill>
                        </Tooltip>
                    </div>
                    <Tooltip
                        side="bottom"
                        disabled={!sketch.projects.length}
                        content={
                            <PopoverProjectList
                                projects={sketch?.projects.map((project) => ({
                                    name: project.name || "",
                                    color: project.color || "",
                                }))}
                            />
                        }
                    >
                        <s.Pill disabled={!sketch.projects.length}>
                            <p>
                                <Icons.Tag />
                            </p>
                        </s.Pill>
                    </Tooltip>

                    <div className="center">
                        <s.Status $status={sketch.status}>
                            {renderSketchStatus(sketch.status)}
                        </s.Status>
                    </div>

                    <PopoverRadix
                        side="bottom"
                        sideOffset={5}
                        align="end"
                        trigger={
                            <span className="trigger">
                                <Icons.More />
                            </span>
                        }
                    >
                        <s.ContainerPopover>
                            {sketch?.status === "valid" && (
                                <>
                                    <s.ButtonPopover
                                        onClick={() =>
                                            sendSketch([sketch.id_e])
                                        }
                                    >
                                        <Icons.EmailForward />
                                        <span className="button-text">
                                            Enviar convite
                                        </span>
                                    </s.ButtonPopover>
                                </>
                            )}

                            <s.ButtonPopover
                                onClick={() => {
                                    setSelectedSketch(sketch);
                                    setOpenSingleInvite(true);
                                }}
                            >
                                <Icons.PencilOutline />
                                <span className="button-text">
                                    Editar dados
                                </span>
                            </s.ButtonPopover>

                            <AlertAction
                                onClickBtn={() => deleteSketch([sketch.id_e])}
                                trigger={
                                    <s.ButtonPopover>
                                        <Icons.Trash />
                                        <span className="button-text">
                                            Deletar
                                        </span>
                                    </s.ButtonPopover>
                                }
                                description={`Você deseja deletar o rascunho selecionado?`}
                                title={"Deletar rascunhos"}
                                textBtn={"Deletar"}
                            />
                        </s.ContainerPopover>
                    </PopoverRadix>
                </div>
            ));
        }

        if (!loading && filteredSketches?.length === 0) {
            return (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Não existem rascunhos enviados</h2>
                    <p>
                        Clique em <strong>adicionar</strong> para convidar
                        profissionais
                    </p>
                </s.NoContent>
            );
        }

        return <LoadingGray />;
    };

    return (
        <>
            {openSingleInvite && (
                <ModalInviteSingle
                    setOpen={setOpenSingleInvite}
                    getSketches={getSketches}
                    sketchData={selectedSketch}
                    editSketch
                />
            )}
            <s.TabContent>
                <div>
                    <div className="invites-title">
                        <div>Nome</div>
                        <div>E-mail</div>
                        <div>
                            Formato de
                            <br />
                            contratação
                        </div>
                        <div>Valor R$</div>
                        <div>Atuação</div>
                        <div className="center">Setor</div>
                        <div className="center">Projeto</div>
                        <div className="center">Status</div>
                    </div>
                    <div className="invites-content">{renderSketches()}</div>
                </div>
                <div className="pagination">
                    <Pagination
                        id="footer-pagination"
                        page={page - 1}
                        totalPages={sketchData?.total_pages || 1}
                        disabled={getSketches.loading}
                        onClick={(page: { selected: number }) => {
                            getSketchesByPage(page.selected + 1);
                        }}
                    />
                </div>
            </s.TabContent>
        </>
    );
};
