import * as yup from "yup";

import { validationErrors } from "../../../../utils";

const { name, client } = validationErrors;

const accentedCharacters = "çÇàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕ";

export default yup.object().shape({
    name: yup
        .string()
        .required(name.required)
        .min(3, name.minChars)
        .matches(
            new RegExp(`^[a-zA-Z${accentedCharacters}0-9\\s-()]{3,}$`),
            "Favor preencher com valores válidos (Letras e/ou números)"
        ),
    client: yup
        .object()
        .shape({
            label: yup.string().required(client.required),
        })
        .nullable(),
    description: yup.string(),
    technologies: yup.string(),
    start_at: yup.string().notRequired().nullable(),
    end_at: yup.string(),
    estimatedHours: yup
        .string()
        .test(
            "scopePercentage",
            "O valor deve ser maior ou igual a zero",
            (value) => {
                if (value !== undefined && value !== null && value !== "") {
                    const valueNumber = Number(value);
                    return valueNumber >= 0;
                }
                return true;
            }
        ),
    scopePercentage: yup
        .string()
        .nullable()
        .test(
            "scopePercentage",
            "O valor deve estar entre 0 e 100",
            (value) => {
                if (value !== undefined && value !== null && value !== "") {
                    const valueNumber = Number(value);
                    return valueNumber >= 0 && valueNumber <= 100;
                }
                return true;
            }
        ),
});
