import { useMutation } from "@tanstack/react-query";

import { TAuthResponse } from "../../models";
import { OAuth } from "../../services";
import { ReactQueryHookParams } from "./_index";

export function useAuthenticateWithGoogle(
    params: ReactQueryHookParams<TAuthResponse> = {}
) {
    return useMutation({
        mutationKey: ["authenticateWithGoogle"],
        mutationFn: OAuth,
        ...params,
    });
}
