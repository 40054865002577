import { TTrackReadBody } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    startDate,
    endDate,
    responseFormat,
    page,
    length,
    projectId,
    clientId,
    all,
    reportId,
    searchTerm,
}: TTrackReadBody = {}) => {
    const params = {
        startDate,
        endDate,
        responseFormat,
        page,
        length,
        all,
        searchTerm,
    };

    const tempHeaders = {
        auth: true,
        "x-project-id": projectId,
        "x-client-id": clientId,
        "x-report-id": reportId,
    };

    if (!projectId) delete tempHeaders["x-project-id"];
    if (!clientId) delete tempHeaders["x-client-id"];
    if (!reportId) delete tempHeaders["x-report-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/read`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpResponse;
    const errorFeedBacks = {
        401: "Acesso negado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
