import styled from "styled-components";

import { theme } from "../../assets";

type TSection = {
    gap?: string;
};

export const Main = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(448px, 1fr));
    height: calc(100vh - 80px);
    overflow: hidden;
    gap: 6.5rem;
    background-color: ${({ theme }) => theme.white};

    @media (max-width: 1000px) {
        overflow-y: auto;
    }
`;

export const Section = styled.section<TSection>`
    display: flex;
    gap: ${({ gap }) => gap};

    @media (max-width: 1000px) {
        order: 2;
    }
`;

export const SectionColumn = styled(Section)`
    flex-direction: column;
    width: 440px;
    justify-content: center;

    @media (max-width: 1000px) {
        width: 100vw;
        padding: 20px;
        order: 1;
    }
`;
export const FlexColumn = styled(SectionColumn).attrs({ as: "div" })``;
export const FlexColumnCenter = styled(FlexColumn)`
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const FlexRowCenter = styled(FlexColumnCenter)`
    flex-direction: row;
`;
export const FlexRow = styled(FlexRowCenter)`
    justify-content: flex-start;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.125rem;

    @media (max-width: 1000px) {
        input {
            height: 40px;
            font-size: 13px;
        }

        p {
            font-size: 11px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        label {
            font-size: 10px;
        }

        button {
            font-size: 11px;
        }
    }
`;

export const H1 = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 2.125rem;
    line-height: 2.9375rem;
    letter-spacing: 0.25%;
    font-weight: normal;
    font-style: normal;

    @media (max-width: 1000px) {
        margin-top: 5rem;
        text-align: center;
    }
`;
export const Img = styled.img`
    width: 100%;
    object-fit: cover;

    @media (max-width: 1000px) {
        display: none;
    }
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const A = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.gray800};

    @media (max-width: 1000px) {
        font-size: 11px;
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};

    @media (max-width: 1000px) {
        font-size: 11px;
    }
`;
export const Paragrafo = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.001em;
    color: ${({ theme }) => theme.gray600};
`;
export const Container = styled.div`
    height: 600px;
    margin-top: 50px;

    @media (max-width: 1000px) {
        height: fit-content;
    }
`;

export const Google = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    p {
        color: ${theme.textPrimary};
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.36px;
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        grid-column-gap: 1.2rem;
        align-items: center;

        @media (max-width: 1000px) {
            font-size: 14px;
        }

        &::after,
        &::before {
            content: "";
            display: block;
            height: 1px;
            background-color: ${theme.purple50};
            width: 180px;

            @media (max-width: 1000px) {
                width: 37vw;
            }
        }
    }
`;
