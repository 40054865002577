import { styled } from "styled-components";

export const Container = styled.tr`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    border-top: 1px solid ${({ theme }) => theme.gray200};
    height: 56px;

    .more-menu {
        visibility: hidden;
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: ${({ theme }) => theme.purple50};

        .more-menu {
            visibility: visible;
        }
    }
`;

export const CheckboxCell = styled.td``;

export const CheckboxWrapper = styled.span<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    div {
        &:hover {
            box-shadow: ${({ disabled }) =>
                disabled
                    ? "none"
                    : "0px 0px 0.5rem 0.10rem rgba(102, 51, 152, 0.15)"};
        }
    }

    #all-reviewer,
    #all-biller {
        pointer-events: none;
    }

    display: flex;
    align-items: center;
    gap: 11px;

    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const LoadingWrapper = styled.div`
    img {
        -webkit-animation: spin 1s linear infinite;
        -moz-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        -webkit-filter: invert(100%);
        filter: invert(100%);
        width: 20px;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;
