import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TGetAllUsers = {
    searchPermission?: string;
    sectors?: boolean;
};

export default async ({ searchPermission, sectors }: TGetAllUsers = {}) => {
    const params = {
        searchPermission,
        sectors,
    };
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/all`,
        method: "get",
        headers: { auth: true },
        params,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
