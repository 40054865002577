import * as Dialog from "@radix-ui/react-dialog";
import { useQuery } from "@tanstack/react-query";

import { activities } from "../../../../../services";
import { TSectionActivity } from "../types";
import { Attachments } from "./components/attachments/attachments";
import { Checklist } from "./components/checklist/checklist";
import { CollaboratorsHours } from "./components/collaborators-hours/collaborators-hours";
import { GeneralInformation } from "./components/general-information/general-information";
import { Header } from "./components/header/header";
import { Hours } from "./components/hours/hours";
import { Options } from "./components/options/options";
import * as s from "./styles";
import { ActivityDTO } from "./types/activityDTO";
import { ReadDetailedActivityResponse } from "./types/readDetailedActivityResponse";

type TActivityDetailsDialog = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    activity: TSectionActivity;
    refetchActivity: () => void;
};

export const ActivityDetailsDialog = ({
    open,
    setOpen,
    activity,
    refetchActivity,
}: TActivityDetailsDialog) => {
    const { data: detailedActivity } = useQuery<ReadDetailedActivityResponse>({
        queryKey: ["readActivities", activity.id_e],
        queryFn: () =>
            activities.readActivityDetails({ activityId: activity.id_e }),
        enabled: open,
    });

    const generateActivity = (
        detailedActivity: ReadDetailedActivityResponse
    ): ActivityDTO => {
        return {
            ...detailedActivity,
            hourProgress: activity.hourProgress,
            Users: Object.values(activity.Users).map((user) => user.User),
        };
    };

    return (
        <Dialog.Root
            open={open}
            onOpenChange={(open) => {
                setOpen(open);
                refetchActivity();
            }}
        >
            {detailedActivity && (
                <Dialog.Portal>
                    <s.StyledOverlay />
                    <s.StyledContent className={open ? "open" : "closed"}>
                        <Options
                            close={() => {
                                setOpen(false);
                                refetchActivity();
                            }}
                            activity={generateActivity(detailedActivity)}
                        />
                        <Header activity={generateActivity(detailedActivity)} />
                        <s.Divider />
                        <GeneralInformation
                            activity={generateActivity(detailedActivity)}
                        />
                        <Hours activity={generateActivity(detailedActivity)} />
                        <s.Divider />
                        <Attachments
                            activity={generateActivity(detailedActivity)}
                        />
                        <s.Divider />
                        <Checklist
                            activity={generateActivity(detailedActivity)}
                        />
                        <s.Divider />
                        <CollaboratorsHours
                            activity={generateActivity(detailedActivity)}
                        />
                    </s.StyledContent>
                </Dialog.Portal>
            )}
        </Dialog.Root>
    );
};
