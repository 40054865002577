import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 10px;

    button {
        margin-top: 30px;
    }
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.purple500};
    margin-bottom: 30px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
`;
