import React from "react";

import * as s from "./styles";

type TabsProps<T = any> = {
    tabs: T[];
    activeTab: T;
    setActiveTab: React.Dispatch<React.SetStateAction<T>>;
};

export const TabPicker = ({ tabs, activeTab, setActiveTab }: TabsProps) => {
    return (
        <s.Container>
            {tabs.map((tab) => (
                <s.Tab
                    onClick={() => setActiveTab(tab)}
                    key={tab}
                    active={tab === activeTab}
                >
                    <p title={tab}>{tab}</p>
                    <s.Line active={tab === activeTab} />
                    <s.LineBackground />
                </s.Tab>
            ))}
        </s.Container>
    );
};
