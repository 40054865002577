import { useState } from "react";

import { Icons } from "../../assets";

export type TFormInputs = {
    email: string;
    password: string;
};

type TForm = {
    id: keyof TFormInputs;
    label: string;
    icon: JSX.Element;
    placeholder: string;
    type?: string;
};

export default () => {
    const [isVisible, setIsVisible] = useState(false);
    const form: TForm[] = [
        {
            label: "E-mail",
            id: "email",
            icon: <Icons.Email />,
            placeholder: "Digite aqui seu e-mail",

            type: "text",
        },
        {
            type: isVisible ? "text" : "password",
            label: "Senha",
            id: "password",
            icon: !isVisible ? (
                <Icons.Eye onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <Icons.SenhaFechado onClick={() => setIsVisible(!isVisible)} />
            ),
            placeholder: "Digite aqui sua senha",
        },
    ];
    return form;
};
