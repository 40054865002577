import styled from "styled-components";

export const AddActivityButton = styled.div`
    gap: 8px;
    align-items: center;
    display: flex;
    padding: 18px 48px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
        background: ${({ theme }) => theme.pink50};
    }

    svg {
        scale: 0.9;
    }
`;

export const AddSectionContainer = styled.form`
    background: ${({ theme }) => theme.pink50};
    padding: 24px 64px;
    gap: 20px;

    div {
        width: fit-content;

        input {
            display: flex;
            justify-content: flex-start;
            width: 600px;
        }
    }
`;

export const ActivityContainer = styled.form`
    background: ${({ theme }) => theme.pink50};
    padding: 24px 71px;
    gap: 20px;
    min-height: 230px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 350px !important;
    margin-top: 20px;
`;

export const AddSectionButton = styled.div`
    padding: 24px 48px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: ${({ theme }) => theme.gray800};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
    transition: background 0.3s ease;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.pink50};
    }

    svg {
        scale: 1.2;
    }
`;

export const ActivityContent = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 35% 12% 12% 13% 18%;
    align-items: center;
    gap: 1%;
`;

export const ActivityName = styled.div`
    input {
        max-width: 100% !important;
    }
`;

export const PredictedHours = styled.div`
    display: flex;
    align-items: center;

    span {
        color: ${({ theme }) => theme.gray600};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.12px;
    }

    input {
        max-width: 100px !important;
    }
`;

export const Priority = styled.div`
    margin-top: -10px;

    .react-select__menu {
        .react-select__option--is-selected {
            background-color: ${({ theme }) => theme.gray100};
        }
        .react-select__option:hover {
            background-color: ${({ theme }) => theme.gray100};
        }
    }

    span {
        margin-bottom: -2px;
        font-size: 25px;
    }
`;

export const PriorityOption = styled.div<{ color?: string }>`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${({ color }) => color};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.07px;
`;

export const Deadline = styled.div``;
