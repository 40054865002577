import styled from "styled-components";

type TInviteCard = {
    selected: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 544px;
    height: 414px;
    padding: 1rem;
    gap: 2rem;

    span#modal-title {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
        line-height: 24px;
    }

    p#modal-description {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
    }

    div#invite-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    button {
        height: 48px;
    }
`;

export const InviteCard = styled.div<TInviteCard>`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.625rem;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid white;
    height: 172px;
    width: 240px;

    cursor: pointer;

    span.card-title {
        font-size: 0.75rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple800};
    }

    p.card-description {
        font-size: 0.75rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray800};
    }

    &:hover {
        transition: all ease-in-out 0.3s;

        border: 1px solid ${({ theme }) => theme.gray200};
        box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
        opacity: 1;
    }

    ${({ selected, theme }) =>
        selected &&
        `transition: all ease-in-out 0.3s; border: 1px solid ${theme.gray200}; box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);`}
`;
