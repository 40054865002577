import * as s from "../../styles";

type MissionChallengesContainerProps = {
    children: React.ReactNode;
};

export const MissionChallengesContainer = ({
    children,
}: MissionChallengesContainerProps) => {
    return <s.ChallengesContainer>{children}</s.ChallengesContainer>;
};
