import { Root } from "@radix-ui/react-checkbox";
import styled from "styled-components";

export const Label = styled.label``;

export const CheckBox = styled(Root)`
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${({ theme, checked }) =>
        checked ? theme.purple500 : theme.white};

    border: 0.075rem solid ${({ theme }) => theme.purple500};
    box-sizing: border-box;
    border-radius: 2px;

    :hover {
        box-shadow: 0px 0px 6px 1px rgba(102, 51, 152, 0.15);
    }

    span {
        display: flex;
        align-items: center;
    }

    svg {
        width: 18px;
        height: 18px;
    }
`;

export const Indeterminate = styled.span`
    font-size: 1.3rem;
    margin-bottom: 3px;
    color: ${({ theme }) => theme.white};
`;
