import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 16px;
    width: 472px;
    height: fit-content;
    background-color: #fff;

    section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 32px;
        width: 100%;

        header {
            display: flex;
            gap: 8px;
            align-items: center;
            flex-direction: row;
            width: 100%;
            h1 {
                color: #d32f2f;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.07px;
            }
        }

        p {
            color: ${({ theme }) => theme.gray800};
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }

        p#paperonExperience {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 20px;
        }

        button {
            width: 100%;
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 4px;
            border: 1.2px solid ${({ theme }) => theme.purple500};
            background: #fff;
        }
    }
`;
