import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import {
    DayPicker,
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../../components";
import ContainerModal from "../../../../../../../../components/molecules/modals/container-modal";
import { nps } from "../../../../../../../../services";
import * as s from "./styles";

type Fields = {
    title: string;
    description: string;
    startDate: Date;
    endDate: Date;
};

type CreateNPSProps = {
    setIsToCreate: React.Dispatch<React.SetStateAction<boolean>>;
};

const schema = yup.object().shape({
    title: yup
        .string()
        .required("Título é obrigatório")
        .min(3, "O título deve conter no mínimo 3 caracteres")
        .max(100, "O título deve conter no máximo 100 caracteres")
        .matches(/^(?!\s+$).*/g, "título inválido"),
    description: yup
        .string()
        .required("Descrição é obrigatório")
        .min(3, "A descrição deve conter no mínimo 3 caracteres")
        .max(300, "A descrição deve conter no máximo 300 caracteres")
        .matches(/^(?!\s+$).*/g, "Descrição inválida"),
    startDate: yup.date().required("Data de início é obrigatória"),
    endDate: yup.date().required("Data final é obrigatória"),
});

export const CreateNPS = ({ setIsToCreate }: CreateNPSProps) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<Fields>({
        resolver: yupResolver(schema),
    });

    const createNPS = useMutation({
        mutationKey: ["createNPS"],
        mutationFn: nps.createNps,
        onSuccess: () => {
            toast.success("NPS criado com sucesso!");
            setIsToCreate(false);
        },
    });

    const submit = (fields: Fields) => {
        createNPS.mutate({
            name: fields.title,
            description: fields.description,
            start_date: fields.startDate,
            end_date: fields.endDate,
        });
    };

    return (
        <ContainerModal closeIcon onOpenChange={setIsToCreate}>
            <s.Container
                onSubmit={handleSubmit(submit)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit(submit)();
                    }
                }}
            >
                <s.Title>Criar NPS</s.Title>
                <s.Fields>
                    <Controller
                        name="title"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Título"
                                error={errors.title?.message}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Descrição"
                                error={errors.description?.message}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <s.SideBySideFields>
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DayPicker
                                    onSelect={onChange}
                                    label="Data de início"
                                    error={errors.startDate?.message}
                                    value={value}
                                />
                            )}
                        />
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DayPicker
                                    onSelect={onChange}
                                    label="Data final"
                                    value={value}
                                    error={errors.endDate?.message}
                                />
                            )}
                        />
                    </s.SideBySideFields>
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton onClick={() => setIsToCreate(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit">Criar</PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
