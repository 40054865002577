import * as Dialog from "@radix-ui/react-dialog";
import { Dispatch, SetStateAction } from "react";

import { Icons, theme } from "../../../assets";
import * as s from "./styled-container-modal-scroll";

type TAlert = {
    children: JSX.Element;
    onOpenChange?: Dispatch<SetStateAction<boolean>>;
    closeIcon?: boolean;
    closeIconColor?: string;
    closeOnIconOnly?: boolean;
    onCloseAction?: () => void;
    topDistance?: string;
    backgroundColor?: string;
    width?: string;
    height?: string;
};

const ContainerModalScroll = ({
    children,
    onOpenChange,
    closeIcon,
    closeIconColor,
    closeOnIconOnly,
    onCloseAction,
    topDistance,
    backgroundColor,
    width,
    height,
}: TAlert) => {
    return (
        <Dialog.Root onOpenChange={onOpenChange} open={true}>
            <Dialog.DialogPortal>
                <s.Overlay>
                    <s.Content
                        onInteractOutside={(e) => {
                            if (closeOnIconOnly) {
                                e.preventDefault();
                            }
                        }}
                        style={{
                            top: topDistance || "50%",
                            backgroundColor: backgroundColor || theme.white,
                            width: width || undefined,
                            height: height || undefined,
                        }}
                    >
                        {children}

                        {closeIcon && (
                            <s.RadixClose
                                style={{
                                    paddingTop: topDistance || "0px",
                                }}
                                onClick={() => {
                                    if (onCloseAction) {
                                        onCloseAction();
                                    }
                                }}
                            >
                                <Icons.XSolid
                                    color={closeIconColor || theme.gray200}
                                />
                            </s.RadixClose>
                        )}
                    </s.Content>
                </s.Overlay>
            </Dialog.DialogPortal>
        </Dialog.Root>
    );
};

export default ContainerModalScroll;
