import InputMask from "react-input-mask";
import styled from "styled-components";

type TEditingText = {
    itemsSelected?: boolean;
};

type TCardContainer = {
    dayOpen?: boolean;
};

export const Flex = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6e697a;
    gap: 3px;
`;

export const CardContainer = styled.div<TCardContainer>`
    width: 100%;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    box-sizing: border-box;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
        border: 1px solid
            ${({ theme, dayOpen }) =>
                !dayOpen ? theme.purple500 : theme.gray200};
    }
`;

export const TitleContainer = styled.div<TCardContainer>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    height: 3rem;
    background: ${({ theme }) => theme.purple50};
    border-radius: 0.1875rem 0.1875rem 0px 0px;

    &[data-state="open"] {
        div#container-timeline {
            display: none;
        }
    }

    &:hover {
        ${({ theme, dayOpen }) => !dayOpen && `background: ${theme.white}`};
        border-radius: 4px;
    }

    span.periods {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.375rem;
        letter-spacing: 0.0015em;
        color: ${({ theme }) => theme.gray800};
        text-transform: capitalize;
        max-width: 6.25rem;
        white-space: nowrap;

        @media (max-width: 768px) {
            font-size: 10px;
        }
    }

    #right-content {
        gap: 2.875rem;

        button#btn-edit {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            border: 1px solid transparent;
            border-radius: 0.25rem;

            &:hover {
                background: ${({ theme }) => theme.white};
                border: 1px solid ${({ theme }) => theme.gray200};
            }
        }
    }

    #container-timeline {
        width: 100%;
        padding: 1rem;
    }
`;

export const ActivityContainer = styled.div<{ isEditing?: boolean }>`
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    position: relative;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: ${({ isEditing }) =>
            isEditing ? "1fr" : "47vw 47vw"};
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
    }

    .menu-trigger {
        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding-right: 20px;
        }
    }

    svg.icon-more {
        cursor: pointer;
        opacity: 1;
        width: 18px;

        &:hover {
            path {
                stroke: ${({ theme }) => theme.pink600};
                transition: 0.3s ease-in-out;
            }
        }
    }

    &:hover {
        svg.icon-more {
            opacity: 1;
        }
    }

    #right-content {
        gap: 3rem;
        padding-left: 1.5rem;

        @media (max-width: 768px) {
            display: grid;
            grid-template-columns: ${({ isEditing }) =>
                isEditing ? "1fr 1fr 1fr" : "1fr 3fr"};
            column-gap: 10px;
            padding-left: ${({ isEditing }) => (isEditing ? "0" : "1.5rem")};
            margin-top: ${({ isEditing }) => (isEditing ? "10px" : "0")};
        }
    }
`;

export const Text = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.gray200};
`;

export const EditingText = styled.p<TEditingText>`
    cursor: ${({ itemsSelected }) => (itemsSelected ? "pointer" : "default")};
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme, itemsSelected }) =>
        itemsSelected ? theme.gray800 : theme.gray400};
    white-space: nowrap;

    .icon-favorite {
        margin-right: 8px;

        path {
            fill: ${({ theme, itemsSelected }) =>
                itemsSelected ? theme.gray800 : theme.gray400};
        }
    }
`;

export const DeletingText = styled(EditingText)`
    color: #ff0000;

    .icon-delete {
        margin-right: 8px;
        path {
            stroke: #ff0000;
        }
    }
`;

export const ContainerPopover = styled.div`
    background: ${({ theme }) => theme.white};
    padding: 8px 0;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    position: relative;
`;

export const ButtonPopover = styled.button`
    &[data-state="open"] {
        display: none;
    }
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.25rem 0.5rem;
    gap: 0.75rem;
    background: ${({ theme }) => theme.white};

    &:hover {
        background: ${({ theme }) => theme.gray50};
        transition: 0.3s ease-in-out;
    }
`;

export const Play = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid var(--color-gray-100, #e7e6e9);
    transition: 0.3s ease-in-out;
`;

export const PlayTooltip = styled.div`
    padding: 0.5rem;
    word-break: break-word;
`;

export const DescriptionTooltip = styled.div`
    padding: 0.5rem;
    max-width: 900px;
`;

export const StartAndEndTrackTimes = styled.div<{ isEditing?: boolean }>`
    cursor: pointer;
    display: flex;
    gap: 5px;
    border: ${({ isEditing, theme }) =>
        isEditing ? `1px ${theme.gray200} solid` : "none"};
    border-radius: 4px;
    padding: 5px 12px;
    background-color: ${({ isEditing, theme }) =>
        isEditing ? theme.white : "transparent"};

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

export const LeftSide = styled.div<{
    isEditing?: boolean;
    isToEditEntry?: boolean;
}>`
    display: grid;
    grid-template-columns: ${({ isEditing, isToEditEntry }) => {
        if (isEditing) {
            return "20px 250px 50px 1fr";
        }
        if (isToEditEntry) {
            return "1fr";
        }
        return "250px 50px 1fr";
    }};
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6e697a;
    gap: 16px;
    width: 100%;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;

        .container-tag {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        .project-tag {
            p {
                max-width: 100px !important;
            }
        }
    }
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 800px;
    min-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
    }
`;

export const TaskDescription = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    min-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SessionName = styled.div`
    color: ${({ theme }) => theme.gray400};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 120px;
`;

export const EditControl = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    max-width: 50px;

    @media (max-width: 768px) {
        max-width: 100%;
        width: 100%;
        align-items: flex-end;
    }

    svg {
        width: 25px;
        height: 25px;
        stroke-width: 0.5px;
    }
`;
