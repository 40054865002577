type TMinChars = {
    fieldName: string;
    minChars: number;
};

type TToFeminine = {
    sentence: string;
    values: [string, string];
};
type TGenerateErrors = {
    fieldProperties: any;
    isFeminine?: boolean;
};

const toFeminine = ({ sentence, values }: TToFeminine) => {
    return sentence.replace(values[0], values[1]);
};

const errorMessages = {
    required: (fieldName: string) => `${fieldName} é obrigatório`,
    minChars: ({ fieldName, minChars }: TMinChars) =>
        `Seu ${fieldName.toLowerCase()} deve conter no mínimo ${minChars} caracteres`,
    invalid: (fieldName: string) => `${fieldName} inválido`,
};
const { required, minChars, invalid } = errorMessages;

const mascFieldProperties = [
    { propertyName: "name", fieldName: "Nome", minChars: 3 },
    {
        propertyName: "nameAddAmin",
        fieldName: "Nome do administrador",
        minChars: 3,
    },
    { propertyName: "nameFantasy", fieldName: "Nome fantasia", minChars: 3 },
    {
        propertyName: "responsibleAdministrator",
        fieldName: "Administrador Responsável",
    },
    { propertyName: "projectName", fieldName: "Nome do projeto", minChars: 3 },
    { propertyName: "email", fieldName: "E-mail" },
    { propertyName: "emailAddAdmin", fieldName: "E-mail do administrador" },
    { propertyName: "contract", fieldName: "Contrato" },
    { propertyName: "rg", fieldName: "RG" },
    { propertyName: "cnpj", fieldName: "CNPJ" },
    { propertyName: "pis", fieldName: "PIS" },
    { propertyName: "celNumber", fieldName: "Número de telefone" },
    { propertyName: "phone", fieldName: "Telefone" },
    { propertyName: "cep", fieldName: "CEP" },
    { propertyName: "address", fieldName: "Endereço" },
    { propertyName: "houseNumber", fieldName: "Número" },
    { propertyName: "complement", fieldName: "Complemento" },
    { propertyName: "state", fieldName: "Estado" },
    { propertyName: "cityAndState", fieldName: "Cidade/Estado" },
    { propertyName: "terms", fieldName: "Termos e condições" },
    { propertyName: "role", fieldName: "Atuação" },
    { propertyName: "sector", fieldName: "Setor" },
    { propertyName: "project", fieldName: "Projeto" },
    { propertyName: "projects", fieldName: "Projetos" },
    { propertyName: "hourValue", fieldName: "Valor hora" },
    { propertyName: "client", fieldName: "Cliente" },
    { propertyName: "discount", fieldName: "Desconto" },
    { propertyName: "benefits", fieldName: "Benefícios" },
    { propertyName: "hourValue", fieldName: "Valor fixo mensal" },
    { propertyName: "scopePercentage", fieldName: "Porcentagem de escopo" },
] as const;

const femFieldProperties = [
    {
        propertyName: "birthDate",
        fieldName: "Data de nascimento",
    },
    { propertyName: "projectDescription", fieldName: "Descrição do projeto" },
    { propertyName: "description", fieldName: "Descrição", minChars: 3 },
    {
        propertyName: "admissionDate",
        fieldName: "Data de início",
    },
    { propertyName: "description", fieldName: "Descrição", minChars: 3 },
    {
        propertyName: "startDate",
        fieldName: "Data de início",
    },
    {
        propertyName: "color",
        fieldName: "Cor",
    },
    { propertyName: "endDate", fieldName: "Data de fim" },
    { propertyName: "city", fieldName: "Cidade" },
    { propertyName: "password", fieldName: "Senha", minChars: 5 },
    { propertyName: "workload", fieldName: "Carga horária mensal" },
] as const;

type TMasc = (typeof mascFieldProperties)[number]["propertyName"];
type TFem = (typeof femFieldProperties)[number]["propertyName"];

type TErrors<T extends string> = {
    [key in T]: {
        required: string;
        invalid: string;
        minChars?: string;
    };
};

const generateErrors = <T extends string>({
    fieldProperties,
    isFeminine,
}: TGenerateErrors) => {
    const errors: TErrors<T> = fieldProperties.reduce(
        (acc: any, item: any) => ({
            ...acc,
            [item.propertyName]: {
                required: isFeminine
                    ? toFeminine({
                          sentence: required(item.fieldName),
                          values: ["obrigatório", "obrigatória"],
                      })
                    : required(item.fieldName),
                invalid: isFeminine
                    ? toFeminine({
                          sentence: invalid(item.fieldName),
                          values: ["inválido", "inválida"],
                      })
                    : invalid(item.fieldName),
                ...(item.minChars && {
                    minChars: isFeminine
                        ? toFeminine({
                              sentence: minChars({
                                  fieldName: item.fieldName,
                                  minChars: item.minChars,
                              }),
                              values: ["Seu", "Sua"],
                          })
                        : minChars({
                              fieldName: item.fieldName,
                              minChars: item.minChars,
                          }),
                }),
            },
        }),
        {}
    );

    return errors;
};

export default {
    ...generateErrors<TMasc>({ fieldProperties: mascFieldProperties }),
    ...generateErrors<TFem>({
        fieldProperties: femFieldProperties,
        isFeminine: true,
    }),
    confirmPassword: {
        required: "Confirmar senha é obrigatório",
        invalid: "As senhas não estão iguais!",
    },
    picture: {
        isNotImage: "Erro! O arquivo não é uma imagem",
        tooLarge: "Erro!! O arquivo é muito grande (maior que 2MB)!!",
    },
};
