import styled from "styled-components";

export const Container = styled.div`
    width: 600px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    div.form {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        gap: 20px;
    }

    .input-wrapper {
        gap: 4px;
        div {
            label {
                color: ${({ theme }) => theme.gray600};
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    .select-input {
        gap: 4px;
        label {
            color: ${({ theme }) => theme.gray600};
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
        }

        .react-select__placeholder {
            color: ${({ theme }) => theme.gray400};
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.08px;
        }
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const TooltipContent = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.07px;
    padding: 5px 10px;
    width: 240px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    button {
        width: fit-content;
        padding: 0 1rem;
    }
`;
