import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { PurpleLogo, theme } from "../../../../assets";
import { TProject } from "../../../../models";
import {
    TSection,
    TSectionActivity,
} from "../../../../pages/organization/project/tab-activities/types";
import { numberHoursToString } from "../../../../utils";
import PDFTemplate from "../pdf-template";
import * as s from "../styled-pdf-template";

const Styles = StyleSheet.create({
    table: {
        border: `1px solid ${theme.gray200}`,
        borderRadius: "3px",
        display: "flex",
        width: "100%",
    },
    tableHead: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "10px",
        borderBottom: `1px solid ${theme.gray200}`,
    },
    tableHeadTitle: {
        textTransform: "uppercase",
        color: theme.gray800,
        fontSize: "9px",
    },
    tableBody: {},
    sectionName: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        color: theme.gray800,
        fontSize: "12px",
        borderBottom: `1px solid ${theme.gray200}`,
        padding: "10px",
    },
    activitiesContainer: {
        fontSize: "10px",
        color: theme.gray600,
        display: "flex",
    },
    activityRow: {
        paddingLeft: "30px",
        display: "flex",
        paddingTop: "10px",
        paddingBottom: "10px",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: `1px solid ${theme.gray200}`,
    },
    activityName: {
        width: "174px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    activityUsers: {
        width: "120px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: "10px",
    },
    activityDeadline: {
        width: "80px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    activityPriority: {
        width: "70px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    activityHours: {
        width: "30px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    noData: {
        color: theme.gray400,
    },
});

type PDFData = {
    activitiesWithoutSection: TSectionActivity[];
    archive: {
        archiveSection: TSection;
        archivedSections: [];
    };
    sections: (Omit<TSection, "Activities"> & {
        Activities: Record<number, TSectionActivity>;
    })[];
};

type RowProps = {
    activity: TSectionActivity;
};

const Row = ({ activity }: RowProps) => {
    const priorityMapper = {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
    };

    const formatUsers = (Users: TSectionActivity["Users"]) => {
        if (!Users.length) return "-";
        return Users.map(
            (user) =>
                `${user.User.name.split(" ")?.[0]} ${
                    user.User.name.split(" ")?.[1]?.[0]
                }.`
        ).join(", ");
    };

    const choosePriorityColor = (type: string) => {
        switch (type) {
            case "high":
                return "#D32F2F";
            case "medium":
                return theme.orange;
            case "low":
                return theme.green1;
            default:
                return theme.gray600;
        }
    };

    return (
        <View style={Styles.activityRow}>
            <Text style={Styles.activityName}>
                {activity.name.length > 27
                    ? `${activity.name.slice(0, 27)}...`
                    : activity.name}
            </Text>
            <Text
                style={
                    activity.Users.length
                        ? Styles.activityUsers
                        : {
                              ...Styles.activityUsers,
                              ...Styles.noData,
                          }
                }
            >
                {formatUsers(activity.Users)}
            </Text>
            <Text
                style={
                    activity.deadline
                        ? Styles.activityDeadline
                        : {
                              ...Styles.activityDeadline,
                              ...Styles.noData,
                          }
                }
            >
                {activity.deadline
                    ? dayjs(activity.deadline).format("DD/MM/YYYY")
                    : "-"}
            </Text>
            <Text
                style={{
                    ...Styles.activityPriority,
                    color: choosePriorityColor(activity.priority),
                }}
            >
                {priorityMapper[activity.priority]}
            </Text>
            <Text style={Styles.activityHours}>
                {numberHoursToString(activity.hourProgress.minutesDone / 60)}
            </Text>
        </View>
    );
};

type Props = {
    pdfData: PDFData;
    project: TProject;
};

export const ActivitiesReport = ({ pdfData, project }: Props) => {
    return (
        <PDFTemplate>
            <View style={s.PDF.Header}>
                <Text style={s.PDF.Title}>
                    Relatório de atividades do projeto
                </Text>

                <Image src={PurpleLogo} style={s.PDF.ImageStyle} fixed />
            </View>

            <View
                style={{
                    ...s.PDFTableSummaryReport.ProjectHeader,
                    marginTop: 35,
                    marginBottom: 35,
                }}
            >
                <View
                    style={{
                        ...s.PDFTableSummaryReport.Section,
                    }}
                >
                    <View
                        style={[
                            s.PDFTableSummaryReport.HeaderColumn,
                            {
                                width: "50%",
                            },
                        ]}
                    >
                        <Text style={s.PDFTableSummaryReport.TableHeaderText}>
                            CLIENTE
                        </Text>
                        <Text style={s.PDFTableSummaryReport.HeaderValueText}>
                            {project.Client?.name}
                        </Text>
                    </View>

                    <View
                        style={[
                            s.PDFTableSummaryReport.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray200}`,
                                width: "40%",
                            },
                        ]}
                    >
                        <Text style={s.PDFTableSummaryReport.TableHeaderText}>
                            PROJETO
                        </Text>
                        <Text style={s.PDFTableSummaryReport.HeaderValueText}>
                            {project.name}
                        </Text>
                    </View>
                </View>
            </View>

            <View style={Styles.table}>
                <View style={Styles.tableHead}>
                    <Text style={{ ...Styles.tableHeadTitle, width: "37%" }}>
                        Sessão/Atividade
                    </Text>
                    <Text style={{ ...Styles.tableHeadTitle, width: "25%" }}>
                        Profissionais
                    </Text>
                    <Text style={{ ...Styles.tableHeadTitle, width: "15%" }}>
                        Prazo
                    </Text>
                    <Text style={{ ...Styles.tableHeadTitle, width: "13%" }}>
                        Prioridade
                    </Text>
                    <Text style={{ ...Styles.tableHeadTitle, width: "10%" }}>
                        Horas
                    </Text>
                </View>
                <View>
                    {pdfData.sections.map((section) => (
                        <View>
                            <Text style={Styles.sectionName}>
                                {section.name}
                            </Text>
                            <View style={Styles.activitiesContainer}>
                                {Object.values(section.Activities).map(
                                    (activity) => (
                                        <Row activity={activity} />
                                    )
                                )}
                                {Object.values(section.Activities).length ===
                                    0 && (
                                    <View style={Styles.activityRow}>
                                        <Text style={Styles.noData}>
                                            Sem atividades
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    ))}
                    <View>
                        <Text style={Styles.sectionName}>Sem sessão</Text>
                        <View style={Styles.activitiesContainer}>
                            {pdfData.activitiesWithoutSection.map(
                                (activity) => (
                                    <Row activity={activity} />
                                )
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </PDFTemplate>
    );
};
