import styled from "styled-components";

import { theme } from "../../assets";

export const Container = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;

    div.contentSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;

        @media (max-width: 1366px) {
            gap: 16px;
        }
    }

    @media (max-width: 1366px) {
        svg.maintenanceMan {
            width: 216px;
            height: 196px;
        }
    }
`;

export const content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 52px;
    width: 100%;
    height: 100%;

    @media (max-width: 1366px) {
        gap: 36px;
    }
`;

export const title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 47px;
    letter-spacing: 0.09px;
    white-space: nowrap;

    @media (max-width: 1366px) {
        font-size: 30px;
    }
`;

export const information = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.018px;
    width: 65%;
    white-space: nowrap;

    @media (max-width: 1366px) {
        font-size: 16px;
    }
`;

export const button = styled.div`
    display: flex;
    width: 254px;
    height: 48px;
    padding: 14px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.purple600};
    cursor: pointer;

    color: ${({ theme }) => theme.purple600};
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;

    @media (max-width: 1366px) {
        width: 236px;
        height: 40px;
        font-size: 12px;
    }
`;

export const footer = styled.footer`
    display: flex;
    align-items: center;
    gap: 36px;

    @media (max-width: 1366px) {
        gap: 34px;
    }

    svg {
        @media (max-width: 1366px) {
            width: 34px;
            height: 34px;
        }
    }
`;
