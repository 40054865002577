/* eslint-disable import/no-duplicates */
import { format, differenceInSeconds } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";

import "dayjs/locale/pt-br";
import { firstUpperCase } from "../string-manipulation/first-upper-case";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(isoWeek);
dayjs.locale("pt-br");

export const secondsToStringDate = (timeStamp: number): string => {
    const hours = Math.floor(timeStamp / 3600);
    const minutes = Math.floor((timeStamp % 3600) / 60);
    const seconds = timeStamp % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const minutesToStringDate = (timeStamp: number): string => {
    const hours = Math.floor(timeStamp / 60);
    const minutes = Math.floor(timeStamp % 60);
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
};

export const sumTimeStampsInMinutes = (duration: number[]): string => {
    if (duration.length > 0) {
        const allDurationInSeconds = duration?.reduce(
            (acc, item) => acc + item
        );

        return minutesToStringDate(allDurationInSeconds);
    }
    return "00:00:00";
};

export const getHourAndMinutesAndSeconds = (date: Date): string => {
    return format(date, "HH:mm:ss", {
        locale: ptBR,
    });
};

export const getDayAndWeek = (date: Date): string => {
    return format(date, "EEEEEE, dd MMM", {
        locale: ptBR,
    });
};

export const getFinalDateOfCurrentMonth = (): string => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const formattedMonth = month.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const finalDate = `${lastDayOfMonth}/${formattedMonth}/${year}`;
    return finalDate;
};

export const calculateTimeRangeDuration = (
    date: Date,
    start: string,
    end: string
): { startTrackTime: Date; finalTrackTime: Date; duration: number } => {
    const parseTime = (time: string) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return { hours, minutes, seconds };
    };

    const startTime = parseTime(start);
    const endTime = parseTime(end);

    const startTrackTime = new Date(date);
    const finalTrackTime = new Date(date);

    startTrackTime.setHours(
        startTime.hours,
        startTime.minutes,
        startTime.seconds
    );
    finalTrackTime.setHours(endTime.hours, endTime.minutes, endTime.seconds);

    if (startTrackTime > finalTrackTime) {
        finalTrackTime.setDate(startTrackTime.getDate() + 1);
    }

    const duration = differenceInSeconds(finalTrackTime, startTrackTime);

    return { startTrackTime, finalTrackTime, duration };
};

export const getTimeFromIsoString = (Iso: string) => {
    if (Iso) return Iso.substring(Iso.indexOf("T") + 1, Iso.indexOf("."));
    return "";
};

export const getDateTimeFromIsoString = (Iso: string) => {
    const date = new Date(Iso);
    return format(date, "dd/MM/yyyy - HH:mm");
};

export const formatISOToDate = (Iso: string) => {
    return String(Iso).split("T")[0].split("-").reverse().join("/");
};

export const getDateWithoutUTC = (iso: string): string => {
    const [year, month, day, hour, minute, second] = iso
        .slice(0, 19)
        .split(/-|T|:/)
        .map(Number);
    const utcDate = new Date(
        Date.UTC(year, month - 1, day, hour, minute, second)
    );
    const localDate = new Date(
        utcDate.toLocaleString("en-US", { timeZone: "UTC" })
    );
    const formattedDate = `${localDate
        .getDate()
        .toString()
        .padStart(2, "0")}/${(localDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${localDate.getFullYear()}`;
    return formattedDate;
};

export const getDateFromIsoString = (Iso: string) => {
    const day = new Date(Iso).getUTCDate();
    const month = new Date(Iso).getUTCMonth();
    const year = new Date(Iso).getUTCFullYear();
    const date = new Date();
    date.setFullYear(year, month, day);

    return date;
};

export const formatDay = (day: Date): string => {
    const dayAndWeek = getDayAndWeek(day);
    const currentDayAndWeek = getDayAndWeek(new Date());
    return dayAndWeek === currentDayAndWeek ? "Hoje" : dayAndWeek;
};

export const getDateFromStringDate = (date: string) => {
    if (date) {
        const dateArr = date.split("/");
        const formattedDate = [dateArr[1], dateArr[0], dateArr[2]].join("/");
        return new Date(formattedDate);
    }
    return undefined;
};

export const getDayMonthAndYearFromDate = (date: Date) =>
    format(date, "dd/MM/yyyy");

export const getMonthAndYearFromDate = (date: Date) =>
    format(date, "MMMM yyyy", { locale: ptBR });

export const getPercentage = ({
    total,
    quantity,
}: {
    total: number;
    quantity: number;
}): number => {
    if (total === 0 || Number.isNaN(quantity) || Number.isNaN(total)) {
        return 0;
    }
    return (quantity / total) * 100;
};
export const dayFromWeek = (date: Date, abbreviation?: boolean) => {
    const format = abbreviation ? "ddd" : "dddd";
    return firstUpperCase(dayjs(date).format(format)).split("-")[0];
};

export const monthFromYear = (date: Date, abbreviation?: boolean) => {
    const format = abbreviation ? "MMM" : "MMMM";
    return firstUpperCase(dayjs(date).format(format));
};

export const formatISOToMonthYear = (isoDate: string): string => {
    return firstUpperCase(dayjs.utc(isoDate).format("MMM/YYYY"));
};

export const getDayMonthAndHalfYear = (Iso?: string): string => {
    if (!Iso) return "";
    return dayjs.utc(Iso).format("DD/MM/YY");
};

export const getCurrentMonthAndYear = () => {
    return dayjs().format("MMMM YYYY").toLocaleUpperCase();
};

export const formatUSDateToBrazilianDate = (date: string | undefined) => {
    if (!date) return "";
    const dateArr = date.split("/");
    return [dateArr[1], dateArr[0], dateArr[2]].join("/");
};

export const addMillisecondsToHour = (hour: string) => {
    if (hour.length === 8) return hour;
    const [hours, minutes] = hour.split(":");
    return `${hours}:${minutes}:00`;
};

export const numberHoursToString = (hoursAndMinutes: number) => {
    const [hours] = hoursAndMinutes.toString().split(".");
    const formattedHours = Number(hours) < 10 ? `0${hours}` : hours.toString();
    const formattedMinutes = Math.floor((hoursAndMinutes % 1) * 60)
        .toString()
        .padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
};

export const formatFullDateTimeWithoutUTC = (isoString: string): string => {
    const date = new Date(isoString);

    date.setHours(date.getHours() + 3);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
};
