import { TooltipItem } from "chart.js";
import { Bar } from "react-chartjs-2";

import { theme } from "../../../../assets";
import { TColumnData } from "../../../../pages/report-summary/report-summary";
import { minutesToStringDate } from "../../../../utils";

const BarChartDashboard = ({
    times,
    periods,
    abreviatedDates,
}: TColumnData) => {
    const data = {
        labels: periods,
        datasets: [
            {
                label: "Horas",
                data: times,
                backgroundColor: theme.pink200,
                borderRadius: 4,
                barPercentage: 0.5,
                maxBarThickness: 100,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 50,
            },
        },
        plugins: {
            datalabels: {
                align: "end" as const,
                anchor: "end" as const,
                rotation: -90,
                formatter(value: number) {
                    return minutesToStringDate(value);
                },
                color: theme.gray800,
            },
            title: {
                display: true,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title(value: TooltipItem<"bar">[]) {
                        const tempValue = value[0]?.label?.replace(",", ", ");
                        return tempValue;
                    },
                },
                displayColors: false,
                padding: 10,
            },
        },
        scales: {
            yAxes: {
                stacked: true,
                position: "right" as const,
                grid: {
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    callback(value: number | string) {
                        const tempValue = minutesToStringDate(value as number);
                        return tempValue;
                    },
                    font: { size: 12 },
                    color: theme.gray600,
                    maxTicksLimit: 7,
                },
            },
            x: {
                id: "xAxis1",
                grid: {
                    display: false,
                    borderColor: theme.gray400,
                },
                ticks: {
                    font: { size: 12 },
                    color: theme.gray600,
                },
            },
            x2: {
                grid: {
                    display: false,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    font: { size: 10 },
                    color: theme.gray400,
                    fontWeight: 400,
                },
                labels: abreviatedDates,
            },
        },
        animation: {
            duration: 0,
        },
    };

    return (
        <div style={{ width: "100%", height: 450 }}>
            <Bar data={data} options={options} id="bar-chart-dashboard" />
        </div>
    );
};

export default BarChartDashboard;
