import styled from "styled-components";

type TButtonPopover = { disabled?: boolean };

type TTooltipContent = { simple?: boolean };

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    height: calc(522px + 2.75rem);
`;

export const History = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    overflow: hidden;

    span.no-reports {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        color: ${({ theme }) => theme.gray500};
        border-top: 1px solid ${({ theme }) => theme.gray200};
    }

    div.table-header {
        background: ${({ theme }) => theme.purple50};
        border-radius: 4px 4px 0 0;

        span.heading-text {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            color: ${({ theme }) => theme.purple800};
        }
    }

    div.table-row {
        display: grid;
        grid-template-columns: 0.6fr 0.6fr 1fr 1fr 1fr 1fr 0.3fr;
        align-items: center;
        height: 48px;
        place-items: center;
    }

    div.rows {
        div.table-row {
            height: 60px;
            border-top: 1px solid ${({ theme }) => theme.gray200};

            span.body-text {
                font-size: 0.875rem;
                color: ${({ theme }) => theme.gray600};
            }

            span.options {
                display: flex;
                opacity: 0.2;
                padding: 0.75rem 1.875rem;
                align-items: center;
                justify-content: center;
            }

            &:hover {
                background: ${({ theme }) => theme.gray50};

                    span.options {
                        opacity: 1;
                    }
                }
            }
        }
    }
`;

export const ProjectsList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: flex-start;

    span#title {
        font-size: 0.875rem;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
        text-align: start;
    }

    span#empty {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    span {
        width: 100%;
    }
`;

export const TooltipContent = styled.div<TTooltipContent>`
    display: flex;
    flex-direction: column;
    padding: ${({ simple }) => (simple ? "5px 12px" : "20px 16px")};
    gap: 10px;
    width: ${({ simple }) => !simple && "230px"};

    span.tooltip-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
    }

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div.tooltip-row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;

        div.icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 32px;
            height: 32px;
            border: 1px solid ${({ theme }) => theme.gray100};
            border-radius: 4px;

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
`;

export const ContainerPopover = styled.div`
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    width: 192px;
`;

export const ButtonPopover = styled.button<TButtonPopover>`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background: ${({ theme }) => theme.white};
    cursor: pointer;

    div {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0.5rem;
    }

    svg {
        width: 24px;
        height: 24px;

        path {
            ${({ disabled, theme }) => disabled && `stroke: ${theme.gray200}`}
        }
    }

    span.button-text {
        font-weight: 400;
        font-size: 0.875rem;
        color: ${({ theme, disabled }) =>
            disabled ? theme.gray200 : theme.gray600};
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
        transition: 0.3s ease-in-out;
    }
`;

export const ButtonTable = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;

    &:hover {
        border: 1px solid ${({ theme }) => theme.pink100};
    }

    &:active {
        background: ${({ theme }) => theme.pink50};
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
        max-width: 450px;
        text-align: center;
    }
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    margin: 1rem 0 3rem;
    height: 40px;

    button {
        width: 152px;
    }
`;
