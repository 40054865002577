import dayjs from "dayjs";

import { DEFAULT_TRIAL_DAYS } from "../constants/free-days";

type TVerifyIfHaveRedirectToTheTrack = {
    plan: string | undefined;
    created_at: string | undefined;
    location: string | undefined;
    trial_days: number | undefined;
};

export function verifyIfHaveRedirectToTheTrack({
    plan,
    created_at,
    location,
    trial_days = DEFAULT_TRIAL_DAYS,
}: TVerifyIfHaveRedirectToTheTrack): boolean {
    const notRedirectToTrack =
        !plan ||
        !created_at ||
        !location ||
        location.includes("assinaturas/plano");
    if (notRedirectToTrack) {
        return false;
    }
    if (location.includes("/organizacao/perfil")) {
        return false;
    }

    const todayDate = dayjs();
    const planCreationDate = dayjs(created_at);
    const planExpiresDate = planCreationDate
        .add(trial_days, "day")
        .endOf("day");
    const hoursToExpireTrialSubscription = planExpiresDate.diff(
        todayDate,
        "hours"
    );

    return plan === "trial" && hoursToExpireTrialSubscription <= 0;
}
