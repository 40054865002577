import { styled } from "styled-components";

export const Container = styled.div``;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const TableHeadCell = styled.th`
    padding: 15px 0;

    &:first-child {
        padding-left: 24px;
        padding-bottom: 10px;
    }

    color: ${({ theme }) => theme.gray800};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const LoadingContainer = styled.div``;

export const SearchWrapper = styled.div`
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
`;

export const TooltipTitle = styled.div`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    margin-bottom: 8px;
`;
