import { View, Text, Svg, Path } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { theme } from "../../../../../../assets";
import { SelectedDate } from "../../../../../../pages/organization/project/tab-costs/tab-costs";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import { formatToBRMoneyWithoutMoneySign } from "../../../../../../utils";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

type TableProps = {
    data: ReadProjectCostsResult;
    date: SelectedDate;
};

export const Table = ({ data, date }: TableProps) => {
    const dateFilterIsThisMonth =
        dayjs(date.start).isSame(dayjs(), "month") ||
        dayjs(date.end).isSame(dayjs(), "month");
    const month = dayjs(date.start).format("MMMM/YYYY");

    const typeMapper = {
        fixed: "Custos fixos",
        variable: "Custos variáveis",
        team: "Custos time",
    };

    return (
        <View
            style={{
                border: `1px solid ${theme.gray200}`,
                borderRadius: "4px",
                width: "100%",
            }}
        >
            <View
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "12px 0px",
                    width: "100%",
                }}
            >
                <Text
                    style={{
                        textTransform: "uppercase",
                        letterSpacing: "0.96px",
                        color: theme.gray600,
                        fontSize: "9px",
                    }}
                >
                    tabela de custos mensal - {month}
                </Text>
            </View>
            <View
                style={{
                    borderTop: `1px solid ${theme.gray200}`,
                    padding: "12px 25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Text
                    style={{
                        textTransform: "uppercase",
                        fontSize: "9px",
                        color: theme.gray700,
                    }}
                >
                    tipo
                </Text>
                <Text
                    style={{
                        textTransform: "uppercase",
                        fontSize: "9px",
                        color: theme.gray700,
                    }}
                >
                    custo realizado (r$)
                </Text>
            </View>

            {data.resume.data.map((cost) => (
                <View
                    style={{
                        borderTop: `1px solid ${theme.gray200}`,
                        padding: "12px 25px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Text
                        style={{
                            fontSize: "9px",
                            color: theme.gray600,
                        }}
                    >
                        {typeMapper[cost.type]}
                    </Text>
                    <View
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "5px",
                        }}
                    >
                        <Text
                            style={{
                                fontSize: "9px",
                                color: theme.gray600,
                            }}
                        >
                            {formatToBRMoneyWithoutMoneySign(cost.realizedCost)}
                        </Text>
                        {dateFilterIsThisMonth && (
                            <Svg width={"10"} height={"10"} viewBox="0 0 14 14">
                                <Path
                                    d="M7.66667 9.66667H7V8.33333V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                                    stroke={theme.orange}
                                    strokeWidth="1.2"
                                />
                            </Svg>
                        )}
                    </View>
                </View>
            ))}

            <View
                style={{
                    borderTop: `1px solid ${theme.gray200}`,
                    padding: "12px 25px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    backgroundColor: theme.gray50,
                }}
            >
                <Text
                    style={{
                        fontSize: "9px",
                        color: theme.gray600,
                    }}
                >
                    Total {month}
                </Text>
                <View
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "5px",
                    }}
                >
                    <Text
                        style={{
                            fontSize: "9px",
                            color: theme.gray600,
                        }}
                    >
                        {formatToBRMoneyWithoutMoneySign(
                            data.resume.monthTotal.totalCost
                        )}
                    </Text>
                    {dateFilterIsThisMonth && (
                        <Svg width={"10"} height={"10"} viewBox="0 0 14 14">
                            <Path
                                d="M7.66667 9.66667H7V8.33333V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                                stroke={theme.orangeWarning}
                                strokeWidth="1.2"
                            />
                        </Svg>
                    )}
                </View>
            </View>
        </View>
    );
};
