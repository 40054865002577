import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    gap: 0.5rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: "center";
    gap: 0.5rem;

    .Label {
        color: ${({ theme }) => theme.purple500};
        font-size: 0.75rem;
        font-weight: 400;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;
