import * as s from "./styles";

type ChallengeProps = {
    done?: boolean;
    children: React.ReactNode;
};

export const MissionChallenge = ({ children, done }: ChallengeProps) => {
    return (
        <s.Container>
            <s.Indicator done={done}>•</s.Indicator>
            <s.Title done={done}>{children}</s.Title>
        </s.Container>
    );
};
