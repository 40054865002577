import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 64px;
    align-items: center;

    @media (max-width: 1424px) {
        gap: 40px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
    width: 10vw;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1424px) {
        width: 10vw;
    }
`;
