import styled from "styled-components";

type TBar = {
    percentage: number;
};

export const Container = styled.div`
    width: 100%;
    min-height: 4px;
    div#container-infos {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: ${({ theme }) => theme.white};

        div#info-right {
            text-align: right;
        }
    }
`;

export const BarContainer = styled.span<TBar>`
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 4px;
    #delimiter {
        display: ${({ percentage }) => percentage >= 100 && "none"};
    }

    &::after {
        content: "";
        display: block;
        background: ${({ theme, percentage }) =>
            percentage >= 100 ? theme.greenDone : theme.purple400};
        width: ${({ percentage }) => (percentage >= 100 ? 100 : percentage)}%;
        height: 4px;
        position: absolute;
        overflow: hidden;
    }
    &::before {
        content: "";
        height: 12px;
        background: ${({ theme }) => theme.black};
        width: 3px;
        position: absolute;
        left: ${({ percentage }) => (percentage >= 100 ? 100 : percentage)}%;
    }
`;

export const Bar = styled.div<TBar>`
    display: flex;
    justify-content: flex-end;
    background: ${({ theme }) => theme.purple50};
    width: ${({ percentage }) => percentage}%;
    height: 4px;
`;

export const BarDelimiter = styled.div`
    height: 12px;
    background: ${({ theme }) => theme.black};
    width: 3px;
`;

export const Paragraph = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.08em;
    color: ${({ theme }) => theme.gray800};
`;

export const GrayParagraph = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.015em;
    color: ${({ theme }) => theme.gray600};
`;
