import * as yup from "yup";

/* rating: yup.number().required("Avaliação é obrigatória!"), */

export const schema1 = yup.object().shape({
    rating: yup.number(),
    comment: yup
        .string()
        .max(1000, "O comentário deve ter no máximo 1000 caracteres."),
});

export const schema2 = yup.object().shape({
    rating: yup.number(),
    comment: yup.string(),
});
