import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const Billing = ({ data }: { data: TBackOfficeData }) => {
    const centsToReal = (cents: number) => {
        return (cents / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    };

    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Dollar color={theme.purple500} />
                Faturamento
            </generalStyles.Title>
            <generalStyles.Row>
                <generalStyles.Content>
                    <h1>Faturamento total</h1>
                    <span>
                        <p>{centsToReal(data.totalIncome)}</p>
                    </span>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>Faturamento - plano mensal</h1>
                    <span>
                        <p>{centsToReal(data.monthlyIncome)}</p>
                    </span>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>Faturamento - plano anual</h1>
                    <span>
                        <p>{centsToReal(data.annualIncome)}</p>
                    </span>
                </generalStyles.Content>
            </generalStyles.Row>
        </s.Container>
    );
};
