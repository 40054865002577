import { useState } from "react";

import { FotoUser } from "../../../../assets";
import { LevelCircle } from "../../../../components/atoms/level-circle/level-circle";
import { LevelsCard } from "../../../../components/molecules/levels-card/levels-card";
import { useUser } from "../../../../context";
import { Level } from "../../../../models";
import { ReadGameficationDataUseCaseReturn } from "../../../../services/gamefication/types";
import { levelsMapper } from "../../../../utils";
import * as s from "./styles";

type ProfileCardProps = {
    data: ReadGameficationDataUseCaseReturn | undefined;
};

export const ProfileCard = ({ data }: ProfileCardProps) => {
    const [levelsCardOpen, setLevelsCardOpen] = useState(false);
    const { user } = useUser();
    const userLevel = data?.user_information?.level?.level_number as Level;
    const points = data?.user_information?.points;

    return (
        <s.Container>
            <s.Name>{user.name}</s.Name>
            <s.PictureContainer>
                <LevelCircle level={userLevel} size="large" />
                <s.Picture src={user.avatar || FotoUser} />
            </s.PictureContainer>
            <s.InformationContainer>
                <s.LevelText level={userLevel}>
                    Nível {levelsMapper[userLevel] || "iniciante"}
                </s.LevelText>
                <s.Points>
                    {points?.toLocaleString("pt-BR", {
                        minimumFractionDigits: 0,
                    })}{" "}
                    pts
                </s.Points>
                <LevelsCard
                    isOpen={levelsCardOpen}
                    setIsOpen={setLevelsCardOpen}
                >
                    <s.SeeLevels
                        onClick={() => setLevelsCardOpen(!levelsCardOpen)}
                    >
                        Ver níveis
                    </s.SeeLevels>
                </LevelsCard>
            </s.InformationContainer>
        </s.Container>
    );
};
