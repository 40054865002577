import { useState } from "react";

import { TabPicker } from "../../../../components/molecules/tab-picker/tab-picker";
import { TBackOfficeData } from "../../types";
import * as s from "./styles";
import { ChartsTab } from "./tabs/charts/charts";
import { DetailsTab } from "./tabs/details/details";
import { GeneralTab } from "./tabs/general";
import { NotificationsTab } from "./tabs/notifications/notifications";
import { NPSTab } from "./tabs/nps/nps";
import { Vouchers } from "./tabs/vouchers/vouchers";

type TPaperonData = {
    data: TBackOfficeData;
};

export type TabProps = {
    data: TBackOfficeData;
};

export const PaperonData = ({ data }: TPaperonData) => {
    const tabs = {
        Geral: <GeneralTab data={data} />,
        "Detalhes org.": <DetailsTab data={data} />,
        Gráficos: <ChartsTab data={data} />,
        NPS: <NPSTab data={data} />,
        Cupons: <Vouchers data={data} />,
        Notificações: <NotificationsTab data={data} />,
    };

    const [activeTab, setActiveTab] = useState<keyof typeof tabs>("Geral");

    return (
        <>
            <TabPicker
                tabs={Object.keys(tabs)}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            />
            <s.Container>{tabs[activeTab]}</s.Container>
        </>
    );
};
