import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Input, PrimaryButton } from "../../../../../../../../../components";
import { activities } from "../../../../../../../../../services";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TEditAttachment = {
    activity: ActivityDTO;
    attachment: ActivityDTO["ActivityAttachments"][0];
    setEditAttachment: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditAttachment = ({
    activity,
    attachment,
    setEditAttachment,
}: TEditAttachment) => {
    const [attachmentName, setAttachmentName] = useState(attachment.title);
    const [attachmentLink, setAttachmentLink] = useState(
        attachment.attachment_url
    );

    const { mutate: editAttachment } = useMutation({
        mutationFn: () =>
            activities.updateAttachment({
                attachmentId: attachment.id_e,
                title: attachmentName,
                link: attachmentLink,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const save = () => {
        editAttachment();
        setEditAttachment(false);
    };

    return (
        <s.Container
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    save();
                }
            }}
        >
            <s.Inputs>
                <Input
                    ref={(input) => input && input.focus()}
                    mask=""
                    placeholder="Nome do anexo"
                    label={`Nome do ${
                        attachment.type === "LINK"
                            ? "link (opcional)"
                            : "arquivo"
                    }`}
                    value={attachmentName}
                    onChange={(e) => setAttachmentName(e.target.value)}
                />
                {attachment.type === "LINK" && (
                    <Input
                        label="Link"
                        mask=""
                        value={attachmentLink}
                        onChange={(e) => setAttachmentLink(e.target.value)}
                        placeholder="Link do anexo"
                    />
                )}
            </s.Inputs>
            <s.SaveButtonWrapper>
                <PrimaryButton
                    disabled={!attachmentLink}
                    variation="small"
                    onClick={save}
                >
                    SALVAR
                </PrimaryButton>
            </s.SaveButtonWrapper>
        </s.Container>
    );
};
