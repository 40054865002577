function levenshteinDistance(a: string, b: string): number {
    const aLen = a.length;
    const bLen = b.length;

    if (aLen === 0) return bLen;
    if (bLen === 0) return aLen;

    let prevRow = Array(bLen + 1)
        .fill(0)
        .map((_, j) => j);
    let currentRow = Array(bLen + 1);

    for (let i = 1; i <= aLen; i += 1) {
        currentRow[0] = i;
        for (let j = 1; j <= bLen; j += 1) {
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            currentRow[j] = Math.min(
                prevRow[j] + 1, // Deletion
                currentRow[j - 1] + 1, // Insertion
                prevRow[j - 1] + cost // Substitution
            );
        }
        [prevRow, currentRow] = [currentRow, prevRow];
    }

    return prevRow[bLen];
}

export function findClosestMatch(
    input: string,
    array: string[]
): { match: string; percentage: number } {
    let closestMatch = "";
    let closestDistance = Infinity;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of array) {
        const distance = levenshteinDistance(input, item);
        if (distance < closestDistance) {
            closestDistance = distance;
            closestMatch = item;
        }
    }

    const maxLength = Math.max(input.length, closestMatch.length);
    const similarityPercentage =
        maxLength === 0
            ? 100
            : ((1 - closestDistance / maxLength) * 100).toFixed(2);

    return {
        match: closestMatch,
        percentage: Number(similarityPercentage),
    };
}
