import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import { useCreateBugReport } from "../../../../hooks/react-query/use-create-bug-report";
import { PrimaryButton, SecondaryButton, TextArea } from "../../../atoms";
import ContainerModal from "../container-modal";
import {
    ModalButtonsWrapper,
    ModalText,
    ModalTitle,
} from "../styled-generic-modal";
import * as s from "./styles";

const DESCRIPTION_MAX_LENGTH = 1000;

type BugFormData = {
    description: string;
    attachment?: FormData;
};

const validation = yup.object().shape({
    description: yup
        .string()
        .required("Campo obrigatório")
        .max(
            DESCRIPTION_MAX_LENGTH,
            `Máximo de ${DESCRIPTION_MAX_LENGTH} caracteres`
        ),
    attachment: yup.mixed(),
});

type Props = {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ModalReportBug({ onOpenChange }: Props) {
    const [attachment, setAttachment] = useState<File | null>(null);

    const close = () => onOpenChange(false);

    const createBugReport = useCreateBugReport({
        onSuccess: () => {
            toast.success("Problema reportado com sucesso!");
            close();
        },
    });

    const {
        control,
        formState: { isValid, errors },
        handleSubmit,
    } = useForm<BugFormData>({
        resolver: yupResolver(validation),
    });

    const submit = (data: BugFormData) => {
        if (attachment) {
            const formData = new FormData();
            formData.append("attachment", attachment as Blob);
            createBugReport.mutate({ ...data, attachment: formData });
            return;
        }
        createBugReport.mutate({
            description: data.description,
        });
    };

    return (
        <ContainerModal onOpenChange={onOpenChange} closeIcon>
            <s.Container onSubmit={handleSubmit(submit)}>
                <ModalTitle>Reportar um problema</ModalTitle>
                <ModalText>
                    Se você encontrou algum problema no Paper ON, por favor, nos
                    informe para que possamos corrigir.
                </ModalText>
                <Controller
                    name="description"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <TextArea
                            placeholder="Descreva o problema"
                            value={value}
                            onChange={onChange}
                            charCount
                            counter={value}
                            maxLength={DESCRIPTION_MAX_LENGTH}
                            error={errors.description?.message}
                        />
                    )}
                />

                <s.AttachmentWrapper>
                    <s.AttachmentInput
                        type="file"
                        accept=".png, .jpg, .jpeg, .mp4"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                setAttachment(file);
                            }
                        }}
                    />
                    <s.AttachmentLabel>
                        Anexar imagem ou vídeo do ocorrido (opcional)
                    </s.AttachmentLabel>
                </s.AttachmentWrapper>
                <ModalButtonsWrapper>
                    <SecondaryButton onClick={close}>Fechar</SecondaryButton>
                    <PrimaryButton
                        disabled={!isValid}
                        loading={createBugReport.isLoading}
                    >
                        Enviar
                    </PrimaryButton>
                </ModalButtonsWrapper>
            </s.Container>
        </ContainerModal>
    );
}
