import { styled } from "styled-components";

export const Container = styled.div`
    margin-top: 60px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.018px;

    span {
        font-weight: 500;
    }
`;

export const TooltipContainer = styled.div`
    padding: 10px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    width: 190px;
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
`;
