import { RefCallback } from "react";
import Select, { GroupBase, MenuListProps, MultiValue } from "react-select";

import { Icons, theme } from "../../../../assets";
import { TOption } from "../../../../models";
import * as s from "./styled-select-projects";

type TSelect = {
    id?: string;
    key?: string;
    error?: string;
    options?: TOption[];
    onChange?: (param: MultiValue<TOption>) => void;
    name?: string;
    inputRef?: RefCallback<Record<string, string>>;
    placeholder?: string;
    value?: TOption[];
    positionY?: string;
    disabled?: boolean;
    isMulti?: true | undefined;
};

const SelectProjects = ({
    id,
    key,
    error,
    options,
    name,
    placeholder,
    value,
    positionY,
    disabled,
    onChange,
    isMulti = true,
}: TSelect) => {
    const MenuList = (
        props: MenuListProps<TOption, true, GroupBase<TOption>>
    ) => {
        return (
            <s.MenuList {...props}>
                {Array.isArray(props.children) &&
                    props.children.map((child) => (
                        <s.Option
                            key={child.key}
                            color={child.props.data.color}
                        >
                            {child}
                        </s.Option>
                    ))}
            </s.MenuList>
        );
    };

    const DropdownIndicator = () => {
        return (
            <s.PositionIcons positionY={positionY} disabled={disabled}>
                <Icons.Chevron color={theme.gray600} />
            </s.PositionIcons>
        );
    };

    return (
        <s.FlexColumn className="select-input-projects">
            <s.Flex error={!!error}>
                <Select
                    id={id}
                    key={key}
                    placeholder={placeholder || "Selecione o valor"}
                    name={name}
                    onChange={onChange}
                    classNamePrefix={"react-select"}
                    options={options}
                    value={value}
                    isDisabled={disabled}
                    components={{ MenuList, DropdownIndicator }}
                    noOptionsMessage={() => "Sem opções"}
                    required
                    closeMenuOnSelect={false}
                    isMulti={isMulti}
                />
            </s.Flex>
            <s.Error>{error || null}</s.Error>
        </s.FlexColumn>
    );
};

export default SelectProjects;
