import { useMutation } from "@tanstack/react-query";

import { UserSkillModel } from "../../models/user-skill";
import createSkills, {
    CreateSkillsParams,
} from "../../services/user/create-skills";

type CreateSKillsResult = Array<UserSkillModel>;

type Params = {
    onSuccess?: (
        data: CreateSKillsResult,
        variables: CreateSkillsParams | undefined,
        context: unknown
    ) => unknown;
};

export function useCreateSkills({ onSuccess }: Params = {}) {
    return useMutation({
        mutationKey: ["createSkills"],
        mutationFn: createSkills,
        onSuccess,
    });
}
