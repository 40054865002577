import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;

    span.title {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.gray400};
        font-weight: 600;
    }
`;

export const Details = styled.section`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    width: 100%;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;

    div {
        display: flex;
        flex-direction: column;
        padding: 16px 32px;
        gap: 8px;

        span.info {
            font-size: 1.25rem;
            color: ${({ theme }) => theme.gray800};
            font-weight: 500;
        }
    }

    div:not(:last-child) {
        border-right: 1px solid ${({ theme }) => theme.gray200};
    }
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button {
        width: 93px;

        &:first-child {
            width: 267px;
        }
    }
`;

export const HeaderTitle = styled.h2`
    font-weight: 500;
    color: ${({ theme }) => theme.gray600};
    width: fit-content;
`;

export const ProjectTimeWrapper = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    gap: 2rem;

    div.project-time-header {
        padding: 43px 40px 0 40px;
    }
`;

export const ActivitiesTable = styled.table`
    display: flex;
    flex-direction: column;

    tr {
        display: grid;
        grid-template-columns: 5% 15% 14% 40% 11% 8% 8%;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
        padding: 0 40px;

        th {
            span {
                float: left;
            }
        }

        span.heading-text {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.gray400};
            font-weight: 600;
            text-align: left;
        }
    }

    tbody {
        tr {
            height: 60px;
            border-top: 1px solid ${({ theme }) => theme.gray200};
        }

        tr {
            td {
                display: flex;
                gap: 20px;

                span.time,
                span.percentage {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray800};
                }

                span.description {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray600};
                }

                .project-tag {
                    p {
                        max-width: 120px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        td.entry {
            span {
                font-size: 0.875rem;
            }

            span.project {
                color: #0b49ec;
                background: rgba(11, 73, 236, 0.05);
                border: 1px solid rgba(11, 73, 236, 0.1);
                border-radius: 4px;
                padding: 0 15px;
            }
        }
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2.625rem;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const Filters = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1.2px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    background: ${({ theme }) => theme.white};
    padding: 16px;
    height: 100%;
`;

export const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 70%;
`;

export const Filter = styled.div`
    display: flex;
    flex-direction: column;
    height: 88px;
    width: 200px;
`;

export const FilterName = styled.div`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
`;

export const FilterButtons = styled.div`
    display: flex;
    gap: 16px;

    button {
        width: 150px;
    }
`;

export const PDFs = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    button {
        height: 40px;
        &:last-child {
            width: 97px !important;
        }
    }
`;

export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 40%;
    margin-top: 16px;
`;

export const SelectedRowInformation = styled.div`
    background-color: ${({ theme }) => theme.gray50};
    color: ${({ theme }) => theme.gray600};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 63px;
    padding-left: 37px;

    td {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;

        &:last-child {
            font-size: 14px;
            width: 57px;
            text-align: left;
        }
    }
`;
