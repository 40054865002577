import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Icons, FotoUser, IlustracaoTelaVazia, theme } from "../../assets";
import {
    CollaboratorsOptions,
    Input,
    LoadingGray,
    ModalEditHours,
    ModalInviteChoose,
    SecondaryButton,
    Tooltip,
    PopoverProjectList,
    PopoverSectorList,
    PopoverRadix,
} from "../../components";
import { CheckBox } from "../../components/atoms/radix-checkbox/styled-radix-checkbox";
import { TutorialsPopover } from "../../components/molecules/tutorials-popover/tutorials-popover";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TNotification } from "../../models";
import { hourControl, invite, manageUser, roles } from "../../services";
import * as r from "../../styles/radio-group-styles";
import { Mixpanel } from "../../utils/mixpanel/Mixpanel";
import ModalEditCollaboratorsHours from "./modals/editHours/modal-edit-collaborators-hour";
import ModalEditUseCases from "./modals/editUseCases/modal-edit-use-cases";
import ModalNoUsersSelected from "./modals/noUsersSelected/modal-no-users-selected";
import * as s from "./styled-collaborators";

export type TCollaborators = {
    name: string;
    avatar?: string;
    contract?: string;
    id_e?: string;
    Projects?: { name: string; color: string }[];
    Sectors?: string[];
    Role?: { name: string };
    Notifications: TNotification[];
    ContractFormat?: {
        id_e: string;
        name: string;
        workload: number;
    };
    workload: number;
    hoursLock?: boolean;
    active: boolean;
};

type TToggleCollaboratorsSelectionProps = {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    id: string | undefined;
};

type TLockCollaboratorHoursProps = {
    event: React.MouseEvent<HTMLElement, MouseEvent>;
    userId: string | undefined;
};

type TStatusFilter = "active" | "inactive" | "all";
type TContractFilter = "clt" | "pj" | "estagio" | "all";

type TListUsersParams = {
    admin: boolean;
    page?: number;
    filter?: string;
    active?: boolean;

    contractFilter?: string[];
    roleFilter?: string[];
    descendingMode?: boolean;
};

type TGetCollaboratorsService = {
    pageModeParam?: "pagination" | "all";
    statusFilterParam?: TStatusFilter;
    contractFilterParam?: TContractFilter;
    roleFilterParam?: string;
    descendingModeParam?: boolean;
};

type TypeGetRoleName = {
    name: string;
}[];

export const Collaborators = () => {
    const { user, verifyPermission } = useUser();
    const firstSearch = useRef(true);

    const [loading, setLoading] = useState(true);
    const [pageMode, setPageMode] = useState<"all" | "pagination" | undefined>(
        "pagination"
    );
    const [collaborators, setCollaborators] = useState<TCollaborators[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [allSelected, setAllSelected] = useState(false);
    const [selectedCollaboratorsIds, setSelectedCollaboratorsIds] = useState<
        string[]
    >([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState<TStatusFilter>("active");
    const [contractFilter, setContractFilter] =
        useState<TContractFilter>("all");
    const [roleFilter, setRoleFilter] = useState<string>("all");
    const [descendingMode, setDescendingMode] = useState<boolean>(false);
    const [seeMoreOrLessLoading, setSeeMoreOrLessLoading] = useState(false);
    const [isOpenModalNoUsersSelected, setIsOpenModalNoUsersSelected] =
        useState(false);
    const [isOpenModalEditUseCase, setIsOpenModalEditUseCase] = useState(false);
    const [isOpenModalEditHours, setIsOpenModalEditHours] = useState(false);
    const [isOpenModalAddCollaborators, setIsOpenModalAddCollaborators] =
        useState(false);

    const navigate = useNavigate();

    const viewUsersPermission = verifyPermission({
        id: "professionals",
        rule: "view",
    });

    const manageUsersPermission = verifyPermission({
        id: "professionals",
        rule: "manage",
    });

    const listUsers = useFetch({
        fn: manageUser.getCollaborators,
        params: {
            admin: verifyPermission({
                id: "professionals",
                rule: "manage",
            }),
            page: 1,
        } as TListUsersParams,
    });

    const getRoles = useFetch({
        fn: roles.getRoleNames,
        start: true,
    });

    const getSketches = useFetch({
        fn: invite.getSketches,
        start: false,
    });
    const getInvites = useFetch({
        fn: invite.getInvites,
        start: false,
    });

    const updateWorkload = useFetch({
        fn: hourControl.updateCollaboratorsWorkload,
        start: false,
    });

    const updateCollaboratorsWorkload = useFetch({
        fn: hourControl.massToggleLockCollaborators,
        start: false,
    });

    const toggleLockCollaborator = useFetch({
        fn: hourControl.toggleLockCollaborator,
        start: false,
    });

    const organizationRoles: TypeGetRoleName = getRoles.response;

    const verifyIfAllCollaboratorsAreSelected = (
        collaborators: TCollaborators[]
    ) => {
        if (!collaborators.length || !selectedCollaboratorsIds.length) return;

        const allSelected = collaborators.every(({ id_e }) => {
            return id_e && selectedCollaboratorsIds.includes(id_e);
        });

        setAllSelected(allSelected);
    };

    const filterCollaboratorsService = ({
        pageModeParam = pageMode,
        statusFilterParam = statusFilter,
        contractFilterParam = contractFilter,
        roleFilterParam = roleFilter,
        descendingModeParam = descendingMode,
    }: TGetCollaboratorsService = {}) => {
        const pageOption = pageModeParam === "pagination" ? 1 : undefined;
        let active;
        const roleFilterArray =
            roleFilterParam !== "all" ? [roleFilterParam] : [];
        const contractFilterArray =
            contractFilterParam !== "all" ? [contractFilterParam] : [];
        if (statusFilterParam !== "all") {
            active = statusFilterParam === "active";
        }

        const params = {
            admin: manageUsersPermission,
            active,
            page: pageOption,
            contractFilter: contractFilterArray,
            descendingMode: descendingModeParam,
            roleFilter: roleFilterArray,
            filter: searchValue,
        } as TListUsersParams;

        setStatusFilter(statusFilterParam);
        setContractFilter(contractFilterParam);
        setRoleFilter(roleFilterParam);
        setDescendingMode(descendingModeParam);
        setPageMode(pageOption === undefined ? "all" : "pagination");

        if (pageOption === undefined) {
            setSeeMoreOrLessLoading(true);
        }

        listUsers.onRefresh(params);
    };

    function transformTargetToValue<T = string>(target: HTMLInputElement): T {
        return target.value as unknown as T;
    }

    useEffect(() => {
        if (selectedCollaboratorsIds.length === 0) return;
        verifyIfAllCollaboratorsAreSelected(collaborators);
    }, [selectedCollaboratorsIds]);

    useEffect(() => {
        if (listUsers.response) {
            setCollaborators(listUsers.response.collaborators);
            setLoading(false);
            setSeeMoreOrLessLoading(false);
        }
        if (listUsers.response && selectedCollaboratorsIds.length > 0) {
            verifyIfAllCollaboratorsAreSelected(
                listUsers.response.collaborators
            );
        }
    }, [listUsers.response]);

    useEffect(() => {
        const delay = setTimeout(() => {
            filterCollaboratorsService({
                pageModeParam: pageMode,
            });
        }, 400);

        if (searchValue.length > 1) {
            return () => {
                clearTimeout(delay);
                setPageMode("pagination");
                firstSearch.current = false;
            };
        }

        if (searchValue.length === 0 && firstSearch.current === false) {
            return () => {
                clearTimeout(delay);
                setPageMode("pagination");
                firstSearch.current = false;
            };
        }
        return clearTimeout(delay);
    }, [searchValue]);

    useEffect(() => {
        if (updateWorkload.response) {
            toast.success("Horas definidas com sucesso");
            setIsEditModalOpen(false);
            setIsOpenModalEditHours(false);
            filterCollaboratorsService();
        }
    }, [updateWorkload.response]);

    useEffect(() => {
        if (updateCollaboratorsWorkload.response) {
            toast.success("Regras de uso dos usuários definidas com sucesso");
            setIsOpenModalEditUseCase(false);
            filterCollaboratorsService();
        }
    }, [updateCollaboratorsWorkload.response]);

    useEffect(() => {
        if (toggleLockCollaborator.response) {
            const message = toggleLockCollaborator.response.hoursLock
                ? "travadas"
                : "destravadas";
            toast.success(`Horas ${message} com sucesso`);
            const collaboratorsUpdated = collaborators.map((collaborator) => {
                if (
                    collaborator.id_e === toggleLockCollaborator.response.id_e
                ) {
                    collaborator.hoursLock =
                        toggleLockCollaborator?.response?.hoursLock;
                }
                return collaborator;
            });
            setCollaborators(collaboratorsUpdated);
        }
    }, [toggleLockCollaborator.response]);

    useEffect(() => {
        if (getInvites.response) {
            const redirectionType = getSketches.response
                ? "rascunhos"
                : "enviados";
            navigate("/profissionais/convites", {
                state: {
                    redirectionType,
                },
            });
        }
    }, [getInvites.response]);

    const toggleMultipleCollaboratorsSelection = () => {
        setAllSelected(!allSelected);

        const collaboratorsId = collaborators?.map(
            ({ id_e }) => id_e as string
        );

        if (!allSelected) {
            const uniqueCollaborators = [
                ...new Set([...selectedCollaboratorsIds, ...collaboratorsId]),
            ];
            setSelectedCollaboratorsIds(uniqueCollaborators);
            return;
        }

        const idsAfterRemoving = selectedCollaboratorsIds.filter((id) => {
            return !collaboratorsId.includes(id);
        });

        setSelectedCollaboratorsIds([...idsAfterRemoving]);
    };

    const removeCollaboratorIndex = (id: string) => {
        selectedCollaboratorsIds.splice(
            selectedCollaboratorsIds.indexOf(id),
            1
        );
    };

    const toggleSingleCollaboratorSelection = ({
        event,
        id,
    }: TToggleCollaboratorsSelectionProps) => {
        event.stopPropagation();
        if (!id) return;

        if (selectedCollaboratorsIds.includes(id)) {
            removeCollaboratorIndex(id);
            setAllSelected(false);
            setSelectedCollaboratorsIds([...selectedCollaboratorsIds]);
            return;
        }
        setSelectedCollaboratorsIds([...selectedCollaboratorsIds, id]);
    };

    const lockWorkload = ({ event, userId }: TLockCollaboratorHoursProps) => {
        event.stopPropagation();
        toggleLockCollaborator.onRefresh({
            userId,
        });
    };

    const openEditHoursModal = () => {
        setIsEditModalOpen(!isEditModalOpen);
    };

    const editSingleCollaboratorHours = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        id: string | undefined
    ) => {
        event.stopPropagation();
        if (!id) return;
        setSelectedCollaboratorsIds([id]);
        openEditHoursModal();
    };

    const setHours = (hours: string) => {
        updateWorkload.onRefresh({
            usersIds: selectedCollaboratorsIds,
            workloadValue: hours,
        });
    };

    const setUseCase = (locked: boolean) => {
        updateCollaboratorsWorkload.onRefresh({
            usersId: selectedCollaboratorsIds,
            lock: locked,
        });
    };

    const updateUser = useFetch({
        fn: manageUser.updateUser,
        start: false,
    });

    const handleToggleAccountActivation = (collaborator: TCollaborators) => {
        const userId = collaborator.id_e;
        const newStatus = !collaborator.active;

        setCollaborators((prev) =>
            prev.map((col) =>
                col.id_e === userId ? { ...col, active: newStatus } : col
            )
        );

        updateUser.onRefresh({
            userId,
            userData: {
                active: newStatus,
            },
        });
    };

    const renderCollaborators = () => {
        if (loading)
            return (
                <s.LoadingWrapper>
                    <LoadingGray />
                </s.LoadingWrapper>
            );
        if (!collaborators?.length) {
            return (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Sem dados a serem mostrados</h2>
                    {collaborators.length > 0 && (
                        <p>Adicione novos profissionais!</p>
                    )}
                </s.NoContent>
            );
        }
        return (
            <tbody>
                {collaborators.map(
                    (collaborator: TCollaborators, index: number) => {
                        const userUrl = collaborator.name
                            ?.replaceAll(" ", "-")
                            .toLowerCase()
                            .normalize("NFD")
                            .replace(/\p{Diacritic}/gu, "");

                        return (
                            <s.TableRow
                                key={collaborator.id_e}
                                hasClick={
                                    viewUsersPermission ||
                                    collaborator.id_e === user.id_e
                                }
                                onClick={(
                                    event: React.MouseEvent<HTMLTableRowElement>
                                ) => {
                                    // Verifica se o clique foi no botão de status
                                    if (
                                        (event.target as HTMLElement).closest(
                                            "s.StatusButton"
                                        )
                                    ) {
                                        return; // Se o clique foi no botão de status, não faz nada
                                    }

                                    if (
                                        viewUsersPermission ||
                                        collaborator.id_e === user.id_e
                                    ) {
                                        navigate(`/profissional/${userUrl}`, {
                                            state: {
                                                id: collaborator.id_e,
                                                name: collaborator.name,
                                            },
                                        });
                                    }
                                }}
                            >
                                <td>
                                    {manageUsersPermission && (
                                        <CheckBox
                                            onClick={(event) =>
                                                toggleSingleCollaboratorSelection(
                                                    {
                                                        id: collaborator.id_e,
                                                        event,
                                                    }
                                                )
                                            }
                                            key={index}
                                            checked={selectedCollaboratorsIds.includes(
                                                collaborator.id_e || ""
                                            )}
                                        >
                                            <Icons.Checked></Icons.Checked>
                                        </CheckBox>
                                    )}
                                </td>

                                <s.CollaboratorName>
                                    <img
                                        className="photo"
                                        src={collaborator.avatar || FotoUser}
                                        alt={""}
                                    />

                                    <a title={collaborator.name}>
                                        {collaborator.name || ""}
                                    </a>
                                </s.CollaboratorName>

                                {manageUsersPermission && (
                                    <td>
                                        <span className="contract">
                                            {collaborator.ContractFormat?.name}
                                        </span>
                                    </td>
                                )}

                                <td>
                                    <span className="role">
                                        {collaborator.Role?.name}
                                    </span>
                                </td>

                                <s.Acting>
                                    <td>
                                        <Tooltip
                                            side="bottom"
                                            content={
                                                <PopoverSectorList
                                                    sectors={
                                                        collaborator?.Sectors
                                                    }
                                                />
                                            }
                                        >
                                            <span>
                                                <Icons.Template
                                                    color={theme.gray600}
                                                />
                                            </span>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <Tooltip
                                            side="bottom"
                                            content={
                                                <PopoverProjectList
                                                    projects={
                                                        collaborator?.Projects
                                                    }
                                                />
                                            }
                                        >
                                            <span>
                                                <Icons.Tag
                                                    color={theme.gray600}
                                                />
                                            </span>
                                        </Tooltip>
                                    </td>
                                </s.Acting>

                                <s.Hours>
                                    <s.Workload
                                        onClick={(event) => {
                                            if (manageUsersPermission) {
                                                editSingleCollaboratorHours(
                                                    event,
                                                    collaborator.id_e
                                                );
                                            }
                                        }}
                                    >
                                        {collaborator?.workload
                                            ? `${collaborator?.workload}:00`
                                            : "Sem horas previstas"}
                                    </s.Workload>
                                </s.Hours>

                                <Tooltip
                                    side="bottom"
                                    content={
                                        <s.lockedUseCaseToolTip>
                                            <header>
                                                {collaborator.hoursLock ? (
                                                    <>
                                                        <Icons.iconLockClosedFill
                                                            width="24px"
                                                            height="24px"
                                                            color={
                                                                theme.gray600
                                                            }
                                                        />
                                                        <h1>Obrigatório</h1>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Icons.IconLockOpen
                                                            width="24px"
                                                            height="24px"
                                                            color={
                                                                theme.gray200
                                                            }
                                                        />
                                                        <h1>Flexível</h1>
                                                    </>
                                                )}
                                            </header>

                                            {collaborator.hoursLock ? (
                                                <>
                                                    <p>
                                                        O profissional deve
                                                        trabalhar o número de
                                                        horas previstas, sem
                                                        ultrapassá-las.
                                                    </p>
                                                    <p>
                                                        Clique no cadeado para
                                                        trocar para Flexível.
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        O profissional pode
                                                        trabalhar menos ou mais
                                                        horas do que o previsto,
                                                        combinando com o gestor.
                                                    </p>
                                                    <p>
                                                        Clique no cadeado para
                                                        trocar para Obrigatório.
                                                    </p>
                                                </>
                                            )}
                                        </s.lockedUseCaseToolTip>
                                    }
                                >
                                    <s.LockHours
                                        onClick={(event) => {
                                            if (manageUsersPermission) {
                                                lockWorkload({
                                                    userId: collaborator.id_e,
                                                    event,
                                                });
                                            }
                                        }}
                                    >
                                        {collaborator.hoursLock && (
                                            <>
                                                <Icons.iconLockClosedFill
                                                    width="24px"
                                                    height="24px"
                                                    color={theme.gray600}
                                                />{" "}
                                                Obrigatório
                                            </>
                                        )}
                                        {!collaborator.hoursLock && (
                                            <>
                                                <Icons.IconLockOpen
                                                    width="24px"
                                                    height="24px"
                                                    color={theme.gray200}
                                                />{" "}
                                                Flexível
                                            </>
                                        )}
                                    </s.LockHours>
                                </Tooltip>

                                <s.StatusButton
                                    $active={collaborator.active}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleToggleAccountActivation(
                                            collaborator
                                        );
                                    }}
                                >
                                    {collaborator.active ? "Ativo" : "Inativo"}
                                </s.StatusButton>
                            </s.TableRow>
                        );
                    }
                )}
            </tbody>
        );
    };

    return (
        <s.Container onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>
            {isEditModalOpen && (
                <ModalEditHours
                    selectedCollaboratorsQuantity={
                        selectedCollaboratorsIds.length
                    }
                    setHours={setHours}
                    setOpen={setIsEditModalOpen}
                />
            )}
            {isOpenModalNoUsersSelected && (
                <ModalNoUsersSelected setOpen={setIsOpenModalNoUsersSelected} />
            )}
            {isOpenModalEditHours && (
                <ModalEditCollaboratorsHours
                    selectedCollaboratorsQuantity={
                        selectedCollaboratorsIds.length
                    }
                    setOpen={setIsOpenModalEditHours}
                    setHours={setHours}
                />
            )}
            {isOpenModalEditUseCase && (
                <ModalEditUseCases
                    selectedCollaboratorsQuantity={
                        selectedCollaboratorsIds.length
                    }
                    setOpen={setIsOpenModalEditUseCase}
                    setCollaboratorsUseCase={setUseCase}
                />
            )}
            {isOpenModalAddCollaborators && (
                <ModalInviteChoose
                    setOpen={setIsOpenModalAddCollaborators}
                    getInvites={getInvites}
                    getSketches={getSketches}
                />
            )}

            <CollaboratorsOptions />

            <TutorialsPopover
                screen="setores"
                tutorials={[
                    {
                        url: "uwhgmEUnN3A",
                        label: "Convidar usuários",
                    },
                ]}
                className="tutorials-popover"
            />

            <s.Details>
                <header>
                    <form id="filter">
                        <Input
                            mask=""
                            type="text"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            value={searchValue}
                            icon={<Icons.SearchOutline />}
                            placeholder="Pesquise por profissional, atuação, setor ou projeto."
                            disabled={loading}
                        />
                    </form>
                    {manageUsersPermission && (
                        <div
                            onClick={() => {
                                setIsOpenModalAddCollaborators(true);
                                Mixpanel.track(
                                    "Botão de adicionar colaborador"
                                );
                            }}
                            className="buttonAddPeople"
                        >
                            <span>ADICIONAR</span>
                            <Icons.UserAdd color={theme.white} />
                        </div>
                    )}
                </header>
                {manageUsersPermission && (
                    <s.EditOptions>
                        <div
                            onClick={() => {
                                if (selectedCollaboratorsIds.length > 0) {
                                    setIsOpenModalEditHours(true);
                                } else {
                                    setIsOpenModalNoUsersSelected(true);
                                }
                            }}
                        >
                            <Icons.EditOutline />
                            <span>HORAS PREVISTAS</span>
                        </div>
                        <div
                            onClick={() => {
                                if (selectedCollaboratorsIds.length > 0) {
                                    setIsOpenModalEditUseCase(true);
                                } else {
                                    setIsOpenModalNoUsersSelected(true);
                                }
                            }}
                        >
                            <Icons.EditOutline />
                            <span>REGRAS DE USO</span>
                        </div>
                    </s.EditOptions>
                )}
                <s.CollaboratorsTable
                    manager={manageUsersPermission}
                    onlyView={viewUsersPermission && !manageUsersPermission}
                >
                    <thead>
                        <tr>
                            <s.TableHeading>
                                {manageUsersPermission && (
                                    <CheckBox
                                        checked={allSelected}
                                        onClick={
                                            toggleMultipleCollaboratorsSelection
                                        }
                                    >
                                        <Icons.Checked></Icons.Checked>
                                    </CheckBox>
                                )}
                            </s.TableHeading>

                            <s.TableHeading>
                                <span
                                    className="profissionalColumn"
                                    onClick={() => {
                                        filterCollaboratorsService({
                                            descendingModeParam:
                                                !descendingMode,
                                        });
                                    }}
                                >
                                    Profissional{" "}
                                    <p>
                                        ({listUsers?.response?.total_entries})
                                    </p>
                                    {descendingMode ? (
                                        <Icons.IconArrowWithDirectionOption direction="up" />
                                    ) : (
                                        <Icons.IconArrowWithDirectionOption direction="down" />
                                    )}
                                </span>
                            </s.TableHeading>

                            {manageUsersPermission && (
                                <PopoverRadix
                                    side="top"
                                    align="start"
                                    sideOffset={10}
                                    trigger={
                                        <s.TableHeading
                                            style={{ cursor: "pointer" }}
                                        >
                                            Contratação
                                            {contractFilter !== "all" ? (
                                                <Icons.Filler
                                                    width="20px"
                                                    height="20px"
                                                />
                                            ) : (
                                                <Icons.FillerOutlined
                                                    color={theme.gray600}
                                                />
                                            )}
                                        </s.TableHeading>
                                    }
                                >
                                    <r.RadioGroupRoot
                                        value={contractFilter}
                                        onClick={(e) => {
                                            const target =
                                                e.target as HTMLInputElement;

                                            if (target.tagName === "BUTTON") {
                                                filterCollaboratorsService({
                                                    contractFilterParam:
                                                        transformTargetToValue<TContractFilter>(
                                                            target
                                                        ),
                                                });
                                            }
                                        }}
                                    >
                                        <r.RadioWrapper>
                                            <r.RadioGroupItem
                                                value="clt"
                                                id="clt"
                                            >
                                                <r.RadioGroupIndicator />
                                            </r.RadioGroupItem>
                                            <label htmlFor="clt">CLT</label>
                                        </r.RadioWrapper>
                                        <r.RadioWrapper>
                                            <r.RadioGroupItem
                                                value="pj"
                                                id="pj"
                                            >
                                                <r.RadioGroupIndicator />
                                            </r.RadioGroupItem>
                                            <label htmlFor="pj">PJ</label>
                                        </r.RadioWrapper>
                                        <r.RadioWrapper>
                                            <r.RadioGroupItem
                                                value="estagio"
                                                id="estagio"
                                            >
                                                <r.RadioGroupIndicator />
                                            </r.RadioGroupItem>
                                            <label htmlFor="estagio">
                                                Estágio
                                            </label>
                                        </r.RadioWrapper>
                                        <r.RadioWrapper>
                                            <r.RadioGroupItem
                                                value="all"
                                                id="all"
                                            >
                                                <r.RadioGroupIndicator />
                                            </r.RadioGroupItem>
                                            <label htmlFor="all">Todos</label>
                                        </r.RadioWrapper>
                                    </r.RadioGroupRoot>
                                </PopoverRadix>
                            )}

                            <PopoverRadix
                                side="bottom"
                                align="start"
                                sideOffset={10}
                                trigger={
                                    <s.TableHeading
                                        style={{ cursor: "pointer" }}
                                    >
                                        Atuação
                                        {roleFilter !== "all" ? (
                                            <Icons.Filler
                                                width="20px"
                                                height="20px"
                                            />
                                        ) : (
                                            <Icons.FillerOutlined
                                                color={theme.gray600}
                                            />
                                        )}
                                    </s.TableHeading>
                                }
                            >
                                <r.RadioGroupRoot
                                    value={roleFilter}
                                    onClick={(e) => {
                                        const target =
                                            e.target as HTMLInputElement;

                                        if (target.tagName === "BUTTON") {
                                            filterCollaboratorsService({
                                                roleFilterParam:
                                                    transformTargetToValue<string>(
                                                        target
                                                    ),
                                            });
                                        }
                                    }}
                                >
                                    <r.RadioWrapper className="roleFilters">
                                        <r.RadioGroupItem value="all" id="all">
                                            <r.RadioGroupIndicator />
                                        </r.RadioGroupItem>
                                        <label htmlFor="all">Todos</label>
                                    </r.RadioWrapper>

                                    {organizationRoles?.length > 0
                                        ? organizationRoles.map((role) => {
                                              return (
                                                  <r.RadioWrapper
                                                      className="roleFilters"
                                                      key={role.name}
                                                  >
                                                      <r.RadioGroupItem
                                                          value={role.name}
                                                          id={role.name}
                                                      >
                                                          <r.RadioGroupIndicator />
                                                      </r.RadioGroupItem>
                                                      <label
                                                          htmlFor={role.name}
                                                      >
                                                          {role.name}
                                                      </label>
                                                  </r.RadioWrapper>
                                              );
                                          })
                                        : null}
                                </r.RadioGroupRoot>
                            </PopoverRadix>

                            <s.TableHeading>Atuação</s.TableHeading>
                            <s.TableHeading>
                                <span className="expectedHours">
                                    Horas previstas
                                </span>
                            </s.TableHeading>

                            <s.TableHeading>
                                <span className="useCaseText">
                                    <span className="useCase">Regras</span>
                                    <Tooltip
                                        side="top"
                                        align={"start"}
                                        positiony={10}
                                        content={
                                            <>
                                                <s.UseCaseTooltip>
                                                    <div>
                                                        <strong>
                                                            Flexível
                                                        </strong>
                                                        <p>
                                                            O profissional
                                                            poderá trabalhar
                                                            menos ou mais horas
                                                            do que o previsto,
                                                            mantendo o
                                                            cronômetro sempre
                                                            ativo.
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <strong>
                                                            Obrigatório
                                                        </strong>
                                                        <p>
                                                            O profissional
                                                            deverá trabalhar o
                                                            horário previsto,
                                                            sem poder
                                                            ultrapassá-las, e o
                                                            cronômetro será
                                                            inativado ao atingir
                                                            o limite das horas
                                                            previstas.
                                                        </p>
                                                    </div>
                                                </s.UseCaseTooltip>
                                            </>
                                        }
                                    >
                                        <span>
                                            de Uso{" "}
                                            <Icons.Info
                                                color={theme.gray800}
                                                height="16px"
                                                width="16px"
                                            />
                                        </span>
                                    </Tooltip>
                                </span>
                            </s.TableHeading>

                            <PopoverRadix
                                side="top"
                                align="start"
                                sideOffset={10}
                                trigger={
                                    <s.TableHeading
                                        style={{ cursor: "pointer" }}
                                    >
                                        <p>Status</p>
                                        {statusFilter !== "all" ? (
                                            <Icons.Filler
                                                width="20px"
                                                height="20px"
                                            />
                                        ) : (
                                            <Icons.FillerOutlined
                                                color={theme.gray600}
                                            />
                                        )}
                                    </s.TableHeading>
                                }
                            >
                                <r.RadioGroupRoot
                                    value={statusFilter}
                                    onClick={(e) => {
                                        const target =
                                            e.target as HTMLInputElement;

                                        if (target.tagName === "BUTTON") {
                                            filterCollaboratorsService({
                                                statusFilterParam:
                                                    transformTargetToValue<TStatusFilter>(
                                                        e.target as HTMLInputElement
                                                    ),
                                            });
                                        }
                                    }}
                                >
                                    <r.RadioWrapper>
                                        <r.RadioGroupItem
                                            value="active"
                                            id="active"
                                        >
                                            <r.RadioGroupIndicator />
                                        </r.RadioGroupItem>
                                        <label htmlFor="active">Ativo</label>
                                    </r.RadioWrapper>

                                    <r.RadioWrapper>
                                        <r.RadioGroupItem
                                            value="inactive"
                                            id="inactive"
                                        >
                                            <r.RadioGroupIndicator />
                                        </r.RadioGroupItem>
                                        <label htmlFor="inactive">
                                            Inativo
                                        </label>
                                    </r.RadioWrapper>

                                    <r.RadioWrapper>
                                        <r.RadioGroupItem value="all" id="all">
                                            <r.RadioGroupIndicator />
                                        </r.RadioGroupItem>
                                        <label htmlFor="all">Todos</label>
                                    </r.RadioWrapper>
                                </r.RadioGroupRoot>
                            </PopoverRadix>
                        </tr>
                    </thead>
                    {selectedCollaboratorsIds.length > 0 && (
                        <s.SelectedRowInformation>
                            <td
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                    cursor: "pointer",
                                    width: "100%",
                                }}
                            >
                                <Icons.XOutline
                                    onClick={() => {
                                        setSelectedCollaboratorsIds([]);
                                    }}
                                />
                                <span>{selectedCollaboratorsIds.length}</span>
                                <span>selecionados</span>
                            </td>
                        </s.SelectedRowInformation>
                    )}
                    {renderCollaborators()}
                </s.CollaboratorsTable>
            </s.Details>

            {collaborators.length > 0 && (
                <s.Footer>
                    {!seeMoreOrLessLoading && collaborators.length >= 8 && (
                        <SecondaryButton
                            variation="big"
                            onClick={() => {
                                filterCollaboratorsService({
                                    pageModeParam:
                                        pageMode === "pagination"
                                            ? "all"
                                            : "pagination",
                                });
                            }}
                        >
                            {(() => {
                                let textToBeDisplayed = null;
                                if (!seeMoreOrLessLoading) {
                                    if (pageMode === "pagination") {
                                        textToBeDisplayed = `VER TODOS (${listUsers.response.total_entries})`;
                                    } else {
                                        textToBeDisplayed = "VER MENOS";
                                    }
                                }
                                return textToBeDisplayed;
                            })()}
                        </SecondaryButton>
                    )}
                    {seeMoreOrLessLoading && "Aguarde..."}
                </s.Footer>
            )}
        </s.Container>
    );
};
