import { Icons, theme } from "../../../../../../assets";
import { SecondaryButton } from "../../../../../../components/atoms";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import * as s from "./styled-one-admin-modal";

type TOneAdminModal = {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OneAdminModal = ({ onOpenChange }: TOneAdminModal) => {
    return (
        <ContainerModal closeIcon onOpenChange={onOpenChange}>
            <s.Container>
                <s.Title>
                    <Icons.Warning color={theme.orangeWarning} />
                    Único administrador
                </s.Title>
                <s.Text>
                    Você é o único administrador da plataforma. Para inativar a
                    sua conta, você deverá transformar outro usuário em
                    administrador.
                </s.Text>
                <SecondaryButton onClick={() => onOpenChange(false)}>
                    OK
                </SecondaryButton>
            </s.Container>
        </ContainerModal>
    );
};
