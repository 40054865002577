import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useUser } from "../context";
import { useFetch } from "../hooks";
import { manageUser } from "../services";

type TPrivateRoutes = {
    isLogged: boolean;
};

export const PrivateRoute = ({ isLogged }: TPrivateRoutes) => {
    const { setUser } = useUser();

    const getUser = useFetch({
        fn: manageUser.getUser,
        params: {
            sectors: true,
            reportErrors: true,
        },
    });

    useEffect(() => {
        if (getUser.response) setUser(getUser.response);
    }, [getUser.response]);

    return <>{isLogged ? <Outlet /> : <Navigate to="/login" />}</>;
};
