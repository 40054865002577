import { Skill } from "../../components/molecules/modals/modal-skills/modal-skills";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type CreateSkillsParams = {
    skills?: Omit<Skill, "id_e">[];
    userId?: string;
};

export default async ({ skills, userId }: CreateSkillsParams = {}) => {
    const data = {
        skills,
    };

    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
    };

    if (!userId) delete tempHeaders["x-user-id"];

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/skills`,
        method: "post",
        headers: tempHeaders,
        body: data,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
