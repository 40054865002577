import styled from "styled-components";

export const StyledContainer = styled.div`
    width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledTitle = styled.h2`
    color: var(--color-brand-purple-600, #503479);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const StyledInformation = styled.p`
    color: var(--color-gray-600, #6e697a);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;

    span {
        font-weight: 600;
    }
`;

export const StyledHoursInput = styled.div`
    width: 100px;
`;

export const StyledButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 32px;
    padding: 16px;
`;
