import * as yup from "yup";

import { validationErrors } from "../../../../../utils";

const { role, contract, hourValue, workload } = validationErrors;

export default yup.object().shape({
    manageProfessionalsValues: yup.boolean(),
    role: yup.string().required(role.required),
    contract: yup.string().required(contract.required),
    workload: yup.string().when("manageProfessionalsValues", {
        is: true,
        then: yup
            .string()
            .required(workload.required)
            .min(2, "Digite a carga horária"),
    }),
    hourValue: yup.string().when("manageProfessionalsValues", {
        is: true,
        then: yup
            .string()
            .required(hourValue.required)
            .min(7, "Digite o valor com centavos")
            .test(
                "it should be gratter than zero",
                hourValue.invalid,
                (value) => {
                    if (value) {
                        const valueNumber = Number(
                            value.slice(3).replace(",", ".")
                        );
                        if (valueNumber > 0) {
                            return true;
                        }
                    }
                    return false;
                }
            ),
    }),
    projects: yup
        .array()
        .of(
            yup.object().shape({
                value: yup.string(),
                label: yup.string(),
            })
        )
        .min(1, "Obrigatório no mínimo 1 projeto"),
    sectors: yup
        .array()
        .of(
            yup.object().shape({
                value: yup.string(),
                label: yup.string(),
            })
        )
        .min(1, "Obrigatório no mínimo 1 setor"),
});
