import styled from "styled-components";

import { theme } from "../../assets";

type TState = { isFocused?: boolean; isSelected?: boolean };

type TTriangle = {
    open?: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;
`;

export const Details = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;

    header#details {
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 99999999;
        justify-content: space-between;
        padding: 2rem;
        height: 130px;

        div#time {
            display: flex;
            flex-direction: column;

            span#text-time {
                font-size: 0.75rem;
                color: ${({ theme }) => theme.gray400};
                align-self: flex-end;
            }

            span#total-time {
                font-size: 2.125rem;
            }
        }
    }

    div#filters {
        display: flex;
        align-items: center;
        padding: 0rem 2rem 5rem;
    }
`;

export const ActivitiesTable = styled.table`
    display: flex;
    flex-direction: column;

    tr {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 0 40px;
        align-items: center;
        gap: 10px;
        border-top: 1px solid ${({ theme }) => theme.gray200};
        text-align: left;

        th:first-child {
            span {
                float: left;
            }
        }

        span.heading-text {
            font-size: 0.75rem;
            color: ${({ theme }) => theme.gray400};
            font-weight: 600;
        }
    }

    thead {
        tr {
            height: 52px;
        }
    }

    tbody {
        tr {
            min-height: 60px;
            padding: 16px 40px;
            border-top: 1px solid ${({ theme }) => theme.gray200};
        }

        tr {
            td {
                display: flex;
                gap: 20px;

                button.expand {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 42px;
                    padding: 0 5px;
                    border: 1px solid ${({ theme }) => theme.gray400};
                    border-radius: 2px;
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray600};
                }

                span.duration,
                span.time,
                span.date {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray800};
                }
            }

            td.entry {
                align-items: center;

                span.name {
                    color: ${({ theme }) => theme.gray600};
                }
            }

            span {
                font-size: 0.875rem;
            }

            span.project-tag {
                margin-right: unset;
                min-width: fit-content;
            }

            span.client {
                color: ${({ theme }) => theme.gray400};
                min-width: fit-content;
            }
        }

        tr.activity-instance {
            background: ${({ theme }) => theme.gray50};

            td:first-child {
                margin-left: 62px;
            }
        }
    }
`;

export const Triangle = styled.span<TTriangle>`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${({ theme }) => theme.gray600};
    transition: all 0.5s ease;

    ${({ open }) => open && `transform: rotate(-180deg)`}
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin: 2.625rem 0;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const SelectStyles = {
    control: (styles: any, state: TState) => ({
        ...styles,
        borderRadius: 5,
        color: "#484848",
        fontSize: 14,
        height: 32,
        minHeight: 32,
        border: state.isFocused
            ? `1px solid ${theme.purple500}`
            : `1px solid ${theme.gray400}`,
        boxShadow: state.isFocused ? `0 0 0 1px ${theme.purple500}` : "none",
        "&:hover": {
            border: `1px solid ${theme.purple500}`,
        },
    }),
    container: (styles: any) => ({
        ...styles,
        width: "150px",
        boxSizing: "border-box" as const,
        borderRadius: 5,
        minWidth: "unset",
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        height: 32,
    }),
    placeholder: (styles: any) => ({
        ...styles,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        outline: "none",
    }),
    option: (styles: any, state: TState) => ({
        ...styles,
        fontSize: 14,
        boxSizing: "border-box",
        height: "min-content",
        cursor: "pointer",
        width: "100%",
        backgroundColor: state.isSelected && theme.purple500,
        ":hover": { backgroundColor: !state.isSelected && theme.pink50 },
    }),
    dropdownIndicator: (styles: any) => ({
        ...styles,
        paddingTop: 6,
        paddingBottom: 6,
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        paddingTop: 6,
        paddingBottom: 6,
    }),
};
