import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Icons, theme } from "../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../components/atoms/menu/menu";
import { activities } from "../../../../../../../services";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import { DeleteConfirmationModal } from "../../modals/delete-confirmation-general/delete-confirmation-general";
import { ActivityDTO } from "../../types/activityDTO";
import { AddAttachment } from "./components/add-attachment/add-attachment";
import { AttachmentRow } from "./components/attachment-row/attachment-row";
import * as s from "./styles";

type TAttachments = {
    activity: ActivityDTO;
};

export const Attachments = ({ activity }: TAttachments) => {
    const SLICE_NUMBER = 3;

    const [addAttachment, setAddAttachment] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [slice, setSlice] = useState(SLICE_NUMBER);

    const options: TMenuAtom["options"] = [
        {
            label: "Deletar",
            action: () => {
                setOpenDeleteConfirmation(true);
            },
            color: theme.redError,
            icon: <Icons.Trash color={theme.redError} />,
        },
    ];

    const { mutate: deleteAttachment } = useMutation({
        mutationFn: () =>
            activities.deleteAllAttachments({
                activityId: activity.id_e,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    return (
        <>
            {openDeleteConfirmation && (
                <DeleteConfirmationModal
                    message="todos anexos"
                    onConfirm={deleteAttachment}
                    setOpen={setOpenDeleteConfirmation}
                />
            )}
            <s.Container>
                {Boolean(activity.ActivityAttachments.length) && (
                    <s.Header>
                        <s.Title>Anexos</s.Title>
                        <MenuAtom options={options}>
                            <s.Button>
                                <Icons.More />
                            </s.Button>
                        </MenuAtom>
                    </s.Header>
                )}
                {activity.ActivityAttachments.slice(0, slice).map(
                    (attachment) => (
                        <AttachmentRow
                            key={attachment.id_e}
                            attachment={attachment}
                            activity={activity}
                        />
                    )
                )}
                {slice === SLICE_NUMBER &&
                    activity.ActivityAttachments.length > SLICE_NUMBER && (
                        <s.SeeAll
                            onClick={() =>
                                setSlice(activity.ActivityAttachments.length)
                            }
                        >
                            VER TODOS ({activity.ActivityAttachments.length})
                        </s.SeeAll>
                    )}
                {slice !== SLICE_NUMBER && (
                    <s.SeeAll onClick={() => setSlice(SLICE_NUMBER)}>
                        VER MENOS
                    </s.SeeAll>
                )}
                {addAttachment ? (
                    <AddAttachment
                        activity={activity}
                        setAddAttachment={setAddAttachment}
                    />
                ) : (
                    <s.AddButton onClick={() => setAddAttachment(true)}>
                        <Icons.AttachFile color={theme.gray900} />
                        Adicionar arquivo ou link
                    </s.AddButton>
                )}
            </s.Container>
        </>
    );
};
