/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, IlustracaoTelaVazia } from "../../../assets";
import {
    BlockTooltip,
    LoadingGray,
    PopoverProjectList,
    PopoverUserList,
    Table,
    Tooltip,
} from "../../../components";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { sectors } from "../../../services";
import { getDateTimeFromIsoString } from "../../../utils";
import * as s from "./styled-sectors";

type TGetSectorsObject = {
    id_e: string;
    name: string;
    description: string;
    created_at: string;
    Users: {
        id_e: string;
        name: string;
        avatar?: string;
        permissions: string[];
    }[];
    Projects: {
        id_e: string;
        name: string;
        color?: string;
    }[];
};

export const Sectors = () => {
    const { verifyPermission } = useUser();
    const readSectorsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const manageSectors = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [sectorsData, setSectorsData] = useState<TGetSectorsObject[]>([]);

    const getSectors = useFetch({
        fn: sectors.getSector,
        start: true,
        params: {
            all: readSectorsPermission,
            users: true,
            projects: true,
            page: 0,
        },
    });

    useEffect(() => {
        if (getSectors.error) toast.error(`${getSectors.error}`);
        if (getSectors.response) {
            setSectorsData(getSectors.response.sectors);
            setLoading(false);
        }
    }, [getSectors.response]);

    const renderTableBody = () => {
        return sectorsData.map((sector, index) => {
            return {
                row: [
                    {
                        column: (
                            <BlockTooltip
                                key={index}
                                offset={12}
                                align="start"
                                side="bottom"
                                title="Descrição do setor"
                                trigger={
                                    <div className="left">
                                        {sector?.name || "Sem nome"}
                                    </div>
                                }
                            >
                                {sector?.description || "Sem descrição"}
                            </BlockTooltip>
                        ),
                    },
                    {
                        column: sector?.created_at
                            ? getDateTimeFromIsoString(sector?.created_at)
                            : "Sem informação",
                    },
                    {
                        column: (
                            <Tooltip
                                side="bottom"
                                content={
                                    <PopoverProjectList
                                        projects={sector?.Projects}
                                        message="Sem projetos vinculados!"
                                    />
                                }
                            >
                                <div className="pill">
                                    <p>{sector?.Projects.length || "-"}</p>
                                </div>
                            </Tooltip>
                        ),
                    },
                    {
                        column: (
                            <Tooltip
                                side="bottom"
                                content={
                                    <PopoverUserList users={sector?.Users} />
                                }
                            >
                                <div className="pill">
                                    <p>{sector?.Users.length || "-"}</p>
                                </div>
                            </Tooltip>
                        ),
                    },
                    {
                        column: manageSectors ? (
                            <button
                                onClick={() => {
                                    navigate(`${location.pathname}/editar`, {
                                        state: {
                                            sector,
                                        },
                                    });
                                }}
                            >
                                <Icons.EditOutline title={"Editar"} />
                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    navigate(
                                        `${location.pathname}/visualizar`,
                                        {
                                            state: {
                                                sector,
                                            },
                                        }
                                    );
                                }}
                            >
                                <Icons.Eye title={"Visualizar"} />
                            </button>
                        ),
                    },
                ],
            };
        });
    };

    return (
        <>
            {loading ? (
                <LoadingGray />
            ) : sectorsData.length ? (
                <Table
                    templateColumns="1fr 1fr 1fr 1fr 1fr"
                    header={[
                        {
                            column: (
                                <BlockTooltip
                                    offset={12}
                                    align="start"
                                    side="bottom"
                                    title="Setores"
                                    trigger={
                                        <div className="left">
                                            Setores <Icons.Info />
                                        </div>
                                    }
                                >
                                    Veja a descrição passando o mouse no nome do
                                    setor desejado
                                </BlockTooltip>
                            ),
                        },
                        { column: "Data de criação" },
                        {
                            column: (
                                <BlockTooltip
                                    offset={12}
                                    align="start"
                                    side="bottom"
                                    title="Projetos no setor"
                                    trigger={
                                        <>
                                            Projetos no setor <Icons.Info />
                                        </>
                                    }
                                >
                                    Veja quais são os projetos no setor passando
                                    o mouse no número da linha desejada
                                </BlockTooltip>
                            ),
                        },
                        {
                            column: (
                                <BlockTooltip
                                    offset={12}
                                    align="start"
                                    side="bottom"
                                    title="Integrantes no setor"
                                    trigger={
                                        <>
                                            Integrantes no setor <Icons.Info />
                                        </>
                                    }
                                >
                                    Veja quem são os integrantes do setor
                                    passando o mouse no número da linha desejada
                                </BlockTooltip>
                            ),
                        },
                        { column: manageSectors ? "Editar" : "Visualizar" },
                    ]}
                    body={renderTableBody()}
                />
            ) : (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Ainda não há registro de setor criado</h2>
                    <p>
                        Clique em <strong>CRIAR SETOR</strong> e comece agora a{" "}
                        <br /> organizar melhor seus projetos
                    </p>
                </s.NoContent>
            )}
        </>
    );
};
