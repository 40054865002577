import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import * as s from "./styled-tooltip";

export type TooltipProps = {
    children: JSX.Element;
    content: JSX.Element | JSX.Element[];
    open?: boolean;
    defaultOpen?: boolean;
    onOpenChange?: () => void;
    positionArrow?: number;
    error?: boolean;
    offset?: number;
    positionx?: number;
    positiony?: number;
    side?: "top" | "right" | "bottom" | "left";
    align?: "center" | "start" | "end";
    arrow?: boolean;
    disabled?: boolean;
    radius?: number;
};

export default ({
    children,
    content,
    open,
    defaultOpen,
    onOpenChange,
    positionArrow,
    error,
    offset,
    positionx,
    positiony,
    align,
    side,
    arrow,
    disabled,
    radius = 4,
}: TooltipProps) => (
    <TooltipPrimitive.TooltipProvider>
        <TooltipPrimitive.Root
            delayDuration={100}
            open={!disabled && open}
            defaultOpen={defaultOpen}
            onOpenChange={onOpenChange}
        >
            <TooltipPrimitive.Trigger asChild>
                {children}
            </TooltipPrimitive.Trigger>

            <s.StyledContent
                $arrow={arrow}
                offset={offset}
                positionx={positionx}
                positiony={positiony}
                error={error}
                side={side || "top"}
                align={align || "center"}
                position={positionArrow}
                radius={radius}
                className="tooltip-container"
            >
                {content}
            </s.StyledContent>
        </TooltipPrimitive.Root>
    </TooltipPrimitive.TooltipProvider>
);
