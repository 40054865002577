import { styled } from "styled-components";

import { Level } from "../../../models";

export const Container = styled.div<{ small?: boolean; level: Level }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ small }) => (small ? "5px" : "11px")};
    width: ${({ small }) => (small ? "21px" : "44px")};
    height: ${({ small }) => (small ? "21px" : "44px")};
    background-color: ${({ level, theme }) => theme.levels[level]};
    border-radius: 50%;

    svg {
        scale: ${({ small }) => (small ? "1" : "2")};
    }
`;
