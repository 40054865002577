/* eslint-disable no-nested-ternary */
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { validateEmail } from "validations-br";

import { LoadingGray, PrimaryButton } from "../../..";
import { Icons, theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { TReport } from "../../../../models";
import { timeReports } from "../../../../services";
import { formatToBRMoney } from "../../../../utils";
import SelectEmails from "../../../atoms/selects/select-emails/select-emails";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-document";

type TModalDocument = {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>;
    getReportsByPage: (page: number) => void;
    data?: TReport;
    emailsHistory?: string[];
};

type TDocuments = {
    invoices: Blob[] | File[] | string[] | undefined[];
    billets: Blob[] | File[] | string[] | undefined[];
    recipientsIds?: string;
    reportId: string;
};

const ModalDocument = ({
    setIsOpenModal,
    data,
    getReportsByPage,
    emailsHistory,
}: TModalDocument) => {
    const [invoices, setInvoices] = useState<File[]>([]);
    const [billets, setBillets] = useState<File[]>([]);
    const [emails, setEmails] = useState<string[]>(emailsHistory || []);
    const [emailsToSend, setEmailsToSend] = useState<string[]>([]);
    const [invoiceError, setInvoiceError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const fileSuccess = !invoiceError && !emailError;
    const hasDocuments =
        data?.invoices?.length ||
        data?.billets?.length ||
        data?.recipients?.length;

    const nfInputRef = useRef<HTMLInputElement>(null);
    const boletoInputRef = useRef<HTMLInputElement>(null);

    const { handleSubmit } = useForm<TDocuments>({});

    const reportDocs = useFetch({
        fn: timeReports.reportDocs,
        start: false,
        successMessage: "Documentos enviados!",
    });

    const handleAddInvoice = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        if (chosenFiles.length > 3) {
            toast.error("Você só pode enviar 3 notas ao mesmo tempo.");
            return;
        }

        const formattedFiles = chosenFiles.map((file) => {
            const formattedName = file.name
                ?.replaceAll(" ", "-")
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "");
            return new File([file], formattedName);
        });

        setInvoices(formattedFiles);
        setInvoiceError(false);
    };

    const handleAddBillet = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const chosenFiles = Array.prototype.slice.call(e.target.files);

        const formattedFiles = chosenFiles.map((file) => {
            const formattedName = file.name
                ?.replaceAll(" ", "-")
                .toLowerCase()
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "");
            return new File([file], formattedName);
        });

        setBillets(formattedFiles);
    };

    const handleRemoveNF = (file: File) => {
        const newFiles = invoices?.filter((f: File) => f.name !== file?.name);
        setInvoices(newFiles);
    };

    const handleRemoveBoleto = (file: File) => {
        const newFiles = billets?.filter((f: File) => f.name !== file?.name);
        setBillets(newFiles);
    };

    const verifyErrors = () => {
        if (invoices.length < 1) setInvoiceError(true);
        else setInvoiceError(false);

        if (emailsToSend.length < 1) setEmailError(true);
        else setEmailError(false);
    };

    const readFileName = (url: string) => {
        const fileNameWithNumber = url.split("/").pop();
        const fileName = fileNameWithNumber?.split("-").pop();
        return fileName;
    };

    const onSubmit = () => {
        if (fileSuccess) {
            const formContentType: FormData = new FormData();

            invoices.forEach((invoice) => {
                formContentType.append("invoices", invoice);
            });

            billets.forEach((billet) => {
                formContentType.append("billets", billet);
            });

            setLoading(true);

            reportDocs.onRefresh({
                recipientsIds: emailsToSend ? emailsToSend.join(", ") : "",
                reportId: data?.id_e,
                data: formContentType,
            });
        }
    };

    useEffect(() => {
        if (reportDocs.error) {
            setLoading(false);
            setIsOpenModal(false);
        }
        if (reportDocs.response) {
            toast.success("Documentos enviados com sucesso!");
            getReportsByPage(0);
            setLoading(false);
            setIsOpenModal(false);
        }
    }, [reportDocs.response, reportDocs.error]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter" || event.key === "Tab") {
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return (
        <ContainerModal
            closeOnIconOnly={!!loading}
            onOpenChange={setIsOpenModal}
        >
            {loading ? (
                <s.Loading>
                    <LoadingGray />
                </s.Loading>
            ) : (
                <s.Container read={!hasDocuments}>
                    <header>
                        <h1>
                            {hasDocuments ? "Documentos" : "Enviar documentos"}
                        </h1>
                    </header>
                    <div id="informacoes">
                        <span id="atributo">
                            Valor da nota Fiscal:{"  "}
                            <span id="info">
                                {formatToBRMoney(
                                    parseFloat(
                                        data?.ReportBilling?.final_total || "0"
                                    )
                                ) || "Indisponível"}
                            </span>
                        </span>
                        <span id="atributo">
                            Faturado por:{"  "}
                            <span id="info">
                                {data?.ReportBilling?.Author.name ||
                                    "Faturador"}
                            </span>
                        </span>
                        <span id="atributo">
                            Comentário:{"  "}
                            <span id="info">
                                {data?.ReportBilling?.comment ||
                                    "Sem comentários"}
                            </span>
                        </span>
                    </div>

                    <form id="content" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-file">
                            <div className="only-pdf">
                                {!hasDocuments
                                    ? "Nota fiscal (formato .PDF)"
                                    : "Nota fiscal"}
                            </div>
                            <s.DocumentWrapper>
                                {!hasDocuments && (
                                    <s.ButtonInput
                                        className="add-file"
                                        onClick={() =>
                                            nfInputRef?.current?.click()
                                        }
                                        type="button"
                                        fileError={invoiceError}
                                    >
                                        <span>
                                            {invoices.length === 0
                                                ? "Clique aqui e anexe sua nota fiscal"
                                                : "Arquivo Anexado"}
                                        </span>

                                        <Icons.AttachFile
                                            color={
                                                invoices.length === 0
                                                    ? theme.gray600
                                                    : theme.white3
                                            }
                                        />
                                    </s.ButtonInput>
                                )}
                                {hasDocuments ? (
                                    <s.DocumentList>
                                        <s.Scroll>
                                            <s.Viewport>
                                                {data?.invoices?.map(
                                                    (invoice, id) => (
                                                        <li
                                                            className="file-item"
                                                            key={id}
                                                        >
                                                            <a
                                                                href={invoice}
                                                                className="file-name"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {readFileName(
                                                                    invoice
                                                                )}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </s.Viewport>
                                        </s.Scroll>
                                    </s.DocumentList>
                                ) : (
                                    invoices.length > 0 && (
                                        <s.DocumentList>
                                            <s.Scroll>
                                                <s.Viewport>
                                                    {invoices?.map(
                                                        (invoice, id) => (
                                                            <li
                                                                className="file-item"
                                                                key={id}
                                                            >
                                                                <span className="file-name">
                                                                    {
                                                                        invoice?.name
                                                                    }
                                                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        handleRemoveNF(
                                                                            invoice
                                                                        )
                                                                    }
                                                                    type="button"
                                                                >
                                                                    <Icons.XOutline
                                                                        color={
                                                                            theme.purple500
                                                                        }
                                                                    />
                                                                </button>
                                                            </li>
                                                        )
                                                    )}
                                                </s.Viewport>
                                                <s.Scrollbar orientation="vertical">
                                                    <s.Thumb />
                                                </s.Scrollbar>
                                            </s.Scroll>
                                        </s.DocumentList>
                                    )
                                )}
                                {invoiceError ? (
                                    <span id="error">
                                        Anexe sua nota fiscal
                                    </span>
                                ) : null}
                            </s.DocumentWrapper>

                            <input
                                type="file"
                                onChange={(e) => handleAddInvoice(e)}
                                ref={nfInputRef}
                                style={{ display: "none" }}
                                accept=".pdf"
                                multiple
                            />
                        </div>
                        <div className="input-file">
                            <div className="only-pdf">
                                {!hasDocuments
                                    ? "Boleto (formato .PDF)"
                                    : "Boletos"}
                            </div>
                            <s.DocumentWrapper>
                                {!hasDocuments && (
                                    <s.ButtonInput
                                        className="add-file"
                                        onClick={() =>
                                            boletoInputRef?.current?.click()
                                        }
                                        type="button"
                                    >
                                        <span>
                                            {billets.length === 0
                                                ? "Clique aqui e anexe seu boleto"
                                                : "Arquivo Anexado"}
                                        </span>
                                        <Icons.AttachFile
                                            color={
                                                billets.length === 0
                                                    ? theme.gray600
                                                    : theme.white3
                                            }
                                        />
                                    </s.ButtonInput>
                                )}
                                {hasDocuments ? (
                                    <s.DocumentList>
                                        <s.Scroll>
                                            <s.Viewport>
                                                {data?.billets?.map(
                                                    (billet, id) => (
                                                        <li
                                                            className="file-item"
                                                            key={id}
                                                        >
                                                            <a
                                                                href={billet}
                                                                className="file-name"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                {readFileName(
                                                                    billet
                                                                )}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </s.Viewport>
                                        </s.Scroll>
                                    </s.DocumentList>
                                ) : (
                                    billets.length > 0 && (
                                        <s.DocumentList>
                                            <s.Scroll>
                                                <s.Viewport>
                                                    {billets?.map(
                                                        (billet, id) => (
                                                            <li
                                                                className="file-item"
                                                                key={id}
                                                            >
                                                                <span className="file-name">
                                                                    {
                                                                        billet.name
                                                                    }
                                                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        handleRemoveBoleto(
                                                                            billet
                                                                        )
                                                                    }
                                                                    type="button"
                                                                >
                                                                    <Icons.XOutline
                                                                        color={
                                                                            theme.purple500
                                                                        }
                                                                    />
                                                                </button>
                                                            </li>
                                                        )
                                                    )}
                                                </s.Viewport>
                                                <s.Scrollbar orientation="vertical">
                                                    <s.Thumb />
                                                </s.Scrollbar>
                                            </s.Scroll>
                                        </s.DocumentList>
                                    )
                                )}
                            </s.DocumentWrapper>
                            <input
                                type="file"
                                onChange={(e) => handleAddBillet(e)}
                                ref={boletoInputRef}
                                style={{ display: "none" }}
                                accept=".pdf"
                                multiple
                            />
                        </div>
                        <div className="input-file">
                            <div className="only-pdf">E-mail de envio</div>
                            <s.DocumentWrapper>
                                {!hasDocuments && (
                                    <>
                                        <SelectEmails
                                            placeholder="Insira o e-mail de quem receberá os documentos"
                                            emails={emails}
                                            setEmails={setEmails}
                                            positionY="0px"
                                            setEmailsToSend={setEmailsToSend}
                                            emailsToSend={emailsToSend}
                                            error={emailError}
                                        />
                                    </>
                                )}

                                {hasDocuments ? (
                                    <s.DocumentList>
                                        <s.Scroll>
                                            <s.Viewport>
                                                {data?.recipients?.map(
                                                    (email, id) => (
                                                        <li
                                                            className="file-item"
                                                            key={id}
                                                        >
                                                            <span className="file-name">
                                                                {email}
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </s.Viewport>
                                        </s.Scroll>
                                    </s.DocumentList>
                                ) : null}
                            </s.DocumentWrapper>
                        </div>

                        {hasDocuments ? (
                            <s.WrapperCertifications>
                                <span id="validated">
                                    <Icons.CheckCircle /> Documentos validados
                                </span>

                                <span id="no-alteration">
                                    Os documentos não podem ser alterados depois
                                    de validados
                                </span>
                            </s.WrapperCertifications>
                        ) : (
                            <div id="buttons">
                                <PrimaryButton
                                    onClick={() => verifyErrors()}
                                    type="submit"
                                >
                                    ENVIAR
                                </PrimaryButton>
                            </div>
                        )}
                    </form>
                </s.Container>
            )}
        </ContainerModal>
    );
};

export default ModalDocument;
