import { Dispatch } from "react";

import { theme } from "../../../../../assets";
import { SecondaryButton } from "../../../../../components";
import * as s from "./styled-header";

type THeaderProps = {
    edit: boolean | undefined;
    setShowDialogDelete: Dispatch<boolean>;
};

export const Header = ({ edit, setShowDialogDelete }: THeaderProps) => {
    return (
        <s.Container>
            {edit && (
                <div>
                    <SecondaryButton
                        children={"Deletar atuação"}
                        onClick={() => setShowDialogDelete(true)}
                        color={theme.red}
                        type="button"
                    />
                </div>
            )}
        </s.Container>
    );
};
