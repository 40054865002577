import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { PrimaryButton } from "../../../atoms";
import * as s from "./styled-organization-options";

type TOrganizationOptions = {
    disableButton?: boolean;
};

const OrganizationOptions = ({ disableButton }: TOrganizationOptions) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { verifyPermission } = useUser();
    const viewRolesPermission = verifyPermission({
        id: "roles_permissions",
        rule: "view",
    });
    const viewSectorsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const manageProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });

    const verifyLocation = (location: string) => {
        switch (location) {
            case "/organizacao/setores":
                return "SETOR";
            case "/organizacao/atuacoes":
                return "ATUAÇÃO";
            case "/projetos":
                return "PROJETO";
            default:
                return "SETOR";
        }
    };

    return (
        <s.Container>
            <s.UpperOptions>
                <s.Options>
                    {viewSectorsPermission && (
                        <div
                            className="option"
                            onClick={() => navigate("/organizacao/setores")}
                        >
                            <Icons.Template
                                color={
                                    location.pathname === "/organizacao/setores"
                                        ? theme.purple600
                                        : theme.gray400
                                }
                                title="Setores"
                            />

                            <s.OptionText
                                active={
                                    location.pathname === "/organizacao/setores"
                                }
                            >
                                SETORES
                            </s.OptionText>
                        </div>
                    )}

                    {viewRolesPermission && (
                        <div
                            className="option"
                            onClick={() => navigate("/organizacao/atuacoes")}
                        >
                            <Icons.Briefcase
                                color={
                                    location.pathname ===
                                    "/organizacao/atuacoes"
                                        ? theme.purple600
                                        : theme.gray400
                                }
                                title="Atuações"
                            />

                            <s.OptionText
                                active={
                                    location.pathname ===
                                    "/organizacao/atuacoes"
                                }
                            >
                                ATUAÇÕES
                            </s.OptionText>
                        </div>
                    )}

                    {manageProjectsPermission && (
                        <div
                            className="option"
                            onClick={() => navigate("/organizacao/clientes")}
                        >
                            <Icons.BuildingOffice
                                color={
                                    location.pathname ===
                                    "/organizacao/clientes"
                                        ? theme.purple600
                                        : theme.gray400
                                }
                                title="Clientes"
                            />

                            <s.OptionText
                                active={
                                    location.pathname ===
                                    "/organizacao/clientes"
                                }
                            >
                                CLIENTES
                            </s.OptionText>
                        </div>
                    )}

                    {manageProjectsPermission && (
                        <div
                            className="option"
                            onClick={() =>
                                navigate("/organizacao/demonstrativos")
                            }
                        >
                            <Icons.TrendingUp
                                color={
                                    location.pathname ===
                                    "/organizacao/demonstrativos"
                                        ? theme.purple600
                                        : theme.gray400
                                }
                                title="Demonstrativos"
                            />

                            <s.OptionText
                                active={
                                    location.pathname ===
                                    "/organizacao/demonstrativos"
                                }
                            >
                                DEMONSTRATIVOS
                            </s.OptionText>
                        </div>
                    )}
                </s.Options>

                <div>
                    {disableButton ? (
                        <></>
                    ) : (
                        <PrimaryButton
                            onClick={() =>
                                navigate(`${location.pathname}/criar`)
                            }
                        >
                            CRIAR {verifyLocation(location.pathname)}
                        </PrimaryButton>
                    )}
                </div>
            </s.UpperOptions>
        </s.Container>
    );
};

export default OrganizationOptions;
