import { styled } from "styled-components";

import { Radio } from "../../../../../../../styles/radio-group-styles";

export const Container = styled.form`
    padding: 16px;
    width: 664px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    input {
        width: 100%;
    }
`;

export const SideFields = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
`;

export const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    .switch {
        scale: 0.9;
    }
`;

export const SwitchLabel = styled.p`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;
`;

export const RecurringNumberContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    div {
        width: 140px;
        position: relative;
    }

    p {
        white-space: nowrap;
    }
`;

export const RecurringNumberLabel = styled.p`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const InputInsideLabel = styled.p`
    margin-left: -110px;
    color: ${({ theme }) => theme.gray400};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const IncreaseDecreaseContainer = styled.div`
    display: flex;
    flex-direction: column;

    svg {
        &:hover {
            background-color: ${({ theme }) => theme.gray50};
            cursor: pointer;
            border-radius: 5px;
        }

        &:first-child {
            rotate: 180deg;
        }
    }
`;

export const EditRecurringContainer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.lightYellow};
    border-radius: 4px;
`;

export const EditRecurringTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    h1 {
        color: ${({ theme }) => theme.purple500};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
    }
`;

export const EditRecurringRadioContainer = styled(Radio.Root)`
    background-color: transparent;
    box-shadow: none;

    p {
        color: ${({ theme }) => theme.gray800};
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }
`;

export const EditRecurringRadioItem = styled(Radio.Item)`
    background-color: white;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    button {
        &:first-child {
            width: 121px;
        }
        width: 150px;
    }
`;
