import { useQuery } from "@tanstack/react-query";

import { projects } from "../../services";
import {
    ReadProjectCostsParams,
    ReadProjectCostsResult,
} from "../../services/projects/types";

type Params = {
    enabled?: boolean;
    cacheTime?: number;
};

export function useReadCosts({
    endDate,
    orderByDate,
    projectId,
    startDate,
}: ReadProjectCostsParams & Params) {
    const filters = {
        endDate,
        orderByDate,
        projectId,
        startDate,
    };

    return useQuery<ReadProjectCostsResult, unknown, ReadProjectCostsResult>({
        enabled: !!projectId,
        queryKey: ["readCosts", filters],
        queryFn: () => projects.readCosts(filters),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
