import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TPatchReport = {
    reportId?: string;
};

export default async ({ reportId }: TPatchReport = {}) => {
    const tempHeaders = {
        auth: true,
        "x-report-id": reportId,
    };
    if (!reportId) delete tempHeaders["x-report-id"];
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeReport/toggleReview`,
        method: "patch",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Não foi possível concluir a revisão do relatório!",
        401: "Acesso negado",
        404: "Relatório não existente!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
