import { TProject } from "../../../../context";
import { TOption } from "../../../../models";
import {
    TOptions,
    TReadActivities,
} from "../../../../pages/organization/project/tab-activities/types";

export const readActivitiesResponseToOptions = (
    response?: TReadActivities
): TOptions => {
    if (!response) return [];
    const noArchivedSections = response.noArchived?.sections;
    const archivedSections = response.archived?.sections;
    const sectionsOptions = [
        ...(noArchivedSections?.map((section) => ({
            label: section.sectionName,
            options: section.activities?.map((activity) => ({
                label: activity.name,
                value: activity.id_e,
            })),
        })) ?? []),
        ...(archivedSections?.map((section) => ({
            label: section.sectionName,
            options: section.activities?.map((activity) => ({
                label: activity.name,
                value: activity.id_e,
            })),
        })) ?? []),
    ];
    const noArchivedActivitiesWithoutSection = response.noArchived?.activities;
    const archivedActivitiesWithoutSection = response.archived?.activities;
    const withoutSectionOptions = [
        {
            label: "Sem sessão",
            options: [
                ...(noArchivedActivitiesWithoutSection?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })) ?? []),
                ...(archivedActivitiesWithoutSection?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })) ?? []),
            ],
        },
    ];
    const options = [...sectionsOptions, ...withoutSectionOptions];
    return options;
};

export const readActivitiesResponseNotArchivedToOptions = (
    response?: TReadActivities
): TOptions => {
    if (!response) return [];
    const noArchivedSections = response.noArchived?.sections;
    const sectionsOptions = [
        ...(noArchivedSections?.map((section) => ({
            label: section.sectionName,
            options: section.activities?.map((activity) => ({
                label: activity.name,
                value: activity.id_e,
            })),
        })) ?? []),
    ];
    const noArchivedActivitiesWithoutSection = response.noArchived?.activities;
    const withoutSectionOptions = [
        {
            label: "Sem sessão",
            options: [
                ...(noArchivedActivitiesWithoutSection?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })) ?? []),
            ],
        },
    ];
    const options = [...sectionsOptions, ...withoutSectionOptions];
    return options;
};

export const projectsToOptions = (projects: TProject[]): TOption[] => {
    return projects.map((project) => ({
        label: project.name,
        value: project.id_e,
        color: project.color,
    }));
};
