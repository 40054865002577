import { deepClone } from "@mui/x-data-grid/utils/utils";
import { useState } from "react";

import { Tooltip } from "../..";
import { Icons, theme } from "../../../assets";
import { TUser } from "../../../models";
import { formatISOToDate, formatStringNumberToMoney } from "../../../utils";
import * as s from "./styles";

type TooltipHourValueHistoryProps = {
    dataList: TUser["UserHourValueHistory"];
};

export const TooltipHourValueHistory = ({
    dataList = [],
}: TooltipHourValueHistoryProps) => {
    const [showAll, setShowAll] = useState(false);

    let listClone = deepClone(dataList) as TUser["UserHourValueHistory"];
    if (!showAll && listClone) {
        listClone = listClone.slice(0, 2);
    }
    if (!listClone?.length) {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-2px",
                }}
            >
                <Icons.History color={theme.gray400} />
            </div>
        );
    }

    return (
        <Tooltip
            side="left"
            align="start"
            positionx={-10}
            content={
                <s.ProjectScopeHistory>
                    <h5>Histórico de edições</h5>
                    <section>
                        {listClone.map((historic) => (
                            <label>
                                {historic?.created_by}
                                {" - "}
                                {historic?.created_at
                                    ? formatISOToDate(historic?.created_at)
                                    : "-"}
                                {" - "}
                                {historic.value
                                    ? formatStringNumberToMoney(historic.value)
                                    : "-"}
                            </label>
                        ))}
                        {!showAll && dataList.length > listClone.length && (
                            <a onClick={() => setShowAll(!showAll)}>
                                Mostrar tudo ({dataList.length})
                            </a>
                        )}
                    </section>
                </s.ProjectScopeHistory>
            }
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "-2px",
                }}
            >
                <Icons.History color={theme.purple500} />
            </div>
        </Tooltip>
    );
};
