import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { Icons, theme } from "../../assets";
import IconLoadingSpinCircle from "../../assets/icons/icon-loading-spin-circle";
import { PrimaryButton } from "../../components";
import DayPicker from "../../components/molecules/day-picker/day-picker";
import { getBackOffice } from "../../services/back-office";
import { PaperonData } from "./components/paperon-data/paperon-data";
import { plansMappers, plansTypesMappers } from "./mappers";
import { NPSHistoryModal } from "./modals/nps-history";
import { PlanHistoryModal } from "./modals/plan-history/plan-history";
import * as s from "./styles";
import { TBackOfficeData, TNPSHistory, TPlanHistory } from "./types";

export const BackOffice = () => {
    const [planHistoryModal, setPlanHistoryModal] = useState<TPlanHistory[]>(
        []
    );
    const [npsHistoryModal, setNpsHistoryModal] = useState<TNPSHistory[]>([]);
    const [view, setView] = useState<"organizations" | "paperon">(
        "organizations"
    );
    const [startAt, setStartAt] = useState<Date | undefined>();
    const [endAt, setEndAt] = useState<Date | undefined>(new Date());

    const {
        data: backOffice,
        refetch,
        isLoading,
    } = useQuery<TBackOfficeData>({
        queryKey: ["backOffice"],
        queryFn: () =>
            getBackOffice({
                startAt,
                endAt,
            }),
    });

    useEffect(() => {
        if (backOffice) refetch();
    }, [startAt, endAt]);

    const formatDate = (date: string) => {
        if (!date) return "";
        return dayjs(date).format("DD/MM/YYYY");
    };

    const formatDateWithHour = (date: string) => {
        if (!date) return "";
        return dayjs(date).format("DD/MM/YYYY HH:mm");
    };

    const getOrganizations = () => {
        return (
            backOffice?.organizations?.map((organization) => ({
                ...organization,
                id: organization.id_e,
                planStartDate: formatDate(organization.planStartDate),
            })) ?? []
        );
    };

    const columns: GridColDef[] = [
        { field: "name", headerName: "Nome", flex: 1 },
        { field: "email", headerName: "E-mail", flex: 1 },
        {
            field: "numberOfUsers",
            headerName: "Número de usuários",
            flex: 1,
        },
        {
            field: "planStartDate",
            headerName: "Data de início do plano",
            flex: 1,
        },
        {
            field: "planEndDate",
            headerName: "Data de fim do plano",
            flex: 1,
        },
        {
            field: "currentPlan",
            headerName: "Plano atual",
            flex: 1,
            renderCell: ({ row }) => {
                if (!row.currentPlan) return "Sem plano cadastrado";
                const planName =
                    plansMappers[
                        row.currentPlan.name as keyof typeof plansMappers
                    ];
                const planType =
                    plansTypesMappers[
                        row.currentPlan.type as keyof typeof plansTypesMappers
                    ] ?? "";

                return (
                    <div>
                        {planName}
                        {planType && ` - ${planType}`}
                    </div>
                );
            },
        },
        {
            field: "planHistory",
            headerName: "Histórico dos planos",
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <button
                        onClick={() => setPlanHistoryModal(row.planHistory)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                        }}
                    >
                        <Icons.History
                            color={
                                row.planHistory.length
                                    ? theme.gray800
                                    : theme.gray400
                            }
                        />
                    </button>
                );
            },
        },
        {
            field: "NPSHistory",
            headerName: "Histórico dos NPS",
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <button
                        onClick={() => setNpsHistoryModal(row.NPSHistory)}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                        }}
                    >
                        <Icons.History
                            color={
                                row.NPSHistory.length
                                    ? theme.gray800
                                    : theme.gray400
                            }
                        />
                    </button>
                );
            },
        },
        {
            field: "createdAt",
            headerName: "Data de criação",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
        {
            field: "lastLogin",
            headerName: "Último login do criador",
            flex: 1,
            valueFormatter: ({ value }) => formatDateWithHour(value as string),
        },
    ];

    return backOffice && !isLoading ? (
        <s.Container>
            <s.Header>
                <PrimaryButton
                    onClick={() =>
                        setView(
                            view === "organizations"
                                ? "paperon"
                                : "organizations"
                        )
                    }
                    icon={
                        view === "organizations" ? (
                            <Icons.PaperClip color={theme.white} />
                        ) : (
                            <Icons.ChartColumns color={theme.white} />
                        )
                    }
                >
                    {view === "organizations"
                        ? "Ver Paperon"
                        : "Ver Organizações"}
                </PrimaryButton>
                {view === "paperon" && (
                    <>
                        <s.Filter>
                            <DayPicker
                                placeholder="Data inicial"
                                value={startAt}
                                onSelect={(param) => {
                                    setStartAt(param);
                                }}
                            />
                            <DayPicker
                                placeholder="Data final"
                                value={endAt}
                                onSelect={(param) => {
                                    setEndAt(param);
                                }}
                            />
                            {(startAt || endAt) && (
                                <Icons.XOutline
                                    onClick={() => {
                                        setStartAt(undefined);
                                        setEndAt(undefined);
                                    }}
                                />
                            )}
                        </s.Filter>
                        <s.Observation>
                            Observação: Os dados com o ícone{" "}
                            <Icons.Calendario color={theme.gray600} /> serão
                            filtrados pela data inserida.
                        </s.Observation>
                    </>
                )}
            </s.Header>
            {planHistoryModal.length > 0 && (
                <PlanHistoryModal
                    setOpen={setPlanHistoryModal}
                    history={planHistoryModal}
                />
            )}
            {npsHistoryModal.length > 0 && (
                <NPSHistoryModal
                    history={npsHistoryModal}
                    setOpen={setNpsHistoryModal}
                />
            )}
            {view === "organizations" ? (
                <s.DataGridContainer>
                    <DataGrid
                        rows={getOrganizations()}
                        columns={columns}
                        pageSizeOptions={[10, 20, 100]}
                        localeText={
                            ptBR.components.MuiDataGrid.defaultProps.localeText
                        }
                    />
                </s.DataGridContainer>
            ) : (
                <PaperonData data={backOffice} />
            )}
        </s.Container>
    ) : (
        <IconLoadingSpinCircle />
    );
};
