import { useMutation } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

import { Icons } from "../../../../assets";
import { projects } from "../../../../services";
import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Projects = Array<{
    name: string;
    customer: string;
    registeredHours: number;
}>;

type Success = Projects;

type Error = {
    body: Array<{
        error: {
            message: string;
            statusCode: number;
        };
        projects?: Projects;
        errorProjectIndex?: number;
    }>;
};

type Props = {
    data: {
        type: "error" | "success";
        result: Error | Success;
    };
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalMassProjectVerification = ({ data, setOpen }: Props) => {
    if (!data) return null;

    const haveError = Object.keys(data.result).includes("body");

    const errors = (data.result as Error)?.body;
    const projectsList = errors?.[0]?.projects || (data.result as Success);

    const errorsMapper = {
        "projects duplicated in plan":
            "Esse projeto está com o nome duplicado na planilha.",
        "project already exists":
            "Já existe um projeto com o mesmo nome na sua organização.",
        "hours in wrong format":
            "As horas devem estar em formato numérico (exemplo: 20, 30, 400) ou completamente vazias",
        "projects not have name":
            "Projeto não tem nome. Nome do projeto é obrigatório.",
        "projects not have customer":
            "Projeto não tem cliente. Cliente é um campo obrigatório.",
        "client exceeded name length":
            "Nome do cliente excede o limite de 100 caracteres.",
        "project exceeded name length":
            "Nome do projeto excede o limite de 200 caracteres.",
    };

    const createMassProjects = useMutation({
        mutationKey: ["createMassProjects"],
        mutationFn: projects.createMassProjects,
        onSuccess() {
            setOpen(false);
            toast.success("Projetos importados com sucesso!");
        },
    });

    const save = () => {
        createMassProjects.mutate({
            projects: projectsList,
        });
    };

    return (
        <ContainerModal>
            <s.Container>
                <s.Title>Verificar informações</s.Title>
                {haveError && (
                    <s.ErrorWarning>
                        As linhas com erro estão pintadas de vermelho.
                    </s.ErrorWarning>
                )}
                <s.Table>
                    <s.Header>
                        <s.Item bold>Linha</s.Item>
                        <s.Item bold>Nome do projeto</s.Item>
                        <s.Item bold>Cliente</s.Item>
                        <s.Item bold>Horas registradas</s.Item>
                    </s.Header>
                    <s.Content>
                        {projectsList.map((item, index) => {
                            const projectHaveError = errors?.some(
                                (e) => e.errorProjectIndex === index
                            );
                            const startLine = 3;
                            return (
                                <>
                                    <s.Row error={projectHaveError}>
                                        <s.Item>{startLine + index}</s.Item>
                                        <s.Item>{item.name}</s.Item>
                                        <s.Item>{item.customer}</s.Item>
                                        <s.Item>
                                            {item.registeredHours || 0}
                                        </s.Item>
                                    </s.Row>
                                    {projectHaveError && (
                                        <s.ErrorsContainer>
                                            {errors
                                                .filter(
                                                    (e) =>
                                                        e.errorProjectIndex ===
                                                        index
                                                )
                                                .map((error) => (
                                                    <s.ErrorWarning>
                                                        {errorsMapper[
                                                            error.error
                                                                .message as keyof typeof errorsMapper
                                                        ] ||
                                                            error.error.message}
                                                    </s.ErrorWarning>
                                                ))}
                                        </s.ErrorsContainer>
                                    )}
                                </>
                            );
                        })}
                    </s.Content>
                </s.Table>
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        {haveError ? "Fechar" : "Cancelar"}
                    </SecondaryButton>
                    {!haveError && (
                        <PrimaryButton
                            onClick={save}
                            disabled={createMassProjects.isLoading}
                        >
                            Concluir importação
                            {createMassProjects.isLoading && <Icons.Loading />}
                        </PrimaryButton>
                    )}
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
