import styled from "styled-components";

export const Container = styled.div<{ width?: string }>`
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    width: ${({ width }) => width || "163px"};
    background-color: ${({ theme }) => theme.white};
`;

export const Option = styled.div<{ disabled?: boolean }>`
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;

export const Label = styled.div<{ color: string; disabled?: boolean }>`
    color: ${({ theme, color, disabled }) =>
        disabled ? theme.gray300 : color};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const TooltipContent = styled.div`
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.gray700};
`;

export const Children = styled.div``;
