import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="16"
        height="20"
        viewBox="5 1 15 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-dashboard-reference"}
    >
        <title>{title}</title>
        <path
            d="M12.6 21.9L20.9991 21.9L21 21.9M12.6 21.9H21M12.6 21.9V21.6H21.0015H21.6015L21.6015 21L21.6007 9.74996L21.6007 9.15H21.0007H3H2.4V9.75V11.4H2.1V3.00034C2.10031 2.76165 2.19526 2.53282 2.36404 2.36404C2.53282 2.19526 2.76165 2.10031 3.00034 2.1H20.9997L12.6 21.9ZM21 21.9C21.2385 21.8994 21.4671 21.8044 21.6357 21.6357C21.8044 21.4671 21.8994 21.2385 21.9 21M21 21.9L21.9 21M21.9 21C21.9 20.9997 21.9 20.9994 21.9 20.9991L21.9 21ZM7.07574 17.1362L2.5605 21.6515L2.34853 21.4395L6.86376 16.9243L7.88803 15.9H6.4395H3.6V15.6H8.4V20.4H8.1V17.5605V16.112L7.07574 17.1362ZM9 8.85H9.6V8.25V3V2.4H9H3H2.4V3V8.25V8.85H3H9ZM9.9 8.25V8.85H10.5H21H21.6V8.25V3V2.4H21H10.5H9.9V3V8.25ZM17.6 16.35H17.9V18.9H17.6V16.35ZM14.6 12.6H14.9V18.9H14.6V12.6Z"
            stroke={color || theme.purple400}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
