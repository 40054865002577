import { Tag } from "../../..";
import { theme } from "../../../../assets";
import * as s from "./styled-popover-project-list";

type TPopoverProjectList = {
    name: string;
    color?: string;
};

const PopoverProjectList = ({
    projects,
    message,
}: {
    projects?: TPopoverProjectList[];
    message?: string;
}) => {
    if (projects) {
        return (
            <s.ProjectsList>
                <div id="projects-header">
                    <span id="title">Projetos</span>
                </div>
                <div id="projects-body">
                    {projects.length > 0 ? (
                        projects?.map((project, index) => {
                            return (
                                <div className="tag" key={index}>
                                    <Tag
                                        key={project.name}
                                        color={project.color || theme.purple500}
                                    >
                                        {project.name}
                                    </Tag>
                                </div>
                            );
                        })
                    ) : (
                        <span>{message || "Usuário sem projetos!"}</span>
                    )}
                </div>
            </s.ProjectsList>
        );
    }
    return (
        <s.ProjectsList>
            <div id="projects-header">Projetos</div>
            Sem projetos
        </s.ProjectsList>
    );
};

export default PopoverProjectList;
