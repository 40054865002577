import styled from "styled-components";

export const Container = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 54px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.purple500};
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.018px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
`;
