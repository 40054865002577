import styled from "styled-components";

type TSection = {
    gap?: string;
};

export const Container = styled.div`
    width: 100%;
    display: grid;
    height: calc(100vh - 80px);
    grid-template-columns: repeat(auto-fit, minmax(448px, 1fr));
    gap: 6.5rem;
    overflow-y: hidden;
    background-color: ${({ theme }) => theme.white};

    h2 {
        color: ${({ theme }) => theme.purple500};
        font-size: 2.125rem;
        line-height: 2.9375rem;
        letter-spacing: 0.25%;
        font-weight: normal;
        font-style: normal;
    }

    p.step-description {
        font-style: normal;
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 1.5625rem;
        letter-spacing: 0.001em;
        color: ${({ theme }) => theme.gray600};
    }

    @media (max-width: 1000px) {
        place-items: center;
        margin-bottom: 12.5rem;
    }
`;

export const SectionColumn = styled.section<TSection>`
    display: flex;
    gap: ${({ gap }) => gap};
    flex-direction: column;
    width: 440px;
    justify-content: center;
    margin-bottom: 80px;

    div#buttons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-top: 3.5rem;
    }

    @media (max-width: 1000px) {
        order: 1;
    }
`;

export const FlexColumn = styled.div<TSection>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${({ gap }) => gap};
`;

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1000px) {
        display: none;
    }
`;

export const Input = styled.input`
    width: 100%;
    background: #ffffff;
    height: 3rem;
    border: 1px solid #e7e6e9;
    border-radius: 4px;
`;
