import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { TProject } from "../../../../../../../models";
import { projects } from "../../../../../../../services";
import { ReadProjectCostsResult } from "../../../../../../../services/projects/types";
import { UpsertUserProjectBenefit } from "../../../../../../../services/projects/upsert-user-project-benefit";
import {
    formatToBRMoney,
    formatToBRMoneyWithoutMoneySign,
    numberHoursToString,
} from "../../../../../../../utils";
import { Mixpanel } from "../../../../../../../utils/mixpanel/Mixpanel";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import { TabProps } from "../../../tab-costs";
import { costStringToNumber } from "../../../utils";
import { ModalUpsertBenefit } from "../../modals/modal-upsert-benefit/modal-upsert-benefit";
import * as Table from "../../table/table";
import { Benefit } from "./components/benefit/benefit";
import { CustomTooltip } from "./components/custom-tooltip/custom-tooltip";
import * as s from "./styles";

type Benefit = ReadProjectCostsResult["teamCosts"]["data"][0]["benefit"];
type UpsertUserProjectBenefitResult = Benefit & {
    user_id: string;
};

export type CreateEditBenefitSaveParams = {
    value: string;
    comment: string;
    includeToBilling: boolean;
};

export const TeamTab = ({ data, date }: TabProps) => {
    if (!data) return null;

    const { state } = useLocation();
    const { project }: { project: TProject } = state || {};

    const [userIdToEditBenefit, setUserIdToEditBenefit] = useState<string>("");
    const [userIdToCreateBenefit, setUserIdToCreateBenefit] =
        useState<string>();

    const thisUserIsEditing = (id: string) => userIdToEditBenefit === id;
    const thisUserIsCreating = (id: string) => userIdToCreateBenefit === id;

    const handleUpsertUserProjectBenefit = () => {
        const action = userIdToCreateBenefit ? "criado" : "atualizado";
        toast.success(`Benefício ${action} com sucesso!`);
        queryClient.invalidateQueries(["readCosts"]);
    };

    const upsertUserProjectBenefit = useMutation<
        UpsertUserProjectBenefitResult,
        unknown,
        UpsertUserProjectBenefit
    >({
        mutationKey: ["upsertUserProjectBenefit"],
        mutationFn: projects.upsertUserProjectBenefit,
        onSuccess: handleUpsertUserProjectBenefit,
        onSettled: () => {
            setUserIdToCreateBenefit("");
            setUserIdToEditBenefit("");
        },
    });

    const benefitToDefaultValues = (
        benefit: Benefit
    ): CreateEditBenefitSaveParams | undefined => {
        if (!benefit) return undefined;
        return {
            comment: benefit.comment,
            value: formatToBRMoney(benefit.value),
            includeToBilling: benefit.includeToBilling,
        };
    };

    const upsert = ({
        comment,
        value,
        user_id,
        includeToBilling,
    }: CreateEditBenefitSaveParams & { user_id: string }) => {
        Mixpanel.track("Criar/Atualizar benefício");
        upsertUserProjectBenefit.mutate({
            comment,
            value: costStringToNumber(value),
            date: dayjs(date.start).startOf("month").toISOString(),
            projectId: project.id_e,
            userId: user_id,
            includeToBilling,
        });
    };

    return (
        <s.Container>
            <Table.Root>
                <Table.Header>
                    <Table.Head>Profissional</Table.Head>
                    <Table.Head>
                        Horas trabalhadas
                        <CustomTooltip>
                            Valores automáticos baseados nas entradas no
                            cronômetro.
                        </CustomTooltip>
                    </Table.Head>
                    <Table.Head>
                        Valor hora (R$)
                        <CustomTooltip>
                            Valor hora existente no perfil do profissional.
                        </CustomTooltip>
                    </Table.Head>
                    <Table.Head>Total parcial (R$)</Table.Head>
                    <Table.Head>
                        Benefícios (R$)
                        <CustomTooltip>
                            O valor inserido nos benefícios será autocompletado
                            no campo de "Adicionais" no faturamento do
                            colaborador.
                        </CustomTooltip>
                    </Table.Head>
                    <Table.Head>Total (R$)</Table.Head>
                    <Table.Head>Total faturado (R$)</Table.Head>
                </Table.Header>
                <Table.Body>
                    {data.teamCosts.data.map((data, index) => (
                        <React.Fragment key={index}>
                            <Table.Row>
                                <Table.Data>{data.name}</Table.Data>
                                <Table.Data>
                                    {numberHoursToString(data.workedHours)}
                                </Table.Data>
                                <Table.Data>
                                    {formatToBRMoneyWithoutMoneySign(
                                        data.hourValue
                                    )}
                                </Table.Data>
                                <Table.Data>
                                    {formatToBRMoneyWithoutMoneySign(
                                        data.workCost
                                    )}
                                </Table.Data>
                                <Table.Data>
                                    <p>
                                        {formatToBRMoneyWithoutMoneySign(
                                            data.benefit?.value || 0
                                        )}
                                    </p>
                                    <Benefit
                                        active={
                                            thisUserIsCreating(data.user_id) ||
                                            thisUserIsEditing(data.user_id)
                                        }
                                        userData={data}
                                        setUserIdToEditBenefit={
                                            setUserIdToEditBenefit
                                        }
                                        setIsToCreateBenefit={(value) => {
                                            if (value) {
                                                setUserIdToCreateBenefit(
                                                    data.user_id
                                                );
                                                return;
                                            }
                                            setUserIdToCreateBenefit("");
                                        }}
                                    />
                                </Table.Data>
                                <Table.Data>
                                    {formatToBRMoneyWithoutMoneySign(
                                        data.total
                                    )}
                                </Table.Data>
                                <Table.Data>
                                    {formatToBRMoneyWithoutMoneySign(
                                        data.totalBilled
                                    )}
                                </Table.Data>
                            </Table.Row>
                            {(thisUserIsCreating(data.user_id) ||
                                thisUserIsEditing(data.user_id)) && (
                                <ModalUpsertBenefit
                                    defaultValues={benefitToDefaultValues(
                                        data.benefit
                                    )}
                                    onSave={(params) =>
                                        upsert({
                                            ...params,
                                            user_id: data.user_id,
                                        })
                                    }
                                    onCancel={() => {
                                        setUserIdToCreateBenefit("");
                                        setUserIdToEditBenefit("");
                                    }}
                                />
                            )}
                        </React.Fragment>
                    ))}
                    {data.teamCosts.data.length === 0 && (
                        <Table.NoData>
                            Nenhum profissional encontrado.
                        </Table.NoData>
                    )}
                </Table.Body>
                {data.teamCosts.data.length > 0 && (
                    <Table.Total>
                        <Table.Data>
                            {numberHoursToString(
                                data.teamCosts.total.workedHours
                            )}
                        </Table.Data>
                        <Table.Data></Table.Data>
                        <Table.Data>
                            {formatToBRMoneyWithoutMoneySign(
                                data.teamCosts.total.workCost
                            )}
                        </Table.Data>
                        <Table.Data>
                            {formatToBRMoneyWithoutMoneySign(
                                data.teamCosts.total.benefitsCost
                            )}
                        </Table.Data>
                        <Table.Data>
                            {formatToBRMoneyWithoutMoneySign(
                                data.teamCosts.total.teamCost
                            )}
                        </Table.Data>
                        <Table.Data>
                            {formatToBRMoneyWithoutMoneySign(
                                data.teamCosts.total.billedCost
                            )}
                        </Table.Data>
                    </Table.Total>
                )}
            </Table.Root>
        </s.Container>
    );
};
