/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

import PopoverRadix from "../popover/popover";
import * as s from "./styles";

import { Tooltip } from "..";

export type TMenuAtom = {
    options: {
        label: string;
        icon: JSX.Element | null;
        action: (param?: any) => void;
        color: string;
        disabled?: boolean;
        tooltipWhenDisabled?: string;
    }[];
    data?: unknown;
    width?: string;
    children: JSX.Element;
    align?: "end" | "start" | "center";
    alignOffset?: number;
    sideOffset?: number;
    disabled?: boolean;
};

export const MenuAtom = ({
    align = "end",
    width,
    options,
    children,
    data,
    alignOffset,
    sideOffset,
    disabled,
}: TMenuAtom) => {
    const [open, setOpen] = useState(false);

    return (
        <PopoverRadix
            open={disabled ? false : open}
            onOpenChange={() => setOpen(!open)}
            align={align}
            side="bottom"
            trigger={children}
            alignOffset={alignOffset}
            sideOffset={sideOffset}
        >
            <s.Container style={{ width }}>
                {options.map((option) => (
                    <Tooltip
                        side="left"
                        align="center"
                        positionx={-10}
                        content={
                            <s.TooltipContent>
                                {option.tooltipWhenDisabled}
                            </s.TooltipContent>
                        }
                        open={
                            option.tooltipWhenDisabled && option.disabled
                                ? undefined
                                : false
                        }
                    >
                        <s.Option
                            key={option.label}
                            onClick={() => {
                                if (!option.disabled) option.action(data);
                            }}
                            disabled={option.disabled}
                        >
                            {option.icon}
                            <s.Label
                                disabled={option.disabled}
                                color={option.color}
                            >
                                {option.label}
                            </s.Label>
                        </s.Option>
                    </Tooltip>
                ))}
            </s.Container>
        </PopoverRadix>
    );
};
