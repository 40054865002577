import { useState } from "react";

import { FotoRapaz } from "../../assets";
import { EmailStep } from "./components/register-steps/email-step/email-step";
import { RegisterFinalStep } from "./components/register-steps/final-step/final-step";
import { RegisterFirstStep } from "./components/register-steps/first-step/first-step";
import * as s from "./styled-register";

export const STEPS = {
    FIRST: 1,
    EMAIL: 2,
    FINAL: 3,
};

export const Register = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");

    return (
        <s.Container>
            <s.Img src={FotoRapaz} />

            <s.Section>
                {step === STEPS.FIRST && (
                    <RegisterFirstStep setStep={setStep} setEmail={setEmail} />
                )}
                {step === STEPS.EMAIL && (
                    <EmailStep setStep={setStep} email={email} />
                )}
                {step === STEPS.FINAL && (
                    <RegisterFinalStep userData={{ email } as never} />
                )}
            </s.Section>
        </s.Container>
    );
};
