import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import {
    ColorPicker,
    Input,
    PopoverRadix,
    PrimaryButton,
    SecondaryButton,
    Tag,
    Tooltip,
} from "../../../../../../components";
import { useFetch } from "../../../../../../hooks";
import { manageUser } from "../../../../../../services";
import { normalizeString } from "../../../../../../utils";
import * as s from "../styled-steps";

type TUser = {
    id_e: string;
    avatar?: string;
    name?: string;
    role?: string;
    Role?: {
        name?: string;
    };
    hidden?: boolean;
    Sectors?: {
        id_e: string;
        name: string;
    }[];
    Permissions: string[];
};

type TSector = {
    id_e: string;
    Projects?: [
        {
            color?: string;
            id_e?: string;
            name?: string;
        }
    ];
    Users: TUser[];
    created_at?: string;
    description?: string;
    name?: string;
};

type TFields = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    start_at?: Date;
    end_at?: Date;
    color?: string;
};

type TStep2 = {
    storeData: <IProject>(data: IProject) => void;
    manageSteps: Dispatch<SetStateAction<1 | 2>>;
    sectorsData?: TSector[];
    billersData: TUser[];
    loading: boolean;
    color: string;
    setColor: React.Dispatch<React.SetStateAction<string>>;
    setLinkedSectors: React.Dispatch<string[]>;
    setProjectTeam: React.Dispatch<React.SetStateAction<TUser[]>>;
    setProjectReviewer: React.Dispatch<React.SetStateAction<TUser[]>>;
    setProjectBiller: React.Dispatch<React.SetStateAction<TUser[]>>;
    linkedSectors: string[];
    projectTeam: TUser[];
    projectReviewer: TUser[];
    projectBiller: TUser[];
    setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
    sectorsError: boolean;
    verifyErrors: () => void;
    fileSuccess: boolean;
};

const Step2Project = ({
    storeData,
    manageSteps,
    sectorsData,
    billersData,
    loading,
    color,
    setColor,
    setProjectTeam,
    setProjectReviewer,
    setProjectBiller,
    projectTeam,
    projectReviewer,
    projectBiller,
    setIsDone,
    verifyErrors,
    fileSuccess,
    setLinkedSectors,
}: TStep2) => {
    const [usersData, setUsersData] = useState<TUser[]>([]);
    const [teamSearch, setTeamSearch] = useState("");
    const [reviewerSearch, setReviewerSearch] = useState("");
    const [billerSearch, setBillerSearch] = useState("");
    const [billers, setBillers] = useState<TUser[]>([]);

    const { handleSubmit, control } = useForm<TFields>();

    const getAllUsers = useFetch({
        fn: manageUser.getAllUsers,
        errorMessage: "Erro ao pegar usuários da organização",
        params: { sectors: true },
        start: true,
    });

    useEffect(() => {
        if (getAllUsers.response) {
            setUsersData(getAllUsers.response);
        }
    }, [getAllUsers.response]);

    useEffect(() => {
        setBillers(billersData);
    }, [billersData]);

    const filterTeam = () => {
        const searchFilter = new RegExp(normalizeString(teamSearch), "i");

        return usersData.filter(
            (user: TUser) =>
                normalizeString(user?.name)?.match(searchFilter) ||
                normalizeString(user?.role)?.match(searchFilter)
        );
    };

    const filterReviewer = () => {
        const searchFilter = new RegExp(normalizeString(reviewerSearch), "i");

        return projectTeam.filter(
            (user: TUser) =>
                (normalizeString(user?.name)?.match(searchFilter) ||
                    normalizeString(user?.role)?.match(searchFilter)) &&
                !user?.hidden &&
                user.Permissions.includes("review_reports")
        );
    };

    const addTeamUser = (collaborator: TUser) => {
        const added = projectTeam?.find(
            (user) => user.id_e === collaborator.id_e
        );
        if (projectTeam) {
            if (added) {
                toast.error("Profissional já adicionado");
            } else setProjectTeam([...projectTeam, collaborator]);
        }
    };

    const correctUsersList = (userId: string) => {
        sectorsData?.forEach((sector: TSector) => {
            const removedUser = sector.Users.find(
                (user: TUser) => user.id_e === userId
            );
            if (removedUser) {
                setUsersData((prevUsers) => {
                    const isAlreadyInList = prevUsers.some((user: TUser) => {
                        return user.id_e === removedUser.id_e;
                    });
                    if (!isAlreadyInList) {
                        return [...prevUsers, removedUser];
                    }
                    return prevUsers;
                });
            }
        });
    };

    const removeTeamUser = (id: string) => {
        const newTeam = projectTeam?.filter((col: TUser) => col.id_e !== id);
        const newReviewers = projectReviewer?.filter(
            (rev: TUser) => rev.id_e !== id
        );

        setProjectTeam(newTeam);
        setProjectReviewer(newReviewers);
        correctUsersList(id);
    };

    const addReviewer = (reviewer: TUser) => {
        const added = projectReviewer?.find(
            (user: TUser) => user.id_e === reviewer.id_e
        );
        if (!projectReviewer) return;
        if (added) {
            toast.error("Profissional já adicionado");
            return;
        }
        setProjectReviewer([...projectReviewer, reviewer]);
        setProjectTeam((prevTeam) => {
            return prevTeam.map((user: TUser) => {
                if (user.id_e === reviewer.id_e) {
                    return { ...user, hidden: true };
                }
                return user;
            });
        });
    };

    const removeReviewer = (id: string) => {
        const newReviewers = projectReviewer?.filter((rev) => rev.id_e !== id);
        setProjectReviewer(newReviewers);
        setProjectTeam((prevTeam) => {
            return prevTeam.map((user: TUser) => {
                if (user.id_e === id) {
                    return { ...user, hidden: false };
                }
                return user;
            });
        });
    };

    const addBiller = (biller: TUser) => {
        const added = projectBiller?.find((user) => user.id_e === biller.id_e);
        if (!projectBiller) return;
        if (added) {
            toast.error("Profissional já adicionado");
            return;
        }
        setProjectBiller([...projectBiller, biller]);
        setBillers((prevTeam) => {
            return prevTeam.map((user: TUser) => {
                if (user.id_e === biller.id_e) {
                    return { ...user, hidden: true };
                }
                return user;
            });
        });
    };

    const removeBiller = (id: string) => {
        const newBiller = projectBiller?.filter((rev) => rev.id_e !== id);
        setProjectBiller(newBiller);
        setBillers((prevTeam) => {
            return prevTeam.map((user: TUser) => {
                if (user.id_e === id) {
                    return { ...user, hidden: false };
                }
                return user;
            });
        });
    };

    const filterBiller = () => {
        const removeEspecialCharacters = normalizeString(billerSearch);
        const searchFilter = new RegExp(removeEspecialCharacters, "i");

        return billers.filter((user: TUser) => {
            const name = user?.name ? normalizeString(user.name) : " ";
            const role = user?.role ? normalizeString(user.role) : " ";

            return (
                (name.match(searchFilter) || role.match(searchFilter)) &&
                !user?.hidden &&
                user.Permissions.includes("bill_reports")
            );
        });
    };

    const handleSelectUserForTeam = (collaborator: TUser) => {
        const usersWithoutSelected = usersData.filter(
            (user: TUser) => user.id_e !== collaborator.id_e
        );
        addTeamUser(collaborator);
        setTeamSearch("");
        setUsersData(usersWithoutSelected);
    };

    const renderTeamTable = () => {
        if (projectTeam?.length)
            return (
                <s.TeamTable>
                    <div className="table-header">
                        <div className="header-cell">Time</div>
                        <div className="header-cell">Atuação</div>
                        <div className="header-cell"></div>
                    </div>
                    <div className="table-content">
                        {projectTeam?.map(
                            (collaborator: TUser, index: number) => (
                                <div className="table-row" key={index}>
                                    <div className="row-cell">
                                        <s.ProfilePicture
                                            src={
                                                collaborator.avatar || FotoUser
                                            }
                                        />
                                        <p>{collaborator.name}</p>
                                    </div>
                                    <div className="row-cell">
                                        <p>{collaborator.role}</p>
                                    </div>
                                    <div className="row-cell">
                                        <s.ButtonRemove
                                            type="button"
                                            onClick={() =>
                                                collaborator?.id_e
                                                    ? removeTeamUser(
                                                          collaborator.id_e
                                                      )
                                                    : ""
                                            }
                                        >
                                            <Icons.XSolid
                                                color={theme.gray600}
                                            />
                                        </s.ButtonRemove>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </s.TeamTable>
            );
        return <s.Advice>Sem Profissionais</s.Advice>;
    };

    const renderReviewerTable = () => {
        if (projectReviewer?.length)
            return (
                <s.TeamTable>
                    <div className="table-header">
                        <div className="header-cell">Revisor</div>
                        <div className="header-cell">Atuação</div>
                        <div className="header-cell"></div>
                    </div>
                    <div className="table-content">
                        {projectReviewer?.map(
                            (reviewer: TUser, index: number) => (
                                <div className="table-row" key={index}>
                                    <div className="row-cell">
                                        <s.ProfilePicture
                                            src={reviewer.avatar || FotoUser}
                                        />
                                        <p>{reviewer.name}</p>
                                    </div>
                                    <div className="row-cell">
                                        <p>{reviewer.role}</p>
                                    </div>
                                    <div className="row-cell">
                                        <s.ButtonRemove
                                            type="button"
                                            onClick={() =>
                                                reviewer?.id_e
                                                    ? removeReviewer(
                                                          reviewer.id_e
                                                      )
                                                    : ""
                                            }
                                        >
                                            <Icons.XSolid
                                                color={theme.gray600}
                                            />
                                        </s.ButtonRemove>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </s.TeamTable>
            );
        return "";
    };

    const renderBillerTable = () => {
        if (projectBiller?.length)
            return (
                <s.TeamTable>
                    <div className="table-header">
                        <div className="header-cell">Faturador</div>
                        <div className="header-cell">Atuação</div>
                        <div className="header-cell"></div>
                    </div>
                    <div className="table-content">
                        {projectBiller?.map((biller: TUser, index: number) => (
                            <div className="table-row" key={index}>
                                <div className="row-cell">
                                    <s.ProfilePicture
                                        src={biller.avatar || FotoUser}
                                    />
                                    <p>{biller.name}</p>
                                </div>
                                <div className="row-cell">
                                    <p>{biller?.Role?.name}</p>
                                </div>
                                <div className="row-cell">
                                    <s.ButtonRemove
                                        type="button"
                                        onClick={() =>
                                            biller?.id_e
                                                ? removeBiller(biller.id_e)
                                                : ""
                                        }
                                    >
                                        <Icons.XSolid color={theme.gray600} />
                                    </s.ButtonRemove>
                                </div>
                            </div>
                        ))}
                    </div>
                </s.TeamTable>
            );
        return "";
    };

    const retrieveSelectedUsersSectors = (users: TUser[]): string[] => {
        const sectors: string[] = [];
        const usersSectors = users.map((user) => user?.Sectors);
        usersSectors.forEach((userSectors) => {
            userSectors?.forEach((sector) => {
                if (!sectors.includes(sector.id_e)) {
                    sectors.push(sector.id_e);
                }
            });
        });
        return sectors;
    };

    useEffect(() => {
        setLinkedSectors(retrieveSelectedUsersSectors(projectTeam));
    }, [projectTeam]);

    const onSubmit = (data: TFields) => {
        storeData({ ...data });
        verifyErrors();
        if (fileSuccess) {
            setIsDone(true);
        } else setIsDone(false);
    };

    return (
        <s.Form onSubmit={handleSubmit(onSubmit)}>
            <s.Section>
                <div className="has-label">
                    <s.Label>Tag do projeto</s.Label>
                    <s.Description>
                        Clique na tag abaixo, defina uma cor e facilite a
                        identificação do projeto para seu time
                    </s.Description>
                    <div className="container-tag">
                        <PopoverRadix
                            side="right"
                            sideOffset={20}
                            closeOnSelect
                            align="end"
                            trigger={<Tag color={color}>Nome do projeto</Tag>}
                        >
                            <Controller
                                name="color"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <ColorPicker
                                        title="Escolha a cor da tag"
                                        onSelect={(selectedColor: string) => {
                                            onChange(selectedColor);
                                            setColor(selectedColor);
                                        }}
                                    />
                                )}
                            />
                        </PopoverRadix>
                    </div>
                </div>
            </s.Section>

            <s.Section>
                <s.Description>
                    Selecione as pessoas que estarão no time deste projeto
                </s.Description>
                <div className="container-search">
                    <div id="input-team">
                        <Input
                            className="search-input"
                            value={teamSearch}
                            onChange={(e) => setTeamSearch(e.target.value)}
                            mask=""
                            icon={<Icons.SearchOutline />}
                            placeholder="Busque por nome ou atuação"
                            label={"Montar time *"}
                            error={
                                !projectTeam.length
                                    ? "Necessário no mínimo um profissional no time"
                                    : ""
                            }
                        />
                    </div>
                    {teamSearch && (
                        <div className="popover-container">
                            {usersData.length === 0 ? (
                                <div className="popover-item-no-content">
                                    <p>Nenhum usuário encontrado</p>
                                </div>
                            ) : (
                                filterTeam().map(
                                    (collaborator: TUser, index) => (
                                        <div
                                            className="popover-item"
                                            key={index}
                                            onClick={() => {
                                                handleSelectUserForTeam(
                                                    collaborator
                                                );
                                            }}
                                        >
                                            <span className="first-item">
                                                <s.ProfilePicture
                                                    src={
                                                        collaborator.avatar ||
                                                        FotoUser
                                                    }
                                                />
                                                <span>{collaborator.name}</span>
                                            </span>
                                            <span
                                                style={{
                                                    marginRight: "40px",
                                                }}
                                            >
                                                {collaborator.role}
                                            </span>
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    )}
                </div>

                <div className="container-table">{renderTeamTable()}</div>

                <div className="container-search">
                    <div id="input-reviewer">
                        <Input
                            className="search-input"
                            value={reviewerSearch}
                            onChange={(e) => setReviewerSearch(e.target.value)}
                            mask=""
                            icon={<Icons.SearchOutline />}
                            placeholder="Busque por nome ou atuação"
                            label="Responsáveis por REVISAR relatórios de projeto por profissional *"
                            error={
                                !projectReviewer.length
                                    ? "Necessário no mínimo um revisor"
                                    : ""
                            }
                        />
                    </div>
                    {reviewerSearch && (
                        <div className="popover-container">
                            {projectTeam.length === 0 ? (
                                <div className="popover-item-no-content">
                                    <p>Nenhum usuário encontrado</p>
                                </div>
                            ) : (
                                filterReviewer().map(
                                    (reviewer: TUser, index: number) => (
                                        <div
                                            className="popover-item"
                                            key={index}
                                            onClick={() => {
                                                addReviewer(reviewer);
                                                setReviewerSearch("");
                                            }}
                                        >
                                            <span className="first-item">
                                                <s.ProfilePicture
                                                    src={
                                                        reviewer.avatar ||
                                                        FotoUser
                                                    }
                                                />
                                                <span>{reviewer.name}</span>
                                            </span>
                                            <span
                                                style={{
                                                    marginRight: "40px",
                                                }}
                                            >
                                                {reviewer.role}
                                            </span>
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    )}
                </div>

                <div className="container-table">{renderReviewerTable()}</div>

                <div className="container-search">
                    <div id="input-biller">
                        <Input
                            className="search-input"
                            value={billerSearch}
                            onChange={(e) => setBillerSearch(e.target.value)}
                            mask=""
                            icon={<Icons.SearchOutline />}
                            placeholder="Selecione"
                            error={
                                !projectBiller.length
                                    ? "Necessário no mínimo um faturador"
                                    : ""
                            }
                            label={
                                <Tooltip
                                    side="right"
                                    align={"start"}
                                    content={
                                        <s.TooltipContent>
                                            <span className="tooltip-text">
                                                Aqui você define quem será
                                                responsável por faturar os
                                                relatórios dos profissionais
                                                deste projeto, ou seja,
                                                responsável reportar ao
                                                profissional o valor que deve
                                                ser gerado na nota fiscal de
                                                pagamento.
                                            </span>
                                        </s.TooltipContent>
                                    }
                                >
                                    <s.BillerInfo>
                                        <span className="heading-text">
                                            Responsável por FATURAR *
                                        </span>
                                        <Icons.Info
                                            width="18px"
                                            height="18px"
                                            color={theme.gray600}
                                        />
                                    </s.BillerInfo>
                                </Tooltip>
                            }
                        />
                    </div>
                    {billerSearch && (
                        <div className="popover-container">
                            {billersData.length === 0 ? (
                                <div className="popover-item-no-content">
                                    <p>Nenhum usuário encontrado</p>
                                </div>
                            ) : (
                                filterBiller().map(
                                    (biller: TUser, index: number) => (
                                        <div
                                            className="popover-item"
                                            key={index}
                                            onClick={() => {
                                                addBiller(biller);
                                                setBillerSearch("");
                                            }}
                                        >
                                            <span className="first-item">
                                                <s.ProfilePicture
                                                    src={
                                                        biller.avatar ||
                                                        FotoUser
                                                    }
                                                />
                                                <span>
                                                    {biller.name ||
                                                        "Não consta"}
                                                </span>
                                            </span>
                                            <span
                                                style={{
                                                    marginRight: "40px",
                                                }}
                                            >
                                                {biller.name || "Sem atuação"}
                                            </span>
                                        </div>
                                    )
                                )
                            )}
                        </div>
                    )}
                </div>

                <div className="container-table">{renderBillerTable()}</div>
            </s.Section>

            <div className="button-group">
                <PrimaryButton type="submit" loading={loading}>
                    Criar projeto
                </PrimaryButton>
                <SecondaryButton
                    onClick={() => manageSteps(1)}
                    type="button"
                    disabled={loading}
                >
                    Voltar
                </SecondaryButton>
            </div>
        </s.Form>
    );
};

export default Step2Project;
