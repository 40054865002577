import { PrimaryButton, SecondaryButton } from "../../../../../../components";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import * as s from "./styled-leave-edit-collaborators";

type TLeaveEditCollaboratorsTab = {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
};

export const LeaveEditCollaboratorsModal = ({
    setModalOpen,
    onConfirm,
}: TLeaveEditCollaboratorsTab) => {
    return (
        <ContainerModal onOpenChange={setModalOpen}>
            <s.Container>
                <s.Title>Sair da edição do time</s.Title>
                <s.Description>
                    Você deseja salvar as alterações da aba{" "}
                    <strong>Time</strong>?
                </s.Description>
                <s.Buttons>
                    <SecondaryButton onClick={() => setModalOpen(false)}>
                        Continuar editando
                    </SecondaryButton>
                    <PrimaryButton onClick={onConfirm}>
                        Salvar alterações
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
