import { toast } from "react-hot-toast";

import { axiosHttpClient } from "../axios";
import { handleErrors } from "../errors";
import { Auth } from "./Authentication";
import { SERVER_ERROR, NOT_FOUND, OK } from "./status-codes.constants";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sessions/re-auth`,
        method: "put",
        headers: { refresh: true },
    });
    const { body, statusCode } = httpRequest;
    const auth = Auth.getInstance();
    const currentLocation = window.location.pathname.split("/");
    const { unauthenticatedRoutes } = auth;

    if (statusCode === OK) {
        auth.login({ body });
        toast.success("Sessão renovada");
        return handleErrors({
            body,
            message: undefined,
        });
    }
    if (statusCode === NOT_FOUND && body.message === "token does not exists!") {
        auth.logout();
    }
    if (
        statusCode === SERVER_ERROR &&
        !unauthenticatedRoutes.includes(currentLocation[1])
    ) {
        toast.error("Sessão encerrada");
        auth.logout();
    }
    return handleErrors({
        body,
        message: "Sessão encerrada",
    });
};
