import { View, Text, Path, Svg } from "@react-pdf/renderer";

import { theme } from "../../../../../../assets";
import { SelectedDate } from "../../../../../../pages/organization/project/tab-costs/tab-costs";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import { formatToBRMoney } from "../../../../../../utils";
import * as s from "../../../styled-pdf-template";
import { SmallCard } from "../components/small-card";
import { Table } from "../components/table";

type ResumeProps = {
    data: ReadProjectCostsResult | undefined;
    date: SelectedDate;
};

export const Resume = ({ data, date }: ResumeProps) => {
    if (!data) return null;

    return (
        <View>
            <View
                style={[
                    {
                        border: `1px solid ${theme.gray200}`,
                        borderRadius: 4,
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "25px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                    },
                ]}
            >
                <Text
                    style={[
                        s.PDFTableSummaryReport.TableBodyText,
                        {
                            textAlign: "center",
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "9px",
                            letterSpacing: "0.96px",
                        },
                    ]}
                >
                    andamento geral dos custos
                </Text>
                <View
                    style={{
                        display: "flex",
                        marginTop: "15px",
                        marginBottom: "20px",
                        borderRadius: 4,
                        flexDirection: "row",
                        gap: "50px",
                        border: `1px solid ${theme.gray200}`,
                        padding: "22px 35px",
                        width: "472px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <View
                        style={{
                            borderRight: `1px solid ${theme.gray200}`,
                            paddingRight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total previsto
                        </Text>
                        <Text style={s.ProjectCosts.CostText}>
                            {formatToBRMoney(data.resume.total.forecastedCost)}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total realizado
                        </Text>
                        <Text style={s.ProjectCosts.CostTextPurple}>
                            {formatToBRMoney(data.resume.total.realizedCost)}
                        </Text>
                    </View>
                </View>

                <View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            justifyContent: "center",
                            marginBottom: "20px",
                        }}
                    >
                        <SmallCard
                            text="Custos fixos"
                            data={data.resume.total.fixedCost}
                        />
                        <SmallCard
                            text="Custos variáveis"
                            data={data.resume.total.variableCost}
                        />
                        <SmallCard
                            text="Custos de time"
                            data={data.resume.total.teamCost}
                        />
                    </View>
                </View>
            </View>

            <Table data={data} date={date} />

            <View
                style={{
                    display: "flex",
                    gap: "6px",
                    marginTop: "20px",
                }}
            >
                <View
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px",
                    }}
                >
                    <Svg width={"10"} height={"10"} viewBox="0 0 14 14">
                        <Path
                            d="M7.66667 9.66667H7V8.33333V7H6.33333M7 4.33333H7.00667M13 7C13 7.78793 12.8448 8.56815 12.5433 9.2961C12.2417 10.0241 11.7998 10.6855 11.2426 11.2426C10.6855 11.7998 10.0241 12.2417 9.2961 12.5433C8.56815 12.8448 7.78793 13 7 13C6.21207 13 5.43185 12.8448 4.7039 12.5433C3.97595 12.2417 3.31451 11.7998 2.75736 11.2426C2.20021 10.6855 1.75825 10.0241 1.45672 9.2961C1.15519 8.56815 1 7.78793 1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7Z"
                            stroke={theme.orangeWarning}
                            strokeWidth="1.2"
                        />
                    </Svg>
                    <Text
                        style={{ color: theme.orangeWarning, fontSize: "9px" }}
                    >
                        Mês em andamento
                    </Text>
                </View>
                <Text style={{ color: theme.gray600, fontSize: "9px" }}>
                    Valores em andamento. Para valores finais, acesse a planilha
                    no final do mês.
                </Text>
            </View>
        </View>
    );
};
