import React, { ReactNode } from "react";

import { Tooltip } from "../../atoms";
import * as s from "./styled-block-tooltip";

type TBlockTooltip = {
    title?: string;
    trigger: ReactNode;
    children: ReactNode;
    side: "top" | "right" | "bottom" | "left";
    align: "center" | "start" | "end";
    offset?: number | undefined;
};

export default ({
    title,
    trigger,
    children,
    side,
    align,
    offset,
}: TBlockTooltip) => (
    <Tooltip
        offset={offset}
        side={side}
        align={align}
        content={
            <s.Container>
                {title && <s.Title>{title}</s.Title>}
                <s.Content>{children}</s.Content>
            </s.Container>
        }
    >
        <s.Text>{trigger}</s.Text>
    </Tooltip>
);
