import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 3.5rem;
    gap: 1rem;
    width: 70%;
`;
