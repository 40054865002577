import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-arrow"}
    >
        <title>{title}</title>
        <path
            d="M15 7a2 2 0 012 2l-2-2zm6 2a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
            stroke={color || theme.gray600}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
