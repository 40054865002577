import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select, { GroupBase, MenuListProps } from "react-select";

import { PrimaryButton, SecondaryButton } from "../../..";
import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { useSaveProjectActivityFavorite } from "../../../../hooks/react-query/use-save-project-activity-favorite";
import { TOption } from "../../../../models";
import { activities } from "../../../../services";
import {
    ReadActivitiesResponse,
    ReadActivitiesVariables,
} from "../../../../services/activities/types";
import ContainerModal from "../container-modal";
import {
    projectsToOptions,
    readActivitiesResponseNotArchivedToOptions,
} from "./helpers";
import * as s from "./styles";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Favorite = { id_e: string | null; name: string | null };

export function ModalFavorites({ setOpen }: Props) {
    const { userProjects, user, refreshUser } = useUser();
    const favorite = user.UserProjectActivityFavorite?.[0];
    const favoriteProject = favorite?.project;
    const favoriteActivity = favorite?.activity;

    const setOption = (favorite?: Favorite) => {
        if (!favorite) return null;
        if (!favorite.name || !favorite.id_e) {
            return null;
        }
        return {
            label: favorite.name,
            value: favorite.id_e,
        };
    };

    const [project, setProject] = useState<TOption | undefined | null>(
        setOption(favoriteProject)
    );
    const [activity, setActivity] = useState<TOption | null | undefined>(
        setOption(favoriteActivity)
    );
    const filteredProjects = userProjects.filter(
        (project) => !project.archived
    );

    const readActivities = useQuery<
        ReadActivitiesVariables,
        { message: string },
        ReadActivitiesResponse
    >({
        queryKey: ["readActivities"],
        queryFn: () => activities.readActivities({ projectId: project?.value }),
        enabled: false,
    });

    const saveProjectActivityFavorite = useSaveProjectActivityFavorite({
        onSuccess: () => {
            toast.success("Favoritos salvos com sucesso!");
            refreshUser();
            setOpen(false);
        },
    });

    useEffect(() => {
        if (project) {
            readActivities.refetch();
        }
    }, [project]);

    const MenuList = (
        props: MenuListProps<TOption, true, GroupBase<TOption>>
    ) => {
        return (
            <s.MenuList {...props}>
                {Array.isArray(props.children) &&
                    props.children.map((child) => (
                        <s.Option
                            key={child.key}
                            color={child.props.data.color}
                        >
                            {child}
                        </s.Option>
                    ))}
            </s.MenuList>
        );
    };

    const close = () => setOpen(false);

    const save = () => {
        saveProjectActivityFavorite.mutate({
            activityId: activity?.value,
            projectId: project?.value,
        });
    };

    const activitiesOptions = readActivitiesResponseNotArchivedToOptions(
        readActivities.data
    );
    const projectsOptions = projectsToOptions(filteredProjects);

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Content>
                <s.Title>Selecionar atividade e projeto favoritos</s.Title>
                <s.Description>
                    Ao selecionar uma atividade ou um projeto favorito, eles
                    serão automaticamente preenchidos ao abrir o cronômetro.
                    <br />
                    Você pode optar por selecionar apenas um projeto ou um
                    projeto e uma atividade. A escolha é sua.
                </s.Description>
                <Select
                    placeholder={"Selecionar projeto favorito"}
                    className="select"
                    options={projectsOptions}
                    value={project}
                    noOptionsMessage={() => "Sem opções"}
                    menuPlacement={"auto"}
                    closeMenuOnSelect
                    isClearable
                    onChange={(option) => {
                        setProject(option as unknown as TOption);
                        setActivity(null);
                    }}
                    components={{ MenuList }}
                    loadingMessage={() => (
                        <Icons.Loading color={theme.purple500} />
                    )}
                    styles={{
                        placeholder: (base) => ({
                            ...base,
                            fontSize: 14,
                        }),
                    }}
                />
                <Select
                    placeholder={"Selecionar atividade favorita"}
                    className="select"
                    options={activitiesOptions}
                    isDisabled={readActivities.isLoading || !project}
                    value={activity}
                    noOptionsMessage={() => "Sem opções"}
                    menuPlacement={"auto"}
                    closeMenuOnSelect
                    isClearable
                    onChange={(option) => {
                        setActivity(option);
                    }}
                    loadingMessage={() => (
                        <Icons.Loading color={theme.purple500} />
                    )}
                    styles={{
                        placeholder: (base) => ({
                            ...base,
                            fontSize: 14,
                        }),
                    }}
                />
                <s.Buttons>
                    <SecondaryButton variation="small" onClick={close}>
                        Fechar
                    </SecondaryButton>
                    <PrimaryButton variation="small" onClick={save}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Content>
        </ContainerModal>
    );
}
