import React, { ChangeEvent } from "react";

import * as s from "./styles";

type Props = {
    setBase64: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const InputImage = React.forwardRef<HTMLInputElement, Props>(
    ({ setBase64 }, ref) => {
        const data64 = (file: Blob) => {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    const resultado = event.target?.result;

                    if (resultado) {
                        setBase64(resultado.toString());
                    }
                };
            }
        };

        return (
            <s.FlexColumnCenter>
                <s.Input
                    ref={ref}
                    name={""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const { files } = e.target;

                        if (files) {
                            data64(files[0]);
                        }
                    }}
                    type="file"
                    accept="image/png, image/jpeg"
                />
            </s.FlexColumnCenter>
        );
    }
);

export default InputImage;
