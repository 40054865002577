import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;

    .profile-root {
        width: 100%;
        position: relative;
        z-index: 0;
        overflow-x: clip;
    }

    .profile-tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 3rem;
        background-color: ${({ theme }) => theme.gray50};
        white-space: nowrap;

        button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${({ theme }) => theme.gray400};
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px 4px 0px 0px;
        }

        [data-state="active"] {
            background-color: ${({ theme }) => theme.purple50};
            color: ${({ theme }) => theme.gray800};
            border-bottom: 0px;
        }
    }
`;

export const TabSubtitles = styled.div`
    height: 3rem;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: calc(24px + 1.5rem);
    background-color: ${({ theme }) => theme.purple50};
    border-left: 1px solid ${({ theme }) => theme.gray200};
    border-right: 1px solid ${({ theme }) => theme.gray200};
    color: ${({ theme }) => theme.purple500};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;

    .title-row {
        width: 18.125rem;
        margin-right: auto;
    }
`;

export const Content = styled.div`
    width: 100%;
    padding: 1.5rem 1.5rem 1.5rem 2.5rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
    background-color: ${({ theme }) => theme.white2};
`;
