import { TCreateBill } from "../../models/bill";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    initial_total,
    final_total,
    discounts,
    additional,
    comment,
    report_id,
}: TCreateBill = {}) => {
    const params = {
        initial_total,
        final_total,
        discounts,
        additional,
        comment,
    };

    const tempHeaders = {
        auth: true,
        "x-report-id": report_id,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeReport/billing/create`,
        method: "post",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        400: "Você não é faturador dos projetos desse usuário!",
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
