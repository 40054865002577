import styled from "styled-components";

export const Container = styled.form`
    background-color: ${({ theme }) => theme.white};
    padding: 20px;
    border-radius: 8px;
    width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .notification-card-container {
        width: 400px;
        pointer-events: none;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SideBySideFields = styled.div`
    display: flex;
    gap: 16px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
`;
