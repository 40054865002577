import styled from "styled-components";

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
`;

export const Columns = styled.div<{ isBusinessPlan?: boolean }>`
    display: grid;
    grid-template-columns: ${({ isBusinessPlan }) =>
        isBusinessPlan ? "1fr" : "1fr 1fr"};
    gap: 24px;
    margin-top: 56px;
    max-height: 100vh;
    align-items: ${({ isBusinessPlan }) =>
        isBusinessPlan ? "center" : "start"};
    max-width: ${({ isBusinessPlan }) => (isBusinessPlan ? "488px" : "100%")};
    padding-bottom: 56px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100vh - 56px);
`;

export const Title = styled.h1<{ bottom?: string }>`
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.024px;
    margin-bottom: ${({ bottom }) => bottom || "0px"};
`;

export const Container = styled.div`
    display: flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 21px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
    background: ${({ theme }) => theme.white};
    max-width: 488px;
    height: fit-content;
`;

export const PaymentContainer = styled.div`
    display: flex;
    padding: 40px;
    flex-direction: row;
    align-items: flex-end;
    gap: 70px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
    background: ${({ theme }) => theme.white};
    min-width: 488px;
`;

export const PlanContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const Button = styled.button<{ disabled?: boolean }>`
    color: ${({ disabled, theme }) =>
        disabled ? theme.gray400 : theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const InfoTitle = styled.h1`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const Info = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Card = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.gray800};
`;

export const NextPayment = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Tooltip = styled.div`
    color: ${({ theme }) => theme.lightBlack};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const HistoricContainer = styled.div<{ isFirst?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.white};
    border: ${({ isFirst, theme }) =>
        isFirst ? `1px solid ${theme.gray50}` : "none"};
    border-bottom: 1px solid ${({ theme }) => theme.gray50};
    box-shadow: ${({ isFirst }) =>
        isFirst ? "0px 3px 12px 2px rgba(0, 0, 0, 0.08)" : "none"};
    margin-bottom: ${({ isFirst }) => (isFirst ? "20px" : "0px")};
`;

export const HistoricContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const HistoricPayment = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const HistoricPaymentPeriod = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    gap: 8px;
`;

export const Status = styled.div<{ success?: boolean }>`
    display: flex;
    align-items: center;
    padding: 3.5px 8px;
    color: ${({ success }) => (success ? "#2e7d32" : "#d32f2f")};
    font-size: 10px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.05px;
    text-transform: capitalize;
    border-radius: 4px;
    background: ${({ success }) => (success ? "#edf7ed" : "#ffebee")};
    height: fit-content;
    max-height: 20px;
`;

export const Historic = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 90%;
`;

export const Description = styled.div`
    color: var(--light-text-secondary, rgba(0, 0, 0, 0.6));
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;
