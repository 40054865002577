import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({
    title,
    color,
    className,
    onClick,
    height,
    width,
}: TBaseSVG) => (
    <svg
        onClick={onClick}
        width={width || "16"}
        height={height || "16"}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "exclamation-outline"}
    >
        <title>{title}</title>
        <path
            d="M7.99995 5.99983V7.33317M7.99995 9.99983H8.00662M3.38128 12.6665H12.6186C13.6453 12.6665 14.2866 11.5552 13.7733 10.6665L9.15462 2.6665C8.64128 1.77783 7.35862 1.77783 6.84528 2.6665L2.22662 10.6665C1.71328 11.5552 2.35462 12.6665 3.38128 12.6665Z"
            stroke={color || theme.redError}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
