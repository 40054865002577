import * as Popover from "@radix-ui/react-popover";
import { format } from "date-fns";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { DateRange } from "react-day-picker";

import { DoubleCalendar } from "../../..";
import { Icons, theme } from "../../../../assets";
import { optionsPeriods as mockedOptionsPeriods } from "../../../../models";
import * as s from "./styled-popover-calendar";

type TPeriod = { label: string; index: number };

type TPopoverCalendar = {
    getPeriod?: (period: DateRange | undefined) => void;
    alternativeInput?: boolean;
    clear?: boolean;
    setClear?: Dispatch<SetStateAction<boolean>>;
    setExternLabel?: Dispatch<SetStateAction<string | undefined>>;
    optionsPeriods?: {
        label: string;
        value: {
            from: Date;
            to: Date;
        };
    }[];
    defaultValue?: DateRange;
    noFilter?: boolean;
};

const PopoverCalendar = ({
    getPeriod,
    alternativeInput,
    clear,
    setClear,
    setExternLabel,
    defaultValue,
    optionsPeriods = mockedOptionsPeriods,
    noFilter,
}: TPopoverCalendar) => {
    const defaultPeriod =
        optionsPeriods.find(({ label }) => label === "Este mês") ||
        optionsPeriods[0];

    const [period, setPeriod] = useState<DateRange | undefined>(
        defaultValue || defaultPeriod.value
    );
    const [periodLabel, setPeriodLabel] = useState<TPeriod>({
        label: defaultPeriod.label,
        index: 4,
    });
    const [month, setMonth] = useState<Date>(new Date());

    useEffect(() => {
        if (clear) {
            setPeriod(defaultPeriod.value);
            setPeriodLabel({
                label: defaultPeriod.label,
                index: 4,
            });
            setMonth(new Date());
            setClear?.(false);
        }
    }, [clear]);

    useEffect(() => {
        if (periodLabel) setExternLabel?.(periodLabel.label);
    }, [periodLabel]);

    function compareDates(date: DateRange) {
        if (
            date.from?.setHours(0, 0, 0, 0) ===
                period?.from?.setHours(0, 0, 0, 0) &&
            date.to?.setHours(0, 0, 0, 0) === period?.to?.setHours(0, 0, 0, 0)
        ) {
            return true;
        }
        return false;
    }

    function previousPeriod() {
        if (periodLabel.index === 0) {
            setPeriodLabel({
                label: optionsPeriods[optionsPeriods.length - 1].label,
                index: optionsPeriods.length - 1,
            });
            setPeriod(optionsPeriods[optionsPeriods.length - 1].value);
            getPeriod?.(optionsPeriods[optionsPeriods.length - 1].value);
            setMonth(optionsPeriods[optionsPeriods.length - 1].value.from);
        } else {
            setPeriodLabel({
                label: optionsPeriods[periodLabel.index - 1].label,
                index: periodLabel.index - 1,
            });
            setPeriod(optionsPeriods[periodLabel.index - 1].value);
            getPeriod?.(optionsPeriods[periodLabel.index - 1].value);
            setMonth(optionsPeriods[periodLabel.index - 1].value.from);
        }
    }

    function nextPeriod() {
        if (periodLabel.index + 1 === optionsPeriods.length) {
            setPeriodLabel({
                label: optionsPeriods[0].label,
                index: 0,
            });
            setPeriod(optionsPeriods[0].value);
            getPeriod?.(optionsPeriods[0].value);
            setMonth(optionsPeriods[0].value.from);
        } else {
            setPeriodLabel({
                label: optionsPeriods[periodLabel.index + 1].label,
                index: periodLabel.index + 1,
            });
            setPeriod(optionsPeriods[periodLabel.index + 1].value);
            getPeriod?.(optionsPeriods[periodLabel.index + 1].value);
            setMonth(optionsPeriods[periodLabel.index + 1].value.from);
        }
    }

    function setLabelValues() {
        let invalidOption = true;
        let existingOption;

        optionsPeriods.forEach((option, index) => {
            if (
                period?.from?.setHours(0, 0, 0) ===
                    option.value.from.setHours(0, 0, 0) &&
                period?.to?.setHours(0, 0, 0) ===
                    option.value.to.setHours(0, 0, 0)
            ) {
                invalidOption = false;
                existingOption = { label: option.label, index };
            }
        });

        if (period === undefined) {
            setPeriodLabel({ label: "Sem data", index: 7 });
        } else if (period && period !== undefined) {
            if (invalidOption) {
                if (period?.from === undefined) {
                    setPeriodLabel({
                        label: `?"
                        )} - ${format(period.to as Date, "dd/MM/yyyy")}`,
                        index: 7,
                    });
                } else if (period?.to === undefined) {
                    setPeriodLabel({
                        label: `${format(
                            period.from as Date,
                            "dd/MM/yyyy"
                        )} - ?`,
                        index: 7,
                    });
                } else {
                    setPeriodLabel({
                        label: `${format(
                            period.from as Date,
                            "dd/MM/yyyy"
                        )} - ${format(period.to as Date, "dd/MM/yyyy")}`,
                        index: 7,
                    });
                }
            } else if (!invalidOption && existingOption) {
                setPeriodLabel(existingOption);
            }
        }
    }

    useEffect(() => {
        setLabelValues();
    }, [period]);

    return (
        <Popover.Root
            onOpenChange={(e) => {
                if (!e) {
                    if (
                        period?.to === undefined ||
                        period?.from === undefined
                    ) {
                        setPeriod(defaultPeriod.value);
                        getPeriod?.(defaultPeriod.value);
                    } else getPeriod?.(period);
                }
            }}
        >
            <s.CalendarTrigger alternativeInput={alternativeInput}>
                {!alternativeInput && (
                    <button id="arrow-left" onClick={() => previousPeriod()}>
                        <Icons.Chevron color={theme.gray400} />
                    </button>
                )}

                <Popover.Trigger>
                    <div id="time-text">
                        <Icons.Calendario color={theme.gray800} />

                        <span id="period">
                            {noFilter ? "Sem filtro" : periodLabel.label}
                        </span>
                    </div>
                </Popover.Trigger>

                {!alternativeInput && (
                    <button id="arrow-right" onClick={() => nextPeriod()}>
                        <Icons.Chevron color={theme.gray400} />
                    </button>
                )}
            </s.CalendarTrigger>

            <Popover.Content
                alignOffset={-30}
                align="start"
                style={{ zIndex: 9999 }}
            >
                <s.Container>
                    <ul id="options-wrapper">
                        {optionsPeriods.map((option, index) => (
                            <s.Option
                                key={option.label}
                                className="option"
                                onClick={() => {
                                    setPeriod(option.value);
                                    setMonth(option.value.from);

                                    setPeriodLabel({
                                        label: option.label,
                                        index,
                                    });
                                }}
                                selected={compareDates(option.value)}
                            >
                                <span className="option-text">
                                    {option.label}
                                </span>
                            </s.Option>
                        ))}
                    </ul>

                    <div id="calendar-wrapper">
                        <DoubleCalendar
                            month={month}
                            setMonth={setMonth}
                            period={period}
                            setPeriod={setPeriod}
                        />
                    </div>
                </s.Container>
            </Popover.Content>
        </Popover.Root>
    );
};

export default PopoverCalendar;
