import { styled } from "styled-components";

import * as generalStyles from "../../styles";

export const Container = styled.div``;

export const Content = styled(generalStyles.Content)`
    h1 {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    svg {
        scale: 0.8;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TooltipContent = styled.div`
    width: 250px;
    padding: 12px;
    font-size: 13px;
    color: ${({ theme }) => theme.gray700};
`;
