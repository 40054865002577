import React from "react";

import { Icons, theme } from "../../../assets";
import * as s from "./styled-secondary-button";

type TSecondaryButton = {
    children: string | React.ReactNode;
    onClick?: () => void;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    variation?: "small" | "medium" | "big" | "link";
    style?: React.CSSProperties;
    className?: string;
    color?: string;
    icon?: JSX.Element;
    loading?: boolean;
};

const SecondaryButton = ({
    children,
    onClick,
    type,
    disabled,
    variation,
    style,
    className,
    color,
    icon,
    loading,
}: TSecondaryButton) => {
    return (
        <s.Button
            variation={variation || "medium"}
            type={type}
            onClick={onClick}
            disabled={disabled}
            style={style}
            className={className}
            color={color}
        >
            <div className="inside-button">
                {children}
                <>{icon}</>
                {loading && <Icons.Loading color={theme.purple500} />}
            </div>
        </s.Button>
    );
};

export default SecondaryButton;
