import React from "react";

import { SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalFeatureVideoProps = {
    title: string;
    embed: string;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalFeatureVideo = ({
    title,
    embed,
    setOpen,
}: ModalFeatureVideoProps) => {
    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.Title>{title}</s.Title>
                <s.VideoContainer>
                    <iframe
                        width="1000px"
                        height="600px"
                        src={`https://www.youtube.com/embed/${embed}`}
                    />
                </s.VideoContainer>
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen?.(false)}>
                        Fechar
                    </SecondaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
