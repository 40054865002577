export const timeToMinutes = (time?: string) => {
    if (!time) return 0;
    if (time.length === 2 || time.length === 1) {
        return Number(time) * 60;
    }
    const [hours, minutes] = time.split(":");
    return Number(hours) * 60 + Number(minutes);
};

export const minutesToTime = (minutes?: number) => {
    if (!minutes) return undefined;
    const hours = Math.floor(minutes / 60);
    const restMinutes = minutes % 60;
    return `${hours}:${restMinutes < 10 ? `0${restMinutes}` : restMinutes}`;
};

export const minutesToStringHoursAndMinutes = (minutes: number) => {
    const remainingMinutes = minutes % 60;
    const hours = Math.floor(minutes / 60);
    const minutesString =
        remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
    return `${hours}h ${minutesString}m`;
};
