/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, IlustracaoTelaVaziaHomem } from "../../../assets";
import {
    BlockTooltip,
    LoadingGray,
    PopoverUserList,
    Table,
    Tooltip,
} from "../../../components";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { roles } from "../../../services";
import { getDateTimeFromIsoString } from "../../../utils";
import * as s from "./styled-roles";

type TGetRoleObject = {
    id_e: string;
    name: string;
    organization_id: string;
    description: string;
    created_at: string;

    User: {
        name: string;
    }[];
};

export const Roles = () => {
    const { verifyPermission } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rolesData, setRolesData] = useState<TGetRoleObject[]>([]);

    const manageRolesPermission = verifyPermission({
        id: "roles_permissions",
        rule: "manage",
    });

    const getAllRoles = useFetch({
        fn: roles.getRole,
        start: true,
        params: {
            all: true,
            permissions: true,
            users: true,
            page: 0,
        },
    });

    useEffect(() => {
        if (getAllRoles.error) toast.error(`${getAllRoles.error}`);
        if (getAllRoles.response) {
            setRolesData(getAllRoles.response.roles);
            setLoading(false);
        }
    }, [getAllRoles.response]);

    const renderTableBody = () => {
        return rolesData.map((role, index) => {
            return {
                row: [
                    {
                        column: (
                            <BlockTooltip
                                key={index}
                                offset={12}
                                align="start"
                                side="bottom"
                                title="Descrição da atuação"
                                trigger={
                                    <div className="left">{role?.name}</div>
                                }
                            >
                                {role?.description ||
                                    "Responsável por atuar liderando setores."}
                            </BlockTooltip>
                        ),
                    },
                    { column: getDateTimeFromIsoString(role?.created_at) },
                    {
                        column: (
                            <Tooltip
                                side="bottom"
                                content={
                                    <PopoverUserList
                                        users={role?.User}
                                        message={
                                            "Ainda não há profissionais atrelados a essa atuação, para adicionar profissional, envie um convite ao profissional com a atuação."
                                        }
                                    />
                                }
                            >
                                <div className="pill">
                                    <p>{role?.User.length || "-"}</p>
                                </div>
                            </Tooltip>
                        ),
                    },
                    {
                        column: manageRolesPermission ? (
                            <button
                                onClick={() => {
                                    navigate(`${location.pathname}/editar`, {
                                        state: {
                                            role,
                                        },
                                    });
                                }}
                            >
                                <Icons.EditOutline title={"Editar"} />
                            </button>
                        ) : (
                            <button
                                onClick={() => {
                                    navigate(
                                        `${location.pathname}/visualizar`,
                                        {
                                            state: {
                                                role,
                                            },
                                        }
                                    );
                                }}
                            >
                                <Icons.Eye title={"Visualizar"} />
                            </button>
                        ),
                    },
                ],
            };
        });
    };

    return (
        <>
            {loading ? (
                <LoadingGray />
            ) : rolesData.length ? (
                <Table
                    templateColumns="1fr 1fr 1fr 1fr"
                    header={[
                        {
                            column: (
                                <BlockTooltip
                                    offset={12}
                                    align="start"
                                    side="bottom"
                                    title="Atuação"
                                    trigger={
                                        <div className="left">
                                            Atuação <Icons.Info />
                                        </div>
                                    }
                                >
                                    Veja a descrição passando o mouse no nome da
                                    atuação desejada
                                </BlockTooltip>
                            ),
                        },
                        { column: "Data de criação" },
                        {
                            column: (
                                <BlockTooltip
                                    offset={12}
                                    align="start"
                                    side="bottom"
                                    title="Profissionais por atuação"
                                    trigger={
                                        <>
                                            Profissionais por atuação{" "}
                                            <Icons.Info />
                                        </>
                                    }
                                >
                                    Veja quais são os profissionais por atuação
                                    passando o mouse no número da linha desejada
                                </BlockTooltip>
                            ),
                        },
                        {
                            column: manageRolesPermission
                                ? "Editar"
                                : "Visualizar",
                        },
                    ]}
                    body={renderTableBody()}
                />
            ) : (
                <s.NoContent>
                    <IlustracaoTelaVaziaHomem />
                    <h2>Ainda não há registro de atuação criada</h2>
                    <p>
                        Clique em <strong>CRIAR ATUAÇÃO</strong> e comece agora
                        a <br /> organizar melhor seus projetos
                    </p>
                </s.NoContent>
            )}
        </>
    );
};
