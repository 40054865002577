import * as yup from "yup";

export const PASSWORD_MIN_LENGTH = 8;

export const specialCharsRegex = /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/;
export const capitalLettersRegex = /[A-Z]/;

export default () =>
    yup.object().shape({
        currentPassword: yup
            .string()
            .required("Senha atual é obrigatória!")
            .min(5, "Senha deve conter no mínimo 5 caracteres."),
        newPassword: yup
            .string()
            .trim()
            .required("Insira a sua senha")
            .min(
                PASSWORD_MIN_LENGTH,
                `Deve conter no mínimo ${PASSWORD_MIN_LENGTH} caracteres`
            )
            .test({
                test: (password) => {
                    if (!password) return false;
                    return (
                        password.length >= PASSWORD_MIN_LENGTH &&
                        capitalLettersRegex.test(password) &&
                        specialCharsRegex.test(password)
                    );
                },
            })
            .oneOf([yup.ref("newPasswordConfirm")], "Senhas não conferem!"),
        newPasswordConfirm: yup
            .string()
            .required("Confirmação de senha é obrigatória!")
            .min(5, "Senha deve conter no mínimo 5 caracteres.")
            .oneOf([yup.ref("newPassword")], "Senhas não conferem!"),
    });
