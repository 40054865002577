import styled from "styled-components";

const calculateColumns = (length: number) =>
    Array.from({ length }).map(() => "1fr ");

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 110%;
    margin-left: -24px;
    margin-right: -24px;
`;

export const Row = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: ${({ columns }) => calculateColumns(columns)} 0fr;

    width: calc(100% + 11px);
    gap: 20px;

    padding: 18px 26px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};

    .more {
        opacity: 0;
    }

    &:hover {
        background-color: ${({ theme }) => theme.gray50};

        .more {
            opacity: 1;
        }
    }

    &:last-child {
        border-bottom: none;
    }
`;

export const Header = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: ${({ columns }) => calculateColumns(columns)} 0fr;

    width: 100%;
    gap: 20px;

    padding: 12px 26px;
    border-top: 1px solid ${({ theme }) => theme.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
`;

export const Head = styled.div<{ orderBy?: "asc" | "desc" }>`
    position: relative;

    display: flex;
    align-items: center;
    gap: 8px;

    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;
    text-transform: uppercase;

    cursor: ${({ orderBy }) => (orderBy ? "pointer" : "auto")};

    svg {
        transition: all 0.5s;
        rotate: ${({ orderBy }) => (orderBy === "asc" ? "0deg" : "180deg")};
    }
`;

export const Data = styled.div<{ bold?: boolean }>`
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ bold }) => (bold ? "600" : "400")};
    line-height: 20px;
    letter-spacing: ${({ bold }) => (bold ? "0.07px" : "0.14px")};

    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 10vw;
    overflow: hidden;

    p {
        width: 75px;
    }
`;

export const Total = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: ${({ columns }) => calculateColumns(columns)} 0fr;

    width: 100%;
    gap: 20px;
    padding: 18px 26px;
    border-top: 1px solid ${({ theme }) => theme.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    background-color: ${({ theme }) => theme.gray50};
`;

export const Body = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 30vh;
`;

export const NoData = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.14px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
`;

export const NewRowContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 18px;

    width: 100%;
    background-color: ${({ theme }) => theme.pink50};
    padding: 16px;
`;

export const NewRowFields = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1.1fr;
    gap: 20px;
`;

export const NewRowButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    button {
        &:first-child {
            width: 113px;
        }
        &:last-child {
            width: 89px;
        }
    }
`;

export const CreateEditBenefit = styled.form`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 6px;
    padding-bottom: 20px;
    width: 100%;

    background-color: ${({ theme }) => theme.pink50};

    .input-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        text-align: left;

        div {
            width: 300px;
        }

        p {
            width: 300px;
        }
    }

    input {
        width: 300px;
    }
`;

export const CreateEditBenefitButtons = styled.div`
    display: flex;
    align-self: flex-end;
    padding-bottom: 17px;
    gap: 20px;

    button {
        &:first-child {
            width: 113px;
        }
        &:last-child {
            width: 89px;
        }
    }
`;

export const CreateEditBenefitFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Status = styled.div<{ active?: boolean }>`
    color: ${({ active, theme }) => (active ? theme.green1 : theme.gray600)};
    background-color: ${({ active, theme }) =>
        active ? theme.lightGreen : theme.lightGray};
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.07px;
    text-transform: capitalize;
`;
