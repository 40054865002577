import styled from "styled-components";

import { theme } from "../../../../assets";

type TSelect = {
    error?: boolean;
};

type TPositionY = {
    positionY?: string;
    disabled?: boolean;
};

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    width: 100%;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Flex = styled.div<TSelect>`
    .react-select__menu-list {
        max-height: 12.5rem;
    }

    .react-select__control {
        width: 100%;
        border: none;
        border-radius: 0.25rem;
        height: 2.5rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        width: 100%;
        box-shadow: none;
        font-size: 0.875rem;
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
        cursor: pointer;
    }

    .react-select__control--is-focused:hover {
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
    }

    .react-select__clear-indicator,
    .react-select__indicator-separator {
        display: none;
    }

    .react-select--is-disabled {
        visibility: unset;
        cursor: not-allowed;
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const PositionIcons = styled.div<TPositionY>`
    display: flex;
    position: absolute;
    right: 0.625rem;
    top: ${({ positionY }) => (positionY ? `${positionY}rem` : "2.5rem")};
    ${({ disabled }) => (disabled ? `cursor: not-allowed` : `cursor: pointer`)};
`;

export const Option = styled.div<{ color?: string }>`
    div {
        display: flex;
        flex-direction: row;
        width: max-content;
        border-radius: 4px;
        padding: 4px 7px 4px 12px;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: 100%;
        border: 1px solid ${({ color }) => color || theme.purple500}1A;
        background: ${({ color }) => color || theme.purple500}0D;
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
            background: ${({ color }) => color || theme.purple500}1A;
        }

        &::before {
            content: "";
            display: flex;
            min-width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ color }) => color || theme.purple500};
        }

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
`;

export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 12.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
`;

export const NoOptions = styled.div`
    margin: 1.25rem;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 0.9375rem;

    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    color: ${theme.purple600};
`;
