import { Tooltip } from "..";

import { theme } from "../../../assets";
import { minutesToStringDate } from "../../../utils";
import * as s from "./styled-timeline";

const correctColor = {
    green1: theme.green1,
    pink1: theme.pink1,
};

type TTimeline = {
    barAtributes: {
        color: string;
        title?: string;
        thickness?: number;
        totalTimeInMinutes: number;
        barDelimiter?: boolean;
    }[];
};

export default ({ barAtributes }: TTimeline) => {
    const totalTime = barAtributes.reduce(
        (acc, item) => acc + item.totalTimeInMinutes,
        0
    );

    const checkColor = (color: string) => {
        if (color === "green1") {
            return correctColor.green1;
        }
        if (color === "pink1") {
            return correctColor.pink1;
        }
        return color;
    };

    return (
        <s.Container>
            <s.BarContainer>
                {barAtributes.map((bar, index) => (
                    <Tooltip
                        arrow
                        key={index}
                        content={
                            <s.TooltipContent style={{ marginBottom: 8 }}>
                                {bar.title}
                                <s.TooltipDuration>
                                    {minutesToStringDate(
                                        bar.totalTimeInMinutes
                                    )}
                                </s.TooltipDuration>
                                <s.TooltipPercentage>
                                    {(
                                        (bar.totalTimeInMinutes / totalTime) *
                                        100
                                    ).toFixed(1)}
                                    %
                                </s.TooltipPercentage>
                            </s.TooltipContent>
                        }
                    >
                        <s.Bar
                            color={checkColor(bar.color)}
                            percentage={
                                (bar.totalTimeInMinutes / totalTime) * 100
                            }
                            thickness={bar.thickness}
                        />
                    </Tooltip>
                ))}
            </s.BarContainer>
        </s.Container>
    );
};
