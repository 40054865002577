import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateSubItem = {
    title?: string;
    users?: string[];
    finished?: boolean;
    subtaskId?: string;
};

export default async ({
    title,
    finished,
    users,
    subtaskId,
}: TUpdateSubItem = {}) => {
    const headers = {
        "x-subtask-id": subtaskId,
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/subtask/update`,
        method: "put",
        body: {
            title,
            finished,
            users,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
