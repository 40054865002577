import styled from "styled-components";

export const Container = styled.form`
    padding: 20px;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 500px;
    border-radius: 8px;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
