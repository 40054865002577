import styled from "styled-components";

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 6px;

    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const ProgressBar = styled.div`
    position: relative;
    width: 100%;
    height: 12px;
    border-radius: 8px;
    background: ${({ theme }) => theme.gray100};
`;

export const Progress = styled.div<{ percentage: number }>`
    max-width: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.green1};
    height: 12px;
    border-radius: 8px;
    width: ${({ percentage }) => percentage}%;
    transition: width 0.5s ease-in-out;
`;
