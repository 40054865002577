import { RefCallback } from "react";
import Select, { Options } from "react-select";

import { TOption } from "../../../../models";
import * as s from "./styled-select-input";

type TSelect = {
    error?: string;
    label?: string;
    options?: TOption[];
    onChange?: any;
    name?: string;
    inputRef?: RefCallback<Record<string, string>>;
    placeholder?: string;
    icon?: JSX.Element;
    value?: string | TOption | TOption[] | null;
    positionY?: string;
    onInputChange?: (e: string) => void;
    disabled?: boolean;
    isMulti?: boolean;
    noError?: boolean;
    noLabel?: boolean;
    noForm?: boolean;
    formatOptionLabel?: (
        option: string | TOption | null | undefined,
        input: { inputValue: string }
    ) => JSX.Element;
    isOptionDisabled?: (
        option: string | TOption,
        selectValue?: Options<string | TOption>
    ) => boolean;
};

const SelectInput = ({
    error,
    label,
    options,
    onChange,
    name,
    placeholder,
    icon,
    value,
    onInputChange,
    disabled,
    isMulti,
    noError,
    noLabel,
    noForm,
    formatOptionLabel,
    isOptionDisabled,
}: TSelect) => {
    const DropdownIndicator = () => {
        return <s.PositionIcons>{!disabled && icon}</s.PositionIcons>;
    };

    return (
        <s.FlexColumn className="select-input">
            {!noLabel && <s.Label>{label}</s.Label>}
            <s.Flex error={!!error}>
                <Select
                    placeholder={placeholder || "selecione"}
                    name={name}
                    onChange={onChange}
                    classNamePrefix={"react-select"}
                    options={options}
                    value={noForm ? value || null : value}
                    onInputChange={onInputChange}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    components={{ DropdownIndicator }}
                    noOptionsMessage={() => "Sem opções"}
                    menuPlacement={"auto"}
                    formatOptionLabel={formatOptionLabel}
                    isOptionDisabled={isOptionDisabled}
                />
            </s.Flex>
            {!noError && <s.Error>{error}</s.Error>}
        </s.FlexColumn>
    );
};

export default SelectInput;
