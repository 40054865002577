import { ReactNode } from "react";

import * as s from "./styled-acordion";

type TAccordion = {
    children: ReactNode;
    trigger: JSX.Element[] | JSX.Element;
    collapsible?: boolean;
    open?: boolean;
    controlled?: boolean;
};

const Accordion = ({
    children,
    trigger,
    collapsible,
    open,
    controlled,
}: TAccordion) => {
    const setMode = () => {
        if (!open && !controlled) return undefined;
        if (open) return "item-1";
        return "empty-item";
    };
    return (
        <s.StyledAccordion
            onKeyDown={(e) => {
                e.stopPropagation();
            }}
            type="single"
            collapsible={collapsible}
            value={setMode()}
        >
            <s.StyledItem value="item-1" open={open}>
                <s.StyledHeader>
                    <s.StyledButton asChild>{trigger}</s.StyledButton>
                </s.StyledHeader>
                <s.StyledPanel>{children}</s.StyledPanel>
            </s.StyledItem>
        </s.StyledAccordion>
    );
};

export default Accordion;
