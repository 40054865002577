import styled, { createGlobalStyle } from "styled-components";

import { theme } from "../assets";

type TFlex = {
    isLogged: boolean;
};

const GlobalStyle = createGlobalStyle`
* {
    padding: 0px;
    margin: 0px;
    font-family: noto-sans, sans-serif;
    outline: none;
    box-sizing: border-box;

    //Firefox
    scrollbar-width: thin;
    scrollbar-color: ${theme.purple600} ${theme.pink50};

    //Chrome, edge, safari
    ::-webkit-scrollbar {
      width: 8px;
      background: ${theme.pink50};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${theme.purple600};
      width: 10px;
    }
}

@media (min-width: 1024px) {
    html {
        font-size: 100%;
    }
}
@media (max-width: 1023px) {
    html {
        font-size: 95%;
    }
}
@media (max-width: 991px) {
     html {
        font-size: 90%;
    }
}
@media (max-width: 767px) {
    html {
        font-size: 75%;
    }
}
@media (max-width: 479px) {
    html {
        font-size: 60%;
    }
}

html,body {
    scroll-behavior: smooth;
    height:100%;
    width:100%;
}

input, button, textarea {
    border: none;
}

button {
    background: none;
    cursor: pointer;
}

button, input, textarea {
    :disabled {
        cursor: not-allowed;
    }
}

a{
    text-decoration: none;
}

.icon-loading {
    transform-origin: center;
    animation: spinner 0.75s infinite linear;

    svg {
        color: white;
    }
}
@keyframes spinner {
    100% {
        transform: rotate(360deg);
    }
}
`;

export const Flex = styled.div<TFlex>`
    display: flex;
    flex-direction: ${({ isLogged }) => (isLogged ? "column" : "row")};

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;
export const Main = styled.main`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default GlobalStyle;
