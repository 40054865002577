import { useState } from "react";

import { PrimaryButton } from "../../../../../../../../components";
import { CreateNPS } from "../../modals/create-nps/create-nps";
import * as s from "./styles";

export const Actions = () => {
    const [isToCreate, setIsToCreate] = useState(false);

    return (
        <>
            {isToCreate && <CreateNPS setIsToCreate={setIsToCreate} />}
            <s.Container>
                <PrimaryButton onClick={() => setIsToCreate(true)}>
                    Criar NPS
                </PrimaryButton>
            </s.Container>
        </>
    );
};
