import { IPermission } from "../../pages/organization/roles-form/roles-form";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateRole = {
    name?: string;
    description?: string;
    roleId?: string;
    permissions?: IPermission[];
};

export default async ({
    name,
    description,
    roleId,
    permissions,
}: TUpdateRole = {}) => {
    const params = {
        name,
        description,
        permissions,
    };

    const tempHeaders = {
        auth: true,
        "x-role-id": roleId,
    };

    if (!roleId) delete tempHeaders["x-role-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/roles/update`,
        method: "put",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;
    const errors: { [key: string]: string } = {
        "A role with this name already exists!":
            "Já existe uma atuação com o mesmo nome!",
        "default role cannot be updated!":
            "Uma atuação padrão não pode ser atualizada!",
        "The x-permissions-id field is required.":
            "Você precisa dar alguma permissão para a criação desta atuação!",
    };
    const defaultMessage = "Houve um problema ao criar a atuação!";

    const errorFeedBacks = {
        404: "Sem atuações!",
        400:
            errors[body.message] ||
            errors[body.message?.["x-permissions-id"]?.[0]] ||
            defaultMessage,
        409: errors[body.message] || defaultMessage,
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
