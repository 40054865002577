import * as s from "./styled-hour-progress";

type THourProgressProps = {
    hourProgress: {
        minutesDone: number;
        percentageOfHoursDone: number;
    };
    expectedMinutes?: number;
};

export const HourProgress = ({
    hourProgress,
    expectedMinutes,
}: THourProgressProps) => {
    const lessThanTen = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };

    const minutesToHours = (receivedMinutes: number) => {
        const hours = Math.floor(receivedMinutes / 60);
        const minutes = receivedMinutes % 60;
        return `${lessThanTen(hours)}:${lessThanTen(minutes)}`;
    };

    return (
        <s.Container>
            <s.Information>
                {hourProgress.minutesDone
                    ? minutesToHours(hourProgress.minutesDone)
                    : "00:00"}
                <span>{hourProgress.percentageOfHoursDone || 0}%</span>
                {expectedMinutes ? minutesToHours(expectedMinutes) : "00:00"}
            </s.Information>
            <s.ProgressBar>
                <s.Bar
                    percentage={
                        hourProgress.percentageOfHoursDone
                            ? hourProgress.percentageOfHoursDone
                            : 0
                    }
                />
            </s.ProgressBar>
        </s.Container>
    );
};
