import { theme } from "../../../../../../../assets";

export const priorities = {
    low: {
        label: "Baixa",
        value: "low",
        color: theme.green1,
    },
    medium: {
        label: "Média",
        value: "medium",
        color: theme.orange,
    },
    high: {
        label: "Alta",
        value: "high",
        color: theme.redError,
    },
};
