import styled from "styled-components";

export const ContainerForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 34.75rem;
    height: 35.625rem;
    padding: 2rem;
    gap: 1.5rem;

    h1 {
        font-weight: 600;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.purple600};
        margin-bottom: 0.5rem;
    }

    p#description {
        font-weight: 400;
        font-size: 1rem;
        color: ${({ theme }) => theme.gray800};
        margin: 2rem 0;
    }

    div#button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            padding: 1rem;
            width: fit-content;
            gap: 0.625rem;
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 34.75rem;
    padding: 2rem;
    gap: 1.5rem;

    h1 {
        font-weight: 600;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.purple600};
        margin-bottom: 0.5rem;
    }

    p#description {
        font-weight: 400;
        font-size: 1rem;
        color: ${({ theme }) => theme.gray800};
        margin: 2rem 0;
    }

    div#button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            padding: 1rem;
            width: fit-content;
            gap: 0.625rem;
        }
    }
`;

export const AdminInfos = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    gap: 1rem;

    span.field-title {
        display: flex;
        gap: 0.5rem;
        font-weight: 400;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray400};

        span.field-description {
            color: ${({ theme }) => theme.gray800};
        }
    }
`;
