import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="-1.5 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-email"}
        >
            <title>{title}</title>
            <path
                d="M1.5 17.0001V8.07011C1.49995 7.74092 1.58116 7.41682 1.73641 7.12654C1.89166 6.83627 2.11617 6.58881 2.39 6.40611L9.39 1.74011C9.71866 1.52083 10.1049 1.40381 10.5 1.40381C10.8951 1.40381 11.2813 1.52083 11.61 1.74011L18.61 6.40611C18.8838 6.58881 19.1083 6.83627 19.2636 7.12654C19.4188 7.41682 19.5 7.74092 19.5 8.07011V17.0001M1.5 17.0001C1.5 17.5305 1.71071 18.0392 2.08579 18.4143C2.46086 18.7894 2.96957 19.0001 3.5 19.0001H17.5C18.0304 19.0001 18.5391 18.7894 18.9142 18.4143C19.2893 18.0392 19.5 17.5305 19.5 17.0001M1.5 17.0001L8.25 12.5001M19.5 17.0001L12.75 12.5001M1.5 8.00011L8.25 12.5001M19.5 8.00011L12.75 12.5001M12.75 12.5001L11.61 13.2601C11.2813 13.4794 10.8951 13.5964 10.5 13.5964C10.1049 13.5964 9.71866 13.4794 9.39 13.2601L8.25 12.5001"
                stroke={color || theme.purple400}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
