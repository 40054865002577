import React, { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { getCurrentMonthAndYear } from "../../../../utils";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-edit-hours";

type TEditHoursModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setHours: (hours: string) => void;
    selectedCollaboratorsQuantity: number;
};

const ModalEditHours = ({
    setOpen,
    setHours,
    selectedCollaboratorsQuantity,
}: TEditHoursModalProps) => {
    const { register, watch } = useForm();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (watch("hours") === "") {
            toast.error("Preencha o campo de horas para continuar");
            return;
        }
        setHours(watch("hours"));
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <>
                <form onSubmit={handleSubmit}>
                    <s.StyledContainer>
                        <s.StyledTitle>Editar horas no mês</s.StyledTitle>
                        <s.StyledInformation>
                            Defina o número de horas a serem trabalhadas no mês
                            vigente:
                            <span> {getCurrentMonthAndYear()}</span>
                        </s.StyledInformation>
                        <s.StyledHoursInput>
                            <Input {...register("hours")} mask="999"></Input>
                        </s.StyledHoursInput>
                        <s.StyledInformation>
                            Deseja definir para todos os profissionais
                            selecionados ({selectedCollaboratorsQuantity}) ?
                        </s.StyledInformation>
                    </s.StyledContainer>
                    <s.StyledButtonsContainer>
                        <SecondaryButton onClick={() => setOpen(false)}>
                            CANCELAR
                        </SecondaryButton>
                        <PrimaryButton type="submit">
                            DEFINIR HORAS
                        </PrimaryButton>
                    </s.StyledButtonsContainer>
                </form>
            </>
        </ContainerModal>
    );
};

export default ModalEditHours;
