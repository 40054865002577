import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const LastWeekOwners = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.Favorite color={theme.purple500} />
                Donos de organização da última semana
            </generalStyles.Title>
            <generalStyles.Row>
                {data.ownersCreatedLastWeek.length > 0 ? (
                    <generalStyles.Content>
                        {data.ownersCreatedLastWeek.map(({ email }) => (
                            <p key={email}>{email}</p>
                        ))}
                    </generalStyles.Content>
                ) : (
                    <generalStyles.NoData>
                        <p>
                            Nenhum dono de organização criado na última semana
                        </p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
