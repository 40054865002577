import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 33px;

    .profile-root {
        width: 100%;
        z-index: 0;
        overflow-x: clip;
    }

    .profile-tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 2.6875rem;
        background-color: ${({ theme }) => theme.purple50};
        white-space: nowrap;

        button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${({ theme }) => theme.gray600};
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px 4px 0px 0px;
        }

        [data-state="active"] {
            background-color: ${({ theme }) => theme.white};
            color: ${({ theme }) => theme.gray800};
            border-bottom: 0px;
        }
    }
`;

export const Header = styled.div`
    width: 100%;
    padding: 0px 33px;
    padding-top: 49px;
`;

export const ProjectContainer = styled.div`
    margin-top: 33px;
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray200};
    background: ${({ theme }) => theme.white};
    padding: 13px 35px;
    display: flex;
    gap: 16px;
    align-items: center;

    span {
        color: ${({ theme }) => theme.gray400};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.12px;
    }
`;
