import { useMutation } from "@tanstack/react-query";

import { organizations } from "../../services";
import { ReactQueryHookParams } from "./_index";

export function useChangeOrganizationOwner({
    onSuccess,
}: ReactQueryHookParams = {}) {
    return useMutation({
        mutationKey: ["changeOrganizationOwner"],
        mutationFn: organizations.changeOwner,
        onSuccess,
    });
}
