import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../assets";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TUser } from "../../models";
import { manageUser } from "../../services";
import { getPercentage } from "../../utils";
import { Mixpanel } from "../../utils/mixpanel/Mixpanel";
import {
    TabContacts,
    TabContract,
    TabExperience,
    TabHistoric,
    TabResume,
    TabProjects,
} from "./components/collaborator";
import { TabBenefits } from "./components/collaborator/tab-benefits/tab-benefits";
import * as s from "./styled-collaborator";

export const Collaborator = () => {
    const { state } = useLocation();
    const { verifyPermission, user } = useUser();
    const isMyProfile = state.id === user.id_e;
    const haveTabPermission =
        verifyPermission({
            id: "professionals",
            rule: "view",
        }) || isMyProfile;
    const canViewBenefits =
        verifyPermission({
            id: "professionals_values",
            rule: "view",
        }) || isMyProfile;

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [workedHoursLimit, setWorkedHoursLimit] = useState<number>(0);

    const [allDuration, setAllDuration] = useState(0);
    const [userData, setUserData] = useState<TUser>({});
    const [selectedTab, setSelectedTab] = useState(
        verifyPermission({
            id: "professionals",
            rule: "view",
        })
            ? "resume"
            : "contacts"
    );
    const [limits, setLimits] = useState({ greaterThan80: false, max: false });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const manageWorkedHours = (time: number) => {
        const percentage = getPercentage({
            total: workedHoursLimit,
            quantity: time,
        });
        if (percentage > 80 && !limits.greaterThan80)
            setLimits((prevState) => ({ ...prevState, greaterThan80: true }));
        else if (percentage < 80 && limits.greaterThan80)
            setLimits((prevState) => ({ ...prevState, greaterThan80: false }));
        if (percentage >= 100 && !limits.max)
            setLimits((prevState) => ({ ...prevState, max: true }));
        else if (percentage < 100 && limits.max)
            setLimits((prevState) => ({ ...prevState, max: false }));
    };

    useEffect(() => {
        if (state.id) {
            getUser.onRefresh({
                userId: state.id,
                sectors: true,
                projects: true,
                benefits: true,
            });
        }
    }, [state.id]);

    useEffect(() => {
        if (getUser.response) {
            setUserData(getUser.response);
            setWorkedHoursLimit(
                getUser.response.Organization.monthly_hour_limit * 60
            );
            setAllDuration(getUser.response.monthlyTime);
            setLoading(false);
        }
    }, [getUser.response, getUser.error]);

    useEffect(() => {
        if (userData?.workload) {
            setWorkedHoursLimit(userData.workload);
        }
    }, []);

    useEffect(() => {
        manageWorkedHours(allDuration);
    }, [allDuration]);

    const profileTabs = () => (
        <Tabs.Root
            className="profile-root"
            defaultValue={selectedTab}
            orientation="vertical"
            onValueChange={(value) => {
                Mixpanel.track("Aba acessada", {
                    page: "profissional",
                    tab: value,
                });
            }}
        >
            <Tabs.List aria-label="tabs example" className="profile-tabs">
                <Tabs.Trigger value="resume">Perfil Resumo</Tabs.Trigger>

                {haveTabPermission && (
                    <>
                        <Tabs.Trigger value="contacts">
                            Perfil / Contatos
                        </Tabs.Trigger>
                        <Tabs.Trigger value="contracts">Contrato</Tabs.Trigger>
                    </>
                )}

                <Tabs.Trigger value="projects">Projetos</Tabs.Trigger>
                {canViewBenefits && (
                    <Tabs.Trigger value="benefits">Benefícios</Tabs.Trigger>
                )}
                <Tabs.Trigger value="experience">Experiência</Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="resume">
                <TabResume
                    loading={loading}
                    userData={userData}
                    getUserOnRefresh={getUser.onRefresh}
                />
            </Tabs.Content>

            <Tabs.Content value="contacts">
                <TabContacts
                    loading={loading}
                    userData={userData}
                    getUserOnRefresh={getUser.onRefresh}
                    userId={state.id}
                    setSelectedTab={setSelectedTab}
                />
            </Tabs.Content>

            <Tabs.Content value="contracts">
                <TabContract
                    loading={loading}
                    userData={userData}
                    setUserData={setUserData}
                    userId={state.id}
                    setSelectedTab={setSelectedTab}
                    getUserOnRefresh={getUser.onRefresh}
                />
            </Tabs.Content>

            <Tabs.Content
                value="projects"
                style={{
                    zIndex: 99,
                }}
            >
                <TabProjects />
            </Tabs.Content>

            <Tabs.Content value="benefits">
                <TabBenefits userData={userData} />
            </Tabs.Content>

            <Tabs.Content value="experience">
                <TabExperience
                    loading={loading}
                    userData={userData}
                    setUserData={setUserData}
                    userId={state.id}
                    setSelectedTab={setSelectedTab}
                    getUserOnRefresh={getUser.onRefresh}
                />
            </Tabs.Content>

            <Tabs.Content value="historic">
                <TabHistoric userId={state.id} />
            </Tabs.Content>
        </Tabs.Root>
    );

    return (
        <s.Container>
            <s.Breadcrumb>
                <Icons.Arrow
                    className="arrow-left"
                    onClick={() => navigate("/profissionais")}
                    title="Profissionais"
                />
                <a
                    className="breadcrumb"
                    onClick={() => navigate("/profissionais")}
                    title="Profissionais"
                >
                    Profissionais
                </a>
                <a href="" title=""></a>
                <Icons.Chevron
                    className="chevron-rigth"
                    color={theme.gray400}
                />
                <p className="blacktext">Perfil</p>
            </s.Breadcrumb>
            {profileTabs()}
        </s.Container>
    );
};
