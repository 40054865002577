import { Dispatch, SetStateAction } from "react";

import { PrimaryButton, SecondaryButton } from "../../..";
import { Icons } from "../../../../assets";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-invite-send";

type TModalInviteSend = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    sendAllSketches?: any;
    sketchData?: any;
};

const ModalInviteSend = ({
    setOpen,
    sendAllSketches,
    sketchData,
}: TModalInviteSend) => {
    const validateSketches = (data: any) => {
        let willSend = 0;
        let willNotSend = 0;

        data.forEach((sketch: any) => {
            if (sketch.name.isValid && sketch.email.isValid) {
                willSend += 1;
            } else {
                willNotSend += 1;
            }
        });

        return { willSend, willNotSend };
    };

    const { willSend, willNotSend } = validateSketches(
        sketchData?.inviteSketchReturn
    );

    return (
        <ContainerModal>
            <s.Container>
                <s.Title>Convidar profissionais</s.Title>

                <s.Text>
                    <p>
                        Ao convidar um profissional para a sua organização do
                        PaperON, você está dando a ele acesso ao cronômetro de
                        atividades, além de outras funcionalidades da
                        plataforma.
                    </p>
                    <p>
                        Somente os convites{" "}
                        <span>sem sinalização de erros</span> serão enviados.{" "}
                    </p>
                    <p>
                        <span>{willSend || `0`}</span> convites sem erros: serão
                        enviados <br />
                        <span>{willNotSend || `0`}</span> convites com erros:
                        não serão enviados
                    </p>
                    <p>
                        Os destinatários tem <span>24 horas</span> para aceitar
                        este convite. Se os convites expirarem, você poderá
                        reenviá-los na aba de convites enviados.
                    </p>
                </s.Text>

                <s.ButtonsContainer>
                    <SecondaryButton
                        variation="small"
                        onClick={() => setOpen(false)}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        variation="small"
                        onClick={() => {
                            sendAllSketches(sketchData?.inviteSketchReturn);
                        }}
                        disabled={Boolean(
                            !sketchData?.inviteSketchReturn.length
                        )}
                        icon={<Icons.SendFilled />}
                    >
                        ENVIAR CONVITES
                    </PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalInviteSend;
