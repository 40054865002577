import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 21rem;
    padding-bottom: 2rem;
    width: 100%;
    top: 2.9375rem;
`;

export const TableHeader = styled.div`
    cursor: default;
    display: grid;
    grid-template-columns: 15rem 1fr 1fr 1fr 1fr 0.3fr;
    place-items: center;
    height: 3rem;
    padding: 0 1.5rem;
    background-color: ${({ theme }) => theme.white};
    border-left: 1px solid ${({ theme }) => theme.gray200};
    border-right: 1px solid ${({ theme }) => theme.gray200};

    color: ${({ theme }) => theme.gray800};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
`;

export const TableContent = styled.div`
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
`;

export const TableColumn = styled.div<{ width: string }>`
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 25rem;
    padding: 1.5rem;
    gap: 1.5rem;
    align-items: center;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const HeaderTooltip = styled.span`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 11px;
    width: 412px;
    margin-top: 0.8125rem;

    header {
        display: flex;
        align-items: center;
        gap: 12px;
        color: ${({ theme }) => theme.gray800};
        font-weight: 600;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    p {
        color: var(--color-gray-600, #6e697a);
        font-size: 12px;
        font-weight: 400;
    }
`;
