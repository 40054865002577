import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../assets";
import { PrimaryButton } from "../../components";
import { HowYouKnowUs } from "../../components/molecules/how-you-know-us/how-you-know-us";
import { PrivacyPolicy, UseTerms } from "./constants";
import * as s from "./styled-google-register";

export const GoogleRegister = () => {
    const navigate = useNavigate();
    const [howYouKnowUs, setHowYouKnowUs] = useState("");

    const routeParamString = window.location.search.split("?")[1];
    const { credential, email } = JSON.parse(
        decodeURIComponent(routeParamString)
    );

    const handleCreateAccount = () => {
        navigate(
            `/login?credential=${credential}&howYouKnowUs=${howYouKnowUs}`
        );
    };

    return (
        <s.Container>
            <s.Header>Você está quase lá!</s.Header>
            <s.Description>
                Você está prestes a criar uma nova conta no{" "}
                <strong>Paper ON</strong> utilizando o login do <Icons.Google />{" "}
                Google ({email})
            </s.Description>
            <HowYouKnowUs setHowYouKnowUsOutside={setHowYouKnowUs} />
            <PrimaryButton onClick={handleCreateAccount}>
                cadastrar
            </PrimaryButton>
            <s.Terms>
                Ao clicar em “Cadastrar”, você leu e concorda com os nossos{" "}
                {UseTerms} e {PrivacyPolicy}
            </s.Terms>
        </s.Container>
    );
};
