import dayjs from "dayjs";
import { useState } from "react";

import { PrimaryButton } from "../../../../../../components";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import { NewNotificationModal } from "./modals/new-notification/new-notification";
import * as s from "./styles";

export function NotificationsTab({ data }: { data: TBackOfficeData }) {
    const [open, setOpen] = useState(false);

    return (
        <s.Container>
            {open && <NewNotificationModal setOpen={setOpen} />}
            <PrimaryButton onClick={() => setOpen(true)}>
                Criar notificação
            </PrimaryButton>
            <s.NotificationsHistoryContainer>
                <generalStyles.Title>
                    Histórico de notificações criadas
                </generalStyles.Title>
                <generalStyles.Row>
                    {data.createdNotificationsHistory.length > 0 ? (
                        <generalStyles.Content>
                            {data.createdNotificationsHistory.map(
                                ({ title, created_at }, index) => (
                                    <>
                                        <span>
                                            <div key={index}>{title}</div>
                                            <div key={index}>
                                                {dayjs(created_at).format(
                                                    "DD/MM/YYYY [às] HH:mm"
                                                )}
                                            </div>
                                        </span>
                                        <generalStyles.Divider />
                                    </>
                                )
                            )}
                        </generalStyles.Content>
                    ) : (
                        <generalStyles.NoData>
                            <p>Nenhuma notificação criada</p>
                        </generalStyles.NoData>
                    )}
                </generalStyles.Row>
            </s.NotificationsHistoryContainer>
        </s.Container>
    );
}
