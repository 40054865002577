import { TUser } from "../../../../models";
import * as s from "./styled-popover-user-list";

const PopoverUserList = ({
    users,
    message,
}: {
    users?: TUser[];
    message?: string;
}) => {
    if (users) {
        return (
            <s.UsersList>
                <div id="users-header">
                    <span id="title">Usuários</span>
                </div>
                <div id="users-body">
                    {users.length > 0 ? (
                        users?.map((user, index) => {
                            return (
                                <span key={index} id="user-name">
                                    <p>
                                        {user.name}
                                        {user?.role ? ` - ${user?.role}` : ""}
                                    </p>
                                </span>
                            );
                        })
                    ) : (
                        <span>{message || "Sem usuários"}</span>
                    )}
                </div>
            </s.UsersList>
        );
    }
    return (
        <s.UsersList>
            <div id="users-header">
                <span id="title">Usuários</span>
            </div>
            {message || "Sem usuários"}
        </s.UsersList>
    );
};

export default PopoverUserList;
