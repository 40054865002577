import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Error = "alguma coisa";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/`,
        method: "get",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const error: Error =
        body?.message?.info ||
        body?.message?.name?.[0] ||
        body?.message?.name?.[0] ||
        body?.message;

    const errorsMapper = {
        "alguma coisa": "mensagem para aparecer no toast",
        "trial period ended": "Seu período de teste acabou!",
    };

    const permissionErrorMapper = {
        "alguma coisa": "mensagem para aparecer no toast",
        "trial period ended": "Seu período de teste acabou!",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Erro!",
        403: permissionErrorMapper[error] || "Erro!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
