import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useFetch } from "../../../../hooks";
import { answerHowYouKnowUs } from "../../../../services/user";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-how-you-know-us";

export type THowDoYouKnowUsOptions =
    | "Google"
    | "LinkedIn"
    | "Instagram"
    | "E-mail"
    | "Indicação de amigo/colega"
    | "Empresa em que trabalho"
    | "Outro";

type THowDoYouKnowUsAlternatives = {
    id: string;
    value: THowDoYouKnowUsOptions;
    label: string;
}[];

type TModalHowYouKnowUs = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const howYouKnowUsAlternatives: THowDoYouKnowUsAlternatives = [
    {
        id: "google",
        value: "Google",
        label: "Google",
    },
    {
        id: "linkedin",
        value: "LinkedIn",
        label: "LinkedIn",
    },
    {
        id: "instagram",
        value: "Instagram",
        label: "Instagram",
    },
    {
        id: "email",
        value: "E-mail",
        label: "E-mail",
    },
    {
        id: "indication",
        value: "Indicação de amigo/colega",
        label: "Indicação de amigo/colega",
    },
    {
        id: "my_company",
        value: "Empresa em que trabalho",
        label: "Empresa em que trabalho",
    },
    {
        id: "other",
        value: "Outro",
        label: "Outro",
    },
];

const ModalHowYouKnowUs = ({ setOpen }: TModalHowYouKnowUs) => {
    const answerReq = useFetch({
        fn: answerHowYouKnowUs,
        start: false,
    });

    const [howYouKnowUs, setHowYouKnowUs] = useState<string>("");
    const [other, setOther] = useState<string>("");

    const handleAnswer = () => {
        const answer = howYouKnowUs === "other" ? other : howYouKnowUs;
        answerReq.onRefresh({
            answer,
        });
    };

    useEffect(() => {
        if (answerReq.response) {
            toast.success("Resposta enviada com sucesso!");
            setOpen(false);
        }
    }, [answerReq.response]);

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Seja bem-vindo ao PaperON!</s.Title>
                <s.HowYouKnowUs>
                    <h4>Como você ouviu falar de nós?</h4>
                    <s.CheckBoxContainer>
                        <s.RadioGroupRoot>
                            {howYouKnowUsAlternatives.map((alternative) => (
                                <s.RadioWrapper>
                                    <s.RadioGroupItem
                                        id={alternative.id}
                                        value={alternative.id}
                                        checked={
                                            howYouKnowUs === alternative.id
                                        }
                                        onClick={() => {
                                            setHowYouKnowUs(alternative.id);
                                        }}
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    {alternative.id !== "other" && (
                                        <label htmlFor={alternative.id}>
                                            {alternative.label}
                                        </label>
                                    )}
                                    {alternative.id === "other" &&
                                        (howYouKnowUs === "other" ? (
                                            <Input
                                                mask=""
                                                disabled={
                                                    howYouKnowUs !==
                                                    alternative.id
                                                }
                                                value={other}
                                                placeholder="Escreva aqui"
                                                maxLength={50}
                                                onChange={(e) => {
                                                    setOther(e.target.value);
                                                }}
                                            />
                                        ) : (
                                            <label htmlFor={alternative.id}>
                                                {alternative.label}
                                            </label>
                                        ))}
                                </s.RadioWrapper>
                            ))}
                        </s.RadioGroupRoot>
                    </s.CheckBoxContainer>
                </s.HowYouKnowUs>
                <s.Buttons>
                    <PrimaryButton
                        disabled={howYouKnowUs === "other" && !other}
                        onClick={handleAnswer}
                    >
                        ENVIAR
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        PULAR
                    </SecondaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalHowYouKnowUs;
