import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={onClick}
        className={className || "icon-arrow-order"}
    >
        <title>{title}</title>
        <mask
            id="mask0_25603_1013"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x="-1"
            y="0"
            width="25"
            height="24"
        >
            <rect x="-0.000488281" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_25603_1013)">
            <path
                d="M11.2494 15.5462V6.75001C11.2494 6.53719 11.3212 6.35899 11.4648 6.21541C11.6084 6.07182 11.7866 6.00003 11.9994 6.00003C12.2122 6.00003 12.3904 6.07182 12.534 6.21541C12.6776 6.35899 12.7494 6.53719 12.7494 6.75001V15.5462L16.0725 12.2231C16.211 12.0846 16.385 12.0138 16.5946 12.0106C16.8042 12.0074 16.9814 12.0782 17.1263 12.2231C17.2712 12.368 17.3436 12.5436 17.3436 12.75C17.3436 12.9564 17.2712 13.132 17.1263 13.2769L12.6321 17.7711C12.4513 17.9519 12.2404 18.0423 11.9994 18.0423C11.7584 18.0423 11.5475 17.9519 11.3667 17.7711L6.87252 13.2769C6.73405 13.1385 6.66322 12.9644 6.66002 12.7548C6.6568 12.5452 6.72763 12.368 6.87252 12.2231C7.01738 12.0782 7.19302 12.0058 7.39942 12.0058C7.60582 12.0058 7.78145 12.0782 7.92632 12.2231L11.2494 15.5462Z"
                fill={color || "#261E38"}
            />
        </g>
    </svg>
);
