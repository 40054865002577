import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 500px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.07px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
        width: 100px;

        &:last-child {
            width: 200px;
        }
    }
`;
