import { TBaseSVG } from "../../models";

export default ({ width, height }: TBaseSVG) => (
    <svg
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth={0.1}
    >
        <g id="military_tech">
            <mask
                id="mask0_25147_383"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_25147_383)">
                <path
                    id="military_tech_2"
                    d="M12.0001 19.1116L10.4001 20.3289C10.2193 20.4661 10.0401 20.4702 9.86258 20.3414C9.68502 20.2125 9.63149 20.0411 9.70201 19.827L10.3308 17.8347L8.75586 16.7174C8.57509 16.5969 8.52157 16.4312 8.59528 16.2203C8.669 16.0094 8.81291 15.9039 9.02701 15.9039H10.9347L11.6539 13.5655L8.18083 11.5232C7.86802 11.3398 7.62507 11.0934 7.45201 10.7837C7.27892 10.4741 7.19238 10.1309 7.19238 9.75395V4.09625C7.19238 3.59755 7.36898 3.17159 7.72218 2.81837C8.07538 2.46517 8.50134 2.28857 9.00006 2.28857H15.0001C15.4988 2.28857 15.9247 2.46517 16.2779 2.81837C16.6311 3.17159 16.8077 3.59755 16.8077 4.09625V9.75395C16.8077 10.1244 16.718 10.4613 16.5385 10.7645C16.359 11.0677 16.1129 11.3142 15.8 11.5039L12.3462 13.5655L13.0654 15.9039H14.9731C15.1872 15.9039 15.3311 16.0094 15.4048 16.2203C15.4785 16.4312 15.425 16.5969 15.2443 16.7174L13.6693 17.8347L14.2981 19.827C14.3686 20.0411 14.3151 20.2125 14.1375 20.3414C13.96 20.4702 13.7808 20.4661 13.6 20.3289L12.0001 19.1116ZM8.69233 4.09625V9.75395C8.69233 9.8501 8.71637 9.93824 8.76446 10.0184C8.81254 10.0985 8.87825 10.1642 8.96158 10.2155L11.2501 11.5694V3.78855H9.00006C8.91031 3.78855 8.83658 3.8174 8.77888 3.8751C8.72118 3.93278 8.69233 4.0065 8.69233 4.09625ZM15.0001 3.78855H12.75V11.5694L15.0385 10.2155C15.1219 10.1642 15.1876 10.0985 15.2357 10.0184C15.2837 9.93824 15.3078 9.8501 15.3078 9.75395V4.09625C15.3078 4.0065 15.2789 3.93278 15.2212 3.8751C15.1635 3.8174 15.0898 3.78855 15.0001 3.78855Z"
                    fill="#6E697A"
                />
            </g>
        </g>
    </svg>
);
