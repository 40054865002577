import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 136px;
    margin-right: 24px;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid var(--color-gray-200, #cfcdd3);
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    width: 375px;
    height: 491px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
`;

export const Header = styled.header`
    padding: 19px 16px;
    font-size: 14px;
    font-weight: 600;
    background: var(--color-brand-pink-050, #fdf1fe);
    border-bottom: 1px solid var(--color-gray-200, #cfcdd3);
    color: var(--color-gray-800, #3e374e);
    margin-bottom: 28px;
`;

export const Information = styled.p`
    margin-top: 16px;
    margin-bottom: 16px;
    color: #6e697a;
    font-size: 14px;
`;

export const ReportReason = styled.p`
    font-size: 14px;
    color: #3e374e;
    white-space: pre-wrap;
    height: 200px;
    max-height: 200px;
    overflow: auto;
    line-height: 18px;
    width: 100%;
    padding-right: 0.125rem;
    word-wrap: break-word;
`;

export const TextContainer = styled.div`
    padding: 0px 24px;

    TextArea {
        max-height: 200px;
        overflow: auto;
        height: 200px !important;
        line-height: 18px;
    }
`;

export const Button = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 48px;
    padding-bottom: 16px;
`;
