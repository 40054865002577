import { Text, View, Image } from "@react-pdf/renderer";
import React from "react";

import { PurpleLogo } from "../../../../../assets";
import { TProject } from "../../../../../models";
import { SelectedDate } from "../../../../../pages/organization/project/tab-costs/tab-costs";
import { ReadProjectCostsResult } from "../../../../../services/projects/types";
import PDFTemplate from "../../pdf-template";
import * as s from "../../styled-pdf-template";
import { Header } from "./components/header";
import { FixedCosts } from "./tabs/fixed-costs";
import { Resume } from "./tabs/resume";
import { Team } from "./tabs/team";
import { VariableCosts } from "./tabs/variable-costs";

type ProjectCostsProps = {
    data: ReadProjectCostsResult | undefined;
    project: TProject;
    date: SelectedDate;
    tab: "Resumo" | "Custos fixos" | "Custos variáveis" | "Custos time";
};

export const ProjectCosts = ({
    data,
    project,
    date,
    tab,
}: ProjectCostsProps) => {
    if (!data) return null;

    const page: Record<typeof tab, React.ReactNode> = {
        Resumo: <Resume data={data} date={date} />,
        "Custos fixos": <FixedCosts data={data} date={date} />,
        "Custos variáveis": <VariableCosts data={data} date={date} />,
        "Custos time": <Team data={data} />,
    };

    return (
        <PDFTemplate>
            <View
                style={[s.PDFTableSummaryReport.Header, { marginBottom: 50 }]}
            >
                <Text style={s.PDF.Title}>Resumo dos custos do projeto</Text>
                <Image src={PurpleLogo} style={s.PDF.ImageStyle} fixed />
            </View>

            <Header project={project} />

            {page[tab]}
        </PDFTemplate>
    );
};
