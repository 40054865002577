import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Icons, theme } from "../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../components/atoms/menu/menu";
import { activities } from "../../../../../../../services";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import { DeleteConfirmationModal } from "../../modals/delete-confirmation-general/delete-confirmation-general";
import { ActivityDTO } from "../../types/activityDTO";
import { AddSubItem } from "./components/add-sub-item/add-sub-item";
import { ProgressBar } from "./components/sub-item/components/progress-bar/progress-bar";
import { SubItems } from "./components/sub-items/sub-items";
import * as s from "./styles";

type TChecklist = {
    activity: ActivityDTO;
};

export const Checklist = ({ activity }: TChecklist) => {
    const checklist = activity.ActivityChecklist?.[0];
    const [createSubItem, setCreateSubItem] = useState(false);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const { mutate: createChecklist } = useMutation({
        mutationFn: () =>
            activities.createChecklist({
                activityId: activity.id_e,
                title: "Checklist",
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const { mutate: deleteChecklist } = useMutation({
        mutationFn: () =>
            activities.deleteChecklist({
                checklistId: checklist.id_e,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const options: TMenuAtom["options"] = [
        {
            label: "Deletar",
            icon: <Icons.Trash color={theme.redError} />,
            action: () => {
                setOpenDeleteConfirmation(true);
            },
            color: theme.redError,
        },
    ];

    return (
        <>
            {openDeleteConfirmation && (
                <DeleteConfirmationModal
                    setOpen={setOpenDeleteConfirmation}
                    onConfirm={deleteChecklist}
                    message="o checklist"
                />
            )}
            <s.Container>
                {!checklist && (
                    <s.Button onClick={() => createChecklist()}>
                        <Icons.Plus color={theme.gray900} />
                        Adicionar checklist
                    </s.Button>
                )}
                {checklist && (
                    <s.Content>
                        <s.Header>
                            <s.Title>{checklist.title}</s.Title>
                            <MenuAtom options={options}>
                                <s.Button>
                                    <Icons.More />
                                </s.Button>
                            </MenuAtom>
                        </s.Header>
                        <ProgressBar checklist={checklist} />
                        <SubItems checklist={checklist} activity={activity} />
                        {createSubItem ? (
                            <AddSubItem
                                activity={activity}
                                setCreateSubItem={setCreateSubItem}
                                checklist={checklist}
                            />
                        ) : (
                            <s.Button onClick={() => setCreateSubItem(true)}>
                                <Icons.Plus color={theme.gray900} />
                                Adicionar item
                            </s.Button>
                        )}
                    </s.Content>
                )}
            </s.Container>
        </>
    );
};
