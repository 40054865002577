/* eslint-disable no-nested-ternary */
import { format, getWeek, parseISO, endOfMonth, startOfMonth } from "date-fns";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import Select from "react-select";

import { IlustracaoTelaVazia } from "../../assets";
import {
    BarChart,
    ButtonShowMore,
    DoughnutChart,
    LoadingGray,
    PopoverCalendar,
    MyReportsOptions,
    Tag,
} from "../../components";
import { useFetch } from "../../hooks";
import { TReportProject, TTasks } from "../../models";
import { track } from "../../services";
import { dayFromWeek, minutesToStringDate, monthFromYear } from "../../utils";
import { AccumulatedValue } from "./components/accumulated-value/accumulated-value";
import * as s from "./styled-report-summary";

export type TColumnData = {
    dates?: string[];
    times?: number[];
    periods?: string[];
    abreviatedDates?: string[];
};

export type TDoughnutData = {
    labels?: string[];
    times?: number[];
    colors?: string[];
    maxTime?: number;
};

type TProjectsOptions = {
    label: string;
    value: string;
};

export const ReportSummary = () => {
    const [totalTime, setTotalTime] = useState<string>();
    const [projects, setProjects] = useState<TReportProject>([]);
    const [period, setPeriod] = useState<DateRange>({
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
    });
    const [filter, setFilter] = useState<TProjectsOptions>({
        label: "Todos",
        value: "all",
    });

    const [barChartData, setBarChartData] = useState<TColumnData>();
    const [doughnutChartData, setDoughnutChartData] = useState<TDoughnutData>();

    const getEntries = useFetch({
        fn: track.getEntries,
        params: {
            startDate: startOfMonth(new Date()).toString(),
            endDate: endOfMonth(new Date()).toString(),
            responseFormat: "summaryReport",
            page: 0,
        },
    });

    const projectsOptions: TProjectsOptions[] = [
        { label: "Todos", value: "all" },
        ...(getEntries?.response?.user_projects_with_entries?.map(
            (project: { name: string; id_e: string }) => ({
                label: project.name,
                value: project.id_e,
            })
        ) ?? []),
    ];

    const formatChartsData = (entries: {
        response1: { date: string; total_time: number }[];
        response2: TReportProject;
    }) => {
        const barDates: string[] = [];
        const barTimes: number[] = [];
        const barPeriods: string[] = [];
        const barAbreviatedDates: string[] = [];
        const activitiesList: TReportProject = [];
        const doughnutLabels: string[] = [];
        const doughnutColors: string[] = [];
        const doughnutTimes: number[] = [];
        let doughnutMaxTime = 0;

        entries?.response1?.sort((a: { date: string }, b: { date: string }) => {
            return a.date.localeCompare(b.date);
        });

        entries?.response1?.forEach(
            (entry: { date: string; total_time: number }) => {
                if (entry.date.length > 10) {
                    const tempDate = entry.date.split(" - ");
                    barAbreviatedDates.push(
                        `${format(parseISO(tempDate[0]), "dd/MM")} - ${format(
                            parseISO(tempDate[1]),
                            "dd/MM"
                        )}`
                    );
                    const tempPeriod = getWeek(
                        parseISO(tempDate[0])
                    ).toString();
                    barPeriods.push(`Sem. ${tempPeriod}`);
                } else if (entry.date.length < 8) {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "yyyy")
                    );
                    barPeriods.push(monthFromYear(parseISO(entry.date), true));
                } else {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "dd/MM")
                    );
                    barPeriods.push(dayFromWeek(parseISO(entry.date), true));
                }
                barDates.push(entry.date);
                barTimes.push(entry.total_time);
            }
        );

        entries?.response2?.forEach(
            (activity: {
                project_name: string;
                project_client: string;
                project_color: string;
                project_id: string;
                project_percent: string;
                project_time: number;
                project_entries: TTasks;
            }) => {
                const tempActivity = { ...activity, open: false };
                activitiesList.push(tempActivity);
                doughnutLabels.push(activity.project_name);
                doughnutColors.push(activity.project_color);
                doughnutTimes.push(activity.project_time);
                doughnutMaxTime += activity.project_time;
            }
        );
        if (Object.keys(entries?.response2).length === 0) {
            setProjects([]);
        } else setProjects(entries?.response2);

        setBarChartData({
            dates: barDates,
            times: barTimes,
            periods: barPeriods,
            abreviatedDates: barAbreviatedDates,
        });
        setDoughnutChartData({
            labels: doughnutLabels,
            colors: doughnutColors,
            times: doughnutTimes,
            maxTime: doughnutMaxTime,
        });
    };

    useEffect(() => {
        if (getEntries.response) {
            const { entries, time_requested } = getEntries.response;

            const tempTimeRequested: number[] = Object.values(time_requested);
            let tempTotalTime = 0;

            tempTimeRequested.forEach((time) => {
                tempTotalTime += time;
            });

            setTotalTime(minutesToStringDate(tempTotalTime));
            formatChartsData(entries);
        }
    }, [getEntries.response]);

    const getPeriod = (period: DateRange | undefined) => {
        getEntries.onRefresh({
            startDate: period?.from?.toString(),
            endDate: period?.to?.toString(),
            responseFormat: "summaryReport",
            page: 0,
        });

        setPeriod({
            from: period?.from,
            to: period?.to,
        });
    };

    const getSummaryWithFilter = (project: TProjectsOptions) => {
        if (project.value === "all") {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                responseFormat: "summaryReport",
                page: 0,
            });
        } else {
            getEntries.onRefresh({
                startDate: period?.from?.toString(),
                endDate: period?.to?.toString(),
                responseFormat: "summaryReport",
                page: 0,
                projectId: project.value,
            });
        }
    };

    const openTasks = (index: number) => {
        const tempActivities = projects.map((oldActivity, i) =>
            i === index
                ? {
                      ...oldActivity,
                      open: !oldActivity.open,
                  }
                : oldActivity
        );

        setProjects(tempActivities);
    };

    const correctProjectEntries = (entries: TTasks) => {
        const uniqueEntries = [...entries].reduce((acc: TTasks, current) => {
            const existingEntry = acc.find(
                (item) => item.description === current.description
            );
            if (!existingEntry) {
                return acc.concat([current]);
            }
            return acc.map((item) => {
                if (item.description !== current.description) return item;
                return {
                    ...item,
                    entry_time: item.entry_time + current.entry_time,
                    entry_percent: `${(
                        parseFloat(item.entry_percent) +
                        parseFloat(current.entry_percent)
                    ).toFixed(1)}%`,
                };
            });
        }, [] as TTasks);
        return uniqueEntries;
    };

    return (
        <s.Container>
            <MyReportsOptions />

            <AccumulatedValue />

            <s.BarChartWrapper>
                <PopoverCalendar getPeriod={getPeriod} />

                {getEntries.loading ? (
                    <LoadingGray />
                ) : projects.length > 0 ? (
                    <>
                        {getEntries.loading ? (
                            <LoadingGray />
                        ) : (
                            <BarChart
                                dates={barChartData?.dates}
                                times={barChartData?.times}
                                periods={barChartData?.periods}
                                abreviatedDates={barChartData?.abreviatedDates}
                            />
                        )}
                    </>
                ) : (
                    <s.NoContent>
                        <IlustracaoTelaVazia />
                        <h2>Sem registro de dados a serem mostrados</h2>
                        <p>
                            Vá ao cronômetro e faça o registro das horas
                            trabalhadas!
                        </p>
                    </s.NoContent>
                )}
            </s.BarChartWrapper>

            {projects.length > 0 && (
                <div id="section-row">
                    <s.Activities>
                        {getEntries.loading ? (
                            <LoadingGray />
                        ) : (
                            <>
                                <header id="select-wrapper">
                                    <Select
                                        options={projectsOptions}
                                        placeholder="Projetos"
                                        styles={s.SelectStyles}
                                        value={filter}
                                        onChange={(value) => {
                                            if (value) {
                                                setFilter(value);
                                                getSummaryWithFilter(value);
                                            }
                                        }}
                                    />
                                </header>

                                <s.ActivitiesTable>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="heading-text">
                                                    ENTRADA
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    DURAÇÃO
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    PORCENTAGEM
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>

                                    {projects?.map((activity, index) => (
                                        <tbody key={index}>
                                            <tr className="activity">
                                                <td className="entry">
                                                    <ButtonShowMore
                                                        onClick={() => {
                                                            openTasks(index);
                                                        }}
                                                        isOpen={activity.open}
                                                        numberOfItems={
                                                            uniqBy(
                                                                activity.project_entries,
                                                                "description"
                                                            ).length
                                                        }
                                                    />

                                                    <Tag
                                                        color={
                                                            activity.project_color
                                                        }
                                                    >
                                                        {activity.project_name}
                                                    </Tag>
                                                </td>

                                                <td>
                                                    <span className="time">
                                                        {minutesToStringDate(
                                                            activity.project_time
                                                        )}
                                                    </span>
                                                </td>

                                                <td>
                                                    <span className="percentage">
                                                        {activity.project_percent.replace(
                                                            ".",
                                                            ","
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>

                                            {activity.open &&
                                                correctProjectEntries(
                                                    activity.project_entries
                                                ).map((task) => (
                                                    <tr
                                                        className="activity-instance"
                                                        key={task.id_e}
                                                    >
                                                        <td className="entry">
                                                            <span className="description">
                                                                {
                                                                    task.description
                                                                }
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span className="time">
                                                                {minutesToStringDate(
                                                                    task.entry_time
                                                                )}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <span className="percentage">
                                                                {task.entry_percent.replace(
                                                                    ".",
                                                                    ","
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    ))}
                                </s.ActivitiesTable>
                            </>
                        )}
                    </s.Activities>

                    <s.ActivitiesTime>
                        {getEntries.loading ? (
                            <LoadingGray />
                        ) : (
                            <>
                                <header id="work-time">
                                    <span id="text-time">TEMPO TOTAL</span>

                                    <span id="total-time">{totalTime}</span>
                                </header>
                                <div id="doughnut-wrapper">
                                    <DoughnutChart
                                        labels={doughnutChartData?.labels}
                                        colors={doughnutChartData?.colors}
                                        times={doughnutChartData?.times}
                                        maxTime={doughnutChartData?.maxTime}
                                    />
                                </div>
                            </>
                        )}
                    </s.ActivitiesTime>
                </div>
            )}
        </s.Container>
    );
};
