import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 16px;
    border-radius: 8px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
`;

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 16px;
`;
