import styled from "styled-components";

type TInput = {
    $error?: boolean;
    $icon?: boolean;
};

export const Content = styled.div`
    display: flex;
    width: 100%;
    gap: 0.75rem;
`;

export const PositionText = styled.div`
    display: flex;
    position: absolute;
    left: 0.625rem;

    .icon-senha-fechado {
        cursor: pointer;
    }
    .icon-senha-aberto {
        cursor: pointer;
        path {
            cursor: pointer;
        }
    }
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    width: 100%;
`;

export const FlexRow = styled.div<TInput>`
    display: flex;
    align-items: center;
    position: relative;

    input {
        background: ${({ theme }) => theme.white};

        &::-ms-reveal,
        ::-ms-clear {
            display: none;
        }

        border: 1px solid
            ${({ theme, $error: error }) => (error ? theme.red : theme.gray100)};
        border-radius: 0.25rem;
        height: 2.5rem;
        padding-left: 1rem;
        padding-right: 2rem;
        width: 100%;
        font-size: 0.875rem;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
    input[type="file"] {
        padding-top: 0.5rem;

        ::file-selector-button {
            display: none;
        }
    }

    input::placeholder {
        color: ${({ theme }) => theme.gray400};
    }

    input.react-input {
        visibility: unset;
        cursor: default;
        border: 1px solid #e7e6e9;
        background-color: hsl(0, 0%, 95%);
    }
`;

export const PositionIcons = styled.div`
    display: flex;
    position: absolute;
    right: 0.625rem;

    .icon-senha-fechado {
        cursor: pointer;
    }
    .icon-senha-aberto {
        cursor: pointer;
        path {
            cursor: pointer;
        }
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Length = styled.span`
    position: absolute;
    bottom: 0;
    right: 30px;
    color: ${({ theme }) => theme.gray200};
    font-size: 0.6rem;
`;
