import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { GetUserVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    sectors,
    projects,
    reportErrors,
    benefits,
}: GetUserVariables = {}) => {
    const params = {
        sectors,
        projects,
        reportErrors,
        benefits,
    };

    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
    };

    if (!userId) delete tempHeaders["x-user-id"];

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/profile`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Usuário não encontrado",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
