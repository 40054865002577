import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 21rem;
    padding-bottom: 2rem;
    position: absolute;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: calc(24px + 1.5rem);
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .actions {
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const Experience = styled.form`
    padding: 1.5rem 1.5rem 1.5rem 2.5rem;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 0px 0px 4px 4px;
    background-color: ${({ theme }) => theme.white2};
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        align-self: flex-start;
    }

    .icons-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: flex-start;
    }

    span.tooltip {
        align-self: flex-start;
        cursor: pointer;
    }

    div#fields {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        gap: 2rem;
        margin-right: 1.875rem;

        div#resume-experience {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            height: 100%;
            width: 100%;
            grid-row-gap: 0.625rem;
            grid-column-gap: 4rem;
        }

        div.contact-field {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 0.5rem;

            span.field-title {
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                color: ${({ theme }) => theme.gray400};
                gap: 0.5rem;
                line-height: 0.875rem;
                align-items: flex-start;
            }

            span.field-text {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.gray900};
                overflow-wrap: anywhere;
            }

            div.text-area-wrapper {
                width: 100%;
            }
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;
