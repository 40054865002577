import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import * as s from "./styled-reports-options";

const MyReportsOptions = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { verifyPermission } = useUser();
    const pathLocation = window.location.pathname;

    const manageOptions = pathLocation === "/relatorio/gerenciar/revisar";
    const billingOptions = pathLocation === "/relatorio/gerenciar/faturar";

    const billingPermission = verifyPermission({
        id: "bill_reports",
        rule: "enabled",
    });

    const reviewPermission = verifyPermission({
        id: "review_reports",
        rule: "enabled",
    });

    return (
        <s.Container>
            <s.Options>
                <div
                    className="option"
                    onClick={() => navigate("/meus-relatorios/resumo")}
                >
                    {!manageOptions && !billingOptions && (
                        <Icons.ChartColumns
                            color={
                                location.pathname === "/meus-relatorios/resumo"
                                    ? theme.purple600
                                    : theme.gray200
                            }
                            title="Resumo"
                        />
                    )}

                    <s.OptionText
                        active={location.pathname === "/meus-relatorios/resumo"}
                        manage={manageOptions || billingOptions}
                    >
                        RESUMO
                    </s.OptionText>
                </div>

                <div
                    className="option"
                    onClick={() => navigate("/meus-relatorios/detalhado")}
                >
                    {!manageOptions && !billingOptions && (
                        <Icons.DocumentDetailed
                            color={
                                location.pathname ===
                                "/meus-relatorios/detalhado"
                                    ? theme.purple600
                                    : theme.gray200
                            }
                            title="Detalhado"
                        />
                    )}

                    <s.OptionText
                        active={
                            location.pathname === "/meus-relatorios/detalhado"
                        }
                        manage={manageOptions || billingOptions}
                    >
                        DETALHADO
                    </s.OptionText>
                </div>

                <div
                    className="option"
                    onClick={() => navigate("/meus-relatorios/gerados")}
                >
                    {!manageOptions && !billingOptions && (
                        <Icons.DocumentList
                            color={
                                location.pathname === "/meus-relatorios/gerados"
                                    ? theme.purple600
                                    : theme.gray200
                            }
                            title="Histórico"
                        />
                    )}

                    <s.OptionText
                        active={
                            location.pathname === "/meus-relatorios/gerados"
                        }
                        manage={manageOptions || billingOptions}
                    >
                        GERADOS
                    </s.OptionText>
                </div>
            </s.Options>
        </s.Container>
    );
};

export default MyReportsOptions;
