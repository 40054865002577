import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import {
    Icons,
    theme,
    FotoUser as DefaultUserPicture,
} from "../../../../../../../../../assets";
import { Tooltip } from "../../../../../../../../../components";
import { activities } from "../../../../../../../../../services";
import { AddCollaboratorsModal } from "../../../../../modals/add-collaborators/add-collaborators-modal";
import { TSectionActivity } from "../../../../../types";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TUsers = {
    activity: ActivityDTO;
};

export const Users = ({ activity }: TUsers) => {
    const { state } = useLocation();

    const [addCollaboratorActivity, setAddCollaboratorActivity] =
        useState<TSectionActivity>();
    const [activityState, setActivityState] = useState(activity);

    const concatUsers = (users: TSectionActivity["Users"]) => {
        return users.map((user) => ({
            ...user.User,
        }));
    };

    const activityToAddCollaborators = {
        ...activity,
        Users: activityState.Users.map((user) => ({
            User: {
                ...user,
            },
        })),
    } as unknown as TSectionActivity;

    const mutation = useMutation({
        mutationFn: activities.updateActivity,
        onSuccess: ([data]) => {
            toast.success("Profissionais atualizados com sucesso!");
            setAddCollaboratorActivity(undefined);
            setActivityState({
                ...activityState,
                Users: concatUsers(data.Users),
            });
        },
    });

    return (
        <>
            {addCollaboratorActivity && (
                <AddCollaboratorsModal
                    activity={addCollaboratorActivity}
                    setActivity={setAddCollaboratorActivity}
                    project={state.project}
                    onSuccess={(data) => {
                        mutation.mutate({
                            activityIds: data.activityIds,
                            users: data.users,
                        });
                    }}
                />
            )}
            <s.Container
                onClick={() =>
                    setAddCollaboratorActivity(activityToAddCollaborators)
                }
            >
                <s.Users>
                    {activityState.Users.map(({ id_e, name, avatar }) => (
                        <Tooltip
                            key={id_e}
                            positiony={5}
                            content={
                                <s.TooltipContainer>{name}</s.TooltipContainer>
                            }
                        >
                            <s.Picture src={avatar ?? DefaultUserPicture} />
                        </Tooltip>
                    ))}
                    <Tooltip
                        positiony={5}
                        content={
                            <s.TooltipContainer>
                                Adicionar profissionais a atividade
                            </s.TooltipContainer>
                        }
                    >
                        <s.AddUserButton>
                            <Icons.UserAdd color={theme.gray800} />
                        </s.AddUserButton>
                    </Tooltip>
                </s.Users>
                {activityState.Users.length === 0 && (
                    <s.Info>Adicione profissionais para a atividade</s.Info>
                )}
            </s.Container>
        </>
    );
};
