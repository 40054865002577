import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-hand-outline"}
    >
        <title>{title}</title>
        <path
            d="M11 15.5V18M11 15.5V9.5C11 9.10218 11.158 8.72064 11.4393 8.43934C11.7206 8.15804 12.1022 8 12.5 8C12.8978 8 13.2794 8.15804 13.5607 8.43934C13.842 8.72064 14 9.10218 14 9.5M11 15.5C11 15.1022 10.842 14.7206 10.5607 14.4393C10.2794 14.158 9.89782 14 9.5 14C9.10218 14 8.72064 14.158 8.43934 14.4393C8.15804 14.7206 8 15.1022 8 15.5V17.5C8 19.4891 8.79018 21.3968 10.1967 22.8033C11.6032 24.2098 13.5109 25 15.5 25C17.4891 25 19.3968 24.2098 20.8033 22.8033C22.2098 21.3968 23 19.4891 23 17.5V12.5C23 12.1022 22.842 11.7206 22.5607 11.4393C22.2794 11.158 21.8978 11 21.5 11C21.1022 11 20.7206 11.158 20.4393 11.4393C20.158 11.7206 20 12.1022 20 12.5M14 9.5V15M14 9.5V8.5C14 8.10218 14.158 7.72064 14.4393 7.43934C14.7206 7.15804 15.1022 7 15.5 7C15.8978 7 16.2794 7.15804 16.5607 7.43934C16.842 7.72064 17 8.10218 17 8.5V9.5M20 12.5V9.5C20 9.10218 19.842 8.72064 19.5607 8.43934C19.2794 8.15804 18.8978 8 18.5 8C18.1022 8 17.7206 8.15804 17.4393 8.43934C17.158 8.72064 17 9.10218 17 9.5M20 12.5V15M17 9.5V15"
            stroke={color || theme.gray400}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
