import * as yup from "yup";

import { validationErrors } from "../../../../utils";

const { name, email } = validationErrors;

export default yup.object().shape({
    name: yup
        .string()
        .required(name.required)
        .min(3, name.minChars)
        .max(70, "Nome tem um limite de 70 caracteres."),
    email: yup.string().trim().email(email.invalid).required(email.required),
});
