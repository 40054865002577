import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Breadcrumb, Tag } from "../../../components";
import { ArchivedTag } from "../../../components/atoms/archived-tag/archived-tag";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { TProject } from "../../../models";
import { projects } from "../../../services";
import { Mixpanel } from "../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-project";
import { TabActivities } from "./tab-activities/tab-activities";
import { TabCollabs } from "./tab-collabs/tab-collabs";
import { TabCosts } from "./tab-costs/tab-costs";
import { TabDemonstrative } from "./tab-demonstrative/tab-demonstrative";
import { TabDescription } from "./tab-description/tab-description";
import { StatusReport } from "./tab-status-report/status-report";

export const Project = () => {
    const { verifyPermission } = useUser();
    const viewProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const manageProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const manageProjectsValuesPermission = verifyPermission({
        id: "projects_values",
        rule: "manage",
    });
    const haveViewProjectsValues = verifyPermission({
        id: "projects_values",
        rule: "view",
    });
    const haveViewProfessionalsValues = verifyPermission({
        id: "professionals_values",
        rule: "view",
    });

    const location = useLocation();
    const [project, setProject] = useState<TProject>(location.state.project);
    const [loading, setLoading] = useState<boolean>(true);

    const getProject = useFetch({
        fn: projects.getProject,
        start: false,
    });

    useEffect(() => {
        if (getProject.error) {
            toast.error(`${getProject.error}`);
            setLoading(getProject.loading);
        }
        if (getProject.response) {
            setProject(getProject.response.projects[0]);
            setLoading(getProject.loading);
        }
    }, [getProject.response, getProject.error, getProject.loading]);

    return (
        <>
            <s.Header>
                <Breadcrumb location={location} defaultRoute="/projetos" />
                <s.ProjectContainer>
                    {project.archived && <ArchivedTag />}
                    <span>Projeto:</span>
                    <Tag color={project.color}>{project.name}</Tag>
                </s.ProjectContainer>
            </s.Header>

            <s.Container>
                <Tabs.Root
                    className="profile-root"
                    defaultValue={"atividades"}
                    orientation="vertical"
                    onValueChange={(value) => {
                        Mixpanel.track("Aba acessada", {
                            page: "projeto",
                            tab: value,
                        });
                    }}
                >
                    <Tabs.List
                        aria-label="Gerencie Projetos"
                        className="profile-tabs"
                    >
                        <Tabs.Trigger value="atividades">
                            Atividades
                        </Tabs.Trigger>
                        {viewProjectsPermission && (
                            <>
                                <Tabs.Trigger value="descricao">
                                    Descrição
                                </Tabs.Trigger>
                                <Tabs.Trigger value="time">Time</Tabs.Trigger>
                                {haveViewProjectsValues &&
                                    haveViewProfessionalsValues && (
                                        <Tabs.Trigger value="demonstrativo">
                                            Demonstrativo
                                        </Tabs.Trigger>
                                    )}
                            </>
                        )}
                        {manageProjectsPermission &&
                            manageProjectsValuesPermission && (
                                <Tabs.Trigger value="custos">
                                    Custos
                                </Tabs.Trigger>
                            )}
                        {viewProjectsPermission && (
                            <Tabs.Trigger value="status-report">
                                Status Report
                            </Tabs.Trigger>
                        )}
                    </Tabs.List>
                    <Tabs.Content value="atividades">
                        <TabActivities loading={loading} project={project} />
                    </Tabs.Content>
                    <Tabs.Content value="descricao">
                        <TabDescription
                            project={project}
                            loading={loading}
                            setLoading={setLoading}
                            getProject={getProject}
                        />
                    </Tabs.Content>
                    <Tabs.Content value="time">
                        <TabCollabs
                            project={project}
                            loading={loading}
                            setLoading={setLoading}
                            getProject={getProject}
                        />
                    </Tabs.Content>
                    <Tabs.Content value="demonstrativo">
                        <TabDemonstrative project={project} />
                    </Tabs.Content>
                    <Tabs.Content value="custos">
                        <TabCosts project={project} />
                    </Tabs.Content>
                    <Tabs.Content value="status-report">
                        <StatusReport
                            project={project}
                            getProject={getProject}
                        />
                    </Tabs.Content>
                </Tabs.Root>
            </s.Container>
        </>
    );
};
