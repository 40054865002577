import { UseMutationResult } from "@tanstack/react-query";

import IlustracaoTelaVazia from "../../../../assets/images/ilustracao-tela-vazia";
import { TProject } from "../../../../context";
import { TEntries, TEntryTask } from "../../../../models";
import {
    UpdateEntryResponse,
    UpdateEntryError,
    UpdateEntryVariables,
} from "../../../../services/track/types";
import { validatePermittedStrings } from "../../../../utils/validation";
import * as s from "../../styled-track";
import { TrackService } from "../../track-service";
import CardContainer from "../card-container/card-container";

type Props = {
    timeEntries: TEntries | undefined;
    userProjects: TProject[];
    handleDeleteEntry: (id: string) => void;
    updateEntry: UseMutationResult<
        UpdateEntryResponse,
        UpdateEntryError,
        UpdateEntryVariables
    >;
    setTimeEntries: React.Dispatch<React.SetStateAction<TEntries | undefined>>;
    continueTask: (task: TEntryTask) => void;
};

export const TrackEntries = ({
    timeEntries,
    userProjects,
    updateEntry,
    handleDeleteEntry,
    setTimeEntries,
    continueTask,
}: Props) => {
    const handleUpdateEntry = ({
        entriesId,
        projectId,
        sectorId,
        description,
        startAt,
        endAt,
        manyUpdates,
        activityId,
    }: UpdateEntryVariables) => {
        const isValidDescription = validatePermittedStrings(
            description as string
        );
        if (!isValidDescription) {
            return;
        }
        updateEntry.mutate({
            entriesId,
            projectId,
            sectorId,
            startAt,
            endAt,
            manyUpdates,
            activityId,
        });
    };

    return (
        <>
            {timeEntries && timeEntries.total_entries ? (
                timeEntries.entries
                    .filter((item) => item.tasks.length > 0)
                    .map((entryByDay, index) => (
                        <CardContainer
                            key={`${entryByDay.date}-${index}-${entryByDay.tasks.length}`}
                            projectList={userProjects}
                            entries={entryByDay}
                            timeEntries={timeEntries}
                            allDuration={TrackService.sumAllDurations(
                                entryByDay
                            )}
                            handleDeleteEntry={handleDeleteEntry}
                            handleUpdateEntry={handleUpdateEntry}
                            setTimeEntries={setTimeEntries}
                            continueTask={continueTask}
                        />
                    ))
            ) : (
                <s.NoContent>
                    <IlustracaoTelaVazia />
                    <h2>Sem registro de dados a serem mostrados</h2>
                    <p>Faça o registro das horas trabalhadas no campo acima!</p>
                </s.NoContent>
            )}
        </>
    );
};
