import { Icons } from "../../../assets";
import { Level } from "../../../models";
import * as s from "./styles";

type LevelCircleProps = {
    level: Level;
    size?: "small" | "large";
};

export const LevelCircle = ({ level, size = "small" }: LevelCircleProps) => {
    return (
        <s.Container
            small={size === "small"}
            level={level}
            className="level-circle"
        >
            <Icons.Medal
                width={size ? "12px" : "32px"}
                height={size ? "12px" : "32px"}
            />
        </s.Container>
    );
};
