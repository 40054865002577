import * as yup from "yup";

export const schema = yup.object({
    skills: yup.array(
        yup
            .object({
                name: yup.object({
                    value: yup.string().required("Obrigatório"),
                    label: yup.string().required("Obrigatório"),
                }),
                years: yup
                    .number()
                    .required("Obrigatório")
                    .typeError("Obrigatório")
                    .max(36, "Deve ser menor ou igual a 36")
                    .integer("Deve ser inteiro")
                    .test("years test", "Deve ser maior que 0", function () {
                        const { parent } = this;
                        if (parent.months === 0) {
                            return parent.years > 0;
                        }
                        return true;
                    }),
                months: yup
                    .number()
                    .required("Obrigatório")
                    .typeError("Obrigatório")
                    .min(0, "Deve ser maior ou igual a zero")
                    .max(12, "Deve ser menor ou igual a 12")
                    .integer("Deve ser inteiro"),
            })
            .test(
                "skills test",
                "Deve conter no mínimo 3 habilidades",
                function () {
                    const { parent } = this;

                    return parent.length >= 3;
                }
            )
    ),
});
