import {
    AlertDialogContent,
    AlertDialogOverlay,
} from "@radix-ui/react-alert-dialog";
import styled, { keyframes } from "styled-components";

const overlayShow = keyframes`
    from{ opacity: 0 };
    to { opacity: .5 };
`;

const contentShow = keyframes`
    from { opacity: 0; transform: translate(-50%, -48%) scale(.96) };
    to { opacity: 1; transform: translate(-50%, -50%) scale(1) };
    `;

export const Content = styled(AlertDialogContent)`
    z-index: 999999999999999999999;
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    max-height: 85vh;
    padding: 25px;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
    :focus {
        outline: "none";
    }
`;

export const Overlay = styled(AlertDialogOverlay)`
    background-color: ${({ theme }) => theme.gray50};
    opacity: 0.7;
    position: fixed;
    inset: 0;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
`;

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;

    button.alert-cancel,
    button.alert-proceed {
        padding: 0 1rem;
    }
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.purple600};
`;

export const Description = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.gray600};
`;
