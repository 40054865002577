import styled from "styled-components";

type TTextArea = {
    error?: boolean;
};

export const Flex = styled.div<TTextArea>`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .text-area {
        resize: none;
        padding: 1rem;

        background: ${({ theme }) => theme.white};
        &::-ms-reveal,
        ::-ms-clear {
            display: none;
        }
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
        border-radius: 0.25rem;
        min-height: 6rem;
        width: 100%;
        font-size: 0.875rem;
        overflow: auto;
    }

    .error {
        border: 1px solid ${({ theme }) => theme.red};
    }
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const CharCount = styled.p`
    text-align: right;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.gray600};
`;
