import styled from "styled-components";

import { PrimaryButton, SecondaryButton } from "../../../components";

export const Container = styled.div`
    width: 100%;
    padding: 32px;

    .tutorials-popover {
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .profile-root {
        width: 100%;
        z-index: 0;
        overflow-x: clip;
    }

    .profile-tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 2.6875rem;
        background-color: ${({ theme }) => theme.gray50};
        white-space: nowrap;

        button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${({ theme }) => theme.gray400};
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px 4px 0px 0px;
        }

        [data-state="active"] {
            background-color: ${({ theme }) => theme.white};
            color: ${({ theme }) => theme.gray800};
            border-bottom: 0px;
        }
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
    gap: 20px;
`;

export const TooltipContainer = styled.div`
    padding: 4px 12px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    width: 300px;
`;

export const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    gap: 20px;
    max-width: 800px;
    min-width: 500px;
    cursor: pointer;

    .show-archived {
        display: flex;
        align-items: center;
        gap: 10px;
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        min-width: 240px;
    }

    .info-icon {
        display: flex;
        align-items: center;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const CreateProjectButton = styled(PrimaryButton)`
    min-width: 200px;
`;

export const ImportProjectsButton = styled(SecondaryButton)`
    min-width: 200px;
`;
