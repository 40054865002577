/* eslint-disable no-nested-ternary */
import styled from "styled-components";

type TPopoverBarDot = {
    percentage: number;
    active?: boolean;
    error?: boolean;
    color?: string;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 164px;
    height: 42px;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    background: ${({ theme }) => theme.white};
    padding: 0 8px;
    justify-content: center;
    gap: 4px;

    .status {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.gray600};
        gap: 4px;
        align-items: center;
    }
`;

export const TooltipContainer = styled.div`
    padding: 5px 12px;

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }
`;

export const PopoverContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 296px;
    padding: 16px 8px;
    background: white;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    margin-bottom: 8px;
    border-radius: 4px;
    gap: 16px;

    span.popover-title {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 600;
        margin-left: 8px;
    }

    div#bar-texts {
        display: flex;
        justify-content: space-between;
    }
`;

export const ProgressText = styled.span`
    display: flex;
    font-size: 0.5rem;
    width: min-content;
    text-align: center;
    align-items: flex-end;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.purple500};
    font-weight: 500;
`;

export const ProgressBar = styled.div<{ percentage: number }>`
    width: 100%;
    background: ${({ theme }) => theme.purple50};
    border-radius: 4px;
    position: relative;
    .actual-value-dot {
        position: absolute;
        left: ${({ percentage }) => (percentage > 93 ? 93 : percentage)}%;
        bottom: -3px;
    }
`;

export const Bar = styled.div<TPopoverBarDot>`
    width: ${({ percentage }) => {
        if (percentage > 100) {
            return "100%";
        }
        return `${percentage}%`;
    }};
    height: 4px;
    background: ${({ theme, color }) => color || theme.purple400};
    border-radius: 4px;
`;

export const PopoverProgressBar = styled.div`
    width: 248px;
    margin: 0 16px;
    background: ${({ theme }) => theme.purple50};
    position: relative;
`;

export const PopoverBar = styled.div<TPopoverBarDot>`
    width: ${({ percentage }) => percentage};
    height: 1px;
    background: ${({ theme }) => theme.purple400};
`;

export const PopoverBarDot = styled.div<TPopoverBarDot>`
    background-color: ${({ active, theme, error }) =>
        error ? theme.redError : active ? theme.purple400 : theme.purple50};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    position: absolute;
    top: -2.5px;
    left: ${({ percentage }) => percentage};
`;

export const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    padding: 12px;
    gap: 4px;

    span#refused {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 0.75rem;
        font-weight: 500;
        color: ${({ theme }) => theme.redError};

        &::before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.redError};
        }
    }

    span.error-text {
        font-size: 0.625rem;
        color: ${({ theme }) => theme.gray600};
    }

    span.margin {
        margin-top: 12px;
    }
`;
