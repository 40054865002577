import { yupResolver } from "@hookform/resolvers/yup";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { FotoUser, Icons, theme } from "../../../../assets";
import {
    Input,
    LoadingGray,
    PopoverHourControl,
    PrimaryButton,
    Scroll,
    Tooltip,
} from "../../../../components";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TProject, THourControl, TListHourControl } from "../../../../models";
import { hourControl, projects } from "../../../../services";
import { numberHoursToString } from "../../../../utils";
import {
    projectPeopleSize,
    renderListWithComma,
} from "../../utils/projects-utils";
import { DefineLockRulesModal } from "./components/define-lock-rules-modal/define-lock-rules-modal";
import * as s from "./styled-tab-collabs";
import schema from "./validation";

type TCollabsHourControl = {
    project?: TProject;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
};

export type TLockProjectHoursProps = {
    hourControlId: string | undefined;
};

type ProjectUser = {
    id_e: string;
    avatar: string;
    name: string;
    hourValue: string;
    Sectors: {
        id_e: string;
        name: string;
    }[];
    role: string;
    active: boolean;
};

type TFields = {
    userId?: string;
    projectId?: string;
    hourControlId?: string;
    workload?: string;
    additionalHours?: string;
};

export const CollabsHourControl = ({
    project,
    setEdit,
    setLoading,
    loading,
}: TCollabsHourControl) => {
    const { verifyPermission } = useUser();
    const [hourControlData, setHourControlData] = useState<TListHourControl[]>(
        []
    );
    const [searchValue, setSearchValue] = useState("");
    const [filteredUsers, setFilteredUsers] = useState<TListHourControl[]>([]);
    const [defineLockRulesModalOpen, setDefineLockRulesModalOpen] =
        useState(false);
    const [defineLockRulesModalData, setDefineLockRulesModalData] = useState({
        hourControlId: "",
        name: "",
        locked: false,
    });

    const defaultValues = {
        userId: "",
        projectId: project?.id_e,
        hourControlId: "",
        workload: "",
        additionalHours: "",
    };

    const canManageProjects = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });

    const { control, getValues, setValue, handleSubmit } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const getMyProjectInHourControl = (data: THourControl[]): THourControl => {
        const hourControlInThisProject = data?.filter((hc) => {
            return hc.Project.id_e === project?.id_e;
        });
        return hourControlInThisProject?.[0];
    };

    const getProject = useFetch({
        fn: projects.getProject,
        errorMessage: "Erro ao pegar usuários da organização",
        params: {
            users: true,
            projectId: project?.id_e,
            showOnlyActiveUsers: true,
        },
        start: true,
    });

    const getHourControl = useFetch({
        fn: hourControl.getHourControl,
        errorMessage: "Erro ao pegar usuários",
        params: { projectId: project?.id_e },
        start: true,
    });

    const toggleLockProject = useFetch({
        fn: hourControl.toggleLockProject,
        start: false,
    });

    const updateHourControl = useFetch({
        fn: hourControl.updateProjectHourControl,
        start: false,
    });

    const lockProject = ({ hourControlId }: TLockProjectHoursProps) => {
        toggleLockProject.onRefresh({
            hourControlId,
        });
    };

    const transformProjectUsersInHourControl = (
        projectUsers: ProjectUser[],
        prev: TListHourControl[]
    ): TListHourControl[] => {
        const usersWithoutHourControl = projectUsers.filter((user) => {
            return !prev.some((prevUser) => {
                return prevUser.id_e === user.id_e;
            });
        });
        const hourControlUsers: TListHourControl[] =
            usersWithoutHourControl.map((user) => {
                return {
                    id_e: user.id_e,
                    name: user.name,
                    Role: {
                        name: user.role,
                    },
                    workload: 0,
                    HourControl: [],
                    hoursLock: false,
                    active: user.active,
                };
            });
        return hourControlUsers;
    };

    useEffect(() => {
        if (getHourControl.response) {
            setHourControlData(orderBy(getHourControl.response, "name", "asc"));
        }
        setLoading(false);
    }, [getHourControl.response]);

    useEffect(() => {
        if (getProject?.response && getProject?.response?.projects?.[0]) {
            const projectUsers: ProjectUser[] =
                getProject.response.projects[0].Users;
            setHourControlData((prev) => {
                const hourControlUsers = transformProjectUsersInHourControl(
                    projectUsers,
                    prev
                );
                return [...prev, ...hourControlUsers];
            });
        }
    }, [getHourControl.response, getProject.response]);

    useEffect(() => {
        if (!project) return () => ({});
        const searchFilter = new RegExp(searchValue || "", "i");

        setLoading(true);

        const delay = setTimeout(() => {
            const hourControlUsers = transformProjectUsersInHourControl(
                project.Users as any as ProjectUser[],
                hourControlData
            );
            const searched = [...hourControlUsers, ...hourControlData]?.filter(
                (user: TListHourControl) => user?.name.match(searchFilter)
            );
            setFilteredUsers(searched);
            setLoading(false);
        }, 200);

        return () => clearTimeout(delay);
    }, [searchValue, hourControlData]);

    useEffect(() => {
        if (toggleLockProject.response) {
            const message = toggleLockProject?.response?.locked
                ? "travadas"
                : "destravadas";
            toast.success(`Horas ${message} com sucesso`);
            setFilteredUsers((prev) => {
                return prev.map((user) => {
                    const userCorrespondentHourControl =
                        user?.HourControl?.find((hourControl) => {
                            return (
                                hourControl?.id_e ===
                                toggleLockProject?.response?.id_e
                            );
                        });
                    if (userCorrespondentHourControl) {
                        return {
                            ...user,
                            HourControl: user?.HourControl?.map(
                                (hourControl) => {
                                    if (
                                        hourControl?.id_e ===
                                        toggleLockProject?.response?.id_e
                                    ) {
                                        return {
                                            ...hourControl,
                                            locked: toggleLockProject?.response
                                                ?.locked,
                                        };
                                    }
                                    return hourControl;
                                }
                            ),
                        };
                    }
                    return user;
                });
            });
        }
    }, [toggleLockProject.response]);

    useEffect(() => {
        setLoading(true);
        if (updateHourControl.error) {
            getHourControl.onRefresh({
                projectId: project?.id_e,
            });
        }
        if (updateHourControl.response) {
            toast.success("Horas atualizadas com sucesso!");
            getHourControl.onRefresh({
                projectId: project?.id_e,
            });
        }
        setLoading(false);
    }, [updateHourControl.response, updateHourControl.error]);

    const onSubmit = (data?: TFields) => {
        if (data?.workload !== undefined) {
            updateHourControl.onRefresh({
                userId: data?.userId,
                projectId: project?.id_e,
                hourControlId: data?.hourControlId,
                workload: data?.workload,
            });
        }

        if (data?.additionalHours !== undefined) {
            updateHourControl.onRefresh({
                userId: data?.userId,
                projectId: project?.id_e,
                hourControlId: data?.hourControlId,
                additionalHours: data?.additionalHours,
            });
        }
    };

    const handleSetHourControl = (userId: string, hourControlId: string) => {
        setValue("userId", userId);
        setValue("hourControlId", hourControlId);
        onSubmit(getValues());
    };

    const handleWorkloadChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValue("additionalHours", undefined);
        setValue("workload", event.target.value);
        return event.target.value;
    };

    const handleAdditionalChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setValue("workload", undefined);
        setValue("additionalHours", event.target.value);
        return event.target.value;
    };

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        userId: string,
        hourControlId: string
    ) => {
        if (event.key === "Enter") {
            event.preventDefault();
            setValue("userId", userId);
            setValue("hourControlId", hourControlId);
            onSubmit(getValues());
        }
    };

    const verifyToggleLock = (
        userHourControl: THourControl,
        user: TListHourControl
    ) => {
        if (!userHourControl) {
            return "";
        }
        const icon = userHourControl?.locked ? (
            <Tooltip
                side="top"
                align={"start"}
                positiony={10}
                content={
                    <s.LockTooltip>
                        <p>
                            <Icons.iconLockClosedFill color={theme.gray600} />
                            Obrigatório
                        </p>
                        O profissional deve trabalhar o número de horas
                        previstas, sem ultrapassá-las. <br /> <br />
                        Clique no cadeado para trocar para Flexível.
                    </s.LockTooltip>
                }
            >
                <span>
                    <Icons.iconLockClosedFill color={theme.gray300} />
                </span>
            </Tooltip>
        ) : (
            <Tooltip
                side="top"
                align={"start"}
                positiony={10}
                content={
                    <s.LockTooltip>
                        <p>
                            <Icons.IconLockOpen color={theme.gray600} />
                            Flexível
                        </p>
                        O profissional pode trabalhar menos ou mais horas do que
                        o previsto, combinando com o gestor. <br />
                        <br />{" "}
                        {canManageProjects &&
                            "Clique no cadeado para trocar para Obrigatório."}
                    </s.LockTooltip>
                }
            >
                <span>
                    <Icons.IconLockOpen color={theme.gray300} />
                </span>
            </Tooltip>
        );
        return (
            <button
                type="button"
                onClick={(event) => {
                    event.stopPropagation();
                    setDefineLockRulesModalData({
                        hourControlId: userHourControl?.id_e,
                        name: user.name,
                        locked: userHourControl.locked,
                    });
                    setDefineLockRulesModalOpen(true);
                }}
                disabled={!canManageProjects}
            >
                {icon}
            </button>
        );
    };

    const renderListBody = (listHoursControls: TListHourControl[]) => {
        return (
            <s.ListBody
                templateColumns={"2fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr"}
            >
                {loading ? (
                    <LoadingGray />
                ) : (
                    <Scroll>
                        {filteredUsers && filteredUsers.length > 0
                            ? listHoursControls?.map((user, index: number) => {
                                  const userHourControl =
                                      getMyProjectInHourControl(
                                          user.HourControl
                                      );
                                  return (
                                      <div
                                          className={
                                              user.active === false
                                                  ? "content-row content-row--disabled"
                                                  : "content-row"
                                          }
                                          key={index}
                                      >
                                          <div className="left-align">
                                              <div className="collaborator">
                                                  <s.ProfilePicture
                                                      src={
                                                          user.avatar ||
                                                          FotoUser
                                                      }
                                                  />
                                                  {user.active === false &&
                                                      "[Inativo] "}
                                                  {user?.name || "-"}
                                              </div>
                                          </div>
                                          <div className="left-align">
                                              {user?.Role?.name || "-"}
                                          </div>
                                          <div>
                                              <Controller
                                                  name="workload"
                                                  control={control}
                                                  key={`${userHourControl?.id_e}`}
                                                  render={() => (
                                                      <span
                                                          style={{
                                                              width: "fit-content",
                                                          }}
                                                      >
                                                          <s.InputParagraph
                                                              mask={""}
                                                              defaultValue={userHourControl?.workLoad?.toString()}
                                                              maskChar={null}
                                                              onChange={
                                                                  handleWorkloadChange
                                                              }
                                                              onBlur={(
                                                                  event
                                                              ) => {
                                                                  setValue(
                                                                      "workload",
                                                                      event
                                                                          .target
                                                                          .value
                                                                  );
                                                                  handleSetHourControl(
                                                                      user.id_e,
                                                                      userHourControl?.id_e
                                                                  );
                                                              }}
                                                              onKeyDown={(e) =>
                                                                  handleKeyDown(
                                                                      e,
                                                                      user.id_e,
                                                                      userHourControl?.id_e
                                                                  )
                                                              }
                                                              onKeyPress={(
                                                                  event
                                                              ) => {
                                                                  if (
                                                                      !/[0-9]/.test(
                                                                          event.key
                                                                      )
                                                                  ) {
                                                                      event.preventDefault();
                                                                  }
                                                              }}
                                                              maxLength={3}
                                                              disabled={
                                                                  !user
                                                                      ?.HourControl
                                                                      ?.length ||
                                                                  !canManageProjects
                                                              }
                                                              style={{
                                                                  cursor:
                                                                      !user
                                                                          ?.HourControl
                                                                          ?.length ||
                                                                      !canManageProjects
                                                                          ? "not-allowed"
                                                                          : "auto",
                                                              }}
                                                          />
                                                      </span>
                                                  )}
                                              />
                                          </div>
                                          <div>
                                              <Controller
                                                  name="workload"
                                                  control={control}
                                                  render={() => (
                                                      <span
                                                          style={{
                                                              width: "fit-content",
                                                          }}
                                                      >
                                                          <s.InputParagraph
                                                              key={`${userHourControl?.id_e}`}
                                                              mask={""}
                                                              maskChar={null}
                                                              defaultValue={
                                                                  userHourControl?.additionalHours
                                                              }
                                                              disabled={
                                                                  !user
                                                                      ?.HourControl
                                                                      ?.length ||
                                                                  !canManageProjects
                                                              }
                                                              onChange={
                                                                  handleAdditionalChange
                                                              }
                                                              onBlur={() => {
                                                                  handleSetHourControl(
                                                                      user.id_e,
                                                                      userHourControl?.id_e
                                                                  );
                                                              }}
                                                              onKeyDown={(e) =>
                                                                  handleKeyDown(
                                                                      e,
                                                                      user.id_e,
                                                                      userHourControl?.id_e
                                                                  )
                                                              }
                                                              onKeyPress={(
                                                                  event
                                                              ) => {
                                                                  if (
                                                                      !/[0-9]/.test(
                                                                          event.key
                                                                      )
                                                                  ) {
                                                                      event.preventDefault();
                                                                  }
                                                              }}
                                                              maxLength={3}
                                                              style={{
                                                                  cursor:
                                                                      !user
                                                                          ?.HourControl
                                                                          ?.length ||
                                                                      !canManageProjects
                                                                          ? "not-allowed"
                                                                          : "auto",
                                                              }}
                                                          />
                                                      </span>
                                                  )}
                                              />
                                          </div>
                                          <div>
                                              {userHourControl
                                                  ? numberHoursToString(
                                                        userHourControl
                                                            ?.statistics
                                                            ?.hoursDone
                                                    )
                                                  : "-"}
                                          </div>
                                          <div>
                                              {userHourControl
                                                  ? numberHoursToString(
                                                        userHourControl
                                                            ?.statistics
                                                            ?.pendingHours
                                                    )
                                                  : "-"}
                                          </div>
                                          <div>
                                              {verifyToggleLock(
                                                  userHourControl,
                                                  user
                                              )}
                                          </div>
                                          <div>
                                              {user?.HourControl ? (
                                                  <PopoverHourControl
                                                      hourControl={
                                                          user?.HourControl
                                                      }
                                                      trigger={
                                                          <Tooltip
                                                              side="top"
                                                              align={"start"}
                                                              positiony={10}
                                                              content={
                                                                  <s.LockTooltip>
                                                                      <p>
                                                                          <Icons.Tag
                                                                              color={
                                                                                  theme.gray600
                                                                              }
                                                                          />
                                                                          Todos
                                                                          os
                                                                          projetos
                                                                      </p>
                                                                      Clique
                                                                      para ver
                                                                      as horas
                                                                      previstas
                                                                      e
                                                                      realizadas
                                                                      de todos
                                                                      os
                                                                      projetos
                                                                      que este
                                                                      profissional
                                                                      participa.
                                                                  </s.LockTooltip>
                                                              }
                                                          >
                                                              <span>
                                                                  <Icons.Tag />
                                                              </span>
                                                          </Tooltip>
                                                      }
                                                  />
                                              ) : (
                                                  <Icons.Tag />
                                              )}
                                          </div>
                                      </div>
                                  );
                              })
                            : ""}
                    </Scroll>
                )}
            </s.ListBody>
        );
    };

    useEffect(() => {
        if (defineLockRulesModalOpen === false) {
            setDefineLockRulesModalData({
                hourControlId: "",
                name: "",
                locked: false,
            });
        }
    }, [setDefineLockRulesModalOpen]);

    return (
        <s.Form onSubmit={handleSubmit(onSubmit)}>
            <s.Header>
                {defineLockRulesModalOpen && (
                    <DefineLockRulesModal
                        setOpen={setDefineLockRulesModalOpen}
                        defineLockRulesModalData={defineLockRulesModalData}
                        lockProject={lockProject}
                    />
                )}
                <s.FieldsWrapper>
                    <div className="field-row">
                        <s.FieldTitle>Setores:</s.FieldTitle>
                        <s.FieldText>
                            {renderListWithComma(project?.Sectors)}
                        </s.FieldText>
                    </div>

                    <div className="field-row">
                        <s.FieldTitle>Time:</s.FieldTitle>
                        <s.FieldText>
                            {projectPeopleSize(hourControlData)}
                        </s.FieldText>
                    </div>
                    <div className="field-row">
                        <s.FieldTitle>Revisor:</s.FieldTitle>
                        <s.FieldText>
                            {renderListWithComma(project?.ProjectReviewers)}
                        </s.FieldText>
                    </div>
                    <div className="field-row">
                        <s.FieldTitle>Faturador:</s.FieldTitle>
                        <s.FieldText>
                            {renderListWithComma(project?.ProjectBiller)}
                        </s.FieldText>
                    </div>
                </s.FieldsWrapper>
                <s.ActionWrapper>
                    {canManageProjects && (
                        <div className="action-buttons">
                            <PrimaryButton
                                variation="small"
                                onClick={() => setEdit(true)}
                                disabled={loading}
                            >
                                Editar time
                            </PrimaryButton>
                        </div>
                    )}
                    <Input
                        mask=""
                        type="text"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        icon={<Icons.SearchOutline />}
                        placeholder="Pesquise por profissional"
                        disabled={false}
                    />
                </s.ActionWrapper>
            </s.Header>
            <s.CollaboratorsList>
                <s.ListHeader
                    templateColumns={
                        "2fr 1fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr"
                    }
                >
                    <div className="left-align">
                        Profissional ( {filteredUsers?.length || "0"} )
                    </div>
                    <div className="left-align">Atuação</div>
                    <div>
                        Horas
                        <br />
                        previstas
                    </div>
                    <div>
                        Horas
                        <br />
                        adicionais
                    </div>
                    <div>
                        Horas
                        <br />
                        realizadas
                    </div>
                    <div>
                        Horas
                        <br />
                        disponíveis
                    </div>
                    <div>
                        Bloqueio
                        <br />
                        de horas
                    </div>
                    <div>Projetos</div>
                </s.ListHeader>
                {renderListBody(filteredUsers)}
            </s.CollaboratorsList>
        </s.Form>
    );
};
