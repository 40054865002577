import { TUpdateClient } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Errors =
    | "Already exists a client with this name"
    | "The name field must be between 2 and 100 characters."
    | "client already exists!";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    active,
    clientId,
    projectsId,
}: TUpdateClient = {}) => {
    const params = {
        name,
        active,
    };

    const tempHeaders = {
        auth: true,
        "x-client-id": clientId,
        "x-projects-id": projectsId,
    };

    if (!clientId) delete tempHeaders["x-client-id"];
    if (!projectsId) delete tempHeaders["x-projects-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/clients/update`,
        method: "put",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const error: Errors = body?.message?.name?.[0] || body?.message;

    const errorsMapper = {
        "Already exists a client with this name":
            "Já existe um cliente com esse nome",
        "The name field must be between 2 and 100 characters.":
            "O nome deve ter entre 2 e 100 caracteres",
        "client already exists!": "Já existe um cliente com esse nome!",
        "Client does not exists!": "Cliente não existe",
    };

    const GENERIC_ERROR_MESSAGE = "Erro ao atualizar cliente";
    const errorFeedBacks = {
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        404: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        409: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
