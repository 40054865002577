import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";

import { theme, Icons, Check } from "../../../../assets";
import { DEFAULT_TRIAL_DAYS } from "../../../../utils/constants/free-days";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import { PrimaryButton } from "../../../atoms";
import Tooltip from "../../../atoms/tooltip/tooltip";
import ContainerModalScroll from "../container-modal-scroll";
import ModalBusinessContact from "../modal-business-contact/modal-business-contact";
import * as s from "./styled-modal-plan-cards";

type TModalPlanCards = {
    freeTrialExpiresDate?: string;
    numberOfUsers: number;
    isOnEditMode?: boolean;
    changingPlan?: boolean;

    setOpen: Dispatch<SetStateAction<boolean>>;
    currentPlan?: string;
    currentPlayType?: string;
    planExpired?: boolean;
    trialDays?: number;
};

type TButton = {
    type: "Lite" | "Starter" | "Standard";
};

const ModalPlanCards = ({
    freeTrialExpiresDate,
    setOpen,
    numberOfUsers,
    isOnEditMode,
    currentPlan,
    changingPlan,
    currentPlayType,
    planExpired,
    trialDays = DEFAULT_TRIAL_DAYS,
}: TModalPlanCards) => {
    const navigate = useNavigate();
    const [isAnnual, setIsAnnual] = useState(false);
    const [isModalBusinessActive, setIsModalBusinessActive] = useState(false);

    const isOnTrialTime =
        freeTrialExpiresDate && freeTrialExpiresDate !== "Nenhum dia";

    const handleKeyDown = (event: KeyboardEvent) => {
        if (freeTrialExpiresDate && freeTrialExpiresDate !== "Nenhum dia")
            return;
        if (event.key === "Escape") {
            event.preventDefault();
        }
    };

    document.addEventListener("keydown", handleKeyDown);

    const renderButton = ({ type }: TButton) => {
        if (isOnTrialTime && !isOnEditMode) {
            return (
                <>
                    <PrimaryButton disabled>
                        DISPONÍVEL EM {freeTrialExpiresDate.toLocaleUpperCase()}
                    </PrimaryButton>
                    <p className="inTrial">
                        O plano estará disponível após o período de teste
                        grátis.
                    </p>
                </>
            );
        }
        if (numberOfUsers > 3 && type === "Lite") {
            return (
                <>
                    <PrimaryButton disabled>INDISPONÍVEL</PrimaryButton>
                    <p className="extrapolatedUsers">
                        Limite de usuários excedido. Em caso de dúvidas, entre
                        em contato com <span>Suporte</span>.
                    </p>
                </>
            );
        }
        if (numberOfUsers > 10 && type === "Starter") {
            return (
                <>
                    <PrimaryButton disabled>INDISPONÍVEL</PrimaryButton>{" "}
                    <p className="extrapolatedUsers">
                        Limite de usuários excedido. Em caso de dúvidas, entre
                        em contato com <span>Suporte</span>.
                    </p>
                </>
            );
        }
        if (numberOfUsers > 20 && type === "Standard") {
            return (
                <>
                    <PrimaryButton disabled>INDISPONÍVEL</PrimaryButton>{" "}
                    <p className="extrapolatedUsers">
                        Limite de usuários excedido. Em caso de dúvidas, entre
                        em contato com <span>Suporte</span>.
                    </p>
                </>
            );
        }
        const isCurrentPlan = currentPlan === type.toLowerCase();
        const selectedPeriod = isAnnual ? "yearly" : "monthly";
        const isCurrentPlanType = currentPlayType === selectedPeriod;
        if (isCurrentPlan && isCurrentPlanType) {
            return (
                <>
                    <PrimaryButton disabled>SEU PLANO ATUAL</PrimaryButton>{" "}
                    <p className="your-plan">
                        Este é o seu plano atual. Para alterar, escolha outra
                        opção de plano.
                    </p>
                </>
            );
        }

        return (
            <PrimaryButton
                onClick={() => {
                    setOpen(false);
                    const path = changingPlan
                        ? "/gerenciar-assinatura/assinatura-do-plano"
                        : "/assinaturas/plano";
                    if (!changingPlan) {
                        Mixpanel.track("Clicou para escolher o plano", {
                            planType: type,
                            paymentType: isAnnual ? "yearly" : "monthly",
                            numberOfUsers,
                        });
                    }
                    navigate(path, {
                        state: {
                            planType: type,
                            paymentType: isAnnual ? "yearly" : "monthly",
                            numberOfUsers,
                            changingPlan,
                            currentPlan,
                        },
                    });
                }}
            >
                COMPRE AGORA
            </PrimaryButton>
        );
    };

    return (
        <>
            {isModalBusinessActive ? (
                <ModalBusinessContact
                    setOpen={setIsModalBusinessActive}
                    type="insideSystem"
                />
            ) : (
                <ContainerModalScroll
                    backgroundColor={theme.gray50}
                    onOpenChange={setOpen}
                    closeOnIconOnly
                    closeIcon={isOnTrialTime || isOnEditMode || false}
                    closeIconColor={theme.gray600}
                    topDistance="20px"
                >
                    <s.Container>
                        <div id="section5-header">
                            {isOnTrialTime || isOnEditMode || planExpired ? (
                                <>
                                    <div id="knowThePlans">
                                        Conheça os Planos
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h1>
                                        <Tooltip
                                            side="left"
                                            positionx={-10}
                                            content={
                                                <s.Tooltip>
                                                    Acessar a tela de
                                                    configurações da organização
                                                </s.Tooltip>
                                            }
                                        >
                                            <div
                                                className="settings"
                                                onClick={() =>
                                                    navigate(
                                                        "/organizacao/perfil"
                                                    )
                                                }
                                            >
                                                <Icons.Settings />
                                            </div>
                                        </Tooltip>
                                        Seu teste grátis de {trialDays} dias
                                        terminou.
                                    </h1>
                                    <h2>Escolha seu plano</h2>
                                </>
                            )}
                            <div className="toggleAnnualMode">
                                <div
                                    className="planMode"
                                    style={{
                                        color: !isAnnual
                                            ? theme.pink600
                                            : theme.gray600,
                                    }}
                                >
                                    Mensal
                                </div>
                                <s.ContainerToggle>
                                    <Switch
                                        width={50}
                                        height={20}
                                        handleDiameter={35}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onColor={theme.pink600}
                                        offColor={theme.pink600}
                                        offHandleColor={"#fff"}
                                        onHandleColor={"#fff"}
                                        checked={isAnnual}
                                        onChange={() => setIsAnnual(!isAnnual)}
                                    />
                                </s.ContainerToggle>

                                <div
                                    className="planMode"
                                    style={{
                                        color: isAnnual
                                            ? theme.pink600
                                            : theme.gray600,
                                    }}
                                >
                                    Anual
                                </div>
                            </div>
                        </div>
                        <div id="section5-cards">
                            <div className="section5-card">
                                <div className="section5-card-header">
                                    <strong>Lite</strong>
                                    <div className="priceInformation">
                                        <div className="coin">R$</div>
                                        <div className="planValue">
                                            <p className="price">
                                                {isAnnual ? "326" : "29"}
                                            </p>
                                            <div className="centsAndMonth">
                                                <div className="cents">,70</div>
                                                <div className="months">
                                                    {isAnnual
                                                        ? "por ano"
                                                        : "por mês"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isAnnual && (
                                    <div className="annualPromotionInformation">
                                        <Icons.IconArrowWithDirectionOption
                                            direction="down"
                                            height="24px"
                                            width="24px"
                                            color="#2E7D32"
                                        />
                                        <p>Use 12 meses, pague 11</p>
                                    </div>
                                )}
                                <section>
                                    <div className="planInformation">
                                        <h1>Até 3 usuários</h1>
                                        <p>
                                            {isAnnual
                                                ? "Equivale a R$ 9,08"
                                                : "R$ 9,90"}
                                            /mês por usuário
                                        </p>
                                    </div>
                                    <div className="section5-card-button">
                                        {renderButton({ type: "Lite" })}
                                    </div>
                                </section>
                                <ul className="section5-card-list">
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Acesso a todas funcionalidades da
                                        plataforma
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Cadastro de até 3 usuários
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Suporte rápido e eficiente, com pessoas
                                        reais
                                    </li>
                                </ul>
                            </div>

                            <div className="section5-card">
                                <div className="section5-card-header">
                                    <strong>Starter</strong>
                                    <div className="priceInformation">
                                        <div className="coin">R$</div>
                                        <div className="planValue">
                                            <p className="price">
                                                {isAnnual ? "979" : "89"}
                                            </p>
                                            <div className="centsAndMonth">
                                                <div className="cents">,00</div>
                                                <div className="months">
                                                    {isAnnual
                                                        ? "por ano"
                                                        : "por mês"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isAnnual && (
                                    <div className="annualPromotionInformation">
                                        <Icons.IconArrowWithDirectionOption
                                            direction="down"
                                            height="24px"
                                            width="24px"
                                            color="#2E7D32"
                                        />
                                        <p>Use 12 meses, pague 11</p>
                                    </div>
                                )}
                                <section>
                                    <div className="planInformation">
                                        <h1>Até 10 usuários</h1>
                                        <p>
                                            {isAnnual
                                                ? "Equivale a R$ 8,16"
                                                : "R$ 8,90"}
                                            /mês por usuário
                                        </p>
                                    </div>
                                    <div className="section5-card-button">
                                        {renderButton({ type: "Starter" })}
                                    </div>
                                </section>
                                <ul className="section5-card-list">
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Acesso a todas funcionalidades da
                                        plataforma
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Cadastro de até 10 usuários
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Suporte rápido e eficiente, com pessoas
                                        reais
                                    </li>
                                </ul>
                            </div>

                            <div className="section5-card">
                                <div className="section5-card-header">
                                    <strong>Standard</strong>
                                    <div className="priceInformation">
                                        <div className="coin">R$</div>
                                        <div className="planValue">
                                            <p className="price">
                                                {isAnnual ? "1738" : "158"}
                                            </p>
                                            <div className="centsAndMonth">
                                                <div className="cents">,00</div>
                                                <div className="months">
                                                    {isAnnual
                                                        ? "por ano"
                                                        : "por mês"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isAnnual && (
                                    <div className="annualPromotionInformation">
                                        <Icons.IconArrowWithDirectionOption
                                            direction="down"
                                            height="24px"
                                            width="24px"
                                            color="#2E7D32"
                                        />
                                        <p>Use 12 meses, pague 11</p>
                                    </div>
                                )}
                                <section>
                                    <div className="planInformation">
                                        <h1>Até 20 usuários</h1>
                                        <p>
                                            {isAnnual
                                                ? "Equivale a R$ 7,24"
                                                : "R$ 7,90"}
                                            /mês por usuário
                                        </p>
                                    </div>
                                    <div className="section5-card-button">
                                        {renderButton({ type: "Standard" })}
                                    </div>
                                </section>
                                <ul className="section5-card-list">
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Acesso a todas funcionalidades da
                                        plataforma
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Cadastro de até 20 usuários
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Suporte rápido e eficiente, com pessoas
                                        reais
                                    </li>
                                </ul>
                            </div>

                            <div className="section5-card">
                                <div className="section5-card-header">
                                    <strong>Business</strong>
                                    <div className="information">
                                        Entre em contato com a{" "}
                                        <span>equipe de vendas</span> para
                                        conhecer os preços.
                                    </div>
                                </div>
                                {isAnnual && (
                                    <div className="annualPromotionInformation">
                                        <div
                                            style={{
                                                opacity: 0,
                                            }}
                                        >
                                            -
                                        </div>
                                        <p></p>
                                    </div>
                                )}
                                <section>
                                    <div className="planInformation">
                                        <h1>A partir de 21 usuários</h1>
                                    </div>
                                    <div className="section5-card-button">
                                        <PrimaryButton
                                            onClick={() => {
                                                setIsModalBusinessActive(true);
                                            }}
                                        >
                                            ENTRE EM CONTATO
                                        </PrimaryButton>
                                    </div>
                                </section>
                                <ul className="section5-card-list">
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Acesso a todas funcionalidades da
                                        plataforma
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Quantidade de usuários cadastrados
                                        personalizado
                                    </li>
                                    <li>
                                        <img
                                            src={Check}
                                            id="check-plan"
                                            alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                                        />
                                        Suporte rápido e eficiente, com pessoas
                                        reais
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </s.Container>
                </ContainerModalScroll>
            )}
        </>
    );
};

export default ModalPlanCards;
