import styled from "styled-components";

export const Container = styled.div<{ changingPlan?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${({ changingPlan }) => (changingPlan ? "0px" : "80px 33px")};
    height: ${({ changingPlan }) => (changingPlan ? "0" : "100vh")};
    gap: 24px;

    div#content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;

        div#errors {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            border-radius: 4px;
            background-color: #fdeded;
            width: 100%;
            padding: 12px;
            gap: 12px;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h1 {
                    color: #d32f2f;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                }

                span {
                    color: #d32f2f;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        div#forms {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            gap: 3.125rem;
            width: 100%;

            div#pay-method {
                display: flex;
                padding: 40px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                border-radius: 4px;
                border: 1px solid ${({ theme }) => theme.gray200};
                width: 580px;

                header {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    h1 {
                        color: rgba(0, 0, 0, 0.87);
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;
                        letter-spacing: 0.024px;
                    }
                    p {
                        color: rgba(0, 0, 0, 0.6);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.14px;
                    }
                }

                section {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    div#credit-cards {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        gap: 8px;
                        padding: 24px 0px 12px 0px;
                    }

                    form {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        div#card-number {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 4px;
                            align-self: stretch;

                            div {
                                color: ${({ theme }) => theme.gray600};
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px;
                                white-space: nowrap;
                            }
                        }

                        div#card-information {
                            display: flex;
                            justify-content: center;
                            align-items: space-between;
                            gap: 24px;
                            align-self: stretch;
                            width: 100%;

                            div {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 4px;
                                flex: 1 0 0;

                                div {
                                    color: ${({ theme }) => theme.gray600};
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    white-space: nowrap;
                                }
                            }
                        }

                        div#card-name {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 4px;
                            align-self: stretch;

                            div {
                                color: ${({ theme }) => theme.gray600};
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px;
                                white-space: nowrap;
                            }
                        }

                        div#installments {
                            div {
                                color: ${({ theme }) => theme.gray800};
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px;
                                white-space: nowrap;
                            }
                            .select-input {
                                color: ${({ theme }) => theme.gray800};
                                div {
                                    cursor: pointer;
                                }
                                .react-select__menu {
                                    .react-select__option {
                                        color: ${({ theme }) => theme.gray600};
                                    }
                                    .react-select__option:hover {
                                        background: #fdf1fe;
                                    }
                                    .react-select__option--is-selected {
                                        background-color: #fdf1fe;
                                        color: ${({ theme }) => theme.gray800};
                                    }
                                }
                            }
                        }

                        div#contractor-name {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 4px;
                            align-self: stretch;

                            h1 {
                                color: rgba(0, 0, 0, 0.87);
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 22px;
                                letter-spacing: 0.024px;
                                padding: 24px 0px;
                                border-top: 1px solid #cfcdd3;
                                width: 100%;
                            }
                            section {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 4px;

                                span {
                                    color: ${({ theme }) => theme.gray600};
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    white-space: nowrap;
                                }

                                width: fit-content;
                            }

                            :last-child {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .plan {
                width: 392px;
            }
        }
    }

    div#talk-with-us {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        padding-bottom: 36px;

        p {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }

        button {
            border-radius: 4px;
            border: 1px solid ${({ theme }) => theme.purple600};
            display: flex;
            width: 151px;
            height: 40px;
            padding: 14px 16px;
            justify-content: center;
            align-items: center;

            color: ${({ theme }) => theme.purple600};
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.12px;
            white-space: nowrap;
        }
    }
`;

export const ContractorNameToolTip = styled.div`
    display: flex;
    flex-direction: column;

    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    align-self: stretch;
`;

export const BreadcrumbContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 23px 32px;
`;
