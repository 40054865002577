import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    width: 400px;
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 10px;

    button {
        width: 120px;
    }
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const ManagerSelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
