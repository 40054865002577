import styled from "styled-components";

type TContainer = {
    errors: {
        description: boolean;
        project: boolean;
    };
    disabled?: boolean;
};

export const Flex = styled.div`
    display: flex;
    align-items: center;
`;

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const BetaBadge = styled.div`
    background: ${({ theme }) => theme.purple500};
    color: ${({ theme }) => theme.white};
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.1rem 0.4rem;
    border-radius: 0.25rem;
`;

export const AssistantButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
`;

export const AssistantButton = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${({ theme }) => theme.purple500};
    font-size: 13px;
    font-weight: 500;
    background-color: ${({ theme }) => theme.purple50};
    padding: 10px 15px;
    border-radius: 0.25rem;
`;

export const Container = styled.section<TContainer>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 29px;

    @media (max-width: 768px) {
        padding: 0 1rem;
    }

    #track-container {
        height: 98px;

        svg {
            cursor: ${({ disabled }) => disabled && "not-allowed"};
            path {
                stroke: ${({ theme, disabled }) => disabled && theme.gray200};
            }
        }

        padding: 1rem;
        border: 1px solid
            ${({ theme, errors }) =>
                errors.description || errors.project
                    ? theme.red
                    : theme.purple500};
        border: 1px solid
            ${({ disabled, theme }) =>
                disabled ? theme.gray200 : theme.purple500};
        margin-top: 1rem;
        gap: 24px;

        @media (max-width: 768px) {
            display: grid;
            width: 100%;
            gap: 10px;
            height: auto;

            .icon-arrow {
                width: 15px;
            }

            #container-calendar {
                margin-left: 0px !important;
            }
        }

        .track-right-side {
            display: flex;
            align-items: center;
            gap: 24px;

            @media (max-width: 768px) {
                gap: 0px;
                justify-content: space-between;
            }

            .clear-entries-button {
                display: flex;
                align-items: center;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }

        input {
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px;
            padding: 12px 16px;
            font-style: normal;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            letter-spacing: 0.0015em;
            color: ${({ theme, disabled }) =>
                disabled ? theme.gray200 : theme.gray800};

            &:focus {
                border: 1px solid ${({ theme }) => theme.gray400};
                border-radius: 4px;
                &::placeholder {
                    visibility: hidden;
                }
            }
            &::placeholder {
                transform: translateX(-16px);
                font-style: normal;
                font-weight: 500;
                font-size: 1.25rem;
                padding: 12px 16px;
                line-height: 1.75rem;
                letter-spacing: 0.0015em;
                color: ${({ theme, disabled }) =>
                    disabled ? theme.gray200 : theme.gray400};
            }
        }
        .icon-tag {
            min-width: 18px;
            min-height: 18px;
        }
        .tag-project {
            display: flex;
            gap: 0.8125rem;
            max-width: 200px;
            overflow: hidden;

            p {
                white-space: nowrap;
                text-align: left;
                color: ${({ theme }) => theme.gray800};
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.14px;
            }
        }

        #timer {
            gap: 8px;
            #input-start,
            #input-end {
                border: 1px solid ${({ theme }) => theme.gray200};
                padding: 12px;
                width: 4.8125rem;
                height: 48px;

                @media (max-width: 768px) {
                    width: 58px;
                }
            }

            #container-calendar {
                display: flex;
                margin-left: 16px;

                #container-icon {
                    gap: 8px;

                    p {
                        color: ${({ disabled, theme }) =>
                            disabled && theme.gray200};
                        cursor: ${({ disabled }) => disabled && "not-allowed"};
                        white-space: nowrap;
                    }

                    button {
                        display: flex;
                    }
                }
                &:hover {
                    svg {
                        path {
                            stroke: ${({ theme, disabled }) =>
                                disabled ? theme.gray200 : theme.pink600};
                        }
                    }
                    p#trigger {
                        color: ${({ theme, disabled }) =>
                            disabled ? theme.gray200 : theme.pink600};
                    }
                }
            }
        }

        #container-button {
            button {
                padding: 0.75rem 1.5rem;

                @media (max-width: 768px) {
                    padding: 9px;
                    height: 40px;
                }
            }
        }

        #container-modes {
            svg {
                cursor: pointer;
                &:hover {
                    path {
                        stroke: ${({ theme, disabled }) =>
                            disabled ? theme.gray200 : theme.pink600};
                    }
                }
            }
        }
    }

    #month-summary {
        padding: 1rem;
        margin-top: 1.5rem;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;

        div#container-info {
            display: flex;
            gap: 1.9375rem;
            align-items: center;

            @media (max-width: 768px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            span {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            span:first-child {
                white-space: nowrap;
            }

            span#btn-limit {
                margin-left: auto;
            }
        }

        #container-summary {
            gap: 0.5rem;
        }
    }

    #cards {
        width: 100%;
        margin: 2.25rem 0;
        gap: 1.5rem;
    }
`;

export const BorderContainer = styled(Flex)<{ disabled?: boolean }>`
    cursor: default;
    background: ${({ theme }) => theme.white};
    width: 100%;
    border: 1px solid
        ${({ theme, disabled }) => (disabled ? theme.white2 : theme.gray200)};
    box-sizing: border-box;
    border-radius: 0.25rem;
    align-items: center;
    position: relative;

    input#input-description {
        border: 1px solid transparent;

        &:focus {
            border: 1px solid ${({ theme }) => theme.gray200};
        }
    }

    .project-hours {
        @media (max-width: 768px) {
            display: none !important;
        }

        display: flex;
        align-items: center;

        &__tag {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .project-hours--favorite {
        display: flex;
        align-items: center;

        &__tag--favorite {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .value-done {
        display: flex;
        align-items: center;
        gap: 12px;

        button {
            height: 24px;
            margin-left: 10px;
        }
    }

    .clear-entries {
        min-width: 38px;
        margin-left: -10px;
        margin-right: -10px;

        @media (max-width: 768px) {
            margin-left: 0px;
        }
    }
`;

export const Input = styled.input`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.gray200};
    box-sizing: border-box;
    border-radius: 4px;
    width: 33.1875rem;
    height: 4.25rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.0625rem;
    padding: 1rem;
    color: ${({ theme }) => theme.gray800};
    &&::placeholder {
        color: ${({ theme }) => theme.gray400};
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme, color }) => color || theme.gray800};

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

export const SmallP = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme, color }) => color || theme.gray800};

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

export const DailyGoalTextContainer = styled.div<{ show?: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;

    div {
        display: flex;
        align-items: center;
    }

    ${({ show }) => `
        .icon-collapse-arrow {
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
            transform: ${show ? "rotate(0deg)" : "rotate(180deg)"}
        }
    `}

    .icon-pencil-outline {
        cursor: pointer;
    }
`;

export const DailyGoalContainer = styled.div<{ show?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`;

export const GrayP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.001em;
    color: ${({ theme }) => theme.gray600};

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

export const BigP = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 28px;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
    opacity: 0.8;
`;

export const activitiesTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    background: ${({ theme }) => theme.purple50};
    border-radius: 0.1875rem 0.1875rem 0px 0px;
    padding: 0 1rem;

    #container-hours-and-edit {
        gap: 4.375rem;
    }
`;

export const H1 = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.0625rem;
    color: ${({ theme }) => theme.purple800};
    margin-top: 3.5rem;
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2.625rem;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const LockTooltip = styled.div`
    width: 18.75rem;
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
`;

export const ClearDescriptionTooltip = styled.div`
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
`;

export const TimeLimitWarningTooltip = styled.span`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 11px;
    width: 412px;
    margin-top: 0.8125rem;

    header {
        display: flex;
        align-items: center;
        gap: 12px;
        color: ${({ theme }) => theme.gray800};
        font-weight: 600;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    p {
        color: var(--color-gray-600, #6e697a);
        font-size: 12px;
        font-weight: 400;
    }
`;

export const CommonTooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding: 5px 10px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
`;
