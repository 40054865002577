import { LoadingGray, SecondaryButton } from "../../../../components";
import * as s from "../styled-components";

type FooterProps = {
    onClickShowMore: () => void;
    isLoading: boolean;
};

export const Footer = ({ isLoading, onClickShowMore }: FooterProps) => {
    return (
        <s.Footer>
            {isLoading ? (
                <LoadingGray />
            ) : (
                <SecondaryButton onClick={onClickShowMore}>
                    MOSTRAR MAIS
                </SecondaryButton>
            )}
        </s.Footer>
    );
};
