import { theme } from "..";

type TAllertCircle = {
    width?: string;
    height?: string;
};

export default ({ width, height }: TAllertCircle) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "24"}
            height={height || "24"}
            viewBox="0 0 24 24"
            fill="none"
        >
            <mask
                id="mask0_23917_4023"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    x="24"
                    y="24"
                    width="24"
                    height="24"
                    transform="rotate(-180 24 24)"
                    fill={theme.white3}
                />
            </mask>
            <g mask="url(#mask0_23917_4023)">
                <path
                    d="M11.9997 7.25005C11.7871 7.25005 11.609 7.32192 11.4654 7.46567C11.3218 7.60941 11.2501 7.78752 11.2501 8.00002L11.2501 12.25C11.2501 12.4625 11.3219 12.6406 11.4657 12.7844C11.6096 12.9281 11.7878 13 12.0003 13C12.2129 13 12.391 12.9281 12.5346 12.7844C12.6782 12.6406 12.75 12.4625 12.75 12.25L12.75 8.00002C12.75 7.78752 12.6781 7.60941 12.5343 7.46567C12.3905 7.32192 12.2123 7.25005 11.9997 7.25005ZM12 14.7115C11.7712 14.7115 11.5793 14.7889 11.4245 14.9437C11.2697 15.0985 11.1923 15.2904 11.1923 15.5192C11.1923 15.7481 11.2697 15.9399 11.4245 16.0947C11.5793 16.2495 11.7712 16.3269 12 16.3269C12.2289 16.3269 12.4207 16.2495 12.5755 16.0947C12.7303 15.9399 12.8077 15.7481 12.8077 15.5192C12.8077 15.2904 12.7303 15.0985 12.5755 14.9437C12.4207 14.7889 12.2289 14.7115 12 14.7115ZM11.9984 2.50005C13.3123 2.50005 14.5473 2.74938 15.7035 3.24805C16.8596 3.74672 17.8653 4.42346 18.7205 5.2783C19.5757 6.13315 20.2528 7.13839 20.7517 8.29402C21.2506 9.44964 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8596 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7517C14.5504 21.2506 13.3156 21.5 12.0017 21.5C10.6878 21.5 9.45273 21.2507 8.2966 20.752C7.14045 20.2533 6.13477 19.5766 5.27955 18.7217C4.42432 17.8669 3.74726 16.8617 3.24837 15.706C2.74949 14.5504 2.50005 13.3156 2.50005 12.0017C2.50005 10.6878 2.74938 9.45273 3.24805 8.2966C3.74672 7.14045 4.42347 6.13476 5.2783 5.27955C6.13315 4.42432 7.13839 3.74726 8.29403 3.24837C9.44964 2.74949 10.6844 2.50005 11.9984 2.50005ZM12 4.00002C9.76669 4.00002 7.87502 4.77502 6.32502 6.32502C4.77502 7.87502 4.00002 9.76669 4.00002 12C4.00002 14.2334 4.77502 16.125 6.32502 17.675C7.87502 19.225 9.76669 20 12 20C14.2334 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2334 20 12C20 9.76669 19.225 7.87502 17.675 6.32502C16.125 4.77502 14.2334 4.00002 12 4.00002Z"
                    fill="#D32F2F"
                />
            </g>
        </svg>
    );
};
