import { View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { theme } from "../../../../../../assets";
import { SelectedDate } from "../../../../../../pages/organization/project/tab-costs/tab-costs";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import {
    formatToBRMoney,
    formatToBRMoneyWithoutMoneySign,
    numberHoursToString,
} from "../../../../../../utils";
import * as s from "../../../styled-pdf-template";

type TeamProps = {
    data: ReadProjectCostsResult | undefined;
};

export const Team = ({ data }: TeamProps) => {
    if (!data) return null;

    return (
        <View>
            <View
                style={[
                    {
                        border: `1px solid ${theme.gray200}`,
                        borderRadius: 4,
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "25px",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                    },
                ]}
            >
                <Text
                    style={[
                        s.PDFTableSummaryReport.TableBodyText,
                        {
                            textAlign: "center",
                            textTransform: "uppercase",
                            marginTop: "20px",
                            fontSize: "9px",
                            letterSpacing: "0.96px",
                        },
                    ]}
                >
                    andamento geral dos custos do time
                </Text>
                <View
                    style={{
                        display: "flex",
                        marginTop: "15px",
                        marginBottom: "20px",
                        borderRadius: 4,
                        flexDirection: "row",
                        gap: "50px",
                        border: `1px solid ${theme.gray200}`,
                        padding: "22px 35px",
                        width: "472px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <View
                        style={{
                            borderRight: `1px solid ${theme.gray200}`,
                            paddingRight: "50px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total previsto
                        </Text>
                        <Text style={s.ProjectCosts.CostText}>
                            {formatToBRMoney(
                                data.resume.total.teamCost.forecast
                            )}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <Text style={s.ProjectCosts.CostHeader}>
                            Custo total realizado
                        </Text>
                        <Text style={s.ProjectCosts.CostTextPurple}>
                            {formatToBRMoney(
                                data.resume.total.teamCost.realized
                            )}
                        </Text>
                    </View>
                </View>
            </View>

            <View
                style={{
                    border: `1px solid ${theme.gray200}`,
                    borderRadius: "4px",
                    width: "100%",
                }}
            >
                <View
                    style={{
                        padding: "12px 25px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                        }}
                    >
                        profissional
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                        }}
                    >
                        h. trabalhadas
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                        }}
                    >
                        valor hora
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                            marginRight: "23px",
                        }}
                    >
                        total parcial
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                        }}
                    >
                        benefícios
                    </Text>
                    <Text
                        style={{
                            textTransform: "uppercase",
                            letterSpacing: "0.96px",
                            color: theme.gray700,
                            fontSize: "7px",
                            width: "16.6%",
                        }}
                    >
                        total
                    </Text>
                </View>

                {data.teamCosts.data.map((user) => (
                    <View
                        style={{
                            borderTop: `1px solid ${theme.gray200}`,
                            padding: "12px 25px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        <Text
                            style={{
                                fontSize: "7px",
                                color: theme.gray600,
                                width: "16.6%",
                            }}
                        >
                            {user.name}
                        </Text>
                        <Text
                            style={{
                                fontSize: "7px",
                                color: theme.gray600,
                                width: "16.6%",
                            }}
                        >
                            {numberHoursToString(user.workedHours)}
                        </Text>
                        <Text
                            style={{
                                fontSize: "7px",
                                color: theme.gray600,
                                width: "16.6%",
                            }}
                        >
                            {formatToBRMoneyWithoutMoneySign(user.hourValue)}
                        </Text>
                        <Text
                            style={{
                                fontSize: "7px",
                                color: theme.gray600,
                                width: "16.6%",
                            }}
                        >
                            {formatToBRMoneyWithoutMoneySign(user.workCost)}
                        </Text>
                        <View
                            style={{
                                width: "16.6%",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: "7px",
                                    padding: "5px 10px",
                                    borderRadius: "8px",
                                    textTransform: "capitalize",
                                    color: theme.gray600,
                                }}
                            >
                                {formatToBRMoneyWithoutMoneySign(
                                    user.benefit?.value || 0
                                )}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "16.6%",
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: "7px",
                                    padding: "5px 10px",
                                    borderRadius: "8px",
                                    textTransform: "capitalize",
                                    color: theme.gray600,
                                }}
                            >
                                {formatToBRMoneyWithoutMoneySign(user.total)}
                            </Text>
                        </View>
                    </View>
                ))}
            </View>
        </View>
    );
};
