import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type CreateNPSProps = {
    name?: string;
    description?: string;
    start_date?: Date;
    end_date?: Date;
};

export default async ({
    name,
    description,
    start_date,
    end_date,
}: CreateNPSProps) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/nps/create`,
        method: "post",
        headers: { auth: true },
        body: { name, description, start_date, end_date },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
