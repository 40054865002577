import { PrimaryButton, SecondaryButton } from "../../../../../../components";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { TResponse } from "../../../../../../models";
import { TDeleteSection } from "../../../../../../services/activities/delete-section";
import * as s from "./styled-delete-confirmation-section";

export type DeleteConfirmationSectionProps = {
    setOpen: React.Dispatch<React.SetStateAction<string>>;
    sectionId: string;
    deleteSection: TResponse<unknown, TDeleteSection>;
};

export const DeleteConfirmationSection = ({
    setOpen,
    sectionId: activityId,
    deleteSection,
}: DeleteConfirmationSectionProps) => {
    const handleDeleteSection = (sectionId: string) => {
        deleteSection.onRefresh({
            sectionId,
        });
    };

    return (
        <ContainerModal>
            <s.Container
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setOpen("");
                    }
                    if (e.key === "Enter") {
                        handleDeleteSection(activityId);
                    }
                }}
            >
                <s.Title>Deletar sessão</s.Title>
                <s.Description>
                    Ao deletar a sessão, esta ação não poderá ser desfeita.
                </s.Description>
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => {
                            setOpen("");
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setOpen("");
                            handleDeleteSection(activityId);
                        }}
                    >
                        DELETAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
