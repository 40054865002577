import * as yup from "yup";

export default yup.object().shape({
    cardNumber: yup.string().required("Insira o número do cartão"),
    cardCVV: yup.string().required("Insira o CVV"),
    cardExpirationMonth: yup.string().required("Insira o mês de expiração"),
    cardExpirationYear: yup.string().required("Insira o ano de expiração"),
    cardName: yup.string().required("Insira o nome impresso no cartão"),
    contractorName: yup.string().required("Insira o nome do contratante"),
});
