import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";

import { Icons } from "../../../../assets";
import { TabPicker } from "../../../../components/molecules/tab-picker/tab-picker";
import { useReadCosts } from "../../../../hooks/react-query/use-read-costs";
import { TProject } from "../../../../models";
import { ReadProjectCostsResult } from "../../../../services/projects/types";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import { UpdateChoiceEnum } from "./components/modals/modal-upsert-additional-cost/modal-upsert-additional-cost";
import { TabHeader } from "./components/tab-header/tab-header";
import { FixedCostsTab } from "./components/tabs/fixed-costs/fixed-costs";
import { ResumeTab } from "./components/tabs/resume/resume";
import { TeamTab } from "./components/tabs/team/team";
import { VariableCostsTab } from "./components/tabs/variable-costs/variable-costs";
import { TotalCard } from "./components/total-card/total-card";
import { tabs } from "./constants";
import * as s from "./styles";

const tabsComponents = {
    Resumo: ResumeTab,
    "Custos fixos": FixedCostsTab,
    "Custos variáveis": VariableCostsTab,
    "Custos time": TeamTab,
};

type TabCostsProps = {
    project: TProject;
};

export type SelectedDate = Record<"start" | "end", Date | undefined>;

export type TabProps = {
    data: ReadProjectCostsResult | undefined;
    isToCreate: boolean;
    orderByDate: "asc" | "desc";
    date: SelectedDate;
    setIsToCreate: React.Dispatch<React.SetStateAction<boolean>>;
    setOrderByDate: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
};

export type ActiveTabsEnum = keyof typeof tabsComponents;

export type CostsFields = {
    id_e?: string;
    title: string;
    description: string;
    date?: Date;
    cost: string;
    recurringNumber?: number;
    recurring?: boolean;
    updateChoice?: UpdateChoiceEnum;
    recurringId?: string;
};

dayjs.extend(timezone);
dayjs.extend(utc);

export const TabCosts = ({ project }: TabCostsProps) => {
    const [activeTab, setActiveTab] = useState<ActiveTabsEnum>("Resumo");
    const ActiveComponent = tabsComponents[activeTab];
    const [date, setDate] = useState<SelectedDate>({
        start: dayjs(new Date()).startOf("month").toDate(),
        end: dayjs(new Date()).endOf("month").toDate(),
    });
    const [isToCreate, setIsToCreate] = useState(false);
    const [orderByDate, setOrderByDate] = useState<"asc" | "desc">("desc");

    const startDate = date.start?.toISOString();
    const endDate = date.end?.toISOString();

    const { data, isLoading, refetch } = useReadCosts({
        projectId: project.id_e,
        startDate: dayjs(startDate).subtract(3, "hours").toISOString(),
        endDate: dayjs(endDate).subtract(3, "hours").toISOString(),
        orderByDate,
    });

    useEffect(() => {
        setIsToCreate(false);
        setOrderByDate("desc");
        Mixpanel.track("Trocar de aba de custos", {
            tab: activeTab,
        });
    }, [activeTab]);

    useEffect(() => {
        refetch();
    }, [date, orderByDate]);

    if (isLoading || !data) return <Icons.LoadingSpinCircle />;

    const tabsDataCosts = {
        Resumo: data.resume.total,
        "Custos fixos": {
            forecastedCost: data.resume.total.fixedCost.forecast,
            realizedCost: data.resume.total.fixedCost.realized,
            history: data.resume.total.fixedCost.history,
        },
        "Custos variáveis": {
            forecastedCost: data.resume.total.variableCost.forecast,
            realizedCost: data.resume.total.variableCost.realized,
            history: data.resume.total.variableCost.history,
        },
        "Custos time": {
            forecastedCost: data.resume.total.teamCost.forecast,
            realizedCost: data.resume.total.teamCost.realized,
            history: data.resume.total.teamCost.history,
        },
    };

    return (
        <s.Container>
            <TabPicker
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <TotalCard data={tabsDataCosts[activeTab]} activeTab={activeTab} />
            <TabHeader
                date={date}
                data={data}
                setDate={setDate}
                activeTab={activeTab}
                setIsToCreate={setIsToCreate}
            />
            {ActiveComponent && (
                <ActiveComponent
                    data={data}
                    date={date}
                    isToCreate={isToCreate}
                    setIsToCreate={setIsToCreate}
                    orderByDate={orderByDate}
                    setOrderByDate={setOrderByDate}
                />
            )}
        </s.Container>
    );
};
