import { Text, View, Image } from "@react-pdf/renderer";

import { PurpleLogo, theme } from "../../../../assets";
import { TReport } from "../../../../models";
import { formatToBRMoney, minutesToStringDate } from "../../../../utils";
import PDFTemplate from "../pdf-template";
import * as s from "../styled-pdf-template";

type TTotalValue = {
    totalDiscount: number;
    totalAdditional: number;
    totalValue: number;
};

type TCollaboratorInvoiceParams = {
    reports: TReport[];
    period: string;
    organization: string;
    totalValue: TTotalValue;
};

export const CollaboratorInvoice = ({
    reports,
    period,
    organization,
    totalValue,
}: TCollaboratorInvoiceParams) => {
    const correctUserName = (name: string | undefined | null) => {
        if (!name) return <p>Sem informação</p>;
        const nameArray = name.split(" ");
        if (nameArray.length === 1) return <p>{nameArray[0]}</p>;

        return (
            <>
                <Text style={s.TableInvoiceReport.TableBodyText}>
                    {nameArray[0]}
                </Text>
                <Text style={s.TableInvoiceReport.TableBodyLowerText}>
                    {delete nameArray[0]}
                    {nameArray.map((name) => `${name} `)}
                </Text>
            </>
        );
    };

    return (
        <PDFTemplate>
            <View style={s.PDF.Header}>
                <Text
                    style={
                        (s.PDF.Title,
                        { color: theme.gray600, fontSize: "14px" })
                    }
                >
                    Lista de faturamento profissionais
                </Text>

                <Image src={PurpleLogo} style={s.PDF.ImageStyle} fixed />
            </View>

            <View style={(s.PDF.Project, { marginTop: "40px" })}>
                <View style={s.PDF.ProjectHeader}>
                    <View
                        style={[
                            s.PDF.HeaderColumn,
                            {
                                width: "50%",
                            },
                        ]}
                    >
                        <Text style={s.PDF.TableHeaderText}>ORGANIZAÇÃO</Text>
                        <Text style={s.PDF.HeaderValueText}>
                            {organization}
                        </Text>
                    </View>

                    <View
                        style={[
                            s.PDF.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray50}`,
                                width: "20%",
                            },
                        ]}
                    >
                        <Text style={s.PDF.TableHeaderText}>PERÍODO</Text>
                        <Text style={s.PDF.HeaderValueText}>{period}</Text>
                    </View>
                </View>
            </View>

            <View style={{ marginTop: "10px", width: "100%" }}>
                <View style={s.TableInvoiceReport.TableHeader} wrap={false}>
                    <View
                        style={{
                            width: "20%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Nome
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Horas trabalhadas
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Valor hora
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Descontos
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Adicionais
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Valor total
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Status
                        </Text>
                    </View>
                </View>

                {reports.map((report) => (
                    <View style={s.TableInvoiceReport.TableRow} wrap={false}>
                        <View
                            style={{
                                width: "20%",
                            }}
                        >
                            {correctUserName(report.User?.name)}
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {minutesToStringDate(report?.total_time)}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {report?.User?.hourValue
                                    ? formatToBRMoney(report.User.hourValue)
                                    : "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {report?.ReportBilling?.discounts
                                    ? formatToBRMoney(
                                          parseFloat(
                                              report?.ReportBilling?.discounts
                                          )
                                      )
                                    : "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {report?.ReportBilling?.additional
                                    ? formatToBRMoney(
                                          parseFloat(
                                              report?.ReportBilling?.additional
                                          )
                                      )
                                    : "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {report?.ReportBilling?.final_total
                                    ? formatToBRMoney(
                                          parseFloat(
                                              report?.ReportBilling?.final_total
                                          )
                                      )
                                    : "-"}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: "15%",
                            }}
                        >
                            <Text style={s.TableInvoiceReport.TableBodyText}>
                                {report?.ReportBilling ? (
                                    <Text
                                        style={
                                            s.TableInvoiceReport.BilledStatus
                                        }
                                    >
                                        <Text>Faturado</Text>
                                    </Text>
                                ) : (
                                    <Text
                                        style={
                                            s.TableInvoiceReport.NotBilledStatus
                                        }
                                    >
                                        <Text>Pendente</Text>
                                    </Text>
                                )}
                            </Text>
                        </View>
                    </View>
                ))}
                <View
                    style={{
                        ...s.TableInvoiceReport.TableRow,
                        backgroundColor: theme.gray200,
                    }}
                    wrap={false}
                >
                    <View
                        style={{
                            width: "20%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            Valor total
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text
                            style={s.TableInvoiceReport.TableHeaderText}
                        ></Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text
                            style={s.TableInvoiceReport.TableHeaderText}
                        ></Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            {formatToBRMoney(totalValue.totalDiscount)}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            {formatToBRMoney(totalValue.totalAdditional)}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text style={s.TableInvoiceReport.TableHeaderText}>
                            {formatToBRMoney(totalValue.totalValue)}
                        </Text>
                    </View>
                    <View
                        style={{
                            width: "15%",
                        }}
                    >
                        <Text
                            style={s.TableInvoiceReport.TableHeaderText}
                        ></Text>
                    </View>
                </View>
            </View>
        </PDFTemplate>
    );
};
