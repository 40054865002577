import styled from "styled-components";

import { Icons } from "../../../../../assets";

export const Container = styled.div`
    width: 4rem;
    height: 2.6875rem;

    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.white};
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
    font-size: 0.75rem;
`;

export const Arrow = styled(Icons.ArrowBack)<{ up: boolean }>`
    transform: rotate(${({ up }) => (up ? -90 : 90)}deg);
    margin-left: -0.5rem;
    width: 1.2rem;
    height: 1rem;
`;
export const TooltipContainer = styled.div<{ value: number }>`
    padding: 1rem;
    max-width: 18rem;
    h5 {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.purple600};
        margin-bottom: 0.5rem;
    }
    label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};
        strong {
            text-transform: uppercase;
            font-weight: 600;
            color: ${({ theme, value }) => {
                if (value === 0) {
                    return theme.gray600;
                }
                if (value > 0) {
                    return theme.greenDone;
                }
                return theme.redError;
            }};
        }
    }
`;
