import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../components/atoms/menu/menu";
import { activities } from "../../../../../../../services";
import { DeleteConfirmationModal } from "../../modals/delete-confirmation-activity/delete-confirmation-activity";
import { ActivityDTO } from "../../types/activityDTO";
import * as s from "./styles";

type TOptions = {
    activity: ActivityDTO;
    close: () => void;
};

export const Options = ({ close, activity }: TOptions) => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
        useState<boolean>(false);

    const isArchived = activity.ActivitySection?.name === "Arquivo";

    const deleteActivity = useMutation({
        mutationKey: ["deleteActivity", activity.id_e],
        mutationFn: () =>
            activities.deleteActivity({ activityIds: [activity.id_e] }),
        onSuccess: () => {
            toast.success("Atividade excluída com sucesso!");
            close();
        },
    });

    const archiveActivity = useMutation({
        mutationKey: ["archiveActivity", activity.id_e],
        mutationFn: () =>
            activities.archiveActivity({
                activityIds: [activity.id_e],
                projectId: activity.project_id,
            }),
        onSuccess: () => {
            toast.success("Atividade arquivada com sucesso!");
            close();
        },
    });

    const unarchiveActivity = useMutation({
        mutationKey: ["unarchiveActivity", activity.id_e],
        mutationFn: () =>
            activities.unarchiveActivity({
                activityIds: [activity.id_e],
                projectId: activity.project_id,
            }),
        onSuccess: () => {
            toast.success("Atividade desarquivada com sucesso!");
            close();
        },
    });

    const duplicateActivity = useMutation({
        mutationKey: ["duplicateActivity", activity.id_e],
        mutationFn: () =>
            activities.duplicateActivity({
                activityId: activity.id_e,
            }),
        onSuccess: () => {
            toast.success("Atividade duplicada com sucesso!");
            close();
        },
    });

    const options: TMenuAtom["options"] = [
        {
            label: "Duplicar",
            icon: <Icons.Duplicate color={theme.gray800} />,
            action: () => {
                duplicateActivity.mutate();
            },
            color: theme.gray800,
        },
        {
            label: isArchived ? "Desarquivar" : "Arquivar",
            icon: isArchived ? (
                <Icons.Unarchive color={theme.gray600} />
            ) : (
                <Icons.IconArchive color={theme.gray600} />
            ),
            action: () => {
                if (isArchived) {
                    unarchiveActivity.mutate();
                    return;
                }
                archiveActivity.mutate();
            },
            color: theme.gray600,
        },
        {
            label: "Excluir",
            icon: <Icons.Trash color={theme.red} />,
            action: () => {
                setDeleteConfirmationOpen(true);
            },
            color: theme.red,
        },
    ];

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    setOpen={setDeleteConfirmationOpen}
                    onConfirm={deleteActivity.mutate}
                />
            )}
            <s.Options>
                <MenuAtom options={options}>
                    <s.Button>
                        <Icons.More color={theme.gray800} />
                    </s.Button>
                </MenuAtom>
                <s.Button>
                    <Icons.XOutline color={theme.gray800} onClick={close} />
                </s.Button>
            </s.Options>
        </>
    );
};
