import styled from "styled-components";

import { theme } from "../../../assets";

type TWarning = {
    success?: boolean;
    projectStatus?: string;
    projectIsLate?: boolean;
};

const getStatusColors = (projectIsLate: boolean): Record<string, string> => {
    const statusColors: Record<string, string> = {
        to_start: projectIsLate ? theme.redError : theme.greenDone,
        in_progress: projectIsLate ? theme.redError : theme.greenDone,
        late: theme.gray800,
        paused: theme.gray800,
        canceled: theme.gray800,
        finished: theme.gray800,
        no_information: theme.gray800,
    };
    return statusColors;
};

export const Container = styled.div<TWarning>`
    background: transparent;
    border: 1px solid ${theme.gray50};
    border-radius: 4px;
    font-size: 0.75rem;
    padding: 0.7rem;
    span {
        color: ${({ projectStatus, projectIsLate }) =>
            projectStatus
                ? getStatusColors(projectIsLate || false)[projectStatus]
                : "inherit"};
        display: flex;
        align-items: center;
        gap: 0.3rem;
        margin-bottom: 0.5rem;
    }
    p {
        color: ${theme.gray500};
    }
`;
