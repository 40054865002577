import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        strokeWidth={0}
    >
        <title>{title}</title>
        <g id="content_copy">
            <mask
                id="mask0_22668_1602"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill={theme.white3}
                />
            </mask>
            <g mask="url(#mask0_22668_1602)">
                <path
                    id="content_copy_2"
                    d="M5.75006 21.3075C5.24494 21.3075 4.81738 21.1325 4.46738 20.7825C4.11738 20.4325 3.94238 20.005 3.94238 19.4998V6.80762H5.44236V19.4998C5.44236 19.5768 5.47441 19.6473 5.53851 19.7114C5.60261 19.7755 5.67312 19.8075 5.75006 19.8075H15.4423V21.3075H5.75006ZM9.25003 17.8075C8.7449 17.8075 8.31733 17.6325 7.96733 17.2825C7.61735 16.9325 7.44236 16.505 7.44236 15.9999V4.61529C7.44236 4.11018 7.61735 3.68262 7.96733 3.33262C8.31733 2.98262 8.7449 2.80762 9.25003 2.80762H17.6346C18.1397 2.80762 18.5673 2.98262 18.9173 3.33262C19.2673 3.68262 19.4423 4.11018 19.4423 4.61529V15.9999C19.4423 16.505 19.2673 16.9325 18.9173 17.2825C18.5673 17.6325 18.1397 17.8075 17.6346 17.8075H9.25003ZM9.25003 16.3076H17.6346C17.7115 16.3076 17.782 16.2755 17.8462 16.2114C17.9103 16.1473 17.9423 16.0768 17.9423 15.9999V4.61529C17.9423 4.53836 17.9103 4.46784 17.8462 4.40374C17.782 4.33964 17.7115 4.30759 17.6346 4.30759H9.25003C9.1731 4.30759 9.10258 4.33964 9.03848 4.40374C8.97437 4.46784 8.94231 4.53836 8.94231 4.61529V15.9999C8.94231 16.0768 8.97437 16.1473 9.03848 16.2114C9.10258 16.2755 9.1731 16.3076 9.25003 16.3076Z"
                    fill={color || theme.gray800}
                />
            </g>
        </g>
    </svg>
);
