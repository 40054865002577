import { constants } from "fs/promises";

import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Errors =
    | "total projects workload is bigger than hours limit"
    | "You don't have permission to access this resource"
    | "user does not have a report";

type TUpdateProjectHourControl = {
    userId?: string;
    projectId?: string;
    hourControlId?: string;
    workload?: string;
    additionalHours?: string;
};

type TTempBody = {
    additionalHours?: number;
    workload?: number;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    projectId,
    hourControlId,
    workload,
    additionalHours,
}: TUpdateProjectHourControl = {}) => {
    let tempBody: TTempBody = {};

    if (additionalHours === undefined) {
        tempBody = {
            workload: workload ? parseInt(workload, 10) : 0,
        };
    }

    if (workload === undefined) {
        tempBody = {
            additionalHours: additionalHours
                ? parseInt(additionalHours, 10)
                : 0,
        };
    }

    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
        "x-project-id": projectId,
        "x-hour-control-id": hourControlId,
    };

    if (!userId) delete tempHeaders["x-user-id"];
    if (!projectId) delete tempHeaders["x-project-id"];
    if (!hourControlId) delete tempHeaders["x-hour-control-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/project/update`,
        method: "put",
        headers: tempHeaders,
        body: tempBody,
    });

    const { body, statusCode } = httpResponse;

    let error: Errors = body?.message?.name?.[0] || body?.message;

    if (body?.message?.info) error = body?.message?.info;

    const errorsMapper = {
        "total projects workload is bigger than hours limit":
            "Horas previstas maior do que o limite do profissional",
        "You don't have permission to access this resource": "Acesso negado!",
        "user does not have a report":
            "O relatório do usuário no mês vigente não existe",
        "total project workload is bigger than estimated hours": `As horas estimadas do projeto (${body?.message?.projectEstimatedHours}h) são menores do que o total somado dos colaboradores (${body?.message?.totalProjectWorkload}h)`,
    };

    const GENERIC_ERROR_MESSAGE = "Campos obrigatórios não preenchidos";
    const errorFeedBacks = {
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        401: "Acesso negado!",
        404: "Rota não encontrada!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
