import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

import { Icons, theme } from "../../../../assets";
import IlustracaoTelaVazia from "../../../../assets/images/ilustracao-tela-vazia";
import { PopoverCalendar } from "../../../../components";
import { useUser } from "../../../../context";
import { TProject } from "../../../../models";
import { TUseFetch } from "../../../../types/TUseFetch";
import { ModalCreate } from "./components/modal-create/modal-create";
import { Report } from "./components/report/report";
import * as s from "./styled-status-report";

dayjs.extend(utc);

type ProjectStatusReport = {
    id_e: string;
    description: string;
    created_at: string;
    end_at: string;
    start_at: string;
    User: {
        name: string;
    };
};

type TStatusReportParams = {
    project: TProject;
    getProject: TUseFetch;
};

export const StatusReport = ({ project, getProject }: TStatusReportParams) => {
    const { verifyPermission } = useUser();

    const haveManageProjectPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const [open, setOpen] = useState(false);
    const [statusReports, setStatusReports] = useState<ProjectStatusReport[]>(
        []
    );

    const filterByPeriod = (period: DateRange | undefined) => {
        const filtered =
            getProject.response?.projects?.[0]?.ProjectStatusReport?.filter(
                (report: ProjectStatusReport) => {
                    if (!period?.from || !period.to) return true;

                    return (
                        dayjs(report.start_at).add(3, "hour").toDate() >=
                            period.from &&
                        dayjs(report.end_at).add(3, "hour").toDate() <=
                            period.to
                    );
                }
            );
        setStatusReports(filtered);
    };

    const renderAddButton = () => {
        return (
            <s.AddButton
                disabled={!haveManageProjectPermission}
                onClick={() => {
                    setOpen(true);
                }}
                icon={<Icons.Plus color={theme.white} />}
            >
                adicionar
            </s.AddButton>
        );
    };

    useEffect(() => {
        if (getProject.response) {
            filterByPeriod({
                from: dayjs.utc().startOf("month").toDate(),
                to: dayjs.utc().endOf("month").toDate(),
            });
        }
    }, [getProject.response]);

    useEffect(() => {
        if (open === false && project?.id_e) {
            getProject.onRefresh({
                projectId: project.id_e,
                users: true,
                all: true,
                reviewers: true,
                sectors: true,
            });
        }
    }, [open]);

    return (
        <>
            {open && <ModalCreate setIsOpenModal={setOpen} project={project} />}
            <s.Container>
                <s.Header>
                    <PopoverCalendar getPeriod={filterByPeriod} />
                    {Boolean(statusReports?.length) && renderAddButton()}
                </s.Header>
                {statusReports?.length ? (
                    <>
                        <s.Content>
                            {statusReports?.map((report) => (
                                <Report
                                    key={report.id_e}
                                    statusReport={report}
                                />
                            ))}
                        </s.Content>
                    </>
                ) : (
                    <s.NoData>
                        <IlustracaoTelaVazia />
                        <h3>Nenhum Status Report criado</h3>
                        <p>
                            Adicione um status report clicando no botão abaixo
                        </p>
                        {renderAddButton()}
                    </s.NoData>
                )}
            </s.Container>
        </>
    );
};
