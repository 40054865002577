import styled from "styled-components";

export const Container = styled.div``;

export const Title = styled.h2`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Tooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    max-width: 240px;
`;

export const MasterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px;
    border: 1px solid ${({ theme }) => theme.gray100};
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    margin-top: 16px;
    max-width: 50%;
    margin-bottom: 32px;

    div {
        span {
            color: ${({ theme }) => theme.gray400};
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }
    }
`;

export const AdministratorContainer = styled.div`
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.gray100};
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    margin-top: 16px;
    max-width: 50%;
    margin-bottom: 32px;

    div {
        span {
            color: ${({ theme }) => theme.gray400};
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }
    }
`;

export const Actions = styled.div``;

export const Action = styled.div`
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }
`;

export const HistoryContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
`;

export const HistoryRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;

    &:first-child {
        margin-bottom: 5px;
    }
`;
