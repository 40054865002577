import { Icons, theme } from "../../../../../../assets";
import { Tooltip } from "../../../../../../components";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

type PresentUsersProps = {
    data: TBackOfficeData;
};

export const PresentUsers = ({ data }: PresentUsersProps) => {
    return (
        <>
            <Tooltip
                side="top"
                align="start"
                positiony={10}
                positionx={24}
                arrow
                positionArrow={76.5}
                content={
                    <s.TooltipContent>
                        Usuários que fizeram o login na plataforma nos{" "}
                        <strong>últimos 15 dias</strong>
                    </s.TooltipContent>
                }
            >
                <generalStyles.Title>
                    <Icons.Checked color={theme.purple500} />
                    Usuários presentes - {data.recentActiveUsers.length}
                    <Icons.Info
                        color={theme.purple500}
                        width="18px"
                        height="18px"
                    />
                </generalStyles.Title>
            </Tooltip>
            <details>
                <summary>Ver usuários</summary>
                <generalStyles.Row>
                    {data.recentActiveUsers.length > 0 ? (
                        <generalStyles.Content>
                            {data.recentActiveUsers.map(({ name, email }) => (
                                <>
                                    <span key={email}>
                                        <div>{name}</div>
                                        <div>{email}</div>
                                    </span>
                                    <generalStyles.Divider />
                                </>
                            ))}
                        </generalStyles.Content>
                    ) : (
                        <generalStyles.NoData>
                            <p>Nenhuma usuário presente</p>
                        </generalStyles.NoData>
                    )}
                </generalStyles.Row>
            </details>
            <Tooltip
                side="top"
                align="start"
                positiony={10}
                positionx={32}
                arrow
                positionArrow={76.5}
                content={
                    <s.TooltipContent>
                        Usuários que <strong>não</strong> fizeram o login na
                        plataforma nos <strong>últimos 15 dias</strong>
                    </s.TooltipContent>
                }
            >
                <generalStyles.Title>
                    <Icons.XSolid color={theme.purple500} />
                    Usuários ausentes - {data.recentInactiveUsers.length}
                    <Icons.Info
                        color={theme.purple500}
                        width="18px"
                        height="18px"
                    />
                </generalStyles.Title>
            </Tooltip>
            <details>
                <summary>Ver usuários</summary>
                <generalStyles.Row>
                    {data.recentInactiveUsers.length > 0 ? (
                        <generalStyles.Content>
                            {data.recentInactiveUsers.map(({ name, email }) => (
                                <>
                                    <span key={email}>
                                        <div>{name}</div>
                                        <div>{email}</div>
                                    </span>
                                    <generalStyles.Divider />
                                </>
                            ))}
                        </generalStyles.Content>
                    ) : (
                        <generalStyles.NoData>
                            <p>Nenhuma usuário ausente eba :D</p>
                        </generalStyles.NoData>
                    )}
                </generalStyles.Row>
            </details>
        </>
    );
};
