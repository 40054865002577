import { useEffect, useState } from "react";

import { PopoverRadix, Tag } from "../../..";
import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { THourControl } from "../../../../models";
import * as s from "./styled-popover-project";

export type TProjectList = {
    color: string;
    name: string;
    id_e: string;
    Sectors: { id_e: string; name: string }[];
    selectedSector?: string;
};

type TPopoverProject = {
    trigger: JSX.Element;
    projectList: TProjectList[];
    onSelect?: (e: TProjectList) => void;
    side?: "bottom" | "top" | "right" | "left";
    align?: "end" | "start" | "center";
    sideOffset?: number;
    alignOffset?: number;
    disabled?: boolean;
    hourControlData?: THourControl[];
};

export default ({
    trigger,
    projectList,
    onSelect,
    side,
    align,
    sideOffset,
    alignOffset,
    disabled,
    hourControlData,
}: TPopoverProject) => {
    const [inputData, setInputData] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const { favoriteProject } = useUser();

    const organizeBySector = (hasFilter?: boolean) => {
        let sectors: {
            sectorName: string;
            sectorId: string;
            projects: {
                color: string;
                name: string;
                id_e: string;
                Sectors: { id_e: string; name: string }[];
            }[];
        }[] = [];

        projectList.forEach((project) => {
            project.Sectors.forEach((sector) => {
                let hasSector = false;

                sectors.forEach((tempProject, index) => {
                    if (tempProject.sectorId === sector.id_e) {
                        hasSector = true;

                        sectors[index].projects.push(project);
                    }
                });

                if (!hasSector) {
                    sectors.push({
                        sectorName: sector.name,
                        sectorId: sector.id_e,
                        projects: [
                            {
                                ...project,
                                Sectors: [
                                    { id_e: sector.id_e, name: sector.name },
                                ],
                            },
                        ],
                    });
                }
            });
        });

        if (hasFilter) {
            const tempSectors: {
                sectorName: string;
                sectorId: string;
                projects: {
                    color: string;
                    name: string;
                    id_e: string;
                    Sectors: { id_e: string; name: string }[];
                }[];
            }[] = [];

            sectors.forEach((sector) => {
                const tempProjects = sector.projects.filter((project) =>
                    project.name.toLowerCase().includes(inputData.toLowerCase())
                );

                if (tempProjects.length > 0) {
                    tempSectors.push({ ...sector, projects: tempProjects });
                }
            });

            sectors = tempSectors;
        }

        return sectors;
    };

    const [projectsBySectors, setProjectsBySectors] = useState(
        organizeBySector()
    );

    useEffect(() => {
        if (!isOpen) setInputData("");
    }, [isOpen]);

    useEffect(() => {
        if (projectList.length) {
            setProjectsBySectors(organizeBySector(!!inputData.length));
        }
    }, [projectList, inputData]);

    const isTagEnabled = (project: TProjectList) => {
        const hourControl = hourControlData?.find(
            (data: THourControl) => data.Project.id_e === project.id_e
        );

        const reachedLimit = hourControl?.statistics?.pendingHours === 0;

        if (hourControl && hourControl.locked && reachedLimit) {
            return false;
        }

        return true;
    };

    return (
        <PopoverRadix
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            onOpenChange={(e) => setIsOpen(e)}
            avoidCollisions={true}
            trigger={
                <s.ContainerTrigger isOpen={isOpen}>
                    {trigger}
                </s.ContainerTrigger>
            }
            open={isOpen}
        >
            <s.Container>
                {!disabled && (
                    <>
                        <div id="projects-header">
                            <span id="popover-title">Projetos</span>
                            <div id="container-input">
                                <Icons.SearchOutline />
                                <s.Input
                                    onChange={(e) => {
                                        setInputData(e.target.value);
                                    }}
                                    placeholder="Busque por projetos"
                                />
                            </div>
                        </div>
                        <div id="projects-body">
                            {projectsBySectors.map(
                                (sector) =>
                                    sector.projects.length > 0 && (
                                        <div
                                            className="container-tags"
                                            key={sector.sectorId}
                                        >
                                            <s.SubTitle>
                                                {sector.sectorName}
                                            </s.SubTitle>

                                            {sector.projects.map((project) => (
                                                <div
                                                    className="tag"
                                                    key={project.id_e}
                                                    onClick={() =>
                                                        isTagEnabled(project)
                                                            ? onSelect?.({
                                                                  ...project,
                                                                  selectedSector:
                                                                      sector.sectorId,
                                                              })
                                                            : ""
                                                    }
                                                >
                                                    <Tag
                                                        isFavorite={
                                                            favoriteProject?.id_e ===
                                                            project.id_e
                                                        }
                                                        color={
                                                            isTagEnabled(
                                                                project
                                                            )
                                                                ? project.color
                                                                : theme.gray200
                                                        }
                                                    >
                                                        {project.name}
                                                    </Tag>
                                                </div>
                                            ))}
                                        </div>
                                    )
                            )}
                            {projectsBySectors.length === 0 && (
                                <s.NotFound>
                                    <span id="error-title">
                                        Nenhum projeto encontrado
                                    </span>
                                    <span id="suggestion">
                                        Refaça sua busca ou entre em contato com
                                        gestor
                                    </span>
                                </s.NotFound>
                            )}
                        </div>
                    </>
                )}
            </s.Container>
        </PopoverRadix>
    );
};
