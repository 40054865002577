import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../../../components";
import { activities } from "../../../../../../../../../services";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { AddCollaboratorsModal } from "../../../../../modals/add-collaborators/add-collaborators-modal";
import { TSectionActivity } from "../../../../../types";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TAddSubItem = {
    activity: ActivityDTO;
    checklist: ActivityDTO["ActivityChecklist"][0];
    setCreateSubItem: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddSubItem = ({
    activity,
    setCreateSubItem,
    checklist,
}: TAddSubItem) => {
    const [title, setTitle] = useState("");
    const [users, setUsers] = useState<string[]>([]);

    const activityToAddCollaborators = {
        ...activity,
        Users: users.map((user) => ({
            User: {
                id_e: user,
            },
        })),
    } as unknown as TSectionActivity;

    const [activityState, setActivityState] = useState<
        TSectionActivity | undefined
    >();

    const { state } = useLocation();

    const { mutate: createSubItemMutation } = useMutation({
        mutationFn: () =>
            activities.createSubItem({
                activityId: activity.id_e,
                title,
                checklistId: checklist.id_e,
                users,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
            setTitle("");
            setUsers([]);
        },
    });

    return (
        <>
            {activityState && (
                <AddCollaboratorsModal
                    activity={activityState}
                    project={state?.project}
                    onSuccess={(data) => {
                        setUsers(data.users);
                        setActivityState(undefined);
                    }}
                    setActivity={setActivityState}
                />
            )}
            <s.Container
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        createSubItemMutation();
                    }
                }}
            >
                <s.Content>
                    <Input
                        mask=""
                        placeholder="Adicione um item"
                        value={title}
                        ref={(input) => input && input.focus()}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <s.UserAddButton
                        onClick={() =>
                            setActivityState(activityToAddCollaborators)
                        }
                    >
                        <Icons.UserAdd color={theme.gray800} />
                    </s.UserAddButton>
                    {users.length > 0 && (
                        <s.UsersLength>+{users.length}</s.UsersLength>
                    )}
                </s.Content>
                <s.Buttons>
                    <SecondaryButton onClick={() => setCreateSubItem(false)}>
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={createSubItemMutation}
                        disabled={!title}
                    >
                        ADICIONAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </>
    );
};
