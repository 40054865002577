import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TGetPermissions = {
    roleId?: string;
};

export default async ({ roleId }: TGetPermissions = {}) => {
    const headers = { auth: true, "x-role-id": roleId };

    if (!roleId) delete headers["x-role-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/permissions/read`,
        method: "get",
        headers,
    });

    const { body, statusCode } = httpResponse;
    const errorFeedBacks = {
        404: "Sem permissões!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
