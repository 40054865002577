const isString = (value: any) => typeof value === "string";

export const findErrorString = <T = any>(body: any): T => {
    if (isString(body?.message)) return body?.message;
    if (isString(body?.message?.name?.[0])) return body?.message?.name?.[0];
    if (isString(body?.message?.info)) return body?.message?.info;
    if (isString(body?.message?.error)) return body?.message?.error;
    if (isString(body?.message?.["x-activity-id"]?.[0]))
        return body?.message?.["x-activity-id"]?.[0];

    return "" as T;
};
