import * as yup from "yup";

export default yup.object().shape({
    name: yup.string().required("Insira seu nome"),
    email: yup
        .string()
        .required("Insira seu e-mail")
        .email("Insira um e-mail válido"),
    celphone: yup
        .string()
        .transform((value, originalValue) =>
            originalValue === "" ? null : value
        )
        .matches(/^\d{2} \(\d{2}\) \d{5}-\d{4}$/, "Número inválido")
        .nullable(),
    numberOfUsers: yup.string().required("Insira o número de usuários"),
    message: yup.string(),
});
