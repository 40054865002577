import * as s from "./styled-loading-gray";

type props = {
    height?: string;
    scale?: string;
};

const LoadingGray = ({ height, scale }: props) => {
    return (
        <s.ContainerLoader>
            <s.Loading height={height} scale={scale}>
                <span className="border">
                    <span className="line"></span>
                    <span className="circle"></span>
                </span>
            </s.Loading>
        </s.ContainerLoader>
    );
};

export default LoadingGray;
