import styled from "styled-components";

export const Container = styled.div`
    padding: 16px;
    width: 472px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.orangeWarning};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
        &:first-child {
            max-width: 120px;
        }

        &:last-child {
            max-width: 243px;
        }
    }
`;
