import styled from "styled-components";

type TContainerTrigger = {
    isOpen?: boolean;
};

export const Container = styled.div<TContainerTrigger>`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px rgba(14, 2, 26, 0.2);
    border-radius: 4px;
    background: ${({ theme }) => theme.white};
    width: 300px;
    padding: 16px 0;

    div#projects-body {
        overflow-y: auto;
        max-height: 15rem;
    }

    span#popover-title {
        align-self: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 0.875rem;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.purple400};
        margin-left: 16px;
        margin-bottom: 8px;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    div#container-input {
        display: flex;
        align-items: center;
        position: relative;
        padding: 8px 16px;

        svg.icon-search-outline {
            position: absolute;
            left: 24px;
        }
    }

    div.container-tags {
        display: flex;
        flex-direction: column;

        .tag {
            border-radius: 4px;
            cursor: pointer;
            background: ${({ theme }) => theme.white};
            padding: 8px 16px;

            &:hover {
                background: ${({ theme }) => theme.gray50};
                transition: 0.3s linear;
            }
            span {
                width: 100%;
                text-align: left;
            }
        }
    }
`;

export const ContainerTrigger = styled.div<TContainerTrigger>`
    svg {
        path {
            stroke: ${({ isOpen, theme }) => isOpen && theme.pink400};
        }
    }
    &:hover {
        svg {
            path {
                stroke: ${({ theme }) => theme.pink400};
            }
        }
    }
`;

export const Input = styled.input`
    width: 100%;
    height: 2.5rem;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    padding-left: 36px;
    color: ${({ theme }) => theme.gray600};

    @media (max-width: 768px) {
        font-size: 10px;
    }

    &::placeholder {
        color: ${({ theme }) => theme.gray400};
    }
`;

export const SubTitle = styled.p`
    align-self: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.015em;
    color: ${({ theme }) => theme.gray400};
    margin: 16px 16px 8px;

    @media (max-width: 768px) {
        font-size: 11px;
    }
`;

export const NotFound = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0;
    text-align: start;
    gap: 8px;

    span#error-title {
        font-size: 1rem;
        color: ${({ theme }) => theme.purple600};
        font-weight: 500;
    }

    span#suggestion {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray400};
        font-weight: 400;
    }
`;
