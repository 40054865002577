import { ActivityDTO } from "../../../../../../types/activityDTO";
import * as s from "./styles";

type TProgressBar = {
    checklist: ActivityDTO["ActivityChecklist"][0];
};

export const ProgressBar = ({ checklist }: TProgressBar) => {
    const calculateProgress = (): number => {
        if (!checklist?.subtasks?.length) return 0;
        const total = checklist.subtasks?.length;
        const completed = checklist.subtasks?.filter(
            (item) => item.finished_at
        ).length;
        return Math.round((completed / total) * 100);
    };

    return (
        <s.ProgressContainer>
            {calculateProgress()}%
            <s.ProgressBar>
                <s.Progress percentage={calculateProgress()} />
            </s.ProgressBar>
        </s.ProgressContainer>
    );
};
