import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { PrimaryButton, SecondaryButton } from "../../../../../components";
import ContainerModal from "../../../../../components/molecules/modals/container-modal";
import * as s from "./styled-cancel-confirmation-modal";

dayjs.extend(utc);
dayjs.extend(timezone);

type TCancelConfirmationModal = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickContinue: () => void;
    plan: string;
    end_date: string;
};

export const CancelConfirmationModal = ({
    setOpen,
    onClickContinue,
    plan,
    end_date,
}: TCancelConfirmationModal) => {
    const endDay = dayjs.utc(end_date).format("DD");
    const inactiveDay = dayjs.utc(end_date).add(1, "day").format("DD");
    const monthInText = dayjs.utc(end_date).format("MMMM");
    const planName = plan.charAt(0).toUpperCase() + plan.slice(1);

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Cancelar o seu plano?</s.Title>
                <s.Description>
                    <p>
                        Ao cancelar o Plano {planName} agora, você poderá
                        continuar utilizando-o até o dia {endDay} de{" "}
                        {monthInText}, data em que se encerra o pagamento já
                        efetuado do mês vigente.
                    </p>
                    <br />
                    <p>
                        A partir de {inactiveDay} de {monthInText}, todos
                        usuários vinculados à organização perderão acesso à
                        plataforma até que um novo plano seja comprado.
                    </p>
                </s.Description>
                <s.ButtonsContainer>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        VOLTAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={onClickContinue}
                        style={{
                            backgroundColor: "#D32F2F",
                            border: "none",
                            boxShadow: "none",
                        }}
                    >
                        QUERO CANCELAR O PLANO
                    </PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};
