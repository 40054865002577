import React, { useState } from "react";
import Select from "react-select";

import { Icons } from "../../../../../../assets";
import { TOption, TProject } from "../../../../../../models";
import * as s from "./styles";

type Props = {
    project: TProject;
    selectedTechnologies: TOption[];
    setSelectedTechnologies: React.Dispatch<React.SetStateAction<TOption[]>>;
};

export function SelectTechnologies({
    project,
    selectedTechnologies,
    setSelectedTechnologies,
}: Props) {
    const [technologiesInput, setTechnologiesInput] = useState<string>("");

    const extractUsersSkills = (users: TProject["Users"]): TOption[] => {
        const userSkills: TOption[] = [];
        users?.forEach((user) => {
            user.UserSkill?.forEach((skill) => {
                userSkills.push({
                    label: skill.name,
                    value: skill.name,
                });
            });
        });
        return userSkills;
    };

    const projectUsersSkillsOptions = extractUsersSkills(project?.Users || []);

    return (
        <Select
            isMulti
            options={projectUsersSkillsOptions}
            components={{
                Option: (props) => {
                    return (
                        <s.Option {...props.innerProps}>
                            <span className="label">
                                <Icons.UserGroup width="22px" />
                                {props.data.label}
                            </span>
                            <span
                                style={{
                                    fontSize: "0.8rem",
                                    color: "gray",
                                }}
                            >
                                (usuário)
                            </span>
                        </s.Option>
                    );
                },
            }}
            onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " " || e.key === "Tab") {
                    e.preventDefault();
                    if (technologiesInput.trim()) {
                        setSelectedTechnologies([
                            ...selectedTechnologies,
                            {
                                label: technologiesInput,
                                value: technologiesInput,
                            },
                        ]);

                        setTechnologiesInput("");
                    }
                }
            }}
            value={selectedTechnologies}
            inputValue={technologiesInput}
            onInputChange={(value) => {
                setTechnologiesInput(value);
            }}
            onChange={(options) => {
                setSelectedTechnologies(options as TOption[]);
            }}
            menuIsOpen={!projectUsersSkillsOptions.length ? false : undefined}
            placeholder="Escreva as tecnologias"
            noOptionsMessage={() => "Nenhuma tecnologia encontrada"}
        />
    );
}
