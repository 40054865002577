import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import * as s from "./styled-collaborators-options";

const CollaboratorsOptions = () => {
    const { verifyPermission } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const accessInvite = verifyPermission({
        id: "professionals",
        rule: "manage",
    });

    return (
        <s.Container>
            <s.UpperOptions>
                <s.Options>
                    <div
                        className="option"
                        onClick={() => navigate("/profissionais")}
                    >
                        <Icons.UserGroup
                            color={
                                location.pathname === "/profissionais"
                                    ? theme.purple600
                                    : theme.gray400
                            }
                            title="Setores"
                        />

                        <s.OptionText
                            active={location.pathname === "/profissionais"}
                        >
                            PROFISSIONAIS CADASTRADOS
                        </s.OptionText>
                    </div>
                    {accessInvite && (
                        <div
                            className="option"
                            onClick={() => navigate("/profissionais/convites")}
                        >
                            <Icons.UserAdd
                                color={
                                    location.pathname ===
                                    "/profissionais/convites"
                                        ? theme.purple600
                                        : theme.gray400
                                }
                                title="Atuações"
                            />

                            <s.OptionText
                                active={
                                    location.pathname ===
                                    "/profissionais/convites"
                                }
                            >
                                CONVITES
                            </s.OptionText>
                        </div>
                    )}
                </s.Options>
            </s.UpperOptions>
        </s.Container>
    );
};

export default CollaboratorsOptions;
