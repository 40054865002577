import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 21rem;
    padding-bottom: 2rem;
    position: absolute;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: calc(24px + 1.5rem);
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .resume {
        padding: 1.5rem 1.5rem 1.5rem 2.5rem;
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 0px 0px 4px 4px;
        background-color: ${({ theme }) => theme.white2};
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            align-self: flex-start;
        }
    }

    .resume {
        img {
            border-radius: 50%;
            width: 180px;
            height: 180px;
            object-fit: cover;
            aspect-ratio: 1/1;
            margin-right: 1rem;
        }

        .resume-data,
        .resume-projects {
            height: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .resume-data {
            gap: 1.5rem;
            max-width: 400px;
            width: 100%;

            li {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1rem;
                color: ${({ theme }) => theme.gray400};
                list-style: none;
            }

            span {
                margin-left: 0.5rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: ${({ theme }) => theme.gray900};
            }
        }

        .resume-projects {
            max-width: 400px;
            width: 100%;
            flex-direction: row;
            align-self: flex-start;
            justify-content: flex-start;
            gap: 8px;

            h2 {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1rem;
                color: ${({ theme }) => theme.gray400};
            }

            ul {
                display: flex;
                row-gap: 4px;
                column-gap: 8px;
                flex-wrap: wrap;
                flex-direction: column;
                font-size: 0.75rem;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.25rem;
                width: 7.5625rem;
                height: 1.75rem;
                background-color: ${({ theme }) => theme.purple50};
                border: 1px solid ${({ theme }) => theme.purple100};
                border-radius: 4px;
            }

            span {
                width: 95%;
                text-align: left;
                justify-content: flex-start;
            }
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;
