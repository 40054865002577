/* eslint-disable func-names */
import dayjs from "dayjs";
import * as yup from "yup";

import { formatUSDateToBrazilianDate } from "../../../../../../utils";

export default yup.object().shape({
    startAt: yup
        .string()
        .required("Campo obrigatório")
        .min(10, "Data inválida")
        .test("startAt", "Data inválida", function (value) {
            if (!value) return true;
            const formattedDate = formatUSDateToBrazilianDate(value);
            const isNotAndAbsurdDate = dayjs(formattedDate).isBefore(
                dayjs().add(5, "years")
            );
            return dayjs(formattedDate).isValid() && isNotAndAbsurdDate;
        })
        .test(
            "startAt",
            "Deve ser menor ou igual a data final",
            function (value) {
                if (!value) return true;
                const formattedDate = formatUSDateToBrazilianDate(value);
                const { endAt } = this.parent;
                const endAtFormatted = formatUSDateToBrazilianDate(endAt);
                if (!endAt) return true;
                return new Date(formattedDate) <= new Date(endAtFormatted);
            }
        ),
    endAt: yup
        .string()
        .required("Campo obrigatório")
        .min(10, "Data inválida")
        .test("endAt", "Data inválida", function (value) {
            if (!value) return true;
            const formattedDate = formatUSDateToBrazilianDate(value);
            const isNotAndAbsurdDate = dayjs(formattedDate).isBefore(
                dayjs().add(5, "years")
            );
            return dayjs(formattedDate).isValid() && isNotAndAbsurdDate;
        })
        .test(
            "endAt",
            "Deve ser maior ou igual a data inicial",
            function (value) {
                if (!value) return true;
                const formattedDate = formatUSDateToBrazilianDate(value);
                const { startAt } = this.parent;
                const startAtFormatted = formatUSDateToBrazilianDate(startAt);
                if (!startAt) return true;
                return new Date(formattedDate) >= new Date(startAtFormatted);
            }
        ),
    description: yup
        .string()
        .required("Campo obrigatório")
        .max(500, "Máximo de 500 caracteres"),
});
