import * as yup from "yup";

import { validationErrors } from "../../utils";

const { email, password } = validationErrors;

export default () =>
    yup.object().shape({
        email: yup
            .string()
            .trim()
            .email(email.invalid)
            .required(email.required),
        password: yup.string().required(password.required),
    });
