import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import {
    Input,
    PrimaryButton,
    SecondaryButton,
    TextArea,
} from "../../../../../../../components";
import { LabelWrapper } from "../../../../../../../components/molecules/label-wrapper/label-wrapper";
import ContainerModal from "../../../../../../../components/molecules/modals/container-modal";
import { Switch } from "../../../../../../../components/molecules/switch/switch";
import { costStringToNumber, formatCurrency } from "../../../utils";
import { CreateEditBenefitSaveParams } from "../../tabs/team/team";
import * as s from "./styles";

const schema = yup.object().shape({
    value: yup
        .string()
        .required("Obrigatório")
        .test("min cost", "Maior que 1", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue > 1;
        })
        .test("max cost", "Menor que 999.999", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue < 999999;
        })
        .typeError("Deve ser um número válido"),
    comment: yup
        .string()
        .required("Obrigatório")
        .max(200, "Máximo de 200 caracteres")
        .min(3, "Mínimo de 3 caracteres"),
    includeToBilling: yup.boolean(),
});

type ModalUpsertBenefitProps = {
    defaultValues?: CreateEditBenefitSaveParams | undefined;
    onSave: (params: CreateEditBenefitSaveParams) => void;
    onCancel: () => void;
};

export const ModalUpsertBenefit = ({
    defaultValues,
    onCancel,
    onSave,
}: ModalUpsertBenefitProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<CreateEditBenefitSaveParams>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    return (
        <ContainerModal>
            <s.Container
                onSubmit={handleSubmit(onSave)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit(onSave)();
                    }
                    if (e.key === "Escape") {
                        onCancel();
                    }
                }}
            >
                <s.Title>Benefícios</s.Title>
                <s.Fields>
                    <Controller
                        name="value"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                value={value}
                                onChange={(e) =>
                                    onChange(formatCurrency(e.target.value))
                                }
                                error={errors.value?.message}
                                label="Benefício"
                            />
                        )}
                    />
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextArea
                                onChange={onChange}
                                value={value}
                                error={errors.comment?.message}
                                label="Observações:"
                                counter={value}
                                maxLength={200}
                                charCount
                            />
                        )}
                    />
                    <Controller
                        name="includeToBilling"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <LabelWrapper
                                label="Incluir no faturamento"
                                tooltip="Ao habilitar, o benefício será incluído no faturamento do profissional. Será inserido no campo de 'Adicionais' no faturamento."
                            >
                                <Switch
                                    checked={value}
                                    onChange={onChange}
                                    scale={0.8}
                                />
                            </LabelWrapper>
                        )}
                    />
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton onClick={onCancel}>
                        cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit">salvar</PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
