import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    width: 536px;
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;

    strong {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
    }

    margin-top: 1rem;
    margin-bottom: 3.875rem;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
`;
