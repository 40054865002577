import styled from "styled-components";

export const Container = styled.form`
    background-color: ${({ theme }) => theme.white};
    padding: 16px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    border-radius: 4px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.h1`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
        &:first-child {
            width: 113px;
        }

        width: 150px;
    }
`;
