import { encryptObjectToBase64 } from "../../utils/encrypt/encrypt-to-base-64";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Error = "alguma coisa";

const { REACT_APP_BASE_URL } = process.env;

type TCreateSubscription = {
    cardNumber?: string;
    cardCVV?: string;
    cardExpirationMonth?: string;
    cardExpirationYear?: string;
    cardName?: string;
    numberOfInstallments?: string;
    contractorName?: string;
    planType?: "monthly" | "yearly";
    plan?: "lite" | "starter" | "standard";
    organizationId?: string;
};

export default async ({
    cardCVV,
    cardExpirationMonth,
    cardExpirationYear,
    cardName,
    cardNumber,
    contractorName,
    numberOfInstallments,
    planType,
    plan,
    organizationId,
}: TCreateSubscription = {}) => {
    const tempHeaders = {
        auth: true,
    };

    let creditCardEncrypted;

    if (organizationId) {
        organizationId = organizationId.slice(0, 32);
        creditCardEncrypted = await encryptObjectToBase64(
            {
                number: cardNumber,
                exp_month: cardExpirationMonth,
                exp_year: cardExpirationYear,
                cvc: cardCVV,
                holder_name: cardName,
            },
            organizationId
        );
    }

    const bodyParams = {
        trial: false,
        creditCard: creditCardEncrypted,
        planType,
        installments: numberOfInstallments || 1,
        plan: plan?.toLocaleLowerCase(),
        contractorName,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/subscriptions/create`,
        method: "post",
        headers: tempHeaders,
        body: bodyParams,
    });
    const { body, statusCode } = httpResponse;

    const error: Error =
        body?.message?.info ||
        body?.message?.name?.[0] ||
        body?.message?.name?.[0] ||
        body?.message;

    const errorsMapper = {
        "alguma coisa": "mensagem para aparecer no toast",
        "something wrong with payment": "Algo deu errado com o pagamento",
    };

    const permissionErrorMapper = {
        "alguma coisa": "mensagem para aparecer no toast",
        "trial period ended": "Seu período de teste acabou!",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Erro!",
        403: permissionErrorMapper[error] || "Erro!",
        500:
            errorsMapper[error] ||
            "Ocorreu um erro na hora do pagamento. Entre em contato com o suporte.",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
