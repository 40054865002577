import toast from "react-hot-toast";

export const validatePermittedStrings = (value: string) => {
    const noPermittedWords = [
        "<Iframe />",
        "<Iframe/>",
        "<Iframe /",
        "<Iframe/",
        "localhost",
    ];
    const wordProblems: string[] = [];
    noPermittedWords.forEach((word) => {
        const stringValidatorRegex = new RegExp(word, "i");
        const hasDescriptionNoPermittedWords = stringValidatorRegex.test(value);
        if (hasDescriptionNoPermittedWords) {
            wordProblems.push(word);
        }
    });
    const hasWordProblems = wordProblems.length > 0;
    if (hasWordProblems) {
        toast.error(
            `A descrição provida contém palavras não permitidas: "${wordProblems[0]}"`
        );
        return false;
    }
    return true;
};
