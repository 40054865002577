import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-dot-outline"}
    >
        <title>{title}</title>
        <circle cx="5" cy="5" r="4.5" stroke={color || theme.purple500} />
        <circle
            cx="5.00043"
            cy="5.00043"
            r="2.77778"
            fill={color || theme.purple500}
        />
    </svg>
);
