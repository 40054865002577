import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px;
    gap: 40px;

    @media (max-width: 1000px) {
        width: 300px;
    }

    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.375rem;
        letter-spacing: 0.0015em;
        color: ${({ theme }) => theme.purple500};
    }

    div#buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            width: 200px;

            @media (max-width: 1000px) {
                width: 120px;
                height: 40px;
                font-size: 10px;
            }
        }
    }
`;
