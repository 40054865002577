import styled from "styled-components";

type TContainer = {
    $loading?: boolean;
};

type TSection = {
    invert?: boolean;
    center?: boolean;
};

type TFlexColumn = {
    gap?: string;
};

export const Container = styled.div<TContainer>`
    display: ${({ $loading }) => ($loading ? "flex" : "grid")};
    top: 0;
    width: 100%;
    height: 100vh;
    grid-template-columns: 1fr 2fr;
    gap: 8rem;
    min-height: ${({ $loading }) => ($loading ? "100vh" : "unset")};
    overflow-y: auto;
    background-color: ${({ theme }) => theme.white};

    @media (max-width: 700px) {
        display: flex;
        place-items: center;
        justify-content: center;
    }
`;

export const Section = styled.section<TSection>`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    width: 100%;
    padding: 7.5rem 0;
    height: 100%;
    ${({ invert }) => invert && `grid-row: 1;`};
    ${({ center }) =>
        center && `justify-self: center; justify-content: center;`};

    h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 2.125rem;
        line-height: 2.9375rem;
        letter-spacing: 0.0025em;
        color: ${({ theme }) => theme.purple500};
    }

    span#line {
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.gray50};
        margin: 40px 0;
    }

    span#question {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.purple300};
        margin-bottom: 1rem;
    }

    p#invite-description {
        color: ${({ theme }) => theme.gray600};
    }

    div#invite-concluded {
        display: flex;
        flex-direction: column;
        max-width: 400px;

        button.redirect {
            margin-top: 72px;
        }
    }

    @media (max-width: 700px) {
        order: 1;
    }
`;

export const FlexRow = styled.div`
    display: flex;
`;

export const FlexColumn = styled(FlexRow)<TFlexColumn>`
    flex-direction: column;
    position: relative;
    max-width: 80%;
    gap: ${({ gap }) => gap};
    align-items: center;
    justify-content: center;
`;

export const Img = styled.img`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    object-fit: cover;
    object-position: right;

    @media (max-width: 700px) {
        display: none;
    }
`;

export const Aviso = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.purple400};
    margin-bottom: 3rem;
    margin-top: 1.5rem;
`;
