import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type VerifyMassCreation = {
    docFile?: FormData;
};

export default async ({ docFile }: VerifyMassCreation = {}) => {
    const headers = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/mass/verify`,
        method: "post",
        body: docFile,
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Há erros na sua planilha! Corrija e tente novamente.",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
