import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const Users = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.UserGroup color={theme.purple500} />
                Usuários
            </generalStyles.Title>
            <generalStyles.Row>
                <s.Content>
                    <h1>Nº total de usuários</h1>
                    <p>
                        <span>{data.numberOfUsers}</span>
                    </p>
                </s.Content>
                <s.Content>
                    <h1>Nº de usuários ativos</h1>
                    <p>
                        <span>{data.numberOfActiveUsers}</span>
                    </p>
                </s.Content>
                <s.Content>
                    <h1>Nº de usuários inativos</h1>
                    <p>
                        <span>{data.numberOfInactiveUsers}</span>
                    </p>
                </s.Content>
                <s.Content>
                    <h1>
                        Nº de usuários criados por data{" "}
                        <Icons.Calendario color={theme.gray600} />
                    </h1>
                    <p>
                        <span>{data.numberOfNewUsersByDate}</span>
                    </p>
                </s.Content>
                <s.Content>
                    <h1>
                        Nº de usuários ativos criados por data{" "}
                        <Icons.Calendario color={theme.gray600} />
                    </h1>
                    <p>
                        <span>{data.numberOfNewActiveUsersByDate}</span>
                    </p>
                </s.Content>
            </generalStyles.Row>
        </s.Container>
    );
};
