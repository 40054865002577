import { styled } from "styled-components";

export const Container = styled.div`
    margin-top: 30px;
`;

export const Labels = styled.div<{ hide?: boolean }>`
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    height: 35px;
    max-width: 95%;
    display: ${({ hide }) => (hide ? "none" : "flex")};
`;

export const LegendContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
`;

export const Legend = styled.p`
    display: flex;
    gap: 7px;
    align-items: center;

    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const LegendIndicator = styled.div<{ color: string }>`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    ${({ color, theme }) => {
        if (color === theme.gray300) {
            return `
                border: 2px dashed ${theme.gray300};
                background-color: transparent;
            `;
        }
        return ``;
    }}
`;

export const Label = styled.div<{
    left?: number;
    hide?: boolean;
    offset?: number;
}>`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin-left: ${({ left }) => (left ? `${left}% ` : "fit-content")};
    display: ${({ hide }) => (hide ? "none" : "block")};
    position: absolute;
    left: ${({ offset }) => (offset ? `${offset}px` : "0px")};
`;

export const LineContainer = styled.div`
    height: 22px;
    position: relative;
    max-width: 95%;
`;

export const RealizedHours = styled.div<{ percentage?: number }>`
    position: absolute;
    transition: width 0.5s;

    border-radius: 4px;
    height: 22px;
    background-color: ${({ theme }) => theme.purple300};
    width: ${({ percentage }) => `${percentage}%` || `0px`};
    z-index: 4;
`;

export const EndProjection = styled.div<{ percentage?: number }>`
    position: absolute;
    transition: width 0.5s;

    border-radius: 4px;
    height: 22px;
    background-color: ${({ theme }) => theme.gray200};
    width: ${({ percentage }) => `${percentage}%` || `0px`};
    z-index: 3;
`;

export const DelayProjection = styled.div<{ percentage?: number }>`
    position: absolute;
    transition: width 0.5s;

    border-radius: 4px;
    height: 22px;
    width: ${({ percentage }) => `${percentage}%` || `0px`};
    background-color: ${({ theme }) => theme.red};
    z-index: 2;
`;

export const ExceededTime = styled.div<{ percentage?: number }>`
    position: absolute;
    transition: width 0.5s;

    border-radius: 4px;
    height: 22px;
    width: ${({ percentage }) => `${percentage}%` || `0px`};
    border: 3px dashed ${({ theme }) => theme.gray200};
    z-index: 1;
`;
