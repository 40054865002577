import { Control, Controller, FieldErrors } from "react-hook-form";

import { TDetailsEntry } from "../../../models";
import { EntryFields } from "../../../pages/track/components/card-container/entry";
import { getTimeFromIsoString } from "../../../utils";
import { Input } from "../../atoms";
import * as s from "./styled-card-edit-hours";

type TCardEditHoursProps = {
    task: TDetailsEntry;
    control?: Control<EntryFields>;
    errors?: FieldErrors<EntryFields>;
};

export const CardEditHours = ({
    task,
    control,
    errors,
}: TCardEditHoursProps) => {
    return (
        <s.Container key={task.id_e}>
            <section>
                <s.Title>Inicio</s.Title>
                <Controller
                    name="startTrackTime"
                    control={control}
                    defaultValue={getTimeFromIsoString(task.start_at)}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            className="input"
                            mask="99:99"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            error={errors?.startTrackTime?.message ? " " : ""}
                        />
                    )}
                />
            </section>
            <section>
                <s.Title>Fim</s.Title>
                <Controller
                    name="endTrackTime"
                    control={control}
                    defaultValue={getTimeFromIsoString(task.end_at)}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            className="input"
                            mask="99:99"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            error={errors?.endTrackTime?.message ? " " : ""}
                        />
                    )}
                />
            </section>
        </s.Container>
    );
};
