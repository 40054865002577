import { PrimaryButton, SecondaryButton } from "../../..";
import { TDefinePermissionsModalData } from "../../../../pages/organization/project/tab-collabs/collabs-edit";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-define-mass-permissions";

type TDefinePermissions = {
    data?: TDefinePermissionsModalData;
    isToRemovePermissions?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleConfirm: (type: "reviewer" | "biller") => void;
};

export const DefineMassPermissionsModal = ({
    data,
    isToRemovePermissions,
    setOpen,
    handleConfirm,
}: TDefinePermissions) => {
    const convertTypeToText = (type?: "reviewer" | "biller") => {
        return type === "reviewer" ? "revisor" : "faturador";
    };

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                {isToRemovePermissions ? (
                    <>
                        <s.Title>
                            Remover permissão em massa de{" "}
                            {convertTypeToText(data?.type)}
                        </s.Title>
                        <s.Description>
                            Esta ação removerá permissão em massa de{" "}
                            {convertTypeToText(data?.type)} para todos os
                            projetos do profissional.
                        </s.Description>
                        <s.Description>
                            Você tem certeza de que deseja remover
                            <strong> {data?.user.name}</strong> como{" "}
                            <strong>{convertTypeToText(data?.type)}</strong> de
                            todos os projetos?
                        </s.Description>
                    </>
                ) : (
                    <>
                        <s.Title>
                            Conceder permissão em massa de{" "}
                            {convertTypeToText(data?.type)}
                        </s.Title>
                        <s.Description>
                            Esta ação concederá permissão em massa de{" "}
                            {convertTypeToText(data?.type)} para todos os
                            projetos do profissional.
                        </s.Description>
                        <s.Description>
                            Você tem certeza de que deseja tornar
                            <strong> {data?.user.name}</strong> um{" "}
                            <strong>{convertTypeToText(data?.type)}</strong>{" "}
                            para todos os projetos?
                        </s.Description>
                    </>
                )}
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            if (!data) return;
                            handleConfirm(data.type);
                            setOpen(false);
                        }}
                    >
                        CONFIRMAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
