import { minutesToStringDate } from "../../../utils";
import * as s from "./styled-timeline-track";

type TTimelineTrack = {
    limitHours: number;
    totalTime: number;
};

type TTimeToWidth = {
    time: number;
    total: number;
};

export default ({ limitHours, totalTime }: TTimelineTrack) => {
    const getTotalTime = () => {
        return limitHours;
    };

    const timeToWidth = ({ time, total }: TTimeToWidth) => (time / total) * 100;

    const returnPendingHours = (limitHours: number, totalTime: number) => {
        if (limitHours - totalTime < 0) {
            return minutesToStringDate(0);
        }
        return minutesToStringDate(limitHours - totalTime);
    };

    return (
        <s.Container>
            <div id="container-infos">
                <div id="info-left">
                    <s.Paragraph>{minutesToStringDate(totalTime)}</s.Paragraph>
                    <s.GrayParagraph>Horas trabalhadas</s.GrayParagraph>
                </div>
                <div id="info-right">
                    <s.Paragraph>
                        {returnPendingHours(limitHours, totalTime)}
                    </s.Paragraph>
                    <s.GrayParagraph>Horas pendentes</s.GrayParagraph>
                </div>
            </div>
            <s.BarContainer
                percentage={timeToWidth({
                    time: totalTime,
                    total: getTotalTime(),
                })}
            >
                <s.Bar
                    percentage={timeToWidth({
                        time: limitHours,
                        total: getTotalTime(),
                    })}
                />
                <s.BarDelimiter id="delimiter" />
            </s.BarContainer>
        </s.Container>
    );
};
