import styled from "styled-components";

import { theme } from "../../assets";

type TTabSketches = {
    disabled?: boolean;
};

const templateColumns = "2.5fr 2.5fr 1fr 1fr 1.5fr 0.5fr 0.5fr 1fr 1.5fr 0.5fr";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;

    .profile-root {
        width: 100%;
        z-index: 0;
        overflow-x: clip;
    }

    .profile-tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 2.6875rem;
        background-color: ${({ theme }) => theme.purple50};
        white-space: nowrap;

        button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${({ theme }) => theme.gray600};
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px 4px 0px 0px;
        }

        [data-state="active"] {
            background-color: ${({ theme }) => theme.white};
            color: ${({ theme }) => theme.gray800};
            border-bottom: 0px;
        }
    }
`;

export const Details = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.background};
    gap: 2rem;

    form#filter {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1.5rem;

        button {
            padding: 1rem;
            width: fit-content;
            white-space: nowrap;
        }

        input {
            width: 100%;
            height: 50px;
        }
    }
`;

export const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 28rem;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;
    gap: 2rem;

    .invites-title {
        display: grid;
        grid-template-columns: ${templateColumns};
        align-items: center;
        height: 3rem;
        padding: 0 1.5rem;
        background-color: ${({ theme }) => theme.white};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.gray800};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;

        .filter-time {
            display: flex;
            align-items: center;
            gap: 0.3125rem;
        }
    }

    .invites-content {
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 0px 0px 4px 4px;
        min-height: 28rem;

        .content-row {
            display: grid;
            grid-template-columns: ${templateColumns};
            align-items: center;
            padding: 0 1.5rem;
            height: 3.5rem;
            background-color: ${({ theme }) => theme.white};

            border-bottom: 1px solid ${({ theme }) => theme.gray200};

            &:hover {
                background-color: ${({ theme }) => theme.gray50};
                span.trigger {
                    opacity: 1;
                }
            }

            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray600};
        }
    }

    .center {
        display: flex;
        justify-content: center;
    }

    .pagination {
        display: flex;
        justify-content: center;
    }

    span.trigger {
        display: flex;
        align-items: center;
        justify-content: right;
        padding-right: 10px;
        width: 100%;
        height: 40px;
        opacity: 0.2;
    }
`;

export const Pill = styled.div<TTabSketches>`
    opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
    display: flex;
    justify-content: center;

    p {
        display: flex;
        align-items: center;
        padding: 4px;
        &:hover {
            background-color: ${({ theme }) => theme.white};
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px;
        }
    }
`;

export const ContainerPopover = styled.div`
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    padding: 0.5rem 0;
    width: 192px;
`;

export const ButtonPopover = styled.button`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background: ${({ theme }) => theme.white};
    cursor: pointer;

    div {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0.5rem;
    }

    svg {
        width: 24px;
        height: 24px;
    }

    span.button-text {
        width: 100%;
        text-align: left;
        font-weight: 400;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};
    }

    &:hover {
        background: ${({ theme }) => theme.gray50};
        transition: 0.3s ease-in-out;

        span {
            color: ${({ theme }) => theme.gray800};
        }

        svg {
            path {
                stroke: ${({ theme }) => theme.gray800};
            }
        }
    }
`;

const getStatusBackground = (status: string) => {
    if (status === "invalid") return "#F9F9F9";
    return "#F9F9F9";
};

const getStatusColor = (status: string) => {
    if (status === "expired") return theme.orange;
    if (status === "waiting") return "#0479EC";
    if (status === "canceled") return "#D14141";
    if (status === "invalid") return "#D14141";
    return theme.green1;
};

export const Status = styled.span<{ $status?: string }>`
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: ${(props) => getStatusBackground(props.$status || "")};
    border-radius: 8px;
    color: ${(props) => getStatusColor(props.$status || "")} !important;
    font-weight: 600;
    width: fit-content;
    padding: 2px 20px;
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 25rem;
    padding: 1.5rem;
    gap: 1.5rem;
    align-items: center;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;
