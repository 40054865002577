import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icons, theme } from "../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
} from "../../../../../components";
import DayPicker from "../../../../../components/molecules/day-picker/day-picker";
import { TOption, TResponse } from "../../../../../models";
import { TUpdateActivity } from "../../../../../services/activities/update-activity";
import { TPriority, TSectionActivity } from "../types";
import { Collaborators } from "./add-collaborators-button/add-collaborators-button";
import * as s from "./styled-components";

type TPriorityOption = {
    value: TPriority;
    label: string;
    color: string;
};

type TFields = {
    activityName: string;
    deadline: Date | undefined;
    priority: TPriorityOption;
    predictedHours: string;
};

type TEditActivity = {
    activity: TSectionActivity;
    updateActivity: TResponse<unknown, TUpdateActivity>;
    sectionId?: string;
    setUpdateActivityStates: React.Dispatch<React.SetStateAction<string[]>>;
    updateActivityStates: string[];
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    activityToAddCollaborators?: TSectionActivity;
};

export const EditActivity = ({
    activity,
    sectionId,
    updateActivity,
    updateActivityStates,
    setUpdateActivityStates,
    setActivityToAddCollaborators,
    activityToAddCollaborators,
}: TEditActivity) => {
    const priorityOptions: TPriorityOption[] = [
        {
            value: "low",
            label: "Baixa",
            color: theme.green1,
        },
        {
            value: "medium",
            label: "Média",
            color: theme.orange,
        },
        {
            value: "high",
            label: "Alta",
            color: "#D32F2F",
        },
    ];

    const priorityMapper = {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
    };
    const [users, setUsers] = useState<TSectionActivity["Users"]>(
        activity.Users || []
    );

    useEffect(() => {
        if (activityToAddCollaborators?.Users) {
            setUsers(activityToAddCollaborators?.Users);
        }
    }, [activityToAddCollaborators]);

    const predictedMinutesToHours = (predictedMinutes?: number): string => {
        if (!predictedMinutes) return "00:00";
        const hours = Math.floor(predictedMinutes / 60);
        const minutes = predictedMinutes % 60;
        return `${hours < 10 ? `0${hours}` : hours}:${
            minutes < 10 ? `0${minutes}` : minutes
        }`;
    };

    const {
        handleSubmit,
        control,
        reset,
        setFocus,
        formState: { errors },
    } = useForm<TFields>({
        defaultValues: {
            activityName: activity.name,
            deadline: activity.deadline
                ? new Date(activity.deadline)
                : undefined,
            priority: {
                value: activity.priority,
                label: priorityMapper[activity.priority],
            },
            predictedHours: predictedMinutesToHours(activity.expectedMinutes),
        },
    });
    const REQUIRED_FIELD = {
        required: {
            value: true,
            message: "Campo obrigatório!",
        },
    };

    useEffect(() => {
        setFocus("activityName");
    }, [setFocus]);

    const predictedHoursToMinutes = (
        predictedHours: string
    ): number | undefined => {
        if (!predictedHours) return undefined;
        const [hours, minutes] = predictedHours.split(":");
        return Number(hours) * 60 + Number(minutes);
    };

    const mapData = (data: TFields): TUpdateActivity => {
        return {
            name: data?.activityName,
            priority: data?.priority.value,
            deadline: data?.deadline,
            expectedMinutes: predictedHoursToMinutes(data?.predictedHours),
            users: activity.Users?.map((user) => user.User.id_e) || [],
        };
    };

    const clearData = () => {
        reset();
    };

    const exit = () => {
        setUpdateActivityStates(
            updateActivityStates.filter((state) => state !== activity.id_e)
        );
    };

    const formatOptionLabel = (
        option: string | TOption | null | undefined,
        input: { inputValue: string }
    ) => {
        if (typeof option !== "object") return <p>{input.inputValue}</p>;
        const optionValue = priorityOptions.find(
            (priority) => priority.value === (option as TPriorityOption).value
        );
        return (
            <s.PriorityOption color={optionValue?.color}>
                <span>•</span>
                {optionValue?.label}
            </s.PriorityOption>
        );
    };

    const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue
            .replace(/[^0-9:]/g, "")
            .replace(/(\d{3})(\d{2})/, "$1:$2")
            .replace(/(:\d{2})\d+?$/, "$1");
        return formattedValue;
    };

    const onSubmit = handleSubmit((data) => {
        updateActivity.onRefresh({
            ...mapData(data),
            users: users.map((user) => user.User.id_e),
            sectionId,
            activityIds: [activity.id_e],
        });
    });

    useEffect(() => {
        updateActivity.response = undefined;
    }, []);

    useEffect(() => {
        if (updateActivity.response) {
            clearData();
            exit();
        }
    }, [updateActivity.response]);

    return (
        <s.ActivityContainer
            onSubmit={onSubmit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    clearData();
                    exit();
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <s.ActivityContent>
                <s.ActivityName>
                    <Controller
                        name="activityName"
                        control={control}
                        rules={{
                            ...REQUIRED_FIELD,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                ref={(el) => el?.focus()}
                                value={value}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                placeholder="Escreva o nome da atividade"
                                mask=""
                                maxLength={150}
                                error={errors.activityName?.message as string}
                            />
                        )}
                    />
                </s.ActivityName>
                <Collaborators
                    key={activity.id_e + users.length}
                    openModal={() => {
                        setActivityToAddCollaborators({
                            ...activity,
                            activitySection_id: sectionId || "",
                            Users: users,
                        });
                    }}
                    activity={{
                        ...activity,
                        Users: users,
                    }}
                    isEditing={true}
                />
                <s.Deadline>
                    <Controller
                        name="deadline"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DayPicker
                                value={value}
                                placeholder="Data"
                                onSelect={(date) => {
                                    onChange(date);
                                }}
                                toYear={new Date().getFullYear() + 1}
                            />
                        )}
                    />
                </s.Deadline>
                <s.Priority>
                    <Controller
                        name="priority"
                        control={control}
                        render={({ field: { ref, onChange, value } }) => (
                            <SelectInput
                                value={value}
                                inputRef={ref}
                                onChange={(e: TPriorityOption) => {
                                    onChange(e);
                                }}
                                options={priorityOptions}
                                icon={<Icons.Chevron color={theme.gray400} />}
                                formatOptionLabel={formatOptionLabel}
                            />
                        )}
                    />
                </s.Priority>
                <s.PredictedHours>
                    <span>Horas previstas:</span>
                    <Controller
                        name="predictedHours"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                value={value}
                                onChange={(e) =>
                                    onChange(handleTimeInputChange(e))
                                }
                                placeholder="Escreva o nome da atividade"
                                defaultValue="00:00"
                                mask=""
                                error={errors.predictedHours?.message as string}
                            />
                        )}
                    />
                </s.PredictedHours>
            </s.ActivityContent>
            <s.ButtonsContainer>
                <SecondaryButton
                    type="button"
                    onClick={() => {
                        clearData();
                        exit();
                    }}
                >
                    CANCELAR
                </SecondaryButton>
                <PrimaryButton
                    id="save"
                    type="submit"
                    loading={updateActivity.loading}
                >
                    SALVAR
                </PrimaryButton>
            </s.ButtonsContainer>
        </s.ActivityContainer>
    );
};
