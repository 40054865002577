import { styled } from "styled-components";

export const Container = styled.div``;

export const Trigger = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1.5rem;

    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.gray600};
`;

export const Content = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.purple500};
    margin-bottom: 10px;
    padding-left: 5px;
`;

export const TutorialLabel = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.gray600};
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }
`;
