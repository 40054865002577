import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const AddButton = styled.button`
    display: flex;
    align-items: center;
    gap: 16px;
    color: ${({ theme }) => theme.gray900};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    width: fit-content;
`;

export const SeeAll = styled.button`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1.12px;
    text-decoration-line: underline;
    text-align: left;
`;

export const Button = styled.button`
    width: 30px;
`;
