import src from "react-select/dist/declarations/src";

import ContainerModal from "../../components/molecules/modals/container-modal";
import { TBaseSVG } from "../../models";
import { LoadingCircle } from "../images";

export default ({ width, height }: TBaseSVG) => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1001,
            }}
        >
            <div
                style={{
                    position: "fixed",
                    backgroundColor: "black",
                    opacity: 0.8,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100vh",
                    overflowY: "hidden",
                }}
            >
                <img
                    src={LoadingCircle}
                    id="check-plan"
                    alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                    style={{
                        animation: "spin 6s linear infinite",
                        transformOrigin: "center",
                    }}
                />
                <style>
                    {`
                        @keyframes spin {
                            0% {
                                transform: rotate(0deg);
                            }
                            25% {
                                transform: rotate(90deg);
                            }
                            50% {
                                transform: rotate(180deg);
                            }
                            75% {
                                transform: rotate(270deg);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }
                    `}
                </style>
            </div>
        </div>
    );
};
