import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    height,
    width,
}: TBaseSVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
        fill="none"
        onClick={onClick}
        className={className || "expand-content"}
        strokeWidth={0.1}
    >
        <title>{title}</title>
        <mask
            id="mask0_24635_14234"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill={theme.white3} />
        </mask>
        <g mask="url(#mask0_24635_14234)">
            <path
                d="M6.99997 17H10.25C10.4625 17 10.6406 17.0719 10.7843 17.2157C10.9281 17.3595 11 17.5377 11 17.7503C11 17.9629 10.9281 18.141 10.7843 18.2846C10.6406 18.4282 10.4625 18.5 10.25 18.5H6.40385C6.14777 18.5 5.93311 18.4133 5.75987 18.2401C5.58662 18.0668 5.5 17.8522 5.5 17.5961V13.75C5.5 13.5375 5.57191 13.3594 5.71572 13.2156C5.85954 13.0719 6.03774 13 6.25033 13C6.46293 13 6.64102 13.0719 6.7846 13.2156C6.92818 13.3594 6.99997 13.5375 6.99997 13.75V17ZM17 6.99998H13.75C13.5375 6.99998 13.3594 6.92807 13.2156 6.78425C13.0718 6.64045 13 6.46225 13 6.24965C13 6.03707 13.0718 5.85898 13.2156 5.7154C13.3594 5.5718 13.5375 5.5 13.75 5.5H17.5961C17.8522 5.5 18.0668 5.58663 18.2401 5.75988C18.4133 5.93311 18.5 6.14777 18.5 6.40385V10.25C18.5 10.4625 18.428 10.6406 18.2842 10.7844C18.1404 10.9281 17.9622 11 17.7496 11C17.537 11 17.3589 10.9281 17.2154 10.7844C17.0718 10.6406 17 10.4625 17 10.25V6.99998Z"
                fill={color || theme.gray800}
            />
        </g>
    </svg>
);
