import {
    BillReports1,
    BillReports2,
    BillReports3,
    Professionals1,
    Professionals2,
    ProfessionalsValues,
    ProjectsSectors1,
    ProjectsSectors2,
    ProjectsSectors3,
    ProjectsValues,
    ReviewReport1,
    ReviewReport2,
    Role1,
    Role2,
} from "../../assets";
import { TPermissionsList } from "./types";

export const permissionsList: TPermissionsList = [
    {
        id: "all_permissions",
        title: "Conceder permissão total",
        type: "checkbox",
        description: (
            <p>
                Será possível visualizar e editar todas as informações abaixo.
            </p>
        ),
        detailedDescription: "",
        images: [],
        options: [],
    },
    {
        id: "manage_teams_projects",
        title: "Gestão",
        type: "checkbox",
        description: "",
        detailedDescription: "",
        images: [],
        options: [],
    },
    {
        id: "professionals",
        title: "Profissionais",
        type: "select",
        description: (
            <p>
                Lista de profissionais com nome, cargo, contratação, horas
                previstas, atuação, setor, e status.
            </p>
        ),
        detailedDescription: (
            <p>
                A lista de profissionais exibe informações detalhadas sobre cada
                contratação, incluindo nome, atuação, setor, projetos e tipo de
                contratação. Além disso, tendo a permissão de editor, é possível
                adicionar ou remover profissionais, bem como a ativar ou
                inativar suas contas.
            </p>
        ),
        images: [Professionals1, Professionals2],
        options: [
            {
                label: "Somente visualizar",
                value: "view",
            },
            {
                label: "Visualizar e editar",
                value: "manage",
            },
        ],
    },
    {
        id: "roles_permissions",
        title: "Atuações e permissões",
        type: "select",
        description: (
            <p>
                Informações sobre atuações, como nome e permissões de acesso à
                plataforma.
            </p>
        ),
        detailedDescription: (
            <p>
                As atuações descrevem as responsabilidades de cada profissional,
                enquanto as permissões especificam as áreas da aplicação às
                quais eles têm acesso.
            </p>
        ),
        images: [Role1, Role2],
        options: [
            {
                label: "Somente visualizar",
                value: "view",
            },
            {
                label: "Visualizar e editar",
                value: "manage",
            },
        ],
    },
    {
        id: "projects_sectors",
        title: "Projetos e setores",
        type: "select",
        description: (
            <p>
                Informações sobre projetos e setores, como título, descrição,
                cronograma e equipe.
            </p>
        ),
        detailedDescription: (
            <p>
                Na sessão projetos é possível ver a descrição, nome do cliente,
                time, status report e relatórios de andamento. Destaque para
                previsões de atrasos e escopo já realizado. Nos Setores,
                identifique facilmente os projetos associados e os profissionais
                envolvidos. Uma visão clara da distribuição de talentos e
                recursos na plataforma.
            </p>
        ),
        images: [ProjectsSectors1, ProjectsSectors2, ProjectsSectors3],
        options: [
            {
                label: "Somente visualizar",
                value: "view",
            },
            {
                label: "Visualizar e editar",
                value: "manage",
            },
        ],
    },
    {
        id: "professionals_values",
        title: "Valores profissionais",
        type: "select",
        description: (
            <p>
                Informações sobre valor mensal ou valor por hora de cada
                profissional.
            </p>
        ),
        detailedDescription: (
            <>
                <p>
                    Detalhes sobre o valor mensal ou por hora de cada
                    profissional.
                </p>
                <br />
                <p>
                    * A opção de "Visualizar e editar"{" "}
                    <strong>estará desabilitada</strong> caso a permissão de
                    "Profissionais" não esteja em "Visualizar e editar"
                </p>
            </>
        ),
        images: [ProfessionalsValues],
        options: [
            {
                label: "Ocultar",
                value: "hide",
            },
            {
                label: "Somente visualizar",
                value: "view",
            },
            {
                label: "Visualizar e editar",
                value: "manage",
            },
        ],
    },
    {
        id: "projects_values",
        title: "Valores projetos",
        type: "select",
        description: (
            <p>
                Informações sobre valores de projetos, como estimativas, custo
                de mão-de-obra, e custo total.
            </p>
        ),
        detailedDescription: (
            <>
                <p>
                    Detalhes sobre os valores associados a projetos, incluindo
                    estimativas, custo de mão de obra e custo total.
                </p>
                <br />
                <p>
                    * A opção de "Visualizar e editar"{" "}
                    <strong>estará desabilitada</strong> caso a permissão de
                    "Projetos e setores" não esteja em "Visualizar e editar"
                </p>
            </>
        ),
        images: [ProjectsValues],
        options: [
            {
                label: "Ocultar",
                value: "hide",
            },
            {
                label: "Somente visualizar",
                value: "view",
            },
            {
                label: "Visualizar e editar",
                value: "manage",
            },
        ],
    },
    {
        id: "bill_reports",
        title: "Faturar relatórios dos profissionais",
        type: "checkbox",
        description: (
            <p>
                Visualizar os relatórios de horas dos profissionais e definir o
                valor a ser faturado pelo profissional.
            </p>
        ),
        detailedDescription: (
            <p>
                Após a aprovação do relatório, o faturador revisa os horários e
                determina o valor a ser pago ao profissional no respectivo mês.
                A partir desse ponto, o profissional tem a opção de incluir nota
                fiscal e boleto, se aplicável.
            </p>
        ),
        images: [BillReports1, BillReports2, BillReports3],
        options: [],
    },
    {
        id: "review_reports",
        title: "Revisar relatórios dos profissionais",
        type: "select",
        description: (
            <p>
                Visualizar, revisar e aprovar relatórios de horas dos
                profissionais.
            </p>
        ),
        detailedDescription: (
            <p>
                O relatório do profissional é o registro detalhado de todas as
                atividades e horas registradas no cronômetro ao longo do mês.
                Com a permissão para revisar relatórios, o usuário pode: Acessar
                os relatórios mensais dos profissionais. Revisar e fornecer
                comentários de ajustes ao profissional. Aprovar ou recusar o
                relatório. Ao aprovar, o relatório é encaminhado para o
                faturamento.
            </p>
        ),
        images: [ReviewReport1, ReviewReport2],
        options: [
            {
                label: "Não revisor",
                value: "disabled",
            },
            {
                label: "Revisor",
                value: "enabled",
            },
        ],
    },
];
