import { useMutation, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../assets";
import {
    Input,
    PopoverProjectList,
    PopoverRadix,
    PrimaryButton,
    Table,
    Tooltip,
} from "../../../components";
import { ModalClientProjectsInProgress } from "../../../components/molecules/modals/modal-client-projects-in-progress/modal-client-projects-in-progress";
import { ModalCreateClient } from "../../../components/molecules/modals/modal-create-client/modal-create-client";
import { ModalUpdateClient } from "../../../components/molecules/modals/modal-update-client/modal-update-client";
import { TCreateClient, TUpdateClient } from "../../../models";
import { clients } from "../../../services";
import { GetClientsResponse } from "../../../services/clients/types/get-clients";
import { Radio } from "../../../styles/radio-group-styles";
import * as s from "./styles";

type Error = {
    body: {
        message: string;
    };
    message: string;
};

export function Clients() {
    const [inputValue, setInputValue] = useState("");
    const [createClientOpen, setCreateClientOpen] = useState(false);
    const [clientIdToUpdate, setClientIdToUpdate] = useState("");
    const [statusFilter, setStatusFilter] = useState("active");
    const [projectsInProgress, setProjectsInProgress] = useState("");

    const active =
        statusFilter !== "all" ? statusFilter === "active" : undefined;

    const getClients = useQuery<GetClientsResponse>({
        queryKey: ["getClients"],
        queryFn: () =>
            clients.getClients({
                all: true,
                active,
            }),
        refetchOnWindowFocus: false,
    });

    const createClient = useMutation<unknown, Error, TCreateClient>({
        mutationKey: ["createClient"],
        mutationFn: clients.createClient,
        onSuccess() {
            toast.success("Cliente criado com sucesso");
            setCreateClientOpen(false);
            getClients.refetch();
        },
        onError(error) {
            toast.error(error.message);
        },
    });

    const updateClient = useMutation<unknown, Error, TUpdateClient>({
        mutationKey: ["updateClient"],
        mutationFn: clients.updateClient,
        onSuccess() {
            toast.success("Cliente atualizado com sucesso");
            setClientIdToUpdate("");
            getClients.refetch();
        },
        onError(error) {
            const projects = error.body.message.split("|")?.[1];
            if (projects) {
                setProjectsInProgress(projects);
                return;
            }
            toast.error(error.message);
        },
    });

    const orderedData = orderBy(getClients.data ?? [], "name_i", "asc");
    const filteredData = orderedData.filter(({ name_i }) =>
        inputValue ? name_i.includes(inputValue) : true
    );

    const body = filteredData.map((client) => {
        return {
            row: [
                {
                    column: client.name,
                },
                {
                    column: (
                        <Tooltip
                            side="bottom"
                            align="start"
                            content={
                                <PopoverProjectList
                                    projects={client.Projects}
                                    message="Sem projetos vinculados!"
                                />
                            }
                        >
                            <div className="projects">
                                <p>{client?.Projects?.length || "-"}</p>
                            </div>
                        </Tooltip>
                    ),
                },
                {
                    column: (
                        <s.Status $active={client.active}>
                            {client.active ? "Ativo" : "Inativo"}
                        </s.Status>
                    ),
                },
                {
                    column: (
                        <button
                            onClick={() => {
                                setClientIdToUpdate(client.id_e);
                            }}
                        >
                            <Icons.EditOutline />
                        </button>
                    ),
                },
            ],
        };
    });

    const statusFilterOptions = [
        {
            id: "all",
            label: "Todos",
        },
        {
            id: "inactive",
            label: "Inativos",
        },
        {
            id: "active",
            label: "Ativos",
        },
    ];

    const fillerColors: Record<typeof statusFilter, string> = {
        all: theme.gray600,
        active: theme.green1,
        inactive: "#D14141",
    };

    const columns = [
        { column: "Cliente" },
        { column: "Projetos vinculados" },
        {
            column: (
                <div>
                    <PopoverRadix
                        side="bottom"
                        sideOffset={5}
                        alignOffset={5}
                        align="end"
                        trigger={
                            <s.FillerWrapper>
                                Status
                                <Icons.Filler
                                    color={fillerColors[statusFilter]}
                                />
                            </s.FillerWrapper>
                        }
                        onOpenChange={() => {
                            getClients.refetch();
                        }}
                    >
                        <Radio.Root>
                            {statusFilterOptions.map((option) => (
                                <Radio.Wrapper key={option.id}>
                                    <Radio.Item
                                        value={option.id}
                                        id={option.id}
                                        checked={option.id === statusFilter}
                                        onClick={() =>
                                            setStatusFilter(option.id)
                                        }
                                    >
                                        <Radio.Indicator />
                                    </Radio.Item>
                                    <label htmlFor={option.id}>
                                        {option.label}
                                    </label>
                                </Radio.Wrapper>
                            ))}
                        </Radio.Root>
                    </PopoverRadix>
                </div>
            ),
        },
        { column: "Editar" },
    ];

    return (
        <>
            {clientIdToUpdate && (
                <ModalUpdateClient
                    onSuccess={(data) => {
                        if (clientIdToUpdate) {
                            updateClient.mutate({
                                name: data.name,
                                active: data.active,
                                clientId: clientIdToUpdate,
                            });
                            setClientIdToUpdate("");
                        }
                    }}
                    onClose={() => {
                        setClientIdToUpdate("");
                        setCreateClientOpen(false);
                    }}
                    defaultValues={getClients.data?.find(
                        ({ id_e }) => id_e === clientIdToUpdate
                    )}
                    setOpen={(open) => {
                        if (!open) {
                            setClientIdToUpdate("");
                        }
                    }}
                    loading={updateClient.isLoading}
                />
            )}
            {createClientOpen && (
                <ModalCreateClient
                    onSuccess={(data) => {
                        createClient.mutate({
                            name: data.name,
                        });
                    }}
                    setOpen={setCreateClientOpen}
                    loading={createClient.isLoading}
                />
            )}
            {projectsInProgress && (
                <ModalClientProjectsInProgress
                    projects={projectsInProgress}
                    setOpen={(open) => {
                        if (!open) {
                            setProjectsInProgress("");
                        }
                    }}
                />
            )}
            <s.Container>
                <s.Header>
                    <Input
                        label="Pesquise por clientes"
                        icon={<Icons.SearchOutline />}
                        value={inputValue}
                        placeholder="Ex. Cliente 123"
                        onChange={(e) => {
                            setInputValue(e.target.value);
                        }}
                    />
                    <PrimaryButton
                        disabled={getClients.isLoading}
                        onClick={() => setCreateClientOpen(true)}
                    >
                        Criar cliente
                    </PrimaryButton>
                </s.Header>
                {getClients.isLoading ? (
                    <s.LoadingContainer>
                        <Icons.Loading
                            color={theme.purple500}
                            width="50px"
                            height="50px"
                        />
                    </s.LoadingContainer>
                ) : (
                    <Table
                        templateColumns="1fr 1fr 1fr 1fr"
                        header={columns}
                        body={
                            !filteredData.length
                                ? [
                                      {
                                          row: [
                                              {
                                                  column: "Nenhum cliente encontrado",
                                              },
                                          ],
                                      },
                                  ]
                                : body
                        }
                    />
                )}
            </s.Container>
        </>
    );
}
