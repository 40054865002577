import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 80px 33px;
    width: 100%;
    gap: 3.125rem;

    div#section-row {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 25px;
    }

    .tutorials-popover {
        margin-top: -1rem;
    }
`;
