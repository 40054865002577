import styled from "styled-components";

type TTextArea = {
    error?: boolean;
};

export const Container = styled.form<TTextArea>`
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
    gap: 1.5rem;
    height: fit-content;
    width: 450px;

    h1 {
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.75rem;
        color: ${({ theme }) => theme.purple600};
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
    }
    div#text-tittle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h1 {
            display: flex;
            align-items: flex-start;
            font-size: 0.85rem;
            font-weight: 600;
            line-height: 1.75rem;
            color: ${({ theme }) => theme.purple600};
            margin-bottom: 0;
        }
        svg {
            cursor: pointer;
            width: 1.5rem;
        }
    }

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
        color: var(--color-gray-400, #9e9ba7);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.96px;
        text-transform: uppercase;

        strong {
            width: 100%;
            color: var(--color-gray-800, #3e374e);
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.08px;
        }

        .inputs {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-left: 3rem;
            padding-right: 0;
        }
    }

    .info-wrapper {
        display: flex;
        align-items: center;
    }

    div#buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8rem;
    }

    div#fields-modals {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .descriptionInput {
            width: 75%;
            div {
                width: 75%;
            }
        }
    }

    div#views-modals {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 400px;
        .descriptionInput {
            width: 75%;
            div {
                width: 75%;
            }
        }

        span {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.85rem;
            color: ${({ theme }) => theme.gray400};
            height: 1.5rem;
            strong {
                color: ${({ theme }) => theme.gray800};
                .descriptionInput {
                    width: 75%;
                    div {
                        width: 75%;
                    }
                }
            }
        }
    }

    div.view-box {
        display: flex;
        align-items: baseline;
        padding: 0.5rem;
        border: 1px solid ${({ theme }) => theme.gray100};
        span {
            font-weight: 400;
            font-size: 0.625rem;
            line-height: 0.875rem;
            letter-spacing: 0.015em;
            border-radius: 0.25rem;
            color: ${({ theme }) => theme.gray600};
        }
        min-height: 5rem;
    }

    div {
        .commentary {
            display: flex;
            resize: none;
            background: ${({ theme }) => theme.white};
            &::-ms-reveal,
            ::-ms-clear {
                display: none;
            }
            border-radius: 0.25rem;
            width: 100%;
            font-size: 0.875rem;

            .text-area {
                padding-top: 1.8rem;
            }

            p {
                margin-top: -10px;
                font-size: 0.875rem;
            }
        }
        .labelCommentary {
            display: flex;
            position: absolute;
            padding-top: 0.5rem;
            padding-left: 1rem;
            font-style: normal;
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 1.375rem;
            letter-spacing: 0.0015em;
            color: ${({ theme }) => theme.gray800};
        }
    }
`;

export const UnbilledContainer = styled.div`
    div {
        width: 400px;
        height: 350px;
        padding: 20px;
    }
`;

export const TooltipContainer = styled.div`
    padding: 7px 12px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    max-width: 400px;
    letter-spacing: normal;
    text-transform: none;
`;
