import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 10px;
    max-width: 17.5rem;
`;

export const Content = styled.div`
    width: 100%;
    text-align: start;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.gray600};
    font-weight: 400;

    svg {
        margin: 0 5px;
    }

    #projects {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    li {
        list-style: none;
    }
`;

export const Title = styled.span`
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.purple600};
    font-weight: 600;
    text-align: start;
`;

export const Text = styled.div`
    .pill {
        padding: 0 2.5rem;

        &:hover {
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 3px;
        }
    }
`;
