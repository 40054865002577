import styled from "styled-components";

type TSelect = {
    error?: boolean;
};

export const FlexColumn = styled.div<{ creating?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    height: fit-content;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const LockTooltip = styled.div`
    width: 18.75rem;
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
`;

export const SmallP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    padding: 10px 16px;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};
`;

export const Flex = styled.div<TSelect>`
    min-width: 100%;
    max-width: 100px;

    @media (max-width: 768px) {
        .select-activity__placeholder {
            font-size: 11px !important;
        }
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const PositionIcons = styled.div`
    display: flex;
`;

export const AddActivityButton = styled.button`
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &:hover {
        background: ${({ theme }) => theme.gray50};
    }
`;

export const OptionRow = styled.div<{ lastTask?: boolean }>`
    cursor: pointer;
    height: 100%;
    display: flex;
    gap: 20px;
`;

export const SearchPart = styled.span<{ match?: boolean }>`
    ${({ match }) => match && "background: #e5d6e8;"}
`;

export const Section = styled.div`
    color: ${({ theme }) => theme.gray400};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
`;
