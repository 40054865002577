import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

import { FotoRapaz } from "../../assets";
import { Loading } from "../../components";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import {
    TAuthBody,
    TAuthError,
    TAuthResponse,
    TCreateUserBody,
    TCreateUserResponse,
    TUserError,
} from "../../models";
import { manageUser, invite, auth, projects } from "../../services";
import { RegisterFinalStep } from "../cadastro/components/register-steps/final-step/final-step";
import { TUserData } from "../invite/invite";
import * as s from "./styled-register-admin";

export type TData = {
    password_confirmation: string;
    celNumber: string | undefined;
    name: string;
    organizationName?: string;
    password: string;
    invitedBy: string;
    voucher?: string;
};

export const RegisterAdmin = () => {
    const { inviteToken } = useParams<{ inviteToken?: string }>();

    const [userData, setUserData] = useState<TUserData>();
    const [loginData, setLoginData] = useState<TAuthBody>();
    const navigate = useNavigate();

    const {
        setIsLogged,
        setPermissions,
        setHaveOrganization,
        setUserProjects,
        setWorkedHours,
    } = useUser();
    const { createUser, setAvatar } = manageUser;

    const sendAvatar = useFetch({
        fn: setAvatar,
        start: false,
        errorMessage: "Erro ao enviar a imagem!",
    });

    const createUserReq = useFetch<
        TCreateUserBody,
        TCreateUserResponse,
        TUserError
    >({
        fn: createUser,
        start: false,
    });

    const getInviteData = useFetch({
        fn: invite.getInviteUser,
        start: false,
    });

    const loginReq = useFetch<TAuthBody, TAuthResponse, TAuthError>({
        fn: auth,
        start: false,
    });

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const getProjects = useFetch({ fn: projects.getProject, start: false });

    const mapDataToCreate = (data: TData) => {
        const formData = cloneDeep({ ...data });

        const body = {
            ...formData,
            ...userData,
            birthDate: null,
            admissionDate: new Date(userData?.admissionDate as string),
            inviteToken,
            terms: true,
        };
        delete body.organizationName;
        return body;
    };

    const postUserData = (data: TData) => {
        createUserReq.onRefresh(mapDataToCreate(data));
        setLoginData({
            email: userData?.email as string,
            password: data.password,
        });
    };

    const redirect = (haveOrganization?: boolean) => {
        if (!haveOrganization) return navigate("/registrar-empresa");
        return navigate("/cronometro");
    };

    useEffect(() => {
        if (createUserReq.response) {
            loginReq.onRefresh(loginData);
        }
    }, [createUserReq.response]);

    useEffect(() => {
        if (loginReq.response) {
            setIsLogged(true);
            setPermissions(loginReq.response.userPermsId);
            toast.success("Logado com sucesso");
            localStorage.setItem(
                "haveOrganization",
                JSON.stringify(!!loginReq.response.user.organization)
            );
            setHaveOrganization(!!loginReq.response.user.organization);

            getUser.onRefresh();
        }
    }, [loginReq.response]);

    useEffect(() => {
        if (getUser.response && loginReq.response) {
            const monthlyHourLimit =
                getUser?.response?.Organization?.monthly_hour_limit ?? 0;
            setWorkedHours({
                total: getUser.response.monthlyTime,
                limit: monthlyHourLimit * 60,
            });

            if (loginReq.response.user.organization) {
                getProjects.onRefresh({ sectors: true });
            } else {
                redirect(Boolean(loginReq.response.user.organization));
            }
        }
    }, [getUser.response]);

    useEffect(() => {
        if (getProjects.response && loginReq.response) {
            setUserProjects(getProjects.response.projects);

            redirect(Boolean(loginReq.response.user.organization));
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (sendAvatar.response) {
            navigate("/cronometro");
        }
    }, [sendAvatar.response]);

    useEffect(() => {
        if (inviteToken) {
            getInviteData.onRefresh(inviteToken);
        }
    }, []);

    useEffect(() => {
        if (getInviteData.response) {
            setUserData(getInviteData.response);
        }
    }, [getInviteData.response || getInviteData.error]);

    return (
        <s.Container>
            <s.Img src={FotoRapaz} />

            <s.Section>
                <s.FlexColumn>
                    <div>
                        {userData ? (
                            <RegisterFinalStep
                                userData={userData}
                                postUserData={postUserData}
                            />
                        ) : (
                            <Loading />
                        )}
                    </div>
                </s.FlexColumn>
            </s.Section>
        </s.Container>
    );
};
