/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";

type TUseFetch<TBody, TResponse, TError> = {
    fn: (e?: TBody) => Promise<TResponse>;
    params?: TBody;
    start?: boolean;
    subSequentReqs?: { fn: (e?: any) => Promise<any>; params?: any }[];
    errorMessage?: string;
    successMessage?: string;
    noError?: boolean;
};

function useFetch<
    TBody,
    TResponse,
    TError extends {
        message?: string;
        errorToken: boolean;
        body?: { status: string; message: string };
    }
>({
    fn,
    params,
    start = true,
    subSequentReqs,
    successMessage,
    errorMessage,
    noError,
}: TUseFetch<TBody, TResponse, TError>) {
    const [response, setResponse] = React.useState<TResponse>();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<TError>();
    const [allSubsequentResponses, setAllSubsequentResponses] = useState<any>(
        []
    );

    const fetchData = async (params?: TBody) => {
        try {
            if (!loading) {
                setLoading(true);
            }

            const data = await fn(params);

            setResponse(data);

            setError(undefined);
            if (successMessage) toast.success(successMessage);
        } catch (error) {
            const typedError = error as TError;
            setResponse(undefined);
            setError(typedError);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (start) {
            fetchData(params);
        } else setLoading(false);
    }, []);

    useEffect(() => {
        if (error && error.message && !noError) {
            toast.error(error.message);
        }
        if (error && error.errorToken) fetchData(params);
    }, [error]);

    useEffect(() => {
        if (response && subSequentReqs) {
            const manageRequests = async () => {
                const asyncSome = async (arr: any, resolve: any) => {
                    return arr.some(async (item: any) => {
                        const result = await resolve(item.fn(item.params));
                        return result;
                    });
                };
                const res = await asyncSome(
                    subSequentReqs,
                    async (req: any) => {
                        const reqBody = await req;

                        setAllSubsequentResponses((prevState: any) => [
                            ...prevState,
                            reqBody,
                        ]);

                        return reqBody?.statusCode >= 400;
                    }
                );

                return res;
            };
            manageRequests();
        }
    }, [response]);

    const onRefresh = (params?: TBody) => {
        fetchData(params);
    };

    return {
        response,
        error,
        loading,
        onRefresh,
        allSubsequentResponses,
    };
}

export default useFetch;
