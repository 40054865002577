import { TResetPasswordBody, TResetPasswordResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (
    { password, password_confirmation, emailToken }: TResetPasswordBody = {
        password: "",
        password_confirmation: "",
        emailToken: "",
    }
) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/password/reset`,
        method: "patch",
        body: { password, password_confirmation },
        headers: { auth: true, "x-mail-token": emailToken },
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases<TResetPasswordResponse>({
        body,
        statusCode,
    });
};
