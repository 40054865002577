import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        className={className || "icon-chart-bar"}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            d="M7 17V11C7 10.4696 6.78929 9.96086 6.41421 9.58579C6.03914 9.21071 5.53043 9 5 9H3C2.46957 9 1.96086 9.21071 1.58579 9.58579C1.21071 9.96086 1 10.4696 1 11V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H5C5.53043 19 6.03914 18.7893 6.41421 18.4142C6.78929 18.0391 7 17.5304 7 17ZM7 17V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5H11C11.5304 5 12.0391 5.21071 12.4142 5.58579C12.7893 5.96086 13 6.46957 13 7V17M7 17C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19H11C11.5304 19 12.0391 18.7893 12.4142 18.4142C12.7893 18.0391 13 17.5304 13 17M13 17V3C13 2.46957 13.2107 1.96086 13.5858 1.58579C13.9609 1.21071 14.4696 1 15 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H15C14.4696 19 13.9609 18.7893 13.5858 18.4142C13.2107 18.0391 13 17.5304 13 17Z"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={color || theme.purple800}
        />
    </svg>
);
