import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import "dayjs/plugin/utc";

import { Icons, theme } from "../../../../assets";
import {
    ButtonShowMore,
    PopoverRadix,
    RadixCheckBox,
    Tag,
    Tooltip,
} from "../../../../components";
import { MenuAtom, TMenuAtom } from "../../../../components/atoms/menu/menu";
import { CardEditHours } from "../../../../components/molecules/card-edit-hours/card-edit-hours";
import { EditEntry } from "../../../../components/molecules/edit-entry/edit-entry";
import { ModalDeleteEntry } from "../../../../components/molecules/modals/modal-delete-entry/modal-delete-entry";
import { TProjectList } from "../../../../components/molecules/popovers/popover-project/popover-project";
import { useUser } from "../../../../context";
import { TEntryTask } from "../../../../models";
import { UpdateEntryVariables } from "../../../../services/track/types";
import {
    addMillisecondsToHour,
    calculateTimeRangeDuration,
    getTimeFromIsoString,
    sumTimeStampsInMinutes,
} from "../../../../utils";
import * as s from "./styled-card-container";
import validation from "./validation";

dayjs.utc();

type TEntryList = {
    index: number;
    task: TEntryTask;
    isEditing: boolean;
    projectList: TProjectList[];
    toggleTaskChecked: (index: number, checked: boolean) => void;
    manageIsOpen: (index: number) => void;
    handleDeleteEntry: (id: string) => void;
    handleUpdateEntry: (params: UpdateEntryVariables) => void;
    onContinueTask: (task: TEntryTask) => void;
};

export type EntryFields = {
    startTrackTime: string;
    endTrackTime: string;
    project: string;
    importantInfos: {
        isFather?: boolean;
        ids?: string[];
        id: string;
        date: string;
    };
    activityId: string;
    description?: string;
    sectorId: string | undefined;
};

export const Entry = ({
    index,
    task,
    isEditing,
    projectList,
    toggleTaskChecked,
    manageIsOpen,
    handleDeleteEntry,
    handleUpdateEntry,
    onContinueTask,
}: TEntryList) => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        getValues,
        trigger,
    } = useForm<EntryFields>({
        resolver: yupResolver(validation),
    });
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [entriesIdsToDelete, setEntriesIdsToDelete] = useState<string>("");
    const [isToEditEntry, setIsToEditEntry] = useState<boolean>(false);
    const [editIsValid, setEditIsValid] = useState<boolean>(true);

    const { favoriteProject } = useUser();

    useEffect(() => {
        if (!task.entries.length) {
            return;
        }
        const lastEntry = task.entries[task.entries.length - 1];
        setValue("startTrackTime", getTimeFromIsoString(lastEntry.start_at));
        setValue("endTrackTime", getTimeFromIsoString(lastEntry.end_at));
        setValue("project", task.project.id_e);
        trigger("startTrackTime");
        trigger("endTrackTime");
    }, [task]);

    const entry = task.entries?.[0];

    const onSubmit = handleSubmit((data) => {
        const startAt = new Date(entry.start_at.replace("Z", ""));
        const currentData = calculateTimeRangeDuration(
            startAt,
            addMillisecondsToHour(data.startTrackTime),
            addMillisecondsToHour(data.endTrackTime)
        );
        handleUpdateEntry({
            entriesId: data.importantInfos.ids?.join(", "),
            startAt: currentData.startTrackTime,
            endAt: currentData.finalTrackTime,
            activityId: data.activityId || entry.Activity?.id_e,
            sectorId: data.sectorId || task.sector?.id_e,
            projectId: data.project,
            manyUpdates: data.importantInfos.isFather,
            description: data.description,
        });
        setIsToEditEntry(false);
    });

    if (!entry) return <div></div>;

    const importantInfos = {
        isFather: task.entries.length > 1,
        ids: task.entries.map(({ id_e }) => id_e),
        id: entry.id_e,
        date: entry.start_at,
    };
    const canOpenEntry = task.entries.length > 1 && task.entries[1].end_at;
    const menuOptions: TMenuAtom["options"] = [
        {
            label: "Editar",
            icon: (
                <Icons.PencilOutline
                    color={entry.locked ? theme.gray300 : theme.gray600}
                />
            ),
            action: () => {
                setIsToEditEntry(true);
            },
            color: theme.gray800,
            disabled: entry.locked,
            tooltipWhenDisabled: "O relatório do mês já foi fechado.",
        },
        {
            label: "Apagar",
            icon: (
                <Icons.Trash color={entry.locked ? theme.gray300 : theme.red} />
            ),
            action: () => {
                setIsDeleteModalOpen(true);
                setEntriesIdsToDelete(
                    task.entries.map((item) => item.id_e).join(", ")
                );
            },
            color: theme.red,
            disabled: entry.locked,
            tooltipWhenDisabled: "O relatório do mês já foi fechado.",
        },
    ];

    const chooseContinueTaskTooltip = () => {
        if (task.project.archived) {
            return <div>O projeto desta atividade está arquivado</div>;
        }
        if (!entry?.Activity?.id_e) {
            return (
                <div>
                    <div>
                        A atividade não pode ser usada com o cronômetro porque
                        foi criada antes do mural de atividades do projeto.
                    </div>
                    <div>
                        Verifique se tem uma atividade já criada em projetos que
                        atende às suas necessidades ou crie uma nova.
                    </div>
                </div>
            );
        }
        return <div>Continuar atividade</div>;
    };

    return (
        <div
            style={{
                width: "100%",
                backgroundColor: isToEditEntry ? theme.gray50 : "transparent",
            }}
        >
            {isDeleteModalOpen && (
                <ModalDeleteEntry
                    setOpen={setIsDeleteModalOpen}
                    onSave={() => {
                        handleDeleteEntry(entriesIdsToDelete);
                        setEntriesIdsToDelete("");
                    }}
                />
            )}
            <s.Separator />
            <Controller
                name="importantInfos"
                defaultValue={importantInfos}
                control={control}
                render={({ field: { onChange } }) => (
                    <form
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onChange(importantInfos);
                                onSubmit();
                            }
                        }}
                    >
                        <s.ActivityContainer
                            style={{
                                cursor: canOpenEntry ? "pointer" : "default",
                            }}
                            onClick={() => {
                                if (canOpenEntry) manageIsOpen(index);
                            }}
                            isEditing={isToEditEntry}
                        >
                            {isToEditEntry ? (
                                <EditEntry
                                    task={task}
                                    projectList={projectList}
                                    index={index}
                                    manageIsOpen={manageIsOpen}
                                    control={control}
                                    setValue={setValue}
                                    setEditIsValid={setEditIsValid}
                                />
                            ) : (
                                <s.LeftSide
                                    isEditing={isEditing}
                                    isToEditEntry={isToEditEntry}
                                >
                                    {isEditing && (
                                        <RadixCheckBox
                                            checked={task.entryChecked}
                                            onClick={() => {
                                                toggleTaskChecked(
                                                    index,
                                                    task.entryChecked
                                                );
                                            }}
                                        />
                                    )}

                                    <div
                                        style={{ pointerEvents: "none" }}
                                        className="container-tag"
                                    >
                                        <Tag
                                            maxWidth="200px"
                                            color={task.project.color}
                                            isFavorite={
                                                favoriteProject?.id_e ===
                                                task.project.id_e
                                            }
                                        >
                                            {task.project.name}
                                        </Tag>
                                    </div>

                                    {canOpenEntry ? (
                                        <ButtonShowMore
                                            numberOfItems={
                                                task.entries.filter(
                                                    ({ end_at }) => end_at
                                                ).length
                                            }
                                            isOpen={task.entryOpen}
                                            onClick={() => {
                                                manageIsOpen(index);
                                            }}
                                        />
                                    ) : (
                                        <div></div>
                                    )}

                                    <Tooltip
                                        positiony={10}
                                        align="start"
                                        content={
                                            <s.DescriptionTooltip>
                                                {entry.description}
                                            </s.DescriptionTooltip>
                                        }
                                    >
                                        <s.Description>
                                            <s.TaskDescription>
                                                {entry.description}
                                            </s.TaskDescription>
                                            <s.SessionName>
                                                {
                                                    entry?.Activity
                                                        ?.ActivitySection?.name
                                                }
                                            </s.SessionName>
                                        </s.Description>
                                    </Tooltip>
                                </s.LeftSide>
                            )}

                            <s.Flex id="right-content">
                                {!isToEditEntry && (
                                    <Tooltip
                                        align="end"
                                        side="bottom"
                                        content={
                                            <s.PlayTooltip>
                                                {chooseContinueTaskTooltip()}
                                            </s.PlayTooltip>
                                        }
                                    >
                                        <s.Play
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    entry?.Activity?.id_e &&
                                                    !task.project.archived
                                                )
                                                    onContinueTask(task);
                                            }}
                                        >
                                            <Icons.Play
                                                color={
                                                    entry?.Activity?.id_e &&
                                                    !task.project.archived
                                                        ? theme.purple500
                                                        : theme.gray300
                                                }
                                            />
                                        </s.Play>
                                    </Tooltip>
                                )}
                                <s.Flex
                                    style={{
                                        width: "84px",
                                    }}
                                >
                                    <PopoverRadix
                                        side="bottom"
                                        align="end"
                                        open={
                                            task.entries.length > 1 ||
                                            !isToEditEntry
                                                ? false
                                                : undefined
                                        }
                                        onOpenChange={() => {
                                            trigger("startTrackTime");
                                            trigger("endTrackTime");
                                        }}
                                        trigger={
                                            <s.StartAndEndTrackTimes
                                                isEditing={isToEditEntry}
                                                style={{
                                                    cursor:
                                                        task.entries.length > 1
                                                            ? "not-allowed"
                                                            : "pointer",
                                                }}
                                            >
                                                <p>
                                                    {getValues(
                                                        "startTrackTime"
                                                    )?.slice(0, 5)}
                                                </p>
                                                {" - "}
                                                <p>
                                                    {task.entries.length > 1
                                                        ? entry.end_at?.slice(
                                                              11,
                                                              16
                                                          )
                                                        : getValues(
                                                              "endTrackTime"
                                                          )?.slice(0, 5)}
                                                </p>
                                            </s.StartAndEndTrackTimes>
                                        }
                                    >
                                        <CardEditHours
                                            task={
                                                task.entries[
                                                    task.entries.length - 1
                                                ]
                                            }
                                            control={control}
                                            errors={errors}
                                        />
                                    </PopoverRadix>
                                </s.Flex>
                                <s.Text style={{ marginRight: 14 }}>
                                    {sumTimeStampsInMinutes(
                                        task.entries.map(
                                            (item) => item.total_time
                                        )
                                    )}
                                </s.Text>

                                {isToEditEntry ? (
                                    <s.EditControl>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (editIsValid) onSubmit();
                                            }}
                                        >
                                            <Icons.Checked
                                                color={
                                                    editIsValid
                                                        ? theme.gray800
                                                        : theme.gray300
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsToEditEntry(false);
                                            }}
                                        >
                                            <Icons.XOutline
                                                color={theme.gray800}
                                            />
                                        </div>
                                    </s.EditControl>
                                ) : (
                                    <span
                                        className="menu-trigger"
                                        onClick={(e) => {
                                            if (!entry.id_e) return;
                                            e.stopPropagation();
                                        }}
                                    >
                                        <MenuAtom
                                            disabled={!entry.id_e}
                                            options={menuOptions}
                                        >
                                            <Icons.More
                                                color={
                                                    entry.id_e
                                                        ? undefined
                                                        : theme.gray200
                                                }
                                            />
                                        </MenuAtom>
                                    </span>
                                )}
                            </s.Flex>
                        </s.ActivityContainer>
                    </form>
                )}
            />
        </div>
    );
};
