import React from "react";

import ContainerModal from "../../../../components/molecules/modals/container-modal";
import { TPlanHistory } from "../../types";
import * as s from "./styles";

type TPlanHistoryModal = {
    setOpen: React.Dispatch<React.SetStateAction<TPlanHistory[]>>;
    history: TPlanHistory[];
};

export const PlanHistoryModal = ({ history, setOpen }: TPlanHistoryModal) => {
    const transformPlanValue = (value: number) => {
        if (!value) return "-";
        return (value / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    };

    return (
        <ContainerModal
            onOpenChange={(open) => {
                if (!open) {
                    setOpen([]);
                }
            }}
            closeIcon
        >
            <s.Container>
                <s.HeaderRow>
                    <div>Plano</div>
                    <div>Nº de usuários</div>
                    <div>Valor</div>
                    <div>Data de criação</div>
                </s.HeaderRow>
                <s.Rows>
                    {history.map((plan) => (
                        <s.Row>
                            <div>{plan.name}</div>
                            <div>{plan.numberOfUsers ?? "-"}</div>
                            <div>{transformPlanValue(plan.value)}</div>
                            <div>
                                {new Date(plan.date).toLocaleDateString(
                                    "pt-BR"
                                )}
                            </div>
                        </s.Row>
                    ))}
                </s.Rows>
            </s.Container>
        </ContainerModal>
    );
};
