import { useMutation } from "@tanstack/react-query";

import { changeRequestHoursStatus } from "../../services/hour-control";

type Params = {
    onSuccess?: () => void;
};

export function useChangeRequestHoursStatus({ onSuccess }: Params) {
    return useMutation(changeRequestHoursStatus, {
        onSuccess,
    });
}
