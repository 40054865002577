import dayjs from "dayjs";
import React from "react";

import { TReport } from "../../../../models";
import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalSendReportWrongConfirmProps = {
    report?: TReport;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickConfirm: () => void;
};

export const ModalSendReportWrongConfirm = ({
    report,
    setOpen,
    onClickConfirm,
}: ModalSendReportWrongConfirmProps) => {
    if (!report) return null;

    const month = dayjs(report.end_at).format("MMMM");

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Deseja enviar o relatório de {month}?</s.Title>
                <s.Text>
                    Parece que você está tentando enviando um relatório com{" "}
                    <strong>menos de 80% das horas totais completas</strong>.
                </s.Text>
                <s.Text>
                    Ao fechar o relatório você concorda com as informações
                    presentes neste documento e estará enviando para revisão,
                    depois de fechado não poderá editá-lo.
                </s.Text>
                <s.Text>
                    Verifique se esse é realmente o relatório do mês correto
                    antes de enviar.
                </s.Text>
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton onClick={onClickConfirm}>
                        Enviar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
