import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TArchiveActivity = {
    activityIds?: string[];
    projectId?: string;
};

export default async ({ activityIds, projectId }: TArchiveActivity = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/archive`,
        method: "post",
        body: {
            activityIds,
        },
        headers: {
            "x-project-id": projectId,
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
