import styled from "styled-components";

export const Container = styled.main`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 100%;
`;

export const Content = styled.section`
    margin-left: 40px;
`;

export const Title = styled.h1`
    color: var(--color-brand-purple-500, #663398);
    font-size: 34px;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0.085px;
`;

export const Explanation = styled.p`
    color: var(--color-gray-600, #6e697a);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.018px;
    width: 70%;
    margin-top: 15px;
`;
