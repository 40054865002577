import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TSimplifiedCreateOrganization = {
    email?: string;
    phone?: string;
    name?: string;
    organizationName?: string;
    password?: string;
    verificationToken?: string;
};

export default async (data: TSimplifiedCreateOrganization = {}) => {
    const bodyParams = data;

    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/organization/createSimplified`,
        method: "post",
        headers: tempHeaders,
        body: bodyParams,
    });
    const { body, statusCode } = httpResponse;

    const errors: { [key: string]: string } = {
        "user already exists": "Este e-mail já está cadastrado",
        "user already exists!": "Número de telefone já cadastrado",
        "voucher not exists or inactive": "Cupom inexistente ou inativo",
    };

    const errorFeedBacks = {
        400: errors[body.message] || "Erro ao criar organização",
        409: errors[body.message] || "Erro ao criar organização",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
