import styled from "styled-components";

export const Container = styled.div`
    padding: 40px;
    width: 700px;
`;

export const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    margin-bottom: 20px;
`;

export const Rows = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }
`;
