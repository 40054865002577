/* eslint-disable no-use-before-define */
type TAuthenticatedUser = {
    id_e: string;
};

type TLoginParams = {
    body: {
        user: TAuthenticatedUser;
        token: string | null;
        refresh_token: string | null;
    };
};

export class Auth {
    public unauthenticatedRoutes = [
        "",
        "login",
        "cadastro",
        "recuperar-senha",
        "nova-senha",
        "link-expirado",
        "convite",
        "registrar-empresa",
        "convite-admin",
        "sem-acesso",
        "registro-google",
        "sucesso-inativacao",
        "not-found",
        "manutencao",
        "indisponivel",
    ];

    private static instance: Auth;
    private authenticatedUser: TAuthenticatedUser | null;

    private constructor() {
        this.authenticatedUser = null;
    }

    public static getInstance(): Auth {
        if (!Auth.instance) {
            Auth.instance = new Auth();
        }
        return Auth.instance;
    }

    public isAuthenticated(): boolean {
        return this.authenticatedUser !== null;
    }

    public getAuthenticatedUser(): TAuthenticatedUser | null {
        return this.authenticatedUser;
    }

    public login({ body }: TLoginParams): void {
        this.authenticatedUser = body.user || this.authenticatedUser;
        this.token = body.token;
        this.refreshToken = body.refresh_token;
    }

    public clearLocalStorage(): void {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("haveOrganization");
    }

    public logout(reason?: string): void {
        this.authenticatedUser = null;
        sessionStorage.clear();
        this.clearLocalStorage();
        if (reason) {
            window.location.href = `/login?reason=${encodeURIComponent(
                reason
            )}`;
        } else {
            window.location.href = "/login";
        }
    }

    public logoutToTheInformationPages(reason?: string): void {
        this.authenticatedUser = null;
        sessionStorage.clear();
        this.clearLocalStorage();
        if (reason === "manutencao") {
            window.location.href = "/manutencao";
        }
        if (reason === "indisponivel") {
            window.location.href = "/indisponivel";
        }
    }

    public clearTokens(): void {
        this.token = null;
        this.refreshToken = null;
    }

    public get token(): string | null {
        return sessionStorage.getItem("token");
    }

    private set token(token: string | null) {
        if (!token) {
            sessionStorage.removeItem("token");
            return;
        }
        if (!this.authenticatedUser) return;
        sessionStorage.setItem("token", token);
    }

    public get refreshToken(): string | null {
        return localStorage.getItem("refreshToken");
    }

    private set refreshToken(refreshToken: string | null) {
        if (!refreshToken) {
            localStorage.removeItem("refreshToken");
            return;
        }
        if (!this.authenticatedUser) return;
        localStorage.setItem("refreshToken", refreshToken);
    }
}
