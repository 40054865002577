import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    width: 550px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);

    header {
        color: ${({ theme }) => theme.purple600};
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.024px;
    }

    section {
        display: flex;
        flex-direction: column;
        gap: 32px;
        color: ${({ theme }) => theme.gray600};
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;
        margin-top: 32px;

        div.inputButton {
            width: 177px;

            .input {
                height: 3rem;
            }
        }
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
        width: 100%;
        margin-top: 32px;
        font-size: 16px;

        .cancelButton {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1.2px solid #663398;
            background: #fff;
            cursor: pointer;
            color: ${({ theme }) => theme.purple500};
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.28px;
            text-transform: uppercase;
        }

        .confirmButton {
            display: flex;
            height: 48px;
            padding: 12px 24px;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1.2px solid #663398;
            background: ${({ theme }) => theme.purple600};
            color: #fff;
            font-size: 16px;
            cursor: pointer;
        }
    }
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    gap: 0.5rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;

    div {
        display: flex;
        gap: 15px;
        align-items: center;

        .flexible {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }

        .mandatory {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }

    .description {
        margin-left: 48px;
        color: ${({ theme }) => theme.gray400};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .flexibleOption {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
    }

    .mandatoryOption {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 8px;
    }

    label {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;
