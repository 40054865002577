import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type UpsertUserProjectBenefit = {
    value?: number;
    comment?: string;
    date?: string;
    userId?: string;
    projectId?: string;
    includeToBilling?: boolean;
};

export default async ({
    value,
    comment,
    date,
    projectId,
    userId,
    includeToBilling,
}: UpsertUserProjectBenefit = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
        "x-user-id": userId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/benefits`,
        method: "put",
        body: {
            value,
            comment,
            date,
            includeToBilling,
        },
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
