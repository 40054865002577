import styled from "styled-components";

export const Content = styled.form`
    width: 100%;
    padding: 1.5rem;
    min-height: 21rem;
    top: 2.9375rem;
    z-index: -10;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: ${({ theme }) => theme.white};

    .fields {
        display: grid;
        column-gap: 35px;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
    }
`;

export const historicWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
`;

export const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .field-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        span {
            min-width: 7.1875rem;
        }

        p {
            margin-right: auto;
        }
    }
`;

export const TextAreaWrapper = styled.div<{ isEditing?: boolean }>`
    margin-top: ${({ isEditing }) => (isEditing ? "21px" : "0px")};

    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .action-buttons {
        display: flex;
        gap: 1.25rem;

        button {
            padding: 0.5rem 1rem;
        }
    }
`;

export const FieldTitle = styled.span`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.gray400};
`;

export const TooltipContent = styled.span`
    font-size: 13px;
    color: ${({ theme }) => theme.gray600};
    padding: 5px 12px;
`;

export const FieldText = styled.div`
    width: 100%;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.gray900};

    .tag-container {
        width: 100%;
        margin-right: 1.5rem;
        position: relative;
        display: flex;
        align-items: center;

        span {
            width: fit-content;
            white-space: normal;
            word-break: break-all;
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }
        }

        &:hover span {
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
            transition: all 0.2s ease;
        }
    }
`;

export const DateInput = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    .close {
        cursor: pointer;
        border: none;
        border-radius: 50%;
        padding: 5px;

        &:hover {
            background-color: ${({ theme }) => theme.gray50};
        }
    }
`;
