import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-document-list"}
    >
        <title>{title}</title>
        <path
            d="M9.25 7.5V6.108C9.25 4.973 10.095 4.01 11.226 3.916C11.599 3.886 11.974 3.859 12.349 3.836M16.75 18H19C19.5967 18 20.169 17.7629 20.591 17.341C21.0129 16.919 21.25 16.3467 21.25 15.75V6.108C21.25 4.973 20.405 4.01 19.274 3.916C18.9 3.88498 18.5256 3.85831 18.151 3.836M18.151 3.836C18.009 3.3767 17.7226 2.97493 17.3357 2.68954C16.9489 2.40414 16.4808 2.25011 16 2.25H14.5C14.0192 2.25011 13.5511 2.40414 13.1643 2.68954C12.7774 2.97493 12.492 3.3767 12.35 3.836C12.285 4.046 12.25 4.269 12.25 4.5V5.25H18.25V4.5C18.25 4.269 18.216 4.046 18.151 3.836ZM16.75 18.75V16.875C16.75 15.9799 16.3944 15.1215 15.7615 14.4885C15.1285 13.8556 14.2701 13.5 13.375 13.5H11.875C11.5766 13.5 11.2905 13.3815 11.0795 13.1705C10.8685 12.9595 10.75 12.6734 10.75 12.375V10.875C10.75 9.97989 10.3944 9.12145 9.76149 8.48851C9.12855 7.85558 8.27011 7.5 7.375 7.5H6.25M7.75 7.5H5.875C5.254 7.5 4.75 8.004 4.75 8.625V20.625C4.75 21.246 5.254 21.75 5.875 21.75H15.625C16.246 21.75 16.75 21.246 16.75 20.625V16.5C16.75 14.1131 15.8018 11.8239 14.114 10.136C12.4261 8.44821 10.1369 7.5 7.75 7.5Z"
            stroke={color || theme.purple600}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
