import styled from "styled-components";

import { theme } from "../../../../assets";

export const Content = styled.div`
    box-shadow: 0px 2px 8px rgba(14, 2, 26, 0.2);
    border-radius: 4px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 400px;

    svg {
        cursor: pointer;
    }
`;
export const Title = styled.h1`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const PopoverTriggerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
        width: 100%;
    }
`;

export const Activity = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.gray600};
`;

export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 12.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
`;

export const Option = styled.div<{ color?: string }>`
    div {
        display: flex;
        flex-direction: row;
        width: max-content;
        border-radius: 4px;
        padding: 4px 7px 4px 12px;
        margin-right: auto;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: 100%;
        border: 1px solid ${({ color }) => color || theme.purple500}1A;
        background: ${({ color }) => color || theme.purple500}0D;
        max-width: 320px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        color: ${({ color }) => color};

        &:hover {
            background: ${({ color }) => color || theme.purple500}1A;
        }

        &::before {
            content: "";
            display: flex;
            min-width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ color }) => color || theme.purple500};
        }

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
    gap: 10px;

    button {
        width: 100px;
    }
`;
