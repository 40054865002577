import { View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { theme } from "../../../../../../assets";
import { TProject } from "../../../../../../models";
import * as s from "../../../styled-pdf-template";

type HeaderProps = {
    project: TProject;
};

export const Header = ({ project }: HeaderProps) => {
    return (
        <View style={s.PDFTableSummaryReport.Project}>
            <View style={s.PDFTableSummaryReport.ProjectHeader}>
                <View
                    style={{
                        ...s.PDFTableSummaryReport.Section,
                        borderBottom: `1px solid ${theme.gray100}`,
                    }}
                >
                    <View
                        style={[
                            s.PDFTableSummaryReport.HeaderColumn,
                            {
                                width: "50%",
                            },
                        ]}
                    >
                        <Text style={s.PDFTableSummaryReport.TableBodyText}>
                            CLIENTE
                        </Text>
                        <Text style={s.PDFTableSummaryReport.HeaderValueText}>
                            {project.Client?.name}
                        </Text>
                    </View>

                    <View
                        style={[
                            s.PDFTableSummaryReport.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray200}`,
                                width: "40%",
                            },
                        ]}
                    >
                        <Text style={s.PDFTableSummaryReport.TableBodyText}>
                            PROJETO
                        </Text>
                        <Text style={s.PDFTableSummaryReport.HeaderValueText}>
                            {project.name}
                        </Text>
                    </View>

                    <View
                        style={[
                            s.PDFTableSummaryReport.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray200}`,
                                width: "30%",
                            },
                        ]}
                    >
                        <Text style={s.PDFTableSummaryReport.TableBodyText}>
                            DATA DE EMISSÃO
                        </Text>
                        <Text style={s.PDFTableSummaryReport.HeaderValueText}>
                            {dayjs().format("DD/MM/YYYY")}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
};
