import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";

import { theme } from "../../../assets";
import { Breadcrumb, ModalAction, Steps } from "../../../components";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { TProject } from "../../../models";
import { projects, sectors, manageUser } from "../../../services";
import { Step1Project, Step2Project } from "./components/project-steps";
import * as s from "./styled-projects-form";

export type IProject = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    color?: string;
    start_at?: Date;
    end_at?: Date;
    projectId?: string;
    sectorsId?: string[];
    usersId?: string[];
    reviewersId?: string[];
    scopePercentage?: number;
    cost?: number;
    estimated_hours?: string;
    status?: string;
    technologies?: string;
};

type TUser = {
    id_e: string;
    avatar?: string;
    name?: string;
    role?: string;
    Permissions: string[];
};

type TSector = {
    id_e: string;
    Projects?: [
        {
            color?: string;
            id_e?: string;
            name?: string;
        }
    ];
    Users: TUser[];
    created_at?: string;
    description?: string;
    name?: string;
};

export const ProjectsForm = () => {
    const {
        user: { Permissions: userPerms },
        refreshProject,
    } = useUser();
    const getAllSectors = userPerms.includes("all_sectors");
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const { project }: { project: TProject } = location.state || "";

    const [data, setData] = useState<IProject>();
    const [steps, setSteps] = useState<1 | 2>(1);
    const [done, setIsDone] = useState(false);

    const [sectorsData, setSectorsData] = useState<TSector[]>([]);
    const [linkedSectors, setLinkedSectors] = useState<string[]>([]);
    const [billersData, setBillersData] = useState<TUser[]>([]);
    const [projectTeam, setProjectTeam] = useState<TUser[]>([]);
    const [projectReviewer, setProjectReviewer] = useState<TUser[]>([]);
    const [projectBiller, setProjectBiller] = useState<TUser[]>([]);

    const [sectorsError, setSectorsError] = useState<boolean>(false);
    const [teamError, setTeamError] = useState<boolean>(false);
    const [reviewerError, setReviewerError] = useState<boolean>(false);
    const [billerError, setBillerError] = useState<boolean>(false);

    const fileSuccess =
        !sectorsError && !teamError && !reviewerError && !billerError;

    const [color, setColor] = useState<string>(theme.gray400);

    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);

    const getSectors = useFetch({
        fn: sectors.getSector,
        start: false,
    });

    const getUsers = useFetch({
        fn: manageUser.getAllUsers,
        start: false,
    });

    const createProject = useFetch({
        fn: projects.createProject,
        start: false,
    });

    const updateProject = useFetch({
        fn: projects.updateProject,
        start: false,
    });

    const deleteProject = useFetch({
        fn: projects.deleteProject,
        start: false,
    });

    const handleVerifyColor = (color: string) => {
        let resp = "";
        const colors = [
            "#0B49EC",
            theme.pink1,
            theme.orange,
            "#9D0000",
            "#03BA78",
            theme.green1,
            "#0CB8B8",
            "#FF015C",
            "#9933FF",
            "#824700",
            "#800380",
            "#4B07BF",
            "#D14141",
            "#8BB908",
            "#333333",
        ];

        if (color) resp = color;
        else resp = colors[Math.floor(Math.random() * colors.length)];
        return resp;
    };

    const verifyErrors = () => {
        if (linkedSectors.length < 1) setSectorsError(true);
        else setSectorsError(false);

        if (projectTeam.length < 1) setTeamError(true);
        else setTeamError(false);

        if (projectReviewer.length < 1) setReviewerError(true);
        else setReviewerError(false);

        if (projectBiller.length < 1) setBillerError(true);
        else setBillerError(false);
    };

    const storeData = <IProject,>(data: IProject) => {
        setData((prevState) => ({ ...prevState, ...data }));
    };

    const handleDeleteProject = () => {
        deleteProject.onRefresh({
            projectId: project?.id_e,
        });
    };

    const handleSubmitProject = (operation: "create" | "update") => {
        if (data) {
            if (data.start_at === undefined) {
                data.start_at = new Date();
            }
        }

        switch (operation) {
            case "create":
                createProject.onRefresh({
                    name: data?.name,
                    clientId: data?.client?.value,
                    description: data?.description,
                    color: handleVerifyColor(color),
                    estimatedHours: data?.estimated_hours
                        ? parseInt(data.estimated_hours, 10)
                        : undefined,
                    start_at: data?.start_at,
                    end_at: data?.end_at,
                    sectorsId: linkedSectors?.join(", "),
                    scopePercentage: data?.scopePercentage,
                    cost: data?.cost?.toString(),
                    usersId: projectTeam
                        ?.map((col: TUser) => col.id_e)
                        .join(", "),
                    reviewersId: projectReviewer
                        ?.map((rev: TUser) => rev.id_e)
                        .join(", "),
                    billersId: projectBiller
                        ?.map((biller: TUser) => biller.id_e)
                        .join(", "),
                    status: data?.status,
                    technologies: data?.technologies,
                });

                break;
            case "update":
                updateProject.onRefresh({
                    name: data?.name,
                    clientId: data?.client?.value,
                    description: data?.description,
                    color: data?.color,
                    start_at: data?.start_at,
                    end_at: data?.end_at,
                    scopePercentage: data?.scopePercentage,
                    cost: data?.cost?.toString(),
                    projectId: project?.id_e,
                    estimatedHours: data?.estimated_hours
                        ? parseInt(data.estimated_hours, 10)
                        : undefined,
                    sectorsId: linkedSectors?.join(", "),
                    usersId: projectTeam?.map((t) => t.id_e).join(", "),
                    reviewersId: projectReviewer
                        ?.map((rev: TUser) => rev.id_e)
                        .join(", "),
                    billersId: projectBiller
                        ?.map((biller: TUser) => biller.id_e)
                        .join(", "),
                });

                break;
            default:
                toast.success(`Operação ${operation} inválida!`);
        }

        setIsDone(false);
    };

    useEffect(() => {
        getSectors.onRefresh({
            all: getAllSectors,
            users: true,
            projects: true,
        });
        getUsers.onRefresh({
            searchPermission: "bill_reports",
        });
    }, []);

    useEffect(() => {
        if (done) {
            setLoading(true);
            if (fileSuccess) handleSubmitProject("create");
            setTimeout(() => setLoading(false), 2000);
        }
    }, [done]);

    useEffect(() => {
        if (getSectors.error) toast.error(`${getSectors.error}`);
        if (getSectors.response) {
            setSectorsData(getSectors.response.sectors);
        }
    }, [getSectors.response]);

    useEffect(() => {
        if (getUsers.error) toast.error(`${getUsers.error}`);
        if (getUsers.response) {
            setBillersData(getUsers.response);
        }
    }, [getUsers.response]);

    useEffect(() => {
        if (createProject.error) toast.error(`${createProject.error?.message}`);
        if (createProject.response) {
            toast.success("Projeto criado com sucesso!");
            window.scrollTo(0, 0);
            navigate("/projetos");
            refreshProject({
                sectors: true,
                showOnlyActiveProjects: true,
            });
        }
    }, [createProject.response]);

    useEffect(() => {
        if (updateProject.error) toast.error(`${updateProject.error?.message}`);
        if (updateProject.response) {
            toast.success("Projeto atualizado com sucesso!");
            window.scrollTo(0, 0);
            navigate("/projetos");
            refreshProject({
                sectors: true,
                showOnlyActiveProjects: true,
            });
        }
    }, [updateProject.response]);

    useEffect(() => {
        if (deleteProject.error) toast.error(`${deleteProject.error?.message}`);
        if (deleteProject.response) {
            toast.success("Projeto deletado com sucesso!");
            setShowDialogDelete(false);
            window.scrollTo(0, 0);
            navigate("/projetos");
        }
    }, [deleteProject.response]);

    return (
        <s.Container>
            <s.BreadcrumbContainer>
                <Breadcrumb defaultRoute="/projetos" location={location} />
            </s.BreadcrumbContainer>
            {showDialogDelete && (
                <ModalAction
                    setOpen={setShowDialogDelete}
                    title={"Deseja mesmo deletar o projeto?"}
                    description={"Essa ação não pode ser revertida"}
                    primaryAction={handleDeleteProject}
                    secondaryAction={() => setShowDialogDelete(false)}
                    primaryText={"Cancelar"}
                    secondaryText={"Deletar"}
                />
            )}

            <Steps
                steps={[{ stepName: "Dados" }, { stepName: "Time" }]}
                actualStep={steps}
            />

            <div style={{ display: steps === 1 ? "block" : "none" }}>
                <Step1Project storeData={storeData} manageSteps={setSteps} />
            </div>

            <div style={{ display: steps === 2 ? "block" : "none" }}>
                <Step2Project
                    storeData={storeData}
                    manageSteps={setSteps}
                    sectorsData={sectorsData}
                    billersData={billersData}
                    loading={loading}
                    color={color}
                    setColor={setColor}
                    setLinkedSectors={setLinkedSectors}
                    setProjectTeam={setProjectTeam}
                    setProjectReviewer={setProjectReviewer}
                    setProjectBiller={setProjectBiller}
                    linkedSectors={linkedSectors}
                    projectTeam={projectTeam}
                    projectReviewer={projectReviewer}
                    projectBiller={projectBiller}
                    setIsDone={setIsDone}
                    sectorsError={sectorsError}
                    verifyErrors={verifyErrors}
                    fileSuccess={fileSuccess}
                />
            </div>
        </s.Container>
    );
};
