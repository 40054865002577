import styled from "styled-components";

export const Container = styled.div`
    width: 360px;
    height: 194px;
    padding: 25px;

    h1 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: #503479;
        margin-bottom: 8px;
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: #6e697a;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 16px;
`;
