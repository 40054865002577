import * as yup from "yup";

import { validationErrors } from "../../../../../../utils";

const { client } = validationErrors;

export default () =>
    yup.object().shape({
        name: yup
            .string()
            .required("O nome do projeto é obrigatório")
            .min(3, "O nome do projeto deve ter no mínimo 3 caracteres")
            .max(200, "O nome do projeto deve ter menos de 200 caracteres")
            .trim(),
        client: yup
            .object()
            .shape({
                label: yup.string().required(client.required),
            })
            .nullable(),
        description: yup.string(),
        start_at: yup.string(),
        end_at: yup
            .string()
            .test(
                "end_at",
                "A data de fim deve ser maior ou igual à data de início",
                function (value) {
                    if (!value) return true;
                    const { start_at } = this.parent;
                    return new Date(value) >= new Date(start_at);
                }
            ),
        scopePercentage: yup
            .string()
            .typeError("Escopo precisa ser um número!"),
        cost: yup.string(),
    });
