import React, { SetStateAction } from "react";
import { useLocation } from "react-router-dom";

import { Paper, PrimaryButton, SecondaryButton } from "../..";
import * as s from "./styled-header";

export type THeader = {
    myTheme: string | null;
    setMyTheme: React.Dispatch<SetStateAction<string | null>>;
    id?: string;
};

const Header = ({ id, myTheme, setMyTheme }: THeader) => {
    const location = useLocation();
    const isSmartphone = window.innerWidth < 768;

    const scrollTo = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    };

    return (
        <s.Container
            style={
                location.pathname.includes("/convite") ||
                location.pathname.includes("/link-expirado") ||
                location.pathname.includes("/registrar-empresa") ||
                location.pathname.includes("/convite-admin")
                    ? { display: "none" }
                    : {}
            }
            home={location.pathname === "/"}
        >
            <s.Nav id={id}>
                <a href="/">
                    <Paper
                        title={"Paper On"}
                        myTheme={myTheme}
                        setMyTheme={setMyTheme}
                    />
                </a>
                <s.FlexRow>
                    {location.pathname === "/" && (
                        <>
                            {isSmartphone ? (
                                <s.FlexRow
                                    style={{
                                        width: "5rem",
                                        justifyContent: "center",
                                    }}
                                >
                                    <a href="https://blog.paperon.app/conteudo">
                                        <s.StyledSecondaryButton variation="link">
                                            BLOG
                                        </s.StyledSecondaryButton>
                                    </a>
                                </s.FlexRow>
                            ) : (
                                <s.FlexRow style={{ width: "32rem" }}>
                                    <s.StyledSecondaryButton
                                        variation="link"
                                        onClick={() => scrollTo("section3")}
                                    >
                                        BENEFÍCIOS
                                    </s.StyledSecondaryButton>
                                    <s.StyledSecondaryButton
                                        variation="link"
                                        onClick={() => scrollTo("section5")}
                                    >
                                        PREÇOS
                                    </s.StyledSecondaryButton>
                                    <s.StyledSecondaryButton
                                        variation="link"
                                        onClick={() => scrollTo("section6")}
                                    >
                                        FAQ
                                    </s.StyledSecondaryButton>
                                    <s.StyledSecondaryButton
                                        variation="link"
                                        onClick={() =>
                                            scrollTo("contact-content")
                                        }
                                    >
                                        CONTATO
                                    </s.StyledSecondaryButton>
                                    <a
                                        href="https://blog.paperon.app/conteudo"
                                        style={{
                                            width: "fit-content",
                                        }}
                                    >
                                        <s.StyledSecondaryButton variation="link">
                                            BLOG
                                        </s.StyledSecondaryButton>
                                    </a>

                                    <s.StyledSecondaryButton
                                        variation="link"
                                        onClick={() =>
                                            scrollTo("social-content")
                                        }
                                    >
                                        PARCEIROS
                                    </s.StyledSecondaryButton>
                                </s.FlexRow>
                            )}
                        </>
                    )}
                    <s.FlexRow style={{ width: "16rem" }}>
                        <a style={{ width: "70%" }} href="/login">
                            <SecondaryButton
                                style={{
                                    height: isSmartphone ? "30px" : undefined,
                                }}
                            >
                                ENTRAR
                            </SecondaryButton>
                        </a>

                        {!location.pathname.includes("/cadastro") && (
                            <a style={{ width: "100%" }} href="/cadastro">
                                <PrimaryButton
                                    id="register-button"
                                    style={{
                                        height: isSmartphone
                                            ? "30px"
                                            : undefined,
                                    }}
                                >
                                    CADASTRE-SE
                                </PrimaryButton>
                            </a>
                        )}
                    </s.FlexRow>
                </s.FlexRow>
            </s.Nav>
        </s.Container>
    );
};

export default Header;
