import { Location } from "react-router-dom";

import { theme } from "../../assets";
import { Auth } from "../../services/auth/Authentication";

export const redirectDefaultRoute = (permissions: string[]) => {
    let defaultRoute = "";

    const sectorsPermission = permissions.includes("all_sectors");
    const rolesPermission = permissions.includes("view_roles");
    const projectsPermission = permissions.includes("manage_projects");
    const organizationPermission = permissions.includes("manage_organization");

    if (organizationPermission) defaultRoute = "/demonstrativos";
    if (projectsPermission) defaultRoute = "/projetos";
    if (rolesPermission) defaultRoute = "/atuacoes";
    if (sectorsPermission) defaultRoute = "/setores";

    return defaultRoute;
};

export const handleUnauthenticatedRoutes = (location: Location) => {
    const currentUrl = location.pathname.split("/");

    if (Auth.getInstance().unauthenticatedRoutes.includes(currentUrl[1])) {
        document.body.style.backgroundColor = theme.white;
        return true;
    }

    document.body.style.backgroundColor = theme.white2;
    return false;
};
