import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TDeleteAttachment = {
    attachmentId?: string;
    activityId?: string;
};

export default async ({ attachmentId, activityId }: TDeleteAttachment = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/attachment/delete`,
        method: "delete",
        body: {},
        headers: {
            auth: true,
            "x-attachment-id": attachmentId,
            "x-activity-id": activityId,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
