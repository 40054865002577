import styled from "styled-components";

type TOption = {
    selected?: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 0.0625rem solid ${({ theme }) => theme.gray200};
    border-radius: 0.25rem;
    gap: 16px;
    padding: 16px;
    width: 364px;

    header {
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: center;

        h1 {
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.005em;
            color: ${({ theme }) => theme.purple600};
        }
    }
`;

export const Body = styled.footer`
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 16px;
    width: 100%;

    div.row {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 60px;
        span.text {
            font-size: 12px;
            text-align: left;
            color: ${({ theme }) => theme.gray500};
        }
    }
    div.chat-box {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 104px;
    }

    div.instruction {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: ${({ theme }) => theme.pink50};
        align-items: center;
        justify-content: center;
        border-radius: 0.25rem 0.25rem 0 0;
        height: 64px;
        gap: 16px;

        span {
            font-size: 12px;
            color: ${({ theme }) => theme.gray800};
        }
    }

    div.loading-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7rem;
    }

    div#buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;

        button {
            width: 100%;
        }
    }
`;
