import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 25.5rem;
    padding: 1rem;
    gap: 2rem;

    h1 {
        font-size: 14px;
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
    }

    p {
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;

        button {
            padding: 0 1.5rem;
            width: fit-content;
        }
    }
`;
