import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

import { Icons, FotoUser } from "../../../assets";
import * as s from "./styled-input-foto";

type TInputFoto = {
    setFoto?: Dispatch<SetStateAction<Blob | File | string | undefined>>;
    name?: string;
    value?: Blob;
    onChange: (value: React.ChangeEvent) => void;
    color?: string;
    avatar?: string;
};

const InputFoto = React.forwardRef<HTMLInputElement, TInputFoto>(
    ({ name, onChange, color, avatar }, ref) => {
        const [base64, setBase64] = useState<string>();

        const data64 = (file: Blob) => {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event: ProgressEvent<FileReader>) => {
                    const resultado = event.target?.result;

                    if (resultado) {
                        setBase64(resultado.toString());
                    }
                };
            }
        };

        return (
            <s.FlexColumnCenter>
                <s.Img src={base64 || avatar || FotoUser} />

                <s.Button image={!!base64} type="button">
                    <s.Label>
                        <s.Input
                            ref={ref}
                            name={name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const { files } = e.target;

                                if (files) {
                                    onChange(e);
                                    data64(files[0]);
                                }
                            }}
                            type="file"
                            accept="image/png, image/jpeg"
                        />
                        <Icons.Camera color={color} />
                    </s.Label>
                </s.Button>
            </s.FlexColumnCenter>
        );
    }
);

export default InputFoto;
