import { Icons, theme } from "../../../assets";
import { Level } from "../../../models";
import { levelsMapper, levelsPointsRangeMapper } from "../../../utils";
import { PopoverRadix } from "../../atoms";
import { LevelCircle } from "../../atoms/level-circle/level-circle";
import * as s from "./styles";

type LevelsScore = {
    level: Level;
    title: string;
    points: string;
};

type LevelsCardProps = {
    children: JSX.Element;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LevelsCard = ({
    children,
    isOpen,
    setIsOpen,
}: LevelsCardProps) => {
    return (
        <PopoverRadix
            trigger={children}
            align="start"
            sideOffset={5}
            alignOffset={23}
            open={true}
            closeIcon={
                isOpen ? (
                    <Icons.XOutline
                        color={theme.gray600}
                        onClick={() => setIsOpen(false)}
                    />
                ) : undefined
            }
        >
            <s.Container open={isOpen}>
                <s.Content>
                    {Array.from({ length: 10 }).map((_, level) => {
                        const currentLevel = (level + 1) as Level;
                        return (
                            <s.LevelContainer>
                                <LevelCircle
                                    level={currentLevel}
                                    size="large"
                                />
                                <s.LevelInformation>
                                    <s.LevelTitle level={currentLevel}>
                                        Nível {levelsMapper[currentLevel]}
                                    </s.LevelTitle>
                                    <s.LevelPointsRange>
                                        {levelsPointsRangeMapper[currentLevel]}
                                    </s.LevelPointsRange>
                                </s.LevelInformation>
                            </s.LevelContainer>
                        );
                    })}
                </s.Content>
            </s.Container>
        </PopoverRadix>
    );
};
