import * as yup from "yup";

export const schema = yup.object({
    title: yup
        .string()
        .required("Título é obrigatório")
        .max(100, "Deve conter no máximo 100 caracteres"),
    sub_title: yup.string().max(200, "Deve conter no máximo 200 caracteres"),
    description: yup
        .string()
        .required("Descrição é obrigatória")
        .max(2000, "Deve conter no máximo 2000 caracteres"),
    path_to_go: yup.string().max(200, "Deve conter no máximo 200 caracteres"),
    type: yup.string().required("Tipo é obrigatório"),
    button_text: yup.string(),
});
