import { useEffect, useState } from "react";

import { Pagination } from "../../../components";
import { useReadDemonstratives } from "../../../hooks/react-query/use-read-demonstratives";
import { DemonstrativeTable } from "./components/demonstrative-table";
import * as s from "./styled-demonstrative";

interface IDemonstrativeProps {
    searchValue?: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showArchived: boolean;
}

export const Demonstrative = ({
    searchValue,
    setLoading,
    showArchived,
}: IDemonstrativeProps) => {
    const [filterType, setFilterType] = useState<
        "late" | "onTime" | "finished" | "noInformation" | "all" | undefined
    >(undefined);
    const [page, setPage] = useState(1);

    const readDemonstratives = useReadDemonstratives({
        page,
        length: 6,
        searchTerm: searchValue,
        filterType,
        archived: showArchived,
    });

    useEffect(() => {
        readDemonstratives.refetch();
    }, [page]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            readDemonstratives.refetch();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (filterType !== undefined) {
            readDemonstratives.refetch();
        }
    }, [filterType]);

    useEffect(() => {
        readDemonstratives.refetch();
    }, [showArchived]);

    return (
        <s.Container>
            <DemonstrativeTable
                loading={readDemonstratives?.isLoading}
                setLoading={setLoading}
                projects={readDemonstratives?.data?.projects || []}
                setFilterType={setFilterType}
                filterType={filterType}
            />
            <Pagination
                id="pagination"
                disabled={readDemonstratives?.isLoading}
                page={page - 1}
                onClick={({ selected }) => {
                    setPage(selected + 1);
                }}
                totalPages={readDemonstratives?.data?.totalPages}
            />
        </s.Container>
    );
};
