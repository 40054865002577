import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type ToggleVoucherActivationParams = {
    active?: boolean;
    voucherId?: string;
};

export default async ({
    active,
    voucherId,
}: ToggleVoucherActivationParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/vouchers/`,
        method: "patch",
        headers: {
            auth: true,
            "x-voucher-id": voucherId,
        },
        body: {
            active,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
