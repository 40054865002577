import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons } from "../../../../../../assets";
import {
    DayPicker,
    Input,
    PrimaryButton,
    SelectClient,
    TextArea,
    SecondaryButton,
    SelectInput,
} from "../../../../../../components";
import { useUser } from "../../../../../../context";
import { useFetch } from "../../../../../../hooks";
import { TOption, TProject } from "../../../../../../models";
import { manageClients } from "../../../../../../services";
import { formatToBRMoney, onlyNumbers } from "../../../../../../utils";
import { IProject } from "../../../projects-form";
import * as s from "../styled-steps";
import validation from "./validation";

dayjs.extend(utc);

type TStep1 = {
    storeData: <IProject>(data: IProject) => void;
    manageSteps: Dispatch<SetStateAction<1 | 2>>;
};
type TFields = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    start_at?: Date;
    end_at?: Date;
    status?: string;
    estimated_hours: string;
    scopePercentage: string;
    cost?: string;
    technologies?: string;
};

const Step1Project = ({ storeData, manageSteps }: TStep1) => {
    const {
        user: { Permissions: userPerms },
    } = useUser();
    const manageCostsAllowed = userPerms.includes("manage_cost");
    const navigate = useNavigate();
    const location = useLocation();
    const [clientsOptions, setClientsOptions] = useState<TOption[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const { project }: { project: TProject } = location.state || "";

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<TFields>({
        resolver: yupResolver(validation()),
    });

    const statusOptions = [
        {
            label: "A iniciar",
            value: "to_start",
        },
        {
            label: "Em andamento",
            value: "in_progress",
        },
        {
            label: "Atrasado",
            value: "late",
        },
        {
            label: "Pausado",
            value: "paused",
        },
        {
            label: "Cancelado",
            value: "canceled",
        },
        {
            label: "Concluído",
            value: "finished",
        },
        {
            label: "Sem informação",
            value: "no_information",
        },
    ];

    const getClients = useFetch({
        fn: manageClients.getClients,
        start: true,
        params: {
            active: true,
        },
    });

    const handleClientSelectValue = () => {
        let resp: TOption | undefined = {
            label: "Selecione o cliente",
            value: "",
        };
        if (getValues("client")) resp = getValues("client");
        return resp;
    };

    const clearsClientSelect = () => {
        setValue("client", undefined);
    };

    const verifyDates = () => {
        const start_at = getValues("start_at") as Date;
        const end_at = getValues("end_at") as Date;
        const startDate = new Date(start_at);
        const endDate = new Date(end_at);

        if (endDate < startDate) {
            toast.error("Data de início maior que a de fim do projeto!");
        }
    };

    const onSubmit = (data: TFields) => {
        let newData = {
            ...data,
            status: data.status,
            estimatedHours: parseInt(data.estimated_hours, 10),
            scopePercentage: Number(onlyNumbers(data.scopePercentage)),
        } as IProject;
        if (data.cost) {
            newData = {
                ...newData,
                cost: Number(onlyNumbers(data.cost)) / 100,
            };
        }

        storeData(newData);
        manageSteps(2);
        window.scrollTo(0, 0);
    };

    const valueToStartAt = (value?: Date) => {
        let date;

        if (value) {
            date = dayjs(value);
            if (date.isValid()) {
                date = dayjs(value).utc(false).add(3, "hours").toDate();
            } else {
                date = new Date();
            }
        }

        if (!value) date = dayjs().startOf("day").toDate();

        return date;
    };

    useEffect(() => {
        if (getClients.error) toast.error(`${getClients.error}`);
        if (getClients.response) {
            const tempClientsOptions: TOption[] = [];
            getClients.response.forEach(
                (client: { id_e: string; name: string }) =>
                    tempClientsOptions.push({
                        value: client.id_e,
                        label: client.name,
                    })
            );
            setClientsOptions(tempClientsOptions);
            setLoading(false);
        }
    }, [getClients.response]);

    return (
        <s.Form onSubmit={handleSubmit(onSubmit)}>
            <s.FirstStepFieldsWrapper>
                <div className="container-input">
                    <Input
                        {...register("name")}
                        label="Nome do projeto *"
                        mask=""
                        placeholder="Digite aqui o nome do projeto que você está criando"
                        error={errors.name?.message}
                    />
                </div>

                <div className="container-input">
                    <Controller
                        name="client"
                        control={control}
                        render={({ field: { ref, onChange } }) => (
                            <SelectClient
                                value={handleClientSelectValue()}
                                title={"Nome do Cliente *"}
                                placeholder={"Selecione o cliente"}
                                options={clientsOptions}
                                inputRef={ref}
                                onChange={(val: TOption) => {
                                    onChange(val);
                                }}
                                positionY={"2.3"}
                                buttonName={"Novo Cliente"}
                                noOptionsMessage={"Cliente não encontrado"}
                                getClients={getClients}
                                clearsClientSelect={clearsClientSelect}
                                error={errors.client?.label?.message}
                            />
                        )}
                    />
                </div>

                <div className="container-input">
                    <TextArea
                        {...register("description")}
                        label="Descrição do projeto"
                        placeholder="Digite aqui uma pequena descrição sobre este projeto"
                        disabled={loading}
                        charCount
                        counter={watch("description")}
                        maxLength={300}
                    />
                </div>

                <div className="container-input">
                    <TextArea
                        {...register("technologies")}
                        label="Tecnologias do projeto (se houver)"
                        placeholder="Digite aqui as tecnologias que serão utilizadas para o desenvolvimento do projeto. Exemplos: Python, Javascript, AWS..."
                        disabled={loading}
                        charCount
                        counter={watch("technologies")}
                        maxLength={300}
                    />
                </div>

                <div className="side-by-side-fields">
                    <Controller
                        name="start_at"
                        control={control}
                        defaultValue={valueToStartAt()}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <DayPicker
                                    label={"Início do projeto"}
                                    placeholder="--/--/----"
                                    onSelect={(date) => {
                                        onChange(date);

                                        verifyDates();
                                    }}
                                    value={valueToStartAt(value)}
                                    error={errors.start_at?.message}
                                    toYear={new Date().getFullYear() + 10}
                                />
                            );
                        }}
                    />

                    <Controller
                        name="end_at"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DayPicker
                                label={"Fim do projeto"}
                                placeholder="--/--/----"
                                value={value}
                                onSelect={(date) => {
                                    onChange(date);

                                    verifyDates();
                                }}
                                error={errors.end_at?.message || ""}
                                toYear={new Date().getFullYear() + 10}
                            />
                        )}
                    />
                </div>

                <div className="side-by-side-fields">
                    <Controller
                        name={"status"}
                        control={control}
                        render={({ field: { ref, onChange } }) => (
                            <SelectInput
                                icon={<Icons.Chevron />}
                                inputRef={ref}
                                options={statusOptions}
                                positionY="0.4"
                                placeholder={"Selecione o status"}
                                onChange={(val: { value: string }) => {
                                    onChange(val.value);
                                }}
                                label="Status"
                                noError
                            />
                        )}
                    />
                    <Input
                        {...register("estimated_hours")}
                        label="Horas estimadas"
                        mask="9999999"
                        placeholder="1200:00"
                        defaultValue={project?.estimatedHours}
                    />
                </div>

                <div className="side-by-side-fields">
                    <Input
                        {...register("scopePercentage")}
                        label="Escopo atual"
                        mask="99%"
                        placeholder="20%"
                        defaultValue={project?.scopePercentage}
                        error={errors.scopePercentage?.message}
                        className="scope"
                    />
                    {manageCostsAllowed && (
                        <Input
                            label="Custo total previsto"
                            placeholder="R$ 100.000.000,00"
                            defaultValue={project?.cost}
                            value={
                                watch("cost")
                                    ? formatToBRMoney(
                                          Number(
                                              onlyNumbers(watch("cost") || "")
                                          ) / 100
                                      )
                                    : undefined
                            }
                            onChange={({ target: t }) => {
                                const justNumbers = onlyNumbers(t.value);
                                setValue("cost", justNumbers);
                            }}
                        />
                    )}
                </div>
            </s.FirstStepFieldsWrapper>

            <div className="button-group">
                <PrimaryButton type="submit">Continuar</PrimaryButton>
                <SecondaryButton
                    onClick={() => {
                        navigate("/projetos");
                    }}
                    type="button"
                >
                    Voltar
                </SecondaryButton>
            </div>
        </s.Form>
    );
};

export default Step1Project;
