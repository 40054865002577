import { useEffect, useState } from "react";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../components";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { TProject, TResponse } from "../../../../../../models";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TSectionActivity } from "../../types";
import * as s from "./styled-add-collaborators-modal";

type TAddCollaboratorsModal = {
    activity?: TSectionActivity;
    setActivity: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    project: TProject;
    updateActivity?: TResponse<unknown, TUpdateActivity>;
    updateActivityStates?: string[];
    onSuccess?: (data: {
        activityIds: (string | undefined)[];
        users: string[];
        sectionId?: string;
    }) => void;
};

export const AddCollaboratorsModal = ({
    activity,
    project,
    setActivity,
    updateActivity,
    updateActivityStates,
    onSuccess,
}: TAddCollaboratorsModal) => {
    const [checked, setChecked] = useState<string[]>(
        activity?.Users?.map((user) => user?.User?.id_e) || []
    );
    const [search, setSearch] = useState<string>("");
    const [users, setUsers] = useState<TProject["Users"]>([]);

    const filteredUsers = project.Users?.filter((user) => {
        const searchLower = search.toLowerCase();
        return (
            user.name.toLowerCase().includes(searchLower) ||
            user.role.toLowerCase().includes(searchLower) ||
            user.Sectors?.map((sector) => sector.name)
                .join(", ")
                ?.includes(searchLower)
        );
    });

    const toggleChecked = (id: string) => {
        if (checked.includes(id)) {
            setChecked(checked.filter((item) => item !== id));
            return;
        }
        setChecked([...checked, id]);
    };

    const save = () => {
        if (onSuccess) {
            onSuccess({
                activityIds: [activity?.id_e],
                users: checked,
                sectionId: activity?.activitySection_id,
            });
            return;
        }
        const isCreating = !activity?.id_e && activity;
        const isEditing =
            activity?.id_e && updateActivityStates?.includes(activity?.id_e);
        if ((isCreating || isEditing) && users?.length) {
            setActivity({
                ...activity,
                Users: project.Users?.filter((user) =>
                    checked.includes(user.id_e)
                )?.map((user) => ({
                    User: {
                        id_e: user.id_e,
                        name: user.name,
                        avatar: user.avatar,
                    },
                })) as TSectionActivity["Users"],
            });
            setTimeout(() => {
                setActivity(undefined);
            }, 500);
            return;
        }
        updateActivity?.onRefresh({
            activityIds: [activity?.id_e],
            users: checked,
            sectionId: activity?.activitySection_id,
        });
    };

    useEffect(() => {
        if (project?.Users) {
            setUsers(project?.Users);
        }
    }, [project]);

    return (
        <ContainerModal onCloseAction={() => setActivity(undefined)} closeIcon>
            <s.Container>
                <s.Title>Profissionais</s.Title>
                <Input
                    mask=""
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    placeholder="Pesquise por profissional, atuação ou setor"
                    icon={<Icons.SearchOutline color={theme.gray400} />}
                />
                <s.ProjectTeam>
                    <s.ProjectTeamTitle>Time do projeto</s.ProjectTeamTitle>
                    <s.ProjectTeamList>
                        {filteredUsers?.map((user) => (
                            <Tooltip
                                key={user.id_e}
                                side="top"
                                align="start"
                                content={
                                    <s.ProjectTeamListNameTooltip>
                                        {user.name}
                                    </s.ProjectTeamListNameTooltip>
                                }
                            >
                                <s.ProjectTeamListItem
                                    onClick={() => {
                                        toggleChecked(user.id_e);
                                    }}
                                >
                                    {checked.includes(user.id_e) && (
                                        <s.CheckCircle width="25" height="25" />
                                    )}
                                    <s.ProjectTeamListPhoto
                                        src={user.avatar ?? FotoUser}
                                    />
                                </s.ProjectTeamListItem>
                            </Tooltip>
                        ))}
                    </s.ProjectTeamList>
                </s.ProjectTeam>
                <s.ButtonsContainer>
                    <SecondaryButton
                        onClick={() => {
                            setActivity(undefined);
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton onClick={save}>SALVAR</PrimaryButton>
                </s.ButtonsContainer>
            </s.Container>
        </ContainerModal>
    );
};
