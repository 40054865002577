import styled from "styled-components";

import { SecondaryButton } from "../../components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0em 2rem 0;
    gap: 3.5rem;
    width: 100%;
    max-width: 99rem;
`;

export const Breadcrumb = styled.div`
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 0.5rem;

    .arrow-left {
        transform: rotate(180deg);
        cursor: pointer;
    }

    .chevron-rigth {
        transform: rotate(-90deg);
        color: ${({ theme }) => theme.gray200};
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.75rem;
    }

    .breadcrumb {
        color: ${({ theme }) => theme.gray200};
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .blacktext {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.gray600};
        letter-spacing: 0.005em;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    margin-bottom: 1rem;
    gap: 1rem;
    h1 {
        color: ${({ theme }) => theme.purple500};
        font-size: 2.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 47px;
        letter-spacing: 0.0025em;
    }
    p {
        color: ${({ theme }) => theme.gray600};
        font-size: 1.125rem;
        font-weight: 400;
        font-style: normal;
        line-height: 25px;
        letter-spacing: 0.001em;
    }
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray200};
    padding: 32px;
    gap: 25px;
    background: ${({ theme }) => theme.white};
    border-radius: 4px;

    h2 {
        color: ${({ theme }) => theme.purple800};
        font-size: 1rem;
        font-weight: 600;
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.08em;
    }

    div {
        display: flex;
        flex-direction: row;

        p {
            color: ${({ theme }) => theme.gray800};
            font-size: 0.875rem;
            font-weight: 400;
            font-style: normal;
            line-height: 20px;
            letter-spacing: 0.01em;
        }
    }
`;

export const ChangePasswordButton = styled(SecondaryButton)`
    width: 156px;
`;
