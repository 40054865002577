import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type DeleteUserProjectBenefit = {
    benefitId?: string;
};

export default async ({ benefitId }: DeleteUserProjectBenefit = {}) => {
    const headers = {
        auth: true,
        "x-benefit-id": benefitId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/benefits`,
        method: "delete",
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
