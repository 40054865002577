import { formatCurrency } from "../../../pages/organization/project/tab-costs/utils";
import { minutesToStringDate } from "../../../utils";
import * as s from "./styles";

type Props = {
    limitHours: number;
    totalTime: number;
    hourValue: number;
};

type TimeToWidth = {
    time: number;
    total: number;
};

export function TimeLineSummary({ limitHours, totalTime, hourValue }: Props) {
    const getTotalTime = () => {
        return limitHours;
    };

    const timeToWidth = ({ time, total }: TimeToWidth) => (time / total) * 100;

    const returnPendingHours = (limitHours: number, totalTime: number) => {
        if (limitHours - totalTime < 0) {
            return minutesToStringDate(0);
        }
        return minutesToStringDate(limitHours - totalTime);
    };

    const monthlyCost = hourValue * (totalTime / 60);

    return (
        <s.Container>
            <div id="container-infos">
                <div id="info-left">
                    <s.Paragraph>
                        {minutesToStringDate(totalTime)} /{" "}
                        {formatCurrency(monthlyCost.toFixed(2))}
                    </s.Paragraph>
                    <s.GrayParagraph>
                        Horas trabalhadas / Valor a receber
                    </s.GrayParagraph>
                </div>
                <div id="info-right">
                    <s.Paragraph>
                        {returnPendingHours(limitHours, totalTime)}
                    </s.Paragraph>
                    <s.GrayParagraph>Horas em aberto</s.GrayParagraph>
                </div>
            </div>
            <s.BarContainer
                percentage={timeToWidth({
                    time: totalTime,
                    total: getTotalTime(),
                })}
            >
                <s.Bar
                    percentage={timeToWidth({
                        time: limitHours,
                        total: getTotalTime(),
                    })}
                />
                <s.BarDelimiter id="delimiter" />
            </s.BarContainer>
        </s.Container>
    );
}
