type TIlustracaoTelaVaziaHomem = {
    title?: string;
    className?: string;
};

export default ({ title, className }: TIlustracaoTelaVaziaHomem) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="450"
            height="400"
            fill="none"
            viewBox="0 0 450 400"
            className={className || "icon-ilustracao-tela-vazia-homem"}
        >
            <title>{title}</title>
            <path d="M385 322.115H64V325.702H385V322.115Z" fill="#E7E6E9" />
            <path d="M385 342.33H322.413V342.656H385V342.33Z" fill="#E7E6E9" />
            <path
                d="M358.712 338.639H300.678V338.904H358.712V338.639Z"
                fill="#E7E6E9"
            />
            <path
                d="M357.255 332.547H309.831V332.873H357.255V332.547Z"
                fill="#E7E6E9"
            />
            <path
                d="M77.4264 334.293H64.1162V334.558H77.4264V334.293Z"
                fill="#E7E6E9"
            />
            <path
                d="M161.881 334.293H86.7715V334.558H161.881V334.293Z"
                fill="#E7E6E9"
            />
            <path
                d="M239.844 338.766H190.715V339.031H239.844V338.766Z"
                fill="#E7E6E9"
            />
            <path
                d="M282.711 169.149H235.25L239.455 113.02H278.505L282.711 169.149Z"
                fill="#E7E6E9"
            />
            <path
                d="M272.296 169.149H264.135L262.405 113.02H269.936L272.296 169.149Z"
                fill="#FCFCFC"
            />
            <path
                d="M261.356 169.148H256.605V325.293H261.356V169.148Z"
                fill="white"
            />
            <path
                d="M261.315 239.068H256.647C255.489 239.068 254.549 240.018 254.549 241.188V245.906C254.549 247.077 255.489 248.026 256.647 248.026H261.315C262.473 248.026 263.412 247.077 263.412 245.906V241.188C263.412 240.018 262.473 239.068 261.315 239.068Z"
                fill="white"
            />
            <path
                d="M261.315 253.422H256.647C255.489 253.422 254.549 254.371 254.549 255.542V260.259C254.549 261.43 255.489 262.379 256.647 262.379H261.315C262.473 262.379 263.412 261.43 263.412 260.259V255.542C263.412 254.371 262.473 253.422 261.315 253.422Z"
                fill="white"
            />
            <path
                d="M266.496 325.293H251.465C251.465 324.443 251.795 323.628 252.383 323.021C252.971 322.415 253.772 322.065 254.612 322.049H263.244C264.084 322.065 264.885 322.415 265.473 323.021C266.061 323.628 266.391 324.443 266.391 325.293H266.496Z"
                fill="white"
            />
            <path
                d="M261.356 169.148H256.605V172.085H261.356V169.148Z"
                fill="white"
            />
            <path
                d="M223.531 373.165C309.961 373.165 380.026 367.793 380.026 361.166C380.026 354.538 309.961 349.166 223.531 349.166C137.101 349.166 67.0352 354.538 67.0352 361.166C67.0352 367.793 137.101 373.165 223.531 373.165Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.1"
                d="M309.709 29.9648L307.737 91.1718L355.22 87.8539L309.709 29.9648Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.1"
                d="M276.03 218.261L277.258 172.986L99.3901 175.424L276.03 218.261Z"
                fill="#8C53C5"
            />
            <path
                d="M96.4634 35.4238H311.482V172.17C311.482 173.052 311.135 173.899 310.517 174.523C309.899 175.147 309.062 175.498 308.188 175.498H99.7568C99.3243 175.498 98.8961 175.412 98.4965 175.245C98.0969 175.078 97.7338 174.832 97.428 174.523C97.1222 174.214 96.8796 173.847 96.7141 173.443C96.5486 173.04 96.4634 172.607 96.4634 172.17V35.4238Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M96.4634 35.4238H311.482V172.17C311.482 173.052 311.135 173.899 310.517 174.523C309.899 175.147 309.062 175.498 308.188 175.498H99.7568C99.3243 175.498 98.8961 175.412 98.4965 175.245C98.0969 175.078 97.7338 174.832 97.428 174.523C97.1222 174.214 96.8796 173.847 96.7141 173.443C96.5486 173.04 96.4634 172.607 96.4634 172.17V35.4238Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.9"
                d="M289.844 44.5625H118.07C116.431 44.5625 115.102 45.9056 115.102 47.5624V96.3563C115.102 98.0131 116.431 99.3562 118.07 99.3562H289.844C291.483 99.3562 292.812 98.0131 292.812 96.3563V47.5624C292.812 45.9056 291.483 44.5625 289.844 44.5625Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.7"
                d="M165.951 107.75H118.07C116.431 107.75 115.102 109.093 115.102 110.75V120.736C115.102 122.392 116.431 123.735 118.07 123.735H165.951C167.591 123.735 168.92 122.392 168.92 120.736V110.75C168.92 109.093 167.591 107.75 165.951 107.75Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M227.898 107.75H180.017C178.378 107.75 177.049 109.093 177.049 110.75V120.736C177.049 122.392 178.378 123.735 180.017 123.735H227.898C229.537 123.735 230.866 122.392 230.866 120.736V110.75C230.866 109.093 229.537 107.75 227.898 107.75Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M289.834 107.75H241.953C240.313 107.75 238.984 109.093 238.984 110.75V120.736C238.984 122.392 240.313 123.735 241.953 123.735H289.834C291.473 123.735 292.802 122.392 292.802 120.736V110.75C292.802 109.093 291.473 107.75 289.834 107.75Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M118.07 130.201H165.951C166.341 130.201 166.727 130.279 167.087 130.43C167.447 130.58 167.775 130.801 168.05 131.08C168.326 131.358 168.544 131.689 168.694 132.053C168.843 132.417 168.92 132.807 168.92 133.201V175.401H115.102V133.201C115.102 132.405 115.415 131.642 115.971 131.08C116.528 130.517 117.283 130.201 118.07 130.201Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M180.059 130.201H227.94C228.727 130.201 229.482 130.518 230.039 131.08C230.596 131.643 230.908 132.406 230.908 133.201V175.402H177.049V133.201C177.049 132.804 177.127 132.41 177.279 132.043C177.431 131.677 177.653 131.344 177.933 131.065C178.213 130.786 178.545 130.566 178.91 130.418C179.275 130.269 179.666 130.196 180.059 130.201Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M241.942 130.201H289.823C290.61 130.201 291.365 130.517 291.922 131.08C292.479 131.642 292.791 132.405 292.791 133.201V175.401H238.984V133.201C238.984 132.407 239.296 131.646 239.85 131.084C240.404 130.521 241.157 130.204 241.942 130.201Z"
                fill="#8C53C5"
            />
            <path
                d="M302.125 26.0001H105.787C104.559 26 103.342 26.2455 102.207 26.7224C101.072 27.1993 100.042 27.8982 99.1755 28.7789C98.3089 29.6596 97.6232 30.7048 97.1577 31.8542C96.6922 33.0037 96.4561 34.2349 96.463 35.4769H311.481C311.488 34.2322 311.251 32.9984 310.784 31.8469C310.316 30.6953 309.628 29.6488 308.758 28.7676C307.888 27.8865 306.854 27.1883 305.716 26.7133C304.577 26.2382 303.357 25.9958 302.125 26.0001Z"
                fill="#663398"
            />
            <path
                d="M109.669 30.7064C109.671 31.0805 109.563 31.4467 109.359 31.7586C109.154 32.0706 108.863 32.3141 108.521 32.4582C108.18 32.6023 107.804 32.6406 107.44 32.5681C107.077 32.4956 106.744 32.3157 106.482 32.0512C106.22 31.7867 106.042 31.4495 105.97 31.0825C105.899 30.7155 105.937 30.3352 106.079 29.99C106.222 29.6448 106.463 29.3502 106.771 29.1437C107.08 28.9372 107.442 28.828 107.813 28.8301C108.304 28.8329 108.775 29.0315 109.122 29.3827C109.47 29.734 109.666 30.2096 109.669 30.7064Z"
                fill="#FCFCFC"
            />
            <path
                d="M115.669 30.6107C115.669 30.9818 115.56 31.3445 115.356 31.6531C115.152 31.9616 114.862 32.2021 114.523 32.3441C114.184 32.4861 113.81 32.5233 113.45 32.4509C113.09 32.3785 112.759 32.1998 112.5 31.9374C112.24 31.675 112.063 31.3407 111.992 30.9767C111.92 30.6127 111.957 30.2355 112.097 29.8926C112.238 29.5498 112.476 29.2568 112.781 29.0506C113.086 28.8444 113.445 28.7344 113.813 28.7344C114.304 28.7372 114.775 28.9357 115.122 29.287C115.47 29.6383 115.666 30.1139 115.669 30.6107Z"
                fill="#FCFCFC"
            />
            <path
                d="M121.668 30.6107C121.668 30.9818 121.559 31.3445 121.355 31.6531C121.151 31.9616 120.861 32.2021 120.522 32.3441C120.182 32.4861 119.809 32.5233 119.449 32.4509C119.089 32.3785 118.758 32.1998 118.498 31.9374C118.239 31.675 118.062 31.3407 117.99 30.9767C117.919 30.6127 117.955 30.2355 118.096 29.8926C118.236 29.5498 118.474 29.2568 118.78 29.0506C119.085 28.8444 119.444 28.7344 119.811 28.7344C120.303 28.7372 120.773 28.9357 121.121 29.287C121.468 29.6383 121.665 30.1139 121.668 30.6107Z"
                fill="#FCFCFC"
            />
            <path
                d="M274.342 98.4219H357.202V214.889C357.202 216.014 356.76 217.092 355.973 217.887C355.187 218.682 354.119 219.129 353.007 219.129H278.485C277.372 219.129 276.305 218.682 275.518 217.887C274.731 217.092 274.289 216.014 274.289 214.889V98.4219H274.342Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.8"
                d="M274.342 98.4219H357.202V214.889C357.202 216.014 356.76 217.092 355.973 217.887C355.187 218.682 354.119 219.129 353.007 219.129H278.485C277.372 219.129 276.305 218.682 275.518 217.887C274.731 217.092 274.289 216.014 274.289 214.889V98.4219H274.342Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.9"
                d="M347.563 104.719H283.949C282.814 104.719 281.894 105.649 281.894 106.796V128.888C281.894 130.035 282.814 130.965 283.949 130.965H347.563C348.699 130.965 349.619 130.035 349.619 128.888V106.796C349.619 105.649 348.699 104.719 347.563 104.719Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.7"
                d="M345.885 136.16H285.628C283.565 136.16 281.894 137.85 281.894 139.934V152.495C281.894 154.58 283.565 156.269 285.628 156.269H345.885C347.947 156.269 349.619 154.58 349.619 152.495V139.934C349.619 137.85 347.947 136.16 345.885 136.16Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M345.885 162.926H285.628C283.565 162.926 281.894 164.615 281.894 166.7V179.261C281.894 181.345 283.565 183.035 285.628 183.035H345.885C347.947 183.035 349.619 181.345 349.619 179.261V166.7C349.619 164.615 347.947 162.926 345.885 162.926Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M345.885 189.691H285.628C283.565 189.691 281.894 191.381 281.894 193.465V206.027C281.894 208.111 283.565 209.8 285.628 209.8H345.885C347.947 209.8 349.619 208.111 349.619 206.027V193.465C349.619 191.381 347.947 189.691 345.885 189.691Z"
                fill="#8C53C5"
            />
            <path
                d="M279.785 86.5704H351.717C353.163 86.5704 354.55 87.1511 355.573 88.1848C356.596 89.2186 357.171 90.6206 357.171 92.0826V98.4429H274.31V92.0826C274.31 91.3569 274.452 90.6384 274.727 89.9682C275.003 89.2981 275.406 88.6895 275.915 88.1773C276.424 87.6652 277.027 87.2596 277.692 86.9839C278.356 86.7081 279.067 86.5676 279.785 86.5704Z"
                fill="#663398"
            />
            <path
                d="M252.394 156.903H269.03C269.714 156.903 270.37 156.628 270.854 156.139C271.338 155.65 271.61 154.987 271.61 154.295C271.607 153.604 271.334 152.943 270.851 152.454C270.368 151.966 269.713 151.69 269.03 151.688H252.394C251.712 151.69 251.058 151.966 250.577 152.455C250.095 152.944 249.825 153.605 249.825 154.295C249.825 154.985 250.095 155.647 250.577 156.135C251.058 156.624 251.712 156.9 252.394 156.903Z"
                fill="white"
            />
            <path
                d="M245.553 356.458H237.875L237.886 338.469H245.574L245.553 356.458Z"
                fill="#EE8181"
            />
            <path
                d="M307.289 341.607L299.936 343.854L291.839 327.54L299.191 325.293L307.289 341.607Z"
                fill="#EE8181"
            />
            <path
                d="M299.192 343.145L307.457 340.622C307.6 340.578 307.754 340.585 307.891 340.643C308.029 340.702 308.143 340.807 308.212 340.94L311.359 347.195C311.433 347.352 311.472 347.523 311.474 347.697C311.476 347.871 311.441 348.043 311.372 348.202C311.302 348.361 311.199 348.503 311.07 348.618C310.941 348.734 310.789 348.819 310.625 348.87C307.73 349.696 306.282 349.93 302.643 351.064C300.409 351.742 293.203 355.41 290.161 356.364C287.119 357.318 285.882 354.308 287.077 353.629C292.416 350.629 296.727 346.209 298.122 343.983C298.368 343.584 298.748 343.287 299.192 343.145Z"
                fill="#565064"
            />
            <path
                d="M237.959 355.557H246.35C246.5 355.555 246.646 355.607 246.762 355.704C246.877 355.801 246.954 355.937 246.98 356.087L248.196 362.998C248.226 363.169 248.217 363.344 248.172 363.511C248.127 363.677 248.046 363.832 247.935 363.964C247.823 364.095 247.685 364.201 247.529 364.272C247.373 364.343 247.203 364.379 247.032 364.376C244.011 364.323 239.69 364.143 235.84 364.143C231.393 364.143 227.533 364.387 222.321 364.387C219.174 364.387 218.429 361.207 219.761 360.878C225.834 359.542 230.743 359.405 236.082 356.161C236.642 355.792 237.291 355.584 237.959 355.557Z"
                fill="#565064"
            />
            <path
                d="M265.292 73.0977C263.939 78.7795 263.09 85.8182 266.855 89.6132C266.855 89.6132 264.348 95.3798 253.902 95.3798C244.357 95.3798 249.381 89.6132 249.381 89.6132C255.674 88.1079 255.727 80.2636 254.835 75.8326L265.292 73.0977Z"
                fill="#EE8181"
            />
            <path
                opacity="0.2"
                d="M237.886 338.48L237.875 347.745H245.564L245.574 338.48H237.886Z"
                fill="#04010D"
            />
            <path
                d="M282.221 96.7148L272.362 102.439C272.362 102.439 282.704 126.82 281.686 130.763C280.291 136.064 279.589 146.887 277.438 153.915C278.288 154.88 281.162 156.607 282.316 156.565C286.511 148.085 293.35 133.615 292.951 128.707C292.049 117.492 282.221 96.7148 282.221 96.7148Z"
                fill="#EE8181"
            />
            <path
                d="M277.438 153.916L270.684 156.736L277.459 162.428C277.459 162.428 283.291 159.767 282.221 154.764L277.438 153.916Z"
                fill="#EE8181"
            />
            <path
                d="M268.149 156.067L274.537 160.911L278.701 155.653L271.925 149.961L268.149 156.067Z"
                fill="#EE8181"
            />
            <path
                d="M280.543 91.3725C287.697 92.846 293.507 118.669 293.507 118.669L277.397 126.502C277.397 126.502 271.88 114.057 269.446 104.92C266.908 95.3371 272.866 89.793 280.543 91.3725Z"
                fill="#663398"
            />
            <path
                opacity="0.2"
                d="M274.795 100.257C271.795 97.0772 273.159 109.734 273.914 118.151C274.554 119.826 275.173 121.331 275.708 122.614C280.04 116.275 278.991 104.71 274.795 100.257Z"
                fill="#04010D"
            />
            <path
                d="M249.623 91.9672C249.623 89.2111 249.224 87.9072 249.969 86.8896C250.713 85.8719 265.482 84.6635 268.03 85.8295C268.901 86.2217 269.404 90.1863 268.314 91.9566C266.719 94.5219 249.623 91.9672 249.623 91.9672Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.2"
                d="M249.623 91.9672C249.623 89.2111 249.224 87.9072 249.969 86.8896C250.713 85.8719 265.482 84.6635 268.03 85.8295C268.901 86.2217 269.404 90.1863 268.314 91.9566C266.719 94.5219 249.623 91.9672 249.623 91.9672Z"
                fill="#04010D"
            />
            <path
                d="M237.466 91.7764C237.466 91.7764 227.376 115.225 234.089 158.898H275.194C275.75 152.538 273.096 121.415 280.543 91.3736C276.024 90.4855 271.451 89.8977 266.856 89.614C261.036 89.2745 255.201 89.2745 249.381 89.614C245.361 90.0239 241.377 90.7468 237.466 91.7764Z"
                fill="#663398"
            />
            <path
                d="M243.329 102.674L233.145 98.5293C233.145 98.5293 219.384 122.147 216.51 124.129C209.849 120.44 207.615 121.935 202.434 118.066C201.123 118.204 199.078 120.048 198.385 120.992C202.581 129.154 216.52 139.256 223.411 133.097C231.939 125.476 243.329 102.674 243.329 102.674Z"
                fill="#EE8181"
            />
            <path
                opacity="0.2"
                d="M244.608 99.8756C243.077 95.8898 239.868 94.2256 236.396 94.8086C234.864 99.6954 232.2 110.434 231.823 126.61L235.808 127.522C235.808 127.522 247.241 106.692 244.608 99.8756Z"
                fill="#04010D"
            />
            <path
                d="M246.36 97.0874C248.647 104.041 234.634 124.5 234.634 124.5L219.635 116.02C222.228 109.639 225.469 103.546 229.305 97.84C235.106 89.6882 243.853 89.4656 246.36 97.0874Z"
                fill="#663398"
            />
            <path
                d="M202.686 118.289L195.837 114.293L191.83 122.349C194.598 123.271 197.496 123.732 200.41 123.717L202.686 118.289Z"
                fill="#EE8181"
            />
            <path
                d="M192.459 114.293L187.561 119L191.829 122.349L195.836 114.293H192.459Z"
                fill="#EE8181"
            />
            <path
                d="M248.259 64.9247C248.196 65.5501 247.819 65.9847 247.42 65.9847C247.022 65.9847 246.738 65.4335 246.801 64.8081C246.864 64.1827 247.242 63.748 247.651 63.748C248.06 63.748 248.322 64.2993 248.259 64.9247Z"
                fill="#565064"
            />
            <path
                d="M248.805 64.9785C247.877 66.8517 246.706 68.5914 245.322 70.1515C245.72 70.484 246.187 70.7222 246.688 70.8487C247.189 70.9753 247.711 70.987 248.217 70.883L248.805 64.9785Z"
                fill="#EE8181"
            />
            <path
                d="M247.525 62.7612L247.368 62.6976C247.329 62.6702 247.295 62.6351 247.27 62.5945C247.245 62.5538 247.228 62.5085 247.22 62.4611C247.212 62.4137 247.214 62.3652 247.225 62.3184C247.236 62.2716 247.256 62.2276 247.284 62.1888C247.613 61.6888 248.052 61.2726 248.567 60.9728C249.082 60.673 249.659 60.4978 250.252 60.4609C250.299 60.4623 250.345 60.473 250.388 60.4924C250.431 60.5118 250.469 60.5395 250.501 60.574C250.533 60.6084 250.558 60.6489 250.575 60.6932C250.592 60.7375 250.6 60.7846 250.598 60.832C250.593 60.9305 250.551 61.0233 250.481 61.0921C250.411 61.1609 250.318 61.2004 250.221 61.203C249.757 61.2417 249.308 61.3881 248.91 61.6308C248.512 61.8735 248.174 62.206 247.924 62.6022C247.88 62.6654 247.819 62.7141 247.748 62.7424C247.677 62.7706 247.6 62.7772 247.525 62.7612Z"
                fill="#565064"
            />
            <path
                d="M269.142 64.3845C267.894 72.0381 267.558 76.5645 263.331 80.2852C261.989 81.5131 260.337 82.3433 258.559 82.6846C256.78 83.0259 254.943 82.8652 253.249 82.2199C251.554 81.5747 250.069 80.47 248.956 79.0272C247.842 77.5844 247.144 75.8593 246.937 74.0415C246.004 67.0453 248.185 55.7134 255.915 53.0845C257.567 52.4723 259.35 52.313 261.082 52.6228C262.815 52.9327 264.435 53.7006 265.779 54.8485C267.123 55.9965 268.142 57.4835 268.732 59.1584C269.323 60.8334 269.464 62.6365 269.142 64.3845Z"
                fill="#EE8181"
            />
            <path
                d="M253.356 67.6801C251.783 62.6449 249.738 54.8641 255.328 51.9808C260.919 49.0975 265.324 53.1045 271.418 56.8146C278.288 60.9912 274.092 74.2418 265.806 77.3583C258.852 79.9766 252.066 74.7188 253.356 67.6801Z"
                fill="#565064"
            />
            <path
                d="M253.965 58.3434C249.035 59.6897 245.637 56.4883 248.07 52.7994C251.154 48.1034 263.279 49.333 267.82 54.4107C272.362 59.4883 257.384 61.9158 253.965 58.3434Z"
                fill="#565064"
            />
            <path
                d="M255.842 54.6327C249.821 53.509 247.357 48.548 252.402 45.4739C257.164 42.58 270.547 47.594 272.781 55.5867C275.015 63.5794 257.667 60.3039 255.842 54.6327Z"
                fill="#565064"
            />
            <path
                d="M271.89 58.4381C272.803 57.6961 276.432 56.636 276.841 60.7384C275.517 59.3962 273.76 58.5797 271.89 58.4381Z"
                fill="#565064"
            />
            <path
                d="M274.49 53.3281C275.214 54.261 276.242 57.9287 272.172 58.3209C273.51 56.9895 274.332 55.2186 274.49 53.3281Z"
                fill="#565064"
            />
            <path
                d="M257.688 67.3312C257.14 68.9848 255.989 70.3648 254.468 71.1897C252.454 72.2498 251.059 70.5749 251.322 68.4124C251.542 66.4725 252.853 63.568 255.024 63.3984C257.195 63.2288 258.349 65.3065 257.688 67.3312Z"
                fill="#EE8181"
            />
            <path
                d="M248.668 158.898C248.668 158.898 254.804 218.261 258.622 240.522C262.817 264.903 292.186 335.29 292.186 335.29L305.664 331.177C305.664 331.177 284.686 266.461 282.704 242.356C280.606 216.183 275.236 158.93 275.236 158.93L248.668 158.898Z"
                fill="#565064"
            />
            <path
                opacity="0.2"
                d="M258.118 179.654C257.51 178.721 256.933 178.403 256.377 178.594H256.272C256.144 178.646 256.023 178.718 255.916 178.806C255.803 178.9 255.698 179.003 255.601 179.113C255.538 179.177 255.486 179.251 255.423 179.325C255.36 179.4 255.265 179.548 255.182 179.675C254.991 179.986 254.819 180.308 254.668 180.64C254.584 180.841 254.489 181.053 254.405 181.265C254.321 181.477 254.196 181.806 254.091 182.103C253.986 182.4 253.818 182.908 253.692 183.343C253.692 183.46 253.619 183.566 253.587 183.682C253.556 183.799 253.451 184.17 253.377 184.414L253.22 185.05C253.105 185.474 253 185.898 252.895 186.343C252.79 186.788 252.685 187.308 252.58 187.806C252.58 187.975 252.507 188.156 252.465 188.325C252.339 188.961 252.213 189.618 252.087 190.276C253.776 205.053 255.8 221.992 257.563 233.812C257.688 233.43 257.814 233.027 257.94 232.593C258.481 230.625 258.919 228.629 259.251 226.614C261.863 211.381 262.062 185.728 258.118 179.654Z"
                fill="#04010D"
            />
            <path
                d="M234.088 158.898C234.088 158.898 230.501 216.968 230.302 240.66C230.092 265.295 235.095 345.954 235.095 345.954H248.112C248.112 345.954 252.045 266.737 253.681 242.536C255.454 216.162 261.107 158.898 261.107 158.898H234.088Z"
                fill="#565064"
            />
            <path
                d="M249.15 341.925C249.202 341.925 248.961 346.218 248.961 346.218H233.952L233.532 341.395L249.15 341.925Z"
                fill="#8C53C5"
            />
            <path
                d="M304.594 326.344C304.929 327.012 306.356 331.199 306.356 331.199L291.577 335.725L289.154 331.697L304.594 326.344Z"
                fill="#8C53C5"
            />
        </svg>
    );
};
