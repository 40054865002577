type TIlustracaoTelaVazia = {
    title?: string;
    className?: string;
};

export default ({ title, className }: TIlustracaoTelaVazia) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="450"
            height="400"
            fill="none"
            viewBox="0 0 450 400"
            className={className || "icon-ilustracao-tela-vazia"}
        >
            <title>{title}</title>
            <path
                fill="#FCFCFC"
                d="M222.171 376.249c109.447 0 198.171-5.18 198.171-11.57 0-6.39-88.724-11.57-198.171-11.57-109.447 0-198.171 5.18-198.171 11.57 0 6.39 88.724 11.57 198.171 11.57z"
            ></path>
            <path
                fill="#663398"
                d="M113.932 146.066h-49.09v218.429h49.09V146.066z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M113.932 146.066h-49.09v218.429h49.09V146.066z"
                opacity="0.7"
            ></path>
            <path
                fill="#663398"
                d="M189.036 73.867h-49.091v290.618h49.091V73.867z"
            ></path>
            <path
                fill="#04010D"
                d="M189.036 73.867h-49.091v290.618h49.091V73.867z"
                opacity="0.1"
            ></path>
            <path
                fill="#663398"
                d="M264.147 146.066h-49.09v218.429h49.09V146.066z"
            ></path>
            <path
                fill="#04010D"
                d="M264.147 146.066h-49.09v218.429h49.09V146.066z"
                opacity="0.4"
            ></path>
            <path
                fill="#663398"
                d="M96.516 146.066h-49.09v218.429h49.09V146.066z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M96.516 146.066h-49.09v218.429h49.09V146.066z"
                opacity="0.8"
            ></path>
            <path
                fill="#663398"
                d="M171.63 73.867h-49.091v290.618h49.091V73.867z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M171.63 73.867h-49.091v290.618h49.091V73.867z"
                opacity="0.2"
            ></path>
            <path
                fill="#663398"
                d="M246.731 146.066h-49.09v218.429h49.09V146.066z"
            ></path>
            <path
                fill="#04010D"
                d="M246.731 146.066h-49.09v218.429h49.09V146.066z"
                opacity="0.2"
            ></path>
            <path
                fill="#663398"
                d="M397.417 193.461a100.263 100.263 0 00-70.84-29.355h-14.463v1.074a100.321 100.321 0 00-35.599 12.346c-47.608 27.464-64.176 89.248-36.713 136.826a100.173 100.173 0 0072.312 49.06v1.022h14.483a100.177 100.177 0 0055.677-16.867 100.168 100.168 0 0015.164-154.158l-.021.052zm-70.84 144.89a74.066 74.066 0 01-68.428-45.707 74.064 74.064 0 0116.039-80.711 74.063 74.063 0 01113.947 11.196 74.075 74.075 0 0112.492 41.141 73.892 73.892 0 01-74.05 74.081z"
            ></path>
            <path
                fill="#04010D"
                d="M397.417 193.461a100.263 100.263 0 00-70.84-29.355h-14.463v1.074a100.321 100.321 0 00-35.599 12.346c-47.608 27.464-64.176 89.248-36.713 136.826a100.173 100.173 0 0072.312 49.06v1.022h14.483a100.177 100.177 0 0055.677-16.867 100.168 100.168 0 0015.164-154.158l-.021.052zm-70.84 144.89a74.066 74.066 0 01-68.428-45.707 74.064 74.064 0 0116.039-80.711 74.063 74.063 0 01113.947 11.196 74.075 74.075 0 0112.492 41.141 73.892 73.892 0 01-74.05 74.081z"
                opacity="0.3"
            ></path>
            <path
                fill="#663398"
                d="M386.143 264.271h26.134a100.163 100.163 0 01-186.959 50.081l22.65-13.072a74.043 74.043 0 0083.291 34.526 74.038 74.038 0 0054.884-71.535z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M386.143 264.271h26.134a100.163 100.163 0 01-186.959 50.081l22.65-13.072a74.043 74.043 0 0083.291 34.526 74.038 74.038 0 0054.884-71.535z"
                opacity="0.2"
            ></path>
            <path
                fill="#663398"
                d="M312.113 164.106A100.23 100.23 0 01412.277 264.27h-26.135a74.07 74.07 0 00-74.029-74.029v-26.135z"
            ></path>
            <path
                fill="#663398"
                d="M312.114 164.106v26.135a74.024 74.024 0 00-64.133 37.012 74.029 74.029 0 00.008 74.047l-22.67 13.052c-27.463-47.598-10.906-109.362 36.693-136.846a100.318 100.318 0 0150.102-13.4z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M312.114 164.106v26.135a74.024 74.024 0 00-64.133 37.012 74.029 74.029 0 00.008 74.047l-22.67 13.052c-27.463-47.598-10.906-109.362 36.693-136.846a100.318 100.318 0 0150.102-13.4z"
                opacity="0.8"
            ></path>
            <path
                fill="#EE8181"
                d="M287.553 234.048l-8.033 4.702-12.112-17.877 8.034-4.701 12.111 17.876z"
            ></path>
            <path
                fill="#565064"
                d="M275.053 233.668l8.81-5.611a.74.74 0 01.971.133l5.448 6.337a1.505 1.505 0 01-.368 2.197c-3.067 1.901-4.702 2.668-8.576 5.111-2.391 1.512-7.154 4.906-10.455 7.011-3.302 2.106-5.54-1.022-4.355-2.218 5.305-5.243 6.562-8.606 7.523-11.672a2.456 2.456 0 011.002-1.288z"
            ></path>
            <path
                fill="#663398"
                d="M271.016 237.604h-.235a1.185 1.185 0 01-.869-.664.63.63 0 010-.634c.613-1.114 4.722-1.502 5.192-1.543a.145.145 0 01.154.092.144.144 0 010 .174c-1.063.981-2.934 2.575-4.242 2.575zm3.659-2.493c-1.431.163-4.088.603-4.487 1.338a.326.326 0 000 .338.838.838 0 00.644.49c.787.133 2.208-.674 3.843-2.166z"
            ></path>
            <path
                fill="#663398"
                d="M274.594 235.1c-1.36 0-3.577-.49-3.935-1.318-.092-.215-.113-.542.337-.889a1.52 1.52 0 011.186-.327c1.482.194 2.994 2.166 3.066 2.248a.14.14 0 01.022.077.14.14 0 01-.022.077.134.134 0 01-.043.062.13.13 0 01-.069.03 3.077 3.077 0 01-.542.04zm-2.637-2.238a1.2 1.2 0 00-.767.276c-.357.276-.276.46-.245.521.276.644 2.525 1.206 3.874 1.125a5.394 5.394 0 00-2.678-1.912l-.184-.01z"
            ></path>
            <path
                fill="#EE8181"
                d="M309.671 95.116c-.153.807-.276 1.38-.429 2.044l-.48 1.89c-.337 1.258-.695 2.485-1.022 3.711a116.355 116.355 0 01-2.545 7.155c-.91 2.32-1.912 4.701-2.944 7.001-.511 1.155-1.022 2.289-1.615 3.424l-.419.858-.235.47a8.532 8.532 0 01-.368.695 11.422 11.422 0 01-1.788 2.31 15.66 15.66 0 01-4.089 2.872 26.171 26.171 0 01-4.088 1.595 44.49 44.49 0 01-7.88 1.533 74.11 74.11 0 01-15.331 0l.081-4.804c2.331-.255 4.763-.521 7.155-.92 2.392-.398 4.691-.797 6.96-1.328a42.305 42.305 0 006.439-2.045 11.843 11.843 0 004.538-2.861c.166-.193.3-.41.399-.644l.521-1.339c.45-1.114.869-2.239 1.288-3.363.859-2.238 1.635-4.507 2.422-6.756a127.459 127.459 0 002.157-6.797c.347-1.134.674-2.258 1.022-3.383l.44-1.686.378-1.554 9.433 1.922z"
            ></path>
            <path
                fill="#663398"
                d="M303.181 83.168c-3.833 1.022-6.521 16.752-6.521 16.752l10.568 7.155s10.129-15.914 7.155-20.125c-3.046-4.364-5.611-5.202-11.202-3.782z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M303.181 83.168c-3.833 1.022-6.521 16.752-6.521 16.752l10.568 7.155s10.129-15.914 7.155-20.125c-3.046-4.364-5.611-5.202-11.202-3.782z"
                opacity="0.6"
            ></path>
            <path
                fill="#663398"
                d="M302.833 88.902l8.177 1.901.787 8.024a93.047 93.047 0 01-4.599 8.279l-6.327-4.324c.225-3.72.92-11.386 1.962-13.88z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M302.833 88.902l8.177 1.901.787 8.024a93.047 93.047 0 01-4.599 8.279l-6.327-4.324c.225-3.72.92-11.386 1.962-13.88z"
                opacity="0.3"
            ></path>
            <path
                fill="#EE8181"
                d="M267.867 125.86l-6.592-6.725-.869 9.423s4.947 3.343 8.371 1.544l-.91-4.242z"
            ></path>
            <path
                fill="#EE8181"
                d="M253.518 118.174l-.307 7.931 7.195 2.494.859-9.424-7.747-1.001zM251.494 236.592l-6.602-6.551 13.031-17.406 6.603 6.541-13.032 17.416z"
            ></path>
            <path
                fill="#565064"
                d="M248.592 225.431l9.526 4.313a.718.718 0 01.409.879l-2.321 8.024a1.508 1.508 0 01-.821.904 1.503 1.503 0 01-1.223-.005c-3.291-1.564-4.804-2.484-8.994-4.375-2.576-1.165-9.301-3.894-12.858-5.498-3.557-1.605-2.156-5.213-.531-4.865 7.297 1.584 12.081 1.369 15.167.5a2.454 2.454 0 011.646.123z"
            ></path>
            <path
                fill="#EE8181"
                d="M267.418 220.873l6.245 9.219 8.033-4.701-6.244-9.209-8.034 4.691zM264.525 219.176l-6.613-6.541-6.715 8.974 6.613 6.551 6.715-8.984z"
            ></path>
            <path
                fill="#663398"
                d="M345.403 139.32h-37.817c-8.882-54.589-4.446-56.142-4.446-56.142s6.081-1.247 12.449-2.045c1.093-.132 2.187-.255 3.27-.357 5.713-.41 11.448-.41 17.161 0 1.084.05 2.167.133 3.23.225 4.301.39 8.577 1.02 12.807 1.89-7.308 31.572-7.237 41.047-6.654 56.429z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M345.403 139.32h-37.817c-8.882-54.589-4.446-56.142-4.446-56.142s6.081-1.247 12.449-2.045c1.093-.132 2.187-.255 3.27-.357 5.713-.41 11.448-.41 17.161 0 1.084.05 2.167.133 3.23.225 4.301.39 8.577 1.02 12.807 1.89-7.308 31.572-7.237 41.047-6.654 56.429z"
                opacity="0.6"
            ></path>
            <path
                fill="#663398"
                d="M339.292 81.01l-.409.962c-.215.531-5.479 12.898-15.679 21.157l-2.494 2.044-1.911-2.586c-5.734-7.768-4.017-17.866-3.168-21.413 1.093-.132 2.187-.255 3.27-.357 5.713-.41 11.448-.41 17.161 0 1.083.02 2.167.102 3.23.194z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M339.292 81.01l-.409.962c-.215.531-5.479 12.898-15.679 21.157l-2.494 2.044-1.911-2.586c-5.734-7.768-4.017-17.866-3.168-21.413 1.093-.132 2.187-.255 3.27-.357 5.713-.41 11.448-.41 17.161 0 1.083.02 2.167.102 3.23.194z"
                opacity="0.1"
            ></path>
            <path
                fill="#565064"
                d="M317.051 33.73c-8.258-3.69-13.226 10.344-12.663 20.279.562 9.934 2.044 22.117-2.31 20.073-4.354-2.044-1.268-5.11-1.268-5.11s-6.132 4.855.828 7.522c6.961 2.668 19.338 2.412 26.574-1.022 4.14-6.245 2.361-29.59 2.361-29.59L317.051 33.73z"
            ></path>
            <path
                fill="#EE8181"
                d="M334.354 61.469c-1.073 5.519-2.156 15.638 1.707 19.317 0 0-5.11 12.091-14.789 19.951-6.071-8.228-2.371-19.951-2.371-19.951 6.132-1.472 5.999-6.04 4.926-10.323l10.527-8.994z"
            ></path>
            <path
                fill="#EE8181"
                d="M330.061 65.139l-6.235 5.315c.271 1.003.449 2.03.532 3.066 2.35-.338 5.58-2.923 5.836-5.387a8.2 8.2 0 00-.133-2.994z"
            ></path>
            <path
                fill="#EE8181"
                d="M335.008 46.925c1.022 9.198 1.707 13.031-2.044 18.5-5.642 8.237-17.14 7.542-20.922-1.125-3.404-7.799-4.579-21.464 3.659-26.687a12.591 12.591 0 0119.307 9.312z"
            ></path>
            <path
                fill="#565064"
                d="M316.724 40.61c3.301 3.873 13.287 15.146 20.257 13.685 7.155-1.503 7.4-14.82-1.226-20.33-8.627-5.508-27.893-3.75-19.031 6.644z"
            ></path>
            <path
                fill="#565064"
                d="M337.401 24.44c-6.572-2.178-7.155 4.24-6.644 8.237l8.074 5.938a24.447 24.447 0 007.4-2.422c3.169-1.809-2.259-9.577-8.83-11.754z"
            ></path>
            <path
                fill="#EE8181"
                d="M338.882 54.805a7.065 7.065 0 01-2.381 4.568c-1.799 1.523-3.588.174-3.843-2.044-.225-1.983.439-5.11 2.606-5.836s3.823 1.145 3.618 3.312z"
            ></path>
            <path
                fill="#663398"
                d="M270.73 179.437c-.276 8.299 2.258 18.397 5.038 26.819a138.155 138.155 0 001.789 5.111c2.535 6.909 4.804 11.723 4.804 11.723l-11.131 6.337s-1.512-1.901-3.74-5.243c-1.022-1.472-2.106-3.24-3.302-5.244-6.234-10.425-14.391-27.463-14.779-45.595-.613-28.373 58.197-34.025 58.197-34.025h27.811l-7.788 22.128s-56.399 2.893-56.899 17.989z"
            ></path>
            <path
                fill="#04010D"
                d="M270.73 179.437c-.276 8.299 2.258 18.397 5.038 26.819a138.155 138.155 0 001.789 5.111c2.535 6.909 4.804 11.723 4.804 11.723l-11.131 6.337s-1.512-1.901-3.74-5.243c-1.022-1.472-2.106-3.24-3.302-5.244-6.234-10.425-14.391-27.463-14.779-45.595-.613-28.373 58.197-34.025 58.197-34.025h27.811l-7.788 22.128s-56.399 2.893-56.899 17.989z"
                opacity="0.6"
            ></path>
            <path
                fill="#565064"
                d="M277.557 211.418c-1.451 2.412-5.376 8.555-10.047 12.766-1.022-1.472-2.105-3.24-3.301-5.244l11.56-12.653a135.657 135.657 0 001.788 5.131zM287.134 155.93l-1.185 12.06c-8.177 2.576-14.555 6.133-15.178 10.896-1.39-10.691 6.05-16.967 16.363-22.956z"
            ></path>
            <path
                fill="#663398"
                d="M317.602 139.311s-34.812 14.206-40.116 29.906c-6.766 20.032-25.164 48.344-25.164 48.344l7.727 10.057s21.239-20.584 35.712-44.154c18.131-29.64 61.815 1.666 49.693-44.153h-27.852z"
            ></path>
            <path
                fill="#04010D"
                d="M317.602 139.311s-34.812 14.206-40.116 29.906c-6.766 20.032-25.164 48.344-25.164 48.344l7.727 10.057s21.239-20.584 35.712-44.154c18.131-29.64 61.815 1.666 49.693-44.153h-27.852z"
                opacity="0.6"
            ></path>
            <path
                fill="#565064"
                d="M321.7 51.923c.133.736-.153 1.41-.633 1.493-.481.081-1.022-.44-1.114-1.186-.092-.746.153-1.4.633-1.492.481-.092.982.45 1.114 1.185zM313.351 53.415c.133.736-.153 1.41-.634 1.492-.48.082-1.022-.439-1.114-1.175-.092-.736.153-1.41.634-1.502.48-.092.981.47 1.114 1.185z"
            ></path>
            <path
                fill="#565064"
                d="M312.461 52.24l-1.87-.195s1.165 1.216 1.87.194z"
            ></path>
            <path
                fill="#EE8181"
                d="M315.783 54.294a20.032 20.032 0 01-1.789 5.182 3.242 3.242 0 002.729 0l-.94-5.182z"
            ></path>
            <path
                fill="#565064"
                d="M320.975 61.418a.156.156 0 01-.133-.092.146.146 0 01.003-.109.146.146 0 01.079-.075 6.222 6.222 0 003.066-3.301.138.138 0 01.07-.078.132.132 0 01.104-.004.154.154 0 01.092.184 6.47 6.47 0 01-3.26 3.465l-.021.01zM322.631 48.611a.283.283 0 01-.194-.071 3.694 3.694 0 00-3.169-.95.275.275 0 01-.347-.195.287.287 0 01.026-.213.29.29 0 01.168-.135 4.22 4.22 0 013.731 1.023.29.29 0 010 .398.274.274 0 01-.215.143zM308.792 50.094h-.103a.298.298 0 01-.153-.378 4.228 4.228 0 012.944-2.515.281.281 0 01.322.23.282.282 0 01-.23.322 3.679 3.679 0 00-2.515 2.187.283.283 0 01-.265.154z"
            ></path>
            <path
                fill="#663398"
                d="M247.436 226.198c-1.206-.235-3.291-.787-3.751-1.85a1.197 1.197 0 01.154-1.155.667.667 0 01.593-.358c1.155 0 2.964 2.8 3.158 3.128a.139.139 0 01.025.081.14.14 0 01-.025.082.179.179 0 01-.154.072zm-3.066-3.067a.388.388 0 00-.337.205.928.928 0 00-.123.869c.276.623 1.441 1.206 3.159 1.584-.655-.951-1.953-2.658-2.699-2.658z"
            ></path>
            <path
                fill="#663398"
                d="M247.437 226.198a.159.159 0 01-.068-.017.17.17 0 01-.055-.044c-.664-.91-1.257-3.342-.685-4.15a.7.7 0 01.859-.225 1.022 1.022 0 01.685.614c.541 1.206-.542 3.628-.593 3.73a.175.175 0 01-.05.061.157.157 0 01-.073.031h-.02zm-.266-4.18a.33.33 0 00-.296.153c-.43.603 0 2.586.541 3.537.256-.665.859-2.341.47-3.199a.75.75 0 00-.5-.45.59.59 0 00-.215-.041z"
            ></path>
            <path
                fill="#565064"
                d="M320.812 50.748l-1.871-.194s1.166 1.216 1.871.194z"
            ></path>
            <path
                fill="#663398"
                d="M345.975 137.103l.296 3.373c.133.266-.173.532-.613.532h-38.226c-.337 0-.623-.164-.644-.379l-.337-3.383c0-.235.276-.429.644-.429h38.257a.7.7 0 01.623.286z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M345.975 137.103l.296 3.373c.133.266-.173.532-.613.532h-38.226c-.337 0-.623-.164-.644-.379l-.337-3.383c0-.235.276-.429.644-.429h38.257a.7.7 0 01.623.286z"
                opacity="0.2"
            ></path>
            <path
                fill="#663398"
                d="M340.835 141.375h1.022c.204 0 .357-.102.347-.235l-.48-4.395c0-.123-.194-.235-.399-.235h-1.022c-.204 0-.358.112-.347.235l.48 4.395c.01.133.194.235.399.235z"
            ></path>
            <path
                fill="#04010D"
                d="M340.835 141.375h1.022c.204 0 .357-.102.347-.235l-.48-4.395c0-.123-.194-.235-.399-.235h-1.022c-.204 0-.358.112-.347.235l.48 4.395c.01.133.194.235.399.235z"
                opacity="0.6"
            ></path>
            <path
                fill="#663398"
                d="M310.315 141.375h1.022c.204 0 .368-.102.348-.235l-.481-4.395c0-.123-.194-.235-.398-.235h-1.022c-.205 0-.358.112-.348.235l.48 4.395c.021.133.154.235.399.235z"
            ></path>
            <path
                fill="#04010D"
                d="M310.315 141.375h1.022c.204 0 .368-.102.348-.235l-.481-4.395c0-.123-.194-.235-.398-.235h-1.022c-.205 0-.358.112-.348.235l.48 4.395c.021.133.154.235.399.235z"
                opacity="0.6"
            ></path>
            <path
                fill="#663398"
                d="M325.575 141.375h1.022c.204 0 .358-.102.347-.235l-.48-4.395c0-.123-.194-.235-.399-.235h-1.022c-.204 0-.357.112-.347.235l.48 4.395c.021.133.225.235.399.235z"
            ></path>
            <path
                fill="#04010D"
                d="M325.575 141.375h1.022c.204 0 .358-.102.347-.235l-.48-4.395c0-.123-.194-.235-.399-.235h-1.022c-.204 0-.357.112-.347.235l.48 4.395c.021.133.225.235.399.235z"
                opacity="0.6"
            ></path>
            <path
                fill="#663398"
                d="M343.216 97.549l4.875 4.149c-1.022 5.52-1.737 10.119-2.187 14.207-.91-4.098-3.23-15.167-2.688-18.356z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M343.216 97.549l4.875 4.149c-1.022 5.52-1.737 10.119-2.187 14.207-.91-4.098-3.23-15.167-2.688-18.356z"
                opacity="0.3"
            ></path>
            <path
                fill="#FCFCFC"
                d="M239.924 113.207h66.312l9.608 39.146h-66.302l-9.618-39.146z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M273.52 145.064l40.556 9.455 26.022-7.288-.266-2.167H273.52z"
            ></path>
            <path
                fill="#663398"
                d="M237.891 113.207h66.302l9.607 39.146h-66.302l-9.607-39.146z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M237.891 113.207h66.302l9.607 39.146h-66.302l-9.607-39.146z"
                opacity="0.2"
            ></path>
            <path
                fill="#663398"
                d="M247.498 152.352l.266 2.166h66.312l-.276-2.166h-66.302z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M247.498 152.352l.266 2.166h66.312l-.276-2.166h-66.302z"
                opacity="0.2"
            ></path>
            <path
                fill="#EE8181"
                d="M355.664 93.287a165.943 165.943 0 014.763 16.732 97.388 97.388 0 011.666 8.697c.215 1.493.389 3.016.481 4.61 0 .797.061 1.615 0 2.473V126.822l-.052.49a9.9 9.9 0 01-.408 1.871 12.645 12.645 0 01-3.455 5.212 24.682 24.682 0 01-4.088 3.066 49.583 49.583 0 01-8.289 3.935 104.156 104.156 0 01-8.412 2.658 81.054 81.054 0 01-8.555 1.829l-1.155-4.671c2.566-.94 5.213-1.931 7.748-3.066 2.534-1.134 5.11-2.177 7.481-3.414a48.02 48.02 0 006.715-4.016 16.682 16.682 0 002.555-2.259 4.636 4.636 0 001.125-1.86 17.75 17.75 0 00-.174-2.586c-.153-1.237-.378-2.525-.624-3.833-.521-2.606-1.195-5.264-1.87-7.931-.675-2.668-1.482-5.336-2.279-7.993-.797-2.657-1.615-5.356-2.412-7.952l9.239-3.015z"
            ></path>
            <path
                fill="#663398"
                d="M352.097 82.902c3.792 1.145 9.588 16.006 9.588 16.006l-14.688 10.497s-6.602-12.705-5.018-17.59c1.656-5.1 5.805-10.231 10.118-8.913z"
            ></path>
            <path
                fill="#FCFCFC"
                d="M352.097 82.902c3.792 1.145 9.588 16.006 9.588 16.006l-14.688 10.497s-6.602-12.705-5.018-17.59c1.656-5.1 5.805-10.231 10.118-8.913z"
                opacity="0.6"
            ></path>
            <path
                fill="#EE8181"
                d="M330.092 141.549l-8.78-2.238 4.201 8.483s5.969.255 7.942-3.066l-3.363-3.179zM314.148 142.54l3.895 6.899 7.43-1.646-4.201-8.473-7.124 3.22z"
            ></path>
        </svg>
    );
};
