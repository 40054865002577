import { toast } from "react-hot-toast";

import { reAuth } from "./auth";
import { Auth } from "./auth/Authentication";
import { handleErrors } from "./errors";

type THandleStatusCases<TBody> = {
    body: TBody | any;
    statusCode: number;
    errorFeedBacks?: Record<number, string> | any;
};

enum HttpStatusCode {
    okWithData = 201,
    ok = 200,
    noContent = 204,
    nonAuthorized = 401,
    redirect = 302,
    conflict = 409,
    serverError = 500,
    unknownError = 0,
    forbidden = 403,
}

export default <TBody>({
    body,
    statusCode,
    errorFeedBacks = { 0: "" },
}: THandleStatusCases<TBody>) => {
    const statusCodeCases = {
        [HttpStatusCode.okWithData]: () => body,
        [HttpStatusCode.noContent]: () => body,
        [HttpStatusCode.ok]: () => body,
        [HttpStatusCode.conflict]: () => {
            if (!errorFeedBacks[statusCode]) toast.error(JSON.stringify(body));
            return handleErrors({
                body,
                message:
                    errorFeedBacks[statusCode] ||
                    "Erro! Entre em contato com o suporte.",
            });
        },
        [HttpStatusCode.forbidden]: () => {
            if (body?.message === "user is inactive") {
                window.location.href = `/sem-acesso`;
                return body;
            }
            if (body?.message === "admin is inactive") {
                return body;
            }
            if (!errorFeedBacks[statusCode]) toast.error(JSON.stringify(body));
            return handleErrors({
                body,
                message:
                    errorFeedBacks[statusCode] ||
                    "Erro! Entre em contato com o suporte.",
            });
        },
        [HttpStatusCode.serverError]: () => {
            toast.error("Erro! Entre em contato com o suporte.");
            return handleErrors({
                body,
                message:
                    JSON.stringify(body) ||
                    "Erro! Entre em contato com o suporte.",
            });
        },
        [HttpStatusCode.nonAuthorized]: async () => {
            const userIsAuthenticated = Auth.getInstance().isAuthenticated();
            if (body.message === "Invalid Token") return null;
            if (!userIsAuthenticated) {
                if (!errorFeedBacks[statusCode])
                    toast.error(JSON.stringify(body));
                return handleErrors({
                    body,
                    message:
                        errorFeedBacks[statusCode] ||
                        "Erro! Entre em contato com o suporte.",
                });
            }
            await reAuth();
            return body;
        },
        [HttpStatusCode.unknownError]: async () => {
            Auth.getInstance().logout("erro");
            return body;
        },
        [HttpStatusCode.redirect]: () => {
            return body;
        },
        default: () => {
            if (!errorFeedBacks[statusCode]) toast.error(JSON.stringify(body));

            return handleErrors({
                body,
                message:
                    errorFeedBacks[body?.message?.error?.toLowerCase()] ||
                    errorFeedBacks[statusCode] ||
                    "Erro! Entre em contato com o suporte.",
            });
        },
    };

    return (
        statusCodeCases[statusCode as keyof typeof statusCodeCases] ||
        statusCodeCases.default
    )();
};
