import styled from "styled-components";

export const FlexColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
`;

export const Input = styled.input``;

export const Img = styled.img`
    object-fit: cover;
    object-position: center;
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 1rem;
    position: absolute;
    padding-left: 0.3125rem;
`;
