import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Icons, theme } from "../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../components";
import { TProject, TResponse } from "../../../../../models";
import { TCreateSection } from "../../../../../services/activities/create-section";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-components";

type TAddSection = {
    isToCreateSection: boolean;
    setIsToCreateSection: React.Dispatch<React.SetStateAction<boolean>>;
    project: TProject;
    createSection: TResponse<unknown, TCreateSection>;
};

export const AddSection = ({
    isToCreateSection,
    setIsToCreateSection,
    project,
    createSection,
}: TAddSection) => {
    const {
        register,
        reset,
        handleSubmit,
        setFocus,
        formState: { errors },
    } = useForm();

    const submit = handleSubmit(({ sectionName }) => {
        createSection.onRefresh({
            name: sectionName,
            projectId: project.id_e,
        });
    });

    useEffect(() => {
        if (createSection.response) {
            setIsToCreateSection(false);
            reset();
        }
    }, [createSection.response]);

    useEffect(() => {
        if (isToCreateSection) {
            setFocus("sectionName");
        }
    }, [isToCreateSection]);

    return isToCreateSection ? (
        <s.AddSectionContainer
            onSubmit={submit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setIsToCreateSection(false);
                    reset();
                }
                if (e.key === "Enter") {
                    document.getElementById("save")?.click();
                }
            }}
        >
            <div>
                <Input
                    {...register("sectionName", {
                        required: {
                            value: true,
                            message: "Campo obrigatório",
                        },
                    })}
                    maxLength={150}
                    placeholder="Escreva o nome da sessão"
                    mask=""
                    error={errors.sectionName?.message as string}
                />
                <s.ButtonsContainer>
                    <SecondaryButton
                        onClick={() => {
                            setIsToCreateSection(false);
                            reset();
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        id="save"
                        type="submit"
                        loading={createSection.loading}
                    >
                        ADICIONAR
                    </PrimaryButton>
                </s.ButtonsContainer>
            </div>
        </s.AddSectionContainer>
    ) : (
        <s.AddSectionButton
            onClick={() => {
                setIsToCreateSection(true);
                Mixpanel.track("Adicionar sessão");
            }}
        >
            <Icons.Plus color={theme.gray800} width="24" height="24" />
            Adicionar sessão
        </s.AddSectionButton>
    );
};
