import { styled } from "styled-components";

export const Container = styled.div<{ isAdmin?: boolean }>`
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    height: ${({ isAdmin }) => (isAdmin ? "100vh" : "fit-content")};
    min-height: 75vh;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    margin-top: -1px;
    height: fit-content;

    padding: 16px 25px;
    background-color: ${({ theme }) => theme.purple50};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};

    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Content = styled.div<{ havePermission?: boolean }>`
    padding: 40px 25px;

    display: flex;
    justify-content: ${({ havePermission }) =>
        havePermission ? "space-between" : "flex-end"};
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 24px;
    margin-top: auto;
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
    font-size: 14px;
    color: ${({ theme }) => theme.gray800};
`;
