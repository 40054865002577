import { SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Props = {
    projects: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function ModalClientProjectsInProgress({ setOpen, projects }: Props) {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Não foi possível inativar o cliente</s.Title>
                <s.Text>
                    O cliente pertence a projetos que ainda não foram
                    finalizados ou cancelados. <br />
                    Altere o cliente dos projetos ou mude o status deles para
                    inativa-lo.
                </s.Text>
                <s.Text>
                    Projetos: <strong>{projects}</strong>
                </s.Text>
                <s.Buttons>
                    <SecondaryButton
                        type="button"
                        onClick={() => setOpen(false)}
                    >
                        Ok
                    </SecondaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
}
