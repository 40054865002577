import {
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../components/atoms";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import * as s from "./styled-delete-account-modal";

type TDeleteAccountModal = {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteAccountModal = ({ onOpenChange }: TDeleteAccountModal) => {
    return (
        <ContainerModal onOpenChange={onOpenChange} closeIcon>
            <s.Container>
                <s.Title>Exclusão da conta</s.Title>
                <s.Subtitle>Lamentamos que queira sair do PaperON</s.Subtitle>
                <s.Description>
                    <p>
                        Para prosseguir, envie um e-mail solicitando a exclusão
                        da conta para <b>suporte@paperon.app</b>.
                    </p>
                    <br />
                    <p>Responderemos a sua solicitação em até 72 horas.</p>
                </s.Description>
                <s.Buttons>
                    <SecondaryButton onClick={() => onOpenChange(false)}>
                        cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            window.location.href =
                                "mailto: suporte@paperon.app";
                        }}
                    >
                        solicitar exclusão por e-mail
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
