import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TUpdateCollaboratorsWorkload = {
    usersIds?: string[];
    workloadValue?: string;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    usersIds,
    workloadValue,
}: TUpdateCollaboratorsWorkload = {}) => {
    const DEFAULT_WORKLOAD_VALUE = 168;
    const params = {
        usersIds,
        workloadValue:
            (workloadValue && parseInt(workloadValue, 10)) ||
            DEFAULT_WORKLOAD_VALUE,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/update`,
        method: "put",
        headers: { auth: true },
        body: params,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Rota não encontrada!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
