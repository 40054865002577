import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 22px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

export const SaveButtonWrapper = styled.div`
    margin-top: 29px;
    width: 100px;
`;
