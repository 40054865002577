import styled from "styled-components";

export const Container = styled.div`
    width: 600px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.purple600};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
`;

export const Text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.875rem;
    font-weight: 400;

    p {
        color: ${({ theme }) => theme.gray600};
    }

    span {
        font-weight: 600;
        color: ${({ theme }) => theme.purple600};
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    button {
        width: fit-content;
        padding: 0 1rem;
    }
`;
