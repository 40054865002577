import { useMutation } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Input, PrimaryButton, SecondaryButton } from "../../..";
import { AddMassProjectTable, Icons, theme } from "../../../../assets";
import { projects } from "../../../../services";
import ContainerModal from "../container-modal";
import { ModalMassProjectVerification } from "../modal-mass-project-verification/modal-mass-project-verification";
import * as s from "./styles";

type TInputs = {
    attachment?: File;
};

type TModalInviteMass = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    onClose: () => void;
};

export const ModalMassProjectCreation = ({
    setOpen,
    onClose,
}: TModalInviteMass) => {
    const [verificationModalOpen, setVerificationModalOpen] =
        useState<boolean>(false);

    const { handleSubmit, control, watch } = useForm<TInputs>();
    const [file, setFile] = useState<File>();

    const verifyMassCreation = useMutation({
        mutationKey: ["verifyMassCreation"],
        mutationFn: projects.verifyMassCreation,
        onSuccess() {
            setVerificationModalOpen(true);
        },
        onError() {
            setVerificationModalOpen(true);
        },
    });

    function downloadTemplate() {
        fetch(AddMassProjectTable).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                const alink = document.createElement("a");
                alink.href = fileURL;
                alink.setAttribute("download", "Adicionar-Projetos-Massa.xlsx");
                alink.click();
            });
        });
    }

    const onSubmit = () => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file as Blob);
            verifyMassCreation.mutate({ docFile: formData });
        }
    };

    return (
        <>
            {verificationModalOpen && (
                <ModalMassProjectVerification
                    data={{
                        result:
                            verifyMassCreation.data || verifyMassCreation.error,
                        type: verifyMassCreation.error ? "error" : "success",
                    }}
                    setOpen={setVerificationModalOpen}
                />
            )}
            <ContainerModal>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <s.Container>
                        <s.Title>Adicionar projetos em massa</s.Title>

                        <s.StepByStep>
                            <p>
                                Para adicionar projetos em massa, baixe o nosso
                                template da planilha Excel, preencha as
                                informações e importe os dados.
                            </p>

                            <p>
                                <span>Passo 1:</span> Baixe o template da
                                planilha Excel
                            </p>

                            <p>
                                <span>Passo 2:</span> Preencha o nome e o
                                cliente dos projetos na planilha
                            </p>
                            <p>
                                <span>Passo 3:</span> Anexe a planilha Excel
                                preenchida
                            </p>
                            <p>
                                <span>Passo 4:</span> Importe a planilha no
                                botão “importar dados planilha”
                            </p>
                        </s.StepByStep>

                        <SecondaryButton
                            icon={<Icons.Download color={theme.purple600} />}
                            onClick={() => downloadTemplate()}
                            type="button"
                        >
                            TEMPLATE PLANILHA EXCEL
                        </SecondaryButton>

                        <s.AttachmentContainer>
                            <h2>Anexar planilha Excel preenchida</h2>
                            <Controller
                                name="attachment"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Input
                                        mask=""
                                        type="file"
                                        icon={<Icons.PaperClip />}
                                        label={
                                            "Preencha até 1.000 projetos por vez"
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (!e.target.files) return;
                                            setFile(e?.target?.files[0]);
                                            onChange(e?.target?.files[0]);
                                        }}
                                        accept=".csv, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xml, .xlam, .xlsx"
                                    />
                                )}
                            />
                        </s.AttachmentContainer>

                        <s.ButtonsContainer>
                            <SecondaryButton
                                variation="small"
                                onClick={() => {
                                    setOpen(false);
                                    onClose();
                                }}
                            >
                                CANCELAR
                            </SecondaryButton>
                            <PrimaryButton
                                variation="small"
                                disabled={
                                    !watch("attachment") ||
                                    verifyMassCreation.isLoading
                                }
                                type="submit"
                            >
                                IMPORTAR DADOS da PLANILHA
                                {verifyMassCreation.isLoading && (
                                    <Icons.Loading />
                                )}
                            </PrimaryButton>
                        </s.ButtonsContainer>
                    </s.Container>
                </form>
            </ContainerModal>
        </>
    );
};
