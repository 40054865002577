import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { FotoUser, Icons } from "../../../../assets";
import {
    AlertAction,
    CheckBox,
    Input,
    LoadingGray,
    PrimaryButton,
    Scroll,
    SecondaryButton,
} from "../../../../components";
import { Tooltip } from "../../../../components/atoms";
import { DefinePermissionsModal } from "../../../../components/molecules/modals/modal-define-permissions/modal-define-permissions";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TOption, TProject, TUser } from "../../../../models";
import { manageUser, projects } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import { normalizeString } from "../../../../utils";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import {
    projectPeopleSize,
    renderListWithComma,
} from "../../utils/projects-utils";
import { LeaveEditCollaboratorsModal } from "./components/leave-edit-collaborators/leave-edit-collaborators";
import * as s from "./styled-tab-collabs";
import schema from "./validation";

type TCollaboratorsEdit = {
    project: TProject;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    getProject: TUseFetch;
};

type TFields = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    color?: string;
    start_at?: string;
    end_at?: string;
    projectId?: string;
    usersId?: string;
    sectorsId?: string;
    reviewersId?: string;
    billersId?: string;
    estimatedHours?: number;
    sectors?: TOption[];
};

type TReviewer = {
    avatar?: string | null | undefined;
    id_e: string;
    name: string;
    role: string;
};

type TBiller = {
    avatar: string | null;
    id_e: string;
    name: string;
    Role: {
        name: string;
    };
};

type TPermission = {
    isReviewer?: boolean;
    isBiller?: boolean;
};

type TCollaborator = TUser & TPermission;

type TReturnCorrectTooltipMessage = {
    type: "reviewer" | "biller";
    checked: boolean;
    disabled: boolean;
};

export type TDefinePermissionsModalData = {
    type: "reviewer" | "biller";
    user: TUser & {
        isReviewer?: boolean;
        isBiller?: boolean;
    };
};

dayjs.extend(utc);

export const CollaboratorsEdit = ({
    project,
    setEdit,
    getProject,
}: TCollaboratorsEdit) => {
    const [loading, setLoading] = useState<boolean>(false);
    const checkReviewer = (userId: string) => {
        return project?.ProjectReviewers?.some(
            (reviewer) => reviewer.id_e === userId
        );
    };
    const checkBiller = (userId: string) => {
        return project?.ProjectBiller?.some((biller) => biller.id_e === userId);
    };
    const [teamSearch, setTeamSearch] = useState<string>("");
    const [allUsers, setAllUsers] = useState<TUser[]>([]);
    const [reviewersData, setReviewersData] = useState(
        project?.ProjectReviewers?.map((reviewer: TReviewer) => {
            return reviewer;
        }) || []
    );
    const [billersData, setBillersData] = useState(
        project?.ProjectBiller?.map((biller: TBiller) => {
            return biller;
        }) || []
    );
    const [usersData, setUsersData] = useState<TCollaborator[]>(
        (project?.Users || []).map((user: TUser & TPermission) => {
            return {
                ...user,
                isReviewer: checkReviewer(user?.id_e || ""),
                isBiller: checkBiller(user?.id_e || ""),
            };
        })
    );
    const [definePermissionsModal, setDefinePermissionsModal] =
        useState<boolean>(false);
    const [exitConfirmationModal, setExitConfirmationModal] =
        useState<boolean>(false);
    const [definePermissionsModalData, setDefinePermissionsModalData] =
        useState<TDefinePermissionsModalData>();
    const defaultValues = {
        sectors: project?.Sectors?.map(
            (sector: { name: string; id_e: string }) => {
                return { label: sector.name, value: sector.id_e };
            }
        ),
    };
    const { refreshProject } = useUser();

    const getAllUsers = useFetch({
        fn: manageUser.getAllUsers,
        errorMessage: "Erro ao pegar usuários da organização",
        params: { sectors: true },
        start: true,
    });

    const updateProject = useFetch({
        fn: projects.updateProject,
        start: false,
    });

    useEffect(() => {
        if (getAllUsers?.response?.length) {
            setUsersData((prevUsersData) => {
                const usersIds = prevUsersData.map((user) => user.id_e);
                const activeUsers: TUser[] = getAllUsers.response.filter(
                    (user: TUser) => {
                        return user?.active;
                    }
                );
                const users = activeUsers.filter((user) =>
                    usersIds.includes(user.id_e)
                );
                const result = users.map((user) => ({
                    ...user,
                    isReviewer: checkReviewer(user?.id_e || ""),
                    isBiller: checkBiller(user?.id_e || ""),
                }));
                return orderBy(result, "name", "asc");
            });
        }
    }, [getAllUsers.response]);

    const { handleSubmit } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const handleCheckbox = (type: "reviewer" | "biller", userId?: string) => {
        setUsersData((prevState) => {
            const userIndex = prevState.findIndex(
                (user) => user.id_e === userId
            );

            if (userIndex === -1) {
                return prevState;
            }

            if (type === "reviewer") {
                const updatedUsers = [...prevState];
                updatedUsers[userIndex] = {
                    ...updatedUsers[userIndex],
                    isReviewer: !updatedUsers[userIndex].isReviewer,
                };
                return updatedUsers;
            }

            if (type === "biller") {
                const updatedUsers = [...prevState];
                updatedUsers[userIndex] = {
                    ...updatedUsers[userIndex],
                    isBiller: !updatedUsers[userIndex].isBiller,
                };
                return updatedUsers;
            }

            return prevState;
        });
    };

    const returnCorrectTooltipMessage = ({
        type,
        checked,
        disabled,
    }: TReturnCorrectTooltipMessage) => {
        const typeMessage = type === "reviewer" ? "revisor" : "faturador";
        if (disabled) {
            return `A atuação deste profissional não permite lhe conceder permissão de ${typeMessage}.`;
        }
        if (checked) {
            return `Remover permissão de ${typeMessage}.`;
        }
        return `Conceder permissão de ${typeMessage}.`;
    };

    const handleDeleteUser = (user: TUser) => {
        setBillersData((prevState: TBiller[]) => {
            return prevState.filter((biller) => biller.id_e !== user?.id_e);
        });
        setReviewersData((prevState: TReviewer[]) => {
            return prevState.filter((reviewer) => reviewer.id_e !== user?.id_e);
        });
        setUsersData((prevState: TUser[]) => {
            return prevState.filter(
                (collaborator) => collaborator.id_e !== user?.id_e
            );
        });
    };

    const filterTeam = () => {
        const alreadyAddedUsers = usersData.map((user) => user.id_e);
        const searchString = normalizeString(teamSearch);

        return allUsers.filter((user: TUser) => {
            return (
                (normalizeString(user?.name).includes(searchString) ||
                    normalizeString(user?.role).includes(searchString)) &&
                !alreadyAddedUsers.includes(user?.id_e)
            );
        });
    };

    const addTeamUser = (collaborator: TUser) => {
        setUsersData((prevUser: TUser[]) => {
            const isUserExist = prevUser.some(
                (user: TUser) => user.id_e === collaborator?.id_e
            );
            if (isUserExist) {
                toast.error("Profissional já adicionado");
                return prevUser;
            }

            return [...prevUser, collaborator];
        });
        setAllUsers((prevUser: TUser[]) => {
            return prevUser.filter(
                (user: TUser) => user.id_e !== collaborator?.id_e
            );
        });
        toast.success("Profissional adicionado ao time com sucesso");
    };

    useEffect(() => {
        if (getAllUsers.response) {
            const activeUsers = getAllUsers.response.filter((user: TUser) => {
                return user?.active;
            });
            setAllUsers(activeUsers);
        }
        setLoading(false);
    }, [getAllUsers.response]);

    useEffect(() => {
        if (updateProject.error) {
            setLoading(false);
        }
        if (updateProject.response) {
            toast.success(`Equipe atualizada com sucesso!`);
            getProject.onRefresh({
                projectId: project?.id_e,
                users: true,
                hours: true,
                finished: false,
                sectors: true,
                reviewers: true,
                all: true,
            });
            setEdit(false);
            setLoading(false);
            refreshProject({
                sectors: true,
                showOnlyActiveProjects: true,
            });
            Mixpanel.track("Salvar edição do projeto na aba time");
        }
    }, [updateProject.response, updateProject.error]);

    const retrieveSelectedUsersSectors = (users: TCollaborator[]): string => {
        const sectors: string[] = [];
        const usersSectors = users.map((user) => user?.Sectors);
        usersSectors.forEach((userSectors) => {
            userSectors?.forEach((sector) => {
                if (!sectors.includes(sector.id_e)) {
                    sectors.push(sector.id_e);
                }
            });
        });
        return sectors.join(", ");
    };

    const onSubmit = () => {
        setLoading(true);

        const users = usersData.map((user) => user.id_e).join(", ");

        const reviewers = usersData
            .filter((user) => user.isReviewer === true)
            .map((user) => user.id_e)
            .join(", ");

        const billers = usersData
            .filter((user) => user.isBiller === true)
            .map((user) => user.id_e)
            .join(", ");

        updateProject.onRefresh({
            name: project?.name,
            clientId: project?.Client?.id_e,
            description: project?.description,
            color: project?.color,
            start_at:
                dayjs.utc(project?.start_at).add(3, "hour").toDate() || "",
            end_at: dayjs.utc(project?.end_at).add(3, "hour").toDate() || "",
            projectId: project?.id_e,
            estimatedHours: project?.estimatedHours,
            usersId: users,
            reviewersId: reviewers,
            billersId: billers,
            sectorsId: retrieveSelectedUsersSectors(usersData),
        });
    };

    const renderListBody = (usersData: TCollaborator[]) => {
        return (
            <s.ListBody templateColumns={"2fr 1fr 1fr 0.5fr 0.5fr 0.5fr"}>
                <Scroll>
                    {usersData?.map((user, index: number) => (
                        <div className="content-row" key={user?.id_e}>
                            <div className="left-align">
                                <div className="collaborators">
                                    <s.ProfilePicture
                                        src={user?.avatar || FotoUser}
                                    />
                                    {user?.name || ""}
                                </div>
                            </div>
                            <div className="left-align">{user?.role || ""}</div>
                            <div className="sector-content">
                                {renderListWithComma(user?.Sectors)}
                            </div>
                            <Tooltip
                                positiony={10}
                                side="top"
                                align="start"
                                content={
                                    <s.PermissionsTooltip>
                                        {returnCorrectTooltipMessage({
                                            type: "reviewer",
                                            checked: Boolean(user?.isReviewer),
                                            disabled:
                                                !user?.Permissions?.includes(
                                                    "review_reports"
                                                ),
                                        })}
                                    </s.PermissionsTooltip>
                                }
                            >
                                <div
                                    className="check-box"
                                    style={{
                                        cursor: !user?.Permissions?.includes(
                                            "review_reports"
                                        )
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (
                                            !user?.Permissions?.includes(
                                                "review_reports"
                                            )
                                        )
                                            return;
                                        setDefinePermissionsModalData({
                                            type: "reviewer",
                                            user,
                                        });
                                        setDefinePermissionsModal(true);
                                    }}
                                >
                                    <CheckBox
                                        id={`checkbox-reviewer-${index}`}
                                        width={16}
                                        height={16}
                                        strokeWidth="3"
                                        isChecked={user?.isReviewer}
                                        onChange={() => ({})}
                                        disabled={
                                            !user?.Permissions?.includes(
                                                "review_reports"
                                            )
                                        }
                                    />
                                </div>
                            </Tooltip>

                            <Tooltip
                                positiony={10}
                                side="top"
                                align="start"
                                content={
                                    <s.PermissionsTooltip>
                                        {returnCorrectTooltipMessage({
                                            type: "biller",
                                            checked: Boolean(user?.isBiller),
                                            disabled:
                                                !user?.Permissions?.includes(
                                                    "bill_reports"
                                                ),
                                        })}
                                    </s.PermissionsTooltip>
                                }
                            >
                                <div
                                    className="check-box"
                                    style={{
                                        cursor: !user?.Permissions?.includes(
                                            "bill_reports"
                                        )
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (
                                            !user?.Permissions?.includes(
                                                "bill_reports"
                                            )
                                        )
                                            return;
                                        setDefinePermissionsModal(true);
                                        setDefinePermissionsModalData({
                                            type: "biller",
                                            user,
                                        });
                                    }}
                                >
                                    <CheckBox
                                        id={`checkbox-faturador-${index}`}
                                        width={16}
                                        height={16}
                                        strokeWidth="3"
                                        isChecked={user?.isBiller}
                                        onChange={() => ({})}
                                        disabled={
                                            !user?.Permissions?.includes(
                                                "bill_reports"
                                            )
                                        }
                                    />
                                </div>
                            </Tooltip>
                            <div>
                                <AlertAction
                                    onClickBtn={() => handleDeleteUser(user)}
                                    trigger={
                                        <button>
                                            <Icons.Trash />
                                        </button>
                                    }
                                    description={`Deseja remover ${user?.name} do projeto?`}
                                    title={"Remover profissional"}
                                    textBtn={"Remover"}
                                />
                            </div>
                        </div>
                    )) || ""}
                </Scroll>
            </s.ListBody>
        );
    };

    useEffect(() => {
        setLoading(true);

        const delay = setTimeout(() => {
            renderListBody(usersData);
            setLoading(false);
        }, 1000);

        return () => clearTimeout(delay);
    }, [usersData]);

    return (
        <s.Form onSubmit={handleSubmit(onSubmit)}>
            <s.Header>
                {definePermissionsModal && (
                    <DefinePermissionsModal
                        setOpen={setDefinePermissionsModal}
                        data={definePermissionsModalData}
                        handleCheckbox={handleCheckbox}
                    />
                )}
                {exitConfirmationModal && (
                    <LeaveEditCollaboratorsModal
                        setModalOpen={setExitConfirmationModal}
                        onConfirm={() => {
                            onSubmit();
                            setExitConfirmationModal(false);
                        }}
                    />
                )}
                <s.FieldsWrapper>
                    <div className="field-row">
                        <s.FieldTitle>Time:</s.FieldTitle>
                        <s.FieldText>
                            {projectPeopleSize(usersData) || ""}
                        </s.FieldText>
                    </div>
                    <div className="field-row">
                        <s.FieldTitle>Revisor:</s.FieldTitle>
                        <s.FieldText>
                            {renderListWithComma(reviewersData)}
                        </s.FieldText>
                    </div>
                    <div className="field-row">
                        <s.FieldTitle>Faturador:</s.FieldTitle>
                        <s.FieldText>
                            {renderListWithComma(billersData)}
                        </s.FieldText>
                    </div>
                </s.FieldsWrapper>
                <s.ActionWrapper>
                    <div className="action-buttons">
                        <SecondaryButton
                            variation="small"
                            onClick={() => setEdit(false)}
                        >
                            Cancelar
                        </SecondaryButton>
                        <PrimaryButton
                            variation="small"
                            type="button"
                            onClick={() => {
                                setExitConfirmationModal(true);
                            }}
                            disabled={loading}
                        >
                            Salvar
                        </PrimaryButton>
                    </div>
                    <div className="action-input">
                        <Input
                            mask=""
                            type="text"
                            onChange={(e) => setTeamSearch(e.target.value)}
                            value={teamSearch}
                            icon={<Icons.SearchOutline />}
                            placeholder="Pesquise por profissional"
                            disabled={false}
                        />
                        {teamSearch && (
                            <div className="popover-container">
                                {allUsers.length === 0 ? (
                                    <div className="popover-item-no-content">
                                        <p>Nenhum usuário encontrado</p>
                                    </div>
                                ) : (
                                    filterTeam().map(
                                        (
                                            collaborator: TUser,
                                            index: number
                                        ) => (
                                            <div
                                                className="popover-item"
                                                key={index}
                                                onClick={() => {
                                                    addTeamUser(collaborator);

                                                    setTeamSearch("");
                                                }}
                                            >
                                                <span className="first-item">
                                                    <s.ProfilePicture
                                                        src={
                                                            collaborator.avatar ||
                                                            FotoUser
                                                        }
                                                    />
                                                    <span>
                                                        {collaborator.name}
                                                    </span>
                                                </span>
                                                <span
                                                    style={{
                                                        marginRight: "40px",
                                                    }}
                                                >
                                                    {collaborator.role}
                                                </span>
                                            </div>
                                        )
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </s.ActionWrapper>
            </s.Header>
            <s.CollaboratorsList>
                <s.ListHeader templateColumns={"2fr 1fr 1fr 0.5fr 0.5fr 0.5fr"}>
                    <div className="left-align">
                        Time ( {usersData?.length || "-"} )
                    </div>
                    <div className="left-align">Atuação</div>
                    <div>Setor</div>
                    <div>Revisor</div>
                    <div>Faturador</div>
                    <div></div>
                </s.ListHeader>
                {loading ? <LoadingGray /> : renderListBody(usersData)}
            </s.CollaboratorsList>
        </s.Form>
    );
};
