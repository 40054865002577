type TIlustracaoSemNotificacoes = {
    title?: string;
    className?: string;
};

export default ({ title, className }: TIlustracaoSemNotificacoes) => {
    return (
        <svg
            width="242"
            height="216"
            viewBox="0 0 242 216"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-ilustracao-sem-notificacoes"}
        >
            <title>{title}</title>
            <path
                d="M207.045 173.942H34.418V175.879H207.045V173.942Z"
                fill="#E7E6E9"
            />
            <path
                d="M207.045 184.858H173.387V185.034H207.045V184.858Z"
                fill="#E7E6E9"
            />
            <path
                d="M192.908 182.865H161.698V183.008H192.908V182.865Z"
                fill="#E7E6E9"
            />
            <path
                d="M192.124 179.576H166.62V179.752H192.124V179.576Z"
                fill="#E7E6E9"
            />
            <path
                d="M41.6384 180.518H34.4805V180.661H41.6384V180.518Z"
                fill="#E7E6E9"
            />
            <path
                d="M87.0563 180.518H46.6641V180.661H87.0563V180.518Z"
                fill="#E7E6E9"
            />
            <path
                d="M128.983 182.934H102.562V183.077H128.983V182.934Z"
                fill="#E7E6E9"
            />
            <path
                d="M152.035 91.3401H126.512L128.774 61.0303H149.774L152.035 91.3401Z"
                fill="#E7E6E9"
            />
            <path
                d="M146.434 91.3401H142.046L141.115 61.0303H145.165L146.434 91.3401Z"
                fill="#FCFCFC"
            />
            <path
                d="M140.551 91.3398H137.996V175.658H140.551V91.3398Z"
                fill="white"
            />
            <path
                d="M140.529 129.097H138.019C137.396 129.097 136.891 129.609 136.891 130.242V132.789C136.891 133.421 137.396 133.934 138.019 133.934H140.529C141.152 133.934 141.657 133.421 141.657 132.789V130.242C141.657 129.609 141.152 129.097 140.529 129.097Z"
                fill="white"
            />
            <path
                d="M140.529 136.848H138.019C137.396 136.848 136.891 137.36 136.891 137.993V140.54C136.891 141.172 137.396 141.685 138.019 141.685H140.529C141.152 141.685 141.657 141.172 141.657 140.54V137.993C141.657 137.36 141.152 136.848 140.529 136.848Z"
                fill="white"
            />
            <path
                d="M143.315 175.658H135.232C135.232 175.2 135.41 174.759 135.726 174.432C136.042 174.104 136.473 173.916 136.925 173.907H141.567C142.019 173.916 142.449 174.104 142.765 174.432C143.082 174.759 143.259 175.2 143.259 175.658H143.315Z"
                fill="white"
            />
            <path
                d="M140.551 91.3398H137.996V92.9255H140.551V91.3398Z"
                fill="white"
            />
            <path
                d="M120.21 201.509C166.69 201.509 204.369 198.608 204.369 195.029C204.369 191.45 166.69 188.549 120.21 188.549C73.7294 188.549 36.0498 191.45 36.0498 195.029C36.0498 198.608 73.7294 201.509 120.21 201.509Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.1"
                d="M166.555 16.1807L165.494 49.2324L191.029 47.4407L166.555 16.1807Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.1"
                d="M148.443 117.861L149.103 93.4126L53.4502 94.7292L148.443 117.861Z"
                fill="#8C53C5"
            />
            <path
                d="M51.876 19.1289H167.508V92.9716C167.508 93.4483 167.321 93.9055 166.989 94.2426C166.657 94.5797 166.207 94.769 165.737 94.769H53.6471C53.4145 94.769 53.1842 94.7226 52.9693 94.6322C52.7544 94.5419 52.5592 94.4095 52.3947 94.2426C52.2303 94.0757 52.0998 93.8775 52.0108 93.6595C51.9218 93.4414 51.876 93.2077 51.876 92.9716V19.1289Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M51.876 19.1289H167.508V92.9716C167.508 93.4483 167.321 93.9055 166.989 94.2426C166.657 94.5797 166.207 94.769 165.737 94.769H53.6471C53.4145 94.769 53.1842 94.7226 52.9693 94.6322C52.7544 94.5419 52.5592 94.4095 52.3947 94.2426C52.2303 94.0757 52.0998 93.8776 52.0108 93.6595C51.9218 93.4414 51.876 93.2077 51.876 92.9716V19.1289Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.9"
                d="M155.872 24.0635H63.4957C62.6141 24.0635 61.8994 24.7888 61.8994 25.6834V52.0321C61.8994 52.9268 62.6141 53.6521 63.4957 53.6521H155.872C156.753 53.6521 157.468 52.9268 157.468 52.0321V25.6834C157.468 24.7888 156.753 24.0635 155.872 24.0635Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.7"
                d="M89.245 58.1851H63.4957C62.6141 58.1851 61.8994 58.9103 61.8994 59.805V65.1973C61.8994 66.0919 62.6141 66.8172 63.4957 66.8172H89.245C90.1266 66.8172 90.8413 66.0919 90.8413 65.1973V59.805C90.8413 58.9103 90.1266 58.1851 89.245 58.1851Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M122.558 58.1851H96.8092C95.9276 58.1851 95.2129 58.9103 95.2129 59.805V65.1973C95.2129 66.0919 95.9276 66.8172 96.8092 66.8172H122.558C123.44 66.8172 124.155 66.0919 124.155 65.1973V59.805C124.155 58.9103 123.44 58.1851 122.558 58.1851Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M155.866 58.1851H130.117C129.235 58.1851 128.521 58.9103 128.521 59.805V65.1973C128.521 66.0919 129.235 66.8172 130.117 66.8172H155.866C156.748 66.8172 157.462 66.0919 157.462 65.1973V59.805C157.462 58.9103 156.748 58.1851 155.866 58.1851Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M63.4957 70.3091H89.245C89.4546 70.3091 89.6622 70.351 89.8559 70.4324C90.0495 70.5138 90.2255 70.6331 90.3737 70.7836C90.522 70.934 90.6395 71.1126 90.7198 71.3091C90.8 71.5057 90.8413 71.7163 90.8413 71.929V94.7172H61.8994V71.929C61.8994 71.4994 62.0676 71.0874 62.367 70.7836C62.6663 70.4798 63.0723 70.3091 63.4957 70.3091Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M96.8317 70.3092H122.581C123.004 70.3092 123.41 70.4799 123.71 70.7837C124.009 71.0875 124.177 71.4996 124.177 71.9292V94.7174H95.2129V71.9292C95.2129 71.7145 95.2549 71.502 95.3365 71.3039C95.4181 71.1059 95.5377 70.9263 95.6884 70.7755C95.839 70.6248 96.0177 70.506 96.2139 70.4259C96.4102 70.3459 96.6202 70.3062 96.8317 70.3092Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.3"
                d="M130.111 70.3091H155.86C156.284 70.3091 156.69 70.4798 156.989 70.7836C157.289 71.0874 157.457 71.4994 157.457 71.929V94.7172H128.521V71.929C128.521 71.5004 128.688 71.0892 128.986 70.7856C129.284 70.4819 129.689 70.3106 130.111 70.3091Z"
                fill="#8C53C5"
            />
            <path
                d="M162.477 14.0401H56.8905C56.2296 14.0401 55.5753 14.1726 54.965 14.4302C54.3548 14.6877 53.8008 15.0651 53.3348 15.5407C52.8687 16.0162 52.5 16.5806 52.2496 17.2013C51.9993 17.822 51.8723 18.4869 51.8761 19.1575H167.508C167.512 18.4854 167.384 17.8192 167.133 17.1973C166.882 16.5755 166.511 16.0104 166.043 15.5346C165.576 15.0588 165.02 14.6817 164.407 14.4252C163.795 14.1687 163.139 14.0378 162.477 14.0401Z"
                fill="#663398"
            />
            <path
                d="M58.9778 16.5816C58.9789 16.7836 58.9208 16.9814 58.8109 17.1498C58.701 17.3182 58.5443 17.4497 58.3606 17.5275C58.1769 17.6054 57.9745 17.626 57.7793 17.5869C57.584 17.5478 57.4046 17.4506 57.2638 17.3078C57.123 17.1649 57.0273 16.9828 56.9887 16.7847C56.9502 16.5865 56.9705 16.3811 57.0472 16.1947C57.1239 16.0083 57.2535 15.8492 57.4195 15.7377C57.5854 15.6262 57.7803 15.5672 57.9794 15.5684C58.2437 15.5699 58.4968 15.6771 58.6837 15.8668C58.8706 16.0565 58.9763 16.3133 58.9778 16.5816Z"
                fill="#FCFCFC"
            />
            <path
                d="M62.2038 16.5303C62.2038 16.7307 62.1452 16.9266 62.0355 17.0932C61.9258 17.2598 61.7699 17.3897 61.5875 17.4663C61.405 17.543 61.2043 17.5631 61.0106 17.524C60.817 17.4849 60.6391 17.3884 60.4995 17.2467C60.3598 17.105 60.2647 16.9245 60.2262 16.7279C60.1877 16.5314 60.2075 16.3277 60.283 16.1425C60.3586 15.9574 60.4866 15.7992 60.6507 15.6878C60.8149 15.5765 61.008 15.5171 61.2054 15.5171C61.4697 15.5186 61.7228 15.6258 61.9097 15.8155C62.0967 16.0052 62.2023 16.262 62.2038 16.5303Z"
                fill="#FCFCFC"
            />
            <path
                d="M65.4304 16.5303C65.4304 16.7307 65.3718 16.9266 65.2621 17.0932C65.1524 17.2598 64.9965 17.3897 64.814 17.4663C64.6316 17.543 64.4309 17.5631 64.2372 17.524C64.0435 17.4849 63.8656 17.3884 63.726 17.2467C63.5864 17.105 63.4913 16.9245 63.4528 16.7279C63.4143 16.5314 63.434 16.3277 63.5096 16.1425C63.5852 15.9574 63.7131 15.7992 63.8773 15.6878C64.0415 15.5765 64.2345 15.5171 64.432 15.5171C64.6963 15.5186 64.9494 15.6258 65.1363 15.8155C65.3232 16.0052 65.4289 16.262 65.4304 16.5303Z"
                fill="#FCFCFC"
            />
            <path
                d="M147.535 53.1479H192.096V116.04C192.096 116.647 191.858 117.23 191.435 117.659C191.012 118.089 190.438 118.33 189.839 118.33H149.763C149.165 118.33 148.591 118.089 148.168 117.659C147.745 117.23 147.507 116.647 147.507 116.04V53.1479H147.535Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.8"
                d="M147.535 53.1479H192.096V116.04C192.096 116.647 191.858 117.23 191.435 117.659C191.012 118.089 190.438 118.33 189.839 118.33H149.763C149.165 118.33 148.591 118.089 148.168 117.659C147.745 117.23 147.507 116.647 147.507 116.04V53.1479H147.535Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.9"
                d="M186.911 56.5483H152.701C152.091 56.5483 151.596 57.0506 151.596 57.6703V69.5996C151.596 70.2192 152.091 70.7216 152.701 70.7216H186.911C187.522 70.7216 188.017 70.2192 188.017 69.5996V57.6703C188.017 57.0506 187.522 56.5483 186.911 56.5483Z"
                fill="#FCFCFC"
            />
            <path
                opacity="0.7"
                d="M186.009 73.5264H153.604C152.495 73.5264 151.596 74.4387 151.596 75.5642V82.3474C151.596 83.4729 152.495 84.3853 153.604 84.3853H186.009C187.118 84.3853 188.017 83.4729 188.017 82.3474V75.5642C188.017 74.4387 187.118 73.5264 186.009 73.5264Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M186.009 87.98H153.604C152.495 87.98 151.596 88.8923 151.596 90.0178V96.801C151.596 97.9265 152.495 98.8389 153.604 98.8389H186.009C187.118 98.8389 188.017 97.9265 188.017 96.801V90.0178C188.017 88.8923 187.118 87.98 186.009 87.98Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.7"
                d="M186.009 102.434H153.604C152.495 102.434 151.596 103.346 151.596 104.471V111.255C151.596 112.38 152.495 113.292 153.604 113.292H186.009C187.118 113.292 188.017 112.38 188.017 111.255V104.471C188.017 103.346 187.118 102.434 186.009 102.434Z"
                fill="#8C53C5"
            />
            <path
                d="M150.462 46.7481H189.145C189.923 46.7481 190.669 47.0617 191.219 47.6199C191.769 48.1781 192.078 48.9352 192.078 49.7247V53.1592H147.518V49.7247C147.518 49.3328 147.594 48.9448 147.742 48.5829C147.89 48.221 148.107 47.8924 148.381 47.6158C148.654 47.3393 148.979 47.1203 149.336 46.9714C149.693 46.8224 150.076 46.7466 150.462 46.7481Z"
                fill="#663398"
            />
            <path
                d="M135.733 84.728H144.679C145.047 84.728 145.399 84.5796 145.66 84.3155C145.92 84.0514 146.066 83.6933 146.066 83.3198C146.065 82.9468 145.918 82.5895 145.658 82.3257C145.398 82.062 145.046 81.9131 144.679 81.9116H135.733C135.365 81.9131 135.014 82.0622 134.755 82.3261C134.496 82.59 134.351 82.9473 134.351 83.3198C134.351 83.6923 134.496 84.0496 134.755 84.3135C135.014 84.5774 135.365 84.7264 135.733 84.728Z"
                fill="white"
            />
            <path
                d="M132.053 192.487H127.924L127.929 182.773H132.064L132.053 192.487Z"
                fill="#EE8181"
            />
            <path
                d="M165.253 184.468L161.299 185.681L156.944 176.872L160.898 175.658L165.253 184.468Z"
                fill="#EE8181"
            />
            <path
                d="M160.899 185.298L165.344 183.936C165.42 183.912 165.503 183.916 165.577 183.947C165.651 183.979 165.712 184.035 165.75 184.108L167.442 187.485C167.481 187.57 167.503 187.662 167.504 187.756C167.505 187.85 167.486 187.943 167.449 188.029C167.411 188.115 167.356 188.192 167.287 188.254C167.217 188.316 167.136 188.362 167.047 188.389C165.49 188.836 164.712 188.962 162.754 189.574C161.553 189.941 157.678 191.921 156.042 192.436C154.406 192.952 153.741 191.326 154.384 190.96C157.255 189.34 159.573 186.953 160.323 185.75C160.456 185.535 160.66 185.375 160.899 185.298Z"
                fill="#565064"
            />
            <path
                d="M127.969 192.001H132.482C132.562 192 132.641 192.028 132.703 192.081C132.765 192.133 132.807 192.206 132.82 192.287L133.474 196.019C133.49 196.111 133.486 196.206 133.461 196.296C133.437 196.386 133.394 196.47 133.334 196.541C133.274 196.612 133.199 196.669 133.116 196.707C133.032 196.746 132.94 196.765 132.848 196.764C131.224 196.735 128.9 196.638 126.83 196.638C124.438 196.638 122.362 196.769 119.559 196.769C117.867 196.769 117.466 195.052 118.183 194.875C121.449 194.153 124.088 194.079 126.96 192.327C127.261 192.128 127.61 192.016 127.969 192.001Z"
                fill="#565064"
            />
            <path
                d="M142.669 39.4731C141.941 42.5413 141.484 46.3422 143.509 48.3915C143.509 48.3915 142.161 51.5055 136.543 51.5055C131.41 51.5055 134.112 48.3915 134.112 48.3915C137.496 47.5787 137.525 43.3427 137.045 40.95L142.669 39.4731Z"
                fill="#EE8181"
            />
            <path
                opacity="0.2"
                d="M127.929 182.779L127.924 187.782H132.058L132.064 182.779H127.929Z"
                fill="#04010D"
            />
            <path
                d="M151.772 52.2266L146.47 55.3177C146.47 55.3177 152.031 68.4834 151.484 70.6128C150.734 73.475 150.356 79.3194 149.2 83.1146C149.657 83.6355 151.202 84.5685 151.823 84.5456C154.079 79.9662 157.757 72.1527 157.542 69.5023C157.057 63.4461 151.772 52.2266 151.772 52.2266Z"
                fill="#EE8181"
            />
            <path
                d="M149.2 83.1147L145.567 84.6374L149.211 87.7113C149.211 87.7113 152.347 86.2745 151.772 83.5727L149.2 83.1147Z"
                fill="#EE8181"
            />
            <path
                d="M144.204 84.2762L147.639 86.8921L149.879 84.0529L146.235 80.979L144.204 84.2762Z"
                fill="#EE8181"
            />
            <path
                d="M150.87 49.3415C154.717 50.1371 157.842 64.0814 157.842 64.0814L149.178 68.3116C149.178 68.3116 146.211 61.5913 144.902 56.657C143.537 51.4823 146.741 48.4885 150.87 49.3415Z"
                fill="#663398"
            />
            <path
                opacity="0.2"
                d="M147.778 54.1385C146.165 52.4212 146.899 59.256 147.305 63.801C147.649 64.7054 147.982 65.5183 148.269 66.2109C150.599 62.7878 150.035 56.5427 147.778 54.1385Z"
                fill="#04010D"
            />
            <path
                d="M134.242 49.6623C134.242 48.174 134.028 47.4699 134.428 46.9204C134.828 46.3708 142.77 45.7183 144.141 46.3479C144.609 46.5597 144.88 48.7006 144.293 49.6566C143.436 51.0418 134.242 49.6623 134.242 49.6623Z"
                fill="#8C53C5"
            />
            <path
                opacity="0.2"
                d="M134.242 49.6623C134.242 48.174 134.028 47.4699 134.428 46.9204C134.828 46.3708 142.77 45.7183 144.141 46.3479C144.609 46.5597 144.88 48.7006 144.293 49.6566C143.436 51.0418 134.242 49.6623 134.242 49.6623Z"
                fill="#04010D"
            />
            <path
                d="M127.704 49.5591C127.704 49.5591 122.278 62.2211 125.888 85.805H147.993C148.292 82.3705 146.865 65.5641 150.87 49.3416C148.439 48.862 145.98 48.5446 143.509 48.3914C140.379 48.2081 137.241 48.2081 134.112 48.3914C131.949 48.6127 129.807 49.0031 127.704 49.5591Z"
                fill="#663398"
            />
            <path
                d="M130.857 55.4442L125.38 53.2061C125.38 53.2061 117.98 65.9597 116.434 67.0301C112.853 65.0381 111.651 65.8452 108.865 63.7558C108.16 63.8302 107.06 64.8263 106.688 65.3357C108.944 69.7434 116.44 75.1986 120.146 71.8728C124.732 67.7571 130.857 55.4442 130.857 55.4442Z"
                fill="#EE8181"
            />
            <path
                opacity="0.2"
                d="M131.545 53.9326C130.721 51.7803 128.995 50.8816 127.128 51.1964C126.305 53.8353 124.872 59.634 124.669 68.3692L126.812 68.8614C126.812 68.8614 132.961 57.6133 131.545 53.9326Z"
                fill="#04010D"
            />
            <path
                d="M132.486 52.4271C133.716 56.1822 126.18 67.23 126.18 67.23L118.114 62.6506C119.509 59.2047 121.252 55.9148 123.315 52.8335C126.434 48.4316 131.138 48.3113 132.486 52.4271Z"
                fill="#663398"
            />
            <path
                d="M109 63.8758L105.317 61.7178L103.162 66.0682C104.651 66.5657 106.209 66.8151 107.776 66.8066L109 63.8758Z"
                fill="#EE8181"
            />
            <path
                d="M103.5 61.7178L100.866 64.2593L103.162 66.0682L105.317 61.7178H103.5Z"
                fill="#EE8181"
            />
            <path
                d="M133.508 35.0597C133.475 35.3974 133.272 35.6321 133.057 35.6321C132.843 35.6321 132.691 35.3345 132.724 34.9967C132.758 34.659 132.961 34.4243 133.181 34.4243C133.401 34.4243 133.542 34.722 133.508 35.0597Z"
                fill="#565064"
            />
            <path
                d="M133.801 35.0879C133.302 36.0994 132.673 37.0389 131.929 37.8813C132.143 38.0608 132.394 38.1895 132.663 38.2578C132.932 38.3262 133.213 38.3325 133.486 38.2763L133.801 35.0879Z"
                fill="#EE8181"
            />
            <path
                d="M133.113 33.8916L133.028 33.8572C133.008 33.8424 132.99 33.8235 132.976 33.8015C132.962 33.7796 132.953 33.7551 132.949 33.7295C132.945 33.7039 132.946 33.6777 132.951 33.6524C132.957 33.6272 132.968 33.6034 132.983 33.5825C133.16 33.3124 133.397 33.0877 133.674 32.9258C133.951 32.7639 134.261 32.6693 134.58 32.6494C134.605 32.6502 134.63 32.6559 134.653 32.6664C134.676 32.6769 134.696 32.6918 134.714 32.7104C134.731 32.729 134.744 32.7509 134.753 32.7748C134.762 32.7987 134.767 32.8242 134.766 32.8498C134.763 32.903 134.741 32.9531 134.703 32.9902C134.665 33.0274 134.615 33.0487 134.563 33.0501C134.313 33.071 134.072 33.1501 133.858 33.2812C133.644 33.4122 133.462 33.5917 133.327 33.8057C133.304 33.8398 133.271 33.8661 133.233 33.8814C133.195 33.8967 133.153 33.9002 133.113 33.8916Z"
                fill="#565064"
            />
            <path
                d="M144.738 34.7675C144.067 38.9004 143.887 41.3447 141.613 43.3539C140.892 44.017 140.004 44.4653 139.047 44.6496C138.091 44.8339 137.103 44.7471 136.191 44.3986C135.28 44.0502 134.482 43.4537 133.883 42.6746C133.284 41.8955 132.908 40.9639 132.797 39.9823C132.295 36.2043 133.468 30.0851 137.626 28.6655C138.514 28.3349 139.473 28.2489 140.404 28.4162C141.336 28.5835 142.207 28.9982 142.93 29.6181C143.653 30.238 144.2 31.041 144.518 31.9454C144.836 32.8499 144.912 33.8236 144.738 34.7675Z"
                fill="#EE8181"
            />
            <path
                d="M136.249 36.5479C135.403 33.8289 134.303 29.6273 137.31 28.0703C140.316 26.5133 142.685 28.6771 145.963 30.6806C149.657 32.9359 147.401 40.0912 142.945 41.7742C139.205 43.188 135.556 40.3488 136.249 36.5479Z"
                fill="#565064"
            />
            <path
                d="M136.577 31.5051C133.926 32.232 132.098 30.5033 133.407 28.5113C135.065 25.9754 141.586 26.6395 144.028 29.3814C146.471 32.1233 138.416 33.4341 136.577 31.5051Z"
                fill="#565064"
            />
            <path
                d="M137.586 29.5014C134.348 28.8947 133.023 26.2157 135.736 24.5557C138.297 22.993 145.494 25.7005 146.696 30.0166C147.897 34.3327 138.568 32.5639 137.586 29.5014Z"
                fill="#565064"
            />
            <path
                d="M146.217 31.5565C146.708 31.1559 148.659 30.5834 148.879 32.7987C148.167 32.0739 147.222 31.633 146.217 31.5565Z"
                fill="#565064"
            />
            <path
                d="M147.615 28.7974C148.004 29.3011 148.557 31.2817 146.368 31.4935C147.087 30.7745 147.53 29.8182 147.615 28.7974Z"
                fill="#565064"
            />
            <path
                d="M138.58 36.3589C138.285 37.2519 137.665 37.9971 136.848 38.4425C135.765 39.015 135.015 38.1105 135.156 36.9428C135.274 35.8953 135.979 34.3268 137.147 34.2352C138.314 34.1436 138.935 35.2656 138.58 36.3589Z"
                fill="#EE8181"
            />
            <path
                d="M133.729 85.8052C133.729 85.8052 137.028 117.861 139.081 129.882C141.338 143.048 157.131 181.057 157.131 181.057L164.379 178.836C164.379 178.836 153.098 143.889 152.032 130.872C150.904 116.739 148.016 85.8223 148.016 85.8223L133.729 85.8052Z"
                fill="#565064"
            />
            <path
                opacity="0.2"
                d="M138.811 97.0131C138.484 96.5094 138.173 96.3377 137.874 96.4407H137.818C137.749 96.4689 137.684 96.5075 137.626 96.5552C137.566 96.606 137.509 96.6615 137.457 96.7212C137.423 96.7555 137.395 96.7956 137.361 96.8357C137.327 96.8758 137.276 96.9559 137.231 97.0246C137.129 97.1924 137.037 97.3663 136.955 97.5455C136.91 97.6543 136.859 97.7687 136.814 97.8832C136.769 97.9977 136.701 98.1752 136.645 98.3354C136.588 98.4957 136.498 98.7705 136.43 99.0052C136.43 99.0681 136.391 99.1254 136.374 99.1884C136.357 99.2513 136.301 99.4517 136.261 99.5833L136.177 99.9268C136.115 100.156 136.058 100.385 136.002 100.625C135.945 100.866 135.889 101.146 135.832 101.415C135.832 101.507 135.793 101.604 135.77 101.696C135.703 102.039 135.635 102.394 135.567 102.749C136.476 110.728 137.564 119.876 138.512 126.258C138.579 126.052 138.647 125.835 138.715 125.6C139.006 124.537 139.241 123.46 139.42 122.372C140.824 114.146 140.932 100.293 138.811 97.0131Z"
                fill="#04010D"
            />
            <path
                d="M125.887 85.8052C125.887 85.8052 123.958 117.163 123.851 129.956C123.738 143.259 126.429 186.815 126.429 186.815H133.429C133.429 186.815 135.544 144.038 136.424 130.969C137.377 116.728 140.418 85.8052 140.418 85.8052H125.887Z"
                fill="#565064"
            />
            <path
                d="M133.988 184.64C134.016 184.64 133.886 186.958 133.886 186.958H125.814L125.589 184.354L133.988 184.64Z"
                fill="#8C53C5"
            />
            <path
                d="M163.804 176.226C163.984 176.586 164.752 178.847 164.752 178.847L156.804 181.292L155.501 179.116L163.804 176.226Z"
                fill="#8C53C5"
            />
        </svg>
    );
};
