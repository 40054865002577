import styled from "styled-components";

const makeGrid = (steps: number, separatorSize?: string) => {
    const defaultGrid = `24px ${separatorSize || "120px"} 24px`;
    const newStep = `${separatorSize || "120px"} 24px `;
    if (steps === 1) return "auto";
    if (steps > 2) return `${defaultGrid} ${newStep.repeat(steps - 2)}`;
    return defaultGrid;
};

type TIsActive = {
    isActive?: boolean;
};

type TContainer = {
    steps: number;
    separatorSize?: string;
};
export const Container = styled.div<TContainer>`
    cursor: default;
    display: flex;
    flex-direction: column;
    align-items: center;
    .container-steps {
        display: grid;
        grid-template-columns: ${({ steps, separatorSize }) =>
            makeGrid(steps, separatorSize)};
        place-items: center;
        row-gap: 4px;
    }
    .step {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const Separator = styled.span`
    border: 1px dashed ${({ theme }) => theme.purple200};
    width: 100%;
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.purple500};
    margin-bottom: 16px;
`;

export const Steps = styled.div<TIsActive>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.white};
    background: ${({ theme, isActive }) =>
        isActive ? theme.purple500 : theme.pink200};
`;

export const StepName = styled.p<TIsActive>`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme, isActive }) =>
        isActive ? theme.purple500 : theme.gray200};
`;
