import * as Tabs from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../assets";
import { Input, ModalMassProjectCreation, Tooltip } from "../../../components";
import { Switch } from "../../../components/molecules/switch/switch";
import { TutorialsPopover } from "../../../components/molecules/tutorials-popover/tutorials-popover";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { projects } from "../../../services";
import { Demonstrative } from "../demonstrative/demonstrative";
import { TabProjects } from "./components/tab-projects";
import * as s from "./styled-projects";

export type TFilterType =
    | "late"
    | "onTime"
    | "finished"
    | "noInformation"
    | "all"
    | "canceled"
    | "paused"
    | undefined;

export const OrganizationProjects = () => {
    const navigate = useNavigate();
    const { verifyPermission } = useUser();
    const [loading, setLoading] = useState(true);
    const [projectsData, setProjectsData] = useState([]);
    const [searchValue, setSearchValue] = useState<string | undefined>(
        undefined
    );
    const [filterType, setFilterType] = useState<TFilterType>(undefined);
    const [modalCreateMassProjectsOpen, setModalCreateMassProjectsOpen] =
        useState<boolean>(false);
    const [showArchived, setShowArchived] = useState(false);

    const haveViewProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "view",
    });
    const haveManageProjectsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });

    const getProjects = useFetch({
        fn: projects.readProjects,
        start: false,
    });

    const isSearching = Boolean(getProjects.loading && searchValue);

    useEffect(() => {
        if (getProjects.error) {
            toast.error(`${getProjects.error}`);
            setLoading(false);
        }
        if (getProjects.response) {
            setProjectsData(getProjects.response.projects);
            setLoading(false);
        }
    }, [getProjects.response, getProjects.error]);

    useEffect(() => {
        setLoading(true);

        getProjects.onRefresh({
            page: 1,
            length: 10,
            hourProgressFilter: filterType,
            showArchived,
        });
    }, [filterType]);

    return (
        <>
            {modalCreateMassProjectsOpen && (
                <ModalMassProjectCreation
                    setOpen={setModalCreateMassProjectsOpen}
                    onClose={() =>
                        getProjects.onRefresh({
                            page: 1,
                            length: 10,
                            hourProgressFilter: filterType,
                            showArchived,
                        })
                    }
                />
            )}
            <s.Container>
                <Tabs.Root
                    className="profile-root"
                    defaultValue={"projetos"}
                    orientation="vertical"
                >
                    <s.ActionsContainer>
                        <s.FiltersContainer>
                            <Input
                                mask=""
                                type="text"
                                onChange={(e) =>
                                    setSearchValue
                                        ? setSearchValue(e.target.value)
                                        : ""
                                }
                                value={searchValue}
                                icon={
                                    isSearching ? (
                                        <Icons.Loading
                                            color={theme.purple500}
                                        />
                                    ) : (
                                        <Icons.SearchOutline />
                                    )
                                }
                                placeholder="Pesquise por projeto"
                                disabled={false}
                            />
                            <div
                                className="show-archived"
                                onClick={() => {
                                    getProjects.onRefresh({
                                        page: 1,
                                        length: 10,
                                        hourProgressFilter: filterType,
                                        showArchived: !showArchived,
                                    });
                                    setShowArchived(!showArchived);
                                }}
                            >
                                <Switch
                                    checked={showArchived}
                                    onChange={() => null}
                                    scale={0.8}
                                />
                                <p>Mostrar arquivados</p>
                                <Tooltip
                                    side="bottom"
                                    align="start"
                                    content={
                                        <s.TooltipContainer>
                                            Ative para mostrar seus projetos
                                            arquivados. Você pode arquivar
                                            projetos editando eles na aba de
                                            "Descrição"
                                        </s.TooltipContainer>
                                    }
                                >
                                    <div className="info-icon">
                                        <Icons.Info
                                            width="16px"
                                            height="16px"
                                            color={theme.gray600}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </s.FiltersContainer>

                        {haveManageProjectsPermission && (
                            <s.ButtonsContainer>
                                <s.ImportProjectsButton
                                    onClick={() =>
                                        setModalCreateMassProjectsOpen(true)
                                    }
                                >
                                    IMPORTAR PROJETOS
                                </s.ImportProjectsButton>
                                <s.CreateProjectButton
                                    onClick={() => {
                                        navigate("/criar-projetos");
                                    }}
                                >
                                    CRIAR PROJETO
                                </s.CreateProjectButton>
                            </s.ButtonsContainer>
                        )}
                    </s.ActionsContainer>
                    <TutorialsPopover
                        screen="projetos"
                        tutorials={[
                            {
                                url: "LzAV9CkSgVE",
                                label: "Como criar projetos",
                            },
                        ]}
                        className={"tutorials-popover"}
                    />

                    <Tabs.List
                        aria-label="Gerencie Projetos"
                        className="profile-tabs"
                    >
                        <Tabs.Trigger value="projetos">Projetos</Tabs.Trigger>
                        {haveViewProjectsPermission && (
                            <Tabs.Trigger value="demonstrativos">
                                Demonstrativos
                            </Tabs.Trigger>
                        )}
                    </Tabs.List>
                    <Tabs.Content value="projetos">
                        <TabProjects
                            loading={loading}
                            setLoading={setLoading}
                            projectsData={projectsData}
                            searchValue={searchValue}
                            setFilterType={setFilterType}
                            filterType={filterType}
                            getProjects={getProjects}
                            organizationProjects={true}
                            haveProjectsPermissions={haveViewProjectsPermission}
                            showArchived={showArchived}
                        />
                    </Tabs.Content>
                    <Tabs.Content value="demonstrativos">
                        <Demonstrative
                            setLoading={setLoading}
                            searchValue={searchValue}
                            showArchived={showArchived}
                        />
                    </Tabs.Content>
                </Tabs.Root>
            </s.Container>
        </>
    );
};
