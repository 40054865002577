export const cardNumberMask = (value: string) => {
    if (value === undefined) value = "";

    let pattern = value;
    pattern = pattern.replace(/\D/g, "");
    pattern = pattern.replace(/^(\d{4})(\d)/, "$1 $2");
    pattern = pattern.replace(/^(\d{4}) (\d{6})(\d)/, "$1 $2 $3");
    pattern = pattern.replace(/^(\d{4}) (\d{6}) (\d{5})/, "$1 $2 $3");

    if (pattern.length > 17) {
        pattern = pattern.replace(/\s/g, "");
        pattern = pattern.replace(/(\d{4})(\d{4})(\d{4})(\d)/, "$1 $2 $3 $4");
        pattern = pattern.replace(
            /(\d{4})(\d{4})(\d{4})(\d{4})/,
            "$1 $2 $3 $4"
        );
    }

    return pattern;
};
