import { styled } from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 50px 32px;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.gray200};
    margin: 40px 0px;
`;
