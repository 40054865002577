import { memo } from "react";
import ReactPaginate from "react-paginate";

import { Icons } from "../../../assets";
import * as s from "./styled-pagination";

type TPagination = {
    page: number;
    totalPages: number;
    onClick: (page: { selected: number }) => void;
    disabled: boolean;
    id: string;
};

const Pagination = ({
    page,
    totalPages,
    onClick,
    disabled,
    id,
}: TPagination) => {
    const ButtonPrevious = () => (
        <s.ButtonIcon disabled={page === 0} id="previous-page">
            <Icons.Chevron title="Página anterior" />
        </s.ButtonIcon>
    );

    const ButtonNext = () => (
        <s.ButtonIcon id="next-page" disabled={page + 1 === totalPages}>
            <Icons.Chevron title="Página seguinte" />
        </s.ButtonIcon>
    );

    return (
        <s.Pagination className="paginacao" disabled={disabled} id={id}>
            <ReactPaginate
                previousLabel={<ButtonPrevious />}
                nextLabel={<ButtonNext />}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link previous-link"
                nextClassName="page-item"
                nextLinkClassName="page-link next-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link break"
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={onClick}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={page}
            />
        </s.Pagination>
    );
};
export default memo(Pagination);
