import dayjs from "dayjs";

import { CreateProjectAdditionalCostResult } from "../../../../services/projects/types";

export const formatCurrency = (value?: string) => {
    if (!value) return value;
    const numberValue = value.replace(/[^\d]/g, "");
    if (!numberValue) {
        const defaultValue = 0;
        return defaultValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }
    const floatValue = parseFloat(numberValue) / 100;
    return floatValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
};

export const defaultCostToFormattedCurrency = (value?: string) => {
    if (!value) return value;
    const correctedValue = parseFloat(value) * 100;
    return formatCurrency(correctedValue.toString());
};

export const costStringToNumber = (cost: string): number => {
    return parseFloat(cost.replace(/[^\d]/g, "")) / 100;
};

export const resultToCost = (result: CreateProjectAdditionalCostResult) => {
    return {
        id_e: result.id_e,
        cost: Number(result.cost),
        date: result.created_at,
        title: result.title,
        description: result.description,
        recurring: result.recurring,
    };
};

export const statusIsActive = (date: string) => {
    return dayjs(date).isBefore(dayjs());
};
