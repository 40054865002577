import styled from "styled-components";

export const Container = styled.button<{ isEditing?: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
    margin-top: ${({ isEditing }) => (!isEditing ? "0" : "15px")};
`;

export const AddCollaborators = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.white};
    padding: 6px;
    border: 1px solid ${({ theme }) => theme.gray400};
    border-radius: 50%;
`;

export const HaveMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: ${({ theme }) => theme.purple700};
    padding: 6px;
    border-radius: 50%;
    color: ${({ theme }) => theme.white};
`;

export const CollaboratorImage = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
`;

export const StyledTooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
`;
