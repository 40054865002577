import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type CreateNotificationToAllUsersParams = {
    title?: string;
    sub_title?: string;
    image?: string;
    description?: string;
    path_to_go?: string;
    type?: string;
    button_text?: string;
};

export default async (data?: CreateNotificationToAllUsersParams) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/notifications/createToAllUsers`,
        method: "post",
        headers: { auth: true },
        body: data,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
