import { useEffect, useState } from "react";

import { PopoverRadix } from "../../..";
import { Icons, theme } from "../../../../assets";
import * as s from "./styled-popover-notification-filter";

type TPopoverNotificationFilter = {
    filterSelected: (value: string) => void;
    notificationsQuantity?: {
        todos?: number;
        naoLidos?: number;
        lidos?: number;
    };
};

const PopoverNotificationFilter = ({
    filterSelected,
    notificationsQuantity,
}: TPopoverNotificationFilter) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState("todos");

    const filters = [
        {
            value: "todos",
            label: "Todos",
            quantidade: notificationsQuantity?.todos,
            icon: (
                <Icons.Email
                    color={
                        selected === "todos" ? theme.purple500 : theme.gray600
                    }
                />
            ),
        },
        {
            value: "naoLidos",
            label: "Não Lidos",
            quantidade: notificationsQuantity?.naoLidos,
            icon: (
                <Icons.EmailUnread
                    color={
                        selected === "naoLidos"
                            ? theme.purple500
                            : theme.gray600
                    }
                />
            ),
        },
        {
            value: "lidos",
            label: "Lidos",
            quantidade: notificationsQuantity?.lidos,
            icon: (
                <Icons.EmailOpen
                    color={
                        selected === "lidos" ? theme.purple500 : theme.gray600
                    }
                />
            ),
        },
    ];

    const triggerButton = () => (
        <s.Button onClick={() => setIsOpen(true)} className="button-filter">
            {filters?.find((item) => item.value === selected)?.icon}
            <p>
                {filters?.find((item) => item.value === selected)?.label} (
                {filters?.find((item) => item.value === selected)?.quantidade})
            </p>
        </s.Button>
    );

    useEffect(() => {
        filterSelected(selected);
    }, [selected]);

    return (
        <>
            <PopoverRadix
                trigger={triggerButton()}
                open={isOpen}
                closeOnSelect={true}
            >
                <s.FilterContainer>
                    {filters.map((item) => (
                        <s.Button
                            key={item.value}
                            onClick={() => {
                                setSelected(item.value);
                                setIsOpen(false);
                            }}
                            selected={selected === item.value}
                        >
                            {item.icon}
                            <p>
                                {item.label} ({item.quantidade})
                            </p>
                        </s.Button>
                    ))}
                </s.FilterContainer>
            </PopoverRadix>
        </>
    );
};

export default PopoverNotificationFilter;
