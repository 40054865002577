import styled from "styled-components";

type TSelect = {
    error?: boolean;
};

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    width: 100%;
    height: fit-content;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Flex = styled.div<TSelect>`
    .react-select__menu-list {
        max-height: 12.5rem;
    }

    .react-select__placeholder {
        overflow: hidden;
        box-sizing: border-box;
        text-overflow: ellipsis;
    }

    .react-select__control {
        width: 100%;
        border: none;
        border-radius: 0.25rem;
        min-height: 2.5rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        box-shadow: none;
        font-size: 0.875rem;
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
    }

    .react-select__control--is-focused:hover {
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
    }

    .react-select__clear-indicator,
    .react-select__indicator-separator {
        display: none;
    }

    .react-select--is-disabled {
        visibility: none;
        cursor: not-allowed;
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const PositionIcons = styled.div`
    display: flex;
`;
