import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../assets/icons";
import { FotoMoca, FotoSucesso } from "../../assets/images";
import { Input, PrimaryButton, SecondaryButton } from "../../components";
import { useFetch } from "../../hooks";
import {
    TBaseError,
    TForgotPasswordBody,
    TForgotPasswordResponse,
} from "../../models";
import { manageUser } from "../../services";
import * as s from "./styled-password-recover";
import schema from "./validation";

export const PasswordRecover = () => {
    const navigate = useNavigate();

    const [recEmailSuccess, setRecEmailSuccess] = useState<boolean>(false);
    const showImage = () => (recEmailSuccess ? FotoSucesso : FotoMoca);

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<{ email: string }>({ resolver: yupResolver(schema()) });

    const forgotPassword = useFetch<
        TForgotPasswordBody,
        TForgotPasswordResponse,
        TBaseError
    >({
        fn: manageUser.forgotPassword,
        start: false,
        errorMessage: "Não conseguimos enviar seu e-mail!",
    });

    const onSubmit = (data: Record<string, string>) => {
        forgotPassword.onRefresh(data.email);
    };

    useEffect(() => {
        if (forgotPassword.response) {
            setRecEmailSuccess(true);
        }
    }, [forgotPassword.response]);

    return (
        <s.Container>
            <s.Img alt={"foto não carregada"} src={showImage()} />

            <s.SectionColumn>
                {recEmailSuccess && (
                    <s.FlexColumn gap="24px">
                        <h2>Pronto!</h2>

                        <p className="step-description">
                            Enviamos um link para seu e-mail corporativo. Nele
                            você será redirecionado e poderá criar uma nova
                            senha de acesso.
                        </p>
                    </s.FlexColumn>
                )}

                <div
                    style={{
                        display: !recEmailSuccess ? "block" : "none",
                    }}
                >
                    <s.FlexColumn gap={"16px"}>
                        <h2>Recuperação de senha</h2>

                        <p className="step-description">
                            Se você esqueceu sua senha, não precisa se
                            preocupar. Basta digitar seu e-mail corporativo no
                            campo abaixo!
                        </p>
                    </s.FlexColumn>

                    <s.FlexColumn>
                        <form
                            style={{ paddingTop: "3.125rem" }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Input
                                {...register("email", {
                                    onBlur: () => trigger("email"),
                                })}
                                placeholder={"Digite  seu e-mail"}
                                error={errors.email?.message}
                                label={"E-mail"}
                                icon={<Icons.Email />}
                                mask=""
                            />

                            <div id="buttons-wrapper">
                                <SecondaryButton
                                    type="button"
                                    onClick={() => navigate("/login")}
                                >
                                    Voltar
                                </SecondaryButton>

                                <PrimaryButton>Enviar</PrimaryButton>
                            </div>
                        </form>
                    </s.FlexColumn>
                </div>
            </s.SectionColumn>
        </s.Container>
    );
};
