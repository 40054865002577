import { Control, Controller, FieldValues } from "react-hook-form";

import { Icons } from "../../../../assets";
import { Calendar, PopoverRadix } from "../../../../components";
import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import { formatDay } from "../../../../utils";
import * as s from "../styled-components";

type Props = {
    selectedDay: Date;
    setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
    control: Control<FieldValues, unknown>;
    sideOffset?: number;
    disabled?: boolean;
};

export const TrackCalendar = ({
    control,
    selectedDay,
    setSelectedDay,
    sideOffset,
    disabled,
}: Props) => {
    return (
        <div id="container-calendar">
            <Controller
                defaultValue={new Date()}
                name="calendar"
                control={control}
                render={({ field: { onChange } }) => (
                    <PopoverRadix
                        sideOffset={sideOffset}
                        trigger={
                            <Tooltip
                                side="top"
                                positiony={20}
                                offset={17}
                                content={<s.SmallP>Calendário</s.SmallP>}
                            >
                                <s.Flex id="container-icon">
                                    <button type="button" disabled={disabled}>
                                        <Icons.CalendarOutline />
                                    </button>
                                    <s.P id="trigger">
                                        {formatDay(selectedDay)}
                                    </s.P>
                                </s.Flex>
                            </Tooltip>
                        }
                    >
                        {!disabled && (
                            <Calendar
                                selectedDay={selectedDay}
                                onChange={(value) => {
                                    if (!value) setSelectedDay(new Date());
                                    else setSelectedDay(value);
                                    onChange(value);
                                }}
                                disabled={{
                                    after: new Date(),
                                }}
                            />
                        )}
                    </PopoverRadix>
                )}
            />
        </div>
    );
};
