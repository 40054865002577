import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { CreateProjectForecastParams } from "./types/create-project-cost-forecast";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    cost,
    projectId,
    type,
}: CreateProjectForecastParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/cost/forecast`,
        method: "post",
        body: {
            cost,
            type,
        },
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
