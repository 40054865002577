import styled from "styled-components";

import { theme } from "../../../assets";

type TContainer = {
    wasRead?: boolean;
};

export const MediumP = styled.p`
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${theme.gray800};
`;

export const Container = styled.div<TContainer>`
    background: ${theme.white};
    border-radius: 4px;
    margin-bottom: 24px;

    div#card-top-container {
        border: 1px solid ${theme.gray200};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 22px;
        border-radius: 4px 4px 0px 0px;
        background: ${({ theme }) => theme.white};
        color: ${theme.gray800};
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        border-bottom: 1px solid ${theme.gray200};
        letter-spacing: 0.07px;

        .delete-button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    div#card-content {
        border-radius: 0 0 4px 4px;
        border: 1px solid ${theme.gray200};
        border-top: none;
        height: auto;
        padding: 24px 32px 32px 32px;

        div#inner-card-header {
            display: flex;
            align-items: center;

            #avatar {
                position: relative;
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 15px;
                }

                #dot {
                    left: 63px;
                    top: 3px;
                    height: 14px;
                    width: 14px;
                    position: absolute;
                    background-color: ${theme.redError};
                    border-radius: 50%;
                    display: inline-block;
                }
            }

            #inner-card-name {
                font-size: 14px;
                font-weight: 500;
                color: ${theme.gray800};
            }
            #inner-card-time {
                font-size: 0.75rem;
                color: ${theme.gray400};
            }
        }

        div#inner-card-content {
            margin: 12px 0 0px 0;
            color: ${theme.gray600};
            font-size: 13px;
            font-weight: 400;
        }

        div#inner-card-footer {
        }
    }
`;

export const ReportContainer = styled.div`
    width: 100%;
    div#card-header {
        font-weight: bold;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 13px 16px;
        display: flex;
        border-radius: 4px 4px 0 0;
        border: 1px solid ${theme.gray200};
        background-color: ${({ theme }) => theme.pink50};
    }
    div#inner-card {
        display: flex;
        padding-bottom: 24px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        border-radius: 0px 0px 4px 4px;
        border-top: none;
        background: var(--color-neutral-white-01, #fff);

        button {
            width: 90%;
        }

        div#content-card-report {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 24px 17.5px 0px 17.5px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 20px;
            }
        }

        div#inner-card-content {
            color: ${({ theme }) => theme.gray800};
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            div#inner-card-time {
                display: flex;
                flex-direction: row;
                color: ${({ theme }) => theme.gray600};
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                margin-top: 12px;
            }
        }
    }
`;

export const BorderContainer = styled.div`
    border: 1px solid ${theme.gray200};
    border-radius: 4px;
`;

export const Header = styled.header<{ maintenance?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 22px;
    border-radius: 4px 4px 0px 0px;
    background: ${({ maintenance, theme }) =>
        maintenance ? "#fdf0e6" : theme.white};
    color: ${theme.gray800};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    border-bottom: 1px solid ${theme.gray200};
    letter-spacing: 0.07px;

    .delete-button {
        cursor: pointer;
    }
`;

export const Content = styled.div`
    padding: 24px;
    display: flex;
    align-items: center;
    position: relative;
`;

export const FeatureContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 14px;
    gap: 12px;

    .sub_title {
        color: ${theme.gray800};
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
    }

    .description {
        color: ${theme.gray600};
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        white-space: pre-wrap;
    }

    .image {
        margin-top: 16px;
        img {
            width: 340px;
        }
    }
`;

export const AdditionalHoursContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 22px;
    gap: 12px;

    .description {
        color: ${theme.gray900};
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .explanation {
        color: ${theme.gray600};
        font-size: 13px;
        font-weight: 400;
    }

    .buttons {
        display: flex;
        gap: 12px;
        margin-top: 16px;
    }

    .avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
`;

export const Avatar = styled.img`
    width: 50px;
    border-radius: 50px;
    margin-right: 20px;
`;

export const Dot = styled.span`
    height: 14px;
    width: 14px;
    position: absolute;
    background-color: ${theme.redError};
    border-radius: 50%;
    margin-bottom: 40px;
    margin-left: 35px;
`;

export const Description = styled.p`
    color: ${theme.gray800};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
`;

export const SenderInformation = styled.div`
    color: ${theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 12px;
`;

export const PermissionMessage = styled.div`
    padding: 12px;
    border-radius: 4px;
    background: #e5f6fd;
    display: grid;
    grid-template-columns: 7% 93%;
    align-items: center;
    gap: 12px;

    p {
        color: ${theme.gray800};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }
`;
