import { yupResolver } from "@hookform/resolvers/yup";
import cardValidator from "card-validator";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons } from "../../../../assets";
import { Breadcrumb, Input, PrimaryButton } from "../../../../components";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { subscriptions } from "../../../../services";
import { encryptObjectToBase64 } from "../../../../utils/encrypt/encrypt-to-base-64";
import * as s from "./styled-change-credit-card";
import validation from "./validation";

type TFields = {
    number: string;
    name: string;
    expirationMonth: string;
    expirationYear: string;
    cvv: string;
    contractorName: string;
};

export const ChangeCreditCard = () => {
    const location = useLocation();
    const { user } = useUser();
    const navigate = useNavigate();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm<TFields>({
        resolver: yupResolver(validation),
    });

    const [isErrorDivOpen, setIsErrorDivOpen] = useState(false);

    const changeCreditCard = useFetch({
        fn: subscriptions.changeCard,
        start: false,
        noError: true,
    });

    const validateFields = (data: TFields): boolean => {
        const numberValidation = cardValidator.number(data.number);
        const expirationMonthValidation = cardValidator.expirationMonth(
            data.expirationMonth
        );
        const expirationYearValidation = cardValidator.expirationYear(
            data.expirationYear
        );
        const cvvValidation = cardValidator.cvv(data.cvv);

        if (!numberValidation.isValid) {
            setError("number", {
                type: "manual",
                message: "Número do cartão inválido",
            });
        }

        if (!expirationMonthValidation.isValid) {
            setError("expirationMonth", {
                type: "manual",
                message: "Mês de validade inválido",
            });
        }

        if (!expirationYearValidation.isValid) {
            setError("expirationYear", {
                type: "manual",
                message: "Ano de validade inválido",
            });
        }

        if (!cvvValidation.isValid) {
            setError("cvv", {
                type: "manual",
                message: "CVV inválido",
            });
        }

        return (
            numberValidation.isValid &&
            expirationMonthValidation.isValid &&
            expirationYearValidation.isValid &&
            cvvValidation.isValid
        );
    };

    const transformToEncrypt = (data: TFields) => {
        return {
            number: data.number.replace(/\s/g, ""),
            holder_name: data.name,
            exp_month: data.expirationMonth,
            exp_year: data.expirationYear,
            cvc: data.cvv,
        };
    };

    const onSubmit = async (data: TFields) => {
        const isValid = validateFields(data);
        if (!isValid) return;
        const key = (user.Organization?.id_e ?? "").slice(0, 32);
        const card = transformToEncrypt(data);

        changeCreditCard.onRefresh({
            newCreditCard: await encryptObjectToBase64(card, key),
            contractorName: data.contractorName,
        });
    };

    useEffect(() => {
        if (changeCreditCard.response) {
            toast.success("Forma de pagamento alterada com sucesso!");
            navigate("/gerenciar-assinatura");
        }
    }, [changeCreditCard.response]);

    useEffect(() => {
        if (changeCreditCard.error) {
            setIsErrorDivOpen(true);
        }
    }, [changeCreditCard.error]);

    return (
        <s.Page>
            <Breadcrumb
                location={location}
                defaultRoute="/gerenciar-assinatura"
            />
            <s.Container onSubmit={handleSubmit(onSubmit)}>
                {isErrorDivOpen && (
                    <div id="errors">
                        <Icons.IconAlertCircle width="24px" height="24px" />
                        <div>
                            <h1>Erro na transação</h1>
                            <span>
                                Algo deu errado com o pagamento, por favor,
                                verifique o status do seu cartão
                            </span>
                        </div>
                    </div>
                )}
                <s.Card>
                    <s.Title>Editar forma de pagamento</s.Title>
                    <s.SubTitle>
                        Para iniciar sua assinatura do Plano Starter, insira
                        corretamente os dados do seu cartão de crédito.
                    </s.SubTitle>
                    <s.Brands>
                        <Icons.VisaCard />
                        <Icons.MasterCard />
                        <Icons.EloCard />
                        <Icons.HiperCard />
                        <Icons.AmexCard />
                    </s.Brands>
                    <s.Fields>
                        <Controller
                            name="number"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    label="Número do cartão"
                                    placeholder="Número do cartão"
                                    onChange={onChange}
                                    value={value}
                                    mask="9999 9999 9999 9999"
                                    error={errors.number?.message}
                                />
                            )}
                        />
                        <s.Row>
                            <Controller
                                name="expirationMonth"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        label="Mês de validade"
                                        placeholder="00"
                                        onChange={onChange}
                                        value={value}
                                        mask="99"
                                        error={errors.expirationMonth?.message}
                                    />
                                )}
                            />
                            <Controller
                                name="expirationYear"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        label="Ano de validade"
                                        placeholder="00"
                                        onChange={onChange}
                                        value={value}
                                        mask="99"
                                        error={errors.expirationYear?.message}
                                    />
                                )}
                            />
                            <Controller
                                name="cvv"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        label="CVV"
                                        placeholder="CVV"
                                        onChange={onChange}
                                        value={value}
                                        mask="999"
                                        error={errors.cvv?.message}
                                    />
                                )}
                            />
                        </s.Row>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    label="Nome do titular"
                                    placeholder="Nome impresso no cartão"
                                    onChange={onChange}
                                    value={value}
                                    mask=""
                                    error={errors.name?.message}
                                />
                            )}
                        />
                    </s.Fields>
                    <s.Divider />
                    <s.Title style={{ marginBottom: "24px" }}>
                        Dados de cobrança
                    </s.Title>
                    <Controller
                        name="contractorName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Nome do contratante"
                                placeholder="Nome do contratante (pessoa física ou pessoa jurídica)"
                                onChange={onChange}
                                value={value}
                                mask=""
                                error={errors.contractorName?.message}
                            />
                        )}
                    />
                    <PrimaryButton style={{ marginTop: "24px" }}>
                        CONCLUIR
                    </PrimaryButton>
                    <s.Terms>
                        Ao clicar no botão “Concluir”, você concorda com os{" "}
                        <span>Termos do Assinante</span> e com a nossa{" "}
                        <span>Política de Privacidade</span>. Sua assinatura
                        renovará automaticamente através do seu método de
                        pagamento até que você decida efetuar um cancelamento. É
                        possível realizar o cancelamento a qualquer momento para
                        evitar cobranças futuras. Para cancelar, entre em
                        contato conosco pelo e-mail do nosso suporte
                        suporte@paperon.app ou contato@paperon.app.
                    </s.Terms>
                </s.Card>
            </s.Container>
        </s.Page>
    );
};
