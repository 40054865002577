import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

const templateColumns = "1.3fr 0.8fr 0.7fr 0.4fr 0.4fr 0.8fr 0.5fr";

export const Content = styled.div<{ organizationProjects?: boolean }>`
    display: flex;
    flex-direction: column;
    min-height: 21rem;
    padding-bottom: 2rem;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .projects-title {
        display: grid;
        grid-template-columns: ${templateColumns};
        align-items: center;
        height: 3rem;
        padding: 0 1.5rem;
        background-color: ${({ theme }) => theme.white};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};

        ${({ organizationProjects, theme }) =>
            !organizationProjects &&
            `border-top: 1px solid ${theme.gray200} !important;`}

        color: ${({ theme }) => theme.gray800};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.125rem;

        .filter-time {
            display: flex;
            align-items: center;
            gap: 0.3125rem;
        }
    }

    .projects-content {
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 0px 0px 4px 4px;
        margin-bottom: 1.5rem;

        .content-row {
            display: grid;
            grid-template-columns: ${templateColumns};
            align-items: center;
            padding: 0 1.5rem;
            height: 3.5rem;
            background-color: ${({ theme }) => theme.white};
            border-bottom: 1px solid ${({ theme }) => theme.gray200};
            cursor: pointer;

            &:hover {
                background-color: ${({ theme }) => theme.gray50};
                -webkit-transition: background-color 200ms linear;
                -ms-transition: background-color 200ms linear;
                transition: background-color 200ms linear;
            }

            .projects-icons {
                display: flex;
                justify-content: space-around;
                align-items: center;

                &__options {
                    display: flex;
                    align-items: center;
                }

                .edit {
                    cursor: pointer;
                }

                .head-list {
                    max-width: 15rem;

                    li {
                        font-size: 0.75rem;
                        font-weight: 400;

                        color: ${({ theme }) => theme.gray800};

                        span {
                            font-weight: 600;
                            margin-right: 0.3125rem;
                            color: ${({ theme }) => theme.gray600};
                        }
                    }
                }
            }

            &:last-child {
                border-bottom: 0;
                border-radius: 0px 0px 4px 4px;
            }

            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.gray900};
        }
    }
`;

export const TagContainer = styled.div<{ archived?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 200px;
    max-width: 400px;
    margin-right: 50px;

    div {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 4px;
    }

    span {
        cursor: default;
        min-width: 200px;
        width: 100%;
        white-space: nowrap;
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
    }

    &:hover span {
        position: absolute;
        white-space: normal;
        overflow: visible;
        text-overflow: clip;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.2s ease;
        margin-left: ${({ archived }) => (archived ? "42px" : "0px")};
    }
`;

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 25rem;
    padding: 1.5rem;
    gap: 1.5rem;
    align-items: center;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    gap: 0.5rem;
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: "center";
    gap: 0.5rem;

    .Label {
        color: ${({ theme }) => theme.purple500};
        font-size: 0.75rem;
        font-weight: 400;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;

export const Options = styled.div`
    display: flex;
    min-width: 30px;
    justify-content: center;
    align-items: center;
`;

export const PopoverContent = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 4rem;
    gap: 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
        -webkit-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
    }

    color: var(--color-gray-600, #6e697a);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const TooltipContent = styled.div`
    padding: 12px 16px;
    max-width: 208px;

    h3 {
        color: ${({ theme }) => theme.purple600};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
        margin-bottom: 11px;

        display: flex;
        align-items: center;
        gap: 8px;
    }

    p {
        color: ${({ theme }) => theme.gray600};
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`;
