import { useEffect } from "react";

import { Icons, IlustracaoTelaVazia } from "../../../../assets";
import { Tooltip, LoadingGray } from "../../../../components";
import { useUser } from "../../../../context";
import { TProject } from "../../../../models";
import HoursTypeFilter from "./hours-type-filter/hours-type-filter";
import ProjectRow from "./project-row/project-row";
import * as s from "./styled-projects";

type TabResumeProps = {
    loading: boolean;
    projects: TProject[];
    searchValue?: string;
    setFilterType?: React.Dispatch<
        React.SetStateAction<
            "late" | "onTime" | "finished" | "noInformation" | "all" | undefined
        >
    >;
    filterType?: string;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SIZES = {
    hours: 10,
    cost: 9,
    scope: 11,
    actions: 0.2,
};
export const DemonstrativeTable = ({
    loading,
    projects,
    setFilterType,
    filterType,
    setLoading,
}: TabResumeProps) => {
    const {
        user: { Permissions: userPerms },
    } = useUser();
    const canViewCost = userPerms.includes("view_cost");

    useEffect(() => {
        if (projects.length > 0) {
            setLoading(false);
        }
    }, [projects]);

    const renderProjects = () => {
        if (loading) return <LoadingGray />;
        if (!loading && projects.length > 0) {
            return projects.map((project, index) => (
                <ProjectRow
                    key={project.id_e}
                    project={project as any}
                    index={index}
                    canViewCost={canViewCost}
                />
            ));
        }
        return (
            <s.NoContent>
                <IlustracaoTelaVazia />
                <h2>Ainda não há registro de projeto criado</h2>
                <p>
                    Clique em <strong>CRIAR PROJETO</strong> e comece
                    <br /> agora a organizar melhor seus projetos
                </p>
            </s.NoContent>
        );
    };

    return (
        <s.Content>
            <s.TableHeader>
                <label style={{ minWidth: "14rem" }}>Projeto</label>
                <Tooltip
                    side="top"
                    align={"start"}
                    positiony={10}
                    content={
                        <s.HeaderTooltip style={{ marginBottom: "10px" }}>
                            <header>
                                Projeção de duração
                                <Icons.WarningInfo />
                            </header>
                            <p>
                                A projeção de dias é calculada com base no
                                histórico das horas realizadas em relação ao
                                tempo transcorrido do projeto, gerando uma
                                velocidade média de horas realizadas por dia.
                            </p>
                            <p>
                                Não é levado em consideração no cálculo os dias
                                com status de projeto “Pausado”.
                            </p>
                        </s.HeaderTooltip>
                    }
                >
                    <label style={{ width: "8rem", textAlign: "center" }}>
                        Projeção de <br />
                        duração{" "}
                        <label style={{ fontWeight: 400 }}>(dias)</label>
                    </label>
                </Tooltip>
                <Tooltip
                    side="top"
                    align={"start"}
                    positiony={10}
                    positionx={80}
                    content={
                        <s.HeaderTooltip style={{ marginBottom: "10px" }}>
                            <header>
                                Horas
                                <Icons.WarningInfo />
                            </header>
                            <p>
                                A porcentagem de horas realizadas é baseada na
                                porcentagem do tempo do projeto que transcorreu
                                até o momento.
                            </p>
                            <p>
                                A porcentagem de horas ideal é idêntica à
                                porcentagem de tempo transcorrido no projeto até
                                o momento.
                            </p>
                        </s.HeaderTooltip>
                    }
                >
                    <span style={{ flex: SIZES.hours, textAlign: "center" }}>
                        Horas
                        <HoursTypeFilter
                            setFilterType={setFilterType}
                            defaultValue={filterType}
                        />
                    </span>
                </Tooltip>
                {canViewCost && (
                    <Tooltip
                        side="top"
                        align={"end"}
                        positiony={10}
                        positionx={-50}
                        content={
                            <s.HeaderTooltip style={{ marginBottom: "10px" }}>
                                <header>
                                    Custo mão-de-obra
                                    <Icons.WarningInfo />
                                </header>
                                <p>
                                    A porcentagem do custo realizado é baseado
                                    no custo de cada profissional e as suas
                                    horas trabalhadas no projeto, em relação ao
                                    custo previsto de mão-de-obra.
                                </p>
                                <p>
                                    O custo ideal é idêntico à porcentagem de
                                    horas que já transcorreram no projeto até o
                                    momento.
                                </p>
                            </s.HeaderTooltip>
                        }
                    >
                        <div
                            style={{
                                flex: SIZES.cost,
                                textAlign: "center",
                            }}
                        >
                            Custo mão-de-obra
                        </div>
                    </Tooltip>
                )}
                <Tooltip
                    side="top"
                    align={"end"}
                    positiony={10}
                    positionx={-120}
                    content={
                        <s.HeaderTooltip style={{ marginBottom: "10px" }}>
                            <header>
                                Escopo
                                <Icons.WarningInfo />
                            </header>
                            <p>
                                A porcentagem do escopo realizado é definida
                                manualmente pelo gestor do projeto.
                            </p>
                            <p>
                                O escopo ideal é idêntico à porcentagem de tempo
                                transcorrido no projeto até o momento.
                            </p>
                        </s.HeaderTooltip>
                    }
                >
                    <label style={{ flex: SIZES.scope, textAlign: "center" }}>
                        Escopo
                    </label>
                </Tooltip>
                <div style={{ flex: SIZES.actions, textAlign: "center" }}>
                    {" "}
                </div>
            </s.TableHeader>
            <s.TableContent>{renderProjects()}</s.TableContent>
        </s.Content>
    );
};
