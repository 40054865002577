import styled from "styled-components";

import { Radio } from "../../../../../../../../../styles/radio-group-styles";

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

export const Label = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    min-width: 129px;
`;

export const Value = styled.div<{ color?: string }>`
    color: ${({ theme, color }) => color ?? theme.gray900};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    display: flex;
    align-items: center;

    p {
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
    }
`;

export const PriorityIndicator = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: 24px;
    margin-right: 5px;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
`;

export const EditContainer = styled.div`
    width: 100%;
`;

export const InputContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
`;

export const TextAreaContainer = styled.div`
    width: 100%;
    position: relative;

    .text-area {
        padding-right: 60px;
    }

    button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        left: calc(100% - 40px);
        top: calc(83% - 70px);
        background-color: transparent;
        cursor: pointer;
    }
`;

export const StyledRadioWrapper = styled(Radio.Wrapper)`
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;

export const DayPickerWrapper = styled.div`
    max-width: 200px;
`;
