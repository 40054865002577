import { theme } from "../../../../../assets";
import Tooltip from "../../../../../components/atoms/tooltip/tooltip";
import * as s from "./styled-duration-projection";

type DurationProjectionProps = {
    value?: number;
};

const DurationProjection = (props: DurationProjectionProps) => {
    const { value = 0 } = props;
    const positive = value > 0;

    return (
        <Tooltip
            side="right"
            align={"start"}
            positionx={10}
            content={
                <s.TooltipContainer value={value}>
                    <h5>Projeção de duração</h5>
                    <label>
                        Com base no progresso, estima-se que as horas previstas
                        serão finalizadas{" "}
                        <strong>
                            {value === 0 && "NO DIA "}
                            {value > 0 && `${Math.abs(value)} DIAS ANTES `}
                            {value < 0 && `${Math.abs(value)} DIAS DEPOIS `}
                        </strong>
                        da data de término do projeto.
                    </label>
                </s.TooltipContainer>
            }
        >
            <s.Container>
                {value !== 0 && (
                    <s.Arrow
                        up={positive}
                        color={positive ? theme.greenDone : theme.redError}
                        strokeWidth="3px"
                    />
                )}
                {Math.abs(value)}
            </s.Container>
        </Tooltip>
    );
};

export default DurationProjection;
