import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { ReadProjectsParams } from "./types/read-projects";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    length,
    page,
    hourProgressFilter,
    searchTerm,
    showArchived,
}: ReadProjectsParams = {}) => {
    const headers = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project`,
        method: "get",
        headers,
        params: { length, page, hourProgressFilter, searchTerm, showArchived },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
