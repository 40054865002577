import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-send"}
        >
            <title>{title}</title>
            <mask
                id="mask0_21772_28752"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill={color || theme.white}
                />
            </mask>

            <path
                id="send_2"
                d="M4.7666 18.7228C4.46452 18.8435 4.1779 18.8178 3.90675 18.6458C3.63558 18.4738 3.5 18.2233 3.5 17.8942V13.6731L10.423 12L3.5 10.327V6.10587C3.5 5.7768 3.63558 5.52627 3.90675 5.35427C4.1779 5.18225 4.46452 5.15659 4.7666 5.27729L18.723 11.1616C19.0948 11.3281 19.2806 11.6082 19.2806 12.0019C19.2806 12.3955 19.0948 12.6744 18.723 12.8385L4.7666 18.7228Z"
                fill="white"
            />
        </svg>
    );
};
