import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        strokeWidth={0.2}
    >
        <title>{title}</title>
        <g id="archive">
            <mask
                id="mask0_22644_16472"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill={theme.white3}
                />
            </mask>
            <g mask="url(#mask0_22644_16472)">
                <path
                    id="archive_2"
                    d="M12 10.5769C11.8577 10.5769 11.7388 10.6247 11.6433 10.7202C11.5478 10.8157 11.5 10.9346 11.5 11.0769V15.2769L9.75385 13.5308C9.66027 13.4372 9.54553 13.3872 9.40963 13.3808C9.27373 13.3744 9.15257 13.4244 9.04615 13.5308C8.93973 13.6372 8.88653 13.7551 8.88653 13.8846C8.88653 14.0141 8.93973 14.1321 9.04615 14.2385L11.4346 16.6269C11.5962 16.7885 11.7846 16.8692 12 16.8692C12.2154 16.8692 12.4038 16.7885 12.5654 16.6269L14.9538 14.2385C15.0474 14.1449 15.0974 14.0301 15.1039 13.8942C15.1103 13.7583 15.0603 13.6372 14.9538 13.5308C14.8474 13.4244 14.7295 13.3712 14.6 13.3712C14.4705 13.3712 14.3526 13.4244 14.2462 13.5308L12.5 15.2769V11.0769C12.5 10.9346 12.4522 10.8157 12.3567 10.7202C12.2612 10.6247 12.1423 10.5769 12 10.5769ZM5 7.8077V18.3846C5 18.5641 5.05769 18.7115 5.17308 18.8269C5.28846 18.9423 5.43589 19 5.61538 19H18.3846C18.5641 19 18.7115 18.9423 18.8269 18.8269C18.9423 18.7115 19 18.5641 19 18.3846V7.8077H5ZM5.76923 20C5.32179 20 4.91506 19.817 4.54902 19.451C4.18301 19.0849 4 18.6782 4 18.2308V7.48653C4 7.29166 4.03109 7.10833 4.09328 6.93653C4.15544 6.76474 4.24871 6.60641 4.37308 6.46152L5.93075 4.59037C6.07563 4.39551 6.25673 4.24839 6.47403 4.14902C6.69134 4.04967 6.92436 4 7.17308 4H16.7885C17.0372 4 17.2734 4.04967 17.4971 4.14902C17.7208 4.24839 17.9051 4.39551 18.05 4.59037L19.6269 6.5C19.7513 6.64487 19.8446 6.8064 19.9067 6.9846C19.9689 7.16282 20 7.34936 20 7.54422V18.2308C20 18.6782 19.817 19.0849 19.451 19.451C19.0849 19.817 18.6782 20 18.2308 20H5.76923ZM5.38078 6.80768H18.6L17.2692 5.21152C17.2051 5.14742 17.1314 5.09615 17.0481 5.0577C16.9647 5.01923 16.8782 5 16.7885 5H7.1923C7.10255 5 7.01602 5.01923 6.9327 5.0577C6.84937 5.09615 6.77564 5.14742 6.71152 5.21152L5.38078 6.80768Z"
                    fill={color || theme.gray600}
                />
            </g>
        </g>
    </svg>
);
