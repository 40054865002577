import * as Dialog from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";

const contentShow = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

const contentHide = keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
`;

export const StyledOverlay = styled(Dialog.Overlay)`
    background-color: rgba(0, 0, 0, 0.15);
    position: fixed;
    inset: 0;
    z-index: 1000;
`;

export const StyledContent = styled(Dialog.Content)`
    width: 100%;
    max-width: 688px;
    padding: 44px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 10px 0 0 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: 1001;
    overflow-y: auto;

    &.open {
        animation: ${contentShow} 200ms forwards;
    }

    &.closed {
        animation: ${contentHide} 200ms backwards;
    }
`;

export const Divider = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.gray200};
    margin: 52px 0;
`;
