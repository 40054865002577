import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TDeleteSection = {
    sectionId?: string;
};

export default async ({ sectionId }: TDeleteSection = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/section/delete`,
        method: "delete",
        body: {},
        headers: {
            "x-section-id": sectionId,
            auth: true,
        },
    });

    const { body, statusCode } = httpResponse;

    const errors = {
        "the activity cannot be deleted, as it has time entries!":
            "A sessão não pode ser deletada, pois ela possui entradas de tempo!",
    };
    const error = findErrorString<keyof typeof errors>(body);

    const errorFeedBacks = {
        400: errors[error] || body.message,
    };

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
