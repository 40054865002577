import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 31px;
    width: 100%;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.gray800};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 10px;

    svg {
        margin-top: 3px;
    }
`;

export const TooltipContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 400px;
    padding: 16px;
    border-radius: 8px;

    color: ${({ theme }) => theme.gray600};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.03px;
`;

export const Table = styled.table`
    width: calc(100% + 66px);
    border-collapse: collapse;
    margin-left: -33px;
`;

export const TableHead = styled.thead`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
    text-align: left;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{ notInProject?: boolean }>`
    border: 1px solid ${({ theme }) => theme.gray200};

    td {
        color: ${({ theme, notInProject }) => notInProject && theme.gray300};
    }
`;

export const TableData = styled.td`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    padding: 20px 0px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const TableHeader = styled.th`
    padding: 20px 0px;

    &:first-child {
        padding-left: 20px;
    }
`;
