import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../../../../../assets";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../components/atoms/menu/menu";
import { ModalCostHistory } from "../../../../../../components/molecules/modals/modal-cost-history/modal-cost-history";
import {
    ModalEditCost,
    ModalEditCostFields,
} from "../../../../../../components/molecules/modals/modal-edit-cost/modal-edit-cost";
import { TProject } from "../../../../../../models";
import { projects } from "../../../../../../services";
import { ReadProjectCostsResult } from "../../../../../../services/projects/types";
import { formatToBRMoney } from "../../../../../../utils";
import { Mixpanel } from "../../../../../../utils/mixpanel/Mixpanel";
import { queryClient } from "../../../../../../utils/query-client/query-client";
import { ActiveTabsEnum } from "../../tab-costs";
import { costStringToNumber } from "../../utils";
import * as s from "./styles";

type TotalCardProps = {
    data?: {
        forecastedCost: number;
        realizedCost: number;
        history: ReadProjectCostsResult["resume"]["total"]["history"];
        fixedCost?: {
            realized: number;
            forecast: number;
        };
        variableCost?: {
            realized: number;
            forecast: number;
        };
        teamCost?: {
            realized: number;
            forecast: number;
        };
    };
    activeTab: ActiveTabsEnum;
};

export const TotalCard = ({ activeTab, data }: TotalCardProps) => {
    if (!data) return null;

    const [editCost, setEditCost] = useState(false);
    const [costHistoryOpen, setCostHistoryOpen] = useState(false);

    const { state } = useLocation();
    const { project }: { project: TProject } = state || {};

    const createProjectForecast = useMutation({
        mutationKey: ["createProjectForecast"],
        mutationFn: projects.createProjectCostForecast,
        onSuccess: () => {
            queryClient.invalidateQueries(["readCosts"]);
            setEditCost(false);
            toast.success("Custo atualizado com sucesso!");
        },
    });

    const createProjectForecastTypeMapper = {
        Resumo: "total",
        "Custos fixos": "fixed",
        "Custos variáveis": "variable",
        "Custos time": "team",
    };

    const options: TMenuAtom["options"] = [
        {
            label: "Editar",
            icon: <Icons.EditOutline color={theme.gray800} />,
            action: () => {
                setEditCost(true);
            },
            color: theme.gray800,
        },
        {
            label: "Ver histórico de edição",
            icon: (
                <Icons.History
                    color={theme.gray600}
                    width="19.5px"
                    height="19.5px"
                />
            ),
            action: () => {
                setCostHistoryOpen(true);
            },
            color: theme.gray600,
        },
    ];

    const handleEditCostSubmit = ({ cost }: ModalEditCostFields) => {
        Mixpanel.track("Salvar custos", {
            costType: createProjectForecastTypeMapper[activeTab],
        });
        createProjectForecast.mutate({
            cost: costStringToNumber(cost),
            projectId: project.id_e,
            type: createProjectForecastTypeMapper[activeTab] as
                | "fixed"
                | "variable"
                | "team"
                | "total",
        });
    };

    return (
        <>
            {editCost && (
                <ModalEditCost
                    setOpen={setEditCost}
                    submit={handleEditCostSubmit}
                    defaultCost={data.forecastedCost}
                    loading={createProjectForecast.isLoading}
                />
            )}
            {costHistoryOpen && (
                <ModalCostHistory
                    setOpen={setCostHistoryOpen}
                    history={data.history}
                />
            )}
            <s.Container>
                <s.BigCard>
                    <s.BigCardLeftSide>
                        <h1>Custo total previsto</h1>
                        <div>
                            <h3>{formatToBRMoney(data.forecastedCost)}</h3>
                            <MenuAtom
                                align="start"
                                options={options}
                                width="219px"
                                alignOffset={200}
                                sideOffset={20}
                            >
                                <div className="more">
                                    <Icons.More color={theme.gray600} />
                                </div>
                            </MenuAtom>
                        </div>
                    </s.BigCardLeftSide>
                    <s.BigCardRightSide>
                        <h1>Custo total realizado</h1>
                        <h3>{formatToBRMoney(data.realizedCost)}</h3>
                    </s.BigCardRightSide>
                </s.BigCard>
                {data.fixedCost && data.variableCost && data.teamCost && (
                    <s.SmallCardsContainer>
                        <s.SmallCard>
                            <h1>Custos fixos</h1>
                            <s.SmallCardCost>
                                <h3>Previsto</h3>
                                <span>
                                    {formatToBRMoney(data.fixedCost.forecast)}
                                </span>
                            </s.SmallCardCost>
                            <s.SmallCardCost purple>
                                <h3>Realizado</h3>
                                <span>
                                    {formatToBRMoney(data.fixedCost.realized)}
                                </span>
                            </s.SmallCardCost>
                        </s.SmallCard>
                        <s.SmallCard>
                            <h1>Custos variáveis</h1>
                            <s.SmallCardCost>
                                <h3>Previsto</h3>
                                <span>
                                    {formatToBRMoney(
                                        data.variableCost.forecast
                                    )}
                                </span>
                            </s.SmallCardCost>
                            <s.SmallCardCost purple>
                                <h3>Realizado</h3>
                                <span>
                                    {formatToBRMoney(
                                        data.variableCost.realized
                                    )}
                                </span>
                            </s.SmallCardCost>
                        </s.SmallCard>
                        <s.SmallCard>
                            <h1>Custos time</h1>
                            <s.SmallCardCost>
                                <h3>Previsto</h3>
                                <span>
                                    {formatToBRMoney(data.teamCost.forecast)}
                                </span>
                            </s.SmallCardCost>
                            <s.SmallCardCost purple>
                                <h3>Realizado</h3>
                                <span>
                                    {formatToBRMoney(data.teamCost.realized)}
                                </span>
                            </s.SmallCardCost>
                        </s.SmallCard>
                    </s.SmallCardsContainer>
                )}
            </s.Container>
        </>
    );
};
