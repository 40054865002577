import React from "react";

import ContainerModal from "../../../../../../../../components/molecules/modals/container-modal";
import { ModalTitle } from "../../../../../../../../components/molecules/modals/styled-generic-modal";
import * as s from "./styles";

type Props = {
    attachmentUrl: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BugReportAttachmentModal = ({ attachmentUrl, setOpen }: Props) => {
    const isVideo = attachmentUrl.includes("mp4");

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <ModalTitle>Anexo</ModalTitle>
                {isVideo ? (
                    <video src={attachmentUrl} controls />
                ) : (
                    <img src={attachmentUrl} alt="Anexo" />
                )}
            </s.Container>
        </ContainerModal>
    );
};
