import { useState } from "react";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import Tooltip from "../../../../../../components/atoms/tooltip/tooltip";
import { TSectionActivity } from "../../types";
import * as s from "./styled-add-collaborators-button";

type TAddCollaborators = {
    openModal: React.MouseEventHandler<HTMLButtonElement> | undefined;
    isEditing?: boolean;
    activity?: TSectionActivity;
};

export const Collaborators = ({
    openModal,
    activity,
    isEditing,
}: TAddCollaborators) => {
    const [users] = useState<TSectionActivity["Users"]>(activity?.Users ?? []);

    return (
        <s.Container type="button" onClick={openModal} isEditing={isEditing}>
            {users?.slice(0, 2).map((user) => (
                <Tooltip
                    key={user.User?.id_e}
                    content={
                        <s.StyledTooltip>{user.User?.name}</s.StyledTooltip>
                    }
                >
                    <s.CollaboratorImage
                        key={user.User?.id_e}
                        src={user.User?.avatar ?? FotoUser}
                    />
                </Tooltip>
            ))}
            {users?.length > 2 && (
                <Tooltip
                    content={
                        <s.StyledTooltip>
                            {users?.slice(2).map((user) => (
                                <div key={user?.User.id_e}>
                                    {user.User?.name}
                                </div>
                            ))}
                        </s.StyledTooltip>
                    }
                >
                    <s.HaveMore>+{Number(users?.length) - 2}</s.HaveMore>
                </Tooltip>
            )}
            <s.AddCollaborators>
                <Icons.IconUserAdd2 color={theme.gray800} />
            </s.AddCollaborators>
        </s.Container>
    );
};
