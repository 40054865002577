import { ReadGameficationDataUseCaseReturn } from "../../../../services/gamefication/types";
import { MissionsGroup } from "./components";
import * as s from "./styles";

type ScoreCardProps = {
    data: ReadGameficationDataUseCaseReturn | undefined;
};

export const ScoreCard = ({ data }: ScoreCardProps) => {
    return (
        <s.Container>
            <s.Title>Ganhe mais pontos sendo consistente na plataforma</s.Title>
            <MissionsGroup data={data?.missions} />
        </s.Container>
    );
};
