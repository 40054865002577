import dayjs from "dayjs";

import { FotoUser } from "../../../../../../../assets";
import { ActivityDTO } from "../../types/activityDTO";
import { minutesToStringHoursAndMinutes } from "../../utils";
import * as s from "./styles";

type TCollaboratorsHours = {
    activity: ActivityDTO;
};

export const CollaboratorsHours = ({ activity }: TCollaboratorsHours) => {
    return (
        <s.Container>
            <s.Title>Horas realizadas por profissional</s.Title>
            <s.Content>
                {activity.historic_hour_collaborators.map((collaborator) => (
                    <s.Row key={collaborator.user_id}>
                        <s.Picture src={collaborator.avatar ?? FotoUser} />
                        <s.Wrapper>
                            <div>
                                <s.Name>{collaborator.name}</s.Name>
                                <s.LastUpdate>
                                    última atualização em{" "}
                                    {dayjs(collaborator.last_update).format(
                                        "DD/MM/YYYY"
                                    )}
                                </s.LastUpdate>
                            </div>
                            <s.HoursDone>
                                {minutesToStringHoursAndMinutes(
                                    collaborator.total_time
                                )}
                            </s.HoursDone>
                        </s.Wrapper>
                    </s.Row>
                ))}
            </s.Content>
        </s.Container>
    );
};
