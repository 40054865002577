import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TDeleteNotification = {
    notificationId: string;
};

export default async (data?: TDeleteNotification) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/notifications/delete`,
        method: "delete",
        headers: { auth: true, "x-notification-id": data?.notificationId },
    });
    const { body, statusCode } = httpRequest;
    const errorFeedBacks = {};
    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
