export const missions = [
    {
        id: "uniqueMissions",
        title: "Preenchimento do perfil",
        text: "Complete os seguintes campos e ganhe {points} pontos!",
    },
    {
        id: "dailyMission",
        title: "Meta do dia",
        text: "Complete a meta diária e ganhe um bônus de {points}pts",
    },
    {
        id: "weeklyMission",
        title: "Desafio da semana",
        text: "Complete a meta diária por 5 dias em uma semana e ganhe um bônus de {points} pts",
    },
];
