import { useEffect, useState, useRef } from "react";

import { secondsToStringDate } from "../../../utils";
import * as s from "./styled-timer";

type TTimer = {
    isRunning?: boolean;
    onChange: (timerData: number) => void;
    onClick?: () => void;
    onBlur?: () => void;
    startTime?: number;
};

const Timer = ({ isRunning, onChange, onClick, onBlur, startTime }: TTimer) => {
    const timeNowRef = useRef<number>(startTime || Date.now());
    const [secondsAmount, setSecondsAmount] = useState(0);

    useEffect(() => {
        if (isRunning) {
            const interval = setInterval(() => {
                const duration = Math.floor(
                    (Date.now() - timeNowRef.current) / 1000
                );
                setSecondsAmount(duration);
            }, 1000);

            if (!startTime) timeNowRef.current = Date.now();

            return () => clearInterval(interval);
        }
        if (secondsToStringDate(secondsAmount) === "00:00:00") {
            return () => null;
        }

        setSecondsAmount(0);

        return () => null;
    }, [isRunning]);

    useEffect(() => {
        onChange(secondsAmount);
    }, [secondsAmount]);

    return (
        <s.Timer onClick={onClick} onBlur={onBlur}>
            {secondsToStringDate(secondsAmount)?.slice(0, -3)}
            <span id="seconds">
                :{secondsToStringDate(secondsAmount)?.split(":")[2]}
            </span>
        </s.Timer>
    );
};

export default Timer;
