import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Section = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    width: fit-content;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ActivityName = styled.h1`
    color: ${({ theme }) => theme.gray800};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
    width: fit-content;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Button = styled.button``;

export const TooltipContainer = styled.div`
    padding: 4px 12px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;

export const SectionWrapper = styled.div`
    margin-top: 24px;
`;

export const CheckButton = styled.button`
    position: absolute;
    margin-top: 7px;
    right: 0;
    z-index: 9999;
    margin-right: 50px;
`;
