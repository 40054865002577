import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-whatsapp"}
        >
            <title>{title}</title>
            <path
                d="M2 21.9727L3.83255 17.7523C2.43055 15.7621 1.80305 13.3287 2.0678 10.9087C2.33255 8.48867 3.47136 6.24848 5.2705 4.60849C7.06965 2.9685 9.40545 2.04147 11.8396 2.00136C14.2737 1.96125 16.6387 2.81082 18.4909 4.39064C20.3431 5.97046 21.5551 8.17192 21.8995 10.5819C22.2438 12.9918 21.6968 15.4446 20.3612 17.4799C19.0255 19.5152 16.993 20.9932 14.645 21.6363C12.2971 22.2795 9.79514 22.0437 7.60872 20.9731L2 21.9727Z"
                stroke={color || theme.gray800}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 9.82931C8 9.99252 8.06483 10.149 8.18024 10.2645C8.29565 10.3799 8.45217 10.4447 8.61538 10.4447C8.77859 10.4447 8.93512 10.3799 9.05053 10.2645C9.16593 10.149 9.23077 9.99252 9.23077 9.82931V8.59854C9.23077 8.43533 9.16593 8.2788 9.05053 8.1634C8.93512 8.04799 8.77859 7.98315 8.61538 7.98315C8.45217 7.98315 8.29565 8.04799 8.18024 8.1634C8.06483 8.2788 8 8.43533 8 8.59854V9.82931ZM8 9.82931C8 11.4614 8.64835 13.0267 9.80242 14.1807C10.9565 15.3348 12.5217 15.9832 14.1538 15.9832M14.1538 15.9832H15.3846C15.5478 15.9832 15.7044 15.9183 15.8198 15.8029C15.9352 15.6875 16 15.531 16 15.3678C16 15.2046 15.9352 15.048 15.8198 14.9326C15.7044 14.8172 15.5478 14.7524 15.3846 14.7524H14.1538C13.9906 14.7524 13.8341 14.8172 13.7187 14.9326C13.6033 15.048 13.5385 15.2046 13.5385 15.3678C13.5385 15.531 13.6033 15.6875 13.7187 15.8029C13.8341 15.9183 13.9906 15.9832 14.1538 15.9832Z"
                stroke={color || theme.gray800}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
