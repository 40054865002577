import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
        strokeWidth={0.2}
    >
        <title>{title}</title>
        <g id="unarchive">
            <mask
                id="mask0_22668_16296"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill={theme.white3}
                />
            </mask>
            <g mask="url(#mask0_22668_16296)">
                <path
                    id="unarchive_2"
                    d="M12 16.9442C12.1423 16.9442 12.2612 16.8965 12.3567 16.801C12.4522 16.7055 12.5 16.5865 12.5 16.4442V12.2443L14.2462 13.9904C14.3397 14.084 14.4545 14.134 14.5904 14.1404C14.7263 14.1468 14.8474 14.0968 14.9538 13.9904C15.0603 13.884 15.1135 13.766 15.1135 13.6365C15.1135 13.507 15.0603 13.3891 14.9538 13.2827L12.5654 10.9C12.4038 10.7385 12.2154 10.6577 12 10.6577C11.7846 10.6577 11.5962 10.7385 11.4346 10.9L9.04615 13.2827C8.95257 13.3763 8.90257 13.491 8.89615 13.6269C8.88973 13.7628 8.93973 13.884 9.04615 13.9904C9.15257 14.0968 9.27052 14.15 9.4 14.15C9.52948 14.15 9.64743 14.0968 9.75385 13.9904L11.5 12.2443V16.4442C11.5 16.5865 11.5478 16.7055 11.6433 16.801C11.7388 16.8965 11.8577 16.9442 12 16.9442ZM5 7.8077V18.3846C5 18.5641 5.05769 18.7115 5.17308 18.8269C5.28846 18.9423 5.43589 19 5.61538 19H18.3846C18.5641 19 18.7115 18.9423 18.8269 18.8269C18.9423 18.7115 19 18.5641 19 18.3846V7.8077H5ZM5.76923 20C5.32179 20 4.91506 19.817 4.54902 19.451C4.18301 19.0849 4 18.6782 4 18.2308V7.48653C4 7.29166 4.03109 7.10833 4.09328 6.93653C4.15544 6.76474 4.24871 6.60641 4.37308 6.46152L5.93075 4.59037C6.07563 4.39551 6.25673 4.24839 6.47403 4.14902C6.69134 4.04967 6.92436 4 7.17308 4H16.7885C17.0372 4 17.2734 4.04967 17.4971 4.14902C17.7208 4.24839 17.9051 4.39551 18.05 4.59037L19.6269 6.5C19.7513 6.64487 19.8446 6.8064 19.9067 6.9846C19.9689 7.16282 20 7.34936 20 7.54422V18.2308C20 18.6782 19.817 19.0849 19.451 19.451C19.0849 19.817 18.6782 20 18.2308 20H5.76923ZM5.38078 6.80768H18.6L17.2692 5.21152C17.2051 5.14742 17.1314 5.09615 17.0481 5.0577C16.9647 5.01923 16.8782 5 16.7885 5H7.1923C7.10255 5 7.01602 5.01923 6.9327 5.0577C6.84937 5.09615 6.77564 5.14742 6.71152 5.21152L5.38078 6.80768Z"
                    fill={color || theme.gray800}
                />
            </g>
        </g>
    </svg>
);
