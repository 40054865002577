import { TBaseSVG } from "../../models";

type Props = TBaseSVG & {
    pulse: boolean;
};

export default ({ color, width, height, pulse }: Props) => {
    return (
        <svg
            width={width || "200"}
            height={height || "200"}
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
        >
            {pulse && (
                <circle
                    cx="20"
                    cy="20"
                    fill="none"
                    r="10"
                    stroke={color || "#383a36"}
                    strokeWidth="2"
                >
                    <animate
                        attributeName="r"
                        from="8"
                        to="20"
                        dur="1.5s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                    <animate
                        attributeName="opacity"
                        from="1"
                        to="0"
                        dur="1.5s"
                        begin="0s"
                        repeatCount="indefinite"
                    />
                </circle>
            )}
            <circle cx="20" cy="20" fill={color || "#383a36"} r="10" />
            <circle cx="20" cy="20" fill="white" r="5" />
        </svg>
    );
};
