import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-senha-fechado"}
        >
            <title>{title || "Esconder senha"}</title>
            <path
                d="M17.411 17.411L21 21M13.875 18.825C13.2569 18.9419 12.6291 19.0005 12 19C7.52203 19 3.73203 16.057 2.45703 12C2.8003 10.9081 3.32902 9.88346 4.02003 8.971L13.875 18.825ZM9.87803 9.879C10.4407 9.31634 11.2038 9.00025 11.9995 9.00025C12.7952 9.00025 13.5584 9.31634 14.121 9.879C14.6837 10.4417 14.9998 11.2048 14.9998 12.0005C14.9998 12.7962 14.6837 13.5593 14.121 14.122L9.87803 9.879ZM9.87803 9.878L14.12 14.12L9.87803 9.878ZM9.88003 9.88L6.59003 6.59L9.88003 9.88ZM14.122 14.122L17.412 17.412L14.122 14.122ZM3.00003 3L6.59003 6.59L3.00003 3ZM6.59003 6.59C8.20239 5.54957 10.0811 4.9974 12 5C16.478 5 20.268 7.943 21.543 12C20.8391 14.2305 19.3774 16.1446 17.411 17.411L6.59003 6.59Z"
                stroke={color || theme.purple400}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
