import dayjs from "dayjs";

import { formatToBRMoneyWithoutMoneySign } from "../../../../../../../utils";
import { TabProps } from "../../../tab-costs";
import * as Table from "../../table/table";
import { ThisMonthTooltip } from "./components/this-month-tooltip/this-month-tooltip";
import * as s from "./styles";

export const ResumeTab = ({ data, date }: TabProps) => {
    if (!data) return null;
    const dateFilterIsThisMonth =
        dayjs(date.start).isSame(dayjs(), "month") ||
        dayjs(date.end).isSame(dayjs(), "month");

    const typeMapper = {
        fixed: "Custos fixos",
        variable: "Custos variáveis",
        team: "Custos time",
    };

    return (
        <s.Container>
            <Table.Root>
                <Table.Header>
                    <Table.Head>Tipo</Table.Head>
                    <Table.Head>Custo realizado (R$)</Table.Head>
                </Table.Header>
                <Table.Body>
                    {data.resume.data.map((data) => (
                        <Table.Row>
                            <Table.Data>{typeMapper[data.type]}</Table.Data>
                            <Table.Data>
                                {dateFilterIsThisMonth && <ThisMonthTooltip />}
                                {formatToBRMoneyWithoutMoneySign(
                                    data.realizedCost
                                )}
                            </Table.Data>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Total>
                    <Table.Data></Table.Data>
                    <Table.Data>
                        {formatToBRMoneyWithoutMoneySign(
                            data.resume.monthTotal.totalCost
                        )}
                    </Table.Data>
                    <Table.Data></Table.Data>
                </Table.Total>
            </Table.Root>
        </s.Container>
    );
};
