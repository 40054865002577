import { TDeleteSector } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type Errors =
    "the sector cannot be deleted or have its user list changed, as it is the only one available to the following users:";

export default async ({ sectorId }: TDeleteSector = {}) => {
    const tempHeaders = {
        auth: true,
        "x-sector-id": sectorId,
    };

    if (!sectorId) delete tempHeaders["x-sector-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sector/delete`,
        method: "delete",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const error: Errors = body?.message?.error || body?.message;

    const errorMapper = {
        "the sector cannot be deleted or have its user list changed, as it is the only one available to the following users:":
            "O setor não pode ser excluído pois é o único setor de alguns usuários.",
        "the sector cannot be deleted, as it has time entries!":
            "O setor não pode ser excluído pois possui registros de horas!",
    };

    const errorFeedBacks = {
        400: errorMapper[error] || "Propriedade inválida",
        404: "Setor não encontrado!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
