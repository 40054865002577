import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icons, theme } from "../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
} from "../../../../../components";
import DayPicker from "../../../../../components/molecules/day-picker/day-picker";
import { TOption, TProject, TResponse } from "../../../../../models";
import { CreateActivityVariables } from "../../../../../services/activities/types";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import { validatePermittedStrings } from "../../../../../utils/validation";
import { TPriority, TSectionActivity } from "../types";
import { Collaborators } from "./add-collaborators-button/add-collaborators-button";
import * as s from "./styled-components";

type TPriorityOption = {
    value: TPriority;
    label: string;
    color: string;
};

type TFields = {
    activityName: string;
    deadline: Date | undefined;
    priority: TPriorityOption;
    predictedHours: string;
};

type TAddActivity = {
    project: TProject;
    createActivity: TResponse<unknown, CreateActivityVariables>;
    sectionId?: string;

    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    activityToAddCollaborators: TSectionActivity | undefined;
    setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddActivity = ({
    sectionId,
    createActivity,
    project,
    setActivityToAddCollaborators,
    activityToAddCollaborators,
    setIsCreating,
}: TAddActivity) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<TFields>();

    const REQUIRED_FIELD = {
        required: {
            value: true,
            message: "Campo obrigatório!",
        },
    };

    const priorityOptions: TPriorityOption[] = [
        {
            value: "low",
            label: "Baixa",
            color: theme.green1,
        },
        {
            value: "medium",
            label: "Média",
            color: theme.orange,
        },
        {
            value: "high",
            label: "Alta",
            color: "#D32F2F",
        },
    ];

    const [isToCreate, setIsToCreate] = useState<boolean>(false);
    const [users, setUsers] = useState<TSectionActivity["Users"]>([]);

    useEffect(() => {
        if (isToCreate) {
            setUsers([]);
        }
    }, [isToCreate]);

    useEffect(() => {
        if (activityToAddCollaborators?.Users) {
            setUsers(activityToAddCollaborators?.Users);
        }
    }, [activityToAddCollaborators]);

    const predictedHoursToMinutes = (
        predictedHours: string
    ): number | undefined => {
        if (!predictedHours) return undefined;
        const [hours, minutes] = predictedHours.split(":");
        return Number(hours) * 60 + Number(minutes);
    };

    const mapData = (data: TFields): CreateActivityVariables => {
        return {
            name: data?.activityName,
            priority: data?.priority.value,
            deadLine: data?.deadline,
            expectedMinutes: predictedHoursToMinutes(data?.predictedHours),
            users: users.map((user) => user.User.id_e),
        };
    };

    const clearData = () => {
        setUsers([]);
        setActivityToAddCollaborators(undefined);
        reset();
    };

    const onSubmit = handleSubmit((data) => {
        const isValid = validatePermittedStrings(data.activityName);
        if (!isValid) return;

        createActivity.onRefresh({
            ...mapData(data),
            sectionId,
            projectId: project.id_e,
        });
    });

    useEffect(() => {
        if (createActivity.response) {
            clearData();
            setIsToCreate(false);
            setIsCreating?.(true);
        }
    }, [createActivity.response]);

    const formatOptionLabel = (
        option: string | TOption | null | undefined,
        input: { inputValue: string }
    ) => {
        if (typeof option !== "object") return <p>{input.inputValue}</p>;
        const optionValue = priorityOptions.find(
            (priority) => priority.value === (option as TPriorityOption).value
        );
        return (
            <s.PriorityOption color={optionValue?.color}>
                <span>•</span>
                {optionValue?.label}
            </s.PriorityOption>
        );
    };

    const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const formattedValue = inputValue
            .replace(/[^0-9:]/g, "")
            .replace(/(\d{3})(\d{2})/, "$1:$2")
            .replace(/(:\d{2})\d+?$/, "$1");
        return formattedValue;
    };

    return isToCreate ? (
        <s.ActivityContainer
            onSubmit={onSubmit}
            onKeyDown={(e) => {
                if (e.key === "Escape") {
                    setIsToCreate(false);
                    setIsCreating?.(false);
                    reset();
                }
                if (e.key === "Enter") {
                    document.getElementById("add")?.click();
                }
            }}
        >
            <s.ActivityContent>
                <s.ActivityName>
                    <Controller
                        name="activityName"
                        control={control}
                        rules={{
                            ...REQUIRED_FIELD,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                ref={(el) => el?.focus()}
                                value={value}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                placeholder="Escreva o nome da atividade"
                                mask=""
                                maxLength={150}
                                error={errors.activityName?.message as string}
                            />
                        )}
                    />
                </s.ActivityName>
                <Collaborators
                    key={users.length}
                    openModal={() => {
                        setActivityToAddCollaborators({
                            Users: users,
                        } as never);
                    }}
                    activity={
                        {
                            Users: users,
                        } as never
                    }
                />
                <s.Deadline>
                    <Controller
                        name="deadline"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DayPicker
                                value={value}
                                placeholder="Data"
                                onSelect={(date) => {
                                    onChange(date);
                                }}
                                toYear={new Date().getFullYear() + 1}
                            />
                        )}
                    />
                </s.Deadline>
                <s.Priority>
                    <Controller
                        name="priority"
                        control={control}
                        defaultValue={priorityOptions[0]}
                        render={({ field: { ref, onChange, value } }) => (
                            <SelectInput
                                value={value}
                                inputRef={ref}
                                onChange={(e: TPriorityOption) => {
                                    onChange(e);
                                }}
                                options={priorityOptions}
                                icon={<Icons.Chevron color={theme.gray400} />}
                                formatOptionLabel={formatOptionLabel}
                            />
                        )}
                    />
                </s.Priority>
                <s.PredictedHours>
                    <span>Horas previstas:</span>
                    <Controller
                        name="predictedHours"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                value={value}
                                onChange={(e) =>
                                    onChange(handleTimeInputChange(e))
                                }
                                mask=""
                                placeholder="Escreva o nome da atividade"
                                defaultValue="00:00"
                                error={errors.predictedHours?.message as string}
                            />
                        )}
                    />
                </s.PredictedHours>
            </s.ActivityContent>
            <s.ButtonsContainer>
                <SecondaryButton
                    onClick={() => {
                        clearData();
                        setIsToCreate(false);
                        setIsCreating?.(false);
                    }}
                >
                    CANCELAR
                </SecondaryButton>
                <PrimaryButton
                    id="add"
                    type="submit"
                    loading={createActivity.loading}
                >
                    ADICIONAR
                </PrimaryButton>
            </s.ButtonsContainer>
        </s.ActivityContainer>
    ) : (
        <s.AddActivityButton
            onClick={() => {
                setIsToCreate(true);
                setIsCreating?.(true);
                Mixpanel.track("Adicionar atividade");
            }}
        >
            <Icons.Plus color={theme.gray800} width="20" height="20" />
            Adicionar atividade
        </s.AddActivityButton>
    );
};
