import { Auth } from "../auth/Authentication";

export const verifyWebsiteStatus = () => {
    const auth = Auth.getInstance();
    const isSiteOnMaintenance = process.env.REACT_APP_SERVER_MAINTENANCE;
    const isSiteUnavailable = process.env.REACT_APP_SERVER_UNAVAILABLE;

    if (isSiteUnavailable === "true") {
        auth.logoutToTheInformationPages("indisponivel");
    }

    if (isSiteOnMaintenance === "true") {
        auth.logoutToTheInformationPages("manutencao");
    }
};
