import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/reducedRead`,
        method: "get",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        403:
            body.message === "You don't have permission to access this resource"
                ? "Você não tem permissão para acessar esse recurso"
                : "Erro ao tentar listar projetos",
        404: "Sem projetos!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
