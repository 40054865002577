import * as ScrollArea from "@radix-ui/react-scroll-area";
import styled from "styled-components";

export const Scroll = styled(ScrollArea.Root)`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: "hidden";
`;

export const Viewport = styled(ScrollArea.Viewport)`
    width: 100%;
    height: 100%;
`;

export const Scrollbar = styled(ScrollArea.Scrollbar)`
    border-radius: 10px;
    display: "flex";
    padding: 2px;
    background-color: ${({ theme }) => theme.gray100};
    transition: ${({ theme }) => theme.pink50} 160ms ease-out;
    &[data-orientation="vertical"] {
        width: 10px;
    }
`;

export const Thumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background-color: ${({ theme }) => theme.purple600};
    border-radius: 10px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: "50%";
        left: "50%";
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        min-width: 44;
        min-height: 44;
    }
`;
