import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable,
} from "react-beautiful-dnd";

import { activities } from "../../../../../../../../../services";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { ActivityDTO } from "../../../../types/activityDTO";
import { SubItem } from "../sub-item/sub-item";
import * as s from "./styles";

type TSubItems = {
    checklist: ActivityDTO["ActivityChecklist"][0];
    activity: ActivityDTO;
};

type TUpdateSubItem = {
    checklistId: string;
    position: number;
    subtaskId: string;
};

export const SubItems = ({ checklist, activity }: TSubItems) => {
    const [subItems, setSubItems] = useState(checklist.subtasks);

    useEffect(() => {
        setSubItems(checklist.subtasks);
    }, [checklist.subtasks]);

    const { mutate: updateSubItemPosition } = useMutation<
        unknown,
        unknown,
        TUpdateSubItem
    >({
        mutationFn: (params) =>
            activities.updateSubItemPosition({
                ...params,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(subItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSubItems(items);
        updateSubItemPosition({
            subtaskId: reorderedItem.id_e,
            checklistId: checklist.id_e,
            position: result.destination.index + 1,
        });
    };

    return (
        <s.Container>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="checklist">
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {subItems?.map((item, index) => (
                                <Draggable
                                    key={item.id_e}
                                    draggableId={item.id_e}
                                    index={index}
                                >
                                    {(provided) => (
                                        <ul
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            style={{
                                                ...provided.draggableProps
                                                    .style,
                                                left: "auto !important",
                                                top: "auto !important",
                                            }}
                                        >
                                            <SubItem
                                                item={item}
                                                activity={activity}
                                            />
                                        </ul>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </s.Container>
    );
};
