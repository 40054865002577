import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-clock"}
        onClick={onClick}
        strokeWidth={0}
    >
        <title>{title}</title>
        <g id="Timer">
            <path
                id="timer"
                d="M10.329 2.32612C10.1165 2.32612 9.93838 2.25422 9.79464 2.11042C9.65089 1.96661 9.57902 1.78841 9.57902 1.57582C9.57902 1.36322 9.65089 1.18513 9.79464 1.04155C9.93838 0.897965 10.1165 0.826172 10.329 0.826172H14.4444C14.6569 0.826172 14.835 0.898073 14.9787 1.04187C15.1225 1.18569 15.1944 1.36389 15.1944 1.57647C15.1944 1.78907 15.1225 1.96716 14.9787 2.11075C14.835 2.25433 14.6569 2.32612 14.4444 2.32612H10.329ZM12.387 13.3838C12.5996 13.3838 12.7777 13.3119 12.9213 13.1682C13.0649 13.0245 13.1367 12.8463 13.1367 12.6338V8.51845C13.1367 8.30595 13.0648 8.12783 12.921 7.9841C12.7772 7.84035 12.599 7.76847 12.3864 7.76847C12.1738 7.76847 11.9957 7.84035 11.8521 7.9841C11.7085 8.12783 11.6367 8.30595 11.6367 8.51845V12.6338C11.6367 12.8463 11.7086 13.0245 11.8524 13.1682C11.9962 13.3119 12.1744 13.3838 12.387 13.3838ZM12.3867 21.0761C11.2205 21.0761 10.1212 20.853 9.08897 20.4069C8.0567 19.9607 7.15467 19.3518 6.38287 18.58C5.61109 17.8082 5.00212 16.9061 4.55597 15.8739C4.1098 14.8416 3.88672 13.7424 3.88672 12.5761C3.88672 11.4099 4.1098 10.3107 4.55597 9.27842C5.00212 8.24616 5.61109 7.34412 6.38287 6.57232C7.15467 5.80054 8.0567 5.19157 9.08897 4.74542C10.1212 4.29926 11.2205 4.07617 12.3867 4.07617C13.388 4.07617 14.3524 4.24605 15.28 4.5858C16.2075 4.92555 17.0687 5.41209 17.8636 6.04542L18.5828 5.32617C18.7213 5.18772 18.8953 5.1169 19.1049 5.1137C19.3145 5.11048 19.4918 5.18131 19.6367 5.32617C19.7815 5.47106 19.854 5.6467 19.854 5.8531C19.854 6.0595 19.7815 6.23513 19.6367 6.38L18.9174 7.09925C19.5508 7.89411 20.0373 8.75532 20.377 9.68287C20.7168 10.6104 20.8867 11.5749 20.8867 12.5761C20.8867 13.7424 20.6636 14.8416 20.2174 15.8739C19.7713 16.9061 19.1623 17.8082 18.3905 18.58C17.6187 19.3518 16.7167 19.9607 15.6844 20.4069C14.6522 20.853 13.5529 21.0761 12.3867 21.0761Z"
                fill={color || theme.purple500}
            />
        </g>
    </svg>
);
