import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { ModalAction } from "../../../components";
import { TDeleteRole } from "../../../models";
import { IRoleModel } from "../../../models/role";
import { permissions as permissionsService, roles } from "../../../services";
import { TCreateRole } from "../../../services/roles/create-role";
import { TUpdateRole } from "../../../services/roles/update-role";
import { ButtonGroup } from "./components/button-group/button-group";
import { Header } from "./components/header/header";
import { InputGroup } from "./components/input-group/input-group";
import { PermissionsGroup } from "./components/permissions-group/permissions-group";
import * as s from "./styled-roles-form";
import validation from "./validation";

type TRolesForm = {
    edit?: boolean;
    view?: boolean;
};

type Error = {
    message: string;
};

export interface IPermission {
    id: string;
    value: "enabled" | "disabled" | "view" | "manage";
    type: "switch" | "access_control";
    classification: "management" | "finance" | "total";
}

export type TReadPermissionsResult = {
    permissions: IPermission[];
    hasManagerPermission: boolean;
};

export const RolesForm = ({ edit, view }: TRolesForm) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { role }: { role?: IRoleModel; onlyView?: boolean } =
        location.state || {};

    const { data: permissionsData, isLoading } =
        useQuery<TReadPermissionsResult>({
            queryKey: ["readPermissions"],
            queryFn: () =>
                permissionsService.getPermission({ roleId: role?.id_e }),
            cacheTime: 0,
        });

    const updateRole = useMutation<unknown, Error, TUpdateRole>({
        mutationKey: ["updateRole"],
        mutationFn: (params) => roles.updateRole(params),
        onSuccess: () => {
            toast.success("Atuação atualizada com sucesso");
            navigate("/organizacao/atuacoes");
        },
        onError: (error) => {
            toast.error(error?.message);
        },
    });

    const createRole = useMutation<unknown, unknown, TCreateRole>({
        mutationKey: ["createRole"],
        mutationFn: (params) => roles.createRole(params),
        onSuccess: () => {
            toast.success("Atuação criada com sucesso");
            navigate("/organizacao/atuacoes");
        },
    });

    const deleteRole = useMutation<unknown, Error, TDeleteRole>({
        mutationKey: ["deleteRole"],
        mutationFn: (params) => roles.deleteRole(params),
        onSuccess: () => {
            toast.success("Atuação deletada com sucesso");
            navigate("/organizacao/atuacoes");
        },
        onError: (error) => {
            toast.error(error?.message);
        },
    });

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        control,
    } = useForm<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>({
        resolver: yupResolver(validation()),
        defaultValues: {
            name: role?.name,
            description: role?.description,
            permissions: permissionsData?.permissions,
        },
    });

    const handleDeleteRole = async () => {
        deleteRole.mutate({
            roleId: role?.id_e,
        });
    };

    const handleUpdateRole = async (data: Omit<TUpdateRole, "roleId">) => {
        updateRole.mutate({
            ...data,
            roleId: role?.id_e,
        });
    };

    const handleCreateRole = async (data: TCreateRole) => {
        createRole.mutate(data);
    };

    return (
        <s.Form
            onSubmit={handleSubmit(edit ? handleUpdateRole : handleCreateRole)}
        >
            {showDialog && (
                <ModalAction
                    setOpen={setShowDialog}
                    title={"Sair sem salvar alterações"}
                    description={
                        "Os dados alterados não serão salvos. Deseja sair mesmo assim?"
                    }
                    primaryAction={() => {
                        setShowDialog(false);
                        navigate("/organizacao/atuacoes");
                    }}
                    secondaryAction={() => {
                        setShowDialog(false);
                    }}
                    primaryText={"Sair"}
                    secondaryText={"Cancelar"}
                />
            )}

            {showDialogDelete && (
                <ModalAction
                    setOpen={setShowDialogDelete}
                    title={"Deseja mesmo deletar a atuação?"}
                    description={"Essa ação não pode ser revertida"}
                    primaryAction={handleDeleteRole}
                    primaryText={"Deletar atuação"}
                    secondaryAction={() => setShowDialogDelete(false)}
                    secondaryText={"Cancelar"}
                />
            )}

            <Header edit={edit} setShowDialogDelete={setShowDialogDelete} />

            <InputGroup
                register={register}
                watch={watch}
                errors={errors}
                loading={isLoading}
                view={view}
            />

            {permissionsData?.permissions?.length && (
                <PermissionsGroup
                    loading={isLoading}
                    data={permissionsData}
                    control={control}
                    watch={watch}
                    view={view}
                />
            )}

            <ButtonGroup
                edit={edit}
                onSave={() => {
                    handleSubmit(edit ? handleUpdateRole : handleCreateRole);
                }}
                view={view}
                loading={createRole.isLoading || updateRole.isLoading}
            />
        </s.Form>
    );
};
