import { ReactNode } from "react";

import { Icons } from "../../../assets";
import Tooltip from "../../atoms/tooltip/tooltip";
import * as s from "./styles";

type Props = {
    label: string;
    tooltip?: string;
    children: ReactNode;
};

export function LabelWrapper({ label, children, tooltip }: Props) {
    return (
        <s.Container>
            <s.Label>
                {label}
                {tooltip && (
                    <Tooltip
                        align="start"
                        positiony={5}
                        content={<s.TooltipContent>{tooltip}</s.TooltipContent>}
                    >
                        <div>
                            <Icons.Info />
                        </div>
                    </Tooltip>
                )}
            </s.Label>
            {children}
        </s.Container>
    );
}
