import { contactPhone } from "../constants/contact-phone";

export const redirectToWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
        window.location.href = `whatsapp://send?phone=${contactPhone}`;
    } else {
        window.open(
            `https://web.whatsapp.com/send?phone=${contactPhone}`,
            "_blank"
        );
    }
};
