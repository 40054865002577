export const plansMappers = {
    trial: "Teste grátis",
    lite: "Lite",
    starter: "Starter",
    standard: "Standard",
    business: "Business",
};

export const plansTypesMappers = {
    monthly: "Mensal",
    yearly: "Anual",
};
