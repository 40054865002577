import styled from "styled-components";

import { PrimaryButton } from "../../../../../../../../components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 43px;
    width: 752px;
    height: 640px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple400};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;
    margin-bottom: 24px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin: 24px;
    margin-top: 0;
`;

export const Button = styled(PrimaryButton)`
    max-width: 107px;
`;

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const ImageButton = styled.button<{ left?: boolean; hide?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: none;
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 50%;
    cursor: pointer;

    ${({ left }) =>
        left &&
        `
        transform: rotate(180deg);
    `}

    ${({ hide }) =>
        hide &&
        `
        opacity: 0;
        pointer-events: none;
    `}
`;

export const IndicatorsContainer = styled.div`
    display: flex;
    gap: 3px;
`;

export const ImageIndicator = styled.span<{ selected?: boolean }>`
    font-size: 30px;
    cursor: pointer;
    color: ${({ theme }) => theme.gray200};

    ${({ selected, theme }) =>
        selected &&
        `
        color: ${theme.purple300};
    `}
`;
