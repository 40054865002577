import styled from "styled-components";

export const Container = styled.div`
    max-width: 80vw;
    height: 75vh;
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    padding: 20px 40px;
    border: 1px solid ${({ theme }) => theme.gray100};
    overflow-y: auto;

    details {
        margin-bottom: 20px;

        summary {
            margin-bottom: 10px;
            border-radius: 8px;
            padding: 10px 10px;
            width: fit-content;
            color: ${({ theme }) => theme.gray600};
            cursor: pointer;
            &:hover {
                background-color: ${({ theme }) => theme.gray50};
            }
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.white};
    width: fit-content;
    border: 1px solid ${({ theme }) => theme.gray100};
    transition: all 0.3s;
    border-radius: 8px;

    max-height: 300px;
    overflow-y: auto;

    &:hover {
        box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.gray100};
    }

    h1 {
        margin: 0px;
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
    }

    span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ theme }) => theme.gray900};
        text-align: left;
        font-size: 17px;

        div {
            &:first-child {
                width: 400px;
                margin-right: 20px;
            }

            &:last-child {
                width: 100%;
            }
        }
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    font-size: 16px;
    color: ${({ theme }) => theme.purple500};
    font-weight: 600;
    width: fit-content;
`;

export const NoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 20px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.gray100};
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 600;
`;

export const Divider = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.gray100};
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: -20px;
    margin-right: -20px;
`;
