import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.gray50};
    display: grid;
    grid-template-columns: 300px 50px 1fr;
    align-items: center;
    width: 100%;
    gap: 16px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 5px;
    }
`;

export const ProjectSelector = styled.div`
    padding: 12px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.white};
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid ${({ theme }) => theme.gray400};
`;
