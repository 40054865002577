import { useForm } from "react-hook-form";

import PrimaryButton from "../primary-button/primary-button";
import TextArea from "../text-area/text-area";
import * as s from "./styled-refuse-report-modal";

type TRefuseReportModalProps = {
    isOpen: boolean;
    mode: "refuse" | "correct";
    message?: string;
    onClickRefuseReport?: (message: string) => void;
    onClickSendCorrections?: () => void;
};

type TFields = {
    reason: string;
};

export const RefuseReportModal = ({
    isOpen,
    mode,
    message,
    onClickRefuseReport,
    onClickSendCorrections,
}: TRefuseReportModalProps) => {
    const { register, watch, getValues } = useForm<TFields>();

    return (
        <s.Container
            style={
                isOpen
                    ? { display: "unset", pointerEvents: "all" }
                    : { height: "0", opacity: "0", pointerEvents: "none" }
            }
        >
            {mode === "correct" ? (
                <>
                    <s.Header>Relatório recusado</s.Header>
                    <s.TextContainer>
                        <s.Information>
                            Seu relatório foi recusado, corrija os itens
                            descritos pelo seu gestor:
                        </s.Information>
                        <s.ReportReason>
                            {message || "Nenhuma descrição informada"}
                        </s.ReportReason>
                        <s.Information>
                            Após corrigir os itens acima, clique em “corrigido”
                            e ele será reenviado para seu gestor.
                        </s.Information>
                        <s.Button>
                            <PrimaryButton onClick={onClickSendCorrections}>
                                Corrigido
                            </PrimaryButton>
                        </s.Button>
                    </s.TextContainer>
                </>
            ) : (
                <>
                    <s.Header>
                        Descreva os itens que devem ser corrigidos no relatório
                        do profissional
                    </s.Header>
                    <s.TextContainer>
                        <TextArea
                            {...register("reason")}
                            counter={watch("reason") || ""}
                            charCount={true}
                            maxLength={300}
                        />
                        <s.Button>
                            <PrimaryButton
                                onClick={() => {
                                    if (onClickRefuseReport)
                                        onClickRefuseReport(
                                            getValues("reason")
                                        );
                                }}
                            >
                                Enviar recusa
                            </PrimaryButton>
                        </s.Button>
                    </s.TextContainer>
                </>
            )}
        </s.Container>
    );
};
