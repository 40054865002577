import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    width: 100%;
    max-width: 99rem;
    padding-bottom: 50px;

    #month-summary {
        padding: 1rem;
        margin-top: 1.5rem;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        height: 168px;

        div#container-info {
            width: 100%;
            display: flex;
            gap: 76px;

            span {
                display: flex;
                gap: 8px;
            }

            span#btn-limit {
                margin-left: auto;

                button {
                    padding: 0 0.5rem;
                }
            }
        }

        #container-summary {
            gap: 0.5rem;
        }
    }

    .profile-root {
        width: 100%;
        margin-top: 2rem;
        position: relative;
        z-index: 0;
        overflow-x: clip;
    }

    .profile-tabs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 3rem;
        background-color: ${({ theme }) => theme.gray50};
        white-space: nowrap;

        button {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${({ theme }) => theme.gray400};
            width: 100%;
            border: 1px solid ${({ theme }) => theme.gray200};
            border-radius: 4px 4px 0px 0px;
        }

        [data-state="active"] {
            background-color: ${({ theme }) => theme.purple50};
            color: ${({ theme }) => theme.gray800};
            border-bottom: 0px;
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 21rem;
    position: absolute;
    width: 100%;
    top: 47px;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: calc(24px + 1.5rem);
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .title-row {
        width: 18.125rem;
        margin-right: auto;
    }

    .title-row-historic {
        flex: 0.5;
        width: 100%;
        margin-right: 0;
    }

    .resume,
    .contacts {
        height: 18rem;
        padding: 1.5rem 1.5rem 1.5rem 2.5rem;
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 0px 0px 4px 4px;
        background-color: ${({ theme }) => theme.gray50};
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            align-self: flex-start;
        }
    }

    .resume {
        img {
            border-radius: 50%;
            width: 180px;
            height: 180px;
        }

        .resume-data,
        .resume-projects,
        .resume-contract,
        .resume-experience,
        .resume-health,
        .resume-historic {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .resume-data,
        .resume-contract,
        .resume-experience,
        .resume-health,
        .resume-historic {
            margin: 0 1.875rem 0 2.5rem;
            li {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1rem;
                color: ${({ theme }) => theme.gray400};
                list-style: none;
            }

            span {
                margin-left: 0.5rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: ${({ theme }) => theme.gray900};
            }
        }

        .resume-contract,
        .resume-experience,
        .resume-health,
        .resume-historic {
            height: 100%;
            margin: 0 1.875rem 0 0;
            flex-wrap: wrap;

            li {
                margin-right: 4rem;
                max-width: 365px;
            }
        }

        .resume-experience,
        .resume-health {
            padding-bottom: 1.5rem;

            li {
                margin-bottom: 24px;
            }
        }

        .resume-projects {
            width: 19.3125rem;
            margin-right: 5.375rem;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;

            h2 {
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 1rem;
                color: ${({ theme }) => theme.gray400};
            }

            ul {
                display: flex;
                row-gap: 4px;
                column-gap: 8px;
                flex-wrap: wrap;
                flex-direction: column;
                max-height: calc(18rem - 3rem);
                font-size: 0.75rem;
            }

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.25rem;
                width: 7.5625rem;
                height: 1.75rem;
                background-color: ${({ theme }) => theme.purple50};
                border: 1px solid ${({ theme }) => theme.purple100};
                border-radius: 4px;
            }
        }

        .resume-historic {
            width: 100%;
            flex-wrap: nowrap;
            margin-right: 24px;

            li {
                display: flex;
                flex-direction: row;
                max-width: 100%;
                margin: 0;

                p {
                    width: 18.125rem;
                    margin: 0 auto 0 0;
                    white-space: nowrap;
                }
                p:last-child {
                    flex: 0.5;
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
    }

    .contacts-data {
        margin-right: 1.875rem;
        height: 100%;

        li {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: ${({ theme }) => theme.gray400};
            list-style: none;
        }

        span {
            margin-left: 0.5rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            color: ${({ theme }) => theme.gray900};
        }

        .contact-name {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.375rem;
            margin-left: 0rem;
        }
    }

    .contacts-row {
        width: 18.125rem;
        height: 100%;
        margin-right: auto;
        display: flex;
        flex-direction: column;

        div:first-child {
            margin-left: 1.75rem;
        }

        label {
            width: 1.5rem;
            height: 1.5rem;
            border-color: ${({ theme }) => theme.gray500};

            svg {
                transform: scale(0.75);
            }
        }

        img {
            border-radius: 50%;
            width: 80px;
            height: 80px;
        }

        ul {
            display: flex;
            row-gap: 4px;
            column-gap: 8px;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-around;
        }
    }
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
`;

export const Breadcrumb = styled.div`
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 0.5rem;

    .arrow-left {
        transform: rotate(180deg);
        cursor: pointer;
    }

    .chevron-rigth {
        transform: rotate(-90deg);
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.75rem;
    }

    .breadcrumb {
        color: ${({ theme }) => theme.gray400};
        cursor: pointer;
    }

    .blacktext {
        color: ${({ theme }) => theme.gray800};
    }
`;

export const H2 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    letter-spacing: 0.15%;
    color: ${({ theme }) => theme.gray900};
    margin-top: 3.375rem;
`;

export const BorderContainer = styled(Flex)`
    background: ${({ theme }) => theme.white};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.gray200};
    box-sizing: border-box;
    border-radius: 0.25rem;
    align-items: center;

    .loading-container {
        display: flex;
        width: 100%;
        height: 100%;
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const GrayP = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.001em;
    color: ${({ theme }) => theme.gray600};
`;
