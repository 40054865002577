import styled from "styled-components";

export const Container = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 400px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: -0.64px;
    margin-bottom: 20px;
`;

export const Or = styled.p`
    color: ${({ theme }) => theme.textPrimary};
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.36px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;

    &::after,
    &::before {
        content: "";
        display: block;
        height: 1px;
        background-color: ${({ theme }) => theme.purple50};
        width: 180px;
    }
`;

export const Terms = styled.p`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    a {
        color: ${({ theme }) => theme.gray600} !important;
        text-decoration-line: underline;
    }
`;

export const AlreadyRegister = styled.p`
    color: ${({ theme }) => theme.gray800};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    a {
        color: ${({ theme }) => theme.purple500} !important;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
        text-decoration-line: underline;
        cursor: pointer;
    }
`;

export const EmailAlreadyRegistered = styled.div`
    padding: 12px;
    background-color: ${({ theme }) => theme.softRed};
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    .message-container {
        div {
            color: ${({ theme }) => theme.redError};
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 2px;

            &:first-child {
                font-weight: 600;
            }
        }
    }
`;
