import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TDuplicateSubItem = {
    subItemId?: string;
};

export default async ({ subItemId }: TDuplicateSubItem = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/subtask/duplicate`,
        method: "post",
        body: {},
        headers: {
            auth: true,
            "x-subtask-id": subItemId,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
