import { useMutation } from "@tanstack/react-query";

import { saveProjectActivityFavorite } from "../../services/user";
import { SaveProjectActivityFavoriteParams } from "../../services/user/save-project-activity-favorite";

type Params = {
    onSuccess?: (
        data: unknown,
        variables: SaveProjectActivityFavoriteParams | undefined,
        context: unknown
    ) => unknown;
};

export function useSaveProjectActivityFavorite({ onSuccess }: Params = {}) {
    return useMutation({
        mutationKey: ["saveProjectActivityFavorite"],
        mutationFn: saveProjectActivityFavorite,
        onSuccess,
    });
}
