import * as s from "./styled-steps";

type TSteps = {
    steps: { stepName: string }[];
    actualStep: number;
    separatorSize?: string;
};

export default ({ steps, actualStep, separatorSize }: TSteps) => {
    return (
        <s.Container separatorSize={separatorSize} steps={steps.length}>
            <s.Title>Etapas do Cadastro</s.Title>

            <div className="container-steps">
                {steps.map((step, index) => (
                    <>
                        {index !== 0 && <s.Separator />}
                        <s.Steps isActive={actualStep >= index + 1}>
                            {index + 1}
                        </s.Steps>
                    </>
                ))}
                {steps.map((step, index) => (
                    <>
                        {index !== 0 && <span></span>}
                        <s.StepName isActive={actualStep >= index + 1}>
                            {step.stepName}
                        </s.StepName>
                    </>
                ))}
            </div>
        </s.Container>
    );
};
