import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick, style }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-filler-outlined"}
            style={style}
        >
            <title>{title}</title>
            <path
                d="M1 2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V4.586C18.9999 4.85119 18.8946 5.10551 18.707 5.293L12.293 11.707C12.1055 11.8945 12.0001 12.1488 12 12.414V15L8 19V12.414C7.99994 12.1488 7.89455 11.8945 7.707 11.707L1.293 5.293C1.10545 5.10551 1.00006 4.85119 1 4.586V2Z"
                stroke={color || theme.purple400}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
