import styled, { css, keyframes } from "styled-components";

import { theme } from "../../../assets";

const colorTransition = keyframes`
    0% { background-color: ${theme.white}; }
    50% { background-color: ${theme.orangeWarning}; }
    100% { background-color: ${theme.white}; }
`;

const colorTransitionInside = keyframes`
    0% { border-color: ${theme.orangeWarning};
    background-color: ${theme.white};}
    50% { border-color: ${theme.white};
    background-color: ${theme.orangeWarning};}
    100% { border-color: ${theme.orangeWarning};
    background-color: ${theme.white};}
`;

const colorTransitionBar = keyframes`
    0% {background-color: ${theme.white};}
    50% {background-color: ${theme.orangeWarning};}
    100% {background-color: ${theme.white};}
`;

const colorTransitionToggle = keyframes`
    0% {background-color: ${theme.orangeWarning};}
    50% {background-color: ${theme.white};}
    100% {background-color: ${theme.orangeWarning};}
`;

interface IProps {
    withAnimation?: boolean;
}

export const Container = styled.div<IProps>`
    position: fixed;
    bottom: 0;
    right: 0;
    width: fit-content;
    margin-bottom: 56px;
    margin-right: 24px;
    background-color: #fff;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 50%;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    animation: ${(props) =>
        props.withAnimation ? css`2s infinite ${colorTransition}` : ""};
`;

export const ContainerToggle = styled.div<IProps>`
    display: flex;
    align-items: center;
    width: 32px;
    height: 18px;
    border-radius: 24px;
    border: 0.1rem solid #663398;
    padding-left: 0.2rem;
    padding-right: 1.8rem;
    animation: ${(props) =>
        props.withAnimation ? css`2s infinite ${colorTransitionInside}` : ""};
    .react-switch-handle {
        animation: ${(props) =>
            props.withAnimation
                ? css`2s infinite ${colorTransitionToggle}`
                : ""};
    }
    .react-switch-bg {
        animation: ${(props) =>
            props.withAnimation ? css`2s infinite ${colorTransitionBar}` : ""};
    }
`;
