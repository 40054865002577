/* eslint-disable no-nested-ternary */
import { endOfMonth, format, getWeek, parseISO, startOfMonth } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../assets";
import IlustracaoTelaVazia from "../../assets/images/ilustracao-tela-vazia";
import {
    LoadingGray,
    ButtonShowMore,
    BarChart,
    Tag,
    PrimaryButton,
    ModalReview,
    Breadcrumb,
    RefuseReportToggle,
} from "../../components";
import { useFetch } from "../../hooks";
import { TReportProject, TTasks } from "../../models";
import { reportError, timeReports, track } from "../../services";
import {
    dayFromWeek,
    getDateWithoutUTC,
    getDayMonthAndHalfYear,
    minutesToStringDate,
    monthFromYear,
} from "../../utils";
import * as s from "./styled-report-manage-collaborators";

export type TColumnData = {
    dates?: string[];
    times?: number[];
    periods?: string[];
    abreviatedDates?: string[];
};

export type TDoughnutData = {
    labels?: string[];
    times?: number[];
    colors?: string[];
    maxTime?: number;
};

type TProjectsOptions = {
    label: string;
    value: string;
};

export const ReportManageCollaborators = () => {
    const location = useLocation();
    const userName = location.pathname.slice(29);
    const currentReport = JSON.parse(sessionStorage.getItem(userName) || "{}");
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [barChartData, setBarChartData] = useState<TColumnData>();
    const [projects, setProjects] = useState<TReportProject>([]);

    const [isRefuseModalOpen, setRefuseModalOpen] = useState(false);
    const [isToReopen, setIsToReopen] = useState(false);

    const navigate = useNavigate();

    const openReportError = useFetch({
        fn: reportError.createReportError,
        start: false,
        params: {
            reportId: currentReport.id_e,
            messageRefuse: "mensagem teste",
        },
        errorMessage: "Erro ao abrir o registro!",
    });

    const getEntries = useFetch({
        fn: track.getEntries,
        start: true,
        params: {
            startDate: startOfMonth(new Date()).toString(),
            endDate: endOfMonth(new Date()).toString(),
            responseFormat: "summaryReport",
            page: 0,
            reportId: currentReport.id_e,
        },
    });

    const toggleApproved = useFetch({
        fn: timeReports.toggleApproved,
        start: false,
        errorMessage: "Erro ao abrir o registro!",
    });

    const formatChartsData = (entries: {
        response1: { date: string; total_time: number }[];
        response2: TReportProject;
    }) => {
        const tempProjectsOptions: TProjectsOptions[] = [
            { label: "Todos", value: "all" },
        ];
        const barDates: string[] = [];
        const barTimes: number[] = [];
        const barPeriods: string[] = [];
        const barAbreviatedDates: string[] = [];
        const activitiesList: TReportProject = [];

        entries?.response1?.sort((a: { date: string }, b: { date: string }) => {
            return a.date.localeCompare(b.date);
        });

        entries?.response1?.forEach(
            (entry: { date: string; total_time: number }) => {
                if (entry.date.length > 10) {
                    const tempDate = entry.date.split(" - ");
                    barAbreviatedDates.push(
                        `${format(parseISO(tempDate[0]), "dd/MM")} - ${format(
                            parseISO(tempDate[1]),
                            "dd/MM"
                        )}`
                    );
                    const tempPeriod = getWeek(
                        parseISO(tempDate[0])
                    ).toString();
                    barPeriods.push(`Sem. ${tempPeriod}`);
                } else if (entry.date.length < 8) {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "yyyy")
                    );
                    barPeriods.push(monthFromYear(parseISO(entry.date), true));
                } else {
                    barAbreviatedDates.push(
                        format(parseISO(entry.date), "dd/MM")
                    );
                    barPeriods.push(dayFromWeek(parseISO(entry.date), true));
                }
                barDates.push(entry.date);
                barTimes.push(entry.total_time);
            }
        );

        entries?.response2?.forEach(
            (activity: {
                project_name: string;
                project_client: string;
                project_color: string;
                project_id: string;
                project_percent: string;
                project_time: number;
                project_entries: TTasks;
            }) => {
                const tempActivity = { ...activity, open: false };
                activitiesList.push(tempActivity);
                tempProjectsOptions.push({
                    label: activity.project_name,
                    value: activity.project_id,
                });
            }
        );
        if (Object.keys(entries?.response2).length === 0) {
            setProjects([]);
        } else setProjects(entries?.response2);

        setBarChartData({
            dates: barDates,
            times: barTimes,
            periods: barPeriods,
            abreviatedDates: barAbreviatedDates,
        });
    };

    const refactorName = (name: string) => {
        const parts: string[] = name.split("-");
        const upperCaseParts: string[] = parts.map(
            (part: string) =>
                part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        );
        const formatedText: string = upperCaseParts.join(" ");

        return formatedText;
    };

    const openTasks = (index: number) => {
        const tempActivities = projects.map((oldActivity, i) =>
            i === index
                ? {
                      ...oldActivity,
                      open: !oldActivity.open,
                  }
                : oldActivity
        );

        setProjects(tempActivities);
    };

    const totalHours = () => {
        if (getEntries.response) {
            const { entries } = getEntries.response;
            const data = entries.response2;
            let totalTime = 0;
            data.forEach((element: { project_time: number }) => {
                totalTime += element.project_time;
                return totalTime;
            });

            return minutesToStringDate(totalTime);
        }
        return "-";
    };

    useEffect(() => {
        if (openReportError.response) {
            toast.success("Relatório recusado com sucesso!");
            setTimeout(() => {
                navigate("/relatorio/gerenciar/revisar");
            }, 1000);
        }
    }, [openReportError.response]);

    useEffect(() => {
        if (toggleApproved.response) {
            toast.success("Relatório reaberto com sucesso!");
            setTimeout(() => {
                navigate("/relatorio/gerenciar/revisar");
            }, 1000);
        }
    }, [toggleApproved.response]);

    const handleRefuseReport = (message?: string) => {
        if (isToReopen) {
            toggleApproved.onRefresh({
                reportId: currentReport.id_e,
                messageRefuse: message,
            });
            return;
        }
        openReportError.onRefresh({
            messageRefuse: message,
            reportId: currentReport.id_e,
        });
    };

    const openRefuseReportModal = () => {
        setRefuseModalOpen(!isRefuseModalOpen);
    };

    const environment = () => {
        const { locked, reviewed, ReportBilling } = currentReport;

        if (locked && reviewed && !ReportBilling) {
            return (
                <>
                    <RefuseReportToggle
                        onClickRefuseReport={handleRefuseReport}
                        open={isRefuseModalOpen}
                        mode="refuse"
                    />
                    <s.ReviewContainer>
                        <PrimaryButton
                            onClick={() => {
                                setIsToReopen(true);
                                openRefuseReportModal();
                            }}
                            className="header-button"
                        >
                            Reabrir relatório
                        </PrimaryButton>
                    </s.ReviewContainer>
                </>
            );
        }
        if (locked && !reviewed)
            return (
                <>
                    <RefuseReportToggle
                        onClickRefuseReport={handleRefuseReport}
                        open={isRefuseModalOpen}
                        mode="refuse"
                    />
                    <s.ReportAvailable>
                        <Icons.CheckCircle
                            color={theme.white}
                            height="30"
                            width="24"
                            viewBox="3 3 10 10"
                        />
                        Relatório pronto para revisão
                    </s.ReportAvailable>
                    <s.ReviewContainer>
                        <PrimaryButton
                            type="submit"
                            className="header-button"
                            disabled={!getEntries.response}
                            onClick={openRefuseReportModal}
                        >
                            <Icons.Close color={theme.white} />
                            RECUSAR RELATÓRIO
                        </PrimaryButton>
                        <PrimaryButton
                            type="submit"
                            className="header-button"
                            disabled={!getEntries.response}
                            iconSolid
                            onClick={() => setIsOpenModal(true)}
                        >
                            <Icons.CheckCircle
                                color={theme.purple500}
                                height="30"
                                width="24"
                                viewBox="3 3 10 10"
                            />
                            VALIDAR REVISÃO
                        </PrimaryButton>
                    </s.ReviewContainer>
                </>
            );
        if (reviewed) return <></>;
        return (
            <s.EnvirementLock>
                <Icons.HorizontalBarCircle />
                Este relatório ainda está aberto e recebendo novas entradas de
                tempo, caso haja algum erro, entre em contato com o
                profissional.
            </s.EnvirementLock>
        );
    };

    useEffect(() => {
        if (getEntries.response) {
            const { entries, start_date, end_date } = getEntries.response;
            setStartDate(getDateWithoutUTC(start_date));
            setEndDate(getDateWithoutUTC(end_date));

            formatChartsData(entries);
            totalHours();
        }
    }, [getEntries.response]);

    return (
        <s.Container>
            <Breadcrumb
                defaultRoute={"/relatorio/gerenciar/revisar"}
                location={useLocation()}
            />
            {isOpenModal && (
                <ModalReview
                    setOpen={setIsOpenModal}
                    collaborator={currentReport.User.name}
                    period={`${startDate || ""} - ${endDate || ""}`}
                    totalTime={totalHours()}
                    id={currentReport.id_e}
                />
            )}
            {environment()}

            <s.CollaboratorInfo>
                <div className="name">
                    <h1>Nome</h1>
                    {refactorName(userName)}
                </div>
                <div className="otherinfos">
                    <h1>Período</h1>
                    {`${startDate || ""} - ${endDate || ""}`}
                </div>
                <div className="otherinfos">
                    <h1>Total de horas</h1>
                    {totalHours()}
                </div>
            </s.CollaboratorInfo>

            <s.BarChartWrapper>
                {getEntries.loading ? (
                    <LoadingGray />
                ) : projects.length > 0 ? (
                    <>
                        <s.TittleBarChart>Horas por dia</s.TittleBarChart>
                        {getEntries.loading ? (
                            <LoadingGray />
                        ) : (
                            <BarChart
                                dates={barChartData?.dates}
                                times={barChartData?.times}
                                periods={barChartData?.periods}
                                abreviatedDates={barChartData?.abreviatedDates}
                            />
                        )}
                    </>
                ) : (
                    <s.NoContent>
                        <IlustracaoTelaVazia />
                        <h2>Sem registro de dados a serem mostrados</h2>
                        <p>
                            Vá ao cronômetro e faça o registro das horas
                            trabalhadas!
                        </p>
                    </s.NoContent>
                )}
            </s.BarChartWrapper>

            {projects.length > 0 && (
                <div id="section-row">
                    <s.Activities>
                        {getEntries.loading ? (
                            <LoadingGray />
                        ) : (
                            <>
                                <s.TittleTable>
                                    Horas por atividade
                                </s.TittleTable>
                                <s.ActivitiesTable>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="heading-text">
                                                    TÍTULO
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    CLIENTE
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    DATA
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    PORCENTAGEM
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    INÍCIO DA ATIVIDADE
                                                </span>
                                            </th>
                                            <th>
                                                <span className="heading-text">
                                                    FIM DA ATIVIDADE
                                                </span>
                                            </th>

                                            <th>
                                                <span className="heading-text">
                                                    REALIZADO
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    {projects?.map((activity, index) => (
                                        <tbody key={index}>
                                            <tr className="activity">
                                                <td className="entry">
                                                    <ButtonShowMore
                                                        onClick={() => {
                                                            openTasks(index);
                                                        }}
                                                        isOpen={activity.open}
                                                        numberOfItems={
                                                            activity
                                                                .project_entries
                                                                .length
                                                        }
                                                    />

                                                    <Tag
                                                        maxWidth="200px"
                                                        color={
                                                            activity.project_color
                                                        }
                                                    >
                                                        {activity.project_name}
                                                    </Tag>
                                                </td>
                                                <td>
                                                    <span className="time">
                                                        {
                                                            activity.project_client
                                                        }
                                                    </span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span className="percentage">
                                                        {activity.project_percent.replace(
                                                            ".",
                                                            ","
                                                        )}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="percentage"></span>
                                                </td>
                                                <td>
                                                    <span className="percentage"></span>
                                                </td>
                                                <td>
                                                    <span className="time">
                                                        {minutesToStringDate(
                                                            activity.project_time
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>

                                            {activity.open &&
                                                activity.project_entries.map(
                                                    (task) => (
                                                        <tr
                                                            className="activity-instance"
                                                            key={task.id_e}
                                                        >
                                                            <td className="entry">
                                                                <span className="description">
                                                                    {
                                                                        task.description
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td>
                                                                <span className="percentage">
                                                                    {task.start_date
                                                                        ? getDayMonthAndHalfYear(
                                                                              task.start_date
                                                                          )
                                                                        : "Indisponível"}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="percentage">
                                                                    {task.entry_percent.replace(
                                                                        ".",
                                                                        ","
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="percentage">
                                                                    {dayjs(
                                                                        task.start_at
                                                                    )
                                                                        .add(
                                                                            3,
                                                                            "hours"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY [-] HH:mm"
                                                                        )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="percentage">
                                                                    {dayjs(
                                                                        task.end_at
                                                                    )
                                                                        .add(
                                                                            3,
                                                                            "hours"
                                                                        )
                                                                        .format(
                                                                            "DD/MM/YYYY [-] HH:mm"
                                                                        )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="time">
                                                                    {minutesToStringDate(
                                                                        task.entry_time
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    ))}
                                </s.ActivitiesTable>
                            </>
                        )}
                    </s.Activities>
                </div>
            )}
        </s.Container>
    );
};
