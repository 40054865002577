import { Control, UseFormWatch } from "react-hook-form";

import { LoadingGray } from "../../../../../components";
import { IPermission, TReadPermissionsResult } from "../../roles-form";
import { PermissionRow } from "./permission-row/permission-row";
import * as s from "./styled-permissions-group";

type TPermissionsGroupProps = {
    data: TReadPermissionsResult;
    loading: boolean;
    control: Control<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    watch: UseFormWatch<{
        name: string;
        description: string;
        permissions: IPermission[];
    }>;
    view?: boolean;
};

export const PermissionsGroup = ({
    data,
    loading,
    control,
    watch,
    view,
}: TPermissionsGroupProps) => {
    if (loading) {
        return <LoadingGray />;
    }
    const { permissions } = data;

    const permissionsWithIndex = permissions.map((permission, index) => ({
        ...permission,
        index,
    }));
    const totalPermission = permissionsWithIndex.filter(
        ({ classification }) => classification === "total"
    );
    const managementPermissions = permissionsWithIndex.filter(
        ({ classification }) => classification === "management"
    );
    const financePermissions = permissionsWithIndex.filter(
        ({ classification }) => classification === "finance"
    );
    const permissionsData = watch("permissions") || permissionsWithIndex;
    const manageTeamsProjectsPermission = permissionsData.find(
        (permission) => permission.id === "manage_teams_projects"
    );
    const allPermissions = permissionsData.find(
        (permission) => permission.id === "all_permissions"
    );
    const isEnabled = manageTeamsProjectsPermission?.value === "enabled";

    return (
        <s.Container>
            <s.Content>
                {totalPermission.map((permission) => (
                    <PermissionRow
                        key={permission.index}
                        permission={permission}
                        control={control}
                        view={view}
                    />
                ))}
            </s.Content>
            <s.Content>
                <s.Title>Gestão de times e projetos</s.Title>
                {managementPermissions.map((permission) => (
                    <PermissionRow
                        permissions={permissionsData}
                        key={permission.index}
                        permission={permission}
                        control={control}
                        enabled={
                            isEnabled && allPermissions?.value !== "enabled"
                        }
                        view={view || allPermissions?.value === "enabled"}
                    />
                ))}
            </s.Content>
            <s.Content>
                <s.Title>financeiro</s.Title>
                {financePermissions.map((permission) => (
                    <PermissionRow
                        key={permission.index}
                        permission={permission}
                        control={control}
                        view={view || allPermissions?.value === "enabled"}
                        enabled={allPermissions?.value !== "enabled"}
                    />
                ))}
            </s.Content>
        </s.Container>
    );
};
