import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const statusOptions = [
    {
        label: "A iniciar",
        value: "to_start",
    },
    {
        label: "Em andamento",
        value: "in_progress",
    },
    {
        label: "Atrasado",
        value: "late",
    },
    {
        label: "Pausado",
        value: "paused",
    },
    {
        label: "Cancelado",
        value: "canceled",
    },
    {
        label: "Concluído",
        value: "finished",
    },
    {
        label: "Sem informação",
        value: "no_information",
    },
];

export const handleProjectStatus = ({
    start_at,
    end_at,
    finished,
}: {
    start_at: string;
    end_at: string;
    finished: boolean;
}) => {
    const currentDate = new Date();
    const startDate = start_at ? new Date(start_at) : null;
    const endDate = end_at ? new Date(end_at) : null;

    if (finished) {
        return "Finalizado";
    }
    if (!startDate && !endDate) {
        return "-";
    }
    if (startDate && currentDate < startDate) {
        return "A iniciar";
    }
    if (endDate && currentDate > endDate) {
        return "Atrasado";
    }
    return "Ativo";
};

export const projectName = (project: { name: string }) => {
    return project?.name
        ?.replaceAll(" ", "-")
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .replaceAll("/", "");
};

export const projectPeopleSize = (users: any[]) => {
    if (users.length) {
        return `${users.length} pessoa${users.length > 1 ? "s" : ""}`;
    }

    return "-";
};

export const renderListWithComma = (data: any) => {
    if (data) {
        return data?.map((item: any, index: number) => (
            <>
                {item?.name || "-"}
                {item && index !== data.length - 1 ? ", " : ""}
            </>
        ));
    }
    return "-";
};

export const renderProjectDuration = (start_at: string, end_at: string) => {
    const startDate = start_at ? dayjs.utc(start_at).format("DD/MM/YYYY") : "";
    const endDate = end_at ? dayjs.utc(end_at).format("DD/MM/YYYY") : "";

    if (startDate && endDate) {
        return `${startDate} - ${endDate}`;
    }
    if (startDate) {
        return `${startDate} - `;
    }
    if (endDate) {
        return ` - ${endDate}`;
    }
    return "-";
};

export const renderProjectStatus = (status: string) => {
    const item = statusOptions?.find((item) => status === item.value);
    return item ? item.label : "-";
};

export const verifyProjectStatus = (status: string) => {
    const item = statusOptions?.find((item) => status === item.value);
    return item ? item.label : "Selecione";
};
