import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { FotoSucesso } from "../../assets";
import { FotoMoca } from "../../assets/images";
import { Input, PrimaryButton } from "../../components";
import { useFetch } from "../../hooks";
import { manageUser } from "../../services";
import generateForm, { TFormFields } from "./generate-form";
import * as s from "./styled-password-reset";
import schema from "./validation";

export const PasswordReset = () => {
    const navigate = useNavigate();
    const { emailToken } = useParams<{ emailToken?: string }>();

    const [newPasswordSuccess, setNewPasswordSuccess] = useState(false);
    const showImage = () => (newPasswordSuccess ? FotoSucesso : FotoMoca);

    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<TFormFields>({ resolver: yupResolver(schema()) });

    const resetPassword = useFetch({
        fn: manageUser.resetPassword,
        start: false,
        errorMessage: "Não foi possível resetar sua senha!",
    });

    const expiredPassword = useFetch({
        fn: manageUser.expiredPassword,
        start: true,
        params: emailToken,
        errorMessage: "Link para reset de senha expirado",
    });

    const onSubmit = (data: Record<string, string>) => {
        if (emailToken)
            resetPassword.onRefresh({
                password: data.password,
                password_confirmation: data.confirmPassword,
                emailToken,
            });
    };

    useEffect(() => {
        if (expiredPassword.error) navigate("/link-expirado");
    }, [expiredPassword]);

    useEffect(() => {
        if (resetPassword.response) {
            setNewPasswordSuccess(true);
        }
    }, [resetPassword.response]);

    return (
        <s.Container>
            <s.Img alt={"foto não carregada"} src={showImage()} />

            <s.SectionColumn>
                {newPasswordSuccess && (
                    <s.FlexColumn gap="24px">
                        <h2>Pronto!</h2>

                        <p className="step-description">
                            Sua senha foi redefinida com sucesso. Você já pode
                            acessar a área de login e ser direcionado ao Paper
                            ON.
                        </p>

                        <PrimaryButton onClick={() => navigate("/login")}>
                            FAZER LOGIN
                        </PrimaryButton>
                    </s.FlexColumn>
                )}

                <div
                    style={{
                        display: !newPasswordSuccess ? "block" : "none",
                    }}
                >
                    <s.FlexColumn gap={"16px"}>
                        <h2>Criar nova senha</h2>

                        <p className="step-description">
                            Estamos quase terminando. Crie sua nova senha de
                            acesso. Ela deve ser a mesma nos 2 campos.
                        </p>
                    </s.FlexColumn>

                    <s.Form onSubmit={handleSubmit(onSubmit)}>
                        {generateForm().map((input, index) => (
                            <s.FlexColumn key={index}>
                                <Input
                                    {...register(input.id, {
                                        onBlur: () => trigger(input.id),
                                    })}
                                    maxLength={input.maxLength}
                                    type={input.type}
                                    placeholder={input.placeholder}
                                    error={errors[input.id]?.message}
                                    label={input.label}
                                    icon={input.icon}
                                    mask=""
                                />
                            </s.FlexColumn>
                        ))}

                        <PrimaryButton>REDEFINIR SENHA</PrimaryButton>
                    </s.Form>
                </div>
            </s.SectionColumn>
        </s.Container>
    );
};
