import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 400px;
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 4rem;
    margin-top: 16px;
`;
