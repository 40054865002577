import React, { useState } from "react";

import { Icons } from "../../../assets";
import * as s from "./styled-checkbox";

type TCheckBox = {
    id: string;
    width?: number;
    height?: number;
    strokeWidth?: string;
    label?: React.ReactNode;
    terms?: boolean;
    urlTermos?: string;
    urlPolitica?: string;
    value?: string;
    error?: string;
    name?: string;
    isChecked?: boolean;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    gap?: string;
};

const CheckBox = React.forwardRef<HTMLInputElement, TCheckBox>(
    (
        {
            id,
            width,
            height,
            strokeWidth,
            terms,
            label,
            urlTermos,
            urlPolitica,
            error,
            name,
            isChecked,
            onChange,
            disabled = false,
            gap,
        },
        ref
    ) => {
        const [checked, setChecked] = useState(isChecked || false);

        const check = () => {
            setChecked(!checked);
        };

        return (
            <s.FlexColumn>
                <s.Flex gap={gap}>
                    <s.Box
                        checked={checked}
                        width={width}
                        height={height}
                        error={error}
                        disabled={disabled}
                        id={id}
                    >
                        <input
                            name={name}
                            ref={ref}
                            type="checkbox"
                            onChange={(e) => {
                                if (!disabled) {
                                    onChange?.(e.target.checked);
                                    check();
                                }
                            }}
                        />
                        {checked ? (
                            <Icons.Checked strokeWidth={strokeWidth} />
                        ) : null}
                    </s.Box>
                    {label && <s.Span>{label}</s.Span>}
                    {terms && (
                        <s.Span>
                            <s.Span>Li e aceito os</s.Span>
                            <s.UnderlinedText
                                href={urlTermos || ""}
                                target="_blank"
                            >
                                Termos
                            </s.UnderlinedText>
                            {` e`}
                            <s.UnderlinedText
                                href={urlPolitica || ""}
                                target="_blank"
                            >
                                Política de Privacidade
                            </s.UnderlinedText>
                        </s.Span>
                    )}
                </s.Flex>
                {error && <s.Error>{error || null}</s.Error>}
            </s.FlexColumn>
        );
    }
);

export default CheckBox;
