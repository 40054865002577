type TIlustracaoHome = {
    title?: string;
    className?: string;
};

export default ({ title, className }: TIlustracaoHome) => {
    return (
        <svg
            width="901"
            height="541"
            viewBox="0 0 901 541"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-ilustracao-home"}
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M595.447 0.350612C641.019 4.00868 677.792 34.6719 715.882 59.9562C753.407 84.8642 787.856 111.437 815.896 146.682C851.277 191.153 907.2 234.773 899.793 291.117C892.401 347.35 819.348 366.334 781.472 408.55C744.499 449.759 732.819 518.774 680.171 535.903C627.564 553.019 575.043 512.506 523.468 492.493C476.344 474.207 424.644 462.244 391.653 423.946C358.702 385.695 359.573 331.444 343.505 283.582C323.195 223.085 256.872 162.872 284.731 105.459C312.808 47.5981 400.987 60.1786 461.909 39.5698C506.764 24.3964 548.247 -3.43809 595.447 0.350612Z"
                fill="#F6EDFF"
            />
            <path
                opacity="0.1"
                d="M181.035 177.169L176.789 169.617L294.285 237.927L298.531 244.61L181.035 177.169Z"
                fill="url(#paint0_linear_2535_29656)"
            />
            <g opacity="0.1">
                <path
                    opacity="0.1"
                    d="M306.676 232.024L294.285 237.926L298.531 244.436L303.643 241.919L319.327 233.673L319.5 225.948L319.067 226.035L306.676 232.024Z"
                    fill="url(#paint1_linear_2535_29656)"
                />
                <path
                    opacity="0.1"
                    d="M319.067 226.035L309.449 230.722L319.067 226.035L319.5 225.948L319.327 233.673L340.902 222.303L343.848 209.283L319.067 226.035Z"
                    fill="url(#paint2_linear_2535_29656)"
                />
            </g>
            <path
                opacity="0.0142857"
                d="M177.309 156.163L235.97 122.485C238.57 120.923 242.122 121.096 244.722 122.659L301.303 155.381L305.203 157.638L357.972 188.191C363.517 191.402 364.124 199.735 358.665 202.947L300.004 236.624C297.404 238.186 293.852 238.013 291.252 236.45L234.67 203.728L230.771 201.471L178.002 170.918C172.543 167.793 171.85 159.461 177.309 156.163Z"
                fill="#010202"
            />
            <path
                opacity="0.0285714"
                d="M177.309 156.771L235.537 123.354C237.963 121.878 241.689 122.312 244.115 123.701L300.351 156.25L304.163 158.507L356.499 188.799C361.785 191.837 363.084 200.17 357.799 203.208L299.571 236.625C297.144 238.1 293.419 237.666 290.992 236.278L234.757 203.728L230.945 201.472L178.609 171.179C173.41 168.141 172.11 159.809 177.309 156.771Z"
                fill="#010202"
            />
            <path
                opacity="0.0428571"
                d="M177.222 157.292L235.017 124.135C237.357 122.747 241.169 123.354 243.509 124.656L299.311 156.945L303.123 159.115L355.112 189.234C360.051 192.098 362.044 200.431 357.105 203.295L299.311 236.452C296.971 237.84 293.159 237.233 290.819 235.931L235.017 203.642L231.205 201.472L179.215 171.353C174.276 168.576 172.283 160.243 177.222 157.292Z"
                fill="#010202"
            />
            <path
                opacity="0.0571429"
                d="M177.222 157.899L234.584 124.915C236.75 123.613 240.822 124.395 242.989 125.61L298.357 157.638L302.17 159.808L353.812 189.667C358.491 192.357 361.091 200.603 356.412 203.381L299.05 236.364C296.884 237.666 292.812 236.885 290.645 235.669L235.277 203.641L231.464 201.471L179.822 171.613C175.143 168.922 172.543 160.676 177.222 157.899Z"
                fill="#010202"
            />
            <path
                opacity="0.0714286"
                d="M177.136 158.507L234.064 125.785C236.144 124.569 240.303 125.524 242.382 126.653L297.404 158.507L301.13 160.677L352.34 190.362C356.759 192.879 359.965 201.125 355.546 203.729L298.617 236.452C296.538 237.667 292.379 236.712 290.299 235.584L235.277 203.729L231.551 201.559L180.342 171.874C176.009 169.27 172.803 161.024 177.136 158.507Z"
                fill="#010202"
            />
            <path
                opacity="0.0857143"
                d="M177.136 159.027L233.631 126.565C235.537 125.437 239.87 126.565 241.863 127.693L296.452 159.288L300.177 161.458L351.04 190.882C355.199 193.312 359.012 201.471 354.94 203.902L298.444 236.364C296.538 237.492 292.206 236.364 290.213 235.236L235.624 203.641L231.898 201.471L181.035 172.047C176.876 169.703 172.977 161.458 177.136 159.027Z"
                fill="#010202"
            />
            <path
                opacity="0.1"
                d="M177.136 159.636L233.198 127.434C235.017 126.392 239.523 127.694 241.343 128.736L295.585 160.07L299.311 162.24L349.827 191.49C353.726 193.747 358.232 201.906 354.333 204.163L298.271 236.365C296.451 237.406 291.946 236.105 290.126 235.063L235.884 203.729L232.158 201.559L181.642 172.308C177.742 170.051 173.237 161.892 177.136 159.636Z"
                fill="#010202"
            />
            <path
                opacity="0.1143"
                d="M177.049 160.156L232.678 128.214C234.324 127.26 239.003 128.735 240.736 129.69L294.545 160.85L298.185 162.933L348.354 191.924C351.907 194.007 357.106 202.166 353.553 204.249L297.925 236.191C296.278 237.145 291.599 235.67 289.866 234.715L236.057 203.555L232.418 201.472L182.248 172.481C178.609 170.398 173.497 162.239 177.049 160.156Z"
                fill="#010202"
            />
            <path
                opacity="0.1286"
                d="M177.049 160.763L232.245 129.082C233.804 128.128 238.657 129.863 240.216 130.731L293.592 161.631L297.231 163.715L346.968 192.531C350.26 194.441 356.066 202.6 352.773 204.51L297.578 236.191C296.018 237.146 291.166 235.41 289.606 234.542L236.231 203.642L232.591 201.558L182.855 172.742C179.475 170.832 173.757 162.673 177.049 160.763Z"
                fill="#010202"
            />
            <path
                opacity="0.1429"
                d="M176.962 161.285L231.724 129.864C233.111 128.996 238.136 130.906 239.609 131.687L292.638 162.326L296.278 164.41L345.668 192.966C348.7 194.702 355.112 202.774 352.08 204.597L297.317 236.018C295.931 236.886 290.905 234.976 289.432 234.195L236.403 203.555L232.764 201.472L183.374 172.916C180.341 171.18 173.929 163.108 176.962 161.285Z"
                fill="#010202"
            />
            <path
                opacity="0.1571"
                d="M176.963 161.892L231.292 130.731C232.591 129.95 237.704 132.033 239.003 132.728L291.599 163.194L295.152 165.277L344.195 193.66C346.968 195.222 353.986 203.294 351.3 204.943L296.971 236.104C295.672 236.885 290.559 234.802 289.26 234.021L236.664 203.555L233.111 201.471L184.068 173.089C181.209 171.526 174.19 163.454 176.963 161.892Z"
                fill="#010202"
            />
            <path
                opacity="0.1714"
                d="M176.876 162.413L230.771 131.426C231.898 130.732 237.27 132.988 238.396 133.683L290.559 163.888L294.111 165.972L342.721 194.094C345.234 195.57 352.859 203.555 350.433 205.03L296.538 236.017C295.411 236.712 290.039 234.455 288.912 233.76L236.75 203.555L233.197 201.472L184.587 173.349C182.161 171.961 174.449 163.888 176.876 162.413Z"
                fill="#010202"
            />
            <path
                opacity="0.1857"
                d="M176.876 163.02L230.338 132.294C231.378 131.686 236.837 134.117 237.877 134.724L289.692 164.67L293.245 166.753L341.508 194.702C343.675 196.003 352.08 203.989 349.827 205.291L296.364 236.017C295.325 236.625 289.866 234.194 288.826 233.587L237.01 203.642L233.457 201.559L185.194 173.61C183.028 172.308 174.623 164.322 176.876 163.02Z"
                fill="#010202"
            />
            <path
                opacity="0.2"
                d="M176.79 163.541L229.732 133.075C230.598 132.555 236.317 135.158 237.184 135.679L288.567 165.451L292.119 167.447L340.036 195.136C341.942 196.264 350.954 204.249 349.047 205.378L296.105 235.844C295.238 236.365 289.52 233.761 288.653 233.24L237.27 203.468L233.718 201.472L185.801 173.783C183.895 172.655 174.883 164.67 176.79 163.541Z"
                fill="#010202"
            />
            <path
                opacity="0.2143"
                d="M176.789 164.149L229.299 133.943C230.079 133.509 235.884 136.287 236.664 136.721L287.7 166.232L291.166 168.228L338.65 195.743C340.296 196.698 349.914 204.683 348.268 205.638L295.758 235.844C294.978 236.278 289.173 233.5 288.393 233.066L237.357 203.555L233.891 201.559L186.408 174.044C184.761 173.089 175.143 165.104 176.789 164.149Z"
                fill="#010202"
            />
            <path
                opacity="0.2286"
                d="M176.703 164.669L228.779 134.724C229.385 134.376 235.364 137.328 236.057 137.675L286.747 167.012L290.213 169.009L337.35 196.263C338.736 197.044 348.961 204.943 347.574 205.811L295.498 235.756C294.892 236.103 288.913 233.152 288.22 232.805L237.617 203.467L234.151 201.471L187.014 174.217C185.628 173.435 175.403 165.537 176.703 164.669Z"
                fill="#010202"
            />
            <path
                opacity="0.2429"
                d="M176.703 165.276L228.345 135.592C228.865 135.244 235.017 138.456 235.537 138.803L285.707 167.88L289.173 169.877L335.963 196.958C337.09 197.565 347.921 205.464 346.881 206.158L295.238 235.843C294.718 236.19 288.566 232.979 288.046 232.632L237.79 203.467L234.324 201.471L187.534 174.39C186.494 173.783 175.576 165.884 176.703 165.276Z"
                fill="#010202"
            />
            <path
                opacity="0.2571"
                d="M176.702 165.798L227.912 136.374C228.258 136.113 234.584 139.498 235.017 139.759L284.84 168.576L288.219 170.572L334.576 197.392C335.443 197.913 346.967 205.725 346.101 206.246L294.891 235.67C294.545 235.931 288.219 232.546 287.786 232.285L237.963 203.468L234.584 201.472L188.227 174.651C187.36 174.217 175.836 166.319 176.702 165.798Z"
                fill="#010202"
            />
            <path
                opacity="0.2714"
                d="M176.616 166.405L227.392 137.241C227.652 137.067 234.151 140.626 234.411 140.799L283.801 169.356L287.18 171.352L333.19 197.999C333.71 198.346 345.928 206.158 345.321 206.505L294.545 235.582C294.285 235.756 287.786 232.197 287.526 232.024L238.223 203.467L234.844 201.471L188.833 174.824C188.227 174.564 176.096 166.752 176.616 166.405Z"
                fill="#010202"
            />
            <path
                opacity="0.2857"
                d="M176.616 167.013L226.959 138.023C227.046 137.936 233.718 141.668 233.804 141.755L282.848 170.138L286.227 172.048L331.891 198.434C332.151 198.608 344.975 206.42 344.715 206.593L294.372 235.584C294.285 235.67 287.613 231.938 287.527 231.851L238.397 203.468L235.018 201.559L189.354 175.172C189.094 174.912 176.27 167.1 176.616 167.013Z"
                fill="#010202"
            />
            <path
                opacity="0.3"
                d="M293.938 235.496L235.277 201.472L176.529 167.534L226.439 138.804L285.187 172.828L343.848 206.766L293.938 235.496Z"
                fill="#010202"
            />
            <path
                d="M201.657 46.0166L176.789 167.099L294.285 235.496L319.067 113.458L201.657 46.0166Z"
                fill="url(#paint3_linear_2535_29656)"
            />
            <path
                opacity="0.2"
                d="M294.372 224.212L315.427 115.108L203.91 51.1377L182.768 160.069L294.372 224.212Z"
                fill="#010202"
            />
            <path
                d="M293.505 222.824L315.427 115.108L203.91 51.1377L181.901 158.68L293.505 222.824Z"
                fill="#F1F2F2"
            />
            <path
                opacity="0.2"
                d="M291.686 224.039L315.427 115.108L203.91 51.1377L180.082 159.895L291.686 224.039Z"
                fill="#010202"
            />
            <path
                d="M291.165 222.303L315.427 115.108L203.91 51.1377L179.562 158.246L291.165 222.303Z"
                fill="#FAFBFB"
            />
            <path
                d="M200.184 97.3154L242.729 121.706L245.761 108.512L203.477 84.209L200.184 97.3154Z"
                fill="#E6E7E9"
            />
            <path
                d="M259.279 191.316L287.786 207.461L290.819 194.268L262.571 178.21L259.279 191.316Z"
                fill="#E6E7E9"
            />
            <path
                d="M265.258 164.67L279.208 172.395L282.241 159.115L268.55 151.563L265.258 164.67Z"
                fill="#82368C"
            />
            <path
                d="M288.306 208.763L187.534 150.869L187.62 150.348L202.611 82.9062L303.296 140.714L288.306 208.763ZM189.18 150.175L287.266 206.506L301.563 141.495L203.65 85.2498L189.18 150.175Z"
                fill="#D2D4D8"
            />
            <path
                d="M303.162 141.225L301.724 140.908L287.113 207.457L288.552 207.774L303.162 141.225Z"
                fill="#D2D4D8"
            />
            <path
                d="M288.985 133.062L287.547 132.742L272.807 199.257L274.245 199.576L288.985 133.062Z"
                fill="#D2D4D8"
            />
            <path
                d="M274.769 124.909L273.331 124.589L258.591 191.103L260.029 191.423L274.769 124.909Z"
                fill="#D2D4D8"
            />
            <path
                d="M260.567 116.74L259.129 116.42L244.389 182.934L245.827 183.254L260.567 116.74Z"
                fill="#D2D4D8"
            />
            <path
                d="M246.437 108.606L244.999 108.286L230.259 174.801L231.697 175.12L246.437 108.606Z"
                fill="#D2D4D8"
            />
            <path
                d="M232.228 100.445L230.79 100.125L216.05 166.64L217.488 166.959L232.228 100.445Z"
                fill="#D2D4D8"
            />
            <path
                d="M218.006 92.3608L216.568 92.041L201.828 158.556L203.266 158.875L218.006 92.3608Z"
                fill="#D2D4D8"
            />
            <path
                d="M203.818 84.2097L202.38 83.8896L187.626 150.404L189.064 150.724L203.818 84.2097Z"
                fill="#D2D4D8"
            />
            <path
                d="M288.177 206.971L188.73 149.853L187.997 151.133L287.444 208.251L288.177 206.971Z"
                fill="#D2D4D8"
            />
            <path
                d="M291.116 193.687L191.669 136.569L190.936 137.849L290.383 194.967L291.116 193.687Z"
                fill="#D2D4D8"
            />
            <path
                d="M293.975 180.362L194.603 123.287L193.87 124.567L293.242 181.642L293.975 180.362Z"
                fill="#D2D4D8"
            />
            <path
                d="M296.957 167.003L197.585 109.928L196.852 111.208L296.224 168.283L296.957 167.003Z"
                fill="#D2D4D8"
            />
            <path
                d="M299.887 153.723L200.515 96.6484L199.783 97.9285L299.154 155.003L299.887 153.723Z"
                fill="#D2D4D8"
            />
            <path
                d="M302.825 140.44L203.528 83.4082L202.795 84.6883L302.092 141.72L302.825 140.44Z"
                fill="#D2D4D8"
            />
            <path
                d="M319.067 113.459L294.285 235.497L319.067 213.971L343.848 206.767L319.067 113.459Z"
                fill="url(#paint4_linear_2535_29656)"
            />
            <path
                d="M343.502 206.853L343.848 206.767L319.067 113.459L304.596 184.546L343.502 206.853Z"
                fill="url(#paint5_linear_2535_29656)"
            />
            <path
                d="M319.5 213.797L300.87 203.121L294.285 235.497L319.067 213.971L319.5 213.797Z"
                fill="url(#paint6_linear_2535_29656)"
            />
            <path
                opacity="0.5"
                d="M319.067 113.459L294.285 235.41L319.153 213.884L343.935 206.68L319.067 113.459ZM318.807 213.363L295.325 233.674L319.153 116.41L343.068 206.333L318.807 213.363Z"
                fill="white"
            />
            <path
                d="M309.189 117.278L302.863 113.633L301.997 117.886L308.322 121.531L309.189 117.278Z"
                fill="url(#paint7_linear_2535_29656)"
            />
            <path
                d="M293.419 108.165L287.093 104.52L286.14 108.773L292.552 112.418L293.419 108.165Z"
                fill="url(#paint8_linear_2535_29656)"
            />
            <path
                d="M277.562 99.0508L271.237 95.4053L270.37 99.6584L276.696 103.304L277.562 99.0508Z"
                fill="url(#paint9_linear_2535_29656)"
            />
            <path
                d="M261.705 89.9365L255.38 86.291L254.513 90.6309L260.839 94.2764L261.705 89.9365Z"
                fill="url(#paint10_linear_2535_29656)"
            />
            <path
                d="M245.849 80.8233L239.523 77.2646L238.657 81.5177L244.982 85.1632L245.849 80.8233Z"
                fill="url(#paint11_linear_2535_29656)"
            />
            <path
                d="M230.078 71.7959L223.666 68.1504L222.8 72.4035L229.212 76.049L230.078 71.7959Z"
                fill="url(#paint12_linear_2535_29656)"
            />
            <path
                d="M214.222 62.6826L207.896 59.0371L207.03 63.2902L213.355 66.9357L214.222 62.6826Z"
                fill="url(#paint13_linear_2535_29656)"
            />
            <path
                d="M317.507 100.265C315.774 99.2237 313.521 99.3973 311.268 100.786C307.023 103.216 303.73 108.945 303.73 113.806C303.73 115.976 304.337 117.712 305.636 118.84C306.416 119.621 307.456 119.968 308.669 120.055L309.189 117.364C308.496 117.451 307.889 117.278 307.456 116.844C306.849 116.236 306.503 115.194 306.503 113.806C306.503 109.9 309.362 105.039 312.741 103.043C314.128 102.262 315.428 102.088 316.294 102.522C317.161 103.043 317.594 104.171 317.594 105.734C317.594 107.556 316.987 109.553 315.947 111.549L318.287 112.851C319.587 110.507 320.28 107.99 320.28 105.734C320.193 103.216 319.24 101.22 317.507 100.265Z"
                fill="url(#paint14_linear_2535_29656)"
            />
            <path
                d="M301.651 91.152C299.918 90.1104 297.665 90.284 295.412 91.6728C291.166 94.1031 287.874 99.8318 287.874 104.692C287.874 106.862 288.48 108.598 289.78 109.727C290.56 110.508 291.599 110.855 292.813 110.942L293.332 108.251C292.639 108.338 292.033 108.164 291.599 107.73C290.993 107.123 290.646 106.081 290.646 104.692C290.646 100.787 293.506 95.9259 296.885 93.9295C298.271 93.1483 299.571 92.9747 300.438 93.4087C301.304 93.9295 301.737 95.0579 301.737 96.6202C301.737 98.443 301.131 100.439 300.091 102.436L302.431 103.738C303.73 101.394 304.424 98.877 304.424 96.6202C304.337 94.1031 303.384 92.1068 301.651 91.152Z"
                fill="url(#paint15_linear_2535_29656)"
            />
            <path
                d="M285.794 82.0377C284.061 80.9962 281.808 81.1698 279.555 82.5585C275.309 84.9889 272.017 90.7175 272.017 95.5782C272.017 97.7481 272.623 99.4841 273.923 100.612C274.703 101.394 275.743 101.741 276.956 101.828L277.475 99.1369C276.782 99.2237 276.176 99.0501 275.742 98.6161C275.136 98.0085 274.789 96.967 274.789 95.5782C274.789 91.6723 277.649 86.8116 281.028 84.8153C282.414 84.0341 283.714 83.8605 284.581 84.2945C285.447 84.8153 285.88 85.9436 285.88 87.506C285.88 89.3287 285.274 91.3251 284.234 93.3215L286.574 94.6234C287.873 92.2799 288.567 89.7627 288.567 87.506C288.48 84.9889 287.527 82.9925 285.794 82.0377Z"
                fill="url(#paint16_linear_2535_29656)"
            />
            <path
                d="M270.024 72.9245C268.291 71.8829 266.038 72.0565 263.785 73.4452C259.539 75.8756 256.247 81.6042 256.247 86.4649C256.247 88.6348 256.853 90.3708 258.153 91.4992C258.933 92.2803 259.972 92.6275 261.186 92.7143L261.705 90.0236C261.012 90.1104 260.406 89.9368 259.972 89.5028C259.366 88.8952 259.019 87.8537 259.019 86.4649C259.019 82.559 261.879 77.6983 265.258 75.702C266.644 74.9208 267.944 74.7472 268.811 75.1812C269.677 75.702 270.11 76.8304 270.11 78.3927C270.11 80.2155 269.504 82.2118 268.464 84.2082L270.804 85.5101C272.103 83.1666 272.796 80.6494 272.796 78.3927C272.623 75.8756 271.67 73.966 270.024 72.9245Z"
                fill="url(#paint17_linear_2535_29656)"
            />
            <path
                d="M254.167 63.8102C252.434 62.7686 250.181 62.9422 247.928 64.331C243.682 66.7613 240.39 72.49 240.39 77.3507C240.39 79.5206 240.996 81.2566 242.296 82.3849C243.076 83.1661 244.116 83.5133 245.329 83.6001L245.849 80.9094C245.155 80.9962 244.549 80.8226 244.116 80.3886C243.509 79.781 243.162 78.7394 243.162 77.3507C243.162 73.4448 246.022 68.5841 249.401 66.5877C250.788 65.8066 252.087 65.633 252.954 66.0669C253.82 66.5877 254.254 67.7161 254.254 69.2785C254.254 71.1012 253.647 73.0976 252.607 75.0939L254.947 76.3959C256.246 74.0523 256.94 71.5352 256.94 69.2785C256.853 66.8481 255.9 64.8518 254.167 63.8102Z"
                fill="url(#paint18_linear_2535_29656)"
            />
            <path
                d="M238.31 54.6969C236.577 53.6554 234.325 53.8289 232.072 55.2177C227.826 57.648 224.533 63.3767 224.533 68.2374C224.533 70.4073 225.14 72.1433 226.439 73.2716C227.219 74.0528 228.259 74.4 229.472 74.4868L229.992 71.7961C229.299 71.8829 228.692 71.7093 228.259 71.2753C227.653 70.6677 227.306 69.6261 227.306 68.2374C227.306 64.3315 230.165 59.4708 233.545 57.4745C234.931 56.6933 236.231 56.5197 237.097 56.9537C237.964 57.4744 238.397 58.6028 238.397 60.1652C238.397 61.9879 237.79 63.9843 236.751 65.9806L239.09 67.2826C240.39 64.9391 241.083 62.4219 241.083 60.1652C240.996 57.7348 240.043 55.7385 238.31 54.6969Z"
                fill="url(#paint19_linear_2535_29656)"
            />
            <path
                d="M222.453 45.6696C220.72 44.628 218.468 44.8016 216.215 46.1904C211.969 48.6207 208.676 54.3494 208.676 59.21C208.676 61.38 209.283 63.1159 210.583 64.2443C211.362 65.0255 212.402 65.3727 213.615 65.4595L214.135 62.7687C213.442 62.8555 212.835 62.6819 212.402 62.2479C211.796 61.6404 211.449 60.5988 211.449 59.21C211.449 55.3041 214.308 50.4435 217.688 48.4471C219.074 47.6659 220.374 47.4923 221.24 47.9263C222.107 48.4471 222.54 49.5755 222.54 51.1378C222.54 52.9606 221.934 54.9569 220.894 56.9533L223.233 58.2552C224.533 55.9117 225.226 53.3946 225.226 51.1378C225.14 48.6207 224.186 46.6244 222.453 45.6696Z"
                fill="url(#paint20_linear_2535_29656)"
            />
            <path
                d="M305.289 114.152C305.289 109.639 308.409 104.258 312.308 102.001C314.474 100.786 316.467 100.612 318.113 101.567C318.2 101.654 318.373 101.74 318.46 101.827C318.113 101.48 317.767 101.133 317.333 100.872C315.687 99.9176 313.694 100.091 311.528 101.306C307.542 103.563 304.509 108.945 304.509 113.458C304.509 115.454 305.116 117.104 306.242 118.145C306.329 118.232 306.502 118.319 306.589 118.406C305.722 117.364 305.289 115.975 305.289 114.152Z"
                fill="url(#paint21_radial_2535_29656)"
            />
            <path
                d="M318.72 106.775C318.72 105.213 318.2 103.997 317.42 103.563C317.334 103.563 317.334 103.563 317.247 103.477C317.68 104.084 317.853 104.952 317.853 105.994C317.853 107.816 317.247 109.813 316.207 111.809L317.16 112.33C318.2 110.42 318.72 108.424 318.72 106.775Z"
                fill="url(#paint22_radial_2535_29656)"
            />
            <path
                d="M289.259 105.126C289.259 100.613 292.379 95.2312 296.278 92.9744C298.444 91.7593 300.437 91.5857 302.083 92.5404C302.17 92.6272 302.343 92.714 302.43 92.8008C302.083 92.4536 301.737 92.1064 301.304 91.8461C299.657 90.8913 297.664 91.0649 295.498 92.28C291.512 94.5368 288.48 99.9183 288.48 104.432C288.48 106.428 289.086 108.077 290.213 109.119C290.299 109.206 290.473 109.292 290.559 109.379C289.779 108.338 289.259 106.862 289.259 105.126Z"
                fill="url(#paint23_radial_2535_29656)"
            />
            <path
                d="M302.777 97.6616C302.777 96.0992 302.257 94.8841 301.477 94.4501C301.39 94.4501 301.39 94.4501 301.303 94.3633C301.737 94.9709 301.91 95.8388 301.91 96.8804C301.91 98.7032 301.303 100.7 300.264 102.696L301.217 103.217C302.257 101.307 302.777 99.3975 302.777 97.6616Z"
                fill="url(#paint24_radial_2535_29656)"
            />
            <path
                d="M273.576 96.0119C273.576 91.4984 276.696 86.1169 280.595 83.8602C282.761 82.645 284.754 82.4714 286.4 83.4262C286.487 83.513 286.66 83.5998 286.747 83.6866C286.4 83.3394 286.054 82.9922 285.621 82.7318C283.974 81.777 281.981 81.9506 279.815 83.1658C275.829 85.4225 272.796 90.804 272.796 95.3175C272.796 97.3138 273.403 98.963 274.529 100.005C274.616 100.091 274.789 100.178 274.876 100.265C274.009 99.2234 273.576 97.7478 273.576 96.0119Z"
                fill="url(#paint25_radial_2535_29656)"
            />
            <path
                d="M287.093 88.5473C287.093 86.985 286.573 85.7698 285.793 85.3358C285.706 85.3358 285.706 85.3358 285.62 85.249C286.053 85.8566 286.226 86.7246 286.226 87.7661C286.226 89.5889 285.62 91.5852 284.58 93.5816L285.533 94.1024C286.486 92.2796 287.093 90.2833 287.093 88.5473Z"
                fill="url(#paint26_radial_2535_29656)"
            />
            <path
                d="M257.719 86.8116C257.719 82.2982 260.838 76.9167 264.737 74.66C266.904 73.4448 268.896 73.2712 270.543 74.226C270.629 74.3128 270.803 74.3996 270.889 74.4864C270.543 74.1392 270.196 73.792 269.763 73.5316C268.117 72.5768 266.124 72.7504 263.957 73.9656C259.972 76.2223 256.939 81.6038 256.939 86.1173C256.939 88.1136 257.546 89.7627 258.672 90.8043C258.759 90.8911 258.932 90.9779 259.019 91.0647C258.152 90.0231 257.719 88.5476 257.719 86.8116Z"
                fill="url(#paint27_radial_2535_29656)"
            />
            <path
                d="M271.149 79.3471C271.149 77.7848 270.63 76.5696 269.85 76.1356C269.763 76.1356 269.763 76.1356 269.676 76.0488C270.11 76.6564 270.283 77.5244 270.283 78.566C270.283 80.3887 269.676 82.3851 268.637 84.3814L269.59 84.9022C270.63 83.0794 271.149 81.0831 271.149 79.3471Z"
                fill="url(#paint28_radial_2535_29656)"
            />
            <path
                d="M241.689 77.6974C241.689 73.1839 244.808 67.8025 248.708 65.5457C250.874 64.3305 252.867 64.157 254.513 65.1117C254.6 65.1985 254.773 65.2853 254.86 65.3721C254.513 65.0249 254.166 64.6777 253.733 64.4173C252.087 63.4626 250.094 63.6362 247.928 64.8513C243.942 67.1081 240.909 72.4896 240.909 77.0031C240.909 78.9994 241.516 80.6486 242.642 81.6901C242.729 81.7769 242.902 81.8637 242.989 81.9505C242.209 80.909 241.689 79.5202 241.689 77.6974Z"
                fill="url(#paint29_radial_2535_29656)"
            />
            <path
                d="M255.206 70.3198C255.206 68.7574 254.687 67.5423 253.907 67.1083C253.82 67.1083 253.82 67.1083 253.734 67.0215C254.167 67.6291 254.34 68.497 254.34 69.5386C254.34 71.3614 253.734 73.3577 252.694 75.3541L253.647 75.8748C254.687 73.9653 255.206 71.969 255.206 70.3198Z"
                fill="url(#paint30_radial_2535_29656)"
            />
            <path
                d="M226.006 68.671C226.006 64.1575 229.125 58.7761 233.024 56.5193C235.191 55.3042 237.184 55.1306 238.83 56.0854C238.917 56.1721 239.09 56.2589 239.177 56.3457C238.83 55.9986 238.483 55.6514 238.05 55.391C236.404 54.4362 234.411 54.6098 232.245 55.825C228.259 58.0817 225.226 63.4632 225.226 67.9766C225.226 69.973 225.833 71.6221 226.959 72.6637C227.046 72.7505 227.219 72.8373 227.306 72.9241C226.439 71.8825 226.006 70.407 226.006 68.671Z"
                fill="url(#paint31_radial_2535_29656)"
            />
            <path
                d="M216.301 85.9435L205.904 79.9544C205.21 79.5204 204.257 79.9544 204.084 80.8224L203.737 82.9924L217.514 90.8909L217.861 89.155C218.034 87.9398 217.428 86.6379 216.301 85.9435Z"
                fill="#BCBEC0"
            />
            <path
                d="M230.425 94.1027L220.027 88.1136C219.334 87.6796 218.381 88.1136 218.207 88.9816L217.861 91.1515L231.638 99.0501L231.985 97.3142C232.158 96.0122 231.552 94.7102 230.425 94.1027Z"
                fill="#BCBEC0"
            />
            <path
                d="M244.636 102.175L234.238 96.1859C233.545 95.7519 232.592 96.1859 232.418 97.0539L232.072 99.2238L245.849 107.122L246.196 105.386C246.369 104.171 245.762 102.869 244.636 102.175Z"
                fill="#BCBEC0"
            />
            <path
                d="M258.759 110.333L248.362 104.344C247.668 103.91 246.715 104.344 246.542 105.212L246.195 107.382L259.972 115.281L260.319 113.545C260.492 112.243 259.886 110.941 258.759 110.333Z"
                fill="#BCBEC0"
            />
            <path
                d="M272.883 118.405L262.485 112.416C261.792 111.982 260.839 112.416 260.665 113.284L260.319 115.454L274.096 123.353L274.443 121.617C274.616 120.402 274.009 119.1 272.883 118.405Z"
                fill="#BCBEC0"
            />
            <path
                d="M287.007 126.565L276.609 120.576C275.915 120.142 274.962 120.576 274.789 121.444L274.442 123.613L288.22 131.512L288.566 129.776C288.74 128.474 288.133 127.172 287.007 126.565Z"
                fill="#BCBEC0"
            />
            <path
                d="M301.217 134.637L290.819 128.648C290.126 128.214 289.173 128.648 288.999 129.516L288.653 131.686L302.43 139.584L302.777 137.848C302.95 136.546 302.344 135.331 301.217 134.637Z"
                fill="#BCBEC0"
            />
            <path
                d="M294.718 92.4534C291.859 91.0647 288.133 92.193 286.226 95.1441C284.407 98.0084 285.187 101.567 288.046 102.956C290.905 104.345 294.631 103.216 296.538 100.265C298.357 97.4009 297.577 93.929 294.718 92.4534Z"
                fill="url(#paint32_radial_2535_29656)"
            />
            <path
                d="M450.249 276.699C434.046 267.324 407.791 267.324 391.588 276.699C375.385 286.073 375.385 301.262 391.588 310.637C407.791 320.011 434.046 320.011 450.249 310.637C466.453 301.262 466.453 286.073 450.249 276.699ZM449.036 309.855C433.526 318.796 408.311 318.796 392.888 309.855C377.378 300.915 377.378 286.333 392.888 277.393C408.398 268.453 433.613 268.453 449.036 277.393C464.546 286.333 464.546 300.915 449.036 309.855Z"
                fill="#802785"
            />
            <path
                d="M360.827 299.787L304.419 332.423C303.986 332.684 303.293 332.857 302.686 332.857H222.016L150.011 374.52C149.058 375.041 147.498 375.041 146.545 374.52C145.592 373.999 145.592 373.045 146.545 372.524L219.243 330.427C219.677 330.167 220.37 329.993 220.976 329.993H301.646L357.362 297.791C358.315 297.27 359.874 297.27 360.827 297.791C361.781 298.312 361.781 299.18 360.827 299.787Z"
                fill="#802785"
            />
            <path
                d="M112.492 442.57L68.3873 468.088C67.4342 468.609 65.8745 468.609 64.9214 468.088C63.9683 467.567 63.9683 466.613 64.9214 466.092L109.026 440.573C109.979 440.052 111.538 440.052 112.492 440.573C113.445 441.094 113.445 442.049 112.492 442.57Z"
                fill="#802785"
            />
            <path
                d="M60.2426 472.863L4.18081 505.325C3.22767 505.846 1.66799 505.846 0.714853 505.325C-0.238284 504.804 -0.238284 503.849 0.714853 503.329L56.7766 470.866C57.7298 470.345 59.2895 470.345 60.2426 470.866C61.1957 471.387 61.1957 472.255 60.2426 472.863Z"
                fill="#802785"
            />
            <path
                d="M124.016 435.973L120.81 437.796C119.857 438.317 118.297 438.317 117.344 437.796C116.391 437.275 116.391 436.32 117.344 435.799L119.857 434.324V388.495C119.857 388.147 120.117 387.8 120.55 387.453L138.313 377.211C139.266 376.69 140.826 376.69 141.779 377.211C142.732 377.732 142.732 378.686 141.779 379.207L124.709 389.015V434.931C124.709 435.279 124.449 435.713 124.016 435.973Z"
                fill="#802785"
            />
            <path
                d="M384.743 339.974L351.816 358.983V405.68C351.816 406.027 351.556 406.374 351.123 406.722L252.43 463.835C251.997 464.095 251.304 464.269 250.697 464.269H171.327L148.018 477.722C147.065 478.243 145.505 478.243 144.552 477.722C143.599 477.201 143.599 476.247 144.552 475.726L168.554 461.838C168.987 461.578 169.68 461.404 170.287 461.404H249.657L346.964 405.159V358.462C346.964 358.115 347.224 357.768 347.657 357.507L384.743 336.068V339.974V339.974Z"
                fill="#802785"
            />
            <path
                d="M138.573 483.105L63.882 526.243C62.9288 526.764 61.3692 526.764 60.416 526.243C59.4629 525.722 59.4629 524.768 60.416 524.247L135.107 481.108C136.061 480.588 137.62 480.588 138.573 481.108C139.526 481.629 139.526 482.584 138.573 483.105Z"
                fill="#802785"
            />
            <path
                d="M88.0569 525.375L63.5353 539.61C62.5822 540.131 61.0225 540.131 60.0693 539.61C59.1162 539.089 59.1162 538.135 60.0693 537.614L84.591 523.379C85.5441 522.858 87.1038 522.858 88.0569 523.379C89.01 523.9 89.01 524.854 88.0569 525.375Z"
                fill="#802785"
            />
            <path
                d="M136.927 497.078L96.2882 520.601C95.3351 521.121 93.7754 521.121 92.8223 520.601C91.8691 520.08 91.8691 519.125 92.8223 518.604L133.461 495.082C134.414 494.561 135.973 494.561 136.927 495.082C137.88 495.603 137.88 496.558 136.927 497.078Z"
                fill="#802785"
            />
            <path
                d="M384.743 349.002V352.995L367.76 362.803V409.5C367.76 409.847 367.5 410.281 367.066 410.542L259.015 473.036C258.582 473.296 257.889 473.47 257.282 473.47H237.44C236.053 473.47 235.014 472.862 235.014 472.081C235.014 471.3 236.14 470.692 237.44 470.692H256.329L362.907 409.066V362.369C362.907 362.022 363.167 361.588 363.601 361.327L384.743 349.002Z"
                fill="#802785"
            />
            <path
                d="M222.709 472.949C222.276 473.209 221.669 473.383 220.976 473.383H177.998L145.245 492.305C144.292 492.826 142.732 492.826 141.779 492.305C140.826 491.784 140.826 490.829 141.779 490.308L175.139 470.953C175.572 470.692 176.266 470.519 176.872 470.519H220.89C222.276 470.519 223.316 471.126 223.316 471.907C223.402 472.341 223.142 472.688 222.709 472.949Z"
                fill="#802785"
            />
            <path
                d="M101.661 491.437L73.7599 507.581C72.8068 508.102 71.2471 508.102 70.294 507.581C69.3408 507.06 69.3408 506.106 70.294 505.585L98.1948 489.44C99.148 488.92 100.708 488.92 101.661 489.44C102.614 489.961 102.614 490.829 101.661 491.437Z"
                fill="#802785"
            />
            <path
                d="M384.743 322.875V326.868L335.959 355.077V401.774C335.959 402.121 335.699 402.468 335.266 402.816L245.931 454.547C245.498 454.807 244.805 454.981 244.198 454.981H205.64C204.253 454.981 203.213 454.373 203.213 453.592C203.213 452.811 204.34 452.204 205.64 452.204H243.158L331.107 401.34V354.643C331.107 354.296 331.367 353.862 331.8 353.601L384.743 322.875Z"
                fill="#802785"
            />
            <path
                d="M190.822 454.548C190.389 454.808 189.782 454.982 189.089 454.982H164.741L110.325 486.402C109.372 486.923 107.813 486.923 106.859 486.402C105.906 485.882 105.906 484.927 106.859 484.406L161.968 452.551C162.401 452.291 163.094 452.117 163.701 452.117H189.089C190.476 452.117 191.515 452.725 191.515 453.506C191.515 453.94 191.255 454.287 190.822 454.548Z"
                fill="#802785"
            />
            <path
                d="M65.5283 512.355L40.9201 526.503C39.9669 527.023 38.4072 527.023 37.4541 526.503C36.501 525.982 36.501 525.027 37.4541 524.506L61.9757 510.271C62.9288 509.751 64.4885 509.751 65.4416 510.271C66.4814 510.879 66.4814 511.747 65.5283 512.355Z"
                fill="#802785"
            />
            <path
                d="M159.455 441.615L71.1602 492.652C70.207 493.173 68.6474 493.173 67.6942 492.652C66.7411 492.131 66.7411 491.176 67.6942 490.656L155.296 439.966V395.786C155.296 395.439 155.556 395.091 155.989 394.744L162.401 391.099C163.355 390.578 164.914 390.578 165.867 391.099C166.821 391.619 166.821 392.574 165.867 393.095L160.235 396.393V440.573C160.149 441.007 159.889 441.355 159.455 441.615Z"
                fill="#802785"
            />
            <path
                d="M59.5495 499.422L39.0137 511.313C38.0606 511.834 36.5009 511.834 35.5477 511.313C34.5946 510.793 34.5946 509.838 35.5477 509.317L56.0835 497.426C57.0367 496.905 58.5963 496.905 59.5495 497.426C60.5026 497.947 60.5026 498.815 59.5495 499.422Z"
                fill="#802785"
            />
            <path
                d="M379.89 310.811L311.871 350.13C311.438 350.391 310.744 350.564 310.138 350.564H239.519L174.099 388.408C173.146 388.929 171.586 388.929 170.633 388.408C169.68 387.887 169.68 386.933 170.633 386.412L236.833 348.134C237.266 347.874 237.959 347.7 238.566 347.7H309.185L374.691 309.769L368.626 306.211L308.145 341.19C307.712 341.451 307.018 341.624 306.412 341.624H231.634L162.402 381.638C161.448 382.159 159.889 382.159 158.936 381.638C157.982 381.117 157.982 380.162 158.936 379.642L228.861 339.194C229.294 338.933 229.988 338.76 230.594 338.76H305.372L366.893 303.173C367.846 302.652 369.406 302.652 370.359 303.173L379.89 308.728C380.843 309.335 380.843 310.29 379.89 310.811Z"
                fill="#802785"
            />
            <path
                d="M122.89 448.559L36.674 498.381C35.7208 498.902 34.1612 498.902 33.208 498.381C32.2549 497.86 32.2549 496.905 33.208 496.384L119.424 446.562C120.377 446.042 121.936 446.042 122.89 446.562C123.843 447.083 123.843 448.038 122.89 448.559Z"
                fill="#802785"
            />
            <path
                d="M26.1896 504.456L14.0587 511.487C13.1056 512.008 11.5459 512.008 10.5928 511.487C9.63965 510.966 9.63965 510.011 10.5928 509.49L22.7236 502.46C23.6768 501.939 25.2364 501.939 26.1896 502.46C27.1427 503.067 27.1427 503.935 26.1896 504.456Z"
                fill="#802785"
            />
            <path
                d="M141.519 437.796L131.121 443.785C130.168 444.305 128.608 444.305 127.655 443.785C126.702 443.264 126.702 442.309 127.655 441.788L137.36 436.233V392.834C137.36 392.487 137.62 392.14 138.053 391.793L150.791 384.415C151.744 383.894 153.303 383.894 154.256 384.415C155.21 384.936 155.21 385.891 154.256 386.411L142.212 393.355V436.754C142.212 437.188 141.952 437.535 141.519 437.796Z"
                fill="#802785"
            />
            <path
                d="M404.152 372.177C408.571 374.781 408.571 378.947 404.152 381.464C399.733 384.068 392.541 384.068 388.035 381.464C383.616 378.86 383.616 374.694 388.035 372.177C392.454 369.573 399.733 369.573 404.152 372.177Z"
                fill="#802785"
            />
            <path
                d="M233.973 214.377C236.746 215.94 236.746 218.544 233.973 220.193C231.201 221.755 226.782 221.755 224.009 220.193C221.236 218.631 221.236 216.027 224.009 214.377C226.782 212.728 231.287 212.728 233.973 214.377Z"
                fill="#802785"
            />
            <g opacity="0.2">
                <path
                    opacity="0.0909091"
                    d="M315.423 188.339L67.1745 331.555C60.9358 335.201 57.3832 340.843 57.4698 347.092C57.4698 353.949 61.8023 360.372 69.3407 364.712L171.586 423.821C171.76 423.908 176.785 426.773 176.959 426.859C183.284 430.331 191.082 432.067 198.707 431.72C204.86 431.72 211.878 429.55 216.297 427.033L463.16 284.685C469.398 281.039 472.951 275.397 472.864 269.148C472.864 262.291 468.532 255.868 460.994 251.528L354.589 189.988C352.336 188.686 349.303 187.298 346.704 186.516C342.458 185.128 337.866 184.346 333.186 184.26C326.428 184.173 320.276 185.562 315.423 188.339Z"
                    fill="#010202"
                />
                <path
                    opacity="0.1818"
                    d="M315.597 188.773L67.3483 331.989C61.3695 335.461 57.9902 340.843 58.0768 346.832C58.1635 353.341 62.236 359.417 69.4278 363.584L172.193 423.04C172.54 423.214 177.219 425.904 177.566 425.991C184.064 429.376 192.036 430.852 199.488 430.244C205.033 430.244 212.832 427.467 216.817 425.123L462.38 283.469C468.359 279.997 471.738 274.616 471.652 268.627C471.565 262.117 467.493 256.041 460.301 251.875L354.503 190.769C352.51 189.641 349.044 187.992 346.704 187.211C342.545 185.822 337.953 184.954 333.274 184.867C326.602 184.693 320.449 186.082 315.597 188.773Z"
                    fill="#010202"
                />
                <path
                    opacity="0.2727"
                    d="M315.857 189.207L67.608 332.337C61.8891 335.635 58.6831 340.756 58.7698 346.398C58.8564 352.474 62.7556 358.289 69.6875 362.282L172.886 421.999C173.406 422.259 177.739 424.776 178.345 424.95C184.93 428.162 193.075 429.55 200.441 428.596C205.38 428.596 213.958 425.124 217.51 423.127L461.687 282.341C467.406 279.043 470.612 273.922 470.438 268.28C470.352 262.204 466.453 256.389 459.521 252.396L354.329 191.551C352.509 190.509 348.697 188.6 346.617 187.906C342.458 186.517 337.952 185.475 333.36 185.388C326.775 185.215 320.536 186.517 315.857 189.207Z"
                    fill="#010202"
                />
                <path
                    opacity="0.3636"
                    d="M316.03 189.641L67.7811 332.77C62.3222 335.895 59.3761 340.756 59.4628 346.05C59.5494 351.779 63.2753 357.247 69.8607 360.98L173.579 420.957C174.273 421.304 178.345 423.561 179.125 423.821C185.883 426.946 194.202 428.074 201.307 426.859C205.639 426.859 214.997 422.78 218.117 420.957L460.907 280.952C466.366 277.827 469.312 272.967 469.225 267.672C469.138 261.943 465.413 256.475 458.827 252.743L354.242 192.332C352.682 191.464 348.437 189.294 346.617 188.686C342.545 187.211 338.039 186.169 333.446 185.995C326.948 185.735 320.709 186.95 316.03 189.641Z"
                    fill="#010202"
                />
                <path
                    opacity="0.4545"
                    d="M316.203 190.076L67.9544 333.205C62.7555 336.243 59.9827 340.756 60.0694 345.791C60.2427 351.172 63.7953 356.293 70.034 359.852L174.273 420.176C175.052 420.61 178.865 422.693 179.818 423.041C186.75 426.079 195.242 426.947 202.173 425.471C205.899 425.471 216.124 420.697 218.723 419.135L460.127 279.911C465.326 276.873 468.099 272.36 468.012 267.326C467.839 261.944 464.286 256.823 458.047 253.264L354.155 193.2C352.856 192.419 348.09 189.989 346.617 189.468C342.545 187.992 338.125 186.864 333.62 186.604C327.121 186.17 320.882 187.385 316.203 190.076Z"
                    fill="#010202"
                />
                <path
                    opacity="0.5455"
                    d="M316.463 190.509L68.1277 333.639C63.1887 336.503 60.5892 340.843 60.7625 345.443C60.9358 350.391 64.3151 355.165 70.2072 358.636L174.879 419.134C175.832 419.742 179.385 421.565 180.511 421.912C187.616 424.863 196.195 425.557 202.953 423.648C206.073 423.648 217.077 418.266 219.243 416.964L459.261 278.522C464.2 275.658 466.799 271.318 466.626 266.718C466.452 261.77 463.073 256.91 457.181 253.524L353.896 193.808C352.769 193.2 347.657 190.509 346.444 189.988C342.458 188.513 338.039 187.211 333.533 186.951C327.208 186.69 321.056 187.905 316.463 190.509Z"
                    fill="#010202"
                />
                <path
                    opacity="0.6364"
                    d="M316.637 190.943L68.3879 334.073C63.7088 336.764 61.2826 340.843 61.4559 345.096C61.6292 349.696 64.8352 354.123 70.4674 357.421L175.659 418.267C176.786 418.961 179.992 420.61 181.378 420.957C188.656 423.822 197.408 424.256 203.993 422.086C206.419 422.086 218.29 416.01 220.023 414.968L458.741 277.307C463.42 274.616 465.846 270.537 465.673 266.284C465.5 261.684 462.294 257.257 456.661 253.959L354.069 194.589C353.203 194.068 347.657 191.117 346.704 190.77C342.718 189.208 338.386 187.906 333.967 187.558C327.381 187.211 321.143 188.34 316.637 190.943Z"
                    fill="#010202"
                />
                <path
                    opacity="0.7273"
                    d="M316.81 191.377L68.5611 334.507C64.142 337.111 61.8892 340.843 62.1491 344.836C62.4091 349.089 65.3551 353.168 70.6407 356.293L176.266 417.399C177.565 418.18 180.511 419.569 181.984 420.089C189.436 422.867 198.274 423.127 204.686 420.61C206.506 420.61 219.157 413.927 220.456 413.146L457.788 276.352C462.207 273.748 464.46 270.016 464.2 266.023C463.94 261.77 460.994 257.691 455.708 254.566L353.722 195.63C353.029 195.283 347.224 191.985 346.444 191.724C342.545 190.162 338.299 188.687 333.793 188.339C327.554 187.732 321.316 188.773 316.81 191.377Z"
                    fill="#010202"
                />
                <path
                    opacity="0.8182"
                    d="M317.07 191.812L68.7344 334.941C64.5752 337.371 62.4956 340.843 62.7556 344.489C63.0155 348.308 65.7883 352.127 70.8139 354.991L176.959 416.357C178.432 417.225 181.031 418.44 182.764 418.961C190.389 421.565 199.401 421.652 205.64 418.874C206.853 418.874 220.283 411.496 221.236 410.976L457.181 274.964C461.34 272.533 463.42 269.061 463.16 265.416C462.9 261.597 460.127 257.778 455.101 254.913L353.809 196.325C353.376 196.065 347.137 192.593 346.617 192.332C342.805 190.77 338.559 189.208 334.14 188.774C327.728 188.253 321.489 189.208 317.07 191.812Z"
                    fill="#010202"
                />
                <path
                    opacity="0.9091"
                    d="M317.243 192.245L68.9941 335.375C65.0949 337.631 63.1886 340.843 63.5352 344.228C63.8818 347.7 66.3946 351.172 71.0737 353.862L177.652 415.489C179.298 416.444 181.638 417.485 183.544 418.093C191.256 420.61 200.527 420.436 206.593 417.398C207.199 417.398 221.496 409.413 221.929 409.153L456.488 273.922C460.387 271.665 462.293 268.453 461.947 265.155C461.6 261.683 459.087 258.211 454.408 255.521L353.636 197.106C353.376 197.019 346.79 193.2 346.53 193.113C342.718 191.464 338.559 189.902 334.14 189.381C327.901 188.773 321.662 189.641 317.243 192.245Z"
                    fill="#010202"
                />
                <path
                    opacity="0.2"
                    d="M207.373 415.749L455.622 272.62C463.073 268.28 462.12 260.815 453.542 255.781L346.444 193.807C337.866 188.86 324.782 188.252 317.33 192.592L69.1678 335.808C61.716 340.148 62.6691 347.613 71.2473 352.647L178.345 414.534C186.924 419.568 199.921 420.089 207.373 415.749Z"
                    fill="#010202"
                />
            </g>
            <path
                d="M172.193 417.572L72.1136 359.678C60.8493 353.168 62.2356 341.711 75.3196 334.159L310.745 197.974C323.742 190.422 343.498 189.641 354.762 196.151L454.842 254.045C466.106 260.555 464.72 272.012 451.636 279.563L216.297 415.749C203.213 423.3 183.544 424.082 172.193 417.572Z"
                fill="url(#paint33_linear_2535_29656)"
            />
            <path
                d="M172.193 415.835L72.1139 357.941C67.0882 354.99 64.5754 351.171 64.4888 347.005C64.4888 346.658 64.4888 338.759 64.4888 338.412C64.922 333.638 68.5613 336.329 75.2332 332.423L310.658 196.237C323.656 188.686 343.411 187.905 354.676 194.415L454.755 252.309C459.781 255.26 462.294 250.052 462.38 254.131C462.38 254.479 462.38 263.853 462.38 264.2C461.947 268.974 458.308 273.834 451.636 277.74L216.298 414.013C203.214 421.564 183.544 422.345 172.193 415.835Z"
                fill="url(#paint34_linear_2535_29656)"
            />
            <path
                d="M172.193 407.244L72.1136 349.35C60.8493 342.84 62.2356 331.383 75.3196 323.831L310.745 187.646C323.742 180.094 343.498 179.313 354.762 185.823L454.842 243.717C466.106 250.227 464.72 261.684 451.636 269.235L216.297 405.421C203.213 412.972 183.544 413.754 172.193 407.244Z"
                fill="url(#paint35_linear_2535_29656)"
            />
            <path
                opacity="0.2"
                d="M462.467 267.412C462.467 266.805 462.38 266.458 462.294 266.197C463.333 271.752 459.694 277.828 451.723 282.428L216.298 418.527C203.3 426.079 183.544 426.86 172.28 420.35L72.1139 362.456C67.2615 359.678 64.7487 355.946 64.4888 351.953C64.4888 353.603 64.4888 359.765 64.4888 360.112C64.5754 364.192 67.0882 368.098 72.1139 371.049L172.193 428.943C183.458 435.453 203.214 434.672 216.211 427.12L451.636 290.935C458.308 287.029 461.947 282.168 462.38 277.394C462.467 277.134 462.467 267.76 462.467 267.412Z"
                fill="url(#paint36_linear_2535_29656)"
            />
            <path
                d="M72.1135 348.221L172.193 406.115C183.457 412.625 203.213 411.843 216.21 404.292L451.635 268.106C458.481 264.114 462.12 259.079 462.38 254.219C462.64 259.427 459 264.895 451.635 269.235L216.297 405.42C203.3 412.972 183.544 413.753 172.28 407.243L72.2002 349.349C66.8279 346.224 64.4018 342.058 64.5751 337.718C64.7484 341.624 67.2612 345.443 72.1135 348.221Z"
                fill="url(#paint37_radial_2535_29656)"
            />
            <path
                d="M77.9192 325.566L313.604 189.293C325.042 182.697 342.371 182.002 352.249 187.731L451.809 245.278C461.687 251.007 460.474 260.988 449.036 267.585L213.351 403.857C201.914 410.454 184.584 411.148 174.706 405.42L75.1465 347.786C65.2685 342.144 66.4816 332.162 77.9192 325.566Z"
                fill="url(#paint38_linear_2535_29656)"
            />
            <path
                d="M82.5116 327.563L317.07 191.898C326.601 186.343 341.072 185.736 349.39 190.596L446.87 246.928C455.188 251.702 454.149 260.121 444.531 265.589L209.972 401.254C200.441 406.809 185.97 407.417 177.652 402.556L80.1721 346.225C71.8538 341.451 72.8936 333.031 82.5116 327.563Z"
                fill="#1F193C"
            />
            <path
                d="M432.139 240.678C427.374 243.456 419.489 243.456 414.636 240.678L360.134 209.17C355.282 206.393 355.282 201.879 360.048 199.102L348.437 192.419C340.638 187.905 327.988 187.905 320.276 192.332L83.2046 329.472C75.4062 333.986 75.4928 341.277 83.2912 345.79L178.518 400.82C186.317 405.333 198.967 405.333 206.679 400.907L443.837 263.766C451.635 259.253 451.549 251.962 443.75 247.448L432.139 240.678Z"
                fill="#3A235A"
            />
            <path
                d="M192.642 402.296C197.581 402.296 202.26 401.167 205.726 399.171L442.798 262.031C445.917 260.208 447.65 257.951 447.65 255.608C447.65 253.264 445.917 250.921 442.711 249.098L432.053 242.935C429.627 244.064 426.594 244.758 423.388 244.758C419.749 244.758 416.283 243.89 413.597 242.414L359.095 210.907C356.149 209.171 354.502 206.827 354.502 204.137C354.502 202.401 355.196 200.752 356.582 199.363L347.57 194.068C344.105 192.072 339.339 190.943 334.4 190.943C329.461 190.943 324.782 192.072 321.316 194.068L84.158 331.122C81.0386 332.944 79.3057 335.201 79.3057 337.545C79.3057 339.888 81.0387 342.232 84.2447 344.055L179.472 399.084C182.938 401.167 187.617 402.296 192.642 402.296Z"
                fill="url(#paint39_linear_2535_29656)"
            />
            <path
                d="M407.272 302.478L418.189 296.142C419.576 295.361 420.616 293.278 420.616 291.455C420.616 289.632 419.489 288.764 418.189 289.545L407.272 295.882C405.885 296.663 404.846 298.746 404.846 300.569C404.759 302.478 405.885 303.346 407.272 302.478Z"
                fill="url(#paint40_linear_2535_29656)"
            />
            <path
                d="M407.272 302.478L418.189 296.142C419.576 295.361 420.616 293.278 420.616 291.455C420.616 289.632 419.489 288.764 418.189 289.545L407.272 295.882C405.885 296.663 404.846 298.746 404.846 300.569C404.759 302.478 405.885 303.346 407.272 302.478Z"
                fill="url(#paint41_linear_2535_29656)"
            />
            <path
                d="M406.232 302.131C406.405 302.131 406.665 302.044 406.925 301.957L417.843 295.621C418.969 294.927 420.009 293.017 420.009 291.455C420.009 290.761 419.749 289.893 419.142 289.893C418.969 289.893 418.709 289.979 418.449 290.066L407.531 296.402C406.405 297.097 405.365 299.006 405.365 300.569C405.452 301.35 405.625 302.131 406.232 302.131Z"
                fill="url(#paint42_linear_2535_29656)"
            />
            <path
                d="M387.775 313.761L398.693 307.425C400.079 306.644 401.119 304.561 401.119 302.738C401.119 300.915 399.993 300.047 398.693 300.829L387.775 307.165C386.389 307.946 385.349 310.029 385.349 311.852C385.262 313.761 386.389 314.543 387.775 313.761Z"
                fill="url(#paint43_linear_2535_29656)"
            />
            <path
                d="M387.775 313.761L398.693 307.425C400.079 306.644 401.119 304.561 401.119 302.738C401.119 300.915 399.993 300.047 398.693 300.829L387.775 307.165C386.389 307.946 385.349 310.029 385.349 311.852C385.262 313.761 386.389 314.543 387.775 313.761Z"
                fill="url(#paint44_linear_2535_29656)"
            />
            <path
                d="M386.822 313.415C386.996 313.415 387.256 313.328 387.516 313.242L398.433 306.905C399.56 306.211 400.6 304.302 400.6 302.739C400.6 302.045 400.34 301.177 399.733 301.177C399.56 301.177 399.3 301.264 399.04 301.35L388.122 307.687C386.996 308.381 385.956 310.291 385.956 311.853C385.956 312.634 386.129 313.415 386.822 313.415Z"
                fill="url(#paint45_linear_2535_29656)"
            />
            <path
                d="M292.635 361.414L451.722 269.061C458.394 265.155 462.034 260.295 462.467 255.521C462.467 255.347 462.467 253.438 462.467 251.354C462.467 251.354 462.467 261.163 462.467 261.51C462.034 266.284 458.394 271.144 451.722 275.05L292.635 361.414Z"
                fill="#3A235A"
            />
            <path
                d="M113.012 380.943L130.949 391.272C130.949 387.974 128.956 384.154 126.529 382.766L117.518 377.558C115.005 376.082 113.012 377.645 113.012 380.943Z"
                fill="#010202"
            />
            <path
                d="M233.714 365.753L149.837 317.407L318.456 217.242L402.332 265.588L233.714 365.753Z"
                fill="#E6E7E9"
            />
            <path
                d="M406.925 218.284L323.569 170.546C318.803 167.247 312.911 166.64 305.632 170.893C293.068 178.184 282.844 195.717 282.844 210.212C282.844 218.024 285.79 223.319 290.556 225.402L373.305 273.227C377.464 276.092 383.443 275.918 390.115 272.012C402.679 264.721 412.904 247.188 412.904 232.693C412.99 225.749 410.737 220.802 406.925 218.284Z"
                fill="url(#paint46_linear_2535_29656)"
            />
            <path
                d="M288.909 189.121C285.183 195.891 282.93 203.443 282.93 210.213C282.93 218.025 285.876 223.319 290.642 225.403L373.392 273.228C369.666 270.711 367.5 265.85 367.5 259.08C367.5 252.31 369.752 244.758 373.478 237.988L288.909 189.121Z"
                fill="white"
            />
            <path
                d="M288.909 189.121C285.183 195.891 282.93 203.443 282.93 210.213C282.93 218.025 285.876 223.319 290.642 225.403L373.392 273.228C369.666 270.711 367.5 265.85 367.5 259.08C367.5 252.31 369.752 244.758 373.478 237.988L288.909 189.121Z"
                fill="url(#paint47_linear_2535_29656)"
            />
            <path
                d="M405.798 217.677L323.568 170.546C318.803 167.247 312.911 166.64 305.632 170.893C303.813 171.935 302.08 173.15 300.433 174.625C271.666 196.498 260.228 249.618 236.4 262.899C236.4 262.899 284.23 290.587 303.206 301.523H303.119C303.119 305.777 304.246 309.075 306.152 311.158C309.358 314.717 314.73 315.151 320.882 311.679C347.57 296.836 362.82 229.481 395.314 217.503L395.227 217.417C399.3 216.115 402.939 216.201 405.798 217.677Z"
                fill="url(#paint48_linear_2535_29656)"
            />
            <path
                d="M306.238 306.471C306.238 302.304 304.765 299.266 302.512 297.791L219.763 250.312C216.817 248.316 213.264 247.969 208.758 250.486L222.709 258.558C222.709 262.291 224.182 264.721 226.435 265.676L308.924 313.328L308.751 313.241C307.105 311.939 306.238 309.682 306.238 306.471Z"
                fill="url(#paint49_linear_2535_29656)"
            />
            <path
                d="M301.819 297.357L219.763 250.226C216.817 248.229 213.264 247.882 208.759 250.399C207.632 251.007 206.592 251.788 205.553 252.656C187.876 266.11 170.893 304.735 156.25 312.893C156.25 312.893 217.164 348.047 228.861 354.817H228.775C228.775 357.421 229.468 359.417 230.681 360.806C232.674 362.976 235.966 363.323 239.779 361.066C256.156 351.952 275.392 304.561 295.407 297.183C297.747 296.402 300 296.489 301.819 297.357Z"
                fill="url(#paint50_linear_2535_29656)"
            />
            <path
                d="M228.775 354.904C228.775 352.647 229.294 350.303 230.161 347.96L148.278 300.829L146.632 299.874C145.765 302.218 145.245 304.561 145.245 306.731C145.245 310.55 147.325 313.154 149.231 314.109L231.721 361.761L231.547 361.587C229.814 360.372 228.775 358.115 228.775 354.904Z"
                fill="white"
            />
            <path
                d="M315.337 173.584C337.779 186.343 360.221 199.016 382.663 211.775C383.357 212.122 385.523 211.167 385.003 210.907C362.561 198.148 340.119 185.475 317.677 172.716C316.983 172.282 314.904 173.323 315.337 173.584Z"
                fill="#EDEDED"
            />
            <path
                d="M212.571 256.388C226.349 264.113 240.039 271.925 253.816 279.65C254.509 279.997 256.676 279.043 256.156 278.782C242.379 271.057 228.688 263.245 214.911 255.521C214.304 255.087 212.138 256.128 212.571 256.388Z"
                fill="#EDEDED"
            />
            <path
                d="M208.412 260.641C230.854 273.4 253.297 286.073 275.739 298.832C276.432 299.179 278.598 298.225 278.078 297.964C255.636 285.205 233.194 272.532 210.752 259.773C210.059 259.339 207.979 260.294 208.412 260.641Z"
                fill="#EDEDED"
            />
            <path
                d="M200.354 270.71C207.199 274.616 214.044 278.522 220.976 282.427C221.669 282.775 223.836 281.82 223.316 281.559C216.47 277.654 209.625 273.748 202.693 269.842C202 269.408 199.834 270.449 200.354 270.71Z"
                fill="#EDEDED"
            />
            <path
                d="M192.382 279.65C209.365 289.285 226.262 298.833 243.245 308.467C243.938 308.814 246.104 307.859 245.585 307.599C228.601 297.965 211.705 288.417 194.722 278.782C194.115 278.348 191.949 279.39 192.382 279.65Z"
                fill="#EDEDED"
            />
            <path
                d="M186.49 289.805C206.679 301.263 226.955 312.72 247.144 324.177C247.837 324.525 250.004 323.57 249.484 323.309C229.294 311.852 209.019 300.395 188.829 288.938C188.136 288.504 186.057 289.545 186.49 289.805Z"
                fill="#EDEDED"
            />
            <path
                d="M181.724 295.447C204.166 308.206 226.608 320.879 249.05 333.638C249.744 333.985 251.91 333.031 251.39 332.77C228.948 320.011 206.506 307.338 184.064 294.579C183.371 294.145 181.204 295.187 181.724 295.447Z"
                fill="#EDEDED"
            />
            <path
                d="M309.272 179.747C328.941 190.943 348.697 202.14 368.366 213.25C369.059 213.598 371.225 212.643 370.706 212.382C351.036 201.186 331.28 189.989 311.611 178.879C310.918 178.445 308.752 179.399 309.272 179.747Z"
                fill="#EDEDED"
            />
            <path
                d="M305.112 183.826C317.33 190.77 329.634 197.714 341.851 204.657C342.545 205.005 344.711 204.05 344.191 203.789C331.973 196.846 319.669 189.902 307.452 182.958C306.759 182.524 304.593 183.566 305.112 183.826Z"
                fill="#EDEDED"
            />
            <path
                d="M296.534 192.506C318.976 205.265 341.418 217.937 363.86 230.697C364.553 231.044 366.719 230.089 366.199 229.829C343.757 217.069 321.315 204.397 298.873 191.638C298.18 191.204 296.014 192.245 296.534 192.506Z"
                fill="#EDEDED"
            />
            <path
                d="M292.548 196.412C299.133 200.144 305.632 203.79 312.217 207.522C312.91 207.869 315.077 206.914 314.557 206.654C307.972 202.922 301.473 199.276 294.888 195.544C294.281 195.11 292.115 196.152 292.548 196.412Z"
                fill="#EDEDED"
            />
            <path
                d="M287.436 205.263C305.719 215.592 324.002 226.008 342.285 236.337C342.978 236.684 345.144 235.73 344.624 235.469C326.341 225.14 308.058 214.724 289.775 204.396C289.169 203.962 287.003 205.003 287.436 205.263Z"
                fill="#EDEDED"
            />
            <path
                d="M275.565 222.363C298.007 235.122 320.449 247.795 342.891 260.554C343.584 260.901 345.75 259.947 345.23 259.686C322.788 246.927 300.346 234.254 277.904 221.495C277.298 221.061 275.131 222.103 275.565 222.363Z"
                fill="#EDEDED"
            />
            <path
                d="M284.23 210.906C294.194 216.548 304.246 222.19 314.21 227.918C314.903 228.266 317.07 227.311 316.55 227.05C306.585 221.409 296.534 215.767 286.569 210.038C285.876 209.604 283.71 210.646 284.23 210.906Z"
                fill="#EDEDED"
            />
            <path
                d="M271.493 228.439C293.935 241.199 316.377 253.871 338.819 266.63C339.512 266.977 341.678 266.023 341.158 265.762C318.716 253.003 296.274 240.331 273.832 227.571C273.139 227.137 270.973 228.179 271.493 228.439Z"
                fill="#EDEDED"
            />
            <path
                d="M259.708 244.844C272.532 252.048 285.27 259.339 298.094 266.543C298.787 266.89 300.953 265.935 300.433 265.675C287.609 258.471 274.872 251.18 262.048 243.976C261.355 243.542 259.275 244.583 259.708 244.844Z"
                fill="#EDEDED"
            />
            <path
                d="M250.524 256.388C272.966 269.148 295.408 281.82 317.85 294.579C318.543 294.927 320.709 293.972 320.189 293.711C297.747 280.952 275.305 268.28 252.863 255.521C252.257 255.087 250.09 256.128 250.524 256.388Z"
                fill="#EDEDED"
            />
            <path
                d="M253.816 252.222C268.46 260.468 283.017 268.801 297.66 277.047C298.354 277.394 300.52 276.439 300 276.179C285.356 267.933 270.799 259.6 256.156 251.354C255.462 250.921 253.296 251.962 253.816 252.222Z"
                fill="#EDEDED"
            />
            <path
                opacity="0.15"
                d="M516.362 343.534L496.52 332.076C483.089 324.264 464.546 322.442 455.101 327.91C445.657 333.378 448.863 344.141 462.293 351.866L482.136 363.323C495.566 371.135 514.109 372.958 523.554 367.49C532.999 362.021 529.793 351.259 516.362 343.534Z"
                fill="#6C99D1"
            />
            <path
                opacity="0.15"
                d="M597.726 316.973L548.336 288.416C545.303 286.68 540.364 286.68 537.331 288.416L487.942 316.973C484.909 318.709 484.909 321.573 487.942 323.396L537.331 351.952C540.364 353.688 545.303 353.688 548.336 351.952L597.726 323.396C600.758 321.66 600.758 318.795 597.726 316.973Z"
                fill="#6C99D1"
            />
            <path
                d="M597.725 258.21L548.335 229.654C545.302 227.918 540.363 227.918 537.331 229.654L487.941 258.21C484.908 259.946 484.908 262.81 487.941 264.633L537.331 293.189C540.363 294.925 545.302 294.925 548.335 293.189L597.725 264.633C600.758 262.81 600.758 259.946 597.725 258.21Z"
                fill="#7D3B8F"
            />
            <path
                d="M542.79 229.134C544.696 229.134 546.516 229.568 547.902 230.349L597.292 258.905C598.505 259.6 599.112 260.468 599.112 261.336C599.112 262.204 598.419 263.071 597.292 263.766L547.902 292.322C546.516 293.104 544.783 293.537 542.79 293.537C540.884 293.537 539.064 293.104 537.678 292.322L488.288 263.766C487.075 263.071 486.468 262.204 486.468 261.336C486.468 260.468 487.161 259.6 488.288 258.905L537.678 230.349C539.064 229.568 540.797 229.134 542.79 229.134ZM542.79 228.266C540.797 228.266 538.804 228.7 537.244 229.568L487.854 258.124C484.822 259.86 484.822 262.724 487.854 264.547L537.244 293.104C538.804 293.971 540.797 294.405 542.79 294.405C544.783 294.405 546.776 293.971 548.336 293.104L597.726 264.547C600.758 262.811 600.758 259.947 597.726 258.124L548.336 229.568C546.776 228.7 544.783 228.266 542.79 228.266Z"
                fill="white"
            />
            <path
                d="M599.978 311.331C599.978 312.632 599.112 313.674 597.725 314.542L548.335 343.098C546.949 343.966 545.129 344.4 542.79 344.4V294.492C544.956 294.492 546.776 294.058 548.335 293.19L597.725 264.633C598.938 263.939 599.978 262.897 599.978 261.422V311.331Z"
                fill="#7D3B8F"
            />
            <path
                d="M485.516 311.331C485.516 312.632 486.382 313.674 487.768 314.542L537.158 343.098C538.544 343.966 540.364 344.4 542.704 344.4V294.492C540.537 294.492 538.718 294.058 537.158 293.19L487.768 264.633C486.555 263.939 485.516 262.897 485.516 261.422V311.331Z"
                fill="url(#paint51_linear_2535_29656)"
            />
            <path
                d="M506.744 232.693C503.625 238.595 500.159 242.675 495.74 247.709C494.527 249.098 494.007 250.4 492.014 253.351C490.021 256.215 487.075 259.079 484.389 260.381C481.703 261.596 480.576 261.683 480.057 261.249C479.45 260.728 479.71 260.034 480.836 259.253C481.963 258.559 484.042 257.517 485.862 255.347C485.862 255.347 483.436 256.996 481.876 257.951C480.316 258.819 478.67 259.427 477.544 259.687C476.071 260.034 474.338 258.559 475.551 257.777C476.677 256.996 477.804 256.736 480.057 255.434C482.136 254.219 483.349 252.917 483.349 252.917C483.349 252.917 480.836 254.219 478.497 254.913C476.157 255.607 475.031 255.434 474.338 255.26C473.645 255.087 472.951 253.958 474.424 253.177C475.811 252.483 477.804 252.309 480.23 250.573C481.963 249.358 483.089 248.23 482.656 248.143C482.223 248.056 481.27 248.837 479.363 249.098C477.63 249.358 475.724 248.49 475.637 247.448C475.551 246.407 475.984 246.928 477.804 246.407C479.623 245.886 480.923 244.758 483.523 244.237C485.775 243.803 487.248 243.369 489.155 242.414C491.061 241.459 495.047 235.21 498.08 229.655C500.679 223.492 503.365 213.25 504.492 209.257C506.398 202.4 508.997 188.513 510.384 183.739C511.77 179.052 514.023 177.923 516.796 177.316C520.088 176.535 521.821 180.701 521.215 184.694C520.262 191.724 514.803 218.892 506.744 232.693Z"
                fill="#F4A6A8"
            />
            <path
                d="M493.227 237.554L490.368 241.286C490.368 241.286 490.887 243.022 493.747 244.671C496.606 246.32 497.559 246.06 497.559 246.06L500.332 242.501L493.227 237.554Z"
                fill="#F6FBFB"
            />
            <path
                d="M517.229 176.535C513.849 176.014 511.077 179.139 509.69 184C508.304 188.86 505.358 203.095 504.491 206.914C499.812 227.832 491.841 239.029 491.841 239.029C491.841 239.029 494.787 243.196 499.466 243.803C499.466 243.803 507.871 233.822 513.763 220.281C518.182 210.213 520.348 196.585 521.041 192.593C522.601 183.392 523.208 177.49 517.229 176.535Z"
                fill="#2C2E76"
            />
            <path
                d="M483.609 322.875C483.609 322.875 483.869 324.785 484.216 326.347C484.649 327.909 484.995 330.426 484.736 331.728C484.476 332.944 480.923 334.766 478.93 335.027C476.937 335.287 473.904 337.37 472.778 338.846C471.132 340.929 466.886 342.404 464.113 342.057C461.34 341.71 458.221 341.103 457.614 339.974C456.921 338.846 457.874 337.631 463.68 333.464C469.485 329.298 473.385 326.781 475.204 322.962L483.609 322.875Z"
                fill="#2C2E76"
            />
            <path
                d="M504.578 362.108C503.105 364.191 500.592 365.233 498.08 365.58C496.78 365.754 495.394 365.754 494.094 365.754C492.707 365.754 490.541 364.886 490.628 363.15C490.628 362.629 491.061 362.108 491.408 361.674C493.661 358.723 496.087 355.946 498.166 352.821C500.506 349.349 502.845 346.051 503.712 340.843L512.377 340.93C512.377 341.624 512.637 342.666 512.723 343.62C512.897 344.575 513.07 345.53 513.33 346.485C513.763 348.394 514.023 350.391 513.07 352.126C512.377 353.428 510.904 354.21 509.691 355.164C507.524 357.074 506.225 359.765 504.578 362.108Z"
                fill="#2C2E76"
            />
            <path
                d="M549.982 248.23C553.102 267.933 544.35 275.31 532.999 279.477C524.854 282.515 513.157 287.115 513.157 287.115C513.157 287.115 513.33 291.455 513.416 301.176C513.503 309.682 512.377 340.93 512.377 340.93C512.377 340.93 507.698 343.36 503.712 340.843C503.712 340.843 499.119 316.973 498.166 306.124C496.867 291.542 494.614 281.907 495.393 278.782C496.087 276.005 512.03 268.193 517.056 263.94L483.609 275.224C483.609 275.224 484.736 280.952 484.562 286.247C484.216 297.878 483.609 322.962 483.609 322.962C483.609 322.962 479.017 326.261 475.118 323.049C475.118 323.049 466.713 273.314 467.753 266.717C468.706 260.468 498.166 247.709 503.365 243.282C506.311 240.765 509.604 238.074 509.604 238.074L549.982 248.23Z"
                fill="#C6D8F1"
            />
            <path
                d="M516.969 263.94C516.969 263.94 511.337 260.382 509.43 254.566C509.43 254.566 509.084 258.733 511.25 262.378C511.51 263.767 483.522 275.137 483.522 275.137L516.969 263.94Z"
                fill="#ADC8EA"
            />
            <path
                d="M538.458 172.977C538.458 172.977 537.938 182.351 538.198 183.219C538.458 184.087 542.53 186.777 543.657 187.819C544.87 188.774 537.158 196.412 533.432 198.061C529.706 199.797 517.922 195.284 517.662 189.468C517.489 183.653 519.655 178.792 521.475 178.532C523.381 178.184 538.458 172.977 538.458 172.977Z"
                fill="#F4A6A8"
            />
            <path
                d="M523.121 183.652C521.908 182.523 523.294 180.093 524.161 178.357C528.06 177.229 538.544 172.889 538.544 172.889C538.544 172.889 538.458 175.059 538.371 176.534C538.371 178.27 537.245 180.093 535.858 181.134C534.819 181.916 533.432 182.87 531.699 183.565C530.053 184.259 525.894 186.256 523.121 183.652Z"
                fill="#F2908F"
            />
            <path
                d="M539.498 184.52C539.498 184.52 535.512 191.116 522.601 192.418C520.782 188.773 521.301 182.784 524.161 178.878C524.161 178.878 520.348 175.84 517.749 176.361C517.749 176.361 513.676 178.531 511.857 183.825C510.124 189.207 508.391 195.63 508.477 205.525C508.651 215.507 508.131 240.244 508.131 240.244C508.131 240.244 506.484 247.535 518.529 253.09C526.24 256.649 533.519 257.517 539.324 256.215C544.09 255.086 549.289 253.264 550.329 251.007C549.376 243.022 546.95 229.134 547.556 220.801C548.249 211.948 550.156 205.525 550.589 198.494C551.109 191.377 549.636 191.55 546.083 189.033C541.491 185.475 539.498 184.52 539.498 184.52Z"
                fill="#F6FBFB"
            />
            <path
                d="M524.161 178.878C524.161 178.878 512.81 193.634 520.348 210.646C520.348 210.646 518.615 246.841 518.355 255.434C513.676 253.698 511.25 250.573 511.25 250.573C511.25 250.573 507.004 247.709 507.091 240.244C507.091 240.244 507.784 227.051 508.131 215.42C508.477 203.789 507.784 193.981 510.644 185.735C513.503 177.489 518.355 176.014 518.355 176.014L524.161 178.878Z"
                fill="#A8C9EC"
            />
            <path
                d="M523.121 180.527V176.447C523.121 176.447 519.308 179.485 517.749 183.044C516.189 186.603 514.196 194.501 513.763 196.932C513.416 199.362 517.142 204.57 517.142 204.57C517.142 204.57 514.543 207.087 514.976 208.823C515.409 210.559 519.828 219.846 519.655 222.71C519.655 222.19 520.262 210.472 520.262 210.472C520.262 210.472 518.095 201.271 518.702 194.762C519.742 184.606 523.121 180.527 523.121 180.527Z"
                fill="#2C2E76"
            />
            <path
                d="M538.198 183.131C538.198 186.169 533.692 194.154 520.348 210.559C519.395 227.051 518.355 255.347 518.355 255.347C518.355 255.347 541.577 267.672 552.322 256.475C552.322 256.475 551.628 251.267 549.722 242.24C547.903 233.213 548.422 220.193 549.549 210.906C550.675 201.619 553.361 193.46 549.722 190.335C546.083 187.21 538.198 183.131 538.198 183.131Z"
                fill="#A8C9EC"
            />
            <path
                d="M517.315 158.914C518.442 151.623 523.641 146.068 531.179 146.502C539.151 146.936 545.303 153.793 544.783 161.779C544.523 166.466 542.097 170.459 538.458 172.889C538.458 172.889 537.765 175.493 537.331 176.1C534.645 179.225 526.674 181.135 524.334 180.787C521.388 180.353 519.742 179.138 518.355 175.927C515.842 170.198 516.622 162.56 517.315 158.914Z"
                fill="#F4A6A8"
            />
            <path
                d="M518.355 153.099C521.475 148.412 527.107 145.548 533.346 146.85C539.411 148.152 544.177 153.447 544.697 159.609C545.216 165.164 542.53 170.025 538.458 172.889C538.631 172.368 542.357 166.727 538.545 165.511C537.938 166.206 537.765 167.334 537.765 168.463C537.765 168.463 535.858 168.289 536.292 166.032C536.378 165.425 536.552 164.817 536.638 164.296C536.985 162.56 536.552 160.738 535.512 159.262C534.732 158.047 534.039 156.398 534.472 154.575C534.472 154.575 529.62 156.745 523.468 155.443C520.868 154.835 519.049 153.881 518.355 153.099Z"
                fill="#3A2C6D"
            />
            <path
                d="M534.385 154.575C534.385 154.575 531.959 156.745 525.894 157.353C519.828 157.96 514.543 154.488 513.243 152.058C511.337 148.326 515.149 151.016 518.615 149.194C522.081 147.371 535.079 141.121 540.537 150.669C540.537 150.669 543.657 151.19 544.523 153.013C545.303 154.835 545.65 161.519 543.83 165.685L534.385 154.575Z"
                fill="#3A2C6D"
            />
            <path
                d="M538.458 172.889C538.458 172.889 538.804 173.671 540.191 173.757C542.79 173.931 545.91 168.983 543.743 165.685C542.443 163.602 539.411 164.296 538.458 165.598V172.889Z"
                fill="#F4A6A8"
            />
            <path
                d="M536.378 267.413C537.938 266.458 539.064 265.069 541.404 263.941C543.483 262.899 544.263 262.465 545.736 260.816C547.296 259.08 549.462 252.657 550.848 246.494C552.495 239.203 552.148 229.569 551.368 225.402C549.809 217.07 545.823 205.526 544.61 200.752C543.397 195.978 544.783 193.895 546.949 191.899C549.375 189.555 553.101 190.163 555.701 196.846C558.213 203.443 561.506 215.594 562.546 225.229C563.066 229.916 563.239 237.381 561.073 246.755C559.6 253.265 557.347 258.125 554.401 264.201C553.621 265.85 553.448 267.239 552.321 270.624C551.195 273.922 549.029 277.481 546.862 279.391C544.61 281.3 543.57 281.647 542.963 281.387C542.27 281.04 542.27 280.259 543.223 279.304C544.176 278.349 545.823 276.787 547.036 274.183C547.036 274.183 545.129 276.44 543.916 277.741C542.703 278.957 541.23 279.998 540.191 280.606C538.891 281.3 536.811 280.345 537.764 279.304C538.631 278.262 539.671 277.742 541.49 275.832C543.137 274.096 544.003 272.534 544.003 272.534C544.003 272.534 541.924 274.443 539.844 275.745C537.764 277.047 536.638 277.134 535.945 277.134C535.165 277.134 534.212 276.179 535.512 275.138C536.638 274.096 538.544 273.402 540.45 271.058C541.837 269.409 542.617 268.107 542.183 268.107C541.75 268.107 540.97 269.149 539.237 269.843C537.591 270.537 535.598 270.19 535.252 269.235C534.732 268.281 534.818 268.454 536.378 267.413Z"
                fill="#F4A6A8"
            />
            <path
                d="M548.336 255L546.603 258.819C546.603 258.819 547.036 260.642 550.502 262.204C553.968 263.767 555.094 263.159 555.094 263.159L557.087 258.819L548.336 255Z"
                fill="#F6FBFB"
            />
            <path
                d="M520.349 210.56C520.349 210.56 529.36 197.627 532.653 193.287C535.945 188.947 538.112 182.003 538.112 182.003L538.198 179.66C538.198 179.66 539.585 179.573 540.191 180.788C540.798 182.003 543.83 186.256 543.83 186.256C543.83 186.256 540.971 197.627 537.852 201.099C534.732 204.657 530.14 205.786 530.14 205.786C530.14 205.786 531.44 210.646 529.967 211.688C528.407 212.729 522.948 217.503 519.742 222.711L520.349 210.56Z"
                fill="#2C2E76"
            />
            <path
                d="M547.643 190.683C545.303 191.985 542.79 194.937 544.523 201.88C546.256 208.824 549.202 217.504 550.589 223.753C551.975 230.003 552.148 237.901 550.849 243.891C549.549 249.88 547.469 256.303 547.469 256.303C547.469 256.303 552.235 260.729 556.741 260.556C556.741 260.556 561.42 251.095 562.806 239.377C564.193 227.659 561.766 210.473 556.827 198.322C552.322 187.298 547.643 190.683 547.643 190.683Z"
                fill="#2C2E76"
            />
            <path
                d="M469.832 256.476L492.274 243.456C492.707 243.196 493.14 243.196 493.574 243.456L532.999 266.284L509.864 279.651L469.832 256.476Z"
                fill="#EFF5FD"
            />
            <path
                d="M510.037 277.741L522.168 270.711L486.728 250.227L474.598 257.17L510.037 277.741Z"
                fill="#ADCFEF"
            />
            <path
                d="M516.016 258.211L515.756 258.385L509.863 261.77L499.292 255.694L499.119 255.608L505.271 252.049L516.016 258.211Z"
                fill="#AFB5B7"
            />
            <path
                d="M515.756 258.385L509.864 261.771L499.292 255.695L505.271 252.31L515.756 258.385Z"
                fill="#ADCFEF"
            />
            <path
                d="M532.999 266.284V266.805C532.999 267.586 532.566 268.28 531.959 268.714L510.99 280.779C510.643 280.953 510.297 281.039 509.864 281.039C509.517 281.039 509.084 280.953 508.824 280.779L470.958 258.906C470.265 258.559 469.832 257.778 469.832 256.996V256.476L509.864 279.651L532.999 266.284Z"
                fill="#C9E1F6"
            />
            <path
                d="M509.864 279.651V281.126C509.517 281.126 509.084 281.04 508.824 280.866L470.958 258.993C470.265 258.646 469.832 257.864 469.832 257.083V256.562L509.864 279.651Z"
                fill="#ADCFEF"
            />
            <path
                d="M509.257 279.911C508.564 280.171 507.698 280.171 507.004 279.737L469.312 257.951C468.619 257.604 468.186 256.909 468.099 256.128L463.94 231.477C463.94 231.304 463.94 231.217 463.94 231.043C463.94 230.349 464.2 229.741 464.72 229.221L503.885 251.875C504.405 252.135 504.751 252.656 504.838 253.177L509.257 279.911Z"
                fill="#EFF5FD"
            />
            <path
                d="M509.864 279.651L509.604 279.824C509.517 279.911 509.344 279.911 509.257 279.998L504.838 253.351C504.752 252.83 504.405 252.309 503.885 252.049L464.72 229.395C464.893 229.221 465.067 229.135 465.24 228.961L504.492 251.702C505.012 251.962 505.358 252.483 505.445 253.004L509.864 279.651Z"
                fill="#C9E1F6"
            />
            <path
                d="M488.028 257.17C487.768 255.521 486.035 253.351 484.215 252.31C482.396 251.268 481.183 251.702 481.443 253.351C481.703 255 483.436 257.17 485.255 258.212C487.075 259.34 488.288 258.819 488.028 257.17Z"
                fill="#ADCFEF"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2535_29656"
                    x1="158.567"
                    y1="230.359"
                    x2="274.502"
                    y2="196.444"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2535_29656"
                    x1="306.475"
                    y1="239.529"
                    x2="311.001"
                    y2="188.821"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2535_29656"
                    x1="318.593"
                    y1="215.85"
                    x2="344.086"
                    y2="228.868"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_2535_29656"
                    x1="122.816"
                    y1="177.474"
                    x2="306.4"
                    y2="123.769"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.010101" stopColor="#873A8E" />
                    <stop offset="0.4293" stopColor="#523F92" />
                    <stop offset="0.904" stopColor="#3A235A" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_2535_29656"
                    x1="295.146"
                    y1="168.564"
                    x2="363.095"
                    y2="203.264"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_2535_29656"
                    x1="322.744"
                    y1="155.79"
                    x2="390.693"
                    y2="190.49"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_2535_29656"
                    x1="295.667"
                    y1="218.393"
                    x2="342.756"
                    y2="240.331"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#544394" />
                    <stop offset="0.3409" stopColor="#604E9B" />
                    <stop offset="0.9306" stopColor="#806BAE" />
                    <stop offset="1" stopColor="#8470B0" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_2535_29656"
                    x1="199.876"
                    y1="62.3854"
                    x2="443.873"
                    y2="189.326"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_2535_29656"
                    x1="200.219"
                    y1="61.7305"
                    x2="444.214"
                    y2="188.669"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_2535_29656"
                    x1="200.56"
                    y1="61.0742"
                    x2="444.555"
                    y2="188.014"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_2535_29656"
                    x1="200.901"
                    y1="60.4179"
                    x2="444.895"
                    y2="187.355"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_2535_29656"
                    x1="201.243"
                    y1="59.7629"
                    x2="445.235"
                    y2="186.699"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_2535_29656"
                    x1="201.584"
                    y1="59.1063"
                    x2="445.579"
                    y2="186.045"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_2535_29656"
                    x1="201.925"
                    y1="58.4511"
                    x2="445.92"
                    y2="185.388"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0353535" stopColor="#7F2A86" />
                    <stop offset="0.4614" stopColor="#8A3189" />
                    <stop offset="1" stopColor="#9D3F90" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_2535_29656"
                    x1="316.492"
                    y1="114.14"
                    x2="297.3"
                    y2="95.9054"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_2535_29656"
                    x1="300.656"
                    y1="105.039"
                    x2="281.465"
                    y2="86.8049"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_2535_29656"
                    x1="284.82"
                    y1="95.9379"
                    x2="265.628"
                    y2="77.7038"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_2535_29656"
                    x1="268.984"
                    y1="86.8375"
                    x2="249.792"
                    y2="68.6034"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_2535_29656"
                    x1="253.148"
                    y1="77.7363"
                    x2="233.956"
                    y2="59.5021"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_2535_29656"
                    x1="237.312"
                    y1="68.6359"
                    x2="218.12"
                    y2="50.4018"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_2535_29656"
                    x1="221.476"
                    y1="59.5348"
                    x2="202.284"
                    y2="41.3006"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7F8090" />
                    <stop offset="0.5253" stopColor="#9D9EB3" />
                    <stop offset="1" stopColor="#D5D1D8" />
                </linearGradient>
                <radialGradient
                    id="paint21_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(309.613 106.556) scale(8.09432 8.10812)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint22_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(314.874 107.541) scale(4.47236 4.47971)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint23_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(293.646 97.4651) scale(8.0942 8.10814)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint24_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(298.908 98.4496) scale(4.47202 4.47954)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint25_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(277.932 88.3731) scale(8.09429 8.10813)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint26_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(283.193 89.3577) scale(4.47179 4.47962)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint27_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(262.048 79.1978) scale(8.09417 8.10811)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint28_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(267.31 80.1825) scale(4.47204 4.47972)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint29_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(246.082 70.1059) scale(8.09418 8.10815)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint30_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(251.344 71.0906) scale(4.47174 4.47971)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint31_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(230.368 61.015) scale(8.09421 8.10812)"
                >
                    <stop stopColor="white" />
                    <stop offset="0.3253" stopColor="#B8B6B6" />
                    <stop offset="1" />
                </radialGradient>
                <radialGradient
                    id="paint32_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(291.384 97.7081) rotate(-57.5095) scale(6.21166 5.69302)"
                >
                    <stop stopColor="#F29588" />
                    <stop offset="0.0028131" stopColor="#F19487" />
                    <stop offset="0.1211" stopColor="#AA6B67" />
                    <stop offset="0.2422" stopColor="#744C4D" />
                    <stop offset="0.3644" stopColor="#4C3438" />
                    <stop offset="0.4875" stopColor="#2E2226" />
                    <stop offset="0.6116" stopColor="#191418" />
                    <stop offset="0.7373" stopColor="#0A080B" />
                    <stop offset="0.8655" stopColor="#030304" />
                    <stop offset="1" stopColor="#010202" />
                </radialGradient>
                <linearGradient
                    id="paint33_linear_2535_29656"
                    x1="462.498"
                    y1="306.886"
                    x2="64.496"
                    y2="306.886"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E1D48" />
                    <stop offset="1" stopColor="#4F3A8F" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_2535_29656"
                    x1="410.172"
                    y1="305.167"
                    x2="12.1688"
                    y2="305.167"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0555556" stopColor="#2F245A" />
                    <stop offset="0.2653" stopColor="#30245B" />
                    <stop offset="0.4466" stopColor="#31255E" />
                    <stop offset="0.4697" stopColor="#32265F" />
                    <stop offset="0.4808" stopColor="#352861" />
                    <stop offset="0.4928" stopColor="#3E2E69" />
                    <stop offset="0.5054" stopColor="#4E3977" />
                    <stop offset="0.5182" stopColor="#66508F" />
                    <stop offset="0.5303" stopColor="#8671B1" />
                    <stop offset="0.5461" stopColor="#6C5796" />
                    <stop offset="0.5686" stopColor="#513C7A" />
                    <stop offset="0.59" stopColor="#3F2F6A" />
                    <stop offset="0.6098" stopColor="#352862" />
                    <stop offset="0.6263" stopColor="#32265F" />
                    <stop offset="0.6392" stopColor="#30245B" />
                    <stop offset="0.688" stopColor="#29204F" />
                    <stop offset="0.7345" stopColor="#251C46" />
                    <stop offset="0.7774" stopColor="#221940" />
                    <stop offset="0.8131" stopColor="#21183E" />
                    <stop offset="1" stopColor="#21183E" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_2535_29656"
                    x1="462.498"
                    y1="296.575"
                    x2="64.496"
                    y2="296.575"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#532581" />
                    <stop offset="1" stopColor="#744B98" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_2535_29656"
                    x1="-15.0217"
                    y1="349.765"
                    x2="382.982"
                    y2="349.765"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0555556" stopColor="#2F245A" />
                    <stop offset="0.2653" stopColor="#30245B" />
                    <stop offset="0.4466" stopColor="#31255E" />
                    <stop offset="0.4697" stopColor="#32265F" />
                    <stop offset="0.4808" stopColor="#352861" />
                    <stop offset="0.4928" stopColor="#3E2E69" />
                    <stop offset="0.5054" stopColor="#4E3977" />
                    <stop offset="0.5182" stopColor="#66508F" />
                    <stop offset="0.5303" stopColor="#8671B1" />
                    <stop offset="0.5461" stopColor="#6C5796" />
                    <stop offset="0.5686" stopColor="#513C7A" />
                    <stop offset="0.59" stopColor="#3F2F6A" />
                    <stop offset="0.6098" stopColor="#352862" />
                    <stop offset="0.6263" stopColor="#32265F" />
                    <stop offset="0.6392" stopColor="#30245B" />
                    <stop offset="0.688" stopColor="#29204F" />
                    <stop offset="0.7345" stopColor="#251C46" />
                    <stop offset="0.7774" stopColor="#221940" />
                    <stop offset="0.8131" stopColor="#21183E" />
                    <stop offset="1" stopColor="#21183E" />
                </linearGradient>
                <radialGradient
                    id="paint37_radial_2535_29656"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(236.857 340.672) scale(151.277 151.537)"
                >
                    <stop stopColor="white" />
                    <stop offset="1" />
                </radialGradient>
                <linearGradient
                    id="paint38_linear_2535_29656"
                    x1="458.522"
                    y1="296.574"
                    x2="68.4723"
                    y2="296.574"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E1D48" />
                    <stop offset="1" stopColor="#583089" />
                </linearGradient>
                <linearGradient
                    id="paint39_linear_2535_29656"
                    x1="283.348"
                    y1="197.149"
                    x2="214.039"
                    y2="543.1"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.010101" stopColor="#873A8E" />
                    <stop offset="0.4293" stopColor="#523F92" />
                    <stop offset="0.904" stopColor="#3A235A" />
                </linearGradient>
                <linearGradient
                    id="paint40_linear_2535_29656"
                    x1="421.011"
                    y1="291.265"
                    x2="409.142"
                    y2="287.161"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3A235A" />
                    <stop offset="1" stopColor="#523F92" />
                </linearGradient>
                <linearGradient
                    id="paint41_linear_2535_29656"
                    x1="421.011"
                    y1="291.265"
                    x2="409.142"
                    y2="287.161"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E1D48" />
                    <stop offset="1" stopColor="#4F3A8F" />
                </linearGradient>
                <linearGradient
                    id="paint42_linear_2535_29656"
                    x1="410.681"
                    y1="362.973"
                    x2="419.036"
                    y2="370.397"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0555556" stopColor="#9E79B5" />
                    <stop offset="0.255" stopColor="#A07BB6" />
                    <stop offset="0.3533" stopColor="#A682BA" />
                    <stop offset="0.4292" stopColor="#B18FC2" />
                    <stop offset="0.4937" stopColor="#C0A1CC" />
                    <stop offset="0.4949" stopColor="#C0A1CC" />
                    <stop offset="0.506" stopColor="#C3A5CF" />
                    <stop offset="0.518" stopColor="#CAB1D6" />
                    <stop offset="0.5305" stopColor="#D6C4E1" />
                    <stop offset="0.5432" stopColor="#E8DFEF" />
                    <stop offset="0.5556" stopColor="white" />
                    <stop offset="0.5587" stopColor="#F9F6FB" />
                    <stop offset="0.5706" stopColor="#E6D9EC" />
                    <stop offset="0.5819" stopColor="#D8C4E1" />
                    <stop offset="0.5923" stopColor="#D1B7D9" />
                    <stop offset="0.601" stopColor="#CEB3D7" />
                    <stop offset="0.6207" stopColor="#C3A7D0" />
                    <stop offset="0.6747" stopColor="#A78BC0" />
                    <stop offset="0.7262" stopColor="#9378B5" />
                    <stop offset="0.7736" stopColor="#886DAE" />
                    <stop offset="0.8131" stopColor="#8369AC" />
                    <stop offset="1" stopColor="#AC86BC" />
                </linearGradient>
                <linearGradient
                    id="paint43_linear_2535_29656"
                    x1="401.529"
                    y1="302.531"
                    x2="389.66"
                    y2="298.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3A235A" />
                    <stop offset="1" stopColor="#523F92" />
                </linearGradient>
                <linearGradient
                    id="paint44_linear_2535_29656"
                    x1="401.529"
                    y1="302.531"
                    x2="389.66"
                    y2="298.427"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#2E1D48" />
                    <stop offset="1" stopColor="#4F3A8F" />
                </linearGradient>
                <linearGradient
                    id="paint45_linear_2535_29656"
                    x1="390.865"
                    y1="385.293"
                    x2="399.223"
                    y2="392.721"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0555556" stopColor="#9E79B5" />
                    <stop offset="0.255" stopColor="#A07BB6" />
                    <stop offset="0.3533" stopColor="#A682BA" />
                    <stop offset="0.4292" stopColor="#B18FC2" />
                    <stop offset="0.4937" stopColor="#C0A1CC" />
                    <stop offset="0.4949" stopColor="#C0A1CC" />
                    <stop offset="0.506" stopColor="#C3A5CF" />
                    <stop offset="0.518" stopColor="#CAB1D6" />
                    <stop offset="0.5305" stopColor="#D6C4E1" />
                    <stop offset="0.5432" stopColor="#E8DFEF" />
                    <stop offset="0.5556" stopColor="white" />
                    <stop offset="0.5587" stopColor="#F9F6FB" />
                    <stop offset="0.5706" stopColor="#E6D9EC" />
                    <stop offset="0.5819" stopColor="#D8C4E1" />
                    <stop offset="0.5923" stopColor="#D1B7D9" />
                    <stop offset="0.601" stopColor="#CEB3D7" />
                    <stop offset="0.6207" stopColor="#C3A7D0" />
                    <stop offset="0.6747" stopColor="#A78BC0" />
                    <stop offset="0.7262" stopColor="#9378B5" />
                    <stop offset="0.7736" stopColor="#886DAE" />
                    <stop offset="0.8131" stopColor="#8369AC" />
                    <stop offset="1" stopColor="#AC86BC" />
                </linearGradient>
                <linearGradient
                    id="paint46_linear_2535_29656"
                    x1="363.487"
                    y1="194.547"
                    x2="319.736"
                    y2="269.965"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00505051" stopColor="#D1D3D4" />
                    <stop offset="1" stopColor="#F1F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint47_linear_2535_29656"
                    x1="318.691"
                    y1="252.139"
                    x2="338.523"
                    y2="218.82"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00505051" stopColor="#D1D3D4" />
                    <stop offset="1" stopColor="#F1F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint48_linear_2535_29656"
                    x1="282.489"
                    y1="306.175"
                    x2="347.483"
                    y2="195.642"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00505051" stopColor="#E6E7E9" />
                    <stop offset="0.2173" stopColor="#F4F4F5" />
                    <stop offset="0.4811" stopColor="#FCFDFD" />
                    <stop offset="0.904" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint49_linear_2535_29656"
                    x1="262.817"
                    y1="275.013"
                    x2="242.832"
                    y2="309.465"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00505051" stopColor="#D1D3D4" />
                    <stop offset="1" stopColor="#F1F2F2" />
                </linearGradient>
                <linearGradient
                    id="paint50_linear_2535_29656"
                    x1="205.771"
                    y1="344.849"
                    x2="250.752"
                    y2="268.352"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00505051" stopColor="#E6E7E9" />
                    <stop offset="0.125" stopColor="#EEEFF0" />
                    <stop offset="0.3885" stopColor="#FBFBFB" />
                    <stop offset="0.6414" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="paint51_linear_2535_29656"
                    x1="517.195"
                    y1="263.858"
                    x2="460.24"
                    y2="376.279"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.010101" stopColor="#873A8E" />
                    <stop offset="0.4293" stopColor="#523F92" />
                    <stop offset="0.904" stopColor="#3A235A" />
                </linearGradient>
            </defs>
        </svg>
    );
};
