import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick, style }: TBaseSVG) => (
    <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={className || "icon-edit-outline"}
        style={style}
    >
        <title>{title}</title>
        <path
            stroke={color || theme.purple600}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        ></path>
    </svg>
);
