import { TooltipItem } from "chart.js";
import { format, parseISO } from "date-fns";
import { Bar } from "react-chartjs-2";

import { theme } from "../../../../assets";
import { TColumnData } from "../../../../pages/report-summary/report-summary";
import {
    dayFromWeek,
    monthFromYear,
    minutesToStringDate,
} from "../../../../utils";

const BarChart = ({ dates, times, periods, abreviatedDates }: TColumnData) => {
    const hours = times?.map((time) => time / 60);

    const formatTooltips = (dates: string[], index: number) => {
        if (dates[index].length > 10) {
            const tempDate = dates[index].split(" - ");

            return `${format(parseISO(tempDate[0]), "dd")} de ${monthFromYear(
                parseISO(tempDate[0])
            )} ${
                format(parseISO(tempDate[0]), "yyyy") !==
                format(parseISO(tempDate[1]), "yyyy")
                    ? format(parseISO(tempDate[0]), "yyyy")
                    : ``
            }- ${format(parseISO(tempDate[1]), "dd")} de ${monthFromYear(
                parseISO(tempDate[1])
            )} ${format(parseISO(tempDate[1]), "yyyy")}`;
        }

        if (dates[index].length < 8) {
            return `${monthFromYear(parseISO(dates[index]))} - ${format(
                parseISO(dates[index]),
                `yyyy`
            )}`;
        }

        const date = parseISO(dates[index]);

        return `${dayFromWeek(date)}, ${format(date, "dd")} de ${monthFromYear(
            date
        )} ${format(date, "yyyy")}`;
    };

    const scaleY = () => {
        const yAxesReview = {
            stacked: true,
            position: "right" as const,
            grid: {
                drawBorder: false,
                drawTicks: false,
            },
            ticks: {
                callback(value: number | string) {
                    return `  ${value}:00`;
                },
                font: { size: 16 },
                color: theme.gray400,
                precision: 0,
            },
        };
        const yAxesDefault = {
            stacked: true,
            position: "right" as const,
            grid: {
                drawBorder: false,
                drawTicks: false,
            },
            ticks: {
                callback(value: number | string) {
                    return `${value}h`;
                },
                font: { size: 12 },
                color: theme.gray600,
                precision: 0,
            },
        };
        const location = window.location.pathname.slice(0, 28);
        if (location === "/relatorio/gerenciar/revisar") return yAxesReview;
        return yAxesDefault;
    };

    const data = {
        labels: periods,
        datasets: [
            {
                label: "Tempo",
                data: hours,
                backgroundColor: theme.purple500,
                borderRadius: 4,
                barPercentage: 0.7,
                maxBarThickness: 100,
            },
        ],
    };

    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: { display: false },
            title: {
                display: true,
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title(value: TooltipItem<"bar">[]) {
                        const index = value[0].dataIndex;

                        if (dates) {
                            return formatTooltips(dates, index);
                        }
                        return ``;
                    },
                    label(value: TooltipItem<"bar">) {
                        if (times)
                            return `Tempo: ${minutesToStringDate(
                                times[value.dataIndex]
                            )}`;
                        return ``;
                    },
                },
                displayColors: false,
                padding: 10,
                intersect: false,
            },
        },
        scales: {
            yAxes: scaleY(),
            x: {
                id: "xAxis1",
                grid: {
                    display: false,
                    borderColor: theme.gray400,
                },
                ticks: {
                    font: { size: 12 },
                    color: theme.gray600,
                },
            },
            x2: {
                grid: {
                    display: false,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    font: { size: 10 },
                    color: theme.gray400,
                    fontWeight: 400,
                },
                labels: abreviatedDates,
            },
        },
    };

    return (
        <div style={{ width: "100%", height: 350 }}>
            <Bar data={data} options={options} id="bar-chart" />
        </div>
    );
};

export default BarChart;
