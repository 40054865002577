import { TDeleteRole } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ roleId }: TDeleteRole = {}) => {
    const tempHeaders = {
        auth: true,
        "x-roles-id": roleId,
    };

    if (!roleId) delete tempHeaders["x-roles-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/roles/delete`,
        method: "delete",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;
    const errors: { [key: string]: string } = {
        "default role cannot be deleted!":
            "Uma atuação padrão não pode ser apagada!",
    };

    const errorFeedBacks = {
        400: errors[body.message] || "Propriedade inválida",
        404: "Atuação não encontrado!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
