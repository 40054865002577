import { useState } from "react";

import { Icons, theme } from "../../../../assets";
import { DesignedAlert, RadixCheckBox, Timeline } from "../../../../components";
import { TEntryDay } from "../../../../models";
import {
    formatDay,
    getDateFromIsoString,
    sumTimeStampsInMinutes,
} from "../../../../utils";
import { TTimelineData } from "./card-container";
import * as s from "./styled-card-container";

type TDayListTrigger = {
    isEditing: boolean;
    entries: TEntryDay;
    timelineData?: TTimelineData;
    allDuration: number[];
    selectedEntries: string[];
    toggleDayChecked: (checked: boolean) => void;
    btnEditOnClick: () => void;
    containerOnClick: () => void;
    handleDeleteEntry: (id: string) => void;
};

export const DayTrigger = ({
    isEditing,
    entries,
    timelineData,
    allDuration,
    selectedEntries,
    containerOnClick,
    toggleDayChecked,
    btnEditOnClick,
    handleDeleteEntry,
}: TDayListTrigger) => {
    const { tasks, date: day } = entries;
    const entriesNumber = tasks.reduce(
        (acc, task) => acc + task.entries.length,
        0
    );
    const [isOpenDeleteAlert, setIsOpenDeleteAlert] = useState(false);

    return (
        <>
            <DesignedAlert
                textBtn={"Apagar"}
                onClickBtn={() => {
                    handleDeleteEntry(selectedEntries.join(", "));
                    setIsOpenDeleteAlert(false);
                }}
                onOpenChange={() => setIsOpenDeleteAlert(false)}
                description={
                    "Ao apagar estas tarefas elas não poderão ser recuperadas. Deseja apagar estas tarefas?"
                }
                title={"Apagar tarefas"}
                open={isOpenDeleteAlert}
            />

            <s.TitleContainer
                onClick={containerOnClick}
                dayOpen={entries.dayOpen}
            >
                <s.Flex style={{ gap: "12px" }}>
                    {isEditing && (
                        <RadixCheckBox
                            checked={entries.dayChecked}
                            onClick={() => {
                                toggleDayChecked(entries.dayChecked);
                            }}
                        />
                    )}

                    <span className="periods" style={{ width: "5.5rem" }}>
                        {formatDay(getDateFromIsoString(day))}
                    </span>

                    {isEditing && (
                        <s.Flex
                            style={{
                                marginLeft: "24px",
                                gap: "20px",
                            }}
                        >
                            <s.EditingText
                                itemsSelected={Boolean(selectedEntries.length)}
                            >
                                {`${selectedEntries.length}/${entriesNumber} itens selecionados`}
                            </s.EditingText>

                            {selectedEntries.length > 0 && (
                                <s.DeletingText
                                    itemsSelected={Boolean(
                                        selectedEntries.length
                                    )}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpenDeleteAlert(true);
                                    }}
                                >
                                    <Icons.Trash
                                        title="apagar"
                                        color={theme.redError}
                                    />
                                    Apagar
                                </s.DeletingText>
                            )}
                        </s.Flex>
                    )}
                </s.Flex>

                <div id="container-timeline">
                    {timelineData && !isEditing && (
                        <Timeline
                            barAtributes={timelineData.map((item) => ({
                                totalTimeInMinutes: item.totalTime,
                                title: item.name,
                                color: item.color,
                            }))}
                        />
                    )}
                </div>

                <s.Flex id="right-content">
                    <span className="periods">
                        {sumTimeStampsInMinutes(allDuration)}
                    </span>

                    <button
                        id="btn-edit"
                        onClick={(e) => {
                            e.stopPropagation();
                            btnEditOnClick();
                        }}
                    >
                        <Icons.EditOutline
                            title={"Editar registro"}
                            color={theme.purple800}
                        />
                    </button>
                </s.Flex>
            </s.TitleContainer>
        </>
    );
};
