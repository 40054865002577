type TMasterCard = {
    width?: string;
    height?: string;
};

export default ({ width, height }: TMasterCard) => {
    return (
        <div
            style={{
                display: "flex",
                width: "24px",
                height: "16px",
                padding: "4px 4px 3px 5px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
                border: "0.25px solid var(--common-black-12-p, rgba(0, 0, 0, 0.12))",
                background: "#FFF",
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width || "15"}
                height={height || "9"}
                viewBox="0 0 15 9"
                fill="none"
            >
                <g clipPath="url(#clip0_23811_1290)">
                    <path
                        d="M9.45538 7.6645H5.54492V0.917969H9.45545L9.45538 7.6645Z"
                        fill="#FF5F00"
                    />
                    <path
                        d="M5.79331 4.29064C5.79331 2.92211 6.46079 1.70302 7.50019 0.91738C6.71342 0.321816 5.7403 -0.00141654 4.73837 4.66668e-06C2.27024 4.66668e-06 0.269531 1.92096 0.269531 4.29064C0.269531 6.66033 2.27024 8.58129 4.73837 8.58129C5.74033 8.58272 6.71347 8.25949 7.50026 7.66391C6.46094 6.87841 5.79331 5.65925 5.79331 4.29064Z"
                        fill="#EB001B"
                    />
                    <path
                        d="M14.7311 4.29064C14.7311 6.66033 12.7304 8.58128 10.2623 8.58128C9.26019 8.5827 8.28694 8.25947 7.5 7.66391C8.53969 6.87827 9.20717 5.65925 9.20717 4.29064C9.20717 2.92204 8.53969 1.70302 7.5 0.91738C8.28692 0.321834 9.26014 -0.00139163 10.2622 4.5039e-06C12.7303 4.5039e-06 14.731 1.92096 14.731 4.29064"
                        fill="#F79E1B"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_23811_1290">
                        <rect width="15" height="9" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
