import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 56px;
    font-family: Noto Sans;

    a {
        text-decoration: underline;
        color: ${({ theme }) => theme.purple500};
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
    }

    a#termos {
        margin-right: 1rem;
    }

    div#header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    div#header > p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: ${({ theme }) => theme.purple500};
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
    }
`;

export const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
`;
