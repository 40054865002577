import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 600px;
    min-height: 400px;
    padding: 1rem;
`;
