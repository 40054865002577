import * as Checkbox from "@radix-ui/react-checkbox";

import { Icons } from "../../../assets";
import * as s from "./styled-radix-checkbox";

type TCheckBox = {
    onClick: () => void;
    checked: boolean;
    indeterminate?: boolean;
};

const CheckboxRadix = ({ checked, onClick, indeterminate }: TCheckBox) => {
    return (
        <s.Label>
            <s.CheckBox
                checked={checked || indeterminate}
                onCheckedChange={onClick}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Checkbox.Indicator>
                    {checked && !indeterminate && <Icons.Checked />}
                    {!checked && indeterminate && (
                        <s.Indeterminate>-</s.Indeterminate>
                    )}
                </Checkbox.Indicator>
            </s.CheckBox>
        </s.Label>
    );
};

export default CheckboxRadix;
