import { CSSProperties } from "react";

import { Icons } from "../../../assets";
import * as s from "./styled-tag";

type TTag = {
    children: string;
    color: string;
    style?: CSSProperties;
    onClick?: () => void;
    maxWidth?: string;
    isFavorite?: boolean;
};

export default ({
    children,
    color,
    style,
    onClick,
    maxWidth,
    isFavorite,
}: TTag) => (
    <s.Container
        color={color}
        className="project-tag"
        style={style}
        onClick={onClick}
        isFavorite={isFavorite}
    >
        {isFavorite && (
            <Icons.Star width="16px" height="16px" color={color} fill={color} />
        )}
        <p
            style={{
                maxWidth: maxWidth || "fit-content",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginBottom: "1px",
            }}
        >
            {children}
        </p>
    </s.Container>
);
