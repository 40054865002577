import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { UpdateEntryVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

type Error = "you must link the user to project";

export default async ({
    entriesId,
    projectId,
    sectorId,
    description,
    startAt,
    endAt,
    manyUpdates,
    activityId,
}: UpdateEntryVariables = {}) => {
    const tempHeaders = {
        auth: true,
        "x-entries-id": entriesId,
        "x-project-id": projectId,
        "x-sector-id": sectorId,
        "x-activity-id": activityId,
    };

    if (!entriesId) delete tempHeaders["x-entries-id"];
    if (!projectId) delete tempHeaders["x-project-id"];
    if (!sectorId) delete tempHeaders["x-sector-id"];

    const setUrl = () => {
        if (manyUpdates)
            return `${REACT_APP_BASE_URL}/timeEntry/update?updateMany=true`;
        return `${REACT_APP_BASE_URL}/timeEntry/update`;
    };

    const httpResponse = await axiosHttpClient({
        url: setUrl(),
        method: "put",
        headers: tempHeaders,
        body: { description, start_at: startAt, end_at: endAt },
    });

    const { body, statusCode } = httpResponse;

    const error: Error =
        body?.message?.error ||
        body?.message?.info ||
        body?.message?.name?.[0] ||
        body?.message?.name?.[0] ||
        body?.message;

    const errorsMapper = {
        "you must link the user to project":
            "Você não faz mais parte desse projeto!",
        "It's impossible to do this, you've already exceeded the project hour limit":
            "Você não pode atualizar esta entrada de tempo, você excedeu as horas previstas neste projeto!",
        "Start or End time is invalid!": "Data de início ou de fim inválidos!",
        "start or end time is invalid!": "Data de início ou de fim inválidos!",
        "impossible to do this to a locked entry!":
            "Relatório já fechado no mês desse registro",
        "Invalid property x-activity-id":
            "Entrada de tempo não contém atividade, crie ou selecione uma atividade.",
        "It's impossible to do this, you've already exceeded the hour limit":
            "Você excedeu as horas previstas neste mês!",
        "It's impossible to do this, you've exceeded the project hour limit":
            "Você não pode atualizar esta entrada de tempo, você excedeu as horas previstas neste projeto!",
    };

    const GENERIC_ERROR_MESSAGE = "Você não pode atualizar estes campos!";

    const errorFeedBacks = {
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        404: "Entrada de tempo inexistente!",
        409:
            errorsMapper[error] ||
            "Você já possui uma entrada de tempo nesse período!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
