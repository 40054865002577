/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";

import { theme } from "../../assets";

type TState = { isFocused?: boolean; isSelected?: boolean };

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8vh 20px 2rem 20px;
    width: 100%;
    max-width: 99rem;
    gap: 2rem;

    div#section-row {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 2rem;
    }
`;

export const BarChartWrapper = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    padding: 2.625rem 2rem;
    gap: 2rem;
`;

export const Activities = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    height: fit-content;

    header#select-wrapper {
        padding: 20px 40px;
    }
`;

export const ActivitiesTime = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    height: fit-content;

    header#work-time {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 5px;
        border-bottom: 1px solid ${({ theme }) => theme.gray200};

        span#text-time {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.gray400};
        }

        span#total-time {
            font-size: 2.125rem;
        }
    }

    div#doughnut-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ActivitiesTable = styled.table`
    display: flex;
    flex-direction: column;

    tr {
        display: grid;
        grid-template-columns: 5fr 1fr 1fr;
        padding: 0 40px;
        align-items: center;
        height: 40px;

        th:first-child {
            span {
                float: left;
            }
        }

        td:not(:first-child) {
            justify-self: center;
        }

        span.heading-text {
            font-size: 0.625rem;
            color: ${({ theme }) => theme.gray400};
            font-weight: 600;
        }
    }

    tbody {
        tr {
            height: 60px;
            border-top: 1px solid ${({ theme }) => theme.gray200};
        }

        tr {
            td {
                display: flex;
                gap: 20px;

                span.time,
                span.percentage {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray800};
                }

                span.description {
                    font-size: 0.875rem;
                    color: ${({ theme }) => theme.gray600};
                }
            }
        }

        td.entry {
            span {
                font-size: 0.875rem;
            }

            span.project {
                color: #0b49ec;
                background: rgba(11, 73, 236, 0.05);
                border: 1px solid rgba(11, 73, 236, 0.1);
                border-radius: 4px;
                padding: 0 15px;
            }
        }

        tr.activity-instance {
            background: ${({ theme }) => theme.gray50};

            td:first-child {
                margin-left: 62px;
            }
        }
    }
`;

export const SelectStyles = {
    control: (styles: any, state: TState) => ({
        ...styles,
        borderRadius: 5,
        color: "#484848",
        fontSize: 14,
        height: 32,
        minHeight: 32,
        border: state.isFocused
            ? `1px solid ${theme.purple500}`
            : `1px solid ${theme.gray400}`,
        boxShadow: state.isFocused ? `0 0 0 1px ${theme.purple500}` : "none",
        "&:hover": {
            border: `1px solid ${theme.purple500}`,
        },
    }),
    container: (styles: any) => ({
        ...styles,
        width: "150px",
        boxSizing: "border-box" as const,
        borderRadius: 5,
        minWidth: "unset",
    }),
    valueContainer: (styles: any) => ({
        ...styles,
        height: 32,
    }),
    placeholder: (styles: any) => ({
        ...styles,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        outline: "none",
    }),
    option: (styles: any, state: TState) => ({
        ...styles,
        fontSize: 14,
        boxSizing: "border-box",
        height: "min-content",
        cursor: "pointer",
        width: "100%",
        backgroundColor: state.isSelected && theme.purple500,
        ":hover": { backgroundColor: !state.isSelected && theme.pink50 },
    }),
    dropdownIndicator: (styles: any) => ({
        ...styles,
        paddingTop: 6,
        paddingBottom: 6,
    }),
    clearIndicator: (styles: any) => ({
        ...styles,
        paddingTop: 6,
        paddingBottom: 6,
    }),
};

export const NoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2.625rem;

    h2 {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: ${({ theme }) => theme.purple800};
    }

    p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.gray600};
    }
`;
