import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    CheckBox,
    DefinePermissionsModal,
    ModalUniqueWithPermissions,
    Tag,
} from "../../../../../../../../../components";
import {
    MenuAtom,
    TMenuAtom,
} from "../../../../../../../../../components/atoms/menu/menu";
import { useUser } from "../../../../../../../../../context";
import { projects } from "../../../../../../../../../services";
import { ReadUserProjectsResponse } from "../../../../../../../../../services/user/types";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { statusOptions } from "../../../../../../../../organization/utils/projects-utils";
import { CustomTooltip } from "../../../tooltip/tooltip";
import * as s from "./styles";

type TableRowProps = {
    data: ReadUserProjectsResponse;
    project: ReadUserProjectsResponse["projects"][0];
};

type Error = {
    message: string;
    body: {
        message: string;
    };
};

export const TableRow = ({ project, data }: TableRowProps) => {
    const { user: authenticatedUser } = useUser();
    const isAdmin = authenticatedUser.Role.name === "Administrador";

    const { state } = useLocation();
    const user = {
        id_e: state?.id,
        name: state?.name,
        isBiller: project.isBiller,
        isReviewer: project.isReviewer,
    };
    const havePermission = data?.hasBillPermission && data?.hasReviewPermission;

    const [defineReviewerPermissionsModal, setDefineReviewerPermissionsModal] =
        useState(false);
    const [defineBillerPermissionsModal, setDefineBillerPermissionsModal] =
        useState(false);
    const [modalUniqueWithPermissions, setModalUniqueWithPermissions] =
        useState(false);

    const updateProject = useMutation({
        mutationKey: ["updateProject"],
        mutationFn: projects.updateProject,
        onSuccess: () => {
            toast.success(`Ação realizada com sucesso.`);
            queryClient.invalidateQueries(["userProjects"]);
        },
        onError: (error: Error) => {
            const isUniqueReviewerError =
                "project need at least one reviewer and biller";
            if (error.body.message === isUniqueReviewerError) {
                setModalUniqueWithPermissions(true);
                return;
            }
            toast.error(error?.message);
        },
    });

    const arrayToIds = (array: string[]) => {
        return array.join(", ");
    };

    const handleDisconnectProject = () => {
        if (!user.id_e || !project || !data) {
            toast.error(`Ocorreu um erro ao atualizar o projeto.`);
            return;
        }
        const updatedReviewers = project.reviewers.filter(
            (reviewer) => reviewer !== user.id_e
        );
        const updatedBillers = project.billers.filter(
            (biller) => biller !== user.id_e
        );
        const updatedUsers = project.users.filter(
            (projectUser) => projectUser !== user.id_e
        );
        updateProject.mutate({
            projectId: project.id_e,
            name: project.name,
            reviewersId: arrayToIds(updatedReviewers),
            billersId: arrayToIds(updatedBillers),
            usersId: arrayToIds(updatedUsers),
        });
    };

    const menuOptions: TMenuAtom["options"] = [
        {
            icon: <Icons.XOutline color={theme.redError} />,
            action: handleDisconnectProject,
            color: theme.redError,
            label: "Desvincular projeto do profissional",
        },
    ];

    const chooseTooltipMessage = (type: "reviewer" | "biller") => {
        switch (type) {
            case "reviewer":
                if (!data?.hasReviewPermission)
                    return `A atuação deste profissional não permite lhe conceder permissão de revisor.`;
                if (project.isReviewer) {
                    return "Remover permissão de revisor.";
                }
                return "Conceder permissão de revisor.";
            default:
                if (!data?.hasBillPermission)
                    return `A atuação deste profissional não permite lhe conceder permissão de faturador.`;
                if (project.isBiller) {
                    return "Remover permissão de faturador.";
                }
                return "Conceder permissão de faturador.";
        }
    };

    const handleMarkCheckbox = (type: "reviewer" | "biller") => {
        if (!user.id_e || !project || !data) {
            toast.error(`Ocorreu um erro ao atualizar o projeto.`);
            return;
        }
        const oppositeType = type === "reviewer" ? "biller" : "reviewer";
        const updatedIds = [...project[`${type}s`], user.id_e];
        const oppositeIds = project[`${oppositeType}s`];
        updateProject.mutate({
            projectId: project.id_e,
            name: project.name,
            [`${type}sId`]: arrayToIds(updatedIds),
            [`${oppositeType}sId`]: arrayToIds(oppositeIds),
            usersId: arrayToIds(project.users),
        });
    };

    const handleUnmarkCheckbox = (type: "reviewer" | "biller") => {
        if (!user.id_e || !project || !data) {
            toast.error(`Ocorreu um erro ao atualizar o projeto.`);
            return;
        }
        const oppositeType = type === "reviewer" ? "biller" : "reviewer";
        const oppositeIds = project[`${oppositeType}s`];
        const updatedIds = project[`${type}s`].filter((id) => id !== user.id_e);
        updateProject.mutate({
            projectId: project.id_e,
            name: project.name,
            [`${type}sId`]: arrayToIds(updatedIds),
            [`${oppositeType}sId`]: arrayToIds(oppositeIds),
            usersId: arrayToIds(project.users),
        });
    };

    return (
        <>
            {defineReviewerPermissionsModal && (
                <DefinePermissionsModal
                    setOpen={setDefineReviewerPermissionsModal}
                    data={{
                        type: "reviewer",
                        user,
                    }}
                    removePermission={project.isReviewer}
                    handleCheckbox={() => {
                        if (project.isReviewer) {
                            handleUnmarkCheckbox("reviewer");
                            return;
                        }
                        handleMarkCheckbox("reviewer");
                    }}
                />
            )}
            {defineBillerPermissionsModal && (
                <DefinePermissionsModal
                    setOpen={setDefineBillerPermissionsModal}
                    data={{
                        type: "biller",
                        user,
                    }}
                    removePermission={project.isBiller}
                    handleCheckbox={() => {
                        if (project.isBiller) {
                            handleUnmarkCheckbox("biller");
                            return;
                        }
                        handleMarkCheckbox("biller");
                    }}
                />
            )}
            {modalUniqueWithPermissions && (
                <ModalUniqueWithPermissions
                    projectName={project.name}
                    onOpenChange={setModalUniqueWithPermissions}
                />
            )}
            <s.Container key={project.id_e}>
                <s.ProjectNameCell havePermission={havePermission}>
                    <Tag color={project.color}>{project.name}</Tag>
                </s.ProjectNameCell>
                <s.ProjectSectorsCell havePermission={havePermission}>
                    <p>{arrayToIds(project.sectors.map(({ name }) => name))}</p>
                </s.ProjectSectorsCell>
                {havePermission && (
                    <>
                        <s.ProjectReviewerBillerCell
                            disabled={!data.hasReviewPermission}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (data.hasReviewPermission) {
                                    setDefineReviewerPermissionsModal(true);
                                }
                            }}
                        >
                            <CustomTooltip
                                side="left"
                                positionx={30}
                                positiony={24}
                                text={chooseTooltipMessage("reviewer")}
                            >
                                <CheckBox
                                    id={`reviewer`}
                                    key={`${project.id_e}-reviewer-${project.isReviewer}`}
                                    width={16}
                                    height={16}
                                    isChecked={project.isReviewer}
                                    disabled={!data.hasReviewPermission}
                                />
                            </CustomTooltip>
                        </s.ProjectReviewerBillerCell>
                        <s.ProjectReviewerBillerCell
                            disabled={!data.hasBillPermission}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (data.hasBillPermission) {
                                    setDefineBillerPermissionsModal(true);
                                }
                            }}
                        >
                            <CustomTooltip
                                side="left"
                                positionx={30}
                                positiony={25}
                                text={chooseTooltipMessage("biller")}
                            >
                                <CheckBox
                                    id={`biller`}
                                    key={`${project.id_e}-biller-${project.isBiller}`}
                                    width={16}
                                    height={16}
                                    isChecked={project.isBiller}
                                    disabled={!data.hasBillPermission}
                                />
                            </CustomTooltip>
                        </s.ProjectReviewerBillerCell>
                        <s.StatusCell>
                            {
                                statusOptions.find(
                                    ({ value }) => value === project.status
                                )?.label
                            }
                        </s.StatusCell>
                    </>
                )}
                {isAdmin && (
                    <MenuAtom options={menuOptions} width="289px">
                        <s.MoreWrapper className="more-menu">
                            <Icons.More />
                        </s.MoreWrapper>
                    </MenuAtom>
                )}
            </s.Container>
        </>
    );
};
