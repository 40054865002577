export const formatStringNumberToMoney = (value?: string) =>
    value
        ? parseFloat(value)
              .toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                  minimumFractionDigits: 2,
              })
              .replace(".", ",")
        : "-";
