import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import * as s from "./styled-reports-options";

export const ManageReportsOptions = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { verifyPermission } = useUser();
    const pathLocation = window.location.pathname;
    if (pathLocation === "/relatorio/gerenciar") {
        navigate("/relatorio/gerenciar/revisar");
    }

    const manageOptions = pathLocation === "/relatorio/gerenciar/revisar";
    const billingOptions = pathLocation === "/relatorio/gerenciar/faturar";

    const billingPermission = verifyPermission({
        id: "bill_reports",
        rule: "enabled",
    });

    const reviewPermission = verifyPermission({
        id: "review_reports",
        rule: "enabled",
    });

    return (
        <s.Container>
            {(manageOptions || billingOptions) &&
                (reviewPermission || billingPermission) && (
                    <s.OptionsManage>
                        {reviewPermission && (
                            <div
                                onClick={() =>
                                    navigate("/relatorio/gerenciar/revisar")
                                }
                            >
                                <Icons.DocumentText
                                    color={
                                        location.pathname ===
                                        "/relatorio/gerenciar/revisar"
                                            ? theme.purple600
                                            : theme.gray200
                                    }
                                    title="Histórico"
                                />

                                <s.OptionTextManage
                                    active={
                                        location.pathname ===
                                        "/relatorio/gerenciar/revisar"
                                    }
                                >
                                    REVISAR
                                </s.OptionTextManage>
                            </div>
                        )}
                        {billingPermission && (
                            <div
                                onClick={() =>
                                    navigate("/relatorio/gerenciar/faturar")
                                }
                            >
                                <Icons.Dollar
                                    color={
                                        location.pathname ===
                                        "/relatorio/gerenciar/faturar"
                                            ? theme.purple600
                                            : theme.gray200
                                    }
                                    title="Histórico"
                                    width="18px"
                                    height="18px"
                                />

                                <s.OptionTextManage
                                    active={
                                        location.pathname ===
                                        "/relatorio/gerenciar/faturar"
                                    }
                                >
                                    FATURAR
                                </s.OptionTextManage>
                            </div>
                        )}
                    </s.OptionsManage>
                )}
        </s.Container>
    );
};
