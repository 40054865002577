/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";

import { useFetch } from "../hooks";
import { TGetProject } from "../models";
import { manageUser, projects } from "../services";
import { Auth } from "../services/auth/Authentication";
import { DEFAULT_TRIAL_DAYS } from "../utils/constants/free-days";
import { TPermissionsList } from "../utils/permissions/types";

type TProviderChildren = {
    children: ReactNode;
};

export type TProject = {
    color: string;
    name: string;
    filiation: string;
    id_e: string;
    Sectors: { id_e: string; name: string }[];
    archived: boolean;
};

type TOrganization = {
    name?: string;
    id_e?: string;
    monthly_hour_limit?: number;
    Subscription?: {
        plan?: string;
        created_at?: string;
        status?: string;
        number_of_users?: number;
        trial_days?: number;
    };
    OrganizationSettings?: {
        report_model: string;
    };
};

export type TRuleSwitch = {
    enabled: string[];
    disabled: string[];
};

export type TRuleAccessControl = {
    view: string[];
    manage: string[];
    hide?: string[];
};

type TPermission = {
    id: string;
    type: "switch" | "access_control";
    rules: TRuleSwitch | TRuleAccessControl;
    classification: "management" | "finance" | "total";
};

type TVerifyPermission = {
    id: TPermissionsList[0]["id"];
    rule: keyof TRuleSwitch | keyof TRuleAccessControl;
};

type UserAdditionalHours = Array<{
    id_e: string;
    hours: number;
    created_at: string;
    status: "accepted" | "refused" | "requested";
}>;

const user = {
    Organization:
        {
            id_e: "",
            name: "",
            Subscription: [
                {
                    plan: "",
                    created_at: "",
                    status: "",
                    trial_days: DEFAULT_TRIAL_DAYS,
                },
            ],
            OrganizationSettings: {
                report_model: "",
                receive_emails: true,
            },
        } || null,
    Role: {
        description: "",
        id_e: "",
        name: "",
    },
    Sectors: [{ id_e: "", name: "", description: "" }],
    UsersConfigs: {
        groupTasks: false,
        clearTaskFieldsAfterAdd: false,
        dailyGoal: 0,
    },
    UserProjectActivityFavorite:
        [
            {
                project: {
                    name: null,
                    id_e: null,
                },
                activity: {
                    id_e: null,
                    name: null,
                },
            },
        ] || null,
    Permissions: [""],
    monthlyTime: 0,
    address: "",
    admissionDate: null,
    areasOfInterest: "",
    avatar: null,
    birthDate: "",
    celNumber: "",
    cep: "",
    city: "",
    cnpj: "",
    complement: "",
    contract: "",
    created_at: "",
    dietaryRestrictions: "",
    education: "",
    email: "",
    hourValue: null,
    houseNumber: "",
    id_e: "",
    id_i: 0,
    name: "",
    password: "",
    pis: null,
    rg: null,
    skills: "",
    state: "",
    terms: true,
    updated_at: "",
    workFacilities: "",
    workload: null,
    netPromoterScore: {
        description: "",
        userParticipation: true,
    },
    permissionsList: [] as TPermission[],
    UserSkills: [],
    UserAdditionalHours: [] as UserAdditionalHours,
};

const workedHours = {
    total: 0,
    limit: 0,
};

const initialValue = {
    user,
    setUser: (value: typeof user) => {},
    isLogged: false,
    setIsLogged: (value: boolean) => {},
    permissions: [] as string[],
    setPermissions: (value: string[]) => {},
    organization: {} as TOrganization | null,
    setOrganization: (value: TOrganization) => {},
    userProjects: [] as TProject[],
    setUserProjects: (value: TProject[]) => {},
    workedHours,
    setWorkedHours: (value: typeof workedHours) => {},
    haveOrganization: false,
    setHaveOrganization: (value: boolean) => {},
    refreshUser: () => {},
    refreshProject: (params?: TGetProject) => {},
    verifyPermission: (params: TVerifyPermission) => {
        return false;
    },
    isAdministrator: false,
    favoriteProject: undefined as TProject | undefined,
    favoriteActivity: undefined as { id_e: null; name: null } | undefined,
};

export const UserContext = createContext<typeof initialValue>(initialValue);

export const UserProvider = ({ children }: TProviderChildren) => {
    const [user, setUser] = useState(initialValue.user);
    const [isLogged, setIsLogged] = useState(Boolean(Auth.getInstance().token));
    const [permissions, setPermissions] = useState(initialValue.permissions);
    const [organization, setOrganization] = useState(initialValue.organization);
    const [userProjects, setUserProjects] = useState(initialValue.userProjects);
    const [workedHours, setWorkedHours] = useState(initialValue.workedHours);
    const [permissionsList, setPermissionsList] = useState<TPermission[]>([]);
    const isAdministrator = user.Role.name === "Administrador";

    const validateOrganization = () => {
        if (localStorage.getItem("haveOrganization") === null) return false;
        if (localStorage.getItem("haveOrganization") === "false") return false;
        return true;
    };
    const haveOrganizationData =
        validateOrganization() || initialValue.haveOrganization;

    const [haveOrganization, setHaveOrganization] =
        useState<boolean>(haveOrganizationData);

    const getUser = useFetch({
        fn: manageUser.getUser,
        start: false,
    });

    const getProjects = useFetch({
        fn: projects.getProject,
        start: false,
    });

    const favoriteProject = userProjects.find((project) => {
        return (
            project.id_e ===
            user.UserProjectActivityFavorite?.[0]?.project?.id_e
        );
    });
    const favoriteActivity = user.UserProjectActivityFavorite?.[0]?.activity;

    const verifyToken = () => {
        getUser.onRefresh();
    };

    const verifyLogin = () => {
        const { token } = Auth.getInstance();

        if (!token) {
            setIsLogged(false);
        } else {
            setIsLogged(true);
            verifyToken();
        }
    };

    const verifyPermission = ({ id, rule }: TVerifyPermission): boolean => {
        const permission = permissionsList.find(
            (permission) => permission.id === id
        );
        if (!permission) {
            return false;
        }
        const rules: string[] =
            permission.rules[rule as keyof TPermission["rules"]];
        return rules.every((rule) => permissions.includes(rule));
    };

    useEffect(() => {
        verifyLogin();
    }, []);

    useEffect(() => {
        setWorkedHours({
            limit: (user?.workload ?? 1) * 60,
            total: user?.monthlyTime ?? 0,
        });
    }, [user]);

    useEffect(() => {
        const hasUser = getUser.response || user;
        const hasProjectsOrIsLoading =
            getProjects.response || getProjects.loading;
        const isLoginPageOrHome =
            window.location.pathname === "/" ||
            window.location.pathname === "/login" ||
            window.location.pathname === "/registrar-empresa";
        if (hasUser && !hasProjectsOrIsLoading && !isLoginPageOrHome) {
            getProjects.onRefresh({
                sectors: true,
                showOnlyActiveProjects: true,
            });
        }

        if (getUser.response) {
            setIsLogged(true);
            setPermissions(getUser.response.Permissions);

            setWorkedHours({
                total: getUser.response.monthlyTime,
                limit: (getUser.response?.workload ?? 1) * 60,
            });
            setPermissionsList(getUser.response.permissionsList);
        }
        if (user) {
            setPermissionsList(user.permissionsList);
        }
    }, [getUser.response, user]);

    useEffect(() => {
        if (getUser.response) {
            setOrganization({
                ...getUser.response.Organization,
                Subscription: getUser.response.Organization.Subscription?.[0],
            });
            setUser({
                ...getUser.response,
                UserSkills: getUser.response.UserSkill,
                Organization: getUser.response.Organization,
                UserProjectActivityFavorite:
                    getUser.response.UserProjectActivityFavorite,
            });
        }
    }, [getUser.response]);

    useEffect(() => {
        if (getUser.error) setIsLogged(false);
    }, [getUser.error]);

    useEffect(() => {
        if (getProjects.response) {
            setUserProjects(getProjects.response.projects);
        }
    }, [getProjects.response]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                isLogged,
                setIsLogged,
                permissions,
                setPermissions,
                organization,
                setOrganization,
                userProjects,
                setUserProjects,
                workedHours,
                setWorkedHours,
                haveOrganization,
                setHaveOrganization,
                refreshUser: getUser.onRefresh,
                refreshProject: getProjects.onRefresh,
                verifyPermission,
                isAdministrator,
                favoriteProject,
                favoriteActivity,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export function useUser() {
    const context = useContext(UserContext);

    return context;
}
