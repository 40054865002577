import { Icons, theme } from "../../../../../../../assets";
import { PopoverRadix } from "../../../../../../../components";
import { TResponse } from "../../../../../../../models";
import { TArchiveSection } from "../../../../../../../services/activities/archive-section";
import { TUnarchiveSection } from "../../../../../../../services/activities/unarchive-section";
import { TSection } from "../../../types";
import * as s from "../styled-section";

type TSectionHeader = {
    archiveSection?: TResponse<unknown, TArchiveSection>;
    unarchiveSection?: TResponse<unknown, TUnarchiveSection>;
    setDeleteConfirmationSectionId: React.Dispatch<
        React.SetStateAction<string>
    >;
    openedSectionsIds: string[];
    index: number;
    archive?: boolean;
    section: TSection;
    toggleSectionVisibility: (sectionId: string) => void;
    toggleSectionEdit: (index: number) => void;
};

export const SectionHeader = ({
    archiveSection,
    unarchiveSection,
    setDeleteConfirmationSectionId,
    toggleSectionVisibility,
    openedSectionsIds,
    index,
    section,
    archive,
    toggleSectionEdit,
}: TSectionHeader) => {
    const handleArchiveSection = (sectionId: string) => {
        archiveSection?.onRefresh({
            sectionId,
        });
    };

    const handleUnarchiveSection = (sectionId: string) => {
        unarchiveSection?.onRefresh({
            sectionId,
        });
    };

    return (
        <s.ActivitySection
            onClick={() => toggleSectionVisibility(section.id_e)}
            isOpen={openedSectionsIds.includes(section.id_e)}
        >
            <div className="left">
                <Icons.Drag className="hidden" />
                <Icons.Chevron className="chevron" color={theme.gray600} />
                <div className="left-align">{section.name}</div>
            </div>
            <div
                className="right"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <PopoverRadix
                    align="end"
                    trigger={
                        <s.More className="hidden">
                            <Icons.More />
                        </s.More>
                    }
                >
                    <s.MenuContainer>
                        {!archive && (
                            <s.MenuOption
                                edit
                                onClick={() => toggleSectionEdit(index)}
                            >
                                <Icons.PencilOutline color={theme.gray800} />
                                Editar
                            </s.MenuOption>
                        )}
                        {archive ? (
                            <s.MenuOption
                                onClick={() =>
                                    handleUnarchiveSection(section.id_e)
                                }
                            >
                                <Icons.Unarchive color={theme.gray600} />
                                Desarquivar
                            </s.MenuOption>
                        ) : (
                            <s.MenuOption
                                onClick={() =>
                                    handleArchiveSection(section.id_e)
                                }
                            >
                                <Icons.IconArchive color={theme.gray600} />
                                Arquivar
                            </s.MenuOption>
                        )}
                        <s.MenuOption
                            danger
                            onClick={() => {
                                setDeleteConfirmationSectionId(section.id_e);
                            }}
                        >
                            <Icons.Trash color={theme.red} />
                            Deletar
                        </s.MenuOption>
                    </s.MenuContainer>
                </PopoverRadix>
            </div>
        </s.ActivitySection>
    );
};
