import {
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../components";
import ContainerModal from "../../../../../../../components/molecules/modals/container-modal";
import * as s from "./styles";

export type TDeleteConfirmation = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    onConfirm: () => void;
};

export const DeleteConfirmationModal = ({
    setOpen,
    message,
    onConfirm,
}: TDeleteConfirmation) => {
    return (
        <ContainerModal>
            <s.Container
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setOpen(false);
                    }
                    if (e.key === "Enter") {
                        onConfirm();
                    }
                }}
            >
                <s.Title>Deletar {message}</s.Title>
                <s.Description>
                    Ao deletar {message}, esta ação não poderá ser desfeita.
                </s.Description>
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setOpen(false);
                            onConfirm();
                        }}
                    >
                        DELETAR
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
