import styled from "styled-components";

import { Icons } from "../../../../../../assets";

export const Container = styled.section`
    background: var(--color-neutral-white-02, #fcfcfc);
    border: 1px solid var(--color-gray-200, #cfcdd3);
    border-radius: 4px;
    padding: 16px;

    &:first-child {
        margin-top: 32px;
    }

    &:not(:first-child) {
        margin-top: 20px;
    }
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ReportInformationContainer = styled.div`
    display: flex;
    gap: 32px;
`;

export const ReportInformation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const Title = styled.h4`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
`;

export const Information = styled.span`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Chevron = styled(Icons.Chevron)<{ open: boolean }>`
    color: ${({ theme }) => theme.gray600};
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
`;

export const DescriptionContainer = styled.div<{ open?: boolean }>`
    visibility: hidden;
    transition: all 0.2s;
    opacity: 0;
    height: 0;

    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;

    ${({ open }) =>
        open &&
        `
        height: fit-content;
        margin-top: 32px;
        visibility: visible;
        opacity: 1;
    `}
`;

export const TooltipContent = styled.div`
    padding: 8px;
`;
