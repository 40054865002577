import styled from "styled-components";

export const BreadCrumb = styled.div`
    cursor: default;
    display: flex;
    margin-top: 3.5rem;
    justify-content: space-between;
    position: relative;

    h2 {
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: ${({ theme }) => theme.gray400};
    }
`;
export const TimeLimit = styled.div`
    cursor: default;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const TimeLimitWarningTooltip = styled.span`
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 11px;
    width: 412px;
    margin-top: 0.8125rem;

    header {
        display: flex;
        align-items: center;
        gap: 12px;
        color: ${({ theme }) => theme.gray800};
        font-weight: 600;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    p {
        color: var(--color-gray-600, #6e697a);
        font-size: 12px;
        font-weight: 400;
    }
`;

export const Flex = styled.div`
    display: flex;
    align-items: center;
`;

export const TimeLimitWarning = styled.div`
    cursor: default;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.yellow};
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    gap: 12px;

    p {
        font-size: 16px;
        color: ${({ theme }) => theme.gray800};
    }

    svg {
        width: 24px;
        height: 24px;
    }
`;
