import * as Accordion from "@radix-ui/react-accordion";
import styled, { keyframes } from "styled-components";

import { theme } from "../../../assets";

export const ContentTable = styled.section`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
    cursor: default;

    span.no-reports {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        color: ${({ theme }) => theme.gray500};
        border-top: 1px solid ${({ theme }) => theme.gray200};
    }

    div.table-header {
        background: ${({ theme }) => theme.purple50};
        border-radius: 4px 4px 0 0;

        span.heading-text {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            font-size: 1rem;
            font-weight: 500;
            color: ${({ theme }) => theme.purple800};
        }
    }

    div.table-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        height: 48px;
        place-items: center;
    }

    div.rows {
        div.table-row {
            height: 60px;
            border-top: 1px solid ${({ theme }) => theme.gray200};

            span.body-text {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.gray900};
            }

            div.buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;
                padding: 0 10px 0 45%;

                span.options {
                    display: flex;
                    opacity: 0;
                    width: 25px;
                    height: 25px;
                    align-items: center;
                    justify-content: center;
                }
            }

            &:hover {
                background: ${({ theme }) => theme.gray50};

                div.buttons {
                    span.options {
                        opacity: 1;

                        &:hover {
                            svg {
                                path {
                                    stroke: ${({ theme }) => theme.pink600};
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Form = styled.form`
    align-self: center;
    width: 40.9375rem;

    .switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
`;

const slideDown = keyframes`
 from{ height: 0};
 to{ height: var(--radix-accordion-content-height)};
`;

const slideUp = keyframes`
    from { height: var(--radix-accordion-content-height) };
    to { height: 0 };
`;

export const AccordionRoot = styled(Accordion.Root)`
    border-radius: 4px;
    padding: 13px 24px;
    width: 300px;
    border: 1px solid ${theme.gray200};
`;

export const AccordionItem = styled(Accordion.Item)``;

export const AccordionHeader = styled(Accordion.Header)``;

export const AccordionTrigger = styled(Accordion.Trigger)`
    font-size: 14px;
    font-weight: 400;
    color: ${theme.gray800};
`;

export const AccordionContent = styled(Accordion.Content)`
    &[data-state="open"] {
        animation: ${slideDown} 500ms;
    }
    &[data-state="closed"] {
        animation: ${slideUp} 300ms;
    }
`;
