import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TReportDocs = {
    recipientsIds?: string;
    reportId?: string;
    data?: FormData;
};

type TError = "Unexpected file type";

export default async ({ recipientsIds, reportId, data }: TReportDocs = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeReport/reportDocs`,
        method: "patch",
        body: data,
        headers: {
            auth: true,
            "x-report-id": reportId,
            "x-recipients": recipientsIds,
        },
    });
    const { body, statusCode } = httpResponse;

    const error: TError = body?.message?.error;

    const errorsMapper = {
        "Unexpected file type":
            "Tipo de arquivo não suportado, por favor, somente PDF",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Não foi possível enviar os documentos!",
        401: "Acesso negado",
        404: "Relatório não existente!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
