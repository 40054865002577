import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: -20px;

    .input-wrapper {
        max-width: 30%;
    }

    .table-header {
        border-bottom: 1px solid ${({ theme }) => theme.gray200};
        text-align: left;
        padding-right: 34px;
    }

    .table-body {
        max-height: 47vh;
        overflow-y: scroll;

        .table-row {
            text-align: left;

            &:first-child {
                border-top: none;
            }
        }
    }

    .projects {
        width: fit-content;
        padding: 10px 20px;
        border-radius: 4px;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid ${({ theme }) => theme.gray200};
        }
    }
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        width: 200px;
    }
`;

export const Status = styled.td<{ $active?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 14px;
    background: ${({ $active }) => ($active ? "#2780270D" : "#F9F9F9")};
    border-radius: 8px;
    color: ${({ $active, theme }) =>
        $active ? theme.green1 : "#D14141"} !important;
    font-weight: 600;
    width: fit-content;
    padding: 2px 20px;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;

export const FillerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.purple800};
`;

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
`;
