import { theme } from "..";

type TArrow = {
    width?: string;
    height?: string;
    direction: "up" | "down" | "left" | "right";
    color?: string;
};

export default ({ width, height, direction, color }: TArrow) => {
    let rotation = "0deg";

    if (direction === "up") {
        rotation = "180deg";
    } else if (direction === "left") {
        rotation = "90deg";
    } else if (direction === "right") {
        rotation = "-90deg";
    }

    return (
        <span style={{ transform: `rotate(${rotation})`, display: "flex" }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width || "24"}
                height={height || "24"}
                viewBox="0 0 24 24"
                fill="none"
            >
                <mask
                    id="mask0_23255_12038"
                    style={{
                        maskType: "alpha",
                    }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                >
                    <rect width="24" height="24" fill={theme.white3} />
                </mask>
                <g mask="url(#mask0_23255_12038)">
                    <path
                        d="M11.2501 15.5462V6.74998C11.2501 6.53716 11.3218 6.35896 11.4654 6.21538C11.609 6.07179 11.7872 6 12 6C12.2128 6 12.391 6.07179 12.5346 6.21538C12.6782 6.35896 12.75 6.53716 12.75 6.74998V15.5462L16.0731 12.2231C16.2116 12.0846 16.3856 12.0138 16.5952 12.0106C16.8048 12.0074 16.9821 12.0782 17.1269 12.2231C17.2718 12.3679 17.3443 12.5436 17.3443 12.75C17.3443 12.9564 17.2718 13.132 17.1269 13.2769L12.6327 17.7711C12.4519 17.9519 12.241 18.0423 12 18.0423C11.759 18.0423 11.5481 17.9519 11.3674 17.7711L6.87313 13.2769C6.73466 13.1384 6.66383 12.9644 6.66063 12.7548C6.65741 12.5452 6.72824 12.3679 6.87313 12.2231C7.01799 12.0782 7.19363 12.0058 7.40003 12.0058C7.60643 12.0058 7.78206 12.0782 7.92693 12.2231L11.2501 15.5462Z"
                        fill={color || theme.gray800}
                    />
                </g>
            </svg>
        </span>
    );
};
