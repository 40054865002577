import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 32px;
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.pink600};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.03px;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.gray400};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const ChallengesContainer = styled.div``;
