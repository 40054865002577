import { pdf } from "@react-pdf/renderer";
import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { createRef, useEffect, useMemo, useState } from "react";
import {
    DragDropContext,
    DragUpdate,
    DraggableLocation,
    DropResult,
} from "react-beautiful-dnd";
import { DateRange } from "react-day-picker";
import toast from "react-hot-toast";
import Select from "react-select";

import { FotoUser, Icons, theme } from "../../../../assets";
import {
    ActivitiesReport,
    PopoverCalendar,
    PopoverRadix,
    SecondaryButton,
} from "../../../../components";
import { useFetch } from "../../../../hooks";
import { TProject } from "../../../../models";
import { activities } from "../../../../services";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import { AddActivity } from "./components/add-activity";
import { AddSection } from "./components/add-section";
import { Archive } from "./components/archive/archive";
import { Sections } from "./components/sections/sections";
import { AddCollaboratorsModal } from "./modals/add-collaborators/add-collaborators-modal";
import { DeleteConfirmationActivity } from "./modals/delete-confirmation-activity/delete-confirmation-activity";
import { DeleteConfirmationSection } from "./modals/delete-confirmation-section/delete-confirmation-section";
import * as s from "./styled-tab-activities";
import {
    TSectionActivity,
    TSection,
    TReadActivities,
    TOptions,
    TActivityIdsRef,
} from "./types";

type TTabActivities = {
    loading: boolean;
    project: TProject;
};

export const TabActivities = ({ project }: TTabActivities) => {
    const [isToCreateSection, setIsToCreateSection] = useState<boolean>(false);
    const [activityToAddCollaborators, setActivityToAddCollaborators] =
        useState<TSectionActivity | undefined>(undefined);
    const [deleteConfirmationActivityId, setDeleteConfirmationActivityId] =
        useState<string>("");
    const [deleteConfirmationSectionId, setDeleteConfirmationSectionId] =
        useState<string>("");
    const [updateActivityStates, setUpdateActivityStates] = useState<string[]>(
        []
    );
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [activitiesOptions, setActivitiesOptions] = useState<TOptions>([]);
    const [openedSectionsIds, setOpenedSectionsIds] = useState<string[]>([]);
    const [archiveOpen, setArchiveOpen] = useState<boolean>(false);
    const [draggingDestination, setDraggingDestination] =
        useState<DragUpdate["destination"]>();
    const [generatePdfLoading, setGeneratePdfLoading] = useState(false);
    const [period, setPeriod] = useState<DateRange>({
        from: undefined,
        to: undefined,
    });
    const [usersFilter, setUsersFilter] = useState<string[]>([]);

    const getSections = useQuery({
        queryKey: ["getSections"],
        queryFn: () =>
            activities.getSections({
                projectId: project.id_e,
                startDate: period.from?.toISOString(),
                endDate: period.to?.toISOString(),
                userIds: usersFilter.join(","),
            }),
    });

    const createSection = useFetch({
        fn: activities.createSection,
        start: false,
    });

    const createActivity = useFetch({
        fn: activities.createActivity,
        start: false,
    });

    const updateActivity = useFetch({
        fn: activities.updateActivity,
        start: false,
    });

    const updateSection = useFetch({
        fn: activities.updateSection,
        start: false,
    });

    const archiveActivity = useFetch({
        fn: activities.archiveActivity,
        start: false,
    });

    const unarchiveActivity = useFetch({
        fn: activities.unarchiveActivity,
        start: false,
    });

    const deleteActivity = useFetch({
        fn: activities.deleteActivity,
        start: false,
    });

    const deleteSection = useFetch({
        fn: activities.deleteSection,
        start: false,
    });

    const toggleActivityStatus = useFetch({
        fn: activities.toggleActivityStatus,
        start: false,
    });

    const archiveSection = useFetch({
        fn: activities.archiveSection,
        start: false,
    });

    const unarchiveSection = useFetch({
        fn: activities.unarchiveSection,
        start: false,
    });

    const duplicateActivity = useFetch({
        fn: activities.duplicateActivity,
        start: false,
    });

    const readActivities = useFetch({
        fn: activities.readActivities,
        start: false,
    });

    const sections: TSection[] = getSections.data?.sections ?? [];
    const archivedSection: TSection | undefined =
        getSections.data?.archive?.archiveSection;
    const archivedSections: TSection[] | undefined =
        getSections.data?.archive?.archivedSections;
    const activitiesWithoutSection: TSectionActivity[] =
        getSections.data?.activitiesWithoutSection ?? [];

    /*     const handleGameficationSocket = (data: GameficationChallenge) => {
        if (!data) return;
        if (data.finished_type !== "challenge") return;
        const { description } = data.challenge_completed;
        if (description === "Entrar nas atividades de um projeto") {
            const audio = new Audio(
                // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
                require("../../../../assets/sounds/challenge-completed.mp3")
            );
            audio.play();
            toast.success(
                `Você acessou o mural de atividades de um projeto e ganhou ${data.challenge_completed.points} pts!`,
                challengeDoneToastOptions
            );
        }
        refetchDailyMission();
    };

    useEffect(() => {
        if (!user) return () => ({});
        const key = `gamefication-${user.id_e}`;
        socket.on(key, handleGameficationSocket);
        return () => {
            socket.off(key);
        };
    }, [user]); */

    useEffect(() => {
        if (createSection.response) {
            getSections.refetch();
            toast.success("Sessão criada com sucesso!");
        }
    }, [createSection.response]);

    useEffect(() => {
        if (createActivity.response) {
            getSections.refetch();
            toast.success("Atividade criada com sucesso!");
        }
    }, [createActivity.response]);

    useEffect(() => {
        if (updateActivity.response) {
            getSections.refetch();
            setActivityToAddCollaborators(undefined);
            toast.success("Atividade atualizada com sucesso!");
        }
    }, [updateActivity.response]);

    useEffect(() => {
        if (updateSection.response) {
            getSections.refetch();
            toast.success("Sessão atualizada com sucesso!");
        }
    }, [updateSection.response]);

    useEffect(() => {
        if (archiveActivity.response) {
            getSections.refetch();
            toast.success("Atividade arquivada com sucesso!");
        }
    }, [archiveActivity.response]);

    useEffect(() => {
        if (unarchiveActivity.response) {
            getSections.refetch();
            toast.success("Atividade desarquivada com sucesso!");
        }
    }, [unarchiveActivity.response]);

    useEffect(() => {
        if (deleteActivity.response) {
            getSections.refetch();
            toast.success("Atividade deletada com sucesso!");
        }
    }, [deleteActivity.response]);

    useEffect(() => {
        if (deleteSection.response) {
            getSections.refetch();
            toast.success("Sessão deletada com sucesso!");
        }
    }, [deleteSection.response]);

    useEffect(() => {
        if (toggleActivityStatus.response) {
            const { finished } = toggleActivityStatus.response;
            const message = finished
                ? "finalizada"
                : "desmarcada como finalizada";
            getSections.refetch();
            toast.success(`Atividade ${message}!`);
        }
    }, [toggleActivityStatus.response]);

    useEffect(() => {
        if (archiveSection.response) {
            getSections.refetch();
            toast.success("Sessão arquivada com sucesso!");
        }
    }, [archiveSection.response]);

    useEffect(() => {
        if (unarchiveSection.response) {
            getSections.refetch();
            toast.success("Sessão desarquivada com sucesso!");
        }
    }, [unarchiveSection.response]);

    useEffect(() => {
        if (duplicateActivity.response) {
            getSections.refetch();
            setUpdateActivityStates([
                ...updateActivityStates,
                duplicateActivity.response.id_e,
            ]);
            toast.success("Atividade duplicada com sucesso!");
        }
    }, [duplicateActivity.response]);

    useEffect(() => {
        const delay = setTimeout(() => {
            if (searchTerm === undefined) return;
            if (searchTerm === "") {
                setActivitiesOptions([]);
                return;
            }
            readActivities.onRefresh({
                projectId: project.id_e,
                search: searchTerm,
                startDate: period.from?.toISOString(),
                endDate: period.to?.toISOString(),
            });
        }, 500);

        return () => clearTimeout(delay);
    }, [searchTerm]);

    const readActivitiesResponseToOptions = (
        response: TReadActivities
    ): TOptions => {
        const noArchivedSections = response.noArchived?.sections;
        const archivedSections = response.archived?.sections;
        const sectionsOptions = [
            ...(noArchivedSections?.map((section) => ({
                label: section.sectionName,
                options: section.activities?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })),
            })) ?? []),
            ...(archivedSections?.map((section) => ({
                label: section.sectionName,
                options: section.activities?.map((activity) => ({
                    label: activity.name,
                    value: activity.id_e,
                })),
            })) ?? []),
        ];
        const noArchivedActivitiesWithoutSection =
            response.noArchived?.activities;
        const archivedActivitiesWithoutSection = response.archived?.activities;
        const withoutSectionOptions = [
            {
                label: "Sem sessão",
                options: [
                    ...(noArchivedActivitiesWithoutSection?.map((activity) => ({
                        label: activity.name,
                        value: activity.id_e,
                    })) ?? []),
                    ...(archivedActivitiesWithoutSection?.map((activity) => ({
                        label: activity.name,
                        value: activity.id_e,
                    })) ?? []),
                ],
            },
        ];
        const options = [...sectionsOptions, ...withoutSectionOptions];
        return options;
    };

    useEffect(() => {
        if (readActivities.response) {
            const { response }: { response: TReadActivities } = readActivities;
            const options = readActivitiesResponseToOptions(response);
            setActivitiesOptions(options);
        }
    }, [readActivities.response]);

    const mapSectionId = (sectionId: string) => {
        if (sectionId.includes("archive")) return archivedSection?.id_e;
        return sectionId;
    };

    const chooseDestinationPosition = ({
        droppableId,
        index,
    }: DraggableLocation) => {
        if (droppableId.includes("withoutSection")) return index + 1;
        return index || 1;
    };

    const reorderActivity = (result: DropResult) => {
        setDraggingDestination(undefined);
        const { draggableId, destination, type, source } = result;

        if (
            !destination ||
            destination.index === null ||
            destination.index === undefined
        )
            return;

        if (type !== "section") {
            updateActivity.onRefresh({
                position: chooseDestinationPosition(destination),
                activityIds: [draggableId],
                sectionId: mapSectionId(destination.droppableId),
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            updateSection.onRefresh({
                position: destination.index + 1,
                sectionId: draggableId,
            });
            return;
        }

        if (destination.droppableId.includes("archiveSections")) {
            archiveSection.onRefresh({
                sectionId: draggableId,
            });
            return;
        }

        if (destination.droppableId.includes("withoutSectionSections")) {
            unarchiveSection.onRefresh({
                sectionId: draggableId,
            });
        }
    };

    const formatOptionLabel = (
        option: {
            label: string;
            value: string;
        },
        { inputValue }: { inputValue: string }
    ) => {
        const escapedInputValue = inputValue.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
        );
        const parts = option.label.split(
            new RegExp(`(${escapedInputValue})`, "gi")
        );
        return (
            <div
                style={{
                    cursor: "pointer",
                    height: "100%",
                }}
            >
                {parts.map((part: string, index: number) =>
                    part.toLowerCase().includes(inputValue.toLowerCase()) ? (
                        <span
                            key={index}
                            style={{
                                backgroundColor: "#e5d6e8",
                            }}
                        >
                            {part}
                        </span>
                    ) : (
                        <span key={index}>{part}</span>
                    )
                )}
            </div>
        );
    };

    const getAllActivities = (): TSectionActivity[] => {
        const allSections = [
            ...sections,
            archivedSection,
            ...(archivedSections ?? []),
        ];
        return allSections
            .flatMap((section) => Object.values(section?.Activities ?? {}))
            .concat(activitiesWithoutSection);
    };

    const activitiesIdsRef = useMemo(() => {
        const refs = {} as TActivityIdsRef;
        getAllActivities()?.forEach((item) => {
            if (!item) return;
            refs[item?.id_e] = createRef();
        });
        return refs;
    }, [sections]);

    const goToActivity = (activityId: string) => {
        const section = sections.find((section) =>
            Object.values(section?.Activities)?.find(
                (activity) => activity.id_e === activityId
            )
        );
        const activity = getAllActivities().find(
            (activity) => activity.id_e === activityId
        );
        if (section) {
            setOpenedSectionsIds((prevOpenedSectionsIds) => [
                ...prevOpenedSectionsIds,
                section.id_e,
            ]);
        }
        if (activity?.isArchived) {
            setArchiveOpen(true);
        }
        const ref = activitiesIdsRef[activityId]?.current;
        if (!ref) {
            return;
        }
        ref.scrollIntoView({
            behavior: "smooth",
            block: "center",
        });
        ref.style.background = theme.gray50;
        setTimeout(() => {
            if (ref) {
                ref.style.background = "none";
            }
        }, 5000);
    };

    async function generatePDF() {
        setGeneratePdfLoading(true);
        try {
            const blob = await pdf(
                <ActivitiesReport
                    pdfData={getSections.data}
                    project={project}
                />
            ).toBlob();
            const fileURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", "relatorio-de-atividades.pdf");
            tempLink.click();
            Mixpanel.track("Gerar PDF das atividades");
        } catch (error) {
            toast.error("Erro ao fazer download!");
        } finally {
            setGeneratePdfLoading(false);
        }
    }

    const getPeriod = (period: DateRange | undefined) => {
        if (!period) return;
        setPeriod(period);
    };

    const toggleUserInFilter = (userId: string) => {
        setUsersFilter((prev) => {
            const userExists = prev.find((user) => user === userId);
            if (userExists) {
                return prev.filter((user) => user !== userId);
            }
            return [...prev, userId];
        });
    };

    useEffect(() => {
        if (period) {
            getSections.refetch();
        }
    }, [period, usersFilter]);

    const isSearching = Boolean(readActivities.loading && searchTerm);

    return (
        <s.Content>
            {activityToAddCollaborators && (
                <AddCollaboratorsModal
                    setActivity={setActivityToAddCollaborators}
                    activity={activityToAddCollaborators}
                    project={project}
                    updateActivity={updateActivity}
                    updateActivityStates={updateActivityStates}
                />
            )}
            {deleteConfirmationActivityId && (
                <DeleteConfirmationActivity
                    setOpen={setDeleteConfirmationActivityId}
                    activityId={deleteConfirmationActivityId}
                    deleteActivity={deleteActivity}
                />
            )}
            {deleteConfirmationSectionId && (
                <DeleteConfirmationSection
                    sectionId={deleteConfirmationSectionId}
                    deleteSection={deleteSection}
                    setOpen={setDeleteConfirmationSectionId}
                />
            )}
            <s.ActivityHeader>
                <SecondaryButton
                    onClick={generatePDF}
                    loading={generatePdfLoading}
                >
                    Gerar PDF
                </SecondaryButton>
                <s.SearchBar>
                    <Select
                        placeholder={"Pesquise por atividade"}
                        classNamePrefix={"react-select"}
                        options={activitiesOptions}
                        onInputChange={(value, action) => {
                            if (action.action === "input-change")
                                setSearchTerm(value);
                        }}
                        value={null}
                        inputValue={searchTerm}
                        noOptionsMessage={() => "Sem opções"}
                        menuPlacement={"auto"}
                        components={{
                            DropdownIndicator: Icons.SearchOutline,
                        }}
                        formatOptionLabel={formatOptionLabel}
                        onChange={(option) => {
                            if (!option) return;
                            goToActivity(option.value);
                            setSearchTerm("");
                        }}
                        loadingMessage={() => (
                            <Icons.Loading color={theme.purple500} />
                        )}
                        isLoading={isSearching}
                    />
                </s.SearchBar>
            </s.ActivityHeader>
            <s.ActivitySubHeader>
                <button
                    onClick={() => {
                        getPeriod({
                            from: undefined,
                            to: undefined,
                        });
                    }}
                >
                    <Icons.XOutline />
                </button>
                <PopoverCalendar
                    getPeriod={getPeriod}
                    noFilter={period.from === undefined}
                />
            </s.ActivitySubHeader>
            <s.ActivityList>
                <s.ActivityListHeader>
                    <div>Atividade</div>
                    <div>
                        Profissional
                        <PopoverRadix
                            trigger={
                                <div>
                                    {usersFilter.length ? (
                                        <Icons.Filler />
                                    ) : (
                                        <Icons.FillerOutlined
                                            color={theme.gray600}
                                        />
                                    )}
                                </div>
                            }
                        >
                            <s.UserListFilter>
                                {orderBy(project.Users, "name", "asc")?.map(
                                    (user) => (
                                        <div
                                            onClick={() => {
                                                toggleUserInFilter(user.id_e);
                                            }}
                                        >
                                            {usersFilter.includes(
                                                user.id_e
                                            ) && (
                                                <Icons.Check
                                                    width="20px"
                                                    color={theme.greenDone}
                                                />
                                            )}
                                            <img
                                                src={user.avatar || FotoUser}
                                            />
                                            {user.name}
                                        </div>
                                    )
                                )}
                            </s.UserListFilter>
                        </PopoverRadix>
                    </div>
                    <div>Prazo</div>
                    <div>Prioridade</div>
                    <div>Horas</div>
                </s.ActivityListHeader>
                <s.ActivityListBody>
                    <s.ActivityListBody>
                        <DragDropContext
                            onDragEnd={reorderActivity}
                            onDragUpdate={(data) => {
                                if (data.destination !== draggingDestination)
                                    setDraggingDestination(data.destination);
                            }}
                        >
                            <Sections
                                sectionName="withoutSection"
                                sections={sections}
                                updateActivity={updateActivity}
                                setDeleteConfirmationSectionId={
                                    setDeleteConfirmationSectionId
                                }
                                updateSection={updateSection}
                                project={project}
                                setActivityToAddCollaborators={
                                    setActivityToAddCollaborators
                                }
                                setDeleteConfirmationActivityId={
                                    setDeleteConfirmationActivityId
                                }
                                createActivity={createActivity}
                                activityToAddCollaborators={
                                    activityToAddCollaborators
                                }
                                archiveActivity={archiveActivity}
                                unarchiveActivity={unarchiveActivity}
                                toggleActivityStatus={toggleActivityStatus}
                                archiveSection={archiveSection}
                                activitiesWithoutSection={
                                    activitiesWithoutSection
                                }
                                duplicateActivity={duplicateActivity}
                                updateActivityStates={updateActivityStates}
                                setUpdateActivityStates={
                                    setUpdateActivityStates
                                }
                                activitiesIdsRef={activitiesIdsRef}
                                openedSectionsIds={openedSectionsIds}
                                setOpenedSectionsIds={setOpenedSectionsIds}
                                getSections={getSections}
                                draggingDestination={draggingDestination}
                            />
                            <AddSection
                                isToCreateSection={isToCreateSection}
                                setIsToCreateSection={setIsToCreateSection}
                                project={project}
                                createSection={createSection}
                            />
                            <AddActivity
                                createActivity={createActivity}
                                project={project}
                                setActivityToAddCollaborators={
                                    setActivityToAddCollaborators
                                }
                                activityToAddCollaborators={
                                    activityToAddCollaborators
                                }
                            />
                            <Archive
                                project={project}
                                archiveSection={archivedSection}
                                unarchiveActivity={unarchiveActivity}
                                setActivityToAddCollaborators={
                                    setActivityToAddCollaborators
                                }
                                setDeleteConfirmationActivityId={
                                    setDeleteConfirmationActivityId
                                }
                                archiveActivity={archiveActivity}
                                toggleActivityStatus={toggleActivityStatus}
                                activityToAddCollaborators={
                                    activityToAddCollaborators
                                }
                                updateActivity={updateActivity}
                                setDeleteConfirmationSectionId={
                                    setDeleteConfirmationSectionId
                                }
                                createActivity={createActivity}
                                updateSection={updateSection}
                                archivedSections={archivedSections}
                                unarchiveSection={unarchiveSection}
                                updateActivityStates={updateActivityStates}
                                setUpdateActivityStates={
                                    setUpdateActivityStates
                                }
                                activitiesIdsRef={activitiesIdsRef}
                                openedSectionsIds={openedSectionsIds}
                                setOpenedSectionsIds={setOpenedSectionsIds}
                                setArchiveOpen={setArchiveOpen}
                                archiveOpen={archiveOpen}
                                getSections={getSections}
                            />
                        </DragDropContext>
                    </s.ActivityListBody>
                </s.ActivityListBody>
            </s.ActivityList>
        </s.Content>
    );
};
