import styled from "styled-components";

type TOption = {
    selected?: boolean;
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;

    header {
        display: flex;
        height: 50px;
        background: ${({ theme }) => theme.pink50};
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 4px 4px 0 0;

        h1 {
            font-weight: 600;
            font-size: 0.875rem;
            letter-spacing: 0.005em;
            color: ${({ theme }) => theme.gray800};
        }
    }

    ul#options-wrapper {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        border-right: 1px solid ${({ theme }) => theme.gray100};

        .option:first-child {
            padding: 10px 10px 5px;
        }

        .option:last-child {
            padding: 5px 10px 10px;
        }
    }

    div#calendar-wrapper {
        padding: 10px;
    }
`;

export const Calendar = styled.div`
    display: flex;
    flex-direction: row;
    height: 350px;
`;

export const Option = styled.li<TOption>`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px 10px;
    width: 130px;
    cursor: pointer;

    span.option-text {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};
    }

    ${({ selected, theme }) =>
        selected
            ? `background: ${theme.pink50}; border-left: 2px solid ${theme.purple400}; padding: 5px 10px 5px 8px;`
            : `
    :hover {
        background: ${theme.gray50}
    };`};
`;

export const Footer = styled.footer`
    display: flex;
    border-top: 1px solid ${({ theme }) => theme.gray100};
    height: 88px;
    align-items: center;
    padding: 0 16px;
    width: 100%;

    div.footer-column {
        display: flex;
        flex-direction: column;
        width: 120px;
        margin-right: 24px;
    }

    span.footer-title {
        font-size: 10px;
        color: ${({ theme }) => theme.gray600};
        font-weight: 600;
    }

    span.footer-text {
        font-size: 10px;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }

    div#buttons {
        display: flex;
        margin-left: auto;
        gap: 16px;

        button {
            height: 40px;
        }

        button.cancel {
            width: 110px;
        }

        button.save {
            width: 90px;
        }
    }
`;
