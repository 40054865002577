import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className || "icon-membership"}
        onClick={onClick}
        strokeWidth={0.1}
    >
        <title>{title}</title>
        <mask
            id="mask0_24057_14191"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <rect width="24" height="24" fill={theme.white3} />
        </mask>
        <g mask="url(#mask0_24057_14191)">
            <path
                d="M3.99998 12.7884V14.6923C3.99998 14.7692 4.03202 14.8397 4.09613 14.9038C4.16024 14.9679 4.23077 15 4.3077 15H19.6923C19.7692 15 19.8397 14.9679 19.9038 14.9038C19.9679 14.8397 20 14.7692 20 14.6923V12.7884H3.99998ZM4.3077 2.5H19.6923C20.191 2.5 20.6169 2.67661 20.9701 3.02982C21.3233 3.38302 21.5 3.80898 21.5 4.3077V14.6923C21.5 15.191 21.3233 15.6169 20.9701 15.9701C20.6169 16.3233 20.191 16.5 19.6923 16.5H15.4615V19.6634C15.4615 20.0083 15.3192 20.2685 15.0346 20.4442C14.7499 20.6198 14.457 20.6307 14.1557 20.4769L12.4019 19.6096C12.2814 19.539 12.1474 19.5038 12 19.5038C11.8525 19.5038 11.7186 19.539 11.5981 19.6096L9.84423 20.4769C9.54296 20.6307 9.25002 20.6198 8.9654 20.4442C8.68078 20.2685 8.53848 20.0083 8.53848 19.6634V16.5H4.3077C3.80898 16.5 3.38303 16.3233 3.02983 15.9701C2.67661 15.6169 2.5 15.191 2.5 14.6923V4.3077C2.5 3.80898 2.67661 3.38302 3.02983 3.02982C3.38303 2.67661 3.80898 2.5 4.3077 2.5ZM3.99998 10.2115H20V4.3077C20 4.23077 19.9679 4.16024 19.9038 4.09613C19.8397 4.03203 19.7692 3.99998 19.6923 3.99998H4.3077C4.23077 3.99998 4.16024 4.03203 4.09613 4.09613C4.03202 4.16024 3.99998 4.23077 3.99998 4.3077V10.2115ZM3.99998 14.6923V3.99998H4.3077C4.23077 3.99998 4.16024 4.03203 4.09613 4.09613C4.03202 4.16024 3.99998 4.23077 3.99998 4.3077V14.6923C3.99998 14.7692 4.03202 14.8397 4.09613 14.9038C4.16024 14.9679 4.23077 15 4.3077 15H3.99998V14.6923Z"
                fill={color || theme.gray800}
            />
        </g>
    </svg>
);
