import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/vouchers/`,
        method: "get",
        headers: {
            auth: true,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
