import { View, Text } from "@react-pdf/renderer";

import { theme } from "../../../../../../assets";
import { formatToBRMoney } from "../../../../../../utils";

export const SmallCard = ({
    text,
    data,
}: {
    text: string;
    data: {
        realized: number;
        forecast: number;
    };
}) => {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                border: `1px solid ${theme.gray200}`,
                padding: "12px",
                width: "150px",
                borderRadius: 4,
            }}
        >
            <Text
                style={{
                    fontSize: "11px",
                    color: theme.gray600,
                    textAlign: "center",
                }}
            >
                {text}
            </Text>
            <View
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                }}
            >
                <Text
                    style={{
                        fontSize: "9px",
                        color: theme.gray600,
                        textAlign: "center",
                    }}
                >
                    Previsto
                </Text>
                <Text
                    style={{
                        fontSize: "11px",
                        color: theme.gray800,
                        textAlign: "center",
                    }}
                >
                    {formatToBRMoney(data.forecast)}
                </Text>
            </View>
            <View
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                }}
            >
                <Text
                    style={{
                        fontSize: "9px",
                        color: theme.gray600,
                        textAlign: "center",
                    }}
                >
                    Realizado
                </Text>
                <Text
                    style={{
                        fontSize: "11px",
                        color: theme.purple600,
                        textAlign: "center",
                    }}
                >
                    {formatToBRMoney(data.realized)}
                </Text>
            </View>
        </View>
    );
};
