import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import * as s from "./styled-how-you-know-us";

export type THowDoYouKnowUsOptions =
    | "Google"
    | "LinkedIn"
    | "Instagram"
    | "E-mail"
    | "Indicação de amigo/colega"
    | "Outro:";

export type THowDoYouKnowUsAlternatives = {
    id: string;
    value: THowDoYouKnowUsOptions;
    label: string;
}[];

type THowYouKnowUsProps = {
    setHowYouKnowUsOutside: (howYouKnowUs: string) => void;
};

export const HowYouKnowUs = ({
    setHowYouKnowUsOutside,
}: THowYouKnowUsProps) => {
    const howYouKnowUsAlternatives: THowDoYouKnowUsAlternatives = [
        {
            id: "google",
            value: "Google",
            label: "Google",
        },
        {
            id: "linkedin",
            value: "LinkedIn",
            label: "LinkedIn",
        },
        {
            id: "instagram",
            value: "Instagram",
            label: "Instagram",
        },
        {
            id: "email",
            value: "E-mail",
            label: "E-mail",
        },
        {
            id: "indication",
            value: "Indicação de amigo/colega",
            label: "Indicação de amigo/colega",
        },
        {
            id: "other",
            value: "Outro:",
            label: "Outro:",
        },
    ];
    const [howYouKnowUs, setHowYouKnowUs] = useState<string>("");
    const { control } = useForm();

    return (
        <s.HowYouKnow>
            <s.TitleHowYouKnew>Como você conheceu o PaperON?</s.TitleHowYouKnew>
            <s.RadioGroupRoot>
                {howYouKnowUsAlternatives.map((alternative) => (
                    <s.RadioWrapper>
                        <s.RadioGroupItem
                            id={alternative.id}
                            value={alternative.id}
                            checked={howYouKnowUs === alternative.id}
                            onClick={() => {
                                setHowYouKnowUs(alternative.id);
                                setHowYouKnowUsOutside(alternative.id);
                            }}
                        >
                            <s.RadioGroupIndicator />
                        </s.RadioGroupItem>
                        <label htmlFor={alternative.id}>
                            {alternative.label}
                        </label>
                        {alternative.id === "other" && (
                            <Controller
                                control={control}
                                name="other"
                                render={({ field: { onChange } }) => (
                                    <s.OtherInput
                                        disabled={
                                            howYouKnowUs !== alternative.id
                                        }
                                        maxLength={50}
                                        onChange={(e) => {
                                            setHowYouKnowUsOutside(
                                                e.target.value
                                            );
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        )}
                    </s.RadioWrapper>
                ))}
            </s.RadioGroupRoot>
        </s.HowYouKnow>
    );
};
