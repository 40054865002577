import styled from "styled-components";

import { theme } from "../../../../../assets";

type TSteps = {
    sectorsError?: boolean;
};

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 40.9375rem;
    padding-bottom: 80px;

    .button-group {
        display: flex;
        flex-direction: column;
        margin: auto;
        gap: 1rem;
        width: 70%;
    }
`;

export const Section = styled.section<TSteps>`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .has-label {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .container-search {
        position: relative;
        .popover-container {
            filter: drop-shadow(0px 2px 9px rgba(8, 1, 23, 0.2));

            position: absolute;
            z-index: 100;
            width: 100%;
            margin-top: 8px;
            max-height: 280px;
            overflow: auto;
            border-radius: 4px;
            background: ${({ theme }) => theme.white};

            .popover-item {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 8px 16px;
                justify-content: space-between;
                &:hover {
                    background: ${({ theme }) => theme.gray100};
                }
                .first-item {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                }
            }

            .popover-item-no-content {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 16px;
                height: 5rem;
            }
        }

        border-radius: 4px;
    }
    .container-tag {
        margin-top: 1rem;
        width: fit-content;
    }
    .container-table {
        img {
            width: 40px;
        }
    }
`;

export const Label = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Description = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};
`;

export const Advice = styled(Description)`
    color: ${({ theme }) => theme.purple400};
`;

export const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

export const ButtonRemove = styled.button`
    display: flex;
    visibility: hidden;
`;

export const TeamTable = styled.div`
    width: 100%;
    border: 1px solid ${theme.gray200};
    border-radius: 4px;
    cursor: default;
    font-size: 0.875rem;
    background-color: ${({ theme }) => theme.white};

    .table-header {
        display: flex;
        flex-direction: row;
        height: 48px;
        justify-content: space-between;
        border-bottom: 1px solid ${theme.gray200};

        .header-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40%;

            color: ${theme.gray800};
            font-weight: 600;

            &:last-child {
                width: 10%;
            }
        }
    }

    .table-content {
        .table-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid ${theme.gray200};

            &:hover {
                background-color: ${theme.gray50};

                ${ButtonRemove} {
                    visibility: visible;
                }
            }

            .row-cell {
                display: flex;
                align-items: center;
                width: calc(40%);
                gap: 1rem;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:nth-child(2) {
                    justify-content: center;
                }

                &:last-child {
                    width: calc(8%);
                    justify-content: center;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

export const SectorList = styled.div<TSteps>`
    width: 100%;
    border-radius: 4px;
    border: 1.2px solid
        ${({ sectorsError }) => (sectorsError ? theme.redError : theme.gray200)};

    #projetos-header {
        padding: 10px 22px;
        font-weight: 600;
        color: ${theme.gray600};
        border-bottom: 1.2px solid ${theme.gray200};
    }

    #projetos-content {
        #projetos-row {
            cursor: default;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 22px;
            gap: 1rem;
            border-bottom: 1.2px solid ${theme.gray200};
        }

        #projetos-row:last-child {
            border-bottom: none;
        }
    }

    #projetos-no-content {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 22px;
    }
`;

export const SectorListText = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.gray600};
`;

export const TooltipContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1rem;
    gap: 0.625rem;
    max-width: 18rem;
    max-height: 8.5rem;

    span.tooltip-text {
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.gray600};
        font-weight: 400;
    }
`;

export const BillerInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;

    span {
        display: flex;
        align-items: center;
    }
`;

export const HourHistory = styled.div<{ header?: boolean }>`
    color: ${({ header }) =>
        header
            ? "var(--color-gray-400, #6E697A)"
            : "var(--color-gray-400, #9E9BA7)"};
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    margin-left: 19px;
`;

export const SeeMoreHourHistory = styled.div`
    color: var(--color-brand-purple-500, #663398);
    font-size: 14px;
    margin-top: 10px;
    margin-left: 19px;
    font-weight: 600;
`;

export const FirstStepFieldsWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .side-by-side-fields {
        display: flex;
        gap: 20px;
        width: 100%;
    }
`;
