import { styled } from "styled-components";

export const Option = styled.div`
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }

    .label {
        display: flex;
        align-items: center;
        gap: 13px;
    }
`;
