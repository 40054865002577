import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({
    title,
    color,
    className,
    onClick,
    strokeWidth,
}: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-arrow-back"}
    >
        <title>{title}</title>
        <path
            d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
            stroke={color || theme.gray900}
            strokeWidth={strokeWidth || "1.2"}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
