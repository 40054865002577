import * as yup from "yup";

import { validationErrors } from "../../../../utils";

const { name } = validationErrors;

export default () =>
    yup.object().shape({
        label: yup.string().required(name.required),
    });
