import * as Accordion from "@radix-ui/react-accordion";
import styled, { keyframes } from "styled-components";

const slideDown = keyframes`
  from { max-height: 0; opacity: 0; }
  to { max-height: var(--radix-accordion-content-height); opacity: 1; }
`;

const slideUp = keyframes`
  from { max-height: var(--radix-accordion-content-height); opacity: 1; }
  to { max-height: 0; opacity: 0; }
`;

export const StyledAccordion = styled(Accordion.Root)`
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledItem = styled(Accordion.Item)<{ open?: boolean }>``;

export const StyledHeader = styled(Accordion.Header)`
    margin: 0;
    display: flex;
`;

export const StyledButton = styled(Accordion.Trigger)`
    width: 100%;
    font-weight: normal;
`;

export const StyledPanel = styled(Accordion.Content)`
    &[data-state="open"] {
        animation: ${slideDown} 500ms;
    }
    &[data-state="closed"] {
        animation: ${slideUp} 300ms;
    }
`;
