import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icons, theme } from "../../../../assets";
import { PopoverRadix, RadixCheckBox } from "../../../../components";
import { MenuAtom, TMenuAtom } from "../../../../components/atoms/menu/menu";
import { CardEditHours } from "../../../../components/molecules/card-edit-hours/card-edit-hours";
import { EditEntry } from "../../../../components/molecules/edit-entry/edit-entry";
import { ModalDeleteEntry } from "../../../../components/molecules/modals/modal-delete-entry/modal-delete-entry";
import { TProjectList } from "../../../../components/molecules/popovers/popover-project/popover-project";
import { TDetailsEntry, TEntryTask } from "../../../../models";
import { UpdateEntryVariables } from "../../../../services/track/types";
import {
    addMillisecondsToHour,
    calculateTimeRangeDuration,
    getTimeFromIsoString,
    minutesToStringDate,
} from "../../../../utils";
import { EntryFields } from "./entry";
import * as s from "./styled-card-container";
import validation from "./validation";

type TSubEntryList = {
    indexEntry: number;
    task: TEntryTask;
    entry: TDetailsEntry;
    isEditing: boolean;
    index: number;
    toggleEntryChecked: (id: string, checked: boolean, index: number) => void;
    handleDeleteEntry: (id: string) => void;
    handleUpdateEntry: ({
        entriesId,
        projectId,
        sectorId,
        description,
        startAt,
        endAt,
    }: UpdateEntryVariables) => void;
    projectList: TProjectList[];
};

export const SubEntry = ({
    indexEntry,
    task,
    entry,
    isEditing,
    index,
    toggleEntryChecked,
    handleDeleteEntry,
    handleUpdateEntry,
    projectList,
}: TSubEntryList) => {
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<EntryFields>({
        resolver: yupResolver(validation),
    });

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [entryIdToDelete, setEntriesIdToDelete] = useState<string>("");
    const [isToEditEntry, setIsToEditEntry] = useState<boolean>(false);
    const [editIsValid, setEditIsValid] = useState<boolean>(true);

    const menuOptions: TMenuAtom["options"] = [
        {
            label: "Editar",
            icon: <Icons.PencilOutline />,
            action: () => {
                setIsToEditEntry(true);
            },
            color: theme.gray800,
        },
        {
            label: "Apagar",
            icon: <Icons.Trash />,
            action: () => {
                setIsDeleteModalOpen(true);
                setEntriesIdToDelete(entry.id_e);
            },
            color: theme.gray800,
        },
    ];

    useEffect(() => {
        if (task.entries.length) {
            setValue("startTrackTime", getTimeFromIsoString(entry.start_at));
            setValue("endTrackTime", getTimeFromIsoString(entry.end_at));
            trigger("startTrackTime");
            trigger("endTrackTime");
        }
    }, [task]);

    const onSubmit = handleSubmit((data) => {
        const startAt = new Date(entry.start_at.replace("Z", ""));
        const currentData = calculateTimeRangeDuration(
            startAt,
            addMillisecondsToHour(data.startTrackTime),
            addMillisecondsToHour(data.endTrackTime)
        );
        handleUpdateEntry({
            entriesId: data.importantInfos.id,
            startAt: currentData.startTrackTime,
            endAt: currentData.finalTrackTime,
            activityId: data.activityId || entry.Activity?.id_e,
            sectorId: data.sectorId || task.sector?.id_e,
            projectId: data.project,
            description: data.description,
        });
        setIsToEditEntry(false);
    });

    return (
        <div
            key={indexEntry}
            style={{
                height: task.entries.length > 1 ? "auto" : ".1px",
                opacity: task.entries.length > 1 ? 1 : 0,
                backgroundColor: theme.gray50,
            }}
        >
            {isDeleteModalOpen && (
                <ModalDeleteEntry
                    setOpen={setIsDeleteModalOpen}
                    onSave={() => {
                        setIsDeleteModalOpen(false);
                        handleDeleteEntry(entryIdToDelete);
                        setEntriesIdToDelete("");
                    }}
                />
            )}
            <s.Separator />
            <Controller
                name="importantInfos"
                control={control}
                defaultValue={{
                    id: entry.id_e,
                    date: entry.start_at,
                }}
                render={({ field: { onChange } }) => (
                    <form
                        onSubmit={onSubmit}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onChange({
                                    id: entry.id_e,
                                    date: entry.start_at,
                                });
                                onSubmit();
                            }
                        }}
                    >
                        <s.ActivityContainer isEditing={isToEditEntry}>
                            {isToEditEntry ? (
                                <EditEntry
                                    control={control}
                                    index={index}
                                    task={task}
                                    projectList={projectList}
                                    entry={entry}
                                    setEditIsValid={setEditIsValid}
                                    setValue={setValue}
                                />
                            ) : (
                                <s.LeftSide isEditing={isEditing}>
                                    {isEditing && (
                                        <RadixCheckBox
                                            checked={entry.entryChecked}
                                            onClick={() =>
                                                toggleEntryChecked(
                                                    entry.id_e,
                                                    entry.entryChecked,
                                                    index
                                                )
                                            }
                                        />
                                    )}
                                    <div style={{ width: "250px" }}></div>
                                    <div></div>
                                    <s.TaskDescription>
                                        {task.entries[0].description}
                                    </s.TaskDescription>
                                </s.LeftSide>
                            )}

                            <s.Flex id="right-content">
                                <s.Flex
                                    style={{
                                        width: "84px",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            trigger("startTrackTime");
                                            trigger("endTrackTime");
                                        }
                                    }}
                                >
                                    <PopoverRadix
                                        side="bottom"
                                        align="end"
                                        open={
                                            !isToEditEntry ? false : undefined
                                        }
                                        onOpenChange={() => {
                                            trigger("startTrackTime");
                                            trigger("endTrackTime");
                                        }}
                                        trigger={
                                            <s.StartAndEndTrackTimes
                                                isEditing={isToEditEntry}
                                            >
                                                <p>
                                                    {getValues(
                                                        "startTrackTime"
                                                    )?.slice(0, 5)}
                                                </p>
                                                {" - "}
                                                <p>
                                                    {getValues(
                                                        "endTrackTime"
                                                    )?.slice(0, 5)}
                                                </p>
                                            </s.StartAndEndTrackTimes>
                                        }
                                    >
                                        <CardEditHours
                                            task={entry}
                                            control={control}
                                            errors={errors}
                                        />
                                    </PopoverRadix>
                                </s.Flex>
                                <s.Text style={{ marginRight: 14 }}>
                                    {minutesToStringDate(entry.total_time)}
                                </s.Text>
                                {isToEditEntry ? (
                                    <s.EditControl>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (editIsValid) onSubmit();
                                            }}
                                        >
                                            <Icons.Checked
                                                color={
                                                    editIsValid
                                                        ? theme.gray800
                                                        : theme.gray300
                                                }
                                            />
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsToEditEntry(false);
                                            }}
                                        >
                                            <Icons.XOutline
                                                color={theme.gray800}
                                            />
                                        </div>
                                    </s.EditControl>
                                ) : (
                                    <MenuAtom options={menuOptions}>
                                        <Icons.More />
                                    </MenuAtom>
                                )}
                            </s.Flex>
                        </s.ActivityContainer>
                    </form>
                )}
            />
        </div>
    );
};
