import { TDeleteProject, TUser } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type Errors =
    | "The project cannot be deleted, as it has time entries!"
    | "The project cannot be deleted, as it is the only one available to the following users:"
    | "the project cannot be deleted, as it has time entries!";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ projectId }: TDeleteProject = {}) => {
    const tempHeaders = {
        auth: true,
        "x-project-id": projectId,
    };

    if (!projectId) delete tempHeaders["x-project-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/delete`,
        method: "delete",
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const error: Errors = body?.message.name?.[0] || body?.message;
    const errorsMapper = {
        "The project cannot be deleted, as it has time entries!":
            "O projeto não pode ser deletado pois possui lançamentos de tempo!",
        "The project cannot be deleted, as it is the only one available to the following users:": `O projeto não pode ser deletado pois é o único disponível para o(s) seguinte(s) usuário(s): ${
            body?.message?.users &&
            body.message.users.map((user: TUser) => user.name)
        }`,
        "the project cannot be deleted, as it has time entries!":
            "O projeto não pode ser deletado pois possui lançamentos de tempo!",
    };

    const GENERIC_ERROR_MESSAGE = "Erro ao deletar o projeto";
    const errorFeedBacks = {
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        404: "Projeto não encontrado!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
