import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.gray100};
    width: 100%;
    margin-top: 32px;
    justify-content: space-between;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 12px;
    border-right: 1px solid ${({ theme }) => theme.gray100};
    width: 230px;

    &:last-child {
        border-right: none;
    }
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
`;

export const Value = styled.div`
    color: ${({ theme }) => theme.gray800};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;
