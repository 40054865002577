import styled from "styled-components";

type TContacts = {
    edit: boolean;
};

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    span.tooltip {
        align-self: flex-start;
        cursor: pointer;
    }

    div.icons-group {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-self: flex-start;

        svg {
            align-self: flex-start;
        }
    }
`;

export const HiringConfigurations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    span#hiring-formats {
        font-size: 1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.gray800};
    }
`;

export const CardsWrapper = styled.div`
    display: grid;
    row-gap: 2rem;
    column-gap: 4rem;
    grid-template-columns: 390px 390px;
`;

export const HiringFormatCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    width: 100%;
    max-width: 390px;

    span.field-title {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray400};

        span.field-description {
            color: ${({ theme }) => theme.gray800};
        }
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;
