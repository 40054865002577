import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem 0;
    gap: 3.5rem;
    width: 100%;
    max-width: 99rem;
`;
