import styled from "styled-components";

import { theme } from "../../../assets";

type TOptionText = {
    active?: boolean;
};

export const Container = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;

    #arrow {
        cursor: pointer;
        display: flex;
    }

    svg {
        margin-right: 0.5rem;
    }

    li {
        display: inline;
        max-width: 1000px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    li + li:before {
        padding: 0 0.5rem;
        content: ">";
        color: ${theme.gray400};
    }
`;

export const OptionText = styled.a<TOptionText>`
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: ${({ active, theme }) =>
        active ? `${theme.gray900}` : `${theme.gray400}`};
`;
