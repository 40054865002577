import React, { Dispatch, SetStateAction } from "react";

import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-action";

type TModalAction = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    title: string;
    description: string;
    secondDescription?: string;
    primaryAction: () => any;
    secondaryAction: () => any;
    primaryText: string;
    secondaryText: string;
};

const ModalAction = ({
    setOpen,
    title,
    description,
    secondDescription,
    primaryAction,
    secondaryAction,
    primaryText,
    secondaryText,
}: TModalAction) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <div className="text">
                    <h1>{title}</h1>
                    <p>{description}</p>
                    {secondDescription && <p>{secondDescription}</p>}
                </div>

                <div className="buttons">
                    <SecondaryButton onClick={secondaryAction}>
                        {secondaryText}
                    </SecondaryButton>
                    <PrimaryButton onClick={primaryAction}>
                        {primaryText}
                    </PrimaryButton>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalAction;
