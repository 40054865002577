import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    width,
    height,
    title,
    color,
    className,
    onClick,
}: TBaseSVG) => (
    <svg
        className={className || "icon-x-outline"}
        onClick={onClick}
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <path
            d="M6 6L18 18M6 18L18 6L6 18Z"
            stroke={color || theme.gray600}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
