import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (data: any) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/avatar`,
        method: "patch",
        body: data,
        headers: { auth: true },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        413: "Imagem muito grande! A imagem tem que ter no máximo 2MB",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
