import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-mouse-pointer"}
    >
        <title>{title}</title>
        <path
            d="M3 3L10.07 19.97L12.58 12.58L19.97 10.07L3 3Z"
            stroke={color || "#FFA0F6"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 13L19 19"
            stroke={color || "#FFA0F6"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
