import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { ReadDemonstrativeParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    projectId,
    startAt,
    endAt,
}: ReadDemonstrativeParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/demonstrative`,
        method: "get",
        headers,
        params: { startAt, endAt },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
