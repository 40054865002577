import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    width: 100%;

    padding: 31px 24px;
    height: fit-content;
    padding-bottom: 0px;

    background-color: ${({ theme }) => theme.white};

    border: 1px solid ${({ theme }) => theme.gray200};
    border-top: none;
    border-bottom: none;
    border-radius: 0px 0px 4px 4px;
`;
