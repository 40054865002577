import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { Input, PrimaryButton, SecondaryButton } from "../../..";
import { CSVInstructions, Icons, InviteTable, theme } from "../../../../assets";
import { useFetch } from "../../../../hooks";
import { invite } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-invite-mass";

type TInputs = {
    attachment?: File;
};

type TModalInviteMass = {
    getInvites?: TUseFetch;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIsOpenChoose?: Dispatch<SetStateAction<boolean>>;
};

const ModalInviteMass = ({
    setOpen,
    setIsOpenChoose,
    getInvites,
}: TModalInviteMass) => {
    const { handleSubmit, control, watch } = useForm<TInputs>();
    const [file, setFile] = useState<File>();

    const massInvite = useFetch({
        fn: invite.createInviteMass,
        start: false,
        successMessage: "Convite criado com sucesso!",
    });

    function downloadTutorials() {
        Mixpanel.track("Baixar planilha de adicionar profissionais em massa");

        fetch(CSVInstructions).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                const alink = document.createElement("a");
                alink.href = fileURL;
                alink.setAttribute("download", "Instrucoes-CSV.pdf");
                alink.click();
            });
        });

        fetch(InviteTable).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                const alink = document.createElement("a");
                alink.href = fileURL;
                alink.setAttribute("download", "Tabela-Convites.xlsx");
                alink.click();
            });
        });
    }

    useEffect(() => {
        if (massInvite.response) {
            getInvites?.onRefresh({
                all: true,
                length: 8,
                page: 1,
            });
            if (setIsOpenChoose) setIsOpenChoose(false);
            setOpen(false);
        }
    }, [massInvite.response]);

    const onSubmit = () => {
        const formDataFile = new FormData();
        formDataFile.append("invite_csv", file as Blob);
        massInvite.onRefresh({ docFile: formDataFile });
        window.scrollTo(0, 0);
    };

    return (
        <ContainerModal>
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <s.Container>
                        <s.Title>Adicionar profissionais em massa</s.Title>

                        <s.StepByStep>
                            <p>
                                Para adicionar profissionais em massa, baixe o
                                nosso template da planilha Excel, preencha as
                                informações e importe os dados.
                            </p>

                            <p>
                                <span>Passo 1:</span> Baixe o template da
                                planilha Excel
                            </p>

                            <p>
                                <span>Passo 2:</span> Preencha o nome e o e-mail
                                dos profissionais na planilha
                            </p>
                            <p>
                                <span>Passo 3:</span> Anexe a planilha Excel
                                preenchida
                            </p>
                            <p>
                                <span>Passo 4:</span> Importe a planilha no
                                botão “importar dados planilha”
                            </p>
                            <p>
                                <span>Passo 5:</span> Revise os dados em
                                “rascunhos” e clique em “enviar convites”
                            </p>
                            <p>
                                <span>Passo 6:</span> Acompanhe o andamento dos
                                convites enviados na aba “convites enviados”
                            </p>
                        </s.StepByStep>

                        <SecondaryButton
                            icon={<Icons.Download color={theme.purple600} />}
                            onClick={() => downloadTutorials()}
                            type="button"
                        >
                            TEMPLATE PLANILHA EXCEL
                        </SecondaryButton>

                        <s.AttachmentContainer>
                            <h2>Anexar planilha Excel preenchida</h2>
                            <Controller
                                name="attachment"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Input
                                        mask=""
                                        type="file"
                                        icon={<Icons.PaperClip />}
                                        label={
                                            "Preencha até 1.000 profissionais por vez"
                                        }
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            if (!e.target.files) return;
                                            setFile(e?.target?.files[0]);
                                            onChange(e?.target?.files[0]);
                                        }}
                                        accept=".csv, .xlsm, .xlsb, .xltx, .xltm, .xls, .xlt, .xml, .xlam, .xlsx"
                                    />
                                )}
                            />
                        </s.AttachmentContainer>

                        <s.ButtonsContainer>
                            <SecondaryButton
                                variation="small"
                                onClick={() => setOpen(false)}
                            >
                                CANCELAR
                            </SecondaryButton>
                            <PrimaryButton
                                variation="small"
                                disabled={!watch("attachment")}
                                type="submit"
                            >
                                IMPORTAR DADOS da PLANILHA
                            </PrimaryButton>
                        </s.ButtonsContainer>
                    </s.Container>
                </form>
            </>
        </ContainerModal>
    );
};

export default ModalInviteMass;
