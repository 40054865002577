import { OfficeWithChair } from "../../assets";
import * as s from "./styled-no-access";

export const NoAccess = () => {
    return (
        <s.Container>
            <img src={OfficeWithChair} />
            <s.Content>
                <s.Title>Sem acesso!</s.Title>
                <s.Explanation>
                    Lamentamos, mas parece que você não tem acesso ao Paper On.
                    Por favor, entre em contato com seu gestor para obter mais
                    informações sobre como obter acesso.
                </s.Explanation>
            </s.Content>
        </s.Container>
    );
};
