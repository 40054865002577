import styled from "styled-components";

export const Container = styled.div<{ isCheckbox?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: ${({ isCheckbox }) =>
        isCheckbox ? "flex-start" : "space-between"};
    gap: 8px;

    ${({ isCheckbox }) =>
        isCheckbox &&
        `
        div {
            &:first-child {
                max-width: 16px;
                margin-top: 1px;
            }

            &:last-child {
                width: 380px;
            }
        }
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:first-child {
        width: 380px;
    }

    &:last-child {
        width: 240px;
    }
`;

export const Title = styled.h1<{ disabled?: boolean }>`
    color: ${({ theme, disabled }) =>
        disabled ? theme.gray300 : theme.purple400};
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
`;

export const KnowMore = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
