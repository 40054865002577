import { TUpdateSector } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    description,
    usersId,
    sectorId,
    projectsId,
}: TUpdateSector = {}) => {
    const params = {
        name,
        description,
        projectsIds: projectsId,
    };

    const tempHeaders = {
        auth: true,
        "x-users-id": usersId,
        "x-sector-id": sectorId,
    };

    if (!usersId) delete tempHeaders["x-users-id"];
    if (!sectorId) delete tempHeaders["x-sector-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sector/update`,
        method: "put",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        404: "Sem setores!",
        409:
            body.message === "A sector with this name already exists!"
                ? "Já existe um setor com o mesmo nome!"
                : "Houve um problema ao criar o setor!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
