import { Icons } from "../../../assets";
import {
    LoadingGray,
    PrimaryButton,
    SecondaryButton,
} from "../../../components";
import ContainerModal from "../../../components/molecules/modals/container-modal";
import * as s from "./styled-inactive-admin-modal";

type TInactiveAdminModal = {
    onClickSuccess: () => void;
    onClickCancel: () => void;
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    loading?: boolean;
};

export const InactiveAdminModal = ({
    onClickSuccess,
    onOpenChange,
    onClickCancel,
    loading,
}: TInactiveAdminModal) => {
    return (
        <ContainerModal closeIcon onOpenChange={onOpenChange}>
            {loading ? (
                <s.Container>
                    <LoadingGray />
                </s.Container>
            ) : (
                <s.Container>
                    <s.Title>
                        <Icons.Warning />
                        Conta inativada
                    </s.Title>
                    <s.Text>
                        Esta conta está inativada no momento. Ao entrar na
                        plataforma, você deverá reativar a sua conta.
                    </s.Text>
                    <s.Text>Você deseja entrar e reativar a sua conta?</s.Text>
                    <s.Buttons>
                        <SecondaryButton onClick={onClickCancel}>
                            CANCELAR
                        </SecondaryButton>
                        <PrimaryButton onClick={onClickSuccess}>
                            ENTRAR E REATIVAR A CONTA
                        </PrimaryButton>
                    </s.Buttons>
                </s.Container>
            )}
        </ContainerModal>
    );
};
