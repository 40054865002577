import { Icons, theme } from "../../../assets";
import { TReport } from "../../../models";
import * as s from "./styled-report-status";

type TReportPopover = {
    data: TReport;
    page?: string;
};

const ReportStatus = ({ data, page }: TReportPopover) => {
    const hasError = data?.ReportError && data.ReportError.length > 0;

    const hasBill = data?.ReportBilling;

    const hasDocs =
        ((data?.invoices?.length || 0) > 0 &&
            (data?.recipients?.length || 0) > 0) ||
        (data?.billets?.length || 0) > 0;

    const returnStatusProgress = () => {
        if (data.docFile) {
            return "100%";
        }
        if (data.locked && data.reviewed && hasBill && !hasDocs) {
            return "100%";
        }
        if (data.locked && data.reviewed && hasBill && hasDocs) {
            return "100%";
        }

        if (page === "faturar") {
            if (data.locked && data.reviewed && !hasBill) {
                return "100%";
            }
            if (data.locked && data.reviewed && hasBill && !hasDocs) {
                return "100%";
            }
        } else if (data.locked && data.reviewed && !hasBill) {
            return "50%";
        }

        if ((data.locked && !data.reviewed) || hasError) {
            return "50%";
        }
        return "2.73%";
    };

    const returnStatus = () => {
        if (data.docFile) {
            return (
                <>
                    Documentos enviados
                    <Icons.CheckCircle />
                </>
            );
        }
        if (page === "faturar") {
            if (data.locked && data.reviewed && hasBill && !hasDocs) {
                return (
                    <>
                        Concluído
                        <Icons.CheckCircle color={theme.greenDone} />
                    </>
                );
            }
        }
        if (data.locked && data.reviewed && hasBill && !hasDocs) {
            return (
                <>
                    Enviar Documentos
                    <Icons.CheckCircle />
                </>
            );
        }

        if (data.locked && data.reviewed && hasBill && hasDocs) {
            return (
                <>
                    Concluído
                    <Icons.CheckCircle color={theme.greenDone} />
                </>
            );
        }

        if (page === "faturar") {
            if (data.locked && data.reviewed && !hasBill) {
                return (
                    <>
                        Concluído
                        <Icons.CheckCircle color={theme.greenDone} />
                    </>
                );
            }
        } else if (data.locked && data.reviewed && !hasBill) {
            if (data.locked && data.reviewed && !hasBill) {
                return (
                    <>
                        Relatório em Revisão
                        <Icons.CheckCircle />
                    </>
                );
            }
        }

        if (data.locked && !data.reviewed) {
            return (
                <>
                    Relatório em Revisão
                    <Icons.CheckCircle />
                </>
            );
        }
        return (
            <>
                Relatório aberto
                <Icons.CheckCircle />
            </>
        );
    };

    const returnColor = () => {
        if (hasError) {
            return theme.redError;
        }
        if (data.locked && data.reviewed && hasDocs) {
            return theme.greenDone;
        }
        if (page === "faturar") {
            if (data.locked && data.reviewed && !hasBill) {
                return theme.greenDone;
            }
            if (data.locked && data.reviewed && hasBill && !hasDocs) {
                return theme.greenDone;
            }
        }

        return theme.purple400;
    };

    return (
        <s.Container>
            <span className="status">
                {hasError ? (
                    <>
                        Recusado
                        <Icons.ExclamationOutline />
                    </>
                ) : (
                    <>{returnStatus()}</>
                )}
            </span>

            <s.ProgressBar>
                <s.Bar
                    color={returnColor()}
                    percentage={returnStatusProgress()}
                />
            </s.ProgressBar>
        </s.Container>
    );
};

export default ReportStatus;
