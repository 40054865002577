import { StyleSheet } from "@react-pdf/renderer";
import styled from "styled-components";

import { theme } from "../../../../assets";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 1000px;
    height: 800px;
`;

export const PDF = StyleSheet.create({
    PageStyle: {
        flexDirection: "column",
        padding: "30px 5px 40px",
        display: "flex",
        justifyContent: "space-between",
    },
    PageContent: { margin: "0 20px 50px" },
    Pagination: {
        position: "absolute",
        right: 20,
        bottom: 15,
        fontFamily: "Helvetica",
        fontSize: 6,
        color: theme.gray600,
    },
    Header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    Title: {
        fontFamily: "Helvetica",
        fontSize: 12,
        fontWeight: 400,
    },
    ImageStyle: { width: "85px", height: "12px" },
    Project: {
        display: "flex",
        flexDirection: "column",
        marginTop: 30,
    },
    ProjectHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: `1px solid ${theme.gray50}`,
        borderRadius: 4,
        marginBottom: 40,
    },
    HeaderColumn: {
        display: "flex",
        padding: 15,
    },
    HeaderValueText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray800,
    },
    ProjectTag: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#2780270D",
        border: "1px solid #E0EDE0",
        borderRadius: 4,
        padding: "0 5px",
        marginRight: 8,
        height: 14,
    },
    ProjectName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.green1,
    },
    ClientName: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray400,
    },
    Table: {
        width: "auto",
    },
    TableHeader: {
        flexDirection: "row",
        padding: "7.5px 10px",
        alignItems: "center",
    },
    TableRow: {
        display: "flex",
        flexDirection: "row",
        padding: "7.5px 10px",
        minHeight: 30,
        alignItems: "center",
        borderTop: `1px solid ${theme.gray50}`,
    },
    ActivityColumn: {
        width: "80%",
    },
    CentralizedColumn: {
        width: "10%",
        alignItems: "center",
    },
    TableHeaderText: {
        fontFamily: "Helvetica",
        fontSize: 5,
        fontWeight: 600,
        color: theme.gray400,
    },
    TableBodyText: {
        fontFamily: "Helvetica",
        fontSize: 7,
        fontWeight: 400,
        color: theme.gray800,
    },
    TableBodyLowerText: {
        fontFamily: "Helvetica",
        fontSize: 6,
        fontWeight: 400,
        color: theme.gray400,
    },
    SectionText: {
        fontFamily: "Helvetica",
        fontSize: 10,
        color: theme.gray600,
    },
});
