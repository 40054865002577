import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Icons, theme } from "../../../../../../../../../assets";
import { PopoverRadix } from "../../../../../../../../../components";
import { activities } from "../../../../../../../../../services";
import { Radio } from "../../../../../../../../../styles/radio-group-styles";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TSelectStatus = {
    activity: ActivityDTO;
};

export const SelectStatus = ({ activity }: TSelectStatus) => {
    const [status, setStatus] = useState<"inProgress" | "completed">(
        activity.finished ? "completed" : "inProgress"
    );

    const mutation = useMutation({
        mutationFn: activities.toggleActivityStatus,
        onSuccess: () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const save = () => {
        if (status === "completed" && activity.finished) return;
        if (status === "inProgress" && !activity.finished) return;

        mutation.mutate({
            activityId: activity.id_e,
        });
    };

    return (
        <s.Container>
            <PopoverRadix
                onOpenChange={(open) => {
                    if (!open) {
                        save();
                    }
                }}
                trigger={
                    status === "completed" ? (
                        <s.StatusCompleted>
                            <Icons.IconCheckCircle2
                                width="24px"
                                height="24px"
                            />
                            Atividade concluída
                            <Icons.Chevron color={theme.gray600} />
                        </s.StatusCompleted>
                    ) : (
                        <s.StatusInProgress>
                            <Icons.CheckCircle
                                width="24px"
                                height="24px"
                                color={theme.gray600}
                            />
                            Atividade em progresso
                            <Icons.Chevron color={theme.gray600} />
                        </s.StatusInProgress>
                    )
                }
            >
                <Radio.Root>
                    <s.StyledRadioWrapper
                        onClick={() => setStatus("inProgress")}
                    >
                        <Radio.Item
                            value="inProgress"
                            checked={status === "inProgress"}
                        >
                            <Radio.Indicator />
                        </Radio.Item>
                        <s.StatusInProgress>
                            Atividade em progresso
                        </s.StatusInProgress>
                    </s.StyledRadioWrapper>
                    <s.StyledRadioWrapper
                        onClick={() => setStatus("completed")}
                    >
                        <Radio.Item
                            value="completed"
                            checked={status === "completed"}
                        >
                            <Radio.Indicator />
                        </Radio.Item>
                        <s.StatusCompleted>
                            Atividade concluída
                        </s.StatusCompleted>
                    </s.StyledRadioWrapper>
                </Radio.Root>
            </PopoverRadix>
        </s.Container>
    );
};
