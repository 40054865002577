import { TProject } from "../../../../../../models";
import { ReadDemonstrativeResult } from "../../../../../../services/projects/types";
import {
    formatISOToDate,
    formatStringNumberToMoney as formatMoney,
} from "../../../../../../utils";
import * as s from "./styles";

type ProjectInformationProps = {
    data: ReadDemonstrativeResult | undefined;
    project: TProject;
};

export const ProjectInformation = ({
    project,
    data,
}: ProjectInformationProps) => {
    const formatDate = (date?: string) => (date ? formatISOToDate(date) : "-");

    function formatCost(cost?: string) {
        if (!cost) return "-";

        return formatMoney(cost);
    }

    return (
        <s.Container>
            <s.Row>
                <s.Content>
                    <s.Title>Projeto</s.Title>
                    <s.Text>{project.name}</s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Cliente</s.Title>
                    <s.Text>{project.Client?.name}</s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Data de inicio</s.Title>
                    <s.Text>{formatDate(project.start_at)}</s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Previsão de termino</s.Title>
                    <s.Text>{formatDate(project.end_at)}</s.Text>
                </s.Content>
            </s.Row>
            <s.Row>
                <s.Content>
                    <s.Title>N° pessoas</s.Title>
                    <s.Text>{project.Users?.length}</s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Custo estimado</s.Title>
                    <s.Text>{formatMoney(project.cost)}</s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Custo realizado</s.Title>
                    <s.Text>
                        {formatMoney(data?.projectTotalCost?.toString())}
                    </s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Horas estimadas</s.Title>
                    <s.Text>{project.expectedHours}</s.Text>
                </s.Content>
            </s.Row>
            <s.Row>
                <s.Content>
                    <s.Title>Custo fixo previsto</s.Title>
                    <s.Text>
                        {formatCost(
                            data?.projectCostForecast?.find(
                                ({ type }) => type === "fixed"
                            )?.cost
                        )}
                    </s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Custo fixo realizado</s.Title>
                    <s.Text>
                        {formatCost(data?.fixedAdditionalCostDone?.toFixed(2))}
                    </s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Custo variável previsto</s.Title>
                    <s.Text>
                        {formatCost(
                            data?.projectCostForecast?.find(
                                ({ type }) => type === "variable"
                            )?.cost
                        )}
                    </s.Text>
                </s.Content>
                <s.Content>
                    <s.Title>Custo variável realizado</s.Title>
                    <s.Text>
                        {formatCost(
                            data?.variableAdditionalCostDone?.toFixed(2)
                        )}
                    </s.Text>
                </s.Content>
            </s.Row>
        </s.Container>
    );
};
