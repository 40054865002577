import { Icons, theme } from "../../../../../../../../../assets";
import { Tooltip } from "../../../../../../../../../components";
import * as s from "./styles";

export const ThisMonthTooltip = () => {
    return (
        <Tooltip
            side="top"
            align={"end"}
            positiony={10}
            positionx={10}
            arrow
            positionArrow={89}
            content={
                <s.TooltipContainer>
                    <s.TooltipTitle>
                        Mês em andamento
                        <Icons.Info
                            color={theme.orangeWarning}
                            width="18px"
                            height="18px"
                        />
                    </s.TooltipTitle>
                    Valores em andamento. Para valores finais, acesse a planilha
                    no final do mês.
                </s.TooltipContainer>
            }
        >
            <s.InfoContainer>
                <Icons.Info
                    color={theme.orangeWarning}
                    width="18px"
                    height="18px"
                />
            </s.InfoContainer>
        </Tooltip>
    );
};
