import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        className={className || "icon-search-outline"}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        onClick={onClick}
    >
        <title>{title}</title>
        <path
            stroke={color || theme.gray200}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
            d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
        />
    </svg>
);
