import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const Vouchers = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
`;

export const VoucherRow = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: ${({ columns }) =>
        Array.from({ length: columns }).map(() => "1fr ")};
    border-bottom: 1px solid ${({ theme }) => theme.gray100};
    padding: 10px;
    gap: 30px;

    div {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    p {
        font-weight: 500;
        color: ${({ theme }) => theme.purple500};
    }

    &:last-child {
        border: none;
    }
`;
