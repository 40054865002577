import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <>
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "exclamation-filled"}
        >
            <title>{title}</title>
            <mask
                id="mask0_20006_258475"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect
                    id="Bounding box"
                    width="24"
                    height="24"
                    fill={color || theme.yellow}
                />
            </mask>

            <path
                id="warning_2"
                d="M3.42555 20.4999C3.25605 20.4999 3.10382 20.4584 2.96887 20.3756C2.83392 20.2927 2.72897 20.1835 2.654 20.048C2.57602 19.9133 2.53312 19.7675 2.52532 19.6105C2.51752 19.4534 2.5599 19.2979 2.65245 19.1438L11.2132 4.35603C11.3057 4.202 11.4216 4.08813 11.5607 4.01441C11.6998 3.94068 11.8462 3.90381 12.0001 3.90381C12.1539 3.90381 12.3004 3.94068 12.4395 4.01441C12.5786 4.08813 12.6944 4.202 12.787 4.35603L21.3477 19.1438C21.4403 19.2979 21.4827 19.4534 21.4749 19.6105C21.4671 19.7675 21.4242 19.9133 21.3462 20.048C21.2712 20.1835 21.1663 20.2927 21.0313 20.3756C20.8964 20.4584 20.7441 20.4999 20.5746 20.4999H3.42555ZM12.0001 17.8076C12.2289 17.8076 12.4208 17.7302 12.5756 17.5754C12.7304 17.4206 12.8078 17.2288 12.8078 16.9999C12.8078 16.7711 12.7304 16.5792 12.5756 16.4244C12.4208 16.2696 12.2289 16.1922 12.0001 16.1922C11.7712 16.1922 11.5794 16.2696 11.4246 16.4244C11.2698 16.5792 11.1924 16.7711 11.1924 16.9999C11.1924 17.2288 11.2698 17.4206 11.4246 17.5754C11.5794 17.7302 11.7712 17.8076 12.0001 17.8076ZM12.0004 15.1922C12.213 15.1922 12.3911 15.1204 12.5347 14.9766C12.6783 14.8329 12.7501 14.6547 12.7501 14.4423V10.9422C12.7501 10.7297 12.6782 10.5516 12.5344 10.4079C12.3906 10.2641 12.2124 10.1922 11.9998 10.1922C11.7872 10.1922 11.6091 10.2641 11.4655 10.4079C11.3219 10.5516 11.2501 10.7297 11.2501 10.9422V14.4423C11.2501 14.6547 11.322 14.8329 11.4658 14.9766C11.6096 15.1204 11.7878 15.1922 12.0004 15.1922Z"
                fill={color || theme.yellow}
            />
        </svg>
    </>
);
