/* eslint-disable no-continue */
import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import {
    Input,
    ModalUserLimitReached,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
    Tooltip,
} from "../../..";
import { Icons } from "../../../../assets";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TOption } from "../../../../models";
import {
    TInvite,
    TSketch,
} from "../../../../pages/collaborators-invite/collaborators-invite";
import { formatCurrency } from "../../../../pages/organization/project/tab-costs/utils";
import { roles, sectors, invite } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import {
    formatToBRMoneyWithoutMoneySign,
    onlyNumbers,
} from "../../../../utils";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import ContainerModalScroll from "../container-modal-scroll";
import * as s from "./styled-modal-invite-single";
import schema from "./validation";

type TModalInviteSingle = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setIsOpenChoose?: Dispatch<SetStateAction<boolean>>;
    sketchData?: TSketch;
    inviteData?: TInvite;
    getSketches?: TUseFetch;
    getInvites?: TUseFetch;
    editSketch?: boolean;
    editInvite?: boolean;
};

export type TInputs = {
    name?: string;
    email?: string;
    hourValue?: string;
    value?: string;
    role?: { label: string; value: string };
    sectors?: { label: string; value: string }[];
    projects?: { label: string; value: string }[];
    contract?: { label: string; value: string };
    workload?: string;
    monthlyValue: string;
};

export type TForm = {
    id: string;
    select?: boolean;
    label: string;
    icon?: JSX.Element;
    placeholder: string;
    options?: TOption[];
    customMask?: (value: string) => string;
    type?: string;
    mask?: string;
    isMulti?: boolean;
};

export type TSectors = {
    id_e: string;
    name: string;
    Projects: {
        id_e: string;
        name: string;
    }[];
}[];

const ModalInviteSingle = ({
    setOpen,
    setIsOpenChoose,
    getSketches,
    getInvites,
    sketchData,
    inviteData,
    editSketch,
    editInvite,
}: TModalInviteSingle) => {
    const { verifyPermission } = useUser();
    const [inviteType, setActionType] = useState<
        "createSketch" | "updateSketch" | "createInvite" | "updateInvite"
    >();
    const [allSectors, setAllSectors] = useState<TSectors>();
    const [sectorsOptions, setSectorsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [projectsOptions, setProjectsOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [rolesOptions, setRolesOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [contractOptions, setContractOptions] = useState<
        { label: string; value: string }[]
    >([]);
    const [maxUsersLimitReached, setMaxUsersLimitReached] = useState(false);

    const renderDefaultValues = (): Omit<TInputs, "monthlyValue"> => {
        let response = {};

        if (sketchData) {
            response = {
                name: sketchData?.name.value || "",
                email: sketchData?.email.value || "",
                hourValue:
                    formatToBRMoneyWithoutMoneySign(
                        Number(sketchData?.value)
                    ) || "",
                role: sketchData?.role
                    ? {
                          label: sketchData?.role || "",
                          value: sketchData?.role || "",
                      }
                    : undefined,
                contract: sketchData?.contract_format
                    ? {
                          label: sketchData?.contract_format || "",
                          value: sketchData?.contract_format || "",
                      }
                    : undefined,
                sectors:
                    sketchData?.sectors?.map(
                        (sector: { name: string; id_e: string }) => {
                            return { label: sector.name, value: sector.id_e };
                        }
                    ) || [],
                projects:
                    sketchData?.projects?.map(
                        (sector: { name: string; id_e: string }) => {
                            return { label: sector.name, value: sector.id_e };
                        }
                    ) || [],
                workload: sketchData.workload,
            };
        }
        if (inviteData) {
            response = {
                name: inviteData.name,
                email: inviteData.email,
                hourValue: formatToBRMoneyWithoutMoneySign(
                    Number(inviteData.hourValue || 0)
                ),
                role: inviteData.role
                    ? {
                          label: inviteData.role,
                          value: inviteData.role,
                      }
                    : undefined,
                contract: inviteData.contract
                    ? {
                          label: inviteData.contract,
                          value: inviteData.contract,
                      }
                    : undefined,
                sectors:
                    inviteData.sectors?.map(
                        (sector: { name: string; id: string }) => {
                            return { label: sector.name, value: sector.id };
                        }
                    ) || [],
                projects:
                    inviteData.projects?.map(
                        (sector: { name: string; id: string }) => {
                            return { label: sector.name, value: sector.id };
                        }
                    ) || [],
                workload: inviteData.workload,
            };
        }
        return response;
    };

    const defaultValues = renderDefaultValues();

    const {
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm<TInputs>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const getSectors = useFetch({
        fn: sectors.getSector,
        errorMessage: "Erro ao pegar setores",
        params: {
            all: Boolean(
                verifyPermission({
                    id: "projects_sectors",
                    rule: "view",
                })
            ),
            projects: true,
        },
    });

    const getRoles = useFetch({
        fn: roles.getRole,
        errorMessage: "Erro ao pegar atuações",
        params: { all: true },
    });

    const createSketch = useFetch({
        fn: invite.createSketch,
        errorMessage: "Erro ao criar rascunho",
        start: false,
    });

    const updateSketch = useFetch({
        fn: invite.updateSketch,
        errorMessage: "Erro ao salvar rascunho",
        start: false,
    });

    const createInvite = useFetch({
        fn: invite.createInvite,
        errorMessage: "Erro ao criar convite",
        start: false,
    });

    const updateInvite = useFetch({
        fn: invite.updateInvite,
        errorMessage: "Erro ao salvar convite",
        start: false,
    });

    const onSubmit = (data: TInputs) => {
        const numberHourValue = Number(
            data?.hourValue?.slice(3).replace(",", ".") || 0
        );
        if (inviteType === "updateSketch") {
            updateSketch.onRefresh({
                inviteSketchesIds: [sketchData?.id_e || ""],
                name: data.name,
                email: data.email,
                contractFormat: data?.contract?.label || "",
                value: numberHourValue?.toString() || "",
                role: data?.role?.value || "",
                sectors: data?.sectors?.map((sector) => sector?.value) || [],
                projects:
                    data?.projects?.map((project) => project?.value) || [],
                workload: Number(data.workload ?? 168),
            });
        }
        if (inviteType === "updateInvite") {
            updateInvite.onRefresh({
                inviteIds: [inviteData?.id_e || ""],
                multipleUpdate: true,
                name: data.name,
                email: data.email,
                contract: data?.contract?.label || "",
                hourValue: numberHourValue?.toString() || "",
                roleName: data?.role?.value || null,
                sectors: data?.sectors?.map((sector) => sector?.value) || [],
                projects:
                    data?.projects?.map((project) => project?.value) || [],
                workload: Number(data.workload ?? 168),
            });
        }
        if (inviteType === "createSketch") {
            createSketch.onRefresh({
                name: data.name,
                email: data.email,
                contract_format: data?.contract?.label || "",
                value: numberHourValue?.toString() || "",
                role: data?.role?.value || "",
                sectors: data?.sectors?.map((sector) => sector?.value) || [],
                projects:
                    data?.projects?.map((project) => project?.value) || [],
                workload: Number(data.workload ?? 168),
            });
        }
        if (inviteType === "createInvite") {
            createInvite.onRefresh({
                name: data.name,
                email: data.email,
                contract: data?.contract?.label || "",
                hourValue: numberHourValue || 0,
                role: data?.role?.value || "",
                sectors: data?.sectors?.map((sector) => sector?.value) || [],
                projects:
                    data?.projects?.map((project) => project?.value) || [],
                workload: Number(data.workload ?? 168),
            });
        }

        window.scrollTo(0, 0);
    };

    const returnOptions = (
        id: string,
        options: TOption[] | undefined
    ): TOption[] | undefined => {
        if (id === "projects") return projectsOptions;
        if (id === "sectors") return sectorsOptions;
        if (id === "role") return rolesOptions;
        if (id === "contract") return contractOptions;
        return options;
    };

    useEffect(() => {
        const tempContractOptions = [
            { label: "CLT", value: "CLT" },
            { label: "PJ", value: "PJ" },
            { label: "Estágio", value: "Estágio" },
        ];
        setContractOptions(tempContractOptions);
    }, []);

    useEffect(() => {
        if (getSectors.response) {
            const tempSectorsOptions: { label: string; value: string }[] = [];
            getSectors.response.sectors.forEach(
                (sector: { name: string; id_e: string }) =>
                    tempSectorsOptions.push({
                        label: sector.name,
                        value: sector.id_e,
                    })
            );

            setAllSectors(getSectors.response.sectors);
            setSectorsOptions(tempSectorsOptions);
        }
    }, [getSectors.response]);

    useEffect(() => {
        if (getRoles.response) {
            const tempRolesOptions: { label: string; value: string }[] = [];
            getRoles.response.roles.forEach((role: { name: string }) =>
                tempRolesOptions.push({
                    label: role.name,
                    value: role.name,
                })
            );
            setRolesOptions(tempRolesOptions);
        }
    }, [getRoles.response]);

    useEffect(() => {
        if (createSketch.response) {
            toast.success("Rascunho criado com sucesso");
            getSketches?.onRefresh({
                length: 8,
                page: 1,
            });
            getInvites?.onRefresh({
                all: true,
                length: 8,
                page: 1,
            });
            if (setIsOpenChoose) setIsOpenChoose(false);
            setOpen(false);
        }
    }, [createSketch]);

    useEffect(() => {
        if (updateSketch.response) {
            toast.success("Rascunho editado com sucesso");
            getSketches?.onRefresh({
                length: 8,
                page: 1,
            });
            if (setIsOpenChoose) setIsOpenChoose(false);
            setOpen(false);
        }
    }, [updateSketch]);

    useEffect(() => {
        if (createInvite.response) {
            toast.success("Convite criado com sucesso");
            getInvites?.onRefresh({
                all: true,
                length: 8,
                page: 1,
            });
            if (setIsOpenChoose) setIsOpenChoose(false);
            setOpen(false);
            Mixpanel.track("Envio de convite");
        }
    }, [createInvite.response]);

    useEffect(() => {
        if (
            createInvite.error &&
            createInvite.error.body?.message ===
                "user limit reached for this plan"
        ) {
            setMaxUsersLimitReached(true);
        }
    }, [createInvite.error]);

    useEffect(() => {
        if (updateInvite.response) {
            toast.success("Convite editado com sucesso");
            getInvites?.onRefresh({
                all: true,
                length: 8,
                page: 1,
            });
            if (setIsOpenChoose) setIsOpenChoose(false);
            setOpen(false);
        }
    }, [updateInvite]);

    const renderButtons = () => {
        if (!editSketch && !editInvite) {
            return (
                <>
                    <SecondaryButton
                        variation="small"
                        onClick={() => setOpen(false)}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        variation="small"
                        onClick={() => setActionType("createSketch")}
                        type="submit"
                    >
                        SALVAR RASCUNHO
                    </PrimaryButton>
                    <PrimaryButton
                        variation="small"
                        onClick={() => setActionType("createInvite")}
                        type="submit"
                        icon={<Icons.SendFilled />}
                    >
                        ENVIAR CONVITE
                    </PrimaryButton>
                </>
            );
        }
        if (editSketch) {
            return (
                <>
                    <SecondaryButton
                        variation="small"
                        onClick={() => setOpen(false)}
                    >
                        CANCELAR
                    </SecondaryButton>
                    <PrimaryButton
                        variation="small"
                        onClick={() => setActionType("updateSketch")}
                        type="submit"
                    >
                        SALVAR
                    </PrimaryButton>
                </>
            );
        }
        if (editInvite) {
            return (
                <>
                    <SecondaryButton
                        variation="small"
                        onClick={() => setOpen(false)}
                    >
                        CANCELAR
                    </SecondaryButton>

                    <PrimaryButton
                        variation="small"
                        onClick={() => setActionType("updateInvite")}
                        type="submit"
                    >
                        SALVAR
                    </PrimaryButton>
                </>
            );
        }
        return <></>;
    };

    const onBlurWorkload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const hourValue = Number(onlyNumbers(watch("hourValue") || "")) / 100;
        const workload = Number(value);
        const monthlyValue = hourValue * workload;
        setValue(
            "monthlyValue",
            formatCurrency(monthlyValue.toFixed(2)) || watch("monthlyValue")
        );
    };

    const onBlurMonthlyValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (watch("workload")) {
            const monthlyValue = Number(onlyNumbers(value)) / 100;
            const workload = Number(watch("workload"));
            const hourValue = monthlyValue / workload;
            setValue(
                "hourValue",
                formatCurrency(hourValue.toFixed(2)) || watch("hourValue")
            );
        }
    };

    const onBlurHourValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (watch("monthlyValue")) {
            const hourValue = Number(onlyNumbers(value)) / 100;
            const workload = Number(watch("workload"));
            const monthlyValue = hourValue * workload;
            setValue(
                "monthlyValue",
                formatCurrency(monthlyValue.toFixed(2)) || watch("monthlyValue")
            );
        }
    };

    const onChangeSectors = (
        onChange: (newValue: TOption[]) => void,
        event: TOption[]
    ) => {
        onChange(event);
        if (!allSectors?.length) return;
        const selectedSectorsIds = event.map(({ value }) => value);
        const projectsInSectors: TOption[] = [];
        for (let i = 0; i < allSectors.length; i += 1) {
            const sector = allSectors[i];
            if (!selectedSectorsIds.includes(sector.id_e)) {
                continue;
            }
            sector.Projects.forEach((project) => {
                const projectExists = projectsInSectors.some(
                    ({ value }) => value === project.id_e
                );
                if (!projectExists) {
                    projectsInSectors.push({
                        label: project.name,
                        value: project.id_e,
                    });
                }
            });
        }
        setProjectsOptions(projectsInSectors);
    };

    const monthlyPayment =
        (Number(onlyNumbers(defaultValues.hourValue ?? "0") || 0) / 100) *
        Number(watch("workload") || 1);

    return (
        <>
            {maxUsersLimitReached && (
                <ModalUserLimitReached setOpen={setMaxUsersLimitReached} />
            )}
            <ContainerModalScroll>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <s.Container>
                        <s.Title>
                            {editSketch || editInvite
                                ? `Editar `
                                : `Adicionar `}
                            {editSketch ? `rascunho` : `convite`}
                        </s.Title>

                        <div className="form">
                            <div>
                                <Controller
                                    name={"name"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Input
                                            label="Nome completo *"
                                            placeholder="Digite o nome completo do profissional"
                                            value={value}
                                            onChange={onChange}
                                            error={errors.name?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name={"email"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Input
                                            label="E-mail corporativo *"
                                            placeholder="Digite o e-mail corporativo completo"
                                            value={value}
                                            onChange={onChange}
                                            error={errors.name?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className="input-row">
                                <Controller
                                    name={"contract"}
                                    control={control}
                                    render={({
                                        field: { ref, onChange, value },
                                    }) => (
                                        <SelectInput
                                            label="Formato de contratação"
                                            placeholder="Selecione um formato"
                                            value={value}
                                            options={returnOptions(
                                                "contract",
                                                []
                                            )}
                                            error={
                                                errors.contract?.message || ""
                                            }
                                            inputRef={ref}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <Controller
                                    name={"workload"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Input
                                            label={"Horas mensais"}
                                            placeholder={
                                                "Digite as horas mensais"
                                            }
                                            value={value as string}
                                            onChange={(e) => {
                                                if (
                                                    Number.isNaN(
                                                        Number(e.target.value)
                                                    )
                                                ) {
                                                    e.target.value = "";
                                                    return;
                                                }
                                                onChange(e);
                                            }}
                                            error={errors.workload?.message}
                                            maxLength={3}
                                            onBlur={onBlurWorkload}
                                        />
                                    )}
                                />
                            </div>
                            <div className="input-row">
                                <Controller
                                    name="monthlyValue"
                                    control={control}
                                    defaultValue={
                                        watch("hourValue")
                                            ? formatCurrency(
                                                  monthlyPayment.toFixed(2)
                                              )
                                            : ""
                                    }
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Input
                                            type="text"
                                            label="Valor mensal (R$)"
                                            placeholder="Digite o valor mensal"
                                            value={value as string}
                                            onChange={(e) => {
                                                onChange(
                                                    formatCurrency(
                                                        e.target.value
                                                    )
                                                );
                                            }}
                                            error={errors.hourValue?.message}
                                            onBlur={onBlurMonthlyValue}
                                        />
                                    )}
                                />
                                <Controller
                                    name={"hourValue"}
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Input
                                            label="Valor hora (R$)"
                                            placeholder="0,00"
                                            value={value as string}
                                            onChange={(e) => {
                                                onChange(
                                                    formatCurrency(
                                                        e.target.value
                                                    )
                                                );
                                            }}
                                            error={errors.monthlyValue?.message}
                                            onBlur={onBlurHourValue}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name={"role"}
                                    control={control}
                                    render={({
                                        field: { ref, onChange, value },
                                    }) => (
                                        <SelectInput
                                            icon={<Icons.Chevron />}
                                            label="Atuação"
                                            placeholder="Selecione a atuação do profissional"
                                            value={value}
                                            options={returnOptions("role", [])}
                                            error={errors.role?.message || ""}
                                            inputRef={ref}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name={"sectors"}
                                    control={control}
                                    render={({
                                        field: { ref, onChange, value },
                                    }) => (
                                        <SelectInput
                                            icon={<Icons.Chevron />}
                                            label="Setor"
                                            placeholder="Selecione um ou mais setores que o profissional pertence"
                                            value={value}
                                            options={returnOptions(
                                                "sectors",
                                                []
                                            )}
                                            error={
                                                errors.sectors?.message || ""
                                            }
                                            inputRef={ref}
                                            onChange={(event: TOption[]) => {
                                                onChangeSectors(
                                                    onChange,
                                                    event
                                                );
                                            }}
                                            isMulti
                                        />
                                    )}
                                />
                            </div>
                            <Tooltip
                                align="start"
                                content={
                                    <s.TooltipContent>
                                        Você poderá selecionar um projeto ao
                                        selecionar um setor
                                    </s.TooltipContent>
                                }
                                positiony={10}
                                arrow
                                open={
                                    watch("sectors")?.length ? false : undefined
                                }
                            >
                                <div>
                                    <Controller
                                        name={"projects"}
                                        control={control}
                                        render={({
                                            field: { ref, onChange, value },
                                        }) => (
                                            <SelectInput
                                                icon={<Icons.Chevron />}
                                                label="Projetos"
                                                placeholder="Selecione um ou mais projetos que o profissional pertence"
                                                value={value}
                                                options={returnOptions(
                                                    "projects",
                                                    []
                                                )}
                                                disabled={
                                                    !watch("sectors")?.length
                                                }
                                                error={errors.projects?.message}
                                                inputRef={ref}
                                                onChange={onChange}
                                                isMulti
                                            />
                                        )}
                                    />
                                </div>
                            </Tooltip>
                        </div>

                        <s.ButtonsContainer>
                            {renderButtons()}
                        </s.ButtonsContainer>
                    </s.Container>
                </form>
            </ContainerModalScroll>
        </>
    );
};

export default ModalInviteSingle;
