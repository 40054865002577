import * as Popover from "@radix-ui/react-popover";
import { ReactNode } from "react";

import * as s from "./styled-popover";

type TPopover = {
    trigger: JSX.Element;
    children: ReactNode;
    closeOnSelect?: boolean;
    modal?: boolean;
    open?: boolean;
    onOpenChange?: (e: boolean) => void;
    closeIcon?: JSX.Element;
    side?: "bottom" | "top" | "right" | "left";
    align?: "end" | "start" | "center";
    sideOffset?: number;
    alignOffset?: number;
    avoidCollisions?: boolean;
};

const PopoverRadix = ({
    trigger,
    children,
    closeOnSelect,
    modal,
    open,
    onOpenChange,
    closeIcon,
    side,
    align,
    sideOffset,
    alignOffset,
    avoidCollisions,
}: TPopover) => {
    return (
        <Popover.Root onOpenChange={onOpenChange} modal={modal} open={open}>
            <Popover.Trigger>{trigger}</Popover.Trigger>

            {closeOnSelect ? (
                <Popover.Portal>
                    <Popover.Close>
                        <Popover.Content
                            avoidCollisions={avoidCollisions}
                            sideOffset={sideOffset}
                            alignOffset={alignOffset}
                            side={side}
                            align={align}
                        >
                            {children}
                        </Popover.Content>
                    </Popover.Close>
                </Popover.Portal>
            ) : (
                <Popover.Portal>
                    <s.StyledContent
                        avoidCollisions={avoidCollisions}
                        sideOffset={sideOffset}
                        alignOffset={alignOffset}
                        side={side}
                        align={align}
                    >
                        {closeIcon && (
                            <Popover.Close id="close-btn">
                                {closeIcon}
                            </Popover.Close>
                        )}
                        {children}
                    </s.StyledContent>
                </Popover.Portal>
            )}
        </Popover.Root>
    );
};

export default PopoverRadix;
