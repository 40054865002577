import * as AlertDialog from "@radix-ui/react-alert-dialog";

import { PrimaryButton, SecondaryButton } from "../../atoms";
import * as s from "./styled-alert-action";

type TAlertProject = {
    trigger?: JSX.Element;
    title: string;
    description: string;
    textBtn: string;
    open?: boolean;
    className?: string;
    onOpenChange?: () => void;
    onClickBtn: () => void;
};

export default ({
    trigger,
    description,
    title,
    textBtn,
    open,
    onOpenChange,
    onClickBtn,
    className,
}: TAlertProject) => {
    return (
        <div>
            <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
                <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>

                <AlertDialog.AlertDialogPortal>
                    <s.Overlay />
                    <s.Content>
                        <s.TextWrapper>
                            <AlertDialog.Title>
                                <s.Title>{title}</s.Title>
                            </AlertDialog.Title>

                            <AlertDialog.Description>
                                <s.Description>{description}</s.Description>
                            </AlertDialog.Description>
                        </s.TextWrapper>

                        <s.ButtonWrapper>
                            <AlertDialog.Cancel>
                                <SecondaryButton
                                    variation="small"
                                    className={"alert-cancel"}
                                >
                                    Cancelar
                                </SecondaryButton>
                            </AlertDialog.Cancel>
                            <AlertDialog.Action>
                                <PrimaryButton
                                    onClick={onClickBtn}
                                    className={className || "alert-proceed"}
                                    variation="small"
                                >
                                    {textBtn}
                                </PrimaryButton>
                            </AlertDialog.Action>
                        </s.ButtonWrapper>
                    </s.Content>
                </AlertDialog.AlertDialogPortal>
            </AlertDialog.Root>
        </div>
    );
};
