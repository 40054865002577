import { UseMutationResult, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import SpeechRecognition, {
    useSpeechRecognition,
} from "react-speech-recognition";

import { Icons, theme } from "../../../../assets";
import { PrimaryButton, Tag } from "../../../../components";
import ContainerModalScroll from "../../../../components/molecules/modals/container-modal-scroll";
import {
    ModalText,
    ModalTitle,
} from "../../../../components/molecules/modals/styled-generic-modal";
import { Separator } from "../../../../components/molecules/sidebar/styled-sidebar";
import { useUser } from "../../../../context";
import {
    TTrackCreateBody,
    TTrackCreateResponse,
    TTrackError,
} from "../../../../models";
import { activities } from "../../../../services";
import {
    ReadActivitiesResponse,
    ReadActivitiesVariables,
} from "../../../../services/activities/types";
import { findClosestMatch } from "../../../../utils/string-manipulation/find-closest-match-input";
import * as s from "./styles";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    createEntry: UseMutationResult<
        TTrackCreateResponse,
        TTrackError,
        TTrackCreateBody,
        unknown
    >;
};

const VoiceAssistant = ({ setOpen, createEntry }: Props) => {
    const { userProjects } = useUser();

    const [project, setProject] = useState("");
    const [projectId, setProjectId] = useState("");
    const [activity, setActivity] = useState("");
    const [activityId, setActivityId] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition({
        transcribing: true,
    });

    const readActivities = useQuery<
        ReadActivitiesVariables,
        { message: string },
        ReadActivitiesResponse
    >({
        queryKey: ["readActivities"],
        queryFn: () => activities.readActivities({ projectId }),
        enabled: false,
    });

    useEffect(() => {
        const formattedTranscript = transcript.toLowerCase();

        const projectMatch = formattedTranscript.match(
            /projeto\s+(.*)\s+atividade/
        );
        const activityMatch = formattedTranscript.match(
            /atividade\s+(.*)\s+das/
        );
        const startMatch = formattedTranscript.match(
            /das\s+(\d{1,2}:\d{2})\s+às/
        );
        const endMatch = formattedTranscript.match(/às\s+(\d{1,2}:\d{2})/);

        if (projectMatch) {
            setProject(projectMatch[1].trim());
        }
        if (activityMatch) {
            setActivity(activityMatch[1].trim());
        }
        if (startMatch) {
            setStart(startMatch[1].trim());
        }
        if (endMatch) {
            setEnd(endMatch[1].trim());
        }
    }, [transcript]);

    useEffect(() => {
        if (transcript) {
            const result = findClosestMatch(
                transcript,
                userProjects.map((project) => project.name.toLowerCase())
            );
            const projectId = userProjects.find(
                (project) => project.name.toLowerCase() === result.match
            )?.id_e;
            setProject(result.match);
            if (projectId) {
                setProjectId(projectId);
            }
        }
    }, [project, userProjects]);

    useEffect(() => {
        if (projectId) {
            readActivities.refetch();
        }
    }, [projectId]);

    useEffect(() => {
        if (activity && readActivities.data) {
            const allActivities = [
                ...readActivities.data.noArchived.sections.reduce(
                    (acc, section) => {
                        return acc.concat(section.activities as never);
                    },
                    []
                ),
                ...readActivities.data.noArchived.activities,
            ];
            const result = findClosestMatch(
                activity,
                allActivities.map((activity) =>
                    activity.name.toLowerCase()
                ) as string[]
            );
            const activityId = allActivities.find(
                (activity) => activity.name.toLowerCase() === result.match
            )?.id_e;
            setActivity(result.match);
            if (activityId) {
                setActivityId(activityId);
            }
        }
    }, [activity]);

    const startAt = new Date().setHours(
        Number(start.split(":")[0]),
        Number(start.split(":")[1] || 0)
    );
    const endAt = new Date().setHours(
        Number(end.split(":")[0]),
        Number(end.split(":")[1] || 0)
    );
    const startAtDate = new Date(startAt);
    const endAtDate = new Date(endAt);

    function create() {
        const project = userProjects.find(
            (project) => project.id_e === projectId
        );
        createEntry.mutate({
            activityId,
            project: projectId,
            startAt: startAtDate,
            endAt: endAtDate,
            sectorId: project?.Sectors[0].id_e,
        });
        resetTranscript();
        setProject("");
        setActivity("");
        setStart("");
        setEnd("");
        setProjectId("");
        setActivityId("");
    }

    const selectedProject = userProjects.find(
        (project) => project.id_e === projectId
    );

    const canCreate = Boolean(
        projectId && activityId && start && end && !listening
    );
    const notUnderstood = Boolean(
        (!projectId ||
            !activityId ||
            Number.isNaN(startAt) ||
            Number.isNaN(endAt)) &&
            transcript &&
            !listening
    );

    return (
        <ContainerModalScroll closeIcon onOpenChange={setOpen}>
            <s.Container>
                {!isMicrophoneAvailable || !browserSupportsSpeechRecognition ? (
                    <s.ErrorFlag>
                        <Icons.XOutline color={theme.red} />
                        {!isMicrophoneAvailable
                            ? "Microfone não disponível."
                            : "Navegador não suporta reconhecimento de voz."}
                    </s.ErrorFlag>
                ) : (
                    <>
                        <ModalTitle>Assistente de voz do Paper ON</ModalTitle>
                        <s.BetaFlag>
                            <Icons.Warning color={theme.orange} />
                            Essa funcionalidade está em beta, pode não funcionar
                            corretamente.
                        </s.BetaFlag>
                        <s.SubTitle>
                            Instruções para funcionar corretamente
                        </s.SubTitle>
                        <ModalText>
                            Fale naturalmente a frase: Projeto [nome do projeto]
                            atividade [nome da atividade] das [hora] às [hora].
                        </ModalText>
                        <ModalText>
                            Exemplo: Projeto <strong>PaperON</strong> atividade{" "}
                            <strong>realizando consulta</strong> das{" "}
                            <strong>08</strong> às <strong>15</strong>
                        </ModalText>
                        <ModalText>
                            É necessário que o projeto e a atividade existam no
                            sistema.
                        </ModalText>
                        <s.PulsingIconWrapper>
                            <Icons.CirclePulsating
                                color={
                                    listening && !loading
                                        ? theme.purple500
                                        : theme.gray300
                                }
                                pulse={listening && !loading}
                            />
                        </s.PulsingIconWrapper>
                        {notUnderstood && (
                            <s.ErrorFlag>
                                <Icons.XOutline color={theme.red} />
                                Não entendi, tente falar novamente.
                            </s.ErrorFlag>
                        )}
                        <s.ListenStopButtons>
                            <PrimaryButton
                                variation="small"
                                onClick={() => {
                                    SpeechRecognition.startListening();
                                    setLoading(true);
                                    setTimeout(() => {
                                        setLoading(false);
                                    }, 1000);
                                }}
                                disabled={listening}
                                loading={loading}
                            >
                                {listening ? "Ouvindo..." : "Ouvir"}
                            </PrimaryButton>
                            <PrimaryButton
                                variation="small"
                                onClick={() => {
                                    SpeechRecognition.stopListening();
                                    resetTranscript();
                                }}
                                disabled={!listening}
                            >
                                Parar
                            </PrimaryButton>
                        </s.ListenStopButtons>
                        <Separator />
                        <s.ResultContainer>
                            <ModalTitle>Resultado</ModalTitle>
                            <s.OutputText>
                                Projeto:{" "}
                                {selectedProject && (
                                    <Tag color={selectedProject.color}>
                                        {selectedProject.name}
                                    </Tag>
                                )}
                            </s.OutputText>
                            <s.OutputText>Atividade: {activity}</s.OutputText>
                            <s.OutputText>Inicio: {start}</s.OutputText>
                            <s.OutputText>Fim: {end}</s.OutputText>
                        </s.ResultContainer>
                        <s.Buttons>
                            <PrimaryButton
                                variation="small"
                                disabled={!canCreate || notUnderstood}
                                loading={createEntry.isLoading}
                                onClick={create}
                            >
                                Criar
                            </PrimaryButton>
                            <PrimaryButton
                                variation="small"
                                disabled={!canCreate}
                                onClick={() => {
                                    resetTranscript();
                                    setProject("");
                                    setActivity("");
                                    setStart("");
                                    setEnd("");
                                    setProjectId("");
                                    setActivityId("");
                                }}
                            >
                                Tentar novamente
                            </PrimaryButton>
                        </s.Buttons>
                    </>
                )}
            </s.Container>
        </ContainerModalScroll>
    );
};

export default VoiceAssistant;
