import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className={className || "icon-repeat"}
        onClick={onClick}
    >
        <title>{title}</title>
        <mask
            id="mask0_25737_5500"
            style={{
                maskType: "alpha",
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
        >
            <rect
                x="0.400024"
                y="0.399902"
                width="19.2"
                height="19.2"
                fill="#D9D9D9"
            />
        </mask>
        <g mask="url(#mask0_25737_5500)">
            <path
                d="M6.04008 15.5201L6.67574 16.1558C6.8253 16.3053 6.90008 16.4768 6.90008 16.6701C6.90008 16.8634 6.82993 17.0334 6.68964 17.1801C6.5366 17.3268 6.36341 17.4001 6.17008 17.4001C5.97674 17.4001 5.80707 17.3274 5.66104 17.1819L3.78088 15.3092C3.70785 15.2365 3.65613 15.1577 3.62572 15.0728C3.59529 14.988 3.58008 14.8971 3.58008 14.8001C3.58008 14.7031 3.59529 14.6122 3.62572 14.5274C3.65613 14.4425 3.70785 14.3637 3.78088 14.291L5.66104 12.4183C5.80707 12.2728 5.97674 12.2001 6.17008 12.2001C6.36341 12.2001 6.5366 12.2734 6.68964 12.4201C6.82993 12.5668 6.90008 12.7368 6.90008 12.9301C6.90008 13.1234 6.8253 13.2949 6.67574 13.4444L6.04008 14.0801H14.3201V11.6801C14.3201 11.4761 14.3887 11.3051 14.5259 11.1671C14.6631 11.0291 14.8331 10.9601 15.0359 10.9601C15.2387 10.9601 15.4101 11.0291 15.5501 11.1671C15.6901 11.3051 15.7601 11.4761 15.7601 11.6801V14.0801C15.7601 14.4761 15.6191 14.8151 15.3371 15.0971C15.0551 15.3791 14.7161 15.5201 14.3201 15.5201H6.04008ZM13.9601 5.9201H5.68008V8.3201C5.68008 8.5241 5.61149 8.6951 5.4743 8.8331C5.3371 8.9711 5.1671 9.0401 4.9643 9.0401C4.76148 9.0401 4.59008 8.9711 4.45008 8.8331C4.31008 8.6951 4.24008 8.5241 4.24008 8.3201V5.9201C4.24008 5.5241 4.38108 5.1851 4.66308 4.9031C4.94508 4.6211 5.28408 4.4801 5.68008 4.4801H13.9601L13.3244 3.84444C13.1749 3.69488 13.1001 3.52343 13.1001 3.3301C13.1001 3.13676 13.1702 2.96676 13.3105 2.8201C13.4636 2.67343 13.6367 2.6001 13.8301 2.6001C14.0234 2.6001 14.1931 2.67282 14.3391 2.81828L16.2193 4.691C16.2923 4.76373 16.344 4.84252 16.3744 4.92738C16.4049 5.01222 16.4201 5.10312 16.4201 5.2001C16.4201 5.29707 16.4049 5.38798 16.3744 5.47282C16.344 5.55767 16.2923 5.63646 16.2193 5.7092L14.3391 7.58192C14.1931 7.72737 14.0267 7.79676 13.8401 7.7901C13.6534 7.78343 13.4836 7.70676 13.3305 7.5601C13.1902 7.41343 13.1201 7.24343 13.1201 7.0501C13.1201 6.85676 13.1931 6.68705 13.3392 6.54096L13.9601 5.9201Z"
                fill={color || "#9E9BA7"}
            />
        </g>
    </svg>
);
