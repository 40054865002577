import styled from "styled-components";

export const Container = styled.div`
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    border-radius: 4px;

    .show-on-hover {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        background-color: ${({ theme }) => theme.gray50};

        .show-on-hover {
            opacity: 1;
        }
    }
`;

export const LeftSideContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const RightSideContent = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

export const Users = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const UsersLength = styled.div`
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    background-color: ${({ theme }) => theme.purple700};
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const UserAvatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    background-color: ${({ theme }) => theme.gray100};
    cursor: pointer;
`;

export const AddUserButton = styled.button`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid ${({ theme }) => theme.gray400};
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Button = styled.button`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TooltipContainer = styled.div`
    padding: 4px 12px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`;
