import dayjs from "dayjs";
import React, { useState } from "react";

import { Icons, theme } from "../../../../../../../../assets";
import { TBackOfficeData } from "../../../../../../types";
import * as generalStyles from "../../../../styles";
import { BugReportAttachmentModal } from "../../modals/bug-report-attachment/bug-report-attachment";
import * as s from "./styles";

export const NPSCards = ({ data }: { data: TBackOfficeData }) => {
    const [attachmentToOpen, setAttachmentToOpen] = useState<string>("");

    return (
        <s.Container>
            {attachmentToOpen && (
                <BugReportAttachmentModal
                    setOpen={(open) => {
                        if (!open) {
                            setAttachmentToOpen("");
                        }
                    }}
                    attachmentUrl={attachmentToOpen}
                />
            )}
            <generalStyles.Title>
                <Icons.Tag color={theme.purple500} />
                Dados do NPS
            </generalStyles.Title>
            <generalStyles.Row>
                <generalStyles.Content>
                    <h1>Média do NPS</h1>
                    <p>
                        <span>{data.NPS.averageNPS?.toFixed(2)}%</span>
                    </p>
                </generalStyles.Content>
                <generalStyles.Content>
                    <h1>
                        Data de fim do último NPS - {data.NPS.lastNPS?.name}
                    </h1>
                    <p>
                        <span>
                            {dayjs(data.NPS.lastNPS?.start_date).format(
                                "DD [de] MMMM [de] YYYY"
                            )}
                        </span>
                    </p>
                </generalStyles.Content>
            </generalStyles.Row>

            <generalStyles.Title>
                <Icons.History color={theme.purple500} />
                Todas as respostas do NPS (ordenadas por data)
                <Icons.CalendarOutline color={theme.purple500} />
            </generalStyles.Title>
            <generalStyles.Row>
                {data.NPS.NPSAnswers.length > 0 ? (
                    <generalStyles.Content>
                        <p>
                            {data.NPS.NPSAnswers.map((answer) => (
                                <React.Fragment key={answer.id_e}>
                                    <s.AnswersRow>
                                        <s.Rating>{answer.rating}</s.Rating>
                                        <s.Comment>{answer.comment}</s.Comment>
                                        <s.Date>
                                            {dayjs(answer.created_at).format(
                                                "DD [de] MMMM [de] YYYY"
                                            )}
                                        </s.Date>
                                    </s.AnswersRow>
                                    <generalStyles.Divider />
                                </React.Fragment>
                            ))}
                        </p>
                    </generalStyles.Content>
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhuma resposta do NPS</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>

            <generalStyles.Title>
                <Icons.Bug color={theme.purple500} />
                Reports de bugs
            </generalStyles.Title>
            <generalStyles.Row>
                {data.bugReports.length > 0 ? (
                    <generalStyles.Content>
                        <p>
                            {data.bugReports.map((answer) => (
                                <React.Fragment key={answer.id_e}>
                                    <s.BugRow>
                                        <s.Description>
                                            <strong>{answer.User.email}</strong>
                                            <br />
                                            {answer.description}
                                        </s.Description>
                                        {answer.attachmentUrl ? (
                                            <s.ClickToView
                                                onClick={() =>
                                                    setAttachmentToOpen(
                                                        answer.attachmentUrl
                                                    )
                                                }
                                            >
                                                Anexo
                                            </s.ClickToView>
                                        ) : (
                                            <s.ClickToView
                                                style={{
                                                    cursor: "default",
                                                    color: theme.gray500,
                                                }}
                                            >
                                                Sem anexo
                                            </s.ClickToView>
                                        )}
                                        <s.CreatedAt>
                                            {dayjs(answer.created_at).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </s.CreatedAt>
                                    </s.BugRow>
                                    <generalStyles.Divider />
                                </React.Fragment>
                            ))}
                        </p>
                    </generalStyles.Content>
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhum report</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
