import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { Icons, theme } from "../../../../assets";
import {
    ColorPicker,
    DayPicker,
    Input,
    LoadingGray,
    ModalAction,
    PopoverRadix,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
    SelectClient,
    Tag,
    TextArea,
    TooltipHistory,
} from "../../../../components";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TOption, TProject } from "../../../../models";
import { manageClients, projects } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import { formatToBRMoney, onlyNumbers } from "../../../../utils";
import { Mixpanel } from "../../../../utils/mixpanel/Mixpanel";
import {
    renderProjectDuration,
    statusOptions,
    verifyProjectStatus,
} from "../../utils/projects-utils";
import { SelectTechnologies } from "./components/select-technologies/select-technologies";
import * as s from "./styled-tab-description";
import schema from "./validation";

dayjs.extend(utc);

type TTabDescription = {
    project?: TProject;
    loading?: boolean;
    setLoading: (bool: boolean) => void;
    getProject: TUseFetch;
};

type TFields = {
    name?: string;
    client?: { label: string; value: string };
    description?: string;
    color?: string;
    start_at?: Date | string;
    end_at?: Date | string;
    status?: string;
    projectId?: string;
    usersId?: string;
    sectorsId?: string;
    reviewersId?: string;
    billersId?: string;
    estimatedHours?: string;
    scopePercentage?: string;
    cost?: string;
    generalCosts?: number;
    technologies?: string;
    archived?: string;
};

export const TabDescription = ({
    project,
    loading,
    setLoading,
    getProject,
}: TTabDescription) => {
    const { verifyPermission } = useUser();
    const [edit, setEdit] = useState(false);
    const [clientsOptions, setClientsOptions] = useState<TOption[]>([]);
    const [color, setColor] = useState<string>(project?.color || theme.gray400);
    const [modalData, setModalData] = useState<{
        isOpen: boolean;
        title: string;
        description: string;
        secondDescription?: string;
        primaryAction: () => unknown;
        secondaryAction: () => unknown;
        primaryText: string;
        secondaryText: string;
    }>({
        isOpen: false,
        title: "",
        description: "",
        secondDescription: "",
        primaryAction: () => ({}),
        secondaryAction: () => ({}),
        primaryText: "",
        secondaryText: "",
    });
    const [selectedTechnologies, setSelectedTechnologies] = useState<TOption[]>(
        project?.technologies?.split(", ").map((tech) => ({
            label: tech,
            value: tech,
        })) || []
    );

    const defaultValues = {
        name: project?.name || "",
        client: {
            value: project?.Client?.id_e || "",
            label: project?.Client?.name || "",
        },
        description: project?.description || "",
        start_at: project?.start_at && new Date(project.start_at),
        end_at:
            typeof project?.end_at === "string" ? new Date(project.end_at) : "",
        status: project?.status || "no_information",
    };

    const canManageProjects = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const canViewCost = verifyPermission({
        id: "projects_values",
        rule: "view",
    });
    const canEditCost = verifyPermission({
        id: "projects_values",
        rule: "manage",
    });

    const {
        register,
        trigger,
        control,
        getValues,
        setValue,
        watch,
        formState: { errors },
        handleSubmit,
    } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const getClients = useFetch({
        fn: manageClients.getClients,
        start: true,
    });

    const updateProject = useFetch({
        fn: projects.updateProject,
        start: false,
    });

    const verifyDates = () => {
        const start_at = getValues("start_at") as string;
        const end_at = getValues("end_at") as string;

        const startDate = new Date(start_at);
        const endDate = new Date(end_at);

        if (startDate > endDate) {
            toast.error("Data de início maior que a de fim do projeto!");
        }
    };

    const handleClientSelectValue = () => {
        let resp: TOption | undefined = {
            label: "Selecione o cliente",
            value: "",
        };
        if (getValues("client")) resp = getValues("client");
        return resp;
    };

    const clearsClientSelect = () => {
        setValue("client", undefined);
    };

    const onSubmit = (data?: TFields) => {
        setLoading(true);

        updateProject.onRefresh({
            name: data?.name,
            clientId: data?.client?.value,
            description: data?.description,
            color: data?.color,
            start_at: dayjs.utc(data?.start_at).add(3, "hour").toDate(),
            end_at: dayjs.utc(data?.end_at).add(3, "hour").toDate(),
            status: data?.status,
            projectId: project?.id_e,
            usersId: project?.Users?.map((user) => user.id_e).join(", "),
            reviewersId: project?.ProjectReviewers?.map(
                (reviewer) => reviewer.id_e
            ).join(", "),
            billersId: project?.ProjectBiller?.map(
                (biller) => biller.id_e
            ).join(", "),
            estimatedHours: parseInt(data?.estimatedHours || "0", 10),
            scopePercentage: parseInt(data?.scopePercentage || "0", 10),
            cost: data?.cost
                ? (parseFloat(onlyNumbers(data?.cost || "")) / 100).toString()
                : undefined,
            technologies: selectedTechnologies
                .map((tech) => tech.value)
                .join(", "),
            archived: data?.archived === "true",
        });

        setEdit(!edit);
    };

    useEffect(() => {
        if (getClients.error) {
            setLoading(false);
        }
        if (getClients.response) {
            const tempClientsOptions: TOption[] = [];
            getClients.response.forEach(
                (client: { id_e: string; name: string }) =>
                    tempClientsOptions.push({
                        value: client.id_e,
                        label: client.name,
                    })
            );
            setClientsOptions(tempClientsOptions);
            setLoading(false);
        }
    }, [getClients.response]);

    useEffect(() => {
        if (updateProject.error) {
            setLoading(false);
        }
        if (updateProject.response) {
            getProject.onRefresh({
                projectId: project?.id_e,
                users: true,
                hours: true,
                finished: false,
                sectors: true,
                reviewers: true,
                all: true,
            });
            setLoading(false);
            Mixpanel.track("Salvar edição do projeto na aba descrição");
        }
    }, [updateProject.response, updateProject.error]);

    const handlePrimaryAction = (value: string) => {
        setValue("status", value);
        setModalData({ ...modalData, isOpen: false });
    };

    const handleSecondaryAction = () => {
        setModalData({ ...modalData, isOpen: false });
    };

    const canActivateClient = (value: string) => {
        if (!project) return false;
        const projectInactiveStatus = ["canceled", "finished"];
        const isActivating =
            projectInactiveStatus.includes(project.status) &&
            projectInactiveStatus.includes(value) === false;
        return isActivating && project?.Client?.active === false;
    };

    const modalAction = (value: string) => {
        if (canActivateClient(value)) {
            setModalData({
                isOpen: true,
                title: "Cliente inativo",
                description:
                    "Ao retirar o projeto de pausado/cancelado, você também estará ativando o cliente.",
                secondDescription: "Você deseja ativar o projeto novamente?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Ativar projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "paused") {
            setModalData({
                isOpen: true,
                title: "Pausar projeto",
                description:
                    "Enquanto o projeto estiver pausado, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja pausar o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Pausar Projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "canceled") {
            setModalData({
                isOpen: true,
                title: "Cancelar projeto",
                description:
                    "Enquanto o projeto estiver cancelado, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja cancelar o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Cancelar Projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        if (value === "finished") {
            setModalData({
                isOpen: true,
                title: "Concluir projeto",
                description:
                    "Enquanto o projeto estiver concluído, o time não poderá utilizar a tag deste projeto no cronômetro para inserir horas.",
                secondDescription: "Você deseja concluir o projeto?",
                primaryAction: () => handlePrimaryAction(value),
                secondaryAction: handleSecondaryAction,
                primaryText: "Concluir projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        setValue("status", value);
    };

    const openArchivedModal = (option: TOption, onChange: any) => {
        if (option.value === "true") {
            setModalData({
                isOpen: true,
                title: "Arquivar projeto",
                description:
                    "Ao arquivar o projeto, ele não será exibido para os colaboradores inseridos e será ocultado da tela de projetos.",
                secondDescription: "Você deseja arquivar o projeto?",
                primaryAction: () => {
                    onChange(option.value);
                    handleSecondaryAction();
                },
                secondaryAction: handleSecondaryAction,
                primaryText: "Arquivar projeto",
                secondaryText: "Cancelar",
            });
            return;
        }
        onChange(option.value);
    };

    const archivedOptions = [
        {
            label: "Não",
            value: "false",
        },
        {
            label: "Sim",
            value: "true",
        },
    ];

    return (
        <>
            {modalData.isOpen && (
                <ModalAction
                    setOpen={() =>
                        setModalData({
                            ...modalData,
                            isOpen: !modalData.isOpen,
                        })
                    }
                    title={modalData.title}
                    description={modalData.description}
                    secondDescription={modalData.secondDescription}
                    primaryAction={modalData.primaryAction}
                    primaryText={modalData.primaryText}
                    secondaryAction={modalData.secondaryAction}
                    secondaryText={modalData.secondaryText}
                />
            )}
            <s.Content
                onSubmit={handleSubmit(onSubmit)}
                /* onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit(onSubmit)();
                    }
                }} */
            >
                {loading ? (
                    <LoadingGray />
                ) : (
                    <div className="fields">
                        <s.FieldsWrapper>
                            <div className="field-row">
                                <s.FieldTitle>
                                    Nome do Projeto{edit && `*`}:
                                </s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Input
                                            {...register("name", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: () => {
                                                    trigger("name");
                                                },
                                            })}
                                            className="input-contact-data"
                                            type="text"
                                            defaultValue={project?.name || ""}
                                            mask=""
                                            error={errors.name?.message || ""}
                                        />
                                    ) : (
                                        <div className="tag-container">
                                            <Tag color={project?.color || ""}>
                                                {project?.name || "-"}
                                            </Tag>
                                        </div>
                                    )}
                                </s.FieldText>
                            </div>
                            {edit && (
                                <div className="field-row">
                                    <s.FieldTitle>Tag do Projeto:</s.FieldTitle>
                                    <s.FieldText>
                                        <PopoverRadix
                                            side="right"
                                            sideOffset={20}
                                            closeOnSelect
                                            align="end"
                                            trigger={
                                                <div className="tag-container">
                                                    <Tag color={color}>
                                                        {project?.name || "-"}
                                                    </Tag>
                                                </div>
                                            }
                                        >
                                            <Controller
                                                name="color"
                                                control={control}
                                                render={({
                                                    field: { onChange },
                                                }) => (
                                                    <ColorPicker
                                                        title="Escolha a cor da tag"
                                                        onSelect={(
                                                            selectedColor: string
                                                        ) => {
                                                            onChange(
                                                                selectedColor
                                                            );
                                                            setColor(
                                                                selectedColor
                                                            );
                                                        }}
                                                    />
                                                )}
                                            />
                                        </PopoverRadix>
                                    </s.FieldText>
                                </div>
                            )}
                            <div className="field-row">
                                <s.FieldTitle>
                                    Cliente{edit && `*`}:
                                </s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Controller
                                            name="client"
                                            control={control}
                                            render={({
                                                field: { ref, onChange },
                                            }) => (
                                                <SelectClient
                                                    value={handleClientSelectValue()}
                                                    placeholder={
                                                        "Selecione o cliente"
                                                    }
                                                    options={clientsOptions}
                                                    inputRef={ref}
                                                    onChange={(
                                                        val: TOption
                                                    ) => {
                                                        onChange(val);
                                                    }}
                                                    buttonName={"Novo Cliente"}
                                                    noOptionsMessage={
                                                        "Cliente não encontrado"
                                                    }
                                                    getClients={getClients}
                                                    clearsClientSelect={
                                                        clearsClientSelect
                                                    }
                                                    error={
                                                        errors.client?.label
                                                            ?.message
                                                    }
                                                />
                                            )}
                                        />
                                    ) : (
                                        <>{project?.Client?.name || "-"}</>
                                    )}
                                </s.FieldText>
                            </div>
                            {edit ? (
                                <>
                                    <div className="field-row">
                                        <s.FieldTitle>
                                            Início do projeto:
                                        </s.FieldTitle>
                                        <s.FieldText>
                                            <Controller
                                                name="start_at"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <s.DateInput>
                                                        <DayPicker
                                                            placeholder="--/--/----"
                                                            onSelect={(
                                                                date
                                                            ) => {
                                                                onChange(date);
                                                                verifyDates();
                                                            }}
                                                            value={
                                                                value instanceof
                                                                Date
                                                                    ? dayjs
                                                                          .utc(
                                                                              value
                                                                          )
                                                                          .add(
                                                                              3,
                                                                              "hour"
                                                                          )
                                                                          .toDate()
                                                                    : undefined
                                                            }
                                                            toYear={
                                                                new Date().getFullYear() +
                                                                10
                                                            }
                                                        />
                                                        <Icons.XOutline
                                                            width="36px"
                                                            height="36px"
                                                            onClick={() => {
                                                                setValue(
                                                                    "start_at",
                                                                    ""
                                                                );
                                                            }}
                                                            className="close"
                                                        />
                                                    </s.DateInput>
                                                )}
                                            />
                                        </s.FieldText>
                                    </div>
                                    <div className="field-row">
                                        <s.FieldTitle>
                                            Fim do projeto:
                                        </s.FieldTitle>
                                        <s.FieldText>
                                            <Controller
                                                name="end_at"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <s.DateInput>
                                                        <DayPicker
                                                            placeholder="--/--/----"
                                                            value={
                                                                value instanceof
                                                                Date
                                                                    ? value
                                                                    : undefined
                                                            }
                                                            onSelect={(
                                                                date
                                                            ) => {
                                                                onChange(date);
                                                            }}
                                                            toYear={
                                                                new Date().getFullYear() +
                                                                10
                                                            }
                                                            error={
                                                                errors.end_at
                                                                    ?.message ||
                                                                ""
                                                            }
                                                        />
                                                        <Icons.XOutline
                                                            width="36px"
                                                            height="36px"
                                                            onClick={() => {
                                                                setValue(
                                                                    "end_at",
                                                                    ""
                                                                );
                                                            }}
                                                            className="close"
                                                        />
                                                    </s.DateInput>
                                                )}
                                            />
                                        </s.FieldText>
                                    </div>
                                </>
                            ) : (
                                <div className="field-row">
                                    <s.FieldTitle>
                                        Duração do projeto:
                                    </s.FieldTitle>
                                    <s.FieldText>
                                        {renderProjectDuration(
                                            project?.start_at || "",
                                            project?.end_at || ""
                                        )}
                                    </s.FieldText>
                                </div>
                            )}
                            <div className="field-row">
                                <s.FieldTitle>Status:</s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Controller
                                            name={"status"}
                                            control={control}
                                            render={({ field: { ref } }) => (
                                                <SelectInput
                                                    icon={<Icons.Chevron />}
                                                    inputRef={ref}
                                                    options={statusOptions}
                                                    positionY="0.4"
                                                    placeholder={verifyProjectStatus(
                                                        project?.status || ""
                                                    )}
                                                    onChange={(val: {
                                                        value: string;
                                                    }) => {
                                                        modalAction(val.value);
                                                    }}
                                                    noLabel
                                                />
                                            )}
                                        />
                                    ) : (
                                        <>
                                            {verifyProjectStatus(
                                                project?.status || ""
                                            )}
                                        </>
                                    )}
                                </s.FieldText>
                            </div>
                            <div className="field-row">
                                <s.FieldTitle>Horas previstas:</s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Input
                                            {...register("estimatedHours")}
                                            mask="9999999"
                                            placeholder="1200:00"
                                            defaultValue={String(
                                                project?.estimatedHours
                                            )}
                                            rightSideLabelIcon={
                                                <TooltipHistory
                                                    dataList={
                                                        project?.ProjectEstimatedHoursHistory
                                                    }
                                                    valueKey="estimated_hours"
                                                />
                                            }
                                            error={
                                                errors.estimatedHours?.message
                                            }
                                        />
                                    ) : (
                                        <>
                                            <s.historicWrapper>
                                                {project?.estimatedHours || "0"}
                                                <TooltipHistory
                                                    dataList={
                                                        project?.ProjectEstimatedHoursHistory
                                                    }
                                                    valueKey="estimated_hours"
                                                />
                                            </s.historicWrapper>
                                        </>
                                    )}
                                </s.FieldText>
                            </div>
                            <div className="field-row">
                                <s.FieldTitle>Escopo atual:</s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Input
                                            {...register("scopePercentage")}
                                            mask="999"
                                            placeholder="20%"
                                            defaultValue={String(
                                                project?.scopePercentage
                                            )}
                                            error={
                                                errors.scopePercentage
                                                    ?.message || ""
                                            }
                                            rightSideLabelIcon={
                                                <TooltipHistory
                                                    dataList={
                                                        project?.ProjectScopeHistory
                                                    }
                                                    valueKey="scopePercentage"
                                                />
                                            }
                                        />
                                    ) : (
                                        <s.historicWrapper>
                                            {project?.scopePercentage || "0"}
                                            %
                                            <TooltipHistory
                                                dataList={
                                                    project?.ProjectScopeHistory
                                                }
                                                valueKey="scopePercentage"
                                            />
                                        </s.historicWrapper>
                                    )}
                                </s.FieldText>
                            </div>
                            {canViewCost && (
                                <div className="field-row">
                                    <s.FieldTitle>
                                        Custo total previsto:
                                    </s.FieldTitle>
                                    <s.FieldText>
                                        {edit && canEditCost ? (
                                            <Input
                                                {...register("cost")}
                                                placeholder="R$ 100.000.000,00"
                                                defaultValue={String(
                                                    project?.cost || 0
                                                )}
                                                value={
                                                    watch("cost")
                                                        ? formatToBRMoney(
                                                              Number(
                                                                  onlyNumbers(
                                                                      watch(
                                                                          "cost"
                                                                      ) || ""
                                                                  )
                                                              ) / 100
                                                          )
                                                        : formatToBRMoney(
                                                              Number(
                                                                  project?.cost
                                                              )
                                                          ) || 0
                                                }
                                                onChange={({ target: t }) => {
                                                    const justNumbers =
                                                        onlyNumbers(t.value);
                                                    setValue(
                                                        "cost",
                                                        justNumbers
                                                    );
                                                }}
                                                rightSideLabelIcon={
                                                    <TooltipHistory
                                                        dataList={
                                                            project?.ProjectCostHistory
                                                        }
                                                        valueKey="cost"
                                                    />
                                                }
                                            />
                                        ) : (
                                            <s.historicWrapper>
                                                {formatToBRMoney(
                                                    parseFloat(
                                                        project?.cost as unknown as string
                                                    ) || 0
                                                )}
                                                <TooltipHistory
                                                    dataList={
                                                        project?.ProjectCostHistory
                                                    }
                                                    valueKey="cost"
                                                />
                                            </s.historicWrapper>
                                        )}
                                    </s.FieldText>
                                </div>
                            )}
                            <div className="field-row">
                                <s.FieldTitle>Arquivado:</s.FieldTitle>
                                <s.FieldText>
                                    {edit ? (
                                        <Controller
                                            name={"archived"}
                                            control={control}
                                            defaultValue={
                                                project?.archived === true
                                                    ? "true"
                                                    : "false"
                                            }
                                            render={({
                                                field: { ref, value, onChange },
                                            }) => (
                                                <SelectInput
                                                    icon={<Icons.Chevron />}
                                                    inputRef={ref}
                                                    options={archivedOptions}
                                                    value={archivedOptions.find(
                                                        (option) =>
                                                            option.value ===
                                                            value
                                                    )}
                                                    onChange={(
                                                        option: TOption
                                                    ) => {
                                                        openArchivedModal(
                                                            option,
                                                            onChange
                                                        );
                                                    }}
                                                    noLabel
                                                />
                                            )}
                                        />
                                    ) : (
                                        <>
                                            {project?.archived === true
                                                ? "Sim"
                                                : "Não"}
                                        </>
                                    )}
                                </s.FieldText>
                            </div>
                            {project && (
                                <div className="field-row">
                                    <s.FieldTitle>
                                        Projeto criado por{" "}
                                        {project.CreatedBy?.name || "---"} em{" "}
                                        {dayjs(project.created_at).format(
                                            "DD/MM/YYYY [às] HH:mm"
                                        )}
                                    </s.FieldTitle>
                                </div>
                            )}
                        </s.FieldsWrapper>

                        <div>
                            <s.ButtonWrapper>
                                {canManageProjects && (
                                    <div className="action-buttons">
                                        {edit ? (
                                            <>
                                                <SecondaryButton
                                                    variation="small"
                                                    onClick={() =>
                                                        setEdit(false)
                                                    }
                                                >
                                                    Cancelar
                                                </SecondaryButton>
                                                <PrimaryButton
                                                    variation="small"
                                                    type="submit"
                                                >
                                                    Salvar
                                                </PrimaryButton>
                                            </>
                                        ) : (
                                            <PrimaryButton
                                                variation="small"
                                                onClick={() => setEdit(!edit)}
                                            >
                                                Editar
                                            </PrimaryButton>
                                        )}
                                    </div>
                                )}
                            </s.ButtonWrapper>
                            <s.TextAreaWrapper isEditing={edit}>
                                <div className="description-header">
                                    <s.FieldTitle>
                                        Descrição do projeto:
                                    </s.FieldTitle>
                                </div>
                                <div className="description-text">
                                    {edit ? (
                                        <s.FieldText>
                                            <TextArea
                                                {...register("description")}
                                                placeholder="Digite aqui uma pequena descrição sobre este projeto"
                                                charCount
                                                counter={watch("description")}
                                                maxLength={300}
                                            />
                                        </s.FieldText>
                                    ) : (
                                        <s.FieldText>
                                            {project?.description || "-"}
                                        </s.FieldText>
                                    )}
                                </div>
                            </s.TextAreaWrapper>
                            <s.TextAreaWrapper isEditing={edit}>
                                <div className="description-header">
                                    <s.FieldTitle>
                                        Tecnologias do projeto (se houver):
                                    </s.FieldTitle>
                                </div>
                                <div className="description-text">
                                    {edit ? (
                                        <s.FieldText>
                                            <SelectTechnologies
                                                project={project!}
                                                selectedTechnologies={
                                                    selectedTechnologies
                                                }
                                                setSelectedTechnologies={
                                                    setSelectedTechnologies
                                                }
                                            />
                                        </s.FieldText>
                                    ) : (
                                        <s.FieldText>
                                            {project?.technologies || "-"}
                                        </s.FieldText>
                                    )}
                                </div>
                                <div className="description-text">
                                    {edit && (
                                        <s.FieldTitle>
                                            Dica: Digite a tecnologia desejada e
                                            pressione [Enter], [Espaço] ou [Tab]
                                            para adicionar.
                                            <br />
                                            As opções disponíveis são baseadas
                                            nas habilidades dos usuários
                                            vinculados ao projeto.
                                        </s.FieldTitle>
                                    )}
                                </div>
                            </s.TextAreaWrapper>
                        </div>
                    </div>
                )}
            </s.Content>
        </>
    );
};
