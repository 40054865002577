import styled from "styled-components";

type TButton = {
    image?: boolean;
};

export const FlexColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: relative;
`;

export const Label = styled.label`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${({ theme }) => theme.gray50};
    border: 1px solid ${({ theme }) => theme.purple500};
    border-radius: 50%;
`;

export const Input = styled.input`
    display: none;
`;

export const Img = styled.img`
    width: 8.875rem;
    height: 8.875rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
`;

export const Button = styled.button<TButton>`
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: ${({ image }) => (image ? 0.3 : 1)};
    &:hover {
        opacity: 1;
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 1rem;
    position: absolute;
    padding-left: 0.3125rem;
`;
