import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Accordion } from "../../../../components";
import { TProjectList } from "../../../../components/molecules/popovers/popover-project/popover-project";
import {
    TDetailsEntry,
    TEntries,
    TEntryDay,
    TEntryTask,
} from "../../../../models";
import { UpdateEntryVariables } from "../../../../services/track/types";
import { DayTrigger } from "./day-trigger";
import { Entry } from "./entry";
import * as s from "./styled-card-container";
import { SubEntry } from "./sub-entry";

type TCardProject = {
    allDuration: number[];
    entries: TEntryDay;
    projectList: TProjectList[];
    timeEntries: TEntries;
    handleDeleteEntry: (id: string) => void;
    handleUpdateEntry: (params: UpdateEntryVariables) => void;
    setTimeEntries: Dispatch<SetStateAction<TEntries | undefined>>;
    continueTask: (task: TEntryTask) => void;
};

export type TTimelineData = {
    name: string;
    totalTime: number;
    color: string;
}[];

type TDurationByProject = {
    [key: string]: {
        name: string;
        totalTime: number;
        color: string;
    };
};

const CardContainer = ({
    allDuration,
    entries,
    projectList,
    timeEntries,
    handleDeleteEntry,
    handleUpdateEntry,
    setTimeEntries,
    continueTask,
}: TCardProject) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const calculateDurationByProject = (
        detailedEntries: TDetailsEntry[]
    ): TDurationByProject =>
        detailedEntries.reduce(
            (acc: TDurationByProject, { Project: project, total_time }) => {
                const { name, color } = project;
                const totalTime = total_time + (acc[name]?.totalTime || 0);
                return {
                    ...acc,
                    [name]: {
                        name,
                        totalTime,
                        color,
                    },
                };
            },
            {}
        );

    const { tasks } = entries;
    const selectedEntries = tasks.flatMap(({ entries }) =>
        entries
            .filter(({ entryChecked }) => entryChecked)
            .map(({ id_e }) => id_e)
    );
    const detailedEntries = tasks.flatMap(({ entries }) => entries);
    const timelineData = Object.values(
        calculateDurationByProject(detailedEntries)
    );

    const toggleDayChecked = (dayChecked?: boolean) => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries.entries.map((day) => {
                if (day.date !== entries.date) return day;
                const updatedTasks = day.tasks.map((task) => ({
                    ...task,
                    entries: task.entries.map((entry) => ({
                        ...entry,
                        entryChecked: !dayChecked,
                    })),
                    entryChecked: !dayChecked,
                }));
                return {
                    ...day,
                    tasks: updatedTasks,
                    dayChecked: !dayChecked,
                };
            }),
        });
    };

    const toggleTaskChecked = (index: number, taskChecked: boolean) => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries.entries.map((day) => {
                if (day.date !== entries.date) return day;
                let allTasksChecked = day.tasks.every(
                    (task) => task.entryChecked
                );
                const updatedTasks = day.tasks.map((task, indexTask) => {
                    if (index === indexTask) {
                        if (taskChecked) allTasksChecked = false;

                        return {
                            ...task,
                            entries: task.entries.map((entry) => ({
                                ...entry,
                                entryChecked: !taskChecked,
                            })),
                            entryChecked: !taskChecked,
                        };
                    }

                    if (!task.entryChecked) allTasksChecked = false;

                    return task;
                });
                return {
                    ...day,
                    tasks: updatedTasks,
                    dayChecked: allTasksChecked,
                };
            }),
        });
    };

    const toggleEntryChecked = (
        id: string,
        entryChecked: boolean,
        taskIndex: number
    ) => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries.entries.map((day) => {
                if (day.date !== entries.date) return day;
                let allTasksChecked = true;
                let allEntriesChecked = true;
                const updatedTasks = day.tasks.map((task, index) => ({
                    ...task,
                    entries: task.entries.map((entry) => {
                        if (id === entry.id_e) {
                            if (entryChecked) {
                                allTasksChecked = false;
                                allEntriesChecked = false;
                            }
                            return {
                                ...entry,
                                entryChecked: !entryChecked,
                            };
                        }
                        if (!entry.entryChecked) {
                            allTasksChecked = false;
                            allEntriesChecked = false;
                        }
                        return entry;
                    }),
                    entryChecked:
                        taskIndex === index
                            ? allEntriesChecked
                            : task.entryChecked,
                }));
                return {
                    ...day,
                    tasks: updatedTasks,
                    dayChecked: allTasksChecked,
                };
            }),
        });
    };

    const manageIsOpen = (index?: number) => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries.entries.map((day) => {
                if (day.date !== entries.date) {
                    return day;
                }
                return {
                    ...day,
                    tasks: day.tasks.map((task, indexTask) => {
                        if (index === indexTask) {
                            return { ...task, entryOpen: !task.entryOpen };
                        }
                        return task;
                    }),
                };
            }),
        });
    };

    useEffect(() => {
        setTimeEntries({
            ...timeEntries,
            entries: timeEntries.entries.map((day, index) => {
                if (index === 0) {
                    return {
                        ...day,
                        dayOpen: true,
                    };
                }
                return day;
            }),
        });
    }, []);

    if (!tasks[0].entries[0]) return <></>;

    return (
        <s.CardContainer dayOpen={entries.dayOpen}>
            <Accordion
                open={entries.dayOpen}
                controlled={
                    new Date(tasks[0].entries[0].start_at).getDate() ===
                    new Date().getDate()
                }
                collapsible
                trigger={
                    <DayTrigger
                        allDuration={allDuration}
                        entries={entries}
                        isEditing={isEditing}
                        selectedEntries={selectedEntries}
                        timelineData={timelineData}
                        handleDeleteEntry={handleDeleteEntry}
                        toggleDayChecked={toggleDayChecked}
                        containerOnClick={() => {
                            setTimeEntries({
                                ...timeEntries,
                                entries: timeEntries.entries.map((day) => {
                                    if (day.date !== entries.date) {
                                        return day;
                                    }
                                    return {
                                        ...day,
                                        dayOpen: !entries.dayOpen,
                                    };
                                }),
                            });
                        }}
                        btnEditOnClick={() => {
                            if (!isEditing) {
                                setTimeEntries({
                                    ...timeEntries,
                                    entries: timeEntries.entries.map((day) => {
                                        if (day.date !== entries.date) {
                                            return day;
                                        }
                                        return {
                                            ...day,
                                            dayOpen: true,
                                        };
                                    }),
                                });
                            }
                            setIsEditing(!isEditing);
                        }}
                    />
                }
            >
                {entries.tasks.map((task, index) => (
                    <Accordion
                        controlled
                        open={task.entryOpen}
                        collapsible
                        key={`${task.taskName}-${index}-${task.entries.length}`}
                        trigger={
                            <Entry
                                key={`${task.taskName}-${index}`}
                                handleUpdateEntry={handleUpdateEntry}
                                projectList={projectList}
                                handleDeleteEntry={handleDeleteEntry}
                                index={index}
                                isEditing={isEditing}
                                manageIsOpen={manageIsOpen}
                                task={task}
                                toggleTaskChecked={toggleTaskChecked}
                                onContinueTask={continueTask}
                            />
                        }
                    >
                        {task.entries.map(
                            (entry, indexEntry) =>
                                task.entries.length > 0 &&
                                entry.end_at && (
                                    <SubEntry
                                        key={entry.id_e}
                                        index={index}
                                        task={task}
                                        indexEntry={indexEntry}
                                        entry={entry}
                                        isEditing={isEditing}
                                        projectList={projectList}
                                        handleDeleteEntry={handleDeleteEntry}
                                        toggleEntryChecked={toggleEntryChecked}
                                        handleUpdateEntry={handleUpdateEntry}
                                    />
                                )
                        )}
                    </Accordion>
                ))}
            </Accordion>
        </s.CardContainer>
    );
};

export default CardContainer;
