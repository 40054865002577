import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    padding: 40px 30px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const Header = styled.section`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ValueContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const HeaderTitle = styled.h2`
    font-size: 16px;
    color: ${({ theme }) => theme.gray900};
    font-weight: 500;
`;

export const HeaderValue = styled.p`
    font-size: 16px;
    color: ${({ theme }) => theme.gray600};
    font-weight: 400;
`;

export const Text = styled.p`
    font-size: 13px;
    color: ${({ theme }) => theme.gray900};
`;
