import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

import { useFetch } from "../../../../hooks";
import { TOption } from "../../../../models";
import { manageClients } from "../../../../services";
import { TUseFetch } from "../../../../types/TUseFetch";
import { PrimaryButton, SecondaryButton, TextArea } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-client";
import validation from "./validation";

type TModalClient = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    option?: TOption;
    editRegister?: boolean;
    name?: string;
    getClients?: TUseFetch;
    clearsClientSelect?: () => void;
};

const ModalClient = ({
    setOpen,
    editRegister,
    option,
    getClients,
    clearsClientSelect,
}: TModalClient) => {
    const [loading, setLoading] = useState(false);

    const renderDefaultValues = () => {
        const resp = {
            label: option?.label || "",
            value: option?.value || "",
        };

        return resp;
    };

    const {
        register,
        handleSubmit,
        watch,
        formState: { isDirty, errors },
    } = useForm<TOption>({
        resolver: yupResolver(validation()),
        defaultValues: editRegister
            ? renderDefaultValues()
            : { label: "", value: "" },
    });

    const createClient = useFetch({
        fn: manageClients.createClient,
        start: false,
    });

    const updateClient = useFetch({
        fn: manageClients.updateClient,
        start: false,
    });

    const handleGetClient = () => {
        if (getClients) {
            getClients.onRefresh();
            clearsClientSelect?.();
        }
    };

    const handleCreateClient = (data: TOption) => {
        createClient.onRefresh({
            name: data?.label,
        });
    };

    const handleUpdateClient = (data: TOption) => {
        updateClient.onRefresh({
            name: data?.label,
            clientId: data?.value,
        });
    };

    useEffect(() => {
        if (createClient.error) toast.error(`${createClient.error?.message}`);
        if (createClient.response) {
            toast.success("Cliente criado com sucesso!");
            handleGetClient();
            setLoading(false);
            setOpen(false);
        }
    }, [createClient.response]);

    useEffect(() => {
        if (updateClient.error) toast.error(`${updateClient.error?.message}`);
        if (updateClient.response) {
            toast.success("Cliente atualizado com sucesso!");
            handleGetClient();
            setLoading(false);
            setOpen(false);
        }
    }, [updateClient.response]);

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container
                className="client-content"
                onSubmit={(e) => e.preventDefault}
            >
                <div>
                    <s.Title htmlFor="title">
                        {editRegister ? "Editar Cliente" : "Novo Cliente"}
                    </s.Title>
                    <TextArea
                        {...register("label")}
                        placeholder="Digite aqui o nome do cliente"
                        disabled={loading}
                        charCount
                        counter={watch("label")}
                        maxLength={150}
                        error={errors.label?.message}
                    />
                </div>

                <div className="button-wrapper">
                    <div>
                        <SecondaryButton
                            onClick={() => setOpen(false)}
                            type="button"
                        >
                            VOLTAR
                        </SecondaryButton>
                    </div>

                    <div>
                        {editRegister ? (
                            <PrimaryButton
                                type="button"
                                onClick={handleSubmit(handleUpdateClient)}
                            >
                                SALVAR EDIÇÃO
                            </PrimaryButton>
                        ) : (
                            <PrimaryButton
                                type="button"
                                onClick={handleSubmit(handleCreateClient)}
                            >
                                SALVAR NOVO CLIENTE
                            </PrimaryButton>
                        )}
                    </div>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalClient;
