import { styled } from "styled-components";

export const Container = styled.tr`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    border-top: 1px solid ${({ theme }) => theme.gray200};
    height: 56px;

    .more-menu {
        visibility: hidden;
    }

    &:hover {
        background-color: ${({ theme }) => theme.purple50};

        .more-menu {
            visibility: visible;
        }
    }
`;

export const ProjectNameCell = styled.td<{ havePermission?: boolean }>`
    padding-left: 24px;
    width: ${({ havePermission }) => (havePermission ? "25vw" : "45vw")};

    .project-tag {
        width: ${({ havePermission }) => (havePermission ? "280px" : "400px")};
        margin-right: 50px;
    }
`;

export const ProjectSectorsCell = styled.td<{ havePermission?: boolean }>`
    width: ${({ havePermission }) => (havePermission ? "20vw" : "26vw")};
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin-right: 50px;

    p {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const ProjectReviewerBillerCell = styled.td<{ disabled?: boolean }>`
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    #biller,
    #reviewer {
        pointer-events: none;
    }

    div {
        justify-content: center;
        width: fit-content;

        &:hover {
            box-shadow: ${({ disabled }) =>
                disabled
                    ? "none"
                    : "0px 0px 0.5rem 0.10rem rgba(102, 51, 152, 0.15)"};
        }
    }
`;

export const MoreWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.gray200};
    }
`;

export const StatusCell = styled.td`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    margin-right: 50px;
`;
