import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import {
    CaptionLayout,
    ClassNames,
    DayPicker,
    Matcher,
} from "react-day-picker";
import styles from "react-day-picker/dist/style.css";

import * as s from "./styled-calendar";

type TCalendar = {
    selectedDay: Date | undefined;
    onChange?: (e: Date | undefined) => void;
    navigationMode?: CaptionLayout;
    toYear?: number;
    disabled?: Matcher | Matcher[] | undefined;
};

const Calendar = ({
    selectedDay,
    onChange,
    navigationMode,
    toYear,
    disabled,
}: TCalendar) => {
    const classNames: ClassNames = {
        ...styles,
        caption_label: "caption-label",
        nav_button: "nav-button",
        head: "head",
        head_row: "head-row",
        head_cell: "head-cell",
        row: "table-row",
        cell: "table-cell",
        day_range_start: "range-start",
        day_range_middle: "range-middle",
        day_range_end: "range-end",
        button: "buttons",
        day_disabled: "disabled",
    };
    return (
        <s.ContainerCalendar>
            <DayPicker
                fromYear={1970}
                toYear={toYear || new Date().getFullYear()}
                classNames={classNames}
                selected={selectedDay}
                onSelect={onChange}
                mode="single"
                locale={ptBR}
                captionLayout={navigationMode || "buttons"}
                weekStartsOn={0}
                disabled={disabled}
            />
        </s.ContainerCalendar>
    );
};

export default Calendar;
