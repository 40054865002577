import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Icons, theme } from "../../../../../assets";
import {
    LoadingGray,
    TextArea,
    Tooltip,
} from "../../../../../components/atoms";
import { DesignedAlert } from "../../../../../components/molecules";
import { ModalSkills } from "../../../../../components/molecules/modals/modal-skills/modal-skills";
import { useUser } from "../../../../../context";
import { useFetch } from "../../../../../hooks";
import { TUser } from "../../../../../models";
import { manageUser } from "../../../../../services";
import { GetUserVariables } from "../../../../../services/user/types";
import {
    getDateFromIsoString,
    getDayMonthAndYearFromDate,
} from "../../../../../utils";
import { Mixpanel } from "../../../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-tab-experience";
import schema from "./validation";

type TFields = {
    education: string;
    skills: string;
    areasOfInterest: string;
    workFacilities: string;
};

type TTabExperience = {
    loading: boolean;
    userData: TUser;
    setUserData: Dispatch<SetStateAction<TUser>>;
    userId: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    getUserOnRefresh: (params: GetUserVariables) => void;
};

export const TabExperience = ({
    loading,
    userData,
    setUserData,
    userId,
    setSelectedTab,
    getUserOnRefresh,
}: TTabExperience) => {
    const { user } = useUser();

    const defaultValues = {
        education: userData.education,
        skills: userData.skills,
        areasOfInterest: userData.areasOfInterest,
        workFacilities: userData.workFacilities,
    };

    const {
        handleSubmit,
        trigger,
        register,
        formState: { errors },
        reset,
    } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const updateUser = useFetch({
        fn: manageUser.updateUser,
        start: false,
    });

    const [showEdit, setShowEdit] = useState(false);
    const [showSkillsModal, setShowSkillsModal] = useState(false);

    const onSubmit = (data: TFields) => {
        setUserData({ ...userData, ...data });
        setSelectedTab("experience");
        setShowEdit(!showEdit);
        updateUser.onRefresh({
            userData: data,
        });
    };
    function convertFloatToYearsAndMonths(float: number) {
        const years = Math.floor(float);
        const yearName = years > 1 ? "anos" : "ano";
        const months = Math.round((float - years) * 12);
        if (years === 0) {
            const monthName = months > 1 ? "meses" : "mês";
            return `${months} ${monthName}`;
        }
        if (months === 0) {
            return `${years} ${yearName}`;
        }
        const monthName = months > 1 ? "meses" : "mês";
        return `${years} ${yearName} e ${months} ${monthName}`;
    }

    useEffect(() => {
        if (updateUser.response) {
            Mixpanel.track("Perfil colaborador - Salvar Experiência");
        }
    }, [updateUser.response]);

    return (
        <s.Content>
            <div className="content-title">Dados de contratação:</div>
            <s.Experience onSubmit={handleSubmit(onSubmit)}>
                {loading ? (
                    <LoadingGray />
                ) : (
                    <>
                        {showSkillsModal && (
                            <ModalSkills
                                onCreate={() => {
                                    getUserOnRefresh({
                                        userId,
                                    });
                                }}
                                skills={userData.UserSkill}
                                setOpen={setShowSkillsModal}
                                userId={userId}
                                canClose
                            />
                        )}
                        <div id="fields">
                            <div className="contact-field">
                                <span className="field-title">
                                    Data de início:
                                    <span className="field-text">
                                        {userData?.admissionDate
                                            ? getDayMonthAndYearFromDate(
                                                  getDateFromIsoString(
                                                      userData?.admissionDate as string
                                                  )
                                              )
                                            : "-"}
                                    </span>
                                </span>
                            </div>

                            <div id="resume-experience">
                                <div className="contact-field">
                                    <span className="field-title">
                                        Formação:{" "}
                                        {!showEdit && (
                                            <span className="field-text">
                                                {userData?.education || "-"}
                                            </span>
                                        )}
                                    </span>

                                    {showEdit && (
                                        <TextArea
                                            {...register("education", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: () => {
                                                    trigger("education");
                                                },
                                            })}
                                            error={
                                                errors.education?.message || ""
                                            }
                                            placeholder={"Qual sua formação?"}
                                            noLabel
                                        />
                                    )}
                                </div>

                                <div className="contact-field">
                                    <span className="field-title">
                                        Habilidades:{" "}
                                        {!showEdit && (
                                            <span className="field-text">
                                                {userData?.skills || "-"}
                                            </span>
                                        )}
                                    </span>

                                    {showEdit && (
                                        <TextArea
                                            {...register("skills", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: () => {
                                                    trigger("skills");
                                                },
                                            })}
                                            error={errors.skills?.message || ""}
                                            placeholder={
                                                "Nos conte um pouco de suas habilidades"
                                            }
                                            noLabel
                                        />
                                    )}
                                </div>

                                <div className="contact-field">
                                    <span className="field-title">
                                        Interesses:{" "}
                                        {!showEdit && (
                                            <span className="field-text">
                                                {userData?.areasOfInterest ||
                                                    "-"}
                                            </span>
                                        )}
                                    </span>

                                    {showEdit && (
                                        <TextArea
                                            {...register("areasOfInterest", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: () => {
                                                    trigger("areasOfInterest");
                                                },
                                            })}
                                            error={
                                                errors.areasOfInterest
                                                    ?.message || ""
                                            }
                                            placeholder={
                                                "Pensando no seu futuro, quais áreas você gostaria de trabalhar?"
                                            }
                                            noLabel
                                        />
                                    )}
                                </div>

                                <div className="contact-field">
                                    <span className="field-title">
                                        Facilidades:{" "}
                                        {!showEdit && (
                                            <span className="field-text">
                                                {userData?.workFacilities ||
                                                    "-"}
                                            </span>
                                        )}
                                    </span>

                                    {showEdit && (
                                        <TextArea
                                            {...register("workFacilities", {
                                                onChange: (e) => {
                                                    const { value } = e.target;
                                                    e.target.value = value;
                                                },
                                                onBlur: () => {
                                                    trigger("workFacilities");
                                                },
                                            })}
                                            error={
                                                errors.workFacilities
                                                    ?.message || ""
                                            }
                                            placeholder={
                                                "Com o que você tem mais facilidade de trabalhar?"
                                            }
                                            noLabel
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="contact-field">
                                    <span className="field-title">
                                        Habilidades técnicas:{" "}
                                        <span className="field-text">
                                            {userData?.UserSkill?.map(
                                                ({ name, experience }) =>
                                                    `${name} ${convertFloatToYearsAndMonths(
                                                        experience
                                                    )}`
                                            ).join(" | ") || "-"}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {showEdit ? (
                            <div className="icons-group">
                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Salvar alterações</s.P>}
                                >
                                    <span className="tooltip">
                                        <DesignedAlert
                                            textBtn={"Salvar"}
                                            onClickBtn={handleSubmit(onSubmit)}
                                            trigger={
                                                <Icons.Save
                                                    color={theme.purple600}
                                                />
                                            }
                                            description={
                                                "Ao salvar alterações as informações serão substituídas. Deseja salvar as alterações?"
                                            }
                                            title={"Salvar alterações"}
                                        />
                                    </span>
                                </Tooltip>

                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Descartar alterações</s.P>}
                                >
                                    <span className="tooltip">
                                        <DesignedAlert
                                            textBtn={"Descartar"}
                                            onClickBtn={() => {
                                                reset(defaultValues);
                                                setShowEdit(!showEdit);
                                            }}
                                            trigger={
                                                <Icons.Trash
                                                    color={theme.purple600}
                                                />
                                            }
                                            description={
                                                "Ao descartar as alteraçãos, os campos editados não serão editados. Deseja descartar as alterações?"
                                            }
                                            title={"Descartar alterações"}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className="actions">
                                {user.id_e === userId && (
                                    <Tooltip
                                        side="left"
                                        positionx={-10}
                                        content={<s.P>Editar</s.P>}
                                    >
                                        <span className="tooltip">
                                            <Icons.EditOutline
                                                color={theme.purple600}
                                                onClick={() =>
                                                    setShowEdit(!showEdit)
                                                }
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                                <Tooltip
                                    side="left"
                                    positionx={-10}
                                    content={<s.P>Editar habilidades</s.P>}
                                >
                                    <span
                                        className="tooltip"
                                        onClick={() => setShowSkillsModal(true)}
                                    >
                                        <Icons.Lamp
                                            width="26px"
                                            height="26px"
                                            color={theme.purple500}
                                        />
                                    </span>
                                </Tooltip>
                            </div>
                        )}
                    </>
                )}
            </s.Experience>
        </s.Content>
    );
};
