import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 31.25rem;
    padding: 1.25rem;
    gap: 4.375rem;

    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.375rem;
        letter-spacing: 0.0015em;
        color: ${({ theme }) => theme.purple500};
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            padding: 0.5rem 1rem;
        }
    }
`;

export const Title = styled.label`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.purple600};
`;
