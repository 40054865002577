import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { CreateProjectAdditionalCostParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    title,
    cost,
    description,
    date,
    projectId,
    type,
    recurringNumber,
}: CreateProjectAdditionalCostParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/additionalCost`,
        method: "post",
        body: {
            title,
            cost,
            description,
            date,
            type,
            recurringNumber,
        },
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
