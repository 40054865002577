import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <>
            <svg
                onClick={onClick}
                width="110"
                height="22"
                viewBox="0 0 110 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className || "icon-paper"}
            >
                <title>{title}</title>
                <path
                    d="M36.1971 17.9486H28.0904L26.658 21.6667H19.3438L28.6695 0.333344H35.7399L45.0656 21.6667H37.6295L36.1971 17.9486ZM34.2161 12.7676L32.1437 7.40382L30.0714 12.7676H34.2161Z"
                    fill={color || theme.purple500}
                />
                <path
                    d="M56.5283 0.333344C58.4991 0.333344 60.2159 0.658423 61.6788 1.30858C63.1416 1.95874 64.2692 2.89334 65.0616 4.11239C65.854 5.33144 66.2502 6.75366 66.2502 8.37906C66.2502 10.0045 65.854 11.4267 65.0616 12.6457C64.2692 13.8648 63.1416 14.7994 61.6788 15.4495C60.2159 16.0997 58.4991 16.4248 56.5283 16.4248H53.4502V21.6667H46.2578V0.333344H56.5283ZM56.0711 10.8781C57.0464 10.8781 57.7778 10.6648 58.2654 10.2381C58.753 9.79112 58.9969 9.17144 58.9969 8.37906C58.9969 7.58668 58.753 6.97715 58.2654 6.55049C57.7778 6.1035 57.0464 5.88001 56.0711 5.88001H53.4502V10.8781H56.0711Z"
                    fill={color || theme.purple500}
                />
                <path
                    d="M86.3934 16.2419V21.6667H68.6562V0.333344H85.9972V5.75811H75.7267V8.25715H84.7477V13.4381H75.7267V16.2419H86.3934Z"
                    fill={color || theme.purple500}
                />
                <path
                    d="M98.3484 16.3029H96.3979V21.6667H89.2056V0.333344H99.476C101.447 0.333344 103.164 0.658423 104.627 1.30858C106.089 1.95874 107.217 2.89334 108.009 4.11239C108.802 5.33144 109.198 6.75366 109.198 8.37906C109.198 9.90287 108.853 11.2337 108.162 12.3714C107.471 13.5092 106.475 14.4133 105.175 15.0838L109.655 21.6667H101.975L98.3484 16.3029ZM101.945 8.37906C101.945 7.58668 101.701 6.97715 101.213 6.55049C100.726 6.1035 99.9941 5.88001 99.0189 5.88001H96.3979V10.8781H99.0189C99.9941 10.8781 100.726 10.6648 101.213 10.2381C101.701 9.79112 101.945 9.17144 101.945 8.37906Z"
                    fill={color || theme.purple500}
                />
                <path
                    d="M10.604 0.333344C12.5748 0.333344 14.2916 0.658423 15.7544 1.30858C17.2173 1.95874 18.3449 2.89334 19.1373 4.11239C19.9297 5.33144 20.3259 6.75366 20.3259 8.37906C20.3259 10.0045 19.9297 11.4267 19.1373 12.6457C18.3449 13.8648 17.2173 14.7994 15.7544 15.4495C14.2916 16.0997 12.5748 16.4248 10.604 16.4248H7.52587V21.6667H0.333496V0.333344H10.604ZM10.1468 10.8781C11.1221 10.8781 11.8535 10.6648 12.3411 10.2381C12.8287 9.79112 13.0725 9.17144 13.0725 8.37906C13.0725 7.58668 12.8287 6.97715 12.3411 6.55049C11.8535 6.1035 11.1221 5.88001 10.1468 5.88001H7.52587V10.8781H10.1468Z"
                    fill={color || theme.purple500}
                />
            </svg>
        </>
    );
};
