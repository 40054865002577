import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const TooltipContent = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray600};
    padding: 0.5rem;
    width: 300px;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};

    display: flex;
    align-items: center;
    gap: 0.5rem;

    div {
        display: flex;
        align-items: center;
    }
`;
