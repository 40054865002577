import { TCreateUserBody } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateUserConfigs = {
    userId?: string;
    userConfigs?: {
        groupTasks?: boolean;
        clearTaskFieldsAfterAdd?: boolean;
        dailyGoal?: number;
    };
} & TCreateUserBody;

export default async ({ userConfigs, userId }: TUpdateUserConfigs = {}) => {
    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/configs/update`,
        method: "put",
        body: userConfigs,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        400: "Erro! campos obrigatórios não preenchidos",
    };
    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
