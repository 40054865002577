import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 4.5rem;
    flex-direction: column;
    align-items: center;
`;

export const DeleteWrapper = styled.div`
    position: absolute;
    top: 150px;
    right: 50px;

    button {
        padding: 0.5rem 1rem;
    }
`;

export const BreadcrumbContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    margin-top: 2rem;
`;
