import * as yup from "yup";

export default yup.object().shape({
    number: yup.string().required("Insira o número do cartão"),
    cvv: yup.string().required("Insira o CVV"),
    expirationMonth: yup.string().required("Insira o mês de expiração"),
    expirationYear: yup.string().required("Insira o ano de expiração"),
    name: yup.string().required("Insira o nome impresso no cartão"),
    contractorName: yup.string().required("Insira o nome do contratante"),
});
