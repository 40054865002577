import { TEntryDay, TLimits } from "../../models";
import { getPercentage } from "../../utils";

type UpdateLimitsInput = {
    time: number;
    workedHours: {
        total: number;
        limit: number;
    };
    limits: TLimits;
    setLimits: React.Dispatch<React.SetStateAction<TLimits>>;
    isRunning?: boolean;
};

export class TrackService {
    static updateLimits({
        time,
        workedHours,
        limits,
        setLimits,
        isRunning,
    }: UpdateLimitsInput) {
        const percentage = getPercentage({
            total: workedHours.limit,
            quantity: isRunning ? time / 60 : time,
        });
        if (percentage > 90 && !limits.greaterThan90) {
            return setLimits((prevState) => ({
                ...prevState,
                greaterThan90: true,
            }));
        }
        if (percentage > 80 && !limits.greaterThan80) {
            return setLimits((prevState) => ({
                ...prevState,
                greaterThan80: true,
            }));
        }
        if (percentage < 80 && limits.greaterThan80) {
            return setLimits((prevState) => ({
                ...prevState,
                greaterThan80: false,
            }));
        }
        if (percentage >= 100 && !limits.max) {
            return setLimits((prevState) => ({ ...prevState, max: true }));
        }
        if (percentage === Infinity && !limits.max) {
            return setLimits((prevState) => ({ ...prevState, max: false }));
        }
        if (percentage < 100 && limits.max) {
            return setLimits((prevState) => ({ ...prevState, max: false }));
        }
        return {};
    }

    static isValidTimeFormat(HHMM: string) {
        if (!HHMM || HHMM.length < 5) return false;
        const hour = Number(HHMM.substring(0, 2));
        const minute = Number(HHMM.substring(3, 5));
        return hour < 24 && minute < 60;
    }

    static sumAllDurations(entryByDay: TEntryDay): number[] {
        return entryByDay.tasks.map((item) =>
            item.entries.reduce((acc, item) => item.total_time + acc, 0)
        );
    }

    static validateTimes(
        start: string,
        end: string,
        selectedDay: Date
    ): { isValid: boolean; errorMessage: string } {
        const result = {
            isValid: false,
            errorMessage: "",
        };
        if (!start || !end || start.length < 5 || end.length < 5) {
            return result;
        }
        const startHour = Number(start.substring(0, 2));
        const startMin = Number(start.substring(3, 5));
        const endHour = Number(end.substring(0, 2));
        const endMin = Number(end.substring(3, 5));
        const now = new Date().toTimeString();
        const today = `${new Date().getFullYear()}/${
            new Date().getMonth() + 1
        }/${new Date().getDate()}`;
        const selectedDate = `${selectedDay.getFullYear()}/${
            selectedDay.getMonth() + 1
        }/${selectedDay.getDate()}`;

        if (selectedDate === today) {
            if (endHour < startHour) {
                result.errorMessage = "Hora inicial maior que hora final!";
            }

            if (endHour > Number(now.split(":")[0])) {
                result.errorMessage = "Hora final maior que hora atual!";
            }

            if (
                endHour === Number(now.split(":")[0]) &&
                endMin > Number(now.split(":")[1])
            ) {
                result.errorMessage = "Minuto atual maior que horário atual!";
            }

            if (endHour === startHour && endMin === startMin) {
                result.errorMessage = "Hora final igual a hora inicial!";
            }
            if (endHour === startHour && endMin < startMin) {
                result.errorMessage = "Hora final menor que hora inicial!";
            }
        } else if (selectedDate < today) {
            if (endHour < startHour) {
                result.errorMessage = "Hora inicial maior que hora final!";
            }

            if (endHour === startHour && endMin < startMin) {
                result.errorMessage = "Hora final menor que hora inicial!";
            }
        }

        if (!result.isValid && result.errorMessage) {
            return result;
        }

        result.isValid = true;
        return result;
    }
}
