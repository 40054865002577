import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { theme } from "../../../assets";
import IconInstagram from "../../../assets/icons/icon-instagram";
import IconLinkedin from "../../../assets/icons/icon-linkedin";
import IconWhatsappFilled from "../../../assets/icons/icon-whatsapp-filled";
import MaintenanceMan from "../../../assets/images/maintenance-man";
import { useUser } from "../../../context";
import { redirectToWhatsApp } from "../../../utils/contact/redirect-to-whatsapp";
import * as s from "../styled-information-pages";

const renderDeadlineHourAndMinutes = () => {
    const hourAndMinuteDeadLine =
        process.env.REACT_APP_HOUR_AND_MINUTE_DEADLINE;
    const dayAndMonthDeadLine = process.env.REACT_APP_DAY_AND_MONTH_DEADLINE;

    if (
        hourAndMinuteDeadLine === undefined ||
        dayAndMonthDeadLine === undefined
    ) {
        return "O site está passando por uma manutenção programada";
    }
    return `O site está em manutenção programada até às ${dayAndMonthDeadLine} (dia ${hourAndMinuteDeadLine})`;
};

export const MaintenancePage = () => {
    const user = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const isSiteOnMaintenance = process.env.REACT_APP_SERVER_MAINTENANCE;
        if (isSiteOnMaintenance === "false") {
            if (user.isLogged) {
                navigate("/cronometro");
            } else {
                navigate("/");
            }
        }
    }, []);
    return (
        <s.Container>
            <s.content>
                <MaintenanceMan
                    height="231px"
                    width="245px"
                    className="maintenanceMan"
                />
                <div className="contentSection">
                    <s.title>Site em manutenção programada</s.title>
                    <s.information>
                        <span>{renderDeadlineHourAndMinutes()}.</span>
                        <span>
                            Enquanto isso, acesse nossas redes sociais ou entre
                            em contato conosco.
                        </span>
                    </s.information>
                    <s.button
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        TENTAR NOVAMENTE
                    </s.button>
                </div>
                <s.footer>
                    <IconLinkedin
                        width="36px"
                        height="36px "
                        color={theme.purple600}
                        opacity="1"
                        cursor="pointer"
                        onClick={() => {
                            window.open(
                                "https://www.linkedin.com/company/paperonapp/",
                                "_blank"
                            );
                        }}
                    />
                    <IconInstagram
                        width="36px"
                        height="36px"
                        color={theme.purple600}
                        opacity="1"
                        cursor="pointer"
                        onClick={() => {
                            window.open(
                                "https://www.instagram.com/paperon.app/",
                                "_blank"
                            );
                        }}
                    />
                    <IconWhatsappFilled
                        width="36px"
                        height="36px"
                        color={theme.purple600}
                        cursor="pointer"
                        onClick={redirectToWhatsApp}
                    />
                </s.footer>
            </s.content>
        </s.Container>
    );
};
