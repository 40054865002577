import { components } from "react-select";
import styled from "styled-components";

import { theme } from "../../../../assets";

type TSelect = {
    error?: boolean;
};

type TPositionY = {
    positionY?: string;
    disabled?: boolean;
};

type TState = { isFocused?: boolean; isSelected?: boolean };

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    width: 100%;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Flex = styled.div<TSelect>`
    .react-select__menu-list {
        max-height: 12.5rem;
    }

    .react-select__control {
        width: 100%;
        border: none;
        border-radius: 0.25rem;
        height: 2.5rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        width: 100%;
        box-shadow: none;
        font-size: 0.875rem;
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
        cursor: pointer;
    }

    .react-select__control--is-focused:hover {
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
    }

    .react-select__indicators {
        display: none;
    }

    .react-select--is-disabled {
        visibility: unset;
        cursor: not-allowed;
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const PositionIcons = styled.div<TPositionY>`
    display: flex;
    position: absolute;
    right: 1rem;
    top: ${({ positionY }) => (positionY ? `${positionY}rem` : "1rem")};
    ${({ disabled }) => (disabled ? `cursor: not-allowed` : `cursor: pointer`)};
    pointer-events: none;
`;

export const Menu = styled.div`
    max-height: 300px;
    overflow-y: auto;

    div {
        padding: 0;
        border-radius: 4px;
    }

    .fetching {
        margin: 1.125rem 1.4375rem;
    }
    .bottom-bar {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid ${theme.gray200};
        padding: 1.125rem 1.4375rem;
    }
`;

export const Option = styled.div<TState>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: ${({ isSelected }) => isSelected && theme.purple500};
    padding-right: 10px !important;

    &:hover {
        background-color: ${({ theme, isSelected }) =>
            isSelected ? "" : theme.gray50} !important;
    }

    &:last-child {
        border-radius: 0;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
`;

export const NoOptions = styled.div`
    margin: 1.25rem;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 0.9375rem;

    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    color: ${theme.purple600};
`;
