import { ReactNode } from "react";

import * as s from "./styled-scroll";

type TScroll = {
    children: ReactNode;
    className?: string;
};

const Scroll = ({ children, className }: TScroll) => {
    return (
        <s.Scroll className={className || "scroll"}>
            <s.Viewport>{children}</s.Viewport>
            <s.Scrollbar orientation="vertical">
                <s.Thumb />
            </s.Scrollbar>
        </s.Scroll>
    );
};

export default Scroll;
