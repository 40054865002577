import styled from "styled-components";

type TContainerTrigger = {
    isOpen?: boolean;
};

export const Container = styled.div<TContainerTrigger>`
    cursor: default;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(14, 2, 26, 0.2);
    background: ${({ theme }) => theme.white};
    width: 52.375rem;
    margin: 0 1rem;

    .left-align {
        text-align: left;
    }
`;

export const Trigger = styled.div<TContainerTrigger>`
    svg {
        path {
            stroke: ${({ isOpen, theme }) => isOpen && theme.pink400};
        }
    }
    &:hover {
        svg {
            path {
                stroke: ${({ theme }) => theme.pink400};
            }
        }
    }
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
    align-items: center;
    padding-bottom: 1rem;
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    color: ${({ theme }) => theme.gray800};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
`;

export const List = styled.div`
    height: 12.3125rem;
    padding-bottom: 1rem;

    .content-row {
        display: grid;
        grid-template-columns: 1.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
        align-items: center;
        height: 3rem;
        padding: 0 1rem;
        text-align: center;

        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }

        &:hover {
            background-color: ${({ theme }) => theme.gray50};
        }
    }

    .tag-container {
        margin-right: 1.5rem;
        position: relative;
        display: flex;
        align-items: center;
        width: 17.5rem;

        span {
            cursor: default;
            width: 17.5rem;
            white-space: nowrap;
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }
        }

        &:hover span {
            position: absolute;
            white-space: normal;
            overflow: visible;
            text-overflow: clip;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.3);
            transition: all 0.2s ease;
        }
    }
`;

export const LockTooltip = styled.div`
    display: flex;
    flex-direction: column;
    width: 18.75rem;
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    gap: 17px;

    .rule {
        display: flex;
        gap: 8px;
    }

    .title {
        font-size: 14px;
    }
`;

export const CommonTooltip = styled.div`
    width: 18.75rem;
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    text-align: left;
`;
