import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { CreateActivityVariables } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    deadLine,
    priority,
    expectedMinutes,
    users,
    projectId,
    sectionId,
}: CreateActivityVariables = {}) => {
    const headers = {
        "x-project-id": projectId,
        "x-section-id": sectionId,
        auth: true,
    };

    if (!sectionId) delete headers["x-section-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/create`,
        method: "post",
        body: {
            name,
            deadLine,
            priority,
            expectedMinutes,
            users,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
