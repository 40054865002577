import { TUpdateSketch } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    inviteSketchesIds,
    name,
    email,
    contractFormat,
    value,
    role,
    projects,
    sectors,
    workload,
}: TUpdateSketch = {}) => {
    const params = {
        inviteSketchesIds,
        name,
        email,
        contractFormat,
        value,
        role,
        projects,
        sectors,
        workload,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/updateInviteSketch`,
        method: "put",
        headers: { auth: true },
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro! campos obrigatórios não preenchidos",
        409: "Usuário já existente/rascunho já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
