import { styled } from "styled-components";

export const Container = styled.div`
    width: 615px;
`;

export const Option = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .project-tag {
        width: 15vw;
    }
`;

export const NoOptionsMessage = styled.div`
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    padding: 8px 0;
`;
