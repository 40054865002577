import { TPatchNotification } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (data?: TPatchNotification) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/notifications/toggleWasRead`,
        method: "patch",
        headers: { auth: true, "x-notification-id": data?.notificationId },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
