import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Icons, theme } from "../../../../../../../assets";
import {
    DayPicker,
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../components";
import ContainerModal from "../../../../../../../components/molecules/modals/container-modal";
import { Switch } from "../../../../../../../components/molecules/switch/switch";
import { Radio } from "../../../../../../../styles/radio-group-styles";
import { CostsFields } from "../../../tab-costs";
import {
    costStringToNumber,
    defaultCostToFormattedCurrency,
    formatCurrency,
} from "../../../utils";
import * as s from "./styles";

const schema = yup.object().shape({
    title: yup
        .string()
        .required("Obrigatório")
        .max(100, "Máximo de 100 caracteres")
        .min(3, "Mínimo de 3 caracteres"),
    description: yup
        .string()
        .required("Obrigatório")
        .max(200, "Máximo de 200 caracteres")
        .min(3, "Mínimo de 3 caracteres"),
    date: yup.date().nullable(),
    cost: yup
        .string()
        .required("Obrigatório")
        .test("min cost", "Maior que 1", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue > 1;
        })
        .test("max cost", "Menor que 999.999", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue < 999999;
        })
        .typeError("Deve ser um número válido"),
    recurringNumber: yup
        .number()
        .min(1, "Maior ou igual a 1")
        .max(12, "Menor ou igual a 12")
        .typeError("Deve ser um número válido"),
});

type ModalUpsertAdditionalCostProps = {
    onSave: (params: CostsFields) => void;
    onCancel: () => void;
    defaultValues?: CostsFields;
    loading?: boolean;
};

export type UpdateChoiceEnum = "onlyThis" | "thisAndNext";

const MAX_RECURRING_NUMBER = 12;
const MIN_RECURRING_NUMBER = 1;

export const ModalUpsertAdditionalCost = ({
    defaultValues,
    onCancel,
    onSave,
    loading,
}: ModalUpsertAdditionalCostProps) => {
    const [switchOn, setSwitchOn] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm<CostsFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            ...defaultValues,
            cost: defaultCostToFormattedCurrency(defaultValues?.cost),
            updateChoice: defaultValues?.updateChoice || "onlyThis",
        },
    });

    const increase = (value: number) => {
        if (value >= MAX_RECURRING_NUMBER) return MAX_RECURRING_NUMBER;
        return value + 1;
    };

    const decrease = (value: number) => {
        if (value <= MIN_RECURRING_NUMBER) return MIN_RECURRING_NUMBER;
        return value - 1;
    };

    return (
        <ContainerModal>
            <s.Container
                onSubmit={handleSubmit(onSave)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSubmit(onSave)();
                    }
                    if (e.key === "Escape") {
                        onCancel();
                    }
                }}
            >
                <s.Title>{defaultValues ? "Editar" : "Novo"} custo</s.Title>
                <s.Fields>
                    <Controller
                        name="title"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Categoria"
                                mask=""
                                ref={(e) => e?.focus()}
                                onChange={onChange}
                                value={value}
                                placeholder="Nome do custo"
                                error={errors.title?.message}
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Descrição"
                                mask=""
                                onChange={onChange}
                                value={value}
                                placeholder="Imposto da empresa"
                                error={errors.description?.message}
                            />
                        )}
                    />
                    <s.SideFields>
                        <Controller
                            name="cost"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    mask=""
                                    onChange={(e) => {
                                        onChange(
                                            formatCurrency(e.target.value)
                                        );
                                    }}
                                    label="Valor (R$)"
                                    value={value}
                                    placeholder="R$ 1.000,00"
                                    error={errors.cost?.message}
                                />
                            )}
                        />
                        <Controller
                            name="date"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DayPicker
                                    label="Data"
                                    placeholder="dd/mm/yyyy"
                                    onSelect={(date) => onChange(date)}
                                    value={value}
                                    error={errors.date?.message}
                                />
                            )}
                        />
                    </s.SideFields>
                    {!defaultValues && (
                        <s.SwitchContainer>
                            <Switch
                                checked={switchOn}
                                onChange={() => setSwitchOn(!switchOn)}
                            />
                            <s.SwitchLabel>Repetir mensalmente</s.SwitchLabel>
                        </s.SwitchContainer>
                    )}
                    {defaultValues?.recurring && (
                        <s.EditRecurringContainer>
                            <s.EditRecurringTitleContainer>
                                <Icons.Repeat />
                                <h1>
                                    Este lançamento se repete em outras datas.
                                </h1>
                            </s.EditRecurringTitleContainer>
                            <s.EditRecurringRadioContainer>
                                <Radio.Wrapper>
                                    <s.EditRecurringRadioItem
                                        checked={
                                            watch("updateChoice") === "onlyThis"
                                        }
                                        onClick={() =>
                                            setValue("updateChoice", "onlyThis")
                                        }
                                        value="onlyThis"
                                    >
                                        <Radio.Indicator />
                                    </s.EditRecurringRadioItem>
                                    <p>Atualizar apenas este lançamento</p>
                                </Radio.Wrapper>
                                <Radio.Wrapper>
                                    <s.EditRecurringRadioItem
                                        checked={
                                            watch("updateChoice") ===
                                            "thisAndNext"
                                        }
                                        onClick={() =>
                                            setValue(
                                                "updateChoice",
                                                "thisAndNext"
                                            )
                                        }
                                        value="thisAndNext"
                                    >
                                        <Radio.Indicator />
                                    </s.EditRecurringRadioItem>
                                    <p>
                                        Atualizar este e os próximos lançamentos
                                    </p>
                                </Radio.Wrapper>
                            </s.EditRecurringRadioContainer>
                        </s.EditRecurringContainer>
                    )}
                    {switchOn && (
                        <Controller
                            name="recurringNumber"
                            control={control}
                            render={({
                                field: {
                                    onChange,
                                    value = MIN_RECURRING_NUMBER,
                                },
                            }) => (
                                <s.RecurringNumberContainer>
                                    <s.RecurringNumberLabel>
                                        Termina após
                                    </s.RecurringNumberLabel>
                                    <Input
                                        type="number"
                                        onChange={onChange}
                                        onBlur={() => {
                                            if (value > MAX_RECURRING_NUMBER) {
                                                onChange(MAX_RECURRING_NUMBER);
                                            }
                                            if (value < MIN_RECURRING_NUMBER) {
                                                onChange(MIN_RECURRING_NUMBER);
                                            }
                                        }}
                                        value={value}
                                        error={errors.recurringNumber?.message}
                                        rightSideLabelIcon={
                                            <s.InputInsideLabel>
                                                repetições
                                            </s.InputInsideLabel>
                                        }
                                    />
                                    <s.IncreaseDecreaseContainer>
                                        <Icons.ChevronDecrease
                                            onClick={() =>
                                                onChange(increase(value))
                                            }
                                            color={
                                                value >= MAX_RECURRING_NUMBER
                                                    ? theme.gray200
                                                    : theme.gray600
                                            }
                                        />
                                        <Icons.ChevronDecrease
                                            onClick={() =>
                                                onChange(decrease(value))
                                            }
                                            color={
                                                value <= MIN_RECURRING_NUMBER
                                                    ? theme.gray200
                                                    : theme.gray600
                                            }
                                        />
                                    </s.IncreaseDecreaseContainer>
                                </s.RecurringNumberContainer>
                            )}
                        />
                    )}
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton onClick={onCancel}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit" loading={loading}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
