import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";

import { Icons, theme } from "../../../../../../../../../assets";
import {
    Input,
    PrimaryButton,
    SecondaryButton,
} from "../../../../../../../../../components";
import { activities } from "../../../../../../../../../services";
import { TCreateAttachment } from "../../../../../../../../../services/activities/create-attachment";
import { queryClient } from "../../../../../../../../../utils/query-client/query-client";
import { ActivityDTO } from "../../../../types/activityDTO";
import * as s from "./styles";

type TAddAttachment = {
    activity: ActivityDTO;
    setAddAttachment: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AddAttachment = ({
    activity,
    setAddAttachment,
}: TAddAttachment) => {
    const [link, setLink] = useState("");
    const [file, setFile] = useState<File>();

    const { mutate: createAttachment } = useMutation({
        mutationFn: (params: TCreateAttachment) =>
            activities.createAttachment(params),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const save = () => {
        if (file) {
            const formData = new FormData();
            formData.append("activityAttachment", file as Blob);

            createAttachment({
                activityAttachment: formData,
                activityId: activity.id_e,
                type: "FILE",
            });
            setFile(undefined);
            return;
        }
        createAttachment({
            activityId: activity.id_e,
            link,
            type: "LINK",
        });
        setLink("");
        setAddAttachment(false);
    };

    function NormalizeStrings(string: string): string {
        return string
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.toLowerCase();
    }

    return (
        <s.Container>
            <s.Wrapper>
                <s.Title>Anexar um arquivo</s.Title>
                <SecondaryButton disabled={Boolean(link)}>
                    <s.InputFile
                        type="file"
                        accept=".jpeg,.pjpeg,.png,.gif,.jpg,.pdf"
                        onChange={(e) => {
                            if (e.target.files) {
                                const originalFile = e.target.files[0];

                                const newFile = new File(
                                    [originalFile],
                                    NormalizeStrings(originalFile.name),
                                    {
                                        type: originalFile.type,
                                    }
                                );

                                setFile(newFile);
                            }
                        }}
                        disabled={Boolean(link)}
                    />
                    ESCOLHA UM ARQUIVO
                    <Icons.AttachFile
                        color={link ? theme.gray400 : theme.purple500}
                    />
                </SecondaryButton>
            </s.Wrapper>
            {Boolean(file?.name) && (
                <s.FileName>
                    Selecionado: <span>{file?.name}</span>
                    <Icons.XOutline onClick={() => setFile(undefined)} />
                </s.FileName>
            )}
            <s.Wrapper fullWidth>
                <s.Title>Anexar um link</s.Title>
                <Input
                    ref={(input) => input?.focus()}
                    mask=""
                    placeholder="Cole o link aqui"
                    onChange={(e) => setLink(e.target.value)}
                    disabled={Boolean(file)}
                />
            </s.Wrapper>
            <s.Buttons>
                <SecondaryButton
                    variation="small"
                    onClick={() => setAddAttachment(false)}
                >
                    CANCELAR
                </SecondaryButton>
                <PrimaryButton variation="small" onClick={save}>
                    ADICIONAR
                </PrimaryButton>
            </s.Buttons>
        </s.Container>
    );
};
