import * as s from "./styled-pay-method";

type TPayMethod = {
    plan: "Lite" | "Starter" | "Standard";
    paymentType: "yearly" | "monthly";
    className?: string;
    onSubmit: () => void;
    isOpenModalPlan: (open: boolean) => void;
    isFinishButtonEnabled: boolean;
};

export const PayMethod = ({
    plan,
    paymentType,
    className,
    onSubmit,
    isOpenModalPlan,
    isFinishButtonEnabled,
}: TPayMethod) => {
    function getVacancies() {
        switch (plan) {
            case "Lite":
                return "3";
            case "Starter":
                return "10";
            case "Standard":
                return "20";
            default:
                return "0";
        }
    }
    function getValue() {
        switch (plan) {
            case "Lite":
                if (paymentType === "yearly") return "R$326,70";
                return "R$29,70";
            case "Starter":
                if (paymentType === "yearly") return "R$979,00";
                return "R$89,00";
            case "Standard":
                if (paymentType === "yearly") return "R$1738,00";
                return "R$158,00";
            default:
                return "R$ 0";
        }
    }
    return (
        <s.Container className={className || ""}>
            <header>
                <div>Seu plano</div>
                <button onClick={() => isOpenModalPlan(true)}>Editar</button>
            </header>
            <section>
                <div className="planInformation">
                    <div>Plano</div>
                    <span>{plan}</span>
                </div>
                <div className="planInformation">
                    <div>Cobrança</div>
                    <span className="charge">
                        <div>
                            {paymentType === "monthly" ? "Mensal" : "Anual"}
                        </div>
                        {paymentType === "monthly" ? (
                            <span>
                                Troque para anual e economize {getValue()}/ano
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                </div>
                <div className="planInformation">
                    <div>Vagas</div>
                    <span>{getVacancies()} usuários</span>
                </div>
                <div id="totalValue">
                    <div>Valor total</div>
                    <p>
                        {getValue()}
                        <span>
                            /{paymentType === "monthly" ? "mês" : "ano"}
                        </span>
                    </p>
                </div>
                <button
                    onClick={() => onSubmit()}
                    disabled={!isFinishButtonEnabled}
                    id={isFinishButtonEnabled ? "active" : "inactive"}
                >
                    CONCLUIR
                </button>
            </section>
            <footer>
                Ao clicar no botão “Assinar agora”, você concorda com os{" "}
                <a
                    target="_blank"
                    href="https://prod-paperon.s3.eu-central-1.amazonaws.com/TERMOS_DE_USO_-_PAPER_ON_15_05_2023.pdf"
                >
                    Termos do Assinante
                </a>{" "}
                e com a nossa{" "}
                <a
                    target="_blank"
                    href="https://prod-paperon.s3.eu-central-1.amazonaws.com/POLITICA_DE_PRIVACIDADE_-_PAPERON_15_05_2023.pdf"
                >
                    Política de Privacidade
                </a>
                . Sua assinatura renovará automaticamente através do seu método
                de pagamento até que você decida efetuar um cancelamento. É
                possível realizar o cancelamento a qualquer momento para evitar
                cobranças futuras. Para cancelar, entre em contato conosco pelo
                e-mail do nosso suporte suporte@paperon.app ou
                contato@paperon.app.
            </footer>
        </s.Container>
    );
};
