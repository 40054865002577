import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const LastWeekNewUsers = ({ data }: { data: TBackOfficeData }) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.UserAdd color={theme.purple500} />
                Usuários criados na última semana
            </generalStyles.Title>
            <generalStyles.Row>
                {data.usersCreatedLastWeek.length > 0 ? (
                    <generalStyles.Content>
                        {data.usersCreatedLastWeek.map(({ email }) => (
                            <p key={email}>{email}</p>
                        ))}
                    </generalStyles.Content>
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhum usuário criado na última semana</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
