import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type ReadDemonstrativesParams = {
    page?: number;
    length?: number;
    searchTerm?: string;
    filterType?: string;
    archived?: boolean;
};

export default async ({
    page,
    length,
    searchTerm,
    filterType,
    archived,
}: ReadDemonstrativesParams = {}) => {
    const headers = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/demonstratives`,
        method: "get",
        headers,
        params: {
            page,
            length,
            searchTerm,
            filterType,
            archived,
        },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
