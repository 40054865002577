import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, theme } from "../../../../../assets";
import {
    Collapse,
    ProjectStatus,
    SecondaryButton,
    Warning,
} from "../../../../../components";
import Tag from "../../../../../components/atoms/tag/tag";
import { useFetch } from "../../../../../hooks";
import { projects } from "../../../../../services";
import {
    formatToBRMoney,
    getProjectCostDelayProjectionPercent,
    getProjectDurationProjection,
    getProjectHourDelayProjectionPercent,
    getProjectProjectionStatus,
    getProjectStatus,
    formatISOToDate,
    numberHoursToString,
} from "../../../../../utils";
import { SIZES } from "../demonstrative-table";
import DurationProjection from "../duration-projection/duration-projection";
import * as s from "./styled-project-row";

type TProject = {
    id_e: string;
    start_at: string;
    end_at: string;
    percentageHoursDone: number;
    estimatedHours: number;
    total_time: number;
    expectedCost: number;
    costDone: number;
    idealPercentageOfProgress: number;
    name: string;
    color: string;
    scopePercentage: number;
    ProjectReviewers: {
        name: string;
    }[];
    ProjectBiller: {
        name: string;
    }[];
    finished: boolean;
    status:
        | "finished"
        | "to_start"
        | "in_progress"
        | "late"
        | "paused"
        | "canceled"
        | "no_information";
    costs?: {
        forecasted: number;
        realized: number;
    };
    Client: {
        name: string;
    };
};

type ProjectRowProps = {
    project: TProject;
    index: number;
    canViewCost: boolean;
};

const ProjectRow = (props: ProjectRowProps) => {
    const { project, index, canViewCost } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [collapseIsOpen, setCollapseIsOpen] = useState(false);
    const [projectInfo, setProjectInfo] = useState<TProject>();

    const getProjects = useFetch({
        fn: projects.getProject,
        start: false,
        params: {
            all: true,
            users: true,
            hours: true,
            finished: false,
            sectors: true,
            reviewers: true,
            demonstrative: true,
            projectId: project.id_e,
        },
    });

    useEffect(() => {
        if (projectInfo) {
            setCollapseIsOpen(true);
        }
    }, [projectInfo]);

    useEffect(() => {
        if (getProjects.response) {
            setProjectInfo(getProjects.response.projects[0]);
        }
    }, [getProjects.response]);

    const {
        start_at,
        end_at,
        percentageHoursDone,
        estimatedHours,
        total_time,
        expectedCost,
        costDone,
        idealPercentageOfProgress,
        name,
        color,
        scopePercentage,
        status,
    } = project;

    const getDurationProjection = useCallback(() => {
        return getProjectDurationProjection(
            start_at,
            end_at,
            percentageHoursDone,
            estimatedHours,
            total_time
        );
    }, [project]);

    const getCurrentCostPercentage = useCallback(() => {
        const expected = expectedCost || 0;
        const actual = costDone || 0;
        let current = 0;
        if (expected && actual) {
            current = actual / expectedCost;
            current = Math.floor(current * 100);
        }
        return current;
    }, [project]);

    const handleNavigateToProject = useCallback(() => {
        navigate(`${location.pathname}/${project.name}`, {
            state: {
                project,
            },
        });
    }, [navigate, project]);

    const HoursWarningComponent = () => {
        const projectIsLate = getDurationProjection() < 0;
        const { title, message } = getProjectProjectionStatus(
            "hours",
            status,
            projectIsLate,
            getProjectHourDelayProjectionPercent(
                start_at,
                end_at,
                estimatedHours,
                total_time
            )
        );
        return (
            <Warning
                title={title}
                projectStatus={status}
                projectIsLate={projectIsLate}
                message={message}
            />
        );
    };
    const CostWarningComponent = () => {
        const projectIsLate = getCurrentCostPercentage() > percentageHoursDone;
        const { title, message } = getProjectProjectionStatus(
            "cost",
            status,
            projectIsLate,
            getProjectCostDelayProjectionPercent(
                estimatedHours,
                total_time,
                costDone,
                expectedCost
            )
        );
        return (
            <Warning
                title={title}
                projectStatus={status}
                projectIsLate={projectIsLate}
                message={message}
            />
        );
    };
    const ScopeWarningComponent = () => {
        const projectIsLate = scopePercentage < idealPercentageOfProgress;
        const { title, message } = getProjectProjectionStatus(
            "scope",
            status,
            projectIsLate
        );
        return (
            <Warning
                title={title}
                projectStatus={status}
                projectIsLate={projectIsLate}
                message={message}
            />
        );
    };
    return (
        <>
            <s.Row
                key={index}
                onClick={() => {
                    if (!collapseIsOpen && !projectInfo) {
                        getProjects.onRefresh({
                            all: true,
                            users: true,
                            hours: true,
                            finished: false,
                            sectors: true,
                            reviewers: true,
                            demonstrative: true,
                            projectId: project.id_e,
                        });
                        return;
                    }
                    setCollapseIsOpen(!collapseIsOpen);
                }}
            >
                <s.ProjectTag>
                    <Tag onClick={handleNavigateToProject} color={color}>
                        {name || "-"}
                    </Tag>
                </s.ProjectTag>
                <div style={{ width: "8rem" }}>
                    <DurationProjection value={getDurationProjection()} />
                </div>
                <s.ProgressBarColumn flex={SIZES.hours}>
                    {
                        <ProjectStatus
                            showActualPointInProgressBar
                            actualPercentage={percentageHoursDone}
                            idealPercentage={idealPercentageOfProgress}
                            projectStatus={project?.status}
                        />
                    }
                </s.ProgressBarColumn>
                {canViewCost && (
                    <s.ProgressBarColumn flex={SIZES.cost}>
                        {
                            <ProjectStatus
                                reverseColorLogic
                                showActualPointInProgressBar
                                actualPercentage={getCurrentCostPercentage()}
                                idealPercentage={percentageHoursDone}
                                projectStatus={project?.status}
                            />
                        }
                    </s.ProgressBarColumn>
                )}
                <s.ProgressBarColumn flex={SIZES.scope}>
                    {
                        <ProjectStatus
                            showActualPointInProgressBar
                            actualPercentage={scopePercentage}
                            idealPercentage={idealPercentageOfProgress}
                            projectStatus={project?.status}
                        />
                    }
                </s.ProgressBarColumn>
                <s.ProgressBarColumn flex={SIZES.actions}>
                    <Icons.Tag
                        id="project-row-edit-icon"
                        color={theme.gray800}
                        style={{ margin: "auto" }}
                        onClick={handleNavigateToProject}
                    />
                    {getProjects.loading ? (
                        <Icons.Loading color={theme.purple500} />
                    ) : (
                        <s.ExpandIcon open={collapseIsOpen} />
                    )}
                </s.ProgressBarColumn>
            </s.Row>
            <Collapse
                height="26rem"
                open={collapseIsOpen}
                style={{
                    borderBottom: !collapseIsOpen
                        ? "none"
                        : `1px solid ${theme.gray200}`,
                    position: "relative",
                }}
            >
                {projectInfo && (
                    <s.CollapseContent>
                        <s.ProjectDetails>
                            <s.Label>Nome do cliente:</s.Label>
                            <s.Value>{projectInfo.Client?.name || "-"}</s.Value>
                            <s.Label>Duração do projeto:</s.Label>
                            <s.Value>
                                {projectInfo.start_at
                                    ? formatISOToDate(start_at)
                                    : "-"}{" "}
                                -{" "}
                                {projectInfo.end_at
                                    ? formatISOToDate(end_at)
                                    : "-"}
                            </s.Value>
                            <s.Label>Status:</s.Label>
                            <s.Value>
                                {getProjectStatus(
                                    projectInfo.start_at,
                                    projectInfo.end_at,
                                    projectInfo.finished
                                )}
                            </s.Value>
                            <s.Label>Revisor:</s.Label>
                            <s.Value>
                                {projectInfo.ProjectReviewers.length
                                    ? projectInfo.ProjectReviewers.map(
                                          (reviewer) => reviewer.name
                                      ).join(", ")
                                    : "-"}
                            </s.Value>
                            <s.Label>Faturador:</s.Label>
                            <s.Value>
                                {projectInfo.ProjectBiller.length
                                    ? projectInfo.ProjectBiller.map(
                                          (reviewer) => reviewer.name
                                      ).join(", ")
                                    : "-"}
                            </s.Value>
                        </s.ProjectDetails>
                        <s.BaseDetails flex={1}>
                            <s.ThreeInfo>
                                <s.Label>Horas previstas:</s.Label>
                                <s.Value>
                                    {projectInfo.estimatedHours || "-"}
                                </s.Value>
                                <s.Label>Ideal hoje:</s.Label>
                                <s.Value>
                                    {projectInfo.estimatedHours &&
                                    projectInfo.idealPercentageOfProgress
                                        ? Math.round(
                                              projectInfo.estimatedHours *
                                                  (projectInfo.idealPercentageOfProgress /
                                                      100)
                                          )
                                        : "-"}
                                </s.Value>
                                <s.Label>Horas realizadas:</s.Label>
                                <s.Value>
                                    {projectInfo.total_time
                                        ? numberHoursToString(
                                              projectInfo.total_time / 60
                                          )
                                        : "-"}
                                </s.Value>
                            </s.ThreeInfo>
                            <HoursWarningComponent />
                        </s.BaseDetails>
                        {canViewCost && (
                            <s.BaseDetails flex={1}>
                                <s.ThreeInfo>
                                    <s.Label>Custo total previsto:</s.Label>
                                    <s.Value>
                                        {project.costs?.forecasted
                                            ? formatToBRMoney(
                                                  Number(
                                                      project.costs.forecasted
                                                  )
                                              )
                                            : ""}
                                    </s.Value>
                                    <s.Label>Ideal hoje:</s.Label>
                                    <s.Value>
                                        {projectInfo.expectedCost &&
                                        projectInfo.total_time
                                            ? formatToBRMoney(
                                                  (projectInfo.expectedCost *
                                                      percentageHoursDone) /
                                                      100
                                              )
                                            : ""}
                                    </s.Value>
                                    <s.Label>Custo total realizado:</s.Label>
                                    <s.Value style={{ minWidth: "90px" }}>
                                        {projectInfo.costs?.realized
                                            ? formatToBRMoney(
                                                  projectInfo.costs.realized
                                              )
                                            : ""}
                                    </s.Value>
                                </s.ThreeInfo>
                                <CostWarningComponent />
                            </s.BaseDetails>
                        )}

                        <s.BaseDetails flex={1}>
                            <s.ThreeInfo>
                                <s.Label>Escopo previsto:</s.Label>
                                <s.Value>100%</s.Value>
                                <s.Label>Ideal hoje:</s.Label>
                                <s.Value>
                                    {projectInfo.idealPercentageOfProgress
                                        ? Math.round(
                                              projectInfo.idealPercentageOfProgress
                                          )
                                        : "-"}
                                    %
                                </s.Value>
                                <s.Label>Escopo realizado:</s.Label>
                                <s.Value>
                                    {projectInfo.scopePercentage
                                        ? Math.round(
                                              projectInfo.scopePercentage
                                          )
                                        : "-"}
                                    %
                                </s.Value>
                            </s.ThreeInfo>
                            <ScopeWarningComponent />
                        </s.BaseDetails>
                        <div style={{ flex: SIZES.actions }}></div>
                        <SecondaryButton
                            style={{
                                position: "absolute",
                                maxWidth: "12rem",
                                gap: "1rem",
                                bottom: "1.5rem",
                                right: "1.5rem",
                            }}
                            onClick={handleNavigateToProject}
                            type="button"
                            variation="small"
                        >
                            <Icons.Tag />
                            Abrir projeto
                        </SecondaryButton>
                    </s.CollapseContent>
                )}
            </Collapse>
        </>
    );
};

export default ProjectRow;
