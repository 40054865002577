import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({
    title,
    color,
    className,
    onClick,
    width,
    height,
}: TBaseSVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || "24"}
        height={height || "24"}
        viewBox="0 0 24 24"
        fill={color || theme.white3}
    >
        <mask id="mask0_23828_306691" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill={color || theme.white3} />
        </mask>
        <g>
            <path
                d="M11.5036 21.8843C10.0408 21.8843 8.79687 21.3718 7.77187 20.3468C6.74687 19.3218 6.23438 18.0779 6.23438 16.6151V5.80737C6.23438 4.75497 6.6091 3.85405 7.35855 3.10462C8.10798 2.35519 9.0089 1.98047 10.0613 1.98047C11.1137 1.98047 12.0146 2.35519 12.7641 3.10462C13.5135 3.85405 13.8882 4.75497 13.8882 5.80737V15.5574C13.8882 16.2251 13.6577 16.7894 13.1966 17.2505C12.7355 17.7115 12.1711 17.942 11.5033 17.942C10.8356 17.942 10.2712 17.7115 9.81033 17.2505C9.34944 16.7894 9.119 16.2251 9.119 15.5574V6.40352C9.119 6.2313 9.17526 6.08888 9.28778 5.97627C9.40029 5.86367 9.5426 5.80737 9.7147 5.80737C9.88678 5.80737 10.0292 5.86367 10.142 5.97627C10.2549 6.08888 10.3113 6.2313 10.3113 6.40352V15.5574C10.3113 15.8952 10.4255 16.1784 10.6541 16.4069C10.8826 16.6355 11.1658 16.7497 11.5036 16.7497C11.8414 16.7497 12.1246 16.6355 12.3531 16.4069C12.5817 16.1784 12.6959 15.8952 12.6959 15.5574V5.80737C12.6959 5.06967 12.4412 4.44614 11.9317 3.93679C11.4221 3.42743 10.7984 3.17274 10.0605 3.17274C9.32256 3.17274 8.69911 3.42743 8.19012 3.93679C7.68116 4.44614 7.42668 5.06967 7.42668 5.80737V16.6151C7.42668 17.7407 7.82475 18.7016 8.6209 19.4978C9.41705 20.2939 10.378 20.692 11.5036 20.692C12.6293 20.692 13.5901 20.2939 14.3863 19.4978C15.1824 18.7016 15.5805 17.7407 15.5805 16.6151V6.40352C15.5805 6.2313 15.6368 6.08888 15.7493 5.97627C15.8618 5.86367 16.0041 5.80737 16.1762 5.80737C16.3483 5.80737 16.4908 5.86367 16.6036 5.97627C16.7164 6.08888 16.7728 6.2313 16.7728 6.40352V16.6151C16.7728 18.0779 16.2603 19.3218 15.2353 20.3468C14.2103 21.3718 12.9664 21.8843 11.5036 21.8843Z"
                fill={color || theme.white3}
            />
        </g>
    </svg>
);
