import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 33px;
    padding: 32px;
    width: 550px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.024px;
`;

export const Subtitle = styled.h2`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const RadioWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 1rem;

    .Label {
        color: ${({ theme }) => theme.purple500};
        font-size: 0.75rem;
        font-weight: 400;
    }
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    border-radius: 4px;
    gap: 1.2rem;
    margin-bottom: 0.5rem;

    div {
        display: flex;
        align-items: start;
        gap: 1rem;

        &:first-child {
            button {
                margin-top: 0.4rem;
            }
        }
    }
`;
export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 16px;
    height: 16px;
    border-radius: 100%;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 16px;

    &:after {
        content: "";
        width: 50%;
        height: 50%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;

export const RadioGroupItemLabel = styled.label<{ selected?: boolean }>`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.07px;
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        color: ${({ theme, selected }) =>
            selected ? theme.purple500 : theme.gray400};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        display: flex;
        align-items: center;
        min-width: 8.875rem;
        gap: 0.5rem;
    }

    span {
        color: ${({ theme, selected }) =>
            selected ? theme.purple500 : theme.gray400};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 32px;
    }
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.gray600};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10rem;
`;
