import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TValidatePassword = {
    password?: string;
};

export default async ({ password }: TValidatePassword = {}) => {
    const tempHeaders = {
        auth: true,
    };

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/password/validate`,
        method: "post",
        headers: tempHeaders,
        body: {
            password,
        },
    });
    const { body, statusCode } = httpRequest;

    const errorFeedBacks = {
        404: "senha inválida",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
