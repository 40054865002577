import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.gray50};
    border: 1px solid ${({ theme }) => theme.gray200};
    padding: 5px 10px;
    border-radius: 4px;
    color: ${({ theme }) => theme.gray900};
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    gap: 5px;
    margin-left: -20px;
`;
