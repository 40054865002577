import { theme } from "..";

type TBaseSVG = {
    color?: string;
    className?: string;
    width?: string;
    height?: string;
    onClick?: () => void;
};

export default ({ color, className, width, height }: TBaseSVG) => (
    <svg
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 26 26"
        xmlSpace="preserve"
    >
        <g>
            <path
                style={{
                    fill: color || theme.white,
                }}
                d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162
       C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8
       h-4V6h4V8z"
            />
            <path
                style={{
                    fill: color || theme.white,
                }}
                d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678
       H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037
       c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107
       L9.488,18.08L9.488,18.08z"
            />
        </g>
    </svg>
);
