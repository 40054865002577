import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";

import { Icons, theme } from "../../../assets";
import { Check } from "../../../assets/images";
import { DEFAULT_TRIAL_DAYS } from "../../../utils/constants/free-days";
import { PrimaryButton } from "../../atoms";
import * as s from "./styled-plan-cards";

type TPlanCards = {
    setIsModalBusinessOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PlanCards = ({ setIsModalBusinessOpen }: TPlanCards) => {
    const navigate = useNavigate();
    const [isAnnual, setIsAnnual] = useState(false);

    const goToRegister = () => {
        navigate("/cadastro");
        window.scrollTo(0, 0);
    };

    return (
        <s.Container>
            <div id="section5-header">
                <h2>Escolha seu plano</h2>
                <div className="toggleAnnualMode">
                    <div
                        className="planMode"
                        style={{
                            color: !isAnnual ? theme.pink600 : theme.gray600,
                        }}
                    >
                        Mensal
                    </div>
                    <s.ContainerToggle>
                        <Switch
                            width={50}
                            height={20}
                            handleDiameter={35}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={theme.pink600}
                            offColor={theme.pink600}
                            offHandleColor={"#fff"}
                            onHandleColor={"#fff"}
                            checked={isAnnual}
                            onChange={() => setIsAnnual(!isAnnual)}
                        />
                    </s.ContainerToggle>

                    <div
                        className="planMode"
                        style={{
                            color: isAnnual ? theme.pink600 : theme.gray600,
                        }}
                    >
                        Anual
                    </div>
                </div>
            </div>
            <div id="section5-cards">
                <div className="section5-card">
                    <div className="section5-card-header">
                        <strong>Lite</strong>
                        <div className="priceInformation">
                            <div className="coin">R$</div>
                            <div className="planValue">
                                <p className="price">
                                    {isAnnual ? "326" : "29"}
                                </p>
                                <div className="centsAndMonth">
                                    <div className="cents">,70</div>
                                    <div className="months">
                                        {isAnnual ? "por ano" : "por mês"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAnnual && (
                        <div className="annualPromotionInformation">
                            <Icons.IconArrowWithDirectionOption
                                direction="down"
                                height="24px"
                                width="24px"
                                color="#2E7D32"
                            />
                            <p>Use 12 meses, pague 11</p>
                        </div>
                    )}
                    <section>
                        <div className="planInformation">
                            <h1>Até 3 usuários</h1>
                            <p>
                                {isAnnual ? "Equivale a R$ 9,08" : "R$ 9,90"}
                                /mês por usuário
                            </p>
                        </div>
                        <div className="section5-card-button">
                            <PrimaryButton onClick={goToRegister}>
                                TESTE {DEFAULT_TRIAL_DAYS} DIAS GRÁTIS
                            </PrimaryButton>
                        </div>
                    </section>
                    <ul className="section5-card-list">
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Acesso a todas funcionalidades da plataforma
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Cadastro de até 3 usuários
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Suporte rápido e eficiente, com pessoas reais
                        </li>
                    </ul>
                </div>

                <div className="section5-card">
                    <div className="section5-card-header">
                        <strong>Starter</strong>
                        <div className="priceInformation">
                            <div className="coin">R$</div>
                            <div className="planValue">
                                <p className="price">
                                    {isAnnual ? "979" : "89"}
                                </p>
                                <div className="centsAndMonth">
                                    <div className="cents">,00</div>
                                    <div className="months">
                                        {isAnnual ? "por ano" : "por mês"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAnnual && (
                        <div className="annualPromotionInformation">
                            <Icons.IconArrowWithDirectionOption
                                direction="down"
                                height="24px"
                                width="24px"
                                color="#2E7D32"
                            />
                            <p>Use 12 meses, pague 11</p>
                        </div>
                    )}
                    <section>
                        <div className="planInformation">
                            <h1>Até 10 usuários</h1>
                            <p>
                                {isAnnual ? "Equivale a R$ 8,16" : "R$ 8,90"}
                                /mês por usuário
                            </p>
                        </div>
                        <div className="section5-card-button">
                            <PrimaryButton onClick={goToRegister}>
                                TESTE {DEFAULT_TRIAL_DAYS} DIAS GRÁTIS
                            </PrimaryButton>
                        </div>
                    </section>
                    <ul className="section5-card-list">
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Acesso a todas funcionalidades da plataforma
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Cadastro de até 10 usuários
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Suporte rápido e eficiente, com pessoas reais
                        </li>
                    </ul>
                </div>

                <div className="section5-card">
                    <div className="section5-card-header">
                        <strong>Standard</strong>
                        <div className="priceInformation">
                            <div className="coin">R$</div>
                            <div className="planValue">
                                <p className="price">
                                    {isAnnual ? "1738" : "158"}
                                </p>
                                <div className="centsAndMonth">
                                    <div className="cents">,00</div>
                                    <div className="months">
                                        {isAnnual ? "por ano" : "por mês"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAnnual && (
                        <div className="annualPromotionInformation">
                            <Icons.IconArrowWithDirectionOption
                                direction="down"
                                height="24px"
                                width="24px"
                                color="#2E7D32"
                            />
                            <p>Use 12 meses, pague 11</p>
                        </div>
                    )}
                    <section>
                        <div className="planInformation">
                            <h1>Até 20 usuários</h1>
                            <p>
                                {isAnnual ? "Equivale a R$ 7,24" : "R$ 7,90"}
                                /mês por usuário
                            </p>
                        </div>
                        <div className="section5-card-button">
                            <PrimaryButton onClick={goToRegister}>
                                TESTE {DEFAULT_TRIAL_DAYS} DIAS GRÁTIS
                            </PrimaryButton>
                        </div>
                    </section>
                    <ul className="section5-card-list">
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Acesso a todas funcionalidades da plataforma
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Cadastro de até 20 usuários
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Suporte rápido e eficiente, com pessoas reais
                        </li>
                    </ul>
                </div>

                <div className="section5-card">
                    <div className="section5-card-header">
                        <strong>Business</strong>
                        <div className="information">
                            Entre em contato com a <span>equipe de vendas</span>{" "}
                            para conhecer os preços.
                        </div>
                    </div>
                    {isAnnual && (
                        <div className="annualPromotionInformation">
                            <div
                                style={{
                                    opacity: 0,
                                }}
                            >
                                -
                            </div>
                            <p></p>
                        </div>
                    )}
                    <section>
                        <div className="planInformation">
                            <h1>A partir de 21 usuários</h1>
                        </div>
                        <div className="section5-card-button">
                            <PrimaryButton
                                onClick={() => setIsModalBusinessOpen(true)}
                            >
                                ENTRE EM CONTATO
                            </PrimaryButton>
                        </div>
                    </section>
                    <ul className="section5-card-list">
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Acesso a todas funcionalidades da plataforma
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Quantidade de usuários cadastrados personalizado
                        </li>
                        <li>
                            <img
                                src={Check}
                                id="check-plan"
                                alt="Imagem de um check, ao lado uma descrição do que o plano tem a oferecer"
                            />
                            Suporte rápido e eficiente, com pessoas reais
                        </li>
                    </ul>
                </div>
            </div>
        </s.Container>
    );
};

export default PlanCards;
