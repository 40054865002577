import {
    DialogContent,
    DialogOverlay,
    DialogClose,
} from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";

const overlayShow = keyframes`
    from{ opacity: 0 };
    to { opacity: .7 };
`;

const contentShow = keyframes`
    from { opacity: 0; transform: translate(-50%, -48%) scale(.96) };
    to { opacity: 1; transform: translate(-50%, -50%) scale(1) };
    `;

export const Content = styled(DialogContent)`
    z-index: 999999999999999999999;
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (prefers-reduced-motion: no-preference) {
        animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    :focus {
        outline: "none";
    }
`;

export const Overlay = styled(DialogOverlay)`
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    inset: 0;
    @media (prefers-reduced-motion: no-preference) {
        animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    }
`;

export const RadixClose = styled(DialogClose)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

export const Flex = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
`;
