import { UseMutationResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Select, {
    ActionMeta,
    GroupBase,
    MenuListProps,
    SingleValue,
    components,
} from "react-select";

import { Icons, theme } from "../../../../assets";
import { useUser } from "../../../../context";
import { TSectionsActivitiesOption } from "../../../../pages/track/track";
import {
    CreateActivityError,
    CreateActivityVariables,
    CreateActivityResponse,
} from "../../../../services/activities/types";
import { validatePermittedStrings } from "../../../../utils/validation";
import { TProjectList } from "../../../molecules/popovers/popover-project/popover-project";
import Tooltip from "../../tooltip/tooltip";
import * as s from "./styled-select-activity";

type TSelect = {
    error?: string;
    options?: TSectionsActivitiesOption[];
    selectedActivity?: TSectionsActivitiesOption;
    onChange?: (
        newValue: SingleValue<TSectionsActivitiesOption>,
        actionMeta: ActionMeta<TSectionsActivitiesOption>
    ) => void;
    disabled?: boolean;
    createActivity: UseMutationResult<
        CreateActivityResponse,
        CreateActivityError,
        CreateActivityVariables,
        unknown
    >;
    project: TProjectList | undefined;
    isTimeEntryRunning?: boolean;
    type: "create" | "edit";
    setEditIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectActivity = ({
    error,
    options,
    onChange,
    disabled,
    selectedActivity,
    isTimeEntryRunning,
    createActivity,
    project,
    type,
    setEditIsValid,
}: TSelect) => {
    const [pageSize, setPageSize] = useState(15);

    const { favoriteActivity } = useUser();

    const isLastPage = () => {
        const optionsLength =
            options?.reduce((acc, o) => acc + (o.options?.length || 0), 0) || 0;
        return pageSize >= optionsLength;
    };

    const [inputValue, setInputValue] = useState("");
    const [paginatedOptions, setPaginatedOptions] = useState<
        TSectionsActivitiesOption[] | undefined
    >([]);

    const handleAddActivity = () => {
        if (!inputValue) return;
        const isValidDescription = validatePermittedStrings(inputValue);
        if (!isValidDescription) {
            return;
        }
        createActivity.mutate({
            name: inputValue,
            projectId: project?.id_e,
        });
    };

    const formatOptionLabel = (
        option: TSectionsActivitiesOption,
        { inputValue }: { inputValue: string }
    ) => {
        const sanitizedInputValue = inputValue.replace(
            /[.*+?^${}()|[\]\\]/g,
            "\\$&"
        );
        const parts = option?.label?.split(
            new RegExp(`(${sanitizedInputValue})`, "gi")
        );
        const isFavorite = option.value === favoriteActivity?.id_e;
        return (
            <s.OptionRow lastTask={option.lastTask}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    {isFavorite && (
                        <Icons.Star
                            width="15px"
                            height="15px"
                            fill={theme.gray400}
                            color={theme.gray400}
                        />
                    )}
                    {!isFavorite && option.lastTask && (
                        <div
                            style={{
                                minWidth: "24px",
                            }}
                        >
                            <Icons.History color={theme.gray600} />
                        </div>
                    )}
                    {parts && (
                        <div>
                            {parts?.map((part: string, index: number) =>
                                inputValue &&
                                part
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ? (
                                    <s.SearchPart match key={index}>
                                        {part}
                                    </s.SearchPart>
                                ) : (
                                    <s.SearchPart key={index}>
                                        {part}
                                    </s.SearchPart>
                                )
                            )}
                        </div>
                    )}
                </div>
                <s.Section>{option.section}</s.Section>
            </s.OptionRow>
        );
    };

    const CustomMenuList = (
        props: MenuListProps<
            TSectionsActivitiesOption,
            false,
            GroupBase<TSectionsActivitiesOption>
        >
    ) => {
        return (
            <components.MenuList {...props}>
                {props.children}
                {inputValue && (
                    <s.AddActivityButton
                        type="button"
                        onClick={handleAddActivity}
                    >
                        {createActivity.isLoading ? (
                            <Icons.Loading
                                color={theme.purple500}
                                width="18px"
                                height="18px"
                            />
                        ) : (
                            <Icons.Plus />
                        )}
                        Adicionar atividade: <strong>{inputValue}</strong>
                    </s.AddActivityButton>
                )}
            </components.MenuList>
        );
    };

    useEffect(() => {
        if (paginatedOptions?.length) {
            const elements = document.getElementsByClassName(
                "select-activity__option"
            );
            if (elements.length <= 5) return;
            const lastOption = elements[elements.length - 5];
            lastOption?.scrollIntoView({
                behavior: "auto",
                block: "nearest",
                inline: "start",
            });
        }
    }, [paginatedOptions]);

    useEffect(() => {
        if (!options) return;
        const paginated = options.map((option) => {
            const result = option?.options?.filter((o, index) => {
                if (inputValue && o?.label?.includes(inputValue)) return true;
                if (index >= pageSize) return false;
                return true;
            });
            return {
                ...option,
                options: result,
            };
        });
        setPaginatedOptions(paginated);
    }, [options, pageSize, inputValue]);

    return (
        <s.FlexColumn
            className="select-input"
            creating={type === "create"}
            onClick={(e) => e.stopPropagation()}
        >
            <Tooltip
                side="top"
                align={"center"}
                positiony={15}
                content={
                    isTimeEntryRunning ? (
                        <s.SmallP>
                            Para editar uma atividade, certifique-se de que a
                            <br />
                            entrada de tempo não esteja em andamento.
                            <br />
                            por favor, encerre-a antes de fazer alterações.
                        </s.SmallP>
                    ) : (
                        <></>
                    )
                }
            >
                <div>
                    <s.Flex
                        style={{
                            pointerEvents: isTimeEntryRunning ? "none" : "auto",
                        }}
                        error={!!error}
                    >
                        <Select
                            key={selectedActivity?.value}
                            placeholder={"Selecione ou crie uma atividade..."}
                            name={"select-activity"}
                            onChange={onChange}
                            options={paginatedOptions}
                            isDisabled={disabled}
                            onMenuScrollToBottom={() => {
                                if (isLastPage()) return;
                                setPageSize(pageSize + 15);
                            }}
                            value={selectedActivity}
                            className={"select-activity"}
                            classNamePrefix={"select-activity"}
                            captureMenuScroll
                            noOptionsMessage={() =>
                                inputValue
                                    ? "Nenhuma atividade foi encontrada com este nome."
                                    : "Nenhuma atividade foi encontrada."
                            }
                            menuPlacement={"auto"}
                            styles={{
                                menu: (base) => ({
                                    ...base,
                                }),
                                menuList: (base) => ({
                                    ...base,
                                    maxHeight: "400px !important",
                                }),
                                groupHeading: (base) => ({
                                    ...base,
                                    color: theme.gray400,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    letterSpacing: "0.14px",
                                    textTransform: "none",
                                    padding: "0px 16px",
                                    paddingBottom: "8px",
                                }),
                                option: (base, { isSelected }) => ({
                                    ...base,
                                    "&:hover": {
                                        backgroundColor: theme.gray50,
                                    },
                                    backgroundColor: isSelected
                                        ? theme.gray100
                                        : "",
                                    color: isSelected
                                        ? theme.gray900
                                        : theme.gray600,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    paddingLeft: "32px",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: theme.gray400,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                }),
                                input: (base) => ({
                                    ...base,
                                    color: theme.gray600,
                                    fontSize: "20px",
                                    fontWeight: 400,
                                }),
                                noOptionsMessage: (base) => ({
                                    ...base,
                                    color: theme.gray400,
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    textAlign: "left",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    color: theme.gray600,
                                    fontSize: "20px",
                                    fontWeight: 400,
                                }),
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: isTimeEntryRunning
                                        ? "hsl(0, 0%, 90%)"
                                        : base.backgroundColor,
                                }),
                            }}
                            onInputChange={(value) => {
                                setInputValue(value);
                                if (!value) {
                                    return;
                                }
                                if (selectedActivity?.label !== value) {
                                    setEditIsValid?.(false);
                                }
                            }}
                            defaultInputValue={selectedActivity?.label}
                            formatOptionLabel={formatOptionLabel}
                            components={{ MenuList: CustomMenuList }}
                        />
                    </s.Flex>
                </div>
            </Tooltip>
        </s.FlexColumn>
    );
};

export default SelectActivity;
