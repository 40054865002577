import { TSection } from "../../pages/organization/project/tab-activities/types";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type TUpdateSubItem = {
    subtaskId?: string;
    checklistId?: string;
    position?: number;
};

export default async ({
    subtaskId,
    checklistId,
    position,
}: TUpdateSubItem = {}) => {
    const headers = {
        "x-subtask-id": subtaskId,
        "x-checklist-id": checklistId,
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/activity/subtask/updatePosition`,
        method: "put",
        body: {
            position,
        },
        headers,
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases<TSection>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
