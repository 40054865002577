import { TSubmitScore } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ rating, comment, noAnswer }: TSubmitScore = {}) => {
    const tempBody = {
        rating,
        comment,
    };

    const tempParams = {
        noAnswer,
    };

    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/nps/answer`,
        method: "post",
        body: tempBody,
        params: tempParams,
        headers: tempHeaders,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Erro ao enviar pesquisa.",
        404: "Essa pesquisa não existe.",
        409: "Essa pesquisa já foi respondida.",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
