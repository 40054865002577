export const UseTerms = (
    <a
        href="https://prod-paperon.s3.eu-central-1.amazonaws.com/TERMOS_DE_USO_-_PAPER_ON_15_05_2023.pdf"
        target="_blank"
        rel="noopener noreferer"
        id="termos"
    >
        Termos de uso
    </a>
);

export const PrivacyPolicy = (
    <a
        href="https://prod-paperon.s3.eu-central-1.amazonaws.com/POLITICA_DE_PRIVACIDADE_-_PAPERON_15_05_2023.pdf"
        target="_blank"
        rel="noopener noreferer"
    >
        Políticas de Privacidade
    </a>
);
