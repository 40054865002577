import styled from "styled-components";

export const Timer = styled.span`
    border: 1px solid var(--color-gray-200, #cfcdd3);
    border-radius: 4px;
    padding: 8px 12px;
    @media (max-width: 768px) {
        font-size: 15px;
    }

    span#seconds {
        color: ${({ theme }) => theme.gray200};
    }
`;
