import { Dispatch, SetStateAction } from "react";

import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styled-modal-send-report";

type TModalSendReport = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    resend?: boolean;
    onClick?: () => void;
};

const ModalSendReport = ({ setOpen, resend, onClick }: TModalSendReport) => {
    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container>
                <span id="modal-title">
                    {resend
                        ? "Reenviar relatório"
                        : "Fechar e enviar relatório"}
                </span>

                {resend ? (
                    <span id="modal-description">
                        Certifique-se de que todos os erros estejam corrigidos
                        antes de reenviar o relatório.
                        <br />
                        <br />
                        Deseja reenviar o relatório para revisão?
                    </span>
                ) : (
                    <span id="modal-description">
                        Ao fechar o relatório você concorda com as informações
                        presentes neste documento e estará enviando para
                        revisão, depois de fechado não poderá editá-lo.
                        <br />
                        <br />
                        Deseja fechar e enviar relatório para revisão?
                    </span>
                )}

                <div id="buttons">
                    <SecondaryButton onClick={() => setOpen(false)}>
                        VOLTAR
                    </SecondaryButton>

                    <PrimaryButton onClick={onClick}>
                        {resend ? "SIM, REENVIAR" : "SIM, FECHAR E ENVIAR"}
                    </PrimaryButton>
                </div>
            </s.Container>
        </ContainerModal>
    );
};

export default ModalSendReport;
