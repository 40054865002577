import styled from "styled-components";

export const Container = styled.div``;

export const TooltipContent = styled.div`
    width: 250px;
    padding: 12px;
    font-size: 13px;
    color: ${({ theme }) => theme.gray700};
`;
