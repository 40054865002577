import { Icons } from "../../../assets";
import Tooltip from "../tooltip/tooltip";
import * as s from "./styled-hide-information";

type THideInformation = {
    isToShowInformation: boolean;
    children: React.ReactNode;
};

export const HideInformation = ({
    isToShowInformation,
    children,
}: THideInformation) => {
    return (
        <>
            {isToShowInformation ? (
                children
            ) : (
                <Tooltip
                    content={<s.Tooltip>Essa informação está oculta</s.Tooltip>}
                >
                    <s.Container>
                        <Icons.iconCircle />
                        <Icons.iconCircle />
                        <Icons.iconCircle />
                    </s.Container>
                </Tooltip>
            )}
        </>
    );
};
