import styled from "styled-components";

export const Container = styled.div`
    padding: 1rem;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    gap: 1rem;
    z-index: 9999;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    width: 240px;
    margin-top: 10px;
    border-radius: 4px;

    .input {
        margin-top: 4px;
        height: 28px;
    }
`;

export const Title = styled.section`
    color: ${({ theme }) => theme.purple400};
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.025px;
    text-transform: capitalize;
`;
