import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    width,
    height,
}: TBaseSVG) => (
    <svg
        width={width || "24px"}
        height={height || "24px"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-bug"}
        onClick={onClick}
    >
        <title>{title}</title>
        <path
            d="M12 19C9.23858 19 7 16.7614 7 14M12 19C14.7614 19 17 16.7614 17 14M12 19V14M7 14V11.8571C7 11.0592 7 10.6602 7.11223 10.3394C7.31326 9.76495 7.76495 9.31326 8.33944 9.11223C8.66019 9 9.05917 9 9.85714 9H14.1429C14.9408 9 15.3398 9 15.6606 9.11223C16.2351 9.31326 16.6867 9.76495 16.8878 10.3394C17 10.6602 17 11.0592 17 11.8571V14M7 14H4M17 14H20M17 10L19.5 7.5M4.5 20.5L8 17M7 10L4.5 7.5M19.5 20.5L16 17M14 6V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V6H14Z"
            stroke={color || theme.gray600}
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
