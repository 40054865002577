import { Outlet, useLocation } from "react-router-dom";

import { Breadcrumb, OrganizationOptions } from "../../components";
import { TutorialsPopover } from "../../components/molecules/tutorials-popover/tutorials-popover";
import { useUser } from "../../context";
import { redirectDefaultRoute } from "../../utils";
import * as s from "./styled-organization";

export const Organization = () => {
    const location = useLocation();
    const { permissions, verifyPermission } = useUser();
    const manageProjectsSectorsPermission = verifyPermission({
        id: "projects_sectors",
        rule: "manage",
    });
    const manageRolesPermission = verifyPermission({
        id: "roles_permissions",
        rule: "manage",
    });

    const isToDisableCreateButton = () => {
        if (
            location.pathname === "/organizacao/setores" &&
            !manageProjectsSectorsPermission
        )
            return true;
        if (
            location.pathname === "/organizacao/atuacoes" &&
            !manageRolesPermission
        )
            return true;
        if (location.pathname === "/organizacao/clientes") return true;

        return false;
    };

    const routeNames = {
        setores: "setores",
        atuacoes: "atuações",
    };

    const availableTutorialsRoutes = [
        "/organizacao/setores",
        "/organizacao/atuacoes",
    ];

    const getTutorials = () => {
        if (location.pathname === "/organizacao/setores") {
            return [
                {
                    url: "xyhaqqYk6g",
                    label: "Criar setor",
                },
            ];
        }
        if (location.pathname === "/organizacao/atuacoes") {
            return [
                {
                    url: "EJKIlajftSw",
                    label: "Criar atuação",
                },
            ];
        }
        return [];
    };

    return (
        <s.Container>
            <Breadcrumb
                defaultRoute={
                    !manageProjectsSectorsPermission
                        ? "/projetos"
                        : `/organizacao${redirectDefaultRoute(permissions)}`
                }
                location={location}
            />

            {!location.pathname.match(/criar/i) &&
                !location.pathname.match(/editar/i) &&
                !location.pathname.match(/visualizar/i) &&
                !location.pathname.match("/demonstrativos") &&
                !location.pathname.match("/projetos") && (
                    <OrganizationOptions
                        disableButton={isToDisableCreateButton()}
                    />
                )}
            {availableTutorialsRoutes.includes(location.pathname) && (
                <TutorialsPopover
                    screen={
                        routeNames[
                            location.pathname.split(
                                "/"
                            )[2] as keyof typeof routeNames
                        ]
                    }
                    tutorials={getTutorials()}
                    className="tutorials-popover"
                />
            )}

            <Outlet />
        </s.Container>
    );
};
