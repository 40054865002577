import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 1424px;
    padding: 40px 100px 57px 100px;
    height: 100%;
    overflow-y: auto;

    .toggleAnnualMode {
        display: flex;
        flex-direction: row;
        gap: 8px;
        justify-content: center;
        align-items: center;

        .planMode {
            color: ${({ theme }) => theme.gray400};
            font-size: 28px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 0.042px;
        }
    }

    #section5-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 24px;
        position: relative;

        .settings {
            position: absolute;
            right: 0;
            cursor: pointer;
        }

        #knowThePlans {
            color: ${({ theme }) => theme.purple600};
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.4px;
        }
        h1 {
            color: ${({ theme }) => theme.purple600};
            text-align: center;
            font-size: 26px;
            font-weight: 700;
            letter-spacing: -0.56px;
        }
        h2 {
            color: ${({ theme }) => theme.purple600};
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.4px;
        }
        p {
            font-weight: 400;
            font-size: 1.125rem;
            color: ${({ theme }) => theme.gray800};
        }
        text-align: center;
    }

    #section5-cards {
        display: flex;
        gap: 23px;
        justify-content: center;
        align-items: flex-start;
        height: 100%;

        .section5-card {
            display: flex;
            flex-direction: column;
            width: 288px;
            position: relative;
            min-width: 288px;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 0;
            border-radius: 8px;
            box-shadow: 0px -10px 0px ${({ theme }) => theme.purple200};
            background-color: ${({ theme }) => theme.white};
            height: 600px;

            .annualPromotionInformation {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                p {
                    color: #2e7d32;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
            .section5-card-header {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 45px;
                gap: 15px;
                height: 150px;
                width: 240px;

                strong {
                    font-weight: 600;
                    font-size: 20px;
                    color: ${({ theme }) => theme.purple300};
                }

                .information {
                    color: ${({ theme }) => theme.gray800};
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    span {
                        text-decoration-line: underline;
                    }
                }

                .priceInformation {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: baseline;

                    .coin {
                        display: flex;
                        justify-content: center;
                        align-items: end;
                        height: 100%;
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    }

                    .planValue {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .price {
                            color: ${({ theme }) => theme.gray800};
                            text-align: right;
                            font-size: 48px;
                            font-weight: 700;
                            line-height: 26px;
                            height: 100%;
                        }

                        .centsAndMonth {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            justify-content: start;
                            align-items: start;

                            .cents {
                                color: ${({ theme }) => theme.gray800};
                                text-align: center;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px;
                            }

                            .months {
                                color: ${({ theme }) => theme.gray400};
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }

            section {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 15px;
                padding-top: 20px;

                .planInformation {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 4px;
                    height: 70px;
                    h1 {
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                    }
                    p {
                        color: ${({ theme }) => theme.gray400};
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }

                .section5-card-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    padding: 0 2.9375rem;
                    margin-bottom: 20px;

                    .extrapolatedUsers {
                        color: #d32f2f;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.12px;

                        span {
                            text-decoration-line: underline;
                        }
                    }

                    .your-plan {
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.12px;
                    }

                    .inTrial {
                        color: ${({ theme }) => theme.gray800};
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0.12px;
                        align-self: stretch;
                    }

                    button {
                        border-radius: 4px;
                        width: 240px;
                        text-transform: none;
                        color: var(--color-neutral-white-02, #fcfcfc);
                        text-align: center;
                    }
                }
            }

            .section5-card-list {
                display: flex;
                flex-direction: column;
                justify-content: start;
                padding: 0 1rem;
                list-style: none;
                gap: 14px;
                padding-bottom: 40px;
                padding-top: 20px;
                border-top: 1px solid #e7e6e9;

                svg {
                    width: 24px;
                    height: 24px;
                }

                li {
                    display: flex;
                    justify-content: start;
                    gap: 18px;
                    align-items: center;
                    flex-direction: row;
                    color: ${({ theme }) => theme.gray800};
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }

            .section5-card-footer {
                display: flex;
                gap: 0.3125rem;
                justify-content: center;
                align-items: center;
                padding: 1.375rem 0;
                border-radius: 0 0 8px 8px;
                background-color: ${({ theme }) => theme.purple300};
                color: ${({ theme }) => theme.white};
                font-size: 1.125rem;
                line-height: 2.0625rem;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 1900px) {
        width: 1124px;
        gap: 20px;
        padding: 20px 80px 35px 80px;

        .toggleAnnualMode {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            align-items: center;

            .planMode {
                color: ${({ theme }) => theme.gray400};
                font-size: 20px;
                font-weight: 700;
                line-height: 0px;
                letter-spacing: 0.042px;
            }
        }

        #section5-header {
            gap: 12px;

            #knowThePlans {
                font-size: 16px;
            }
            h1 {
                font-size: 18px;
            }
            h2 {
                font-size: 12px;
            }
            p {
                font-size: 8px;
            }
        }

        #section5-cards {
            gap: 21px;

            .section5-card {
                width: 230px;
                min-width: 230px;
                gap: 10px;
                height: 500px;

                .annualPromotionInformation {
                    margin-top: 10px;
                    p {
                        font-size: 10px;
                    }
                }
                .section5-card-header {
                    gap: 10px;
                    height: 150px;
                    width: 190px;
                    height: 90px;
                    padding-top: 40px;

                    strong {
                        font-size: 14px;
                    }

                    .information {
                        font-size: 10px;
                        line-height: 18px;
                    }

                    .priceInformation {
                        .coin {
                            font-size: 12px;
                            line-height: 24px;
                        }

                        .planValue {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .price {
                                font-size: 44px;
                                line-height: 24px;
                            }

                            .centsAndMonth {
                                .cents {
                                    font-size: 12px;
                                    line-height: 24px;
                                }

                                .months {
                                    font-size: 10px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }

                section {
                    gap: 6px;
                    padding-top: 10px;
                    width: 100%;

                    .planInformation {
                        gap: 2px;
                        height: 60px;
                        h1 {
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 24px;
                        }
                        p {
                            font-size: 10px;
                            line-height: 24px;
                        }
                    }

                    .section5-card-button {
                        padding: 0 1rem;
                        margin-bottom: 20px;
                        width: 100%;

                        .extrapolatedUsers {
                            font-size: 10px;
                            line-height: 14px;
                        }

                        .inTrial {
                            font-size: 10px;
                            line-height: 14px;
                        }

                        button {
                            height: 40px;
                            font-size: 10px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }
                }

                .section5-card-list {
                    padding: 0 0.5rem;
                    gap: 10px;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }

                    li {
                        gap: 18px;
                        font-size: 10px;
                        line-height: 18px;
                    }
                }

                .section5-card-footer {
                    gap: 0.3125rem;
                    padding: 1.375rem 0;
                    font-size: 12px;
                    line-height: 31px;
                }
            }
        }
    }
`;

export const ContainerToggle = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    border-radius: 24px;
    padding-left: 0.3rem;
    padding-right: 0.3;

    div > div.react-switch-handle {
        box-shadow: 0px 1.6px 2px rgba(0, 0, 0, 0.2);
    }
`;

export const Tooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px;
    max-width: 240px;
`;
