import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <g id="arrow-trending-up">
            <path
                id="Vector"
                d="M2.25 18.0001L9 11.2501L13.306 15.5571C14.5507 13.1029 16.6044 11.1535 19.12 10.0381L21.86 8.81809M21.86 8.81809L15.92 6.53809M21.86 8.81809L19.58 14.7591"
                stroke={color || theme.gray600}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);
