import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 9px rgba(8, 1, 23, 0.2);
    background-color: ${({ theme }) => theme.white};
`;

export const ContainerBlocks = styled.div`
    display: grid;
    grid-template-columns: 28px 28px 28px 28px 28px;
    grid-auto-rows: 20px;
`;

export const BlockColor = styled.button`
    width: 100%;
    height: 100%;
    &:focus {
        border: 2px solid ${({ theme }) => theme.white};
    }
    &:hover {
        transform: scale(1.1);
    }
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.purple600};
`;
