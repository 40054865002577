import { Text, View, Image, Svg, Circle } from "@react-pdf/renderer";

import { PurpleLogo, theme } from "../../../../assets";
import { minutesToStringDate } from "../../../../utils";
import PDFTemplate, { TPDFData } from "../pdf-template";
import * as s from "../styled-pdf-template";

type TCollaboratorResume = TPDFData & {
    name?: string;
    period?: string;
    totalTime?: string;
};

const CollaboratorResume = ({
    pdfData,
    name,
    period,
    totalTime,
}: TCollaboratorResume) => {
    return (
        <PDFTemplate>
            <View style={s.PDF.Header}>
                <Text style={s.PDF.Title}>Relatório de Profissional</Text>

                <Image src={PurpleLogo} style={s.PDF.ImageStyle} fixed />
            </View>

            <View style={s.PDF.Project}>
                <View style={s.PDF.ProjectHeader}>
                    <View
                        style={[
                            s.PDF.HeaderColumn,
                            {
                                width: "50%",
                            },
                        ]}
                    >
                        <Text style={s.PDF.TableHeaderText}>NOME</Text>
                        <Text style={s.PDF.HeaderValueText}>{name}</Text>
                    </View>

                    <View
                        style={[
                            s.PDF.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray50}`,
                                width: "30%",
                            },
                        ]}
                    >
                        <Text style={s.PDF.TableHeaderText}>PERÍODO</Text>
                        <Text style={s.PDF.HeaderValueText}>{period}</Text>
                    </View>

                    <View
                        style={[
                            s.PDF.HeaderColumn,
                            {
                                borderLeft: `1px solid ${theme.gray50}`,
                                width: "20%",
                            },
                        ]}
                    >
                        <Text style={s.PDF.TableHeaderText}>
                            TOTAL DE HORAS
                        </Text>
                        <Text style={s.PDF.HeaderValueText}>{totalTime}</Text>
                    </View>
                </View>
            </View>

            <Text style={s.PDF.SectionText}>Horas por dia</Text>
            <Image
                src={pdfData?.barChartImg}
                style={{ width: "100%", marginBottom: 25 }}
            />

            <Text style={s.PDF.SectionText}>Tempo por projeto</Text>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    border: `1px solid ${theme.gray50}`,
                    marginTop: 5,
                }}
            >
                <Image
                    src={pdfData?.doughnutChartImg}
                    style={{ width: 125, height: 125 }}
                />

                <View
                    style={{
                        width: 420.5,
                        borderLeft: `1px solid ${theme.gray50}`,
                    }}
                >
                    <View style={s.PDF.TableHeader} wrap={false}>
                        <View style={s.PDF.ActivityColumn}>
                            <Text style={s.PDF.TableHeaderText}>ATIVIDADE</Text>
                        </View>
                        <View style={s.PDF.CentralizedColumn}>
                            <Text style={s.PDF.TableHeaderText}>DURAÇÃO</Text>
                        </View>
                        <View style={s.PDF.CentralizedColumn}>
                            <Text style={s.PDF.TableHeaderText}>
                                PORCENTAGEM
                            </Text>
                        </View>
                    </View>

                    {pdfData?.projects?.map((project, index) => (
                        <View
                            style={s.PDF.TableRow}
                            key={index}
                            wrap={false}
                            id={"activity"}
                        >
                            <View style={s.PDF.ActivityColumn}>
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <View
                                        style={[
                                            s.PDF.ProjectTag,
                                            {
                                                backgroundColor:
                                                    project.project_color
                                                        ? `${project.project_color}0D`
                                                        : `${theme.purple500}0D`,
                                            },
                                        ]}
                                    >
                                        <Svg
                                            viewBox="0 0 100 100"
                                            width="10"
                                            height="10"
                                        >
                                            <Circle
                                                cx="50"
                                                cy="50"
                                                r="20"
                                                fill={
                                                    project.project_color
                                                        ? project.project_color
                                                        : theme.purple500
                                                }
                                            />
                                        </Svg>

                                        <Text
                                            style={[
                                                s.PDF.ProjectName,
                                                {
                                                    color: project.project_color
                                                        ? project.project_color
                                                        : theme.purple500,
                                                },
                                            ]}
                                        >
                                            {project.project_name}
                                        </Text>
                                    </View>

                                    {/* <Text style={s.PDF.ClientName}>
                                        Cliente {index + 1}
                                    </Text> */}
                                </View>
                            </View>

                            <View style={s.PDF.CentralizedColumn}>
                                <Text style={s.PDF.TableBodyText}>
                                    {minutesToStringDate(project.project_time)}
                                </Text>
                            </View>

                            <View style={s.PDF.CentralizedColumn}>
                                <Text style={s.PDF.TableBodyText}>
                                    {project.project_percent}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </PDFTemplate>
    );
};

export default CollaboratorResume;
