import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    width,
    height,
    style,
}: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width={width || "24"}
            height={height || "24"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-filler"}
            style={style}
        >
            <title>{title}</title>
            <g id="filter-preenchido">
                <path
                    id="Vector"
                    d="M3 4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V6.586C20.9999 6.85119 20.8946 7.10551 20.707 7.293L14.293 13.707C14.1055 13.8945 14.0001 14.1488 14 14.414V17L10 21V14.414C9.99994 14.1488 9.89455 13.8945 9.707 13.707L3.293 7.293C3.10545 7.10551 3.00006 6.85119 3 6.586V4Z"
                    fill={color || theme.gray600}
                    stroke={color || theme.gray600}
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
