import * as RadioGroup from "@radix-ui/react-radio-group";
import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 34px 47px;
`;

export const SettingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 582px;
`;

export const SettingDescription = styled.div<{ selected?: boolean }>`
    color: ${({ theme, selected }) =>
        selected ? theme.purple500 : theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 25px;
    margin-top: 6px;
`;

export const CollaboratorsReport = styled.div`
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    background: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`;

export const SettingTitle = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.024px;
`;

export const RadioGroupRoot = styled(RadioGroup.Root)`
    min-width: 8.875rem;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.white};
    padding: 0.875rem;
    border-radius: 4px;
    gap: 0.5rem;

    div {
        cursor: pointer;
        padding: 5px;
        border-radius: 4px;

        &:hover {
            background: ${({ theme }) => theme.gray50};
        }
    }
`;

export const RadioWrapper = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    label {
        color: ${({ theme, selected }) =>
            selected ? theme.purple500 : theme.gray400};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
    width: 17px;
    height: 17px;
    border-radius: 50px;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.purple500};
`;

export const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    position: relative;

    &:after {
        content: "";
        width: 60%;
        height: 60%;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.purple500};
    }
`;

export const ReceiveEmailsContainer = styled.div`
    display: flex;
    gap: 16px;
    background: ${({ theme }) => theme.white};
    padding: 16px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray100};
`;

export const ReceiveEmailsText = styled.div`
    color: ${({ theme }) => theme.gray800};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const ReceiveEmailsSubText = styled.div`
    color: ${({ theme }) => theme.gray400};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.12px;
    margin-top: 5px;
`;

export const RightSideSettings = styled.div`
    justify-self: flex-end;
    align-self: flex-start;

    button {
        color: ${({ theme }) => theme.redError};
    }
`;

export const Tooltip = styled.div`
    color: ${({ theme }) => theme.gray600};
    font-size: 11px;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 8px;
    max-width: 240px;
`;
