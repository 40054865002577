import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Icons } from "../../../../assets";
import { TProject, optionsPeriods } from "../../../../models";
import { projects } from "../../../../services";
import { ReadDemonstrativeResult } from "../../../../services/projects/types";
import { HoursChart } from "./components/hours-chart/hours-chart";
import { ProjectInformation } from "./components/project-information/project-information";
import { Projections } from "./components/projections/projections";
import { TeamInformation } from "./components/team-information/team-information";
import { ValuesInformation } from "./components/values-information/values-information";
import * as s from "./styles";

export type Dates = {
    startAt: string | undefined;
    endAt: string | undefined;
};

type TabDemonstrativeProps = {
    project: TProject;
};

export const TabDemonstrative = ({ project }: TabDemonstrativeProps) => {
    const defaultPeriod = optionsPeriods[4].value;
    const [dates, setDates] = useState<Dates>({
        startAt: defaultPeriod.from.toISOString(),
        endAt: defaultPeriod.to.toISOString(),
    });

    const {
        data: demonstrative,
        refetch,
        isLoading,
    } = useQuery<ReadDemonstrativeResult>({
        queryFn: () =>
            projects.readDemonstrative({ projectId: project.id_e, ...dates }),
        enabled: false,
    });

    useEffect(() => {
        refetch();
    }, [dates]);

    if (isLoading) return <Icons.LoadingSpinCircle />;

    return (
        <s.Container>
            <ProjectInformation data={demonstrative} project={project} />
            <s.Divider />
            <ValuesInformation
                data={demonstrative}
                project={project}
                setDates={setDates}
            />
            <HoursChart project={project} data={demonstrative} />
            <Projections data={demonstrative} project={project} />
            <s.Divider />
            <TeamInformation users={demonstrative?.users} />
        </s.Container>
    );
};
