import styled from "styled-components";

import { TPriority } from "./types";

export const Content = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    top: 2.9375rem;
    z-index: -10;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: ${({ theme }) => theme.white} !important;
`;

export const ActivityHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    padding-top: 40px;
    width: 100%;
    background-color: ${({ theme }) => theme.white} !important;

    button {
        width: 200px;
    }
`;

export const ActivitySubHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 1.5rem;

    button {
        display: flex;
        align-items: center;
    }
`;

export const ActivityList = styled.div`
    &:first-child {
        width: 100%;
    }
`;

export const ActivityListHeader = styled.div`
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 35% 16.5% 10% 16.4% 23.1%;

    @media (min-width: 1500px) {
        grid-template-columns: 35% 16.5% 10% 11.4% 23.1%;
    }

    width: 100%;
    gap: 1%;
    align-items: center;
    height: 4.5rem;
    padding: 0 71px;
    border-top: 1px solid ${({ theme }) => theme.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    color: ${({ theme }) => theme.gray600};
    font-weight: 600;
    font-size: 14px;
    line-height: 1.125rem;
    letter-spacing: 1.12px;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const UserListFilter = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(14, 2, 26, 0.2);
    height: 300px;
    overflow-y: scroll;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        padding: 8px;
        border-radius: 8px;
        color: ${({ theme }) => theme.gray800};
        height: 54px;

        font-size: 14px;
        line-height: 24px;

        img {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            object-fit: cover;
        }

        &:hover {
            background-color: ${({ theme }) => theme.gray50};
        }
    }
`;

export const ActivityListBody = styled.div`
    height: 100%;
`;

export const Priority = styled.div<{ type?: TPriority }>`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.07px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    color: ${({ theme, type }) => {
        switch (type) {
            case "high":
                return "#D32F2F";
            case "medium":
                return theme.orange;
            case "low":
                return theme.green1;
            default:
                return theme.gray100;
        }
    }};

    span {
        margin-bottom: -2px;
        font-size: 25px;
    }
`;

export const SearchBar = styled.div`
    width: 400px;

    input {
        background-color: ${({ theme }) => theme.white2} !important;
    }

    .react-select__placeholder {
        margin-left: 0.5rem;
    }

    .react-select__control {
        border: none;
        border-radius: 0.25rem;
        min-height: 3rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
        box-shadow: none;
        font-size: 14px;
        white-space: nowrap;
        border: 1px solid ${({ theme }) => theme.gray100};
    }

    .react-select__control--is-focused:hover {
        border: 1px solid ${({ theme }) => theme.gray100};
    }

    .react-select__clear-indicator,
    .react-select__indicator-separator {
        display: none;
    }

    .react-select--is-disabled {
        visibility: none;
        cursor: not-allowed;
    }
`;
