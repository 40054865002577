import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TLockReport = {
    reportId?: string;
};

export default async ({ reportId }: TLockReport = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeReport/toggleLock`,
        method: "patch",
        headers: {
            auth: true,
            "x-report-id": reportId,
        },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Não foi possível abrir o relatório!",
        401: "Acesso negado",
        404: "Relatório não existe!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
