import { CircularProgress } from "@mui/material";

import { Medal2Dark, Medal2Purple } from "../../../../../../../../assets";
import * as s from "./styles";

type ProgressCircleProps = {
    percentageDone?: number;
    points?: number;
};

export const ProgressCircle = ({
    percentageDone = 0,
    points = 0,
}: ProgressCircleProps) => {
    const medal = percentageDone >= 100 ? Medal2Purple : Medal2Dark;

    return (
        <s.Container>
            <s.Progress>
                <s.Points done={percentageDone >= 100}>
                    <img src={medal} className="medal" />
                    <p>+ {points} pts</p>
                </s.Points>
                <CircularProgress
                    value={100}
                    variant="determinate"
                    size={120}
                    thickness={2}
                    className="circular-progress-background"
                />
                <CircularProgress
                    value={percentageDone}
                    variant="determinate"
                    size={120}
                    thickness={2}
                    className="circular-progress"
                />
            </s.Progress>
        </s.Container>
    );
};
