import { Icons, theme } from "../../../../../../assets";
import { TBackOfficeData } from "../../../../types";
import * as generalStyles from "../../styles";
import * as s from "./styles";

export const LastWeekNewUsersOrigins = ({
    data,
}: {
    data: TBackOfficeData;
}) => {
    return (
        <s.Container>
            <generalStyles.Title>
                <Icons.CalendarOutline color={theme.purple500} /> Origem dos
                novos usuários da última semana
            </generalStyles.Title>
            <generalStyles.Row>
                {data.newUsersOriginLastWeek.length > 0 ? (
                    data.newUsersOriginLastWeek.map(
                        ({ source, percentage }) => (
                            <generalStyles.Content key={source}>
                                <h1>{source}</h1>
                                <p>
                                    <span>{percentage.toFixed(2)}%</span>
                                </p>
                            </generalStyles.Content>
                        )
                    )
                ) : (
                    <generalStyles.NoData>
                        <p>Nenhuma origem de usuário</p>
                    </generalStyles.NoData>
                )}
            </generalStyles.Row>
        </s.Container>
    );
};
