import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-favorite"}
    >
        <title>{title}</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.17188 2.17232C1.92199 1.42244 2.93922 1.00118 3.99988 1.00118C5.06053 1.00118 6.07777 1.42244 6.82788 2.17232L7.99988 3.34332L9.17188 2.17232C9.54086 1.79028 9.98224 1.48555 10.4703 1.27592C10.9583 1.06628 11.4832 0.955938 12.0143 0.951323C12.5454 0.946708 13.0721 1.04791 13.5637 1.24904C14.0553 1.45016 14.5019 1.74717 14.8775 2.12274C15.253 2.49832 15.55 2.94492 15.7512 3.43651C15.9523 3.92809 16.0535 4.45481 16.0489 4.98593C16.0443 5.51705 15.9339 6.04193 15.7243 6.52994C15.5146 7.01796 15.2099 7.45934 14.8279 7.82832L7.99988 14.6573L1.17188 7.82832C0.421994 7.07821 0.000732422 6.06098 0.000732422 5.00032C0.000732422 3.93967 0.421994 2.92244 1.17188 2.17232Z"
            fill={color || theme.gray400}
        />
    </svg>
);
