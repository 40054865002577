import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TGetReports = {
    page?: number;
    errorStatus?: string;
    all?: boolean;
    length?: number;
    monthlyReports?: string;
    projects?: boolean;
    users?: boolean;
    reportId?: string;
    responsible?: string;
    approved?: boolean;
    locked?: boolean;
    reviewed?: boolean;
    filter?: string;
    reportBilling?: boolean;
    billed?: boolean;
    projectId?: string;
};

export default async ({
    page,
    errorStatus,
    all,
    length,
    monthlyReports,
    users,
    projects,
    reportId,
    locked,
    reviewed,
    responsible,
    approved,
    filter,
    reportBilling,
    billed,
    projectId,
}: TGetReports = {}) => {
    const params = {
        page,
        errorStatus,
        all,
        length,
        monthlyReports,
        users,
        projects,
        responsible,
        approved,
        locked,
        reviewed,
        filter,
        reportBilling,
        billed,
    };
    const data = {
        projectId,
    };
    const tempHeaders = {
        auth: true,
        "x-report-id": reportId,
    };
    if (!reportId) delete tempHeaders["x-report-id"];
    if (!projectId) delete data.projectId;

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeReport/read`,
        method: "post",
        headers: tempHeaders,
        params,
        body: data,
    });
    const { body, statusCode } = httpResponse;
    const errorFeedBacks = {
        401: "Acesso negado",
        404: "Sem relatórios!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
