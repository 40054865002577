import * as yup from "yup";

import { validationErrors } from "../../../utils";

const { name } = validationErrors;

export default () =>
    yup.object().shape({
        name: yup
            .string()
            .required(name.required)
            .min(3, name.minChars)
            .matches(
                /^\s*\S[^]*$/,
                "Favor preencher com valores válidos (Letras, números ou caracteres especiais)"
            ),
        description: yup
            .string()
            .nullable()
            .test(
                "description",
                "Favor preencher com valores válidos (Letras, números ou caracteres especiais)",
                function () {
                    if (!this.parent.description) return true;

                    return this.parent.description.match(/^\s*\S[^]*$/);
                }
            ),
    });
