import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Fields = {
    name: string;
};

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: (data: Fields) => void;
    loading?: boolean;
};

const schema = yup.object({
    name: yup
        .string()
        .required("Campo obrigatório")
        .max(100, "Máximo de 100 caracteres"),
});

export function ModalCreateClient({ setOpen, onSuccess, loading }: Props) {
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<Fields>({
        defaultValues: {
            name: "",
        },
        resolver: yupResolver(schema),
    });

    function submit(data: Fields) {
        onSuccess({ ...data });
    }

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>Criar cliente</s.Title>
                <s.Fields>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                label="Nome"
                                onChange={onChange}
                                value={value}
                                error={errors.name?.message}
                            />
                        )}
                    />
                </s.Fields>
                <s.Buttons>
                    <SecondaryButton
                        type="button"
                        onClick={() => setOpen(false)}
                        disabled={loading}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit" loading={loading}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
}
