import { useState } from "react";

import { Icons } from "../../assets";

export type TFormFields = {
    password: string;
    confirmPassword: string;
};
type TForm = {
    id: keyof TFormFields;
    type: string;
    label: string;
    placeholder: string;
    icon: JSX.Element;
    maxLength: number;
};

export default () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const form: TForm[] = [
        {
            type: isVisible ? "text" : "password",
            label: "Digite sua nova senha",
            id: "password",
            placeholder: "Digite aqui sua nova senha",
            maxLength: 100,
            icon: !isVisible ? (
                <Icons.Eye onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <Icons.SenhaFechado onClick={() => setIsVisible(!isVisible)} />
            ),
        },
        {
            placeholder: "Repita aqui sua nova senha",
            type: isVisible ? "text" : "password",
            label: "Repita sua nova senha",
            id: "confirmPassword",
            maxLength: 100,
            icon: !isVisible ? (
                <Icons.Eye onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <Icons.SenhaFechado onClick={() => setIsVisible(!isVisible)} />
            ),
        },
    ];

    return form;
};
