import { theme } from "..";

export default () => (
    <svg
        width="126"
        height="126"
        viewBox="0 0 126 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="126" height="126" rx="8" fill={theme.purple700} />
        <path
            opacity="0.5"
            d="M35.6163 98.3174C53.3896 106.787 82.2052 106.787 99.9785 98.3174C117.752 89.8481 117.75 76.1174 99.9785 67.6414C82.2074 59.1655 53.3896 59.1699 35.6163 67.6414C17.8429 76.113 17.8452 89.8459 35.6163 98.3174Z"
            fill="#EBEBEB"
        />
        <path
            d="M93.2107 82.4688C99.9934 82.4688 105.492 79.8478 105.492 76.6146C105.492 73.3815 99.9934 70.7605 93.2107 70.7605C86.4281 70.7605 80.9297 73.3815 80.9297 76.6146C80.9297 79.8478 86.4281 82.4688 93.2107 82.4688Z"
            fill="#E0E0E0"
        />
        <path
            d="M74.6392 99.9181C71.358 101.483 66.0389 101.483 62.7577 99.9181C59.4766 98.353 59.4766 95.82 62.7577 94.2549C66.0389 92.6898 71.358 92.7009 74.6392 94.2549C77.9203 95.8089 77.9292 98.3552 74.6392 99.9181Z"
            fill="#E0E0E0"
        />
        <path
            d="M30.5095 79.9469L32.3143 78.9057C32.3143 78.9057 33.2667 74.6588 31.8481 69.2642C30.4295 63.8696 26.3381 59.8359 24.2047 60.6817C22.0712 61.5275 22.1267 63.1237 23.3033 64.7421C24.2801 66.0741 27.4481 69.3153 28.971 73.0493C29.8783 75.2424 30.3988 77.5761 30.5095 79.9469Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M30.5095 79.9469L32.3143 78.9057C32.3143 78.9057 33.2667 74.6588 31.8481 69.2642C30.4295 63.8696 26.3381 59.8359 24.2047 60.6817C22.0712 61.5275 22.1267 63.1237 23.3033 64.7421C24.2801 66.0741 27.4481 69.3153 28.971 73.0493C29.8783 75.2424 30.3988 77.5761 30.5095 79.9469Z"
            fill="black"
        />
        <path
            d="M31.4707 79.5028H31.4552C31.4264 79.4984 31.4006 79.483 31.3832 79.4597C31.3657 79.4365 31.3581 79.4073 31.3619 79.3785C32.594 70.4985 27.0906 63.319 24.5865 61.8205C24.572 61.8143 24.559 61.805 24.5483 61.7933C24.5377 61.7815 24.5297 61.7677 24.5249 61.7526C24.52 61.7376 24.5185 61.7217 24.5203 61.706C24.5221 61.6903 24.5272 61.6751 24.5353 61.6616C24.5435 61.648 24.5544 61.6363 24.5674 61.6273C24.5804 61.6184 24.5952 61.6122 24.6108 61.6094C24.6263 61.6066 24.6423 61.6072 24.6577 61.611C24.673 61.6149 24.6873 61.622 24.6997 61.6318C27.2416 63.1503 32.8293 70.4253 31.5817 79.4096C31.577 79.4357 31.5634 79.4593 31.5431 79.4763C31.5228 79.4934 31.4972 79.5027 31.4707 79.5028Z"
            fill="white"
        />
        <path
            d="M30.5099 79.9469L27.8059 81.5009C27.2412 81.2186 26.6294 81.0426 26.001 80.9814C24.8511 80.8371 24.0852 79.7271 24.1984 78.899C24.2739 78.3396 24.4537 77.7024 24.3671 77.1363C24.2916 76.6302 23.801 76.1751 23.3437 75.9975C22.9545 75.8759 22.5481 75.8182 22.1405 75.8266C21.49 75.7955 20.8085 75.7222 20.2601 75.3826C19.7118 75.0429 19.3077 74.3636 19.4853 73.7353C19.6048 73.4101 19.7986 73.1174 20.0514 72.8806C20.3032 72.6432 20.5074 72.3599 20.6531 72.0459C20.8928 71.4199 20.4688 71.0203 19.9649 70.7294C18.1889 69.7082 17.3897 68.9024 18.029 68.1298C18.6684 67.3572 20.4244 67.0198 22.2248 67.9655C24.0252 68.9112 25.5193 67.8967 26.707 68.0965C28.5674 68.4073 28.6761 69.7216 28.4453 70.8626C28.2144 72.0037 30.5165 72.4899 31.0871 73.8596C31.5155 74.8542 31.8663 78.122 30.5099 79.9469Z"
            fill="#8C53C5"
        />
        <path
            d="M29.6089 80.5774C29.5802 80.5775 29.5527 80.5664 29.532 80.5465C29.5113 80.5266 29.4991 80.4995 29.4979 80.4709C29.2759 74.1328 21.153 68.605 19.2371 68.2875C19.2227 68.2854 19.2088 68.2804 19.1963 68.2728C19.1838 68.2653 19.1729 68.2553 19.1642 68.2436C19.1556 68.2318 19.1493 68.2185 19.1458 68.2043C19.1423 68.1901 19.1416 68.1754 19.1439 68.161C19.1493 68.132 19.1657 68.1064 19.1898 68.0894C19.2138 68.0724 19.2435 68.0654 19.2726 68.07C21.2906 68.403 29.4957 73.8886 29.7199 80.462C29.7205 80.4766 29.7182 80.4911 29.7132 80.5048C29.7082 80.5185 29.7005 80.5311 29.6906 80.5418C29.6807 80.5525 29.6688 80.5612 29.6555 80.5673C29.6423 80.5734 29.628 80.5769 29.6134 80.5774H29.6089Z"
            fill="white"
        />
        <path
            d="M64.4443 92.0258C64.3555 90.9158 63.4986 89.0199 62.2243 87.6702C60.95 86.3204 58.9254 85.6722 57.6733 86.2138C56.5078 86.72 56.7653 88.2829 58.3038 89.2086C59.8422 90.1344 62.1333 91.2666 62.9191 93.0181L64.4376 95.1205L64.4443 92.0258Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M64.4443 92.0258C64.3555 90.9158 63.4986 89.0199 62.2243 87.6702C60.95 86.3204 58.9254 85.6722 57.6733 86.2138C56.5078 86.72 56.7653 88.2829 58.3038 89.2086C59.8422 90.1344 62.1333 91.2666 62.9191 93.0181L64.4376 95.1205L64.4443 92.0258Z"
            fill="black"
        />
        <path
            d="M64.4449 94.0595H64.4671C64.4868 94.0568 64.5057 94.0502 64.5229 94.0401C64.54 94.03 64.555 94.0167 64.567 94.0008C64.5789 93.9849 64.5876 93.9668 64.5926 93.9476C64.5975 93.9283 64.5986 93.9083 64.5958 93.8886C64.043 89.7749 59.8628 86.9133 58.2 86.5981C58.1805 86.5942 58.1604 86.5943 58.1408 86.5982C58.1213 86.6021 58.1028 86.6099 58.0862 86.6211C58.0697 86.6322 58.0556 86.6465 58.0446 86.6631C58.0337 86.6798 58.0261 86.6984 58.0224 86.718C58.0185 86.7375 58.0186 86.7576 58.0225 86.7771C58.0265 86.7967 58.0342 86.8152 58.0454 86.8317C58.0565 86.8482 58.0708 86.8624 58.0874 86.8733C58.1041 86.8843 58.1227 86.8918 58.1423 86.8956C59.6963 87.1908 63.7678 90.0213 64.2939 93.9285C64.2992 93.9648 64.3174 93.998 64.3451 94.022C64.3728 94.0461 64.4082 94.0594 64.4449 94.0595Z"
            fill="white"
        />
        <path
            d="M64.2811 96.6212C64.0591 96.0107 63.2066 95.0405 61.9035 94.2902C60.4627 93.4577 58.7022 93.1624 58.2449 92.4631C57.6966 91.6195 58.3714 90.7293 59.7856 90.7892C61.1997 90.8492 63.8215 92.5652 64.4476 95.4135L64.2811 96.6212Z"
            fill="#8C53C5"
        />
        <path
            d="M64.3873 96.0218H64.4162C64.436 96.0181 64.4548 96.0105 64.4716 95.9994C64.4884 95.9883 64.5028 95.974 64.514 95.9572C64.5252 95.9404 64.5329 95.9216 64.5367 95.9018C64.5405 95.8821 64.5403 95.8617 64.5361 95.842C64.1165 93.733 61.0595 91.5485 59.0837 91.4575C59.0439 91.4573 59.0054 91.472 58.9759 91.4987C58.9463 91.5254 58.9278 91.5622 58.9239 91.6018C58.9227 91.6423 58.9373 91.6817 58.9647 91.7115C58.992 91.7414 59.03 91.7594 59.0704 91.7617C60.9041 91.846 63.8501 93.9484 64.2386 95.902C64.2457 95.936 64.2645 95.9666 64.2916 95.9884C64.3187 96.0103 64.3525 96.0221 64.3873 96.0218Z"
            fill="white"
        />
        <path
            d="M28.5709 81.592C28.4428 81.5061 28.3431 81.3841 28.2846 81.2413C28.2261 81.0986 28.2114 80.9417 28.2424 80.7906L36.1145 45.9499C36.2092 45.613 36.423 45.322 36.7161 45.1307L83.5581 18.0911C83.7014 18.0154 83.8605 17.9745 84.0225 17.9718C84.1845 17.9691 84.3448 18.0047 84.4905 18.0756C84.6166 18.162 84.7145 18.2838 84.7717 18.4255C84.829 18.5673 84.8431 18.7228 84.8124 18.8726L76.9381 53.7133C76.8432 54.0507 76.6285 54.3419 76.3342 54.5325L29.5033 81.572C29.3605 81.6485 29.2015 81.6901 29.0395 81.6936C28.8775 81.6971 28.717 81.6623 28.5709 81.592Z"
            fill="#F0F0F0"
        />
        <path
            d="M37.0161 46.465L29.144 81.319C29.0774 81.6164 29.2372 81.7363 29.5036 81.5831L76.3456 54.5435C76.6399 54.353 76.8545 54.0618 76.9495 53.7244L84.8127 18.8726C84.8793 18.5729 84.7195 18.4552 84.4531 18.6084L37.6111 45.648C37.321 45.84 37.1098 46.13 37.0161 46.465Z"
            fill="#E6E6E6"
        />
        <path
            d="M85.383 18.5907C85.2371 18.5201 85.0767 18.4849 84.9147 18.488C84.7527 18.4911 84.5937 18.5323 84.4506 18.6084L37.6086 45.648C37.4727 45.736 37.3634 45.8594 37.2926 46.005C37.2218 46.1505 37.1921 46.3127 37.2068 46.4739L43.3717 89.6284C43.4018 89.792 43.4642 89.9479 43.5553 90.0871C43.6464 90.2262 43.7644 90.3458 43.9023 90.4387C44.0476 90.5098 44.2078 90.5453 44.3695 90.5422C44.5313 90.5391 44.69 90.4976 44.8325 90.421L91.6656 63.388C91.8018 63.2999 91.9113 63.1761 91.9822 63.0301C92.053 62.8841 92.0825 62.7215 92.0674 62.56L85.9225 19.401C85.8914 19.2366 85.8277 19.0802 85.735 18.941C85.6423 18.8018 85.5226 18.6827 85.383 18.5907Z"
            fill="#F0F0F0"
        />
        <path
            d="M38.117 46.9954L44.2731 90.1499C44.2743 90.2143 44.2933 90.2771 44.328 90.3313C44.3627 90.3855 44.4117 90.429 44.4697 90.4571C44.5276 90.4851 44.5922 90.4966 44.6562 90.4902C44.7202 90.4838 44.7813 90.4597 44.8325 90.4208L91.6656 63.3879C91.8019 63.2997 91.9113 63.1759 91.9822 63.0299C92.053 62.884 92.0825 62.7214 92.0674 62.5598L85.9225 19.4008C85.9217 19.3362 85.9029 19.2732 85.8684 19.2187C85.8339 19.1641 85.7848 19.1203 85.7268 19.092C85.6688 19.0636 85.6041 19.052 85.5399 19.0583C85.4756 19.0646 85.4144 19.0886 85.363 19.1277L38.521 46.1673C38.3844 46.2552 38.2745 46.3788 38.2032 46.5248C38.132 46.6708 38.1022 46.8336 38.117 46.9954Z"
            fill="#FAFAFA"
        />
        <path
            d="M29.1855 81.1234L41.1735 74.1992L41.5221 76.7078L36.0254 79.8824C36.0254 79.8824 34.5602 80.4019 33.9785 80.5484C33.3969 80.6949 29.1855 81.1234 29.1855 81.1234Z"
            fill="#E0E0E0"
        />
        <path
            d="M41.5299 76.7102L43.3281 89.3198L36.0332 79.8848L41.5299 76.7102Z"
            fill="#E6E6E6"
        />
        <path
            d="M43.1113 89.7392L36.5734 81.2477C36.4649 81.1211 36.3277 81.0222 36.1733 80.9592C36.0188 80.8963 35.8516 80.8711 35.6854 80.8858L29.5716 81.7738C29.2273 81.8107 28.8796 81.7475 28.5703 81.5918C28.3217 81.4497 28.3639 81.2854 28.6636 81.2277L35.6899 79.8757C35.8546 79.851 36.023 79.8692 36.1786 79.9287C36.3342 79.9882 36.4717 80.087 36.5779 80.2154L44.0215 90.2498C44.2036 90.4962 44.1503 90.5784 43.9017 90.4341C43.602 90.2473 43.335 90.0125 43.1113 89.7392Z"
            fill="#E0E0E0"
        />
        <path
            d="M84.8243 18.7015C84.8234 18.5764 84.7918 18.4535 84.7322 18.3435C84.6726 18.2335 84.5868 18.14 84.4824 18.071C84.3363 18.0011 84.176 17.9663 84.0141 17.9694C83.8522 17.9725 83.6933 18.0134 83.55 18.0888L36.708 45.1284C36.5652 45.2179 36.4408 45.334 36.3417 45.4703C36.2317 45.6121 36.1509 45.7743 36.1041 45.9476L34.9941 50.8693L35.8955 51.3866L83.7032 23.792L84.8132 18.8725C84.8237 18.8161 84.8282 18.7588 84.8265 18.7015H84.8243Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.2"
            d="M84.8117 18.8726C84.8783 18.5729 84.7185 18.4552 84.4521 18.6084L37.6101 45.648C37.3175 45.8388 37.1038 46.129 37.0084 46.465L35.8984 51.3867L83.7017 23.7921L84.8117 18.8726Z"
            fill="black"
        />
        <path
            opacity="0.1"
            d="M37.2505 45.9897C37.1403 46.1302 37.0602 46.292 37.0152 46.4648L35.9052 51.3865L35.0039 50.8671L36.1139 45.9453C36.1607 45.772 36.2414 45.6098 36.3514 45.468L37.2505 45.9897Z"
            fill="black"
        />
        <path
            opacity="0.4"
            d="M84.8244 18.6993C84.8022 18.5328 84.6579 18.4773 84.4514 18.6083L37.6094 45.6457C37.4656 45.7348 37.341 45.8518 37.2431 45.9898L36.3418 45.4681C36.4409 45.3318 36.5653 45.2157 36.7081 45.1262L83.5501 18.0866C83.6934 18.0112 83.8523 17.9703 84.0142 17.9672C84.1761 17.9641 84.3364 17.9989 84.4825 18.0688C84.5869 18.1378 84.6727 18.2313 84.7323 18.3413C84.7919 18.4513 84.8236 18.5742 84.8244 18.6993Z"
            fill="white"
        />
        <path
            d="M85.9688 19.8139L85.9221 19.401C85.9188 19.3808 85.9143 19.3607 85.9088 19.341C85.9098 19.3351 85.9098 19.3291 85.9088 19.3233C85.8333 19.0245 85.6508 18.7639 85.396 18.5907C85.2501 18.5201 85.0897 18.4849 84.9277 18.488C84.7657 18.4911 84.6067 18.5323 84.4636 18.6084L37.6216 45.648C37.4798 45.7358 37.3677 45.8642 37.2997 46.0165C37.2304 46.1584 37.2026 46.3169 37.2198 46.4739L38.1078 52.6566L39.0091 53.1783L86.7946 25.5859L85.9688 19.8139Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M39.0013 53.1759L38.1 52.6542L37.212 46.4715C37.1948 46.3145 37.2225 46.156 37.2919 46.0142L38.1954 46.5336C38.1245 46.675 38.096 46.8338 38.1133 46.991L39.0013 53.1759Z"
            fill="black"
        />
        <path
            opacity="0.4"
            d="M85.8961 19.3233C85.8833 19.2673 85.8568 19.2155 85.8188 19.1725C85.7808 19.1296 85.7326 19.0969 85.6787 19.0774C85.6247 19.0579 85.5667 19.0524 85.5101 19.0612C85.4534 19.07 85.3998 19.093 85.3544 19.1279L38.5124 46.1675C38.37 46.2547 38.2577 46.3832 38.1905 46.536L37.3047 46.0165C37.3732 45.864 37.4852 45.735 37.6266 45.6458L84.4686 18.6084C84.6117 18.5323 84.7707 18.4911 84.9327 18.488C85.0947 18.4849 85.2551 18.5201 85.401 18.5907C85.6498 18.7664 85.8258 19.0269 85.8961 19.3233Z"
            fill="white"
        />
        <path
            d="M51.435 82.9994L50.8755 79.0678C50.8747 79.0166 50.8599 78.9666 50.8326 78.9233C50.8054 78.8799 50.7667 78.8449 50.7209 78.822C50.6751 78.7991 50.6239 78.7892 50.5729 78.7934C50.5219 78.7976 50.473 78.8157 50.4315 78.8458L46.349 81.2035C46.2389 81.2738 46.1506 81.3734 46.0938 81.4909C46.037 81.6085 46.0139 81.7395 46.027 81.8695L46.5843 85.81C46.5843 85.8615 46.5986 85.912 46.6257 85.9559C46.6528 85.9997 46.6916 86.0351 46.7377 86.0582C46.7838 86.0812 46.8354 86.091 46.8867 86.0863C46.938 86.0817 46.987 86.0629 47.0283 86.032L51.1109 83.6743C51.2229 83.6037 51.3128 83.5029 51.3701 83.3836C51.4274 83.2642 51.4499 83.131 51.435 82.9994Z"
            fill="#8C53C5"
        />
        <path
            d="M57.7476 79.3585L57.186 75.4291C57.186 75.3776 57.1716 75.3271 57.1445 75.2832C57.1174 75.2394 57.0786 75.204 57.0326 75.1809C56.9865 75.1579 56.9349 75.1481 56.8835 75.1527C56.8322 75.1574 56.7832 75.1762 56.742 75.2071L52.6594 77.5625C52.5495 77.6331 52.4614 77.7326 52.4046 77.8502C52.3478 77.9677 52.3245 78.0986 52.3375 78.2285L52.8991 82.1601C52.8991 82.2117 52.9135 82.2622 52.9406 82.306C52.9677 82.3499 53.0064 82.3853 53.0525 82.4084C53.0986 82.4314 53.1502 82.4412 53.2016 82.4365C53.2529 82.4319 53.3019 82.4131 53.3431 82.3821L57.4257 80.0245C57.5359 79.9543 57.6244 79.8548 57.6812 79.7372C57.7381 79.6196 57.7611 79.4885 57.7476 79.3585Z"
            fill="#EBEBEB"
        />
        <path
            d="M64.0623 75.7179L63.5007 71.7885C63.5007 71.7369 63.4863 71.6864 63.4592 71.6426C63.4321 71.5988 63.3934 71.5633 63.3473 71.5403C63.3012 71.5172 63.2496 71.5075 63.1983 71.5121C63.1469 71.5167 63.0979 71.5356 63.0567 71.5665L58.9719 73.9263C58.862 73.9969 58.7739 74.0964 58.7171 74.214C58.6603 74.3315 58.637 74.4624 58.65 74.5923L59.2116 78.524C59.2116 78.5755 59.226 78.626 59.2531 78.6699C59.2802 78.7137 59.3189 78.7491 59.365 78.7722C59.4111 78.7952 59.4627 78.805 59.5141 78.8003C59.5654 78.7957 59.6144 78.7769 59.6556 78.746L63.7382 76.3883C63.8495 76.3181 63.9389 76.218 63.9962 76.0995C64.0535 75.981 64.0764 75.8488 64.0623 75.7179Z"
            fill="#EBEBEB"
        />
        <path
            d="M70.3828 72.0792L69.8212 68.1476C69.8212 68.0961 69.8068 68.0456 69.7797 68.0017C69.7526 67.9579 69.7139 67.9225 69.6678 67.8994C69.6217 67.8764 69.5701 67.8666 69.5187 67.8712C69.4674 67.8759 69.4184 67.8947 69.3772 67.9256L65.2768 70.2877C65.1666 70.3579 65.0782 70.4574 65.0213 70.575C64.9645 70.6926 64.9415 70.8237 64.9549 70.9537L65.5166 74.8831C65.5166 74.9346 65.5309 74.9852 65.558 75.029C65.5851 75.0728 65.6239 75.1083 65.67 75.1313C65.7161 75.1544 65.7677 75.1641 65.819 75.1595C65.8703 75.1549 65.9193 75.136 65.9606 75.1051L70.0432 72.7497C70.157 72.6811 70.2492 72.5818 70.3093 72.4632C70.3693 72.3447 70.3948 72.2116 70.3828 72.0792Z"
            fill="#EBEBEB"
        />
        <path
            d="M76.6893 68.4385L76.1276 64.498C76.1276 64.4464 76.1133 64.3959 76.0862 64.3521C76.0591 64.3082 76.0203 64.2728 75.9742 64.2498C75.9281 64.2267 75.8765 64.217 75.8252 64.2216C75.7739 64.2262 75.7248 64.245 75.6836 64.276L71.601 66.6336C71.4908 66.7038 71.4024 66.8033 71.3455 66.9209C71.2887 67.0385 71.2657 67.1696 71.2791 67.2996L71.8408 71.229C71.8408 71.2805 71.8551 71.331 71.8822 71.3749C71.9093 71.4187 71.9481 71.4541 71.9942 71.4772C72.0403 71.5002 72.0919 71.51 72.1432 71.5054C72.1945 71.5007 72.2436 71.4819 72.2848 71.451L76.3762 69.1C76.4837 69.0287 76.5697 68.9294 76.6248 68.8128C76.68 68.6962 76.7023 68.5668 76.6893 68.4385Z"
            fill="#EBEBEB"
        />
        <path
            d="M83.0037 64.7975L82.4421 60.8659C82.4421 60.8143 82.4277 60.7638 82.4006 60.72C82.3735 60.6761 82.3348 60.6407 82.2887 60.6177C82.2426 60.5946 82.191 60.5849 82.1396 60.5895C82.0883 60.5941 82.0393 60.613 81.9981 60.6439L77.9155 63.0015C77.8053 63.0717 77.7168 63.1712 77.66 63.2888C77.6031 63.4064 77.5801 63.5376 77.5936 63.6675L78.153 67.6058C78.153 67.6573 78.1674 67.7079 78.1945 67.7517C78.2216 67.7955 78.2603 67.831 78.3064 67.854C78.3525 67.8771 78.4041 67.8868 78.4554 67.8822C78.5068 67.8775 78.5558 67.8587 78.597 67.8278L82.6796 65.4702C82.7911 65.3995 82.8806 65.2991 82.9379 65.1802C82.9952 65.0613 83.018 64.9287 83.0037 64.7975Z"
            fill="#EBEBEB"
        />
        <path
            d="M89.3161 61.1679L88.7544 57.2362C88.7544 57.1847 88.7401 57.1342 88.713 57.0904C88.6859 57.0465 88.6471 57.0111 88.601 56.988C88.5549 56.965 88.5033 56.9552 88.452 56.9599C88.4007 56.9645 88.3517 56.9833 88.3104 57.0142L84.2279 59.3719C84.1178 59.4423 84.0295 59.5418 83.9727 59.6593C83.9159 59.7769 83.8928 59.908 83.906 60.0379L84.4654 63.9695C84.4662 64.0207 84.481 64.0707 84.5083 64.114C84.5356 64.1574 84.5742 64.1924 84.62 64.2153C84.6658 64.2382 84.717 64.2481 84.768 64.2439C84.8191 64.2397 84.868 64.2216 84.9094 64.1915L88.992 61.8339C89.1024 61.7638 89.1912 61.6644 89.2484 61.5468C89.3056 61.4292 89.3291 61.298 89.3161 61.1679Z"
            fill="#EBEBEB"
        />
        <path
            d="M50.3376 75.307L49.7759 71.3798C49.7751 71.3286 49.7603 71.2786 49.733 71.2353C49.7058 71.192 49.6671 71.1569 49.6213 71.134C49.5755 71.1111 49.5243 71.1012 49.4733 71.1054C49.4223 71.1096 49.3734 71.1278 49.3319 71.1578L45.2493 73.5155C45.1393 73.5859 45.051 73.6854 44.9942 73.8029C44.9374 73.9205 44.9142 74.0516 44.9274 74.1815L45.4869 78.1131C45.4877 78.1643 45.5025 78.2143 45.5298 78.2576C45.557 78.301 45.5957 78.336 45.6415 78.3589C45.6873 78.3818 45.7385 78.3917 45.7895 78.3875C45.8405 78.3833 45.8894 78.3652 45.9309 78.3351L50.0135 75.9775C50.1239 75.9063 50.2125 75.806 50.2697 75.6878C50.3269 75.5695 50.3504 75.4378 50.3376 75.307Z"
            fill="#8C53C5"
        />
        <path
            d="M56.652 71.6662L56.0904 67.7346C56.0896 67.6834 56.0747 67.6334 56.0475 67.59C56.0202 67.5467 55.9816 67.5117 55.9358 67.4888C55.89 67.4659 55.8388 67.456 55.7877 67.4602C55.7367 67.4644 55.6878 67.4825 55.6464 67.5126L51.5638 69.8702C51.4538 69.9406 51.3655 70.0401 51.3086 70.1577C51.2518 70.2753 51.2287 70.4063 51.2419 70.5362L51.8013 74.4678C51.8021 74.519 51.817 74.569 51.8442 74.6124C51.8715 74.6557 51.9101 74.6908 51.9559 74.7137C52.0017 74.7366 52.0529 74.7464 52.104 74.7422C52.155 74.738 52.2039 74.7199 52.2453 74.6898L56.3279 72.3322C56.4376 72.2614 56.5258 72.1619 56.583 72.0444C56.6401 71.927 56.664 71.7962 56.652 71.6662Z"
            fill="#EBEBEB"
        />
        <path
            d="M62.9645 68.0256L62.4029 64.0939C62.4021 64.0428 62.3872 63.9928 62.36 63.9494C62.3327 63.9061 62.2941 63.871 62.2483 63.8481C62.2025 63.8252 62.1513 63.8153 62.1002 63.8195C62.0492 63.8237 62.0003 63.8419 61.9589 63.8719L57.8763 66.2296C57.7663 66.3 57.678 66.3995 57.6211 66.5171C57.5643 66.6346 57.5412 66.7657 57.5544 66.8956L58.1138 70.8272C58.1146 70.8784 58.1295 70.9284 58.1567 70.9717C58.184 71.0151 58.2226 71.0501 58.2684 71.073C58.3142 71.0959 58.3654 71.1058 58.4165 71.1016C58.4675 71.0974 58.5164 71.0793 58.5578 71.0492L62.6404 68.6916C62.7501 68.6208 62.8383 68.5212 62.8955 68.4038C62.9526 68.2864 62.9765 68.1556 62.9645 68.0256Z"
            fill="#EBEBEB"
        />
        <path
            d="M69.2725 64.3847L68.7108 60.4531C68.71 60.4019 68.6952 60.3519 68.6679 60.3085C68.6407 60.2652 68.602 60.2302 68.5562 60.2073C68.5104 60.1844 68.4592 60.1745 68.4082 60.1787C68.3571 60.1829 68.3082 60.201 68.2668 60.2311L64.1887 62.5932C64.0788 62.6637 63.9907 62.7633 63.9339 62.8808C63.877 62.9983 63.8538 63.1293 63.8668 63.2592L64.4262 67.1908C64.427 67.242 64.4418 67.292 64.4691 67.3353C64.4964 67.3787 64.535 67.4137 64.5808 67.4366C64.6266 67.4595 64.6778 67.4694 64.7288 67.4652C64.7799 67.461 64.8288 67.4429 64.8702 67.4128L68.9528 65.0551C69.0626 64.9836 69.1505 64.8831 69.2069 64.7649C69.2633 64.6466 69.286 64.515 69.2725 64.3847Z"
            fill="#EBEBEB"
        />
        <path
            d="M75.5936 60.744L75.0319 56.8146C75.0319 56.7631 75.0176 56.7126 74.9905 56.6687C74.9634 56.6249 74.9246 56.5895 74.8785 56.5664C74.8324 56.5434 74.7808 56.5336 74.7295 56.5382C74.6782 56.5429 74.6292 56.5617 74.5879 56.5926L70.5031 58.948C70.3933 59.0186 70.3051 59.1181 70.2483 59.2357C70.1915 59.3532 70.1683 59.4841 70.1812 59.614L70.7407 63.5457C70.7415 63.5968 70.7563 63.6468 70.7836 63.6902C70.8108 63.7335 70.8495 63.7686 70.8953 63.7915C70.9411 63.8144 70.9923 63.8243 71.0433 63.8201C71.0943 63.8159 71.1432 63.7977 71.1847 63.7677L75.2672 61.4056C75.3768 61.3356 75.4652 61.2369 75.5227 61.1203C75.5802 61.0037 75.6047 60.8736 75.5936 60.744Z"
            fill="#EBEBEB"
        />
        <path
            d="M81.9058 57.1031L81.3441 53.1759C81.3441 53.1244 81.3298 53.0739 81.3027 53.03C81.2756 52.9862 81.2368 52.9508 81.1907 52.9277C81.1447 52.9047 81.093 52.8949 81.0417 52.8996C80.9904 52.9042 80.9414 52.923 80.9001 52.9539L76.8198 55.3138C76.7096 55.384 76.6211 55.4835 76.5643 55.6011C76.5074 55.7187 76.4844 55.8498 76.4979 55.9798L77.0573 59.9092C77.0581 59.9604 77.073 60.0104 77.1002 60.0537C77.1275 60.0971 77.1661 60.1321 77.2119 60.155C77.2577 60.1779 77.3089 60.1878 77.36 60.1836C77.411 60.1794 77.4599 60.1613 77.5013 60.1312L81.5839 57.7736C81.6941 57.7023 81.7824 57.602 81.8392 57.4837C81.896 57.3654 81.9191 57.2337 81.9058 57.1031Z"
            fill="#EBEBEB"
        />
        <path
            d="M88.2205 53.4645L87.6589 49.5329C87.6589 49.4813 87.6445 49.4308 87.6174 49.387C87.5903 49.3431 87.5516 49.3077 87.5055 49.2847C87.4594 49.2616 87.4078 49.2519 87.3564 49.2565C87.3051 49.2611 87.2561 49.28 87.2149 49.3109L83.1301 51.6685C83.0205 51.7392 82.9326 51.8388 82.8762 51.9564C82.8197 52.0739 82.797 52.2048 82.8104 52.3345L83.3698 56.2639C83.3706 56.3151 83.3855 56.3651 83.4127 56.4085C83.44 56.4518 83.4786 56.4868 83.5244 56.5097C83.5702 56.5326 83.6214 56.5425 83.6725 56.5383C83.7235 56.5341 83.7724 56.516 83.8138 56.4859L87.8964 54.1305C88.0059 54.0595 88.094 53.9599 88.1511 53.8426C88.2082 53.7252 88.2322 53.5945 88.2205 53.4645Z"
            fill="#EBEBEB"
        />
        <path
            d="M49.2398 67.6059L48.6804 63.6743C48.6796 63.6231 48.6647 63.5731 48.6375 63.5297C48.6102 63.4864 48.5716 63.4514 48.5258 63.4285C48.48 63.4056 48.4288 63.3957 48.3778 63.3999C48.3267 63.4041 48.2778 63.4222 48.2364 63.4523L44.1494 65.8299C44.0399 65.9008 43.952 66.0004 43.8952 66.1179C43.8385 66.2353 43.815 66.3661 43.8275 66.4959L44.3891 70.4253C44.3891 70.4768 44.4035 70.5273 44.4306 70.5712C44.4577 70.615 44.4964 70.6504 44.5425 70.6735C44.5886 70.6965 44.6402 70.7063 44.6915 70.7017C44.7429 70.697 44.7919 70.6782 44.8331 70.6473L48.9179 68.2719C49.0277 68.2013 49.1159 68.1018 49.1727 67.9843C49.2295 67.8667 49.2528 67.7358 49.2398 67.6059Z"
            fill="#8C53C5"
        />
        <path
            d="M55.5537 63.9741L54.9943 60.0424C54.9935 59.9912 54.9787 59.9412 54.9514 59.8979C54.9241 59.8546 54.8855 59.8195 54.8397 59.7966C54.7939 59.7737 54.7427 59.7638 54.6917 59.768C54.6406 59.7722 54.5917 59.7904 54.5503 59.8204L50.4677 62.1781C50.3583 62.249 50.2704 62.3486 50.2136 62.466C50.1568 62.5835 50.1334 62.7142 50.1458 62.8441L50.7075 66.7757C50.7083 66.8269 50.7231 66.8769 50.7504 66.9202C50.7776 66.9636 50.8163 66.9986 50.8621 67.0215C50.9079 67.0444 50.9591 67.0543 51.0101 67.0501C51.0611 67.0459 51.11 67.0278 51.1515 66.9977L55.2341 64.6401C55.3437 64.5694 55.4315 64.4698 55.488 64.3522C55.5444 64.2347 55.5672 64.1038 55.5537 63.9741Z"
            fill="#EBEBEB"
        />
        <path
            d="M61.8661 60.3332L61.3067 56.4016C61.3059 56.3504 61.291 56.3004 61.2638 56.257C61.2365 56.2137 61.1979 56.1787 61.1521 56.1558C61.1063 56.1329 61.0551 56.123 61.0041 56.1272C60.953 56.1314 60.9041 56.1495 60.8627 56.1796L56.7801 58.5372C56.6708 58.6083 56.5831 58.7079 56.5263 58.8253C56.4696 58.9427 56.446 59.0734 56.4582 59.2032L57.0199 63.1348C57.0207 63.186 57.0355 63.236 57.0628 63.2794C57.09 63.3227 57.1287 63.3577 57.1745 63.3806C57.2203 63.4035 57.2715 63.4134 57.3225 63.4092C57.3735 63.405 57.4224 63.3869 57.4639 63.3568L61.5464 60.9992C61.656 60.9285 61.7439 60.8289 61.8004 60.7113C61.8568 60.5938 61.8795 60.4629 61.8661 60.3332Z"
            fill="#EBEBEB"
        />
        <path
            d="M68.1806 56.6923L67.6211 52.7607C67.6203 52.7095 67.6055 52.6595 67.5782 52.6162C67.551 52.5728 67.5123 52.5378 67.4665 52.5149C67.4207 52.492 67.3695 52.4821 67.3185 52.4863C67.2675 52.4905 67.2186 52.5086 67.1771 52.5387L63.0946 54.8963C62.9853 54.9674 62.8975 55.0671 62.8408 55.1845C62.784 55.3019 62.7605 55.4325 62.7727 55.5623L63.3343 59.494C63.3351 59.5452 63.35 59.5952 63.3772 59.6385C63.4045 59.6818 63.4431 59.7169 63.4889 59.7398C63.5347 59.7627 63.5859 59.7726 63.6369 59.7684C63.688 59.7642 63.7369 59.746 63.7783 59.716L67.8609 57.3583C67.9705 57.2877 68.0584 57.188 68.1148 57.0705C68.1712 56.9529 68.194 56.822 68.1806 56.6923Z"
            fill="#EBEBEB"
        />
        <path
            d="M74.4931 53.0517L73.9336 49.1201C73.9328 49.0689 73.918 49.0189 73.8907 48.9755C73.8635 48.9322 73.8248 48.8972 73.779 48.8743C73.7332 48.8514 73.682 48.8415 73.631 48.8457C73.58 48.8499 73.5311 48.868 73.4896 48.8981L69.4071 51.2557C69.2978 51.3268 69.21 51.4264 69.1533 51.5438C69.0965 51.6612 69.073 51.7919 69.0852 51.9217L69.6468 55.8533C69.6476 55.9045 69.6625 55.9545 69.6897 55.9979C69.717 56.0412 69.7556 56.0763 69.8014 56.0992C69.8472 56.1221 69.8984 56.1319 69.9494 56.1277C70.0005 56.1235 70.0494 56.1054 70.0908 56.0753L74.1734 53.7177C74.283 53.647 74.3709 53.5474 74.4273 53.4298C74.4837 53.3123 74.5065 53.1814 74.4931 53.0517Z"
            fill="#EBEBEB"
        />
        <path
            d="M80.8158 49.402L80.2564 45.4704C80.2556 45.4192 80.2407 45.3692 80.2135 45.3259C80.1862 45.2825 80.1476 45.2475 80.1018 45.2246C80.056 45.2017 80.0048 45.1918 79.9537 45.196C79.9027 45.2002 79.8538 45.2183 79.8124 45.2484L75.7098 47.626C75.6005 47.6971 75.5127 47.7968 75.456 47.9142C75.3993 48.0316 75.3757 48.1622 75.3879 48.292L75.9496 52.2237C75.9504 52.2749 75.9652 52.3249 75.9925 52.3682C76.0197 52.4115 76.0584 52.4466 76.1041 52.4695C76.1499 52.4924 76.2011 52.5023 76.2522 52.4981C76.3032 52.4939 76.3521 52.4757 76.3936 52.4457L80.485 50.068C80.5968 49.999 80.6871 49.9 80.7456 49.7823C80.804 49.6646 80.8283 49.5329 80.8158 49.402Z"
            fill="#EBEBEB"
        />
        <path
            d="M87.1221 45.7699L86.5627 41.8405C86.5619 41.7893 86.547 41.7393 86.5198 41.696C86.4925 41.6526 86.4539 41.6176 86.4081 41.5947C86.3623 41.5718 86.3111 41.5619 86.26 41.5661C86.209 41.5703 86.1601 41.5885 86.1187 41.6185L82.0361 43.974C81.9266 44.0449 81.8387 44.1445 81.782 44.2619C81.7252 44.3794 81.7017 44.5101 81.7142 44.64L82.2758 48.5716C82.2766 48.6228 82.2915 48.6728 82.3187 48.7161C82.346 48.7595 82.3846 48.7945 82.4304 48.8174C82.4762 48.8403 82.5274 48.8502 82.5785 48.846C82.6295 48.8418 82.6784 48.8237 82.7198 48.7936L86.8113 46.4315C86.9187 46.3603 87.0045 46.2609 87.0593 46.1442C87.1141 46.0276 87.1358 45.8981 87.1221 45.7699Z"
            fill="#EBEBEB"
        />
        <path
            d="M48.1421 59.9203L47.5805 55.9909C47.5805 55.9393 47.5661 55.8888 47.539 55.845C47.5119 55.8012 47.4732 55.7657 47.4271 55.7427C47.381 55.7196 47.3294 55.7099 47.2781 55.7145C47.2267 55.7191 47.1777 55.738 47.1365 55.7689L43.0539 58.1243C42.9441 58.1949 42.8559 58.2944 42.7991 58.4119C42.7423 58.5295 42.7191 58.6604 42.732 58.7903L43.2981 62.7219C43.2981 62.7735 43.3125 62.824 43.3395 62.8678C43.3666 62.9116 43.4054 62.9471 43.4515 62.9701C43.4976 62.9932 43.5492 63.0029 43.6005 62.9983C43.6519 62.9937 43.7009 62.9748 43.7421 62.9439L47.8247 60.5863C47.9341 60.5155 48.0216 60.4158 48.0777 60.2982C48.1337 60.1806 48.1561 60.0498 48.1421 59.9203Z"
            fill="#8C53C5"
        />
        <path
            d="M54.4568 56.2838L53.8952 52.3544C53.8952 52.3029 53.8808 52.2524 53.8537 52.2085C53.8267 52.1647 53.7879 52.1292 53.7418 52.1062C53.6957 52.0832 53.6441 52.0734 53.5928 52.078C53.5414 52.0827 53.4924 52.1015 53.4512 52.1324L49.3686 54.49C49.2584 54.5602 49.1699 54.6597 49.1131 54.7773C49.0562 54.895 49.0333 55.0261 49.0467 55.156L49.6084 59.0854C49.6084 59.137 49.6227 59.1875 49.6498 59.2313C49.6769 59.2752 49.7157 59.3106 49.7618 59.3336C49.8079 59.3567 49.8595 59.3664 49.9108 59.3618C49.9621 59.3572 50.0111 59.3384 50.0524 59.3074L54.1349 56.9498C54.2451 56.8796 54.3336 56.7801 54.3905 56.6625C54.4473 56.5449 54.4703 56.4138 54.4568 56.2838Z"
            fill="#EBEBEB"
        />
        <path
            d="M60.7693 52.641L60.2077 48.7094C60.2077 48.6578 60.1933 48.6073 60.1662 48.5635C60.1391 48.5197 60.1004 48.4842 60.0543 48.4612C60.0082 48.4381 59.9566 48.4284 59.9053 48.433C59.8539 48.4376 59.8049 48.4565 59.7637 48.4874L55.6811 50.845C55.5709 50.9152 55.4824 51.0147 55.4256 51.1323C55.3687 51.2499 55.3458 51.3811 55.3592 51.511L55.9209 55.4404C55.9209 55.492 55.9352 55.5425 55.9623 55.5863C55.9894 55.6302 56.0282 55.6656 56.0743 55.6886C56.1204 55.7117 56.172 55.7214 56.2233 55.7168C56.2746 55.7122 56.3236 55.6933 56.3649 55.6624L60.4474 53.307C60.5573 53.2364 60.6455 53.1369 60.7023 53.0194C60.7591 52.9018 60.7823 52.7709 60.7693 52.641Z"
            fill="#EBEBEB"
        />
        <path
            d="M67.0838 49.0001L66.5221 45.0685C66.5221 45.017 66.5078 44.9665 66.4807 44.9226C66.4536 44.8788 66.4148 44.8434 66.3687 44.8203C66.3226 44.7973 66.271 44.7875 66.2197 44.7921C66.1684 44.7968 66.1194 44.8156 66.0781 44.8465L61.9956 47.2042C61.8854 47.2743 61.7969 47.3738 61.74 47.4915C61.6832 47.6091 61.6602 47.7402 61.6737 47.8702L62.2331 51.8018C62.2339 51.853 62.2487 51.903 62.276 51.9463C62.3033 51.9897 62.3419 52.0247 62.3877 52.0476C62.4335 52.0705 62.4847 52.0804 62.5357 52.0762C62.5868 52.072 62.6357 52.0539 62.6771 52.0238L66.7597 49.6661C66.8699 49.5959 66.9585 49.4964 67.0158 49.3789C67.073 49.2613 67.0965 49.1302 67.0838 49.0001Z"
            fill="#EBEBEB"
        />
        <path
            d="M73.3963 45.3593L72.8346 41.4276C72.8346 41.3761 72.8203 41.3256 72.7932 41.2818C72.7661 41.2379 72.7273 41.2025 72.6812 41.1794C72.6351 41.1564 72.5836 41.1466 72.5322 41.1513C72.4809 41.1559 72.4319 41.1747 72.3906 41.2056L68.3081 43.5633C68.1979 43.6335 68.1094 43.733 68.0525 43.8506C67.9957 43.9682 67.9727 44.0993 67.9862 44.2293L68.5456 48.1609C68.5464 48.2121 68.5612 48.2621 68.5885 48.3054C68.6158 48.3488 68.6544 48.3838 68.7002 48.4067C68.746 48.4296 68.7972 48.4395 68.8482 48.4353C68.8993 48.4311 68.9482 48.413 68.9896 48.3829L73.0722 46.0253C73.1826 45.9552 73.2714 45.8558 73.3286 45.7382C73.3858 45.6206 73.4093 45.4894 73.3963 45.3593Z"
            fill="#EBEBEB"
        />
        <path
            d="M79.7067 41.7184L79.1451 37.7868C79.1451 37.7352 79.1307 37.6847 79.1036 37.6409C79.0765 37.5971 79.0378 37.5616 78.9917 37.5386C78.9456 37.5155 78.894 37.5058 78.8426 37.5104C78.7913 37.515 78.7423 37.5339 78.7011 37.5648L74.6185 39.9224C74.5085 39.9928 74.4201 40.0923 74.3633 40.2099C74.3065 40.3275 74.2834 40.4585 74.2966 40.5884L74.8582 44.5178C74.859 44.569 74.8739 44.619 74.9011 44.6624C74.9284 44.7057 74.967 44.7407 75.0128 44.7636C75.0586 44.7865 75.1098 44.7964 75.1609 44.7922C75.2119 44.788 75.2608 44.7699 75.3022 44.7398L79.3848 42.3822C79.4945 42.312 79.5826 42.2128 79.6394 42.0957C79.6962 41.9785 79.7195 41.848 79.7067 41.7184Z"
            fill="#EBEBEB"
        />
        <path
            d="M86.0251 38.08L85.4634 34.1483C85.4634 34.0968 85.4491 34.0463 85.422 34.0025C85.3949 33.9586 85.3561 33.9232 85.31 33.9001C85.2639 33.8771 85.2123 33.8673 85.161 33.872C85.1097 33.8766 85.0606 33.8954 85.0194 33.9263L80.9368 36.284C80.8268 36.3544 80.7385 36.4539 80.6817 36.5715C80.6249 36.689 80.6017 36.8201 80.6149 36.95L81.1744 40.8816C81.1752 40.9328 81.19 40.9828 81.2173 41.0262C81.2445 41.0695 81.2832 41.1045 81.329 41.1274C81.3748 41.1503 81.426 41.1602 81.477 41.156C81.528 41.1518 81.5769 41.1337 81.6184 41.1036L85.701 38.746C85.8114 38.6759 85.9001 38.5765 85.9574 38.4589C86.0146 38.3413 86.038 38.2101 86.0251 38.08Z"
            fill="#EBEBEB"
        />
        <path
            d="M47.1722 52.9716L47.0301 51.9682C47.0301 51.9166 47.0157 51.8661 46.9886 51.8223C46.9616 51.7784 46.9228 51.743 46.8767 51.72C46.8306 51.6969 46.779 51.6872 46.7277 51.6918C46.6763 51.6964 46.6273 51.7153 46.5861 51.7462L42.5013 54.1083C42.3913 54.1786 42.303 54.2781 42.2461 54.3957C42.1893 54.5133 42.1662 54.6443 42.1794 54.7743L42.3193 55.7777C42.3201 55.8289 42.3349 55.8789 42.3622 55.9222C42.3894 55.9656 42.428 56.0006 42.4738 56.0235C42.5196 56.0464 42.5708 56.0563 42.6219 56.0521C42.6729 56.0479 42.7218 56.0298 42.7633 55.9997L46.8503 53.6332C46.959 53.5626 47.0465 53.4637 47.1032 53.3471C47.1599 53.2306 47.1838 53.1007 47.1722 52.9716Z"
            fill="#E6E6E6"
        />
        <path
            d="M53.4866 49.331L53.3445 48.3275C53.3445 48.276 53.3302 48.2255 53.3031 48.1817C53.276 48.1378 53.2372 48.1024 53.1912 48.0793C53.1451 48.0563 53.0935 48.0465 53.0421 48.0512C52.9908 48.0558 52.9418 48.0746 52.9005 48.1055L48.8157 50.4676C48.7057 50.538 48.6174 50.6375 48.5606 50.7551C48.5038 50.8727 48.4807 51.0037 48.4938 51.1336L48.6337 52.1371C48.6345 52.1883 48.6493 52.2383 48.6766 52.2816C48.7039 52.325 48.7425 52.36 48.7883 52.3829C48.8341 52.4058 48.8853 52.4157 48.9363 52.4115C48.9874 52.4073 49.0363 52.3891 49.0777 52.3591L53.1625 50.0014C53.2731 49.9304 53.3619 49.8302 53.4191 49.7119C53.4763 49.5936 53.4997 49.4618 53.4866 49.331Z"
            fill="#E6E6E6"
        />
        <path
            d="M59.7991 45.6902L59.657 44.6867C59.6562 44.6355 59.6414 44.5855 59.6141 44.5422C59.5869 44.4988 59.5483 44.4638 59.5025 44.4409C59.4567 44.418 59.4055 44.4081 59.3544 44.4123C59.3034 44.4165 59.2545 44.4346 59.213 44.4647L55.1282 46.8268C55.0182 46.8972 54.9299 46.9967 54.8731 47.1143C54.8163 47.2318 54.7932 47.3629 54.8063 47.4928L54.9462 48.4962C54.9462 48.5478 54.9606 48.5983 54.9877 48.6421C55.0147 48.686 55.0535 48.7214 55.0996 48.7444C55.1457 48.7675 55.1973 48.7773 55.2486 48.7726C55.3 48.768 55.349 48.7492 55.3902 48.7182L59.475 46.3606C59.5856 46.2896 59.6744 46.1894 59.7316 46.0711C59.7888 45.9528 59.8122 45.8209 59.7991 45.6902Z"
            fill="#E6E6E6"
        />
        <path
            d="M66.1136 42.0515L65.9715 41.0459C65.9707 40.9947 65.9559 40.9447 65.9286 40.9013C65.9013 40.858 65.8627 40.8229 65.8169 40.8C65.7711 40.7771 65.7199 40.7673 65.6689 40.7715C65.6178 40.7757 65.5689 40.7938 65.5275 40.8239L61.4427 43.1859C61.3327 43.2563 61.2444 43.3558 61.1875 43.4734C61.1307 43.591 61.1076 43.722 61.1208 43.8519L61.2607 44.8554C61.2607 44.9069 61.275 44.9574 61.3021 45.0013C61.3292 45.0451 61.368 45.0805 61.4141 45.1036C61.4602 45.1266 61.5118 45.1364 61.5631 45.1318C61.6144 45.1271 61.6634 45.1083 61.7047 45.0774L65.7895 42.722C65.8997 42.6506 65.9882 42.5503 66.0454 42.4321C66.1025 42.3139 66.1261 42.1822 66.1136 42.0515Z"
            fill="#E6E6E6"
        />
        <path
            d="M72.4279 38.4106L72.2858 37.405C72.285 37.3538 72.2702 37.3038 72.2429 37.2604C72.2157 37.2171 72.177 37.1821 72.1312 37.1592C72.0854 37.1363 72.0342 37.1264 71.9832 37.1306C71.9322 37.1348 71.8833 37.1529 71.8418 37.183L67.757 39.5451C67.6472 39.6156 67.559 39.7152 67.5022 39.8327C67.4454 39.9502 67.4222 40.0812 67.4351 40.2111L67.575 41.2145C67.575 41.266 67.5893 41.3166 67.6164 41.3604C67.6435 41.4042 67.6823 41.4397 67.7284 41.4627C67.7745 41.4858 67.8261 41.4955 67.8774 41.4909C67.9287 41.4863 67.9778 41.4674 68.019 41.4365L72.1038 39.0811C72.214 39.0097 72.3025 38.9095 72.3597 38.7912C72.4169 38.673 72.4404 38.5414 72.4279 38.4106Z"
            fill="#E6E6E6"
        />
        <path
            d="M78.7404 34.77L78.5983 33.7665C78.5983 33.715 78.584 33.6645 78.5569 33.6206C78.5298 33.5768 78.491 33.5414 78.4449 33.5183C78.3988 33.4953 78.3472 33.4855 78.2959 33.4901C78.2446 33.4948 78.1956 33.5136 78.1543 33.5445L74.0695 35.9044C73.9597 35.9749 73.8715 36.0745 73.8147 36.192C73.7579 36.3095 73.7347 36.4405 73.7476 36.5704L73.8875 37.576C73.8883 37.6272 73.9031 37.6772 73.9304 37.7206C73.9576 37.7639 73.9963 37.799 74.0421 37.8218C74.0879 37.8447 74.1391 37.8546 74.1901 37.8504C74.2411 37.8462 74.2901 37.8281 74.3315 37.798L78.4163 35.4404C78.5267 35.3692 78.6154 35.269 78.6725 35.1507C78.7297 35.0324 78.7532 34.9007 78.7404 34.77Z"
            fill="#E6E6E6"
        />
        <path
            d="M85.0526 31.1291L84.9128 30.1256C84.9128 30.0741 84.8984 30.0236 84.8713 29.9798C84.8442 29.9359 84.8055 29.9005 84.7594 29.8774C84.7133 29.8544 84.6617 29.8446 84.6103 29.8493C84.559 29.8539 84.51 29.8727 84.4688 29.9036L80.3862 32.2613C80.276 32.3315 80.1875 32.431 80.1307 32.5486C80.0738 32.6662 80.0508 32.7973 80.0643 32.9273L80.2041 33.9307C80.2049 33.9819 80.2198 34.0319 80.247 34.0753C80.2743 34.1186 80.3129 34.1536 80.3587 34.1765C80.4045 34.1994 80.4557 34.2093 80.5068 34.2051C80.5578 34.2009 80.6067 34.1828 80.6481 34.1527L84.733 31.7951C84.8424 31.7242 84.9301 31.6246 84.9865 31.5071C85.0429 31.3896 85.0658 31.2588 85.0526 31.1291Z"
            fill="#E6E6E6"
        />
        <path
            opacity="0.2"
            d="M42.41 47.0444C42.3899 46.751 42.4505 46.4578 42.5851 46.1964C42.7197 45.935 42.9232 45.7154 43.1737 45.5614C43.671 45.275 44.135 45.4726 44.2127 46.0054C44.2333 46.2991 44.1727 46.5927 44.0376 46.8543C43.9025 47.1158 43.6981 47.3352 43.4468 47.4884C42.9517 47.7748 42.4877 47.5772 42.41 47.0444Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M48.7305 43.4079C48.7099 43.1145 48.7703 42.821 48.9049 42.5596C49.0396 42.2981 49.2434 42.0786 49.4942 41.9249C49.9915 41.6363 50.4554 41.8339 50.5331 42.3689C50.5536 42.6622 50.4929 42.9555 50.3578 43.2167C50.2228 43.4778 50.0184 43.6968 49.7672 43.8496C49.27 44.1271 48.806 43.9296 48.7305 43.4079Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M55.0371 39.756C55.0166 39.4626 55.0769 39.1692 55.2115 38.9077C55.3462 38.6462 55.55 38.4267 55.8008 38.273C56.2981 37.9844 56.7643 38.182 56.8398 38.717C56.8602 39.0101 56.7998 39.3032 56.6651 39.5643C56.5305 39.8254 56.3267 40.0445 56.0761 40.1978C55.5788 40.4842 55.1126 40.2866 55.0371 39.756Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M67.6602 32.4677C67.6396 32.1743 67.6999 31.8809 67.8346 31.6194C67.9693 31.3579 68.1731 31.1384 68.4239 30.9848C68.9211 30.6984 69.3851 30.896 69.4606 31.4288C69.4822 31.7223 69.4223 32.0161 69.2876 32.2778C69.1528 32.5394 68.9484 32.7588 68.6969 32.9117C68.1997 33.1959 67.7357 33.0005 67.6602 32.4677Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M73.9787 28.8224C73.9577 28.5289 74.0178 28.2353 74.1525 27.9738C74.2872 27.7122 74.4913 27.4927 74.7424 27.3395C75.2397 27.0509 75.7037 27.2484 75.7814 27.7835C75.802 28.0771 75.7414 28.3708 75.6063 28.6323C75.4713 28.8939 75.2668 29.1132 75.0155 29.2664C74.5182 29.5506 74.0542 29.353 73.9787 28.8224Z"
            fill="black"
        />
        <path
            opacity="0.2"
            d="M80.2852 25.1796C80.2646 24.8862 80.3249 24.5928 80.4596 24.3313C80.5943 24.0698 80.7981 23.8503 81.0489 23.6966C81.5461 23.408 82.0124 23.6056 82.0878 24.1406C82.1082 24.4337 82.0478 24.7267 81.9132 24.9878C81.7785 25.2489 81.5748 25.4681 81.3242 25.6214C80.8158 25.9078 80.3718 25.7102 80.2852 25.1796Z"
            fill="black"
        />
        <path
            d="M41.7584 41.046C41.0924 40.6575 40.4398 40.602 39.9425 40.8883C39.4452 41.1747 39.1699 41.7608 39.1699 42.54C39.1743 42.9045 39.228 43.2667 39.3298 43.6167L39.7249 43.3947C39.654 43.1168 39.6167 42.8313 39.6139 42.5445C39.6139 41.9317 39.8093 41.4811 40.1645 41.2768C40.3102 41.1955 40.4749 41.1541 40.6418 41.157C40.9566 41.172 41.2624 41.2675 41.5298 41.4345C42.7108 42.1182 43.6721 43.7832 43.6721 45.1463C43.6727 45.2435 43.6676 45.3406 43.6565 45.4371C43.6511 45.6304 43.6 45.8197 43.5074 45.9895C43.4148 46.1592 43.2833 46.3047 43.1237 46.4139C42.9202 46.5238 42.6854 46.5614 42.4577 46.5205C42.4129 46.664 42.3941 46.8144 42.4022 46.9645C42.4852 46.9777 42.5691 46.9843 42.6531 46.9845C42.8976 46.9875 43.1384 46.9246 43.3502 46.8024C43.5484 46.6781 43.7164 46.5112 43.842 46.3138C43.9675 46.1164 44.0475 45.8935 44.0761 45.6613C44.106 45.4928 44.1209 45.3219 44.1205 45.1507C44.1227 43.63 43.0616 41.7985 41.7584 41.046Z"
            fill="#F5F5F5"
        />
        <path
            d="M48.0768 37.4007C47.4108 37.0122 46.7581 36.9567 46.2608 37.2431C45.7636 37.5295 45.4883 38.1155 45.4883 38.8948C45.4926 39.2592 45.5464 39.6214 45.6481 39.9715L46.0433 39.7495C45.9723 39.4715 45.9351 39.186 45.9323 38.8992C45.9323 38.2865 46.1276 37.8358 46.4828 37.6316C46.6286 37.5502 46.7933 37.5088 46.9601 37.5117C47.275 37.5267 47.5808 37.6223 47.8481 37.7892C49.0292 38.473 49.9904 40.138 49.9904 41.501C49.9911 41.5982 49.9859 41.6953 49.9749 41.7919C49.9695 41.9852 49.9184 42.1744 49.8258 42.3442C49.7332 42.514 49.6017 42.6594 49.4421 42.7687C49.2386 42.8786 49.0038 42.9161 48.7761 42.8752C48.7313 43.0187 48.7125 43.1691 48.7206 43.3192C48.8036 43.3324 48.8874 43.3391 48.9715 43.3392C49.216 43.3422 49.4568 43.2794 49.6685 43.1572C49.8667 43.0329 50.0347 42.8659 50.1603 42.6686C50.2859 42.4712 50.3659 42.2483 50.3945 42.0161C50.4244 41.8475 50.4393 41.6767 50.4389 41.5055C50.4411 39.9914 49.3799 38.1533 48.0768 37.4007Z"
            fill="#F5F5F5"
        />
        <path
            d="M54.3834 33.7576C53.7174 33.3691 53.0648 33.3136 52.5675 33.6C52.0702 33.8864 51.7949 34.4725 51.7949 35.2517C51.7993 35.6162 51.853 35.9784 51.9548 36.3284L52.3499 36.1064C52.279 35.8284 52.2417 35.543 52.2389 35.2561C52.2389 34.6434 52.4343 34.1927 52.7895 33.9885C52.9352 33.9071 53.0999 33.8658 53.2668 33.8686C53.5816 33.8836 53.8874 33.9792 54.1548 34.1461C55.3358 34.8299 56.2971 36.4949 56.2971 37.858C56.2977 37.9551 56.2925 38.0522 56.2815 38.1488C56.2761 38.3421 56.225 38.5314 56.1324 38.7011C56.0398 38.8709 55.9083 39.0164 55.7487 39.1256C55.5452 39.2355 55.3104 39.2731 55.0827 39.2321C55.0379 39.3757 55.0191 39.526 55.0272 39.6761C55.1102 39.6893 55.1941 39.696 55.2781 39.6961C55.5226 39.6992 55.7634 39.6363 55.9752 39.5141C56.1734 39.3898 56.3414 39.2229 56.467 39.0255C56.5925 38.8281 56.6725 38.6052 56.7011 38.373C56.731 38.2045 56.7459 38.0336 56.7455 37.8624C56.7477 36.3484 55.6866 34.5102 54.3834 33.7576Z"
            fill="#F5F5F5"
        />
        <path
            d="M67.0084 26.4805C66.3424 26.092 65.6898 26.0365 65.1925 26.3229C64.6952 26.6093 64.4199 27.1954 64.4199 27.9746C64.4243 28.3391 64.478 28.7013 64.5798 29.0513L64.9749 28.8293C64.904 28.5513 64.8667 28.2659 64.8639 27.979C64.8639 27.3663 65.0593 26.9156 65.4145 26.7114C65.5602 26.63 65.7249 26.5887 65.8918 26.5915C66.2066 26.6065 66.5124 26.7021 66.7798 26.869C67.9608 27.5528 68.9221 29.2178 68.9221 30.5809C68.9227 30.678 68.9176 30.7751 68.9065 30.8717C68.9011 31.065 68.85 31.2543 68.7574 31.424C68.6648 31.5938 68.5333 31.7393 68.3737 31.8485C68.1702 31.9584 67.9354 31.996 67.7077 31.955C67.6629 32.0986 67.6441 32.2489 67.6522 32.399C67.7352 32.4122 67.8191 32.4189 67.9031 32.419C68.1476 32.4221 68.3884 32.3592 68.6002 32.237C68.7984 32.1127 68.9664 31.9458 69.092 31.7484C69.2175 31.551 69.2975 31.3281 69.3261 31.0959C69.356 30.9274 69.3709 30.7565 69.3705 30.5853C69.3727 29.0713 68.3116 27.2331 67.0084 26.4805Z"
            fill="#F5F5F5"
        />
        <path
            d="M73.3268 22.8331C72.6608 22.4446 72.0081 22.3891 71.5108 22.6754C71.0136 22.9618 70.7383 23.5479 70.7383 24.3271C70.7426 24.6916 70.7964 25.0538 70.8981 25.4038L71.2933 25.1818C71.2223 24.9039 71.1851 24.6184 71.1823 24.3316C71.1823 23.7188 71.3776 23.2682 71.7328 23.0639C71.8786 22.9826 72.0433 22.9412 72.2101 22.9441C72.525 22.9591 72.8308 23.0546 73.0981 23.2216C74.2792 23.9053 75.2404 25.5703 75.2404 26.9334C75.2411 27.0306 75.2359 27.1277 75.2249 27.2242C75.2195 27.4175 75.1684 27.6068 75.0757 27.7766C74.9831 27.9464 74.8517 28.0918 74.6921 28.201C74.4886 28.311 74.2538 28.3485 74.0261 28.3076C73.9813 28.4511 73.9625 28.6015 73.9706 28.7516C74.0536 28.7648 74.1374 28.7714 74.2215 28.7716C74.466 28.7746 74.7068 28.7117 74.9185 28.5895C75.1167 28.4652 75.2847 28.2983 75.4103 28.1009C75.5359 27.9035 75.6159 27.6806 75.6445 27.4484C75.6744 27.2799 75.6893 27.109 75.6889 26.9378C75.6911 25.426 74.6299 23.5856 73.3268 22.8331Z"
            fill="#F5F5F5"
        />
        <path
            d="M79.6337 19.19C78.9677 18.8015 78.315 18.746 77.8177 19.0324C77.3205 19.3188 77.043 19.9048 77.043 20.6841C77.0473 21.0485 77.1011 21.4107 77.2028 21.7608L77.598 21.5388C77.527 21.2608 77.4898 20.9753 77.487 20.6885C77.487 20.0758 77.6823 19.6251 78.0375 19.4209C78.1833 19.3395 78.3479 19.2981 78.5148 19.301C78.8297 19.316 79.1354 19.4116 79.4028 19.5785C80.5839 20.2623 81.5451 21.9273 81.5451 23.2903C81.5458 23.3875 81.5406 23.4846 81.5296 23.5812C81.5242 23.7745 81.473 23.9637 81.3804 24.1335C81.2878 24.3033 81.1564 24.4487 80.9968 24.558C80.7933 24.6679 80.5584 24.7055 80.3308 24.6645C80.286 24.808 80.2672 24.9584 80.2753 25.1085C80.3583 25.1217 80.4421 25.1284 80.5261 25.1285C80.7706 25.1315 81.0114 25.0687 81.2232 24.9465C81.4214 24.8222 81.5894 24.6553 81.715 24.4579C81.8406 24.2605 81.9206 24.0376 81.9492 23.8054C81.9791 23.6368 81.9939 23.466 81.9936 23.2948C81.998 21.7807 80.9369 19.9426 79.6337 19.19Z"
            fill="#F5F5F5"
        />
        <path
            d="M86.1927 76.93L84.7008 79.5207C84.6028 79.6826 84.5731 79.8768 84.6183 80.0606C84.6635 80.2445 84.7798 80.4028 84.9417 80.5008C85.1036 80.5989 85.2978 80.6286 85.4817 80.5834C85.6655 80.5382 85.8238 80.4219 85.9218 80.26L87.4226 77.6537C87.5669 77.405 87.4115 77.0432 87.074 76.8478C86.7366 76.6525 86.337 76.688 86.1927 76.93Z"
            fill="#455A64"
        />
        <path
            d="M98.0653 72.368L100.656 73.8599C100.736 73.9092 100.824 73.9421 100.917 73.9568C101.009 73.9715 101.104 73.9677 101.195 73.9455C101.286 73.9233 101.371 73.8832 101.447 73.8276C101.522 73.772 101.586 73.7019 101.634 73.6215C101.682 73.5411 101.713 73.4519 101.727 73.3592C101.74 73.2665 101.734 73.1721 101.711 73.0814C101.687 72.9908 101.646 72.9058 101.589 72.8313C101.532 72.7569 101.461 72.6945 101.38 72.6477L98.7735 71.1581C98.5248 71.0138 98.163 71.1692 97.9676 71.5067C97.7722 71.8441 97.8166 72.2237 98.0653 72.368Z"
            fill="#455A64"
        />
        <path
            d="M100.937 60.6884H100.923L94.612 57.0454H94.5943C93.209 56.2573 91.2998 56.3794 89.193 57.5915C84.9595 60.0335 81.5273 65.9809 81.5273 70.8694C81.5273 73.3114 82.382 75.0185 83.7607 75.8222H83.7718L90.0832 79.4674H90.0988C91.484 80.26 93.3955 80.1334 95.5045 78.9235C99.7358 76.4815 103.168 70.5341 103.168 65.6479C103.168 63.2059 102.315 61.4921 100.937 60.6884Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M89.9799 79.3942L83.7639 75.8111H83.7506C82.372 75.0053 81.5195 73.2914 81.5195 70.8583C81.5195 68.6783 82.2033 66.2896 83.3333 64.0962L89.6447 67.7392C88.5125 69.9326 87.831 72.3213 87.831 74.5013C87.8354 76.8767 88.6524 78.5684 89.9799 79.3942Z"
            fill="black"
        />
        <path
            opacity="0.4"
            d="M100.924 73.1954C103.917 68.0106 103.917 62.4066 100.924 60.6784C97.9308 58.9503 93.0776 61.7524 90.0842 66.9373C87.0907 72.1221 87.0906 77.7261 90.0838 79.4543C93.077 81.1824 97.9302 78.3802 100.924 73.1954Z"
            fill="white"
        />
        <path
            d="M92.0788 78.4109C89.6545 78.4109 89.3926 75.6714 89.3926 74.4926C89.3926 70.1813 92.5472 64.7246 96.2746 62.5623C97.2247 62.0139 98.1394 61.7253 98.9208 61.7253C101.345 61.7253 101.607 64.4648 101.607 65.6436C101.607 69.9549 98.4524 75.4116 94.725 77.5739C93.7726 78.1223 92.858 78.4109 92.0788 78.4109Z"
            fill="#FAFAFA"
        />
        <path
            d="M93.8035 75.0631C93.7917 75.0653 93.7797 75.0653 93.7679 75.0631C93.7402 75.0531 93.7175 75.0326 93.7047 75.0061C93.6918 74.9795 93.6898 74.949 93.6991 74.9211L95.3974 70.0704C95.4071 70.0424 95.4276 70.0194 95.4542 70.0065C95.4809 69.9936 95.5115 69.9918 95.5395 70.0015C95.5675 70.0113 95.5904 70.0317 95.6033 70.0583C95.6162 70.085 95.618 70.1157 95.6083 70.1436L93.9145 74.9877C93.9071 75.0108 93.8921 75.0309 93.872 75.0446C93.8519 75.0582 93.8277 75.0648 93.8035 75.0631Z"
            fill="#8C53C5"
        />
        <path
            d="M98.047 71.5332C98.002 71.534 97.9573 71.5249 97.9161 71.5065L95.1699 70.3188V65.3372C95.1699 65.2488 95.205 65.1641 95.2675 65.1017C95.3299 65.0392 95.4146 65.0042 95.5029 65.0042C95.5912 65.0042 95.6759 65.0392 95.7384 65.1017C95.8008 65.1641 95.8359 65.2488 95.8359 65.3372V69.8815L98.1802 70.896C98.2497 70.927 98.3065 70.9808 98.3411 71.0486C98.3758 71.1163 98.3862 71.1939 98.3706 71.2683C98.355 71.3428 98.3144 71.4097 98.2556 71.4579C98.1967 71.506 98.1231 71.5326 98.047 71.5332Z"
            fill="#37474F"
        />
        <path
            d="M95.5032 69.0269C96.0004 68.7383 96.4045 68.9714 96.4045 69.5464C96.386 69.8578 96.2948 70.1606 96.1383 70.4304C95.9818 70.7003 95.7643 70.9297 95.5032 71.1004C95.0037 71.389 94.5996 71.1559 94.5996 70.5787C94.6187 70.2675 94.7103 69.965 94.8672 69.6956C95.0241 69.4261 95.2419 69.1971 95.5032 69.0269Z"
            fill="#8C53C5"
        />
        <path
            d="M88.169 65.4526C87.0812 67.3329 85.0321 68.1854 83.4914 67.2952C82.5876 66.7726 81.9285 65.9125 81.6589 64.9039C81.3893 63.8953 81.5314 62.821 82.054 61.9172C82.5765 61.0134 83.4367 60.3543 84.4453 60.0847C85.4538 59.8151 86.5281 59.9572 87.4319 60.4798C88.9682 61.39 89.2523 63.57 88.169 65.4526Z"
            fill="#455A64"
        />
        <path
            d="M83.4911 67.2953C85.0318 68.1833 87.0809 67.3331 88.1687 65.4527C88.4117 65.0312 88.5919 64.5765 88.7037 64.103C88.6415 64.1851 86.83 66.4939 84.579 65.8434C82.2879 65.1774 81.8572 63.417 82.0548 61.9163C81.7958 62.3637 81.6275 62.8579 81.5596 63.3704C81.4916 63.8829 81.5252 64.4038 81.6586 64.9033C81.792 65.4029 82.0225 65.8712 82.3369 66.2816C82.6514 66.6921 83.0436 67.0365 83.4911 67.2953Z"
            fill="#37474F"
        />
        <path
            d="M82.8585 61.5344L81.6908 60.8684C81.6112 60.8191 81.5225 60.7861 81.43 60.7714C81.3375 60.7567 81.243 60.7606 81.152 60.7828C81.061 60.805 80.9754 60.845 80.9 60.9007C80.8247 60.9563 80.7611 61.0263 80.7131 61.1067C80.6651 61.1872 80.6336 61.2763 80.6203 61.3691C80.6071 61.4618 80.6125 61.5562 80.6361 61.6468C80.6598 61.7375 80.7012 61.8225 80.758 61.8969C80.8149 61.9714 80.8859 62.0338 80.9671 62.0805L82.1481 62.7643C82.399 62.9086 82.7609 62.7532 82.9562 62.4157C83.1516 62.0783 83.1072 61.6787 82.8585 61.5344Z"
            fill="#8C53C5"
        />
        <path
            d="M100.278 55.9022L100.944 54.7367C100.993 54.6571 101.026 54.5684 101.041 54.4759C101.055 54.3834 101.051 54.2889 101.029 54.1979C101.007 54.1069 100.967 54.0213 100.911 53.9459C100.856 53.8706 100.786 53.807 100.705 53.759C100.625 53.711 100.536 53.6795 100.443 53.6662C100.35 53.653 100.256 53.6584 100.165 53.682C100.075 53.7057 99.9895 53.7471 99.915 53.8039C99.8406 53.8608 99.7782 53.9318 99.7315 54.013L99.0477 55.194C98.9034 55.4427 99.0477 55.8045 99.3962 55.9999C99.7448 56.1953 100.131 56.1531 100.278 55.9022Z"
            fill="#8C53C5"
        />
        <path
            d="M94.6348 56.3839C94.8935 55.9357 95.238 55.5428 95.6486 55.2277C96.0591 54.9126 96.5278 54.6815 97.0277 54.5475C97.5276 54.4135 98.049 54.3792 98.5621 54.4468C99.0752 54.5143 99.57 54.6822 100.018 54.9409C100.466 55.1997 100.859 55.5441 101.174 55.9547C101.49 56.3653 101.721 56.8339 101.855 57.3338C101.989 57.8337 102.023 58.3551 101.955 58.8683C101.888 59.3814 101.72 59.8762 101.461 60.3244L94.6348 56.3839Z"
            fill="#455A64"
        />
        <path
            d="M100.597 55.3606C100.819 55.8246 101.423 57.7893 100.597 58.2777C99.771 58.7661 95.957 57.1455 95.957 57.1455L101.463 60.3245C101.921 59.5264 102.086 58.5928 101.927 57.686C101.769 56.7792 101.299 55.9564 100.597 55.3606Z"
            fill="#37474F"
        />
        <path
            d="M75.6814 66.6093C71.752 61.3279 70.46 60.7396 69.7207 60.5598L71.0172 64.1118L73.7123 67.5684"
            fill="#FFA8A7"
        />
        <path
            d="M69.3359 60.502C69.3359 60.502 70.1707 60.2489 71.3783 61.4321C72.586 62.6154 74.0423 64.3403 74.0423 64.3403C74.0423 64.3403 73.6494 65.75 72.4217 66.1874L70.6457 64.3403L69.3359 60.502Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M69.3359 60.502C69.3359 60.502 70.1707 60.2489 71.3783 61.4321C72.586 62.6154 74.0423 64.3403 74.0423 64.3403C74.0423 64.3403 73.6494 65.75 72.4217 66.1874L70.6457 64.3403L69.3359 60.502Z"
            fill="black"
        />
        <path
            d="M70.1025 57.5798C71.3149 57.2937 72.0658 56.0789 71.7796 54.8665C71.4935 53.6541 70.2787 52.9033 69.0663 53.1894C67.8539 53.4756 67.1031 54.6903 67.3892 55.9027C67.6754 57.1151 68.8902 57.866 70.1025 57.5798Z"
            fill="#263238"
        />
        <path
            d="M64.1839 96.2708H65.7734V93.5513H64.1839V96.2708Z"
            fill="#FFA8A7"
        />
        <path
            d="M69.3652 94.2949L70.9525 94.3504L71.0324 91.471L69.4429 91.4155L69.3652 94.2949Z"
            fill="#FFA8A7"
        />
        <path
            d="M64.2865 71.9883V82.9795C64.2488 83.4235 63.9269 84.1717 63.8425 86.0298C63.736 88.3097 64.0845 94.5835 64.0845 94.5835C64.0845 94.5835 64.8282 95.3227 65.8805 94.5835C65.8805 94.5835 67.2902 85.628 67.5766 83.3702C67.8252 81.3989 68.2426 76.0442 68.2426 76.0442L69.1927 84.1095C69.1927 84.1095 68.933 85.5902 68.8775 86.6336C68.822 87.677 69.3348 93.2559 69.3348 93.2559C69.5923 93.4167 69.8875 93.5073 70.1909 93.5186C70.4943 93.5299 70.7954 93.4615 71.0642 93.3203C71.0642 93.3203 72.152 84.962 72.3163 84.0185C72.4805 83.075 71.7191 71.9861 71.7191 71.9861L64.2865 71.9883Z"
            fill="#37474F"
        />
        <path
            d="M65.8125 61.0103C65.59 61.0412 65.3708 61.0924 65.1576 61.1635C64.7136 61.3344 64.5205 61.8717 64.4161 62.3445C64.3288 62.7412 64.2849 63.1461 64.2852 63.5522V71.9882C65.3952 73.2003 69.2846 73.7642 71.7177 71.9882C71.7177 71.9882 71.6711 64.5379 71.4957 63.3125C71.1783 61.1857 70.1637 60.4265 69.1381 60.5064L66.685 60.8571L65.8125 61.0103Z"
            fill="#8C53C5"
        />
        <path
            d="M65.6855 56.9856C65.6855 56.9856 66.0518 58.7017 66.1984 58.9836C66.2951 59.1566 66.4537 59.2867 66.6424 59.3477L66.6157 58.1378L65.6855 56.9856Z"
            fill="#263238"
        />
        <path
            d="M66.1445 54.5235C66.0165 54.509 65.8869 54.5236 65.7654 54.5662C65.6439 54.6089 65.5337 54.6786 65.443 54.77C65.1433 55.0785 65.312 56.042 65.6849 56.99L66.3754 57.0677L66.1445 54.5235Z"
            fill="#263238"
        />
        <path
            d="M66.5815 57.0632C66.4416 57.1476 66.2462 56.8812 66.0753 56.7036C65.9044 56.526 65.3494 56.2818 65.0719 56.8657C64.7944 57.4495 65.3161 58.2487 65.7534 58.3864C66.5082 58.6217 66.6214 58.1377 66.6214 58.1377L66.6791 60.8594C66.8457 61.2245 67.1002 61.5425 67.4199 61.785C67.7395 62.0275 68.1143 62.187 68.5106 62.2492C69.8426 62.429 69.5718 61.3279 69.1322 60.7862V59.9581C69.4683 60.0121 69.8092 60.03 70.149 60.0114C70.704 59.9248 71.0525 59.4875 71.2213 58.8881C71.4921 57.9224 71.5942 57.1454 71.3656 55.2473C71.1125 53.1694 68.7016 53.1472 67.3895 53.9686C66.0775 54.79 66.5815 57.0632 66.5815 57.0632Z"
            fill="#FFA8A7"
        />
        <path
            d="M66.5808 57.2963C66.5098 57.2963 66.2456 56.8523 66.0746 56.7036C65.8371 56.4816 66.1435 54.5235 66.1435 54.5235C66.0799 54.3545 66.067 54.1705 66.1064 53.9943C66.1459 53.818 66.2359 53.6571 66.3655 53.5312C66.6896 53.1716 67.2357 53.1338 68.1659 52.9873C68.6543 52.9096 69.3958 52.7653 69.8842 52.6832C70.4658 52.5744 70.9475 52.4612 71.3582 52.8164C71.5202 52.9555 71.6348 53.1417 71.6861 53.3489C71.7375 53.5562 71.7229 53.7743 71.6446 53.973C71.6023 54.0639 71.5461 54.1478 71.4781 54.2216C71.3605 54.3526 71.3405 54.5302 71.174 54.5835C71.174 54.5835 70.821 54.9032 69.9374 55.0097C69.1238 55.0706 68.3062 55.055 67.4954 54.9631C67.2379 54.9276 67.1691 55.0097 67.0315 55.6868C66.9138 56.284 66.8139 57.2919 66.5808 57.2963Z"
            fill="#263238"
        />
        <path
            d="M66.1472 54.6766L65.3125 54.2326C65.3389 54.1763 65.3765 54.126 65.423 54.0848C65.4694 54.0435 65.5238 54.0122 65.5828 53.9926C65.6419 53.9731 65.7042 53.9658 65.7661 53.9712C65.8281 53.9766 65.8882 53.9945 65.943 54.0239C66.0536 54.0864 66.1364 54.1886 66.1743 54.3098C66.2123 54.4311 66.2026 54.5623 66.1472 54.6766Z"
            fill="#263238"
        />
        <path
            d="M69.1302 59.958C69.1302 59.958 67.8936 59.7093 67.4607 59.4785C67.206 59.3465 66.997 59.1407 66.8613 58.8879C66.9273 59.1405 67.0427 59.3774 67.201 59.585C67.5184 59.9891 69.1302 60.2888 69.1302 60.2888V59.958Z"
            fill="#F28F8F"
        />
        <path
            d="M68.9131 56.9812C68.9114 57.0299 68.8953 57.0771 68.867 57.1168C68.8386 57.1565 68.7992 57.187 68.7537 57.2044C68.7081 57.2219 68.6584 57.2256 68.6108 57.215C68.5631 57.2044 68.5197 57.1801 68.4858 57.145C68.4519 57.1099 68.4291 57.0656 68.4202 57.0176C68.4113 56.9696 68.4167 56.9201 68.4358 56.8752C68.4548 56.8303 68.4867 56.7919 68.5273 56.765C68.568 56.7381 68.6157 56.7237 68.6645 56.7236C68.6978 56.7239 68.7308 56.7308 68.7614 56.744C68.792 56.7571 68.8197 56.7763 68.8428 56.8002C68.866 56.8242 68.8841 56.8526 68.8962 56.8836C68.9083 56.9147 68.914 56.9478 68.9131 56.9812Z"
            fill="#263238"
        />
        <path
            d="M68.6475 56.0464L68.117 56.3373C68.0798 56.2644 68.0719 56.1801 68.0951 56.1016C68.1182 56.0231 68.1705 55.9565 68.2413 55.9155C68.2764 55.8965 68.3149 55.8848 68.3547 55.8811C68.3944 55.8775 68.4344 55.8819 68.4724 55.8941C68.5104 55.9064 68.5455 55.9262 68.5756 55.9524C68.6057 55.9785 68.6302 56.0105 68.6475 56.0464Z"
            fill="#263238"
        />
        <path
            d="M69.02 58.4685L69.5861 58.6905C69.5739 58.7285 69.5541 58.7636 69.5279 58.7938C69.5018 58.8239 69.4698 58.8484 69.4339 58.8659C69.398 58.8833 69.359 58.8933 69.3191 58.8952C69.2792 58.8971 69.2394 58.8909 69.202 58.877C69.163 58.8625 69.1272 58.8403 69.0968 58.8118C69.0665 58.7833 69.0421 58.749 69.0252 58.711C69.0082 58.673 68.999 58.6319 68.9981 58.5903C68.9972 58.5486 69.0046 58.5072 69.02 58.4685Z"
            fill="#F28F8F"
        />
        <path
            d="M71.1246 56.253L70.6406 55.8801C70.6633 55.8472 70.6925 55.8193 70.7264 55.7981C70.7603 55.7769 70.7981 55.7628 70.8376 55.7568C70.8771 55.7507 70.9175 55.7529 70.9561 55.763C70.9948 55.7731 71.0309 55.791 71.0624 55.8157C71.1271 55.8666 71.1696 55.9404 71.1812 56.0219C71.1928 56.1033 71.1725 56.1861 71.1246 56.253Z"
            fill="#263238"
        />
        <path
            d="M70.974 56.9122C70.9746 56.9453 70.9686 56.9782 70.9564 57.009C70.9442 57.0398 70.926 57.0679 70.9029 57.0917C70.8798 57.1154 70.8522 57.1343 70.8217 57.1473C70.7913 57.1603 70.7585 57.1672 70.7254 57.1675C70.674 57.1692 70.6233 57.1556 70.5797 57.1283C70.5362 57.101 70.5018 57.0613 70.481 57.0143C70.4602 56.9673 70.454 56.9151 70.463 56.8646C70.4721 56.814 70.4962 56.7673 70.532 56.7304C70.5679 56.6936 70.6139 56.6684 70.6643 56.6579C70.7146 56.6475 70.7669 56.6524 70.8144 56.6719C70.862 56.6915 70.9026 56.7248 70.931 56.7676C70.9595 56.8104 70.9744 56.8608 70.974 56.9122Z"
            fill="#263238"
        />
        <path
            d="M69.6309 56.4482L69.713 57.9401L70.4922 57.6759L69.6309 56.4482Z"
            fill="#F28F8F"
        />
        <path
            d="M65.7712 95.4869V95.2649C65.9244 95.3293 66.2774 96.679 66.9078 97.2629C67.4096 97.7202 68.0933 98.2464 68.2665 98.757C68.4396 99.2676 67.3141 99.5806 66.8457 99.4962C66.3018 99.4008 65.3583 99.0167 65.123 98.6615C64.8876 98.3063 64.7744 97.7735 64.5813 97.5226C64.3881 97.2718 63.9464 96.9721 63.842 96.6702C63.8105 96.3886 63.8416 96.1036 63.933 95.8354C64.0063 95.5557 64.0862 95.2471 64.1817 95.2738C64.1817 95.3071 64.1817 95.4469 64.1817 95.4469C64.4088 95.6235 64.6888 95.7181 64.9764 95.7156C65.2095 95.7111 65.7734 95.6645 65.7712 95.4869Z"
            fill="#263238"
        />
        <path
            d="M70.9684 93.8598C71.0527 93.8598 71.1193 93.9797 71.3014 94.1772C71.5738 94.4496 71.8897 94.6747 72.236 94.8432C72.831 95.1429 73.9876 95.6003 74.5115 95.8356C74.8378 95.9843 74.8223 96.3795 74.4849 96.6037C74.1474 96.8279 73.3305 96.99 72.3514 96.7702C71.8164 96.6525 71.1216 96.1575 70.682 96.1841C70.2424 96.2108 69.4188 96.2063 69.0947 95.9821C68.7706 95.7579 68.8993 95.2806 68.9904 94.8721C69.0903 94.4281 69.2346 93.7377 69.3833 93.7621V93.9042C69.5764 94.1928 70.5488 94.3104 70.9684 94.0995V93.8598Z"
            fill="#263238"
        />
        <path
            d="M68.2422 76.042C69.0663 75.7876 69.8242 75.3548 70.4622 74.7744C70.4622 74.7744 70.036 75.5736 68.7661 76.3173L68.7927 80.6485L68.2422 76.042Z"
            fill="#263238"
        />
        <path
            d="M65.2296 75.9754C64.9676 75.7534 64.8033 75.467 64.4082 75.1762C64.1219 74.9993 63.8821 74.7565 63.7089 74.468C63.4869 74.0951 63.2649 72.8519 63.1983 71.6708C63.1586 70.3519 63.344 69.0358 63.7466 67.7792C64.2861 66.2895 65.3805 64.2915 65.7446 63.4479C66.1087 62.6043 65.9133 62.1803 65.587 61.7519C65.2051 61.2501 64.497 61.2479 63.8287 62.3912C62.9385 63.9805 62.2281 65.664 61.7109 67.4106C61.3621 68.7174 61.2323 70.073 61.3268 71.4222C61.4001 72.672 61.6731 73.6266 62.0239 74.8277C62.1216 75.154 62.1105 75.4204 62.2215 76.0664C62.2506 76.3363 62.3194 76.6004 62.4257 76.8501C62.5762 77.1501 62.7683 77.4274 62.9962 77.6737C63.285 77.9865 63.6521 78.2164 64.0596 78.3397C64.2836 78.4064 64.5201 78.4194 64.75 78.3774C64.9811 78.3358 65.1897 78.213 65.3383 78.0311C65.3694 77.9949 65.3908 77.9514 65.4005 77.9046C65.3997 77.8217 65.3671 77.7422 65.3095 77.6826C64.9941 77.3131 64.6977 76.9278 64.4215 76.5282C64.2172 76.1841 64.1196 75.911 64.1995 75.9266C64.2794 75.9421 64.3837 76.1486 64.6812 76.335C64.9787 76.5215 65.3472 76.5149 65.4338 76.3484C65.5204 76.1819 65.4982 76.2019 65.2296 75.9754Z"
            fill="#FFA8A7"
        />
        <path
            d="M65.3381 61.1125C64.7032 61.1503 64.126 61.2924 63.4911 62.5511C62.8562 63.8098 61.8594 66.1675 61.8594 66.1675C62.0999 66.5981 62.4525 66.9556 62.8796 67.2022C63.3068 67.4488 63.7927 67.5753 64.2858 67.5683C64.2858 67.5683 65.7466 64.518 65.9841 63.7144C66.2217 62.9107 66.386 61.612 65.3381 61.1125Z"
            fill="#8C53C5"
        />
        <path
            opacity="0.1"
            d="M65.3381 61.1125C64.7032 61.1503 64.126 61.2924 63.4911 62.5511C62.8562 63.8098 61.8594 66.1675 61.8594 66.1675C62.0999 66.5981 62.4525 66.9556 62.8796 67.2022C63.3068 67.4488 63.7927 67.5753 64.2858 67.5683C64.2858 67.5683 65.7466 64.518 65.9841 63.7144C66.2217 62.9107 66.386 61.612 65.3381 61.1125Z"
            fill="black"
        />
        <path
            d="M73.7128 67.5682C73.5685 67.99 72.1832 69.2753 71.1642 69.6838C70.9229 69.767 70.6633 69.7824 70.4139 69.7282C70.175 69.628 69.9181 69.5781 69.6591 69.5817C69.3616 69.6083 69.0597 69.6661 68.76 69.7016C68.6976 69.7036 68.6363 69.7187 68.5801 69.746C68.3981 69.8459 68.5024 69.9991 68.6467 70.0546C68.8793 70.1384 69.1174 70.2059 69.3594 70.2566V70.321C68.7394 70.5377 68.0924 70.6677 67.4368 70.7072C67.3203 70.7084 67.2074 70.7473 67.1149 70.8182C67.0773 70.8604 67.0509 70.9114 67.0381 70.9665C67.0253 71.0216 67.0265 71.079 67.0417 71.1335C67.0727 71.2415 67.1256 71.342 67.1971 71.4287C67.4647 71.8337 67.881 72.1168 68.3559 72.2168C68.8359 72.2535 69.3184 72.1931 69.7745 72.0392C69.995 71.9771 70.2126 71.9083 70.4272 71.8328C71.0555 71.6015 71.6674 71.3278 72.2587 71.0136C73.5249 70.3491 74.6994 69.523 75.753 68.5561C76.3923 67.9322 76.2347 67.3329 75.6975 66.6091"
            fill="#FFA8A7"
        />
        <path
            d="M71.0156 69.7239C71.0156 69.7239 71.2376 69.6418 71.4907 70.048C71.7438 70.4543 71.9036 71.1957 71.5862 71.3423C71.6955 71.3486 71.8046 71.3263 71.9027 71.2777C72.0008 71.229 72.0845 71.1556 72.1456 71.0648C72.2108 70.7754 72.1929 70.4734 72.0939 70.1938C71.9949 69.9141 71.8189 69.6681 71.5862 69.4841C71.3724 69.4887 71.1684 69.5744 71.0156 69.7239Z"
            fill="#37474F"
        />
        <path
            d="M72.1883 69.8726C72.2358 69.9529 72.2583 70.0456 72.253 70.1388C72.2477 70.232 72.2148 70.3215 72.1585 70.3959C72.1022 70.4704 72.0251 70.5264 71.9368 70.5569C71.8486 70.5874 71.7533 70.5909 71.6631 70.5671C71.5728 70.5433 71.4917 70.4932 71.43 70.4232C71.3683 70.3531 71.3288 70.2663 71.3166 70.1738C71.3043 70.0812 71.3199 69.9871 71.3613 69.9035C71.4026 69.8198 71.468 69.7503 71.5489 69.7039C71.6561 69.6417 71.7836 69.6245 71.9035 69.6561C72.0233 69.6878 72.1257 69.7656 72.1883 69.8726Z"
            fill="#8C53C5"
        />
        <path
            d="M72.0811 69.9347C72.1156 69.994 72.1318 70.0623 72.1275 70.1308C72.1232 70.1993 72.0987 70.265 72.057 70.3195C72.0153 70.374 71.9583 70.4149 71.8933 70.437C71.8283 70.4591 71.7582 70.4614 71.6919 70.4435C71.6257 70.4257 71.5662 70.3886 71.521 70.3369C71.4759 70.2852 71.4471 70.2212 71.4384 70.1532C71.4296 70.0851 71.4413 70.0159 71.472 69.9545C71.5026 69.8931 71.5508 69.8421 71.6104 69.8082C71.6496 69.7855 71.6929 69.7708 71.7378 69.7649C71.7827 69.759 71.8283 69.762 71.872 69.7738C71.9158 69.7855 71.9567 69.8058 71.9926 69.8334C72.0285 69.861 72.0585 69.8954 72.0811 69.9347Z"
            fill="#FAFAFA"
        />
    </svg>
);
