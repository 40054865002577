import { TBaseSVG } from "../../models";

import { theme } from "..";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-arrow-retry"}
    >
        <title>{title}</title>
        <path
            d="M16.0234 9.3494H21.0154L17.8344 6.1664C16.8103 5.14228 15.5346 4.4058 14.1356 4.03101C12.7366 3.65622 11.2636 3.65633 9.86464 4.03133C8.4657 4.40632 7.19014 5.14299 6.16617 6.16727C5.14221 7.19155 4.40594 8.46735 4.03138 9.86641M2.98537 19.6454V14.6534M2.98537 14.6534H7.97738M2.98537 14.6534L6.16537 17.8364C7.18949 18.8605 8.46517 19.597 9.86416 19.9718C11.2632 20.3466 12.7362 20.3465 14.1351 19.9715C15.534 19.5965 16.8096 18.8598 17.8336 17.8355C18.8575 16.8113 19.5938 15.5355 19.9684 14.1364M21.0154 4.35741V9.3474"
            stroke={color || theme.purple600}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
