import styled from "styled-components";

export const ContainerCalendar = styled.div`
    background: white;
    background: #ffffff;
    box-shadow: 0px 0.125rem 0.5rem rgba(14, 2, 26, 0.2);
    border-radius: 0.25rem;
    padding: 10px;

    .rdp-day_selected:not([disabled]),
    .rdp-day_selected:focus:not([disabled]),
    .rdp-day_selected:active:not([disabled]),
    .rdp-day_selected:hover:not([disabled]) {
        color: white;
        background-color: ${({ theme }) => theme.purple400};
        :hover {
            color: ${({ theme }) => theme.gray600};
        }
    }

    .rdp-nav {
        display: flex;
        gap: 16px;
    }
    .rdp-caption_dropdowns {
        gap: 16px;
    }

    .caption-label {
        font-size: 1.125rem;
        font-weight: 400;
        color: ${({ theme }) => theme.gray600};
        text-transform: capitalize;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    .nav-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 4px;
        cursor: pointer;

        svg {
            width: 10px;
            height: 10px;

            path {
                fill: ${({ theme }) => theme.gray400};
            }
        }

        :hover {
            opacity: 0.5;
        }
    }

    .head-row {
        .head-cell {
            padding: 10px 0;
            font-size: 0.625rem;
            font-weight: 500;
            color: ${({ theme }) => theme.purple400};
            text-transform: capitalize;

            @media (max-width: 768px) {
                font-size: 9px;
            }
        }

        .head-cell:first-child,
        .head-cell:last-child {
            color: ${({ theme }) => theme.purple100};
        }
    }

    .table-row {
        border-top: 1px solid ${({ theme }) => theme.gray100};

        .table-cell {
            padding: 4px 0;

            .buttons {
                font-size: 0.625rem;
                cursor: pointer;
                height: 32px;
                border: none;
                border-radius: 10px;

                @media (max-width: 768px) {
                    font-size: 11px;
                }

                :not(.range-start, .range-middle, .range-end):hover {
                    border-radius: 10px;
                    background: ${({ theme }) => theme.gray100};
                }
            }

            .range-start,
            .range-end {
                background: ${({ theme }) => theme.purple400};
            }

            .range-start {
                border-radius: 10px 0 0 10px;
            }

            .range-start.range-end {
                border-radius: 10px;
            }

            .range-middle {
                border-radius: unset;
                background: ${({ theme }) => theme.pink50};
                color: ${({ theme }) => theme.gray600};
                font-size: 0.625rem;
            }

            .range-end {
                border-radius: 0 10px 10px 0;
            }
        }
    }

    .disabled {
        color: ${({ theme }) => theme.gray400};
    }
`;
