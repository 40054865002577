import styled from "styled-components";

type TDayPicker = {
    error?: boolean;
    icon?: boolean;
};

export const Container = styled.div<TDayPicker>`
    width: 100%;
    button {
        width: 100%;
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${({ theme }) => theme.white};
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray100)};
        border-radius: 0.25rem;
        height: 2.5rem;
        padding-left: 1rem;
        padding-right: 2rem;
        width: 100%;
        font-size: 0.875rem;
        gap: 0.5rem;
        .placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.25rem;
            letter-spacing: 0.01em;
            color: ${({ theme }) => theme.gray400};
        }
    }
    .error {
        border: 1px solid ${({ theme }) => theme.red};
    }
    .label {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.375rem;
        letter-spacing: 0.0015em;
        color: ${({ theme }) => theme.gray800};
        margin-bottom: 0.25rem;
    }
`;

export const Error = styled.p`
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
    margin-top: 8px;
`;
