import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TCreateReportError = {
    reportId?: string;
    messageRefuse?: string;
};

export default async ({ reportId, messageRefuse }: TCreateReportError = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/report/error/create`,
        method: "post",
        body: {
            messageRefuse,
        },
        headers: { auth: true, "x-report-id": reportId },
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400:
            body.message === "Start or End time is invalid!"
                ? "Tempo de início ou de fim inválidos!"
                : "Campos obrigatórios não preenchidos",
        401: "Acesso negado!",
        404: "Sem entradas de tempo para esse período!",
        409: "Esse relatório já existe!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
