import styled from "styled-components";

type TVariation = "small" | "medium" | "big";

type TButton = {
    variation: TVariation;
    isActive?: boolean;
    iconSolid?: boolean;
};

const setHeight = (variation: TVariation) => {
    if (variation === "small") return "40px";
    if (variation === "big") return "56px";
    return "48px";
};

export const Button = styled.button<TButton>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: ${({ theme, isActive }) =>
        isActive ? theme.purple100 : theme.purple600};
    border: 0.075rem solid ${({ theme }) => theme.purple600};
    border-radius: 0.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    width: 100%;
    height: ${({ variation }) => setHeight(variation)};
    &:hover {
        svg {
            path {
                fill: ${({ theme, iconSolid }) => iconSolid && theme.white};
                stroke: ${({ theme, iconSolid }) => !iconSolid && theme.white};
            }
        }
        background: ${({ theme }) => theme.purple700};
        box-shadow: 0px 2px 12px rgba(82, 19, 175, 0.4);
        border: 1px solid ${({ theme }) => theme.purple700};
    }
    &:active {
        background: ${({ theme }) => theme.purple100};
        border: 1px solid ${({ theme }) => theme.purple600};
        box-shadow: none;
    }
    &:disabled {
        background: ${({ theme }) => theme.gray300};
        border: 1px solid ${({ theme }) => theme.gray300};
        box-shadow: none;
    }

    .inside-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
`;
