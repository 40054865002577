import { Icons, theme } from "../../../assets";
import { renderProjectStatus } from "../../../pages/organization/utils/projects-utils";
import * as s from "./styled-projects-status";

type TReportPopover = {
    reverseColorLogic?: boolean;
    actualPercentage?: number;
    idealPercentage?: number;
    showActualPointInProgressBar?: boolean;
    projectStatus?: string;
};

const ProjectStatus = ({
    reverseColorLogic = false,
    actualPercentage,
    idealPercentage,
    showActualPointInProgressBar = false,
    projectStatus,
}: TReportPopover) => {
    const projectCanceled = projectStatus === "canceled";
    const projectPaused = projectStatus === "paused";
    const projectFinished = projectStatus === "finished";
    const projectNotValid = projectCanceled || projectPaused;

    const renderActualPercentage = (percentage: number | undefined) => {
        if (percentage) return `${Math.round(percentage)}%`;
        return "-";
    };

    const renderIdealPercentage = (
        percentage: number | undefined,
        projectStatus: string | undefined
    ) => {
        if (projectStatus && (projectNotValid || projectFinished))
            return renderProjectStatus(projectStatus);
        if (percentage) return `Ideal ${Math.round(percentage)}%`;
        return "Ideal -";
    };

    const returnColor = ({
        actualPercentage,
        idealPercentage,
    }: TReportPopover) => {
        if (actualPercentage && idealPercentage) {
            if (projectNotValid) {
                return theme.gray400;
            }
            if (projectFinished) {
                return theme.purple500;
            }
            if (actualPercentage >= idealPercentage) {
                if (reverseColorLogic) return theme.redError;
                return theme.greenDone;
            }
            if (idealPercentage - actualPercentage < 10) {
                return theme.yellow;
            }
            if (idealPercentage - actualPercentage > 10) {
                if (reverseColorLogic) return theme.greenDone;
                return theme.redError;
            }
        }
        return theme.gray300;
    };

    return (
        <s.Container>
            <div className="status">
                <span>{renderActualPercentage(actualPercentage)}</span>
                <span>
                    {renderIdealPercentage(idealPercentage, projectStatus)}
                </span>
            </div>

            <s.ProgressBar percentage={idealPercentage || 0}>
                {showActualPointInProgressBar && (
                    <Icons.IconDotOutline className="actual-value-dot" />
                )}
                <s.Bar
                    color={returnColor({ actualPercentage, idealPercentage })}
                    percentage={actualPercentage || 0}
                />
            </s.ProgressBar>
        </s.Container>
    );
};

export default ProjectStatus;
