type TAmexCard = {
    width?: string;
    height?: string;
};

export default ({ width, height }: TAmexCard) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || "24"}
            height={height || "16"}
            viewBox="0 0 24 16"
            fill="none"
        >
            <rect width="24" height="16" rx="2" fill="#0077A6" />
            <path
                d="M4.25716 6L2 10.9936H4.70214L5.03713 10.1974H5.80283L6.13782 10.9936H9.11211V10.3859L9.37714 10.9936H10.9157L11.1807 10.3731V10.9936H17.3664L18.1186 10.2181L18.8229 10.9936L22 11L19.7357 8.51073L22 6H18.8722L18.14 6.76118L17.4579 6H10.7286L10.1508 7.28889L9.55936 6H6.86286V6.587L6.5629 6C6.5629 6 4.25716 6 4.25716 6ZM4.78001 6.70909H6.09716L7.59433 10.0953V6.70909H9.03722L10.1936 9.13696L11.2594 6.70909H12.695V10.2923H11.8215L11.8143 7.48452L10.5407 10.2923H9.75929L8.47857 7.48452V10.2923H6.68143L6.34072 9.489H4.50002L4.16002 10.2916H3.19713L4.78001 6.70909ZM13.4943 6.70909H17.0464L18.1329 7.8823L19.2543 6.70909H20.3407L18.69 8.51002L20.3407 10.2902H19.205L18.1186 9.10336L16.9914 10.2902H13.4943V6.70909ZM5.42076 7.31536L4.81432 8.74639H6.0265L5.42076 7.31536ZM14.3715 7.451V8.10512H16.3093V8.83418H14.3715V9.54829H16.545L17.555 8.49648L16.5879 7.45038H14.3715V7.451Z"
                fill="white"
            />
        </svg>
    );
};
