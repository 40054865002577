import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import {
    costStringToNumber,
    defaultCostToFormattedCurrency,
    formatCurrency,
} from "../../../../pages/organization/project/tab-costs/utils";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

export type ModalEditCostFields = {
    cost: string;
};

type ModalEditCostProps = {
    defaultCost?: number;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    submit: (params: ModalEditCostFields) => void;
    loading?: boolean;
};

const schema = yup.object().shape({
    cost: yup
        .string()
        .required("Obrigatório")
        .test("min cost", "Deve ser maior que 1", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue > 1;
        })
        .test("max cost", "Deve ser menor que 999.999", (value) => {
            if (!value) return false;
            const numericValue = costStringToNumber(value);
            return numericValue < 999999;
        })
        .typeError("Deve ser um número válido"),
});

export const ModalEditCost = ({
    defaultCost,
    submit,
    setOpen,
    loading,
}: ModalEditCostProps) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ModalEditCostFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            cost: defaultCostToFormattedCurrency(defaultCost?.toString()),
        },
    });

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container
                onSubmit={handleSubmit(submit)}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        setOpen(false);
                    }
                    if (e.key === "Enter") {
                        handleSubmit(submit)();
                    }
                }}
            >
                <s.Title>Custo total previsto</s.Title>
                <s.Description>
                    Defina o valor previsto para o projeto:
                </s.Description>
                <Controller
                    name="cost"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                        <Input
                            value={value}
                            onChange={(e) =>
                                onChange(formatCurrency(e.target.value))
                            }
                            label="Valor (R$)"
                            error={errors.cost?.message}
                        />
                    )}
                />
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton type="submit" loading={loading}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
