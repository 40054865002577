import styled from "styled-components";

type TOptionText = {
    active?: boolean;
    manage?: boolean;
};

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 100%;
    gap: 1.5rem;
    padding: 24px 24px 24px 40px;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.gray200};
    border-radius: 4px;
`;

export const Options = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 3.125rem;

    div.option {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;

        svg {
            width: 25px;
            height: 25px;
        }

        :hover {
            svg path {
                stroke: ${({ theme }) => theme.purple600};
            }

            span {
                color: ${({ theme }) => theme.purple600};
            }
        }
    }
    div.option-manage {
        display: flex;
        align-items: center;
        gap: 12px;
        border-top: ${({ theme }) => `2px solid ${theme.gray100}`};
        padding-top: 1.5rem;
        cursor: pointer;

        svg {
            width: 25px;
            height: 25px;
        }

        :hover {
            svg path {
                stroke: ${({ theme }) => theme.purple600};
            }

            span {
                color: ${({ theme }) => theme.purple600};
            }
        }
    }
`;

export const OptionsManage = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 3.125rem;

    div {
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            width: 25px;
            height: 25px;
        }

        :hover {
            svg path {
                stroke: ${({ theme }) => theme.purple600};
            }

            span {
                color: ${({ theme }) => theme.purple600};
            }
        }
    }
`;

export const OptionText = styled.span<TOptionText>`
    font-size: ${({ manage }) => (manage ? `0.75rem` : `1rem`)};
    font-weight: 600;
    color: ${({ active, theme }) =>
        active ? `${theme.purple600}` : `${theme.gray200}`};
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
`;

export const OptionTextManage = styled.span<TOptionText>`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ active, theme }) =>
        active ? `${theme.purple600}` : `${theme.gray200}`};
`;
