import { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";

import { useChangeOrganizationOwner } from "../../../../hooks/react-query/use-change-organization-owner";
import { TUseFetch } from "../../../../types/TUseFetch";
import { PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import {
    ModalButtonsWrapper,
    ModalText,
    ModalTitle,
} from "../styled-generic-modal";
import * as s from "./styles";

type Props = {
    admins?: {
        name?: string;
        email?: string;
        id_e?: string;
    }[];
    selectedAdmin?: {
        name?: string;
        email?: string;
        id_e?: string;
    };
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getOrganization: TUseFetch;
};

export function ModalChangeMaster({
    admins,
    selectedAdmin,
    setOpen,
    getOrganization,
}: Props) {
    const changeOrganizationOwner = useChangeOrganizationOwner({
        onSuccess: () => {
            getOrganization.onRefresh();
            toast.success("Administrador master alterado com sucesso");
            setOpen(false);
        },
    });

    const [selectedAdminId, setSelectedAdminId] = useState<string>("");

    const adminToOption = (admin: Props["selectedAdmin"]) => ({
        value: admin?.id_e,
        label: admin?.name,
    });

    const close = () => setOpen(false);
    const save = async () => {
        changeOrganizationOwner.mutate({ ownerId: selectedAdminId });
    };

    return (
        <ContainerModal onOpenChange={setOpen} closeIcon>
            <s.Container>
                <ModalTitle>Alterar administrador master</ModalTitle>
                <ModalText>
                    Você pode alterar o administrador master selecionando um
                    administrador comum abaixo
                </ModalText>
                <Select
                    options={admins?.map((admin) => adminToOption(admin))}
                    placeholder="Selecione um administrador"
                    defaultValue={adminToOption(selectedAdmin)}
                    styles={{
                        placeholder: (base) => ({
                            ...base,
                            fontSize: "14px",
                        }),
                        control: (base) => ({
                            ...base,
                            fontSize: "14px",
                            height: "40px",
                        }),
                    }}
                    onChange={(option) => setSelectedAdminId(option!.value!)}
                    noOptionsMessage={() => "Nenhum administrador encontrado"}
                />
                <ModalButtonsWrapper>
                    <SecondaryButton
                        variation="small"
                        onClick={close}
                        disabled={changeOrganizationOwner.isLoading}
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        variation="small"
                        onClick={save}
                        loading={changeOrganizationOwner.isLoading}
                    >
                        Confirmar
                    </PrimaryButton>
                </ModalButtonsWrapper>
            </s.Container>
        </ContainerModal>
    );
}
