import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
    margin-bottom: 4.5rem;

    p#required {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.purple400};
    }

    button {
        padding: 0.5rem 1rem;
    }
`;
