import styled from "styled-components";

export const ProjectScopeHistory = styled.div`
    padding: 1rem;
    h5 {
        color: ${({ theme }) => theme.purple500};
        margin-bottom: 0.5rem;
        font-weight: 500;
        font-size: 14px;
    }
    section {
        width: fit-content;
        display: grid;
        max-height: 9rem;
        overflow-y: auto;

        a,
        label {
            font-weight: 400;
            font-size: 13px;
            color: ${({ theme }) => theme.gray600};
        }
        a {
            margin-top: 0.5rem;
            text-decoration: underline;
            cursor: pointer;
        }
    }
`;
