import * as yup from "yup";

import { validationErrors } from "../../utils";

const { project } = validationErrors;

export const schema = yup.object().shape({
    project: yup.string().required(project.required),
});

export const manualSchema = yup.object().shape({
    project: yup.string().required(project.required),
    startTrackTime: yup.string(),
    endTrackTime: yup.string(),
    calendar: yup.string(),
});
