import { useNavigate } from "react-router-dom";

import { OfficeSpace } from "../../assets/images";
import { PrimaryButton, SecondaryButton } from "../../components";
import * as s from "./styled-password-expired";

export const PasswordExpired = () => {
    const navigate = useNavigate();

    return (
        <s.Container>
            <s.Section>
                <div id="password-expired">
                    <h1>Link expirado</h1>

                    <p style={{ whiteSpace: "pre-line" }}>
                        O tempo para criar uma nova senha expirou.
                    </p>

                    <span id="line" />

                    <span id="question">Como resolver?</span>

                    <p>
                        Você pode <b>tentar novamente</b> para redefir sua senha
                        ou tentar <b>efetuar o login</b>
                    </p>

                    <div id="buttons">
                        <SecondaryButton
                            type="button"
                            onClick={() => navigate("/login")}
                        >
                            Efetuar Login
                        </SecondaryButton>

                        <PrimaryButton
                            type="button"
                            onClick={() => navigate("/recuperar-senha")}
                        >
                            Tentar Novamente
                        </PrimaryButton>
                    </div>
                </div>
            </s.Section>

            <s.Img
                alt={"Relógio, abajur e planta numa sala vazia"}
                title={"Relógio, abajur e planta numa sala vazia"}
                src={OfficeSpace}
            />
        </s.Container>
    );
};
