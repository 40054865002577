import { FieldError } from "react-hook-form";

import { Icons, theme } from "../../../assets";
import {
    PASSWORD_MIN_LENGTH,
    capitalLettersRegex,
    specialCharsRegex,
} from "../../../pages/cadastro/components/register-steps/final-step/validation";
import * as s from "./styles";

type PasswordStrengthProps = {
    password?: string;
    error?: FieldError | undefined;
};

export const PasswordStrength = ({
    password = "",
    error,
}: PasswordStrengthProps) => {
    const rules = [
        {
            code: "password_length",
            label: `Deve conter no mínimo ${PASSWORD_MIN_LENGTH} caracteres`,
            completed: password.length >= PASSWORD_MIN_LENGTH,
        },
        {
            code: "password_capital_letter",
            label: "Deve conter ao menos 1 letra maiúscula",
            completed: capitalLettersRegex.test(password),
        },
        {
            code: "special_character",
            label: "Deve conter no mínimo 1 caractere especial (@!/?&#$*)",
            completed: specialCharsRegex.test(password),
        },
    ];

    const pickColor = (completed: boolean) => {
        if (completed) return theme.greenDone;
        if (error) return theme.red;
        return theme.gray300;
    };

    return (
        <s.Container $error={Boolean(error)}>
            {rules.map((r) => (
                <s.Rule key={r.code} $color={pickColor(r.completed)}>
                    {r.completed || !error ? (
                        <Icons.IconCheckCircle2
                            color={pickColor(r.completed)}
                            width="24px"
                            height="24px"
                            title={`${r.code}-check`}
                        />
                    ) : (
                        <s.XIconContainer>
                            <Icons.XSolid
                                color={theme.white}
                                width="14px"
                                height="14px"
                            />
                        </s.XIconContainer>
                    )}
                    {r.label}
                </s.Rule>
            ))}
        </s.Container>
    );
};
