import { yupResolver } from "@hookform/resolvers/yup";
import { orderBy } from "lodash";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";

import { Icons, theme } from "../../../../assets";
import { useCreateSkills } from "../../../../hooks/react-query/use-create-skills";
import { TOption } from "../../../../models";
import { UserSkillModel } from "../../../../models/user-skill";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModalScroll from "../container-modal-scroll";
import { schema } from "./schema";
import * as s from "./styles";

export type Skill = {
    id_e?: string;
    name: string;
    experience: number;
};

type FormData = {
    skills: Array<{
        id_e?: string;
        name: TOption;
        years: number;
        months: number;
    }>;
};

type Props = {
    skills?: Array<UserSkillModel>;
    userId: string;
    canClose?: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCreate: () => void;
};

export function ModalSkills({
    canClose,
    skills,
    userId,
    setOpen,
    onCreate,
}: Props) {
    const createSkills = useCreateSkills({
        onSuccess() {
            setOpen(false);
            toast.success("Habilidades salvas com sucesso!");
            onCreate();
        },
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const { fields, append, remove, replace } = useFieldArray<FormData>({
        control,
        name: "skills",
    });

    const [skillsOptions, setSkillsOptions] = React.useState<TOption[]>([
        { label: "React", value: "React" },
        { label: "Node", value: "Node" },
        { label: "Angular", value: "Angular" },
        { label: "Vue", value: "Vue" },
        { label: "Java", value: "Java" },
        { label: "Python", value: "Python" },
        { label: "C#", value: "C#" },
        { label: "Ruby", value: "Ruby" },
        { label: "PHP", value: "PHP" },
        { label: "HTML", value: "HTML" },
        { label: "CSS", value: "CSS" },
        { label: "JavaScript", value: "JavaScript" },
        { label: "TypeScript", value: "TypeScript" },
        { label: "SQL", value: "SQL" },
        { label: "NoSQL", value: "NoSQL" },
        { label: "MongoDB", value: "MongoDB" },
        { label: "Firebase", value: "Firebase" },
        { label: "PostgreSQL", value: "PostgreSQL" },
        { label: "MySQL", value: "MySQL" },
        { label: "Docker", value: "Docker" },
        { label: "Kubernetes", value: "Kubernetes" },
        { label: "AWS", value: "AWS" },
        { label: "Azure", value: "Azure" },
        { label: "GCP", value: "GCP" },
        { label: "Git", value: "Git" },
        { label: "GitHub", value: "GitHub" },
        { label: "GitLab", value: "GitLab" },
        { label: "Bitbucket", value: "Bitbucket" },
        { label: "Jenkins", value: "Jenkins" },
        { label: "CircleCI", value: "CircleCI" },
        { label: "TravisCI", value: "TravisCI" },
        { label: "Heroku", value: "Heroku" },
        { label: "Netlify", value: "Netlify" },
        { label: "Vercel", value: "Vercel" },
        { label: "Next.js", value: "Next.js" },
        { label: "NestJS", value: "NestJS" },
        { label: "Express", value: "Express" },
    ]);

    function convertYearsAndMonthsToFloat(
        years: number,
        months: number
    ): number {
        return years + months / 12;
    }

    function convertFloatToYearsAndMonths(float: number): {
        years: number;
        months: number;
    } {
        const years = Math.floor(float);
        const months = Math.round((float - years) * 12);
        return { years, months };
    }

    const typingTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    function addSkillOnInput(value: string) {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        typingTimeoutRef.current = setTimeout(() => {
            if (value) {
                setSkillsOptions([...skillsOptions, { label: value, value }]);
            }
        }, 500);
    }

    useEffect(() => {
        if (skills?.length) {
            remove();
            const newSkills = skills.map((skill) => {
                const { years, months } = convertFloatToYearsAndMonths(
                    skill.experience
                );
                return {
                    id_e: skill.id_e,
                    name: {
                        label: skill.name,
                        value: skill.name,
                    },
                    years,
                    months,
                };
            });
            replace(orderBy(newSkills, "name", "asc"));
        }
    }, [skills]);

    function save(formData: FormData) {
        createSkills.mutate({
            skills: formData.skills.map((skill) => ({
                experience: convertYearsAndMonthsToFloat(
                    skill.years,
                    skill.months
                ),
                name: skill.name.label,
            })),
            userId,
        });
    }

    return (
        <ContainerModalScroll onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(save)}>
                <s.Title>Preencher habilidades</s.Title>
                <s.Description>
                    Digite suas habilidades e o tempo de experiência. Preencha
                    no mínimo 3 habilidades.
                </s.Description>
                <s.Description>
                    Você poderá alterar essas informações quando quiser entrando
                    em <strong>Perfil/Experiências</strong>
                </s.Description>
                <s.Fields>
                    {fields.map((skill, index) => (
                        <s.Field key={skill.id_e || index}>
                            <Controller
                                name={`skills.${index}.name`}
                                control={control}
                                defaultValue={skill.name || ""}
                                render={({ field }) => (
                                    <s.SkillSelectWrapper>
                                        <s.Label htmlFor="skill">
                                            Habilidade
                                        </s.Label>
                                        <Select
                                            id="skill"
                                            {...field}
                                            placeholder="Selecione"
                                            noOptionsMessage={() =>
                                                "Nenhuma opção encontrada"
                                            }
                                            options={skillsOptions}
                                            styles={{
                                                placeholder: (base) => ({
                                                    ...base,
                                                    fontSize: "14px",
                                                }),
                                                noOptionsMessage: (base) => ({
                                                    ...base,
                                                    fontSize: "14px",
                                                }),
                                            }}
                                            onInputChange={(inputValue) => {
                                                addSkillOnInput(inputValue);
                                            }}
                                        />
                                    </s.SkillSelectWrapper>
                                )}
                            />
                            <s.Experience>
                                <Controller
                                    name={`skills.${index}.years`}
                                    control={control}
                                    defaultValue={skill.years || 0}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Input
                                            label="Anos"
                                            value={value}
                                            type="number"
                                            onChange={onChange}
                                            error={
                                                errors.skills?.[index]?.years
                                                    ?.message
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    name={`skills.${index}.months`}
                                    control={control}
                                    defaultValue={skill.months || 0}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <Input
                                            label="Meses"
                                            value={value}
                                            type="number"
                                            onChange={onChange}
                                            error={
                                                errors.skills?.[index]?.months
                                                    ?.message
                                            }
                                        />
                                    )}
                                />
                            </s.Experience>
                            {fields.length > 1 && (
                                <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    disabled={createSkills.isLoading}
                                >
                                    <Icons.Trash color={theme.gray400} />
                                </button>
                            )}
                        </s.Field>
                    ))}
                </s.Fields>
                {errors.skills?.[0]?.message && (
                    <s.Error>{errors.skills[0].message}</s.Error>
                )}
                <SecondaryButton
                    type="button"
                    onClick={() =>
                        append({
                            years: 0,
                            months: 0,
                            name: { label: "", value: "" },
                        })
                    }
                    disabled={createSkills.isLoading}
                >
                    <Icons.Plus />
                    Adicionar experiência
                </SecondaryButton>
                <s.Buttons>
                    {canClose ? (
                        <SecondaryButton
                            type="button"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Fechar
                        </SecondaryButton>
                    ) : (
                        <SecondaryButton
                            type="button"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Me lembre em 3 dias
                        </SecondaryButton>
                    )}
                    <PrimaryButton loading={createSkills.isLoading}>
                        Salvar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModalScroll>
    );
}
