import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => (
    <svg
        onClick={onClick}
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className || "icon-user-track"}
    >
        {title}
        <mask
            id="mask0_2748_31008"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="30"
            height="30"
        >
            <circle cx="15" cy="15" r="15" fill={color || theme.white} />
        </mask>

        <g mask="url(#mask0_2748_31008)">
            <circle
                cx="14.625"
                cy="10.125"
                r="6.375"
                fill={color || theme.white}
            />

            <ellipse
                cx="14.625"
                cy="32.625"
                rx="16.875"
                ry="14.625"
                fill={color || theme.white}
            />

            <circle cx="15" cy="15" r="14.5" stroke={color || theme.white} />
        </g>
    </svg>
);
