import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
        width: 150px;
    }
`;

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
