import * as s from "./styled-popover-sector-list";

const PopoverSectorList = ({ sectors }: { sectors?: string[] }) => {
    if (sectors) {
        return (
            <s.SectorsList>
                <div id="sectors-header">
                    <span id="title">Setores</span>
                </div>
                <div id="sectors-body">
                    {sectors.length > 0 ? (
                        sectors?.map((sector, index) => {
                            return (
                                <span key={index}>
                                    <p>{sector}</p>
                                </span>
                            );
                        })
                    ) : (
                        <span>Usuário sem setor</span>
                    )}
                </div>
            </s.SectorsList>
        );
    }
    return (
        <s.SectorsList>
            <div id="sectors-header">
                <span id="title">Setores</span>
            </div>
            Sem setores
        </s.SectorsList>
    );
};

export default PopoverSectorList;
