import styled from "styled-components";

export const Container = styled.div`
    padding: 16px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.white};
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 700px;
`;

export const Title = styled.h1`
    color: ${({ theme }) => theme.purple600};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Text = styled.p`
    color: ${({ theme }) => theme.purple800};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    button {
        width: 121px;

        &:last-child {
            background-color: ${({ theme }) => theme.redError};
            border-color: ${({ theme }) => theme.redError};

            transition: all 0.2s;

            &:active {
                background-color: ${({ theme }) => theme.red};
            }

            &:disabled {
                background-color: ${({ theme }) => theme.gray200};
                border-color: ${({ theme }) => theme.gray200};
                cursor: not-allowed;

                &:hover {
                    box-shadow: none;
                }
            }

            &:hover {
                box-shadow: 0px 2px 12px ${({ theme }) => theme.redError};
            }
        }
    }
`;
