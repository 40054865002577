import { Mission } from "..";

import { ReadGameficationDataUseCaseReturn } from "../../../../../../services/gamefication/types";
import { missions as missionsInformation } from "./constants";
import * as s from "./styles";

type GameficationMission = (
    | ReadGameficationDataUseCaseReturn["missions"]["dailyMission"]
    | ReadGameficationDataUseCaseReturn["missions"]["weeklyMission"]
) & {
    title?: string;
};

type RenderMissionParams = {
    mission: GameficationMission;
    key: string;
};

type MissionsGroupProps = {
    data: ReadGameficationDataUseCaseReturn["missions"] | undefined;
};

export const MissionsGroup = ({ data }: MissionsGroupProps) => {
    const calculatePercentageDone = (
        challenges?: ReadGameficationDataUseCaseReturn["missions"]["dailyMission"]["challenges"]
    ) => {
        if (!challenges) return 0;
        return (
            (challenges.filter(({ challenge_completed }) => challenge_completed)
                .length /
                challenges.length) *
            100
        );
    };

    const renderMission = ({ mission, key }: RenderMissionParams) => {
        const missionInformation = missionsInformation.find(
            ({ id }) => id === key
        );
        const isProfileMission = mission.title === "Preenchimento do perfil";
        const allProfileMissionDone =
            isProfileMission &&
            mission.challenges.every(
                ({ challenge_completed }) => challenge_completed
            );
        if (allProfileMissionDone) return null;

        return (
            <Mission.Root
                points={mission.points}
                percentageDone={calculatePercentageDone(mission?.challenges)}
            >
                <Mission.Title>{missionInformation?.title}</Mission.Title>
                <Mission.Text>
                    {missionInformation?.text?.replace(
                        "{points}",
                        mission.points.toString()
                    )}
                </Mission.Text>
                <Mission.ChallengesContainer>
                    {mission.challenges?.map(
                        ({ challenge_name, challenge_completed }) => (
                            <Mission.Challenge done={challenge_completed}>
                                {challenge_name}
                            </Mission.Challenge>
                        )
                    )}
                </Mission.ChallengesContainer>
            </Mission.Root>
        );
    };

    return (
        <s.Container>
            {data &&
                Object.keys(data).map((key) => {
                    const mission = data[key as keyof typeof data];
                    if (Array.isArray(mission)) {
                        return mission.map((uniqueMission) =>
                            renderMission({
                                mission: uniqueMission,
                                key,
                            })
                        );
                    }
                    return renderMission({
                        mission,
                        key,
                    });
                })}
        </s.Container>
    );
};
