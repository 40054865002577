import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 35px;
    gap: 25px;
    height: 100%;
`;
