import { ProgressCircle } from "..";

import * as s from "../../styles";

type MissionRootProps = {
    children: React.ReactNode;
    points: number;
    percentageDone?: number;
};

export const MissionRoot = ({
    points,
    percentageDone,
    children,
}: MissionRootProps) => {
    return (
        <s.Container>
            <ProgressCircle points={points} percentageDone={percentageDone} />
            <s.InformationContainer>{children}</s.InformationContainer>
        </s.Container>
    );
};
