import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";

import { Icons, theme } from "../../../../../../../assets";
import { activities } from "../../../../../../../services";
import { queryClient } from "../../../../../../../utils/query-client/query-client";
import { TPriority } from "../../../types";
import { ActivityDTO } from "../../types/activityDTO";
import { minutesToTime, timeToMinutes } from "../../utils";
import { InformationRow } from "./components/information-row/information-row";
import { priorities } from "./mappers";
import * as s from "./styles";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

type TGeneralInformation = {
    activity: ActivityDTO;
};

type TData = {
    deadline?: Date;
    expectedMinutes?: number;
    description?: string;
    priority?: TPriority;
};

export const GeneralInformation = ({ activity }: TGeneralInformation) => {
    const { mutate } = useMutation<unknown, unknown, TData>({
        mutationFn: (variables) =>
            activities.updateActivity({
                ...variables,
                activityIds: [activity.id_e],
                sectionId: activity.ActivitySection?.id_e,
            }),
        onSuccess: async () => {
            queryClient.fetchQuery(["readActivities", activity.id_e]);
        },
    });

    const priority = priorities[activity.priority as keyof typeof priorities];

    const formatDate = (date: string) => {
        return date ? dayjs(date).format("DD/MM/YYYY") : undefined;
    };

    const save = (data: TData) => {
        mutate({ ...data });
    };

    return (
        <s.Container>
            <s.Title>Informações gerais</s.Title>
            <s.Content>
                <InformationRow
                    type="select"
                    label="Prioridade"
                    value={priority.value}
                    color={priority.color}
                    editable
                    icon={<Icons.Chevron color={theme.gray400} />}
                    onSave={(value) => {
                        save({
                            priority: value as TPriority,
                        });
                    }}
                />
                <InformationRow
                    label="Criado por"
                    value={activity.authorName}
                />
                <InformationRow
                    label="Data de criação"
                    value={formatDate(activity.created_at)}
                />
                <InformationRow
                    type="date"
                    label="Prazo"
                    value={
                        activity.deadline
                            ? dayjs(activity.deadline).format("MM/DD/YYYY")
                            : undefined
                    }
                    editable
                    icon={<Icons.CalendarOutline color={theme.gray600} />}
                    altValue="Selecione uma data"
                    onSave={(value) => {
                        save({
                            deadline: dayjs(value).toDate(),
                        });
                    }}
                    disableCalendarDates={{
                        before: new Date(activity.created_at),
                    }}
                />
                <InformationRow
                    label="Data de conclusão"
                    value={formatDate(activity.finished_at)}
                    altValue="Atividade em andamento"
                />
                <InformationRow
                    type="time"
                    label="Horas previstas"
                    value={minutesToTime(activity.expectedMinutes)}
                    editable
                    icon={<Icons.EditOutline color={theme.gray600} />}
                    altValue="0:00"
                    onSave={(value) => {
                        save({
                            expectedMinutes: timeToMinutes(value as string),
                        });
                    }}
                />
                <InformationRow
                    type="textarea"
                    label="Descrição"
                    value={activity.description ?? ""}
                    altValue="Escreva aqui a descrição da atividade"
                    editable
                    icon={<Icons.EditOutline color={theme.gray600} />}
                    onSave={(value) => {
                        save({ description: value as string });
                    }}
                />
            </s.Content>
        </s.Container>
    );
};
