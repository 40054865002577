import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Draggable, DraggableLocation, Droppable } from "react-beautiful-dnd";

import { Collapse } from "../../../../../../components";
import { TProject, TResponse } from "../../../../../../models";
import { TArchiveActivity } from "../../../../../../services/activities/archive-activity";
import { TArchiveSection } from "../../../../../../services/activities/archive-section";
import { TDuplicateActivity } from "../../../../../../services/activities/duplicate-activity";
import { TToggleActivityStatus } from "../../../../../../services/activities/toggle-activity-status";
import { CreateActivityVariables } from "../../../../../../services/activities/types";
import { TUnarchiveActivity } from "../../../../../../services/activities/unarchive-activity";
import { TUnarchiveSection } from "../../../../../../services/activities/unarchive-section";
import { TUpdateActivity } from "../../../../../../services/activities/update-activity";
import { TUpdateSection } from "../../../../../../services/activities/update-section";
import { TActivityIdsRef, TSection, TSectionActivity } from "../../types";
import { ActivityRow } from "../activity-row/activity-row";
import { AddActivity } from "../add-activity";
import { EditActivity } from "../edit-activity";
import { EditSection } from "../edit-section";
import { SectionHeader } from "./section-header/section-header";

type TSectionProps = {
    sectionName?: string;
    archive?: boolean;
    sections?: TSection[];
    updateActivity: TResponse<unknown, TUpdateActivity>;
    updateSection: TResponse<unknown, TUpdateSection>;
    unarchiveActivity: TResponse<unknown, TUnarchiveActivity>;
    archiveActivity: TResponse<unknown, TArchiveActivity>;
    createActivity: TResponse<unknown, CreateActivityVariables>;
    toggleActivityStatus: TResponse<unknown, TToggleActivityStatus>;
    archiveSection?: TResponse<unknown, TArchiveSection>;
    unarchiveSection?: TResponse<unknown, TUnarchiveSection>;
    duplicateActivity?: TResponse<unknown, TDuplicateActivity>;
    activitiesWithoutSection?: TSectionActivity[];
    activityToAddCollaborators: TSectionActivity | undefined;
    setDeleteConfirmationSectionId: React.Dispatch<
        React.SetStateAction<string>
    >;
    project: TProject;
    setActivityToAddCollaborators: React.Dispatch<
        React.SetStateAction<TSectionActivity | undefined>
    >;
    setDeleteConfirmationActivityId: React.Dispatch<
        React.SetStateAction<string>
    >;
    updateActivityStates: string[];
    setUpdateActivityStates: React.Dispatch<React.SetStateAction<string[]>>;
    openedSectionsIds: string[];
    setOpenedSectionsIds: React.Dispatch<React.SetStateAction<string[]>>;
    activitiesIdsRef?: TActivityIdsRef;
    getSections: UseQueryResult<any, unknown>;
    draggingDestination?: DraggableLocation | null | undefined;
};

export const Sections = ({
    sections,
    project,
    updateActivity,
    updateSection,
    unarchiveActivity,
    archiveActivity,
    createActivity,
    activityToAddCollaborators,
    setActivityToAddCollaborators,
    setDeleteConfirmationActivityId,
    setDeleteConfirmationSectionId,
    toggleActivityStatus,
    archiveSection,
    unarchiveSection,
    archive,
    activitiesWithoutSection,
    sectionName,
    duplicateActivity,
    updateActivityStates,
    setUpdateActivityStates,
    activitiesIdsRef,
    openedSectionsIds,
    setOpenedSectionsIds,
    getSections,
    draggingDestination,
}: TSectionProps) => {
    const [updateSectionStates, setUpdateSectionStates] = useState<boolean[]>(
        []
    );
    const [isCreatingActivity, setIsCreatingActivity] =
        useState<boolean>(false);

    const toggleSectionVisibility = (sectionId: string) => {
        if (openedSectionsIds.includes(sectionId)) {
            setOpenedSectionsIds(
                openedSectionsIds.filter((id) => id !== sectionId)
            );
            return;
        }
        setOpenedSectionsIds([...openedSectionsIds, sectionId]);
    };

    const toggleSectionEdit = (index: number) => {
        const newUpdateSectionStates = [...updateSectionStates];
        newUpdateSectionStates[index] = !newUpdateSectionStates[index];
        setUpdateSectionStates(newUpdateSectionStates);
    };

    const toggleActivityEdit = (activityId: string) => {
        setUpdateActivityStates((prev) => {
            if (prev.includes(activityId)) {
                return prev.filter((id) => id !== activityId);
            }
            return [...prev, activityId];
        });
    };

    useEffect(() => {
        if (draggingDestination) {
            setOpenedSectionsIds([
                ...openedSectionsIds,
                draggingDestination.droppableId,
            ]);
        }
    }, [draggingDestination, setOpenedSectionsIds]);

    return (
        <>
            <div
                style={{
                    minHeight: (sections?.length || 0) * 80,
                }}
            >
                <Droppable
                    droppableId={`${sectionName}Sections`}
                    type="section"
                >
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {sections?.map((section, sectionIndex) =>
                                updateSectionStates[sectionIndex] ? (
                                    <EditSection
                                        updateSection={updateSection}
                                        section={section}
                                        toggleSectionEdit={toggleSectionEdit}
                                        index={sectionIndex}
                                    />
                                ) : (
                                    <Draggable
                                        key={section.id_e}
                                        index={sectionIndex}
                                        draggableId={section.id_e}
                                        isDragDisabled={
                                            updateSectionStates.includes(
                                                true
                                            ) ||
                                            updateActivityStates.length > 0 ||
                                            isCreatingActivity
                                        }
                                    >
                                        {(provided, snapshot) => (
                                            <ul
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <SectionHeader
                                                    archiveSection={
                                                        archiveSection
                                                    }
                                                    unarchiveSection={
                                                        unarchiveSection
                                                    }
                                                    setDeleteConfirmationSectionId={
                                                        setDeleteConfirmationSectionId
                                                    }
                                                    toggleSectionVisibility={
                                                        toggleSectionVisibility
                                                    }
                                                    openedSectionsIds={
                                                        openedSectionsIds
                                                    }
                                                    index={sectionIndex}
                                                    archive={archive}
                                                    section={section}
                                                    toggleSectionEdit={
                                                        toggleSectionEdit
                                                    }
                                                />
                                                {!snapshot.isDragging && (
                                                    <Collapse
                                                        open={openedSectionsIds.includes(
                                                            section.id_e
                                                        )}
                                                        height={"100"}
                                                    >
                                                        <Droppable
                                                            droppableId={
                                                                section.id_e
                                                            }
                                                            type="activity"
                                                        >
                                                            {(provided) => (
                                                                <ul
                                                                    {...provided.droppableProps}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                >
                                                                    {Object.values(
                                                                        section?.Activities
                                                                    )?.map(
                                                                        (
                                                                            activity,
                                                                            index
                                                                        ) => (
                                                                            <Draggable
                                                                                key={
                                                                                    activity.id_e
                                                                                }
                                                                                index={
                                                                                    index +
                                                                                    1
                                                                                }
                                                                                draggableId={
                                                                                    activity.id_e
                                                                                }
                                                                                isDragDisabled={updateActivityStates.includes(
                                                                                    activity.id_e
                                                                                )}
                                                                            >
                                                                                {(
                                                                                    provided,
                                                                                    snapshot
                                                                                ) => (
                                                                                    <div
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        {updateActivityStates.includes(
                                                                                            activity.id_e
                                                                                        ) ? (
                                                                                            <EditActivity
                                                                                                updateActivity={
                                                                                                    updateActivity
                                                                                                }
                                                                                                activity={
                                                                                                    activity
                                                                                                }
                                                                                                sectionId={
                                                                                                    section.id_e
                                                                                                }
                                                                                                setUpdateActivityStates={
                                                                                                    setUpdateActivityStates
                                                                                                }
                                                                                                updateActivityStates={
                                                                                                    updateActivityStates
                                                                                                }
                                                                                                setActivityToAddCollaborators={
                                                                                                    setActivityToAddCollaborators
                                                                                                }
                                                                                                activityToAddCollaborators={
                                                                                                    activityToAddCollaborators
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            <div
                                                                                                ref={
                                                                                                    activitiesIdsRef?.[
                                                                                                        activity
                                                                                                            .id_e
                                                                                                    ]
                                                                                                }
                                                                                            >
                                                                                                <ActivityRow
                                                                                                    archive={
                                                                                                        archive
                                                                                                    }
                                                                                                    project={
                                                                                                        project
                                                                                                    }
                                                                                                    unarchiveActivity={
                                                                                                        unarchiveActivity
                                                                                                    }
                                                                                                    activity={
                                                                                                        activity
                                                                                                    }
                                                                                                    archiveActivity={
                                                                                                        archiveActivity
                                                                                                    }
                                                                                                    section={
                                                                                                        section
                                                                                                    }
                                                                                                    toggleActivityEdit={
                                                                                                        toggleActivityEdit
                                                                                                    }
                                                                                                    setActivityToAddCollaborators={
                                                                                                        setActivityToAddCollaborators
                                                                                                    }
                                                                                                    snapshot={
                                                                                                        snapshot
                                                                                                    }
                                                                                                    setDeleteConfirmationActivityId={
                                                                                                        setDeleteConfirmationActivityId
                                                                                                    }
                                                                                                    toggleActivityStatus={
                                                                                                        toggleActivityStatus
                                                                                                    }
                                                                                                    duplicateActivity={
                                                                                                        duplicateActivity
                                                                                                    }
                                                                                                    getSections={
                                                                                                        getSections
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    )}
                                                                    {
                                                                        provided.placeholder
                                                                    }
                                                                </ul>
                                                            )}
                                                        </Droppable>
                                                        {!archive && (
                                                            <AddActivity
                                                                project={
                                                                    project
                                                                }
                                                                sectionId={
                                                                    section.id_e
                                                                }
                                                                createActivity={
                                                                    createActivity
                                                                }
                                                                setActivityToAddCollaborators={
                                                                    setActivityToAddCollaborators
                                                                }
                                                                activityToAddCollaborators={
                                                                    activityToAddCollaborators
                                                                }
                                                                setIsCreating={
                                                                    setIsCreatingActivity
                                                                }
                                                            />
                                                        )}
                                                    </Collapse>
                                                )}
                                            </ul>
                                        )}
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </div>
            <div>
                <Droppable
                    droppableId={`${sectionName}WithoutSection`}
                    type="activity"
                >
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {activitiesWithoutSection?.map((activity, index) =>
                                updateActivityStates.includes(activity.id_e) ? (
                                    <EditActivity
                                        updateActivity={updateActivity}
                                        activity={activity}
                                        setUpdateActivityStates={
                                            setUpdateActivityStates
                                        }
                                        updateActivityStates={
                                            updateActivityStates
                                        }
                                        setActivityToAddCollaborators={
                                            setActivityToAddCollaborators
                                        }
                                        activityToAddCollaborators={
                                            activityToAddCollaborators
                                        }
                                    />
                                ) : (
                                    <Draggable
                                        key={activity.id_e}
                                        index={index}
                                        draggableId={activity.id_e}
                                        isDragDisabled={updateActivityStates.includes(
                                            activity.id_e
                                        )}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div
                                                    ref={
                                                        activitiesIdsRef?.[
                                                            activity.id_e
                                                        ]
                                                    }
                                                >
                                                    <ActivityRow
                                                        withoutSection
                                                        archive={archive}
                                                        project={project}
                                                        unarchiveActivity={
                                                            unarchiveActivity
                                                        }
                                                        activity={activity}
                                                        archiveActivity={
                                                            archiveActivity
                                                        }
                                                        toggleActivityEdit={
                                                            toggleActivityEdit
                                                        }
                                                        snapshot={snapshot}
                                                        setActivityToAddCollaborators={
                                                            setActivityToAddCollaborators
                                                        }
                                                        setDeleteConfirmationActivityId={
                                                            setDeleteConfirmationActivityId
                                                        }
                                                        toggleActivityStatus={
                                                            toggleActivityStatus
                                                        }
                                                        duplicateActivity={
                                                            duplicateActivity
                                                        }
                                                        getSections={
                                                            getSections
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            )}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </div>
        </>
    );
};
