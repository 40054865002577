import styled from "styled-components";

import { Input } from "../../../atoms";

export const Container = styled.form`
    padding: 16px;
    width: 456px;

    h1 {
        color: ${({ theme }) => theme.purple600};
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
        margin-bottom: 45px;
    }

    p {
        justify-content: flex-start;
    }
`;

export const StyledInput = styled(Input)``;

export const PasswordStrengthWrapper = styled.div`
    margin-top: 10px;
`;

export const Label = styled.label`
    color: ${({ theme }) => theme.gray800};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 24px;
    display: block;
`;

export const EyeContainer = styled.div`
    margin-top: 6px;
    cursor: pointer;
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.redError};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    margin-bottom: 5px;
`;

export const Success = styled.div`
    padding: 16px;
    width: 456px;
    display: flex;
    flex-direction: column;
    gap: 56px;

    h1 {
        color: ${({ theme }) => theme.purple500};
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
    }

    p {
        color: ${({ theme }) => theme.gray600};
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0.018px;
    }
`;
