import { FieldValues, UseFormRegister } from "react-hook-form";

import { Input, Tooltip } from "../../../../components";
import { getHourAndMinutesAndSeconds } from "../../../../utils";
import { TrackService } from "../../track-service";
import * as s from "../styled-components";

type ChangeEvent = {
    target: {
        value: string;
    };
};

type ManualInputsData = {
    start: string;
    end: string;
    startUpdated: boolean;
    endUpdated: boolean;
    startKey: string;
    endKey: string;
};

type Props = {
    type: "start" | "end";
    manualInputsData: ManualInputsData;
    register: UseFormRegister<FieldValues>;
    validateInputs: (start: string, end: string, message?: boolean) => boolean;
    setManualInputsData: React.Dispatch<React.SetStateAction<ManualInputsData>>;
    disabled?: boolean;
};

export const TrackInput = ({
    type,
    manualInputsData,
    register,
    validateInputs,
    setManualInputsData,
    disabled,
}: Props) => {
    const inputTypesMapper = {
        start: {
            inputTitle: "Tempo inicial",
            inputRegister: "startTrackTime",
            initialValue: manualInputsData.start,
            id: "input-start",
            inputKey:
                manualInputsData.startUpdated.toString() +
                manualInputsData.startKey,
        },
        end: {
            inputTitle: "Tempo final",
            inputRegister: "endTrackTime",
            initialValue: manualInputsData.end,
            id: "input-end",
            inputKey:
                manualInputsData.endUpdated.toString() +
                manualInputsData.endKey,
        },
    };
    const currentValues = inputTypesMapper[type];

    function handleGenericBlur() {
        if (!TrackService.isValidTimeFormat(manualInputsData[type])) {
            setManualInputsData((prevState) => ({
                ...prevState,
                [type]: getHourAndMinutesAndSeconds(new Date()),
                [`${type}Updated`]: !prevState[`${type}Updated`],
            }));
        }
        validateInputs(manualInputsData.start, manualInputsData.end, true);
    }

    function handleGenericChange(event: ChangeEvent) {
        const input = event.target.value;
        const seconds = new Date().getSeconds().toString().padStart(2, "0");
        const time = `${input}:${seconds}`;
        setManualInputsData((prevState) => ({
            ...prevState,
            [type]: time,
        }));
    }

    return (
        <Tooltip
            offset={7}
            side="bottom"
            content={<s.SmallP>{currentValues.inputTitle}</s.SmallP>}
        >
            <div>
                <Input
                    disabled={disabled}
                    key={currentValues.inputKey}
                    defaultValue={currentValues.initialValue}
                    id={currentValues.id}
                    mask={"99:99"}
                    {...register(currentValues.inputRegister, {
                        onBlur: () => handleGenericBlur(),
                        onChange: (event) => handleGenericChange(event),
                    })}
                />
            </div>
        </Tooltip>
    );
};
