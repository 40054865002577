import { theme } from "..";

type TIconWhatsappFilled = {
    color?: string;
    width?: string;
    height?: string;
    opacity?: string;
    onClick?: () => void;
    cursor?: string;
};

export default ({
    color,
    height,
    width,
    cursor,
    onClick,
    opacity,
}: TIconWhatsappFilled) => (
    <svg
        width={width || "36"}
        height={height || "36"}
        viewBox="0 0 37 36"
        onClick={onClick}
        cursor={cursor || "default"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.5"
            width="36"
            height="36"
            rx="18"
            fill={color || theme.purple600}
        />
        <path
            d="M8.5 27.9727L10.3326 23.7523C8.93055 21.7621 8.30305 19.3287 8.5678 16.9087C8.83255 14.4887 9.97136 12.2485 11.7705 10.6085C13.5696 8.9685 15.9054 8.04147 18.3396 8.00136C20.7737 7.96125 23.1387 8.81082 24.9909 10.3906C26.8431 11.9705 28.0551 14.1719 28.3995 16.5819C28.7438 18.9918 28.1968 21.4446 26.8612 23.4799C25.5255 25.5152 23.493 26.9932 21.145 27.6363C18.7971 28.2795 16.2951 28.0437 14.1087 26.9731L8.5 27.9727Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.5 15.8286C14.5 15.9918 14.5648 16.1483 14.6802 16.2637C14.7956 16.3791 14.9522 16.444 15.1154 16.444C15.2786 16.444 15.4351 16.3791 15.5505 16.2637C15.6659 16.1483 15.7308 15.9918 15.7308 15.8286V14.5978C15.7308 14.4346 15.6659 14.2781 15.5505 14.1627C15.4351 14.0473 15.2786 13.9824 15.1154 13.9824C14.9522 13.9824 14.7956 14.0473 14.6802 14.1627C14.5648 14.2781 14.5 14.4346 14.5 14.5978V15.8286ZM14.5 15.8286C14.5 17.4607 15.1483 19.0259 16.3024 20.18C17.4565 21.3341 19.0217 21.9824 20.6538 21.9824M20.6538 21.9824H21.8846C22.0478 21.9824 22.2044 21.9176 22.3198 21.8022C22.4352 21.6868 22.5 21.5302 22.5 21.367C22.5 21.2038 22.4352 21.0473 22.3198 20.9319C22.2044 20.8165 22.0478 20.7517 21.8846 20.7517H20.6538C20.4906 20.7517 20.3341 20.8165 20.2187 20.9319C20.1033 21.0473 20.0385 21.2038 20.0385 21.367C20.0385 21.5302 20.1033 21.6868 20.2187 21.8022C20.3341 21.9176 20.4906 21.9824 20.6538 21.9824Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
