import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            onClick={onClick}
            className={className || "icons-chat-disabled"}
        >
            <title>{title}</title>
            <mask
                id="mask0_25633_3357"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
            >
                <rect x="0.808838" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_25633_3357)">
                <path
                    d="M5.8408 14.5833L4.17255 16.2516C3.93537 16.4888 3.66267 16.5424 3.35444 16.4126C3.04621 16.2828 2.89209 16.0491 2.89209 15.7115V3.58979C2.89209 3.16885 3.03792 2.81254 3.32959 2.52087C3.62126 2.22921 3.97756 2.08337 4.39851 2.08337H17.219C17.6399 2.08337 17.9962 2.22921 18.2879 2.52087C18.5795 2.81254 18.7254 3.16885 18.7254 3.58979V13.0769C18.7254 13.4979 18.5795 13.8542 18.2879 14.1458C17.9962 14.4375 17.6399 14.5833 17.219 14.5833H5.8408ZM5.30876 13.3334H17.219C17.2831 13.3334 17.3418 13.3066 17.3953 13.2532C17.4487 13.1998 17.4754 13.141 17.4754 13.0769V3.58979C17.4754 3.52568 17.4487 3.46691 17.3953 3.41348C17.3418 3.36006 17.2831 3.33335 17.219 3.33335H4.39851C4.3344 3.33335 4.27562 3.36006 4.22219 3.41348C4.16878 3.46691 4.14207 3.52568 4.14207 3.58979V14.4872L5.30876 13.3334Z"
                    fill={color || theme.gray400}
                />
            </g>
        </svg>
    );
};
