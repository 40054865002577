import { useQuery } from "@tanstack/react-query";

import { projects } from "../../services";

type Params = {
    page: number;
    length: number;
    searchTerm?: string;
    filterType?: "late" | "onTime" | "finished" | "noInformation" | "all";
    archived?: boolean;
};

export function useReadDemonstratives({
    page = 1,
    length = 6,
    searchTerm,
    filterType,
    archived,
}: Params) {
    const filters = {
        page,
        length,
        searchTerm,
        filterType,
        archived,
    };

    const enabled = (!!page && !!length && !searchTerm) || !!searchTerm;
    return useQuery({
        enabled,
        queryKey: ["readDemonstratives", filters],
        queryFn: async () => projects.readDemonstratives(filters),
        refetchOnMount: "always",
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
    });
}
