import { TTrackCreateBody } from "../../models";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (data: TTrackCreateBody = {}) => {
    const tempHeaders = {
        auth: true,
        "x-project-id": data.project,
        "x-sector-id": data.sectorId,
        "x-activity-id": data.activityId,
    };

    if (!data.project) delete tempHeaders["x-project-id"];
    if (!data.sectorId) delete tempHeaders["x-sector-id"];

    const { body, statusCode } = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/timeEntry/create`,
        method: "post",
        body: {
            start_at: data.startAt,
            end_at: data.endAt,
        },
        headers: tempHeaders,
    });

    const errorsMapper = {
        "start or end time is invalid!": "Tempo de início ou de fim inválidos!",
        "You don't have permission to access this resource": "Acesso negado!",
        "you must link the user to project":
            "Você não faz mais parte desse projeto!",
        "It's impossible to do this, you've exceeded the project hour limit":
            "Você excedeu as horas previstas neste projeto!",
        "It's impossible to do this, you've already exceeded the project hour limit":
            "Você excedeu as horas previstas neste projeto!",
        "Activity is archived":
            "Você não pode lançar tempo para uma atividade arquivada!",
        "It's impossible to do this, you've already exceeded the hour limit":
            "Você excedeu as horas previstas neste mês!",
        "impossible to do this to an canceled project!":
            "Você não pode lançar entradas de tempo para um projeto cancelado.",
        "impossible to do this to an paused project!":
            "Você não pode lançar entradas de tempo para um projeto pausado.",
        "impossible to do this to an finished project!":
            "Você não pode lançar entradas de tempo para um projeto finalizado.",
        "It's impossible to do this, you've exceeded the hour limit":
            "Você excedeu as horas previstas neste mês!",
        "project is archived": "O projeto está arquivado.",
    };
    const errorMessage = findErrorString<keyof typeof errorsMapper>(body);

    const GENERIC_ERROR_MESSAGE = "Campos obrigatórios não preenchidos";
    const errorFeedBacks = {
        "impossible to do this to a locked report!":
            "Relatório já fechado no mês desse registro",
        400: errorsMapper[errorMessage] || GENERIC_ERROR_MESSAGE,
        401: "Acesso negado!",
        409: "Você já possui uma entrada de tempo para esse período!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
