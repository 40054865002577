import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TToggleLockCollaborator = {
    userId?: string;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({ userId }: TToggleLockCollaborator = {}) => {
    const params = {
        userId,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/toggleLock`,
        method: "patch",
        headers: { auth: true, "x-user-id": userId },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        401: "Acesso negado!",
        404: "Rota não encontrada!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
