import { useQuery } from "@tanstack/react-query";

import { gamefication } from "../../services";
import { ReadGameficationDataUseCaseReturn } from "../../services/gamefication/types";
import { ProfileCard, ScoreCard } from "./components";
import * as s from "./styles";

export const ScorePage = () => {
    const { data } = useQuery<ReadGameficationDataUseCaseReturn>({
        queryKey: ["gamefication"],
        queryFn: gamefication.readGamefication,
    });

    return (
        <s.Container>
            <ProfileCard data={data} />
            <ScoreCard data={data} />
        </s.Container>
    );
};
