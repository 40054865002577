import { SyncSavedLocally } from "../../../../../assets";
import * as s from "./styled-discover-our-plans";

type TDiscoverOurPlans = {
    timeToEnd: string;
    unique?: boolean;

    setOpenModalPlans: (open: boolean) => void;
};

export const DiscoverOurPlans = ({
    timeToEnd,
    unique,
    setOpenModalPlans,
}: TDiscoverOurPlans) => {
    return (
        <s.Container>
            <div>
                <img
                    src={SyncSavedLocally}
                    alt="Foto de um computador com o check na tela"
                />
                <section>
                    <h1>
                        Período de teste grátis:{" "}
                        <strong>{timeToEnd || "0"}</strong>{" "}
                        {unique ? "restante" : "restantes"}{" "}
                    </h1>
                    <p>
                        Aproveite ao máximo o acesso ilimitado à plataforma e
                        escolha o plano que melhor te atende. Saiba mais sobre
                        os planos ao lado. A compra poderá ser feita após o
                        término do período de teste.
                    </p>
                </section>
            </div>
            <button
                onClick={() => {
                    setOpenModalPlans(true);
                }}
            >
                CONHEÇA OS PLANOS
            </button>
        </s.Container>
    );
};
