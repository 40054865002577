import styled from "styled-components";

export const Options = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 10px;
    position: static;
`;

export const Button = styled.button`
    width: 30px;
`;
