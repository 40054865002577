import styled from "styled-components";

import { theme } from "../../../../assets";

type TSelect = {
    error?: boolean;
};

type TPositionY = {
    positionY?: string;
    disabled?: boolean;
};

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 0.5rem;
    width: 100%;
`;

export const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const Flex = styled.div<TSelect>`
    .react-select__menu-list {
        max-height: 12.5rem;
    }

    .react-select__control {
        width: 100%;
        border: none;
        border-radius: 0.25rem;
        height: fit-content;
        padding-left: 0.5rem;
        padding-right: 1rem;
        width: 100%;
        box-shadow: none;
        font-size: 0.875rem;
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray200)};
        cursor: pointer;
    }

    .react-select__control--is-focused:hover {
        border: 1px solid
            ${({ theme, error }) => (error ? theme.red : theme.gray200)}!;
    }

    .react-select__clear-indicator,
    .react-select__indicator-separator {
        display: none;
    }

    .react-select--is-disabled {
        visibility: unset;
        cursor: not-allowed;
    }

    .react-select__placeholder {
        color: #b7b4bd;
        font-size: 16px;
    }

    .react-select__multi-value {
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.gray200};
        background: #f3f3f4;

        color: ${({ theme }) => theme.purple400};
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;

        .react-select__multi-value__label {
            color: ${({ theme }) => theme.purple400};
        }
    }
`;

export const Error = styled.p`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.red};
    height: 0.5rem;
`;

export const PositionIcons = styled.div<TPositionY>`
    display: flex;
    position: absolute;
    right: 0.625rem;
    top: ${({ positionY }) => (positionY ? `${positionY}rem` : "2.5rem")};
    right: 16px;
    ${({ disabled }) => (disabled ? `cursor: not-allowed` : `cursor: pointer`)};
`;

export const NoOptions = styled.div`
    margin: 1.25rem;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    gap: 0.9375rem;

    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.125rem;
    color: ${theme.purple600};
`;

export const HistoricEmails = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
    padding: 14xp 16px;
    align-items: center;

    div {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
    }
`;
