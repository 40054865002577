import { styled } from "styled-components";

export const ModalTitle = styled.h2`
    color: ${({ theme }) => theme.purple500};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.07px;
`;

export const ModalText = styled.h2`
    color: ${({ theme }) => theme.gray600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const ModalButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
        &:first-child {
            width: 113px;
        }

        width: 150px;
    }
`;
