import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="2 2 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-horizontalbar-circle"}
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6 21.2C14.1461 21.2 16.5879 20.1886 18.3882 18.3882C20.1886 16.5879 21.2 14.1461 21.2 11.6C21.2 9.05392 20.1886 6.61212 18.3882 4.81178C16.5879 3.01143 14.1461 2 11.6 2C9.05392 2 6.61212 3.01143 4.81178 4.81178C3.01143 6.61212 2 9.05392 2 11.6C2 14.1461 3.01143 16.5879 4.81178 18.3882C6.61212 20.1886 9.05392 21.2 11.6 21.2Z"
                fill={color || theme.yellow}
            />
            <rect x="7" y="11" width="10" height="3" rx="1.5" fill="white" />
        </svg>
    );
};
