import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import * as yup from "yup";

import { useReadManagers } from "../../../../hooks/react-query/use-read-managers";
import { useRequestAdditionalHours } from "../../../../hooks/react-query/use-request-additional-hours";
import { TOption } from "../../../../models";
import { Input, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess: () => void;
};

type FormData = {
    hours: number;
    manager: TOption;
};

const schema = yup.object().shape({
    hours: yup
        .number()
        .min(1, "Mínimo de 1 hora")
        .max(100, "Máximo de 100 horas")
        .required("Campo obrigatório")
        .typeError("Campo obrigatório"),
    manager: yup.object().required("Campo obrigatório"),
});

export function ModalRequestMoreHours({ setOpen, onSuccess }: Props) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            hours: 0,
        },
    });

    const readManagers = useReadManagers();
    const requestAdditionalHours = useRequestAdditionalHours({
        onSuccess: () => {
            toast.success("Solicitação enviada com sucesso!");
            onSuccess();
            setOpen(false);
        },
    });

    const managersOptions = readManagers.data
        ?.map((manager) => ({
            label: manager.name,
            value: manager.id_e,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    function submit(data: FormData) {
        requestAdditionalHours.mutate({
            requestedHours: data.hours,
            managerId: data.manager.value,
        });
    }

    return (
        <ContainerModal onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>Solicitar mais horas</s.Title>
                <s.Description>
                    Você completou suas horas para esse mês. Caso precise de
                    mais horas, você pode pedir ao seu gestor.
                </s.Description>
                <Controller
                    name="hours"
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Horas"
                            error={errors.hours?.message}
                            {...field}
                        />
                    )}
                />
                <s.ManagerSelectWrapper>
                    <s.Label htmlFor="manager">Gestor</s.Label>
                    <Controller
                        name="manager"
                        control={control}
                        render={({ field }) => (
                            <Select
                                id="manager"
                                options={managersOptions}
                                placeholder="Selecione o gestor"
                                styles={{
                                    placeholder: (base) => ({
                                        ...base,
                                        fontSize: 14,
                                    }),
                                }}
                                {...field}
                            />
                        )}
                    />
                </s.ManagerSelectWrapper>
                <s.Buttons>
                    <SecondaryButton
                        variation="small"
                        onClick={() => setOpen(false)}
                        type="button"
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton variation="small">Solicitar</PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
}
