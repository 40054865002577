import styled from "styled-components";

import { Level } from "../../../models";

export const Container = styled.div<{ open?: boolean }>`
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    width: 324px;
    height: ${({ open }) => (open ? "350px" : "0px")};
    overflow-y: scroll;
    transition: height 0.3s ease-in-out;
`;

export const Content = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const LevelContainer = styled.div`
    display: flex;
    gap: 25px;
`;

export const LevelInformation = styled.div``;

export const LevelTitle = styled.h3<{ level: Level }>`
    color: ${({ theme, level }) => theme.levels[level]};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const LevelPointsRange = styled.p`
    color: ${({ theme }) => theme.gray400};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
`;
