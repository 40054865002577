import { TCreateSketch } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    name,
    email,
    contract_format,
    value,
    role,
    sectors,
    projects,
    workload,
}: TCreateSketch = {}) => {
    const params = {
        name,
        email,
        contract_format,
        value,
        role,
        sectors,
        projects,
        workload,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/invite/createSketch`,
        method: "post",
        headers: { auth: true },
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        201: "Convite reenviado com sucesso!",
        400: "Erro! campos obrigatórios não preenchidos",
        409: "Usuário já existente/convite já existe para este usuário!",
        500: "Email não verificado",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
