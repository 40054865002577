import pt from "date-fns/locale/pt";
import { DayPicker, DateRange, ClassNames } from "react-day-picker";
import styles from "react-day-picker/dist/style.module.css";

import * as s from "./styled-double-calendar";

type TDoubleCalendar = {
    month: Date;
    setMonth: (e: Date) => void;
    period: DateRange | undefined;
    setPeriod: (e: DateRange | undefined) => void;
};

const classNames: ClassNames = {
    ...styles,
    caption_label: "caption-label",
    nav_button: "nav-button",
    head: "head",
    head_row: "head-row",
    head_cell: "head-cell",
    row: "table-row",
    cell: "table-cell",
    day_range_start: "range-start",
    day_range_middle: "range-middle",
    day_range_end: "range-end",
    button: "buttons",
};

const DoubleCalendar = ({
    month,
    setMonth,
    period,
    setPeriod,
}: TDoubleCalendar) => {
    return (
        <s.Container>
            <DayPicker
                classNames={classNames}
                month={month}
                onMonthChange={setMonth}
                numberOfMonths={2}
                mode="range"
                locale={pt}
                weekStartsOn={0}
                selected={period}
                onSelect={setPeriod}
            />
        </s.Container>
    );
};

export default DoubleCalendar;
