import { TResetPasswordResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type SaveProjectActivityFavoriteParams = {
    projectId?: string;
    activityId?: string;
};

export default async ({
    activityId,
    projectId,
}: SaveProjectActivityFavoriteParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/saveFavorite`,
        method: "post",
        body: { activityId, projectId },
        headers: { auth: true },
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases<TResetPasswordResponse>({
        body,
        statusCode,
    });
};
