import { TBaseSVG } from "../../models";

export default ({ color, className, onClick }: TBaseSVG) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        className={className || "icon-build-office"}
    >
        <g id="building-office-2">
            <path
                id="Vector"
                d="M2.25 21H21.75M3.75 3V21M14.25 3V21M20.25 7.5V21M6.75 6.75H7.5M6.75 9.75H7.5M6.75 12.75H7.5M10.5 6.75H11.25M10.5 9.75H11.25M10.5 12.75H11.25M6.75 21V17.625C6.75 17.004 7.254 16.5 7.875 16.5H10.125C10.746 16.5 11.25 17.004 11.25 17.625V21M3 3H15M14.25 7.5H21M17.25 11.25H17.258V11.258H17.25V11.25ZM17.25 14.25H17.258V14.258H17.25V14.25ZM17.25 17.25H17.258V17.258H17.25V17.25Z"
                stroke={color || "#CFCDD3"}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
);
