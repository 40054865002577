import { Doughnut } from "react-chartjs-2";

import { TDoughnutData } from "../../../../pages/report-summary/report-summary";

const DoughnutChartDashboard = ({ labels, colors, times }: TDoughnutData) => {
    const data = {
        labels,
        datasets: [
            {
                data: times,
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
        },
        animation: {
            duration: 0,
        },
        events: [],
        layout: {
            padding: 50,
        },
    };

    return (
        <div style={{ width: "500px", height: "500px" }}>
            <Doughnut
                data={data}
                options={options}
                id="doughnut-chart-dashboard"
            />
        </div>
    );
};

export default DoughnutChartDashboard;
