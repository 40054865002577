import styled from "styled-components";

import { theme } from "../../../assets";

type TPermissionsCheck = {
    checked?: boolean;
};

export const Container = styled.div<TPermissionsCheck>`
    gap: 8px;
    padding-bottom: 2.125rem;

    div#content {
        display: flex;
        align-items: center;
        gap: 0.5625rem;

        font-size: 0.75rem;
        font-weight: 400;
        color: ${theme.gray400};
    }
`;
