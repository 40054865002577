import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type UpdateProjectAdditionalCostParams = {
    title?: string;
    description?: string;
    date?: string;
    cost?: number;
    projectId?: string;
    additionalCostId?: string;
    recurringId?: string;
    updateChoice?: "onlyThis" | "thisAndNext";
};

export default async ({
    title,
    cost,
    description,
    date,
    projectId,
    additionalCostId,
    recurringId,
    updateChoice,
}: UpdateProjectAdditionalCostParams = {}) => {
    const headers = {
        auth: true,
        "x-project-id": projectId,
        "x-additional-cost-id": additionalCostId,
        "x-recurring-id": recurringId,
    };
    if (!recurringId) Reflect.deleteProperty(headers, "x-recurring-id");

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/additionalCost`,
        method: "put",
        body: {
            title,
            cost,
            description,
            date,
            updateChoice,
        },
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
