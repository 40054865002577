import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 16px;
    width: 490px;
    height: fit-content;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        h1 {
            color: ${({ theme }) => theme.purple500};
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
            text-align: center;
        }
        svg {
            cursor: pointer;
        }
    }

    section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 32px;
        width: 100%;

        h1 {
            color: ${({ theme }) => theme.purple600};
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
        }

        p {
            color: ${({ theme }) => theme.gray600};
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }

        p#paperonExperience {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 20px;
        }

        button {
            color: #fff;
            display: flex;
            height: 40px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            background: ${({ theme }) => theme.purple600};
            width: 100%;
        }
    }
`;
