import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 120px;
    height: 120px;
`;

export const Progress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 60px;

    .circular-progress,
    .circular-progress-background {
        position: absolute;
    }

    .circular-progress {
        .MuiCircularProgress-colorPrimary {
            color: ${({ theme }) => theme.pink600};
        }

        .MuiCircularProgress-circle {
            stroke: ${({ theme }) => theme.pink600};
        }
    }

    .circular-progress-background {
        .MuiCircularProgress-colorPrimary {
            color: ${({ theme }) => theme.gray200};
        }

        .MuiCircularProgress-circle {
            stroke: ${({ theme }) => theme.gray200};
        }
    }
`;

export const Points = styled.div<{ done?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme, done }) => (done ? theme.pink600 : theme.gray400)};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    position: absolute;

    .medal {
        width: 60px;
        height: 60px;
    }
`;
