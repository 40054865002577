import { TResetPasswordResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { ReadUserProjectsParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    page = 1,
    search,
}: ReadUserProjectsParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/projects`,
        method: "get",
        headers: { auth: true, "x-user-id": userId },
        params: { page, search },
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases<TResetPasswordResponse>({
        body,
        statusCode,
    });
};
