import { PrimaryButton } from "../../../../atoms";
import * as s from "../styled-modal-change-password";

type TChangePasswordSuccess = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ChangePasswordSuccess = ({ setOpen }: TChangePasswordSuccess) => {
    return (
        <s.Success>
            <h1>Senha alterada!</h1>
            <p>A sua senha foi alterada com sucesso.</p>
            <PrimaryButton onClick={() => setOpen(false)}>Ok</PrimaryButton>
        </s.Success>
    );
};
