import { useMutation } from "@tanstack/react-query";

import { createSocialMedia } from "../../services/back-office";

type Params = {
    onSuccess?: () => unknown;
};

export function useCreateBackOfficeSocialMedia({ onSuccess }: Params = {}) {
    return useMutation({
        mutationKey: ["createSocialMedia"],
        mutationFn: createSocialMedia,
        onSuccess,
    });
}
