import { TAuthResponse } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { Auth } from "./Authentication";
import { OK } from "./status-codes.constants";

const { REACT_APP_BASE_URL } = process.env;

export type OAuthParams = {
    credential?: string;
    terms?: boolean;
    howYouKnowUs?: string | null;
    reactivate?: boolean;
    organizationName?: string;
    userName?: string;
    phone?: string;
    voucher?: string;
};

export default async ({
    credential,
    terms,
    howYouKnowUs,
    reactivate,
    organizationName,
    userName,
    phone,
    voucher,
}: OAuthParams = {}) => {
    const auth = Auth.getInstance();
    auth.clearTokens();

    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/sessions/google-oauth`,
        method: "post",
        body: {
            credential,
            terms,
            howYouKnowUs,
            reactivate,
            organizationName,
            userName,
            phone,
            voucher,
        },
    });
    const { body, statusCode } = httpRequest;

    if (statusCode === OK) {
        auth.login({ body });
    }

    const errorFeedBacks = {
        500: "Ocorreu um erro com a comunicação com o servidor.",
    };

    return handleStatusCases<TAuthResponse>({
        body,
        statusCode,
        errorFeedBacks,
    });
};
