import React from "react";

import ContainerModal from "../../../../components/molecules/modals/container-modal";
import { TNPSHistory } from "../../types";
import * as s from "./styles";

type TPlanHistoryModal = {
    setOpen: React.Dispatch<React.SetStateAction<TNPSHistory[]>>;
    history: TNPSHistory[];
};

export const NPSHistoryModal = ({ history, setOpen }: TPlanHistoryModal) => {
    const formatDate = (date: string) => {
        if (!date) return "";
        return new Date(date).toLocaleDateString("pt-BR");
    };

    return (
        <ContainerModal
            onOpenChange={(open) => {
                if (!open) {
                    setOpen([]);
                }
            }}
            closeIcon
        >
            <s.Container>
                <s.HeaderRow>
                    <div>Data</div>
                    <div>Média do NPS</div>
                </s.HeaderRow>
                <s.Rows>
                    {history.map((nps) => (
                        <s.Row>
                            <div>{formatDate(nps.date)}</div>
                            <div>{nps.average?.toFixed(2)}</div>
                        </s.Row>
                    ))}
                </s.Rows>
            </s.Container>
        </ContainerModal>
    );
};
