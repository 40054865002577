import { pdf } from "@react-pdf/renderer";
import { endOfMonth, startOfMonth } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { toast } from "react-hot-toast";

import {
    Icons,
    theme,
    IlustracaoTelaVazia,
    ImageWomanSearch,
} from "../../../assets";
import {
    DemonstrativesReport,
    Input,
    LoadingGray,
    OrganizationOptions,
    PopoverCalendar,
    PrimaryButton,
    RadixCheckBox,
    SecondaryButton,
    SelectInput,
    Tag,
} from "../../../components";
import { useFetch } from "../../../hooks";
import {
    TReportProject,
    TSingleReportProject,
    TTrackReadBody,
} from "../../../models";
import { clients, projects, track } from "../../../services";
import { formatISOToDate, minutesToStringDate } from "../../../utils";
import { Mixpanel } from "../../../utils/mixpanel/Mixpanel";
import * as s from "./styled-organization-report";

type TOption = {
    label: string;
    value: string;
};

export type TColumnData = {
    dates?: string[];
    times?: number[];
    periods?: string[];
    abreviatedDates?: string[];
};

export type TDoughnutData = {
    labels?: string[];
    times?: number[];
    colors?: string[];
    maxTime?: number;
};

type TProject = {
    color: string;
    name: string;
    id_e: string;
    Client: { id_e: string; name: string };
};

export const OrganizationReport = () => {
    const [client, setClient] = useState<TOption>({
        label: "Todos",
        value: "all",
    });
    const [project, setProject] = useState<TOption>();
    const [clientsOptions, setClientsOptions] = useState<TOption[]>([]);
    const [projectsOptions, setProjectsOptions] = useState<TOption[]>([]);
    const [allProjects, setAllProjects] = useState<TProject[]>([]);
    const [period, setPeriod] = useState<DateRange>({
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
    });
    const [clear, setClear] = useState<boolean>(false);
    const [totalTime, setTotalTime] = useState<string>();
    const [projectsList, setProjectsList] = useState<TReportProject>([]);
    const [periodLabel, setPeriodLabel] = useState<string>();
    const [projectsReports, setProjectReport] = useState<TReportProject>();
    const [activitySearchTerm, setActivitySearchTerm] = useState<string>("");
    const [activitiesChecked, setActivitiesChecked] = useState<string[]>([]);
    const [allProjectsEntriesLength, setAllProjectsEntriesLength] =
        useState<number>(0);
    const [allEntriesChecked, setAllEntriesChecked] = useState<boolean>(false);
    const [isToGenerateDocument, setIsToGenerateDocument] =
        useState<boolean>(false);

    const getProjects = useFetch({
        fn: projects.getProject,
        errorMessage: "Erro ao pegar projetos",
        params: { all: true },
    });

    const getClients = useFetch({
        fn: clients.getClients,
        errorMessage: "Erro ao pegar clientes",
    });

    const getEntries = useFetch({
        fn: track.getEntries,
        start: false,
    });

    const isChecked = (id: string) => {
        return activitiesChecked.includes(id);
    };

    const isSomeChecked = () => {
        if (!activitiesChecked?.length) return false;

        return activitiesChecked?.length > 0 && !allEntriesChecked;
    };

    const toggleActivityCheck = (id: string) => {
        if (activitiesChecked.includes(id)) {
            setActivitiesChecked(
                activitiesChecked.filter((item) => item !== id)
            );
            return;
        }

        setActivitiesChecked([...activitiesChecked, id]);
        isSomeChecked();
    };

    const selectAllActivities = () => {
        if (allEntriesChecked || isSomeChecked()) {
            setActivitiesChecked([]);
            return;
        }

        const activitiesIds: string[] = [];

        projectsReports?.forEach((project) => {
            project?.project_entries?.forEach((entry) => {
                activitiesIds.push(entry.id_e);
            });
        });

        setActivitiesChecked(activitiesIds);
    };

    const getPeriod = (period: DateRange | undefined) => {
        setPeriod({
            from: period?.from,
            to: period?.to,
        });
    };

    const handleResetInputs = () => {
        setClient({
            label: "Todos",
            value: "all",
        });
        setProject(undefined);
        setPeriod({
            from: startOfMonth(new Date()),
            to: endOfMonth(new Date()),
        });
        setProjectsList([]);
        setProjectReport([]);
        setTotalTime(undefined);
        setActivitySearchTerm("");
        setClear(true);
        setActivitiesChecked([]);
    };

    const getReport = (searchTerm?: string) => {
        const reportInfos: TTrackReadBody = {
            startDate: period?.from?.toString(),
            endDate: period?.to?.toString(),
            responseFormat: "summaryReport" as const,
            page: 0,
            clientId: client.value,
            projectId: project?.value,
            all: true,
            searchTerm,
        };

        if (client.value === "all") delete reportInfos.clientId;
        if (project?.value === "all") delete reportInfos.projectId;

        getEntries.onRefresh(reportInfos);
    };

    const getAllActivityBySelected = (
        projects: TReportProject
    ): TReportProject => {
        if (!projects) return [];

        const projectsWithSelectedEntries = projects?.map((project) => {
            const selectedEntries = project?.project_entries?.filter(
                (entry) => {
                    return activitiesChecked.includes(entry.id_e);
                }
            );

            return {
                ...project,
                project_entries: selectedEntries || [],
            };
        });

        return projectsWithSelectedEntries;
    };

    const getHighlightedText = (text: string, highlight: string) => {
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return (
            <span>
                {parts.map((part: string, i: number) => (
                    <span
                        key={i}
                        style={
                            part.toLowerCase() === highlight.toLowerCase()
                                ? { backgroundColor: "#e5d6e8" }
                                : {}
                        }
                    >
                        {part}
                    </span>
                ))}
            </span>
        );
    };

    const getActivitiesHourSum = (activities: string[]) => {
        if (!projectsReports) return "00:00";

        const activitiesSum = projectsReports?.reduce((acc, project) => {
            const projectSum = project?.project_entries?.reduce(
                (acc, entry) => {
                    if (activities.includes(entry.id_e)) {
                        return acc + entry.entry_time;
                    }

                    return acc;
                },
                0
            );

            return acc + projectSum;
        }, 0);

        return minutesToStringDate(activitiesSum);
    };

    const renderActivities = () => {
        if (!projectsReports) return [];

        return (
            <>
                {activitiesChecked.length > 0 && (
                    <s.SelectedRowInformation>
                        <td
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "1.5rem",
                                cursor: "pointer",
                            }}
                        >
                            <Icons.XOutline
                                onClick={() => {
                                    setActivitiesChecked([]);
                                }}
                            ></Icons.XOutline>
                            <span>
                                {activitiesChecked.length} itens selecionados
                            </span>
                        </td>
                        <td>{getActivitiesHourSum(activitiesChecked)}</td>
                    </s.SelectedRowInformation>
                )}
                {projectsReports.map((projectReport) =>
                    projectReport?.project_entries?.map((activity) => (
                        <tbody key={activity.id_e}>
                            <tr className="activity">
                                <td>
                                    <div>
                                        <RadixCheckBox
                                            checked={isChecked(activity.id_e)}
                                            onClick={() => {
                                                toggleActivityCheck(
                                                    activity.id_e
                                                );
                                            }}
                                        ></RadixCheckBox>
                                    </div>
                                </td>

                                <td>
                                    <span className="percentage">
                                        {projectReport?.project_client || ""}
                                    </span>
                                </td>

                                <td className="entry">
                                    <Tag
                                        color={projectReport.project_color}
                                        maxWidth="6vw"
                                    >
                                        {projectReport.project_name}
                                    </Tag>
                                </td>

                                <td>
                                    {!activitySearchTerm && (
                                        <span className="time">
                                            {activity.description}
                                        </span>
                                    )}
                                    {activitySearchTerm && (
                                        <span className="time">
                                            {getHighlightedText(
                                                activity.description,
                                                activitySearchTerm
                                            )}
                                        </span>
                                    )}
                                </td>

                                <td>
                                    <span className="time">
                                        {(() => {
                                            try {
                                                return formatISOToDate(
                                                    activity.start_date
                                                );
                                            } catch (error) {
                                                return "-";
                                            }
                                        })()}
                                    </span>
                                </td>

                                <td>
                                    <span className="time">
                                        {activity.entry_percent}
                                    </span>
                                </td>

                                <td>
                                    <span className="time">
                                        {minutesToStringDate(
                                            activity.entry_time
                                        )}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    ))
                )}
            </>
        );
    };

    async function generatesDocument(
        projects: TSingleReportProject[],
        type: "all" | "selected"
    ) {
        const correctedTotalTime =
            type === "all"
                ? totalTime
                : getActivitiesHourSum(activitiesChecked);
        try {
            const blob = await pdf(
                <DemonstrativesReport
                    client={client.label}
                    period={periodLabel}
                    totalTime={correctedTotalTime}
                    pdfData={{
                        filteredProject: project?.label,
                        projects,
                    }}
                    allSelected={type === "all"}
                    currentSearchTerm={activitySearchTerm}
                />
            ).toBlob();

            const fileURL = window.URL.createObjectURL(blob);

            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", "Report.pdf");
            tempLink.click();
            Mixpanel.track("Baixar relatório dos demonstrativos");
        } catch (error) {
            toast.error("Erro ao fazer download relatório!");
        }
    }

    const calculateAndSetTotalTime = (timeRequested: {
        [key: string]: number;
    }) => {
        const tempTimeRequested: number[] = Object.values(timeRequested);
        let tempTotalTime = 0;

        tempTimeRequested.forEach((time) => {
            tempTotalTime += time;
        });
        setTotalTime(minutesToStringDate(tempTotalTime));
    };

    const setFilteredProjectsReports = (projectsReports: TReportProject) => {
        if (!project) return;
        if (project?.label?.includes("Todos")) {
            setProjectReport(projectsReports);
            return;
        }

        const entriesByProject = projectsReports.find((entry) => {
            return entry.project_id === project?.value;
        });

        if (!entriesByProject) {
            setProjectReport([]);
            return;
        }

        setProjectReport([entriesByProject] as TReportProject);
    };

    useEffect(() => {
        if (client) {
            setProject(undefined);

            if (client.label === "Todos") {
                const tempProjectsOptions: TOption[] = [];

                allProjects.forEach((project: TProject) => {
                    tempProjectsOptions.push({
                        label: project.name,
                        value: project.id_e,
                    });
                });

                if (tempProjectsOptions.length > 1) {
                    tempProjectsOptions.unshift({
                        label: "Todos",
                        value: "all",
                    });
                }

                setProjectsOptions(tempProjectsOptions);
            } else {
                const tempProjectsOptions: TOption[] = [];

                allProjects.forEach((project: TProject) => {
                    if (client.value === project.Client?.id_e) {
                        tempProjectsOptions.push({
                            label: project.name,
                            value: project.id_e,
                        });
                    }
                });

                if (tempProjectsOptions.length > 1) {
                    tempProjectsOptions.unshift({
                        label: "Todos",
                        value: "all",
                    });
                }

                setProjectsOptions(tempProjectsOptions);
            }
        }
    }, [client]);

    useEffect(() => {
        if (getProjects.response) {
            const tempProjectsOptions: TOption[] = [
                { label: "Todos", value: "all" },
            ];
            const tempAllProjects: TProject[] = [];

            getProjects.response.projects.forEach((project: TProject) => {
                tempProjectsOptions.push({
                    label: project.name,
                    value: project.id_e,
                });
                tempAllProjects.push(project);
            });
            setProjectsOptions(tempProjectsOptions);
            setAllProjects(tempAllProjects);
        }
    }, [getProjects.response]);

    useEffect(() => {
        if (getClients.response) {
            const tempClientsOptions: TOption[] = [
                { label: "Todos", value: "all" },
            ];

            getClients.response.forEach(
                (client: { name: string; id_e: string }) =>
                    tempClientsOptions.push({
                        label: client.name,
                        value: client.id_e,
                    })
            );
            setClientsOptions(tempClientsOptions);
        }
    }, [getClients.response]);

    useEffect(() => {
        if (getEntries.response) {
            const { entries, time_requested } = getEntries.response;
            calculateAndSetTotalTime(time_requested);

            const reportsProjects: TReportProject = entries.response2;
            setFilteredProjectsReports(reportsProjects);

            setProjectsList(reportsProjects);
        }
        if (getEntries.response && isToGenerateDocument) {
            generatesDocument(
                getAllActivityBySelected(
                    getEntries.response?.entries?.response2
                ),
                "selected"
            );
            setActivitySearchTerm("");
            setIsToGenerateDocument(false);
        }
    }, [getEntries.response]);

    useEffect(() => {
        if (projectsReports?.length) {
            const allEntriesLength = projectsReports
                ?.map((project) => project?.project_entries?.length)
                .reduce((a, b) => a + b, 0);

            setAllProjectsEntriesLength(allEntriesLength);
        }
    }, [projectsReports]);

    useEffect(() => {
        if (!allProjectsEntriesLength) return;

        setAllEntriesChecked(() => {
            return allProjectsEntriesLength === activitiesChecked.length;
        });
    }, [activitiesChecked]);

    useEffect(() => {
        setTimeout(() => {
            getReport(activitySearchTerm);
        }, 500);
    }, [activitySearchTerm]);

    return (
        <s.Container>
            <OrganizationOptions disableButton />

            <s.Filters>
                <s.FiltersWrapper>
                    <s.Filter>
                        <s.FilterName>Cliente</s.FilterName>

                        <SelectInput
                            options={clientsOptions}
                            disabled={getClients.loading}
                            placeholder={"Selecione cliente"}
                            value={client}
                            onChange={(value: TOption) => {
                                setClient(value);
                            }}
                            noForm
                            icon={<Icons.Chevron color={theme.gray800} />}
                        />
                    </s.Filter>

                    <s.Filter>
                        <s.FilterName>Projeto</s.FilterName>

                        <SelectInput
                            options={projectsOptions}
                            disabled={getProjects.loading || getClients.loading}
                            placeholder={"Selecione projeto"}
                            value={project}
                            onChange={(value: TOption) => {
                                setProject(value);
                            }}
                            noForm
                            icon={<Icons.Chevron color={theme.gray800} />}
                        />
                    </s.Filter>

                    <s.Filter
                        style={{
                            zIndex: 99,
                        }}
                    >
                        <s.FilterName style={{ marginBottom: "8px" }}>
                            Período
                        </s.FilterName>

                        <PopoverCalendar
                            alternativeInput
                            getPeriod={getPeriod}
                            clear={clear}
                            setClear={setClear}
                            setExternLabel={setPeriodLabel}
                        />
                    </s.Filter>
                </s.FiltersWrapper>

                <s.FilterButtons>
                    <SecondaryButton
                        className="clean"
                        onClick={() => {
                            handleResetInputs();
                        }}
                    >
                        <Icons.XOutline color={theme.purple500} /> LIMPAR
                    </SecondaryButton>

                    <PrimaryButton
                        className="filter"
                        onClick={() => {
                            getReport();
                        }}
                        disabled={!project || !client}
                    >
                        <Icons.FillerOutlined color={theme.white} /> FILTRAR
                    </PrimaryButton>
                </s.FilterButtons>
            </s.Filters>

            <>
                <div>
                    <s.Header>
                        <s.HeaderTitle>Relatório</s.HeaderTitle>
                        <s.PDFs>
                            {activitiesChecked.length > 0 && (
                                <SecondaryButton
                                    onClick={() => {
                                        setIsToGenerateDocument(true);
                                        getReport();
                                    }}
                                    disabled={!totalTime}
                                    style={{ gap: 8 }}
                                >
                                    <Icons.PDF
                                        color={theme.purple600}
                                        width="24px"
                                        height="24px"
                                    />
                                    pdf itens selecionados
                                </SecondaryButton>
                            )}
                            <SecondaryButton
                                onClick={() =>
                                    generatesDocument(projectsList, "all")
                                }
                                disabled={
                                    !totalTime || !projectsReports?.length
                                }
                                style={{ gap: 8 }}
                            >
                                <Icons.PDF
                                    color={
                                        !totalTime || !projectsReports?.length
                                            ? theme.gray500
                                            : theme.purple600
                                    }
                                    width="24px"
                                    height="24px"
                                />
                                PDF
                            </SecondaryButton>
                        </s.PDFs>
                    </s.Header>
                    <s.SearchWrapper>
                        <Input
                            mask=""
                            placeholder="Busque pela atividade"
                            icon={<Icons.SearchOutline />}
                            value={activitySearchTerm}
                            onChange={(e) => {
                                setActivitySearchTerm(e.target.value);
                            }}
                            disabled={
                                !projectsReports?.length && !activitySearchTerm
                            }
                        />
                        <LoadingGray
                            height="0"
                            scale={getEntries.loading ? "0.6" : "0"}
                        />
                    </s.SearchWrapper>
                </div>

                {Boolean(projectsReports?.length) && (
                    <s.Details>
                        <div>
                            <span className="title">CLIENTE</span>
                            <span className="info">{client.label}</span>
                        </div>

                        <div>
                            <span className="title">PROJETO</span>
                            <span className="info">{project?.label || ""}</span>
                        </div>

                        <div>
                            <span className="title">PERÍODO</span>
                            <span className="info">{periodLabel}</span>
                        </div>

                        <div>
                            <span className="title">TOTAL DE HORAS</span>
                            <span className="info">{totalTime}</span>
                        </div>
                    </s.Details>
                )}

                {Boolean(projectsReports?.length) && (
                    <s.ProjectTimeWrapper>
                        <div className="project-time-body">
                            <s.ActivitiesTable>
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <RadixCheckBox
                                                    indeterminate={isSomeChecked()}
                                                    checked={allEntriesChecked}
                                                    onClick={
                                                        selectAllActivities
                                                    }
                                                ></RadixCheckBox>
                                            </div>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                CLIENTE
                                            </span>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                PROJETO
                                            </span>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                ATIVIDADE (
                                                {allProjectsEntriesLength})
                                            </span>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                DATA
                                            </span>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                ATUAÇÃO
                                            </span>
                                        </th>
                                        <th>
                                            <span className="heading-text">
                                                HORAS REALIZADAS
                                            </span>
                                        </th>
                                    </tr>
                                </thead>

                                {renderActivities()}
                            </s.ActivitiesTable>
                        </div>
                    </s.ProjectTimeWrapper>
                )}

                {getEntries.loading && !projectsReports?.length && (
                    <LoadingGray></LoadingGray>
                )}

                {!getEntries.loading &&
                    !projectsReports?.length &&
                    !activitySearchTerm && (
                        <s.NoContent>
                            <IlustracaoTelaVazia />
                            <h2>
                                {totalTime
                                    ? "Não há dados a serem mostrados"
                                    : "Ainda não há filtro de relatório selecionado"}
                            </h2>
                            <p>
                                Utilize os filtros para visualizar e gere
                                relatório dos clientes
                            </p>
                        </s.NoContent>
                    )}

                {!getEntries.loading && !projectsReports?.length && (
                    <>
                        {activitySearchTerm && (
                            <s.NoContent>
                                <ImageWomanSearch />
                                <h2>
                                    Nenhum resultado correspondeu à pesquisa
                                </h2>
                                <p>
                                    Por favor, tente novamente e verifique se as
                                    palavras estão escritas corretamente.
                                </p>
                            </s.NoContent>
                        )}
                    </>
                )}
            </>
        </s.Container>
    );
};
