import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TStates = {
    label: string;
    value: string;
};

export default async () => {
    const httpResponse = await axiosHttpClient({
        url: "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome",
        method: "get",
    });
    const { body, statusCode } = httpResponse;

    const arrStates: TStates[] = body.map((item: Record<string, string>) => {
        return { label: item.nome, value: item.sigla };
    });

    return handleStatusCases({ body: arrStates, statusCode });
};
