import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
    CardNotification,
    PopoverRadix,
    PopoverNotificationFilter,
} from "../../..";
import { Icons, IlustracaoSemNotificacoes } from "../../../../assets";
import { useUser } from "../../../../context";
import { useFetch } from "../../../../hooks";
import { TNotification } from "../../../../models";
import { TCollaborators } from "../../../../pages/collaborators/collaborators";
import { notification } from "../../../../services";
import * as s from "./styled-popover-notification";

type TPopoverNotification = {
    renderAlerts: JSX.Element;
    notifications: TNotification[];
    setNotifications?: Dispatch<SetStateAction<TNotification[]>>;
    collabMode?: boolean;
    collabId?: string;
    collaborators?: TCollaborators[];
    setCollaborators?: Dispatch<SetStateAction<TCollaborators[]>>;
    filterSelected?: (value: string) => void;
};

const PopoverNotification = ({
    renderAlerts,
    notifications,
    setNotifications,
    collabMode,
    collabId,
    collaborators,
    setCollaborators,
}: TPopoverNotification) => {
    const { user } = useUser();
    const [select, setSelect] = useState("todos");
    const [open, setOpen] = useState(false);

    const notificationsFiltered = () => {
        if (select === "todos") return notifications;
        if (select === "naoLidos")
            return notifications?.filter((item) => !item.wasRead);
        return notifications?.filter((item) => item.wasRead);
    };

    const patchNotification = useFetch({
        fn: notification.patchNotification,
        start: false,
    });

    const getNotification = useFetch({
        fn: notification.getNotification,
        params: {
            userId: user.id_e,
        },
        start: false,
    });

    useEffect(() => {
        if (getNotification.response) {
            setNotifications?.(getNotification.response);
        }
    }, [getNotification.response]);

    const updateNotification = (id: string) => {
        const tempNotifications: TNotification[] = [];

        patchNotification.onRefresh({
            notificationId: id,
        });

        if (collabMode) {
            const tempCollaborators = collaborators?.map(
                (collaborator: TCollaborators) => {
                    if (collaborator.id_e === collabId) {
                        return {
                            ...collaborator,
                            Notifications: collaborator.Notifications.map(
                                (notification) => {
                                    if (notification.id_e === id)
                                        return {
                                            ...notification,
                                            wasRead: !notification.wasRead,
                                        };
                                    return notification;
                                }
                            ),
                        };
                    }
                    return collaborator;
                }
            );

            if (tempCollaborators) setCollaborators?.(tempCollaborators);
        } else {
            notifications?.forEach((item) => {
                if (item.id_e === id)
                    tempNotifications.push({ ...item, wasRead: !item.wasRead });
            });
            setNotifications?.(tempNotifications);

            getNotification.onRefresh({
                userId: user.id_e,
            });
        }
    };

    const handleOpen = (open: boolean) => {
        if (open) {
            notifications?.forEach((item) => {
                if (!item.wasRead) {
                    updateNotification(item.id_e);
                }
            });
        }
    };

    return (
        <>
            <PopoverRadix
                trigger={renderAlerts}
                side="right"
                sideOffset={16}
                align="start"
                open={open}
                onOpenChange={(open) => {
                    handleOpen(open);
                    setOpen(open);
                }}
            >
                <s.Container>
                    <div id="top-container">
                        <div id="header">
                            <div id="filter-label">
                                <Icons.FillerOutlined />
                                <p>Filtrar:</p>
                            </div>
                            <PopoverNotificationFilter
                                filterSelected={(filterSelected) =>
                                    setSelect(filterSelected)
                                }
                                notificationsQuantity={{
                                    todos: notifications?.length,
                                    naoLidos: notifications?.filter(
                                        (item) => !item.wasRead
                                    ).length,
                                    lidos: notifications?.filter(
                                        (item) => item.wasRead
                                    ).length,
                                }}
                            />
                        </div>
                    </div>

                    <s.Separator />

                    {notifications?.length > 0 ? (
                        <div id="content">
                            <s.Scroll>
                                <s.Viewport>
                                    {notificationsFiltered()?.map(
                                        (notification) => {
                                            return (
                                                <CardNotification
                                                    key={notification.id_e}
                                                    notification={notification}
                                                    updateNotification={
                                                        updateNotification
                                                    }
                                                    getNotification={
                                                        getNotification
                                                    }
                                                    setOpen={setOpen}
                                                />
                                            );
                                        }
                                    )}
                                </s.Viewport>
                                <s.Scrollbar orientation="vertical">
                                    <s.Thumb />
                                </s.Scrollbar>
                            </s.Scroll>
                        </div>
                    ) : (
                        <div id="no-content">
                            <div>
                                <IlustracaoSemNotificacoes />

                                <h2>Sem notificações</h2>
                                <p>
                                    Ainda não há notificações a serem exibidas,
                                    comece a utilizar o PaperOn para receber
                                    notificações!
                                </p>
                            </div>
                        </div>
                    )}
                </s.Container>
            </PopoverRadix>
        </>
    );
};

export default PopoverNotification;
