import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-chevron-rounded"}
        >
            <title>{title}</title>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.73967 15.175C10.8026 16.2379 12.5374 16.2379 13.6003 15.175L20.1203 8.65503C20.4132 8.36214 20.4132 7.88726 20.1203 7.59437C19.8274 7.30148 19.3526 7.30148 19.0597 7.59437L12.5397 14.1144C12.0626 14.5915 11.2774 14.5915 10.8003 14.1144L4.28033 7.59437C3.98744 7.30148 3.51256 7.30148 3.21967 7.59437C2.92678 7.88726 2.92678 8.36214 3.21967 8.65503L9.73967 15.175Z"
                fill={color || theme.gray800}
            />
        </svg>
    );
};
