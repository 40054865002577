import styled from "styled-components";

export const ActivitySection = styled.section<{ isOpen?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.5rem;
    padding: 0 1rem;
    text-align: center;

    border-bottom: 1px solid ${({ theme }) => theme.gray100};

    font-size: 1.25rem;
    color: ${({ theme }) => theme.gray600};
    font-weight: 500;

    .chevron {
        transition: transform 0.3s ease;
        transform: ${({ isOpen }) =>
            isOpen ? "rotate(0deg)" : "rotate(-90deg)"};
    }

    .left-align {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 600px;
        min-width: 0;
    }

    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .right {
        margin-right: 10px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.gray50};
    }

    &:hover .hidden {
        opacity: 1;
    }

    .hidden {
        opacity: 0;
    }
`;

export const MenuContainer = styled.div`
    width: 163px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 4px;
`;

export const MenuOption = styled.div<{ danger?: boolean; edit?: boolean }>`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    cursor: pointer;

    color: ${({ theme, danger, edit }) => {
        if (danger) {
            return theme.red;
        }
        if (edit) {
            return theme.gray800;
        }
        return theme.gray600;
    }} !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;

    svg {
        stroke: ${({ theme, danger }) => (danger ? theme.red : theme.gray600)};
    }

    &:hover {
        background-color: ${({ theme }) => theme.pink50};
    }
`;

export const More = styled.div`
    width: 50px;
`;
