import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    table {
        width: 100%;
    }

    thead {
        background-color: ${({ theme }) => theme.pink50};
    }

    th {
        text-align: left;
        font-size: 14px;
        padding: 10px;
        font-weight: 500;
    }

    table,
    th,
    td {
        border: 1px solid ${({ theme }) => theme.gray200};
        border-collapse: collapse;
    }

    td {
        padding: 6px;
        color: ${({ theme }) => theme.gray900};
        font-size: 14px;
    }

    .input-percentage {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;

        .percentage {
            width: 100%;
        }
    }

    h2 {
        margin-bottom: 30px;
        color: ${({ theme }) => theme.purple500};
        font-size: 20px;
    }

    .growth {
        padding: 10px;
        background-color: ${({ theme }) => theme.gray50};
    }

    .percentage {
        color: ${({ theme }) => theme.gray300};
        margin-left: 10px;
    }

    .input-wrapper {
        width: 80px;
        max-width: fit-content;
    }
`;

export const ChartContainer = styled.div`
    margin-top: 50px;
    height: 500px;
    padding-bottom: 60px;
`;
