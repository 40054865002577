import { IPermission } from "../../pages/organization/roles-form/roles-form";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TError = "The x-permissions-id field is required.";

export type TCreateRole = {
    permissions?: IPermission[];
    name?: string;
    description?: string;
};

export default async ({ permissions, name, description }: TCreateRole = {}) => {
    const params = {
        name,
        description,
        permissions,
    };

    const tempHeaders = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/roles/create`,
        method: "post",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const error: TError = body?.message?.["x-permissions-id"][0];

    const errorsMapper = {
        "The x-permissions-id field is required.":
            "Você não pode criar uma atuação sem permissões!",
    };

    const errorFeedBacks = {
        400: errorsMapper[error] || "Houve um problema ao criar a atuação!",
        404: "Sem atuações!",
        409:
            body.message === "Role already exists!"
                ? "Já existe uma atuação com o mesmo nome!"
                : "Houve um problema ao criar o projeto!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
