import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";

import { theme } from "../../../../../../assets";
import * as s from "./styled-report";

type ProjectStatusReport = {
    id_e: string;
    description: string;
    created_at: string;
    end_at: string;
    start_at: string;
    User: {
        name: string;
    };
};

type TReportParams = {
    statusReport: ProjectStatusReport;
};

dayjs.extend(utc);

export const Report = ({ statusReport }: TReportParams) => {
    const [open, setOpen] = useState(false);

    return (
        <s.Container>
            <s.Header>
                <s.ReportInformationContainer>
                    <s.ReportInformation>
                        <s.Title>Período do report</s.Title>
                        <s.Information>
                            {dayjs
                                .utc(statusReport.start_at)
                                .format("DD/MM/YYYY")}{" "}
                            -{" "}
                            {dayjs
                                .utc(statusReport.end_at)
                                .format("DD/MM/YYYY")}
                        </s.Information>
                    </s.ReportInformation>
                    <s.ReportInformation>
                        <s.Title>Data do report</s.Title>
                        <s.Information>
                            {dayjs(statusReport.created_at).format(
                                "DD/MM/YYYY"
                            )}{" "}
                            - {dayjs(statusReport.created_at).format("hh:mm")}
                        </s.Information>
                    </s.ReportInformation>
                    <s.ReportInformation>
                        <s.Title>Reportado por</s.Title>
                        <s.Information>
                            {statusReport?.User?.name}
                        </s.Information>
                    </s.ReportInformation>
                </s.ReportInformationContainer>
                <s.Chevron
                    color={theme.gray600}
                    onClick={() => setOpen(!open)}
                    open={open}
                />
            </s.Header>
            <s.DescriptionContainer open={open}>
                <s.Title
                    style={{
                        marginBottom: 12,
                    }}
                >
                    Descrição{" "}
                </s.Title>
                <s.Information>{statusReport.description}</s.Information>
            </s.DescriptionContainer>
        </s.Container>
    );
};
