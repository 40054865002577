export const encryptObjectToBase64 = async (object: unknown, key: string) => {
    if (key.length !== 32) {
        throw new Error("Key must be 32 characters long");
    }
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const keyBuffer = new TextEncoder().encode(key);
    const cryptoKey = await window.crypto.subtle.importKey(
        "raw",
        keyBuffer,
        { name: "AES-CBC" },
        false,
        ["encrypt"]
    );
    const jsonString = JSON.stringify(object);
    const dataBuffer = new TextEncoder().encode(jsonString);
    const encryptedData = await window.crypto.subtle.encrypt(
        { name: "AES-CBC", iv },
        cryptoKey,
        dataBuffer
    );
    const ivBase64 = btoa(String.fromCharCode(...iv));
    const encryptedDataBase64 = btoa(
        String.fromCharCode(...new Uint8Array(encryptedData))
    );
    return `${ivBase64}:${encryptedDataBase64}`;
};
