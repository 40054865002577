import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const BigCard = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    max-width: 740px;
    width: 100%;

    border: 1px solid ${({ theme }) => theme.gray200};
    padding: 22px 80px;

    .more {
        width: 20px;
        position: absolute;
        opacity: 0;
        margin-top: -9px;
        margin-left: 40px;
    }

    &:hover {
        background-color: ${({ theme }) => theme.gray50};

        .more {
            opacity: 1;
        }
    }
`;

export const BigCardLeftSide = styled.div`
    padding-right: 100px;

    h1 {
        color: ${({ theme }) => theme.gray600};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-bottom: 10px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    h3 {
        color: ${({ theme }) => theme.gray800};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.1px;
    }
`;

export const BigCardRightSide = styled.div`
    border-left: 1px solid ${({ theme }) => theme.gray200};
    padding-left: 100px;

    h1 {
        color: ${({ theme }) => theme.gray600};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-bottom: 10px;
    }

    h3 {
        color: ${({ theme }) => theme.purple500};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.1px;
    }
`;

export const SmallCardsContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const SmallCard = styled.div`
    width: 234px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.gray200};
    padding: 15px 20px;

    h1 {
        color: ${({ theme }) => theme.gray600};
        text-align: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        margin-bottom: 8px;
    }
`;

export const SmallCardCost = styled.div<{ purple?: boolean }>`
    display: flex;
    justify-content: space-between;

    h3 {
        color: ${({ theme }) => theme.gray600};
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    span {
        color: ${({ theme, purple }) =>
            purple ? theme.purple500 : theme.gray800};
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.08px;
    }
`;
