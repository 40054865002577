import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";
import { GiveUserAllProjectPermissionsParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    type = "all",
}: GiveUserAllProjectPermissionsParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/projects/mass`,
        method: "patch",
        body: {
            type,
        },
        headers: { auth: true, "x-user-id": userId },
    });
    const { body, statusCode } = httpRequest;

    const errors = {
        "target user cannot be admin":
            "Não é possível dar essas permissões a um administrador.",
    };
    const errorMessage = findErrorString<keyof typeof errors>(body);

    const errorFeedBacks = {
        400: errors[errorMessage] || "Erro ao dar permissões ao usuário",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
