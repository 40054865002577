import { TCreateClient } from "../../models";
import { axiosHttpClient } from "../axios";
import { findErrorString } from "../find-error-string";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ all, name, projectsId }: TCreateClient = {}) => {
    const params = {
        all,
        name,
        projectsId,
    };

    const tempHeaders = {
        auth: true,
        "x-projects-id": projectsId,
    };

    if (!projectsId) delete tempHeaders["x-projects-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/clients/create`,
        method: "post",
        headers: tempHeaders,
        body: params,
    });
    const { body, statusCode } = httpResponse;

    const errorsMapper = {
        "You don't have permission to access this resource":
            "Você não tem permissão para acessar esse recurso",
        "client already exists!": "Já existe um cliente com esse nome!",
        "The name field must be between 2 and 100 characters.":
            "O nome deve ter entre 2 e 100 caracteres",
    };
    const error = findErrorString<keyof typeof errorsMapper>(body);

    const GENERIC_ERROR_MESSAGE = "Erro ao tentar cadastrar cliente!";
    const errorFeedBacks = {
        400: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        403: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
        404: GENERIC_ERROR_MESSAGE,
        409: errorsMapper[error] || GENERIC_ERROR_MESSAGE,
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
