import styled from "styled-components";

import { PrimaryButton } from "../../../../components";

export const Container = styled.section`
    background-color: ${({ theme }) => theme.white};
    padding: 29px 24px;
    border: 1px solid var(--color-gray-200, #cfcdd3);
    border-top: none;
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    border-radius: 0px 0px 4px 4px;
    height: 100%;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
`;

export const AddButton = styled(PrimaryButton)`
    width: 15%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 340px;
    padding-right: 24px;
`;

export const NoData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    h3 {
        color: ${({ theme }) => theme.purple800};
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        margin-top: 24px;
    }

    p {
        color: ${({ theme }) => theme.gray600};
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;
        margin-bottom: 24px;
        margin-top: 12px;
    }
`;
