import { Document, Page, Text, View } from "@react-pdf/renderer";

import { TActivity, TReportProject } from "../../../models";
import * as s from "./styled-pdf-template";

type TPDFGenerator = {
    children: React.ReactNode;
};

export type TPDFData = {
    pdfData?: {
        activities?: TActivity;
        projects?: TReportProject;
        period?: { startDate: Date; endDate: Date };
        totalTime?: string;
        barChartImg?: string;
        doughnutChartImg?: string;
        filteredProject?: string;
    };
};

const PDFTemplate = ({ children }: TPDFGenerator) => {
    return (
        <Document>
            <Page style={s.PDF.PageStyle}>
                <Text
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                    style={s.PDF.Pagination}
                />

                <View style={s.PDF.PageContent}>{children}</View>
            </Page>
        </Document>
    );
};

export default PDFTemplate;
