import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async () => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/managers`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({
        body,
        statusCode,
    });
};
