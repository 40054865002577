type TEloCard = {
    width?: string;
    height?: string;
};

export default ({ width, height }: TEloCard) => {
    return (
        <div
            style={{
                display: "flex",
                width: "24px",
                height: "16px",
                padding: "2px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
                border: "0.25px solid var(--common-black-12-p, rgba(0, 0, 0, 0.12))",
                background: "#FFF",
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={width || "22"}
                height={height || "14"}
                viewBox="0 0 21 8"
                fill="none"
            >
                <path
                    d="M3.25459 1.76679C3.48967 1.68843 3.74043 1.64664 4.00059 1.64664C5.13944 1.64664 6.09023 2.45533 6.30755 3.53045L7.92179 3.20133C7.55193 1.37498 5.93664 0 4.00059 0C3.55654 0 3.1313 0.0720925 2.73218 0.205829L3.25459 1.76679Z"
                    fill="#FFCB05"
                />
                <path
                    d="M1.34991 6.99804L2.44174 5.7641C1.95486 5.33259 1.64768 4.70257 1.64768 4.00045C1.64768 3.29937 1.95486 2.66935 2.44174 2.23888L1.34991 1.00391C0.52241 1.73737 0 2.80831 0 4.00045C0 5.19363 0.52241 6.26457 1.34991 6.99804Z"
                    fill="#00A4E0"
                />
                <path
                    d="M6.30755 4.47168C6.08918 5.54575 5.13944 6.3534 4.00163 6.3534C3.74043 6.3534 3.48863 6.31161 3.25459 6.2322L2.73218 7.79525C3.1313 7.92794 3.55758 8.00004 4.00163 8.00004C5.93559 8.00004 7.54984 6.62714 7.92179 4.80289L6.30755 4.47168Z"
                    fill="#EF4123"
                />
                <path
                    d="M9.33279 5.81468C9.2795 5.729 9.20845 5.59213 9.16457 5.49078C8.90859 4.89733 8.8971 4.28297 9.11233 3.69369C9.3495 3.04695 9.80296 2.55275 10.3891 2.29991C11.1257 1.98333 11.9396 2.04497 12.6459 2.46394C13.0941 2.72097 13.4118 3.118 13.6531 3.68011C13.6834 3.7522 13.7106 3.82848 13.7377 3.8943L9.33279 5.81468ZM10.8039 3.25383C10.2804 3.47846 10.0109 3.96953 10.0673 4.54418L12.2823 3.59026C11.901 3.14307 11.4057 2.99471 10.8039 3.25383ZM12.5581 5.32884C12.5571 5.32884 12.5571 5.32988 12.5571 5.32988L12.5111 5.29854C12.3784 5.51272 12.1726 5.68721 11.9124 5.80005C11.4172 6.01528 10.9585 5.95991 10.6284 5.67049L10.5981 5.71646C10.5981 5.71646 10.5981 5.71542 10.597 5.71542L10.0349 6.5565C10.1749 6.65367 10.3243 6.73516 10.481 6.80099C11.1017 7.05906 11.7369 7.04652 12.3628 6.77487C12.8152 6.57844 13.1704 6.27962 13.4128 5.90035L12.5581 5.32884Z"
                    fill="black"
                />
                <path
                    d="M15.2777 1.06348V5.738L16.0049 6.03264L15.5922 6.99596L14.7898 6.66162C14.6101 6.58326 14.4868 6.46415 14.3949 6.32937C14.3061 6.19249 14.2402 6.00338 14.2402 5.75054V1.06348H15.2777Z"
                    fill="black"
                />
                <path
                    d="M17.1608 4.54823C17.1608 4.15015 17.3374 3.79282 17.6153 3.55043L16.8693 2.71875C16.3636 3.16593 16.0449 3.81894 16.0449 4.54614C16.0439 5.27438 16.3625 5.92844 16.8672 6.37562L17.6122 5.54394C17.3353 5.3005 17.1608 4.94526 17.1608 4.54823Z"
                    fill="black"
                />
                <path
                    d="M18.4853 5.87376C18.339 5.87376 18.1969 5.84869 18.0653 5.80481L17.709 6.86321C17.9524 6.9447 18.2126 6.98963 18.4842 6.98963C19.6649 6.99068 20.6502 6.15273 20.879 5.04L19.784 4.81641C19.6597 5.42136 19.1258 5.87481 18.4853 5.87376Z"
                    fill="black"
                />
                <path
                    d="M18.4871 2.10547C18.2165 2.10547 17.9563 2.14935 17.7129 2.2298L18.066 3.28925C18.1987 3.24537 18.3398 3.22134 18.4861 3.22134C19.1276 3.22238 19.6625 3.67792 19.7837 4.28392L20.8787 4.06242C20.6541 2.94759 19.6688 2.10651 18.4871 2.10547Z"
                    fill="black"
                />
            </svg>
        </div>
    );
};
