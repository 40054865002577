import { useState } from "react";

import { Icons, theme } from "../../../assets";
import { PopoverRadix } from "../../atoms";
import { ModalFeatureVideo } from "../modals/modal-feature-video/modal-feature-video";
import * as s from "./styles";

export type Tutorial = {
    label: string;
    url: string;
};

type Props = {
    tutorials: Tutorial[];
    screen: string;
    className?: string;
};

export function TutorialsPopover({ tutorials, className, screen }: Props) {
    const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(
        null
    );

    return (
        <>
            {selectedTutorial && (
                <ModalFeatureVideo
                    title={selectedTutorial.label}
                    embed={selectedTutorial.url}
                    setOpen={() => {
                        setSelectedTutorial(null);
                    }}
                />
            )}
            <PopoverRadix
                align="start"
                sideOffset={10}
                closeIcon={
                    <Icons.XOutline
                        width="16px"
                        height="16px"
                        color={theme.gray600}
                    />
                }
                trigger={
                    <s.Trigger className={className}>
                        <Icons.VideoCamera
                            color={theme.gray600}
                            width="20px"
                            height="20px"
                        />
                        Ver tutoriais
                    </s.Trigger>
                }
            >
                <s.Content>
                    <s.Title>Tutoriais {screen}</s.Title>
                    {tutorials.map((tutorial, index) => (
                        <s.TutorialLabel
                            key={index}
                            onClick={() => setSelectedTutorial(tutorial)}
                        >
                            {tutorial.label}
                        </s.TutorialLabel>
                    ))}
                </s.Content>
            </PopoverRadix>
        </>
    );
}
