import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import {
    Input,
    PrimaryButton,
    SecondaryButton,
    TextArea,
} from "../../../../../../components";
import ContainerModal from "../../../../../../components/molecules/modals/container-modal";
import { useFetch } from "../../../../../../hooks";
import { TProject } from "../../../../../../models";
import { createStatusReport } from "../../../../../../services/projects";
import * as s from "./styled-modal-create";
import schema from "./validation";

type TModalCreate = {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>;
    project: TProject;
};

type TFields = {
    startAt: string;
    endAt: string;
    description: string;
};

export const ModalCreate = ({ project, setIsOpenModal }: TModalCreate) => {
    const {
        getValues,
        handleSubmit,
        control,
        trigger,
        formState: { errors, isValid },
    } = useForm<TFields>({
        resolver: yupResolver(schema),
    });

    const statusReportFetch = useFetch({
        fn: createStatusReport,
        start: false,
    });

    useEffect(() => {
        if (statusReportFetch.response) {
            setIsOpenModal(false);
            toast.success("Status Report criado com sucesso!");
        }
    }, [statusReportFetch.response]);

    const cancel = () => {
        setIsOpenModal(false);
    };

    const save = () => {
        if (isValid && project?.id_e) {
            const { startAt, endAt, description } = getValues();
            statusReportFetch.onRefresh({
                startAt,
                endAt,
                description,
                projectId: project.id_e,
            });
        }
    };

    return (
        <ContainerModal onOpenChange={setIsOpenModal}>
            <s.Container onSubmit={handleSubmit(save)}>
                <s.Title>Adicionar Status Report</s.Title>
                <s.DateFields>
                    <Controller
                        name="startAt"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                placeholder="01/01/2021"
                                mask="99/99/9999"
                                label={"Início"}
                                value={value}
                                error={errors.startAt?.message}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    trigger("endAt");
                                    trigger("startAt");
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="endAt"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                placeholder="01/01/2021"
                                mask="99/99/9999"
                                label={"Fim"}
                                value={value}
                                error={errors.endAt?.message}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    trigger("startAt");
                                    trigger("endAt");
                                }}
                            />
                        )}
                    />
                </s.DateFields>
                <s.DescriptionField>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <TextArea
                                label={"Descrição"}
                                error={errors.description?.message}
                                onChange={(e) => onChange(e.target.value)}
                                charCount
                                counter={getValues("description")}
                                maxLength={500}
                            />
                        )}
                    />
                </s.DescriptionField>
                <s.Buttons>
                    <SecondaryButton onClick={cancel}>cancelar</SecondaryButton>
                    <PrimaryButton type="submit">
                        salvar status report
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
