import { Icons } from "../../../../assets";
import { Tooltip } from "../../../../components";
import * as s from "./styled-limit-reached-warning";

type LimitReachedWarningProps = {
    limits: {
        max: boolean;
    };
};

export const LimitReachedWarning = ({ limits }: LimitReachedWarningProps) => {
    return (
        <s.BreadCrumb>
            <s.TimeLimit>
                <Tooltip
                    side="top"
                    align={"end"}
                    content={
                        <s.TimeLimitWarningTooltip>
                            <header>
                                <Icons.ExclamationFilled />
                                Limite de horas
                            </header>
                            {limits.max ? (
                                <p>
                                    Você atingiu 100% do limite de horas. Se
                                    necessário, solicite mais horas para o seu
                                    gestor.
                                </p>
                            ) : (
                                <p>
                                    Você atingiu mais de 90% do limite de horas
                                    mensais. Ao completar 100% do limite de
                                    horas, o seu cronômetro será inativado. Se
                                    necessário, solicite mais horas para o seu
                                    gestor.
                                </p>
                            )}
                        </s.TimeLimitWarningTooltip>
                    }
                >
                    <s.Flex>
                        <s.TimeLimitWarning>
                            <Icons.ExclamationFilled />
                            {limits.max ? (
                                <p>
                                    Você atingiu <b>100%</b> do limite de horas
                                </p>
                            ) : (
                                <p>
                                    Você completou mais de 90% das horas totais
                                </p>
                            )}
                        </s.TimeLimitWarning>
                    </s.Flex>
                </Tooltip>
            </s.TimeLimit>
        </s.BreadCrumb>
    );
};
