import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";
import { RemoveUserAllProjectPermissionsParams } from "./types";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    type = "all",
    removeProjects = true,
}: RemoveUserAllProjectPermissionsParams = {}) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/users/projects/mass`,
        method: "delete",
        body: { removeProjects, type },
        headers: { auth: true, "x-user-id": userId },
    });
    const { body, statusCode } = httpRequest;

    return handleStatusCases({
        body,
        statusCode,
    });
};
