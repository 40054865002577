import { validateCep } from "validations-br";
import * as yup from "yup";

import { removePhoneMask, validationErrors } from "../../../../../utils";

const { cep, celNumber, address, houseNumber, city, state } = validationErrors;

export default yup.object().shape({
    cep: yup
        .string()
        .required(cep.required)
        .test("test-cep", cep.invalid, (value) => {
            if (value) return validateCep(value);
            return false;
        }),
    celNumber: yup
        .string()
        .required(celNumber.required)
        .test("telefone", celNumber.invalid, (value) => {
            if (value) {
                return /(\d{2}\+) (\(\d{2}\)) (\d{5}) (\d{4})/.test(
                    removePhoneMask(value)
                );
            }
            return false;
        })
        .nullable(),
    address: yup.string().required(address.required),
    houseNumber: yup
        .string()
        .required(houseNumber.required)
        .test("houseNumber", houseNumber.invalid, (value) => {
            if (value) {
                return /^\d+$/.test(value);
            }
            return false;
        })
        .max(5),
    complement: yup.string().nullable(),
    city: yup.string().required(city.required),
    state: yup.string().required(state.required),
    socialName: yup.string(),
});
