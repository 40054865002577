import { TGetClient } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({ all, active, clientId }: TGetClient = {}) => {
    const params = {
        all,
        active,
    };

    const tempHeaders = {
        auth: true,
        "x-client-id": clientId,
    };

    if (!clientId) delete tempHeaders["x-client-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/clients/read`,
        method: "get",
        headers: tempHeaders,
        params,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        403:
            body.message === "You don't have permission to access this resource"
                ? "Você não tem permissão para acessar esse recurso"
                : "Erro ao tentar listar clientes",
        404: "Sem clientes!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
