/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { pdf } from "@react-pdf/renderer";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import XLSX from "xlsx-js-style";

import { FotoUser, Icons, IllustrationEmptySearch, theme } from "../../assets";
import EmptyScreen from "../../assets/images/ilustracao-tela-vazia";
import {
    LoadingGray,
    ModalInvoicing,
    Tooltip,
    SelectInput,
    ModalDocument,
    SecondaryButton,
    PrimaryButton,
} from "../../components";
import { ManageReportsOptions } from "../../components/molecules/options/reports-options/manage-reports-options";
import { CollaboratorInvoice } from "../../components/molecules/pdf-templates/pdf-types/collaborator-invoice";
import { useUser } from "../../context";
import { useFetch } from "../../hooks";
import { TOption, TReport, TReports } from "../../models";
import { timeReports } from "../../services";
import {
    formatToBRMoney,
    formatISOToMonthYear,
    minutesToStringDate,
} from "../../utils";
import * as s from "./styled-report-manage-invoicing";

type TTotalValue = {
    totalDiscount: number;
    totalAdditional: number;
    totalValue: number;
};

type TGetReportsParams = {
    page: number;
    all: boolean;
    length: number;
    monthlyReports: string;
    users: boolean;
    projects: boolean;
    responsible: string;
    approved?: boolean;
    reportBilling: boolean;
    filter?: string;
    billed?: boolean;
    projectId?: string;
};

type TStatus = "concluido" | "faturar" | "todos";

type TStatusFilter = "concluido" | "faturar" | "todos";

type TGetReportServiceParams = {
    status: TStatus;
    page?: number;
    date?: Date;
};

type SheetData = {
    Profissional: string | undefined;
    "Horas trabalhadas": string;
    "Valor hora": string | undefined;
    Desconto: string;
    Adicionais: string;
    "Valor total": string;
    Status: string;
}[];

export const ReportManageInvoicing = () => {
    const { user, userProjects } = useUser();
    const firstSearch = useRef(true);
    const defaultTakeValue = 8;

    const [reports, setReports] = useState<TReports>();
    const [modalReport, setModalReport] = useState<TReport>();
    const [modalDocument, setModalDocument] = useState<TReport>();
    const [monthsAvailable, setMonthsAvailable] = useState<TOption[]>([]);
    const [choiceDate, setChoiceDate] = useState(new Date());
    const [status, setStatus] = useState<TStatus>("todos");
    const [totalValue, setTotalValue] = useState<TTotalValue>({
        totalDiscount: 0,
        totalAdditional: 0,
        totalValue: 0,
    });
    const [statusFilter, setStatusFilter] = useState<TStatusFilter>("todos");
    const [openModal, setOpenModal] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [filterByStatusIsOpen, setFilterByStatusOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [orderByName, setOrderByName] = useState<"asc" | "desc">("asc");
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(defaultTakeValue);

    const getReports = useFetch({
        fn: timeReports.getReports,
        params: {
            page,
            all: true,
            length: take,
            monthlyReports: choiceDate.toISOString(),
            users: true,
            projects: true,
            responsible: "biller",
            approved: true,
            reportBilling: true,
        },
        noError: true,
    });

    const getReportService = ({
        page = 1,
        status,
        date = choiceDate,
    }: TGetReportServiceParams) => {
        setStatus(status);
        setPage(page);

        const params: TGetReportsParams = {
            page,
            all: true,
            length: take,
            users: true,
            projects: true,
            responsible: "biller",
            reportBilling: true,
            approved: true,
            monthlyReports: date?.toISOString(),
            filter: searchValue,
            billed: status === "concluido",
            projectId: userProjects?.map((project) => project.id_e)?.join(", "),
        };

        if (status === "todos") {
            delete params.billed;
        }

        if (!searchValue) {
            delete params.filter;
        }

        getReports.onRefresh(params);
    };

    const getReportsByPage = (page?: number) => {
        if (!page) {
            getReports.onRefresh();
            return;
        }
        setPage(page);
        getReportService({ page, status });
    };

    const billed = (report: TReport) => {
        return report.ReportBilling;
    };

    const getAvailableMonthsForPeriod = (availableMonths: string[]) => {
        return availableMonths?.map((month) => {
            return {
                value: month,
                label: formatISOToMonthYear(month),
            };
        });
    };

    const calculateTotalValue = (reports: TReport[]) => {
        if (!reports?.length)
            return {
                totalDiscount: 0,
                totalAdditional: 0,
                totalValue: 0,
            };
        const values = reports.map((report) => ({
            discount: parseFloat(report?.ReportBilling?.discounts || "0"),
            additional: parseFloat(report?.ReportBilling?.additional || "0"),
            total: parseFloat(report?.ReportBilling?.final_total || "0"),
        }));
        const totalDiscount = values.reduce(
            (acc, cur) => acc + cur.discount,
            0
        );
        const totalAdditional = values.reduce(
            (acc, cur) => acc + cur.additional,
            0
        );
        const totalValue = values.reduce((acc, cur) => acc + cur.total, 0);
        const result = {
            totalDiscount,
            totalAdditional,
            totalValue,
        };
        setTotalValue(result);

        return result;
    };

    const correctUserName = (name: string) => {
        const nameArray = name.split(" ");
        const firstName = nameArray[0];
        delete nameArray[0];

        return (
            <s.Name>
                <p>{firstName}</p>
                {nameArray.length > 1 && (
                    <span>{nameArray.map((name) => `${name} `)}</span>
                )}
            </s.Name>
        );
    };

    const styleSheet = (sheet: XLSX.WorkSheet, data: SheetData) => {
        const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H"];
        const header = Object.keys(data[0]).map(
            (_, index) => `${alphabet[index]}1`
        );
        const footer = Object.keys(data[0]).map(
            (_, index) => `${alphabet[index]}${data.length + 1}`
        );
        for (let i = 0; i < Object.keys(data[0]).length; i += 1) {
            sheet[header[i]].s = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "fff6edff" },
                },
            };
            sheet[footer[i]].s = {
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "fff3f3f4" },
                },
            };
        }
        const lastColumn = header[header.length - 1].slice(0, 1);
        for (let i = 2; i < data.length + 1; i += 1) {
            if (sheet[lastColumn + i]) {
                const value = sheet[lastColumn + i].v;
                const greenARGB = "ff278027";
                const redARGB = "ffd64747";
                sheet[lastColumn + i].s = {
                    font: {
                        bold: true,
                        color: {
                            rgb: value === "Faturado" ? greenARGB : redARGB,
                        },
                    },
                };
            }
        }
        sheet["!cols"] = Object.keys(data[0]).map(() => ({ wch: 15 }));
        sheet["!rows"] = Array.from({ length: reports!.length + 2 }).map(
            () => ({ hpx: 20 })
        );
        return sheet;
    };

    const generateSheet = (): XLSX.WorkSheet => {
        const data: SheetData = reports!.map((report) => ({
            Profissional: report.User?.name,
            "Horas trabalhadas": minutesToStringDate(report.total_time),
            "Valor hora": report.User?.hourValue?.toString(),
            Desconto: formatToBRMoney(
                parseFloat(report.ReportBilling?.discounts || "0")
            ),
            Adicionais: formatToBRMoney(
                parseFloat(report.ReportBilling?.additional || "0")
            ),
            "Valor total": formatToBRMoney(
                parseFloat(report.ReportBilling?.final_total || "0")
            ),
            Status: billed(report) ? "Faturado" : "Pendente",
        }));
        const totalRowData = {
            Profissional: "Valor total",
            "Horas trabalhadas": "",
            "Valor hora": "",
            Desconto: formatToBRMoney(totalValue.totalDiscount),
            Adicionais: formatToBRMoney(totalValue.totalAdditional),
            "Valor total": formatToBRMoney(totalValue.totalValue),
            Status: "",
        };
        data.push(totalRowData);
        const sheet = XLSX.utils.json_to_sheet(data!);
        const styledSheet = styleSheet(sheet, data);
        return styledSheet;
    };

    async function generateXLSX() {
        try {
            const sheet = generateSheet();
            const excelBuffer = XLSX.write(
                {
                    Sheets: {
                        data: sheet,
                    },
                    SheetNames: ["data"],
                },
                {
                    bookType: "xlsx",
                    type: "array",
                }
            );
            const blob = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            });
            const fileURL = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute(
                "download",
                `relatorio-faturamento-${dayjs().format("DD-MM-YYYY")}.xlsx`
            );
            tempLink.click();
        } catch (error) {
            toast.error("Erro ao fazer download relatório!");
        }
    }

    async function generatePDF() {
        try {
            const blob = await pdf(
                <CollaboratorInvoice
                    reports={reports || []}
                    period={formatISOToMonthYear(choiceDate.toISOString())}
                    organization={user?.Organization?.name}
                    totalValue={calculateTotalValue(reports || [])}
                />
            ).toBlob();

            const fileURL = window.URL.createObjectURL(blob);

            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", "Report.pdf");
            tempLink.click();
        } catch (error) {
            toast.error("Erro ao fazer download relatório!");
        }
    }

    useEffect(() => {
        setTake(defaultTakeValue);
        getReportService({ status: statusFilter });
    }, [statusFilter]);

    useEffect(() => {
        if (getReports.response) {
            setReports(getReports.response.reports);
            setMonthsAvailable(
                getAvailableMonthsForPeriod(getReports.response.all_months)
            );
            calculateTotalValue(getReports.response.reports);
        }
    }, [getReports.response]);

    useEffect(() => {
        const delay = setTimeout(() => {
            getReportService({ status });
        }, 400);

        return () => {
            clearTimeout(delay);
            setPage(1);
            firstSearch.current = false;
        };
    }, [searchValue]);

    useEffect(() => {
        if (isDone) {
            getReportService({
                page,
                status,
                date: choiceDate,
            });
            setIsDone(false);
        }
    }, [isDone]);

    useEffect(() => {
        setReports((olderReports) =>
            orderBy(olderReports, "User.name", orderByName)
        );
    }, [orderByName]);

    useEffect(() => {
        if (take) {
            getReportService({ status });
        }
    }, [take]);

    const generateTableHeader = () => {
        return (
            <div className="table-header">
                <div className="table-row-header">
                    <s.HeaderText
                        key={orderByName}
                        click
                        orderBy={orderByName}
                        onClick={() => {
                            setOrderByName(
                                orderByName === "asc" ? "desc" : "asc"
                            );
                        }}
                    >
                        Nome
                        <Icons.Chevron />
                    </s.HeaderText>

                    <s.HeaderText>
                        Horas <br /> trabalhadas
                    </s.HeaderText>

                    <s.HeaderText>Valor hora</s.HeaderText>

                    <s.HeaderText>Desconto</s.HeaderText>

                    <s.HeaderText>Adicionais</s.HeaderText>

                    <s.HeaderText>Valor total</s.HeaderText>

                    <Tooltip
                        open={filterByStatusIsOpen}
                        content={
                            <s.RadioGroupRoot
                                defaultValue="todos"
                                value={statusFilter}
                            >
                                <div>
                                    <s.RadioGroupItem
                                        value="todos"
                                        id="todos"
                                        onClick={() => setStatusFilter("todos")}
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    <s.RadioGroupItemLabel htmlFor="todos">
                                        Todos
                                    </s.RadioGroupItemLabel>
                                </div>
                                <div>
                                    <s.RadioGroupItem
                                        value="concluido"
                                        id="concluido"
                                        onClick={() =>
                                            setStatusFilter("concluido")
                                        }
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    <s.RadioGroupItemLabel htmlFor="concluido">
                                        Faturado
                                    </s.RadioGroupItemLabel>
                                </div>
                                <div>
                                    <s.RadioGroupItem
                                        value="faturar"
                                        id="faturar"
                                        onClick={() =>
                                            setStatusFilter("faturar")
                                        }
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    <s.RadioGroupItemLabel htmlFor="faturar">
                                        Pendente
                                    </s.RadioGroupItemLabel>
                                </div>
                            </s.RadioGroupRoot>
                        }
                    >
                        <s.HeaderText
                            click
                            onClick={() => {
                                setFilterByStatusOpen(!filterByStatusIsOpen);
                            }}
                        >
                            Status
                            {statusFilter !== "todos" ? (
                                <Icons.Filler color={theme.gray600} />
                            ) : (
                                <Icons.FillerOutlined color={theme.gray600} />
                            )}
                        </s.HeaderText>
                    </Tooltip>

                    <s.HeaderText>Faturar</s.HeaderText>

                    <s.HeaderText>Docs</s.HeaderText>
                </div>
            </div>
        );
    };

    return (
        <s.Container>
            {openModal && (
                <ModalInvoicing
                    data={modalReport}
                    setOpen={setOpenModal}
                    primaryAction={() => {
                        setOpenModal(false);
                    }}
                    setIsDone={setIsDone}
                />
            )}
            {modalDocument && (
                <ModalDocument
                    data={modalDocument}
                    setIsOpenModal={() => {
                        setModalDocument(undefined);
                    }}
                    getReportsByPage={getReportsByPage}
                />
            )}
            <ManageReportsOptions />
            <s.HeaderContainer>
                <s.Tabs>
                    <div id="status">
                        <s.Tab
                            open={status === "faturar"}
                            onClick={() => {
                                setPage(1);
                                getReportService({ status: "faturar" });
                            }}
                        >
                            <s.TabTitle>FATURAR</s.TabTitle>
                            <p>
                                {getReports?.response?.statistic_reports
                                    ?.open_to_bill_reports || 0}
                            </p>
                        </s.Tab>
                        <s.Tab
                            open={status === "concluido"}
                            onClick={() => {
                                setPage(1);
                                getReportService({ status: "concluido" });
                            }}
                        >
                            <s.TabTitle>CONCLUÍDO</s.TabTitle>
                            <p>
                                {getReports?.response?.statistic_reports
                                    ?.billed_reports || 0}
                            </p>
                        </s.Tab>
                        <s.Tab
                            open={status === "todos"}
                            onClick={() => {
                                getReportService({ status: "todos" });
                                setPage(1);
                            }}
                        >
                            <s.TabTitle>TODOS</s.TabTitle>
                            <p>
                                {getReports?.response?.statistic_reports
                                    .approved_reports || 0}
                            </p>
                        </s.Tab>
                    </div>
                    <s.InputDiv>
                        <s.SearchBar
                            type="text"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            value={searchValue}
                            icon={<Icons.SearchOutline />}
                            placeholder="Pesquise por profissional, setor, atuação ou projeto"
                        />
                        <s.Period>
                            <s.PeriodTitle>Período: </s.PeriodTitle>
                            <SelectInput
                                options={monthsAvailable}
                                onChange={(e: TOption) => {
                                    setChoiceDate(new Date(e.value));
                                    getReportService({
                                        date: new Date(e.value),
                                        status,
                                        page,
                                    });
                                }}
                                value={{
                                    value: formatISOToMonthYear(
                                        choiceDate.toISOString()
                                    ),
                                    label: formatISOToMonthYear(
                                        choiceDate.toISOString()
                                    ),
                                }}
                                icon={<Icons.Chevron color={theme.gray800} />}
                                noForm
                                noError
                                noLabel
                            />
                        </s.Period>
                    </s.InputDiv>
                </s.Tabs>
                <s.Buttons>
                    <s.PDFButton>
                        <PrimaryButton
                            onClick={() => generatePDF()}
                            icon={<Icons.PDF color={theme.white} />}
                            disabled={!reports?.length}
                        >
                            BAIXAR PDF
                        </PrimaryButton>
                    </s.PDFButton>
                    <s.ExcelButton>
                        <PrimaryButton
                            onClick={() => generateXLSX()}
                            icon={<Icons.Excel color={theme.white} />}
                            disabled={!reports?.length}
                        >
                            BAIXAR XLSX
                        </PrimaryButton>
                    </s.ExcelButton>
                </s.Buttons>
            </s.HeaderContainer>

            {getReports.loading && reports?.length === 0 && <LoadingGray />}

            {!getReports.loading && (
                <>
                    {Boolean(reports?.length) && (
                        <s.History>
                            {generateTableHeader()}

                            <div className="rows">
                                {reports?.map((report) => {
                                    return (
                                        <div
                                            className="table-row"
                                            key={report?.User?.name}
                                        >
                                            <Tooltip
                                                content={
                                                    <s.TooltipContent>
                                                        {report?.User?.name}
                                                    </s.TooltipContent>
                                                }
                                                align="start"
                                                positionArrow={30}
                                                arrow
                                            >
                                                <s.User>
                                                    <s.ProfilePicture
                                                        src={
                                                            report?.User
                                                                ?.avatar ||
                                                            FotoUser
                                                        }
                                                        alt={`Foto de perfil de ${report?.User?.name}`}
                                                    />
                                                    {report?.User?.name ? (
                                                        correctUserName(
                                                            report?.User?.name
                                                        )
                                                    ) : (
                                                        <p>-</p>
                                                    )}
                                                </s.User>
                                            </Tooltip>

                                            <s.CommonColumn>
                                                {minutesToStringDate(
                                                    report?.total_time
                                                )}
                                            </s.CommonColumn>

                                            <s.CommonColumn>
                                                {report?.User?.hourValue
                                                    ? formatToBRMoney(
                                                          report.User.hourValue
                                                      )
                                                    : "-"}
                                            </s.CommonColumn>

                                            <s.CommonColumn>
                                                {report?.ReportBilling
                                                    ?.discounts
                                                    ? formatToBRMoney(
                                                          parseFloat(
                                                              report
                                                                  ?.ReportBilling
                                                                  ?.discounts
                                                          )
                                                      )
                                                    : "-"}
                                            </s.CommonColumn>

                                            <s.CommonColumn>
                                                {report?.ReportBilling
                                                    ?.additional
                                                    ? formatToBRMoney(
                                                          parseFloat(
                                                              report
                                                                  ?.ReportBilling
                                                                  ?.additional
                                                          )
                                                      )
                                                    : "-"}
                                            </s.CommonColumn>

                                            <s.CommonColumn>
                                                {report?.ReportBilling
                                                    ?.final_total
                                                    ? formatToBRMoney(
                                                          parseFloat(
                                                              report
                                                                  ?.ReportBilling
                                                                  ?.final_total
                                                          )
                                                      )
                                                    : "-"}
                                            </s.CommonColumn>

                                            <s.Status
                                                billed={Boolean(billed(report))}
                                            >
                                                {billed(report)
                                                    ? "Faturado"
                                                    : "Pendente"}
                                            </s.Status>

                                            <Tooltip
                                                content={
                                                    <>
                                                        {!report?.validated && (
                                                            <s.TooltipContent>
                                                                Relatório do
                                                                usuário não foi
                                                                validado
                                                            </s.TooltipContent>
                                                        )}
                                                    </>
                                                }
                                            >
                                                <s.CommonColumn
                                                    className="body-button"
                                                    style={{
                                                        cursor: report?.validated
                                                            ? "pointer"
                                                            : "not-allowed",
                                                    }}
                                                    onClick={() => {
                                                        if (!report?.validated)
                                                            return;

                                                        setModalReport(report);
                                                        if (
                                                            report.User
                                                                ?.hourValue
                                                        ) {
                                                            setOpenModal(true);
                                                        } else {
                                                            toast.error(
                                                                "Usuário sem valor hora, atualize-o!"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <Icons.Dollar
                                                        color={
                                                            report?.validated
                                                                ? theme.gray600
                                                                : theme.gray200
                                                        }
                                                    />
                                                </s.CommonColumn>
                                            </Tooltip>

                                            <Tooltip
                                                content={
                                                    <>
                                                        {!report?.invoices
                                                            ?.length && (
                                                            <s.TooltipContent>
                                                                Não há
                                                                documentos
                                                            </s.TooltipContent>
                                                        )}
                                                    </>
                                                }
                                            >
                                                <s.CommonColumn
                                                    className="body-button"
                                                    style={{
                                                        cursor: report?.invoices
                                                            ?.length
                                                            ? "pointer"
                                                            : "not-allowed",
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            report?.invoices
                                                                ?.length
                                                        ) {
                                                            setModalDocument(
                                                                report
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <Icons.Document
                                                        color={
                                                            report?.invoices
                                                                ?.length
                                                                ? theme.gray600
                                                                : theme.gray200
                                                        }
                                                    />
                                                </s.CommonColumn>
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                                <s.TotalValue>
                                    <p>Valor total</p>
                                    <p></p>
                                    <p></p>
                                    <p>
                                        {formatToBRMoney(
                                            totalValue.totalDiscount
                                        )}
                                    </p>
                                    <p>
                                        {formatToBRMoney(
                                            totalValue.totalAdditional
                                        )}
                                    </p>
                                    <p>
                                        {formatToBRMoney(totalValue.totalValue)}
                                    </p>
                                </s.TotalValue>
                            </div>
                        </s.History>
                    )}

                    {!reports?.length && (
                        <>
                            {statusFilter === "todos" && (
                                <s.History>
                                    {generateTableHeader()}
                                    <s.NoContent>
                                        <EmptyScreen />
                                        <h2>Ainda não há relatórios gerados</h2>
                                        <p>
                                            Vá em resumo, selecione um período
                                            no calendário e após gerá-lo, seu
                                            relatório para faturamento estará
                                            aqui
                                        </p>
                                    </s.NoContent>
                                </s.History>
                            )}

                            {statusFilter !== "todos" && (
                                <s.History>
                                    {generateTableHeader()}
                                    <s.NoContent>
                                        <IllustrationEmptySearch />
                                        <h2>
                                            Nenhum resultado correspondeu à
                                            pesquisa{" "}
                                        </h2>
                                        <p>
                                            Por favor, tente uma nova pesquisa e
                                            verifique que o texto está escrito
                                            corretamente.
                                        </p>
                                    </s.NoContent>
                                </s.History>
                            )}
                        </>
                    )}

                    {getReports.response.total_reports > defaultTakeValue && (
                        <s.ShowButtons>
                            {take === defaultTakeValue && (
                                <SecondaryButton
                                    onClick={() => {
                                        setTake(999);
                                    }}
                                >
                                    MOSTRAR MAIS
                                </SecondaryButton>
                            )}

                            {take !== defaultTakeValue && (
                                <SecondaryButton
                                    onClick={() => {
                                        setTake(defaultTakeValue);
                                    }}
                                >
                                    MOSTRAR MENOS
                                </SecondaryButton>
                            )}
                        </s.ShowButtons>
                    )}
                </>
            )}
        </s.Container>
    );
};
