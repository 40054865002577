import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export type CreateMassProjectsParams = {
    projects?: {
        name: string;
        customer: string;
        registeredHours: number;
    }[];
};

export default async ({ projects }: CreateMassProjectsParams = {}) => {
    const headers = {
        auth: true,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/project/mass`,
        method: "post",
        body: {
            projects,
        },
        headers,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
