import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ className, color, onClick, title }: TBaseSVG) => (
    <svg
        onClick={onClick}
        className={className || "icon-stop"}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>{title}</title>
        <rect
            x="4"
            y="4"
            width="16"
            height="16"
            rx="1.5"
            fill={color || theme.purple600}
        />
    </svg>
);
