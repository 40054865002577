import { useState } from "react";

import { TOrganization } from "../../../../models";
import { CheckBox, PrimaryButton, SecondaryButton } from "../../../atoms";
import ContainerModal from "../container-modal";
import * as s from "./styles";

type ModalDeleteOrganizationConfirmProps = {
    orgData: TOrganization;
    onContinue: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalDeleteOrganizationConfirm = ({
    orgData,
    setOpen,
    onContinue,
}: ModalDeleteOrganizationConfirmProps) => {
    const usersLength = (orgData.Users?.length || 2) - 1;
    const [confirmation, setConfirmation] = useState(false);

    return (
        <ContainerModal closeIcon onOpenChange={setOpen}>
            <s.Container>
                <s.Title>Tem certeza de que deseja prosseguir?</s.Title>
                <s.TextContainer>
                    <s.Text>
                        A exclusão da organização é uma ação{" "}
                        <strong>irreversível</strong> e resultará na{" "}
                        <strong>perda permanente</strong> de todos os dados
                        associados à sua conta e à de seus profissionais.
                    </s.Text>
                </s.TextContainer>
                <s.TextContainer>
                    <s.Text>
                        Você e seus{" "}
                        <strong> {usersLength} profissionais</strong> perderão{" "}
                        <strong>permanentemente</strong>:
                    </s.Text>
                    <s.Text>- Acesso a plataforma</s.Text>
                    <s.Text>- Registros de tempo</s.Text>
                    <s.Text>
                        - Todos os{" "}
                        <strong>{orgData.Projects?.length} projetos</strong> e
                        seus dados
                    </s.Text>
                    <s.Text>- Relatórios gerados</s.Text>
                    <s.Text>- Demonstrativos</s.Text>
                    <s.Text>- Clientes cadastrados</s.Text>
                </s.TextContainer>
                <s.Text>
                    Caso deseje perder todos os benefícios que o Paper ON pode
                    te oferecer, verifique com todas as pessoas que utilizam da
                    plataforma se é um interesse geral antes de realizar a
                    exclusão da organização.
                </s.Text>
                <CheckBox
                    id="confirm"
                    width={16}
                    height={16}
                    label="Confirmo que estou ciente da minha decisão de excluir todos os dados da minha organização"
                    gap="10px"
                    isChecked={confirmation}
                    onChange={() => setConfirmation(!confirmation)}
                />
                <s.Buttons>
                    <SecondaryButton onClick={() => setOpen(false)}>
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={onContinue}
                        disabled={!confirmation}
                    >
                        Continuar
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModal>
    );
};
