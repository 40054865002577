import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({ title, color, className, onClick }: TBaseSVG) => {
    return (
        <>
            <svg
                onClick={onClick}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className || "icon-n"}
            >
                <title>{title}</title>
                <path
                    d="M21.8123 0.333344V21.6667H15.8999L7.79322 11.9752V21.6667H0.783691V0.333344H6.69608L14.8027 10.0248V0.333344H21.8123Z"
                    fill={color || theme.purple500}
                />
            </svg>
        </>
    );
};
