import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useForm, Controller } from "react-hook-form";

import { FotoUser, Icons, theme } from "../../../../../../assets";
import {
    InputFoto,
    Input,
    SelectInput,
    Tooltip,
    DesignedAlert,
} from "../../../../../../components";
import { useFetch } from "../../../../../../hooks";
import { TOption, TOrganization } from "../../../../../../models";
import { locality, organizations } from "../../../../../../services";
import { onlyNumbers } from "../../../../../../utils";
import * as s from "./styled-tab-profile";
import schema from "./validation";

type TFields = {
    cep: string;
    phone: string;
    address: string;
    houseNumber: string;
    complement: string;
    city: string;
    state: string;
    name: string;
    fantasy_name: string;
    cnpj: string;
    email: string;
};

type TTabProfile = {
    orgData: TOrganization;
    setOrgData: Dispatch<SetStateAction<TOrganization>>;
    getOrgOnRefresh: () => void;
};

export const TabProfile = ({
    orgData,
    setOrgData,
    getOrgOnRefresh,
}: TTabProfile) => {
    const defaultValues = {
        cep: orgData?.cep,
        phone: orgData?.phone,
        address: orgData?.address,
        houseNumber: orgData?.houseNumber,
        complement: orgData?.complement || "",
        city: orgData?.city,
        state: orgData?.state,
        fantasy_name: orgData?.fantasy_name,
        cnpj: orgData?.cnpj,
        email: orgData?.email,
    };

    const {
        handleSubmit,
        trigger,
        register,
        setError,
        setValue,
        formState: { errors },
        control,
        reset,
    } = useForm<TFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);

    const [picture, setPicture] = useState<Blob | File | string | undefined>();
    const [allCities, setAllCities] = useState<TOption[]>();
    const [allUF, setAllUF] = useState<TOption[]>();
    const [slicedCity, setSlicedCity] = useState<TOption[]>();
    const [UFInputValue, setUFInputValue] = useState(defaultValues.state || "");
    const [cityInputValue, setCityInputValue] = useState(
        defaultValues.city || ""
    );
    const [CEP, setCEP] = useState(orgData?.cep || "");
    const [CEPFields, setCEPFields] = useState(
        !!(orgData?.city && orgData?.state) || false
    );

    const updateOrganization = useFetch({
        fn: organizations.updateOrganization,
        start: false,
    });

    const updateLogo = useFetch({
        fn: organizations.updateLogo,
        start: false,
    });

    const cepReq = useFetch({
        fn: locality.getCep,
        params: CEP,
        start: false,
    });

    const cityReq = useFetch({
        fn: locality.getCities,
    });

    const UFReq = useFetch({ fn: locality.getStates });

    const filterCities = (value = "") => {
        const searchString = new RegExp(`^${value}`, "i");

        const filterCities = allCities?.filter((item: Record<string, string>) =>
            item.label?.match(searchString)
        );

        const fiftyCities = filterCities
            ?.sort((a: Record<string, string>, b: Record<string, string>) => {
                if (a?.label < b?.label) return -1;
                if (a?.label > b?.label) return 1;
                return 0;
            })
            ?.slice(0, 50);

        return fiftyCities;
    };

    const checkCep = () => {
        const { erro } = cepReq.response || { erro: null };
        if (erro)
            return setError("cep", {
                type: "value",
                message: "CEP não encontrado",
            });

        let adress = "";
        if (cepReq.response?.logradouro && cepReq.response?.logradouro !== "")
            adress += `${cepReq.response.logradouro}`;
        if (
            cepReq.response?.logradouro &&
            cepReq.response?.logradouro !== "" &&
            cepReq.response?.bairro &&
            cepReq.response?.bairro !== ""
        )
            adress += ", ";
        if (cepReq.response?.bairro && cepReq.response?.bairro !== "")
            adress += `${cepReq.response.bairro}`;

        setValue("address", adress);

        if (cepReq.response) {
            setValue("city", cepReq.response.localidade.substring(0, 20));
            setValue("state", cepReq.response.uf);
            setValue("houseNumber", "");
            setValue("complement", "");
            setCityInputValue(cepReq.response.localidade);
            setUFInputValue(cepReq.response.uf);
            setCEPFields(true);
        }

        trigger(["city", "state", "address", "houseNumber"]);
        return null;
    };

    const haveError = Object.keys(errors).length > 0;

    useEffect(() => {
        if (!loading) {
            if (CEP !== "") cepReq.onRefresh(CEP);
            if (CEP === "") {
                setValue("city", "");
                setValue("state", "");
                setValue("address", "");
                setValue("houseNumber", "");
                setValue("complement", "");
                setCityInputValue("");
                setUFInputValue("");
                setCEPFields(false);
            }
        }
        setLoading(false);
    }, [CEP]);

    useEffect(() => {
        if (cepReq.response) checkCep();
    }, [cepReq.response]);

    useEffect(() => {
        if (UFReq.response) {
            setAllUF(UFReq.response);
        }
    }, [UFReq.response]);

    useEffect(() => {
        if (cityReq.response) setAllCities(cityReq.response);
    }, [cityReq.response]);

    useEffect(() => {
        if (allCities) setSlicedCity(filterCities());
    }, [allCities]);

    useEffect(() => {
        if (cityInputValue) setSlicedCity(filterCities(cityInputValue));
    }, [cityInputValue]);

    const convertPicture = () => {
        if (picture) {
            const formDataImage = new FormData();
            formDataImage.append("logo", picture);
            return formDataImage;
        }
        return undefined;
    };

    useEffect(() => {
        if (picture) {
            updateLogo.onRefresh({ logo: convertPicture() });
            setTimeout(async () => {
                setOrgData({ ...orgData, logo: picture });
                getOrgOnRefresh();
            }, 1000);
        }
    }, [picture]);

    useEffect(() => {
        if (updateOrganization.error) {
            getOrgOnRefresh();
        }
    }, [updateOrganization.error]);

    const onSubmit = (data: TFields) => {
        setEdit(false);
        setOrgData({ ...orgData, ...data });
        updateOrganization.onRefresh({
            orgData: data,
        });
    };

    return (
        <s.Container>
            <s.Profile onSubmit={handleSubmit(onSubmit)} edit={edit}>
                <s.FormColumn
                    style={{ width: "min-content", marginRight: "3rem" }}
                >
                    <Tooltip
                        positionx={10}
                        side="right"
                        content={<s.P>Editar</s.P>}
                    >
                        {edit ? (
                            <InputFoto
                                avatar={orgData?.logo as string}
                                setFoto={setPicture}
                                onChange={(e: React.ChangeEvent) => {
                                    if (e) {
                                        const target =
                                            e.target as HTMLInputElement;
                                        const file = (
                                            target?.files as FileList
                                        )[0];
                                        setPicture(file);
                                    }
                                }}
                                color={theme.gray500}
                            />
                        ) : (
                            <img
                                src={orgData?.logo || FotoUser}
                                alt="Foto do usuário"
                            />
                        )}
                    </Tooltip>
                </s.FormColumn>

                <s.FormColumn>
                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">
                            Nome da organização{edit && "*"}:
                        </span>
                        {edit ? (
                            <Input
                                {...register("name", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("name");
                                    },
                                })}
                                mask={""}
                                type="text"
                                error={errors.name?.message || ""}
                                defaultValue={orgData?.name}
                                className="input-contact-data"
                                maxLength={100}
                            />
                        ) : (
                            <span>{orgData?.name || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">
                            Nome fantasia{edit && "*"}:
                        </span>
                        {edit ? (
                            <Input
                                {...register("fantasy_name", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("fantasy_name");
                                    },
                                })}
                                mask={""}
                                type="text"
                                error={errors.fantasy_name?.message || ""}
                                defaultValue={orgData?.fantasy_name}
                                className="input-contact-data"
                                maxLength={100}
                            />
                        ) : (
                            <span>{orgData?.fantasy_name || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">CNPJ{edit && "*"}:</span>
                        {edit ? (
                            <Input
                                {...register("cnpj", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("cnpj");
                                    },
                                })}
                                mask={"99.999.999/9999-99"}
                                type="text"
                                error={errors.cnpj?.message || ""}
                                defaultValue={orgData?.cnpj}
                                className="input-contact-data"
                            />
                        ) : (
                            <span>{orgData?.cnpj || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">
                            Telefone{edit && "*"}:
                        </span>
                        {edit ? (
                            <Input
                                {...register("phone", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("phone");
                                    },
                                })}
                                mask={"55+ (99) 99999 9999"}
                                type="text"
                                error={errors.phone?.message || ""}
                                defaultValue={orgData?.phone}
                                className="input-contact-data"
                            />
                        ) : (
                            <span>{orgData?.phone || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">
                            E-mail corporativo{edit && "*"}:
                        </span>
                        {edit ? (
                            <Input
                                {...register("email", {
                                    onBlur: () => {
                                        trigger("email");
                                    },
                                })}
                                mask={""}
                                type="text"
                                error={errors.email?.message || ""}
                                defaultValue={orgData?.email}
                                className="input-contact-data"
                                maxLength={100}
                            />
                        ) : (
                            <span>{orgData?.email || "-"}</span>
                        )}
                    </div>
                </s.FormColumn>

                <s.FormColumn>
                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">CEP{edit && "*"}:</span>
                        {edit ? (
                            <div id="tip-field">
                                <Input
                                    {...register("cep", {
                                        onChange: (e) => {
                                            const { value } = e.target;
                                            e.target.value = value;
                                        },
                                        onBlur: async (e) => {
                                            setCEP("");
                                            await trigger("cep");
                                            setCEP(e.target.value);
                                        },
                                    })}
                                    mask={"99999-999"}
                                    type="text"
                                    error={errors.cep?.message || ""}
                                    defaultValue={orgData?.cep}
                                    className="input-contact-data"
                                />

                                <s.TipCep
                                    href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                    target="_blank"
                                >
                                    Não sei meu CEP
                                </s.TipCep>
                            </div>
                        ) : (
                            <span>{orgData?.cep || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">
                            Logradouro{edit && "*"}:
                        </span>
                        {edit ? (
                            <Input
                                {...register("address", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("address");
                                    },
                                })}
                                mask={""}
                                type="text"
                                error={errors.address?.message || ""}
                                defaultValue={orgData?.address}
                                className="input-contact-data"
                                disabled={Boolean(errors.cep)}
                            />
                        ) : (
                            <span>{orgData?.address || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">Nº{edit && "*"}:</span>
                        {edit ? (
                            <Input
                                {...register("houseNumber", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = onlyNumbers(value);
                                    },
                                    onBlur: () => {
                                        trigger("houseNumber");
                                    },
                                })}
                                mask={"99999"}
                                type="text"
                                error={errors.houseNumber?.message || ""}
                                defaultValue={orgData?.houseNumber}
                                className="input-contact-data"
                                disabled={Boolean(errors.cep)}
                            />
                        ) : (
                            <span>{orgData?.houseNumber || "-"}</span>
                        )}
                    </div>

                    <div
                        className="contact-field"
                        style={edit ? { marginBottom: 16 } : {}}
                    >
                        <span className="field-title">Complemento:</span>
                        {edit ? (
                            <Input
                                {...register("complement", {
                                    onChange: (e) => {
                                        const { value } = e.target;
                                        e.target.value = value;
                                    },
                                    onBlur: () => {
                                        trigger("complement");
                                    },
                                })}
                                mask={""}
                                type="text"
                                error={errors.complement?.message || ""}
                                defaultValue={orgData?.complement}
                                className="input-contact-data"
                                maxLength={40}
                                disabled={Boolean(errors.cep)}
                            />
                        ) : (
                            <span>{orgData?.complement || "-"}</span>
                        )}
                    </div>

                    <div className="contact-field">
                        <span className="field-title">
                            Estado{edit && "*"}:
                        </span>

                        {edit ? (
                            <Controller
                                name={"state"}
                                control={control}
                                render={({ field: { ref, onChange } }) => (
                                    <SelectInput
                                        icon={<Icons.GPS />}
                                        placeholder={
                                            CEPFields
                                                ? UFInputValue
                                                : "Selecione seu Estado"
                                        }
                                        options={allUF}
                                        error={errors.state?.message || ""}
                                        inputRef={ref}
                                        onChange={(val: { value: string }) => {
                                            onChange(val.value);
                                            setUFInputValue(val.value);
                                            cityReq.onRefresh(val.value);
                                        }}
                                        disabled={true}
                                        key={`state-select-${CEPFields}`}
                                        positionY="0.4"
                                        noError
                                        noLabel
                                    />
                                )}
                            />
                        ) : (
                            <span>{orgData?.state || "-"}</span>
                        )}
                    </div>

                    <div className="contact-field">
                        <span className="field-title">
                            Cidade{edit && "*"}:
                        </span>

                        {edit ? (
                            <Controller
                                defaultValue=""
                                name={"city"}
                                control={control}
                                render={({ field: { ref, onChange } }) => (
                                    <SelectInput
                                        icon={<Icons.GPS />}
                                        placeholder={
                                            CEPFields
                                                ? cityInputValue
                                                : "Selecione sua cidade"
                                        }
                                        options={slicedCity}
                                        error={errors.city?.message || ""}
                                        inputRef={ref}
                                        onInputChange={(value) => {
                                            setCityInputValue(value);
                                        }}
                                        onChange={(val: { value: string }) => {
                                            onChange(val.value);
                                            setCityInputValue(val.value);
                                        }}
                                        disabled={true}
                                        key={`city-select-${CEPFields}`}
                                        positionY="0.4"
                                        noError
                                        noLabel
                                    />
                                )}
                            />
                        ) : (
                            <span>{orgData?.city || "-"}</span>
                        )}
                    </div>
                </s.FormColumn>
            </s.Profile>

            {edit ? (
                <div className="icons-group">
                    <Tooltip
                        open={haveError ? false : undefined}
                        side="left"
                        positionx={-10}
                        content={<s.P>Salvar alterações</s.P>}
                    >
                        <span className="tooltip">
                            <DesignedAlert
                                open={haveError ? false : undefined}
                                textBtn={"Salvar"}
                                onClickBtn={handleSubmit(onSubmit)}
                                trigger={<Icons.Save color={theme.purple600} />}
                                description={
                                    "Ao salvar alterações as informações serão substituídas. Deseja salvar as alterações?"
                                }
                                title={"Salvar alterações"}
                            />
                        </span>
                    </Tooltip>

                    <Tooltip
                        side="left"
                        positionx={-10}
                        content={<s.P>Descartar alterações</s.P>}
                    >
                        <span className="tooltip">
                            <DesignedAlert
                                textBtn={"Descartar"}
                                onClickBtn={() => {
                                    reset(defaultValues);
                                    setEdit(false);
                                }}
                                trigger={
                                    <Icons.Trash color={theme.purple600} />
                                }
                                description={
                                    "Ao descartar as alteraçãos, os campos editados não serão editados. Deseja descartar as alterações?"
                                }
                                title={"Descartar alterações"}
                            />
                        </span>
                    </Tooltip>
                </div>
            ) : (
                <Tooltip
                    side="left"
                    positionx={-10}
                    content={<s.P>Editar</s.P>}
                >
                    <span className="tooltip">
                        <Icons.EditOutline
                            color={theme.purple600}
                            onClick={() => setEdit(!edit)}
                        />
                    </span>
                </Tooltip>
            )}
        </s.Container>
    );
};
