import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../../../../assets";
import { SecondaryButton } from "../../../../../../components";
import { ModalDeleteOrganizationConfirm } from "../../../../../../components/molecules/modals/modal-delete-organization-confirm/modal-delete-organization-confirm";
import { ModalDeleteOrganizationPasswordConfirm } from "../../../../../../components/molecules/modals/modal-delete-organization-password/modal-delete-organization-password";
import { Switch } from "../../../../../../components/molecules/switch/switch";
import { useUser } from "../../../../../../context";
import { TOrganization } from "../../../../../../models";
import { organizations } from "../../../../../../services";
import * as s from "./styled-tab-settings";

type TReportModel = "project" | "sector";

type TTabSettingsProps = {
    orgData: TOrganization;
    setOrgData: React.Dispatch<React.SetStateAction<TOrganization>>;
};

export const TabSettings = ({ orgData, setOrgData }: TTabSettingsProps) => {
    const { user, setUser, isAdministrator } = useUser();
    const [deleteOrganizationConfirm, setDeleteOrganizationConfirm] =
        useState(false);
    const [
        deleteOrganizationPasswordConfirm,
        setDeleteOrganizationPasswordConfirm,
    ] = useState(false);

    const [reportModel, setReportModel] = useState<TReportModel>(
        orgData?.OrganizationSettings?.report_model || "project"
    );
    const [receiveEmails, setReceiveEmails] = useState<boolean>(
        typeof orgData?.OrganizationSettings?.receive_emails !== "boolean" ||
            orgData?.OrganizationSettings?.receive_emails
    );

    const updateSettings = useMutation({
        mutationFn: organizations.updateSettings,
        onSuccess: () => {
            toast.success("Configuração atualizada com sucesso!");
            setOrgData((prev) => ({
                ...prev,
                OrganizationSettings: {
                    ...prev.OrganizationSettings,
                    report_model: reportModel,
                    receive_emails: receiveEmails,
                },
            }));
            setUser({
                ...user,
                Organization: {
                    ...user.Organization,
                    OrganizationSettings: {
                        ...user.Organization.OrganizationSettings,
                        report_model: reportModel,
                        receive_emails: receiveEmails,
                    },
                },
            });
        },
    });

    const handleUpdateReportModel = (value: TReportModel) => {
        updateSettings.mutate({
            reportModel: value,
        });
        setReportModel(value);
    };

    const handleUpdateReceiveEmails = (value: boolean) => {
        updateSettings.mutate({
            receiveEmails: value,
        });
        setReceiveEmails(value);
    };

    return (
        <>
            {deleteOrganizationConfirm && (
                <ModalDeleteOrganizationConfirm
                    orgData={orgData}
                    setOpen={setDeleteOrganizationConfirm}
                    onContinue={() => {
                        setDeleteOrganizationConfirm(false);
                        setDeleteOrganizationPasswordConfirm(true);
                    }}
                />
            )}
            {deleteOrganizationPasswordConfirm && (
                <ModalDeleteOrganizationPasswordConfirm
                    setOpen={setDeleteOrganizationPasswordConfirm}
                />
            )}
            <s.Container>
                <s.SettingContainer>
                    <s.SettingTitle>
                        Gestão dos relatórios dos profissionais
                    </s.SettingTitle>
                    <s.CollaboratorsReport>
                        <s.RadioGroupRoot>
                            <div
                                onClick={() => {
                                    if (reportModel === "project") return;
                                    handleUpdateReportModel("project");
                                }}
                            >
                                <s.RadioWrapper
                                    selected={reportModel === "project"}
                                >
                                    <s.RadioGroupItem
                                        value="project"
                                        checked={reportModel === "project"}
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    <label htmlFor="project">Por projeto</label>
                                </s.RadioWrapper>
                                <s.SettingDescription
                                    selected={reportModel === "project"}
                                >
                                    Os gestores poderão revisar e faturar
                                    somente os relatórios dos profissionais que
                                    são membros do seu time de projetos.
                                </s.SettingDescription>
                            </div>
                            <div
                                onClick={() => {
                                    if (reportModel === "sector") return;
                                    handleUpdateReportModel("sector");
                                }}
                            >
                                <s.RadioWrapper
                                    selected={reportModel === "sector"}
                                >
                                    <s.RadioGroupItem
                                        value="sector"
                                        checked={reportModel === "sector"}
                                    >
                                        <s.RadioGroupIndicator />
                                    </s.RadioGroupItem>
                                    <label htmlFor="sector">Por setor</label>
                                </s.RadioWrapper>
                                <s.SettingDescription
                                    selected={reportModel === "sector"}
                                >
                                    Os gestores poderão revisar e faturar
                                    somente os relatórios dos profissionais que
                                    estão dentro do seu setor.
                                </s.SettingDescription>
                            </div>
                        </s.RadioGroupRoot>
                    </s.CollaboratorsReport>
                    <s.SettingTitle>E-mails automáticos</s.SettingTitle>
                    <s.ReceiveEmailsContainer>
                        <Switch
                            checked={receiveEmails}
                            onChange={() => {
                                handleUpdateReceiveEmails(!receiveEmails);
                            }}
                            scale={0.7}
                        />
                        <s.ReceiveEmailsText>
                            Receber e-mails automáticos relacionados a sua
                            organização.
                            <s.ReceiveEmailsSubText>
                                Ex: Usuários sem registros, quantidade de
                                entradas de tempo alteradas por colaboradores
                            </s.ReceiveEmailsSubText>
                        </s.ReceiveEmailsText>
                    </s.ReceiveEmailsContainer>
                </s.SettingContainer>
                {isAdministrator && orgData.canDelete && (
                    <s.RightSideSettings>
                        <SecondaryButton
                            variation="link"
                            onClick={() => setDeleteOrganizationConfirm(true)}
                        >
                            <Icons.Trash color={theme.redError} />
                            Excluir organização
                        </SecondaryButton>
                    </s.RightSideSettings>
                )}
            </s.Container>
        </>
    );
};
