import { Content, Arrow } from "@radix-ui/react-tooltip";
import styled, { keyframes } from "styled-components";

const Animation = keyframes`
from{opacity:0}
to{opacity:1}
`;

type TContent = {
    position?: number;
    error?: boolean;
    offset?: number;
    positionx?: number;
    positiony?: number;
    $arrow?: boolean;
    radius: number;
};

export const StyledContent = styled(Content)<TContent>`
    cursor: default;
    z-index: 9999;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
    border-radius: ${({ radius }) => `${radius}px`};
    position: relative;
    margin-bottom: ${({ positiony }) => positiony || 0}px;
    transform: translateY(${({ offset }) => offset || -7}px);
    transform: translateX(${({ positionx }) => positionx || 0}px);
    border: ${({ error, theme }) => error && `2px solid ${theme.red}`};
    color: ${({ error, theme }) => error && theme.red};

    &::after {
        content: "";
        display: ${({ $arrow }) => !$arrow && "none"};
        position: absolute;
        top: 100%;
        left: ${({ position }) => position || 10}%;
        border-width: 7px;
        border-style: solid;
        border-radius: 0px 0px 8px;
        border-color: ${({ theme, error }) => (error ? theme.red : theme.white)}
            transparent transparent transparent;
    }

    &[data-state="delayed-open"] {
        animation: ${Animation} 0.5s ease-in-out;
    }
`;

export const StyledArrow = styled(Arrow)`
    fill: ${({ theme }) => theme.white};
`;
