import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 21rem;
    padding-bottom: 2rem;
    position: absolute;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;

    .content-title {
        height: 3rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        padding-right: 1.5rem;
        background-color: ${({ theme }) => theme.purple50};
        border-left: 1px solid ${({ theme }) => theme.gray200};
        border-right: 1px solid ${({ theme }) => theme.gray200};
        color: ${({ theme }) => theme.purple500};
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .title-row {
        width: 18.125rem;
        margin-right: auto;
    }

    .title-row-historic {
        flex: 0.5;
        width: 100%;
        margin-right: 0;
    }

    .resume {
        min-height: 18rem;
        padding: 1.5rem 0;
        border: 1px solid ${({ theme }) => theme.gray200};
        border-radius: 0px 0px 4px 4px;
        background-color: ${({ theme }) => theme.white2};
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            align-self: flex-start;
        }

        img {
            border-radius: 50%;
            width: 180px;
            height: 180px;
        }

        .resume-historic {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            width: 100%;

            li {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.25rem;
                list-style: none;
                display: flex;
                flex-direction: row;
                max-width: 365px;
                max-width: 100%;
                border-bottom: 1px solid ${({ theme }) => theme.gray200};
                padding: 1.25rem 1.5rem 1.5rem 2.5rem;

                p {
                    width: 18.125rem;
                    margin: 0 auto 0 0;
                    white-space: nowrap;
                }
                p:last-child {
                    flex: 0.5;
                    width: 100%;
                    margin-right: 0;
                }
            }

            li:first-child {
                padding-top: 0;
            }

            li:last-child {
                border-bottom: none;
            }

            span {
                margin-left: 0.5rem;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: ${({ theme }) => theme.gray900};
            }
        }
    }
`;
