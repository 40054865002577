import styled from "styled-components";

import { theme } from "../../../../assets";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    padding: 13px;
    width: 568px;
    height: fit-content;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        h1 {
            color: ${({ theme }) => theme.purple500};
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
        }
        svg {
            cursor: pointer;
        }
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 12px;

        div {
            display: flex;
            flex-direction: column;
            gap: 4px;

            span {
                color: ${({ theme }) => theme.gray600};
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }

            input {
                height: 48px;
            }

            .error {
                color: #d32f2f;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }
        }

        div#messageInput {
            textarea {
                height: 160px;
                resize: none;
                overflow: 160px;
                max-height: 160px;
                padding: 0.5rem 1rem;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.08px;
                min-height: 150px;

                ::placeholder {
                    color: ${({ theme }) => theme.gray400};
                }
            }
        }
    }

    footer {
        display: flex;
        justify-content: end;
        align-items: end;
        gap: 16px;
        flex-direction: row;

        button#cancel {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1.2px solid ${({ theme }) => theme.purple500};

            color: ${({ theme }) => theme.purple500};

            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
            order: 1;
        }

        button#submit {
            color: #fff;
            display: flex;
            height: 40px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            background: ${({ theme }) => theme.purple500};
            order: 2;
        }
    }

    @media (max-width: 1366px) {
        gap: 30px;
        padding: 13px;
        width: 550px;

        header {
            h1 {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.07px;
            }
            svg {
                cursor: pointer;
            }
        }

        form {
            gap: 10px;

            div {
                span {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 20px;
                }

                input {
                    height: 44px;
                }

                .error {
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                }
            }

            div#messageInput {
                textarea {
                    height: 130px;
                    overflow: 130px;
                    max-height: 130px;
                    padding: 0.5rem 1rem;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.08px;
                    min-height: 130px;
                }
            }
        }

        footer {
            gap: 16px;

            button#cancel {
                padding: 8px 16px;
                gap: 8px;
                font-size: 12px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 1.12px;
                height: 40px;
            }

            button#submit {
                height: 40px;
                padding: 12px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: ${({ theme }) => theme.purple500};
            }
        }
    }

    @media (max-width: 600px) {
        gap: 30px;
        padding: 13px;
        width: 350px;

        header {
            h1 {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.07px;
            }
            svg {
                cursor: pointer;
            }
        }

        form {
            gap: 10px;

            div {
                span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                }

                input {
                    font-size: 16px;
                    height: 44px;
                }

                .error {
                    font-size: 10px;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                }
            }

            div#messageInput {
                textarea {
                    height: 130px;
                    overflow: 130px;
                    max-height: 130px;
                    padding: 0.5rem 1rem;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.08px;
                    min-height: 130px;
                }
            }
        }

        footer {
            gap: 16px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            button#cancel {
                padding: 8px 16px;
                gap: 8px;
                font-size: 12px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 1.12px;
                height: 40px;
                width: 100%;
                order: 2;
            }

            button#submit {
                height: 40px;
                padding: 12px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                background: ${({ theme }) => theme.purple500};
                width: 100%;
                order: 1;
            }
        }
    }
`;

export const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 16px 16px 60px 16px;
    width: 568px;
    height: fit-content;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        h1 {
            color: ${({ theme }) => theme.purple500};
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
            text-align: center;
        }
        svg {
            cursor: pointer;
        }
    }

    section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        width: 80%;

        h1 {
            color: #2e7d32;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.03px;
            text-align: center;
        }

        p {
            color: ${({ theme }) => theme.gray600};
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
        }

        p#paperonExperience {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 20px;
        }

        button {
            color: #fff;
            display: flex;
            height: 40px;
            padding: 12px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            background: ${({ theme }) => theme.purple600};
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        width: 350px;
        justify-content: center;

        section {
            width: 90%;
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            width: 100%;
            h1 {
                color: ${({ theme }) => theme.purple500};
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.07px;
                text-align: center;
            }
            svg {
                cursor: pointer;
            }
        }
    }
`;
