import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type ChangeRequestHoursStatusParams = {
    status?: string;
    requestId?: string;
    notificationId?: string;
};

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    requestId,
    status,
    notificationId,
}: ChangeRequestHoursStatusParams = {}) => {
    const params = {
        status,
    };

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/hourControl/additional/change`,
        method: "post",
        body: params,
        headers: {
            auth: true,
            "x-request-id": requestId,
            "x-notification-id": notificationId,
        },
    });

    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {};

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
