import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async (email?: string) => {
    const httpRequest = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/password/forgot/${email}`,
        method: "get",
        headers: { auth: true },
    });
    const { body, statusCode } = httpRequest;
    const errorFeedBacks = {
        401: "Acesso negado!",
    };

    return handleStatusCases({ body, statusCode, errorFeedBacks });
};
