import InputMask from "react-input-mask";
import styled from "styled-components";

type TTable = {
    templateColumns?: string;
};

export const Content = styled.div`
    display: grid;
    row-gap: 1.25rem;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    width: 100%;
    top: 2.9375rem;
    z-index: -10;
    border: 1px solid ${({ theme }) => theme.gray200};
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: ${({ theme }) => theme.white};
`;

export const Form = styled.form``;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.25rem;
    padding: 1.5rem;
`;

export const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .field-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.875rem;

        span {
            min-width: 4.0625rem;
        }

        p {
            margin-right: auto;
        }
    }
`;

export const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;

    .action-buttons {
        display: flex;
        gap: 1.25rem;

        button {
            padding: 0.5rem 1rem;
        }
    }

    .action-input {
        width: 100%;
        position: relative;
    }

    .popover-container {
        filter: drop-shadow(0px 2px 9px rgba(8, 1, 23, 0.2));

        position: absolute;
        z-index: 100;
        width: 100%;
        margin-top: 1rem;
        max-height: 280px;
        overflow: auto;
        border-radius: 4px;
        background: ${({ theme }) => theme.white};

        .popover-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            justify-content: space-between;
            &:hover {
                background: ${({ theme }) => theme.gray100};
            }
            .first-item {
                display: flex;
                gap: 1rem;
                align-items: center;
            }
        }

        .popover-item-no-content {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 16px;
            height: 5rem;
        }
    }
`;

export const CollaboratorsList = styled.div<TTable>`
    height: 30rem;

    .left-align {
        text-align: left;
    }

    .collab-list-content {
    }
`;

export const ListHeader = styled.div<TTable>`
    display: grid;
    grid-template-columns: ${({ templateColumns }) => templateColumns || "1fr"};
    align-items: center;
    height: 3rem;
    padding: 0 1rem;
    border-top: 1px solid ${({ theme }) => theme.gray200};
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
    color: ${({ theme }) => theme.gray800};
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
`;

export const ListBody = styled.div<TTable>`
    height: 27rem;

    .content-row {
        display: grid;
        grid-template-columns: ${({ templateColumns }) =>
            templateColumns || "1fr"};
        align-items: center;
        height: 3.5rem;
        padding: 0 1rem;
        text-align: center;

        border-bottom: 1px solid ${({ theme }) => theme.gray200};

        font-size: 0.875rem;
        color: ${({ theme }) => theme.gray600};

        .collaborator {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        &--disabled {
            color: ${({ theme }) => theme.gray300};
        }

        &:last-child {
            margin-bottom: 1rem;
        }

        &:hover {
            background-color: ${({ theme }) => theme.gray50};
        }

        .check-box {
            cursor: pointer;

            div {
                justify-content: center;
                pointer-events: none;
            }
        }
    }

    .collaborators {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .sector-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const ProfilePicture = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
`;

export const FieldTitle = styled.span`
    font-size: 0.75rem;
    color: ${({ theme }) => theme.gray400};
`;

export const FieldText = styled.p`
    font-size: 0.875rem;
    color: ${({ theme }) => theme.gray900};

    width: 100%;
`;

export const InputParagraph = styled(InputMask)`
    cursor: text;
    width: 50%;
    text-align: center;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01em;
    text-decoration: none;
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    color: ${({ theme }) => theme.black};
    background: ${({ theme }) => theme.white};

    &:hover {
        text-decoration: underline;
    }
`;

export const LockTooltip = styled.div`
    display: flex;
    flex-direction: column;
    width: 18.75rem;
    padding: 12px 16px;
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    gap: 17px;

    p {
        color: ${({ theme }) => theme.gray600};
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.07px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    svg {
        color: ${({ theme }) => theme.gray600};
    }
`;

export const P = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0.5rem 1rem;
    letter-spacing: 0.0015em;
    color: ${({ theme }) => theme.gray800};
`;

export const PermissionsTooltip = styled.div`
    box-shadow: 0px 2px 9px 0px rgba(8, 1, 23, 0.2);
    color: ${({ theme }) => theme.gray600};
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px 16px;
    width: 240px;
    text-align: left;
`;
