import { Icons } from "../../../../../assets";
import { PopoverRadix } from "../../../../../components";
import * as s from "./styled-hours-type-filter";

type HoursTypeFilterProps = {
    setFilterType?: React.Dispatch<
        React.SetStateAction<
            "all" | "late" | "onTime" | "finished" | "noInformation" | undefined
        >
    >;
    defaultValue?: string;
};

const HoursTypeFilter = (props: HoursTypeFilterProps) => {
    const { setFilterType, defaultValue = "all" } = props;

    return (
        <PopoverRadix
            side="bottom"
            sideOffset={5}
            alignOffset={5}
            align="end"
            trigger={
                defaultValue !== "all" ? (
                    <Icons.Filler
                        style={{
                            marginBottom: "-0.3rem",
                            marginLeft: "0.1rem",
                        }}
                        width="20"
                        height="20"
                        color="gray"
                    />
                ) : (
                    <Icons.FillerOutlined
                        style={{
                            marginBottom: "-0.2rem",
                            marginLeft: "0.3rem",
                            width: "1rem",
                            height: "1rem",
                        }}
                        color="gray"
                    />
                )
            }
        >
            <s.RadioGroupRoot
                className="RadioGroupRoot"
                defaultValue={defaultValue}
                aria-label="View density"
            >
                <s.RadioWrapper>
                    <s.RadioGroupItem
                        className="RadioGroupItem"
                        value="all"
                        id="todos"
                        onClick={() => setFilterType?.("all")}
                    >
                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                    </s.RadioGroupItem>
                    <label className="Label" htmlFor="todos">
                        Todos
                    </label>
                </s.RadioWrapper>
                <s.RadioWrapper>
                    <s.RadioGroupItem
                        className="RadioGroupItem"
                        value="late"
                        id="atrasados"
                        onClick={() => setFilterType?.("late")}
                    >
                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                    </s.RadioGroupItem>
                    <label className="Label" htmlFor="atrasados">
                        Atrasados
                    </label>
                </s.RadioWrapper>
                <s.RadioWrapper>
                    <s.RadioGroupItem
                        className="RadioGroupItem"
                        value="onTime"
                        id="em-dia"
                        onClick={() => setFilterType?.("onTime")}
                    >
                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                    </s.RadioGroupItem>
                    <label className="Label" htmlFor="em-dia">
                        Em dia
                    </label>
                </s.RadioWrapper>
                <s.RadioWrapper>
                    <s.RadioGroupItem
                        className="RadioGroupItem"
                        value="finished"
                        id="finalizados"
                        onClick={() => setFilterType?.("finished")}
                    >
                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                    </s.RadioGroupItem>
                    <label className="Label" htmlFor="finalizados">
                        Finalizados
                    </label>
                </s.RadioWrapper>
                <s.RadioWrapper>
                    <s.RadioGroupItem
                        className="RadioGroupItem"
                        value="noInformation"
                        id="sem-informacao"
                        onClick={() => setFilterType?.("noInformation")}
                    >
                        <s.RadioGroupIndicator className="RadioGroupIndicator" />
                    </s.RadioGroupItem>
                    <label className="Label" htmlFor="sem-informacao">
                        Sem informação
                    </label>
                </s.RadioWrapper>
            </s.RadioGroupRoot>
        </PopoverRadix>
    );
};

export default HoursTypeFilter;
