import { Tooltip } from "../../../../../../../components";
import { TooltipProps } from "../../../../../../../components/atoms/tooltip/tooltip";
import * as s from "./styles";

type CustomTooltipProps = Omit<TooltipProps, "content"> & {
    text: string | React.ReactNode;
    children: React.ReactNode;
};

export const CustomTooltip = ({
    text,
    children,
    positionArrow = 89,
    positionx = 10,
    positiony = 10,
    align = "end",
    side = "top",
}: CustomTooltipProps) => {
    return (
        <s.Container>
            <Tooltip
                content={<s.Content>{text}</s.Content>}
                align={align}
                side={side}
                positionArrow={positionArrow}
                arrow
                positiony={positionx}
                positionx={positiony}
            >
                <s.Children>{children}</s.Children>
            </Tooltip>
        </s.Container>
    );
};
