import { TabsOrganization } from "./components/tabs-organization/tabs-organization";
import * as s from "./styled-organization-profile";

export const OrganizationProfile = () => {
    return (
        <s.Container>
            <span>Perfil</span>

            <TabsOrganization />
        </s.Container>
    );
};
