import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import toast from "react-hot-toast";

import { Icons, theme } from "../../../assets";
import { useUser } from "../../../context";
import { useFetch } from "../../../hooks";
import { TDetailsEntry, TEntryTask, TLastTask } from "../../../models";
import {
    TReadActivitiesSection,
    TSectionActivity,
} from "../../../pages/organization/project/tab-activities/types";
import { EntryFields } from "../../../pages/track/components/card-container/entry";
import { TSectionsActivitiesOption } from "../../../pages/track/track";
import { activities, track } from "../../../services";
import {
    CreateActivityError,
    CreateActivityResponse,
    CreateActivityVariables,
} from "../../../services/activities/types";
import { ButtonShowMore, SelectActivity, Tag } from "../../atoms";
import { PopoverProject } from "../popovers";
import { TProjectList } from "../popovers/popover-project/popover-project";
import * as s from "./styled-edit-entry";

type TEditEntry = {
    task: TEntryTask;
    entry?: TDetailsEntry;
    projectList: TProjectList[];
    manageIsOpen?: (index: number) => void;
    index: number;
    control: Control<EntryFields>;
    setValue: UseFormSetValue<EntryFields>;
    setEditIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditEntry = ({
    task,
    projectList,
    index,
    control,
    manageIsOpen,
    setValue,
    setEditIsValid,
}: TEditEntry) => {
    const [options, setOptions] = useState<TSectionsActivitiesOption[]>([]);
    const [lastTasks, setLastTasks] = useState<TLastTask[]>([]);
    const [project, setProject] = useState<TProjectList>(
        task?.project as TProjectList
    );
    const [selectedActivity, setSelectedActivity] = useState<
        TSectionsActivitiesOption | undefined
    >({
        label: task?.entries?.[0]?.Activity?.name || "",
        value: task?.entries?.[0]?.Activity?.id_e,
        section: task?.entries?.[0]?.Activity?.ActivitySection?.name,
    });

    const { favoriteProject } = useUser();

    const readActivities = useFetch({
        fn: activities.readActivities,
        start: false,
        params: { projectId: project?.id_e },
    });

    const readEntries = useFetch({
        fn: track.getEntries,
        start: false,
        params: {
            responseFormat: "timeTrack",
            length: 10,
            page: 1,
            projectId: project?.id_e,
        },
    });

    const createActivity = useMutation<
        CreateActivityResponse,
        CreateActivityError,
        CreateActivityVariables
    >({
        mutationFn: activities.createActivity,
        onSuccess: (data) => {
            if (project?.id_e) {
                setSelectedActivity({
                    label: data.name,
                    value: data.id_e,
                });
                setValue("activityId", data.id_e);
                setEditIsValid?.(true);
                setValue("description", data?.name);
            }
            toast.success("Atividade criada com sucesso!");
            readActivities.onRefresh({
                projectId: project?.id_e,
            });
            readEntries.onRefresh({
                responseFormat: "timeTrack",
                length: 10,
                page: 1,
                projectId: project?.id_e,
            });
        },
    });

    useEffect(() => {
        if (task.sector) {
            setValue("sectorId", task.sector.id_e);
        }
    }, [task]);

    useEffect(() => {
        if (project) {
            readActivities.onRefresh({
                projectId: project?.id_e,
            });
            readEntries.onRefresh({
                responseFormat: "timeTrack",
                length: 10,
                page: 1,
                projectId: project?.id_e,
            });
        }
        if (project.id_e !== task?.project.id_e) {
            setSelectedActivity(undefined);
        }
    }, [project]);

    useEffect(() => {
        if (readEntries.response) {
            setLastTasks(readEntries.response.lastTasks);
        }
    }, [readEntries.response]);

    useEffect(() => {
        if (readActivities.response) {
            if (readActivities.response) {
                const optionsWithSections =
                    readActivities.response.noArchived.sections.map(
                        (section: TReadActivitiesSection) => {
                            const activitiesOptions = section.activities.map(
                                (activity: TSectionActivity) => ({
                                    label: activity.name,
                                    value: activity.id_e,
                                })
                            );
                            return {
                                label: section.sectionName,
                                options: activitiesOptions,
                            };
                        }
                    );
                const optionsWithoutSections = [
                    {
                        label: "Sem sessão",
                        options:
                            readActivities.response.noArchived.activities.map(
                                (activity: TSectionActivity) => ({
                                    label: activity.name,
                                    value: activity.id_e,
                                })
                            ),
                    },
                ];
                setOptions([...optionsWithSections, ...optionsWithoutSections]);
            }
        }
        if (lastTasks?.length) {
            const projectLastTasks = lastTasks.filter(
                (task) => task?.project.id_e === project?.id_e
            );
            const lastActivitiesOptions = {
                label: "Últimas tarefas",
                options: projectLastTasks.map((task) => ({
                    label: task?.activities?.name,
                    value: task?.activities?.id_e,
                    section: task?.activities?.ActivitySection?.name,
                    lastTask: true,
                })),
            };
            setOptions((prev) => [lastActivitiesOptions, ...prev]);
        }
    }, [readActivities.response]);

    return (
        <s.Container>
            <div onClick={(e) => e.stopPropagation()}>
                <Controller
                    name="project"
                    control={control}
                    defaultValue={project.id_e}
                    render={({ field: { onChange } }) => (
                        <PopoverProject
                            side={"bottom"}
                            sideOffset={10}
                            disabled={false}
                            onSelect={(item) => {
                                setProject(item);
                                onChange(item.id_e);
                                setValue("sectorId", item.selectedSector);
                                if (project.id_e !== item.id_e) {
                                    setEditIsValid?.(false);
                                }
                            }}
                            projectList={projectList}
                            trigger={
                                <s.ProjectSelector>
                                    <Tag
                                        maxWidth="200px"
                                        color={project.color}
                                        isFavorite={
                                            favoriteProject?.id_e ===
                                            project.id_e
                                        }
                                    >
                                        {project.name}
                                    </Tag>
                                    <Icons.Chevron color={theme.gray600} />
                                </s.ProjectSelector>
                            }
                        />
                    )}
                />
            </div>
            {task.entries.length > 1 && task.entries[1].end_at ? (
                <ButtonShowMore
                    numberOfItems={
                        task.entries.filter((item) => !!item.end_at).length
                    }
                    isOpen={task.entryOpen}
                    onClick={() => {
                        manageIsOpen?.(index);
                    }}
                />
            ) : (
                <div></div>
            )}
            <Controller
                name="activityId"
                control={control}
                defaultValue={selectedActivity?.value}
                render={({ field: { onChange } }) => (
                    <SelectActivity
                        type="edit"
                        options={options}
                        createActivity={createActivity}
                        project={project}
                        selectedActivity={selectedActivity}
                        setEditIsValid={setEditIsValid}
                        onChange={(value) => {
                            setSelectedActivity(
                                value as TSectionsActivitiesOption
                            );
                            onChange(value?.value);
                            setEditIsValid?.(true);
                            setValue("description", value?.label);
                        }}
                    />
                )}
            />
        </s.Container>
    );
};
