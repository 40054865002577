import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    padding: 16px;
    width: 680px;
    flex-direction: column;
    gap: 35px;
    font-size: 14px;

    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 9px;
        }

        h1 {
            color: #ed6c02;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.07px;
        }

        @media (max-width: 1366px) {
            h1 {
                font-size: 12px;
            }
        }
    }

    section {
        color: ${({ theme }) => theme.gray600};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.08px;

        @media (max-width: 1366px) {
            font-size: 14px;
        }
    }
`;

export const Button = styled.div`
    display: flex;
    width: 100%;
    padding: 12px 16px;
    background-color: ${({ theme }) => theme.purple500};
    color: #ffffff;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;

    @media (max-width: 1366px) {
        font-size: 12px;
    }
`;
