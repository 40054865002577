import { differenceInDays } from "date-fns";

export const getProjectStatus = (
    start_at: string,
    end_at?: string,
    finished?: boolean
) => {
    if (start_at && end_at) {
        const currentDate = new Date();
        const startDate = start_at ? new Date(start_at) : null;
        const endDate = end_at ? new Date(end_at) : null;

        if (finished) {
            return "Finalizado";
        }
        if (!startDate && !endDate) {
            return "-";
        }
        if (startDate && currentDate < startDate) {
            return "A iniciar";
        }
        if (endDate && currentDate > endDate) {
            return "Atrasado";
        }
        return "Ativo";
    }
    return "-";
};

export const getProjectProjectionStatus = (
    type: "hours" | "scope" | "cost",
    status:
        | "to_start"
        | "in_progress"
        | "late"
        | "paused"
        | "canceled"
        | "finished"
        | "no_information",
    projectIsLate?: boolean,
    valueInMessage?: number | string
) => {
    const getTitleAndMessage = (title: string, message: string) => ({
        title,
        message,
    });

    const projectionsStatus = {
        hours: {
            to_start: getTitleAndMessage(
                "Horas em dia",
                "Com base no progresso atual, as horas estão em conformidade com as expectativas."
            ),
            in_progress: getTitleAndMessage(
                "Horas em dia",
                "Com base no progresso atual, as horas estão em conformidade com as expectativas."
            ),
            late: getTitleAndMessage(
                "Projeção de atraso das horas",
                "Com base no progresso atual, estima-se realizar -% das horas previstas na data de término do projeto."
            ),
            paused: getTitleAndMessage(
                "Projeto pausado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            canceled: getTitleAndMessage(
                "Projeto cancelado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            finished: getTitleAndMessage(
                "Projeto concluído",
                "O projeto está com status concluído."
            ),
            no_information: getTitleAndMessage(
                "Sem informação",
                "O projeto está com status sem informação."
            ),
        },
        cost: {
            to_start: getTitleAndMessage(
                "Custo em dia",
                "Com base no progresso atual, o custo está em conformidade com as expectativas."
            ),
            in_progress: getTitleAndMessage(
                "Custo em dia",
                "Com base no progresso atual, o custo está em conformidade com as expectativas."
            ),
            late: getTitleAndMessage(
                "Projeção de custo extra",
                "Com base no progresso atual, estima-se completar -% do custo estimado antes de completar as horas previstas."
            ),
            paused: getTitleAndMessage(
                "Projeto pausado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            canceled: getTitleAndMessage(
                "Projeto cancelado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            finished: getTitleAndMessage(
                "Projeto concluído",
                "O projeto está com status concluído."
            ),
            no_information: getTitleAndMessage(
                "Sem informação",
                "O projeto está com status sem informação."
            ),
        },
        scope: {
            to_start: getTitleAndMessage(
                "Escopo em dia",
                "Com base no progresso atual, o escopo está em conformidade com as expectativas."
            ),
            in_progress: getTitleAndMessage(
                "Escopo em dia",
                "Com base no progresso atual, o escopo está em conformidade com as expectativas."
            ),
            late: getTitleAndMessage(
                "Projeção de escopo incompleto",
                "Com base no progresso atual, estima-se que o escopo não será finalizado ao final das horas previstas do projeto."
            ),
            paused: getTitleAndMessage(
                "Projeto pausado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            canceled: getTitleAndMessage(
                "Projeto cancelado",
                "As projeções dos projetos são geradas somente quando eles se encontram ativos (em andamento ou atrasados)."
            ),
            finished: getTitleAndMessage(
                "Projeto concluído",
                "O projeto está com status concluído."
            ),
            no_information: getTitleAndMessage(
                "Sem informação",
                "O projeto está com status sem informação."
            ),
        },
    };

    let response = {
        title: "",
        message: "",
    };

    const mandatoryStatus =
        status === "canceled" ||
        status === "finished" ||
        status === "paused" ||
        status === "late";

    if (projectIsLate && !mandatoryStatus) {
        if (type === "hours") {
            response.title = "Projeção de atraso das horas";
            response.message = `Com base no progresso atual, estima-se realizar ${valueInMessage}% das horas previstas na data de término do projeto.`;
        } else if (type === "cost") {
            response.title = "Projeção de custo extra";
            response.message = `Com base no progresso atual, estima-se completar ${valueInMessage}% do custo estimado antes de completar as horas previstas.`;
        } else if (type === "scope") {
            response.title = "Projeção de escopo incompleto";
            response.message = `Com base no progresso atual, estima-se que o escopo não será finalizado ao final das horas previstas do projeto.`;
        }
    } else {
        response = {
            title: projectionsStatus[type][status]?.title,
            message: projectionsStatus[type][status]?.message,
        };
    }

    if (valueInMessage) {
        response.message = response?.message?.replace(
            "-%",
            `${valueInMessage}%`
        );
    }

    return response;
};

export const getProjectDurationProjection = (
    start_at: string,
    end_at: string,
    percentageHoursDone: number,
    estimatedHours: number,
    total_time: number
) => {
    if (start_at && end_at && percentageHoursDone && estimatedHours) {
        const hoursRealized = total_time / 60;
        const daysRealized = differenceInDays(new Date(), new Date(start_at));
        const averageHoursPerDay = hoursRealized / daysRealized;

        const hoursLeft = estimatedHours - hoursRealized;
        const daysLeftWithCurrentAverage = Math.round(
            hoursLeft / averageHoursPerDay
        );
        const daysLeftToFinishProject =
            differenceInDays(new Date(end_at), new Date()) + 2;
        const daysExtraToFinishProject =
            daysLeftToFinishProject - daysLeftWithCurrentAverage;
        return Math.round(daysExtraToFinishProject);
    }
    return 0;
};

export const getProjectHourDelayProjectionPercent = (
    start_at: string,
    end_at: string,
    estimatedHours: number,
    total_time: number
) => {
    if (start_at && end_at && estimatedHours) {
        const hoursRealized = total_time / 60;
        const daysRealized = differenceInDays(new Date(), new Date(start_at));
        const averageHoursPerDay = hoursRealized / daysRealized;
        const daysLeftToFinishProject =
            differenceInDays(new Date(end_at), new Date()) + 2;

        const hoursProjection = daysLeftToFinishProject * averageHoursPerDay;
        const difference = estimatedHours - hoursProjection;
        const percentageHoursAtFinalDate = (difference / estimatedHours) * 100;
        return Math.round(percentageHoursAtFinalDate);
    }
    return 0;
};

export const getProjectCostDelayProjectionPercent = (
    estimatedHours: number,
    total_time: number,
    costDone: number,
    expectedCost: number
) => {
    let percentageCostWhenEstimatedHoursWillComplete = 0;

    if (estimatedHours && total_time && costDone && expectedCost) {
        const hoursRealized = total_time / 60;
        const costByHour = costDone / hoursRealized;
        const hoursToHitTheEstimative = estimatedHours - hoursRealized;
        const costToHitTheEstimative = hoursToHitTheEstimative * costByHour;
        const percentageCostWhenHitEstimative =
            (costToHitTheEstimative / expectedCost) * 100;
        percentageCostWhenEstimatedHoursWillComplete = Math.round(
            percentageCostWhenHitEstimative
        );
    }

    return percentageCostWhenEstimatedHoursWillComplete;
};
