import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 20px;
    gap: 1.1875rem;

    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.375rem;
        letter-spacing: 0.0015em;
        color: ${({ theme }) => theme.purple500};
    }
    h2 {
        font-size: 1rem;
        letter-spacing: 0.08em;
        text-align: left;
        text-transform: uppercase;
        color: ${({ theme }) => theme.gray800};
    }
    p {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.005em;
        text-align: left;
    }
    div {
        display: flex;
        gap: 0.5rem;
    }

    div#buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;
