import { TGetRole } from "../../models";
import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

export default async ({
    userId,
    roleId,
    permissionId,
    permissions,
    all,
    users,
    page,
}: TGetRole = {}) => {
    const params = {
        userId,
        roleId,
        permissionId,
        permissions,
        all,
        users,
        page,
    };

    const tempHeaders = {
        auth: true,
        "x-user-id": userId,
        "x-role-id": roleId,
        "x-permission-id": permissionId,
    };

    if (!userId) delete tempHeaders["x-user-id"];
    if (!roleId) delete tempHeaders["x-role-id"];
    if (!permissionId) delete tempHeaders["x-permission-id"];

    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/roles/read`,
        method: "get",
        headers: { auth: true },
        params,
    });

    const { body, statusCode } = httpResponse;
    const errorFeedBacks = {
        403:
            body.message === "You don't have permission to access this resource"
                ? "Você não tem permissão para acessar esse recurso"
                : "Erro ao tentar listar as atuações",
        404: "Sem atuações!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
