import { theme } from "..";

import { TBaseSVG } from "../../models";

export default ({
    title,
    color,
    className,
    onClick,
    width,
    height,
}: TBaseSVG) => {
    return (
        <svg
            onClick={onClick}
            width={width || "38px"}
            height={height || "38px"}
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || "icon-check"}
        >
            <title>{title}</title>
            <mask
                id="mask0_18922_251589"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="38"
                height="38"
            >
                <rect width="38" height="38" fill={color || theme.white3} />
            </mask>
            <g mask="url(#mask0_18922_251589)">
                <path
                    d="M15.1203 27.8271C14.9092 27.8271 14.7112 27.7941 14.5265 27.7281C14.3418 27.6621 14.1703 27.55 14.0119 27.3916L7.20359 20.5833C6.91332 20.293 6.77478 19.917 6.78797 19.4552C6.80116 18.9934 6.9529 18.6173 7.24318 18.3271C7.53346 18.0368 7.9029 17.8916 8.35151 17.8916C8.80012 17.8916 9.16957 18.0368 9.45984 18.3271L15.1203 23.9875L28.539 10.5687C28.8293 10.2784 29.2053 10.1333 29.6671 10.1333C30.1289 10.1333 30.505 10.2784 30.7953 10.5687C31.0855 10.859 31.2307 11.235 31.2307 11.6968C31.2307 12.1586 31.0855 12.5347 30.7953 12.825L16.2286 27.3916C16.0703 27.55 15.8987 27.6621 15.714 27.7281C15.5293 27.7941 15.3314 27.8271 15.1203 27.8271Z"
                    fill={color || theme.purple300}
                />
            </g>
        </svg>
    );
};
