import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

type TCities = {
    label: string;
    value: string;
};

export default async (UF?: string) => {
    const httpResponse = await axiosHttpClient({
        url: UF
            ? `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios?orderBy=nome`
            : "https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome",
        method: "get",
    });
    const { body, statusCode } = httpResponse;

    const arrCities: TCities[] = body.map((item: Record<string, string>) => {
        return { label: item.nome, value: item.nome };
    });

    return handleStatusCases({ body: arrCities, statusCode });
};
