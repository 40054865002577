import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 164px;
    height: 42px;
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: 4px;
    background: ${({ theme }) => theme.white};
    padding: 0 8px;
    justify-content: center;
    gap: 4px;
`;

export const Information = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${({ theme }) => theme.gray800};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;

    span {
        color: ${({ theme }) => theme.gray500};
    }
`;

export const ProgressBar = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.gray100};
    border-radius: 4px;
    position: relative;
`;

export const Bar = styled.div<{ percentage: number }>`
    width: ${({ percentage }) => {
        if (percentage >= 100) {
            return "100%";
        }
        return `${percentage}%`;
    }};
    height: 4px;
    background: ${({ theme, color }) => color || theme.purple500};
    border-radius: 4px;
`;
