import { axiosHttpClient } from "../axios";
import handleStatusCases from "../handle-status-cases";

const { REACT_APP_BASE_URL } = process.env;

type TUploadLogo = {
    logo?: FormData;
};

export default async ({ logo }: TUploadLogo = {}) => {
    const httpResponse = await axiosHttpClient({
        url: `${REACT_APP_BASE_URL}/organization/uploadLogo`,
        method: "patch",
        headers: { auth: true },
        body: logo,
    });
    const { body, statusCode } = httpResponse;

    const errorFeedBacks = {
        400: "Não foi possível atualizar a logo da organização!",
        401: "Acesso negado",
        404: "Organização não existente!",
    };

    return handleStatusCases({
        body,
        statusCode,
        errorFeedBacks,
    });
};
