import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    height: fit-content;
    transition: all 0.2s ease-in-out;
`;

export const Indicator = styled.div<{ done?: boolean }>`
    color: ${({ theme, done }) => (done ? theme.pink600 : theme.white3)};
    font-size: 22px;
    width: fit-content;
    height: 8px;
    display: flex;
    align-items: center;
`;

export const Title = styled.div<{ done?: boolean }>`
    color: var(--color-gray-400, #9e9ba7);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.14px;
    text-decoration-line: ${({ done }) => (done ? "line-through" : "none")};
    transition: text-decoration-line 0.2s ease-in-out;
`;
