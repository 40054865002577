import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.purple50};
    padding: 8px 26px 7px 39px;
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 8px;
    height: fit-content;
    justify-content: space-between;

    div {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 63%;
        gap: 32px;
        img {
            width: 100px;
            height: 100px;
        }

        section {
            display: flex;
            flex-direction: column;
            gap: 8px;
            h1 {
                color: ${({ theme }) => theme.gray800};
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: 0.03px;

                strong {
                    color: ${({ theme }) => theme.pink600};
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                    letter-spacing: 0.03px;
                }
            }

            p {
                color: ${({ theme }) => theme.gray800};
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.14px;
            }
        }
    }

    button {
        color: ${({ theme }) => theme.white};
        display: flex;
        height: 40px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1.2px solid ${({ theme }) => theme.purple500};
        background: ${({ theme }) => theme.purple500};
        white-space: nowrap;
        width: fit-content;

        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
    }

    @media (max-width: 1500px) {
        background-color: ${({ theme }) => theme.purple50};
        padding: 8px 26px 7px 39px;
        margin-top: 38px;
        display: flex;
        align-items: center;
        gap: 32px;
        height: fit-content;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 75%;
            gap: 32px;
            img {
                width: 100px;
                height: 100px;
            }

            section {
                display: flex;
                flex-direction: column;
                gap: 8px;
                h1 {
                    color: ${({ theme }) => theme.gray800};
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                    letter-spacing: 0.03px;

                    strong {
                        color: ${({ theme }) => theme.pink600};
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 28px;
                        letter-spacing: 0.03px;
                    }
                }

                p {
                    color: ${({ theme }) => theme.gray800};
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.14px;
                }
            }
        }

        button {
            color: ${({ theme }) => theme.white};
            display: flex;
            height: 40px;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 4px;
            border: 1.2px solid ${({ theme }) => theme.purple500};
            background: ${({ theme }) => theme.purple500};
            white-space: nowrap;
            width: fit-content;

            font-size: 10px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }
    }
`;
