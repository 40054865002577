import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { FotoUser, Logo } from "../../../../../../../../assets";
import {
    CardNotification,
    Input,
    PrimaryButton,
    SecondaryButton,
    SelectInput,
} from "../../../../../../../../components";
import InputImage from "../../../../../../../../components/atoms/input-image/input-image";
import ContainerModalScroll from "../../../../../../../../components/molecules/modals/container-modal-scroll";
import { TOption } from "../../../../../../../../models";
import { notification } from "../../../../../../../../services";
import { queryClient } from "../../../../../../../../utils/query-client/query-client";
import { schema } from "./schema";
import * as s from "./styles";

type FormData = {
    title: string;
    sub_title?: string;
    description: string;
    path_to_go?: string;
    type: string;
    button_text?: string;
};

const options = [
    {
        label: "Manutenção",
        value: "maintenance",
    },
    {
        label: "Implementação",
        value: "feature",
    },
    {
        label: "Apenas administradores",
        value: "organization",
    },
];

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NewNotificationModal = ({ setOpen }: Props) => {
    const [base64, setBase64] = useState<string>();

    const createNotificationToAllUsers = useMutation({
        mutationKey: ["createNotificationToAllUsers"],
        mutationFn: notification.createNotificationToAllUsers,
        onSuccess() {
            setOpen(false);
            toast.success("Notificação enviada com sucesso!");
            queryClient.invalidateQueries(["backOffice"]);
        },
    });

    const {
        register,
        watch,
        setValue,
        handleSubmit,
        formState: { errors },
        resetField,
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            button_text: "",
            description: "",
            path_to_go: "",
            sub_title: "",
            title: "",
            type: "feature",
        },
    });

    const submit = (data: FormData) => {
        createNotificationToAllUsers.mutate({
            ...data,
            image: watch("type") !== "maintenance" ? base64 : undefined,
        });
    };

    return (
        <ContainerModalScroll closeIcon onOpenChange={setOpen}>
            <s.Container onSubmit={handleSubmit(submit)}>
                <s.Title>Nova notificação</s.Title>
                <s.Fields>
                    <s.SideBySideFields>
                        <Input
                            label="Título"
                            {...register("title")}
                            error={errors.title?.message}
                        />
                        <SelectInput
                            {...register("type")}
                            onChange={(e: TOption) => {
                                resetField("sub_title");
                                setValue("type", e.value);
                            }}
                            name="type"
                            label="Tipo"
                            placeholder=" "
                            options={options}
                            value={options.find(
                                ({ value }) => value === watch("type")
                            )}
                            error={errors.type?.message}
                            noError={!errors.type?.message}
                        />
                    </s.SideBySideFields>
                    <s.SideBySideFields>
                        {watch("type") !== "maintenance" && (
                            <Input
                                label="Sub título"
                                error={errors.sub_title?.message}
                                {...register("sub_title")}
                            />
                        )}
                        <Input
                            label="Descrição"
                            error={errors.description?.message}
                            {...register("description")}
                        />
                    </s.SideBySideFields>
                    <s.SideBySideFields>
                        <Input
                            label="Caminho do botão"
                            {...register("path_to_go")}
                            error={errors.path_to_go?.message}
                        />
                        <Input
                            label="Texto do botão"
                            {...register("button_text")}
                            error={errors.button_text?.message}
                        />
                    </s.SideBySideFields>
                    {watch("type") !== "maintenance" && (
                        <InputImage setBase64={setBase64} />
                    )}
                </s.Fields>

                <CardNotification
                    notification={{
                        id_e: "",
                        description: watch("description"),
                        created_at: new Date().toISOString(),
                        user_id: "",
                        title: watch("title"),
                        wasRead: false,
                        type: watch("type"),
                        sub_title: watch("sub_title"),
                        path_to_go: watch("path_to_go"),
                        button_text: watch("button_text"),
                        image: base64,
                        Receiver: {
                            name: "",
                            avatar: FotoUser,
                        },
                        Sender: {
                            avatar: Logo,
                            name: "",
                        },
                    }}
                />
                <s.Buttons>
                    <SecondaryButton
                        onClick={() => setOpen(false)}
                        type="button"
                    >
                        Cancelar
                    </SecondaryButton>
                    <PrimaryButton
                        loading={createNotificationToAllUsers.isLoading}
                    >
                        Criar notificação
                    </PrimaryButton>
                </s.Buttons>
            </s.Container>
        </ContainerModalScroll>
    );
};
