import styled from "styled-components";

type TBar = {
    percentage?: number;
    thickness?: number;
    limiter?: boolean;
};
export const Container = styled.div`
    width: 100%;
    background: #cdcdcd;
    min-height: 4px;
`;
export const BarContainer = styled.span<TBar>`
    display: flex;
    width: 100%;
    position: relative;

    &::after {
        content: "";
        display: block;
        background: green;
        width: ${({ percentage }) => percentage}%;
        height: 4px;
        position: absolute;
    }
    &::before {
        content: "";
        height: 12px;
        background: black;
        width: 3px;
        position: absolute;
        left: ${({ percentage }) => percentage}%;
        opacity: ${({ limiter }) => !limiter && "0"};
    }
`;
export const Bar = styled.div<TBar>`
    display: flex;
    justify-content: flex-end;
    background: ${({ color, theme }) => color || theme.purple500};
    width: ${({ percentage }) => percentage}%;
    height: ${({ thickness }) => `${thickness || 4}px`};
`;
export const BarDelimiter = styled.div`
    height: 12px;
    background: black;
    width: 3px;
`;

export const TooltipContent = styled.div`
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.01em;
    color: ${({ theme }) => theme.purple800};
    gap: 16px;
    padding: 10px 16px;
`;

export const TooltipDuration = styled.span`
    color: ${({ theme }) => theme.gray600};
`;

export const TooltipPercentage = styled.span`
    color: ${({ theme }) => theme.gray400};
`;
