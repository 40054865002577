import React, { ChangeEvent } from "react";
import InputMask from "react-input-mask";

import * as s from "./styled-input";

type TInput = {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
    value?: string | number;
    placeholder?: string;
    type?: HTMLInputElement["type"];
    icon?: React.ReactNode;
    label?: string | JSX.Element;
    error?: string;
    autoComplete?: string;
    name?: string;
    mask?: string;
    formatChars?: { [key: string]: string } | undefined;
    maxLength?: number | undefined;
    id?: string;
    defaultValue?: string | number | readonly string[] | undefined;
    className?: string;
    disabled?: boolean;
    rightSideLabelIcon?: React.ReactNode;
    rightInputText?: string;
    accept?: string;
};

const Input = React.forwardRef<HTMLInputElement, TInput>(
    (
        {
            onChange,
            onBlur,
            onFocus,
            placeholder,
            className,
            type,
            icon,
            error,
            label,
            autoComplete,
            name,
            mask,
            formatChars,
            maxLength,
            id,
            defaultValue,
            disabled,
            value,
            rightSideLabelIcon,
            rightInputText,
            accept,
        },
        ref
    ) => {
        if (typeof label === "string") {
            id = label;
        }
        return (
            <s.Content>
                <s.FlexColumn className="input-wrapper">
                    {label && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "0.625rem",
                            }}
                            className="input-label"
                        >
                            {typeof label === "string" ? (
                                <s.Label htmlFor={id || label}>{label}</s.Label>
                            ) : (
                                <s.Label htmlFor={id}>{label}</s.Label>
                            )}
                        </div>
                    )}
                    <s.FlexRow $error={!!error} $icon={!!icon}>
                        {rightInputText ? (
                            <s.PositionText>{rightInputText}</s.PositionText>
                        ) : null}
                        <InputMask
                            disabled={disabled}
                            className={className}
                            id={id}
                            defaultValue={defaultValue}
                            value={value}
                            maxLength={maxLength}
                            mask={mask || ""}
                            inputRef={ref}
                            autoComplete={autoComplete}
                            type={type}
                            placeholder={placeholder}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            alwaysShowMask={false}
                            maskChar={null}
                            formatChars={formatChars}
                            onFocus={onFocus}
                            accept={accept}
                        />

                        {icon ? (
                            <s.PositionIcons>{icon}</s.PositionIcons>
                        ) : null}
                    </s.FlexRow>
                    {error && <s.Error>{error}</s.Error>}
                </s.FlexColumn>
                {rightSideLabelIcon && (
                    <s.FlexRow>{rightSideLabelIcon}</s.FlexRow>
                )}
            </s.Content>
        );
    }
);

export default Input;
