import { useMutation } from "@tanstack/react-query";

import { requestAdditionalHours } from "../../services/hour-control";

type Params = {
    onSuccess: () => void;
};

export function useRequestAdditionalHours({ onSuccess }: Params) {
    return useMutation(requestAdditionalHours, {
        onSuccess,
    });
}
