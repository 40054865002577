import * as s from "../styles";

type TotalProps = {
    children: React.ReactNode[] | React.ReactNode;
    columns?: number;
};

export const Total = ({ children, columns = 1 }: TotalProps) => {
    const childrenLength = Array.isArray(children) && children.length + 1;
    return (
        <s.Total columns={childrenLength || columns}>
            <s.Data bold>Total</s.Data>
            {children}
        </s.Total>
    );
};
