import { Icons, theme } from "../../../../assets";
import Tooltip from "../../../../components/atoms/tooltip/tooltip";
import * as s from "../styled-components";

type Props = {
    isRunning: boolean | undefined;
    isOnManualMode: boolean;
    handleDeleteCurrentTime: () => void;
    setIsOnManualMode: React.Dispatch<React.SetStateAction<boolean>>;
    disabled?: boolean;
};

export const TrackIcons = ({
    isRunning,
    isOnManualMode,
    handleDeleteCurrentTime,
    setIsOnManualMode,
    disabled,
}: Props) => {
    if (disabled) {
        return (
            <>
                <Tooltip
                    side="top"
                    align={"end"}
                    positiony={5}
                    content={<s.SmallP>Modo cronômetro</s.SmallP>}
                >
                    <div style={{ display: "flex" }}>
                        <Icons.Clock color={theme.gray200} />
                    </div>
                </Tooltip>
                <Tooltip
                    side="bottom"
                    align={"end"}
                    content={<s.SmallP>Modo manual</s.SmallP>}
                >
                    <div style={{ display: "flex" }}>
                        <Icons.HandOutline color={theme.gray200} />
                    </div>
                </Tooltip>
            </>
        );
    }
    if (isRunning) {
        return (
            <Tooltip
                side="top"
                align={"end"}
                positiony={20}
                content={<s.SmallP>Cancelar Registro</s.SmallP>}
            >
                <div style={{ display: "flex" }}>
                    <Icons.Trash onClick={() => handleDeleteCurrentTime()} />
                </div>
            </Tooltip>
        );
    }
    return (
        <>
            <Tooltip
                side="top"
                align={"end"}
                positiony={5}
                content={<s.SmallP>Modo cronômetro</s.SmallP>}
            >
                <div style={{ display: "flex" }}>
                    {isOnManualMode ? (
                        <Icons.ClockOutline
                            onClick={() => setIsOnManualMode(false)}
                        />
                    ) : (
                        <Icons.Clock />
                    )}
                </div>
            </Tooltip>
            <Tooltip
                side="bottom"
                align={"end"}
                content={<s.SmallP>Modo manual</s.SmallP>}
            >
                <div style={{ display: "flex" }}>
                    {isOnManualMode ? (
                        <Icons.Hand />
                    ) : (
                        <Icons.HandOutline
                            onClick={() => setIsOnManualMode(true)}
                        />
                    )}
                </div>
            </Tooltip>
        </>
    );
};
